<form [formGroup]="form" errorTailor id="idForTest">
  <div class="bg-involve-org-without-interaction">
    <mat-dialog-content class="mat-typography">
      <div class="bg-row">
        <div class="bg-col-6">
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="optionsIncidentTypesOptions" formControlName="attractOrgData"></sc-select>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="phone1Options" formControlName="phone1"></sc-input>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="statusStepOptions" formControlName="status"></sc-select>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="attractOrgStatusOptions" formControlName="attractOrgStatus"></sc-select>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-col-6">
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="responseTimeOptions" formControlName="responseTime"></sc-input>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="timeReactOptions" formControlName="timeReact"></sc-input>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-textarea
                  class="involved-org-textarea"
                  [options]="optionsComment"
                  formControlName="operatorComment"
                ></sc-textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="!form.disabled">
      <sc-button [options]="cancelBtnOptions" (clickButton)="closeHandler()"></sc-button>
      <sc-button [options]="saveBtnOptions" (clickButton)="saveHandler($event)"></sc-button>
    </mat-dialog-actions>
  </div>
</form>
