<div class="response-plan-item">
  <div class="response-plan-item-name">
    <div class="response-plan-icon" [innerHTML]="svg"></div>
    <div class="response-plan-item-description">
      <div class="organization-type"> {{ descriptionStep }} </div>
      <div
        *ngIf="organizationName"
        [matTooltip]="organizationName"
        class="organization-name">
        {{ organizationShortName }}
      </div>
    </div>
  </div>
  <div [ngClass]="{ 'response-plan-item-too-late': tooLate }">
    {{ status }}
  </div>
</div>
