<div
  class="atm-notification"
  [ngClass]="{ 'atm-notification__active': isActive }"
  (click)="onClick($event)"
>
  <div
    class="atm-notification__marker"
    *ngIf="isActive"
  ></div>
  <div class="atm-notification__header">
    <div class="atm-notification__name">
      <div class="atm-notification__state">
        {{ notification.type }}
      </div>
      {{ notification.latchDt }}
    </div>
  </div>
  <div class="two-line-ellipsis-overflow" [matTooltip]="notification.latchMessage">
    {{ notification.latchMessage }}
  </div>
</div>
