import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { IHlsStroke } from '../../../hls-player/interfaces';
import { ICanvasCoordinate } from '../../models/interfaces';

/**
 * Компонент который отрисовывает картинку в canvas вместе с рамкой
 */

@Component({
  selector: 'bg-match-image',
  templateUrl: './match-image.component.html',
  styleUrls: ['./match-image.component.scss'],
})

export class MatchImageComponent implements OnInit, AfterViewInit {

  /** src картинки */
  @Input() path?: string;
  /** данные для рамки */
  @Input() frame?: IHlsStroke | ICanvasCoordinate[];
  /** способ отрисовки рамки */
  @Input() mode?: 'hlsStroke' | 'coordinates' = 'hlsStroke';
  /** Ссылка на dom canvas */
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  /** Ссылка на dom Image */
  private image: HTMLImageElement;
  /** Ссылка на canvas контекст */
  private ctx: CanvasRenderingContext2D;


  public ngOnInit(): void {
    this.initImage();
  }


  public ngAfterViewInit(): void {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.drawImageInCanvas();
  }

  /** Инициализация Dom объекта image
   * @return
   */
  private initImage(): void {
    this.image = new Image();
    this.image.src = `${window.location.origin}${this.path}`;
  }

  /**
   * Отрисовка фотографии в canvas
   * @return
   */
  private drawImageInCanvas(): void {
    const ctx = this.ctx;
    if (this.path) {
      this.image.onload = () => {
        this.canvas.nativeElement.width = this.image.width;
        this.canvas.nativeElement.height = this.image.height;
        ctx.drawImage(this.image, 0, 0, this.image.width, this.image.height);
        this.drawFrameInCanvas();
      };
    }
  }

  /**
   * Отрисовка рамки в canvas
   * @return
   */
  private drawFrameInCanvas(): void {
    const ctx = this.ctx;
    ctx.strokeStyle = 'red';
    ctx.lineWidth = 5;
    if (this.mode === 'hlsStroke') {
      const { width, height } = this.frame as IHlsStroke;
      if (width && height) {
        const xRect = this.calculateXFrame();
        const heightFrame = this.calculateHeightFrame();
        const widthFrame = this.calculateWidthFrame();
        const yRect = this.calculateYFrame();
        ctx.rect(xRect, yRect, widthFrame, heightFrame);
      }
    } else if (this.mode === 'coordinates' && (this.frame as ICanvasCoordinate[]).length > 1) {
      const coordinates = this.frame as ICanvasCoordinate[]
      ctx.moveTo(coordinates[0].X, coordinates[0].Y)
      for (let i = 1; i < coordinates.length; i++) {
        ctx.lineTo(coordinates[i].X, coordinates[i].Y);
      }
      ctx.closePath();
    }
    ctx.stroke();
  }

  /**
   * Расчет координаты x для рамки на картинке в canvas
   * @return
   */
  private calculateXFrame(): number {
    return this.image.width * (this.frame as IHlsStroke).x;
  }

  /**
   * Расчет координаты y рамки на картинке в canvas
   * @return
   */
  private calculateYFrame(): number {
    return this.image.height * (this.frame as IHlsStroke).y;
  }

  /**
   * Расчет высоты рамки в canvas
   * @return
   */
  private calculateHeightFrame(): number {
    return this.image.height * (this.frame as IHlsStroke).height;
  }

  /**
   * Расчет длины рамки в canvas
   * @return
   */
  private calculateWidthFrame(): number {
    return this.image.width * (this.frame as IHlsStroke).width;
  }
}
