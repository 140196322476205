<div class="bg-download-sources bg-download-sources_flex-{{ options?.direction || defaultDirection }}">
  <div class="bg-row bg-download-sources_video-button bg-row-align-content-center" *ngIf="isShowPhotoButton">
    <div class="bg-download-sources_button">
      <sc-button [options]="downLoadPhotoOptions" (clickButton)="photoHandler()"></sc-button>
    </div>
    <div
      class="bg-download-sources_message-icon"
      #tooltipPhoto="matTooltip"
      matTooltipPosition="right"
      [matTooltip]="(currentPhotoStatus$ | async)?.message"
    >
      <i
        [ngClass]="(currentPhotoStatus$ | async)?.icon"
        [ngStyle]="{ color: (currentPhotoStatus$ | async)?.iconColor }"
      ></i>
    </div>
  </div>
  <div class="bg-row bg-download-sources_video-button bg-row-align-content-center">
    <div class="bg-download-sources_button">
      <sc-button [options]="downLoadVideoOptions" (clickButton)="videoHandler()"></sc-button>
    </div>
    <div
      class="bg-download-sources_message-icon"
      matTooltipPosition="right"
      [matTooltip]="(currentVideoStatus$ | async)?.message"
    >
      <i
        [ngClass]="(currentVideoStatus$ | async)?.icon"
        [ngStyle]="{ color: (currentVideoStatus$ | async)?.iconColor }"
      ></i>
    </div>
  </div>
</div>
