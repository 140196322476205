import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IStepDialogDataOptions } from '../../models/interfaces';
import { BaseComponent } from '@bg-front/core/components';

/**
 * Форма которая открывается по клику на элемент в блоке 'Привлекаемые службы' при условии
 * что шаг является запросом из дочернего поручения на привлечение службы
 */

@Component({
  selector: 'org-involve-request-dialog',
  templateUrl: './org-involve-request-dialog.component.html',
})
export class OrgInvolveRequestDialogComponent extends BaseComponent {
  /** Форма */
  public involvedWithoutForm: FormGroup;

  /** Опции для формы */
  public options: IStepDialogDataOptions;

  constructor(
    public readonly dialogRef: MatDialogRef<IStepDialogDataOptions>,
    @Inject(MAT_DIALOG_DATA) public data: IStepDialogDataOptions,
  ) {
    super();
  }

  /** Метод закрытия диалога */
  public closeHandler(): void {
    this.dialogRef.close();
  }
}
