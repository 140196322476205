import { Component, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import * as dayjs from 'dayjs';
import { of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { IEmergencyEventDto } from 'smart-city-types';
import { IAnyObject } from 'smart-city-types/base';
import { retryWithDelay } from '@bg-front/core/models/helpers';
import { BaseEventEditFormComponent } from '../base-event-edit-form/base-event-edit-form.component';
import { IAddressDetailsDto, IDeclarer } from '../../models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/**
 * Форма поднимающаяся на редактирование при ответе на звонок
 */
@UntilDestroy()
@Component({
  selector: 'bg-call-event-edit-form',
  templateUrl: '../base-event-edit-form/base-event-edit-form.component.html',
  styleUrls: ['../base-event-edit-form/base-event-edit-form.component.scss'],
})
export class CallEventEditFormComponent extends BaseEventEditFormComponent implements OnInit, OnDestroy {
  /**
   * Внешний номер записи
   */
  public extId: string = undefined;
  private eventData: IAnyObject  = undefined;
  public linkedName: string;

  /** Инициализация формы */
  public initForm() {
    super.initForm();
    if (!this.model.incidentNumber?.startsWith('w')) {
      this.linkedName = `Инцидент ${this.model.incidentNumber}`;
    } else {
      this.linkedName = `Плановая работа ${this.model.incidentNumber}`;
    }
    this.eventForm.controls['factAddress'].valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.declarerStore.setKsipAddress(result);
      });

    this.eventForm.controls['details'].valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.declarerStore.setBuildingInfo({ room: result.room, entrance: result.entrance, corp: result.corp });
      });

    const subscriber = this.route.params
      .pipe(
        switchMap((params: Params) => {
          this.extId = params['extId'];
          return this.emergencyService.getEventByExtId(this.extId)
            .pipe(
              map((response: IEmergencyEventDto) => {
                this.eventData = response || undefined;
                const items = response || null;
                if (items) {
                  return throwError('Событие звонка не найдено');
                }
                return of(items as IAnyObject); /* IEmergencyEventDto */
              }),
            );
        }),
        retryWithDelay({
          delay: 250,
          maxRetryAttempts: 10,
          onRetry: (attempt, delay, error) => {
            // Событие срабатывающее перед каждой новой попыткой
            console.log('Новая попытка получить событие звонка');
          },
        }),
        mergeMap(() => {
          const data = this.eventData ?? <IAnyObject>{};
          this.model.id = data.id;
          this.model.direction = data.direction;
          this.model.isHandled = false;
          this.model.number = `e-${dayjs().format('YYYYMMDD-HHmmss')}`;
          this.model.parentId = data.parentId?.id;
          this.model.sourceId = data.sourceId;
          this.model.timeCreate = Date.now();
          this.model.ksipTime = Date.now();

            if (data.parentId?.extnum && data.parentId?.extnum !== 'anonymous') {
              return this.declare.mapDeclarerData(data)
                .pipe(
                  mergeMap((declarer: IDeclarer) => {
                      if (declarer) {
                        this.model.declarerId = declarer;
                      }
                    const declarerId = this.model.declarerId;
                    const data = {
                      phone: declarerId?.phone,
                      ...(declarerId?.fio ? { fio: declarerId.fio } : {}),
                      ...(declarerId?.address ? { address: declarerId.address } : {}),
                      ...(declarerId?.contactPhone ? { contactPhone: declarerId.contactPhone } : {}),
                      building: {
                        ...(declarerId?.building?.room ? { room: declarerId?.building?.room } : {} ),
                        ...(declarerId?.building?.entrance ? { entrance: declarerId?.building?.entrance } : {} ),
                        ...(declarerId?.building?.corp ? { corp: declarerId?.building?.corp } : {} ),
                      },
                    };
                    this.declare.$formData.next(data);
                    this.declarerStore.setLoadedPhone(declarerId?.phone);
                    return of(null);
                  }),
                );
            }
          return of(null);
        }),
        takeUntil(this.ngUnsubscribe),
        catchError((err: Error) => {
          console.error(err.message);
          return of(undefined);
        }),
      )
      .subscribe(() => {
        subscriber.unsubscribe();
      });

    // Подписка на изменение типа КСиП
    this.eventForm.controls['ksipTypeId'].valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        // Текущие значения фильтров
        const filters = {};
        this.registryStateService.getValue().$and.forEach((query) => {
          Object.keys(query).forEach(key => filters[key] = query[key]);
        });
        const eventsFilters = {};
        this.registryStateService.getEventsValue().$and.forEach((query) => {
          Object.keys(query).forEach(key => eventsFilters[key] = query[key]);
        });
        // Добавить значение типа КСиП
        filters['incidentTypeId'] = value;
        eventsFilters['ksipTypeId'] = value;
        if (!value) {
          delete filters['incidentTypeId'];
          delete eventsFilters['ksipTypeId'];
        }
        // Преобразование в query
        const result = [];
        Object.keys(filters).forEach(key => result.push({ [key]: filters[key] }));
        const eventsResult = [];
        Object.keys(eventsFilters).forEach(key => eventsResult.push({ [key]: eventsFilters[key] }));
        this.registryStateService.patchValue({ $and: result });
        this.registryStateService.patchEventsValue({ $and: eventsResult });
      });

    // Подписка на изменение Адреса
    this.eventForm.controls['factAddress'].valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((values) => {
        const filters: IAnyObject = {};
        this.registryStateService.getValue().$and.forEach((query) => {
          Object.keys(query).forEach(key => filters[key] = query[key]);
        });
        this.deletePointQuery(filters.$or);

        if (values && values.latitude && values.longitude) {
          filters.point = {
            $radius: {
              $lat: values.latitude,
              $lon: values.longitude,
              $meters: 500,
            },
          };
        } else {
          delete filters.point;
        }

        filters.$or = filters.$or.map((query: IAnyObject) => {
          const hasId = query.hasOwnProperty('id');
          if (!hasId) {
            return query;
          }
        });
        filters.$or = filters.$or.filter((item: IAnyObject) => item);

        if (values?.houseUuid || (values?.latitude && values?.longitude)) {
          let query: IAnyObject;
          if (values.houseUuid) {
            query = {
              $eq: ['$address.houseUuid', values.houseUuid],
            }
          } else {
            query = {
              $and: [
                { $expr: { $eq: ['$address.latitude', values.latitude.toString()] } },
                { $expr: { $eq: ['$address.longitude', values.longitude.toString()] } },
              ],
            }
          }

          this.emergencyService.getAddressDetails({
            $expr: {
              ...query,
            },
          })
            .pipe(untilDestroyed(this))
            .subscribe((res: IAddressDetailsDto[]) => {
              const result: string[] = [];
              res.map((item: IAnyObject) => item.emergencyId).forEach((item: string) => {
                if (!result.includes(item)) {
                  result.push(item)
                }
              });

              if (result.length) {
                delete filters.point;
                filters.$or = [
                  {
                    $and: [
                      { $or: filters.$or},
                      {
                        point: {
                          $radius: {
                            $lat: values.latitude,
                            $lon: values.longitude,
                            $meters: 500,
                          },
                        }
                      }
                    ]
                  },
                  { id: { $in: result } },
                ];
              }
              this.updateFilters(filters);
            })
        } else {
          this.updateFilters(filters);
        }
      });

    // Сообщение об открытии формы звонка
    this.registryStateService.callFormOpened();
  }

  public updateFilters(filters: IAnyObject): void {
    const result = [];
    Object.keys(filters).forEach(key => result.push({ [key]: filters[key] }));
    this.registryStateService.patchValue({ $and: result });
  }

  /** Открываем карточку инцидента по клику на привязанный инцидент */
  public openEmergency() {
    const targetUrl = this.getWorkspaceURL();
    window.open(targetUrl);
  }

  /** Закрыть форму события звонка */
  public closeForm() {
    this.declare.resetDeclarersStore();
    this.router.navigate([{ outlets: { callForm: null } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.declare.resetDeclarersStore();
    this.registryStateService.callFormClosed();
  }
}
