import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@bg-front/core/components';
import { IScButtonOptions, IScSelectOptions } from '@smart-city/core/common';
import { takeUntil } from 'rxjs/operators';
import { IStepDialogDataOptions } from '../../models/interfaces';

@Component({
  selector: 'bg-new-involve-org-form',
  templateUrl: './new-involve-org-form.component.html',
  styleUrls: ['./new-involve-org-form.component.scss'],
})
export class NewInvolveOrgFormComponent extends BaseComponent implements OnInit {
  /** Форма */
  public form: FormGroup;

  @Output()
  public nextEvent: EventEmitter<string> = new EventEmitter<string>(true);
  @Output()
  public closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  /** Настройки для кнопки сохранить */
  public nextBtnOptions: IScButtonOptions = {
    title: 'Далее',
    color: 'primary',
    disabled: true,
  };
  /** Настройки для кнопки отмена */
  public cancelBtnOptions: IScButtonOptions = {
    title: 'Отменить',
  };

  constructor() {
    super();
  }

  public serviceTypeOptions: IScSelectOptions = {
    title: 'Тип службы реагирования *',
    clearable: true,
    service: 'Admin',
    entity: 'OrgTypeParams',
    modern: true,
    fieldName: 'shortName',
    query: {
      active: true,
      attractToReact: true,
    },
  };

  ngOnInit(): void {
    this.form = new FormGroup({
      serviceType: new FormControl(null, [Validators.required]),
    });

    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (this.form?.value?.serviceType) {
        this.nextBtnOptions.disabled = false;
      }
    });
  }

  public nextHandler() {
    const id: string = this.form?.value?.serviceType;
    this.nextEvent.emit(id);
  }

  public closeHandler() {
    this.closeEvent.emit();
  }
}
