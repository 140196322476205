<div class="dialog-container">
  <form [formGroup]="form" errorTailor id="idForTest">
    <div class="bg-row m-t-30">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select [options]="additionAddressMethodOptions" formControlName="additionAddressMethod"></sc-select>
        </div>
      </div>
    </div>
  </form>
  <form [formGroup]="manuallyForm" errorTailor id="idForTest" *ngIf="additionMethodName === 'manually'">
    <div>
      <!-- Чекбокс Адрес по координатам -->
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group p-t-0 p-b-0">
            <sc-checkbox [options]="byCoordinatesOptions" formControlName="byCoordinates"></sc-checkbox>
          </div>
        </div>
        <div class="bg-col align-right m-b-15">
          <button
            mat-raised-button
            class="btn btn-primary copy-button"
            (click)="copyHandler()"
          ><div class='icon-copy-container'>
            <div class="icon-copy"></div>
          </div>
            {{ copyAddressOptions.title }}
          </button>
        </div>
      </div>
      <!--Адрес по координатам-->
      <div class="bg-row" *ngIf="byCoordinates; else fiasAddress">
        <div class="bg-col">
          <div class="bg-form-group full-width">
            <sc-input [options]="coordinatesAddressOptions" formControlName="coordinatesAddress"></sc-input>
          </div>
        </div>
      </div>
      <!--Адрес фиас-->
      <ng-template #fiasAddress>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group full-width">
              <div nz-form nzLayout="vertical">
                <nz-form-item>
                  <nz-form-label>Адрес</nz-form-label>
                  <nz-form-control>
                    <nz-address-kit formControlName="address"></nz-address-kit>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!--Получить данные паспортного стола-->
      <div class="bg-row m-b-15" *ngIf="byCoordinates || getPassportIntegrationDataFlag">
        <div class="bg-col">
          <button
            mat-raised-button
            class="btn btn-primary"
            (click)="getPassportIntegrationData()"
          >Получить данные паспортного стола</button>
        </div>
      </div>
      <!--Вид здания-->
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-select [options]="buildingKindOptions" formControlName="buildingKindId"></sc-select>
          </div>
        </div>
      </div>
      <div class="bg-row m-b-15">
        <div class="bg-col">
          Количество граждан, зарегистрированных в жилом доме
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col p-r-0">
          <div class="bg-form-group">
            <sc-input  [options]="totalOptions" formControlName="totalRegistered"></sc-input>
          </div>
        </div>
        <div class="bg-col p-h-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="menOptions" formControlName="menRegistered"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-col p-h-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="womanOptions" formControlName="womanRegistered"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-col p-h-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="pensionersOptions" formControlName="pensionersRegistered"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-col p-l-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="childrenOptions" formControlName="childrenRegistered"></sc-input>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-row m-b-15">
        <div class="bg-col">
          Количество граждан, проживающих в жилом доме временно
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col p-r-0">
          <div class="bg-form-group">
            <sc-input  [options]="totalOptions" formControlName="totalResidents"></sc-input>
          </div>
        </div>
        <div class="bg-col p-h-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="menOptions" formControlName="menResidents"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-col p-h-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="womanOptions" formControlName="womanResidents"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-col p-h-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="pensionersOptions" formControlName="pensionersResidents"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-col p-l-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="childrenOptions" formControlName="childrenResidents"></sc-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="bg-row" *ngIf="additionMethodName === 'pointOnMap'">
    <div class="bg-col">
      <div class="bg-form-group mini-map-container">
        <map-base *ngIf="mapOptions" [mapOptions]="mapOptions"></map-base>
          <div class="elements">
            <button class="edit-button" *ngIf="!startCreatePoints" (click)="editPoints()">
              Редактировать
            </button>
            <button
              class="edit-button"
              *ngIf="startCreatePoints"
              (click)="savePolygon()"
            >
              Сохранить
            </button>
            <button class="cancel-button" (click)="cancelPoints()" *ngIf="startCreatePoints">Отмена</button>
            <button class="clear-button" (click)="clearPoints()">Удалить</button>
          </div>
      </div>
      <form [formGroup]="pointOnMap">
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group">
              <sc-input [options]="optionsCoordinates" formControlName="coordinates"></sc-input>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="bg-row m-b-15" *ngIf="additionMethodName === 'selectFromJkhObject'">
    <div class="bg-col">
      <grid-table [grid]="gridOptions" class="grid"></grid-table>
    </div>
  </div>
  <div class="bg-row align-right">
    <div class="bg-col">
      <button class="btn btn-secondary-outline m-r-15" (click)="close()">Отмена</button>
      <button class="btn btn-primary" (click)="save()">Добавить</button>
    </div>
  </div>
</div>
