<form nz-form [formGroup]="form" nzLayout="vertical" *ngIf="form" appLCSFormState [params]="attributes">
  <nz-page-header nzBackIcon (nzBack)="close()">
    <nz-page-header-title>Новый запрос</nz-page-header-title>
    <nz-page-header-extra>
      <button nz-button type="button" [disabled]="!form?.valid" (click)="save()">Сохранить</button>
      <button nz-button nzType="primary" type="submit" [disabled]="!form?.valid" (click)="sendRequest()">
        Отправить
      </button>
    </nz-page-header-extra>
    <nz-page-header-content>
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="from">Дата и время начала</nz-form-label>
            <nz-form-control>
              <nz-date-picker
                style="width: 100%"
                formControlName="from"
                nzFormat="dd.MM.yyyy HH:mm:ss"
                nzShowTime
                nzPlaceHolder="Выберите дату и время"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label nzFor="durationInput">Продолжительность видео (мин)</nz-form-label>
            <nz-form-control nzExtra="До 60 мин">
              <nz-input-number
                formControlName="durationInput"
                [nzMin]="1"
                [nzMax]="60"
                [nzStep]="1"
                style="width: 100%"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label nzFor="requestDeadline">Обработать запрос до</nz-form-label>
            <nz-form-control>
              <nz-date-picker
                [nzDisabledDate]=minDateTimeResolveTo
                style="width: 100%"
                formControlName="requestDeadline"
                nzFormat="dd.MM.yyyy"
                nzPlaceHolder="Выберите дату"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label nzFor="reason" nzRequired>Основание</nz-form-label>
            <nz-form-control>
              <nz-textarea-count [nzMaxCharacterCount]="1000">
                <textarea
                  nz-input
                  formControlName="reason"
                  placeholder="Введите основание для запроса"
                  [nzAutosize]="{ minRows: 2 }"
                ></textarea>
              </nz-textarea-count>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label nzFor="deleteTime" [nzRequired]="isLongTermStorage">Хранить информацию до</nz-form-label>
            <nz-form-control>
              <nz-date-picker
                [nzDisabled]="!isLongTermStorage"
                [nzDisabledDate]=deleteTimeMinDate
                style="width: 100%"
                formControlName="deleteTime"
                nzFormat="dd.MM.yyyy"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label nzFor="remindDate">Напомнить за (в днях)</nz-form-label>
            <nz-form-control [nzErrorTip]="remindDateErrors">
              <nz-input-number
                nzPlaceHolder="Напомнить"
                formControlName="remindDate"
                [nzMin]="1"
                [nzMax]="7"
                [nzStep]="1"
                style="width: 100%"
              ></nz-input-number>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-control>
              <label nz-checkbox formControlName="permanent">Долговременное хранение</label>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-page-header-content>
  </nz-page-header>
</form>
