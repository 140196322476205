/** 
 * Парсер списка координат 
 * @param coordinatesList - Строка со списком координат
 * @returns Массив координат типа [number, number][]. 
 * 
 * @example
 * parseCoordinatesList("[1.1, 2.2], [3.3, 4.4]") 
 * returns [[1.1,2.2],[3.3,4.4]]
 * */
export function parseCoordinatesList(coordinatesList: string): [number, number][] {
  const result: [number, number][] = [];

  while (coordinatesList !== '') {
    const coordinatesWithBrackets: string = coordinatesList.substring(0, coordinatesList.indexOf(']') + 1);
    const coordinates: string = coordinatesWithBrackets.substring(1, coordinatesWithBrackets.length - 1);

    result.push([
      Number(coordinates.split(',')[0]), 
      Number(coordinates.split(',')[1])]
    );
    coordinatesList = coordinatesList.substring(coordinatesWithBrackets.length + 2);
  }

  return result;
}
