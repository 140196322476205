<div *ngIf="registry">
  <nw-header [options]="headerOptions" (clickButton)="clickHeaderButton($event)"></nw-header>
  <mat-tab-group class="sc-tab-group" [disableRipple]="true" (selectedTabChange)="selectedTabChange($event)">
    <mat-tab [label]="'Происшествия'">
      <div class="emergency-tab-content">
        <div class="filter-area">
          <div class="search-container">
            <sc-input [options]="incidentsSearchOption"></sc-input>
          </div>
          <bg-planned-works-incident-filter
            (changedFilter)="changedIncidentsFilter($event)"
          ></bg-planned-works-incident-filter>
        </div>
        <div class="emergency-scroll-container">
          <scrollable-container #incidentsScroll [options]="optionsIncidents"></scrollable-container>
        </div>
      </div>
    </mat-tab>

    <mat-tab [label]="'События'">
      <div class="emergency-tab-content">
        <div class="filter-area">
          <div class="search-container">
            <sc-input [options]="eventsSearchOption"></sc-input>
          </div>
          <bg-planned-works-event-filter (changedFilter)="changedEventsFilter($event)"></bg-planned-works-event-filter>
        </div>
        <div class="emergency-scroll-container">
          <scrollable-container #eventsScroll [options]="optionsEvents"></scrollable-container>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
