import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { IScInputOptions, IScSelectOptions } from '@smart-city/core/common';

import { LoosesListComponent } from '../looses-list/looses-list.component';

@Component({
  selector: 'hospitalized-list',
  templateUrl: './hospitalized-list.component.html',
  styleUrls: ['./hospitalized-list.component.scss'],
})
export class HospitalizedListComponent extends LoosesListComponent implements OnInit {
  /** Настройка компоненты Наименование ЛПУ */
  public healthFacilitiesIdOptions = <IScSelectOptions>{
    title: 'Наименование ЛПУ',
    clearable: true,
    service: 'Directories',
    entity: 'HealthFacilities',
    modern: true,
    fieldName: 'name',
    query: {
      'state.sysname': 'active',
    },
  };

  /** Настройка компоненты Состояние пациента */
  patientConditionOptions = <IScSelectOptions>{
    title: 'Состояние',
    data: this.settings.getDictForSelect('patientConditions'),
    clearable: true,
  };

  /** Настройка компоненты Диагноз */
  public diagnosisOptions: IScInputOptions = {
    label: 'Диагноз',
    placeholder: 'Диагноз',
    maxLength: 50,
  };

  /** @ignore */
  public override ngOnInit(): void {
    super.ngOnInit();
    this.listData.forEach((item) =>
      (<FormArray>this.form.controls.list).push(
        new FormGroup({
          id: new FormControl(item.id),
          fio: new FormControl(item.fio),
          birthYear: new FormControl(item.birthYear),
          healthFacilitiesId: new FormControl(item.healthFacilitiesId),
          patientCondition: new FormControl(item.patientCondition),
          diagnosis: new FormControl(item.diagnosis),
          comment: new FormControl(item.comment),
        }),
      ),
    );
  }

  /**
   * Добавление записи
   */
  public add() {
    (<FormArray>this.form.controls.list).push(
      new FormGroup({
        fio: new FormControl(),
        birthYear: new FormControl(),
        healthFacilitiesId: new FormControl(),
        patientCondition: new FormControl(),
        diagnosis: new FormControl(),
        comment: new FormControl(),
      }),
    );
  }
}
