import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

import { KsipSectionsState } from '../../../models/states';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'ksip-sections' })
export class KsipSectionsStore extends EntityStore<KsipSectionsState> {
  constructor() {
    super([]);
  }
}
