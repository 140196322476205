<form [formGroup]="form" errorTailor id="idForTest">
  <div class="bg-row m-b-15">
    <div class="bg-col">
      <p>Автор изменений:</p>
      <p>{{ updateAuthor || '-' }}</p>
    </div>
    <div class="bg-col">
      <p>Дата и время:</p>
      <p>{{ updateTime || '-' }}</p>
    </div>
  </div>
  <div class="bg-row ">
    <div class="bg-col">
      <div class="bg-form-group">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Адрес</nz-form-label>
            <nz-form-control>
              <nz-address-kit formControlName="address"></nz-address-kit>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>
  <div>
    <button class="btn btn-primary m-r-10" (click)="save()">Сохранить</button>
    <button class="btn btn-secondary-outline m-r-10" (click)="close()">Закрыть</button>
  </div>
</form>
