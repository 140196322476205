<div [formGroup]="$any(controlContainer.control)">
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-select
          [options]="fireObjectType" formControlName="fireObjectTypeId"></sc-select>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-select [options]="objectFire" formControlName="objectFireId"></sc-select>
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-input [options]="fireCulprit" formControlName="fireCulprit"></sc-input>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-select [options]="rank" formControlName="rankId"></sc-select>
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-select [options]="causeFire" formControlName="causeFireId"></sc-select>
      </div>
    </div>
  </div>
</div>
