import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { CLICK_MAKER_SHAPE_SVG, CLICK_MARKER, NEW_CLICK_MARKER, SEARCH_MARKER } from '@bg-front/core/models/constants';
import { IMapLayer, ISubstrateDto } from '@bg-front/core/models/interfaces';
import { LayersDataService, SubstratesQuery } from '@bg-front/core/services';
import { IMapBaseBaseUrlsOptions } from '@bg-front/map/models/interfaces';
import { IScButtonOptions, IScCheckboxOptions } from '@smart-city/core/common';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import {
  IMapBaseEvent,
  IMapBaseInitOptions,
  IMapViewObjects,
  IMapViewObjectsMarkerData,
  IMapViewObjectsRequest,
  MapBaseCoordinatesType,
  MapBaseModel,
  MapBaseService,
} from '@smart-city/maps/sc';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { IAdminMunicipalSchemaDto, IAnyObject, IMapObjectDto } from 'smart-city-types';
/** Диалоговое окно с карторой для новых компонент */
@Component({
  templateUrl: './map-dialog-v2.component.html',
  styleUrls: ['./map-dialog-v2.component.scss'],
})
export class MapDialogV2Component extends BaseComponent implements OnInit {
  /** Координаты */
  @Input() coordinates: Coordinates;
  /** Приближение */
  @Input() zoom: number;
  /** Отключено ли управление */
  @Input() disabled: boolean = false;
  /** Показывать окно подтверждения при отмене */
  @Input() showConfirmOnCancel = false;
  /** Стили карты */
  @Input() mapStyles: IAnyObject = {
    width: '552px',
    height: '363px',
  };
  /** Настройки для генерации события на карте */
  public request: IMapViewObjectsRequest;
  /** Настройки для карты */
  public mapViewObjectsOptions: IMapBaseInitOptions;
  /** Модель карты */
  public mapModel: MapBaseModel;
  /** Объекты которые отражаются на карте по слоям */
  public mapObjects: IMapViewObjects;
  /** Настройка маркеров для карты */
  public markersOptions: IMapViewObjectsMarkerData[];
  /** Имя сушности слоя Важные объекты */
  public significantObjectsLayer: string = 'SignificantObjectsLayer';
  /** данные слоя Важные объекты */
  public significantObjects: IMapLayer;
  /** Опции для кнопки отмена */
  public cancelOptions: IScButtonOptions = {
    title: 'Отменить',
  };
  /** Опции для кнопки сохранить */
  public saveOptions: IScButtonOptions = {
    color: 'primary',
    title: 'Сохранить',
  };
  /** Опции чекбокса Скопировать в адрес КСиП */
  public copyAddressOptions: IScCheckboxOptions = {
    title: 'Скопировать в адрес КСиП',
  };
  /** Флаг - копировать координаты в адрес КСиП */
  public copyAddressToKsip: boolean;

  /** Координаты маркера */
  private currentCoordinates;

  constructor(
    private readonly mapService: MapBaseService,
    private readonly settings: Settings2Service,
    private readonly gisService: LayersDataService,
    private readonly modalRef: NzModalRef,
    private readonly modalService: NzModalService,
    private readonly substratesQuery: SubstratesQuery,
  ) {
    super();
  }

  /** Текущий пользователь, залогиненный в системе */
  public get user() {
    return (this.settings || <any>{}).currentUser;
  }

  /** @ignore */
  public ngOnInit(): void {
    const mapId = Uuid.newUuid() as any;
    this.mapModel = new MapBaseModel(mapId, this.mapService);
    let center: MapBaseCoordinatesType = this.createDefaultMapCenter();
    if (this.coordinates && this.coordinates.isValid()) {
      center = this.coordinates.toArray();
    }

    const transformSubstratesUrl: (url: string) => string = (url: string): string => {
      return url.startsWith('/', 0) ? `//${window.location.host}${url}` : `//${url}`;
    };

    const substrates = this.substratesQuery.getAll();
    const activeSubstrate = (this.substratesQuery.getActive() as ISubstrateDto) || substrates?.[0];

    this.mapViewObjectsOptions = {
      center,
      mapId: mapId,
      url: substrates?.length > 1 ? undefined : transformSubstratesUrl(activeSubstrate.link),
      urls:
        substrates?.length > 1
          ? substrates.map((el: ISubstrateDto): IMapBaseBaseUrlsOptions => {
              return <IMapBaseBaseUrlsOptions>{
                url: transformSubstratesUrl(el.link),
                name: el.name,
                attribution: el.attribution,
                selected: activeSubstrate ? el.id === activeSubstrate.id : el.default,
              };
            })
          : undefined,
      zoom: this.zoom ?? 16,
      maxZoom: 20,
      zoomControl: true,
      mapStyle: { width: '100%', height: '100%' },
    };
    this.mapViewObjectsOptions['attribution'] = substrates?.length > 1 ? undefined : activeSubstrate.attribution;

    this.createParamsForMapWithoutLayers();

    /** Получаю слои */
    this.gisService
      .getLayers()
      .pipe(
        catchError((err: Error) => this.catchErrorFn<IMapLayer[]>(err, 'Ошибка при загрузке слоёв')),
        mergeMap((layers: IMapLayer[]) => {
          if (layers && layers.length) {
            this.significantObjects = layers.filter(
              (layer: IMapLayer) => layer.claim === this.significantObjectsLayer,
            )[0];
            if (this.significantObjects?.entityFilters) {
              return this.gisService.getLayerData(this.significantObjects?.entityFilters, {
                paNumber: 0,
                paSize: 50,
              });
            }
          }
          return of([]);
        }),
        mergeMap((result) => {
          if (!result.length) {
            return of([]);
          }
          return of(result);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((items: IMapObjectDto[]) => {
        const options = [];
        let size = 17;
        let color = '#ffffff';
        if (this.significantObjects?.nameOnMap === 'Инциденты' || this.significantObjects?.nameOnMap === 'Поручения') {
          color = '#FB4B53';
          size = 26;
        }

        /** Формирую настройку для маркеров */
        options.push(<IMapViewObjectsMarkerData>{
          name: this.significantObjects?.nameOnMap,
          iconOptions: {
            iconSVG: this.significantObjects?.icon,
            iconSize: size,
            iconColor: color,
            shapeType: ((this.significantObjects?.markerShape || {}) as IDictionaryInfo)?.name,
            shapeColor: this.significantObjects?.markerColor,
            shapeSize: 30,
          },
        });
        options.push(CLICK_MARKER);
        options.push(NEW_CLICK_MARKER);
        options.push(SEARCH_MARKER);
        this.markersOptions = options;
        const objects = <IMapViewObjects>{
          [this.significantObjects?.nameOnMap]: {
            layerOperation: 'add',
            isCluster: true,
            objects: {},
          },
        };

        /** Проверка на наличие координат маркера */
        if (this.coordinates.isValid()) {
          objects[this.significantObjects?.nameOnMap].objects['clickMaker'] = {
            coordinates: this.coordinates.toArray(),
            nameIcon: 'newClickMarker',
            objectOperation: 'add',
          };
        }

        if ((items || []).length) {
          items.forEach((item: IMapObjectDto) => {
            objects[this.significantObjects?.nameOnMap].objects[item.id] = {
              coordinates: item.coordinates,
              nameIcon: this.significantObjects?.name,
              objectOperation: 'add',
              tooltip: {
                direction: 'bottom',
                text: item.name,
              },
            };
          });
        }
        this.mapObjects = objects;
      });

    if (!this.disabled) {
      /** Обработка событий карты */
      this.mapModel
        .getObservableMapEvents()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((event: IMapBaseEvent) => {
          switch (event.typeEvent) {
            case 'mapClick': {
              /** При клике на карту сбрасываю установленный маркер в зависимости от условия прихода слоев*/
              if (!this.significantObjects && this.mapObjects['viewObjects']) {
                this.mapObjects['viewObjects'].objects['clickMaker'] = {
                  coordinates: this.coordinates.toArray(),
                  nameIcon: 'newClickMarker',
                  objectOperation: 'remove',
                };
              }
              if (this.significantObjects && this.mapObjects[this.significantObjects.nameOnMap]) {
                this.mapObjects[this.significantObjects.nameOnMap].objects['clickMaker'] = {
                  coordinates: this.coordinates.toArray(),
                  nameIcon: 'newClickMarker',
                  objectOperation: 'remove',
                };
              }

              this.mapModel.removeLayer('clickMarker');
              this.currentCoordinates = event.coordinates
              this.request = this.createNewEvent(event.coordinates);
              this.mapObjects = { ...this.mapObjects };
              break;
            }
            case 'mapReady': {
              // TODO дублирование зоны поражения на мини карту при расчете доп. параметров рад. загр.
              // TODO при нажатии на кнопку "Задать координаты".
              // TODO вынести эту логику из мини карты.
              // if (this.data['forecastingResults']) {
              //   // Запоминаем текущую модель карты для Прогнозирования
              //   const currentMapModel = this.forecastingService.getMapModel();
              //   // Устанавливаем модель мини карты для задания координат
              //   this.forecastingService.setMapModel(event['mapObject']);
              //
              //   const forecastingResults = this.data['forecastingResults'] as IForecastingResultDto;
              //   const params = forecastingResults.params.params as IRadiationForecastInputDto;
              //   const inputType = this.settings.getDictionaryById(params.inputTypeId)?.sysname;
              //   switch (inputType) {
              //     case 'manually':
              //       this.forecastingService.displayRadioactiveResults(forecastingResults);
              //       break;
              //     case 'shelterCalculatingDoses':
              //       break;
              //     case 'propagationTimeDoses':
              //       break;
              //     case 'iodineProphylaxisCalculationDoses':
              //       break;
              //   }
              //   // Восстанавливаем прежнюю модель карты для Прогнозирования
              //   this.forecastingService.setMapModel(currentMapModel);
              // }
              break;
            }
            default:
              break;
          }
        });
    }
  }

  /** Изменение значения чекбокса - копировать координаты в адрес КСиП */
  public copyAddressChange($event: IAnyObject): void {
    this.copyAddressToKsip = $event.checked;
  }

  /** Обработчик клика по объекту на карте
   * @param $event
   * @param mapId
   * @return
   */
  public onMapViewObjectsEvent($event: IAnyObject, mapId: string): void {
    if (!this.disabled && ($event.mapId || mapId) === 'eventAddressDialog') {
      this.currentCoordinates = $event.coordinates
      switch ($event.typeEvent) {
        case 'mapClick':
          /** При клике на карту создаю новый маркер */
          if (this.significantObjects) {
            this.addCirclesToObject($event.coordinates, 'Важные объекты', 'newIncident');
          } else {
            this.request = this.createNewEvent($event.coordinates);
          }
          break;
        case 'click':
          if ((this.request && this.request.typeRequest === 'newEvent') || !$event.coordinates) {
            this.addCirclesToObject($event.coordinates, 'viewObject', $event.objectId);
            return;
          }
          if ($event.layerId === 'Инциденты') {
            this.addCirclesToObject($event.coordinates, $event.layerId, $event.objectId);
          } else {
            this.request = <IMapViewObjectsRequest>{ typeRequest: 'removeCirclesFromObject' };
          }
          break;
      }
    }
  }

  /** Обработчик клика на кнопку Сохранить
   */
  public onSaveHandler(): void {
    this.modalRef.destroy({ coordinates: this.currentCoordinates, copyAddressToKsip: this.copyAddressToKsip });
  }

  /** Обработчик клика на кнопку Отмена
   */
  public onCloseHandler(): void {
    if (this.showConfirmOnCancel) {
      this.modalService.confirm({
        nzTitle: 'Несохраненные изменения будут потеряны. Вы уверенны, что хотите закрыть форму?',
        nzOkText: 'Закрыть',
        nzOkType: 'primary',
        nzOkDanger: true,
        nzMaskClosable: true,
        nzOnOk: () => this.modalRef.destroy(),
        nzCancelText: 'Отмена',
      });
      return;
    }

    this.modalRef.destroy();
  }

  /**
   * Создание координат центра карты
   * @return
   */
  public createDefaultMapCenter(): MapBaseCoordinatesType {
    const mo = this.settings.allMo.find(
      (mo: IAdminMunicipalSchemaDto) => mo.id === this.settings.currentUser.organizationId.mo,
    );
    if (mo && mo.coordinates) {
      const elm = mo.coordinates.split(', ');
      return [Number.parseFloat(elm[0]), Number.parseFloat(elm[1])];
    }
    const elm = this.settings.getConfig().defaultCoordinates.split(', ');
    return [Number.parseFloat(elm[0]), Number.parseFloat(elm[1])];
  }

  /**
   * Метод который создает новое событие на карте
   * @param coordinates - координаты
   * @return
   */
  private createNewEvent(coordinates: MapBaseCoordinatesType): IMapViewObjectsRequest {
    return {
      coordinates,
      typeRequest: 'newEvent',
      layerId: 'viewObjects',
      objectId: 'newIncident',
    };
  }

  /** Метод добавления маркера с кругами
   * @param coordinates - координаты
   * @param layerId - id слоя
   * @param objectId - id объекта
   */
  private addCirclesToObject(coordinates: number[], layerId: string, objectId: string): void {
    this.request = <IMapViewObjectsRequest>{
      coordinates,
      layerId,
      objectId,
      typeRequest: 'addCirclesToObject',
    };
  }

  /** Создаю параметры для карты если слои не пришли */
  private createParamsForMapWithoutLayers() {
    const options = [];
    const objects = <IMapViewObjects>{
      viewObjects: {
        layerOperation: 'add',
        isCluster: true,
        objects: {},
      },
    };
    options.push(<IMapViewObjectsMarkerData>{
      name: 'viewObjects',
      iconOptions: {
        iconSVG: CLICK_MAKER_SHAPE_SVG,
        iconSize: 26,
        iconColor: '#000000',
        shapeType: {},
        shapeColor: '#000000',
        shapeSize: 30,
      },
    });
    options.push(CLICK_MARKER);
    options.push(NEW_CLICK_MARKER);
    options.push(SEARCH_MARKER);
    this.markersOptions = options;
    this.mapObjects = objects;
  }
}
