import { Pipe, PipeTransform } from '@angular/core';
import { IOrganization } from '@smart-city/calls/call-manager';

/**
 * Пайп, позволяющий показывать в списке организаций только те, что удовлетворяют следующему условию —
 * введённый в строке поиска текст содержится либо в названии организации, либо является часть номер телефона.
 */
@Pipe({
  name: 'organizationsFilter',
})
export class OrganizationsFilterPipe implements PipeTransform {
  public transform(organizations: IOrganization[], searchTerm: string = ''): IOrganization[] {
    if (!searchTerm.trim()) {
      return organizations;
    }
    return organizations.filter((organization: IOrganization) => {
      return `${organization.name || ''} ${organization.phone || ''}`.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }
}
