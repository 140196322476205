import { Directive, OnDestroy } from '@angular/core';
import { NotificationService } from '@smart-city/core/services';
import { ScConsole } from '@smart-city/core/utils';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, of, Subject } from 'rxjs';

import { AppInjector } from '../../models/classes/app-injector';
import { CommonService } from '../../services/common/common.service';

/**
 * Базовый класс для всех компонент
 * Реализует отписку от событий и базовый ngOnDestroy
 */
@Directive()
export class BaseComponent implements OnDestroy {
  /**
   * @deprecated
   * Использовать .pipe(untilDestroyed(this))
   */
  public ngUnsubscribe: Subject<any> = new Subject<any>();
  /**
   * Сервис нотификаций
   */
  public noteService: NotificationService;

  /**
   * Сервис-контейнер функций
   */
  public utilsService: CommonService;

  constructor(public notificationService?: NzNotificationService) {
    const injector = AppInjector.getInjector();
    this.noteService = injector.get(NotificationService);
    this.utilsService = injector.get(CommonService);
  }

  /**
   * Функция обработки ошибок в запросах к бэку
   * Реализует стратегию "Перехватить и заменить"
   * @param err Ошибка
   * @param defaultMessage сообщение, которое будет показано
   * @param defaultValue по умолчанию undefined
   */
  protected catchErrorFn<T = undefined>(
    err: Error,
    defaultMessage: string = 'Ошибка при запросе',
    defaultValue: T = undefined,
  ): Observable<T> {
    if (this.notificationService) {
      this.notificationService.error('Ошибка при выполнении запроса', defaultMessage);
    } else {
      this.noteService.pushError(defaultMessage);
    }
    ScConsole.error([err.message]);
    return of(defaultValue);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
