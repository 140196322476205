import { map } from 'rxjs/operators';
import { IWeatherDataDto } from '../../models/interfaces';

import { Injectable } from '@angular/core';
import { RestService } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { BaseCrudService } from '@bg-front/core/services';
import { IAbstractServiceData } from 'smart-city-types';


/**
 * Сервис для работы с метеоданными
 */
@Injectable({
  providedIn: 'root',
})
export class WeatherDataService extends BaseCrudService<IWeatherDataDto> {
  /** @ignore */
  constructor(rest: RestService) {
    super({
      serviceName: 'Admin',
      entityName: 'WeatherData',
    }, rest)
  }

  /** Запрос актуальных данных погоды */
  public getActualWeather(timestamp: number): Observable<IWeatherDataDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'WeatherData',
          query: {
            actualOn: {
              $lte: timestamp,
            },
          },
          limit: 1,
          sort: { field: 'actualOn', direction: 'desc' },
        },
      }, 'http')
      .pipe(map((result: IAbstractServiceData) => <IWeatherDataDto>result.data.items[0]));
  }
}
