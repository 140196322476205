import { Injectable, OnDestroy } from '@angular/core';

import { IPage, IPages } from '@smart-city/core/interfaces';
import { Uuid } from '@smart-city/core/utils';
import { IAnyObject } from 'smart-city-types';
import { Settings2Service } from '@smart-city/core/services';
/**
 * Модифицированный сервис Меню
 *
 * Меняет стандартное поведение, на кастомное
 */
@Injectable()
export class BgMenuService implements OnDestroy {

  public redirectUrl: string = null;
  public user: any;
  public token: any;
  public pages: any[] = [];
  /** Меню страниц для отдельного пользователя */
  public tree: IPages;
  /**
   * Ссылка на таймер обновления токена
   */
  private updateTokenTimerId: any = undefined;

  /** @ignore */
  constructor(private settings2: Settings2Service) {}

  public getMenu(): IPages {
    return this.settings2.getPagesMenu();
  }

  public findPath(menu: IAnyObject[], path: string): IAnyObject {
    let found = null;
    for (let i = 0; i < menu.length; i += 1) {

      if (menu[i].menuItems) {

        found = this.findPath(menu[i].menuItems, path);
      }

      if (found) {
        break;
      }

      found = menu.find((page) => page.url === path);

      if (found) {
        break;
      }
    }

    return found ?? path;
  }

  public findPath2(menu: IAnyObject[], pathArray: string[], level: number): IAnyObject {
    let found = null;
    for (let i = 0; i < menu.length; i += 1) {

      if (menu[i].menuItems) {

        found = this.findPath2(menu[i].menuItems, pathArray, level + 1);
      }

      if (found) {
        break;
      }

      found = menu.find((page) => page.alias === pathArray[level]);

      if (found) {
        break;
      }
    }

    return found;
  }

  /**
   * Ищет рекурсивно элемент меню по условию
   * @param menu Меню страниц
   * @param condition Условие в виде функции
   */
  public menuFind(menu: IPage[] = [], condition: (item: IPage) => boolean): any {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of menu) {
      if (condition(item)) return item;
      if (item.menuItems) {
        const child = this.menuFind(item.menuItems, condition);
        if (child) return child;
      }
    }
    return null;
  }

  /**
   * Проверка доступа для пользователя
   * @param path URL страницы
   */
  public canIGo(path: string): IAnyObject {
    let path2 = path;
    /** Убираем из URL данные об outlets и queryParams. Необходимо для правильного сравнения */

    path2 = path2.split(/[?#]/)[0].replace(/\/(\(.*\))$/, '');
    const menu = this.getMenu();
    const urlArray = path2.split('/');
    const root = urlArray[1];
    // сравнение без лидирующего слэша

    const menuRoot = this.menuFind(menu, (item) => item.url.replace(/^\//, '') === `${root}`);
    // Вырезаем uuid в конце url

    if (Uuid.isValid(urlArray[urlArray.length - 1])) {
      path2 = path2.replace(/\/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/, '');
    }

    if (!root || menuRoot.url === path2) {
      return menuRoot || { id: '' };
    }
    // return this.findPath(menuRoot.menuItems || [], path2);
    return this.findPath2(menuRoot.menuItems || [], urlArray, 2);
  }

  /**
   * Возвращает корневой пункт меню
   */
  public getRootPage(): IAnyObject {

    return this.settings2.getPagesFlatMenu().find((page) => !page.parentid);
  }

  /** @ignore */
  public ngOnDestroy(): void {
    if (this.updateTokenTimerId) {
      clearInterval(this.updateTokenTimerId);
    }
  }
}
