import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';

import { IAbstractServiceData, IAnyObject } from 'smart-city-types';
import { ILimit, ISort, RestService } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';

import { IResponseWithTotal } from '@bg-front/core/models/interfaces';
import { convertedBooleanValueToYesNo } from '@bg-front/core/models/helpers';
import { IDistrict } from '../models/interfaces';

@Injectable({ providedIn: 'root' })
export class DistrictsService {
  /** @ignore */
  constructor(private readonly rest: RestService) {
  }

  /** Получение списка элементов для таблицы */
  public getDistrictsForTable(
    query: IAnyObject,
    pageIndex: number,
    pageSize: number,
    sort?: ISort,
  ): Observable<IResponseWithTotal> {
    let limit: ILimit = undefined;
    if (pageIndex || pageSize) {
      limit = {
        paNumber: pageIndex ?? 1,
        paSize: pageSize ?? 15,
      };
    }
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query,
          name: 'Districts',
          attributes: [
            'id',
            'name',
            'OKTMO',
            'municipalId.name',
            'coordinates',
            'zoom',
            'active',
          ],
        },
        data: { isNeedTotal: true, limit, sort },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return {
            items: (response.data.items || [])
              .map((item: IAnyObject) => ({
                ...item,
                municipalName: item['municipalId.name'],
                active: convertedBooleanValueToYesNo(item, 'active'),
              })),
            totalCount: response.data.totalCount ?? 0,
          };
        }),
        catchError(() => of({ totalCount: 0, items: [] })),
      );
  }

  /**
   * Получение информации о районе
   * @param id ID района
   */
  public getDistrictById(id: string | string[]): Observable<IDistrict | IDistrict[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Districts',
          query: { id: Array.isArray(id) ? { $in: id } : id },
        },
      })
      .pipe(
        pluck('data', 'items'),
        map((items: IDistrict[]) => Array.isArray(id) ? items : items[0]),
      );
  }

  /**
   * Сохранение района
   * @param data Информация о районе
   */
  public saveDistrict(data: IDistrict): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      data,
      action: 'upsert',
      service: { name: 'Admin' },
      entity: {
        name: 'Districts',
        query: { id: data.id || Uuid.newUuid() },
      },
    });
  }

  /** Получение всех районов */
  public getAllDistricts(): Observable<IDistrict[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Districts',
        },
      })
      .pipe(map((result: IAbstractServiceData) => <IDistrict[]>result.data.items));
  }

  /**
   * Удаление записи района
   * @param ids ID районов
   */
  public deleteDistricts(ids: string | string[]): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: 'Admin' },
      entity: { name: 'Districts', query: { id: { $in: Array.isArray(ids) ? ids : [ids] } } },
    });
  }

  /**
   * Получение координат нескольких районов
   * @param districtIds ID районов
   */
  public getMultipleDistrictsCoordinates(districtIds: string[]): Observable<IDistrict[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Districts',
          query: { id: { $in: districtIds } },
          attributes: ['polygonId'],
        },
      })
      .pipe(map((result: IAbstractServiceData) => <IDistrict[]>result.data.items));
  }

  /**
   * Получение районов, относящихся к конкретным МО
   * @param municipalIds ID МО
   */
  public getDistrictsForMunicipality(municipalIds: string[]): Observable<IDistrict[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Districts',
          query: { municipalId: { $in: municipalIds } },
        },
      })
      .pipe(map((result: IAbstractServiceData) => <IDistrict[]>result.data.items));
  }

  /** Получение муниципальных образований для селекта */
  public getMunicipalsForSelect(): Observable<{ id: string; name: string }[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: { name: 'Municipal', attributes: ['id', 'name'], },
    }).pipe(
      pluck('data', 'items'),
      catchError(() => of([])),
    );
  }
}
