<div class="significant-object-container">
  <div
    class="significant-object"
    [ngClass]="{ 'significant-object__active': isActive }"
    (click)="onClickSignificantObject($event)"
  >
    <div class="significant-object__icon">
      <span class="sc-icon-flag"></span>
    </div>
    <div
      class="significant-object__marker"
      *ngIf="isActive"
    ></div>
    <div class="significant-object__header" [attr.tooltip]="significantObject.name" [attr.flow]="'down'">
      <div class="significant-object__name">
        <div class="significant-object__state">
          {{ significantObjectState }}
        </div>
        {{ significantObject.shortName }}
      </div>
    </div>
    <p
      *ngIf="significantObject.address?.fullText || coordinates"
      class="significant-object__address"
      [ngClass]="{ 'significant-object__address--link': !!coordinates }"
      (click)="onClickAddress($event)"
    >
      {{ significantObject.address?.fullText || coordinates}}
    </p>
  </div>
</div>
