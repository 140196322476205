import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { BgMapService, CommunicationService } from '@bg-front/core/services';
import { ISignificantObjectData } from '@bg-front/significant-objects/models';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { IElementButton, INwHeaderBarOptions } from '@smart-city/core/common';
import { IBaseDictionaryData } from '@smart-city/core/interfaces';
import { AccessService, IAccessAction, ScNavService, SfsService } from '@smart-city/core/services';
import { formatPhone, formatPhoneDb } from '@smart-city/core/utils';
import { of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IEmergencyDto, IFileInfoDto, IUploadMetadataDTO } from 'smart-city-types';

import { CallService } from '../../../call/services';
import { IForecastingStartFormOptions } from '../../models/interfaces';
import { EmergencyService } from '../../services';

/**
 * Компонента отображающая данные о важном объекте
 */
@Component({
  selector: 'bg-significant-object-info',
  templateUrl: './significant-object-info.component.html',
  styleUrls: ['./significant-object-info.component.scss'],
})
export class SignificantObjectInfoComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions;

  /** Данные важном объекте */
  public significantObject: ISignificantObjectData = undefined;

  /** Данные важном объекте */
  public emergencyId: string = undefined;

  /** Координаты */
  public coordinates: string = undefined;

  /** Список связанных происшествий */
  public emergencies: IEmergencyDto[] = undefined;

  /** Список загруженных документов */
  public documents: IUploadMetadataDTO[] = [];

  /** Флаг указывающий что открыт аккордеон с документами */
  public showDocuments: boolean = false;

  /** Доступность блока документы */
  public canSeeDocuments: boolean = false;

  /** Флаг для отображения кнопки начала расчета */
  public isForecasting: boolean = false;
  /** Параметры формы запуска расчета */
  public forecastingOptions: IForecastingStartFormOptions;

  /** Доступность запуска Прогнозирования */
  public canForecastingRun: IAccessAction = { visible: false, enabled: false, name: 'CanForecastingRun' };

  /** Признак что при создании компонента нужно показать объект на карте */
  private showOnMap: boolean = false;

  /** Геттер типа важного объекта */
  public get significantObjectType(): string {
    return (<IBaseDictionaryData>this.significantObject.type)?.name;
  }

  /** Геттер состояния важного объекта */
  public get significantObjectState(): string {
    return (<IBaseDictionaryData>this.significantObject.state)?.name;
  }

  /** Геттер категории важного объекта */
  public get significantObjectCategory(): string {
    return (<{ id: string; name: string }>this.significantObject.category)?.name;
  }

  /** @ignore */
  constructor(
    private readonly gisService: BgMapService,
    private readonly significantObjectService: SignificantObjectsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly scNavService: ScNavService,
    private readonly emergencyService: EmergencyService,
    private readonly accessService: AccessService,
    private readonly sfs: SfsService,
    private readonly callService: CallService,
    private readonly communicationService: CommunicationService,
  ) {
    super();
  }

  /** ID важного объекта */
  @Input()
  public set significantObjectId(val: string) {
    if (val) {
      this.significantObject = undefined;
      this.significantObjectService
        .getSignificantObjects({ id: val })
        .pipe(
          switchMap((significant: ISignificantObjectData[]) => {
            this.significantObject = significant[0];
            if (new Coordinates(this.significantObject.coordinates).isValid()) {
              this.coordinates = this.significantObject.coordinates;
            } else if (this.significantObject.address?.latitude && this.significantObject.address?.longitude) {
              this.coordinates = new Coordinates(
                this.significantObject.address.latitude,
                this.significantObject.address.longitude,
              ).toString();
            }
            if (this.coordinates) {
              return this.emergencyService.getIncidents(
                {
                  'docType.sysname': 'incident',
                  $or: [{ 'lifeCycleStepId.status.sysname': 'new' }, { 'lifeCycleStepId.status.sysname': 'inWork' }],
                  point: {
                    $radius: {
                      $lat: new Coordinates(this.coordinates).lat,
                      $lon: new Coordinates(this.coordinates).lon,
                      $meters: 50,
                    },
                  },
                },
                null,
                {
                  field: 'timeCreate',
                  direction: 'desc',
                },
              );
            }
            return of(null);
          }),
          switchMap((emergencies: IAbstractServiceData) => {
            this.isForecasting = this.significantObject.projectedRiskIds?.length > 0;
            this.emergencies = emergencies?.data?.items;
            // Запрос документов прикрепленных к важному объекту
            if (this.significantObject.documents?.length) {
              return this.sfs
                .getData({
                  uuid: {
                    $in: this.significantObject.documents?.map(({ file }: IFileInfoDto) => file) as string[],
                  },
                })
                .pipe(
                  map(({ items }: { items: IAnyObject[]; totalCount: number }): IAnyObject[] =>
                    // Сортировка полученного списка по названию, при совпадении по дате прикрепления
                    items.sort(
                      (file1: IAnyObject, file2: IAnyObject) =>
                        file1.fileName.localeCompare(file2.fileName) || file1.createdAt - file2.createdAt,
                    ),
                  ),
                );
            } else {
              return of([]);
            }
          }),
          switchMap((files: IUploadMetadataDTO[]) => {
            this.documents = files;
            return this.route.queryParams;
          }),
          takeUntil(this.ngUnsubscribe),
          catchError((err: Error) =>
            this.catchErrorFn<ISignificantObjectData[]>(err, 'Ошибка при загрузке данных о важном объекте'),
          ),
        )
        .subscribe((params: Params) => {
          if (params?.openForecastingForm === 'true') {
            this.emergencyId = params.emergencyId;
            this.runForecasting();
          }
        });
    }
  }

  /**
   * @ignore
   */
  public ngOnInit(): void {
    this.canForecastingRun = this.accessService.accessMap[this.canForecastingRun.name];
    this.canSeeDocuments = this.accessService.accessMap['SignificantObjectDocuments']?.visible;

    this.headerOptions = {
      margin: 'collapse',
      title: ' ',
      buttons: [
        {
          type: 'button',
          options: {
            name: 'burger',
            icon: 'menu',
            callback: () => {
              this.scNavService.openMenu();
              return of({ actionStream: 'donothing' });
            },
          },
        },
        {
          type: 'button',
          options: {
            name: 'close',
            icon: 'close',
            position: 'suffix',
            callback: () => {
              this.gisService.setClickMarker('Важные объекты', this.significantObject.id);
              this.router.navigate(
                [
                  {
                    outlets: { leftPopup: null },
                  },
                ],
                {
                  relativeTo: this.route.parent,
                  queryParamsHandling: 'merge',
                },
              );
              // this.route.parent.data.pipe(
              //   switchMap((data: Data) => {
              //     return this.registryPanelService.getRegistries(['SignificantObjectsRegistry']);
              //   }),
              //   takeUntil(this.ngUnsubscribe),
              // ).subscribe((registries: IRegistryPanelDto[]) => {
              //   this.router.navigate(
              //     [{
              //       outlets: {
              //         leftSidebar: ['significantObjectsRegistry', registries[0].id.toString()],
              //         leftPopup: null,
              //       },
              //     }],
              //     {
              //       relativeTo: this.route.parent,
              //       queryParamsHandling: 'merge',
              //     },
              //   );
              // });
              return of();
            },
          },
        },
      ],
    };

    /** Подписываемся на параметры */
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params: Params) => {
      if (params['id']) {
        if (params['showOnMap']) this.showOnMap = true;
        return (this.significantObjectId = params['id']);
      }
    });

    this.gisService.mapLayersReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (this.showOnMap) {
        this.gisService.setPositionMapOnCoordinates(this.coordinates);
        this.gisService.setClickMarker('Важные объекты', this.significantObject.id, new Coordinates(this.coordinates));
      }
    });
  }

  /**
   * Обработчик, срабатываемый при нажатии на номер телефона в истории вызовов.
   * @param phone номер телефона
   */
  public onClickPhone(phone: string): void {
    this.callService.call(formatPhoneDb(phone));
  }

  /** Приведение номера к формату +7 */
  public formatPhoneFn(phone: string) {
    return formatPhone(phone);
  }

  /**
   * Обработка событий из заголовка
   * @param $event событие
   */
  public clickHeaderButton($event: IElementButton): void {
    ($event.options.callback as any)();
  }

  /**
   * Метод перехода/центрирования по координатам
   */
  public selectCoordinates() {
    this.gisService.setPositionMapOnCoordinates(this.coordinates);
    this.gisService.setClickMarker('Важные объекты', this.significantObject.id, new Coordinates(this.coordinates));
  }

  /**
   * Запуск прогнозирования
   */
  runForecasting() {
    // this.canForecastingRun?.enabled = true;
    if (true) {
      // устанавливаем входные параметры
      this.forecastingOptions = {
        object: { ...this.significantObject },
        coordinates: this.significantObject.coordinates,
        route: this.route,
        emergencyId: this.emergencyId,
      };
      this.resetQueryParams();
      // открываем нужную форму в зависимости от типа ВО
      this.communicationService.runForecasting({
        object: { ...this.significantObject },
        coordinates: this.significantObject.coordinates,
        route: this.route,
        emergencyId: this.emergencyId,
      });
    }
  }

  /** Убирает queryParams из URL */
  private resetQueryParams() {
    const urlTree = this.router.createUrlTree([], {
      queryParams: { openForecastingForm: null, emergencyId: null },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
    this.router.navigateByUrl(urlTree);
  }

  /**
   * Скачивание документа
   * @param uuid - UUID файла в хранилище
   */
  public downloadDocument(uuid: string) {
    this.sfs.directDownload(uuid);
  }

  /**
   * Обработка открытия/закрытия списка документов
   */
  public toggleDocuments() {
    this.showDocuments = !this.showDocuments;
  }
}
