import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Settings2Service } from '@smart-city/core/services';

import {
  IGarServiceFindRequest,
  IGarServiceFindResponse,
  IGarServiceUserChoiceRequest,
  IGarServiceUserChoiceResponse,
} from '../models/interfaces';
import { GarFindFlatResponseElement, GarServiceRequest, GarServiceResponse } from '../models/types';

/** Сервис для работы с ГАР */
@Injectable()
export class GarService {
  /** Адрес сервиса ГАР */
  public garUrl = '';

  /** Регион поиска адресов */
  public region: string | undefined;

  /** @ignore */
  constructor(
    private readonly http: HttpClient,
    private readonly settings: Settings2Service,
  ) {
    this.garUrl = this.settings.getConfig().garUrl || '/gar';
    this.region = this.settings.getConfig().region || '03';
  }

  /**
   * Метод запроса данных в ГАР
   * @param request отправляемый запрос в ГАР
   */
  private sendRequest<T extends GarServiceResponse = GarServiceResponse>(request: GarServiceRequest): Observable<T> {
    return this.http.post<T>(`${this.getUrl()}${request.command}`, request);
  }

  /**
   * Получение координат выбранного адреса
   * @param houseGUID Идентификатор выбранного здания
   * @param aoguid Поиск по aoguid, передаваемому в поле houseGUID
   */
  public getCoordinates(
    houseGUID: string | undefined,
    aoguid?: boolean,
  ): Observable<{ latitude: number; longitude: number } | undefined> {
    return this.sendRequest<IGarServiceUserChoiceResponse>({
      command: 'userChoice',
      regions: [this.region],
      [aoguid ? 'aoguid' : 'houseGUID']: [houseGUID],
    } as IGarServiceUserChoiceRequest)
      .pipe(
        map((response: IGarServiceUserChoiceResponse) => response.coordinate),
      );
  }

  /**
   * Получение адресов по строке поиска.
   * @param term Строка поиска
   */
  public find(term: string): Observable<GarFindFlatResponseElement[]> {
    return this.sendRequest<IGarServiceFindResponse>({
      command: 'find',
      limit: 10,
      searchstring: term,
      regions: [this.region],
      mun: false,
      json: true,
    } as IGarServiceFindRequest)
      .pipe(map((result: IGarServiceFindResponse) => (result?.flatElements || [])));
  }

  /**
   * Определение актуального http-пути до сервиса gar.
   * Если в настройке `garUrl` указан локейшн вида /gar, то он добавляется к текущему хосту.
   * В других случаях считаем, что в настройке полный домен, без протокола.
   * Протокол берём с текущего хоста.
   */
  private getUrl(): string {
    const garUrl = this.garUrl;
    let resultUrl: string;
    if (garUrl.startsWith('/')) {
      // Локейшн
      resultUrl = `${
        window.location.protocol
      }//${
        window.location.hostname
      }${
        window.location.port ? `:${window.location.port}` : ''
      }${
        garUrl
      }`;
    } else {
      // Домен
      resultUrl = `${window.location.protocol}//${garUrl}`;
    }

    if (!resultUrl.endsWith('/')) {
      resultUrl += '/';
    }

    return resultUrl + 'api/';
  }
}