import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { VideoDevicesListsState } from '../../../models/states';

/** Хранилище Списков видеокамер */
@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'video-devices-lists' })
export class VideoDevicesListsStore extends EntityStore<VideoDevicesListsState> {
  constructor() {
    super([]);
  }
}
