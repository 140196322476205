import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AddressModule, BgCoreModule } from '@bg-front/core';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { ScComponentsModule } from '@smart-city/core/common';

import {
  SignificantObjectsDashboardComponent,
  SignificantObjectsEditFormComponent,
  SignificantObjectsFilterComponent,
  SignificantObjectsViewFormComponent,
  StoredChemicalComponent,
} from './components';
import { SignificantObjectsService } from './services';
import { SignificantObjectsRoutingModule } from './significant-objects-dictionary-routing.module';

import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NgxMaskModule } from 'ngx-mask';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzViewControlModule } from '@bg-front/nz-view-control';
import { ExportService } from '@bg-front/core/services';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';

@NgModule({
  imports: [
    CommonModule,
    ScComponentsModule,
    SignificantObjectsRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ErrorTailorModule,
    BgCoreModule,
    NzBadgeModule,
    NzButtonModule,
    NzDrawerModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzLayoutModule,
    NzModalModule,
    NzPageHeaderModule,
    NzSelectModule,
    NzTableModule,
    NzToolTipModule,
    NzPopconfirmModule,
    NgxMaskModule,
    NzAutocompleteModule,
    NzTabsModule,
    NzSpinModule,
    NzPaginationModule,
    NzCollapseModule,
    NzViewControlModule,
    AddressModule,
    NzInputNumberModule,
  ],
  declarations: [
    SignificantObjectsDashboardComponent,
    SignificantObjectsEditFormComponent,
    SignificantObjectsFilterComponent,
    StoredChemicalComponent,
    SignificantObjectsViewFormComponent,
  ],
  providers: [SignificantObjectsService, ExportService],
})
export class SignificantObjectsDictionaryModule {}
