import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent, MapDialogComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { IForecastingResultDto, IForecastingTaskParamsDto, ISelectItem } from '@bg-front/core/models/interfaces';
import { ForecastingResultsQuery, ForecastingResultsService } from '@bg-front/core/services';
import { coordinatesValidator } from '@bg-front/core/validators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDictionaryInfo, IScSelectItem } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { IMapBaseEvent } from '@smart-city/maps/sc';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { catchError, takeUntil } from 'rxjs/operators';
import { IAnyObject, IRadiationForecastInputDto, IRadiationForecastResultsDto } from 'smart-city-types';

import { INuclearPollutionTaskDto } from '../../models';
import {
  ForecastingRadioactiveEnvironmentService,
  ForecastingRadioactiveEnvironmentVisualizationService,
} from '../../services';

@UntilDestroy()
@Component({
  selector: 'bg-radioactive-environment-extra-params-modal-form',
  templateUrl: './radioactive-environment-extra-params-modal-form.component.html',
  styleUrls: ['./radioactive-environment-extra-params-modal-form.component.scss'],
})
export class RadioactiveEnvironmentExtraParamsModalFormComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public data: IForecastingResultDto;

  @Input()
  public unitName: string;

  /** Форма расчета доп. параметров */
  public form: FormGroup;
  /** Последнее состояние формы. Хранится в сервисе ForecastingBlowoutVisualizationService */
  private previousFormState;

  /** Модель формы расчета доп. параметров */
  public model: IForecastingTaskParamsDto<IAnyObject>;

  public forecastingResult: IForecastingResultDto<IAnyObject, IRadiationForecastResultsDto>;

  private isLocationAppointment: boolean = false;

  /** Настройки тултипа */
  public tooltipText =
    'Совпадает с временем подхода радиоактивного облака к заданной точке. Можно заменить на произвольное значение';

  /** Тип укрытия */
  public shelterTypes: ISelectItem[] = this.forecastingService.shelterTypeForLocationAppointment();

  /** Этажи */
  public shelterFloors = this.settings.getDictForSelect('floorShelterFromRadiation').map(
    (el: IScSelectItem) =>
      <ISelectItem>{
        text: el.text,
        value: el.id,
      },
  );

  /** Расположение укрытия */
  public shelterLocations: ISelectItem[] = this.settings.getDictForSelect('locationShelterFromRadiation').map(
    (el: IScSelectItem) =>
      <ISelectItem>{
        text: el.text,
        value: el.id,
      },
  );

  constructor(
    public readonly settings: Settings2Service,
    private readonly forecastingService: ForecastingRadioactiveEnvironmentService,
    private readonly forecastingVisualizationService: ForecastingRadioactiveEnvironmentVisualizationService,
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly modalRef: NzModalRef,
    private readonly forecastingResultsQuery: ForecastingResultsQuery,
    private readonly forecastingResultsService: ForecastingResultsService,
  ) {
    super();
  }

  /** @ignore */
  ngOnInit(): void {
    this.forecastingResult = { ...this.forecastingResultsQuery.getActive() } as IForecastingResultDto<
      INuclearPollutionTaskDto,
      IRadiationForecastResultsDto
    >;

    const unitId = (this.data.params.params as INuclearPollutionTaskDto).unitRadiationId;
    this.unitName = this.settings.getDictionaryById(unitId)?.name;

    /**
     *  Инициализация модели формы прогнозирования последними
     *  введенными данными из предыдущего расчета
     */
    this.previousFormState = {
      ...this.forecastingResult.params,
    };

    this.initForm();
  }

  /** Инициализация формы прогнозирования */
  private initForm() {
    const params = this.forecastingResult.params.params as IAnyObject;
    this.form = this.fb.group({
      timeSinceAccident: new FormControl(params?.timeSinceAccident),
      coordinatesTarget: new FormControl(params?.coordinatesTarget, [
        Validators.maxLength(100),
        Validators.pattern(/(-?\d{1,2}[.]\d+)[,][ ]+(-?\d{1,3}[.]\d+)/),
        coordinatesValidator(),
      ]),
      doseStrengthAtPoint: new FormControl(params?.doseStrengthAtPoint),
      shelterLocationId: new FormControl(params?.shelterLocation),
      shelterTypeId: new FormControl(params?.shelterType),
      shelterFloorId: new FormControl(params?.shelterFloor),
    });

    // const localStreetId = this.settings.getDictionaryByTypeSysName('locationShelterFromRadiation').find((item) => {
    //   return item.sysname === 'localAppointmentStreet';
    // }).id;

    // this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
    //   this.getModel();
    //   this.model.countTaskId = this.forecastingResults.params.countTaskId;
    //   this.forecastingService.setForecastingRadioactiveFormState(this.forecastingResults.params.objectId, {
    //     model: { ...this.model },
    //   });
    // });

    this.form
      .get('shelterLocationId')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((uuid: string) => {
        const localStreetId = this.settings
          .getDictionaryByTypeSysName('locationShelterFromRadiation')
          .find((item: IDictionaryInfo) => {
            return item.sysname === 'localAppointmentStreet';
          }).id;
        this.shelterTypes =
          localStreetId === uuid
            ? this.settings.getDictForSelect('typeShelterFromRadiation').map(
                (el: IScSelectItem) =>
                  <ISelectItem>{
                    text: el.text,
                    value: el.id,
                  },
              )
            : this.forecastingService.shelterTypeForLocationAppointment();
      });

    this.form
      .get('shelterTypeId')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((uuid: string) => {
        const shelterTypeSysname: string = this.settings
          .getDictionaryByTypeSysName('typeShelterFromRadiation')
          .filter(({ id }) => {
            return id === uuid;
          })[0]?.sysname;
        this.shelterFloors = this.forecastingService.shelterFloorForType(shelterTypeSysname);
      });
  }

  public startForecasting() {
    if (this.form.valid) {
      this.model = { ...this.forecastingResult.params };
      this.model.objectId = this.forecastingResult.params.objectId;
      this.model.emergencyId = this.forecastingResult.params.emergencyId;
      this.model.countTaskId = this.settings.getDictionaryByTypeAndSysName(
        'radioactiveForecastingTaskTypes',
        'timeAllowedToStartWork',
      )?.id;
      this.model.params = Object.assign({}, this.forecastingResult.params.params, this.form.value);
      this.forecastingService
        .startRadioactiveForecasting(this.model)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          catchError((err: Error) => this.catchErrorFn(err, err.message)),
        )
        .subscribe((res) => {
          if (res) {
            this.model.countTaskId = this.forecastingResult.params.countTaskId;
            this.forecastingResult.params = this.model.params as IForecastingTaskParamsDto<IAnyObject>;
            this.forecastingResult.result = Object.assign(
              {},
              this.forecastingResult.result,
              res,
            ) as IRadiationForecastResultsDto;
            this.forecastingResultsService.add(this.forecastingResult);
            this.modalRef.close(this.forecastingResult);
          }
        });
    }
  }

  /**
   * Обработчки по клику на кнопку задать координаты
   * @param $event - объект события
   * @return
   */
  public onClickAppointCoordinates($event: Event) {
    const currentCoordinates = this.form.controls['coordinatesTarget'].value || '';
    const splited = currentCoordinates.split(',');
    const data = {
      coordinates: new Coordinates(),
      forecastingResults: this.forecastingResult,
    };
    if (splited[0] && splited[1]) {
      data.coordinates = new Coordinates(Number(splited[0]), Number(splited[1]));
    }
    this.openSpecifyMap(data);
  }

  /** Метод который обеспечивает открытие диалога и подписку на закрытие
   * @param data - параметры для диалога
   */
  public openSpecifyMap(data: { coordinates?: Coordinates }): void {
    const dialogRef = this.dialog.open(MapDialogComponent, {
      data,
      width: '600px',
    });

    dialogRef
      .afterOpened()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        dialogRef.componentInstance.mapModel
          .getObservableMapEvents()
          .pipe(untilDestroyed(this))
          .subscribe((event: IMapBaseEvent) => {
            switch (event.typeEvent) {
              case 'mapReady': {
                // TODO дублирование зоны поражения на мини карту при расчете доп. параметров рад. загр.
                // TODO при нажатии на кнопку "Задать координаты".
                // TODO вынести эту логику из мини карты.
                if (this.data['forecastingResults']) {
                  // Запоминаем текущую модель карты для Прогнозирования
                  const currentMapModel = event.mapObject;

                  const forecastingResults = this.data['forecastingResults'] as IForecastingResultDto;
                  const params = forecastingResults.params.params as IRadiationForecastInputDto;
                  const inputType = this.settings.getDictionaryById(params.inputTypeId)?.sysname;
                  switch (inputType) {
                    case 'manually':
                      this.forecastingVisualizationService.displayRadioactiveResults(
                        forecastingResults,
                        currentMapModel,
                      );
                      break;
                    case 'shelterCalculatingDoses':
                      break;
                    case 'propagationTimeDoses':
                      break;
                    case 'iodineProphylaxisCalculationDoses':
                      break;
                  }
                }
                break;
              }
              default:
                break;
            }
          });
      });

    let dialogSub = dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        if (params?.coordinates) {
          this.form.controls['coordinatesTarget'].setValue(`${params.coordinates.join(', ')}`);
        }
        if (dialogSub) {
          dialogSub = null;
        }
      });
  }

  /** Закрытие формы */
  public close() {
    this.modalRef.close();
  }
  /** Очистка фильтра */
  public clear() {
    this.modalRef.close({});
  }
}
