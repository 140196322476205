<nz-drawer
  [nzVisible]="openDrawer"
  [nzWidth]="900"
  [nzClosable]="false"
  [nzCloseOnNavigation]="false"
>
  <ng-container *nzDrawerContent>
    <form nz-form [formGroup]="form" nzLayout="vertical" *ngIf="form" errorTailor id="idForTest" (ngSubmit)="save()">
      <nz-page-header nzBackIcon>
        <nz-page-header-title>{{ title }}</nz-page-header-title>
        <nz-page-header-extra>
          <button
            nz-button
            nzDanger
            type="button"
            nz-popconfirm
            nzPopconfirmTitle="Запись будет удалена. Вы действительно хотите продолжить?"
            nzOkText="Да"
            nzCancelText="Нет"
            (nzOnConfirm)="delete()"
            *ngIf="model?.id"
          >
            Удалить
          </button>
          <button nz-button nzType="primary" type="submit" [disabled]="!form?.valid">Сохранить</button>
        </nz-page-header-extra>
        <nz-page-header-content>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="24">
              <h4 nz-typography>Реестр</h4>
            </div>
          </div>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="24">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="name">Наименование</nz-form-label>
                <nz-form-control>
                  <input nzSize="large" nz-input formControlName="name" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="12">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="name">Системное наименование</nz-form-label>
                <nz-form-control>
                  <input nzSize="large" nz-input formControlName="sysName" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="12">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="name">Claim</nz-form-label>
                <nz-form-control>
                  <input nzSize="large" nz-input formControlName="claim" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="8">
              <nz-form-item>
                <nz-form-label nzRequired>Иконка</nz-form-label>
                <nz-form-control>
                  <nz-select nzShowSearch formControlName="icon" nzSize="large">
                    <nz-option
                      nzCustomContent
                      [nzValue]="icon.id"
                      [nzLabel]="icon.text"
                      *ngFor="let icon of icons$ | async"
                    >
                      <img src="{{ icon.data.url }}" class="image">
                      <span class="m-l-15">{{ icon.text }}</span>
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="8">
              <nz-form-item class="d-flex d-flex-row">
                <nz-form-label nzRequired nzFor="iconColor">Цвет иконки</nz-form-label>
                <nz-form-control>
                  <div class="color-picker-container">
                    <div class="color-picker">
                      <input
                        [nzBorderless]="true"
                        type="color" nzSize="large" nz-input formControlName="iconColor" />
                    </div>
                    <div>{{ iconColor }}</div>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="8">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="name">Порядок</nz-form-label>
                <nz-form-control>
                  <input nzSize="large" nz-input formControlName="order" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <ng-container
            formArrayName="entities"
            *ngFor="let el of entitiesArray.controls; let i = index"
            class="m-b-20"
          >
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="20">
                <h3>Сущность</h3>
              </div>
            </div>
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="22">
                <bg-registry-panel-entity [formControl]="$any(el)"></bg-registry-panel-entity>
              </div>
              <div nz-col [nzSpan]="2">
                <i
                  (click)="removeEntity(i)"
                  *ngIf="entitiesArray?.length > 1"
                  nz-icon
                  nzType="delete"
                  class="dynamic-delete-button"
                ></i>
              </div>
            </div>
          </ng-container>

          <button nz-button nzType="primary" type="button" (click)="addEntity()">Добавить сущность</button>
        </nz-page-header-content>
      </nz-page-header>
    </form>
  </ng-container>
</nz-drawer>
