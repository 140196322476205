import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { AddressComponent, SearchAddressComponent } from './components';
import { FiasService, GarService } from './services';

@NgModule({
  declarations: [AddressComponent, SearchAddressComponent],
  providers: [FiasService, GarService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzAutocompleteModule,
    NzButtonModule,
    NzIconModule,
    NzInputModule,
    NzToolTipModule,
    FormsModule,
  ],
  exports: [AddressComponent, SearchAddressComponent],
})
export class AddressModule {}
