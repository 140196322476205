import { Injectable } from '@angular/core';
import { IAnyObject, ILifeCycleStepDto, IEmergencyDto } from 'smart-city-types';
import { Subject } from 'rxjs';

/**
 * Сервис меж компонентного взаимодействия рабочего стола(workspace).
 * Предназначен для хранения и отслеживания изменений состояний фильтров
 * для компонент "Реестр происшествий и событий" и компонент "Фильтров" кним.
 * Оповещает подписчиков об изменениях.
 * @author Alexandr Yakovlev <yakovlev.alex.o@gmail.com>
 */
@Injectable()
export class RegistryStateService {
  /** Состояния фильтров для соответствующих компонент */
  private states: {
    filter: IAnyObject[];
    registry: IAnyObject[];
  } = { filter: [], registry: [] };
  /** Состояния фильтров событий для соответствующих компонент */
  private eventStates: {
    filter: IAnyObject[];
    registry: IAnyObject[];
  } = { filter: [], registry: [] };
  /** Значения фильтров инцидентов */
  private filters: IAnyObject = { $and: [] };
  /** Значения фильтров событий */
  private eventFilters: IAnyObject = { $and: [] };
  /** Значения всех фильтров по-умолчанию */
  private filtersDefault: IAnyObject = {};
  /** Значения всех фильтров по-умолчанию для событий */
  private eventFiltersDefault: IAnyObject = {};
  /** Значения фильтров для реестра по-умолчанию */
  private registryDefault: IAnyObject = {};
  /** Значения фильтров для реестра по-умолчанию для событий */
  private eventRegistryDefault: IAnyObject = {};
  /** Событие инициализации реестра по всем происшествиям */
  private allEmergencyRegistryInitialized  = new Subject<IAnyObject>();
  public allEmergencyRegistryInitialized$ = this.allEmergencyRegistryInitialized.asObservable();
  /** Событие изменения фильтров происшествий */
  private filtersChanged = new Subject<IAnyObject>();
  public filtersChanged$ = this.filtersChanged.asObservable();
  /** Событие изменения состояния фильтров происшествий */
  private statesChanged = new Subject<IAnyObject>();
  public statesChanged$ = this.statesChanged.asObservable();
  /** Событие открытия/закрытия формы звонка */
  private callFormState = new Subject<'open' | 'close'>();
  public callFormState$ = this.callFormState.asObservable();
  /** Событие привязки события звонка к происшествию */
  private bindEvent = new Subject<{ incident: IEmergencyDto, lifeCycleStepId: ILifeCycleStepDto }>();
  public bindEvent$ = this.bindEvent.asObservable();
  /** Событие изменения фильтров реестра событий */
  private eventFiltersChanged = new Subject<IAnyObject>();
  public eventFiltersChanged$ = this.eventFiltersChanged.asObservable();
  /** Событие изменения состояния фильтров реестра событий */
  private eventStatesChanged = new Subject<IAnyObject>();
  public eventStatesChanged$ = this.eventStatesChanged.asObservable();
  /** Флаг состояния формы звонка (открыта/закрыта) */
  private isOpen: boolean = false;

  /**
   * Извещение о факте инициализации компонента с фильтрами
   */
  public informAllEmergencyRegistryInitialized(): void {
    this.allEmergencyRegistryInitialized.next();
  }

  public isFormOpen(): boolean {
    return this.isOpen;
  }

  /**
   * Установить занчения фиьтров происшествий из компоненты "Реестр" при его инициализации.
   * Не оповещает об изменениях.
   * @param values - значения фильтров(query) для реестра происшествий.
   */
  public initRegistryValues(values: IAnyObject): void {
    this.states.registry = [];
    this.filters = { $and: [] };
    Object.keys(values).forEach(key => this.states.registry.push(values[key]));
    this.filters.$and = [...this.states.filter, ...this.states.registry];
    this.registryDefault.$and = [...this.states.registry];
    this.initDefaultValues();
  }

  /**
   * Установить занчения фиьтров событий из компоненты "Реестр" при его инициализации.
   * Не оповещает об изменениях.
   * @param values - значения фильтров(query) для реестра событий.
   */
  public initEventsRegistryValues(values: IAnyObject): void {
    this.eventStates.registry = [];
    this.eventFilters = { $and: [] };
    Object.keys(values).forEach(key => this.eventStates.registry.push(values[key]));
    this.eventFilters.$and = [...this.eventStates.filter, ...this.eventStates.registry];
    this.eventRegistryDefault.$and = [...this.eventStates.registry];
    this.initEventDefaultValues();
  }

  /**
   * Установить занчения фиьтров из компоненты "Фильтр инцидентов" при его инициализации.
   * Не оповещает об изменениях.
   * @param values - значения фильтров(query) для фильтра инцидентов.
   */
  public initIncidentsFiltersValues(values: IAnyObject): void {
    this.states.filter = [];
    this.filters = { $and: [] };
    this.states.filter = [...values.$and];
    this.filters.$and = [...this.states.filter, ...this.states.registry];
    this.initDefaultValues();
  }

  /**
   * Установить занчения фиьтров из компоненты "Фильтр инцидентов" при его инициализации.
   * Не оповещает об изменениях.
   * @param values - значения фильтров(query) для фильтра инцидентов.
   */
  public initEventsFiltersValues(values: IAnyObject): void {
    this.eventStates.filter = [];
    this.eventFilters = { $and: [] };
    this.eventStates.filter = [...values.$and];
    this.eventFilters.$and = [...this.eventStates.filter, ...this.eventStates.registry];
    this.initEventDefaultValues();
  }

  /**
   * Обновить значение фильтров(запроса к БД) и их состояние для реестра происшествий.
   * Используется сторонними компонентами (не входящие в реестр)
   * @param query - запрос к БД.
   */
  public patchValue(query: IAnyObject): void {
    const states = this.createStates(query);
    this.setValue(states.registry, states.filters);
  }

  /**
   * Обновить значение фильтров(запроса к БД) и их состояние для реестра событий.
   * Используется сторонними компонентами (не входящие в реестр)
   * @param query - запрос к БД.
   */
  public patchEventsValue(query: IAnyObject): void {
    const states = this.createStates(query);
    this.setEventValue(states.registry, states.filters);
  }

  /**
   * Метод устанавливает значение для поисковой формы "Реестра происшествий".
   * Обновляет состояние фильтров.
   * Оповещает только об изменениях в запросе к БД.
   * @param text - текст поискового запроса.
   * @param attributeNames - массив атрибутов для поиска в базе. Не обязательный параметр.
   */
  public patchSearchValue(text: string, attributeNames?: string[]): void {
    this.states.registry = [...this.registryDefault.$and];
    if (text) {
      this.states.registry.push({
        $text: {
          $search: text,
          $fields: attributeNames ?? ['number', 'addressFact.fullText', 'incidentTypeId.name'],
        },
      });
    }
    this.filters.$and = [...this.states.filter, ...this.states.registry];
    this.filtersChanged.next(this.filters);
  }

  /**
   * Метод устанавливает значение для поисковой формы "Реестра событий".
   * Обновляет состояние фильтров.
   * Оповещает только об изменениях в запросе к БД.
   * @param text - текст поискового запроса.
   * @param attributeNames - массив атрибутов для поиска в базе. Не обязательный параметр.
   */
  public patchEventSearchValue(text: string, attributeNames?: string[]): void {
    this.eventStates.registry = [...this.eventRegistryDefault.$and];
    if (text) {
      this.eventStates.registry.push({
        $text: {
          $search: text,
          $fields: attributeNames ?? ['number', 'addressFact.fullText', 'incidentTypeId.name'],
        },
      });
    }
    this.eventFilters.$and = [...this.eventStates.filter, ...this.eventStates.registry];
    this.eventFiltersChanged.next(this.eventFilters);
  }

  /**
   * Метод устанавливает значения для всей группы полей (одной пачкой) внутри "Фильтров инцидента".
   * Обновляет состояние фильтров.
   * Оповещает только об изменениях в запросе к БД.
   * @param values - значения(query) фильтров для фильтров инцидента.
   */
  public patchFiltersValue(values: IAnyObject): void {
    this.states.filter = [...values.$and];
    this.filters.$and = [...this.states.filter, ...this.states.registry];
    this.filtersChanged.next(this.filters);
  }

  /**
   * Метод устанавливает значения для всей группы полей (одной пачкой) внутри "Фильтров событий".
   * Обновляет состояние фильтров.
   * Оповещает только об изменениях в запросе к БД.
   * @param values - значения(query) фильтров для фильтров событий.
   */
  public patchEventFiltersValue(values: IAnyObject): void {
    this.eventStates.filter = [...values.$and];
    this.eventFilters.$and = [...this.eventStates.filter, ...this.eventStates.registry];
    this.eventFiltersChanged.next(this.eventFilters);
  }

  /**
   * Метод возвращает объект фильтров происшествий.
   * @return filters - объект фильтров происшествий.
   */
  public getValue(): IAnyObject {
    return this.filters;
  }

  /**
   * Метод возвращает объект фильтров реестра событий.
   * @return filters - объект фильтров реестра событий.
   */
  public getEventsValue(): IAnyObject {
    return this.eventFilters;
  }

  /**
   * Метод устанавливает связь события с происшествием.
   * Оповещает об изменениях.
   * @param incidentLink - ссылка на происшествие, содежит id происшествия и его номер.
   */
  public bindToEvent(incidentLink: {
    lifeCycleStepId: ILifeCycleStepDto,
    incident: IEmergencyDto
  }): void {
    this.bindEvent.next(incidentLink);
  }

  /** Оповещение об открытии формы звонка */
  public callFormOpened(): void {
    this.isOpen = true;
    this.callFormState.next('open');
  }

  /** Оповещение о закрытии формы звонка */
  public callFormClosed(): void {
    this.isOpen = false;
    this.callFormState.next('close');
  }

  /**
   * Метод формирует объект состояния фильтров по query запросу к БД.
   * @param queries - запрос к БД.
   * @return state - объект нового состояния фильтров.
   */
  private createStates(queries: IAnyObject): { registry: IAnyObject, filters: IAnyObject } {
    const query = (!Object.keys(queries).length) ? { $and: [] } : queries;
    let filters = {
      buttons: [],
    };
    let registry = {};
    query.$and.forEach((elem) => {
      if (Object.keys(elem).includes('$or')) {
        elem.$or.forEach(btn => filters.buttons.push(btn));
      } else if (Object.keys(elem).includes('organization')) {
        filters = { ...filters, ...elem };
      } else {
        registry = { ...registry, ...elem };
      }
    });
    return { registry, filters };
  }

  /**
   * По полученным состояниям сформирует объект фильтра(запроса к БД).
   * Обновит значения фильтров и их состоянияний в реестре происшествий.
   * Оповещает об изменениях в фильтрах и смене состоняния.
   * @param registry - состояние реестра происшествий.
   * @param filters - состояние компонента фильтра происшествий.
   */
  private setValue(registry: IAnyObject, filters: IAnyObject): void {
    // Оповестить о смене состоняния
    this.statesChanged.next({ registry, filters });
    // Если запрос для фильтров не пуст, обновляем состояние фильтров
    if (Object.keys(filters).length) {
      this.states.filter = [];
      if (filters?.buttons && filters.buttons.length) {
        this.states.filter.push({ $or: [...filters.buttons] });
      }
      Object.keys(filters).forEach((key: string) => {
        if (key !== 'buttons') {
          this.states.filter.push({ [key]: filters[key] });
        }
      });
    }
    // Если запрос для реестра не пуст, обновляем состояние реестра
    if (Object.keys(registry).length) {
      this.states.registry = [];
      Object.keys(registry).forEach((key: string) => {
        this.states.registry.push({ [key]: registry[key] });
      });
    }
    this.filters.$and = [...this.states.filter, ...this.states.registry];
    this.filtersChanged.next(this.filters);
  }

  /**
   * По полученным состояниям сформирует объект фильтра(запроса к БД).
   * Обновит значения фильтров и их состоянияний в реестре событий.
   * Оповещает об изменениях в фильтрах и смене состоняния.
   * @param registry - состояние реестра событий.
   * @param filters - состояние компонента фильтра событий.
   */
  private setEventValue(registry: IAnyObject, filters: IAnyObject): void {
    // Оповестить о смене состоняния
    this.eventStatesChanged.next({ registry, filters });
    // Если запрос для фильтров не пуст, обновляем состояние фильтров
    if (Object.keys(filters).length) {
      this.eventStates.filter = [];
      if (filters?.buttons && filters.buttons.length) {
        this.eventStates.filter.push({ $or: [...filters.buttons] });
      }
      Object.keys(filters).forEach((key: string) => {
        if (key !== 'buttons') {
          this.eventStates.filter.push({ [key]: filters[key] });
        }
      });
    }
    // Если запрос для реестра не пуст, обновляем состояние реестра
    if (Object.keys(registry).length) {
      this.eventStates.registry = [];
      Object.keys(registry).forEach((key: string) => {
        this.eventStates.registry.push({ [key]: registry[key] });
      });
    }
    this.eventFilters.$and = [...this.eventStates.filter, ...this.eventStates.registry];
    this.eventFiltersChanged.next(this.eventFilters);
  }

  /**
   * Установить занчения фильтров по умолчанию для реестра происшествий.
   * Срабатывает при каждой инициализации фильтров.
   * Не оповещает об изменениях.
   */
  private initDefaultValues(): void {
    this.filtersDefault = JSON.parse(JSON.stringify(this.filters));
  }

  /**
   * Установить занчения фильтров по умолчанию для реестра событий.
   * Срабатывает при каждой инициализации фильтров.
   * Не оповещает об изменениях.
   */
  private initEventDefaultValues(): void {
    this.eventFiltersDefault = JSON.parse(JSON.stringify(this.eventFilters));
  }
}
