<form nz-form [formGroup]="form" errorTailor id="idForTest" nzLayout="vertical" *ngIf="form">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzFor="timeSinceAccident" [nzTooltipTitle]="tooltipText" nzTooltipIcon="info-circle"
          >Время после выброса</nz-form-label
        >
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="timeSinceAccident">
            <input nzSize="large" nz-input formControlName="timeSinceAccident" />
          </nz-input-group>
          <ng-template #timeSinceAccident> ч </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzFor="coordinatesTarget">Координаты</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" nzSearch [nzAddOnAfter]="suffixIconButton">
            <input type="text" placeholder="Широта, Долгота" nz-input formControlName="coordinatesTarget" />
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button
              nz-button
              nzSize="large"
              nzSearch
              nzType="primary"
              type="button"
              alt="Задать координаты"
              (click)="onClickAppointCoordinates($event)"
            >
              <i nz-icon nzType="aim"></i>
            </button>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzFor="doseStrengthAtPoint">Мощность дозы γ-излучения</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="doseStrengthAtPoint">
            <input nzSize="large" nz-input formControlName="doseStrengthAtPoint" />
          </nz-input-group>
          <ng-template #doseStrengthAtPoint> {{ unitName }} </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="16">
      <nz-form-item>
        <nz-form-label nzFor="shelterLocationId">Расположение укрытия</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="shelterLocationId" nzSize="large">
            <nz-option
              [nzValue]="location.value"
              [nzLabel]="location.text"
              *ngFor="let location of shelterLocations"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="shelterTypeId">Тип укрытия</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="shelterTypeId" nzSize="large">
            <nz-option
              [nzValue]="shelterType.value"
              [nzLabel]="shelterType.text"
              *ngFor="let shelterType of shelterTypes"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="shelterFloorId">Этаж</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="shelterFloorId" nzSize="large">
            <nz-option [nzValue]="floor.value" [nzLabel]="floor.text" *ngFor="let floor of shelterFloors"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row *nzModalFooter>
    <div nz-col [nzSpan]="12" class="d-flex">
    </div>
    <div nz-col [nzSpan]="12">
      <button nz-button nzType="default" class="m-r" (click)="close()">Отмена</button>
      <button nz-button nzType="primary" [disabled]="!form.valid" (click)="startForecasting()">
        Рассчитать прогноз
      </button>
    </div>
  </div>
</form>
