import { Injectable } from '@angular/core';
import { ICreate, NotificationService, RestService, Settings2Service, SfsService } from '@smart-city/core/services';
import { ScConsole, Uuid } from '@smart-city/core/utils';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, map, switchMap } from 'rxjs/operators';
import { IAnyObject, IUserInfoDto } from 'smart-city-types';
import { IFileInfoDto } from 'smart-city-types/interfaces/file-info-dto.interface';
import { ISignature } from '@bg-front/core/models/interfaces';
import { IFileInfo } from '../../models/interfaces/file-info.interface';
import { SignatureService } from '@bg-front/core/services';
import { IFileUpload } from '@bg-front/core/models/interfaces';

/**
 * Сервис для получения списка загруженных файлов
 */
@Injectable({
  providedIn: 'root',
})
export class MultiFileService {
  constructor(
    private readonly sfs: SfsService,
    private readonly settings: Settings2Service,
    private readonly note: NotificationService,
    private readonly rest: RestService,
    private readonly signatureService: SignatureService,
  ) {}

  /**
   * Получение файлов из Sfs
   * @param filesInfo объекты файлов
   */
  public getFilesFromSfs(filesInfo: IFileInfoDto[]): Observable<IFileInfo[] | IFileUpload[]> {
    if (!filesInfo?.length) return of([]);

    return forkJoin(
      filesInfo.map((fileInfo: IFileInfoDto) =>
        forkJoin([
          of(fileInfo.userId),
          this.sfs.getData({ uuid: fileInfo.file }),
          this.signatureService.getSignatureByEntityId(<string>fileInfo.file),
        ]),
      ),
    ).pipe(
      map((responses) =>
        responses
          .map((response) => ({
            file: response[1].items.length ? response[1].items[0] : null,
            userId: response[0],
            signature: response[2],
          }))
          .filter((fileInfo: IFileInfo) => !!fileInfo.file),
      ),
      catchError((err: Error) => {
        ScConsole.error([err.message]);
        this.note.pushError('Ошибка при получении информации о файлах')
        return of(null);
      }),
    );
  }

  /**
   * Замена объектов файлов сущности на соответствующие файлы из Sfs
   * @entity объекты файлов
   */
  public mergeFilesFromSfs(entity: IAnyObject): Observable<IAnyObject> {
    return this.getFilesFromSfs(entity.documents).pipe(
      map((files) => {
        entity.documents = files;
        return entity;
      }),
    );
  }

  /** Сохранение файла в СФС */
  public saveFilesToSfs(documents: IFileInfo[]): Observable<IFileInfo[]> {
    // Список файлов для удаления.
    const deleteFiles = (documents || []).filter(
      (document) => (<IAnyObject>document.file).uuid && !(<IAnyObject>document.file).isActive,
    );

    // Список файлов для загрузки.
    const uploadFiles = (documents || [])
      .filter((document) => document.file instanceof File)
      // В случае если вновь загруженный документ подписан, то надо сгенерировать его идентификатор,
      // сохранить в сущности подписи и передать его в метод загрузки в хранилище SFS.
      .map((document: IFileInfo) => {
        if (document.signature) document.signature.entityId = !document.signature.entityId ? Uuid.newUuid() : undefined;
        return document;
      });

    const newSignatures = (documents || [])
      .map((document) => document.signature)
      .filter((signature) => signature && !signature.id);

    // В результирующий массив попадут файлы не помеченные к удалению.
    const documentsResult = (documents || [])
      .filter((document) => (<IAnyObject>document).file.uuid && (<IAnyObject>document).file.isActive)
      .map((document) => ({
        file: (<IAnyObject>document).file.uuid,
        userId: <IUserInfoDto>document.userId,
      }));

    // Загрузка файлов в хранилище
    return forkJoin(
      uploadFiles.map((document) =>
        this.sfs.upload({
          file: <File>document.file,
          fileName: (<File>document.file).name,
          fileTags: 'file, multiFileService',
          fileUuid: document.signature?.entityId || undefined,
        }),
      ),
    ).pipe(
      defaultIfEmpty([]),
      switchMap((uploadedDocuments: ICreate[]) => {
        // Добавить к результирующему массиву загруженные файлы
        documentsResult.push(
          ...uploadedDocuments.map((document) => ({
            file: document.uuid,
            userId: {
              id: this.settings.currentUser.id,
              fio: this.settings.currentUser.fio,
            },
          })),
        );
        return forkJoin(
          newSignatures.map((signature: ISignature) =>
            this.rest.serviceRequest({
              action: 'insert',
              service: { name: 'Edi' },
              entity: { name: 'Signatures' },
              data: signature,
            }),
          ),
        );
      }),
      defaultIfEmpty(null),
      // Удаление подписей для удаляемых файлов
      switchMap(() =>
        this.rest.serviceRequest({
          action: 'delete',
          service: { name: 'Edi' },
          entity: {
            name: 'Signatures',
            query: { entityId: { $in: deleteFiles.map((item) => (<IAnyObject>item.file).uuid) } },
          },
        }),
      ),
      // Удаление файлов из хранилища
      switchMap(() => forkJoin(deleteFiles.map((item) => this.sfs.delete((<IAnyObject>item.file).uuid)))),
      defaultIfEmpty(null),
      // Если в процессе работы с SFS произошла ошибка, то вывести сообщение но сохранение выполнить.
      catchError(() => {
        this.note.pushError('В процессе сохранения/удаления файлов произошла ошибка.');
        return of(null);
      }),
      map(() => {
        return documentsResult;
      }),
    );
  }
}
