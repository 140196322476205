<div nz-row nzJustify="space-between">
  <div nz-col nzFlex="none">
    <b>{{ title }}</b>
  </div>
  <div nz-col nzFlex="none">
    <nz-space>
      <ng-container *ngIf="!editingPolygon; else polygonEdit">
        <button *nzSpaceItem nz-button nzType="primary" (click)="editPolygon()">Редактировать</button>
      </ng-container>

      <ng-template #polygonEdit>
        <button *nzSpaceItem nz-button nzType="default" (click)="cancelEditPolygon()">Отмена</button>
        <button
          *nzSpaceItem
          nz-button
          nzType="primary"
          [nzLoading]="savingPolygon"
          (click)="savePolygon()"
        >
          Сохранить
        </button>
      </ng-template>
    </nz-space>
  </div>
</div>
<div class="map-container" [ngClass]="{ 'map-cursor': editingPolygon }">
  <map-base *ngIf="mapOptions?.mapId" [mapOptions]="mapOptions"></map-base>
</div>