import { Component, OnInit } from '@angular/core';
import { ControlsOf, FormGroup } from '@ngneat/reactive-forms';
import { ControlContainer, Validators } from '@angular/forms';
import { BaseComponent } from '@bg-front/core/components';
import { SignificantObjectsService } from '../../services';
import { Settings2Service } from '@smart-city/core/services';
import { IChemicalTypeForSelect, IStoredChemicalForForm } from '../../models/interfaces';

@Component({
  selector: 'stored-chemical',
  templateUrl: './stored-chemical.component.html'
})
export class StoredChemicalComponent extends BaseComponent implements OnInit {
  /** Форма */
  public form: FormGroup<ControlsOf<IStoredChemicalForForm>>;

  /** Данные для селекта Тип АХОВ */
  public chemicalTypeForSelect$ = this.service.getChemicalTypeForSelect()

  /** Данные для селекта Тип ёмкости */
  public containerTypeForSelect = this.settings.getDictForSelect('typeEmergencyChemicalSubstances');

  /** @ignore */
  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly service: SignificantObjectsService,
    private readonly settings: Settings2Service,
  ) {
    super();
  }

  /** @ignore */
  ngOnInit(): void {
    this.form = <FormGroup<ControlsOf<IStoredChemicalForForm>>>this.controlContainer.control;
    this.form.controls.chemicalType.setValidators([Validators.required]);
    setTimeout(() => this.form.controls.chemicalType.setErrors(null), 0);
  }

  /** Метод сравнения для корректной подстановки значения в селект типа АХОВ при открытии на редактирование */
  public compareFn(item1: IChemicalTypeForSelect, item2: IChemicalTypeForSelect): boolean {
    return (item1 && item2 ? item1.id === item2.id : item1 === item2);
  }
}
