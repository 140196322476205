import { Directive, Input } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { ILifeCycleStepParamDto } from 'smart-city-types';

/**
 * Директива для подписки на изменения формы или компонента для отображения ошибок валидации
 */
@Directive({
  selector: '[appLCSFormState]',
})
export class LifeCycleStepFormStateDirective {
  private attributes: Map<string, ILifeCycleStepParamDto>;

  /**
   * @ignore
   * @param formGroupDir
   */
  constructor(private formGroupDir: FormGroupDirective) {}

  @Input()
  public set params(value: ILifeCycleStepParamDto[]) {
    this.attributes = new Map<string, ILifeCycleStepParamDto>();
    if (value && this.formGroupDir.control) {
      value.forEach((param: ILifeCycleStepParamDto) => {
        this.attributes.set(param.attribute, param);
      });
      this.changeDisabledState(this.formGroupDir.control);
    }
  }

  private changeDisabledState(form: FormGroup | FormArray) {
    Object.keys(form.controls).forEach((field: string) => {
      const control = form.get(field);
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.changeDisabledState(control);
      } else {
        const attr = this.attributes.get(field);
        if (attr && attr.enabled) {
          control.enable({ emitEvent: false });
        } else {
          control.disable({ emitEvent: false });
        }
      }
    });
  }
}
