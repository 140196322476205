import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@bg-front/core/components';
import { FormControl, FormGroup } from '@angular/forms';
import { IButtonFilter } from '../../../models/interfaces';
import { IAnyObject } from 'smart-city-types';
import { Settings2Service } from '@smart-city/core/services';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bg-planned-works-event-filter',
  templateUrl: './planned-works-event-filter.component.html',
  styleUrls: ['./planned-works-event-filter.component.scss'],
})
export class PlannedWorksEventFilterComponent extends BaseComponent implements OnInit {
  private dictionaryName = 'eventStatus';
  private phoneNumber = undefined;
  /**
   * Признак показа всех фильтров.
   */
  public showAllFilters = false;

  /**
   * Описание формгруппы
   */
  public formGroup: FormGroup = new FormGroup({
    municipal: new FormControl(undefined),
    incidentTypeId: new FormControl(undefined),
    sourceType: new FormControl(undefined),
    dateTimeFrom: new FormControl(''),
    dateTimeTo: new FormControl(''),
  });

  /**
   * Массив кнопок
   */
  private buttons: IButtonFilter[] = [];

  /**
   * Эммитр для оповещения о изменении
   */
  @Output()
  public changedFilter: EventEmitter<IAnyObject> = new EventEmitter<IAnyObject>();

  /**
   * @ignore
   */
  constructor(private settings: Settings2Service) {
    super();

    this.buttons.push(<IButtonFilter>{
      sysname: 'new',
      state: true,
      filter: {
        isHandled: false,
      },
    });

    this.buttons.push(<IButtonFilter>{
      sysname: 'createdByMe',
      state: false,
      filter: {
        responsibleId: this.settings.currentUser.id,
      },
    });

    this.buttons.push(<IButtonFilter>{
      sysname: 'currentDeclarant',
      state: false,
      filter: {
        extnum: this.phoneNumber,
      },
    });
  }

  /**
   * @ignore
   */
  public ngOnInit(): void {
    this.changedFilter.emit(this.getFilterObject());

    this.formGroup.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.changedFilter.emit(this.getFilterObject());
    });
  }

  /**
   * Скрытие/Открытие фильтра
   */
  public hide() {
    this.showAllFilters = !this.showAllFilters;
  }

  /**
   * Получаем состояние кнопки
   * @param sysName системное имя кнопки
   */
  public getState(sysName: string): boolean {
    const btn = this.getButton(sysName);
    return btn ? btn.state : false;
  }

  /**
   * Обработка нажатия на кнопку
   */
  public selectFilter(sysName: string): void {
    const btn = this.getButton(sysName);
    if (btn) {
      btn.state = !btn.state;
      this.changedFilter.emit(this.getFilterObject());
    }
  }

  /**
   * Получаем кнопку по системному имени
   * @param sysName системнео имя
   */
  private getButton(sysName: string): IButtonFilter {
    return this.buttons.find((btn: IButtonFilter) => btn.sysname === sysName);
  }

  /**
   * Формируем объект фильтра
   */
  private getFilterObject(): IAnyObject {
    const selected = this.buttons.filter((btn: IButtonFilter) => btn.state);
    const filter: IAnyObject = {
      $and: [{ $or: [] }],
    };

    selected.forEach((btn: IButtonFilter) => {
      /**
       * Кнопка "созданно мной" попадает под условие И
       */
      if (btn.sysname !== 'createdByMe') {
        filter.$and[0].$or.push(btn.filter);
      } else {
        filter.$and.push(btn.filter);
      }
    });

    if (this.formGroup.controls['dateTimeFrom'].value) {
      filter.$and.push({
        timeCreate: {
          $gte: this.formGroup.controls['dateTimeFrom'].value,
        },
      });
    }

    if (this.formGroup.controls['dateTimeTo'].value) {
      filter.$and.push({
        timeCreate: {
          $lte: this.formGroup.controls['dateTimeTo'].value,
        },
      });
    }

    return filter;
  }
}
