<div class="interview">
  <div class="interview-header">
    <div class="bg-row">
      <div class="interview-header-title">Опрос</div>
    </div>
  </div>
  <div class="interview-body incident-panel">
    <mat-accordion multi>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
            <mat-panel-title class="mat-expansion-panel-header-title">
              <div class="header-container">
                <div class="header-container-title">Информация о заявителе</div>
                <div class="header-container__divider"><hr /></div>
              </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <bg-declarer-info-form [options]="declarantInfoFormOptions"></bg-declarer-info-form>
      </mat-expansion-panel>
      <mat-expansion-panel [disabled]="ksipInfoMessage" [expanded]="true">
        <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
            <mat-panel-title class="mat-expansion-panel-header-title">
              <div class="header-container">
                <div class="header-container-title">Дополнительная информация о КСиП</div>
                <div class="header-container__divider"><hr /></div>
              </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <bg-survey-ksip
          [eventForm]="eventForm"
          [eventId]="eventId"
          [isHandled]="isHandled"
          (messageEvent)="onKsipInfoMessage($event)"
          (answer)="onSurveyAnswer($event)"
          (reAnswer)="onSurveyReAnswer($event)">
        </bg-survey-ksip>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
