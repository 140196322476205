<h3 class="main-header">
  <mat-icon class="header-icon">track_changes</mat-icon>
  Результаты прогноза
</h3>
<hr/>
<div class="bg-col">
  <h4 class="section-header">Степень радиоактивного загрязнения в заданной точке</h4>
  <ul class="results-list">
    <li class="results-list-item">
      <label>Мощность дозы внешнего ɣ-излучения ({{unitsName}})</label><br>
      <label class="results-list-item--var">
        {{forecastingResults?.result?.outerGammaIrradiationStrength | commaToDot | number: '0.2-2'}}
      </label>
    </li>
    <li class="results-list-item">
      <label>Плотность рад. загрязнения (Ки/см²)</label>
      <label class="results-list-item--var">
        {{forecastingResults?.result?.radioactivePollutionDensity | commaToDot | number: '0.2-2'}}
      </label>
    </li>
    <li class="results-list-item">
      <label>Доза внешнего облучения при прохождении рад. облака {{this.unitsName}}</label><br>
      <label class="results-list-item--var">
        {{forecastingResults?.result?.outerIrradiationDoseForCloud | commaToDot | number: '0.2-2'}}
      </label>
    </li>
    <li class="results-list-item">
      <label>Макс. объёмная концентрация рад. нуклидов в приземистом слое атмосферы (Ки/л)</label><br>
      <label class="results-list-item--var">
        {{forecastingResults?.result?.maxNuclideConcentration | commaToDot | number: '0.2-2'}}
      </label>
    </li>
  </ul>
</div>
<hr/>
