<div class="bg-row">
  <div class="bg-col">
    <div class="btn-close align-right m-b-15">
      <mat-icon class="cancel" (click)="close()">close</mat-icon>
    </div>
    <div class="bg-form-group mini-map-container">
      <map-base *ngIf="mapOptions" [mapOptions]="mapOptions"></map-base>
    </div>
  </div>
</div>
