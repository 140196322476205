import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { INwHeaderBarOptions, IScSelectOptions } from '@smart-city/core/common';
import { takeUntil } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import { IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { OperationFilterHelper } from '@bg-front/core/models/classes';
import { BaseComponent } from '@bg-front/core/components';

/**
 * Общая компонента для фильтров организаций
 *  @example
 *  <map-organization-filter
 *    [title]="'Организации Экстренного реагирования'"
 *    [mapLayerFilters]="data.mapLayersFilters['Организации Экстренного реагирования']"
 *    [layerFilters]="data.layersFilters['Организации Экстренного реагирования']"
 *    (changedFilters)="onFiltersChange($event)"
 *  ></map-organization-filter>
 */
@Component({
  selector: 'map-organization-filter',
  templateUrl: './map-organization-filter.component.html',
  styleUrls: ['./map-organization-filter.component.scss'],
})
export class MapOrganizationFilterComponent extends BaseComponent implements OnInit, OnChanges {
  /** Заголовок фильтра */
  @Input()
  public title: string;

  /** Значения фильтров */
  @Input()
  public mapLayerFilters: IMapLayerEntityFilter[] = [];

  /** Значения фильтоов из конструктора слоев */
  @Input()
  public layerFilters: IMapLayerEntityFilter[] = [];

  /** Оповещение о изменении значений фильтров */
  @Output()
  public changedFilters: EventEmitter<IMapLayerEntityFilter[] | 'delete'> = new EventEmitter<
    IMapLayerEntityFilter[] | 'delete'
  >();

  /** Форма */
  public form: FormGroup;

  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions;

  /** Настройка компоненты Тип организации */
  public orgTypeParamOptions: IScSelectOptions = {
    title: 'Тип организации',
    clearable: true,
    service: 'Admin',
    entity: 'OrgTypeParams',
    modern: true,
    fieldName: 'shortName',
  };

  /** Настройка компоненты Наименование организации */
  public organizationOptions: IScSelectOptions = {
    title: 'Наименование организации',
    clearable: true,
    service: 'Admin',
    entity: 'Organizations',
    modern: true,
    fieldName: 'name',
  };

  /** @ignore */
  ngOnInit(): void {
    this.headerOptions = {
      margin: 'collapse',
      title: this.title,
      buttons: [
        {
          type: 'button',
          options: {
            name: 'delete',
            icon: 'delete_outline',
          },
        },
      ],
    };

    const orgTypeParamFilter = this.layerFilters.find(
      (filter) => filter.property === 'orgTypeParam.organizationTypeId.sysname',
    );
    if (!!orgTypeParamFilter) {
      this.orgTypeParamOptions.query = OperationFilterHelper.createValueByOperation(
        'organizationTypeId.sysname',
        orgTypeParamFilter.value,
        orgTypeParamFilter.operation,
      );
      this.organizationOptions.query = OperationFilterHelper.createValueByOperation(
        orgTypeParamFilter.property,
        orgTypeParamFilter.value,
        orgTypeParamFilter.operation,
      );
    }

    this.form = new FormGroup({
      orgTypeParam: new FormControl(this.mapLayerFilters.find((item) => item.property === 'orgTypeParam')?.value),
      organization: new FormControl(this.mapLayerFilters.find((item) => item.property === 'id')?.value),
    });

    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => this.changedFilters.emit(this.getFilters(value)));
  }

  /** @ignore */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue.length === 0) {
      this.form?.reset({}, { emitEvent: false });
    }
  }

  /**
   * Формирование фильтров на основании значения формы
   * @param value значение формы
   */
  getFilters(value: IAnyObject): IMapLayerEntityFilter[] {
    const result: IMapLayerEntityFilter[] = [];
    if (value.orgTypeParam) {
      result.push({
        property: 'orgTypeParam',
        value: value.orgTypeParam,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.organization) {
      result.push({
        property: 'id',
        value: value.organization,
        operation: FilterOperationEnum.equal,
      });
    }

    return result;
  }

  /**
   * Обработка нажатия на кнопку удаления
   */
  public delete() {
    this.changedFilters.emit('delete');
    this.form.reset();
    this.form.enable();
  }
}
