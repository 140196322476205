import { InjectionToken } from '@angular/core';

/**
 * Описание базовых ошибок, характерных для любых полей
 */
export const defaultErrors = {
  required: () => 'Обязательно для заполнения',
  minlength: ({ requiredLength, actualLength }) => `Необходимо не менее ${requiredLength}, введено ${actualLength}`,
  maxlength: ({ requiredLength, actualLength }) => `Необходимо не более ${requiredLength}, введено ${actualLength}`,
  pattern: () => 'Неверный формат вводимого значения',
  maskPhone: ({ prefix, maskText }) => `Не соответствует маске ${prefix} ${maskText}`,
  coordinates: value => `${value.value}. Диапазон значений широта: -+180, долгота: +-90`,
  incorrectTime: () => 'Значение не соответствует условию',
  loginAlreadyExists: () => 'Пользователь с таким логином уже зарегистрирован',
  ordinalNumberExists: (organizationName: string): string => `Такой номер уже заполнен для организации ${organizationName}`,
  mask: ({ actualValue, requiredMask }: { actualValue: string; requiredMask: string }): string =>
  `Неверный формат, небходимо +7 ${requiredMask}`,
  max: value => `Введенное значение больше ${value}`,
  min: value => `Введенное значение меньше ${value}`,
  maxFileSize: ({ value, unit }) => `Размер загружаемого файла превышает ${value} ${unit}`,
  minFileSize: ({ value, unit }) => `Размер загружаемого файла меньше ${value} ${unit}`,
};

/**
 * Токен для DI
 */
export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors,
});
