<div
  *ngIf="isShowWidget"
  class="bell-icon"
  [ngClass]="{ 'bell-icon__active': isActive }"
  [attr.tooltip]="'Режимы функционирования'"
  [attr.flow]="'down'"
  (click)="onClick()"
>
  <mat-icon>notification_important</mat-icon>
</div>


