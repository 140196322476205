import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@bg-front/core/components';
import { IStepDialogDataOptions } from '../../models/interfaces';

/**
 * TODO: Подумать. Может он не нужен или не нужен bg-involve-org-without-interaction-form.
 *       Ибо функциональности он толком не несет.
 * Диалог для редактирования шага реагирования, если привлекаемая служба без информационного взаимодействия
 */

@Component({
  selector: 'bg-involve-org-without-interaction-dialog',
  templateUrl: './involve-org-without-interaction-dialog.component.html',
  styleUrls: ['./involve-org-without-interaction-dialog.component.scss'],
})
export class InvolveOrgWithoutInteractionDialogComponent extends BaseComponent implements OnInit {
  /** Форма */
  public formControl: FormGroup;

  public options: IStepDialogDataOptions = {
    step: null,
  };

  /** Имя привлекаемой службы */
  public serviceTypeName = '';

  constructor(
    public readonly dialogRef: MatDialogRef<IStepDialogDataOptions>,
    @Inject(MAT_DIALOG_DATA) public data: IStepDialogDataOptions,
  ) {
    super();
  }
  /** @ignore */
  public ngOnInit(): void {
    this.options = {
      ...this.data,
    };
    this.serviceTypeName = this.data?.step['serviceType.name'] || this.options?.serviceTypeName;
  }

  /** Обработчик по нажатию на кнопку отмена
   * @return
   */
  public closeHandler(): void {
    this.dialogRef.close();
  }
}
