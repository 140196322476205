import { Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { IScInputOptions, IScTextareaOptions } from '@smart-city/core/common';
import { IDds01AdditionalInfoDto, ILifeCycleStepParamDto } from 'smart-city-types';

/**
 * Компонента реализует возможность просмотра и редактирования дополнительной информации в форме происшествия ДДС01.
 * @author Alexandr Yakovlev
 */
@Component({
  selector: 'bg-dds01-additional-info',
  templateUrl: './dds01-additional-info.component.html',
  styleUrls: ['./dds01-additional-info.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Dds01AdditionalInfoComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => Dds01AdditionalInfoComponent),
      multi: true,
    },
  ],
})
export class Dds01AdditionalInfoComponent {
  /** Аттрибуты */
  @Input()
  public attributes: ILifeCycleStepParamDto[];
  /** Входные параметры компонента */
  @Input() public options: IDds01AdditionalInfoDto;
  /** Реактивная форма, для взаимодействия с внешней реактивной формой */
  public additionalForm: FormGroup;

  /** Задействовано техники */
  public totalCarsOption: IScInputOptions = {
    label: 'Задействовано техники',
    mask: '0*',
  };

  /** Задействовано стволов */
  public trunksOption: IScInputOptions = {
    label: 'Задействовано стволов',
    mask: '0*',
  };

  /** Задействовано личного состава */
  public personalOption: IScInputOptions = {
    label: 'Задействовано личного состава',
    mask: '0*',
  };

  /**
   * Настройка компоненты Ведение пожара
   */
  public actualOptions: IScTextareaOptions = {
    label: 'Ведение пожара',
    maxLength: 1000,
    rows: 14,
  };

  /** @ignore */
  constructor() {
    this.additionalForm = new FormGroup({
      totalCars: new FormControl(undefined, [Validators.required]),
      trunks: new FormControl(undefined, [Validators.required]),
      personal: new FormControl(undefined, [Validators.required]),
      actual: new FormControl(undefined),
      timeDetection: new FormControl(undefined),
      timeDeparture: new FormControl(undefined),
      timeArrival: new FormControl(undefined),
      timeFirstSalvo: new FormControl(undefined),
      timeLocalization: new FormControl(undefined),
      timeLiquidation: new FormControl(undefined),
      timeRecovery: new FormControl(undefined),
    });
  }

  /**
   * Функция обновляет локальную переменную
   * @param dds01AdditionalInfo Новое значение
   */
  public writeValue(dds01AdditionalInfo: IDds01AdditionalInfoDto): void {
    this.additionalForm.controls.totalCars.setValue(dds01AdditionalInfo?.totalCars || 0);
    this.additionalForm.controls.trunks.setValue(dds01AdditionalInfo?.trunks || 0);
    this.additionalForm.controls.personal.setValue(dds01AdditionalInfo?.personal || 0);
    this.additionalForm.controls.actual.setValue(dds01AdditionalInfo?.actual || undefined);
    this.additionalForm.controls.timeDetection.setValue(dds01AdditionalInfo?.timeDetection || undefined);
    this.additionalForm.controls.timeDeparture.setValue(dds01AdditionalInfo?.timeDeparture || undefined);
    this.additionalForm.controls.timeArrival.setValue(dds01AdditionalInfo?.timeArrival || undefined);
    this.additionalForm.controls.timeFirstSalvo.setValue(dds01AdditionalInfo?.timeFirstSalvo || undefined);
    this.additionalForm.controls.timeLocalization.setValue(dds01AdditionalInfo?.timeLocalization || undefined);
    this.additionalForm.controls.timeLiquidation.setValue(dds01AdditionalInfo?.timeLiquidation || undefined);
    this.additionalForm.controls.timeRecovery.setValue(dds01AdditionalInfo?.timeRecovery || undefined);
  }

  /**
   * Регистрируем функцию, которую будем вызывать при изменении/уведомдении значения
   * @param fn Функция
   */
  public registerOnChange(fn: any): void {
    this.additionalForm.valueChanges.subscribe(fn);
  }

  /**
   * Регистрируем функцию, которую будем вызывать если "затронули" данный элемент
   * @param fn Функция
   */
  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /**
   * Активируем или блокируем элемент
   * @param isDisabled новое состояние элемента
   */
  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.additionalForm.disable() : this.additionalForm.enable();
  }

  /** Извещаем о том, что элемент затронут пользователем */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private propagateTouch = () => { return; };


  /**
   * Метод валидации формы. Помечает невалидные контролы.
   * @returns ValidationErrors
   */
  public validate(c: AbstractControl): ValidationErrors | null {
    return this.additionalForm.valid || this.additionalForm.disabled
      ? null
      : { invalidForm: { valid: false, message: 'Невалидное поле в форме.' } };
  }
}
