<nz-spin nzTip="Загрузка..." [nzSpinning]="isLoading" class="nz-spin-center">
  <form *ngIf="form" [formGroup]="form" nz-form nzLayout="vertical" errorTailor (ngSubmit)="save()">
    <nz-layout>
      <nz-header>
        <nz-page-header nzTitle="{{ title }}" nzBackIcon="arrow-left" (nzBack)="close()">
          <nz-page-header-extra>
            <button
              *ngIf="this.model?.id"
              type="button"
              nz-button
              nzType="default"
              nzDanger
              (click)="showDeleteConfirm()"
            >
              Удалить
            </button>
            <button nz-button nzType="primary" type="submit">Сохранить</button>
          </nz-page-header-extra>
        </nz-page-header>
      </nz-header>
      <nz-content>
        <nz-tabset>
          <nz-tab nzTitle="Описание">
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label nzRequired>Категория</nz-form-label>
                  <nz-form-control>
                    <nz-select
                      nzShowSearch
                      nzAllowClear
                      formControlName="category"
                      nzPlaceHolder="Выберите категорию"
                      [compareWith]="compareFn"
                    >
                      <nz-option
                        [nzValue]="item"
                        [nzLabel]="item.name"
                        *ngFor="let item of categoriesForSelect$ | async"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label>Тип</nz-form-label>
                  <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear formControlName="type" nzPlaceHolder="Выберите тип">
                      <nz-option [nzValue]="item.id" [nzLabel]="item.text" *ngFor="let item of typeForSelect">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label nzRequired>Наименование</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="name" placeholder="Укажите наименование" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label nzRequired>Краткое наименование</nz-form-label>
                  <nz-form-control>
                    <input
                      nz-input
                      formControlName="shortName"
                      [maxLength]="100"
                      placeholder="Укажите краткое наименование"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label>Ответственная организация</nz-form-label>
                  <nz-form-control>
                    <nz-select
                      nzShowSearch
                      formControlName="organizationId"
                      nzPlaceHolder="Выберите ответственнаю организацию"
                    >
                      <nz-option
                        [nzValue]="item.id"
                        [nzLabel]="item.text"
                        *ngFor="let item of organizationsForSelect$ | async"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label nzRequired>Муниципальное образование</nz-form-label>
                  <nz-form-control>
                    <nz-select nzShowSearch formControlName="mo" nzPlaceHolder="Выберите Муниципальное образование">
                      <nz-option [nzValue]="item.id" [nzLabel]="item.name" *ngFor="let item of moForSelect$ | async">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label>Адрес</nz-form-label>
                  <nz-form-control>
                    <nz-address-kit formControlName="address"></nz-address-kit>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="8">
                <nz-form-item>
                  <nz-form-label>Корпус</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="corp" placeholder="Укажите корпус" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="8">
                <nz-form-item>
                  <nz-form-label>Координаты</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="coordinates" placeholder="Укажите координаты" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="8">
                <nz-form-item>
                  <nz-form-label>Этажность</nz-form-label>
                  <nz-form-control>
                    <nz-input-number formControlName="level" placeholder="Укажите этажность"></nz-input-number>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label>Категория электроснабжения потребителя</nz-form-label>
                  <nz-form-control>
                    <nz-select
                      nzShowSearch
                      nzAllowClear
                      formControlName="powerSupplyConsumerCategory"
                      nzPlaceHolder="Укажите категорию"
                    >
                      <nz-option
                        [nzValue]="item.id"
                        [nzLabel]="item.text"
                        *ngFor="let item of powerSupplyCategoryForSelect"
                      >
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label nzRequired>Состояние</nz-form-label>
                  <nz-form-control>
                    <nz-select nzShowSearch formControlName="state" nzPlaceHolder="Выберите состояние">
                      <nz-option [nzValue]="item.id" [nzLabel]="item.text" *ngFor="let item of stateForSelect">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label>Ответственный</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="responsible" placeholder="Укажите ответсвенного" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label nzRequired>Контактный телефон</nz-form-label>
                  <nz-form-control>
                    <input
                      prefix="+7 "
                      mask="000 000-00-00"
                      nz-input
                      formControlName="phone"
                      placeholder="Укажите телефон"
                    />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label>Дежурные службы</nz-form-label>
                  <nz-form-control>
                    <nz-textarea-count [nzMaxCharacterCount]="300">
                      <textarea
                        nz-input
                        formControlName="dutyServices"
                        maxlength="300"
                        placeholder="Введите описание дежурных служб"
                      ></textarea>
                    </nz-textarea-count>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label>Комментарий</nz-form-label>
                  <nz-form-control>
                    <nz-textarea-count [nzMaxCharacterCount]="1000">
                      <textarea nz-input formControlName="comment" placeholder="Введите комментарий"></textarea>
                    </nz-textarea-count>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- <div nz-col [nzSpan]="24">
                План/карточка тушения пожара
                <p *ngFor="let card of fireFightingCardPlans">
                  <a class="link" href="javascript:void(0)" (click)="openfireFightingCardPlan(card.id)">
                    {{ card.number }}
                  </a>
                </p>
              </div> -->
            </div>
          </nz-tab>
          <nz-tab nzTitle="Параметры">
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label>Риск для прогнозирования</nz-form-label>
                  <nz-form-control>
                    <nz-select
                      nzShowSearch
                      nzAllowClear
                      nzMode="multiple"
                      formControlName="projectedRiskIds"
                      nzPlaceHolder="Выберите риски для прогнозирования"
                    >
                      <nz-option [nzValue]="item.id" [nzLabel]="item.text" *ngFor="let item of projectedRiskForSelect">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col [nzSpan]="24" *ngIf="isChemicalObject">
                <nz-collapse>
                  <nz-collapse-panel nzHeader="Хранимые АХОВ">
                    <ng-container formArrayName="storedChemicals">
                      <div
                        nz-row
                        class="chemical"
                        [nzGutter]="24"
                        *ngFor="let el of form.controls.storedChemicals?.controls; let i = index"
                        [formGroupName]="i"
                      >
                        <div nz-col nzFlex="1 0">Параметры АХОВ</div>
                        <div nz-col nzFlex="0 0 max-content" *ngIf="form.controls.storedChemicals?.controls.length > 1">
                          <i nz-icon nzType="delete" class="dynamic-delete-button" (click)="removeChemical(i)"></i>
                        </div>
                        <div nz-col [nzSpan]="24"><hr /></div>
                        <div nz-col [nzSpan]="24"><stored-chemical></stored-chemical></div>
                      </div>
                      <div nz-row [nzGutter]="24">
                        <div nz-col nzFlex="0 0 max-content">
                          <button type="button" nz-button nzType="primary" (click)="addChemical()">
                            Добавить АХОВ
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </nz-collapse-panel>
                </nz-collapse>
              </div>
            </div>
          </nz-tab>
          <nz-tab nzTitle="Устройства"> </nz-tab>
          <nz-tab nzTitle="Документы">
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <file-upload formControlName="documents" [title]="'Добавить документы'"></file-upload>
                </nz-form-item>
              </div>
            </div>
          </nz-tab>
        </nz-tabset>
      </nz-content>
      <nz-footer class="empty"></nz-footer>
    </nz-layout>
  </form>
</nz-spin>
