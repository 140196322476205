import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';
import { BgMapService } from '@bg-front/core/services';
import { Observable } from 'rxjs';

import { IChemicalForecastResultsView, ITimeApproachCloudToPointTaskParamDto } from '../../models';
import { ForecastingBlowoutVisualizationService } from '../../services';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

@Component({
  selector: 'bg-time-approach-cloud-to-point-task-result',
  templateUrl: './time-approach-cloud-to-point-task-result.component.html',
  styleUrls: ['./time-approach-cloud-to-point-task-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeApproachCloudToPointTaskResultComponent implements OnInit, OnDestroy {
  /**
   * Режим просмотра.
   * Отображать визуализацию не надо
   * TODO: После реализации формы просмотра убрать
   */
  @Input()
  public isShow = false;

  @Input()
  public forecastingResults: IForecastingResultDto;

  /** Ссылка на результат отрисовки */
  private displayElement: any;

  /** Скачать отчёт */
  @Input()
  public downloadReport: Observable<void>;

  /** Уведомляем о наличии отчёта */
  @Output()
  public hasReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  /** @ignore */
  constructor(
    private readonly gisBgService: BgMapService,
    private readonly forecastingService: ForecastingBlowoutVisualizationService,
  ) {}

  public get result(): IChemicalForecastResultsView {
    return <IChemicalForecastResultsView>{
      ...this.forecastingResults.result,
      timeApproach: dayjs.duration(Math.round(this.forecastingResults.result.timeApproach * 60), 'minutes')
        .format('HH ч mm мин'),
    };
  }

  /**
   * @ignore
   */
  public ngOnInit(): void {
    const params = this.forecastingResults.params.params as ITimeApproachCloudToPointTaskParamDto;

    if (!this.isShow) {
      this.gisBgService.setPositionMapOnCoordinates(params.coordinates);
      this.displayElement = this.forecastingService.displayPoint(params.coordinates);
    }

    this.hasReport.emit(false);
  }

  public ngOnDestroy(): void {
    this.forecastingService.clearResults(this.displayElement);
  }
}
