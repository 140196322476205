import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { MapDialogV2Component } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { ISelectItem } from '@bg-front/core/models/interfaces';
import { WeatherDataService } from '@bg-front/weather-data/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Settings2Service } from '@smart-city/core/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { BaseForecastingRadioactiveEnvironmentComponent } from '../base-forecasting-radioactive-environment/base-forecasting-radioactive-environment.component';

/**
 * Компонент для отображения расчетной задачи: "Расчёт и результатов для оценки степени рад. загрязнения в точке"
 */
@UntilDestroy()
@Component({
  selector: 'bg-nuclear-pollution-point-task',
  templateUrl: './nuclear-pollution-point-task.component.html',
  styleUrls: ['./nuclear-pollution-point-task.component.scss'],
})
export class NuclearPollutionPointTaskComponent
  extends BaseForecastingRadioactiveEnvironmentComponent
  implements OnInit
{
  /** Единицы измерения */
  public units: ISelectItem[] = this.getDictionaryForSelect('unitRadiation');

  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly modalService: NzModalService,
    notificationService: NzNotificationService,
    settings: Settings2Service,
    weatherService: WeatherDataService,
  ) {
    super(settings, weatherService, notificationService);
  }

  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }

  /**
   * Обработчики по клику на кнопку задать координаты
   * @param $event - объект события
   * @return
   */
  public onClickAppointCoordinates($event: Event) {
    const currentCoordinates = this.form.controls['coordinatesTarget'].value || '';
    const splited = currentCoordinates.split(',');
    const data = {
      coordinates: new Coordinates(),
    };
    if (splited[0] && splited[1]) {
      data.coordinates = new Coordinates(Number(splited[0]), Number(splited[1]));
    }
    this.openSpecifyMap(data);
  }

  /** Метод который обеспечивает открытие диалога и подписку на закрытие
   * @param data - параметры для диалога
   */
  public openSpecifyMap(data: { coordinates?: Coordinates }): void {
    const modal = this.modalService.create({
      nzTitle: 'Укажите координату',
      nzContent: MapDialogV2Component,
      nzComponentParams: data,
      nzWidth: '600px',
    });

    let subs = modal.afterClose.pipe(untilDestroyed(this)).subscribe((params: any) => {
      if (params?.coordinates) {
        this.form.controls.coordinatesTarget.setValue(`${params.coordinates.join(', ')}`);
      }

      subs.unsubscribe();
      subs = undefined;
    });
  }
}
