import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { IOrganization } from '../../../../../../app-common/models/interfaces';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RestService } from '@smart-city/core/services';
import { IAbstractServiceData } from 'smart-city-types';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { BaseCrudService } from '@bg-front/core/services';
import { IAttDeviceDto, IVehicle } from '../models/interfaces';
import { IRnisResponse } from '../../../../../../rnis/models/interfaces';
import { RnisService } from '../../../../../../rnis/services';

/** Сервис для работы справочника Транспортные средства */
@Injectable({ providedIn: 'root' })
export class VehiclesService extends BaseCrudService<IVehicle> {
  /** @ignore */
  constructor(
    rest: RestService,
    private readonly rnis: RnisService,
  ) {
    super({
        serviceName: 'Vehicles',
        entityName: 'Dictionary'
      },
      rest);
  }

  /** Получение организаций для селекта */
  public getOrganizationsForSelect(): Observable<IScSelectItem[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          query: { active: true },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items?.map((el: IOrganization) =>
            ({ id: el.id, text: el.name })) || [];
        }),
      );
  }

  /** Получение марок ТС для селекта */
  public getBrandsForSelect(): Observable<IScSelectItem[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Vehicles' },
        entity: {
          name: 'Brands',
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items?.map((el: { id: string, name: string }) =>
            ({ id: el.id, text: el.name })) || [];
        }),
      );
  }

  /** Получение моделей ТС соответствующей марки для селекта
   * @param brandId - id марки авто
   **/
  public getModelsForSelect(brandId: string): Observable<IScSelectItem[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Vehicles' },
        entity: {
          name: 'Models',
          query: {
            brandId,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items?.map((el: { id: string, name: string }) =>
            ({ id: el.id, text: el.name })) || [];
        }),
      );
  }

  /** Получение списка всех абонентских телематических терминалов для селекта из РНИС */
  public getAllDevicesForSelectFromRnis(): Observable<{ attId: string, originalAtt: string, data: IAttDeviceDto }[]> {
    return this.rnis.getAllDevice()
      .pipe(
        map((response: IRnisResponse) => {
          return (response?.result?.devices as IAttDeviceDto[]).map((device: IAttDeviceDto) =>
            ({
              attId: `${ device.uuid }`,
              originalAtt: `${ device.port }.${ device.protocol }.${ device.uuid }`,
              data: device,
            }));
        }),
      );
  }

  /** Получение списка всех абонентских телематических терминалов для селекта из справочника Транспортные средства */
  public getAllDevicesForSelectFromBg(): Observable<{ attId: string, originalAtt: string }[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Vehicles' },
        entity: {
          name: 'Dictionary',
          query: {
            attId: { $ne: null },
          },
          attributes: ['attId', 'originalAtt'],
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => response.data?.items || []),
        catchError(() => of([])),
      );
  }

  /** Получение списка не привязанных к ТС абонентских телематических терминалов для селекта */
  public getUnusedDevicesForSelect(): Observable<{ attId: string, originalAtt: string, data: IAttDeviceDto }[]> {
    return forkJoin([
      this.getAllDevicesForSelectFromRnis(),
      this.rest
        .serviceRequest({
          action: 'select',
          service: { name: 'Vehicles' },
          entity: {
            name: 'Dictionary',
            query: {
              attId: { $ne: null },
            },
            attributes: ['attId'],
          },
        }),
    ])
      .pipe(
        switchMap(([attItems, response]: [{ attId: string, originalAtt: string, data: IAttDeviceDto }[], IAbstractServiceData]) => {
          if (!attItems?.length) return of([]);

          const attDevicesIds = (response?.data?.items || []).map((vehicle: IVehicle) => vehicle.attId);
          const selectItems = attItems.filter((device: { attId: string, originalAtt: string, data: IAttDeviceDto }) =>
            !attDevicesIds.includes(device.attId)
          );
          return of(selectItems);
        }),
      )
  }

  /** Получение подразделения для селекта */
  public getDepartmentsForSelect(): Observable<IOrganization[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          query: { active: true, 'orgTypeParam.isFfsDepartment': true },
          sort: {
            field: 'name',
            direction: 'asc',
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response.data.items || []);
        }),
      );
  }

  /** Получение списка причин для селекта */
  public getReasonsForSelect(): Observable<{ id: string, name: string }[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Reason',
          attributes: ['id', 'name'],
          query: { active: true, 'groupId.sysName': 'fireFightingVehicleDecommissioningReasons' },
          sort: {
            field: 'name',
            direction: 'asc',
          },
        },
      })
      .pipe(map((response: IAbstractServiceData) => response.data.items || []));
  }

  /** Получение списка Вариантов пожарной техники */
  public getRelatedKinds(classId: string): Observable<{ id: string, name: string }[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Directories' },
        entity: {
          name: 'VehicleClassesVehicleKindsLinks',
          attributes: ['kindId.id', 'kindId.name', 'isDividedIntoTypes'],
          query: { classId },
          sort: {
            field: 'name',
            direction: 'asc',
          },
        },
      })
      .pipe(map((response: IAbstractServiceData) => response.data.items[0]?.isDividedIntoTypes
        ? response.data.items.map((item: Record<string, unknown>) => ({ id: item['kindId.id'], name: item['kindId.name'] }))
        : []
      ));
  }
}
