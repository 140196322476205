import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RestService } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import { Observable, of } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { IAbstractServiceData, ISignificantObjectDto, ISurveyAnswerDto, ISurveyQuestionDto } from 'smart-city-types';
import { AppInjector } from '@bg-front/core/models/classes';
import { SurveyQuestionsService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';
import { GarFindFlatResponseElement } from '@bg-front/core';

/** Компонент для отображения блока Дополнительная информация о КСиП в Опросе */
@Component({
  selector: 'bg-survey-ksip',
  templateUrl: './survey-ksip.component.html',
  styleUrls: ['./survey-ksip.component.scss'],
})
export class SurveyKsipComponent extends BaseComponent implements OnInit {
  /** Форма события */
  @Input()
  public eventForm: FormGroup;

  /** Id события */
  @Input()
  public eventId: string;

  /** Обработано или нет событие */
  @Input()
  public isHandled: boolean;

  /** Событие сообщение для родительского компонента о ситуации с формой и наличаи найденных вопрсов */
  @Output()
  public messageEvent = new EventEmitter<string>();

  /** Событие ответа на вопрос */
  @Output()
  public answer = new EventEmitter();

  /** Событие переответа на вопрос */
  @Output()
  public reAnswer = new EventEmitter();

  /** Состояние блока */
  private currentState: 'parametersIsEmpty' | 'questionsNotFound' | 'emptyInfo' | null = null;

  public set state(val) {
    this.currentState = val;
    this.messageEvent.emit(val);
  }

  public get state(): 'parametersIsEmpty' | 'questionsNotFound' | 'emptyInfo' | null {
    return this.currentState;
  }

  /** Список выведенных вопросов */
  public foundQuestions: ISurveyQuestionDto[] = [];

  /** Список ответов */
  public answers: ISurveyAnswerDto[] = [];

  /**
   * Время в секундах, через которое запускается поиск следующего вопроса в опросе,
   * после того, как пользователь закончил отвечать на предыдущий вопрос
   */
  public answerTime: number;

  /** Важные объекты рядом с событием */
  public significantObjects: ISignificantObjectDto[];

  /** Сервис вопросов */
  public readonly surveyQuestionsService: SurveyQuestionsService;

  /** Сервис rest */
  public readonly rest: RestService;

  constructor() {
    super();

    const injector = AppInjector.getInjector();
    this.surveyQuestionsService = injector.get(SurveyQuestionsService);
    this.rest = injector.get(RestService);
  }

  /** @ignore */
  ngOnInit(): void {
    this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Settings',
          query: {
            sysName: 'surveyTimer',
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || [])[0] as any;
        }),
      )
      .subscribe((data) => {
        this.answerTime = +data.value;
      });

    /** Подписка на изменение адреса события в форме события */
    this.eventForm?.controls['factAddress']?.valueChanges
      .pipe(debounceTime(500), takeUntil(this.ngUnsubscribe))
      .subscribe((val: GarFindFlatResponseElement) => {
        this.onAddressChange(val);
      });

    /** Подписка на изменение типа КСиП в форме события */
    this.eventForm?.controls['ksipTypeId']?.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((val: string) => {
        this.onKsipTypeChange(val);
      });

    /** Выборка важных объектов вблизи с координатами события */
    this.getSignificantObjects(this.eventForm?.controls['factAddress'].value).subscribe(
      (data: ISignificantObjectDto[]) => {
        this.significantObjects = data;
        setTimeout(() => {
          this.findQuestions();
        }, 0);
      },
    );

    this.findAnswers();
  }

  /** Обработчик события изменения адрес события */
  public onAddressChange(val?: GarFindFlatResponseElement): void {
    this.getSignificantObjects(this.eventForm?.controls['factAddress'].value).subscribe(
      (data: ISignificantObjectDto[]) => {
        this.significantObjects = data;
        this.findQuestions();
      },
    );
    this.checkStateAndSendMessage();
  }

  /** Обработчик события изменения типа КСиП события */
  public onKsipTypeChange(val?: string): void {
    this.findQuestions();
    this.checkStateAndSendMessage();
  }

  /**
   * Функция проверяет текущие состояние формы события и наличие выводимых вопрсов
   */
  public checkStateAndSendMessage(): void {
    if (
      !this.foundQuestions.length &&
      !this.answers.length &&
      (!this.eventForm?.controls['factAddress']?.value?.fullText || !this.eventForm?.controls['ksipTypeId']?.value) &&
      !this.isHandled
    ) {
      this.state = 'parametersIsEmpty';
    } else if (!this.foundQuestions.length && !this.answers.length && !this.isHandled) {
      this.state = 'questionsNotFound';
    } else if (!this.foundQuestions.length && !this.answers.length && this.isHandled) {
      this.state = 'emptyInfo';
    } else {
      this.state = null;
    }
  }

  /** Поиск вопросов */
  public findQuestions(answerData = undefined): void {
    this.surveyQuestionsService
      .getSurveyQuestions(
        this.eventForm?.controls['ksipTypeId']?.value,
        this.eventForm?.controls['factAddress']?.value?.oktmo,
        this.significantObjects,
        answerData,
      )
      .subscribe((data) => {
        /** Если ответ и вопрос, на который отвечали не передается в функцию,
         * то это новая выборка из-за смены параметров формы. По-этому стираем
         * все найденные ранее вопросы.
         */
        if (!answerData) {
          this.foundQuestions = [];
        }
        this.displayQuestions(this.removeDuplicateQuestions(data));
        this.checkStateAndSendMessage();
      });
  }

  /** Поиск ответов */
  public findAnswers(): void {
    this.surveyQuestionsService.getSurveyAnswers(this.eventId).subscribe((data) => {
      this.answers = data;
      this.checkStateAndSendMessage();
    });
  }

  /** Вывод вопросов */
  public displayQuestions(questions: ISurveyQuestionDto[]): void {
    for (const question of questions) {
      this.foundQuestions.unshift(question);
    }
  }

  /** Обработчик события ответа */
  public onAnswer(answerData): void {
    if (answerData.id) {
      answerData.action = 'update';
      for (let i = 0; i < this.answers.length; i++) {
        if (answerData.id === this.answers[i].id) {
          this.answers[i] = this.getAnswerObjectFromAnswerData(answerData);
        }
      }
      this.reAnswer.emit(this.getAnswerModelFromAnswerData(answerData));
    } else {
      answerData.id = answerData.id ? answerData.id : Uuid.newUuid();
      answerData.action = 'insert';
      this.answers.unshift(this.getAnswerObjectFromAnswerData(answerData));
      this.answer.emit(this.getAnswerModelFromAnswerData(answerData));
    }
    this.deleteQuestions(answerData.question, this.foundQuestions);
    this.findQuestions(answerData);
  }

  /** Получить модель ответа */
  public getAnswerModelFromAnswerData(answerData) {
    return {
      id: answerData.id,
      eventId: this.eventId,
      questionId: answerData.question.id,
      answer: answerData.answer,
      otherAnswer: answerData.otherAnswer,
      order: answerData.order || this.answers.length,
    };
  }

  /** Получить объект ответа */
  public getAnswerObjectFromAnswerData(answerData): ISurveyAnswerDto {
    return {
      id: answerData.id,
      eventId: this.eventId,
      question: answerData.question,
      answer: answerData.answer,
      otherAnswer: answerData.otherAnswer,
      order: answerData.order,
    };
  }

  /** Обработчик события пропуска вопроса */
  public onMiss(answerData): void {
    this.deleteQuestions(answerData.question, this.foundQuestions);
    this.findQuestions(answerData);
  }

  /** Удалить вопрос из массива вопросов*/
  public deleteQuestions(question: ISurveyQuestionDto, questions: ISurveyQuestionDto[]): void {
    for (let i = 0; i < questions.length; i++) {
      if (question.id === questions[i].id) {
        questions.splice(i, 1);
      }
    }
  }

  /** Нахождение важных объектов рядом с событием */
  public getSignificantObjects(address: GarFindFlatResponseElement): Observable<ISignificantObjectDto[]> {
    if (!address || !address.latitude || !address.longitude) {
      return of([]);
    }
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'SignificantObjects',
          query: {
            point: {
              $radius: {
                $lat: address.latitude,
                $lon: address.longitude,
                $meters: 50,
              },
            },
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items as ISignificantObjectDto[];
        }),
      );
  }

  /** Удаление повторяющихся вопросов */
  public removeDuplicateQuestions(questions: ISurveyQuestionDto[]): ISurveyQuestionDto[] {
    let questionsCount = questions.length;
    const questionsIdsArray = this.getQuestionsIdsArray(this.foundQuestions);
    const answeredQuestionsIdsArray = this.getAnsweredQuestionsIdsArray(this.answers);
    for (let i = 0; i < questionsCount; i++) {
      if (questionsIdsArray.includes(questions[i].id) || answeredQuestionsIdsArray.includes(questions[i].id)) {
        questions.splice(i, 1);
        questionsCount = questions.length;
        i--;
      }
    }
    return questions;
  }

  /** Получить массив id вопросов */
  public getQuestionsIdsArray(questions: ISurveyQuestionDto[]): string[] {
    const array: string[] = [];
    for (const question of questions) {
      if (question?.id) {
        array.push(question.id);
      }
    }
    return array;
  }

  /** Получить массив id ответов */
  public getAnsweredQuestionsIdsArray(answers: ISurveyAnswerDto[]): string[] {
    const array: string[] = [];
    for (const answer of answers) {
      if (answer?.question?.id) {
        array.push(answer.question.id);
      }
    }
    return array;
  }
}
