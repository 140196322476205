import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UntilDestroy } from '@ngneat/until-destroy';

import { SubstrateState } from '../../../models/states';
import { SubstratesStore } from '../store/substrates.store';

/**
 * Сервис по работе с хранилищем Организаций
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SubstratesQuery extends QueryEntity<SubstrateState> {
  constructor(store: SubstratesStore) {
    super(store);
  }
}
