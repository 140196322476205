import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAnyObject } from 'smart-city-types';

@Component({
  selector: 'bg-legend-dialog',
  templateUrl: './legend-dialog.component.html',
  styleUrls: ['./legend-dialog.component.scss']
})
export class LegendDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { configurations: IAnyObject[] },
    public dialogRef: MatDialogRef<LegendDialogComponent>,
  ) {
  }

  public close(): void {
    this.dialogRef.close();
  }
}
