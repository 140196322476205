<div *ngIf="state === 'parametersIsEmpty'" class="survey-message">
  <mat-icon>error_outline</mat-icon>
  Для формирования списка вопросов задайте адрес и тип КСиП
</div>
<div *ngIf="state === 'questionsNotFound'"  class="survey-message">
  <mat-icon [style]="{color: '#FF9700'}" class="message-condition-icon">error_outline</mat-icon>
  Для выбранного типа КСиП вопросы не найдены
</div>
<div *ngIf="state === 'emptyInfo'"  class="survey-message">
  <mat-icon>error_outline</mat-icon>
  Не предоставлена
</div>

<ng-container>
  <bg-survey-question
    *ngFor="let question of foundQuestions"
    [question]="question"
    [answerTime]="answerTime"
    (answer)="onAnswer($event)"
    (miss)="onMiss($event)">
  </bg-survey-question>
</ng-container>

<ng-container>
  <bg-survey-answer
    *ngFor="let answer of answers"
    [answer]="answer"
    [isHandled]="isHandled"
    [answerTime]="answerTime"
    (reAnswer)="onAnswer($event)">
  </bg-survey-answer>
</ng-container>
