import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { IScCheckboxOptions, IScInputOptions, IScSelectOptions } from '@smart-city/core/common';
import { takeUntil } from 'rxjs/operators';
import { ISurveyAnswerDto } from 'smart-city-types';
import { IDictionaryModelDto } from 'smart-city-types/interfaces/dictionary-model-dto.interface';
import { AppInjector } from '@bg-front/core/models/classes';
import { SurveyQuestionsService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';
import { IScSelectItem } from '@smart-city/core/interfaces';

/** Компонент для отображения вопроса и вариантов ответа */
@Component({
  selector: 'bg-survey-answer',
  templateUrl: './survey-answer.component.html',
  styleUrls: ['./survey-answer.component.scss'],
})
export class SurveyAnswerComponent extends BaseComponent implements OnInit {
  /** Вопрос */
  @Input()
  public answer: ISurveyAnswerDto;

  /**
   * Время в секундах, через которое запускается поиск следующего вопроса в опросе,
   * после того, как пользователь закончил отвечать на предыдущий вопрос
   */
  @Input()
  public answerTime: number;

  /** Обработано или нет событие */
  @Input()
  public isHandled: boolean;

  /** Событие при ответе */
  @Output()
  public reAnswer = new EventEmitter();

  /** Таймер для окончание ответа */
  private timer;

  /** Форма вопроса */
  public form: FormGroup;

  /** Массив чекбокс контролов для типа ответа с чекбоксами */
  public checkboxesArray: FormArray;

  /** Тип (вид) компонента для ответа */
  public componentType: string;

  /** Сервис вопросов */
  public readonly surveyQuestionsService: SurveyQuestionsService;

  /** Сервис FormBuilder */
  public readonly fb: FormBuilder;

  /** Настройка компоненты Текстовый ввод */
  public textOptions: IScInputOptions;

  /** Настройка компоненты Чекбоксы */
  public checkboxesOptions: IScCheckboxOptions[];

  /** Настройка компоненты Один из селекта */
  public selectOptions: IScSelectOptions;

  /** Настройка компоненты Несколько из селекта */
  public multiSelectOptions: IScSelectOptions;

  /** Настройка компоненты Текстовый ввод другого варианта */
  public otherTextOptions: IScInputOptions;

  constructor() {
    super();

    const injector = AppInjector.getInjector();
    this.surveyQuestionsService = injector.get(SurveyQuestionsService);
    this.fb = injector.get(FormBuilder);
  }

  /** @ignore */
  ngOnInit(): void {
    this.form = this.fb.group({});
    if (this.answer.otherAnswer || this.answer.question.optionOther) {
      this.form.addControl('otherAnswer', this.fb.control(this.answer.otherAnswer));
      this.otherTextOptions = {
        label: 'Ваш вариант ответа',
        maxLength: 165,
        disabled: this.isHandled,
      };
    }

    if (typeof this.answer.question?.answerType === 'object') {
      switch ((this.answer.question?.answerType as IDictionaryModelDto).sysname) {
        case 'textType':
          this.componentType = 'text';
          break;
        case 'oneOfListType':
          if (this.answer.question?.answerOptions?.length < 3) {
            this.componentType = 'radioButtons';
          } else {
            this.componentType = 'select';
          }
          break;
        case 'fewFromListType':
          if (this.answer.question?.answerOptions?.length < 3) {
            this.componentType = 'checkboxes';
          } else {
            this.componentType = 'multiSelect';
          }
          break;
      }
    }

    switch (this.componentType) {
      case 'text':
        this.form.addControl('answer', this.fb.control(this.answer.answer[0]));
        this.textOptions = {
          label: this.answer.question?.text,
          maxLength: 100,
          disabled: this.isHandled,
        };
        break;
      case 'radioButtons':
        const value = (this.answer.answer && this.answer.answer[0]) || 'otherAnswer';
        this.form.addControl('answer', this.fb.control(value));
        break;
      case 'checkboxes':
        this.form.addControl('answer', this.fb.array([]));
        this.checkboxesArray = this.form.controls.answer as FormArray;
        this.answer.question.answerOptions?.forEach((value) => {
          this.checkboxesArray.push(this.fb.control(this.answer.answer?.includes(value)));
        });
        this.checkboxesOptions = [];
        for (let i = 0; i < this.answer.question.answerOptions.length; i++) {
          this.checkboxesOptions.push({
            title: this.answer.question.answerOptions[i] || 'Другое',
            disabled: this.isHandled,
          });
        }
        if (this.answer.otherAnswer || this.answer.question.optionOther) {
          this.checkboxesArray.push(this.fb.control(this.answer.otherAnswer));
          this.checkboxesOptions.push({
            title: 'Другое',
            disabled: this.isHandled,
          });
        }
        break;
      case 'select':
        const valueSelect = (this.answer.answer && this.answer.answer[0]) || 'otherAnswer';
        this.form.addControl('answer', this.fb.control(valueSelect));
        const data: IScSelectItem[] = [];
        for (const answerOption of this.answer.question.answerOptions) {
          data.push({
            id: answerOption,
            text: answerOption,
          });
        }
        if (this.answer.otherAnswer || this.answer.question.optionOther) {
          data.push({
            id: 'otherAnswer',
            text: 'Другое',
          });
        }
        this.selectOptions = {
          data,
          title: this.answer.question?.text,
          value: (this.answer.answer && this.answer.answer[0]) || 'otherAnswer',
          disabled: this.isHandled,
        };
        break;
      case 'multiSelect':
        let valueMultiSelect: string[] = [];
        if (this.answer.answer) {
          valueMultiSelect = this.answer.answer;
        }
        if (this.answer.otherAnswer) {
          valueMultiSelect.push('otherAnswer');
        }
        this.form.addControl('answer', this.fb.control(valueMultiSelect));
        const multiData: IScSelectItem[] = [];
        for (const answerOption of this.answer.question.answerOptions) {
          multiData.push({
            id: answerOption,
            text: answerOption,
          });
        }
        if (this.answer.otherAnswer || this.answer.question.optionOther) {
          multiData.push({
            id: 'otherAnswer',
            text: 'Другое',
          });
        }
        this.multiSelectOptions = {
          title: this.answer.question?.text,
          value: valueMultiSelect,
          data: multiData,
          isMultiple: true,
          disabled: this.isHandled,
        };
        break;
    }

    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      switch (this.componentType) {
        case 'text':
        case 'radioButtons':
        case 'checkboxes':
        case 'select':
        case 'multiSelect':
          if (!this.wasChosenOptionOther() && this.form.controls.otherAnswer) {
            this.form.controls.otherAnswer.setValue(null, { emitEvent: false });
          }

          if (this.hasAnswer()) {
            this.restartAnswerTimer();
          } else {
            this.stopAnswerTimer();
          }

          break;
      }
    });
  }

  /** Остановить таймер для окончание ответа */
  public stopAnswerTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  /** Рестарт таймера для окончание ответа */
  public restartAnswerTimer(): void {
    this.stopAnswerTimer();
    this.timer = setTimeout(() => {
      this.reAnswer.emit(this.getAnswerData());
    }, this.answerTime * 1000);
  }

  /** Сформировать данные ответа */
  public getAnswerData() {
    return {
      id: this.answer.id,
      question: this.answer.question,
      answer: this.getAnswer(),
      otherAnswer: this.form.value.otherAnswer || null,
      order: this.answer.order,
    };
  }

  /** Сформировать ответ (с одним или несколькими вариантами) */
  public getAnswer(): string[] {
    let answer: string[] = [];
    switch (this.componentType) {
      case 'text':
        answer.push(this.form.value.answer);
        break;
      case 'radioButtons':
        answer.push(this.form.value.answer);
        break;
      case 'checkboxes':
        for (let i = 0; i < this.form.value.answer.length; i++) {
          if (this.form.value.answer[i]) {
            answer.push(this.answer.question.answerOptions[i]);
          }
        }
        break;
      case 'select':
        answer.push(this.form.value.answer);
        break;
      case 'multiSelect':
        /** slice используется для копирования массива, чтобы форма не мутировалась далее */
        answer = this.form.value.answer?.slice();
        break;
    }

    for (let i = 0; i < answer.length; i++) {
      if (!answer[i] || answer[i] === 'otherAnswer') {
        answer.splice(i, 1);
      }
    }

    return answer.length ? answer : null;
  }

  /** Проверяет выбран ли вариант "Другой вариант" при ответе */
  public wasChosenOptionOther(): boolean {
    switch (this.componentType) {
      case 'text':
      case 'radioButtons':
      case 'select':
        return this.form.value.answer === 'otherAnswer';
        break;
      case 'checkboxes':
        return this.checkboxesArray?.value[this.checkboxesArray.length - 1] && this.answer.question.optionOther;
        break;
      case 'multiSelect':
        return this.form.value.answer?.includes('otherAnswer');
        break;
    }
  }

  /** Проверяет есть ли какой-либо вариант ответа при ответе */
  public hasAnswer(): boolean {
    switch (this.componentType) {
      case 'text':
      case 'radioButtons':
      case 'select':
        return (
          (this.form.value.answer && !this.wasChosenOptionOther()) ||
          (this.wasChosenOptionOther() && this.form.value.otherAnswer)
        );
        break;
      case 'checkboxes':
        return (
          (this.checkboxesArray?.value?.includes(true) && !this.wasChosenOptionOther()) ||
          (this.wasChosenOptionOther() && this.form.value.otherAnswer)
        );
        break;
      case 'multiSelect':
        return (
          (this.form.value.answer?.length && !this.wasChosenOptionOther()) ||
          (this.wasChosenOptionOther() && this.form.value.otherAnswer)
        );
        break;
    }
  }
}
