import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Сервис для получения данных о состоянии браузера
 */
@Injectable({
  providedIn: 'root',
})
export class BrowserService {

  /**
   * Поток и обсервер состояний активности текущей вкладки браузера,
   * в который можно пушить новые значения. Закрыт от доступа во внешние программе.
   */
  private currentTabIsActiveSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);

  /** Возвращает состояние активности текущей вкладки браузера */
  public get currentTabIsActive(): boolean {
    return !document.hidden;
  }

  /**
   * Поток состояний активности текущей вкладки браузера,
   * на который можно подписаться во внешней программе.
   * При подписке сразу вернет последнее состояние вкладки.
   */
  public currentTabIsActiveObservable: Observable<boolean> = this.currentTabIsActiveSubject.asObservable();

  constructor() {
    this.currentTabIsActiveSubject.next(this.currentTabIsActive);
    document.addEventListener('visibilitychange', () => {
      this.currentTabIsActiveSubject.next(this.currentTabIsActive);
    });
  }
}
