<mat-form-field class="form-field" hideRequiredMarker>
  <mat-label *ngIf="options.label">
    {{ options.label }}
  </mat-label>
  <input
    matInput
    class="form-control"
    type="text"
    [appInputMask]="inputMask"
    mwlFlatpickr
    [(ngModel)]="value"
    [allowInput]="allowInput"
    [enableTime]="enableTime"
    [enableSeconds]="enableSeconds"
    [convertModelValue]="fpConvertModelValue"
    [disabled]="disabled"
    [dateFormat]="dateFormat"
    [minDate]="$any(minDate)"
    [maxDate]="$any(maxDate)"
    (paste)="onPaste($event)"
    (flatpickrChange)="onChangePicker($event)"
    (blur)="onTouched()"
    (input)="onInput($event)"
    [defaultHour]="defaultHour"
    [defaultMinute]="defaultMinute"
    [defaultSeconds]="defaultSecond"
    autocomplete="off"
  />
</mat-form-field>
