import { Component, OnInit } from '@angular/core';
import { BaseIncidentFormComponent } from '../base-incident-form/base-incident-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService, IScSelectOptions, IScTextareaOptions } from '@smart-city/core/common';
import { ILifeCycleStepDto, IEmergencyResolutionDto, IEmergencyEventDto } from 'smart-city-types';
import * as dayjs from 'dayjs';
import { IKsionAggregatedDto, IKsionEventDto } from '../../models/interfaces';
import { UnitServersService } from '../../services';
import { AccessService, RestService, Settings2Service } from '@smart-city/core/services';
import {
  AtmIntegrationService,
  BgAdminService,
  EmergencyService,
  MiniMapService,
  OrganizationsService,
  ResponsePlanStepService,
} from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { MultiFileService } from '@bg-front/core/services';
import { ReportingService } from '../../../bg/modules/reporting/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';

/** Форма для редактирование неисправности КСЭОН */
@UntilDestroy()
@Component({
  selector: 'bg-ksion-fault-edit-form',
  templateUrl: './ksion-fault-edit-form.component.html',
  styleUrls: ['./ksion-fault-edit-form.component.scss']
})
export class KsionFaultEditFormComponent extends BaseIncidentFormComponent implements OnInit {
  /** Настройка селекта Отчёт об устранении неисправности */
  public reportOptions: IScTextareaOptions = {
    label: 'Отчёт об устранении неисправности',
    maxLength: 1000,
    rows: 8,
  };

  /** Настройка селекта Наименование устройства */
  public optionsDeviceName: IScSelectOptions = {
    title: 'Наименование устройства',
    modern: true,
    service: 'Ksion',
    entity: 'Aggregated',
    fieldName: 'name',
    disabled: true,
  };

  /** @ignore */
  constructor(
    private readonly unitService: UnitServersService,
    accessService: AccessService,
    atmIntegrationService: AtmIntegrationService,
    bgAdminService: BgAdminService,
    dialog: MatDialog,
    dialogService: DialogService,
    emergencyService: EmergencyService,
    fb: FormBuilder,
    ksipCategoriesQuery: KsipCategoriesQuery,
    ksipDetailsQuery: KsipDetailsQuery,
    ksipSectionsQuery: KsipSectionsQuery,
    ksipTypesQuery: KsipTypesQuery,
    miniMapService: MiniMapService,
    multiFileService: MultiFileService,
    organizationsService: OrganizationsService,
    reportingService: ReportingService,
    route: ActivatedRoute,
    router: Router,
    settings: Settings2Service,
    significantObjectService: SignificantObjectsService,
    responsePlanStepService: ResponsePlanStepService,
  ) {
    super(
      accessService,
      atmIntegrationService,
      bgAdminService,
      dialog,
      dialogService,
      emergencyService,
      fb,
      ksipCategoriesQuery,
      ksipDetailsQuery,
      ksipSectionsQuery,
      ksipTypesQuery,
      miniMapService,
      multiFileService,
      organizationsService,
      reportingService,
      route,
      router,
      settings,
      significantObjectService,
      responsePlanStepService,
    );
  }

  /** @ignore */
  public ngOnInit(): void {
    this.incidentForm = new FormGroup({
      byCoordinates: new FormControl(this.model.byCoordinates),
      addressFact: new FormControl(this.model.addressFact, [Validators.required]),
      coordinatesAddress: new FormControl(this.model.byCoordinates ? (this.model.addressFact.fullText || this.model.address.fullText) : null),
      incidentTypeId: new FormControl(this.model.incidentTypeId, [Validators.required]),
      comment: new FormControl(this.model.comment),
      description: new FormControl(this.model.description),
      responsible: new FormControl(this.model.responsible || this.settings.currentUser.id),
      report: new FormControl(this.model.resolution?.report),
      deviceName: new FormControl(undefined),
    });

    /** Получение наименования устройства */
    this.unitService.getKsionEvent((<IEmergencyEventDto>this.model.parentEventId).id, ['extId.id']).pipe(untilDestroyed(this))
      .subscribe((event: IKsionEventDto) => {
        this.incidentForm.controls.deviceName.setValue((event.extId as IKsionAggregatedDto)?.id);
      });

    this.eventsGridQuery = { incidentId: this.model.id };

    super.ngOnInit();
    this.headerActionsOptions.title = `Неисправность ${(this.model.number ?? '')}`;
    this.eventsGridOptions.fields = [
      { name: 'id', title: 'ID', disabled: true },
      { name: 'timeCreate', title: 'Время создания', widthColumn: '150px' },
      { name: 'sourceId.name', title: 'Источник' },
      { name: 'creationAuthor.fio', title: 'ФИО заявителя' },
      { name: 'creationAuthor.organizationId.name', title: 'Организация' },
      { name: 'number', title: 'Событие' },
    ];

    this.attributes = (this.model.lifeCycleStepId as ILifeCycleStepDto).params;
    this.initReactPlan();
  }


  /** Получение данных из формы и сохранение в модель */
  public getModelData(): void {
    if (!this.model.address) {
      this.model.address = this.model.byCoordinates
        ? this.addressByCoordinates
        : this.incidentForm.controls['addressFact'].value;
    }
    this.model.byCoordinates = this.incidentForm.controls['byCoordinates'].value;
    this.model.addressFact = this.model.byCoordinates
      ? this.addressByCoordinates
      : this.incidentForm.controls['addressFact'].value;
    if (!this.model.id) {
      this.model.timeCreate = dayjs().unix() * 1000;
      this.model.number = `f-${dayjs(this.model.timeCreate).format('YYYYMMDD-HHmmss')}`;
      this.model.organization = this.settings.currentUser.organizationId.id;
      this.model.createdBy = this.settings.currentUser.id;
    }
    this.model.incidentTypeId = this.incidentForm.controls['incidentTypeId'].value;
    this.model.comment = this.incidentForm.controls['comment'].value;
    this.model.description = this.incidentForm.controls['description'].value;
    this.model.responsible = this.incidentForm.controls['responsible'].value;
    this.model.resolution = this.model.resolution || <IEmergencyResolutionDto>{};
    this.model.resolution.report = this.incidentForm.controls['report'].value;
  }

  /** Обновление формы */
  public updateForm() {
    /** Обновляем заголовок */
    this.generateHeaderBar();
    this.attributes = (this.model.lifeCycleStepId as ILifeCycleStepDto).params;
  }
}
