import { Component, OnInit } from '@angular/core';
import { MonitoringObjectBaseEditFormComponent } from '../monitoring-object-base-edit-form/monitoring-object-base-edit-form.component';
import { IElementButton, IScInputOptions } from '@smart-city/core/common';
import { FormControl } from '@angular/forms';
import { IMonitoringObjectHcsView } from '../../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/models/interfaces';
import { IAbstractServiceData } from 'smart-city-types';
import { IBaseDictionaryData } from '@smart-city/core/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'bg-monitoring-object-tes-edit-form',
  templateUrl: './monitoring-object-tes-edit-form.component.html',
  styleUrls: ['./monitoring-object-tes-edit-form.component.scss'],
})
export class MonitoringObjectTesEditFormComponent extends MonitoringObjectBaseEditFormComponent implements OnInit {

  /** Редактируемая запись */
  public model: IMonitoringObjectHcsView;

  //#region Настройки компонент
  /** Настройки компоненты Состояние */
  public statusOptions: IScInputOptions = {
    label: 'Состояние',
    placeholder: 'Состояние',
    disabled: true,
  };
  /** Настройки компоненты Вид снабжения */
  public supplyTypeOptions: IScInputOptions = {
    label: 'Вид снабжения',
    placeholder: 'Вид снабжения',
    disabled: true,
  };
  /** Настройки компоненты Вид интеграции */
  public integrationTypeOptions: IScInputOptions = {
    label: 'Вид интеграции',
    placeholder: 'Вид интеграции',
    disabled: true,
  };
  /** Настройки компоненты Сервер */
  public serverOptions: IScInputOptions = {
    label: 'Сервер',
    placeholder: 'Сервер',
    disabled: true,
  };
  /** Настройки компоненты Организация */
  public organizationOptions: IScInputOptions = {
    label: 'Организация',
    placeholder: 'Организация',
    disabled: true,
  };
  /** Настройки компоненты Состояние плановых работ */
  public routineMaintenanceStatusOptions: IScInputOptions = {
    label: 'Состояние',
    placeholder: 'Состояние',
    disabled: true,
  };
  /** Настройки компоненты Внешний идентификатор плановых работ */
  public routineMaintenanceExtIdOptions: IScInputOptions = {
    label: 'Внешний идентификатор',
    placeholder: 'Внешний идентификатор',
    disabled: true,
  };
  /** Настройки компоненты Дата и время начала плановых работ */
  public routineMaintenanceStartTimeOptions: IScInputOptions = {
    label: 'Дата и время начала',
    placeholder: 'Дата и время начала',
    disabled: true,
  };
  /** Настройки компоненты Планируемая дата и время завершения плановых работ */
  public routineMaintenancePlannedFinishTimeOptions: IScInputOptions = {
    label: 'Планируемая дата и время завершения',
    placeholder: 'Планируемая дата и время завершения',
    disabled: true,
  };
  /** Настройки компоненты Дата и время последнего изменения плановых работ */
  public routineMaintenanceLastUpdateTimeOptions: IScInputOptions = {
    label: 'Дата и время последнего изменения',
    placeholder: 'Дата и время последнего изменения',
    disabled: true,
  };
  /** Настройки компоненты Фактическая дата и время завершения плановых работ */
  public routineMaintenanceFactFinishTimeOptions: IScInputOptions = {
    label: 'Фактическая дата и время завершения',
    placeholder: 'Фактическая дата и время завершения',
    disabled: true,
  };
  /** Настройки компоненты Пользователь, проводивший изменения плановых работ */
  public routineMaintenanceUserOptions: IScInputOptions = {
    label: 'Пользователь, проводивший изменения',
    placeholder: 'Пользователь, проводивший изменения',
    disabled: true,
  };
  /** Настройки компоненты Описание работ плановых работ */
  public routineMaintenanceDescriptionOptions: IScInputOptions = {
    label: 'Описание работ',
    placeholder: 'Описание работ',
    disabled: true,
  };
  //#endregion

  /** @ignore */
  public override ngOnInit(): void {
    super.ngOnInit();

    if ((<IBaseDictionaryData>this.model.supplyTypeId)?.id) {
      this.hcsObjectKindIdOptions.query = {
        supplyTypeId: (<IBaseDictionaryData>this.model.supplyTypeId)?.id,
      }
    } else {
      this.form.controls.hcsObjectKindId.disable();
      this.hcsObjectKindIdOptions.disabled = true;
    }

    this.form.addControl('status', new FormControl(this.model.status));
    this.form.addControl('supplyType', new FormControl((<IBaseDictionaryData>this.model.supplyTypeId)?.name));
    this.form.addControl('integrationType', new FormControl(this.model.integrationType));
    this.form.addControl('server', new FormControl(this.model.server));
    this.form.addControl('organization', new FormControl(this.model.organization));
    this.form.addControl('routineMaintenanceStatus', new FormControl(this.model.routineMaintenanceStatus));
    this.form.addControl('routineMaintenanceExtId', new FormControl(this.model.routineMaintenanceExtId));
    this.form.addControl('routineMaintenanceStartTime', new FormControl(this.model.routineMaintenanceStartTime));
    this.form.addControl('routineMaintenancePlannedFinishTime', new FormControl(this.model.routineMaintenancePlannedFinishTime));
    this.form.addControl('routineMaintenanceLastUpdateTime', new FormControl(this.model.routineMaintenanceLastUpdateTime));
    this.form.addControl('routineMaintenanceFactFinishTime', new FormControl(this.model.routineMaintenanceFactFinishTime));
    this.form.addControl('routineMaintenanceUser', new FormControl(this.model.routineMaintenanceUser));
    this.form.addControl('routineMaintenanceDescription', new FormControl(this.model.routineMaintenanceDescription));
  }

  /**
   * Обрабатываем нажатие кнопок в заголовке
   * @param $event - информация о нажатой кнопке
   */
  public onClickActionsButton($event: IElementButton) {
    super.onClickActionsButton($event);

    if (this.form.invalid || $event.options.name === 'cancel') return;

    this.service
      .save({
        id: this.model.id,
        showOnMap: this.model.showOnMap,
        hcsObjectKindId: this.model.hcsObjectKindId,
        hcsObjectTypeId: this.model.hcsObjectTypeId,
        operatingOrganizationId: this.model.operatingOrganizationId,
        ownerOrganizationId: this.model.ownerOrganizationId,
      })
      .pipe(
        catchError((error: Error) =>
          this.catchErrorFn<IAbstractServiceData>(error, 'Ошибка при сохранении записи'),
        ),
        untilDestroyed(this),
      )
      .subscribe((data: IAbstractServiceData) => {
        if (data) {
          this.router.navigate(['dictionaries/monitoring-objects-hcs']);
        }
      });
  }
}
