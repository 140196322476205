import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IMiniCardSelectionEvent } from '@bg-front/core/models/interfaces';
import { MultiFileService, OperationsService } from '@bg-front/core/services';
import { BaseRegistryComponent } from '@bg-front/registry-panel/components';
import { IRegistryPanelDto } from '@bg-front/registry-panel/models';
import { IElementButton } from '@smart-city/core/common';
import { SfsService } from '@smart-city/core/services';
import * as dayjs from 'dayjs';
import { forkJoin, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { IAnyObject, IEmergencyDto } from 'smart-city-types';

import { RegimesService } from '../../../services';
import { RegimesEmergenciesDialogComponent } from '../regimes-emergencies-dialog/regimes-emergencies-dialog.component';

/**
 * Реестр режимов функционирования
 */
@Component({
  selector: 'app-regimes-registry-panel',
  templateUrl: './regimes-registry-panel.component.html',
  styleUrls: ['./regimes-registry-panel.component.scss'],
  providers: [OperationsService],
})
export class RegimesRegistryPanelComponent extends BaseRegistryComponent implements OnInit, OnDestroy {
  /** Режимы функционирования */
  public regimes: any;

  constructor(
    route: ActivatedRoute,
    router: Router,
    private regimesService: RegimesService,
    private multiFileService: MultiFileService,
    private sfs: SfsService,
    private dialog: MatDialog,
    operationsService: OperationsService,
  ) {
    super(route, router, operationsService);
  }

  /** @ignore */
  public ngOnInit(): void {
    this.registry = {
      name: 'Режимы функционирования',
    } as IRegistryPanelDto;

    super.ngOnInit();

    this.regimesService
      .getRegimeForRegistryPanel()
      .pipe(
        switchMap((regimes) => {
          return forkJoin(
            regimes.map((regime) =>
              forkJoin([
                of(regime),
                this.multiFileService.getFilesFromSfs(regime.introducingReason),
                this.regimesService.getEmergenciesByRegimeId(regime.id),
              ]),
            ),
          );
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((regimes) => {
        this.regimes = regimes as any;
        this.regimes = this.regimes.map((regime) => {
          regime[0].introducingTime = dayjs(regime[0].introducingTime).format('DD.MM.YYYY HH:mm');
          regime[2].forEach(
            (item: IAnyObject) => (item.timeCreate = dayjs(item.timeCreate).format('DD.MM.YYYY HH:mm')),
          );
          return regime;
        });
      });

    /** Подписка на выбор карточки режима в реестре */
    this.regimesService.regimeSelectItem$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((regime: IMiniCardSelectionEvent) => {
        this.router.navigate([{ outlets: { leftPopup: ['emergencyRegime', regime.id] } }], {
          relativeTo: this.route.parent,
          queryParamsHandling: 'merge',
        });
      });
  }

  /**
   * Обработка событий из заголовка
   * @param $event событие
   */
  public override clickHeaderButton($event: IElementButton): void {
    this.router.navigate([{ outlets: { leftSidebar: ['all'] } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }

  public regimeClick(event: Event, id: string) {
    if (!event.defaultPrevented) {
      this.regimesService.selectRegime({ id, docType: null });
    }
  }

  /** Скачивание файла */
  public download(event: Event, uuid: string): void {
    event.preventDefault();
    this.sfs.directDownload(uuid);
  }

  /** Открыть диалоговое окно со связанными инцидентами */
  public showLinkedEmergencies(event: Event, emergencies: IEmergencyDto[]): void {
    event.preventDefault();

    this.dialog.open(RegimesEmergenciesDialogComponent, {
      disableClose: false,
      width: '914px',
      height: '80vh',
      data: { emergencies: emergencies },
    });
  }
}
