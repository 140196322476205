import { IBaseEntity, IEntityOptions, IResponseWithTotal } from '@bg-front/core/models/interfaces';
import { ILimit, ISort, RestService } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';

/**
 * Базовый класс с реализацией всех CRUD операций
 * K - тип сущности, хранящейся в БД
 */
export abstract class BaseCrudService<K extends IBaseEntity = IBaseEntity> {
  /** Название сервиса в БД */
  protected readonly serviceName: string = '';

  /** Название сущности в БД */
  protected readonly entityName: string = '';

  /** Название системы */
  protected readonly systemName: string = '';

  /**
   *
   * @param options настройки сущности в БД
   * @param restService сервис для работы с БД
   */
  constructor(options: IEntityOptions, protected readonly rest: RestService) {
    this.serviceName = options.serviceName;
    this.entityName = options.entityName;
    this.systemName = options.systemName;
  }

  /**
   *
   * @param query запрос
   * @param pageIndex номер страницы
   * @param pageSize кол-во записей на странице
   * @param sort объект сортировки с сортируемым полем и направлением сортировки
   * @param attributes поля сущности, которые будут получены из БД
   * @returns объект ответа с кол-вом всех записей и записями для текущей страницы
   */
  public getPageItems<T = K>(
    query: IAnyObject,
    pageIndex: number,
    pageSize: number,
    sort?: ISort,
    attributes?: string[],
  ): Observable<IResponseWithTotal<T[]>> {
    let limit: ILimit = undefined;
    if (pageIndex || pageSize) {
      limit = {
        paNumber: pageIndex ?? 1,
        paSize: pageSize ?? 15,
      };
    }
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: this.serviceName },
        system: this.systemName ? { name: this.systemName } : undefined,
        entity: {
          attributes,
          query,
          name: this.entityName,
        },
        data: {
          isNeedTotal: true,
          limit,
          sort,
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return {
            items: response.data.items || [],
            totalCount: response.data.totalCount ?? 0,
          };
        }),
      );
  }

  /**
   *
   * @param id идентификатор получаемой записи
   * @param attributes поля сущности, которые будут получены из БД
   * @returns объект сущности
   */
  public getById<T = K>(id: string, attributes?: string[]): Observable<T> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: this.serviceName },
        system: this.systemName ? { name: this.systemName } : undefined,
        entity: {
          attributes,
          query: {
            id,
          },
          name: this.entityName,
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || [])[0] as T;
        }),
      );
  }

  /**
   *
   * @param ids идектификатор(ы) удаляемых записей
   * @returns
   */
  public delete(ids: string | string[]): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: this.serviceName },
      system: this.systemName ? { name: this.systemName } : undefined,
      entity: {
        query: {
          id: Array.isArray(ids)
            ? {
                $in: ids,
              }
            : ids,
        },
        name: this.entityName,
      },
    });
  }

  /**
   *
   * @param data объект сущности
   * @returns
   */
  public save(data: K): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'upsert',
      service: { name: this.serviceName },
      system: this.systemName ? { name: this.systemName } : undefined,
      entity: {
        query: { id: data.id || Uuid.newUuid() },
        name: this.entityName,
      },
      data,
    });
  }
}
