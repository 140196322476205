<form nz-form [nzLayout]="'vertical'">
  <h4>{{ title }}</h4>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired>Тип файла</nz-form-label>
        <nz-form-control>
          <nz-select nzShowSearch [(ngModel)]="fileType" nzSize="large" [ngModelOptions]="{standalone: true}">
            <nz-option
              [nzValue]="type.id"
              [nzLabel]="type.text"
              *ngFor="let type of fileTypeDict"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button (click)="onCloseHandler()">Отмена</button>
    <button nz-button [nzType]="'primary'" (click)="onExportHandler()" [disabled]="!fileType">Экспорт</button>
  </div>
</form>

