import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Coordinates } from '@bg-front/core/models/classes';
import { GEOLOCATION_MARKER_SVG } from '@bg-front/core/models/constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IGridTableOptions,
  INwForm,
  IScCheckboxOptions,
  IScFias3Options,
  IScInputOptions,
  IScSelectOptions,
  IScTextButtonOptions,
} from '@smart-city/core/common';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import { IMapBaseEvent, IMapBaseIcon, IMapBaseInitOptions, MapBaseModel, MapBaseService } from '@smart-city/maps/sc';
import { of } from 'rxjs';
import { IAdminMunicipalSchemaDto, IAnyObject } from 'smart-city-types';

import { MonitoringObjectHcsService } from '../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/services';
import { IPassportOfficeDataDto } from '../../../bg/modules/external-interactions/modules/passport-office-data/interfaces';
import { EmergencyDto } from '../../models/classes';
import { IAddressDetailsDto } from '../../models/interfaces';
import { PassportOfficeIntegrationService } from '../../services';
import { IMapBaseBaseUrlsOptions } from '@bg-front/map/models/interfaces';
import { ISubstrateDto } from '@bg-front/core/models/interfaces';
import { SubstratesQuery } from '@bg-front/core/services';
import { GarFindFlatResponseElement } from '@bg-front/core';

/** Компонент формы добавления детализации по адресам */
@UntilDestroy()
@Component({
  selector: 'bg-address-details-add-dialog',
  templateUrl: './address-details-add-dialog.component.html',
  styleUrls: ['./address-details-add-dialog.component.scss'],
})
export class AddressDetailsAddDialogComponent implements OnInit {
  /** Форма */
  public form: FormGroup;
  public manuallyForm: FormGroup;
  public pointOnMap: FormGroup;
  /** Метод добавления */
  public additionMethodName: string;
  /** Признак если адрес задан координатами */
  public byCoordinates: boolean;
  /** Грид */
  public grid;
  public searchForm: INwForm;
  /** Опции грида */
  public gridOptions: IGridTableOptions = {};

  /** Настройки чекбокса переключения на адрес по координатам */
  public byCoordinatesOptions: IScCheckboxOptions = {
    title: 'Адрес задан координатами',
  };

  /** Настройки кнопки копировать адрес */
  public copyAddressOptions: IScTextButtonOptions = <IScTextButtonOptions>{
    title: 'Адрес',
    color: 'primary',
  };

  /** Настройка компоненты фактического адреса */
  public addressOptions: IScFias3Options = {
    label: 'Адрес *',
    width: '100%',
    isCoordinatesNecessary: true,
  };

  /** Настройка компоненты фактического адреса заданного по координатам */
  public coordinatesAddressOptions: IScInputOptions = {
    label: 'Адрес *',
    maxLength: 100,
  };

  /** RegExp для валидации координат */
  public coordinatesPattern: RegExp = /(-?\d{1,2}[.]\d+)[,][ ]+(-?\d{1,3}[.]\d+)/;

  /** Настройка селекта Метод добавления адреса */
  public additionAddressMethodOptions: IScSelectOptions = {
    title: 'Метод добавления адреса',
    data: this.settings.getDictForSelect('additionAddressMethod'),
    clearable: true,
    required: true,
  };

  /** Настройка селекта Вид здания */
  public buildingKindOptions: IScSelectOptions = {
    title: 'Вид здания',
    data: this.settings.getDictForSelect('buildingKind'),
    clearable: true,
  };

  /** Настройка инпутов - количество граждан */
  public totalOptions: IScInputOptions = {
    label: 'Всего',
    type: 'number',
    autocomplete: false,
  };

  public menOptions: IScInputOptions = {
    label: 'в т.ч. мужчин',
    type: 'number',
    autocomplete: false,
  };

  public womanOptions: IScInputOptions = {
    label: 'в т.ч. женщин',
    type: 'number',
    autocomplete: false,
  };

  public pensionersOptions: IScInputOptions = {
    label: 'в т.ч. пенсионеров',
    type: 'number',
    autocomplete: false,
  };

  public childrenOptions: IScInputOptions = {
    label: 'в т.ч. детей',
    type: 'number',
    autocomplete: false,
  };

  /**
   * Настройка компоненты Координаты
   */
  public optionsCoordinates: IScInputOptions = {
    label: 'Координаты',
    readonly: true,
  };

  /** Настройка карты */
  public mapOptions = <IMapBaseInitOptions>{};

  /** Модель карты */
  private mapModel: MapBaseModel;
  /** Иконка для точки поинта */
  private pointIcon: IMapBaseIcon;

  private points: Array<[number, number]> = [];

  /** Флаг создания поинтов */
  public startCreatePoints: boolean = false;
  /** Флаг видимости кнопки Получить данные паспортного стола */
  public getPassportIntegrationDataFlag: boolean = false;
  /** Id происшествия */
  public emergencyId: string;

  constructor(
    private readonly settings: Settings2Service,
    private readonly mapService: MapBaseService,
    private readonly service: MonitoringObjectHcsService,
    private readonly passportService: PassportOfficeIntegrationService,
    private readonly accessService: AccessService,
    private readonly substratesQuery: SubstratesQuery,
    public dialogRef: MatDialogRef<AddressDetailsAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isOrder: boolean;
      emergency: EmergencyDto;
      address: GarFindFlatResponseElement;
      addressDetails: IAddressDetailsDto[];
      visualizationMethod: string;
    },
  ) {}

  public ngOnInit(): void {
    this.getPassportIntegrationDataFlag = this.accessService.accessMap['showPassportDataGetButton']?.visible;
    this.form = new FormGroup({
      additionAddressMethod: new FormControl(undefined, [Validators.required]),
    });
    this.emergencyId = this.data.isOrder ? this.data.emergency.parentId['id'] : this.data.emergency.id;

    this.manuallyForm = new FormGroup({
      byCoordinates: new FormControl(false),
      address: new FormControl(undefined, [Validators.required]),
      coordinatesAddress: new FormControl(),
      buildingKindId: new FormControl(),
      totalRegistered: new FormControl(),
      menRegistered: new FormControl(),
      womanRegistered: new FormControl(),
      pensionersRegistered: new FormControl(),
      childrenRegistered: new FormControl(),
      totalResidents: new FormControl(),
      menResidents: new FormControl(),
      womanResidents: new FormControl(),
      pensionersResidents: new FormControl(),
      childrenResidents: new FormControl(),
    });

    this.pointOnMap = new FormGroup({
      coordinates: new FormControl(),
    });

    this.form.controls.additionAddressMethod.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      const dict = this.settings.getDictionaryByTypeSysName('additionAddressMethod');
      this.additionMethodName = value ? dict.find((item: IDictionaryInfo) => item.id === value).sysname : null;
      this.manuallyForm.reset();

      if (this.additionMethodName === 'pointOnMap') {
        this.initMap();
      }

      if (this.additionMethodName === 'selectFromJkhObject') {
        this.initGrid();
      }
    });

    this.manuallyForm.controls.byCoordinates.valueChanges.pipe(untilDestroyed(this)).subscribe((value: boolean) => {
      this.byCoordinates = value;
      const fiasAddressControl = this.manuallyForm.controls.address;
      const coordinatesAddressControl = this.manuallyForm.controls.coordinatesAddress;

      fiasAddressControl.reset();
      if (value) {
        fiasAddressControl.clearValidators();
        coordinatesAddressControl.setValidators([Validators.required, Validators.pattern(this.coordinatesPattern)]);
      } else {
        coordinatesAddressControl.reset();
        coordinatesAddressControl.clearValidators();
        fiasAddressControl.setValidators(Validators.required);
      }
      fiasAddressControl.updateValueAndValidity();
      coordinatesAddressControl.updateValueAndValidity();
    });
  }

  /** Получить данные паспортного стола */
  public getPassportIntegrationData(): void {
    this.clearRegisteredData();

    if (this.manuallyForm.controls.coordinatesAddress.valid) {
      const coordinates = Coordinates.coordinatesToArray(this.manuallyForm.controls.coordinatesAddress.value);
      this.passportService
        .getPassportOfficeByCoordinates(coordinates)
        .pipe(untilDestroyed(this))
        .subscribe((data: IPassportOfficeDataDto[]) => {
          if (!data.length) return;
          const totalRegistered = data[0].totalRegistryPeopleHouse;
          const totalResidents = data[0].totalTemporalPeopleHouse;
          const individualOwnership = data[0].individualOwnership;
          totalRegistered ? this.manuallyForm.controls.totalRegistered.patchValue(totalRegistered) : null;
          totalResidents ? this.manuallyForm.controls.totalResidents.patchValue(totalResidents) : null;

          const buildingKindDictionary = this.settings.getDictionaryByTypeSysName('buildingKind');
          if (individualOwnership) {
            const individualHouseOwnershipId = buildingKindDictionary.find(
              (item: IDictionaryInfo) => item.sysname === 'individualHouseOwnership',
            )?.id;
            this.manuallyForm.controls.buildingKindId.patchValue(individualHouseOwnershipId);
          } else {
            const apartmentBuildingId = buildingKindDictionary.find(
              (item: IDictionaryInfo) => item.sysname === 'apartmentBuilding',
            )?.id;
            this.manuallyForm.controls.buildingKindId.patchValue(apartmentBuildingId);
          }
        });
    }
  }

  /**
   * Обработчик по клику скопировать адресс
   * @return
   */
  public copyHandler(): void {
    const address: GarFindFlatResponseElement = this.data.address;

    if (this.byCoordinates && address?.latitude && address?.longitude) {
      this.manuallyForm.controls.coordinatesAddress.setValue(`${address.latitude}, ${address.longitude}`);
    }
    if (!this.byCoordinates) {
      this.manuallyForm.controls.address.setValue(address);
    }
  }

  /** Закрытие формы */
  public close(): void {
    this.dialogRef.close();
  }

  /** Сохранение адреса */
  public save(): void {
    switch (this.additionMethodName) {
      case 'manually':
        {
          if (this.manuallyForm.invalid) return;
          const coordinatesAddressValue = this.manuallyForm.controls.coordinatesAddress.value;
          const coordinatesAddress: number[] = Coordinates.coordinatesToArray(coordinatesAddressValue) || [];
          const addressByCoordinates: GarFindFlatResponseElement = {
            fullText: coordinatesAddressValue,
            latitude: coordinatesAddress[0],
            longitude: coordinatesAddress[1],
          };

          const addressDetails: IAddressDetailsDto = {
            emergencyId: this.emergencyId,
            address: this.byCoordinates ? addressByCoordinates : this.manuallyForm.controls.address.value,
            numberOfRegistered: {
              total: Number(this.manuallyForm.controls.totalRegistered.value),
              men: Number(this.manuallyForm.controls.menRegistered.value),
              woman: Number(this.manuallyForm.controls.womanRegistered.value),
              pensioners: Number(this.manuallyForm.controls.pensionersRegistered.value),
              children: Number(this.manuallyForm.controls.childrenRegistered.value),
            },
            numberOfResidents: {
              total: Number(this.manuallyForm.controls.totalResidents.value),
              men: Number(this.manuallyForm.controls.menResidents.value),
              woman: Number(this.manuallyForm.controls.womanResidents.value),
              pensioners: Number(this.manuallyForm.controls.pensionersResidents.value),
              children: Number(this.manuallyForm.controls.childrenResidents.value),
            },
            buildingKindId: this.manuallyForm.controls.buildingKindId.value,
            byCoordinates: this.manuallyForm.controls.byCoordinates.value,
          };

          this.dialogRef.close(addressDetails);
        }
        break;
      case 'pointOnMap':
        {
          const addressDetails = this.points.map((point: [number, number]) => ({
            emergencyId: this.emergencyId,
            byCoordinates: true,
            address: {
              fullText: point.join(', '),
              latitude: point[0],
              longitude: point[1],
            },
          }));

          if (this.points.length) {
            this.dialogRef.close(addressDetails);
          }
        }
        break;

      case 'selectFromJkhObject':
        {
          const selectedAddresses: string[] = Object.keys(this.grid.checkedRows);
          if (selectedAddresses.length) {
            this.service
              .getOwnedAddressesByQuery({ id: { $in: selectedAddresses } })
              .pipe(untilDestroyed(this))
              .subscribe((data: GarFindFlatResponseElement[]) => {
                const addressDetails = data.map((item: GarFindFlatResponseElement) => ({
                  emergencyId: this.emergencyId,
                  address: item,
                }));

                this.dialogRef.close(addressDetails);
              });
          }
        }
        break;
    }
  }

  /** Инициализация карты */
  private initMap(): void {
    this.pointIcon = this.mapService.makeIcon({
      iconSize: 24,
      iconColor: 'black',
      iconSVG: GEOLOCATION_MARKER_SVG,
      shapeType: 'Нет',
      shapeSize: 24,
    });

    const defaultCenterCoordinates: string =
      this.data.address.latitude && this.data.address.longitude
        ? this.data.address.latitude + ' ,' + this.data.address.longitude
        : this.settings.allMo.find(
            (mo: IAdminMunicipalSchemaDto) => mo.id === this.settings.currentUser.organizationId.mo,
          ).coordinates;

    const transformSubstratesUrl: (url: string) => string = (url: string): string => {
      return url.startsWith('/', 0) ? `//${window.location.host}${url}` : `//${url}`;
    };

    const substrates = this.substratesQuery.getAll();
    const activeSubstrate = (this.substratesQuery.getActive() as ISubstrateDto) || substrates?.[0];

    const mapId = Uuid.newUuid() as any;
    this.mapModel = new MapBaseModel(mapId, this.mapService);
    this.mapOptions = {
      mapId,
      url: substrates?.length > 1 ? undefined : transformSubstratesUrl(activeSubstrate.link),
      urls:
        substrates?.length > 1
          ? substrates.map((el: ISubstrateDto): IMapBaseBaseUrlsOptions => {
              return <IMapBaseBaseUrlsOptions>{
                url: transformSubstratesUrl(el.link),
                name: el.name,
                attribution: el.attribution,
                selected: activeSubstrate ? el.id === activeSubstrate.id : el.default,
              };
            })
          : undefined,
      maxZoom: 18,
      zoom: this.settings.getConfig().defaultZoom,
      center: new Coordinates(defaultCenterCoordinates ?? this.settings.getConfig().defaultCoordinates)?.toArray(),
      mapStyle: { width: '100%', height: '100%' },
    };
    this.mapOptions['attribution'] = substrates?.length > 1 ? undefined : activeSubstrate.attribution;

    this.mapModel
      .getObservableMapEvents()
      .pipe(untilDestroyed(this))
      .subscribe((event: IMapBaseEvent) => {
        switch (event.typeEvent) {
          case 'mapClick':
            {
              if (event?.coordinates && this.startCreatePoints) {
                this.points.push(event.coordinates);
                this.reDrawPoints();
              }
            }
            break;
          case 'mapReady': {
            this.reDrawPoints();
          }
        }
      });
  }

  /** Перерисовать поинты */
  public reDrawPoints(): void {
    this.mapModel.removeLayer('lines');

    const addressDetails: Array<[number, number]> = this.data.addressDetails
      .map((item: IAnyObject) => Coordinates.coordinatesToArray(item.coordinates))
      .filter((point: [number, number]) => point[0] && point[1]);

    addressDetails.map((point: [number, number], index: number) => {
      this.mapModel.addMarkerToLayer('details', String(index), point, this.pointIcon);
    });

    this.points.map((point: [number, number], index: number) => {
      this.mapModel.addMarkerToLayer('lines', String(index), point, this.pointIcon);
    });

    const visualizationMethodName = this.settings
      .getDictionaryByTypeSysName('areaVisualizationMethod')
      .find((item: IDictionaryInfo) => item.id === this.data.visualizationMethod)?.sysname;

    if (visualizationMethodName === 'line') {
      this.mapModel.addPolylineToLayer('lines', 'lines', [...addressDetails, ...this.points], {
        color: 'black',
        weight: 1,
      });
    }

    this.mapModel.viewLayer('lines', false);
    this.mapModel.viewLayer('details', false);

    const coordinatesArray = [...addressDetails, ...this.points]
      .map((point: [number, number]) => `${point[0]}, ${point[1]}`)
      .join(';');
    this.pointOnMap.controls.coordinates.patchValue(coordinatesArray);
  }

  /** Обработчик кнопки редактирования поинтов */
  public editPoints(): void {
    this.startCreatePoints = true;
    this.mapOptions.mapStyle.cursor = 'crosshair';
  }

  /** Обработчик кнопки сохранить */
  public savePolygon() {
    this.startCreatePoints = false;
    this.mapOptions.mapStyle.cursor = 'grab';
  }

  /** Обработчик кнопки отменить */
  public cancelPoints(): void {
    if (this.points.length > 1) {
      this.points.pop();
      this.reDrawPoints();
    } else {
      this.startCreatePoints = false;
      this.mapOptions.mapStyle.cursor = 'grab';
      this.clearPoints();
    }
  }

  /** Очищаем поинты */
  public clearPoints() {
    this.points = [];
    this.pointOnMap.controls.coordinates.setValue(undefined);
    this.mapModel.removeLayer('lines');
  }

  /** Инициализация грида с адресами объекта мониторинга жкх */
  public initGrid(): void {
    /** Форма поиска */
    this.searchForm = {
      type: 'nw-form',
      options: {
        name: 'search',
        title: 'Поиск',
        layoutForm: {
          layout: {
            background: 'white',
            columns: [
              {
                width: '100%',
                elements: [
                  {
                    type: 'input',
                    options: {
                      name: 'search',
                      placeholder: 'Поиск',
                    },
                  },
                  {
                    type: 'button',
                    options: {
                      name: 'search',
                      title: 'Искать',
                      stdBehavior: true,
                      color: 'primary',
                    },
                  },
                  {
                    type: 'button',
                    options: {
                      name: 'cancel',
                      title: 'Отмена',
                      stdBehavior: true,
                    },
                  },
                ],
              },
            ],
          },
        },
      },
    };
    if (this.data.emergency.jkhObject) {
      this.service
        .getHcsMonitoringObject(this.data.emergency.jkhObject)
        .pipe(untilDestroyed(this))
        .subscribe((object: IAnyObject) => {
          this.gridOptions.title = object.name;
          this.gridOptions.forms.push(this.searchForm);
        });
    }

    this.gridOptions = <IGridTableOptions>{
      height: '100%',
      restVersion: 'modern',
      service: { name: 'Admin' },
      entity: 'MonitoringObjectAddressOwnership',
      query: { monitoringObjectId: this.data.emergency.jkhObject },
      sort: {
        field: 'address.fullText',
        direction: 'asc',
      },
      showTotalRecords: true,
      allowSelectCheckbox: true,
      allowSelect: true,
      isInfiniteScroll: false,
      buildQueryFn: (value: IAnyObject, grid) => {
        this.grid = grid;
        return of(value);
      },
      deleteFn: () => of(null),
      controls: [
        {
          name: 'reload',
          icon: 'refresh',
          hint: 'Обновить',
          callback: () => {
            return of({ actionStream: 'reload' });
          },
        },
        {
          name: 'search',
          icon: 'search',
          hint: 'Поиск',
        },
      ],
      fields: [{ name: 'address.fullText', title: 'Адрес' }],
      forms: [this.searchForm],
    };
  }

  // Сброс старых значений формы регистрационных данных
  public clearRegisteredData(): void {
    // Костыль для очистки значения селекта после ресета
    this.manuallyForm.controls.buildingKindId.disable();
    this.manuallyForm.controls.buildingKindId.enable();
    Object.keys(this.manuallyForm.controls).forEach((field: string) => {
      if (field === 'byCoordinates' || field === 'address' || field === 'coordinatesAddress') return;
      this.manuallyForm.controls[field].reset();
    });
  }
}
