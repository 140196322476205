<div class="filter-container" [formGroup]="formGroup">
  <div class="buttons-filter">
    <div class="buttons-filter__button-panel">
      <button *ngFor="let button of buttons"
              class="buttons-filter__element"
              [ngClass]="{ 'buttons-filter__element_active': getState(button.sysname) }"
              (click)="selectFilter(button.sysname)"
      >
        {{button.name}}
      </button>
      <button class="buttons-filter__icon" *ngIf="!showAllFilters">
        <i class="sc-icon-three-dots"></i>
      </button>
    </div>
    <div class="buttons-filter__button-icon">
      <button
        class="buttons-filter__icon"
        [ngClass]="{ 'buttons-filter__icon_active': showAllFilters }"
        (click)="hide()"
      >
        <i class="sc-icon-filter"></i>
      </button>
    </div>
  </div>
  <div class="buttons-filter" *ngIf="showAllFilters">
    <div class="buttons-filter__button-panel">
      <button *ngFor="let button of hiddenButtons"
              class="buttons-filter__element"
              [ngClass]="{ 'buttons-filter__element_active': getState(button.sysname) }"
              (click)="selectFilter(button.sysname)"
      >
        {{button.name}}
      </button>
    </div>
  </div>

  <div class="inputs-filter" *ngIf="showAllFilters">
    <sc-select [options]="optionsMO" *ngIf="isMoFilterVisible"></sc-select>
    <sc-select [options]="optionsIncidentTypes"></sc-select>
    <div class="inputs-filter__datepicker-element">
      <bg-datetime
        label="Время регистрации с"
        formControlName="dateTimeFrom"
        [defaultHour]="0"
        [defaultMinute]="0"
        [defaultSecond]="0"
      ></bg-datetime>
      <bg-datetime
        label="Время регистрации по"
        formControlName="dateTimeTo"
        [defaultHour]="23"
        [defaultMinute]="59"
        [defaultSecond]="59"
      ></bg-datetime>
    </div>
  </div>
</div>
