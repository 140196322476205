<div [formGroup]="parametersForm" appLCSFormState [params]="attributes">
  <div class="bg-row" >
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-select
          [options]="fireObjectType" formControlName="fireObjectType"></sc-select>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-select [options]="objectFire" formControlName="objectFire"></sc-select>
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-input [options]="leader" formControlName="leader"></sc-input>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-input [options]="fireCulprit" formControlName="fireCulprit"></sc-input>
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-select [options]="causeFire" formControlName="causeFire"></sc-select>
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col container-without-border__column_actual-datetime">
      <div class="bg-form-group">
        <sc-select [options]="rank" formControlName="rank"></sc-select>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-input [options]="fireAreaOption" formControlName="fireArea"></sc-input>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        <bg-datetime label="Актуально на" formControlName="relevantOnDate"></bg-datetime>
      </div>
    </div>
  </div>
</div>
