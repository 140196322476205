import {
  IDds01AdditionalInfoDto,
  IDds01FireParamsDto,
  IEmergencyDto,
  IEmergencyEventDto,
  IEmergencyResolutionDto,
  IFiasValueDto,
  IFileInfoDto,
  IForecastingDetailDto,
  ILifeCycleDto,
  ILifeCycleStepDto,
  IPlacementDetailsDto,
  IUlkAdditionalInfoDto,
} from 'smart-city-types';

/**
 * Класс отвечает за модель инцидента
 */
export class EmergencyDto implements IEmergencyDto {
  id: string = undefined;
  number?: string = undefined;
  showOnPortal?: boolean = false;
  interview?: any[] = undefined;
  address?: IFiasValueDto = undefined;
  addressFact?: IFiasValueDto = undefined;
  details?: IPlacementDetailsDto = undefined;
  detailsFact?: IPlacementDetailsDto = undefined;
  ksipPlaceDescription?: string = undefined;
  responseScriptSteps?: any[] = undefined;
  docType?: string = undefined;
  timeCreate?: number = undefined;
  description?: string = undefined;
  state?: string = undefined;
  organization?: string = undefined;
  incidentTypeId: string = undefined;
  ksipDetailsId: string = undefined;
  sourceType?: string = undefined;
  responsible?: string = undefined;
  timeStart?: number = undefined;
  timeFinish?: number = undefined;
  coordinates?: string = undefined;
  deadline?: number = undefined;
  comment?: string = undefined;
  lifeCycleId?: string | ILifeCycleDto = undefined;
  lifeCycleStepId?: string | ILifeCycleStepDto = undefined;
  createdBy?: string = undefined;
  addressFactMatch: boolean = undefined;
  resolution: IEmergencyResolutionDto = undefined;
  parentId?: string | EmergencyDto = undefined;
  vaDetail?: string;
  parentEventId?: string | IEmergencyEventDto;
  mapMarkerCoordinate?: string = undefined;
  urgently?: boolean = false;
  threatPopulation?: boolean = false;
  threatOrganization: boolean = false;
  fireParams?: IDds01FireParamsDto = undefined;
  dds01AdditionalInfo?: IDds01AdditionalInfoDto;
  supervisedByCuks?: boolean = false;
  ulkAdditionalInfo?: IUlkAdditionalInfoDto;
  internetPortalComment?: string;
  regimeId?: string;
  documents?: IFileInfoDto[];
  forecastingDetailId?: IForecastingDetailDto;
  totalRegistryPeopleHouse?: number;
  totalTemporalPeopleHouse?: number;
  individualOwnership?: boolean;
  jkhObject?: string;
  favorableRestrictionObjects?: string;
  affectedRestrictionObjects?: string;
  resourceConstraintType?: string;
  relatedRestrictions?: string;
  jkhDirection?: string;
  consumerPowerCategoryId?: string;
  countSocialFacilities?: number;
  isSendingToJkhReform?: boolean;
  backToWork?: boolean;
  reasonOfBackToWork?: string;
  workTermFrom?: number;
  workTermTo?: number;
  viewed?: number;
  internetPortalDesc?: string;
  byCoordinates?: boolean;
  involvedVehicles?: string[];
  moId?: string;
  districtId?: string;
  needToSendEmergencyAtlas?: boolean;
  sentEmergencyAtlas?: boolean;

  constructor() {
    this.address = <IFiasValueDto>{};
    this.details = <IPlacementDetailsDto>{};
    this.detailsFact = <IPlacementDetailsDto>{};
    this.resolution = <IEmergencyResolutionDto>{};
    this.fireParams = <IDds01FireParamsDto>{};
    this.forecastingDetailId = <IForecastingDetailDto>{
      params: {
        significantObjectId: undefined,
        aggregationStateId: undefined,
        chemicalTypeId: undefined,
      },
    };
  }
}
