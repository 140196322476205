import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '@bg-front/core/components';

/**
 * Копмпонента реализует диалоговое окно со списком связанных инцидентов с режимом функционирования
 */
@Component({
  selector: 'bg-regimes-emergencies-dialog',
  templateUrl: './regimes-emergencies-dialog.component.html',
  styleUrls: ['./regimes-emergencies-dialog.component.scss'],
})
export class RegimesEmergenciesDialogComponent extends BaseComponent {

  public displayedColumns: string[] = ['number', 'state', 'organization', 'timeCreate'];

  constructor(
    public dialogRef: MatDialogRef<RegimesEmergenciesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
  }

  /** Закрыть это окно */
  public close(): void {
    this.dialogRef.close();
  }
}
