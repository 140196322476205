import { Injectable } from '@angular/core';
import { IModifiedData } from '@smart-city/core/interfaces';
import { RestService, Settings2Service, SubscriberService } from '@smart-city/core/services';
import { Observable, of } from 'rxjs';
import { filter, map, pluck, switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IArchiveTaskDto, ICreateArchiveOutDTO } from 'smart-city-types';
import { ONE_HOUR, ONE_SECOND } from '@bg-front/core/models/constants';
import { IWantedPerson } from '../../models/interfaces';

/**
 * Сервис для работы с результатами видеоаналитики
 */
@Injectable({
  providedIn: 'root',
})
export class VideoResultsService {
  constructor(
    private readonly rest: RestService,
    private readonly subs: SubscriberService,
    private readonly settings: Settings2Service,
  ) {}

  /**
   * Запуск формирования архива для просмотра видео.
   * Используется так как просмотр видео напрямую из архива видеосервера невозможен без VPN
   * @param url - ссылка на видео на видеосервере
   * @param entityId - ID сущности для которой выполняется задача архивирования
   * @param entity - наименование сущности для которой выполняется задача архивирования
   */
  public createArchive(url: string, entityId: string, entity: string = 'VCAResults'): Observable<IArchiveTaskDto> {
    // Запуск задачи архивирования для сохранения видео в SFS
    return this.rest
      .serviceRequest({
        action: 'createArchive',
        service: { name: 'Archive' },
        data: {
          entityId,
          entity,
          service: 'Emergency',
          video: [
            {
              url,
              fileName: 'video.mp4',
            },
          ],
          photo: [],
          storageDuration: ONE_HOUR / ONE_SECOND,
          timeout: ONE_HOUR / 2,
        },
      })
      .pipe(
        map((archive: IAbstractServiceData) => archive.data),
        // Получение полной информации о задаче архивирования
        switchMap((archive: ICreateArchiveOutDTO) => {
          return this.rest.serviceRequest({
            action: 'select',
            service: { name: 'Archive' },
            entity: {
              name: 'ArchiveTasks',
              query: { id: archive.archiveTaskId },
            },
          });
        }),
        map((archive: IAbstractServiceData) => archive.data.items[0]),
        switchMap((archive: IArchiveTaskDto) => {
          // Если задача на архивирование видео находится в ожидании,
          // то подписаться на изменения таблицы для актуализации статуса.
          return archive.status === 'pending'
            ? this.subs
                .onTableChange<IArchiveTaskDto>('Archive', 'ArchiveTasks')
                .pipe(filter((modifiedData: IModifiedData<IArchiveTaskDto>) => modifiedData.data.id === archive.id))
                .pipe(map((modifiedData: IModifiedData<IArchiveTaskDto>) => modifiedData.data))
            : of(archive);
        }),
      );
  }

  /**
   * Получение наименований списков наблюдения
   * @param ids - массив ID списков наблюдения
   */
  public getMatchedListsNames(ids: string[]): Observable<string[]>{
    if (!ids?.length) return of([]);
    
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'FfsIntegration' },
        entity: {
          name: 'DossierListAccessRights',
          attributes: ['id', 'dossierListId.id', 'dossierListId.name'],
          query: {
            'userId': this.settings.currentUser.id,
            'dossierListId.id': { $in : ids }
          },
          sort: {
            field: 'dossierList.name',
            direction: 'asc',
          },
        },
      }).pipe(
        pluck('data', 'items'),
        map((items: IAnyObject[]) => items?.map((item: IAnyObject) => item['dossierListId.name'])),
      )
  }

  /** Получение досье по ID
   * @param id -Id записи
   * @param attributes - аттрибуты
   **/
  public getDossierById(id: string, attributes?: string[]): Observable<IWantedPerson> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'FfsIntegration' },
      entity: {
        attributes,
        name: 'WantedPersons',
        query: { id },
      },
    }).pipe(pluck('data', 'items', '0'));
  }
}
