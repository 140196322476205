import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccessService, IAccessAction, SfsService } from '@smart-city/core/services';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { IArchiveTaskDto, IEmergencyVaDetailDto, IVideoDeviceDto, IWantedRegMarkDto } from 'smart-city-types';
import { IAbstractServiceData } from 'smart-city-types/services';
import { THIRTY_SECONDS } from '@bg-front/core/models/constants';
import { IDownloadSourcesOptions, IMonitoringDate, IVideoDeviceBg } from '../../models/interfaces';
import { EmergencyService, RegMarksService, VideoDevicesService, VideoResultsService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';
import { CallService } from '../../../call/services';

/** Выявление ТС, находящихся в розыске */

@Component({
  selector: 'bg-video-result-wanted-grz',
  templateUrl: './video-result-wanted-grz.component.html',
  styleUrls: ['./video-result-wanted-grz.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoResultWantedGrzComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Перриод просмотра, для нового плеера*/
  public customPeriod;
  /** id камеры */
  public cameraId: string | IVideoDeviceDto;
  /** Ссылки на фотографии */
  public imagesUrls: string[] = [];
  /** Внешний id камеры */
  public extCameraId: string = undefined;
  /** Опция id - сущности */
  @Input()
  public entityId: string;
  @ViewChild('dialogRef', { static: false }) dialogRef: TemplateRef<any>;
  /**
   * Данные по разыскиваемому номеру
   */
  public mark: IWantedRegMarkDto = undefined;
  /**
   * Имя камеры
   */
  public name: string = undefined;
  /** Опции для кнопок скачать фото/видео */
  public downloadSourcesOptions: IDownloadSourcesOptions;
  /** Доступность скачивания материалов */
  public canPreparePhotoAndVideoForWantedGrz: IAccessAction = {
    visible: false,
    enabled: false,
    name: 'CanPreparePhotoAndVideoForWantedGrz',
  };
  /** Доступность просмотра фото и видео */
  public canPhotoAndVideo: IAccessAction = {
    visible: false,
    enabled: false,
    name: 'CanPhotoAndVideo',
  };

  @Input()
  public incidentNumber: string;

  /**
   * Ссылка на поток
   */
  public streamUrl: string = undefined;

  /** ID файла с видео в хранилище SFS */
  public sfsId: string = undefined;

  /** Статус получения видео */
  public videoStatus: string = 'Запущен процесс получения видео';

  /** Параметры рамки */
  public frame: {
    x: number;
    y: number;
    alarm: number;
    width: number;
    height: number;
  };
  /** Состояние отображения компонента bg-match-image */
  public isMatchImageVisible: boolean = false;

  /** Наименования вкладок с фото */
  public tabNames: string[] = ['Фото до', 'Фото сработки', 'Фото после'];

  constructor(
    private readonly emergency: EmergencyService,
    private readonly regMarksService: RegMarksService,
    private readonly videoDevicesService: VideoDevicesService,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly sfs: SfsService,
    private readonly accessService: AccessService,
    private readonly videoResultsService: VideoResultsService,
    private readonly callService: CallService,
  ) {
    super();
  }

  /**
   * Данные о видеопроисшествии
   * @param val данные
   */
  @Input()
  public set vaDetail(val: IEmergencyVaDetailDto) {
    if (val) {
      const vaTypeId = typeof val.vaTypeId === 'string' ? val.vaTypeId : val.vaTypeId.id;
      this.initData(val.markId as string);

      this.cameraId = val.camera1Id;

      this.frame = val['frame'];
      /** Проверка значений от frame */
      this.isMatchImageVisible = !!this.frame.width && !!this.frame.height;

      const videoServerData = this.videoDevicesService.getVideoDevice(val.camera1Id as string).pipe(
        takeUntil(this.ngUnsubscribe),
        mergeMap((result: IVideoDeviceBg) => {
          if (result) {
            this.extCameraId = result.extId;
            this.name = result.name;
            return this.videoDevicesService.getVideoServerById(<string>result.videoServer);
          }
          return of(null);
        }),
        catchError((error: Error) => this.catchErrorFn<IVideoDeviceBg>(error, 'Ошибка загрузки данных о камере')),
      );

      this.videoDevicesService
        .getTypeVaSettingsByMonitoringSubject(vaTypeId)
        .pipe(
          switchMap((monitoringData: IMonitoringDate) => {
            return forkJoin([
              of(monitoringData),
              this.videoDevicesService.getVideoDeviceRangeStreamUrl(val.camera1Id as string, val.camera1Time),
              this.videoDevicesService.getMacroscopExportArchiveURL(
                val.camera1Id as string,
                val.camera1Time - (monitoringData?.timeBeforeEvent || THIRTY_SECONDS),
                val.camera1Time + (monitoringData?.timeAfterEvent || THIRTY_SECONDS),
              ),
              videoServerData,
            ]);
          }),
          switchMap(
            (result: [IMonitoringDate, string, string, IAbstractServiceData]): Observable<any> => {
              const fromTime = val.camera1Time - (result[0]?.timeBeforeEvent || THIRTY_SECONDS);
              const toTime = val.camera1Time + (result[0]?.timeAfterEvent || THIRTY_SECONDS);
              const items = result ? result[3].data.items : [];
              const serverData = [];
              if (items.length) {
                serverData[0] = {
                  ...items[0],
                  extId: this.extCameraId,
                };
              }

              this.downloadSourcesOptions = {
                vaTypeId,
                fromTime,
                toTime,
                serverData,
                entityId: this.entityId,
                photoList: JSON.parse(val.file1Id),
                timeCreate: +val.camera1Time,
                isShowVideoButton: !!result[1],
                photoFileName: `Фотоматериалы_${this.incidentNumber}`,
                videoFileName: `Видеоматериалы_${this.incidentNumber}`,
              };

              this.customPeriod = {
                fromtime: fromTime,
                totime: toTime,
              };

              this.streamUrl = result[1];

              this.cdr.detectChanges();
              return this.videoResultsService.createArchive(result[2], this.entityId);
            },
          ),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((archive: IArchiveTaskDto) => {
          switch (archive.status) {
            case 'error':
              this.videoStatus = 'Ошибка запроса видео';
              this.cdr.detectChanges();
              return;
            case 'success':
              this.sfsId = archive.video[0];
              this.cdr.detectChanges();
              return;
          }
        });
    }

    this.imagesUrls = JSON.parse(val.file1Id)?.map((id: string) => this.sfs.getStreamUrl(id));
    this.cdr.detectChanges();
  }

  public onClickPhone(phone: string): void {
    if (!phone) return;
    this.callService.call(phone);
  }

  public showPhotosAndVideos() {
    this.dialog.open(this.dialogRef);
  }

  public ngOnInit(): void {
    this.canPreparePhotoAndVideoForWantedGrz = this.accessService.accessMap[
      this.canPreparePhotoAndVideoForWantedGrz.name
      ];

    this.canPhotoAndVideo = this.accessService.accessMap[this.canPhotoAndVideo.name];

    this.downloadSourcesOptions = {
      ...this.downloadSourcesOptions,
      photoFileName: `Фотоматериалы_${this.incidentNumber}`,
      videoFileName: `Видеоматериалы_${this.incidentNumber}`,
    };
  }

  private initData(markId: string) {
    this.regMarksService
      .getWantedRegMask(markId)
      .pipe(
        catchError((error: Error) =>
          this.catchErrorFn<IWantedRegMarkDto>(error, 'Ошибка загрузки данных о разыскиваемом ГРЗ'),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((mark: IWantedRegMarkDto) => {
        this.mark = mark;
        this.cdr.detectChanges();
      });
  }
}
