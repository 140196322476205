import { Component } from '@angular/core';
import { Settings2Service } from '@smart-city/core/services';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { VideoDevicesListService } from '../../../bg/modules/security-forces/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'bg-add-camera-dialog',
  templateUrl: './add-camera-dialog.component.html',
  styleUrls: ['./add-camera-dialog.component.scss']
})
export class AddCameraDialogComponent {
  /** Списки в которые уже добавлена камера */
  public list: string[] = [];
  /** Списки в которые камеру добавили */
  public newList: string[] = [];

  /** Списки видеокамер */
  public videoDevicesLists$: Observable<{ id: string; name: string }[]>;

  constructor(
    private readonly settings: Settings2Service,
    private readonly modalRef: NzModalRef,
    private readonly videoDevicesListService: VideoDevicesListService,
  ) {}

  /** @ignore */
  public ngOnInit(): void {
    this.videoDevicesLists$ = this.videoDevicesListService.getVideoDevicesLists({ $and: [
      { userId: this.settings.currentUser.id },
      { id: { $nin: this.list } },
    ]});
  }

  /** Сохранение изменений */
  public save(): void {
    this.modalRef.close(this.newList);
  }

  /** Закрытие окна */
  public close(): void {
    this.modalRef.close(undefined);
  }
}
