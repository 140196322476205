import { Injectable } from '@angular/core';
import { RestService } from '@smart-city/core/services';
import { IAbstractServiceData } from 'smart-city-types';
import { map, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IHlsUrlInfo } from '../interfaces';
import { IServerData } from '@bg-front/core/models/interfaces';
import md5 from 'crypto-js/md5'

@Injectable()
export class HlsPlayerService {
  constructor(private readonly rest: RestService) { }

  /**
   * TODO: Возможно перенести на бэк. Там же можно получить md5 без использования дополнительных пакетов
   *       Но, так как, сейчас используется другой способ возможно закомментировать и отказаться от использования
   *       дополнительных пакетов.
   * Получение информации для формирования URL
   * @param id - ID устройства
   */
  public getUrlInfo(id: string): Observable<IHlsUrlInfo> {
    return  this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'VideoDevices',
        query: { id },
        attributes: [
          'extId',
          'token',
          'videoServer.useSSL',
          'videoServer.ip',
          'videoServer.port',
          'videoServer.type.sysname',
          'videoServer.login',
          'videoServer.password',
          'videoServer.parentServer.login',
          'videoServer.parentServer.password',
        ],
      }
    }).pipe(map((result: IAbstractServiceData) => {
      const urlInfo = result.data.items[0]
      const password = <IServerData>(urlInfo.videoServer.parentServer).password  ||
        (<IServerData>urlInfo.videoServer).password
        ? md5(
          <IServerData>(urlInfo.videoServer.parentServer).password  ||
          urlInfo.videoServer.password
        ).toString()
        : '';

      return {
        protocol: (<IServerData>urlInfo.videoServer).useSSL ? 'https' : 'http',
        ip: (<IServerData>urlInfo.videoServer).ip,
        port: (<IServerData>urlInfo.videoServer).port,
        channelId: urlInfo.extId,
        login: <IServerData>(urlInfo.videoServer.parentServer).login
          || urlInfo.videoServer.login || '',
        password,
      }
    }));
  }

  /**
   * Получение проксированной ссылки на поток
   * @param deviceId - ID камеры
   */
  public getDeviceProxyStreamUrl(deviceId: string): Observable<string> {
    return  this.rest.serviceRequest({
      action: 'getDeviceProxyStreamUrl',
      service: { name: 'VideoHttpProxy' },
      data: { deviceId },
    }).pipe(pluck('data'));
  }

  /**
   * Получение проксированной ссылки на архив в m3u8
   * @param deviceId - ID камеры
   * @param fromtime - начало архива
   */
  public getDeviceProxyArchiveByRangeUrl(deviceId: string, fromtime: string): Observable<{ playerType: string, source: string }> {
    return  this.rest.serviceRequest({
      action: 'getDeviceProxyArchiveByRangeUrl',
      service: { name: 'VideoHttpProxy' },
      data: { deviceId, fromtime },
    }).pipe(
      pluck('data'),
      map((source: string) => ({ playerType: 'hls', source })),
    );
  }

  /**
   * Получение проксированной ссылки на архив в mp4.
   * @param deviceId - ID камеры
   * @param fromtime - начало архива
   * @param totime - окончание архива
   */
  public getDeviceProxyArchiveUrl(deviceId: string, fromtime: string, totime: string): Observable<{ playerType: string, source: string }> {
    return  this.rest.serviceRequest({
      action: 'getDeviceProxyArchiveUrl',
      service: { name: 'VideoHttpProxy' },
      data: { deviceId, fromtime, totime },
    }).pipe(
      pluck('data'),
      map((source: string) => ({ playerType: 'video', source })),
    );
  }
}
