import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Coordinates } from '@bg-front/core/models/classes';
import { coordinatesValidator } from '@bg-front/core/validators';
import { IForestryFacilityDto } from '@bg-front/forestry-facilities/models/interfaces';
import { ForestryFacilitiesService } from '@bg-front/forestry-facilities/services';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  IAnyObject,
  IAppealCommentDto,
  IAppealDetailDto,
  IDds01FireParamsDto,
  IDictionaryModelDto,
  IEmergencyEventDto,
  IEmergencyResolutionDto,
  ILifeCycleStepActionDto,
  ILifeCycleStepDto,
} from 'smart-city-types';

import { IMiniMapMarkerPositionEvent } from '../../models/interfaces';
import { BaseIncidentFormComponent } from '../base-incident-form/base-incident-form.component';
import { BgLoosesComponent } from '../bg-looses/bg-looses.component';
import { TechnologicalFireAdditionalInfoComponent } from '../technological-fire-additional-info/technological-fire-additional-info.component';
import { AccessService, RestService, Settings2Service } from '@smart-city/core/services';
import {
  AtmIntegrationService,
  BgAdminService,
  EmergencyService,
  MiniMapService,
  OrganizationsService,
  ResponsePlanStepService,
} from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '@smart-city/core/common';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { MultiFileService } from '@bg-front/core/services';
import { ReportingService } from '../../../bg/modules/reporting/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';

@UntilDestroy()
@Component({
  selector: 'bg-incident-dds01-edit-form',
  templateUrl: './incident-dds01-edit-form.component.html',
  styleUrls: ['./incident-dds01-edit-form.component.scss'],
})
/**
 * Компонента реализует возможность просмотра и редактирования инцидента ДДС01
 */
export class IncidentDds01EditFormComponent extends BaseIncidentFormComponent implements OnInit, AfterViewInit {
  /** Центр миникарты */
  public miniMapCenter: MapBaseCoordinatesType;

  /** Зум для миникарты */
  public miniMapZoom: number;

  /** Координаты по умолчанию для мини карты */
  public defaultMarkerCoordinate: [number, number];

  /** Сохраняем состояние комментариев, что бы не задваивать при ошибках */
  private currentIPComments: IAppealCommentDto[];

  // TODO заменить на метод поиска полигона из платформы, когда тот будет готов
  /** Лесохозяйственный объект, в полигоне которого расположен инцидент */
  public forestryFacility: IForestryFacilityDto = { name: '' };

  /** Показывать ли детализацию КСиП */
  public isKsipDetails: boolean = false;
  /** @ignore */
  constructor(
    private readonly forestryFacilitiesService: ForestryFacilitiesService,
    accessService: AccessService,
    atmIntegrationService: AtmIntegrationService,
    bgAdminService: BgAdminService,
    dialog: MatDialog,
    dialogService: DialogService,
    emergencyService: EmergencyService,
    fb: FormBuilder,
    ksipCategoriesQuery: KsipCategoriesQuery,
    ksipDetailsQuery: KsipDetailsQuery,
    ksipSectionsQuery: KsipSectionsQuery,
    ksipTypesQuery: KsipTypesQuery,
    miniMapService: MiniMapService,
    multiFileService: MultiFileService,
    organizationsService: OrganizationsService,
    reportingService: ReportingService,
    route: ActivatedRoute,
    router: Router,
    settings: Settings2Service,
    significantObjectService: SignificantObjectsService,
    responsePlanStepService: ResponsePlanStepService,
  ) {
    super(
      accessService,
      atmIntegrationService,
      bgAdminService,
      dialog,
      dialogService,
      emergencyService,
      fb,
      ksipCategoriesQuery,
      ksipDetailsQuery,
      ksipSectionsQuery,
      ksipTypesQuery,
      miniMapService,
      multiFileService,
      organizationsService,
      reportingService,
      route,
      router,
      settings,
      significantObjectService,
      responsePlanStepService,
    );
  }

  /** @ignore */
  ngOnInit(): void {
    this.incidentForm = new FormGroup({
      /** ----- MAIN ----- **/
      addressFact: new FormControl(this.model.addressFact, [Validators.required]),
      moId: new FormControl(this.model.moId),
      districtId: new FormControl({ value: this.model.districtId, disabled: true }),
      detailsFact: new FormControl(this.model.detailsFact),
      incidentTypeId: new FormControl(this.model.incidentTypeId, [Validators.required]),
      ksipDetailsId: new FormControl(this.model.ksipDetailsId),
      comment: new FormControl(this.model.comment),
      description: new FormControl(this.model.description),
      responsibleUser: new FormControl(this.model.responsible || this.settings.currentUser.id, [Validators.required]),
      organization: new FormControl(this.model.organization),
      urgently: new FormControl(this.model.urgently),
      danger: new FormControl(this.model.resolution?.danger),
      dangerOrg: new FormControl(this.model.resolution?.dangerOrg),
      showOnPortal: new FormControl(this.model.showOnPortal || false),
      supervisedByCuks: new FormControl(this.model.supervisedByCuks),
      workTermFrom: new FormControl(this.model.workTermFrom),
      workTermTo: new FormControl(this.model.workTermTo),
      /** ----- PARAMS ----- **/
      fireParams: new FormControl(this.model.fireParams || <IDds01FireParamsDto>{}),
      /** ----- PLACEMENT ----- **/
      coordinates: new FormControl(this.model.coordinates, [coordinatesValidator()]),
      mapMarkerCoordinate: new FormControl(this.model?.mapMarkerCoordinate),
      ksipPlaceDescription: new FormControl(this.model.ksipPlaceDescription),
      /** ----- LOOSES ----- **/
      resolution: BgLoosesComponent.generateFormGroup(this.fb, {
        ...this.model.resolution,
        peopleInTheZoneOnDate: this.model.resolution?.peopleInTheZoneOnDate || this.model.timeCreate,
        deathOnDate: this.model.resolution?.deathOnDate || this.model.timeCreate,
        rescuedOnDate: this.model.resolution?.rescuedOnDate || this.model.timeCreate,
        victimOnDate: this.model.resolution?.victimOnDate || this.model.timeCreate,
        hospitalizedOnDate: this.model.resolution?.hospitalizedOnDate || this.model.timeCreate,
        evacuatedOnDate: this.model.resolution?.evacuatedOnDate || this.model.timeCreate,
        missingOnDate: this.model.resolution?.missingOnDate || this.model.timeCreate,
      }),
      /** ----- Regime ----- **/
      regime: new FormControl(this.model.regimeId),
      /** ----- DOCS ----- **/
      documents: new FormControl(this.model.documents),

      totalRegistryPeopleHouse: new FormControl(this.model.totalRegistryPeopleHouse),
      totalTemporalPeopleHouse: new FormControl(this.model.totalTemporalPeopleHouse),
      individualOwnership: new FormControl(this.model.individualOwnership),
      dds01AdditionalInfo: new FormControl(this.model.dds01AdditionalInfo),
      technologicalFireAdditionalInfo: TechnologicalFireAdditionalInfoComponent.generateFormGroup(this.fb, {
        totalCars: (this.model.resolution || { totalCars: null }).totalCars?.toString(),
        trunks: (this.model.resolution || { trunks: null })['trunks'],
        personal: (this.model.resolution || { personal: null })['personal'],
        actual: (this.model.resolution || { actual: null })['actual'],
        timeDetection: (this.model.resolution || { timeDetection: null })['timeDetection'],
        timeDeparture: (this.model.resolution || { timeDeparture: null })['timeDeparture'],
        timeArrival: (this.model.resolution || { timeArrival: null })['timeArrival'],
        timeFirstSalvo: (this.model.resolution || { timeFirstSalvo: null })['timeFirstSalvo'],
        timeLocalization: (this.model.resolution || { timeLocalization: null })['timeLocalization'],
        timeLiquidation: (this.model.resolution || { timeLiquidation: null })['timeLiquidation'],
        timeRecovery: (this.model.resolution || { timeRecovery: null })['timeRecovery'],
      }),
      forecasting: new FormControl(this.model?.forecastingDetailId),
    });

    this.isKsipDetails = !!this.model.ksipDetailsId;

    /** При изменении типа КСиП меняем видимость блока с детализацией в зависимости от того, есть ли у него детализация */
    this.incidentForm.controls['incidentTypeId'].valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      const ksipType =  this.ksipTypesQuery.getById(value);
      this.isKsipDetails = !!ksipType?.ksipDetails;
    })

    this.vehiclesGridQuery = { id: { $in: this.model.involvedVehicles || [] } };

    super.ngOnInit();
    /** Получение координат и зума для МО */
    this.miniMapCenter = this.getMunicipalCoordinates();
    this.miniMapZoom = parseInt(this.getMunicipal().zoom, 10);

    if (this.disabled) {
      this.incidentForm.disable();
    }

    this.getModelData();

    this.initReactPlan();

    // поиск лесохозяйственных объектов, в полигоне которых находится инцидент
    // TODO заменить на метод поиска полигона из платформы, когда тот будет готов
    this.forestryFacilitiesService
      .getForestryFacilityForEmergency(this.model.id)
      .pipe(untilDestroyed(this))
      .subscribe((res: IForestryFacilityDto) => {
        this.forestryFacility = res;
      });

    // TODO заменить на метод поиска полигона из платформы, когда тот будет готов
    this.incidentForm.controls.coordinates.valueChanges
      .pipe(
        untilDestroyed(this),
        mergeMap((value: string) => {
          return this.forestryFacilitiesService.getForestryFacilityForEmergency(this.model.id);
        }),
      )
      .subscribe((res: IForestryFacilityDto) => {
        this.forestryFacility = res;
      });

    this.setForecastingParamsOptions();

    const markerCoordinates = new Coordinates(this.model.mapMarkerCoordinate || this.model.coordinates);
    if (markerCoordinates.isValid()) {
      this.defaultMarkerCoordinate = markerCoordinates.toArray();
    }

    this.updateForm();

    this.ngMasterValue = new BehaviorSubject<IAnyObject>(this.incidentForm.getRawValue());
    this.isDirty$ = dirtyCheck(this.incidentForm, this.ngMasterValue.asObservable());

    /** Добавляем работу с комментарием для интернет портала */
    if (this.needShowInternetPortalComment) {
      this.currentIPComments = [
        ...(((this.model.parentEventId as IEmergencyEventDto)?.appealDetail as IAppealDetailDto)?.comments ?? []),
      ];
    }
  }

  /** @ignore */
  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Установка маркера
   * @param miniMapEvent событие
   */
  public updateCoordinates(miniMapEvent: IMiniMapMarkerPositionEvent) {
    const markerCoordinates = new Coordinates(this.incidentForm.controls['mapMarkerCoordinate'].value);
    const newMarkerCoordinates = new Coordinates(miniMapEvent?.coordinates[0], miniMapEvent?.coordinates[1]);
    if (!markerCoordinates.equal(newMarkerCoordinates)) {
      this.incidentForm.controls['mapMarkerCoordinate'].setValue(newMarkerCoordinates.toString());
      this.redrawEventMarker(this.incidentForm.controls['mapMarkerCoordinate'].value);
      this.model.mapMarkerCoordinate = this.incidentForm.controls['mapMarkerCoordinate'].value;
    }
  }

  /**
   * Получение данных из формы и сохранение в модель
   * @param action - действие которое выполняется на форме
   */
  public getModelData(action?: string) {
    if (!this.model.id) {
      this.model.timeCreate = moment().unix() * 1000;
      this.model.number = `i-${moment(this.model.timeCreate).format('YYYYMMDD-HHmmss')}`;
      this.model.organization = this.settings.currentUser.organizationId.id;
      this.model.createdBy = this.settings.currentUser.id;
    }
    /** ----- MAIN ----- **/
    this.model.addressFact = this.incidentForm.controls['addressFact'].value;
    this.model.moId = <string>this.moIdComponent.value;
    this.model.districtId = <string>this.districtIdComponent.value;
    this.model.detailsFact = this.incidentForm.controls['detailsFact'].value;
    this.model.incidentTypeId = this.incidentForm.controls['incidentTypeId'].value;
    this.model.ksipDetailsId = this.incidentForm.controls.ksipDetailsId.value;
    this.model.comment = this.incidentForm.controls['comment'].value;
    this.model.description = this.incidentForm.controls['description'].value;
    this.model.responsible = this.incidentForm.controls['responsibleUser'].value;
    this.model.resolution = this.model.resolution || <IEmergencyResolutionDto>{};
    this.model.urgently = this.incidentForm.controls['urgently'].value;
    this.model.resolution.danger = this.incidentForm.controls['danger'].value;
    this.model.resolution.dangerOrg = this.incidentForm.controls['dangerOrg'].value;
    this.model.showOnPortal = this.incidentForm.controls['showOnPortal'].value;
    this.model.supervisedByCuks = this.incidentForm.controls['supervisedByCuks'].value;
    this.model.workTermFrom = this.incidentForm.controls['workTermFrom'].value;
    this.model.workTermTo = this.incidentForm.controls['workTermTo'].value;
    /** ----- PARAMS ----- **/
    this.model.fireParams = this.incidentForm.controls['fireParams'].value || <IDds01FireParamsDto>{};
    /** ----- PLACEMENT ----- **/
    this.model.coordinates = this.incidentForm.controls['coordinates'].value;
    this.model.ksipPlaceDescription = this.incidentForm.controls['ksipPlaceDescription'].value;
    /** ----- ADDITIONAL ----- **/
    this.model.dds01AdditionalInfo = this.incidentForm.controls['dds01AdditionalInfo'].value || {};
    /** ----- LOOSES ----- **/
    this.model.resolution = {
      ...this.model.resolution,
      ...(this.incidentForm.controls['resolution'].value || <IEmergencyResolutionDto>{}),
    };
    /** ----- DOCS ----- **/
    this.model.documents = this.incidentForm.controls['documents'].value;

    this.model.totalRegistryPeopleHouse = this.incidentForm.controls['totalRegistryPeopleHouse'].value;
    this.model.totalTemporalPeopleHouse = this.incidentForm.controls['totalTemporalPeopleHouse'].value;
    this.model.individualOwnership = this.incidentForm.controls['individualOwnership'].value;

    // Из-за особенностей реализации ДДС01, а точнее из-за того что многие изменения которые касались других служб
    // не применялись для ДДС01, метод getModelData вызывается в ngOnInit, где нет информации об action.
    // Поэтому для ДДС01 параметр action сделан необязательным и при инициализации информации для интернет-портала
    // проверяется его наличие.
    if (action && this.needShowInternetPortalComment && this.incidentForm.controls['internetPortalComment'].value) {
      // Текущий статус
      const currentStatus = (<IDictionaryModelDto>(<ILifeCycleStepDto>this.model.lifeCycleStepId).status)?.id
        ? (<IDictionaryModelDto>(<ILifeCycleStepDto>this.model.lifeCycleStepId).status).id
        : <string>(<ILifeCycleStepDto>this.model.lifeCycleStepId).status;
      // Получение информации о шаге ЖЦ в который переходит происшествие
      const nextStep = (this.settings.lifeCycleStep[(<ILifeCycleStepDto>this.model.lifeCycleStepId).id].actions || []).find(
        (i: ILifeCycleStepActionDto) => i.name === action,
      )?.nextStep;
      const nextLifeCycleStep = nextStep
        ? this.settings.lifeCycleStep[nextStep]
        : <ILifeCycleStepDto>this.model.lifeCycleStepId;
      // Если отсутствует следующий шаг, то происшествие остается в текущем шаге
      const newStatus = !!<string>nextLifeCycleStep?.status
        ? this.settings.getDictionaryById(<string>nextLifeCycleStep.status)
        : this.settings.getDictionaryById(currentStatus);
      // Инициализация текущего списка комментариев для интернет портала
      ((this.model.parentEventId as IEmergencyEventDto)
        .appealDetail as IAppealDetailDto).comments = this.currentIPComments;
      // Добавление нового комментария для интернет портала
      ((this.model.parentEventId as IEmergencyEventDto).appealDetail as IAppealDetailDto).comments.push({
        lifeCycleStepId: {
          id: nextLifeCycleStep.id,
          name: nextLifeCycleStep.name,
          status: <IDictionaryModelDto>{
            id: nextLifeCycleStep.status,
            name: newStatus.name,
            sysname: newStatus.sysname,
          },
        },
        text: this.incidentForm.controls['internetPortalComment'].value,
        createTime: Date.now(),
        userId: this.settings.currentUser.id,
      });
    }

    this.model.resolution = Object.assign(
      this.model.resolution,
      this.incidentForm.controls['technologicalFireAdditionalInfo'].value,
    );

    if (this.showForecastingParams) {
      this.model.forecastingDetailId = {
        id: this.model.forecastingDetailId?.id,
        params: (this.incidentForm.controls.forecasting as FormGroup).getRawValue(),
      };
    } else {
      this.model.forecastingDetailId = null;
    }
  }

  /** Обновление формы */
  public updateForm() {
    this.generateHeaderBar();

    this.attributes = (this.model.lifeCycleStepId as ILifeCycleStepDto).params;
  }
}
