import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, Validators } from '@angular/forms';
import { WeatherDataService } from '@bg-front/weather-data/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Settings2Service } from '@smart-city/core/services';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IStoredChemicalDto } from 'smart-city-types';

import { StoredChemicalsService } from '../../../stored-chemicals/services';
import { BaseForecastingBlowoutHazardousChemicalsComponent } from '../base-forecasting-blowout-hazardous-chemicals/base-forecasting-blowout-hazardous-chemicals.component';

/**
 * Задача "Разрушение химически-опасного объекта"
 */
@UntilDestroy()
@Component({
  selector: 'bg-destroy-chemical-object-task',
  templateUrl: './destroy-chemical-object-task.component.html',
  styleUrls: ['./destroy-chemical-object-task.component.scss'],
})
export class DestroyChemicalObjectTaskComponent
  extends BaseForecastingBlowoutHazardousChemicalsComponent
  implements OnInit, OnDestroy
{
  /** Массив отображаемых данных химикатов */
  public chemicalParams: {
    chemicalType: string;
    aggregationState: string;
    chemicalAmount: number;
    totalChemicalAmount: boolean;
  }[] = [];
  public override isShow: boolean = false;
  /** Соответствует целым и дробным числам, в т.ч. со знаком */
  /** Массив хранимых химикатов */
  private chemicalParamsModel: {
    chemicalTypeId: string;
    aggregationStateId: string;
    chemicalAmount: number;
    totalChemicalAmount: boolean;
  }[] = [];

  /**
   * @ignore
   */
  constructor(
    private readonly storedChemicalsService: StoredChemicalsService,
    private readonly controlContainer: ControlContainer,
    notificationService: NzNotificationService,
    settings: Settings2Service,
    weatherService: WeatherDataService,
  ) {
    super(settings, weatherService, notificationService);
  }

  /** @ignore */
  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
    this.isShow = this.form.disabled;

    if (this.isShow) {
      return;
    }

    (this.form.parent as FormGroup).controls.objectId.setValidators(Validators.required);
    (this.form.parent as FormGroup).updateValueAndValidity();

    if ((this.form.parent as FormGroup).controls.objectId.value) {
      this.storedChemicalsService
        .getStoredChemicalsForSignificantObject((this.form.parent as FormGroup).controls.objectId.value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: IStoredChemicalDto[]) => {
          res?.forEach((el: IStoredChemicalDto) => {
            this.chemicalParamsModel.push({
              aggregationStateId: el.aggregationState,
              chemicalAmount: el.chemicalAmount,
              chemicalTypeId: el.chemicalType,
              totalChemicalAmount: true,
            });

            this.chemicalParams.push({
              aggregationState: el['aggregationState.name'],
              chemicalAmount: el.chemicalAmount,
              chemicalType: el['chemicalType.name'],
              totalChemicalAmount: true,
            });
          });

          this.form.controls.storedChemical.setValue(this.chemicalParamsModel);
        });
    }

    (this.form.parent as FormGroup).controls.objectId.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.storedChemicalsService
            .getStoredChemicalsForSignificantObject(value)
            .pipe(untilDestroyed(this))
            .subscribe((res: IStoredChemicalDto[]) => {
              res?.forEach((el: IStoredChemicalDto) => {
                this.chemicalParamsModel.push({
                  aggregationStateId: el.aggregationState,
                  chemicalAmount: el.chemicalAmount,
                  chemicalTypeId: el.chemicalType,
                  totalChemicalAmount: true,
                });

                this.chemicalParams.push({
                  aggregationState: el['aggregationState.name'],
                  chemicalAmount: el.chemicalAmount,
                  chemicalType: el['chemicalType.name'],
                  totalChemicalAmount: true,
                });
              });

              this.form.controls.storedChemical.setValue(this.chemicalParamsModel);
            });
        } else {
          this.form.controls.storedChemical.setValue(null);
        }
      });
  }

  /** @ignore */
  public override ngOnDestroy(): void {
    (this.form.parent as FormGroup).controls.objectId.clearValidators();
    (this.form.parent as FormGroup).controls.objectId.updateValueAndValidity();
  }
}
