import { Injectable } from '@angular/core';
import { BaseCrudService } from '@bg-front/core/services';
import { RestService } from '@smart-city/core/services';
import { IRiskAtlasIntegrationEvent, IThermopoint } from '../../models/interfaces';
import { forkJoin, Observable, of } from 'rxjs';
import { IAbstractServiceData, IEmergencyDto, IEmergencyEventDto } from 'smart-city-types';
import { map, pluck, switchMap } from 'rxjs/operators';
import {
  ICreateThermopointMessage,
  ISendThermopointStatusMessage,
} from '../../../bg/modules/utility/modules/aims-rschs-thermal-point-emulator/components/models';
import { parseCoordinatesList } from '../../../bg/modules/utility/modules/aims-rschs-thermal-point-emulator/helpers';

/**
 * Сервис для работы с сущностью Термоточки
 */
@Injectable({
  providedIn: 'root',
})
export class ThermopointsService extends BaseCrudService<IThermopoint> {
  /** @ignore */
  constructor(rest: RestService) {
    super(
      {
        serviceName: 'RiskAtlasIntegration',
        entityName: 'Thermopoints',
      },
      rest,
    );
  }

  /**
   * Проверка на существование термоточки
   * @param extId Идентификатор термической точки во внешней системе
   * @returns true / false
   */
  public isThermopointExists(extId: string): Observable<boolean> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'RiskAtlasIntegration' },
        entity: {
          attributes: ['extId'],
          query: {
            extId,
          },
          name: 'Thermopoints',
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items?.length > 0 ? true : false;
        }),
      );
  }

  /**
   * Получить термоточку по идентификатору во внешней системе
   * @param extId - Идентификатор термоточки во внешней системе
   * @returns Термоточка
   **/
  public getByExtId(extId: string): Observable<IThermopoint> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'RiskAtlasIntegration' },
        entity: {
          name: 'Thermopoints',
          query: {
            extId,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || [])[0] as IThermopoint;
        }),
      );
  }

  /**
   * Получить все существующие термоточки
   * @returns Существующие термоточки
   **/
  public getAll(): Observable<IThermopoint[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'RiskAtlasIntegration' },
        entity: {
          name: 'Thermopoints',
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || []) as IThermopoint[];
        }),
      );
  }

  /**
   * Передать статус термоточки
   * @param thermopoint - статус термоточки
   */
  public sendThermopointStatus(thermopoint: Partial<IThermopoint>): Observable<IAbstractServiceData> {
    const themopointStatusMessage: ISendThermopointStatusMessage = {
      id: Number(thermopoint.extId),
      status_id: Number(thermopoint.statusId),
      ts: Math.floor(thermopoint.statusUpdateDateTime / 1000),
      fire_category_id: Number(thermopoint.typeId),
      land_category_id: Number(thermopoint.landCategoryId),
      report_ts: Math.floor(thermopoint.reportDateTime / 1000),
      in_work_ts: Math.floor(thermopoint.inWorkDateTime / 1000),
      owner: thermopoint.owner,
      owner_phone: thermopoint.ownerPhone,
      attention_score: thermopoint.attentionScore,
      possible_type: thermopoint.possibleType,
      unit: thermopoint.unit,
      person_count: thermopoint.personnelCount,
      technic_count: thermopoint.equipmentCount,
    };
    
    return this.rest.serviceRequest({
      action: 'sendMessage',
      service: { name: 'RiskAtlasIntegration' },
      data: {
        topic: 'thermopoints_status',
        data: themopointStatusMessage,
      },
    });
  }

  /**
   * Передать новую термоточку
   * @param thermopoint - новая термоточка
   */
  public createThermopoint(thermopoint: Partial<IThermopoint>): Observable<IAbstractServiceData> {
    const createThermopointMessage: ICreateThermopointMessage = {
      id: Number(thermopoint.extId),
      ts: Math.floor(thermopoint.detectionDateTime / 1000),
      point_geojson: JSON.stringify({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            Number(thermopoint.centerCoordinates.split(',')[1]),
            Number(thermopoint.centerCoordinates.split(',')[0]),
          ],
        },
      }),
      polygon_geojson: JSON.stringify({
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              parseCoordinatesList(thermopoint.polygonCoordinates).map((coordinates: number[]) =>
                coordinates.slice().reverse(),
              ),
            ],
          ],
        },
      }),
      burned_polygon_geojson: JSON.stringify({
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              parseCoordinatesList(thermopoint.burnedPolygonCoordinates).map((coordinates: number[]) =>
                coordinates.slice().reverse(),
              ),
            ],
          ],
        },
      }),
      f_name: thermopoint.federalDistrict,
      s_name: 'Калужская обл.',
      r_name: thermopoint.municipal,
      n_locality: thermopoint.closestSettlement,
      azimuth: thermopoint.azimuth,
      distance: thermopoint.distance,
    };
    
    return this.rest.serviceRequest({
      action: 'sendMessage',
      service: { name: 'RiskAtlasIntegration' },
      data: {
        topic: 'thermopoints',
        data: createThermopointMessage,
      },
    });
  }

  /** Получение термоточки по id события
   * @param eventId - ID события
   **/
  public getThermoPointByEventId(eventId: string): Observable<any> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'RiskAtlasIntegration' },
      entity: {
        name: 'IntegrationEvents',
        query: { eventId },
        attributes: ['extId'],
      },
    }).pipe(
      pluck('data', 'items', '0'),
      switchMap((integrationEvent: IRiskAtlasIntegrationEvent) => {
        if (integrationEvent?.extId) {
          return this.rest.serviceRequest({
            action: 'select',
            service: { name: 'RiskAtlasIntegration' },
            entity: {
              name: 'Thermopoints',
              query: { extId: integrationEvent.extId },
              attributes: ['id', 'extId'],
            },
          })
        }
        return of(null);
      }),
      pluck('data', 'items', '0'),
    )
  }

  /** Получение происшествий по id термоточки
   * @param extId - ID события
   **/
  public getEmergenciesByThermoPointId(extId: string): Observable<[IEmergencyDto[], IEmergencyEventDto[]]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'RiskAtlasIntegration' },
      entity: {
        name: 'IntegrationEvents',
        query: { extId },
        attributes: ['eventId'],
      },
    }).pipe(
      pluck('data', 'items'),
      switchMap((integrationEvents: IRiskAtlasIntegrationEvent[]) => {
        const eventIds: string[] = integrationEvents.map((event: IRiskAtlasIntegrationEvent) => event.eventId as string)
        if (eventIds?.length) {
          return forkJoin([
            this.rest.serviceRequest({
              action: 'select',
              service: { name: 'Emergency' },
              entity: {
                name: 'Emergency',
                query: { parentEventId: { $in: eventIds } },
                attributes: [
                  'id',
                  'timeCreate',
                  'coordinates',
                  'incidentTypeId',
                  'lifeCycleStepId.name',
                  'lifeCycleStepId.status.sysname',
                  'docType.sysname',
                ],
              },
            }).pipe(pluck('data', 'items')),
            this.rest.serviceRequest({
              action: 'select',
              service: { name: 'Emergency' },
              entity: {
                name: 'Events',
                query: { id: { $in: eventIds } },
                attributes: [
                  'id',
                  'ksipTime',
                  'exactCoordinates',
                  'ksipTypeId',
                  'isHandled',
                ],
              },
            }).pipe(pluck('data', 'items')),
          ])
        }
        return of([null, null] as [IEmergencyDto[], IEmergencyEventDto[]]);
      }),
    )
  }
}
