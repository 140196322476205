<ng-container>
  <ng-container *ngIf="isOrganizationSelected && isDetailNotEmpty">
    <h2>Информация об организации</h2>
    <div class="bg-row p-10">
      <div class="bg-col-4">
        <span class="subtitle">Наименование:</span>
      </div>
      <div class="bg-col">
        {{detailOrganization.fullname}}
      </div>
    </div>
    <div class="bg-row p-10">
      <div class="bg-col-4">
        <span class="subtitle">Адрес организации:</span>
      </div>
      <div class="bg-col">
        {{detailOrganization.address}}
      </div>
    </div>
    <div class="bg-row p-10">
      <div class="bg-col-4">
        <span class="subtitle">Телефон:</span>
      </div>
      <div class="bg-col">
        <span class="phone" (click)="call(detailOrganization.phone)">{{detailOrganization.phone}}</span>
      </div>
    </div>
    <div class="bg-row p-10">
      <div class="bg-col-4">
        <span class="subtitle">Данные руководителя:</span>
      </div>
      <div class="bg-col">
        {{detailOrganization.leader}}
        <span class="phone" (click)="call(detailOrganization.phoneLeader)">
        {{detailOrganization.phoneLeader}}
      </span>
      </div>
    </div>
    <div class="bg-row p-10">
      <div class="bg-col-4">
        <span class="subtitle">Режим работы:</span>
      </div>
      <div class="bg-col">
        {{detailOrganization.workingHours}}
      </div>
    </div>
    <div class="bg-row p-10">
      <div class="bg-col-4">
        <span class="subtitle">Комментарий:</span>
      </div>
      <div class="bg-col">
        {{detailOrganization.comment}}
      </div>
    </div>
  </ng-container>
  <ng-container [ngSwitch]="switchModal">
    <h2 *ngSwitchCase= "!isOrganizationSelected && !isOrganizationView">
      Для получения дополнительной информации выберите организацию</h2>
    <h2 *ngSwitchCase= "isOrganizationSelected && !isDetailNotEmpty">Дополнительная информация не предоставлена</h2>
    <h2 *ngSwitchCase= "!isOrganizationSelected && isOrganizationView">Организация отсутствует</h2>
  </ng-container>
  <div class="button_container">
    <button class="btn btn-primary" (click)="close()">Закрыть</button>
  </div>
</ng-container>
