<form nz-form [formGroup]="form" errorTailor id="idForTest" nzLayout="vertical">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="projectedRiskId">Риск для прогнозирования</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="projectedRiskId" nzSize="large">
            <nz-option [nzValue]="risk.id" [nzLabel]="risk.text" *ngFor="let risk of projectedRisks"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="countTaskId">Расчетная задача</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="countTaskId" nzSize="large">
            <nz-option [nzValue]="risk.id" [nzLabel]="risk.text" *ngFor="let risk of countTasks"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzFor="emergencyId">Инцидент</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="emergencyId" nzSize="large">
            <nz-option
              nzCustomContent
              [nzValue]="incident.value"
              [nzLabel]="incident.text"
              *ngFor="let incident of incidents$ | async"
            >
              <span nz-typography>{{ incident.text }}</span>
              <br />
              <span nz-typography nzType="secondary">{{ incident['addressFact.fullText'] }}</span>
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzFor="objectId">Объект, для которого расчёт</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="objectId" nzSize="large">
            <nz-option [nzValue]="obj.value" [nzLabel]="obj.text" *ngFor="let obj of objects$ | async"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24" [ngSwitch]="countTaskSysname">
      <div class="bg-row" *ngIf="!isShow && hasPreviousCalculation">
        <div class="bg-col-6">
          <div class="bg-form-group">
            <button class="btn btn-sm btn-primary" type="button" (click)="getPreviousData()">Предыдущий расчёт</button>
          </div>
        </div>
      </div>
      <!-- АХОВ -->
      <div *ngSwitchCase="'accidentChemicalObject'">
        <bg-accident-on-chemical-object-task
          [significantObjectId]="forecastingParams.objectId"
          formGroupName="forecasting"
        >
        </bg-accident-on-chemical-object-task>
      </div>
      <div *ngSwitchCase="'destroyChemicalObject'">
        <bg-destroy-chemical-object-task formGroupName="forecasting"> </bg-destroy-chemical-object-task>
      </div>
      <div *ngSwitchCase="'timeApproachCloudToPoint'">
        <bg-time-approach-cloud-to-point-task formGroupName="forecasting"> </bg-time-approach-cloud-to-point-task>
      </div>
      <!-- Радиационная обстановка -->
      <bg-nuclear-pollution-task *ngSwitchCase="'nuclearPollution'" formGroupName="forecasting">
      </bg-nuclear-pollution-task>

      <bg-thyroid-irradiation-task *ngSwitchCase="'thyroidIrradiation'" formGroupName="forecasting">
      </bg-thyroid-irradiation-task>

      <bg-radioactive-cloud-time-task *ngSwitchCase="'radioactiveCloudTime'" formGroupName="forecasting">
      </bg-radioactive-cloud-time-task>

      <bg-population-irradiation-point-task *ngSwitchCase="'populationIrradiationPoint'" formGroupName="forecasting">
      </bg-population-irradiation-point-task>

      <bg-nuclear-pollution-point-task *ngSwitchCase="'nuclearPollutionPoint'" formGroupName="forecasting">
      </bg-nuclear-pollution-point-task>

      <bg-exposure-population-overcoming-cloud-trail-task
        *ngSwitchCase="'exposurePopulationOvercomingCloudTrail'"
        formGroupName="forecasting"
      >
      </bg-exposure-population-overcoming-cloud-trail-task>

      <bg-time-allowed-to-overcome-cloud-trail-task
        *ngSwitchCase="'timeAllowedToOvercomeCloudTrail'"
        formGroupName="forecasting"
      >
      </bg-time-allowed-to-overcome-cloud-trail-task>

      <!-- Лесной пожар -->
      <bg-forecasting-consequences-of-forest-fires-task
        *ngSwitchCase="'forestFireConsequenceRating'"
        [emergencyParams]="$any(emergencyParams)"
        [coordinates]="forecastingParams?.coordinates"
        [forestTypeId]="$any(data?.object)?.forestTypeId?.id || data?.object?.forestTypeId"
        [flammabilityClass]="$any(data?.object)?.flammabilityClass"
        [trunkDiameterId]="$any(data?.object)?.trunkDiameterId"
        [polygonCoordinates]="$any(data?.object)?.polygonId?.coordinates"
        formGroupName="forecasting"
      >
      </bg-forecasting-consequences-of-forest-fires-task>
      <!-- Техногенный пожар -->
      <bg-forecasting-distance-traveled-by-fire-front-task
        *ngSwitchCase="'fireCoveredDistance'"
        [emergencyId]="emergencyId"
        formGroupName="forecasting"
      >
      </bg-forecasting-distance-traveled-by-fire-front-task>
      <bg-forecasting-technological-fire-areas-task
        *ngSwitchCase="'technologicalFireArea'"
        [emergencyId]="emergencyId"
        [coordinates]="forecastingParams?.coordinates"
        formGroupName="forecasting"
      >
      </bg-forecasting-technological-fire-areas-task>
      <bg-forecasting-extinguishing-agent-consumption-task
        *ngSwitchCase="'extinguishingAgentConsumption'"
        [emergencyId]="emergencyId"
        formGroupName="forecasting"
      >
      </bg-forecasting-extinguishing-agent-consumption-task>
    </div>
  </div>
  <div nz-row *nzModalFooter>
    <div nz-col [nzSpan]="12" class="d-flex">
      <button nz-button nzType="default" class="m-r" (click)="clear()">Очистить</button>
    </div>
    <div nz-col [nzSpan]="12">
      <button nz-button nzType="default" class="m-r" (click)="close()">Отмена</button>
      <button nz-button nzType="primary" [disabled]="!form.valid" (click)="startForecasting()">
        Рассчитать прогноз
      </button>
    </div>
  </div>
</form>
