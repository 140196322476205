import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from '@bg-front/core/components';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IScFias3Options } from '@smart-city/core/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddressOwnershipObject } from '../../models/types';
import * as dayjs from 'dayjs';

/** Компонент диалогового окна для создания/редактирования адреса */
@Component({
  selector: 'bg-address-edit-dialog',
  templateUrl: './address-edit-dialog.component.html',
  styleUrls: ['./address-edit-dialog.component.scss']
})
export class AddressEditDialogComponent extends BaseComponent implements OnInit {

  /** Форма */
  public form: FormGroup;

  /** Время и автор обновления */
  public updateTime: string;
  public updateAuthor: string;

  /** Настройки контрола Адрес */
  public addressOptions: IScFias3Options = {
    label: 'Адрес',
    isCoordinatesNecessary: true,
  };

  /** @ignore */
  constructor(
    public dialogRef: MatDialogRef<AddressEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddressOwnershipObject,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.updateAuthor = this.data?.updateAuthor;

    if (this.data?.updateTime) {
      this.updateTime = dayjs(this.data.updateTime).format('DD.MM.YYYY HH:mm:ss');
    }

    this.form = new FormGroup({
      address: new FormControl(this.data?.address || null, [Validators.required]),
    });
  }

  /** Закрытие формы */
  public close(): void {
    this.dialogRef.close();
  }

  /** Сохранение адреса */
  public save(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
