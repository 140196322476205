import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FireMonitoringObjectsState } from '../../../models/states';
import { FireMonitoringObjectsStore } from '../store/fire-monitoring-objects-store.service';

/**
 * Сервис по работе с хранилищем Объекты пожарного мониторинга
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class FireMonitoringObjectsQuery extends QueryEntity<FireMonitoringObjectsState> {
  constructor(store: FireMonitoringObjectsStore) {
    super(store);
  }

}
