import { AfterViewInit, ChangeDetectionStrategy, SimpleChanges, Component, ElementRef, Input, Output, OnInit, ViewChild, EventEmitter, OnChanges } from '@angular/core';
import { InputData, InputMode } from '../../models/types';

/** Компонент, отвечающий за отображение тектовой информации и чекбоксов */
@Component({
  selector: 'nz-view-control',
  templateUrl: './nz-view-control.component.html',
  styleUrls: ['./nz-view-control.component.scss'],
})
export class NzViewControlComponent implements OnInit, AfterViewInit, OnChanges {
  /** Режим работы */
  @Input() public mode: InputMode = 'text';
  /** Входные данные */
  @Input() public data: InputData = '';
  /** Подпись для чекбокса */
  @Input() public checkboxLabel?: string = '';
  /** Подпись для свитча */
  @Input() public switchLabel?: string = '';
  /** Нужны ли рамки */
  @Input() public borderless?: boolean = false;
  /** Кол-во строк для textarea */
  @Input() public rows: number = 0;
  /** Добавить кнопку иконкой в конце инпута */
  @Input() public addButtonOnAfter?: string = '';
  /** Добавить текст в конце инпута */
  @Input() public addTextOnAfter?: string = '';
  /** Текст - ссылка */
  @Input() linkStyle?: boolean = false;
  /** Добавить колор-пикер */
  @Input() colorPicker?: string = '';
  /** Передаем событие клика */
  @Output() public clickHandler?: EventEmitter<void> = new EventEmitter<void>();
  /** Ссылка на textarea */
  @ViewChild('textarea') public textarea: ElementRef<HTMLTextAreaElement>;
  /** Кол-во строк для textarea по умолчанию */
  private defaultRowsNumber: number = 3;

  /** Внутренние данные для отображения, которые динамически типизируются в зависимости от режима */
  public internalData: any = '';

  public ngOnChanges(changes: SimpleChanges): void {
    this.setInternalData();
  }

  public ngOnInit(): void {
    /** Если мы не в режиме чекбокса и передали подпись, выводим предупреждение */
    if (this.checkboxLabel && this.mode !== 'checkbox') {
      console.warn(`В NzViewControlComponent подпись для чекбокса
        необходмо передавать только с режимом "checkbox". Текущий режим ${this.mode}`);
      return;
    }

    /** Если мы в режиме чекбокса и не передали подпись, выводим ошибку */
    if (!this.checkboxLabel && this.mode === 'checkbox') {
      console.error(`В NzViewControlComponent с режимом checkbox необходимо передавать подпись`);
      return;
    }

    /** Если мы не в режиме свитча и передали подпись, выводим предупреждение */
    if (this.switchLabel && this.mode !== 'switch') {
      console.warn(`В NzViewControlComponent подпись для свитча
        необходмо передавать только с режимом "switch". Текущий режим ${this.mode}`);
      return;
    }

    /** Если мы в режиме свитча и не передали подпись, выводим ошибку */
    if (!this.switchLabel && this.mode === 'switch') {
      console.error(`В NzViewControlComponent с режимом switch необходимо передавать подпись`);
      return;
    }

    /** Если мы не в режиме textarea и передали кол-во строк, выводим предупреждение */
    if (this.rows && this.mode !== 'textarea') {
      console.warn(`В NzViewControlComponent кол-во строк
        необходмо передавать только с режимом "textarea". Текущий режим ${this.mode}`);
      return;
    }

    /** Если мы в режиме textarea и не передали кол-во строк, выводим ошибку */
    if (!this.rows && this.mode === 'textarea') {
      console.error(`В NzViewControlComponent с режимом textarea необходимо передавать кол-во строк.
      Будет установлено кол-во строк по умолчанию ${this.defaultRowsNumber}`);
    }

    this.setInternalData();
  }

  /** Установка внутренних данных для отображения */
  public setInternalData(): void {
    switch (this.mode) {
      case 'text':
      case 'textarea':
      default: {
        this.internalData = <string>this.data;
        break;
      }
      case 'tag': {
        this.internalData = <string[]>this.data;
        break;
      }
      case 'checkbox':
      case 'switch': {
        this.internalData = <boolean>this.data;
        break;
      }
    }
  }

  /** Обработчик нажатия на кнопку */
  public onClickHandler(): void {
    this.clickHandler.emit();
  }

  public ngAfterViewInit(): void {
    /** Для textarea устанавливаем кол-во строк */
    if (this.mode === 'textarea') {
      const textareaElement = this.textarea.nativeElement;
      textareaElement.setAttribute('rows', (this.rows || this.defaultRowsNumber).toString());
    }
  }
}
