<form [formGroup]="$any(controlContainer.control)">
  <div class="parameters-container">
    <div class="parameters__body" *ngIf="isChemical()">
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-select [options]="significantObjectOptions" formControlName="significantObjectId"></sc-select>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-select [options]="chemicalTypeOptions" formControlName="chemicalTypeId"></sc-select>
          </div>
        </div>
        <div class="bg-col-4">
          <div class="bg-form-group readonly-container">
            <div class="readonly-container__column">
              <label>Агрегатное состояние</label>
              <p>{{ aggregationStateText }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-input
              [options]="chemicalAmountOptions"
              formControlName="chemicalAmount"
            ></sc-input>
          </div>
        </div>
        <div class="bg-col col-vertical-center">
          <div class="bg-form-group checkbox-label-container">
            <sc-checkbox [options]="totalChemicalAmountOptions" formControlName="totalChemicalAmount"></sc-checkbox>
            <label class="checkbox-label">Общий запас АХОВ на объекте</label>
          </div>
        </div>
      </div>
      <div *ngIf="isLiquid()">
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group">
              <sc-select [options]="dischargeTypeOptions" formControlName="dischargeTypeId"></sc-select>
            </div>
          </div>
          <div class="bg-col">
            <div class="bg-form-group">
              <sc-input
                [options]="dischargeAreaOptions"
                formControlName="dischargeArea"
              ></sc-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="parameters__body" *ngIf="isRadioactive()">
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-select [options]="significantObjectOptions" formControlName="significantObjectId"></sc-select>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-input
              [options]="irradiationDoseOptions"
              formControlName="irradiationDose"
            ></sc-input>
          </div>
        </div>
      </div>
    </div>
    <div class="parameters__header">
      <h5>Метеоусловия в момент аварии</h5>
    </div>
    <div class="parameters__body">
      <div class="bg-row" *ngIf="canChangeMeteorologicalData">
        <div class="bg-col-4">
          <div class="bg-form-group">
            <button class="btn btn-sm btn-primary" type="button" (click)="getWeatherData()">
              Получить метеоусловия
            </button>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col-3">
          <div class="bg-form-group">
            <sc-input
              [options]="windVelocityOptions"
              formControlName="windVelocity"
            ></sc-input>
          </div>
        </div>
        <div class="bg-col-3">
          <div class="bg-form-group">
            <sc-input
              [options]="windDirectionOptions"
              formControlName="windDirection"
            ></sc-input>
          </div>
        </div>
        <div class="bg-col col-vertical-center">
          <div class="bg-form-group checkbox-label-container">
            <sc-checkbox [options]="isSnowyOptions" formControlName="isSnowy"></sc-checkbox>
            <label class="checkbox-label">Наличие снежного покрова</label>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col-3" *ngIf="isChemical()">
          <div class="bg-form-group">
            <sc-input
              [options]="airTemperatureOptions"
              formControlName="airTemperature"
            ></sc-input>
          </div>
        </div>
        <div class="bg-col-3">
          <div class="bg-form-group">
            <sc-select [options]="cloudStateOptions" formControlName="cloudStateId"></sc-select>
          </div>
        </div>
        <div class="bg-col-3">
          <div class="bg-form-group">
            <sc-select [options]="timeOfDayOptions" formControlName="timeOfDayId"></sc-select>
          </div>
        </div>
        <div class="bg-col col-vertical-center">
          <mat-icon
            aria-hidden="false"
            #tooltip="matTooltip"
            [matTooltip]="tooltipText"
            matTooltipPosition="right"
            matTooltipHideDelay="100000"
            [matTooltipClass]="'time-of-day-tooltip'"
            class="time-of-day-tooltip-icon"
          >
            info
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
</form>
