<ng-container *ngIf="!!form">
  <form [formGroup]="form" errorTailor id="idForTest">
	<nw-header [options]="headerActionsOptions" (clickButton)="onClickActionsButton($event)"></nw-header>
    <div class="information-statement-panel">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
            <mat-panel-title>
              <div class="header-container">
                <div>Параметры</div>
                <div class="header-container__divider">
                  <hr />
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="typeIdOptions" formControlName="typeId"></sc-select>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="themeIdOptions" formControlName="themeId"></sc-select>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="periodicityIdOptions" formControlName="periodicityId"></sc-select>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-textarea [options]="optionsDescription" formControlName="description"></sc-textarea>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col-6">
              <div class="bg-form-group">
                <bg-datetime
                  [options]="deadlineOptions"
                  formControlName="deadline"
                  [controlErrors]="deadlineErrors"
                ></bg-datetime>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
            <mat-panel-title>
              <div class="header-container">
                <div>Результат</div>
                <div class="header-container__divider">
                  <hr />
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-textarea [options]="optionsExecutorComment" formControlName="executorComment"></sc-textarea>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <div class="bg-form-group">
                  <multi-file
                    formControlName="documents"
                    [files]="informationStatement.documents"
                    [fileLimit]="1"
                    (filesChangeEvent)="onFilesChange($event)"
                  ></multi-file>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-textarea [options]="optionsRecipientComment" formControlName="recipientComment"></sc-textarea>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
            <mat-panel-title>
              <div class="header-container">
                <div>Автор</div>
                <div class="header-container__divider">
                  <hr />
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="authorOrganizationOptions" formControlName="authorOrganization"></sc-input>
              </div>
            </div>
          </div>
          <!-- TODO в случае указания долей столбцов (bg-col-7 bg-col-5) они не умещаются в одну строку -->
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="authorFioOptions" formControlName="authorFio"></sc-input>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="creationTimeOptions" formControlName="creationTime"></sc-input>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
            <mat-panel-title>
              <div class="header-container">
                <div>Исполнитель</div>
                <div class="header-container__divider">
                  <hr />
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select
                  [options]="executorOrganizationIdOptions"
                  formControlName="executorOrganizationId"
                ></sc-select>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="executorIdOptions" formControlName="executorId"></sc-select>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="executionTimeOptions" formControlName="executionTime"></sc-input>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
            <mat-panel-title>
              <div class="header-container">
                <div>Получатель</div>
                <div class="header-container__divider">
                  <hr />
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select
                  [options]="recipientOrganizationIdOptions"
                  formControlName="recipientOrganizationId"
                ></sc-select>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="recipientIdOptions" formControlName="recipientId"></sc-select>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="closeTimeOptions" formControlName="closeTime"></sc-input>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </form>
</ng-container>
