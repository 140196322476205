import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IScrollableContainerComponent } from '@smart-city/core/common';
import { IBaseDictionaryData } from '@smart-city/core/interfaces';
import { BaseComponent } from '@bg-front/core/components';
import { IMiniCardSelectionEvent } from '@bg-front/core/models/interfaces';
import { ISignificantObjectData } from '../../models/interfaces';
import { BgMapService } from '@bg-front/core/services';
import { SignificantObjectsService } from '../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Coordinates } from '@bg-front/core/models/classes';

/**
 * Компонент для отображения минимальной информации о важном объекте
 * Реализует интерфейс IScrollableContainerComponent,
 * что дает возможность использовать его в динамическом
 * скроллируемом контейнере (ScrollableContainer)
 */
@UntilDestroy()
@Component({
  selector: 'bg-significant-object-mini-card',
  templateUrl: './significant-object-mini-card.component.html',
  styleUrls: ['./significant-object-mini-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignificantObjectMiniCardComponent
  extends BaseComponent
  implements OnInit, OnDestroy, IScrollableContainerComponent {
  /** Информация о важном объекте */
  public significantObject: ISignificantObjectData;

  /** Координаты */
  public coordinates: string = undefined;

  /** Активная карточка */
  public isActive: boolean = false;

  /** Геттер состояния важного объекта */
  public get significantObjectState(): string {
    return (<IBaseDictionaryData>this.significantObject.state)?.name;
  }

  /** Входной параметр для инициализации полей */
  @Input()
  public set data(val: any) {
    this.coordinates = undefined;
    this.significantObject = val;
    if (new Coordinates(this.significantObject.coordinates).isValid()) {
      this.coordinates = this.significantObject.coordinates;
    } else if (this.significantObject.address?.latitude && this.significantObject.address?.longitude) {
      this.coordinates = new Coordinates(
        this.significantObject.address.latitude,
        this.significantObject.address.longitude,
      ).toString();
    }

    this.cdr.detectChanges();
  }

  /**
   *
   * @param cdr детектор изменений
   * @param gisService - GIS-сервис
   * @param significantObjectService - сервис для работы с важными объектами
   * @param route
   * @param router
   */
  constructor(
    private cdr: ChangeDetectorRef,
    private gisService: BgMapService,
    private significantObjectService: SignificantObjectsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    /** Подписка на выбор карточки происшествия в реестре */
    this.significantObjectService.significantObjectSelectItem$
      .pipe(untilDestroyed(this))
      .subscribe((significantObject: IMiniCardSelectionEvent) => {
        this.isActive = significantObject?.id === this.significantObject.id;
        if (this.isActive) {
          this.router.navigate(['significantObject', this.significantObject.id], {
            relativeTo: this.route.parent,
            queryParamsHandling: 'merge',
          });
        }
        this.cdr.detectChanges();
      });
  }

  /**
   * Обработчик, вызываемый при клике на адрес происшествия.
   * @param event - информация о событии
   */
  public onClickAddress(event: Event): void {
    if (!this.coordinates) {
      return;
    }
    event.preventDefault();
    this.gisService.setPositionMapOnCoordinates(this.coordinates);
    this.gisService.setClickMarker('Важные объекты', this.significantObject.id, new Coordinates(this.coordinates));
  }

  /** Обработчик, вызываемый при клике в любое место карточки, кроме строки адреса если она ссылка. */
  public onClickSignificantObject($event): void {
    if (!$event.defaultPrevented) {
      this.significantObjectService.selectSignificantObject(
        this.isActive ? undefined : { id: this.significantObject.id, docType: null },
      );
    }
  }
}
