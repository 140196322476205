import { Injectable } from '@angular/core';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { IAbstractServiceData, IAnyObject, IReportDataGroup, IReportEmergencyDto } from 'smart-city-types';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import {
  IAccidentOnChemicalObjectReport,
  IDestroyChemicalObjectData,
  IDestroyChemicalObjectReport,
} from '../../models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ForecastingBlowoutReportService {
  constructor(
    private readonly rest: RestService,
    private settings: Settings2Service,
    private xlsxUploader: HttpClient,
  ) {
  }

  /**
   * Формирование и скачивание отчета по расчёту зоны возможного химического заражения АХОВ
   */
  public buildForecastingChemicalReport(report: IAccidentOnChemicalObjectReport) {
    const wb = new Workbook();
    let ws2: Worksheet = undefined;
    let ws3: Worksheet = undefined;
    let ws4: Worksheet = undefined;
    let ws5: Worksheet = undefined;

    this.xlsxUploader.get(
      '/assets/report-templates/forecasting-chemical-report.xlsx',
      {
        responseType: 'arraybuffer',
      },
    ).subscribe((buffer) => {
      wb.xlsx.load(buffer).then((workbook) => {
        ws2 = workbook.getWorksheet('АХОВ_стр. 2');
        if (ws2) {
          const dateFormat = 'DD.MM.YYYY HH:mm:ss';

          /** Блок "Местоположение" */
          ws2.getCell(4, 2).value = this.prepareValue(report.address);
          ws2.getCell(6, 2).value = this.prepareValue(report.coordinates);
          ws2.getCell(7, 2).value = this.prepareValue(report.object);
          ws2.getCell(8, 2).value = this.prepareValue(report.responsible);
          ws2.getCell(9, 2).value = this.prepareValue(report.phone);

          /** Блок "Исходные данные" */
          ws2.getCell(14, 2).value = this.prepareValue(report.emergency);
          ws2.getCell(15, 2).value = this.prepareValue(report.chemicalType);
          ws2.getCell(16, 2).value = this.getCurrentTime(dateFormat);
          ws2.getCell(17, 2).value = this.prepareValue(report.timeToSpread);
          ws2.getCell(18, 2).value = this.prepareValue(report.chemicalAmount);
          ws2.getCell(19, 2).value = this.prepareValue(report.dischargeType);
          ws2.getCell(20, 2).value = this.prepareValue(report.dischargeArea);

          /** Блок "Метеоусловия в момент аварии" */
          ws2.getCell(4, 7).value = this.prepareValue(report.windVelocity);
          ws2.getCell(5, 7).value = this.prepareValue(report.windDirection);
          ws2.getCell(6, 7).value = this.prepareValue(report.isSnowy);
          ws2.getCell(7, 7).value = this.prepareValue(report.airTemperature);
          ws2.getCell(8, 7).value = this.prepareValue(report.cloudState);
          ws2.getCell(9, 7).value = this.prepareValue(report.timeOfDay);
          ws2.getCell(10, 7).value = this.prepareValue(report.chemicalForecastResults.verticalAtmosphereStability);

          let prompt = '';
          switch (report.timeOfDay) {
            case 'Утро':
              prompt = '* Утро = 2 ч. после восхода солнца.';
              break;
            case 'Вечер':
              prompt = '* Вечер = 2 ч. после захода солнца.';
              break;
            case 'День':
              prompt = `* День = период от восхода до захода
солнца (кроме двух утренних часов).`; // строка смещена в начало для корректного форматирования при подстановке в файл
              break;
            case 'Ночь':
              prompt = `* Ночь = период от захода до восхода
солнца (кроме двух вечерних часов).`; // строка смещена в начало для корректного форматирования при подстановке в файл
              break;
          }
          ws2.getCell(11, 6).value = this.prepareValue(prompt);

          /** Блок "Используемая методология для прогнозирования" */
          ws2.getCell(25, 1).value = this.prepareValue(report.methodology);
        }
        ws3 = workbook.getWorksheet('АХОВ_стр. 3');
        if (ws3) {
          ws3.getCell(3, 4).value =
            this.prepareValue(report.chemicalForecastResults.pollutionDepth?.toFixed(2));
          ws3.getCell(4, 4).value =
            this.prepareValue(report.chemicalForecastResults.pollutionZoneArea?.toFixed(2));
          ws3.getCell(5, 4).value =
            this.prepareValue(report.chemicalForecastResults.lethalityDuration?.toFixed(2));
        }
        ws4 = workbook.getWorksheet('АХОВ_стр. 4');

        if (ws4) {
          for (let i = 0; i < report.buildings.length; i++) {
            const el = report.buildings[i];
            const building = `${el.city}, ${el.street}, д.${el.houseNumber}`;
            ws4.getCell(i + 2, 1).value = this.prepareValue(building);
          }
        }
        ws5 = workbook.getWorksheet('АХОВ_стр. 5');

        if (ws5) {
          if (report.devices?.length) {
            const dateFormat = 'DD.MM.YYYY HH:mm:ss';
            report.devices.forEach((device: {
              type?: string;
              name?: string;
              currentSeanceName?: string;
              syncTime?: number;
            }, index: number) => {
              ws5.getCell(3 + index, 1).value = this.prepareValue(device.type);
              ws5.getCell(3 + index, 2).value = this.prepareValue(device.name);
              ws5.getCell(3 + index, 3).value = this.prepareValue(device.currentSeanceName);
              ws5.getCell(3 + index, 4).value = this.prepareDate(new Date(device.syncTime), dateFormat);
              // TODO добавить динамические стили для границ
              // ws5.getCell(3 + index, 1).style.border = ws5.getCell(3 + index - 1, 1).style.border;
              // ws5.getCell(3 + index, 2).style.border = ws5.getCell(3 + index - 1, 2).style.border;
              // ws5.getCell(3 + index, 3).style.border = ws5.getCell(3 + index - 1, 3).style.border;
              // ws5.getCell(3 + index, 4).style.border = ws5.getCell(3 + index - 1, 4).style.border;
            });
          } else {
            ws5.mergeCells(2, 1, 2, 4);
            ws5.getCell(2, 1).value = 'Не найдены средства оповещения в рассчитанной зоне';
          }
        }
        const dateFormat = 'DD.MM.YYYY HH_mm_ss';

        workbook.xlsx.writeBuffer().then((buf) => {
          saveAs(new Blob([buf]),
            `Расчёт зоны возможного химического заражения АХОВ_${this.getCurrentTime(dateFormat)}.xlsx`);
        });
      });
    });
  }

  /**
   * Формирование и скачивание отчета по расчёту зоны возможного химического заражения АХОВ
   */
  public buildForecastingChemicalDestructionReport(report: IDestroyChemicalObjectReport) {
    const wb = new Workbook();
    let ws2: Worksheet = undefined;
    let ws3: Worksheet = undefined;
    let ws4: Worksheet = undefined;
    let ws5: Worksheet = undefined;

    this.xlsxUploader.get(
      '/assets/report-templates/forecasting-chemical-destruction-report.xlsx',
      {
        responseType: 'arraybuffer',
      },
    ).subscribe((buffer) => {
      wb.xlsx.load(buffer).then((workbook) => {
        ws2 = workbook.getWorksheet('АХОВ_стр. 2');
        if (ws2) {
          const dateFormat = 'DD.MM.YYYY HH:mm:ss';

          /** Блок "Используемая методология для прогнозирования" */
          ws2.getCell(4, 1).value = this.prepareValue(report.methodology);

          /** Блок "Местоположение" */
          ws2.getCell(7, 2).value = this.prepareValue(report.address);
          ws2.getCell(9, 2).value = this.prepareValue(report.coordinates);
          ws2.getCell(10, 2).value = this.prepareValue(report.object);
          ws2.getCell(12, 2).value = this.prepareValue(report.responsible);
          ws2.getCell(13, 2).value = this.prepareValue(report.phone);

          /** Блок "Исходные данные" */
          ws2.getCell(17, 2).value = this.prepareValue(report.emergency);
          ws2.getCell(18, 2).value = this.getCurrentTime(dateFormat);
          ws2.getCell(19, 2).value = this.prepareValue(report.timeToSpread);
          let counter = 21;
          report.chemicalsData?.forEach((el: IDestroyChemicalObjectData) => {
            ws2.getCell(counter, 1).value = `${this.prepareValue(el.name)}`;
            const amount = +(el.amount);
            ws2.getCell(counter, 2).value = this.prepareValue(amount.toFixed(2));
            counter++;
          });

          /** Блок "Метеоусловия в момент аварии" */
          ws2.getCell(7, 7).value = this.prepareValue(report.windVelocity);
          ws2.getCell(8, 7).value = this.prepareValue(report.windDirection);
          ws2.getCell(9, 7).value = this.prepareValue(report.isSnowy);
          ws2.getCell(10, 7).value = this.prepareValue(report.airTemperature);
          ws2.getCell(11, 7).value = this.prepareValue(report.cloudState);
          ws2.getCell(12, 7).value = this.prepareValue(report.timeOfDay);
          ws2.getCell(13, 7).value = this.prepareValue(report.chemicalForecastResults.verticalAtmosphereStability);

          let prompt = '';
          switch (report.timeOfDay) {
            case 'Утро':
              prompt = '* Утро = 2 ч. после восхода солнца.';
              break;
            case 'Вечер':
              prompt = '* Вечер = 2 ч. после захода солнца.';
              break;
            case 'День':
              prompt = `* День = период от восхода до захода
солнца (кроме двух утренних часов).`; // строка смещена в начало для корректного форматирования при подстановке в файл
              break;
            case 'Ночь':
              prompt = `* Ночь = период от захода до восхода
солнца (кроме двух вечерних часов).`; // строка смещена в начало для корректного форматирования при подстановке в файл
              break;
          }
          ws2.getCell(14, 6).value = this.prepareValue(prompt);

        }
        ws3 = workbook.getWorksheet('АХОВ_стр. 3');
        if (ws3) {
          ws3.getCell(3, 2).value =
            this.prepareValue(report.chemicalForecastResults.pollutionDepth?.toFixed(2));
          ws3.getCell(4, 2).value =
            this.prepareValue(report.chemicalForecastResults.pollutionZoneArea?.toFixed(2));
          let counter = 6;
          report.chemicalsData?.forEach((el: IDestroyChemicalObjectData) => {
            ws3.getCell(counter, 1).value = this.prepareValue(el.name);
            ws3.getCell(counter, 2).value = this.prepareValue((+(el.lethalityDuration || 0)).toFixed(2));
            counter++;
          });
        }
        ws4 = workbook.getWorksheet('АХОВ_стр. 4');

        if (ws4) {
          for (let i = 0; i < report.buildings.length; i++) {
            const el = report.buildings[i];
            const building = `${el.city}, ${el.street}, д.${el.houseNumber}`;
            ws4.getCell(i + 2, 1).value = this.prepareValue(building);
          }
        }
        ws5 = workbook.getWorksheet('АХОВ_стр. 5');

        if (ws5) {
          if (report.devices?.length) {
            const dateFormat = 'DD.MM.YYYY HH:mm:ss';
            report.devices.forEach((device: {
              type?: string;
              name?: string;
              currentSeanceName?: string;
              syncTime?: number;
            }, index: number) => {
              ws5.getCell(3 + index, 1).value = this.prepareValue(device.type);
              ws5.getCell(3 + index, 2).value = this.prepareValue(device.name);
              ws5.getCell(3 + index, 3).value = this.prepareValue(device.currentSeanceName);
              ws5.getCell(3 + index, 4).value = this.prepareDate(new Date(device.syncTime), dateFormat);
              // TODO добавить динамические стили для границ
              // ws5.getCell(3 + index, 1).style.border = ws5.getCell(3 + index - 1, 1).style.border;
              // ws5.getCell(3 + index, 2).style.border = ws5.getCell(3 + index - 1, 2).style.border;
              // ws5.getCell(3 + index, 3).style.border = ws5.getCell(3 + index - 1, 3).style.border;
              // ws5.getCell(3 + index, 4).style.border = ws5.getCell(3 + index - 1, 4).style.border;
            });
          } else {
            ws5.mergeCells(2, 1, 2, 4);
            ws5.getCell(2, 1).value = 'Не найдены средства оповещения в рассчитанной зоне';
          }
        }

        const dateFormat = 'DD.MM.YYYY HH_mm_ss';
        workbook.xlsx.writeBuffer().then((buf) => {
          saveAs(new Blob([buf]),
            `Расчёт зоны возможного химического заражения АХОВ_${this.getCurrentTime(dateFormat)}.xlsx`);
        });
      });
    });
  }

  /** Получить текущее время */
  public getCurrentTime(dateFormat: string): string {
    return moment().format(dateFormat);
  }

  /** Возвращает дату в нужном формате (ex. 'DD.MM.YYYY hh:mm:ss') */
  private prepareDate(d: Date, dateFormat: string): string {
    return moment(d).format(dateFormat);
  }

  /**
   * Получение кэша ранее записанных данных формы донесения
   * по ID проишествия и типу донесения
   */
  public getReportCashByEmergencyIdAndReportTypeId(
    emergencyId: string,
    reportTypeId: string,
  ): Observable<IReportEmergencyDto> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Report' },
      entity: {
        query: {
          emergencyId,
          typeId: reportTypeId,
        },
        name: 'ReportEmergency',
      },
    })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items[0];
        }),
      );
  }

  /** Получение ЧС по ID */
  public getEmergencyById(id: string): Observable<IAnyObject> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Emergency' },
      entity: {
        query: {
          id,
        },
        name: 'Emergency',
        attributes: [
          'id',
          'incidentTypeId.name',
          'addressFact',
          'organization.mo.name',
          'organization.mo.municipal.name',
          'timeCreate',
          'point',
          'description',
          'resolution',
          'fireParams',
          'forecastingDetailId.params',
          'totalRegistryPeopleHouse',
          'totalTemporalPeopleHouse',
          'coordinates',
        ],
      },
    }).pipe(
      map((response: IAbstractServiceData) => {
        return response?.data?.items[0];
      }),
    );
  }

  /** Получение параметров прогнозирования */
  public getForecastingParamsByEmergencyId(id: string): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Forecasting' },
      entity: {
        query: { id },
        name: 'ForecastingDetail',
      },
    });
  }

  /**
   * Проверяет наличие строк-параметров со значением у строки-сабтайтла или есть ли значение у строки-параметра
   */
  private isNotEmpty(data: IReportDataGroup[], groupIndex: number, recordIndex: number = undefined): boolean {
    // Если не передан индекс строки-параметра, то имеем дело со строкой-сабтайтлом
    if ([null, undefined].includes(recordIndex)) {
      // Это внешний сабтайтл
      if (data[groupIndex].type === 'subtitle') {
        // Проверяем у внешнего сабтайтла существование текста
        if (['', null, undefined].includes(data[groupIndex].name)) {
          return false;
        }
        // Проверяем у внешнего сабтайтла существование непустых аккордионов
        for (let i = groupIndex + 1; i < data.length; i++) {
          // Если дошли до следующего внешнего сабтайтла
          if (data[i].type === 'subtitle') {
            return false;
          }
          if (this.isNotEmpty(data, i)) {
            return true;
          }
        }
        return false;
      }
      // Это аккордион
      // Проверяем у аккордиона существование непустых параметров
      for (let i = 0; i < data[groupIndex].fields.length; i++) {
        if (this.isNotEmpty(data, groupIndex, i)) {
          return true;
        }
      }
      return false;
    }
    // Имеем дело со строкой-параметром или внутренним сабтайтлом
    // Это внутренний сабтайтл
    if (data[groupIndex].fields[recordIndex].type === 'subtitle') {
      // Проверяем у внутреннего сабтайтал существование непустых параметров
      for (let i = recordIndex + 1; i < data[groupIndex].fields.length; i++) {
        // Если дошли до следующего внутреннего сабтайтла
        if (data[groupIndex].fields[i].type === 'subtitle') {
          return false;
        }
        if (this.isNotEmpty(data, groupIndex, i)) {
          return true;
        }
      }
      return false;
    }
    // Это параметр-значение
    return !['', null, undefined].includes(data[groupIndex].fields[recordIndex].value);
  }

  /**
   * Подготовка значения для вывода в таблицу
   * @param value значение
   * @param defaultValue значение по умолчанию
   */
  private prepareValue(value: any, defaultValue?: string): string {
    if (value === null || value === undefined || value === '') {
      return defaultValue || '–––';
    }
    return value.toString();
  }
}
