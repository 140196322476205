<h3 class="main-header">
  <mat-icon class="header-icon">track_changes</mat-icon>
  Результаты прогноза
</h3>
<hr/>
<div class="bg-row">
  <div class="bg-col">
    <ul class="results-list">
      <li class="results-list-item">
        <label> Время подхода рад. облака (ч):</label>
        <label class="results-list-item--var">
          {{result?.approachTime | commaToDot | number: '0.2-2'}}
        </label>
      </li>
    </ul>
  </div>
</div>
