import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { BaseComponent, MapDialogV2Component } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzModalService } from 'ng-zorro-antd/modal';

@UntilDestroy()
@Component({
  selector: 'bg-overcoming-path-point',
  templateUrl: './overcoming-path-point.component.html',
  styleUrls: ['./overcoming-path-point.component.scss'],
})
export class OvercomingPathPointComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Форма */
  public form: FormGroup;

  @Input()
  public canDelete: boolean = false;

  @Input()
  public unit: string;

  @Output()
  public removeItem: EventEmitter<void> = new EventEmitter<void>();

  /** @ignore */
  constructor(private readonly modalService: NzModalService, private readonly controlContainer: ControlContainer) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }

  /**
   * Обработчик клика по кнопке Уточнить адрес
   * @param $event
   */
  public onClickSpecifyAddress($event) {
    const exactCoordinates = new Coordinates(this.form.controls.coordinates.value);

    const data = {
      coordinates: exactCoordinates,
    };
    this.openSpecifyMap(data);
  }

  /**
   * Метод который обеспечивает открытие диалога и подписку на закрытие
   * @param data - параметры для диалога
   */
  public openSpecifyMap(data: { coordinates?: Coordinates }): void {
    const modal = this.modalService.create({
      nzTitle: 'Укажите координату',
      nzContent: MapDialogV2Component,
      nzComponentParams: data,
      nzWidth: '600px',
    });

    let subs = modal.afterClose.pipe(untilDestroyed(this)).subscribe((params: any) => {
      if (params?.coordinates) {
        this.form.controls.coordinates.setValue(`${params.coordinates.join(', ')}`);
      }

      subs.unsubscribe();
      subs = undefined;
    });
  }

  /** Удаляем объект */
  public remove() {
    this.removeItem.emit();
  }
}
