import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseComponent } from '@bg-front/core/components';
import { IScFias3Options, IScInputOptions, IScTextButtonOptions } from '@smart-city/core/common';
import { formatPhoneDb } from '@smart-city/core/utils';
import { takeUntil } from 'rxjs/operators';

import { CallService } from '../../../call/services';
import { IDeclarantInfoFormOptions, IDeclarerForm } from '../../models/interfaces';
import { DeclarerService, DeclarerStoreService } from '../../services';

/** Компонент для отображения блока Информация о заявители в Опросе */

@Component({
  selector: 'bg-declarer-info-form',
  templateUrl: './declarer-info-form.component.html',
  styleUrls: ['./declarer-info-form.component.scss'],
})
export class DeclarerInfoFormComponent extends BaseComponent implements OnInit {
  @Input() options: IDeclarantInfoFormOptions;

  /** Форма */
  public form: FormGroup;

  /** Настройки для отображения Номера абонента */
  public phoneSubscriberOptions: IScInputOptions = {
    type: 'tel',
    label: 'Номер абонента',
    name: 'phoneSubscriber',
    prefixIcon: 'phone',
    placeholder: 'Номер абонента',
  };

  /** Настройки для отображения ФИО */
  public personalInfoOptions: IScInputOptions = {
    label: 'ФИО',
    name: 'personalInfo',
    placeholder: 'ФИО',
  };

  /** Настройки для отображения Внутренний телефон */
  public contactPhoneOptions: IScInputOptions = {
    type: 'tel',
    label: 'Контактный номер',
    name: 'contactPhone',
    placeholder: 'Контактный номер',
    // prefix: '+7 ',
    // mask: '000 000-00-00',
  };

  /** Настройки для отображения Внутренний телефон */
  public addressOptions: IScFias3Options = {
    label: 'Адрес',
    isCoordinatesNecessary: true,
  };

  /** Настройки для отображения Внутренний телефон */
  public addressOptionsDisabled: IScInputOptions = {
    label: 'Адрес',
    disabled: true,
  };

  /**
   * Настройка компоненты Корпус
   */
  public corpOptions: IScInputOptions = {
    label: 'Корпус',
    maxLength: 10,
  };

  /**
   * Настройка компоненты Подъезд
   */
  public entranceOptions: IScInputOptions = {
    label: 'Подъезд',
    mask: '0*',
  };

  /**
   * Настройка компоненты Квартира
   */
  public roomOptions: IScInputOptions = {
    label: 'Квартира',
    maxLength: 10,
  };

  public copyAddressOptions: IScTextButtonOptions = <IScTextButtonOptions>{
    title: 'Адрес КСиП',
    color: 'primary',
  };

  public addressText;

  /** Хранит номер абонента и контактный номер */
  public declarerPhone: string;
  public contactPhone: string;

  /** Возможность редактирования поля Номер телефона */
  public isDeclarerEditable: boolean = true;

  constructor(
    private readonly declarer: DeclarerService,
    private readonly declarerStore: DeclarerStoreService,
    private readonly callService: CallService,
  ) {
    super();
  }

  /** @ignore */
  ngOnInit(): void {
    this.addressText = this.options?.model?.address?.fullText;
    this.declarerPhone = this.declarer.sliceFirstNumber(this.options?.model?.phone);
    this.contactPhone = this.options?.model?.contactPhone
      ? this.declarer.sliceFirstNumber(this.options?.model?.contactPhone)
      : this.declarerPhone;
    this.setPhoneFieldsState();

    const address = this.options.disabled ? this.options?.model?.address?.fullText : this.options?.model?.address;
    this.form = new FormGroup({
      phone: new FormControl(this.declarerPhone, []),
      fio: new FormControl(this.options?.model?.fio, []),
      contactPhone: new FormControl(this.contactPhone),
      address: new FormControl(address),
      corp: new FormControl(this.options?.model?.building?.corp),
      entrance: new FormControl(this.options?.model?.building?.entrance, []),
      room: new FormControl(this.options?.model?.building?.room, []),
    });

    if (this.options?.model) {
      this.initForm();
    }

    this.declarer.$formData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: IDeclarerForm) => {
      if (result) {
        this.declarerPhone = result.phone;
        result.phone = this.declarer.sliceFirstNumber(formatPhoneDb(result.phone));
        result.contactPhone = this.declarer.sliceFirstNumber(formatPhoneDb(result.contactPhone)) || result.phone;
        this.contactPhone = result.contactPhone;
        this.setPhoneFieldsState();
        const clearData = JSON.parse(JSON.stringify(result));
        const newValue = {
          ...this.form.value,
          ...clearData,
          ...clearData.building,
        };
        delete newValue.building;
        newValue.address = (this.options.disabled ? result.address?.fullText : result?.address) || null;
        this.form.setValue(newValue);
        this.initForm();
        if (this.form.value.phone) {
          this.form.controls.phone.disable({ emitEvent: false });
        }
      }
    });

    if (this.options.disabled) {
      this.form.disable();
    }
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.initForm();
      this.addressText = (this.form.value.address || {}).fullText;
    });
  }
  /**
   * Обработчик по клику скопировать адресс
   * @return
   */
  public copyHandler(): void {
    const address = this.declarerStore.getKsipAddress();
    const buildingInfo = this.declarerStore.getBuildingInfo();

    this.form.controls.room.patchValue(buildingInfo?.room);
    this.form.controls.corp.patchValue(buildingInfo?.corp);
    this.form.controls.entrance.patchValue(buildingInfo?.entrance);

    if (typeof address === 'object') {
      this.form.controls.address.setValue(address);
      this.noteService.pushInfo('Адрес КСиП скопирован в адрес заявителя');
    }
  }

  private initForm() {
    const phone = this.form.value.phone || this.declarer.sliceFirstNumber(this.declarerStore.getLoadedPhone());
    const data = {
      phone: this.isPhoneHaveElevenNumbers(phone || '') ? phone : '',
      fio: this.form.value.fio,
      contactPhone: this.isPhoneHaveElevenNumbers(this.form.value.contactPhone || '')
        ? this.form.value.contactPhone
        : '',
      address: this.form.value.address,
      building: {
        corp: this.form.value.corp,
        entrance: this.form.value.entrance,
        room: this.form.value.room,
      },
    };
    this.declarerStore.setFormData(data);
  }

  private isPhoneHaveElevenNumbers(phone): boolean {
    return (formatPhoneDb(phone) || '').length === 11;
  }

  /** Выставить состояние полей для номера телефона в зависимости от состояния открытого события */
  private setPhoneFieldsState(): void {
    if (this.declarerPhone || this.options.disabled) {
      this.phoneSubscriberOptions.readonly = true;
      this.isDeclarerEditable = false;
    }

    if (this.options.disabled) {
      this.contactPhoneOptions.readonly = true;
    }
  }

  /** Вызов по клику на Номер телефона */
  public onClickDeclarerPhone(phone: string): void {
    // Если открыта форма необработанного события, при клике вызов не происходит
    if (this.isDeclarerEditable) return;
    this.callService.call(formatPhoneDb(phone));
  }

  /** Вызов по клику на Контактный номер */
  public onClickContactPhone(phone: string): void {
    // Если событие открыто на создание/редактирование, при клике вызов не происходит
    if (!this.options.disabled) return;
    this.callService.call(formatPhoneDb(phone));
  }
}
