import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BgCoreModule } from '@bg-front/core';
import { ForecastingBlowoutModule } from '@bg-front/forecasting-blowout';
import { ForecastingFiresModule } from '@bg-front/forecasting-fires';
import { ForecastingRadioactiveEnvironmentModule } from '@bg-front/forecasting-radioactive-environment';
import { ForestryFacilitiesModule } from '@bg-front/forestry-facilities';
import { KseonUnitsModule } from '@bg-front/kseon-units';
import { SignificantObjectsModule } from '@bg-front/significant-objects';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { ScComponentsModule } from '@smart-city/core/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

import {
  ForecastingFormComponent,
  ForecastingResultsComponent,
  ForecastingModalFormComponent,
} from './components';
import { ForecastingService } from './services';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ScComponentsModule,
    ForecastingBlowoutModule,
    KseonUnitsModule,
    ForecastingRadioactiveEnvironmentModule,
    BgCoreModule,
    SignificantObjectsModule,
    NzButtonModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzModalModule,
    NzPopconfirmModule,
    NzSelectModule,
    NzSwitchModule,
    NzTagModule,
    NzToolTipModule,
    NzTypographyModule,
    ForestryFacilitiesModule,
    NzDividerModule,
    ErrorTailorModule,
    ForecastingFiresModule,
  ],
  declarations: [ForecastingFormComponent, ForecastingResultsComponent, ForecastingModalFormComponent],
  exports: [ForecastingFormComponent, ForecastingResultsComponent],
  providers: [ForecastingService],
})
export class ForecastingModule {}
