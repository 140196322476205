import { EmergencyEventDto } from './event.dto';
import { IDeclarer } from '../interfaces';
import { IFileInfoDto, IAnyObject, ILifeCycleStepDto } from 'smart-city-types';

export class EmergencyCallEventDto extends EmergencyEventDto {
  /** Дата и время КСиП */
  public ksipTime: number = undefined;
  /** Привлекаемые службы */
  public attractionServices: string[] = [];
  /** Привлекаемые организаций */
  public attractionOrganizations: IAnyObject[] = [];
  /** Угроза организациям */
  public threatOrganization = false;
  /** Угроза населению */
  public threatPopulation = false;
  /** Номер инцидента */
  public incidentNumber?: string;
  /** Шаг ЖЦ инцидента */
  public lifeCycleStepId?: string | ILifeCycleStepDto;
  /** Данные о заявителе */
  public declarerId: IDeclarer = undefined;
  /**  Координаты кнопки утачнить адрес */
  public exactCoordinates = undefined;
  /** Документы */
  public documents: IFileInfoDto[] = [];
  /** Тестовое */
  public isTest: boolean = undefined;
  /** Срочно */
  public urgently: boolean = undefined;
}
