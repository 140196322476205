<nw-header [options]="headerOptions" (clickButton)="onClickHeaderButton($event)"></nw-header>
<div class="registry-panel" *ngIf="!selectRegistry">
    <sc-button
      *ngFor="let registry of registries"
      class="registry-panel__button"
      [options]="{
        title: registry.name
      }"
      [ngStyle]="{ 'background-image': 'url(\'data:image/svg+xml;utf8,' + registry.icon + '\')' }"
      (clickButton)="onClickButton(registry)"
    >
    </sc-button>
</div>
<ng-container #panelContainer></ng-container>
