import { Injectable } from '@angular/core';
import { IDeclarer } from '../../models/interfaces';
import { GarFindFlatResponseElement } from '@bg-front/core';

/** Сервис для Хранения данных, которые используются в блоке Информация о заявители */

@Injectable({
  providedIn: 'root',
})
export class DeclarerStoreService {
  private formData: IDeclarer = null;
  /** Номер телефона заявителя определенный при инициализации формы */
  private loadedPhone: string = '';
  /** адресс ксип */
  private ksipAddress: GarFindFlatResponseElement = null;
  /** Информация о строении */
  private buildingInfo: { room: string; entrance: string; corp: string } = { room: null, entrance: null, corp: null };

  /** Сохранение данных формы в formData  */
  public setFormData(value: IDeclarer) {
    this.formData = value;
  }

  /**
   * Сохраненние телефона заявителя при открытии формы
   * @param phone - телефон
   * @return
   */
  public setLoadedPhone(phone: string): void {
    this.loadedPhone = phone;
  }

  /**
   * Получение телефона заявителя при открытии формы
   * @return
   */
  public getLoadedPhone(): string {
    return this.loadedPhone;
  }

  /** Получение данных формы из formData  */
  public getFormData(): IDeclarer {
    return this.formData;
  }

  /** Сохранение данных формы в formData  */
  public setKsipAddress(value: GarFindFlatResponseElement) {
    this.ksipAddress = value;
  }

  /** Получение данных формы из formData  */
  public getKsipAddress(): GarFindFlatResponseElement {
    return this.ksipAddress;
  }

  /** Сохранение данных формы в buildingInfo  */
  public setBuildingInfo(value: { room: string; entrance: string; corp: string }) {
    this.buildingInfo = value;
  }

  /** Получение данных формы из buildingInfo  */
  public getBuildingInfo(): { room: string; entrance: string; corp: string } {
    return this.buildingInfo;
  }
}
