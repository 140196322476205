import { Observable, throwError, timer } from 'rxjs';
import { retryWhen, switchMap, tap } from 'rxjs/operators';

export {
  retryWithDelay,
};
/** Интерфейс описывает настройки retryWithDelay */
interface IRetryStrategyConfig {
  /** Задержка в миллисекундах. Дефолт 1000ms */
  delay?: number;
  /** Максимальное количество попыток. 0 - бесконечно */
  maxRetryAttempts?: number;
  /** Коэффициент скалирования интервала между попытками. Дефолт 1 */
  scalingFactor?: number;
  /** Каждая успешная попытка сбрасывает текущий счетчик попыток. Дефолт: true */
  resetRetryCountOnEmission?: boolean;
  /** Событие срабатывающее перед каждой попыткой */
  onRetry?: (attempt: number, delay: number, error: any) => void;
}

/**
 * Повтор потока в случае падения из-за ошибки
 * @param params Параметры повторения
 */
function retryWithDelay(params: IRetryStrategyConfig = {
  delay: 1000,
  maxRetryAttempts: 3,
  scalingFactor: 1,
  resetRetryCountOnEmission: true,
  onRetry: null,
}): <T>(source: Observable<T>) => Observable<T> {
  const options = {
    delay: 1000,
    maxRetryAttempts: 3,
    scalingFactor: 1,
    resetRetryCountOnEmission: true,
    onRetry: null,
    ...params,
  };
  return <T>(source: Observable<T>): Observable<T> => {
    let attempt = 0;
    return source.pipe(
      retryWhen((attempts: Observable<any>) => {
        return attempts.pipe(
          switchMap((response) => {
            // Если число попыток изчерпано, вернуть ошибку(throw error)
            attempt += 1;
            if (attempt > options.maxRetryAttempts && options.maxRetryAttempts !== 0) {
              return throwError(response);
            }
            const delay = options.delay * options.scalingFactor ** (attempt - 1);

            // Вызовем событие
            if (options.onRetry) {
              options.onRetry.apply(null, [attempt, delay, response]);
            }
            // Повтор после 1сек, 2сек, и т.д...
            return timer(delay);
          }),
        );
      }),
      tap(() => {
        if (options.resetRetryCountOnEmission) {
          attempt = 0;
        }
      }),
    );
  };
}
