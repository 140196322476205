import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

import { FireMonitoringServersState } from '../../../models/states';

/**
 * Сервис хранилища Серверы пожарного мониторинга
 */
@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'fire-monitoring-servers-store', resettable: true })
export class FireMonitoringServersStore extends EntityStore<FireMonitoringServersState> {
  constructor() {
    super([]);
  }
}
