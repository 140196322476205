import { Component, Injectable, OnInit } from '@angular/core';
import { RippleGlobalOptions } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

/**
 * Глобально отключает ripple.
 */
@Injectable({ providedIn: 'root' })
export class AppGlobalRippleOptions implements RippleGlobalOptions {
  public disabled: boolean = true;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit{
  title = 'BG';

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title) {}

  public ngOnInit(): void {
    // смотрим на все события роутера
    this.router.events
      .pipe(
        // фильтруем по событию NavigationEnd
        filter(event => event instanceof NavigationEnd),
        // переключаем поток на сервис activatedRoute
        map(() => this.activatedRoute),
        // нас интересуют только корневые роуты (это опционально)
        map((route: ActivatedRoute) => {
          let currRoute = route;
          while (currRoute.firstChild) {
            currRoute = currRoute.firstChild;
          }
          return currRoute;
        }),
        // выбираем title
        mergeMap((route) => {
          return route.data;
        }),
      )
      // задаем
      .subscribe((data) => {
        this.titleService.setTitle(data?.title as string || 'АПК Безопасный город');
      });
  }
}
