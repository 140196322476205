import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScComponentsModule } from '@smart-city/core/common';
import { TasksRoutingModule } from './tasks-routing.module';
import { TaskLayoutComponent } from './task-layout/task-layout.component';
import { TasksLayoutComponent } from './tasks-layout/tasks-layout.component';
import { TaskDialogComponent } from './task-dialog/task-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  entryComponents: [
    TaskDialogComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    ScComponentsModule,
    TasksRoutingModule,
  ],
  declarations: [
    TaskDialogComponent,
    TaskLayoutComponent,
    TasksLayoutComponent,
  ],
})
export class TasksModule { }
