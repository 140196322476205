import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IScSelectOptions, IScTextareaOptions, IScTextButtonOptions } from '@smart-city/core/common';
import {
  IEmergencyResponsePlanStep,
  IOrganization,
  IOrganizationType,
  IStepDialogDataOptions
} from "../../models/interfaces";
import { BaseComponent } from '@bg-front/core/components';
import { OrganizationsService, ResponsePlanStepService } from "../../services";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Settings2Service } from '@smart-city/core/services';
import { IEmergencyDto } from 'smart-city-types';
import { FormModal } from "@bg-front/core/models/classes";
import { InvolveOrgWithoutCommissionDialogComponent } from "../involve-org-without-commission-dialog/involve-org-without-commission-dialog.component";
import { InvolveOrgWithoutInteractionDialogComponent } from "../involve-org-without-interaction-dialog/involve-org-without-interaction-dialog.component";
import { forkJoin } from "rxjs";
import { catchError } from 'rxjs/operators';

/**
 * Форма запроса на привлечение службы к реагированию.
 * Вызывается в случае если организация не может сама привлекать сторонние организации к реагированию.
 */
@UntilDestroy()
@Component({
  selector: 'org-involve-request-form',
  templateUrl: './org-involve-request-form.component.html',
  styleUrls: ['./org-involve-request-form.component.scss'],
})
export class OrgInvolveRequestFormComponent extends BaseComponent implements OnInit {
  /** Форма */
  public form: FormGroup;

  /** Происшествие для которого создается шаг */
  @Input() public options: IStepDialogDataOptions;

  @Output()
  public closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  /** Флаг указывает на то, что зарос отклонен */
  public rejected: boolean = false;

  /** Флаг указывает на то, что необходимо скрыть кнопки */
  public hideActions: boolean = false;

  /** Настройки компоненты Тип службы реагирования */
  public serviceTypeOptions: IScSelectOptions = {
    title: 'Тип службы реагирования *',
    clearable: true,
    service: 'Admin',
    entity: 'OrgTypeParams',
    modern: true,
    fieldName: 'shortName',
    query: {
      active: true,
      attractToReact: true,
    },
  };

  /** Настройки компоненты Комментарий */
  public commentOptions: IScTextareaOptions = {
    label: 'Комментарий',
    maxLength: 1000,
    rows: 5,
  };

  /** Настройки компоненты Причина отказа */
  public rejectionReasonOptions: IScTextareaOptions = {
    label: 'Причина отказа',
    maxLength: 1000,
    rows: 5,
  };

  /** Настройки для кнопки с логикой ОК */
  public okBtnOptions: IScTextButtonOptions = {
    title: '',
    color: 'primary',
    disabled: true,
  };
  /** Настройки для кнопки с логикой отмена */
  public cancelBtnOptions: IScTextButtonOptions = {
    title: '',
  };

  constructor(
    private readonly organizationsService: OrganizationsService,
    private readonly responsePlanStepService: ResponsePlanStepService,
    private readonly settings: Settings2Service,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      serviceType: new FormControl(
        (<IOrganizationType>this.options.step?.serviceType)?.id || <string>this.options.step?.serviceType,
        [Validators.required],
      ),
      comment: new FormControl(this.options.step?.operatorComment),
      rejectionReason: new FormControl(),
    });

    this.okBtnOptions.title = this.options.isInvolveThroughEdds ? 'Запросить' : 'Принять';
    this.cancelBtnOptions = this.options.isInvolveThroughEdds
      ? { title: 'Отмена' }
      : { title: 'Отклонить', color: 'accent' }

    // Скрыть кнопки если шаг находится на рассмотрении и его просматривает инициатор или если шаг отклонен
    this.hideActions = (
      this.settings.getDictionaryById(this.options.step?.status)?.sysname === 'consideration'
      && this.options.isInvolveThroughEdds
    ) || this.settings.getDictionaryById(this.options.step?.status)?.sysname === 'fail';

    if (this.options.disabled) this.form.disable();

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.form?.value?.serviceType) {
        this.okBtnOptions.disabled = false;
      }
    });

    if (this.options.isInvolveThroughEdds && !this.options.disabled) {
      this.organizationsService.getOrganizationBy(this.options.emergencyModel.organization)
        .pipe(untilDestroyed(this))
        .subscribe((organization: IOrganization) =>
          this.form.controls.comment.setValue(`Запрос от организации: ${organization.name}`,
        ));
    }
  }

  public cancelHandler() {
    // Если форма открыта для заполнения причины отказа, то закрыть форму
    if (this.rejected) this.closeEvent.emit();
    // Условие определяет, что запрос просматривает согласующая организация.
    // Надо перевести форму в вид для заполнения причины отказа
    if (!this.options.isInvolveThroughEdds) {
      this.rejected = true;
      this.form.controls.rejectionReason.enable();
      this.okBtnOptions = { ...this.okBtnOptions, title: 'Сохранить' };
      this.cancelBtnOptions = { title: 'Отмена' };
      return;
    }
    // Если условия выше не отработали, то закрыть форму
    this.closeEvent.emit();
  }

  public okHandler() {
    // Отказано в привлечении службы
    const rejectionReason = this.form.controls.rejectionReason.value || 'Причина отказа: не указана';
    if (this.rejected) {
      this.responsePlanStepService.updateStepItemById(
        this.options.step.id,
        {
          status: this.settings.getDictionaryByTypeAndSysName('responseStepStatus', 'fail')?.id,
          operatorComment: `${this.form.controls.comment.value}\n${rejectionReason}`,
          startTimeStep: Date.now(),
          finishTimeStep: Date.now(),
        },
        'involvedOrgOpts',
      ).pipe(
        catchError((err: Error) => this.catchErrorFn(err, err.message)),
        untilDestroyed(this),
      ).subscribe(() => this.closeEvent.emit());
      return;
    }

    // Условие определяет, что запрос просматривает согласующая организация.
    // Надо открыть соответствующе диалоги для привлечения службы к реагированию
    if (!this.options.isInvolveThroughEdds) {
      // Запрос параметров типа привлекаемой службы, для определения информационного взаимодействия и
      // существующего шага реагирования для привлекаемой службы с целью получения времени реагирования.
      forkJoin([
        this.organizationsService.getAttractReactOrganizationById(this.form.controls.serviceType.value),
        this.responsePlanStepService.getLastEmergencyStepForService(
          this.options.emergencyModel.id,
          this.form.controls.serviceType.value
        )
      ]).pipe(untilDestroyed(this)).subscribe((
        [organizationType, responsePlanStep]: [IOrganizationType, IEmergencyResponsePlanStep]
      ) => {
        const dialog = new FormModal(
          organizationType.informationInteraction
            ? InvolveOrgWithoutCommissionDialogComponent
            : InvolveOrgWithoutInteractionDialogComponent,
          {
            width: organizationType.informationInteraction ? '477px' : undefined,
            panelClass: 'org-panel',
          },
          {
            step: { ...this.options.step, timeReact: responsePlanStep?.timeReact },
            title: 'Запрос на привлечение службы',
            emergencyModel: organizationType.informationInteraction ? this.options.emergencyModel : undefined,
            entity: 'involvedOrgOpts',
          }
        );
        dialog.open();
      })
      this.closeEvent.emit();
      return;
    }

    // Условия выше на отработали. Создать запрос на привлечение службы к реагированию в родительском происшествии
    this.responsePlanStepService.createSteps(
      [{
        number: 1,
        status: this.settings.getDictionaryByTypeAndSysName('responseStepStatus', 'consideration')?.id,
        attractOrgStatus: this.settings.getDictionaryByTypeAndSysName('statusNotificationAttractOrg', 'inProgress')?.id,
        emergencyId: (<IEmergencyDto>this.options.emergencyModel.parentId)?.id ||
          <string>this.options.emergencyModel.parentId,
        serviceType: this.form.controls.serviceType.value,
        operatorComment: this.form.controls.comment.value,
        stepType: this.settings.getDictionaryByTypeAndSysName('stepType', 'auto')?.id,
        isExpired: false,
      }],
      'involvedOrgOpts'
    ).pipe(
      catchError((err: Error) => this.catchErrorFn(err, err.message)),
      untilDestroyed(this),
    ).subscribe(() => this.closeEvent.emit());
  }
}
