<form nz-form [formGroup]="searchForm" nzLayout="vertical">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Состояние</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="state" nzSize="large" [nzAllowClear]="true" nzShowSearch>
            <nz-option
              [nzValue]="state.id"
              [nzLabel]="state.text"
              *ngFor="let state of stateList">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Тип</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="type" nzSize="large" [nzAllowClear]="true" nzShowSearch>
            <nz-option
              [nzValue]="type.id"
              [nzLabel]="type.text"
              *ngFor="let type of typeList">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Категория</nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="category"
            nzSize="large"
            [nzAllowClear]="true"
            nzShowSearch
          >
            <nz-option
              [nzValue]="category.id"
              [nzLabel]="category.name"
              *ngFor="let category of categoryList$ | async">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Муниципальное образование</nz-form-label>
        <nz-form-control>
          <nz-select
            formControlName="mo"
            nzSize="large"
            [nzAllowClear]="true"
            nzShowSearch
          >
            <nz-option
              [nzValue]="mo.id"
              [nzLabel]="mo.name"
              *ngFor="let mo of moList$ | async">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row *nzModalFooter>
    <div nz-col [nzSpan]="12" class="d-flex">
      <button nz-button nzType="default" (click)="clear()">Очистить</button>
    </div>
    <div nz-col [nzSpan]="12">
      <button nz-button nzType="default" (click)="close()">Отмена</button>
      <button nz-button nzType="primary" (click)="search()">
        Фильтровать
      </button>
    </div>
  </div>
</form>
