import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

import { FireMonitoringMessageTypeState } from '../../../models/states';

/**
 * Сервис хранилища извещений пожарного мониторинга
 */
@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'fire-monitoring-message-type-store', resettable: true })
export class FireMonitoringMessageTypeStore extends EntityStore<FireMonitoringMessageTypeState> {
  constructor() {
    super([]);
  }
}
