<form nz-form [formGroup]="form" errorTailor id="idForTest" nzLayout="vertical" *ngIf="form">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <ng-template #mainParams>
        <h4 class="m-b-0">Основные параметры расчёта</h4>
      </ng-template>
      <nz-divider [nzText]="mainParams" nzOrientation="left"></nz-divider>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="distanceToReactor">Расстояние по оси следа облака</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="distanceToReactor">
            <input nzSize="large" nz-input formControlName="distanceToReactor" />
          </nz-input-group>
          <ng-template #distanceToReactor> км </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <ng-template #weatherText>
        <h4 class="m-b-0">Метеоусловия в момент аварии</h4>
      </ng-template>
      <nz-divider [nzText]="weatherText" nzOrientation="left"></nz-divider>
    </div>
  </div>
  <div nz-row [nzGutter]="[24, 24]" *ngIf="!isShow">
    <div nz-col [nzSpan]="24">
      <button nz-button nzType="primary" type="button" (click)="getWeatherData()" [nzLoading]="isLoadWeather">
        Получить метеоусловия
      </button>
    </div>
  </div>
  <div nz-row [nzGutter]="[24, 24]">
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="windVelocity">Скорость ветра</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="windVelocity">
            <input nzSize="large" nz-input formControlName="windVelocity" />
          </nz-input-group>
          <ng-template #windVelocity> м/с </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="windDirection">Направление ветра</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="degrees">
            <input nzSize="large" nz-input formControlName="windDirection" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="isSnowy">Наличие снежного покрова</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="isSnowy" nzSize="large">
            <nz-option [nzValue]="false" nzLabel="Нет"></nz-option>
            <nz-option [nzValue]="true" nzLabel="Да"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="airTemperature">Температура воздуха в момент аварии</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="degrees">
            <input nzSize="large" nz-input formControlName="airTemperature" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="cloudStateId">Облачность</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="cloudStateId" nzSize="large">
            <nz-option [nzValue]="cloud.value" [nzLabel]="cloud.text" *ngFor="let cloud of clouds"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="timeOfDayId" [nzTooltipTitle]="tooltipText" nzTooltipIcon="info-circle"
          >Время суток</nz-form-label
        >
        <nz-form-control>
          <nz-select formControlName="timeOfDayId" nzSize="large">
            <nz-option [nzValue]="time.value" [nzLabel]="time.text" *ngFor="let time of timeOfDays"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
<ng-template #degrees> ° </ng-template>

