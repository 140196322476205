import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Coordinates } from '@bg-front/core/models/classes';
import { BgDatetimeValidator, coordinatesValidator } from '@bg-front/core/validators';
import { IForestryFacilityDto } from '@bg-front/forestry-facilities/models/interfaces';
import { ForestryFacilitiesService } from '@bg-front/forestry-facilities/services';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { DialogService, INwHeaderBarOptions, IScCheckboxOptions, IScInputOptions } from '@smart-city/core/common';
import { SfsService, AccessService, Settings2Service, RestService } from '@smart-city/core/services';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, debounceTime, mergeMap, takeUntil, switchMap } from 'rxjs/operators';
import {
  IAnyObject,
  IEmergencyEventDto,
  IEmergencyResolutionDto,
  IEmergencyTotalCars,
  ILifeCycleStepDto,
  IEmergencyVaDetailDto,
} from 'smart-city-types';
import { IAppealDetailDto, IDictionaryModelDto, ILifeCycleStepActionDto } from 'smart-city-types/interfaces';
import { IAppealCommentDto } from 'smart-city-types/interfaces/appeal-comment-dto.interface';

import { EmergencyEventDto } from '../../models/classes';
import { IMiniMapMarkerPositionEvent, IVideoDeviceBg } from '../../models/interfaces';
import { IServerData } from '@bg-front/core/models/interfaces';
import { VideoDevicesService } from '../../services';
import { BaseIncidentFormComponent } from '../base-incident-form/base-incident-form.component';
import { BgLoosesComponent } from '../bg-looses/bg-looses.component';
import { TechnologicalFireAdditionalInfoComponent } from '../technological-fire-additional-info/technological-fire-additional-info.component';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { IHcsJournalDto } from '@bg-front/interaction-hcs-journal/models/interfaces';
import { IBaseDictionaryData } from '@smart-city/core/interfaces';
import {
  AtmIntegrationService,
  BgAdminService,
  EmergencyService,
  MiniMapService,
  OrganizationsService,
  ResponsePlanStepService,
} from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { MultiFileService } from '@bg-front/core/services';
import { ReportingService } from '../../../bg/modules/reporting/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';

@Component({
  selector: 'bg-incident-edit-form',
  templateUrl: './incident-edit-form.component.html',
  styleUrls: ['./incident-edit-form.component.scss'],
})
/**
 * Компонента реализует возможность просмотра и редактирования инцидента
 */
export class IncidentEditFormComponent extends BaseIncidentFormComponent implements OnInit, AfterViewInit, OnDestroy {
  //#region Настройки компонент
  /** Погибшие */
  public otherCarsOption: IScInputOptions = {
    label: 'Другая техника',
    mask: '0*',
  };

  /**
   * Конфигурация заголовка
   */
  public headerActionsOptions: INwHeaderBarOptions;
  //#endregion
  /**
   * Центр мини-карты
   */
  public miniMapCenter: MapBaseCoordinatesType;
  /**
   * Зум для мини-карты
   */
  public miniMapZoom: number;
  /**
   * Координаты по умолчанию для мини карты
   */
  public defaultMarkerCoordinate: [number, number];
  /**
   * Объект содержащий информацию о привлечённых машинах
   */
  public totalCars: IEmergencyTotalCars = undefined;
  /**
   * Свойство рассчитывающее общее количество задействованных машин
   */
  public summaryCars: number;
  /**
   * Настройка компоненты На контроле ЦУКС
   */
  public optionsSupervisedByCuks: IScCheckboxOptions = {
    title: 'На контроле ЦУКС',
  };
  /** Флаг для формы "Плановые работы" */
  public isPlanWorkForm: boolean = false;
  // TODO заменить на метод поиска полигона из платформы, когда тот будет готов
  /** Лесохозяйственный объект, в полигоне которого расположен инцидент */
  public forestryFacility: IForestryFacilityDto = { name: '' };
  /** Сохраняем состояние комментариев, что бы не задваивать при ошибках */
  private currentIPComments: IAppealCommentDto[];

  public minFromDate: number = Date.now();

  /** Флаг видимости блока "Информация для интернет портала" */
  public showInternetPortalInfo: boolean = false;
  /** Текст подсказки*/
  public videoDeviceTooltipText: string;
  /** Наименование камеры */
  public videoDeviceNameOptions: IScInputOptions = {
    label: 'Наименование',
    required: true,
    readonly: true,
  }
  /** Ссылка на кмеру */
  public source: SafeResourceUrl;
  /** Форма камеры */
  public cameraForm: FormGroup;
  /** Путь сфс к фото */
  public filePath: string;
  /** Связанное событие */
  public emergencyEvent: IEmergencyEventDto;

  /** Иконка результата обмена информацией с реформой ЖКХ */
  public hcsInteractionResultIcon: string;
  /** Цвет иконки результата обмена информацией с реформой ЖКХ */
  public hcsInteractionResultIconColor: string;
  /** Хинт иконки результата обмена информацией с реформой ЖКХ */
  public hcsInteractionResultIconHint: string;
  /** Отображение блока информации о водоисточниках */
  public showWaterSource = ['dds01', 'cuks'].includes(this.route.snapshot.data?.workspace);
  /** Передать в АИУС РСЧС */
  public needToSendEmergencyAtlasOptions: IScCheckboxOptions = {
    title: 'Передать в АИУС РСЧС',
  };

  /**
   * @ignore
   */
  constructor(
    private readonly forestryFacilitiesService: ForestryFacilitiesService,
    private readonly videoDevicesService: VideoDevicesService,
    private readonly sanitizer: DomSanitizer,
    private readonly sfsService: SfsService,
    accessService: AccessService,
    atmIntegrationService: AtmIntegrationService,
    bgAdminService: BgAdminService,
    dialog: MatDialog,
    dialogService: DialogService,
    emergencyService: EmergencyService,
    fb: FormBuilder,
    ksipCategoriesQuery: KsipCategoriesQuery,
    ksipDetailsQuery: KsipDetailsQuery,
    ksipSectionsQuery: KsipSectionsQuery,
    ksipTypesQuery: KsipTypesQuery,
    miniMapService: MiniMapService,
    multiFileService: MultiFileService,
    organizationsService: OrganizationsService,
    reportingService: ReportingService,
    route: ActivatedRoute,
    router: Router,
    settings: Settings2Service,
    significantObjectService: SignificantObjectsService,
    responsePlanStepService: ResponsePlanStepService,
  ) {
    super(
      accessService,
      atmIntegrationService,
      bgAdminService,
      dialog,
      dialogService,
      emergencyService,
      fb,
      ksipCategoriesQuery,
      ksipDetailsQuery,
      ksipSectionsQuery,
      ksipTypesQuery,
      miniMapService,
      multiFileService,
      organizationsService,
      reportingService,
      route,
      router,
      settings,
      significantObjectService,
      responsePlanStepService,
    );
  }

  /**
   * @ignore
   */
  public ngOnInit(): void {
    this.showInternetPortalInfo = this.accessService.accessMap['showInternetPortalInfo']?.visible;
    this.incidentForm = new FormGroup({
      byCoordinates: new FormControl(this.model.byCoordinates),
      addressFact: new FormControl(this.model.addressFact, [Validators.required]),
      coordinatesAddress: new FormControl(
        this.model.byCoordinates ? this.model.addressFact.fullText || this.model.address.fullText : null,
      ),
      moId: new FormControl(this.model.moId),
      districtId: new FormControl({ value: this.model.districtId, disabled: true }),
      detailsFact: new FormControl(this.model.detailsFact),
      incidentTypeId: new FormControl(this.model.incidentTypeId, [Validators.required]),
      ksipDetailsId: new FormControl(this.model.ksipDetailsId),
      comment: new FormControl(this.model.comment),
      description: new FormControl(this.model.description),
      responsible: new FormControl(this.model.responsible || this.settings.currentUser.id, [Validators.required]),
      organization: new FormControl(this.model.organization),
      urgently: new FormControl(this.model.urgently),
      danger: new FormControl(this.model.resolution?.danger),
      dangerOrg: new FormControl(this.model.resolution?.dangerOrg),
      coordinates: new FormControl(this.model.coordinates, [coordinatesValidator()]),
      supervisedByCuks: new FormControl(this.model.supervisedByCuks),
      ksipPlaceDescription: new FormControl(this.model.ksipPlaceDescription),
      otherCars: new FormControl(this.model.resolution?.otherCars || 0),
      showOnPortal: new FormControl(this.model.showOnPortal),
      additionalInfoAboutCars: new FormControl(this.model.resolution?.additionalInfoAboutCars),
      technologicalFireAdditionalInfo: TechnologicalFireAdditionalInfoComponent.generateFormGroup(this.fb, {
        totalCars: (this.model.resolution || { totalCars: null }).totalCars?.toString(),
        trunks: (this.model.resolution || { trunks: null })['trunks'],
        personal: (this.model.resolution || { personal: null })['personal'],
        actual: (this.model.resolution || { actual: null })['actual'],
        timeDetection: (this.model.resolution || { timeDetection: null })['timeDetection'],
        timeDeparture: (this.model.resolution || { timeDeparture: null })['timeDeparture'],
        timeArrival: (this.model.resolution || { timeArrival: null })['timeArrival'],
        timeFirstSalvo: (this.model.resolution || { timeFirstSalvo: null })['timeFirstSalvo'],
        timeLocalization: (this.model.resolution || { timeLocalization: null })['timeLocalization'],
        timeLiquidation: (this.model.resolution || { timeLiquidation: null })['timeLiquidation'],
        timeRecovery: (this.model.resolution || { timeRecovery: null })['timeRecovery'],
      }),
      report: new FormControl(this.model.resolution?.report),
      /** ----- LOOSES ----- **/
      resolution: BgLoosesComponent.generateFormGroup(this.fb, {
        ...this.model.resolution,
        peopleInTheZoneOnDate: this.model.resolution?.peopleInTheZoneOnDate || this.model.timeCreate,
        deathOnDate: this.model.resolution?.deathOnDate || this.model.timeCreate,
        rescuedOnDate: this.model.resolution?.rescuedOnDate || this.model.timeCreate,
        victimOnDate: this.model.resolution?.victimOnDate || this.model.timeCreate,
        hospitalizedOnDate: this.model.resolution?.hospitalizedOnDate || this.model.timeCreate,
        evacuatedOnDate: this.model.resolution?.evacuatedOnDate || this.model.timeCreate,
        missingOnDate: this.model.resolution?.missingOnDate || this.model.timeCreate,
      }),
      incidentFinishTime: new FormControl(this.model.resolution?.incidentFinishTime),
      mapMarkerCoordinate: new FormControl(this.model?.mapMarkerCoordinate),
      regime: new FormControl(this.model?.regimeId),
      documents: new FormControl(this.model?.documents),
      forecasting: new FormControl(this.model?.forecastingDetailId),
      totalRegistryPeopleHouse: new FormControl(this.model?.totalRegistryPeopleHouse || 0),
      totalTemporalPeopleHouse: new FormControl(this.model?.totalTemporalPeopleHouse || 0),
      individualOwnership: new FormControl(this.model?.individualOwnership),

      jkhObject: new FormControl((this.model?.parentEventId as EmergencyEventDto)?.jkhObject),
      favorableRestrictionObjects: new FormControl(this.model?.favorableRestrictionObjects),
      affectedRestrictionObjects: new FormControl(this.model?.affectedRestrictionObjects),
      resourceConstraintType: new FormControl(this.model?.resourceConstraintType),
      relatedRestrictions: new FormControl(this.model?.relatedRestrictions),
      jkhDirection: new FormControl(this.model?.jkhDirection),
      consumerPowerCategoryId: new FormControl(this.model?.consumerPowerCategoryId, [Validators.required]),
      isSendingToJkhReform: new FormControl(this.model?.isSendingToJkhReform),
      needToSendEmergencyAtlas: new FormControl(this.model?.needToSendEmergencyAtlas),
      workTermFrom: new FormControl(this.model?.workTermFrom),
      workTermTo: new FormControl(this.model?.workTermTo),
      countSocialFacilities: new FormControl(this.model?.countSocialFacilities),

      internetPortalComment: new FormControl(this.model?.internetPortalComment),
      internetPortalDesc: new FormControl(this.model?.internetPortalDesc || this.model?.description?.substring(0, 300)),
    });


    this.emergencyEvent = this.model.parentEventId as IEmergencyEventDto
    this.videoDevicesService.getVideoDevice(
      (this.emergencyEvent.vaDetail as IEmergencyVaDetailDto)?.camera1Id as string,
      [
        'type.name',
        'videoServer.name',
        'status',
        'coordinates',
        'cameraInstallationHeight',
        'modelName',
        'name',
        'mediaUrl'
      ]
    )
    .pipe(
      switchMap((res: IVideoDeviceBg) => {
        const statusName = res.status === 0 ? 'Не работает' : res.status === 1 ? 'Работает и записывает' : ''
        const typeName = (res.type as IDictionaryModelDto)?.name
        const videoServerName = (res.videoServer as IServerData)?.name

        this.videoDeviceTooltipText = (typeName ? `Тип: ${typeName}\n` : '')
        + (videoServerName ? `Видеосервер: ${videoServerName}\n` : '')
        + (statusName ? `Состояние: ${statusName}\n` : '')
        + (res.coordinates ? `Координаты: ${res.coordinates}\n` : '')
        + (res.cameraInstallationHeight ? `Высота: ${res.cameraInstallationHeight}\n` : '')
        + (res.modelName ? `Модель: ${res.modelName}\n` : '')

        this.cameraForm = new FormGroup({
          videoDeviceName: new FormControl(res.name),
        });
        this.source =  this.sanitizer.bypassSecurityTrustResourceUrl(res.mediaUrl)
        return this.videoDevicesService.getForestGuardCameraStatus(res.mediaUrl)
      }),
      catchError((err: Error) => {
        this.source = ''
        console.error(err.message);
        return of(undefined);
      }),
    )
    .subscribe((res: IAnyObject) => {
      if (res?.availability === 'unavailable') this.source = ''
    })
    const fileUuid = JSON.parse((this.emergencyEvent.vaDetail as IEmergencyVaDetailDto)?.file1Id || '[]')?.[0]
    this.filePath = this.sfsService.getStreamUrl(fileUuid)

    this.eventsGridQuery = { incidentId: this.model.id };

    this.vehiclesGridQuery = { id: { $in: this.model.involvedVehicles || [] } };

    super.ngOnInit();

    if (this.model.isSendingToJkhReform) {
      this.emergencyService.getLastHcsInteractionResult(this.model.id).subscribe((hcsInteractionResult: IHcsJournalDto) => {
        switch ((<IBaseDictionaryData>hcsInteractionResult.result).sysname) {
          case 'sent':
            this.hcsInteractionResultIcon = 'schedule';
            this.hcsInteractionResultIconColor = '#7B7B7B';
            this.hcsInteractionResultIconHint = `${
              (<IBaseDictionaryData>hcsInteractionResult.result).name
            } ${
              dayjs(hcsInteractionResult.submitDate).format('DD.MM.YYYY HH:mm:ss')
            }`;
            break;
          case 'sendingError':
            this.hcsInteractionResultIcon = 'cancel';
            this.hcsInteractionResultIconColor = '#FC5A5A';
            this.hcsInteractionResultIconHint = (<string[]>hcsInteractionResult.reformHcsResponse)?.join('\n');
            break;
          case 'delivered':
            this.hcsInteractionResultIcon = 'check_circle';
            this.hcsInteractionResultIconColor = '#1CCA84';
            this.hcsInteractionResultIconHint = (<IBaseDictionaryData>hcsInteractionResult.result).name
            break;
        }
      });
    }

    if (this.isPlanWorkForm) {
      let minDate;
      const dateDefault = new Date(Date.now()).setHours(0, 0, 0, 0);
      if (this.model.timeCreate < dateDefault) {
        minDate = new Date(this.model?.timeCreate).setHours(0, 0, 0, 0);
      } else {
        minDate = dayjs().startOf('day').valueOf();
      }
      const workTermToInitValidators = [
        Validators.required,
        BgDatetimeValidator.minDate(minDate),
        BgDatetimeValidator.minDateOf(this.incidentForm.controls.workTermFrom.value),
      ];
      const validators = this.isPlanWorkForm ? [Validators.required, BgDatetimeValidator.minDate(minDate)] : [];
      this.incidentForm.controls.workTermFrom.setValidators(validators);
      this.incidentForm.controls.workTermFrom.setErrors(null);
      this.incidentForm.controls.workTermTo.setValidators(workTermToInitValidators);
      this.incidentForm.controls.workTermFrom.valueChanges.pipe(untilDestroyed(this)).subscribe((value: number) => {
        if (value) {
          this.incidentForm.controls.workTermTo.setValidators([
            Validators.required,
            BgDatetimeValidator.minDate(minDate),
            BgDatetimeValidator.minDateOf(value),
          ]);
        } else {
          this.incidentForm.controls.workTermTo.setValidators(validators);
        }
        this.incidentForm.controls.workTermTo.reset(this.incidentForm.controls.workTermTo.value, {
          emitEvent: true,
          onlySelf: false,
        });
      });
      this.incidentForm.controls.workTermTo.setErrors(null);
    }

    /** Добавляем работу с комментарием для интернет портала */
    if (this.needShowInternetPortalComment) {
      this.currentIPComments = [
        ...(((this.model.parentEventId as IEmergencyEventDto)?.appealDetail as IAppealDetailDto)?.comments ?? []),
      ];
    }

    if (this.disabled) {
      this.incidentForm.disable();
    }

    if (this.model.id) {
      this.emergencyService
        .getEmergencyTotalCars(this.model.id)
        .pipe(
          catchError((error: Error) =>
            this.catchErrorFn<IEmergencyTotalCars>(error, 'Ошибка запроса количества задействованных машин'),
          ),
          untilDestroyed(this),
        )
        .subscribe((total: IEmergencyTotalCars) => {
          this.totalCars = total;
          this.summaryCars = this.model.resolution?.otherCars || 0;
          this.summaryCars += this.totalCars.totalFireCars;
          this.summaryCars += this.totalCars.totalAmbulanceCars;
          this.summaryCars += this.totalCars.totalPoliceCars;
        });
    }

    this.showLinkToJkhObjectMonitoring(Boolean(this.model.jkhObject));
    this.setMonitoringObjectId(this.model.jkhObject);

    this.incidentForm.controls['jkhObject'].valueChanges
      .pipe(debounceTime(1500), takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.showLinkToJkhObjectMonitoring(Boolean(value));
        this.setMonitoringObjectId(value);
        this.setAddressObservableForLink();
      });

    /**
     * Подписка на изменение формы, с цель сброса ошибок валидации
     */
    this.incidentForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.errors = [];
    });

    const markerCoordinates = new Coordinates(this.model.mapMarkerCoordinate || this.model.coordinates);
    if (markerCoordinates.isValid()) {
      this.defaultMarkerCoordinate = markerCoordinates.toArray();
    }

    this.initReactPlan();

    this.setForecastingParamsOptions();

    // поиск лесохозяйственных объектов, в полигоне которых находится инцидент
    // TODO заменить на метод поиска полигона из платформы, когда тот будет готов
    this.forestryFacilitiesService
      .getForestryFacilityForEmergency(this.model.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: IForestryFacilityDto) => {
        this.forestryFacility = res;
      });

    // TODO заменить на метод поиска полигона из платформы, когда тот будет готов
    this.incidentForm.controls.coordinates.valueChanges
      .pipe(
        mergeMap(() => {
          return this.forestryFacilitiesService.getForestryFacilityForEmergency(this.model.id);
        }),
        untilDestroyed(this),
      )
      .subscribe((res: IForestryFacilityDto) => {
        this.forestryFacility = res;
      });

    this.optionsResponsible.query = { isActive: true, organizationId: this.model.organization };

    this.attributes = (this.model.lifeCycleStepId as ILifeCycleStepDto).params;
    this.updateForm();

    /** Запоминаем эталонное значение */
    const masterFormValue = this.incidentForm.getRawValue();
    this.ngMasterValue = new BehaviorSubject<IAnyObject>(masterFormValue);
    this.isDirty$ = dirtyCheck(this.incidentForm, this.ngMasterValue.asObservable());
  }

  /**
   * Установка маркера
   * @param miniMapEvent событие
   */
  public updateCoordinates(miniMapEvent: IMiniMapMarkerPositionEvent) {
    const markerCoordinates = new Coordinates(this.incidentForm.controls['mapMarkerCoordinate'].value);
    const newMarkerCoordinates = new Coordinates(miniMapEvent?.coordinates[0], miniMapEvent?.coordinates[1]);
    if (!markerCoordinates.equal(newMarkerCoordinates)) {
      this.incidentForm.controls['mapMarkerCoordinate'].setValue(newMarkerCoordinates.toString());
      this.redrawEventMarker(this.incidentForm.controls['mapMarkerCoordinate'].value);
      this.model.mapMarkerCoordinate = this.incidentForm.controls['mapMarkerCoordinate'].value;
    }
  }

  /**
   * Получение данных из формы и сохранение в модель
   * @param action - действие которое выполняется на форме
   */
  public getModelData(action: string) {
    if (!this.model.address) {
      this.model.address = this.model.byCoordinates
        ? this.addressByCoordinates
        : this.incidentForm.controls['addressFact'].value;
    }
    this.model.byCoordinates = this.incidentForm.controls['byCoordinates'].value;
    this.model.addressFact = this.model.byCoordinates
      ? this.addressByCoordinates
      : this.incidentForm.controls['addressFact'].value;
    if (!this.model.id) {
      this.model.timeCreate = dayjs().unix() * 1000;
      this.model.number = `i-${dayjs(this.model.timeCreate).format('YYYYMMDD-HHmmss')}`;
      this.model.organization = this.settings.currentUser.organizationId.id;
      this.model.createdBy = this.settings.currentUser.id;
    }
    this.model.moId = <string>this.moIdComponent.value;
    this.model.districtId = <string>this.districtIdComponent.value;
    this.model.detailsFact = this.incidentForm.controls['detailsFact'].value;
    this.model.incidentTypeId = this.incidentForm.controls['incidentTypeId'].value;
    this.model.ksipDetailsId = this.incidentForm.controls['ksipDetailsId'].value;
    this.model.comment = this.incidentForm.controls['comment'].value;
    this.model.description = this.incidentForm.controls['description'].value;
    this.model.responsible = this.incidentForm.controls['responsible'].value;
    this.model.resolution = this.model.resolution || <IEmergencyResolutionDto>{};
    this.model.showOnPortal = this.incidentForm.controls['showOnPortal'].value;
    if (this.model.showOnPortal) {
      this.model.internetPortalDesc = this.incidentForm.controls['internetPortalDesc'].value;
    }
    this.model.urgently = this.incidentForm.controls['urgently'].value;
    this.model.resolution.danger = this.incidentForm.controls['danger'].value;
    this.model.resolution.dangerOrg = this.incidentForm.controls['dangerOrg'].value;
    this.model.supervisedByCuks = this.incidentForm.controls['supervisedByCuks'].value;
    this.model.coordinates = new Coordinates(this.incidentForm.controls['coordinates'].value).toString();
    this.model.ksipPlaceDescription = this.incidentForm.controls['ksipPlaceDescription'].value;
    this.model.resolution.otherCars = this.incidentForm.controls['otherCars'].value;
    this.model.resolution.additionalInfoAboutCars = this.incidentForm.controls['additionalInfoAboutCars'].value;
    this.model.resolution.incidentFinishTime = this.incidentForm.controls['incidentFinishTime'].value;
    this.model.resolution.report = this.incidentForm.controls['report'].value;

    this.model.totalRegistryPeopleHouse = Number(this.incidentForm.controls['totalRegistryPeopleHouse'].value);
    this.model.totalTemporalPeopleHouse = Number(this.incidentForm.controls['totalTemporalPeopleHouse'].value);
    this.model.individualOwnership = this.incidentForm.controls['individualOwnership'].value;

    this.model.jkhObject = this.incidentForm.controls['jkhObject'].value;
    this.model.favorableRestrictionObjects = this.incidentForm.controls['favorableRestrictionObjects'].value;
    this.model.affectedRestrictionObjects = this.incidentForm.controls['affectedRestrictionObjects'].value;
    this.model.resourceConstraintType = this.incidentForm.controls['resourceConstraintType'].value;
    this.model.relatedRestrictions = this.incidentForm.controls['relatedRestrictions'].value;
    this.model.jkhDirection = this.incidentForm.controls['jkhDirection'].value;
    this.model.consumerPowerCategoryId = this.incidentForm.controls['consumerPowerCategoryId'].value
      ? this.incidentForm.controls['consumerPowerCategoryId'].value
      : undefined;
    this.model.isSendingToJkhReform = this.incidentForm.controls['isSendingToJkhReform'].value;
    this.model.needToSendEmergencyAtlas = this.incidentForm.controls['needToSendEmergencyAtlas'].value;

    if (this.isPlanWorkForm) {
      this.model.workTermFrom = this.incidentForm.controls['workTermFrom'].value;
      this.model.workTermTo = this.incidentForm.controls['workTermTo'].value;
    }

    /** ----- LOOSES ----- **/
    this.model.resolution = {
      ...this.model.resolution,
      ...(this.incidentForm.controls['resolution'].value || <IEmergencyResolutionDto>{}),
    };
    this.model.mapMarkerCoordinate = this.incidentForm.controls['mapMarkerCoordinate'].value;

    if (this.needShowInternetPortalComment && this.incidentForm.controls['internetPortalComment'].value) {
      this.model.internetPortalComment = this.incidentForm.controls['internetPortalComment'].value;
      // Текущий статус
      const currentStatus = (<IDictionaryModelDto>(<ILifeCycleStepDto>this.model.lifeCycleStepId).status)?.id
        ? (<IDictionaryModelDto>(<ILifeCycleStepDto>this.model.lifeCycleStepId).status).id
        : <string>(<ILifeCycleStepDto>this.model.lifeCycleStepId).status;
      // Получение информации о шаге ЖЦ в который переходит происшествие
      const nextStep = (this.settings.lifeCycleStep[(<ILifeCycleStepDto>this.model.lifeCycleStepId).id].actions || []).find(
        (i: ILifeCycleStepActionDto) => i.name === action,
      )?.nextStep;
      const nextLifeCycleStep = nextStep
        ? this.settings.lifeCycleStep[nextStep]
        : <ILifeCycleStepDto>this.model.lifeCycleStepId;
      // Если отсутствует следующий шаг, то происшествие остается в текущем шаге
      const newStatus = !!<string>nextLifeCycleStep?.status
        ? this.settings.getDictionaryById(<string>nextLifeCycleStep.status)
        : this.settings.getDictionaryById(currentStatus);
      // Инициализация текущего списка комментариев для интернет портала
      ((this.model.parentEventId as IEmergencyEventDto)
        .appealDetail as IAppealDetailDto).comments = this.currentIPComments;
      // Добавление нового комментария для интернет портала
      ((this.model.parentEventId as IEmergencyEventDto).appealDetail as IAppealDetailDto).comments.push({
        lifeCycleStepId: {
          id: nextLifeCycleStep.id,
          name: nextLifeCycleStep.name,
          status: <IDictionaryModelDto>{
            id: nextLifeCycleStep.status,
            name: newStatus.name,
            sysname: newStatus.sysname,
          },
        },
        text: this.incidentForm.controls['internetPortalComment'].value,
        createTime: Date.now(),
        userId: this.settings.currentUser.id,
      });
    }

    this.model.resolution = Object.assign(
      this.model.resolution,
      (this.incidentForm.controls['technologicalFireAdditionalInfo'] as FormGroup).getRawValue(),
    );

    this.model.documents = this.incidentForm.controls['documents'].value;

    if (this.showForecastingParams) {
      this.model.forecastingDetailId = {
        id: this.model.forecastingDetailId?.id,
        params: (this.incidentForm.controls.forecasting as FormGroup).getRawValue(),
      };
    } else {
      this.model.forecastingDetailId = null;
    }
  }

  /**
   * Обновление формы
   */
  public updateForm() {
    /**
     * Обновляем заголовок
     */
    this.generateHeaderBar();

    this.attributes = (this.model.lifeCycleStepId as ILifeCycleStepDto).params;
  }

  /** Конфигурация блока "Параметры" */
  public setForecastingParamsOptions(): void {
    if (this.model.incidentTypeId) {
      this.getForecastingParamsInfo(this.model.incidentTypeId);
    }
  }

  /** Закрытие формы */
  public closeForm() {
    this.emergencyService.selectIncident({ id: undefined, docType: 'incident' });
    if (this.route.snapshot.outlet === 'primary') {
      this.router.navigate(['consolidated-registries/incident-register']);
    } else {
      this.router.navigate([{ outlets: { editForm: null, editEventForm: null } }], {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge',
      });
    }
  }

  /**
   * @ignore
   */
  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    localStorage.removeItem('isAddressBlockShow');
  }

  /** Показывает ссылку на объект монитроинга */
  private showLinkToJkhObjectMonitoring(value: boolean): void {
    this.linkToJkhObjectVisible = this.isJhk && value;
  }
}
