<div>
  <h4 nz-typography>
    <i nz-icon nzType="field-time" nzTheme="outline"></i>
    Результаты прогноза
  </h4>
  <nz-divider></nz-divider>

  <nz-descriptions nzTitle="Параметры зоны заражения АХОВ" nzBordered nzSize="small" [nzColumn]="1">
    <nz-descriptions-item nzTitle="Глубина"
      >{{ result?.pollutionDepth | commaToDot | number: '0.2-2' }} км</nz-descriptions-item
    >
    <nz-descriptions-item nzTitle="Площадь зоны"
      >{{ result?.pollutionZoneArea | commaToDot | number: '0.2-2' }} км²</nz-descriptions-item
    >
  </nz-descriptions>

  <nz-descriptions
    *ngIf="storedChemicalResults?.length"
    nzTitle="Время испарения Ахов"
    nzSize="small"
    nzBordered
    [nzColumn]="1"
  >
    <nz-descriptions-item [nzTitle]="$any(item.name) | async" *ngFor="let item of storedChemicalResults">
      {{ item?.lethalityDuration | commaToDot | number: '0.2-2' }} ч
    </nz-descriptions-item>
  </nz-descriptions>
</div>
