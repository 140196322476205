import { RestService } from '@smart-city/core/services';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IAbstractServiceData, IAnyObject, IUserInfoDto } from 'smart-city-types';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SecurityForcesService {
  constructor(
    private readonly rest: RestService,
  ) {}

  public getRuleGroups(): Observable<IAnyObject[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'RuleGroups',
      }
    }).pipe(
      map((response: IAbstractServiceData) => response.data.items || []),
    );
  }

  public getUsersByQuery(query?: IAnyObject): Observable<IUserInfoDto[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'Users',
        query: query || {},
      },
    }).pipe(
      map((response: IAbstractServiceData) => response.data.items || []),
    );
  }

  /** Получение списка организаций для селекта */
  public getFilterVideoDeviceCategories(): Observable<{ id: string; name: string }[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: { name: 'VideoDeviceCategories', attributes: ['id', 'name'], },
    }).pipe(
      map((response: IAbstractServiceData) => response.data.items || []),
      catchError(() => of([])),
    );
  }

}
