import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Coordinates } from '@bg-front/core/models/classes';
import { BUTTON_ICONS } from '@bg-front/core/models/constants';
import {
  DialogService,
  IElementButton,
  IScCheckboxOptions,
  IScFias3Options,
  IScInputOptions,
  IScSelectOptions,
  IScTextareaOptions,
} from '@smart-city/core/common';
import { AccessService, INotificationMessage, RestService, Settings2Service } from '@smart-city/core/services';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { IDds01FireParamsDto, IEmergencyDto, IEmergencyResolutionDto, ILifeCycleStepDto } from 'smart-city-types';
import { IMiniMapMarkerPositionEvent } from '../../models/interfaces';
import { ResponsePlanStepService } from '../../services';
import { BaseIncidentFormComponent } from '../base-incident-form/base-incident-form.component';
import { BgLoosesComponent } from '../bg-looses/bg-looses.component';
import {
  AtmIntegrationService,
  BgAdminService,
  EmergencyService,
  MiniMapService,
  OrganizationsService,
} from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { MultiFileService } from '@bg-front/core/services';
import { ReportingService } from '../../../bg/modules/reporting/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';

@Component({
  selector: 'bg-order-view-form',
  templateUrl: './order-view-form.component.html',
  styleUrls: ['./order-view-form.component.scss'],
  providers: [ResponsePlanStepService],
})
export class OrderViewFormComponent extends BaseIncidentFormComponent implements OnInit, AfterViewInit {
  /** Список сообщений об ошибке */
  public errors: INotificationMessage[] = [];

  /** Настройка компоненты фактического адреса */
  public addressFactOptions: IScFias3Options = {
    label: 'Фактический адрес',
    width: '100%',
    isCoordinatesNecessary: true,
  };

  /** Настройка компоненты Комментарий */
  public optionsDds01Comment: IScTextareaOptions = {
    label: 'Комментарий исполнителя',
    rows: 2,
  };

  /** Настройка компоненты Описание */
  public optionsDescription: IScTextareaOptions = {
    label: 'Описание',
    maxLength: 1000,
    rows: 6,
  };

  /** Настройка компоненты Ответсвенный пользователь */
  public optionsResponsibleUser: IScSelectOptions = {
    title: 'Ответственный пользователь',
    clearable: true,
    service: 'Admin',
    entity: 'Users',
    modern: true,
    fieldId: 'id',
    fieldName: 'fio',
    query: { isActive: true },
  };

  /** Настройка компоненты Угроза населению */
  public optionsThreatPopulation: IScCheckboxOptions = {
    title: 'Угроза населению',
  };

  /** Настройка компоненты Угроза организации */
  public optionsThreatOrganization: IScCheckboxOptions = {
    title: 'Угроза организации',
  };

  /** Настройка компоненты Фактического адреса */
  public coordinatesOption: IScInputOptions = {
    label: 'Координаты фактического адреса',
    maxLength: 100,
  };

  /** Настройка компоненты Доп информация о месте КСиП */
  public placeDescriptionOptions: IScTextareaOptions = {
    label: 'Дополнительная информация о месте КСиП',
    maxLength: 1000,
    rows: 8,
  };

  /** Количество граждан, зарегистрированных в жилом доме */
  public totalRegistryPeopleHouseOptions: IScInputOptions = {
    label: 'Количество граждан, зарегистрированных в жилом доме',
    type: 'number',
  };

  /** Количество граждан, проживающих в жилом доме временно */
  public totalTemporalPeopleHouseOptions: IScInputOptions = {
    label: 'Количество граждан, проживающих в жилом доме временно',
    type: 'number',
  };
  /** Индивидуальное домовладение */
  public individualOwnershipOptions: IScCheckboxOptions = {
    title: 'Индивидуальное домовладение',
  };

  /** Центр миникарты */
  public miniMapCenter: MapBaseCoordinatesType;

  /** Зум для миникарты */
  public miniMapZoom: number;

  /** Координаты по умолчанию для мини карты */
  public defaultMarkerCoordinate: [number, number];

  /** @ignore */
  constructor(
    accessService: AccessService,
    atmIntegrationService: AtmIntegrationService,
    bgAdminService: BgAdminService,
    dialog: MatDialog,
    dialogService: DialogService,
    emergencyService: EmergencyService,
    fb: FormBuilder,
    ksipCategoriesQuery: KsipCategoriesQuery,
    ksipDetailsQuery: KsipDetailsQuery,
    ksipSectionsQuery: KsipSectionsQuery,
    ksipTypesQuery: KsipTypesQuery,
    miniMapService: MiniMapService,
    multiFileService: MultiFileService,
    organizationsService: OrganizationsService,
    reportingService: ReportingService,
    route: ActivatedRoute,
    router: Router,
    settings: Settings2Service,
    significantObjectService: SignificantObjectsService,
    responsePlanStepService: ResponsePlanStepService,
  ) {
    super(
      accessService,
      atmIntegrationService,
      bgAdminService,
      dialog,
      dialogService,
      emergencyService,
      fb,
      ksipCategoriesQuery,
      ksipDetailsQuery,
      ksipSectionsQuery,
      ksipTypesQuery,
      miniMapService,
      multiFileService,
      organizationsService,
      reportingService,
      route,
      router,
      settings,
      significantObjectService,
      responsePlanStepService,
    );
  }

  /** @ignore */
  ngOnInit(): void {
    this.model = this.route.snapshot.data['model'];

    this.incidentForm = new FormGroup({
      /** ----- MAIN ----- **/
      byCoordinates: new FormControl(this.model.byCoordinates),
      addressFact: new FormControl(this.model.addressFact, [Validators.required]),
      moId: new FormControl(this.model.moId),
      districtId: new FormControl({ value: this.model.districtId, disabled: true }),
      detailsFact: new FormControl(this.model.detailsFact),
      incidentTypeId: new FormControl(
        {
          value: this.model.incidentTypeId,
          disabled: true,
        },
        [Validators.required],
      ),
      ksipDetailsId: new FormControl({
        value: this.model.ksipDetailsId,
        disabled: true,
      }),
      comment: new FormControl({
        value: this.model.comment,
        disabled: true,
      }),
      description: new FormControl({
        value: this.model.description,
        disabled: true,
      }),
      responsibleUser: new FormControl(this.model.responsible, [Validators.required]),
      deadline: new FormControl(this.model.deadline),
      organization: new FormControl(this.model.organization),
      threatPopulation: new FormControl((this.model.resolution || <IEmergencyResolutionDto>{}).danger),
      threatOrganization: new FormControl((this.model.resolution || <IEmergencyResolutionDto>{}).dangerOrg),
      /** ----- PARAMS ----- **/
      fireParams: new FormControl(this.model.fireParams || <IDds01FireParamsDto>{}),
      /** ----- PLACEMENT ----- **/
      coordinates: new FormControl(this.model.coordinates),
      ksipPlaceDescription: new FormControl(this.model.ksipPlaceDescription),
      /** ----- ADDITIONAL ----- **/
      dds01AdditionalInfo: new FormControl(this.model.dds01AdditionalInfo),
      dds01Comment: new FormControl({
        value: this.model.dds01AdditionalInfo?.comment || undefined,
        disabled: false,
      }),
      /** ----- LOOSES ----- **/
      resolution: BgLoosesComponent.generateFormGroup(this.fb, {
        ...this.model.resolution,
        peopleInTheZoneOnDate: this.model.resolution?.peopleInTheZoneOnDate || this.model.timeCreate,
        deathOnDate: this.model.resolution?.deathOnDate || this.model.timeCreate,
        rescuedOnDate: this.model.resolution?.rescuedOnDate || this.model.timeCreate,
        victimOnDate: this.model.resolution?.victimOnDate || this.model.timeCreate,
        hospitalizedOnDate: this.model.resolution?.hospitalizedOnDate || this.model.timeCreate,
        evacuatedOnDate: this.model.resolution?.evacuatedOnDate || this.model.timeCreate,
        missingOnDate: this.model.resolution?.missingOnDate || this.model.timeCreate,
      }),
      /** ----- DOCS ----- **/
      documents: new FormControl(this.model.documents),
      totalRegistryPeopleHouse: new FormControl(this.model?.totalRegistryPeopleHouse),
      totalTemporalPeopleHouse: new FormControl(this.model?.totalTemporalPeopleHouse),
      individualOwnership: new FormControl(this.model?.individualOwnership),
    });

    this.eventsGridQuery = { incidentId: (<IEmergencyDto>this.model.parentId).id };

    this.incidentForm.disable();

    super.ngOnInit();
    this.actions = (this.model?.lifeCycleStepId as ILifeCycleStepDto)?.actions || [];
    this.generateHeaderBar();

    this.headerActionsOptions.title = `Поручение ${this.model.number ?? ''}`;
    /** Получение координат и зума для МО */
    this.miniMapCenter = this.getMunicipalCoordinates();
    this.miniMapZoom = parseInt(this.getMunicipal().zoom, 10);

    this.validationControlMap = new Map<string, string>([['addressFact', this.addressFactOptions.label]]);
    this.initReactPlan();

    const markerCoordinates = new Coordinates(this.model.mapMarkerCoordinate || this.model.coordinates);
    if (markerCoordinates.isValid()) {
      this.defaultMarkerCoordinate = markerCoordinates.toArray();
    }
  }

  /** @ignore */
  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Установка маркера
   * @param miniMapEvent событие
   */
  public updateCoordinates(miniMapEvent: IMiniMapMarkerPositionEvent) {
    const markerCoordinates = new Coordinates(this.incidentForm.controls['mapMarkerCoordinate'].value);
    const newMarkerCoordinates = new Coordinates(miniMapEvent?.coordinates[0], miniMapEvent?.coordinates[1]);
    if (!markerCoordinates.equal(newMarkerCoordinates)) {
      this.incidentForm.controls['mapMarkerCoordinate'].setValue(newMarkerCoordinates.toString());
      this.redrawEventMarker(this.incidentForm.controls['mapMarkerCoordinate'].value);
      this.model.mapMarkerCoordinate = this.incidentForm.controls['mapMarkerCoordinate'].value;
    }
  }

  /** Получение данных из формы и сохранение в модель */
  public getModelData() {
    if (!this.model.id) {
      this.model.timeCreate = dayjs().unix() * 1000;
      this.model.number = `i-${dayjs(this.model.timeCreate).format('YYYYMMDD-HHmmss')}`;
      this.model.organization = this.settings.currentUser.organizationId.id;
      this.model.createdBy = this.settings.currentUser.id;
    }
    /** ----- MAIN ----- **/
    this.model.addressFact = this.incidentForm.controls['addressFact'].value;
    this.model.moId = <string>this.moIdComponent.value;
    this.model.districtId = <string>this.districtIdComponent.value;
    this.model.detailsFact = this.incidentForm.controls['detailsFact'].value;
    this.model.incidentTypeId = this.incidentForm.controls['incidentTypeId'].value;
    this.model.comment = this.incidentForm.controls['comment'].value;
    this.model.description = this.incidentForm.controls['description'].value;
    this.model.responsible = this.incidentForm.controls['responsibleUser'].value;
    this.model.resolution = this.model.resolution || <IEmergencyResolutionDto>{};
    this.model.resolution.danger = this.incidentForm.controls['threatPopulation'].value;
    this.model.resolution.dangerOrg = this.incidentForm.controls['threatOrganization'].value;
    /** ----- PARAMS ----- **/
    this.model.fireParams = this.incidentForm.controls['fireParams'].value || <IDds01FireParamsDto>{};
    /** ----- PLACEMENT ----- **/
    this.model.coordinates = this.incidentForm.controls['coordinates'].value;
    this.model.ksipPlaceDescription = this.incidentForm.controls['ksipPlaceDescription'].value;
    /** ----- ADDITIONAL ----- **/
    this.model.dds01AdditionalInfo = this.incidentForm.controls['dds01AdditionalInfo'].value || {};
    this.model.dds01AdditionalInfo['comment'] = this.incidentForm.controls['dds01Comment'].value;
    // Значения по умолчанию
    if (!this.model?.dds01AdditionalInfo?.timeDetection) {
      this.model.dds01AdditionalInfo.timeDetection = this.model.timeCreate;
    }
    /** ----- LOOSES ----- **/
    this.model.resolution = this.incidentForm.controls['resolution'].value || <IEmergencyResolutionDto>{};
    /** ----- DOCS ----- **/
    this.model.documents = this.incidentForm.controls['documents'].value;
    this.model.totalRegistryPeopleHouse = Number(this.incidentForm.controls['totalRegistryPeopleHouse'].value) || 0;
    this.model.totalTemporalPeopleHouse = Number(this.incidentForm.controls['totalTemporalPeopleHouse'].value) || 0;
    this.model.individualOwnership = this.incidentForm.controls['individualOwnership'].value;
  }

  /** Обновление формы */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public updateForm() {}

  public generateHeaderBar() {
    const btnArray = [];

    btnArray.unshift(<IElementButton>{
      type: 'button',
      options: {
        name: 'cancel',
        icon: BUTTON_ICONS.get('cancel'),
      },
    });

    this.headerActionsOptions = {
      title: `Инцидент ${this.model.number ?? ''}`,
      name: 'header',
      margin: 'collapse',
      bgColor: 'white',
      buttons: btnArray,
    };
  }

  /** Закрытие формы */
  public closeForm() {
    this.router.navigate([{ outlets: { viewForm: null } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }
}
