import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IScInputOptions, IScSelectOptions } from '@smart-city/core/common';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IForecastingDetailDto, IForecastingForestFireParamsDto } from 'smart-city-types';
import { BaseComponent } from '@bg-front/core/components';
import { Settings2Service } from '@smart-city/core/services';
import { FLOAT_PATTERN } from '@bg-front/core/models/constants';

@Component({
  selector: 'bg-forecasting-forest-fire-params-form',
  templateUrl: './forecasting-forest-fire-params-form.component.html',
  styleUrls: ['./forecasting-forest-fire-params-form.component.scss'],
})
export class ForecastingForestFireParamsFormComponent extends BaseComponent implements OnInit {
  /** Форма */
  public form: FormGroup;

  /** Сохраняем модель */
  public data: IForecastingDetailDto<IForecastingForestFireParamsDto> = {
    id: undefined,
    params: {},
  };

  /** Настройка компоненты Вид пожара */
  public fireTypesOptions: IScSelectOptions = {
    title: 'Вид пожара',
    fieldName: 'name',
    modern: true,
    clearable: true,
    data: this.settings.getDictForSelect('fireType'),
  };

  /** Настройка компоненты Вид пожара */
  public sizingTypesOptions: IScSelectOptions = {
    title: 'Способ определения размера',
    modern: true,
    clearable: true,
    data: [
      {
        id: 'perimeter',
        text: 'Начальный периметр (в м)',
      },
      {
        id: 'square',
        text: 'Начальная площадь (в га)',
      },
    ],
  };

  /** Настройка компоненты Общий запас АХОВ на объекте */
  public sizingTypeValueOptions: IScInputOptions = {
    label: 'Значение',
    mask: '0*.00',
  };

  /** Признак низового пожара */
  public isDownstream = false;

  /** Настройка компоненты Общий запас АХОВ на объекте */
  public sizingTypeHeightValueOptions: IScInputOptions = {
    label: 'Средняя высота нагара (в м)',
    mask: '0*.00',
  };

  /** @ignore */
  constructor(private readonly settings: Settings2Service, public readonly controlContainer: ControlContainer) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;

    this.form.controls.fireTypeId.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe((value: string) => {
        if (value) {
          const fireType = this.settings.getDictionaryById(value);
          if (fireType?.sysname === 'downstream') {
            this.isDownstream = true;
          } else {
            this.isDownstream = false;
            this.form.controls.height.setValue(null);
          }
        } else {
          this.isDownstream = false;
        }
      });
  }

  /** Генерация FormGroup */
  public static generateFormGroup(fb: FormBuilder, params: IForecastingForestFireParamsDto) {
    return fb.group({
      fireTypeId: [params?.fireTypeId],
      sizingTypeId: [params?.sizingTypeId],
      size: [params?.size, [Validators.pattern(FLOAT_PATTERN)]],
      height: [params?.height, [Validators.pattern(FLOAT_PATTERN)]],
    });
  }
}
