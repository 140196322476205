import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IScButtonOptions, IScSelectOptions } from '@smart-city/core/common';

/** Компонент диалогового окна для кастомного экспорта */
@Component({
  selector: 'bg-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
})
export class ExportDialogComponent implements OnInit {
  public form: FormGroup;

  /** Опции для кнопки отмена */
  public cancelOptions: IScButtonOptions = {
    title: 'Отмена',
  };

  /** Опции для кнопки экспорт */
  public exportOptions: IScButtonOptions = {
    color: 'primary',
    title: 'Экспорт',
  };

  /** Настройки селекта тип файла */
  public fileTypeOptions: IScSelectOptions = {
    title: 'Тип файла',
    data: [
      { id: 'xlsx', text: 'xlsx' },
      { id: 'xls', text: 'xls' },
      { id: 'csv', text: 'csv' },
    ],
    value: 'xlsx',
    clearable: true,
    required: true,
  };

  constructor(private readonly dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: { title: string }) {}

  public ngOnInit(): void {
    this.form = new FormGroup({
      fileType: new FormControl(undefined, [Validators.required]),
    });
  }

  public onCloseHandler(): void {
    this.dialogRef.close();
  }
  /** Экспорт */
  public onExportHandler() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.controls.fileType.value);
    }
  }
}
