<nw-header [options]="headerOptions" (clickButton)="onClickHeaderButton()"></nw-header>
<div class="dashboard-container">
  <nz-table
    #nzTable
    [nzData]="data"
    nzBordered
    nzTableLayout="fixed"
    [nzTitle]="tableHeader"
    (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
    [nzFrontPagination]="false"
    [nzLoading]="isLoading"
    [nzTotal]="totalCount"
    [nzShowTotal]="totalTemplate"
    [nzPageSize]="pageSize"
    [(nzPageIndex)]="pageIndex"
    (nzQueryParams)="onQueryParamsChange($event)"
    [nzNoResult]="'Нет данных'"
    nzSize="middle"
    class="table-pagination-margin table-auto-height"
  >
    <thead>
      <tr>
        <th
          rowspan="2"
          [nzChecked]="selectAll"
          [nzIndeterminate]="indeterminateSelect"
          (nzCheckedChange)="onAllChecked($event)"
          nzWidth="50px"
        >
        </th>
        <th
          [nzColumnKey]="sortableColumns.name.key"
          [(nzSortOrder)]="sortableColumns.name.order"
          [nzSortFn]="true"
        >
          Наименование
        </th>
        <th
          [nzColumnKey]="sortableColumns.sysName.key"
          [(nzSortOrder)]="sortableColumns.sysName.order"
          [nzSortFn]="true"
        >
          sysName
        </th>
        <th
          [nzColumnKey]="sortableColumns.claim.key"
          [(nzSortOrder)]="sortableColumns.claim.order"
          [nzSortFn]="true"
        >
          Claim
        </th>
        <th
          [nzColumnKey]="sortableColumns.order.key"
          [(nzSortOrder)]="sortableColumns.order.order"
          [nzSortFn]="true"
        >
          Порядок
        </th>
      </tr>
    </thead>
    <tbody class="no-cell-border">
    <tr
      *ngFor="let data of nzTable.data"
      (dblclick)="editRow(data)"
    >
      <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
      <td>{{ data.name }}</td>
      <td>{{ data.sysName }}</td>
      <td>{{ data.claim }}</td>
      <td>{{ data.order }}</td>
    </tr>
    </tbody>
  </nz-table>
</div>

<ng-template #tableHeader>
  <div class="table-title">
  </div>
  <div class="table-buttons">
    <nz-badge [nzCount]="setOfCheckedId?.size" *ngIf="setOfCheckedId?.size">
      <button
        nz-button
        nzDanger
        nzSize="large"
        nzType="default"
        nz-popconfirm
        nzPopconfirmTitle="Записи будут удалены. Вы действительно хотите продолжить?"
        nzOkText="Да"
        nzCancelText="Нет"
        (nzOnConfirm)="deleteSelected()"
      >
        <i nz-icon nzType="delete" nzTheme="outline"></i> Удалить
      </button>
    </nz-badge>
    <button nz-button nzSize="large" nzType="default" (click)="export()">
      <i nz-icon nzType="vertical-align-bottom" nzTheme="outline"></i> Экспорт
    </button>
    <button nz-button nzSize="large" nzType="default" (click)="reload()">
      <i nz-icon nzType="reload" nzTheme="outline"></i> Обновить
    </button>
    <nz-badge [nzCount]="filterCount">
      <button
        nz-button
        nzSize="large"
        nzType="primary"
        (click)="openFilterDialog()"
      >
        <i nz-icon nzType="filter" nzTheme="outline"></i> Фильтр
      </button>
    </nz-badge>
    <button nz-button nzSize="large" nzType="primary" (click)="add()">
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      Добавить
    </button>
  </div>
</ng-template>

<ng-template #totalTemplate let-range="range" let-total> {{ range[0] }}-{{ range[1] }} из {{ total }} </ng-template>

<router-outlet (deactivate)="onFormDeactivate($event)"></router-outlet>
