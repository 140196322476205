import { Injectable } from '@angular/core';
import { IOrganizationType, IResponseWithTotal } from '@bg-front/core/models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IModifiedData } from '@smart-city/core/interfaces';
import { ILimit, ISort, RestService, SubscriberService } from '@smart-city/core/services';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IKsipTypeDto } from 'smart-city-types';

import { KsipTypesStore } from '../store/ksip-types-store.service';

/** Сервис для работы с бэком и обновлением состояния хранилища */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class KsipTypesService {
  private isInit = false;

  constructor(
    private readonly rest: RestService,
    private readonly store: KsipTypesStore,
    private readonly subs: SubscriberService,
  ) {}

  /**
   * Загружаем список Типов инцидентов
   */
  public initStore(): Observable<boolean> {
    this.store.setLoading(true);

    if (!this.isInit) {
      this.isInit = true;
      this.subs
        .onTableChange<IKsipTypeDto>('Admin', 'IncidentTypes')
        .pipe(untilDestroyed(this))
        .subscribe((result: IModifiedData<IKsipTypeDto>) => {
          this.store.setLoading(true);
          if (result.action !== 'delete') {
            this.store.upsert(
              result.data.id,
              (oldState: Partial<IKsipTypeDto>) => ({ ...oldState, ...result.data }),
              (id: string, newState: IKsipTypeDto) => ({ id, ...newState }),
            );
          } else {
            this.store.remove((result.data as IAnyObject).ids);
          }
          this.store.setLoading(false);
        });
    }

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'IncidentTypes',
          sort: { field: 'name', direction: 'asc' },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          this.store.add(response?.data?.items as IKsipTypeDto[]);
          return true;
        }),
        catchError(() => {
          return of(false);
        }),
        finalize(() => {
          this.store.setLoading(false);
        }),
      );
  }

  /**
   * Удаление записи
   */
  public delete(ids: string | string[]): Observable<boolean> {
    this.store.setLoading(true);

    return this.rest
      .serviceRequest({
        action: 'delete',
        service: { name: 'Admin' },
        entity: {
          name: 'IncidentTypes',
          query: {
            id: Array.isArray(ids)
              ? {
                  $in: ids,
                }
              : ids,
          },
        },
      })
      .pipe(
        map(() => {
          this.store.remove(ids);
          return true;
        }),
        catchError(() => {
          return of(false);
        }),
        finalize(() => {
          this.store.setLoading(false);
        }),
      );
  }

  /**
   * Сохранение модели
   * @param model модель
   */
  public save(model: IKsipTypeDto): Observable<IAbstractServiceData> {
    let obs: Observable<IAbstractServiceData>;
    this.store.setLoading(true);
    if (model.id) {
      obs = this.rest.serviceRequest({
        action: 'update',
        service: { name: 'Admin' },
        entity: {
          name: 'IncidentTypes',
          query: {
            id: model.id,
          },
        },
        data: model,
      });
    } else {
      obs = this.rest.serviceRequest({
        action: 'insert',
        service: { name: 'Admin' },
        entity: {
          name: 'IncidentTypes',
        },
        data: model,
      });
    }
    return obs.pipe(
      map((response: IAbstractServiceData) => {
        if (model.id) {
          this.store.upsert([model.id], model);
        }
        return response;
      }),
      finalize(() => {
        this.store.setLoading(false);
      }),
    );
  }
  /** Изменение типов из Разделов */
  public saveFromSections(id: string, active: boolean): Observable<IAbstractServiceData> {
    this.store.setLoading(true);
    const obs = this.rest.serviceRequest({
      action: 'update',
      service: { name: 'Admin' },
      entity: {
        name: 'IncidentTypes',
        query: { incidentCategory: id },
      },
      data: {
        active: active,
      },
    });
    return obs.pipe(
      map((response: IAbstractServiceData) => {
        this.store.update(({ incidentCategory }) => incidentCategory === id, {
          active: active,
        });
        return response;
      }),
      finalize(() => {
        this.store.setLoading(false);
      }),
    );
  }

  /** Получение списка элементов для таблицы */
  public getKsipTypesForTable(
    query: IAnyObject,
    pageIndex: number,
    pageSize: number,
    sort?: ISort,
  ): Observable<IResponseWithTotal> {
    let limit: ILimit = undefined;
    if (pageIndex || pageSize) {
      limit = {
        paNumber: pageIndex ?? 1,
        paSize: pageSize ?? 15,
      };
    }
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query,
          name: 'IncidentTypes',
          attributes: [
            'id',
            'identifier',
            'incidentCategory.sectionId.name',
            'incidentCategory.name',
            'name',
            'typeCall.name',
            'attractionServices',
            'useInternetPortal',
            'active',
          ],
        },
        data: {
          isNeedTotal: true,
          limit,
          sort,
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return {
            items: response.data.items || [],
            totalCount: response.data.totalCount ?? 0,
          };
        }),
        catchError(() => {
          return of({
            totalCount: 0,
            items: [],
          });
        }),
      );
  }

  /** Запрашиваем данные для формы редактирования */
  public getById(id: string): Observable<IKsipTypeDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'IncidentTypes',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || [])[0] as IKsipTypeDto;
        }),
        catchError(() => {
          return of(<IKsipTypeDto>{});
        }),
      );
  }

  /** Сброс видимости инцидентов на Интернет портале, если КСиП запрещён к экспорту */
  public rejectShowIncidentOnInternetPortal(id: string): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'update',
      service: { name: 'Emergency' },
      entity: {
        name: 'Emergency',
        query: {
          incidentTypeId: id,
        },
      },
      data: {
        showOnPortal: false,
      },
    });
  }

  /**
   * Запрос активных привлекаемых служб
   * @return Список организаций отсортированный по принципу: с начала ДДС *, потом все остальные по алфавиту
   */
  public getAllAttractToReactOrganizations(): Observable<IOrganizationType[]> {
    // Если будешь править, ищи остальные реализации этого метода (они одинаковые) и тоже дорабатывай.
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'OrgTypeParams',
          query: { shortName: { $ne: null } },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          const org: IOrganizationType[] = response.data?.items ?? [];

          const dds = org
            .filter((org: IOrganizationType) => org.shortName.indexOf('ДДС') !== -1)
            .sort()
            .reverse();
          const other = org.filter((org: IOrganizationType) => org.shortName.indexOf('ДДС') === -1).sort();
          return [...dds, ...other];
        }),
      );
  }
}
