import { NgModule } from '@angular/core';

import { ForestryFacilitiesService } from './services';

@NgModule({
  imports: [],
  declarations: [],
  providers: [ForestryFacilitiesService],
})
export class ForestryFacilitiesModule {}
