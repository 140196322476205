import { Injectable } from '@angular/core';
import { Coordinates } from '@bg-front/core/models/classes';
import { SEARCH_MARKER } from '@bg-front/core/models/constants';
import { BgMapService } from '@bg-front/core/services';
import { GisService, IGisServiceResult } from '@smart-city/maps/sc';
import circle from '@turf/circle';
import ellipse from '@turf/ellipse';
import rhumbDestination from '@turf/rhumb-destination';
import sector from '@turf/sector';
import intersect from '@turf/intersect';
import lineIntersect from '@turf/line-intersect';
import * as Helpers from '@turf/helpers';
import * as L from 'leaflet';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IAnyObject, IAbstractServiceData } from 'smart-city-types';

import {
  IForecastingConsequencesOfForestFiresResultDto,
  IForecastingConsequencesOfForestFiresTaskParamsDto,
  IForecastingTechnologicalFireAreasTaskAreaResultDto,
  IForecastingTechnologicalFireAreasTaskResultDto,
} from '../../models';
import { RestService } from '@smart-city/core/services';

/**
 * Сервис для прогнозирования
 */
@Injectable()
export class ForecastingFiresVisualizationService {
  constructor(
    private readonly mapService: BgMapService,
    private readonly gisService: GisService,
    private readonly rest: RestService,
  ) {}

  /**
   * Убираем результат
   */
  public clearResults(el: any | any[]) {
    if (el) {
      if (el instanceof Array) {
        el.forEach((item: IAnyObject) => this.mapService.mapModel.removeLayer(item));
      } else {
        this.mapService.mapModel.removeLayer(el);
      }
    }
  }

  /** Построение линии */
  public displayPath(path: [number, number][]) {
    const elems = [
      L.polyline(path, {
        color: '#000',
      }).addTo(this.mapService.mapModel),
    ];

    path.forEach((el: [number, number]) => {
      elems.push(this.displayPoint(el.join(', ')));
    });

    return elems;
  }

  /**
   * Отображение маркера на карте
   * @param coordinates координаты
   * @param iconSize (optional) размеры иконки
   * */
  public displayPoint(coordinates: string, iconSize: [number, number] = [40, 40]) {
    const icon = L.divIcon({
      iconSize,
      className: 'leaflet-node-marker',
      html: L.Util.template(SEARCH_MARKER.iconOptions.shapeSVG),
      iconAnchor: [12, 32],
      popupAnchor: [0, -28],
    });

    return L.marker(new Coordinates(coordinates).toArray(), {
      icon,
    }).addTo(this.mapService.mapModel);
  }

  /**
   * Кидаем событие, когда нужно отображать результаты расчета техногенных пожаров на карте
   * @param result - результаты расчёта
   */
  public displayTechnologicalFireArea(result: IForecastingTechnologicalFireAreasTaskResultDto) {
    const elements = [];
    const coordinates = [];
    if (result) {
      result.calculatedFireAreaZones.forEach((el: IForecastingTechnologicalFireAreasTaskAreaResultDto) => {
        const coord = (el.coordinates as string).split(',').map((el: string) => +el);
        const distance = +result.fireCoveredDistance;
        let figure;
        switch (el.fireFormSysName) {
          case 'circular':
            figure = circle([coord[1], coord[0]], distance, { steps: 360, units: 'meters' });
            this.calcPolygonBoundedByWaterV2(
              figure.geometry.coordinates[0].map((el: [string, string]) => [el[1], el[0]]),
              coord,
            ).subscribe((res: IGisServiceResult[]) => {
              if ((<Helpers.Polygon>res[res.length - 1]?.geojson)?.coordinates[0]) {
                elements.push(
                  L.geoJSON(res[res.length - 1].geojson, {
                    fillColor: '#FC5A5A',
                    fillOpacity: 0.52,
                    color: '#FF0000',
                  }).addTo(this.mapService.mapModel),
                );
                coordinates.push(
                  (<Helpers.Polygon>res[res.length - 1].geojson).coordinates[0].map((el: Helpers.Position) => [el[1], el[0]]) || [],
                );
              } else {
                elements.push(
                  L.geoJSON(figure, {
                    fillColor: '#FC5A5A',
                    fillOpacity: 0.52,
                    color: '#FF0000',
                  }).addTo(this.mapService.mapModel),
                );
                coordinates.push(figure.geometry.coordinates[0].map((el: [string, string]) => [el[1], el[0]]) || []);
              }
            });
            break;
          case 'angular':
            figure = sector([coord[1], coord[0]], distance, 45, +el.angleValue, { steps: 100, units: 'meters' });
            let polygonStr = '';

            figure.geometry.coordinates[0].forEach((coordinates: number[]) => {
              polygonStr += `${coordinates[0]} ${coordinates[1]},`;
            });

            this.calcPolygonBoundedByWaterV2(
              figure.geometry.coordinates[0].map((el: [string, string]) => [el[1], el[0]]),
              coord,
            ).subscribe((res: IGisServiceResult[]) => {
              if ((<Helpers.Polygon>res[res.length - 1]?.geojson)?.coordinates[0]) {
                elements.push(
                  L.geoJSON(res[res.length - 1].geojson, {
                    fillColor: '#FC5A5A',
                    fillOpacity: 0.52,
                    color: '#FF0000',
                  }).addTo(this.mapService.mapModel),
                );
                coordinates.push(
                  (<Helpers.Polygon>res[res.length - 1].geojson).coordinates[0].map((el: Helpers.Position) => [el[1], el[0]]) || [],
                );
              } else {
                elements.push(
                  L.geoJSON(figure, {
                    fillColor: '#FC5A5A',
                    fillOpacity: 0.52,
                    color: '#FF0000',
                  }).addTo(this.mapService.mapModel),
                );
                coordinates.push(figure.geometry.coordinates[0].map((el: [string, string]) => [el[1], el[0]]) || []);
              }
            });
            break;
          case 'rectangular':
            const degrees = +el.windDirection + 180;
            const halfSide = +el.smallerSide / 2;

            // углы, перпендикулярные направлению ветра
            const normalA = degrees - 90;
            const normalB = degrees + 90;

            // рассчитываем координаты углов прямоугольника
            let coordBottomLeft;
            let coordBottomRight;
            // если распространяется в обе стороны, смещаем "нижнюю" сторону на расстояние, пройденное фронтом пожара
            if (+el.directionsCount === 2) {
              const coordBottomMid = rhumbDestination([...coord].reverse(), distance / 1000, degrees).geometry
                .coordinates;
              coordBottomLeft = rhumbDestination(
                coordBottomMid,
                halfSide / 1000,
                normalA,
              ).geometry.coordinates.reverse();
              coordBottomRight = rhumbDestination(
                coordBottomMid,
                halfSide / 1000,
                normalB,
              ).geometry.coordinates.reverse();
            } else {
              coordBottomLeft = rhumbDestination(
                [...coord].reverse(),
                halfSide / 1000,
                normalA,
              ).geometry.coordinates.reverse();
              coordBottomRight = rhumbDestination(
                [...coord].reverse(),
                halfSide / 1000,
                normalB,
              ).geometry.coordinates.reverse();
            }
            const coordTopMid = rhumbDestination([...coord].reverse(), distance / 1000, degrees).geometry.coordinates;
            const coordTopLeft = rhumbDestination(coordTopMid, halfSide / 1000, normalA).geometry.coordinates.reverse();
            const coordTopRight = rhumbDestination(
              coordTopMid,
              halfSide / 1000,
              normalB,
            ).geometry.coordinates.reverse();

            figure = L.polygon([coordBottomLeft, coordBottomRight, coordTopRight, coordTopLeft], {
              fillColor: '#FC5A5A',
              fillOpacity: 0.52,
              color: '#FF0000',
            });

            this.calcPolygonBoundedByWaterV2(
              figure.getLatLngs()[0].map((el: { lat: number; lng: number }) => [el.lat, el.lng]),
              coord,
            ).subscribe((res: IGisServiceResult[]) => {
              if ((<Helpers.Polygon>res[res.length - 1]?.geojson)?.coordinates[0]) {
                elements.push(
                  L.geoJSON(res[res.length - 1].geojson, {
                    fillColor: '#FC5A5A',
                    fillOpacity: 0.52,
                    color: '#FF0000',
                  }).addTo(this.mapService.mapModel),
                );
                coordinates.push(
                  (<Helpers.Polygon>res[res.length - 1].geojson).coordinates[0].map((el: Helpers.Position) => [el[1], el[0]]) || [],
                );
              } else {
                elements.push(figure.addTo(this.mapService.mapModel));
                coordinates.push(figure.getLatLngs()[0].map((el: { lat: number; lng: number }) => [el.lat, el.lng]));
              }
            });

            break;
        }
        // добавляем маркер
        elements.push(this.displayPoint(el.coordinates, [20, 20]));
      });
      // переходим на координаты
      this.mapService.mapModel.setView(
        result.calculatedFireAreaZones[0]?.coordinates?.split(',').map((el: string) => +el),
        20,
      );
      return {
        elements,
        coordinates,
      };
    }
  }

  /**
   * Получение полигона ограниченного водными преградами
   * @param polygon массив координат
   * @param center центр полигона, необходим для определения полигона из массива полигонов, полученных методом
   *  рассечения исходного полигона водной преградой
   * @returns geojson: полигон в формате GeoJSON
   */
  public calcPolygonBoundedByWaterV2(polygon: number[][], center: number[]): Observable<IGisServiceResult[]> {
    return this.gisService.calcPolygonBoundedByWaterV2(polygon, center);
  }

  /**
   * Получение полигона ограниченного водными преградами с упрощением водных полигонов
   * @param polygon массив координат
   * @param center центр полигона, необходим для определения полигона из массива полигонов, полученных методом
   *  рассечения исходного полигона водной преградой
   * @returns geojson: полигон в формате GeoJSON
   */
   public calcPolygonBoundedByWaterV3(polygon: number[][], center: number[]): Observable<IGisServiceResult[]> {
    const result: IGisServiceResult[] = [];
    if (!Array.isArray(polygon) || polygon.length < 3 || !Array.isArray(center) || center.length < 2) {
      return of(result);
    }

    return this.rest.serviceRequest({
        action: 'calcPolygonBoundedByWaterV3',
        service: { name: 'Gis' },
        data: {
          polygon,
          center,
          waterTolerance: 20
        },
      }).pipe(map((data: IAbstractServiceData) => data.data));
  }

  /**
   * Кидаем событие, когда нужно отображать результаты расчета лесных пожаров на карте
   * @param result - результаты расчёта
   */
  public displayConsequencesOfForestFires(
    result: IForecastingConsequencesOfForestFiresResultDto,
    params: IForecastingConsequencesOfForestFiresTaskParamsDto,
  ): Observable<IAnyObject> {
    if (result) {
      const elements = [];
      const coordinates = [];
      // извлекаем данные из результатов расчета
      const degrees = result.windDirection - 180;
      const coord = new Coordinates(result.coordinates).toArray();

      // начальный периметр пожара
      let startRadius = 0;
      if (params.sizingTypeId === 'perimeter') {
        startRadius = +params.size / (2 * Math.PI);
      } else {
        startRadius = Math.sqrt(+params.size * 10000 / Math.PI);
      }

      const fireTimes = [...result.burningTimes].sort((a, b) => +a.burningTime - +b.burningTime)
      const zones = [];
      for (let i = 0; i < fireTimes.length; i++) {
        zones.push(
          this.addForestZone(
            degrees,
            coord.slice().reverse(),
            +result.frontSpeed,
            +result.backsideSpeed,
            +result.wingsSpeed,
            +fireTimes[i]?.burningTime,
            startRadius,
          ),
        );
      }

      const req = [];

      zones.forEach((z: any, i: number) => {
        const zoneCoordinates = [...z.firePolygon.geometry.coordinates].slice()[0].map((el: number[]) => el.slice().reverse());
        req.push(this.calcPolygonBoundedByWaterV2(zoneCoordinates, coord));
      });

      return forkJoin([...req]).pipe(
        mergeMap((resArray: any[]) => {
          resArray.forEach((res: IGisServiceResult[], index: number) => {
            let intersectResult = undefined;
            res.forEach((el: IGisServiceResult) => {
              if (intersectResult) {
                const current = intersect(intersectResult, Helpers.polygon((<Helpers.Polygon>el.geojson).coordinates));
                if (current) {
                  intersectResult = current;
                }
              } else {
                intersectResult = Helpers.polygon((<Helpers.Polygon>el.geojson).coordinates);
              }
            });
            if (intersectResult) {
              // отображаем полигон пересечения с водной преградой
              elements.push(
                L.geoJSON(intersectResult, {
                  fillColor: '#FC5A5A',
                  fillOpacity: 0.6,
                  weight: 3,
                  color: '#FF0000',
                }).addTo(this.mapService.mapModel),
              );
              coordinates[index] =
                intersectResult.geometry.coordinates[0].map((el: [string, string]) => el.slice().reverse()) || [];
            } else {
              elements.push(
                L.geoJSON(zones[index].firePolygon, {
                  fillColor: '#FC5A5A',
                  fillOpacity: 0.6,
                  weight: 3,
                  color: '#FF0000',
                }).addTo(this.mapService.mapModel),
              );
              coordinates[index] = zones[index].firePolygon.geometry.coordinates[0].map((el: number[]) => el.slice().reverse());
            }

            // Отображаем направление распространения пожара. Стрелка должна упереться в обрезанный контур пожара
            if (index === zones.length - 1) {
              // Все пересечения линии фронта пожара с водой
              const intersect: Helpers.FeatureCollection<Helpers.Point> = lineIntersect(intersectResult ?? zones[index].firePolygon, zones[index].line)

              // Сортировка по отдаленнию точки пересечения с водой от центра очага
              const points: Helpers.Position[] = [...intersect.features].map(a => a.geometry.coordinates).sort((a, b) =>
                Coordinates.distanceInKmBetweenEarthCoordinates([coord, b.slice().reverse()] as [[number, number], [number, number]]) -
                Coordinates.distanceInKmBetweenEarthCoordinates([coord, a.slice().reverse()] as [[number, number], [number, number]])
              )

              // Расстояние от цента очага до контура пожара (длина стрелки)
              const arrowDistance: number = Coordinates.distanceInKmBetweenEarthCoordinates(
                [coord, points[0].slice().reverse()] as [[number, number], [number, number]]
              )
              // Смещение к левому крылу стрелки
              const leftWing: Helpers.Position = rhumbDestination(points[0], arrowDistance / 15, degrees + 150).geometry.coordinates;
              // Смещение к правому крылу стрелки
              const rightWing: Helpers.Position = rhumbDestination(points[0], arrowDistance / 15, degrees - 150).geometry.coordinates;
              elements.push(
                L.polyline([
                  coord.slice().reverse(),
                  points[0],
                  leftWing,
                  points[0],
                  rightWing,
                ].map((coords: number[]) => coords.slice().reverse()),
                {
                  color: 'black',
                  weight: 3,
                }).addTo(this.mapService.mapModel)
              )
            }
          });
          // отображаем очаг пожара
          elements.push(
            L.circle(L.latLng(coord), {
              radius: startRadius,
              fillColor: '#fff',
              fillOpacity: 0,
              color: '#000',
              dashArray: '10, 10',
              dashOffset: '0',
            }).addTo(this.mapService.mapModel),
          );

          // переходим на координаты
          this.mapService.mapModel.fitBounds(elements[0]?.getBounds());

          return of({
            elements,
            coordinates,
          });
        }),
        // catchError(() => {
        //   zones.forEach((z: any, index: number) => {
        //     console.log(z);
        //     elements.push(
        //       L.geoJSON(z, {
        //         fillColor: '#FC5A5A',
        //         fillOpacity: 0.6,
        //         weight: 3,
        //         color: '#FF0000',
        //       }).addTo(this.mapService.mapModel),
        //     );
        //     coordinates[index] = zones[index].geometry.coordinates[0].map((el: number[]) => el.reverse());
        //   });

        //   elements.push(
        //     L.circle(L.latLng(coord), {
        //       radius: startRadius,
        //       fillColor: '#fff',
        //       fillOpacity: 0,
        //       color: '#000',
        //       dashArray: '10, 10',
        //       dashOffset: '0',
        //     }).addTo(this.mapService.mapModel),
        //   );

        //   return of();
        // }),
      );
    }
  }

  /** Визуализация зоны лесного пожара для конкретного времени горения
   * @param windDirection Направление ветра в градусах
   * @param coordinates Координаты центра очага пожара ()
   * @param frontSpeed Скорость фронта
   * @param backsideSpeed Скорость тыла
   * @param wingsSpeed Скорость флангов
   * @param burningTime Время распространения
   * @param startRadius Радиус очага пожара
  */
  private addForestZone(
    windDirection: number,
    coordinates: number[],
    frontSpeed: number,
    backsideSpeed: number,
    wingsSpeed: number,
    burningTime: number,
    startRadius: number,
  ): {
    line: Helpers.Feature<Helpers.LineString>,
    firePolygon: Helpers.Feature<Helpers.Polygon>
  } {
    coordinates = coordinates.map((coord: number) => +coord.toFixed(7))
    // расстояние, пройденное фронтом пожара
    const distanceFront: number = frontSpeed * burningTime;

    // точка в которую пришел фронт пожара
    const polygoneFront: Helpers.Position = rhumbDestination(coordinates, distanceFront + startRadius + 1, windDirection, {
      units: 'meters',
    }).geometry.coordinates;

    // точки полигона пожара
    const firePolygon: Helpers.Position[] = []

    // расчет смещений координат от 0° до 180°
    for (let i = 0; i <= 180; i += 1) {
      // перевод градусов в радианы
      const radians: number = i * (Math.PI / 180)

      // смещение по оси X
      const xDelta: Helpers.Position = rhumbDestination(
        coordinates,
        wingsSpeed * Math.cos(radians) * burningTime,
        windDirection - 90,
        { units: 'meters' }
      ).geometry.coordinates

      // смещение по обеим осям
      const xyDelta: Helpers.Position = rhumbDestination(
        xDelta,
        frontSpeed * Math.sin(radians) * burningTime,
        windDirection,
        { units: 'meters' }
      ).geometry.coordinates

      // получение точки, в которую сместились
      firePolygon.push(rhumbDestination(
        xyDelta,
        startRadius,
        windDirection - 90 + i,
        { units: 'meters' }
      ).geometry.coordinates)
    }

    // расчет смещений координат от 181° до 360°
    for (let i = 181; i <= 360; i += 1) {
      // перевод градусов в радианы
      const radians = i * (Math.PI / 180)

      // смещение по оси X
      const xDelta: Helpers.Position = rhumbDestination(
        coordinates,
        wingsSpeed * Math.cos(radians) * burningTime,
        windDirection - 90,
        { units: 'meters' }
      ).geometry.coordinates

      // смещение по обеим осям
      const xyDelta: Helpers.Position = rhumbDestination(
        xDelta,
        backsideSpeed * Math.sin(radians) * burningTime,
        windDirection,
        { units: 'meters' }
      ).geometry.coordinates

      // получение точки, в которую сместились
      firePolygon.push(rhumbDestination(
        xyDelta,
        startRadius,
        windDirection - 90 + i,
        { units: 'meters' }
      ).geometry.coordinates)
    }

    return {
      line: Helpers.lineString([coordinates, polygoneFront]),
      firePolygon: Helpers.polygon([firePolygon]),
    }

  }

  /** Визуализация зоны лесного пожара для конкретного времени горения */
  private addForestZone_v2(
    isPerimeter: boolean,
    windDirection: number,
    coordinates: string,
    frontSpeed: number,
    backsideSpeed: number,
    wingsSpeed: number,
    initialFireArea: number,
    fireArea: number,
    burningTime: number,
  ) {
    // извлекаем данные из результатов расчета
    const degrees = windDirection - 180;
    const coord = new Coordinates(coordinates).toArray().reverse();

    let startRadius = 0;
    if (isPerimeter) {
      startRadius = initialFireArea / (2 * Math.PI);
    } else {
      startRadius = Math.sqrt(initialFireArea / Math.PI);
    }

    const distanceBack = (backsideSpeed * burningTime) / 2;
    const distanceWings = wingsSpeed * burningTime;

    // const majorAxis = (frontSpeed * burningTime + startRadius * 2) / 2 + backsideSpeed * burningTime;
    const majorAxis = (frontSpeed * burningTime + startRadius * 2 + backsideSpeed * burningTime) / 2;
    //const majorAxis = (frontSpeed * burningTime ) + startRadius  + backsideSpeed * burningTime;
    const minorAxis = (distanceWings * 2 + startRadius * 2) / 2;
    // const minorAxis = (fireArea * 10000) / (Math.PI * (startRadius + distanceWings) );

    const center = rhumbDestination(coord, majorAxis - startRadius - backsideSpeed * burningTime, degrees, {
      units: 'meters',
    }).geometry.coordinates;

    return ellipse(center, majorAxis, minorAxis, <any>{
      steps: 10000,
      angle: degrees + 90,
      units: 'meters',
    });
  }
}
