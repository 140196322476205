<ng-container *ngIf="!openFromWebcamFailureForm">
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group readonly-container">
        <div class="readonly-container__column">
          <label>Наименование устройства</label>
          <p>{{ name }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group">
        <div formGroupName="reasonFormGroup">
          <sc-select [options]="optionsReasons" formControlName="reasonId"></sc-select>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="bg-row" *ngIf="canPhotoAndVideo?.visible">
  <div class="bg-col">
    <mat-tab-group class="sc-tab-group" [disableRipple]="true">
      <mat-tab [label]="'Фото ' + (idx + 1)" *ngFor="let imageUrl of imagesUrls; let idx = index">
        <img [src]="imageUrl | safe: 'url'" [alt]="'Изображение инцидента ' + (idx + 1)" class="image" />
      </mat-tab>
      <mat-tab [label]="'Видеоинтервал'">
        <span *ngIf="!sfsId; else player">{{videoStatus}}</span>
        <ng-template #player>
          <bg-hls-player
            [sourceType]="'sfs'"
            [sfsId]="sfsId"
          ></bg-hls-player>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<div class="bg-row" *ngIf="canPreparePhotoAndVideo?.visible">
  <div class="bg-col">
    <bg-download-sources [options]="downloadSourcesOptions"></bg-download-sources>
  </div>
</div>
