import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IRnisExternalMessage } from '../../models/interfaces';

/**
 * Сервис для взаимодействия внешних компонент системы с компонентом РНИС.
 *
 * Для передачи сообщения в компонент РНИС, необходимо подключить данный сервис в соответствующим компоненте приложения.
 * Используйте метод sendMessage для отправки объекта сообщения.
 * Сообщение должно содержать action, с текстом действия.
 *
 * Обработка сообщений происходит в компоненте {@link RnisControlLayerComponent}, по подписке на onMessage$ данного сервиса.
 * Реализуйте свой кейс обработки.
 *
 * @example
 * // Отправка сообщений в РНИС:
 * const message = { action: 'onRemoveTracking', data: {} };
 * this.rnisExternal.sendMessage(message);
 *
 * @example
 * // Обработка сообщений:
 * this.rnisExternal.onMessage$
 *   .pipe(untilDestroyed(this))
 *   .subscribe((message: IAnyObject) => {
 *     switch (message.action) {
 *       case 'onRemoveTracking':
 *       // Обработка
 *       break;
 *     }
 *   });
 */
@Injectable({ providedIn: 'root' })
export class RnisExternalService {
  /** Отправка сообщений */
  private commit = new Subject<IRnisExternalMessage>();
  /** Поступающие сообщения от внешних компонент */
  public onMessage$ = this.commit.asObservable();



  /**
   * Отправить сообщение в компонент РНИС
   * @param message объект сообщения
   */
  public sendMessage(message: IRnisExternalMessage): void {
    if (!message.action) {
      console.warn('[RnisExternalService] message.action не определен!');
      console.warn('Для передачи сообщения укажите действие (например: "tracking" или другое)');
      return;
    }
    this.commit.next(message);
  }
}
