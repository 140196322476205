import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/** Общие валидаторы для DatetimeComponent */
export class BgDatetimeValidator {
  /**
   * Валидация минимального значения контрола
   */
  static minDate(value: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && control.value < value) {
        return { minDate: true };
      }
      return null;
    };
  }

  /**
   * Валидация минимального значения контрола относительно
   */
  static minDateOf(value: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && control.value <= value) {
        return { minDateOf: true };
      }
      return null;
    };
  }
}
