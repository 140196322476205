<div class="dialog-container">
  <h3>{{data.title}}</h3>
  <form [formGroup]="form" errorTailor id="idForTest">
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select
            [options]="fileTypeOptions"
            formControlName="fileType"
          ></sc-select>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="btn-container">
          <sc-button [options]='cancelOptions' (clickButton)="onCloseHandler()"></sc-button>
          <sc-button [options]='exportOptions' (clickButton)="onExportHandler()"></sc-button>
        </div>
      </div>
    </div>
  </form>
</div>
