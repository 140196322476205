import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

import { MapLayersState } from '../../../models/states';

@Injectable()
@StoreConfig({ name: 'map-base-layers' })
export class MapLayersStore extends EntityStore<MapLayersState> {
  constructor() {
    super([]);
  }
}
