<div class="map-extFilter">
  <div class="icons_wrapper">
    <div class="icons">
      <div
        *ngFor="let filter of filters"
        (click)="onFilterClick(filter)"
        [ngClass]="{'disabled-filter': filter.disableFilterIcon, 'selected': filter.selected}"
        [attr.tooltip]="filter.name"
        [attr.flow]="'down'"
      >
        <img *ngIf="filter.icon" class="icon" src="{{filter.icon.iconUrl}}" alt="">
      </div>
    </div>
    <div class="icon icon_kebab">
      <mat-icon
        class="more_vert"
        (click)="onKebabClick()"
        [matBadge]="matBadgeCount.toString()"
        matBadgeColor="warn"
        [matBadgeHidden]="!matBadgeCount"
      >more_vert</mat-icon>
    </div>
  </div>
</div>
<bg-rnis-control-layer *ngIf='isRnis'></bg-rnis-control-layer>
