import { Directive, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IElementButton,
  INwHeaderBarOptions,
  IScCheckboxOptions,
  IScFias3Options,
  IScInputOptions,
  IScSelectLoadParams,
  IScSelectOptions,
  ScSelectComponent,
  IScTextareaOptions,
  IScTextButtonOptions,
  ScButtonComponent,
  TLayoutComponent,
} from '@smart-city/core/common';
import { IDictionaryInfo, IScSelectItem } from '@smart-city/core/interfaces';
import { AccessService, IAccessAction, Settings2Service, SfsService } from '@smart-city/core/services';
import { IGisServiceResult } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { BehaviorSubject, forkJoin, noop, iif, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  IAbstractServiceData,
  IAnyObject,
  IAppealDetailDto,
  IDictionaryModelDto,
  IEmergencyDto,
  IEmergencyEventDto,
  IFileInfoDto,
  IForecastingDetailDto,
  IForecastingForestFireParamsDto,
  IForecastingParamsDto,
  IIntegrationEventDto,
  IKsipTypeDto,
  ILifeCycleStepDto,
  ISurveyAnswerDto,
} from 'smart-city-types';
import { ResponseScriptsService } from '../../../bg/modules/administration/services';
import { AppInjector, Coordinates } from '@bg-front/core/models/classes';
import { BASE_EVENT_FORM_SERVICES_ERROR_ICON } from '@bg-front/core/models/constants';
import { BgDatetimeValidator } from '@bg-front/core/validators';
import { ConfirmDialogResultEnum } from '@bg-front/core/models/enums';
import { convertObjectToArray, GrammarHelper } from '@bg-front/core/models/helpers';
import { IDateTimeOptions } from '@bg-front/core/models/interfaces';
import { EmergencyCallEventDto } from '../../models/classes';
import {
  IAttractionOrganizationsList,
  ICreateEmergencyStepsParams,
  IDeclarer,
  IDetailOrganization,
  IKsipDetailsDto,
  IOrganization,
  IOrganizationType,
  IOrgTypeParamOrgList,
} from '../../models/interfaces';
import {
  BgAdminService,
  DeclarerService,
  DeclarerStoreService,
  DetailOrganizationService,
  EmergencyService,
  LifeCycleService,
  OrganizationsService,
  ResponsePlanStepService,
  SurveyQuestionsService,
  AtmIntegrationService,
  RecordService,
} from '../../services';
import { RegistryStateService } from '@bg-front/registry-panel/services';
import { CloseWithoutReactionDialogComponent } from '../close-without-reaction-dialog/close-without-reaction-dialog.component';
import { ForecastingRadiationChemicallyParamsFormComponent } from '../forecasting-radiation-chemically-params-form/forecasting-radiation-chemically-params-form.component';
import { InternetPortalCommentDialogComponent } from '../internet-portal-comment-dialog/internet-portal-comment-dialog.component';
import {
  AlertDialogComponent,
  BaseComponent,
  ConfirmationDialogComponent,
  MapDialogComponent,
} from '@bg-front/core/components';
import { DetailOrganizationDialogComponent } from '../detail-organization-dialog/detail-organization-dialog.component';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { BgMapService, LayersDataService, MultiFileService } from '@bg-front/core/services';
import { CallService } from '../../../call/services';
import { IMonitoringObjectHcsDto } from '../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/models/interfaces';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { ForecastingForestFireParamsFormComponent } from '../../../app-common/components';
import { GarFindFlatResponseElement } from '@bg-front/core';

//TODO: Посмотреть на потомков класса, есть предположение что они не нужны
@UntilDestroy()
@Directive()
export abstract class BaseEventEditFormComponent extends BaseComponent implements OnInit {
  /**
   * Форма
   */
  public eventForm: FormGroup;
  /**
   * Модель
   */
  public model: EmergencyCallEventDto = new EmergencyCallEventDto();

  /** Источник */
  public sourceId: string = undefined;

  /** Комментарий для портала */
  public showPortalComment = false;

  /** Флаг для отображения блока "Параметры" */
  public showForecastingParams: boolean = false;

  /** Опции для настройки блока "Параметры" */
  public projectedRisk: string;

  private validationControlMap = new Map<string, string>([
    ['factAddress', 'Главное. Адрес КСиП'],
    ['ksipTypeId', 'Главное. Тип КСиП'],
    ['organizationId', 'Главное. Ответственная организация'],
    ['responsibleUser', 'Главное. Ответственный пользователь'],
    ['ksipTime', 'Главное. Дата и время возникновения КСиП'],
    ['chemicalTypeId', 'Параметры. Тип АХОВ'],
    ['resourceConstraintType', 'Дополнительная информация. Тип ограничения ресурса'],
    ['workTermFrom', 'Главное. Плановый срок проведения работ с'],
    ['workTermTo', 'Главное. Плановый срок проведения работ по'],
  ]);

  /**
   * Конфигурация заголовка
   */
  public headerActionsOptions: INwHeaderBarOptions = undefined;
  /** Адрес заданный по координатам */
  public coordinatesAddressOptions: IScInputOptions = {
    label: 'Адрес КСиП *',
    maxLength: 100,
  };
  /** RegExp для валидации координат */
  public coordinatesPattern: RegExp = /(-?\d{1,2}[.]\d+)[,][ ]+(-?\d{1,3}[.]\d+)/;
  /** Настройки чекбокса переключения на адрес по координатам */
  public byCoordinatesOptions: IScCheckboxOptions = {
    title: 'Адрес задан координатами',
  };
  /** Флаг - способ задания адреса */
  public byCoordinates: boolean = false;
  /** Адрес по координатам */
  public addressByCoordinates: GarFindFlatResponseElement;

  /**
   * Настройка компоненты муниципальное образование
   */
  public moIdOptions: IScSelectOptions = {
    title: 'Муниципальное образование',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'Municipal',
    fieldName: 'name',
    query: { active: true },
    afterLoadDataFn: (data: IScSelectItem[]) => {
      // Если только одно значение можно выбрать из селекта
      if (data.length === 1) {
        // Если текущее значение МО отличается от того который остался в селекте
        if (this.moIdComponent.value !== data[0].id) {
          this.moIdComponent.value = data[0].id;
          this.districtIdComponent.value = undefined;
          this.districtIdOptions.query = { active: true, municipalId: data[0].id };
          this.districtIdComponent.typeahead.next(undefined);
          this.eventForm.controls.districtId.enable();
        }
      } else {
        // Если значений не одно, надо проверить что текущее значение входит в список значений селекта.
        // В негативном случае сбросить значения. Так же надо сбросить если значений нет
        if (
          ![...data, ...(this.moIdOptions.data || [])].find(
            (item: IScSelectItem) => item.id === this.moIdComponent.value,
          )
        ) {
          this.moIdComponent.value = undefined;
          this.districtIdComponent.value = undefined;
          this.eventForm.controls.districtId.disable();
        }
      }
      // Видимость селекта МО
      this.moIdVisible = this.accessService.accessMap['EventEmergencyMoVisible']?.visible;
      return of(data);
    },
  };

  /**
   * Настройка компоненты район
   */
  public districtIdOptions: IScSelectOptions = {
    title: 'Район',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'Districts',
    fieldName: 'name',
    query: { active: true },
    beforeLoadDataFn: (params: IScSelectLoadParams) => {
      // Для сохранения текущего значения фильтра по введенной строке
      this.districtIdOptions.query = {
        ...this.districtIdOptions.query,
        ...params.query,
      };
      return of(params);
    },
    afterLoadDataFn: (data: IScSelectItem[]) => {
      // Не скрывать контрол, если имеется фильтр по введенной строке
      if (!this.districtIdOptions.query.$text) {
        // Если нечего вывести в селекте, то скрыть его
        this.districtIdVisible =
          this.accessService.accessMap['EventEmergencyDistrictVisible']?.visible && data.length > 0;
        if (data.length === 0) this.districtIdComponent.value = null;
      }
      delete this.districtIdOptions.query.$text;
      return of(data);
    },
  };

  /**
   * Настройка компоненты Описание
   */
  public optionsDescription: IScTextareaOptions = {
    label: 'Описание',
    maxLength: 1000,
    rows: 3,
  };
  /**
   * Настройка компоненты Дополнительная информация о месте КСиП
   */
  public optionsKsipInfo: IScTextareaOptions = {
    label: 'Дополнительная информация о месте КСиП',
    maxLength: 1000,
    rows: 2,
  };
  /**
   * Настройка компоненты Комментарий
   */
  public optionsComment: IScTextareaOptions = {
    label: 'Комментарий',
    maxLength: 1000,
    rows: 2,
  };
  /**
   * Настройка компоненты Ответственный пользователь
   */
  public optionsResponsibleUser: IScSelectOptions;
  /**
   * Настройка компоненты Срочно
   */
  public optionsUrgently: IScCheckboxOptions = {
    title: 'Срочно',
  };
  /**
   * Настройка компоненты Угроза населению
   */
  public optionsThreatPopulation: IScCheckboxOptions = {
    title: 'Угроза населению',
  };
  /**
   * Настройка компоненты Угроза организации
   */
  public optionsThreatOrganization: IScCheckboxOptions = {
    title: 'Угроза организации',
  };
  /** Привлекаемые службы */
  public attractionServices: IOrganizationType[] = [];
  /** Привлекаемые Организации */
  public attractionOrganizations: IAttractionOrganizationsList[] = undefined;
  /**
   * список организаций для блока "Детализация по организациям"
   */
  public detailOrganizationList: IDetailOrganization[] = [];
  /** Показ иконки в чекерах с привлекаемымим службами */
  public showAttractionIcon: boolean = false;

  /** Флаг для отображения контролов для плановых работ */
  public isWorkTerm: boolean = false;

  /** Флаг для отображения контролов жкх
   * TODO жду новый справочник и использую флаг
   */
  public isJhk: boolean = false;

  /** Видимость Отправить в "Реформу ЖКХ" */
  public isSendingReformToJkhVisible: boolean = false;
  /** Значение Отправить в "Реформу ЖКХ" */
  public isSendingReformToJkhValue: boolean;
  /** флаг на значение 'Эксплуатация жилищного фонда' */
  public isHousingService: boolean;
  /** Статус отправки информации в реформу ЖКХ */
  public hcsReformSendingStatus: string = 'Чтобы отправить в Реформу ЖКХ заполните поле Объект ЖКХ';
  /** Цвет иконки для статуса отправки информации в реформу ЖКХ */
  public hcsReformSendingStatusIconColor: string = undefined;
  /** Значение 'Связанные ограничения' */
  public isNestedRestrictionsValue: boolean = false;
  /** Флаг для отображения блока детализация по службам */
  public attractOrganizationVisible: boolean = false;

  /**
   * Настройка компоненты Тип КСиП
   */
  public optionsIncidentTypes: IScSelectOptions = {
    title: 'Тип КСиП',
    clearable: true,
    additionalFields: ['attractionServices'],
  };

  public loadKsipTypes$: Observable<IScSelectItem[]>;

  public loadKsipDetails$: Observable<IScSelectItem[]>;

  /**
   * Настройка компоненты Тип КСиП
   */
  public optionsIncidentTypesDetails: IScSelectOptions = {
    title: 'Детализация КСиП',
    clearable: true,
    modern: true,
    data: [],
  };
  /** Наименование организации */
  public optionsOrganization: IScSelectOptions = {
    title: 'Ответственная организация',
    clearable: true,
    service: 'Admin',
    entity: 'Organizations',
    query: { active: true },
    modern: true,
    fieldName: 'name',
    additionalFields: ['mo'],
    afterLoadDataFn: (data: IScSelectItem[]) => {
      data.forEach((item: IScSelectItem) => {
        item.data['moIds'] = item.data['mo'];
        delete item.data.mo;
      });
      return of(data);
    },
  };

  /** Объект ЖКХ */
  public jkhObjectOptions: IScSelectOptions = {
    title: 'Объект ЖКХ',
    clearable: true,
    modern: true,
    service: 'AtmIntegration',
    entity: 'MonitoringObject',
    fieldName: 'name',
    query: { active: true },
  };

  /** Отправить в "Реформу ЖКХ"  */
  public sendingToJkhReformOptions: IScCheckboxOptions = {
    title: 'Отправить в "Реформу ЖКХ"',
  };

  /** Отправить в "Реформу ЖКХ"  */
  public resourceConstraintTypeOptions: IScSelectOptions = {
    title: 'Тип ограничения ресурса',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'Dictionary',
    query: { 'typeid.sysname': 'resourceLimitationType' },
    fieldName: 'name',
  };

  /** Связанные ограничения  */
  public nestedRestrictionsOptions: IScCheckboxOptions = {
    title: 'Связанные ограничения',
  };

  /** Cфера ЖКХ */
  public jkhDirectionOptions: IScSelectOptions = {
    title: 'Сфера ЖКХ',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'IncidentCategories',
    width: '100%',
    isMultiple: true,
    fieldName: 'name',
    query: { 'sectionId.sysname': 'zhkh' },
  };

  /**
   * Уточнить координаты адреса поле
   */
  public exactCoordinatesOptions: IScInputOptions = {
    label: 'Уточнить координаты адреса',
    hidden: true,
  };
  /** Конфигурация для кнопки уточнить адрес */
  public optionsSpecifyAddressOnMap: IScTextButtonOptions = {
    title: 'Уточнить адрес на карте',
    color: 'primary',
  };
  /** Адрес заявителя */
  public declarerOptions: IScInputOptions = {
    label: 'Адрес заявителя',
    hidden: true,
  };
  public optionsDeclarerAddress: IScTextButtonOptions = {
    title: 'Адрес заявителя',
    color: 'primary',
  };
  /**
   * В радиусе 50 метров есть важные объекты
   */
  public hasDangerObject: boolean;
  /**
   * Количество важных объектов рядом с инцидентом
   */
  public countOfCloseImportantObjects: number;
  /**
   * Сообщение о важных объектах рядом с инцидентом
   */
  public closeImportantObjectsMessage: string;
  /** Массив ответовов из компонента опроса */
  public surveyAnswers: ISurveyAnswerDto[] = [];
  /** Массив переответовов из компонента опроса */
  public surveyReAnswers: ISurveyAnswerDto[] = [];
  /** Флаг, менялось ли значение КСиП ранее */
  public isKsipChangeEarly = false;
  /** Флаг, менялось ли значение организации ранее */
  public isOrganizationChangeEarly = false;
  /** Mo выбранной организации */
  public organizationChooseMo: string;
  /** Доступность блока привлекаемые службы */
  public canAttractionServicesShow: IAccessAction = {
    visible: false,
    enabled: false,
    name: 'CanAttractionServicesShow',
  };
  public minFromDate: number = dayjs().startOf('day').valueOf();

  /** Настройка компоненты Плановый срок проведения работ с */
  public workTermFromOptions: IDateTimeOptions = {
    label: 'Плановый срок проведения работ с *',
    format: 'DD.MM.YYYY HH:mm',
    dataType: 'unix',
  };

  /** Ошибки компоненты  Плановый срок проведения работ с */
  public workTermFromErrors = {
    minDate: 'Не может быть ранее текущей даты',
  };

  /** Настройка компоненты Плановый срок проведения работ с */
  public workTermToOptions: IDateTimeOptions = {
    label: 'Плановый срок проведения работ по *',
    format: 'DD.MM.YYYY HH:mm',
    dataType: 'unix',
  };

  /** Ошибки компоненты  Плановый срок проведения работ с */
  public workTermToErrors = {
    minDate: 'Не может быть ранее текущей даты',
    minDateOf: 'Не может быть ранее даты начала работ',
  };

  /** Флаг для формы "Авария" Детализации Ксип */
  public isAccidentFormDetails: boolean = false;

  /** Сервисы */
  public readonly emergencyService: EmergencyService;
  public readonly orgService: OrganizationsService;
  public readonly settings: Settings2Service;
  public readonly ksipTypesQuery: KsipTypesQuery;
  public readonly ksipDetailsQuery: KsipDetailsQuery;
  public readonly ksipCategoriesQuery: KsipCategoriesQuery;
  public readonly ksipSectionsQuery: KsipSectionsQuery;
  public readonly significantObjectService: SignificantObjectsService;
  public readonly organizationsService: OrganizationsService;
  public readonly dialog: MatDialog;
  public readonly lifeCycleService: LifeCycleService;
  public readonly router: Router;
  public readonly declare: DeclarerService;
  public readonly declarerStore: DeclarerStoreService;
  public readonly surveyQuestionsService: SurveyQuestionsService;
  public readonly multiFileService: MultiFileService;
  public readonly fb: FormBuilder;
  public readonly bgAdminService: BgAdminService;
  public readonly callService: CallService;
  /** Ссылка на аудио-файл */
  public audioUrl: string = undefined;
  /** Ссылка на аудио-файл от 112 */
  public audio112Url: string = undefined;
  /** Проверка изменений */
  public isDirty$: Observable<boolean> = of(false);
  /** Координаты адреса для списка важных объектов*/
  public coordinates: string = '';
  /** Доступность контрола Организация */
  public canChangedOrganization: IAccessAction = { visible: false, enabled: false, name: 'ChangedOrganization' };
  public serviceIconTooltipText = BASE_EVENT_FORM_SERVICES_ERROR_ICON;
  /**
   * Объект которые хранит ключ параметр типа организации а значение массив организаций
   */
  public orgTypeParamOrganizList: { [key: string]: IOrganization[] } = {};
  /**
   *  Типы для организаций, которые нужно привлечь в зависимости от выбранного ксип
   */
  private chosenAttractListByKsip: string[] = [];
  /**
   * Общий список организаций, найденных по типу
   */
  private commonOrgListByType: IOrganization[] = [];

  /**
   * Используемы организации в созданных поручениях
   */
  private existedOrgList: string[] = [];
  /** Объект который возвращается по нажатию на кнопку */
  private eventModel: IElementButton;
  private readonly sfsService: SfsService;
  private readonly gisService: LayersDataService;
  private readonly bgMapService: BgMapService;
  private readonly accessService: AccessService;
  /** Эталонное значение формы */
  private ngMasterValue: BehaviorSubject<IAnyObject>;
  private readonly responsePlanStepService: ResponsePlanStepService;
  private readonly responseScriptService: ResponseScriptsService;
  private readonly detailOrganizationService: DetailOrganizationService;
  protected readonly registryStateService: RegistryStateService;
  private readonly recordService: RecordService;
  private attractActiveOrgList: IOrganizationType[] = [];
  private emergencyResponse: IEmergencyDto = null;
  private eventName: string;
  private orgTypeParamForSteps: string;
  /** Фактический адрес */
  public factAddress: GarFindFlatResponseElement;
  public linkedName: string;
  /** Компонент кнопки Передать в службы */
  private transferToServiceButton: ScButtonComponent;
  /** Компонент селекта МО */
  private moIdComponent: ScSelectComponent;
  /** Компонент селекта Район */
  private districtIdComponent: ScSelectComponent;
  /** Видимость селекта МО */
  public moIdVisible: boolean = true;
  /** Видимость селекта Район */
  public districtIdVisible: boolean = true;
  /** Ответственная организация */
  public responsibleOrganizations: IOrganization[];

  /** Видимость чекбокса Срочно */
  public urgentlyVisible: boolean = true;
  /** Видимость чекбоксов Угроза населению и Угроза организациям */
  public threatsVisible: boolean = true;
  /** Информация о заявителе */
  public declarerId: IDeclarer;

  public linkToJkhObjectVisible: boolean = false;
  public monitoringObjectId: string;
  public isVisibleAtmLink$: Observable<boolean> = of(false);

  constructor(public readonly route: ActivatedRoute, public readonly atmIntegrationService: AtmIntegrationService) {
    super();

    const injector = AppInjector.getInjector();
    this.emergencyService = injector.get(EmergencyService);
    this.orgService = injector.get(OrganizationsService);
    this.settings = injector.get(Settings2Service);
    this.significantObjectService = injector.get(SignificantObjectsService);
    this.organizationsService = injector.get(OrganizationsService);
    this.dialog = injector.get(MatDialog);
    this.lifeCycleService = injector.get(LifeCycleService);
    this.router = injector.get(Router);
    this.sfsService = injector.get(SfsService);
    this.responseScriptService = injector.get(ResponseScriptsService);
    this.responsePlanStepService = injector.get(ResponsePlanStepService);
    this.gisService = injector.get(LayersDataService);
    this.declare = injector.get(DeclarerService);
    this.declarerStore = injector.get(DeclarerStoreService);
    this.surveyQuestionsService = injector.get(SurveyQuestionsService);
    this.accessService = injector.get(AccessService);
    this.multiFileService = injector.get(MultiFileService);
    this.detailOrganizationService = injector.get(DetailOrganizationService);
    this.fb = injector.get(FormBuilder);
    this.registryStateService = injector.get(RegistryStateService);
    this.ksipTypesQuery = injector.get(KsipTypesQuery);
    this.ksipDetailsQuery = injector.get(KsipDetailsQuery);
    this.ksipCategoriesQuery = injector.get(KsipCategoriesQuery);
    this.ksipSectionsQuery = injector.get(KsipSectionsQuery);
    this.bgAdminService = injector.get(BgAdminService);
    this.bgMapService = injector.get(BgMapService);
    this.callService = injector.get(CallService);
    this.recordService = injector.get(RecordService);
  }

  /** @ignore */
  public ngOnInit(): void {
    // Подписка на поднятие трубки при входящем вызове, необходимо закрывать текущую форму события
    this.callService.onPickupIn$
      .pipe(
        mergeMap(() => iif(() => !!this.declarerStore.getFormData(), this.declare.saveDeclarerInfo(), of({}))),
        untilDestroyed(this),
      )
      .subscribe((declarerId: IDeclarer) => {
        this.declarerId = declarerId;
        this.closeActionHandler();
      });

    this.byCoordinates = this.model.byCoordinates;
    this.generateHeaderBar();
    // Видимость чекбокса Срочно
    this.urgentlyVisible = this.accessService.accessMap['UrgentlyAvailable']?.visible;
    // Видимость чекбоксов Угроза населению и Угроза организациям
    this.threatsVisible = this.accessService.accessMap['ThreatsAvailable']?.visible;

    this.eventForm = new FormGroup({
      byCoordinates: new FormControl(this.model.byCoordinates),
      factAddress: new FormControl(this.model.address ?? history?.state?.factAddress, [Validators.required]),
      coordinatesAddress: new FormControl(this.model.byCoordinates ? this.model.address.fullText : null),
      moId: new FormControl(this.model.moId),
      districtId: new FormControl({ value: this.model.districtId, disabled: true }),
      details: new FormControl(this.model.details),
      ksipTypeId: new FormControl(this.model.ksipTypeId, [Validators.required]),
      ksipDetailsId: new FormControl(this.model.ksipDetailsId),
      organizationId: new FormControl(this.model.organizationId),
      comment: new FormControl(this.model.comment ?? history?.state?.comment),
      description: new FormControl(this.model.description),
      responsibleUser: new FormControl(this.model.responsibleId ?? this.settings.currentUser.id, [Validators.required]),
      urgently: new FormControl(this.model.urgently),
      threatPopulation: new FormControl(this.model.threatPopulation),
      threatOrganization: new FormControl(this.model.threatOrganization),
      ksipInfo: new FormControl(this.model.ksipInfo ?? history?.state?.ksipInfo),
      ksipTime: new FormControl(this.model.ksipTime ?? history?.state?.ksipTime ?? Date.now(), [Validators.required]),
      exactCoordinates: new FormControl(this.model.exactCoordinates ?? history?.state?.coordinates),
      documents: new FormControl(this.model.documents),
      forecasting: new FormControl(this.model.forecastingDetailId ?? null),
      workTermFrom: new FormControl(this.model.workTermFrom ?? Date.now()),
      workTermTo: new FormControl(this.model.workTermTo),
      jkhObject: new FormControl(this.model.jkhObject ?? history?.state?.jkhObject),
      isSendingToJkhReform: new FormControl(this.model.isSendingToJkhReform),
      resourceConstraintType: new FormControl(this.model.resourceConstraintType),
      isNestedRestrictions: new FormControl(this.model.isNestedRestrictions),
      jkhDirection: new FormControl(this.model.jkhDirection),
      isTest: new FormControl(this.model.isTest),
    });

    this.initAttractionServicesShow();
    this.initForm();
    this.setForecastingParamsOptions();
    this.moAndDistrictInit();

    // Проверка возможности отправки информации в реформу ЖКХ в случае открытия красного события.
    // Возможна ситуация когда у события уже увели объект ЖКХ или тип КСиП
    if (this.eventForm.controls.inSendingToJkhReform) this.checkHcsReformSendAbility();

    let incident: IEmergencyDto;
    // Подписка действие привязки события к инциденту
    this.registryStateService.bindEvent$
      .pipe(
        switchMap((incidentLink: { incident: IEmergencyDto }) => {
          incident = incidentLink.incident;

          if (this.model.incidentId) {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
              disableClose: false,
              minWidth: 450,
            });
            dialogRef.componentInstance.isHtml = false;
            dialogRef.componentInstance.message = `Событие уже связано с происшествием! Повторная привязка
              приведет к потере данной связи. Продолжить?`;
            return dialogRef.afterClosed();
          } else return of(ConfirmDialogResultEnum.confirm);
        }),
        filter((result: ConfirmDialogResultEnum) => result === ConfirmDialogResultEnum.confirm),
        switchMap(() => {
          if (!this.eventForm.value.factAddress && !this.eventForm.value.details && !this.eventForm.value.ksipTypeId) {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
              disableClose: false,
              minWidth: 450,
            });
            dialogRef.componentInstance.isHtml = false;
            dialogRef.componentInstance.message = `Заполнить событие данными из связанного происшествия?`;
            return dialogRef.afterClosed();
          } else return of(ConfirmDialogResultEnum.reject);
        }),
        untilDestroyed(this),
      )
      .subscribe((result: ConfirmDialogResultEnum) => {
        if (result === ConfirmDialogResultEnum.confirm) {
          this.eventForm.patchValue(
            {
              factAddress: incident.addressFact,
              details: incident.detailsFact,
              ksipTypeId: incident.incidentTypeId,
            },
            { emitEvent: false },
          );
        }

        this.model.incidentId = incident.id;
        this.model.incidentNumber = incident.number;

        if (!this.model.incidentNumber?.startsWith('w')) {
          this.linkedName = `Инцидент ${this.model.incidentNumber}`;
        } else {
          this.linkedName = `Плановая работа ${this.model.incidentNumber}`;
        }
      });

    /** Подписка на изменения чекбокса - Адрес по координатам */
    this.eventForm.controls.byCoordinates.valueChanges.pipe(untilDestroyed(this)).subscribe((value: boolean) => {
      this.byCoordinates = value;
      const factAddressControl = this.eventForm.controls.factAddress;
      const coordinatesAddressControl = this.eventForm.controls.coordinatesAddress;
      factAddressControl.reset();
      if (value) {
        factAddressControl.clearValidators();
        coordinatesAddressControl.setValidators([Validators.required, Validators.pattern(this.coordinatesPattern)]);
      } else {
        coordinatesAddressControl.reset();
        coordinatesAddressControl.clearValidators();
        factAddressControl.setValidators(Validators.required);
      }
      factAddressControl.updateValueAndValidity();
      coordinatesAddressControl.updateValueAndValidity();
    });

    /** Подписка на изменение значения адреса по координатам */
    this.eventForm.controls.coordinatesAddress.valueChanges
      .pipe(
        filter((value: string) => !!Coordinates.coordinatesToArray(value)?.length),
        tap((value: string) => {
          const coordinates: number[] = Coordinates.coordinatesToArray(value);
          this.addressByCoordinates = {
            fullText: value,
            latitude: coordinates[0],
            longitude: coordinates[1],
          };
          this.setJkhObjectByAddress(this.addressByCoordinates);
        }),
        debounceTime(1500),
        untilDestroyed(this),
      )
      .subscribe(() => {
        // установка значений блока "Важные объекты рядом с КСиП"
        if (!this.addressByCoordinates?.latitude && !this.addressByCoordinates?.longitude) {
          this.coordinates = '';
          this.setCloseImportantObjectsSettings(null);
        }
        if (this.addressByCoordinates?.latitude && this.addressByCoordinates?.longitude) {
          this.factAddress = this.addressByCoordinates;
          this.coordinates = `${this.addressByCoordinates.latitude}, ${this.addressByCoordinates.longitude}`;
          this.setCloseImportantObjectsSettings(
            new Coordinates(this.addressByCoordinates.latitude, this.addressByCoordinates.longitude),
          );
        }
      });
    // Вызываем обновление чтобы тригернуть valueChanges выше и разбить контрол на координаты
    this.eventForm.controls.coordinatesAddress.updateValueAndValidity();

    this.eventForm.controls.jkhObject.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      if (!value) {
        this.eventForm.controls.isSendingToJkhReform.disable();
        this.eventForm.controls.isSendingToJkhReform.setValue(false);
        this.hcsReformSendingStatus = 'Чтобы отправить в Реформу ЖКХ заполните поле Объект ЖКХ';
        this.hcsReformSendingStatusIconColor = undefined;
      } else {
        this.setAddressByJkhObject(value);
      }
      this.linkToJkhObjectVisible = this.isJhk && !!value;
      this.monitoringObjectId = value;
      this.setAddressObservableForLink();
      this.checkHcsReformSendAbility();
    });

    /**
     * Обработчик изменения Типа ксип
     * @param $event
     * @return
     */
    this.eventForm.controls.ksipTypeId.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value: string) => {
        if (value) {
          this.getForecastingParamsInfo(value);

          const ksip = this.ksipTypesQuery.getById(value);
          const category = this.ksipCategoriesQuery.getById(ksip.incidentCategory);
          const section = this.ksipCategoriesQuery.getById(category.id)?.sectionId;
          this.isWorkTerm =
            this.settings.getDictionaryById(ksip.eventTypeId)?.sysname === '003' ||
            this.settings.getDictionaryById(ksip.eventTypeId)?.sysname === '001';
          this.isJhk = this.ksipSectionsQuery.getById(section)?.sysname === 'zhkh';
          this.setWorkTermValidators();
          if (this.isJhk) {
            this.setJkhObjectByAddress(this.eventForm.controls.factAddress.value);
          }

          this.isHousingService = this.settings.getDictionaryById(category.supplyTypeHcs)?.sysname !== 'housingService';
          this.isSendingReformToJkhVisible = Boolean(ksip.submitToCommServ);
          if (this.isSendingReformToJkhVisible) this.checkHcsReformSendAbility();

          this.setResourceConstraintTypeValidators();

          if (this.isKsipChangeEarly && ksip.id) {
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              width: '600px',
            });
            dialogRef.componentInstance.isHtml = true;
            dialogRef.componentInstance.message = `Внимание!<br>
        Изменение типа КСиП влечёт обновление списка привлекаемых служб, а также потерю связи с выбранным происшествием.<br>
        Необходимо заполнить данные параметры повторно.`;
          }
          this.chosenAttractListByKsip = ksip.attractionServices || [];
          const isOrganizationId = this.canChangedOrganization?.visible
            ? this.eventForm.controls['organizationId'].value
            : this.settings.currentUser?.organizationId?.id;
          if (ksip.id && isOrganizationId) {
            const condition = (this.model.ksipTypeId || this.eventForm.get('ksipTypeId').value) === ksip.id;
            if (
              this.initAttractOrganizations() &&
              // condition &&
              (ksip.id || !this.isKsipChangeEarly)
            ) {
              this.model.incidentId = null;
              this.executeGetOrganizationByAddress();
            }
            this.isKsipChangeEarly = true;
          } else {
            this.disableAttractServices();
          }
          this.setKsipDetailsOptions(ksip);
          this.setJkhObjectOptions(category.supplyTypeHcs);
        } else {
          this.disableAttractServices();
          this.disableDetailOrganizations();
          this.setKsipDetailsOptions(undefined);
          this.setJkhObjectOptions();
        }
        this.eventForm.controls.ksipDetailsId.setValue(null);
      });

    this.eventForm.controls.ksipDetailsId.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value: string) => {
        const detail = this.ksipDetailsQuery.getById(value)?.eventType;
        this.isAccidentFormDetails = this.settings.getDictionaryById(detail)?.sysname === '001';
        if (this.isAccidentFormDetails) {
          this.setWorkTermValidators();
        }
      });
  }

  /**
   * Генерация кнопок действий
   */
  public generateHeaderBar() {
    const btnArray = [];

    btnArray.unshift(<IElementButton>{
      type: 'button',
      options: {
        name: 'cancel',
        icon: 'clear',
      },
    });

    btnArray.push(<IElementButton>{
      type: 'button',
      options: {
        name: 'closeWithoutReaction',
        title: 'Закрыть без реагирования',
        icon: 'not_interested',
      },
    });
    btnArray.push(<IElementButton>{
      type: 'button',
      options: {
        color: 'primary',
        name: 'transferToService',
        title: 'Принять в работу',
        icon: 'forward',
        type: 'submit',
      },
    });

    this.headerActionsOptions = {
      title: this.model.id ? `Событие ${this.model.number}` : 'Новое событие',
      name: 'header',
      margin: 'collapse',
      bgColor: 'white',
      elements: [
        {
          type: 'checkbox',
          options: {
            name: 'isTest',
            title: 'Тестовое',
            formGroup: this.eventForm,
          },
        },
      ],
      buttons: btnArray,
    };
  }

  public initForm() {
    // В зависимости от того как было получено событие getEddsEventViewById/getEventById, привязанный КСиП пришел
    // либо в виде объекта либо в виде пары incidentNumber incidentId.
    // Если нет информации о номере напрямую, то попытаться взять его из объекта
    this.model.incidentNumber = this.model.incidentNumber || (<IEmergencyDto>this.model.incidentId)?.number;
    this.fillAttractionOrg();
    this.isSendingReformToJkhVisible = Boolean(this.model.isSendingToJkhReform);

    this.loadKsipTypes$ = this.ksipTypesQuery
      .selectAll({
        filterBy: [({ active }) => active],
        sortBy: 'name',
      })
      .pipe(
        map((items: IKsipTypeDto[]) => {
          return items.map(
            (ksip: IKsipTypeDto) =>
              <IScSelectItem>{
                id: ksip.id,
                text: ksip.name,
                data: {
                  attractionServices: this.attractionServices
                    .filter((org: IOrganizationType) => (ksip.attractionServices || []).includes(org.id))
                    .map((org: IOrganizationType) => {
                      return org.shortName;
                    }),
                },
              },
          );
        }),
      );

    const ksip = this.ksipTypesQuery.getById(this.model?.ksipTypeId);
    if (ksip) {
      const category = this.ksipCategoriesQuery.getById(ksip.incidentCategory);
      const section = this.ksipCategoriesQuery.getById(category.id)?.sectionId;
      this.isWorkTerm =
        this.settings.getDictionaryById(ksip.eventTypeId)?.sysname === '003' ||
        this.settings.getDictionaryById(ksip.eventTypeId)?.sysname === '001';
      this.isJhk = this.ksipSectionsQuery.getById(section)?.sysname === 'zhkh';
      if (this.isWorkTerm) {
        this.setWorkTermValidators();
      }

      this.isHousingService = this.settings.getDictionaryById(category.supplyTypeHcs)?.sysname !== 'housingService';
      this.isSendingReformToJkhVisible = Boolean(ksip.submitToCommServ);

      this.setResourceConstraintTypeValidators();
    }

    if (ksip?.ksipDetails?.length) {
      this.loadKsipDetails$ = this.ksipDetailsQuery.selectMany(ksip.ksipDetails).pipe(
        map((items: IKsipDetailsDto[]) =>
          items.map(
            (detail: IKsipDetailsDto) =>
              <IScSelectItem>{
                id: detail.id,
                text: detail.name,
              },
          ),
        ),
      );
    } else {
      this.loadKsipDetails$ = of([]);
    }

    this.optionsResponsibleUser = {
      title: 'Ответственный пользователь',
      clearable: true,
      service: 'Admin',
      entity: 'Users',
      modern: true,
      fieldId: 'id',
      fieldName: 'fio',
      query: {
        isActive: true,
        organizationId: this.settings.currentUser.organizationId?.id
          ? this.settings.currentUser.organizationId?.id
          : this.settings.currentUser.organizationId,
      },
    };

    // Если нет разрешающего правила (enabled === true), то действие запрещено
    this.canChangedOrganization = this.accessService.accessMap['ChangedOrganization'];
    if (!this.canChangedOrganization?.enabled) {
      /** Для нового события автоматом берётся организация пользователя */
      if (!this.model.id) {
        this.model.organizationId = this.settings.currentUser?.organizationId?.id;
      }
      this.eventForm.controls.organizationId.disable();
    }
    let sourceName = undefined;
    const sourceTypes = this.settings.getDictionaryByTypeSysName('sourceTypes');
    /**
     * Если новое событие, то источник берём из настроек роута
     * Если мы редактируем событие то берём на основе ранее записанного
     */
    if (!this.model.id) {
      sourceName = this.route.snapshot.data['sourceTypeSysName'];
      this.sourceId = sourceTypes.find((item: IDictionaryInfo) => {
        return item.sysname === this.route.snapshot.data['sourceTypeSysName'];
      })?.id;
    } else {
      this.sourceId = this.model.sourceId;
      sourceName = sourceTypes.find((item: IDictionaryInfo) => {
        return item.id === this.model.sourceId;
      })?.sysname;
    }

    const sourceType = this.settings.getDictionaryById(this.model.sourceId)?.sysname;
    if (sourceType === '112') {
      const incidentId =
        typeof this.model.incidentId === 'object' ? (this.model.incidentId as IEmergencyDto).id : this.model.incidentId;
      this.recordService
        .getVoiceRecord(incidentId)
        .pipe(
          untilDestroyed(this),
          catchError((error: Error) => {
            this.catchErrorFn<IEmergencyEventDto>(error, 'Ошибка при получении аудиозаписи');
            return of(null);
          }),
        )
        .subscribe((res) => {
          if (res) {
            const blob = new Blob([new Uint8Array(res.data, 0, length)], { type: 'audio/wav' });
            this.audio112Url = URL.createObjectURL(blob);
          }
        });
    }

    /** Устанавливаем привлекаемые организации */
    this.orgService
      .getAttractToReactOrganizations(true, true)
      .pipe(
        mergeMap((attractOrg) => {
          const ids = attractOrg.map(({ id }) => id);
          // Пред заполняем организации при инициализации формы
          return this.orgService.getOrganizationByQuery({ orgTypeParam: { $in: ids }, active: true }).pipe(
            map((orgList) => {
              this.commonOrgListByType = orgList;
              return attractOrg;
            }),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe((orgs: IOrganizationType[]) => {
        this.attractActiveOrgList = orgs;
        orgs.forEach((org: IOrganizationType) => {
          // Заполняю дефолтным значением контролы
          this.orgTypeParamOrganizList[org.id] = this.commonOrgListByType.filter(
            ({ orgTypeParam }) => orgTypeParam === org.id,
          );
          const ctrl = new FormControl(undefined);
          this.model.ksipTypeId ? noop() : ctrl.disable();
          if (this.isUserOrg(org.organizationTypeId)) {
            ctrl.setValue(true, {
              emitEvent: false,
            });
            ctrl.disable();
          }

          this.validationControlMap.set(`org-${org.id}`, `Детализация по службам. ${org.shortName}`);

          this.eventForm.registerControl(org.id, ctrl);
        });
        this.attractionServices = orgs;
        if (this.initAttractOrganizations()) {
          this.initDetailOrganizationListForSelector();
        }

        this.showPortalComment = sourceName === 'internetPortal';

        if (this.canChangedOrganization?.visible) {
          const org = this.settings.currentUser?.organizationId?.id;
          this.eventForm.controls.organizationId.setValidators([Validators.required]);
          if (!this.model.organizationId) {
            this.eventForm.controls.organizationId.setValue(org, { emitEvent: false });
          }
        }

        this.ngMasterValue = new BehaviorSubject<IAnyObject>(this.eventForm.getRawValue());
        this.isDirty$ = dirtyCheck(this.eventForm, this.ngMasterValue.asObservable());

        // Мегакостыль для того, чтобы форма знала, что были произведены изменения в
        // блоке загрузки документов, так как dirtyCheck не видит разницы в массивах
        // даже, если у них разное количество элементов, которыми являются документы.
        // Для этого в мастер объект вписываем свойство, которое далее не учавствует нигде.
        // TODO убрать, когда dirtyCheck станет правильно сравнивать массивы
        this.eventForm.controls.documents.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
          this.ngMasterValue.next({
            ...this.ngMasterValue.getValue(),
            megacrutch: dayjs().unix() * 1000,
          });
        });

        this.eventForm.controls.isSendingToJkhReform.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
          this.isSendingReformToJkhValue = Boolean(value);
          this.setResourceConstraintTypeValidators();
        });
      });

    /**
     * Если новое событие, то источник берём из настроек роута
     * Если мы редактируем событие то берём на основе ранее записанного
     */
    if (!this.model.id) {
      this.sourceId = this.settings.getDictionaryByTypeSysName('sourceTypes').find((item: IDictionaryInfo) => {
        return item.sysname === this.route.snapshot.data['sourceTypeSysName'];
      })?.id;
    } else {
      this.sourceId = this.model.sourceId;
    }

    /**
     * Если форма открыта на редактирование, заполняем
     * координаты из координат адреса модели
     */
    if (this.model.id && this.model.address) {
      this.coordinates = `${this.model.address.latitude}, ${this.model.address.longitude}`;
      this.setCloseImportantObjectsSettings(new Coordinates(this.model.address.latitude, this.model.address.longitude));
    }

    if (this.model.parentId) {
      if (typeof this.model.parentId === 'object') {
        if (this.model.parentId.timeEnd && this.model.parentId?.sfsId) {
          this.audioUrl = this.sfsService.getStreamUrl(this.model.parentId?.sfsId);
        }
      } else {
        this.emergencyService
          .getIntegrationEventById(this.model.parentId)
          .pipe(untilDestroyed(this))
          .subscribe((integrationEvent: IIntegrationEventDto) => {
            if (integrationEvent.sfsId && integrationEvent.timeEnd) {
              this.audioUrl = this.sfsService.getStreamUrl(integrationEvent.sfsId);
            }
          });
      }
    }

    /** Подписка на изменение контрола "адрес КСиП" */
    this.eventForm.controls.factAddress.valueChanges
      .pipe(debounceTime(1500), untilDestroyed(this))
      .subscribe((val: GarFindFlatResponseElement) => {
        if (val) {
          this.declarerStore.setKsipAddress(val);
        }
        // установка значений блока "Важные объекты рядом с КСиП"
        if (!val?.latitude && !val?.longitude) {
          this.coordinates = '';
          this.setCloseImportantObjectsSettings(null);
        }
        if (val?.latitude && val?.longitude) {
          this.factAddress = val;
          this.coordinates = `${val.latitude}, ${val.longitude}`;
          this.setJkhObjectByAddress(val);
          this.setCloseImportantObjectsSettings(new Coordinates(val.latitude, val.longitude));
        }

        // Актуализация селекта МО в зависимости от значения ОКТМО адреса
        if (val?.oktmo) {
          const oktmoSearchString = val.oktmo.substr(0, this.settings.getConfig().oktmoCheckLength || undefined);
          this.moIdOptions.query = {
            $expr: { $regexMatch: ['$OKTMO', `^${oktmoSearchString}`] },
            active: true,
          };
          this.moIdOptions.data = [];
          this.moIdComponent.typeahead.next(undefined);
        } else {
          this.moIdOptions.query = { active: true };
          this.moIdOptions.data = [];
          this.moIdComponent?.typeahead.next(undefined);
        }
      });

    this.isNestedRestrictionsValue = this.eventForm.controls.isNestedRestrictions.value;

    this.eventForm.controls.isNestedRestrictions.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.isNestedRestrictionsValue = value;
    });

    this.declare.resetDeclarersStore();
  }

  /**
   * Обработчик изменения чекбокса в блоке Привлекаемые службы
   * @param $event
   * @param id - id типа служюы
   * @return
   */
  public attractionServiceChange($event: IAnyObject, id: string): void {
    /**
     * Изменения чекбокса привлекаемой службы должен активировать/деактивировать соответствующий
     * селектор с привлекаемой организацией. Если селектор с привлекаемой организацией активный к нему
     * необходимо добавить валидатор, иначе убрать валидатор из селектора если он не активный
     * в противном случае селектор будет валидитроваться если он не активный
     */
    /** Имя селектора с привлекаемой организацией */
    const controlSelectorName = `org-${id}`;
    const method = $event.checked ? 'enable' : 'disable';
    const validators = $event.checked ? [Validators.required] : [];
    // работа с селектором сначала работаем с валидатором затем с котролом
    this.eventForm.controls[controlSelectorName].setValidators(validators);
    this.eventForm.controls[controlSelectorName][method]();
  }

  /**
   * Инициализирует наполнение detailOrganizationList для отображения списка селекторов с организациями
   * в блоке Детализация организаций
   * @return
   */
  public initDetailOrganizationListForSelector() {
    const list: IOrgTypeParamOrgList[] = convertObjectToArray<IOrgTypeParamOrgList>(
      this.orgTypeParamOrganizList,
      'id',
      'foundOrganizations',
    );
    const mo = this.getOrganizationMo();
    /** Регистрация контролов для привлекаемых организаций */
    this.registerControlsForSelectorWithAttractService(list);
    this.detailOrganizationList = list.map((item: IOrgTypeParamOrgList) => {
      return {
        controlName: `org-${item.id}`,
        id: item.id,
        foundOrganizations: item.foundOrganizations.length ? item.foundOrganizations : [],
        options: {
          disabled: !this.eventForm.controls[item.id].value,
          value: this.receiveValueForAttractOrganization(item),
          title: this.attractionServices.find(({ id }) => id === item.id)?.shortName,
          fieldName: 'name',
          clearable: true,
          modern: true,
          required: true,
          ...this.detailOrganizationService.getDefaultConfigForSelectorWithAttractService(item, mo),
        },
      };
    });
  }

  /** Открытие диалога с доаолнительной информацией об организации  */
  public openDetailOrgDialog(organizations: IOrganization[]): void {
    this.dialog.open(DetailOrganizationDialogComponent, {
      width: '600px',
      minWidth: '650px',
      data: { organizations },
    });
  }

  /**
   * Заполняет значение селектора для организации
   * @param item
   * @return
   */
  private receiveValueForAttractOrganization(item: IOrgTypeParamOrgList): string {
    const value = item.foundOrganizations.length === 1 ? item.foundOrganizations[0]?.id : null;
    const defaultValue = (this.model.attractionOrganizations || [])?.find(
      (attrItem) => Object.keys(attrItem)[0] === item.id,
    );
    return defaultValue ? defaultValue[item.id][0] : value;
  }

  /**
   * Вызов получения организация по адресу и Mo для предзаполнения
   * и заполнения после изменения типа КСИП или Организации
   * @return
   */
  private executeGetOrganizationByAddress(): void {
    const mo = this.getOrganizationMo();
    const services = this.attractionServices.map(({ id }) => id);
    const factAddress = this.eventForm.controls.factAddress.value;

    this.orgService
      .getOrganizationByAddress(services, mo, factAddress)
      .pipe(
        tap((orgTypeParamOrganizList) => {
          this.resetOrgList();
          this.orgTypeParamOrganizList = {
            ...this.orgTypeParamOrganizList,
            ...orgTypeParamOrganizList,
          };
          this.showAttractionIcon = true;
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        /** Устанавливаем значение чекбоксов после получения организаций */
        this.setAttractionsCheckboxValues(
          this.attractionServices,
          this.eventForm,
          this.chosenAttractListByKsip,
          this.orgTypeParamOrganizList,
        );
        // Логика заполнения чекбоксов, если организации уже сохранены в событии
        this.model.attractionOrganizations.forEach((item) => {
          const orgTypeParam = Object.keys(item)[0];
          this.eventForm.controls[orgTypeParam].setValue(true);
        });
        /** Регистрирую контролы с организациями */
        this.initDetailOrganizationListForSelector();
        this.model.attractionOrganizations = [];
      });
  }

  /**
   * Инициализация значений чекбоксов для привлекаемых служб
   * @param attractionServices - привлекаемые службы
   * @param eventForm - ссылка на форму события
   * @param choosedAttractListByKsip - Подобранные типы организаций от ксип
   * @param orgListObj - Объект хранящий информацию о подобранных организациях
   * @return
   */
  public setAttractionsCheckboxValues(
    attractionServices: IOrganizationType[],
    eventForm: FormGroup,
    choosedAttractListByKsip: string[],
    orgListObj,
  ): void {
    const userOrgType = this.settings.currentUser.organizationId?.orgTypeParam;
    attractionServices.forEach((org: IOrganizationType) => {
      eventForm.controls[org.id].setValue(false);
      eventForm.controls[org.id]?.enable();
      if (orgListObj[org.id].length && choosedAttractListByKsip.includes(org.id)) {
        eventForm.controls[org.id].setValue(true);
      }
      if (!orgListObj[org.id].length) {
        eventForm.controls[org.id].setValue(false);
        eventForm.controls[org.id]?.disable();
      }
      if (userOrgType === org.id) {
        eventForm.controls[org.id].setValue(false);
        eventForm.controls[org.id]?.disable();
      }
    });
  }

  /**
   * Метод для регистрации селекторов в форме
   * @param list
   * @return
   */
  public registerControlsForSelectorWithAttractService(list: IOrgTypeParamOrgList[]): void {
    list.forEach((item: IOrgTypeParamOrgList) => {
      const controlName = `org-${item.id}`;
      const value = this.receiveValueForAttractOrganization(item);
      const disabled = !this.eventForm.controls[item.id].value;
      this.registerControlForAttractService(this.eventForm, controlName, value, disabled);
    });
  }

  /** Метод для регистрации селектора в форме
   * @param eventForm
   * @param controlName
   * @param value
   * @param disabled
   * @return
   */
  public registerControlForAttractService(eventForm, controlName, value, disabled) {
    if (!eventForm.get(controlName)) {
      const ctrl = new FormControl(value);
      eventForm.registerControl(controlName, ctrl);
    } else {
      eventForm.controls[controlName].setValue(value);
    }
    if (!disabled) {
      eventForm.controls[controlName].enable();
      eventForm.controls[controlName].setValidators([Validators.required]);
    } else {
      (<AbstractControl>eventForm.controls[controlName]).clearValidators();
    }
  }

  /**
   * Метод который устанавливает значение в контрол Детализация ксип
   * @param ksip - Тип инцидента
   * @return
   */
  private setKsipDetailsOptions(ksip: IKsipTypeDto): void {
    if (ksip?.id && ksip?.ksipDetails?.length) {
      this.loadKsipDetails$ = this.ksipDetailsQuery.selectMany(ksip.ksipDetails).pipe(
        map((items: IKsipDetailsDto[]) =>
          items.map(
            (el: IKsipDetailsDto) =>
              <IScSelectItem>{
                id: el.id,
                text: el.name,
              },
          ),
        ),
      );
    } else {
      this.loadKsipDetails$ = of([]);
    }
  }

  /**
   * Установка фильтрации объектов ЖКХ в зависимости от вида снабжения типа КСиП
   * @param supplyTypeId - ID типа снабжения ЖКХ
   */
  private setJkhObjectOptions(supplyTypeId?: string) {
    this.jkhObjectOptions.query.supplyTypeId = supplyTypeId || undefined;
  }

  /**
   * Обработчик изменения организаци
   * @param $event
   * @return
   */
  public organizationChange($event: IAnyObject): void {
    const isMoChanged = $event.data?.moIds && !(this.organizationChooseMo === $event.data?.moIds);
    if (this.isOrganizationChangeEarly && isMoChanged) {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '600px',
      });
      dialogRef.componentInstance.isHtml = true;
      dialogRef.componentInstance.message = `Внимание!<br>
        Изменение организации влечёт обновление списка привлекаемых служб.<br>
        Необходимо выбрать службы повторно.`;
    }

    if ($event.data?.moIds) {
      this.organizationChooseMo = $event.data?.moIds;
    } else {
      this.orgService
        .getOrganizationBy($event.id)
        .pipe(untilDestroyed(this))
        .subscribe((organization: IOrganization) => (this.organizationChooseMo = organization?.mo));
    }

    if ($event?.id && this.eventForm.controls['ksipTypeId'].value) {
      if (this.initAttractOrganizations() && this.eventForm.controls['ksipTypeId'].value && isMoChanged) {
        this.executeGetOrganizationByAddress();
      }
    } else {
      this.disableAttractServices();
      this.disableDetailOrganizations();
    }

    if ($event.id) {
      this.organizationsService
        .getOrganizationBy($event.id)
        .pipe(untilDestroyed(this))
        .subscribe((organization: IOrganization) => {
          this.responsibleOrganizations = [organization];
        });
    }
    this.isOrganizationChangeEarly = true;
  }

  /**
   * Дезейблит привелекаемые службы
   * @return
   */
  private disableAttractServices(): void {
    this.attractionServices?.forEach((org: IOrganizationType) => {
      if (!this.isUserOrg(org.organizationTypeId)) {
        this.eventForm.controls[org.id]?.disable();
      }
    });
  }

  /**
   * Блокирует контролы блока "Детализация по службам"
   */
  private disableDetailOrganizations(): void {
    this.detailOrganizationList?.forEach((org: IDetailOrganization) => {
      this.eventForm.controls[org.controlName]?.disable();
    });
  }

  /**
   * Проверяем является ли организация, организацией текущего пользователя
   * @param typeId тип организации
   */
  public isUserOrg(typeId: string): boolean {
    return this.settings.currentUser.organizationId?.organizationType === typeId;
  }

  /**
   * Получение данных из формы и сохранение в модель
   */
  public getModelData() {
    if (!this.model.number) {
      this.model.timeCreate = dayjs().unix() * 1000;
      this.model.number = `e-${dayjs(this.model.timeCreate).format('YYYYMMDD-HHmmss')}`;
      this.model.sourceId = this.sourceId;
      this.model.isHandled = false;
    }
    this.model.organizationId =
      this.eventForm.controls['organizationId'].value || this.settings.currentUser.organizationId?.id;
    this.model.byCoordinates = this.eventForm.controls['byCoordinates'].value;
    const coordinates: string[] | undefined = this.eventForm.controls['exactCoordinates'].value?.split(',');
    this.model.address = this.model.byCoordinates
      ? this.addressByCoordinates
      : {
        ...this.eventForm.controls['factAddress'].value,
        latitude: coordinates ? +coordinates[0] : undefined,
        longitude: coordinates ? +coordinates[1] : undefined,
      };
    this.model.moId = <string>this.moIdComponent.value;
    this.model.districtId = <string>this.districtIdComponent.value;
    this.model.details = this.eventForm.controls['details'].value;
    this.model.ksipTypeId = this.eventForm.controls['ksipTypeId'].value;
    this.model.ksipDetailsId = this.eventForm.controls['ksipDetailsId'].value;
    this.model.comment = this.eventForm.controls['comment'].value;
    this.model.description = this.eventForm.controls['description'].value;
    this.model.ksipInfo = this.eventForm.controls['ksipInfo'].value;
    this.model.ksipTime = this.eventForm.controls['ksipTime'].value;
    this.model.urgently = this.eventForm.controls['urgently'].value;
    this.model.threatOrganization = this.eventForm.controls['threatOrganization'].value;
    this.model.threatPopulation = this.eventForm.controls['threatPopulation'].value;
    this.model.responsibleId = this.eventForm.controls['responsibleUser'].value;
    this.model.exactCoordinates = this.eventForm.controls['exactCoordinates'].value;

    if (this.showForecastingParams) {
      this.model.forecastingDetailId = this.model.forecastingDetailId
        ? this.model.forecastingDetailId
        : ({
            params: {},
          } as IForecastingDetailDto);
      (this.model.forecastingDetailId as IForecastingDetailDto).params = (
        this.eventForm.controls.forecasting as FormGroup
      )?.getRawValue() as IForecastingParamsDto;
    } else {
      this.model.forecastingDetailId = null;
    }

    this.model.attractionServices = [];

    this.attractionServices.forEach((org: IOrganizationType) => {
      this.eventForm.controls[org.id].value ? this.model.attractionServices.push(org.id) : noop();
    });

    this.model.attractionOrganizations = this.detailOrganizationService.mapAttractionOrgForModel(
      this.attractionServices,
      this.eventForm,
    );
    this.model.documents = this.eventForm.controls['documents'].value;
    if (this.isAccidentFormDetails || this.isWorkTerm) {
      this.model.workTermFrom = this.eventForm.controls['workTermFrom'].value;
      this.model.workTermTo = this.eventForm.controls['workTermTo'].value;
    }
    this.model.jkhObject = this.isJhk ? this.eventForm.controls['jkhObject'].value : null;
    this.model.isSendingToJkhReform = this.isJhk ? this.eventForm.controls['isSendingToJkhReform'].value : null;
    this.model.resourceConstraintType = this.model.isSendingToJkhReform
      ? this.eventForm.controls['resourceConstraintType'].value
      : null;
    this.model.isNestedRestrictions = this.model.isSendingToJkhReform
      ? this.eventForm.controls['isNestedRestrictions'].value
      : null;
    this.model.jkhDirection = this.model.isNestedRestrictions ? this.eventForm.controls['jkhDirection'].value : null;
    this.model.isTest = Boolean(this.eventForm.controls['isTest'].value);
    this.model.creationAuthor = this.settings.currentUser.id;
  }

  /** Второй шаг валидации */
  public secondStepValidate(): Observable<boolean> {
    if (this.transferToServiceButton) this.transferToServiceButton.options.disabled = true;
    if (this.model.incidentId) {
      /**  обработка если инцидент привязан к событию */
      this.model.isHandled = true;
      this.model.declarerId = undefined;
      const parentId = this.model.parentId as IIntegrationEventDto;
      if (parentId?.id) {
        this.model.parentId = parentId.id;
      }
      this.ngMasterValue.next(this.eventForm.getRawValue());
      return this.updateLinkedEmergency();
    }
    const coordinates = new Coordinates(this.model.address.latitude, this.model.address.longitude);

    if (!coordinates.isValid()) {
      return of(true);
    }
    return this.checkExistedEmergencyByCoordinates(coordinates);
  }

  /**
   * Обновление, привязанного инцидента к событию вместе с планом реагирования и заявителем
   */
  private updateLinkedEmergency(): Observable<boolean> {
    return this.declare.saveDeclarerInfo().pipe(
      switchMap((result: IDeclarer) => {
        this.model.declarerId = result;

        const declarer = { ...this.model.declarerId };
        return forkJoin([
          this.responsePlanStepService.getExistedPlanSteps(this.model.incidentId as string),
          this.emergencyService
            .executeEventAction({
              action: 'transferToService',
              model: this.getEventModel(),
            })
            .pipe(
              mergeMap((event: IEmergencyEventDto) => {
                const orgId: string = event.organizationId || this.settings.currentUser.organizationId?.id;
                return this.organizationsService.getOrganizationTypeParam(orgId).pipe(
                  mergeMap((orgTypeParam: IOrganizationType) => {
                    this.orgTypeParamForSteps = orgTypeParam?.id;
                    return of(event);
                  }),
                );
              }),
              mergeMap((event: IEmergencyEventDto) => {
                this.setEventIdToAnswers(this.surveyAnswers, event.id);
                this.setEventIdToAnswers(this.surveyReAnswers, event.id);
                return forkJoin([
                  of(event),
                  forkJoin([
                    this.surveyQuestionsService.insertAnswers(this.surveyAnswers),
                    this.surveyQuestionsService.updateAnswers(this.surveyReAnswers),
                  ]).pipe(
                    catchError((error: Error) =>
                      this.catchErrorFn<IAbstractServiceData>(error, 'Ошибка при сохранении ответов'),
                    ),
                  ),
                ]).pipe(
                  map((response) => {
                    return response[0];
                  }),
                );
              }),
            ),
        ]).pipe(
          catchError((err: Error) =>
            this.catchErrorFn<[string[], IEmergencyEventDto]>(
              err,
              'Ошибка при сохранении события с привязанным инцидентом',
            ),
          ),
          mergeMap(([orgList, model]: [string[], IEmergencyEventDto]) => {
            this.existedOrgList = orgList;
            return forkJoin([this.declare.createEmergencyDeclarer(declarer)]);
          }),
          map(() => false),
          finalize(() => {
            this.existedOrgList = [];
            this.closeForm();
          }),
        );
      }),
    );
  }

  /**
   * Проверка существующего инцидента по координатам
   * @param  coordinates - координаты
   * @return
   */
  private checkExistedEmergencyByCoordinates(coordinates: Coordinates): Observable<boolean> {
    return this.emergencyService.hasDoubleEmergencies(coordinates, 500, this.model.ksipTypeId).pipe(
      switchMap((hasDouble: boolean) => {
        if (hasDouble) {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            closeOnNavigation: true,
            disableClose: true,
          });

          dialogRef.componentInstance.isHtml = true;
          dialogRef.componentInstance.message = `Внимание!<br>
            По указанным данным существует открытое происшествие!<br>
            Вы действительно хотите создать новое?`;

          return dialogRef.afterClosed().pipe(
            untilDestroyed(this),
            switchMap((result: ConfirmDialogResultEnum) => {
              return of(ConfirmDialogResultEnum.confirm === result);
            }),
          );
        }
        return of(true);
      }),
    );
  }

  /**
   * Первый шаг валидации
   */

  public firstStepValidate(): Observable<boolean> {
    const services = this.attractionServices.filter((org: IOrganizationType) =>
      this.model.attractionServices.includes(org.id),
    );

    const { noInteractions, hasInteraction } = this.convertAttractionServicesListByInteraction(services);

    if (noInteractions.length) {
      this.openAlertDialogWithoutInteraction(noInteractions);
    }

    if (hasInteraction.length) {
      this.compareOrgByInteraction(hasInteraction);
    }

    return of(true);
  }

  /**
   * Метод сравнения количества текущих организация с информационным взаимодействием и фактических
   * @param hasInteraction - организации с информац. взаимодействием
   * @return
   */
  private compareOrgByInteraction(hasInteraction: IOrganizationType[]): Observable<boolean> {
    const attractionOrganizations = [];
    const userMoId: string = this.getUserMo();
    return forkJoin(
      ...hasInteraction.map((service: IOrganizationType) =>
        this.organizationsService.getSingleOrganizationForAttraction(userMoId, service.id),
      ),
    ).pipe(
      map((result: [IOrganization[]]) => {
        result.forEach((organization: IOrganization[]) => {
          const filteredOrgs = organization.filter((org: IOrganization) => org.active);
          if (filteredOrgs.length === 1) {
            const curAttractOrg = { id: organization[0].id, organizationType: organization[0].orgTypeParam };
            attractionOrganizations.push(curAttractOrg);
          }
          if (organization.length > 1) {
            const organizationId: IOrganization = organization.find((elem: IOrganization) => elem.mo === userMoId);
            if (organizationId?.id && organizationId?.orgTypeParam) {
              attractionOrganizations.push({
                id: organizationId.id,
                organizationType: organizationId.orgTypeParam,
              });
            }
          }
        });

        if (attractionOrganizations.length < hasInteraction.length) {
          this.openAlertDialogWithInteraction(hasInteraction);
        }
        return attractionOrganizations.length === hasInteraction.length;
      }),
    );
  }

  /**
   * Метод который открывает диалог - предупреждение
   * @param noInteractions - организация без информ. взаимодействия
   * @return
   */
  private openAlertDialogWithoutInteraction(noInteractions: IOrganizationType[]): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      closeOnNavigation: true,
      disableClose: true,
      width: '400px',
    });

    dialogRef.componentInstance.isHtml = true;
    dialogRef.componentInstance.message = `Внимание!<br>
            Не со всеми организациями есть информационное взаимодействие. Поручения созданы не будут.
            Необходимо проинформировать следующие службы:<br>
            <strong>${noInteractions.map((service: IOrganizationType) => service.shortName).join('<br>')}</strong>`;
  }

  /**
   * Метод который открывает диалог - предупреждение
   * @param hasInteraction - организация c информ. взаимодействия
   * @return
   */
  private openAlertDialogWithInteraction(hasInteraction: IOrganizationType[]): void {
    const typeNameList = hasInteraction.map(({ shortName }) => shortName).join(', ');
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      closeOnNavigation: true,
      disableClose: true,
      width: '400px',
    });

    dialogRef.componentInstance.isHtml = true;
    dialogRef.componentInstance.message = `Внимание!<br>
            Для службы ${typeNameList} организации не найдены. Автоматически поручения созданы не будут.`;
  }

  /**
   * Метод конвертирует типы организаций в 2 отдельный массив с информ взаимодействием и без информ
   * @param services - список типов организаций
   * @return
   */
  private convertAttractionServicesListByInteraction(services: IOrganizationType[]): {
    noInteractions: IOrganizationType[];
    hasInteraction: IOrganizationType[];
  } {
    const noInteractions = [];
    const hasInteraction = [];
    services.forEach((service: IOrganizationType) => {
      if (!this.isUserOrg(service.organizationTypeId)) {
        service.informationInteraction ? hasInteraction.push(service) : noInteractions.push(service);
      }
    });
    return {
      noInteractions,
      hasInteraction,
    };
  }

  /**
   * Обработка инициализации заголовка
   * @param button - информация о компонентах (кнопках) в заголовке
   */
  public onHeaderInit(button: TLayoutComponent) {
    const event = button as ScButtonComponent;
    // Сохранение компонента кнопки Передать в службы для возможности ее дальнейшей блокировки/разблокировки
    if (event.options.name === 'transferToService') this.transferToServiceButton = event;
  }

  /**
   * Обрабатываем нажатие кнопки управления состоянием
   * @param $event
   */
  public onClickActionsButton($event: IElementButton) {
    if ($event.options.name === 'cancel') {
      this.closeActionHandler();
      return;
    }

    if ($event.options.name === 'closeWithoutReaction') {
      const dialogRef = this.dialog.open(CloseWithoutReactionDialogComponent, {
        closeOnNavigation: true,
        panelClass: 'close-without-reaction-dialog-container',
      });

      dialogRef.componentInstance.needComment = this.showPortalComment;

      dialogRef.afterClosed().subscribe((result: { reasonId: string; comment: string } | string) => {
        if (result) {
          if (this.transferToServiceButton) this.transferToServiceButton.options.disabled = true;
          this.getModelData();
          this.model.closeReasonId = this.showPortalComment
            ? (result as { reasonId: string; comment: string }).reasonId
            : (result as string);
          if (this.showPortalComment) {
            if ((this.model.appealDetail as IAppealDetailDto).comments) {
              (this.model.appealDetail as IAppealDetailDto).comments.push({
                lifeCycleStepId: {
                  ...((this.model.incidentId as IEmergencyDto)?.lifeCycleStepId as ILifeCycleStepDto),
                },
                createTime: Date.now(),
                text: (result as { reasonId: string; comment: string }).comment,
                userId: this.settings.currentUser.id,
              });
            } else {
              (this.model.appealDetail as IAppealDetailDto).comments = [
                {
                  lifeCycleStepId: {
                    ...((this.model.incidentId as IEmergencyDto)?.lifeCycleStepId as ILifeCycleStepDto),
                  },
                  createTime: Date.now(),
                  text: (result as { reasonId: string; comment: string }).comment,
                  userId: this.settings.currentUser.id,
                },
              ];
            }
          }

          this.multiFileService
            .saveFilesToSfs(this.model.documents)
            .pipe(
              switchMap((documents) => {
                this.model.documents = documents;
                return this.declare.saveDeclarerInfo().pipe(
                  catchError((err: Error) => this.catchErrorFn(err, 'Ошибка при получении заявителя')),
                  mergeMap((result: IDeclarer) => {
                    if (result) {
                      this.model.declarerId = result;
                    }
                    return this.emergencyService.executeEventAction({
                      action: $event.options.name,
                      model: this.getEventModel(),
                    });
                  }),
                );
              }),
              switchMap((model: IEmergencyEventDto) => {
                this.setEventIdToAnswers(this.surveyAnswers, model.id);
                this.setEventIdToAnswers(this.surveyReAnswers, model.id);
                return forkJoin([
                  of(model),
                  forkJoin([
                    this.surveyQuestionsService.insertAnswers(this.surveyAnswers),
                    this.surveyQuestionsService.updateAnswers(this.surveyReAnswers),
                  ]).pipe(
                    catchError((error: Error) =>
                      this.catchErrorFn<IAbstractServiceData>(error, 'Ошибка при сохранении ответов'),
                    ),
                  ),
                ]);
              }),
              catchError((error: Error) => {
                /** Ошибка сохранения, удаляем последний комментарий */
                if (this.showPortalComment) {
                  if (this.model.appealDetail && typeof this.model.appealDetail === 'object') {
                    this.model.appealDetail.comments.pop();
                  }
                }
                if (this.transferToServiceButton) this.transferToServiceButton.options.disabled = false;
                return this.catchErrorFn<IEmergencyEventDto>(error, 'Ошибка при выполнении действия');
              }),
              untilDestroyed(this),
            )
            .subscribe((res) => {
              this.closeForm();
            });
        }
        return;
      });
      return;
    }

    if (this.eventForm.valid) {
      this.eventName = $event.options.name;
      this.eventModel = $event;
      this.getModelData();
      let obs = of({});

      if (this.showPortalComment) {
        const dialogRef = this.dialog.open(InternetPortalCommentDialogComponent, {
          closeOnNavigation: true,
          panelClass: 'close-without-reaction-dialog-container',
        });

        obs = dialogRef.afterClosed().pipe(
          switchMap((comment: string) => {
            if (this.model.appealDetail && typeof this.model.appealDetail === 'object') {
              if (!(this.model.appealDetail as IAppealDetailDto).comments) {
                (this.model.appealDetail as IAppealDetailDto).comments = [];
              }
              // Статус "В работе" выбирается искусственно, по причине того что шаг ЖЦ, из которого он вытягивается,
              // будет определяться на бэке. Так же не указан ID шага и его наименование. В штатном варианте,
              // когда комментарий для портала заполняется в процессе редактирования происшествия
              // (а не события как сейчас) эта информация есть, на данном этапе этой информации нет.
              // Если же шаг ЖЦ пришел от инцидента в результате привязки, то взять его.
              const inWorkStatus = this.settings.getDictionaryByTypeAndSysName('statusLifeCycleStep', 'inWork');
              (this.model.appealDetail as IAppealDetailDto).comments.push({
                lifeCycleStepId: (<IDictionaryModelDto>(<ILifeCycleStepDto>this.model.lifeCycleStepId)?.status)?.id
                  ? <ILifeCycleStepDto>this.model.lifeCycleStepId
                  : <ILifeCycleStepDto>{ status: { id: inWorkStatus.id, name: inWorkStatus.text, sysname: 'inWork' } },
                createTime: Date.now(),
                text: comment,
                userId: this.settings.currentUser.id,
              });
            }
            return of({});
          }),
        );
      }

      obs
        .pipe(
          switchMap(() => this.firstStepValidate()),
          switchMap((firstStepCheck: boolean) => {
            if (!firstStepCheck) {
              return of(false);
            }

            /** Преобразовать переданный документ перед отправкой, если он был прикреплен */
            if (this.model.documents?.length > 0) {
              return this.multiFileService.saveFilesToSfs(this.model.documents).pipe(
                switchMap((documents: IFileInfoDto[]) => {
                  this.model.documents = documents;
                  return of(documents);
                }),
                switchMap(() => this.secondStepValidate()),
              );
            }
            return this.secondStepValidate();
          }),
          switchMap((secondStepCheck: boolean) => {
            if (secondStepCheck) {
              this.ngMasterValue.next(this.eventForm.getRawValue());

              return this.declare.saveDeclarerInfo().pipe(
                catchError((err: Error) => this.catchErrorFn(err, 'Ошибка при получении заявителя')),
                untilDestroyed(this),
                mergeMap((result) => {
                  if (result) {
                    this.model.declarerId = result;
                  }

                  return this.emergencyService
                    .executeEventAction({
                      action: $event.options.name,
                      model: this.getEventModel(),
                    })
                    .pipe(
                      switchMap((event: IEmergencyEventDto) => {
                        const orgId: string = event.organizationId || this.settings.currentUser.organizationId?.id;
                        return this.organizationsService.getOrganizationTypeParam(orgId).pipe(
                          map((orgTypeParam: IOrganizationType) => {
                            return { event, orgTypeParamId: orgTypeParam.id || undefined };
                          }),
                        );
                      }),

                      switchMap(({ event, orgTypeParamId }: { event: IEmergencyEventDto; orgTypeParamId: string }) => {
                        this.orgTypeParamForSteps = orgTypeParamId;
                        this.setEventIdToAnswers(this.surveyAnswers, event.id);
                        this.setEventIdToAnswers(this.surveyReAnswers, event.id);
                        return forkJoin([
                          of(event),
                          forkJoin([
                            this.surveyQuestionsService.insertAnswers(this.surveyAnswers),
                            this.surveyQuestionsService.updateAnswers(this.surveyReAnswers),
                          ]).pipe(
                            catchError((error: Error) =>
                              this.catchErrorFn<IAbstractServiceData>(error, 'Ошибка при сохранении ответов'),
                            ),
                          ),
                        ]).pipe(
                          map((response) => {
                            return response[0];
                          }),
                        );
                      }),
                      switchMap((response: IEmergencyEventDto) => {
                        if (response) {
                          return of(response);
                        }
                        return of(undefined);
                      }),
                      map(() => true),
                      catchError((err: Error) => this.catchErrorFn<boolean>(err, 'Ошибка обновления события')),
                    );
                }),
              );
            }
            if (this.transferToServiceButton) this.transferToServiceButton.options.disabled = false;
            return of(false);
          }),
        )
        .subscribe((result: boolean) => {
          if (result) {
            this.emergencyResponse = null;
            const coordinates = new Coordinates(this.model.address.latitude, this.model.address.longitude);
            if (coordinates.isValid()) {
              this.bgMapService.setPositionMapOnCoordinates(coordinates.toString());
            }
            this.closeForm();
          }
        });
    } else {
      /** Рекурсивно перебираем все контролы, на предмет контролов с ошибкой, для вывода информационного сообщения*/
      const labels: string[] = [];
      const recursiveFunc = (form: FormGroup | FormArray) => {
        Object.keys(form.controls).forEach((field: string) => {
          const control = form.get(field);
          if (control.invalid) {
            if (control instanceof FormGroup || control instanceof FormArray) {
              recursiveFunc(control);
            } else {
              const title = this.validationControlMap.get(field);
              labels.push(title ?? field);
            }
          }
        });
      };
      recursiveFunc(this.eventForm);
      if (labels.length) {
        this.noteService.pushError(`Следующие поля содержат ошибки:\n ${labels.join(',\n')}`);
      } else {
        this.noteService.pushError('Не заполнены обязательные поля');
      }
    }
  }

  /** Получение модели события IEmergencyEventDto */
  // т.к. model имеет тип EmergencyCallEventDto, отличный от полей таблицы Emergency_Events,
  // для предотвращения ошибок на бэке, удаляем отсутствующие в модели события поля
  public getEventModel(): IEmergencyEventDto {
    const eventModel = { ...this.model };
    delete eventModel.incidentNumber;
    delete eventModel.lifeCycleStepId;

    return eventModel;
  }

  /** Закрытие формы */
  public closeForm() {
    this.router.navigate([{ outlets: { editForm: null, editEventForm: null } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Обработчик клика по кнопке Уточнить адрес
   * @param $event
   */
  public onClickSpecifyAddress($event) {
    const factAddress = Object.assign(
      {},
      this.model?.address || this.eventForm.value.factAddress || this.addressByCoordinates,
    );
    const exactCoordinates = new Coordinates(
      this.eventForm.controls['exactCoordinates'].value || [factAddress?.latitude, factAddress?.longitude],
    );

    if (!exactCoordinates.isValid() && factAddress.fullText) {
      this.gisService
        .getCoordinatesByAddress(factAddress.fullText)
        .pipe(
          untilDestroyed(this),
          catchError((err: Error) =>
            this.catchErrorFn<IGisServiceResult>(err, 'Ошибка при обработке запроса. Получения координат.'),
          ),
        )
        .subscribe((result: IGisServiceResult) => {
          const data = {
            coordinates: new Coordinates(),
          };
          if (result?.lat && result?.lon) {
            data.coordinates = new Coordinates(result.lat, result.lon);
          }
          this.openSpecifyMap(data);
        });
    } else {
      const data = {
        coordinates: exactCoordinates,
        showAddressCopyToKsip: this.byCoordinates,
      };
      this.openSpecifyMap(data);
    }
  }

  /**
   * Обработчик по клику скопировать адресс
   * @return
   */
  public copyHandler(): void {
    const declarantAddress = this.declarerStore.getFormData();
    if (declarantAddress) {
      const formDetails = this.declarerStore.getBuildingInfo();
      if (declarantAddress?.building) {
        const declarantDetails = {
          room: declarantAddress?.building.room ? declarantAddress?.building.room : formDetails.room,
          entrance: declarantAddress?.building.entrance ? declarantAddress?.building.entrance : formDetails.entrance,
          corp: declarantAddress?.building.corp ? declarantAddress?.building.corp : formDetails.corp,
        };
        this.eventForm.controls.details?.patchValue(declarantDetails);
      }
      if (declarantAddress?.address) {
        this.eventForm.controls.factAddress.patchValue(declarantAddress?.address);
      }
      this.noteService.pushInfo('Адрес заявителя скопирован в адрес КСиП');
    }
  }

  /** Метод который обеспечивает открытие диалога и подписку на закрытие
   * @param data - параметры для диалога
   */
  public openSpecifyMap(data: { coordinates?: Coordinates; showAddressCopyToKsip?: boolean }): void {
    const dialogRef = this.dialog.open(MapDialogComponent, {
      data,
      width: '600px',
    });

    let dialogSub = dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        if (params?.coordinates) {
          this.eventForm.controls['exactCoordinates'].setValue(`${params.coordinates[0]}, ${params.coordinates[1]}`);
          if (params.copyAddressToKsip) {
            this.addressByCoordinates = {
              fullText: `${params.coordinates[0]}, ${params.coordinates[1]}`,
              latitude: params.coordinates[0],
              longitude: params.coordinates[1],
            };
            this.eventForm.controls['coordinatesAddress'].setValue(this.addressByCoordinates.fullText);
            this.eventForm.controls['factAddress'].setValue(this.addressByCoordinates);
          }
        }
        if (dialogSub) {
          dialogSub.unsubscribe();
          dialogSub = null;
        }
      });
  }

  /** Проверка типа КСиП для отображения блока "Параметры" */
  public getForecastingParamsInfo(id: string): void {
    if (id) {
      const ksip = this.ksipTypesQuery.getById(id);

      const sysname = this.settings.getDictionaryById(ksip.projectedRisk)?.sysname || '';

      const params = (this.model.forecastingDetailId as IForecastingDetailDto)?.params
        ? (this.model.forecastingDetailId as IForecastingDetailDto)?.params
        : undefined;

      switch (sysname) {
        case 'forestFire': {
          this.showForecastingParams = true;
          this.eventForm.setControl(
            'forecasting',
            ForecastingForestFireParamsFormComponent.generateFormGroup(
              this.fb,
              params as IForecastingForestFireParamsDto,
            ),
          );
          break;
        }
        case 'chemicalDischarge':
        case 'radiationSituation': {
          this.showForecastingParams = true;
          if (this.projectedRisk === 'chemicalDischarge' || this.projectedRisk === 'radiationSituation') {
            noop();
          } else {
            this.eventForm.setControl(
              'forecasting',
              ForecastingRadiationChemicallyParamsFormComponent.generateFormGroup(this.fb, params),
            );
          }

          break;
        }
        default:
          this.showForecastingParams = false;
          this.eventForm.setControl('forecasting', new FormControl(this.model.forecastingDetailId));
      }

      this.projectedRisk = sysname;
    } else {
      this.showForecastingParams = false;
    }
  }

  /** Конфигурация блока "Параметры" */
  public setForecastingParamsOptions(): void {
    if (this.model.ksipTypeId) {
      this.getForecastingParamsInfo(this.model.ksipTypeId);
    }
  }

  /** Обработчик события ответа на вопрос из дочернего компонента опроса */
  public onSurveyAnswer(answer: ISurveyAnswerDto): void {
    this.surveyAnswers.push(answer);
  }

  /** Обработчик события переответа на вопрос из дочернего компонента опроса */
  public onSurveyReAnswer(answer: ISurveyAnswerDto): void {
    for (let i = 0; i < this.surveyAnswers.length; i++) {
      if (answer.id === this.surveyAnswers[i].id) {
        this.surveyAnswers[i] = answer;
        return;
      }
    }
    for (let i = 0; i < this.surveyReAnswers.length; i++) {
      if (answer.id === this.surveyReAnswers[i].id) {
        this.surveyReAnswers[i] = answer;
        return;
      }
    }
    this.surveyReAnswers.push(answer);
  }

  /** Установка ID события для ответов на опрос */
  public setEventIdToAnswers(answers: ISurveyAnswerDto[], id): void {
    for (let i = 0; i < answers.length; i++) {
      answers[i].eventId = id;
    }
  }

  /** Отвязать инцидент от события */
  public unbindIncident(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      minWidth: 450,
    });
    dialogRef.componentInstance.isHtml = false;
    dialogRef.componentInstance.message = `Отвязать событие от происшествия ${this.model.incidentNumber}?`;
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result: ConfirmDialogResultEnum) => {
        switch (result) {
          case ConfirmDialogResultEnum.closeWithoutReaction:
            return;
          case ConfirmDialogResultEnum.reject:
            return;
          case ConfirmDialogResultEnum.confirm:
            this.model.incidentId = null;
            this.model.incidentNumber = null;
            return;
        }
      });
    return;
  }

  /** Открываем карточку инцидента по клику на привязанный инцидент */
  public openEmergency() {
    const targetUrl = this.getWorkspaceURL();
    window.open(targetUrl);
  }

  protected getWorkspaceURL(): string {
    const id = typeof this.model.incidentId === 'object' ? this.model.incidentId.id : this.model.incidentId;
    let targetUrl = `http://${window.location.host}`;
    targetUrl += `/consolidated-registries/incident-register/(editForm:incident/${id})`;
    return targetUrl;
  }

  /**
   * Создает шаги реагирования
   * @param model - модель инцидента
   * @param isBinding
   * @return
   */
  public createSteps(model: IEmergencyEventDto, isBinding: boolean = false): Observable<IAbstractServiceData> {
    const incidentDocType = this.settings
      .getDictionaryByTypeSysName('docType')
      .find((item: IDictionaryInfo) => item.sysname === 'incident')?.id;
    let involedTypeServices = this.initAttractOrganizations() ? this.model.attractionServices : [];

    if (this.existedOrgList.length) {
      involedTypeServices = involedTypeServices.filter((service) => {
        return !this.existedOrgList.includes(service);
      });
    }
    const attractionOrganizations = this.initAttractOrganizations()
      ? this.model.attractionOrganizations
          .map((org) => {
            return Object.values(org)[0];
          })
          .reduce((acc, val) => acc.concat(val), [])
      : [];

    const params: ICreateEmergencyStepsParams = {
      attractionOrganizations,
      isBinding,
      involedTypeServices,
      mo: this.organizationChooseMo || this.bgAdminService.getUserMo(),
      orgTypeParamId: this.orgTypeParamForSteps || this.settings.currentUser?.organizationId?.orgTypeParam,
      emergencyType: incidentDocType,
      incidentType: model.ksipTypeId,
      emergencyId: (model.incidentId as IEmergencyDto)?.id || (model.incidentId as string),
    };
    return this.responsePlanStepService.createResponseScriptSteps(params);
  }

  /**
   * Настройка параметров отображения информации о важных объектах рядом с инцидентом
   * @param coordinates - Объект координат инцидента
   */
  private setCloseImportantObjectsSettings(coordinates: Coordinates) {
    if (!coordinates?.isValid()) {
      this.countOfCloseImportantObjects = 0;
      this.closeImportantObjectsMessage = 'Данный адрес не имеет координат';
      return;
    }

    forkJoin([
      this.significantObjectService.getCloseObject(coordinates, 500),
      this.significantObjectService.getCloseObject(coordinates, 50),
    ]).subscribe((result: IAbstractServiceData[]) => {
      this.countOfCloseImportantObjects = +result[0].data.totalCount;
      if (!!this.countOfCloseImportantObjects) {
        this.closeImportantObjectsMessage = `${GrammarHelper.endings(this.countOfCloseImportantObjects, [
          'Найден',
          'Найдено',
          'Найдено',
        ])} ${this.countOfCloseImportantObjects} ${GrammarHelper.endings(this.countOfCloseImportantObjects, [
          'важный',
          'важных',
          'важных',
        ])} ${GrammarHelper.endings(this.countOfCloseImportantObjects, ['объект', 'объекта', 'объектов'])}`;
      } else {
        this.closeImportantObjectsMessage = 'Важные объекты не найдены';
      }
      this.hasDangerObject = !!+result[1].data.totalCount;
    });
  }

  /**
   * Метод получения мо текущей организации
   * @return
   */
  private getOrganizationMo(): string {
    if (this.eventForm.controls.organizationId.value && this.organizationChooseMo) {
      return this.organizationChooseMo;
    }
    return this.getUserMo();
  }

  /**
   * Получение МО пользователя
   * @return
   */
  private getUserMo(): string {
    return typeof this.settings.currentUser.organizationId.mo === 'string'
      ? this.settings.currentUser.organizationId.mo
      : this.settings.currentUser?.organizationId?.mo?.id;
  }

  /**
   * Сброс объекта, который хранит данные о соответствие организация и типов организаций
   * @return
   */
  private resetOrgList(): void {
    this.attractActiveOrgList.forEach((org: IOrganizationType) => {
      this.orgTypeParamOrganizList[org.id] = [];
    });
  }

  /**
   * Обработчик изменения привлекаемой организации
   */
  public attractOrganizationChanged($event: IAnyObject, i: number): void {
    this.detailOrganizationList[i].foundOrganizations = $event.data ? [$event.data] : [];
  }

  /**
   * Определение нужно ли инициализировать Детализацию по организациям
   * @return
   */
  private initAttractOrganizations(): boolean {
    return this.canAttractionServicesShow?.visible && this.canAttractionServicesShow?.enabled;
  }

  /** Инициализация клейма для привлекаемых служб в событии
   * @return
   */
  public initAttractionServicesShow(): void {
    this.canAttractionServicesShow = this.accessService.accessMap[this.canAttractionServicesShow.name];
    this.attractOrganizationVisible = this.initAttractOrganizations();
  }

  /** Устанавливает  валидатор для resourceConstraintType
   * @return
   */
  private setResourceConstraintTypeValidators(): void {
    const validator =
      this.eventForm.controls['isSendingToJkhReform'].value && this.isSendingReformToJkhVisible && this.isHousingService
        ? [Validators.required]
        : [];
    this.eventForm.controls.resourceConstraintType.setValidators(validator);
    this.eventForm.controls.resourceConstraintType.setErrors(null);
  }

  /** Устанавливает  валидатор для контролов Плановые работы
   * @return
   */
  private setWorkTermValidators(): void {
    const minDate = dayjs().startOf('day').valueOf();
    const workTermToInitValidators = [
      Validators.required,
      BgDatetimeValidator.minDate(minDate),
      BgDatetimeValidator.minDateOf(this.eventForm.controls.workTermFrom.value),
    ];
    const validators = this.isWorkTerm ? [Validators.required, BgDatetimeValidator.minDate(minDate)] : [];
    this.eventForm.controls.workTermFrom.setValidators(validators);
    this.eventForm.controls.workTermFrom.setErrors(null);
    this.eventForm.controls.workTermTo.setValidators(workTermToInitValidators);
    this.eventForm.controls.workTermFrom.valueChanges.pipe(untilDestroyed(this)).subscribe((value: number) => {
      if (value) {
        this.eventForm.controls.workTermTo.setValidators([
          Validators.required,
          BgDatetimeValidator.minDate(minDate),
          BgDatetimeValidator.minDateOf(value),
        ]);
      } else {
        this.eventForm.controls.workTermTo.setValidators(validators);
      }
      this.eventForm.controls.workTermTo.reset(this.eventForm.controls.workTermTo.value, {
        emitEvent: true,
        onlySelf: false,
      });
    });
    this.eventForm.controls.workTermTo.setErrors(null);
  }

  /**
   * Устанавливает поле  "Просмотренно"
   * @return
   */
  private setViewedField(): void {
    if (!this.model.viewed && this.settings.currentUser.organizationId?.id === this.model.organizationId) {
      this.emergencyService.updateViewedField(this.model.id, 'Events').pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    }
  }

  /**
   * Заполнение attractionOrganizations еcли поле пустое
   * @return
   */
  private fillAttractionOrg(): void {
    if (!this.model.attractionOrganizations) {
      this.model.attractionOrganizations = (this.model.attractionServices || []).map((orgTypeParam: string) => {
        return {
          [orgTypeParam]: [],
        };
      });
    }
  }

  /**
   * Обработка инициализации компонента МО
   * @param component - инстанс компонента
   */
  public onMoInit(component: ScSelectComponent) {
    this.moIdComponent = component;
    this.moIdComponent.typeahead.next(undefined);
  }

  /**
   * Обработка инициализации компонента Район
   * @param component - инстанс компонента
   */
  public onDistrictInit(component: ScSelectComponent) {
    this.districtIdComponent = component;
    this.districtIdComponent.typeahead.next(undefined);
  }

  /**
   * Инициализация компонентов МО и Район
   */
  private moAndDistrictInit() {
    // Предварительная настройка селекта Район в зависимости от значения МО
    if (this.model.moId) {
      this.districtIdOptions.query = { active: true, municipalId: this.model.moId };
      this.eventForm.controls.districtId.enable();
    }

    // Видимость селекта Район
    this.districtIdVisible = this.accessService.accessMap['EventEmergencyDistrictVisible']?.visible;

    // Подписка на изменение МО для актуализации значений в поле Район
    this.eventForm.controls.moId.valueChanges.pipe(untilDestroyed(this)).subscribe((moId: string) => {
      if (this.districtIdOptions.query.municipalId === moId) return;
      this.districtIdComponent.value = null;
      if (moId) {
        this.districtIdOptions.query = { active: true, municipalId: moId };
        this.districtIdComponent.typeahead.next(undefined);
        this.eventForm.controls.districtId.enable();
      } else {
        this.districtIdOptions.query = { active: true };
        this.eventForm.controls.districtId.disable();
      }
    });
  }

  /** Обработчик действия закрытия формы */
  public closeActionHandler(): void {
    const dirtySub = this.isDirty$.pipe(untilDestroyed(this)).subscribe((isModify: boolean) => {
      if (isModify || this.surveyAnswers?.length || this.surveyReAnswers?.length) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false,
          minWidth: 450,
        });

        dialogRef.componentInstance.message = 'Сохранить изменения?';

        dialogRef
          .afterClosed()
          .pipe(untilDestroyed(this))
          .subscribe((result: ConfirmDialogResultEnum) => {
            switch (result) {
              case ConfirmDialogResultEnum.closeWithoutReaction:
                dirtySub.unsubscribe();
                return;
              case ConfirmDialogResultEnum.reject:
                this.surveyAnswers = [];
                this.surveyReAnswers = [];
                this.ngMasterValue.next(this.eventForm.getRawValue());
                this.closeForm();
                return;
              case ConfirmDialogResultEnum.confirm:
                this.getModelData();
                this.multiFileService
                  .saveFilesToSfs(this.model.documents)
                  .pipe(
                    switchMap((documents) => {
                      this.model.documents = documents;
                      if (this.declarerId) {
                        return this.emergencyService.saveEvent(this.getEventModel());
                      } else {
                        return this.declare.saveDeclarerInfo().pipe(
                          catchError((err: Error) => this.catchErrorFn(err, 'Ошибка при получении заявителя')),
                          mergeMap((result: IDeclarer) => {
                            if (result) {
                              this.model.declarerId = result;
                            }
                            return this.emergencyService.saveEvent(this.getEventModel());
                          }),
                        );
                      }
                    }),
                    switchMap((model: IEmergencyEventDto) => {
                      this.setEventIdToAnswers(this.surveyAnswers, model.id);
                      this.setEventIdToAnswers(this.surveyReAnswers, model.id);
                      return forkJoin([
                        of(model),
                        forkJoin([
                          this.surveyQuestionsService.insertAnswers(this.surveyAnswers),
                          this.surveyQuestionsService.updateAnswers(this.surveyReAnswers),
                        ]).pipe(
                          catchError((error: Error) =>
                            this.catchErrorFn<IAbstractServiceData>(error, 'Ошибка при сохранении ответов'),
                          ),
                        ),
                      ]);
                    }),
                    map((data) => {
                      return data[0];
                    }),
                    tap((data) => {
                      if (data) {
                        this.surveyAnswers = [];
                        this.surveyReAnswers = [];
                      }
                      this.ngMasterValue.next(this.eventForm.getRawValue());
                      this.closeForm();
                    }),
                    catchError((error: Error) => this.catchErrorFn<IEmergencyEventDto>(error, 'Ошибка при сохранении')),
                    untilDestroyed(this),
                  )
                  .subscribe();
                return;
            }
          });
        return;
      }
      this.closeForm();
    });
  }

  /** Метод для удаления поиска по радиусу из запроса, при изменении адреса */
  public deletePointQuery(arr: IAnyObject[]): void {
    arr.forEach((filterObj: IAnyObject) => {
      if (filterObj.hasOwnProperty('point')) {
        delete filterObj.point;
      }
      if (filterObj.hasOwnProperty('$and')) {
        this.deletePointQuery(filterObj.$and);
      }
      if (filterObj.hasOwnProperty('$or')) {
        this.deletePointQuery(filterObj.$or);
      }
    });
  }

  /**
   * Предустановка адреса КСиП из объекта ЖКХ
   */
  public setAddressByJkhObject(objectId: string): void {
    const factAddressControl = this.eventForm.controls.factAddress;
    const coordinatesAddressControl = this.eventForm.controls.coordinatesAddress;

    if (!coordinatesAddressControl.value && !factAddressControl.value) {
      this.atmIntegrationService
        .getById(objectId)
        .pipe(untilDestroyed(this))
        .subscribe((object: IMonitoringObjectHcsDto) => {
          if (this.byCoordinates) {
            coordinatesAddressControl.patchValue(object.coordinates);
          } else {
            const coordinates = Coordinates.coordinatesToArray(object?.coordinates);
            if (object.address || object?.coordinates) {
              factAddressControl.patchValue({
                fullText: object.address || object?.coordinates,
                latitude: coordinates[0],
                longitude: coordinates[1],
              });
            }
          }
        });
    }
  }

  /**
   * Предустановка селекта объект ЖКХ при изменении подписки на адрес КСиП
   */
  public setJkhObjectByAddress(address: GarFindFlatResponseElement): void {
    const jkhObjectControl = this.eventForm.controls.jkhObject;
    // Если отображаются контролы жкх и объект не задан, ищем подходящий объект по координатам
    if (!jkhObjectControl?.value && address?.latitude && address?.longitude) {
      this.atmIntegrationService
        .getByQuery({
          point: {
            $radius: {
              $lat: address.latitude,
              $lon: address.longitude,
              $meters: 50,
            },
          },
          active: true,
        })
        .pipe(untilDestroyed(this))
        .subscribe((objects: IMonitoringObjectHcsDto[]) => {
          if (objects.length) {
            this.jkhObjectOptions.query = {
              $and: [{ active: true }, { id: { $in: objects.map((obj: IMonitoringObjectHcsDto) => obj.id) } }],
            };
            if (objects.length === 1) jkhObjectControl.patchValue(objects[0]?.id);
          }
        });
    }
  }

  /**
   * Проверка возможности отправки информации о событии/происшествии в Реформу ЖКХ
   */
  private checkHcsReformSendAbility() {
    if (this.isSendingReformToJkhVisible) {
      const address = this.eventForm.controls['byCoordinates'].value
        ? this.addressByCoordinates
        : this.eventForm.controls['factAddress'].value;

      this.hcsReformSendingStatus = 'Выполняется проверка возможности отправки информации в Реформу ЖКХ';
      this.emergencyService
        .checkHcsReformSendAbility(
          this.eventForm.controls.ksipTypeId.value,
          this.eventForm.controls.jkhObject.value,
          this.eventForm.controls.ksipDetailsId.value,
          address,
        )
        .pipe(
          catchError(() => of({ data: 'ok' })),
          untilDestroyed(this),
        )
        .subscribe((res) => {
          if (res.data !== 'object' && res.data !== 'double') {
            // Если проверка прошла, но не заполнен объект ЖКХ, надо вывести сообщение о необходимости заполнения.
            if (!this.eventForm.controls.jkhObject.value) {
              this.hcsReformSendingStatus = 'Чтобы отправить в Реформу ЖКХ заполните поле Объект ЖКХ';
              this.hcsReformSendingStatusIconColor = undefined;
              return;
            }
            this.eventForm.controls.isSendingToJkhReform.enable();
            this.hcsReformSendingStatus = undefined;
            this.hcsReformSendingStatusIconColor = undefined;
            return;
          }

          if (this.eventForm.controls.isSendingToJkhReform.value) {
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              width: '600px',
            });
            dialogRef.componentInstance.isHtml = true;
            dialogRef.componentInstance.message = `
              С текущими параметрами отправка происшествия в Реформу ЖКХ невозможна.<br>
              Подробнее см. раздел "Дополнительная информация"
            `;
          }

          if (res.data === 'object') this.hcsReformSendingStatus = 'Дубль объекта';
          if (res.data === 'double') this.hcsReformSendingStatus = 'Дубль происшествия';

          this.eventForm.controls.isSendingToJkhReform.setValue(false);
          this.eventForm.controls.isSendingToJkhReform.disable();
          this.hcsReformSendingStatusIconColor = '#FC5A5A';
        });
    }
  }

  private setAddressObservableForLink() {
    if (this.linkToJkhObjectVisible) {
      this.isVisibleAtmLink$ = this.atmIntegrationService
        .isExistBoundAddresses(this.eventForm.controls.jkhObject.value)
        .pipe(
          tap((isExistBoundAddresses: boolean) => {
            if (isExistBoundAddresses) localStorage.setItem('isAddressBlockShow', 'true');
          }),
        );
    }
  }
}
