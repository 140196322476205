import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseForecastingFormComponent } from '@bg-front/core/components';
import { ForecastingResultsService } from '@bg-front/core/services';
import {
  IAccidentOnChemicalObjectTaskParamDto,
  IDestroyChemicalObjectTaskParamDto,
  ITimeApproachCloudToPointTaskParamDto,
} from '@bg-front/forecasting-blowout/models';
import { ForecastingBlowoutTasksFormFactory } from '@bg-front/forecasting-blowout/models/classes';
import {
  ForecastingFiresTasksFormFactory,
  IForecastingConsequencesOfForestFiresTaskParamsDto,
} from '@bg-front/forecasting-fires/models';
import {
  ForecastingRadioactiveEnvironmentTasksFormFactory,
  IExposurePopulationOvercomingCloudTrailTaskDto,
  INuclearPollutionPointTaskDto,
  INuclearPollutionTaskDto,
  IPopulationIrradiationPointTaskDto,
  IRadioactiveCloudTimeTaskDto,
  IThyroidIrradiationTaskDto,
  ITimeAllowedToOvercomeCloudTrailTaskDto,
} from '@bg-front/forecasting-radioactive-environment/models';
import { ForestryFacilitiesService } from '@bg-front/forestry-facilities/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { EmergencyService } from '../../../app-common/services';
import { ForecastingService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'bg-forecasting-form',
  templateUrl: './forecasting-form.component.html',
  styleUrls: ['./forecasting-form.component.scss'],
})
export class ForecastingFormComponent extends BaseForecastingFormComponent implements OnInit, OnDestroy {
  constructor(
    settings: Settings2Service,
    forecastingService: ForecastingService,
    emergencyService: EmergencyService,
    router: Router,
    fb: FormBuilder,
    forecastingResultsService: ForecastingResultsService,
    significantObjectsService: SignificantObjectsService,
    forestryFacilitiesService: ForestryFacilitiesService,
  ) {
    super(
      settings,
      forecastingService,
      emergencyService,
      router,
      fb,
      forecastingResultsService,
      significantObjectsService,
      forestryFacilitiesService,
    );
  }

  public initControls(value: string) {
    if (value) {
      const projectedRiskSysName = this.settings
        .getDictionaryByTypeSysName('projectedRisks')
        .find((el: IDictionaryInfo) => el.id === value).sysname;
      switch (projectedRiskSysName) {
        case 'forestFire': {
          this.countTasks = this.settings.getDictForSelect('forestFireTasks');
          this.setEmergencyQueryForForestFire();
          break;
        }
        case 'technologicalFire': {
          this.countTasks = this.settings.getDictForSelect('technologicalFireTasks');
          this.setEmergencyQueryForTechnologicalFire();
          break;
        }
        default: {
          super.initControls(value);
        }
      }
    } else {
      this.countTasks = [];
    }
  }

  /** Генерируем FormGroup для элемента */
  public initChildForm(countTaskSysname: string): AbstractControl {
    switch (countTaskSysname) {
      case 'fireCoveredDistance': {
        return ForecastingFiresTasksFormFactory.distanceTraveledByFireFrontTaskFormGroup(
          this.fb,
          this.forecastingParams.params,
        );
      }
      case 'technologicalFireArea': {
        return ForecastingFiresTasksFormFactory.technologicalFireAreasTaskFormGroup(
          this.fb,
          this.forecastingParams?.params,
        );
      }
      case 'extinguishingAgentConsumption': {
        return ForecastingFiresTasksFormFactory.extinguishingAgentConsumptionTaskFormGroup(
          this.fb,
          this.forecastingParams.params,
        );
      }
      case 'nuclearPollution': {
        return ForecastingRadioactiveEnvironmentTasksFormFactory.nuclearPollutionTaskFormGroup(
          this.fb,
          this.forecastingParams.params as INuclearPollutionTaskDto,
        );
      }
      case 'thyroidIrradiation': {
        return ForecastingRadioactiveEnvironmentTasksFormFactory.thyroidIrradiationTaskFormGroup(
          this.fb,
          this.forecastingParams.params as IThyroidIrradiationTaskDto,
        );
      }
      case 'radioactiveCloudTime': {
        return ForecastingRadioactiveEnvironmentTasksFormFactory.radioactiveCloudTimeTaskFormGroup(
          this.fb,
          this.forecastingParams.params as IRadioactiveCloudTimeTaskDto,
        );
      }
      case 'populationIrradiationPoint': {
        return ForecastingRadioactiveEnvironmentTasksFormFactory.populationIrradiationPointTaskFormGroup(
          this.fb,
          this.forecastingParams.params as IPopulationIrradiationPointTaskDto,
        );
      }
      case 'nuclearPollutionPoint': {
        return ForecastingRadioactiveEnvironmentTasksFormFactory.nuclearPollutionPointTaskFormGroup(
          this.fb,
          this.forecastingParams.params as INuclearPollutionPointTaskDto,
        );
      }
      case 'exposurePopulationOvercomingCloudTrail': {
        return ForecastingRadioactiveEnvironmentTasksFormFactory.exposurePopulationOvercomingCloudTrailTaskFormGroup(
          this.fb,
          this.forecastingParams.params as IExposurePopulationOvercomingCloudTrailTaskDto,
        );
      }
      case 'timeAllowedToOvercomeCloudTrail': {
        return ForecastingRadioactiveEnvironmentTasksFormFactory.timeAllowedToOvercomeCloudTrailTaskFormGroup(
          this.fb,
          this.forecastingParams.params as ITimeAllowedToOvercomeCloudTrailTaskDto,
        );
      }

      case 'forestFireConsequenceRating': {
        return ForecastingFiresTasksFormFactory.consequencesOfForestFiresTaskFormGroup(
          this.fb,
          this.forecastingParams?.params as IForecastingConsequencesOfForestFiresTaskParamsDto,
        );
      }
      case 'accidentChemicalObject': {
        return ForecastingBlowoutTasksFormFactory.accidentOnChemicalObjectTaskFormGroup(
          this.fb,
          this.forecastingParams?.params as IAccidentOnChemicalObjectTaskParamDto,
        );
      }
      case 'destroyChemicalObject': {
        return ForecastingBlowoutTasksFormFactory.destroyChemicalObjectTaskFormGroup(
          this.fb,
          this.forecastingParams?.params as IDestroyChemicalObjectTaskParamDto,
        );
      }
      case 'timeApproachCloudToPoint': {
        return ForecastingBlowoutTasksFormFactory.timeApproachCloudToPointTaskFormGroup(
          this.fb,
          this.forecastingParams?.params as ITimeApproachCloudToPointTaskParamDto,
        );
      }
    }

    return this.fb.control(this.forecastingParams.params);
  }

  /**
   * Метод устанавливает query для выборки инцидентов для техногенных пожаров
   * @return
   */
  private setEmergencyQueryForTechnologicalFire(): void {
    const incidentDocType = this.settings
      .getDictionaryByTypeSysName('docType')
      .find((item: IDictionaryInfo) => item.sysname === 'incident')?.id;
    const statusIds = this.settings
      .getDictionaryByTypeSysName('statusLifeCycleStep')
      .filter((item: IDictionaryInfo) => {
        return item.sysname === 'new' || item.sysname === 'inWork';
      })
      .map((item: IDictionaryInfo) => item.id);

    if (!this.forecastingParams.emergencyId) {
      this.form.get('emergencyId')?.setValue(this.forecastingParams?.emergencyId);
      const additionalFields = ['addressFact.fullText'];
      const query = {
        docType: incidentDocType,
        lifeCycleStepId: {
          status: {
            id: { $in: statusIds },
          },
        },
      };
      if (this.form?.controls?.projectedRiskId?.value) {
        query['incidentTypeId'] = {
          projectedRisk: this.form?.controls?.projectedRiskId?.value,
        };
      }

      this.incidents$ = this.emergencyService
        .getIncidentsListForForecasting(query, additionalFields)
        .pipe(untilDestroyed(this));
    }

    if (!this.forecastingParams.objectId) {
      const query = {
        $expr: {
          $in: ['$projectedRiskIds', this.form.controls.projectedRiskId.value],
        },
      };

      this.objects$ = this.significantObjectsService
        .getSignificantObjectsForForecasting(query)
        .pipe(untilDestroyed(this));
    }
  }

  /**
   * Метод устанавливает query для выборки инцидентов для лесных пожаров
   * @return
   */
  private setEmergencyQueryForForestFire(): void {
    const incidentDocType = this.settings
      .getDictionaryByTypeSysName('docType')
      .find((item: IDictionaryInfo) => item.sysname === 'incident')?.id;
    const statusIds = this.settings
      .getDictionaryByTypeSysName('statusLifeCycleStep')
      .filter((item: IDictionaryInfo) => {
        return item.sysname === 'new' || item.sysname === 'inWork';
      })
      .map((item: IDictionaryInfo) => item.id);

    if (!this.forecastingParams.emergencyId) {
      this.form.get('emergencyId')?.setValue(this.forecastingParams?.emergencyId ?? this.data.emergencyId);
      const additionalFields = ['addressFact.fullText'];
      const query = {
        docType: incidentDocType,
        lifeCycleStepId: {
          status: {
            id: { $in: statusIds },
          },
        },
      };
      if (this.form.controls.projectedRiskId.value) {
        query['incidentTypeId'] = {
          projectedRisk: this.form?.controls?.projectedRiskId?.value,
        };
      }

      this.incidents$ = this.emergencyService
        .getIncidentsListForForecasting(query, additionalFields)
        .pipe(untilDestroyed(this));
    }

    if (!this.forecastingParams.objectId) {
      const query = {
        $expr: {
          $in: ['$projectedRiskIds', this.form.controls.projectedRiskId.value],
        },
      };

      this.objects$ = this.forestryFacilitiesService
        .getForestryFacilitiesForForecasting(query)
        .pipe(untilDestroyed(this));
    }
  }
}
