<ng-container *ngIf="loading">
  <nz-skeleton-element nzType="image" [nzActive]="true" [class.full-size]="fullSize"></nz-skeleton-element>
</ng-container>

<ng-container *ngIf="!loading && image">
  <img nz-image alt="photo" [nzSrc]="image || ''"/>
</ng-container>

<ng-container *ngIf="!loading && error">
  <nz-skeleton-element
    class="error"
    nzType="image"
    [nz-tooltip]="noError ? '' : error"
    nzTooltipColor="#e9868e"
    [class.full-size]="fullSize"
    [class.no-error]="noError"
  >
  </nz-skeleton-element>
</ng-container>
