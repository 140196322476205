import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BaseComponent } from '@bg-front/core/components';
import { Timer } from '@bg-front/core/models/classes';
import { ONE_HOUR, ONE_MINUTE, ONE_SECOND, RESPONSE_STEP_STATUS } from '@bg-front/core/models/constants';
import { Settings2Service, SubscriberService } from '@smart-city/core/services';
import * as moment from 'moment';
import { of } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IEmergencyDto } from 'smart-city-types';
import { ILifeCycleStepDto } from 'smart-city-types/interfaces/life-cycle-step-dto.interface';

import { IDictionaryModel, IEmergencyResponsePlanStep } from '../../models/interfaces';
import { responsePlanIcons } from '../../models/maps';
import { ResponsePlanStepService } from '../../services';

@Component({
  selector: 'bg-response-plan-step',
  templateUrl: './response-plan-step.component.html',
  styleUrls: ['./response-plan-step.component.scss'],
})
export class ResponsePlanStepComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Опция шаг реагирования */
  @Input()
  step: IEmergencyResponsePlanStep;
  /** Краткое описание шага Тип организации привлеченной к реагированию или Краткое описание шага реагирования */
  public descriptionStep: string;
  /** Статус шага реагирования */
  public status = '';
  /** Иконка статуса */
  public svg: SafeHtml;
  /** Время реагирования */
  public timeReact: string;
  /** Id поручения */
  private orderId: string;
  /** Название организации */
  public organizationName: string;
  /** Сокращенное название организации */
  public organizationShortName: string;

  private timer: Timer;

  public tooLate: boolean = false;

  constructor(
    private readonly settings2: Settings2Service,
    private readonly sanitizer: DomSanitizer,
    private readonly responsePlanStepService: ResponsePlanStepService,
    private readonly subs: SubscriberService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    // Получение системного наименования статуса шага реагирования
    let statusSysName = this.settings2.getDictionaryById(this.step.status).sysname;

    const stepType = this.settings2.getDictionaryById(this.step.stepType)?.sysname;
    this.descriptionStep = this.step['serviceType.shortName'] ?? this.step?.shortDescription;

    // Если шаг является донесением.
    if (stepType === 'message') {
      // Подставить системное наименование шага для неотправленного донесения
      statusSysName = 'inProgress';

      this.responsePlanStepService
        .getInformationThemeById(this.step.themeId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(({ name }: { name: string }) => {
          this.descriptionStep = name;
        });
      // Если донесение отправлено, получить информацию о нем для актуализации статуса шага реагирования
      if (this.step?.reportId) {
        this.responsePlanStepService
          .getInformationStatementData(this.step.reportId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(({ lifeCycleStepId }) => {
            statusSysName =
              RESPONSE_STEP_STATUS.get((<IDictionaryModel>(<ILifeCycleStepDto>lifeCycleStepId).status).sysname) ||
              statusSysName;
            this.svg = this.sanitizer.bypassSecurityTrustHtml(responsePlanIcons.get(statusSysName));
          });
        this.subsOnInformStatusChange();
      }
    }
    this.svg = this.sanitizer.bypassSecurityTrustHtml(responsePlanIcons.get(statusSysName));

    // Вывести таймер в случае если шаг реагирования имеет тип 'Донесение' или 'Другой' и есть время начала шага
    if ((stepType === 'manual' || stepType === 'message') && this.step.startTimeStep) {
      // В случае если шаг находится в статусе Новый (inProgress) и не заполнено время завершения шага.
      // Статусом будет являться значение таймера
      if ((statusSysName === 'inProgress' || !statusSysName) && !this.step.finishTimeStep) {
        this.timer = new Timer(this.step.timeReact * ONE_MINUTE, this.step.startTimeStep);
        this.timer.start();
        this.timer.tick.pipe(takeUntil(this.ngUnsubscribe)).subscribe((time) => (this.status = time));
        this.timer.alarm.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => (this.tooLate = true));
      }
      // Если есть время завершения выполнения шага
      if (this.step.finishTimeStep) {
        let value = this.step.startTimeStep + this.step.timeReact * ONE_MINUTE - this.step.finishTimeStep;
        this.tooLate = value <= -ONE_SECOND;
        value = Math.abs(value);
        const sign = this.tooLate ? '-' : '';
        const timeFormat = value < ONE_HOUR ? 'mm:ss' : 'HH:mm:ss';
        this.status = `${sign}${moment(value).utc().format(timeFormat)}`;
      }
    }

    // Вывести статус оповещения привлекаемой службы в случе если шаг реагирования имеет тип
    // 'Привлечение службы к реагированию' и есть информация о привлекаемой организации
    // статус информирования привлекаемой организации
    if (stepType === 'auto' && this.step.attractOrgData) {
      this.descriptionStep = this.step['serviceType.shortName'];
      this.organizationName = this.step['attractOrgData.name'];
      this.organizationShortName =
        this.organizationName.length > 33
          ? this.organizationName.substring(0, 33).padEnd(36, '...')
          : this.organizationName;
      let obs;
      // Если нет статуса информирования привлекаемой организации, значит это организация с ИВ,
      // т.е. было создано поручение.
      if (!this.step.attractOrgStatus) {
        // Получение информации о поручении
        obs = this.responsePlanStepService.getOrderStatusByEmergencyOrg(this.step.orderId).pipe(
          map((emergencies: IEmergencyDto[]) => {
            this.orderId = emergencies[0].id;
            this.status = (<IDictionaryModel>(<ILifeCycleStepDto>emergencies[0].lifeCycleStepId).status).name;
            // Подписка на изменения поручения
            this.subsOnOrderStatusChange();
            return (<IDictionaryModel>(<ILifeCycleStepDto>emergencies[0].lifeCycleStepId).status).sysname;
          }),
        );
      } else {
        this.status = this.settings2.getDictionaryById(this.step.attractOrgStatus)?.name;
        obs = of(this.settings2.getDictionaryById(this.step.attractOrgStatus)?.sysname);
      }

      obs.pipe(takeUntil(this.ngUnsubscribe)).subscribe((statusSysName: string) => {
        // Если:
        // 1. В шаге указано время реакции
        // 2. Шаг находится в статусе Оповестить (inProgress для не поручения) или Новый (new для поручения)
        // То:
        // Запустить таймер, для подкрашивания статуса красным
        if (
          this.step.responseTime &&
          ((statusSysName === 'new' && !this.step.attractOrgStatus) ||
            (statusSysName === 'inProgress' && this.step.attractOrgStatus))
        ) {
          this.timer = new Timer(this.step.responseTime, this.step.startTimeStep);
          this.timer.start();
          this.timer.alarm.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.tooLate = true;
            this.timer.stop();
          });
        }
      });
    }
  }

  /**
   * Подписка на изменения записи в поручениях
   */
  private subsOnOrderStatusChange(): void {
    this.subs
      .onTableChange('Emergency', 'Emergency')
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((data: IAbstractServiceData) => data.data.id === this.orderId),
      )
      .subscribe((result: IAnyObject) => {
        if (result.action === 'update' || result.action === 'insert') {
          const currentLifeCycleStepStatus = <string>this.settings2.lifeCycleStep[result.data.lifeCycleStepId].status;
          this.status = this.settings2.getDictionaryById(currentLifeCycleStepStatus).name;
          // Если в результате изменения статус поручения не равен Новый, то остановить таймер
          if (this.settings2.getDictionaryById(currentLifeCycleStepStatus).sysname !== 'new') {
            this.timer?.stop();
            this.tooLate = false;
          }
        }
      });
  }

  /**
   * Подписка на изменения записи Донесения
   */
  private subsOnInformStatusChange(): void {
    this.subs
      .onTableChange('Edi', 'InformationStatements')
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((data: IAbstractServiceData) => data.data.id === this.step.reportId),
      )
      .subscribe((result: IAnyObject) => {
        if (result.action === 'update' || result.action === 'insert') {
          const statusSysName = this.settings2.getDictionaryById(
            <string>this.settings2.lifeCycleStep[result.data?.lifeCycleStepId].status,
          ).sysname;
          this.svg = this.sanitizer.bypassSecurityTrustHtml(
            responsePlanIcons.get(RESPONSE_STEP_STATUS.get(statusSysName)),
          );
        }
      });
  }

  /** @ignore */
  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.timer?.stop();
  }
}
