<div *ngIf="heatSupplyFacility">
  <nw-header [options]="headerOptions" (clickButton)="clickHeaderButton($event)"></nw-header>
  <div class="heat-supply-facility-container">
    <div class="bg-row bg-row-align-content-center">
      <div class="bg-col-min">
        <div class="icon">
          <span class="{{ icon }}"></span>
        </div>
      </div>
      <div class="bg-col">
        <div [matTooltip]="heatSupplyFacility.name">
          <div class="heat-supply-facility-name two-line-ellipsis-overflow">
            {{ heatSupplyFacility.name }}
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="heat-supply-facility-main-info-container">
      <div class="bg-row">
        <div class="bg-col">
          <div class="heat-supply-facility-info">
            <label>Адрес:</label>
            <div class="hide-overflow" [matTooltip]="heatSupplyFacility.address">
              {{ heatSupplyFacility.address }}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="bg-row">
        <div class="bg-col">
          <div class="heat-supply-facility-info">
            <label>Организация:</label>
            <div class="hide-overflow" [matTooltip]="heatSupplyFacility.organization">
              {{ heatSupplyFacility.organization }}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="bg-row">
        <div class="bg-col">
          <div class="heat-supply-facility-info">
            <label>Телефон:</label>
            <div
              *ngIf="heatSupplyFacility?.phone"
              class="heat-supply-facility-info__link"
              (click)="onClickPhone(heatSupplyFacility.phone)"
            >
              {{ formatPhoneFn(heatSupplyFacility.phone) }}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="bg-row">
        <div class="bg-col">
          <div class="heat-supply-facility-info">
            <label>Состояние:</label>
            {{ heatSupplyFacility.status }}
          </div>
        </div>
      </div>
      <hr />
      <div class="bg-row">
        <div class="bg-col align-center">
          <div class="heat-supply-facility-info">
            <button mat-raised-button color="primary" (click)="details()">Подробнее</button>
          </div>
        </div>
      </div>
      <hr />
      <!--Информацию об оповещениях выводить в случае если объект получен по интеграции-->
      <div *ngIf="!!heatSupplyFacility.integrationType" class="bg-row">
        <div class="bg-col">
          <div class="heat-supply-facility-info">
            <label>Оповещения:</label>
          </div>
        </div>
      </div>
    </div>
    <!--Информацию об оповещениях выводить в случае если объект получен по интеграции-->
    <div *ngIf="!!heatSupplyFacility.integrationType" class="heat-supply-facility-notifications">
      <scrollable-container #notifications [options]="notificationsOptions"></scrollable-container>
    </div>
  </div>
</div>
