import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AdminService } from '@smart-city/core/services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private admin: AdminService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.admin.getIsLoggedIn()) {
      return true;
    }

    /**
     * Пользователь не авторизован, запоминаем, какую страницу он запрашивает и редиректим его на страницу логина
     */
    this.admin.redirectUrl = state.url;
    this.router.navigateByUrl('/login');
    return false;
  }
}
