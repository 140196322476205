<div mat-dialog-content>
  <div class="bg-row">
    <div class="bg-col">
      <h3>Новый запрос</h3>
    </div>
    <div class="bg-col-min">
      <button mat-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <form [formGroup]="requestForm" appLCSFormState [params]="attributes" errorTailor id="idForTest">
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group bg-form-control-border">
          <bg-datetime
            label="Дата и время начала *"
            formControlName="from"
            [maxDate]="fromMaxDate"
            [defaultHour]="00"
            [defaultMinute]="00"
            [defaultSecond]="00"
            (input)="onFromChange()"
          ></bg-datetime>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <div class="bg-form-group">
            <div class="bg-row">
              <label style="font-size: 14px"> Продолжительность видео (мин) *</label>
            </div>
            <div class="bg-row">
              <div class="bg-col-2">
                <input
                  *ngIf="!!requestForm.controls.durationInput"
                  formControlName="durationInput"
                  mask="99"
                  (input)="onDurationInputChange()"
                />
              </div>
              <div class="bg-col-10">
                <mat-slider
                  *ngIf="!!requestForm.controls.durationSlider"
                  #durationSlider
                  min="1"
                  max="60"
                  step="1"
                  value="1"
                  formControlName="durationSlider"
                  (input)="onDurationSliderChange($event.value)"
                ></mat-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group bg-form-control-border">
          <bg-datetime
            label="Обработать запрос до"
            format="DD.MM.YYYY"
            [minDate]="minDateTimeResolveTo"
            formControlName="requestDeadline"
          ></bg-datetime>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group bg-form-control-border">
          <sc-textarea [options]="optionsDescription" formControlName="reason"></sc-textarea>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-checkbox [options]="optionsPermanent" formControlName="permanent"></sc-checkbox>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col-min">
        <div class="bg-form-group bg-form-control-border">
          <bg-datetime
            [options]="deleteTimeOptions"
            formControlName="deleteTime"
            [controlErrors]="deleteTimeErrors"
            [minDate]="deleteTimeMinDate"
          ></bg-datetime>
        </div>
      </div>
      <div class="bg-col-min">
        <div class="bg-form-group bg-form-control-border">
          <sc-input
            [options]="remindDateOptions"
            formControlName="remindDate"
            [controlErrors]="remindDateErrors"
          ></sc-input>
        </div>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-flat-button (click)="save('toDraft')">Сохранить</button>
  <button mat-raised-button color="primary" (click)="save('toGeneration')" cdkFocusInitial>Отправить</button>
</div>
