import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FLOAT_PATTERN } from '@bg-front/core/models/constants';
import { coordinatesValidator } from '@bg-front/core/validators';

import {
  IForecastingConsequencesOfForestFiresTaskParamsDto,
  IForecastingDistanceTraveledByFireFrontDto,
  IForecastingExtinguishingAgentConsumptionTaskDto,
  IForecastingTechnologicalFireAreaDto,
  IForecastingTechnologicalFireAreasTaskDto,
} from '../interfaces';

export class ForecastingFiresTasksFormFactory {
  /** Расстояние, пройденное фронтом пожара */
  public static distanceTraveledByFireFrontTaskFormGroup(
    fb: FormBuilder,
    params: IForecastingDistanceTraveledByFireFrontDto,
  ): FormGroup {
    return fb.group({
      objectFireId: [params?.objectFireId],
      linearVelocity: [params?.linearVelocity, [Validators.required, Validators.pattern(FLOAT_PATTERN)]],
      propagationTime: [params?.propagationTime, [Validators.required, Validators.pattern(FLOAT_PATTERN)]],
      firstSalvoTime: [params?.firstSalvoTime, [Validators.pattern(FLOAT_PATTERN)]],
      localizationTime: [params?.localizationTime, [Validators.pattern(FLOAT_PATTERN)]],
    });
  }

  /** Расход средств пожаротушения */
  public static extinguishingAgentConsumptionTaskFormGroup(
    fb: FormBuilder,
    params: IForecastingExtinguishingAgentConsumptionTaskDto,
  ): FormGroup {
    return fb.group({
      objectFireId: [params?.objectFireId],
      fireArea: [params?.fireArea, [Validators.required, Validators.pattern(FLOAT_PATTERN)]],
    });
  }

  /** Генерация FormGroup */
  public static technologicalFireAreasTaskFormGroup(
    fb: FormBuilder,
    params: IForecastingTechnologicalFireAreasTaskDto,
  ): FormGroup {
    return fb.group({
      objectFireId: [params?.objectFireId],
      fireCoveredDistance: [params?.fireCoveredDistance, [Validators.required, Validators.pattern(FLOAT_PATTERN)]],
      autoCalculateDistance: [params?.autoCalculateDistance ?? false],
      linearVelocity: [params?.linearVelocity, [Validators.pattern(FLOAT_PATTERN)]],
      propagationTime: [params?.propagationTime, [Validators.pattern(FLOAT_PATTERN)]],
      firstSalvoTime: [params?.firstSalvoTime, [Validators.pattern(FLOAT_PATTERN)]],
      fireAreaZones: fb.array([
        params?.fireAreaZones?.length
          ? params?.fireAreaZones.map(
              (el: IForecastingTechnologicalFireAreaDto): FormGroup => this.technologicalFireAreaFormGroup(fb, el),
            )
          : this.technologicalFireAreaFormGroup(fb, undefined),
      ]),
    });
  }

  /** Генерация FormGroup */
  private static technologicalFireAreaFormGroup(
    fb: FormBuilder,
    params: IForecastingTechnologicalFireAreaDto,
  ): FormGroup {
    return fb.group({
      id: [params?.id ?? 1],
      coordinates: [params?.coordinates, [coordinatesValidator]],
      technologicalFireFormId: [params?.technologicalFireFormId, [Validators.required]],
      angleValue: [params?.angleValue, [Validators.required, Validators.pattern(FLOAT_PATTERN)]],
      fireFightingEquipmentId: [params?.fireFightingEquipmentId, [Validators.required]],
      smallerSide: [params?.smallerSide, [Validators.pattern(FLOAT_PATTERN)]],
      windDirection: [params?.windDirection, [Validators.pattern(FLOAT_PATTERN)]],
      directionsCount: [params?.directionsCount, [Validators.pattern(FLOAT_PATTERN)]],
      fireExtinguishingWayId: [params?.fireExtinguishingWayId],
    });
  }

  /** Генерация FormGroup */
  public static consequencesOfForestFiresTaskFormGroup(
    fb: FormBuilder,
    params: IForecastingConsequencesOfForestFiresTaskParamsDto,
  ): FormGroup {
    return fb.group({
      fireTypeId: [params?.fireTypeId, [Validators.required]],
      burningTimes: fb.array(
        params?.burningTimes?.length
          ? params?.burningTimes.map((el: { id: number; burningTime: string }) => this.generateTimeFormGroup(fb, el))
          : [this.generateTimeFormGroup(fb, undefined)],
      ),
      coordinates: [params?.coordinates, [Validators.required, coordinatesValidator]],
      sizingTypeId: [params?.sizingTypeId ?? 'perimeter', [Validators.required]],
      size: [params?.size, [Validators.required, Validators.pattern(FLOAT_PATTERN)]],
      height: [params?.height, [Validators.pattern(FLOAT_PATTERN)]],
      forestTypeId: [params?.fireTypeId],
      trunkDiameterId: [params?.trunkDiameterId],
      flammabilityClass: [params?.flammabilityClass, [Validators.required]],
      windVelocity: [params?.windVelocity, [Validators.required, Validators.pattern(FLOAT_PATTERN)]],
      windDirection: [params?.windDirection, [Validators.required, Validators.pattern(FLOAT_PATTERN)]],
      fireHazardTypeId: [params?.fireHazardTypeId, [Validators.required]],
    });
  }

  /** Генерация FormGroup для времени распространения пожара */
  private static generateTimeFormGroup(fb: FormBuilder, params: { id: number; burningTime: string }) {
    return fb.group({
      id: [params?.id ?? 1],
      burningTime: [params?.burningTime, [Validators.required, Validators.pattern(FLOAT_PATTERN)]],
    });
  }
}
