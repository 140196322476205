<div>
  <h4 nz-typography>
    <i nz-icon nzType="field-time" nzTheme="outline"></i>
    Результаты прогноза
  </h4>
  <nz-divider></nz-divider>
  <nz-descriptions [nzTitle]="title" nzBordered nzSize="small" [nzColumn]="1">
    <nz-descriptions-item nzTitle="Время испарения АХОВ"
      >{{ result?.timeApproach }}</nz-descriptions-item
    >
  </nz-descriptions>
</div>
<ng-template #title> Время подхода облака АХОВ <br />к заданной точке </ng-template>
