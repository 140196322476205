<nz-drawer
  [nzVisible]="openDrawer"
  [nzWidth]="364"
  [nzOffsetX]="offsetX"
  [nzClosable]="false"
  [nzMask]="false"
  [nzPlacement]="'left'"
>
  <ng-container *nzDrawerContent>
    <div *ngIf="model">
      <div nz-row [nzGutter]="[12, 24]">
        <div nz-col nzSpan="2">
          <i nz-icon nzType="close" nzTheme="outline" (click)="close()"></i>
        </div>
        <div nz-col nzSpan="2">
          <i nz-icon nzType="video-camera" nzTheme="outline" class="title-icon"></i>
        </div>
        <div nz-col nzSpan="20" class="title">
          {{model.name}}
        </div>
        <div nz-col [nzSpan]="24" *ngIf="model.address">
          <div class="nz-form-label">Адрес</div>
          <div class="nz-form-control-container">
            <div class="nz-form-control">{{model.address?.fullText}}</div>
          </div>
        </div>
        <div nz-col [nzSpan]="24">
          <div class="nz-form-label">Координаты</div>
          <div class="nz-form-control-container">
            <div class="nz-form-control nz-form-control__post-tab">{{model.coordinates}}</div>
            <button (click)="setPositionMapOnCamera()"><div nz-icon nzType="environment"></div></button>
          </div>
        </div>
        <div nz-col [nzSpan]="24">
          <div class="nz-form-label">Высота размещения (м)</div>
          <div class="nz-form-control">{{model.cameraInstallationHeight}}</div>
        </div>
        <div nz-col [nzSpan]="24">
          <div class="nz-form-label">Состояние</div>
          <div class="nz-form-control">{{videoCameraStatus}}</div>
        </div>
        <div nz-col [nzSpan]="24">
          <div class="nz-form-label">Режим записи</div>
          <div class="nz-form-control">{{videoCameraArchiveMode}}</div>
        </div>
        <div nz-col [nzSpan]="24">
          <div class="nz-form-label">Предмет мониторинга</div>
          <div class="nz-form-control">
            <p
              *ngFor="let subject of monitoringSubjects"
              class="hide-overflow"
              nz-tooltip
              nzTooltipTitle="{{ subject }}"
            >
              {{ subject }}
            </p>
          </div>
        </div>
        <div nz-col [nzSpan]="24" *ngIf="canShowVideo || canShowArchiveVideo">
          <nz-collapse nzAccordion>
            <nz-collapse-panel
              nzHeader="Видеопоток"
              [nzActive]="true"
              *ngIf="canShowVideo"
              (nzActiveChange)="videoCollapsePanelActivation($event, 'live')"
            >
              <div nz-row [nzGutter]="[8, 16]">
                <div nz-col>
                  <button nz-button nz-tooltip nzTooltipTitle="Развернуть видео" (click)="expandDialog(liveStream)">
                    <i nz-icon nzType="fullscreen"></i>
                  </button>
                </div>
                <div nz-col>
                  <button nz-button nz-tooltip nzTooltipTitle="Обновить видео" (click)="refreshPlayer('liveStream')">
                    <i nz-icon nzType="reload"></i>
                  </button>
                </div>
                <div nz-col *ngIf="canAddToVideoDeviceList">
                  <button
                    nz-button
                    nz-tooltip
                    nzTooltipTitle="Добавить видеокамеру в список"
                    (click)="addToListCamera()"
                  >
                    <i nz-icon nzType="bars"></i>
                  </button>
                </div>
                <!--
                  Стиль с высотой и скрытием переполнения прописан так как nz-col не понимает,
                  что iframe был масштабирован и растягивается по вертикали и горизонтали.
                -->
                <div nz-col [nzSpan]="24" style="height: 180px; overflow: hidden">
                  <ng-container *ngTemplateOutlet="liveStream; context: blockContext"></ng-container>
                </div>
              </div>
            </nz-collapse-panel>
            <nz-collapse-panel
              nzHeader="Видеоархив"
              [nzActive]="false"
              *ngIf="canShowArchiveVideo"
              (nzActiveChange)="videoCollapsePanelActivation($event, 'archive')"
            >
              <div nz-row [nzGutter]="[8, 16]">
                <div nz-col [nzSpan]="24">
                  <nz-date-picker
                    *ngIf="videoServerTypeSysName === 'macroscop'"
                    style="width: 100%"
                    nzFormat="dd.MM.yyyy HH:mm:ss"
                    nzPlaceHolder="'Начало трансляции с'"
                    nzShowTime
                    [(ngModel)]="archiveDateStart"
                  ></nz-date-picker>
                </div>
                <div nz-col>
                  <button nz-button nz-tooltip nzTooltipTitle="Развернуть видео" (click)="expandDialog(archiveStream)">
                    <i nz-icon nzType="fullscreen"></i>
                  </button>
                </div>
                <div nz-col>
                  <button
                    *ngIf="videoServerTypeSysName === 'macroscop'"
                    nz-button nz-tooltip
                    nzTooltipTitle="Обновить видео"
                    (click)="refreshPlayer('archiveStream')"
                  >
                    <i nz-icon nzType="reload"></i>
                  </button>
                </div>
                <div nz-col *ngIf="canAddToVideoDeviceList">
                  <button
                    nz-button
                    nz-tooltip
                    nzTooltipTitle="Добавить видеокамеру в список"
                    (click)="addToListCamera()"
                  >
                    <i nz-icon nzType="bars"></i>
                  </button>
                </div>
                <!--
                  Стиль с высотой и скрытием переполнения прописан так как nz-col не понимает,
                  что iframe был масштабирован и растягивается по вертикали и горизонтали.
                -->
                <div nz-col [nzSpan]="24" style="height: 180px; overflow: hidden">
                  <ng-container *ngTemplateOutlet="archiveStream; context: blockContext"></ng-container>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
        <div nz-col [nzSpan]="12" *ngIf="canRequestVideoBtn">
          <button nz-button nzSize="small" nzType="primary" (click)="requestVideo()" nzBlock>
            Запросить видеоархив
          </button>
        </div>
        <div *ngIf="referencePhotoUrl" nz-col [nzSpan]="12">
          <button nz-button nzSize="small" nzType="link" (click)="showReferencePhoto(referencePhoto)" nzBlock>
            Эталонное фото
          </button>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-collapse>
            <nz-collapse-panel
              nzHeader="Дополнительная информация"
              [nzActive]="false"
            >
              <div nz-row [nzGutter]="[0, 24]">
                <div nz-col [nzSpan]="24">
                  <div class="nz-form-label">Категория</div>
                  <div class="nz-form-control">
                    <ng-container *ngFor="let category of categories">
                      {{category}} <br>
                    </ng-container>
                  </div>
                </div>
                <div nz-col [nzSpan]="24">
                  <div class="nz-form-label">Дата подключения</div>
                  <div class="nz-form-control">{{model.connectionDate | date: 'dd.MM.yyyy'}}</div>
                </div>
                <div nz-col [nzSpan]="24">
                  <div class="nz-form-label">Глубина DVR в днях</div>
                  <div class="nz-form-control">{{model.dvrDepth}}</div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
    </div>
  </ng-container>
</nz-drawer>

<nz-drawer
  #drawer
  [nzWidth]="'calc(100vw - var(--sidebar-width))'"
  [nzClosable]="false"
  [nzBodyStyle]="{ padding: 0 }"
  [nzCloseOnNavigation]="false"
  [nzContent]="drawerTemplate"
>
</nz-drawer>
<ng-template #drawerTemplate>
  <router-outlet (activate)="drawer.open()" (deactivate)="drawer.close()"></router-outlet>
</ng-template>

<ng-template *ngIf="videoServerTypeSysName && activeVideoCollapsePanel === 'live'" #liveStream>
  <iframe
    *ngIf="videoServerTypeSysName === 'rtc'"
    [src]="source"
    frameborder="0"
    allowfullscreen
    scrolling="no"
    style="width: 640px; height: 380px; transform: scale(0.433); transform-origin: 0 0"
  ></iframe>
  <bg-hls-player
    *ngIf="videoServerTypeSysName === 'macroscop'"
    [sourceType]="'life'"
    [cameraId]="model.id"
  ></bg-hls-player>
  <bg-hls-player
    *ngIf="videoServerTypeSysName === 'forestGuard'"
    [sourceType]="'life'"
    [cameraId]="model.id"
    [url]="model.mediaUrl"
  ></bg-hls-player>
</ng-template>

<ng-template *ngIf="videoServerTypeSysName && activeVideoCollapsePanel === 'archive'" #archiveStream>
  <!-- Если тип сервера Макроскоп видео-архив показать с помощью HLS плеера -->
  <bg-hls-player
    *ngIf="videoServerTypeSysName === 'macroscop' || videoServerTypeSysName === 'forestGuard'"
    [sourceType]="'archive'"
    [cameraId]="model.id"
    [period]="{ fromtime: archiveDateStart }"
  ></bg-hls-player>
</ng-template>

<ng-template #referencePhoto>
  <img [src]="referencePhotoUrl | safe: 'url'" [alt]="'Эталонное фото'" class="image" />
</ng-template>
