<nz-input-group [nzSize]="size" nzSearch [nzAddOnBefore]="prefixColor" [nzAddOnAfter]="suffixIconButton" >
  <input
    type="text"
    nz-input
    [(ngModel)]="value"
    (blur)="onTouched()"
    readonly
    nzSize="large"
  />
</nz-input-group>

<ng-template #prefixColor>
</ng-template>

<ng-template #suffixIconButton>
  <button nz-button nzType="default" type="button" [nzSize]="size">
    <input
      class="picker"
      type="color"
      [(ngModel)]="colorPickerValue"
      (change)="changePickerValue()"
    >
    <i nz-icon nzType="setting"></i>
  </button>
</ng-template>
