import { Component, OnInit } from '@angular/core';
import { IElementButton, INwHeaderBarOptions, IScInputOptions, IScSelectOptions } from '@smart-city/core/common';
import { MonitoringObjectBaseEditFormComponent } from '../monitoring-object-base-edit-form/monitoring-object-base-edit-form.component';
import { FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IAbstractServiceData } from 'smart-city-types';
import { IBaseDictionaryData, IScSelectItem } from '@smart-city/core/interfaces';
import {
  IAtmDevice,
  IMonitoringObjectHcsView,
} from '../../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/models/interfaces';
import { catchError } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'bg-monitoring-object-atm-edit-form',
  templateUrl: './monitoring-object-atm-edit-form.component.html',
  styleUrls: ['./monitoring-object-atm-edit-form.component.scss'],
})
export class MonitoringObjectAtmEditFormComponent extends MonitoringObjectBaseEditFormComponent implements OnInit {
  /** Список устройств для объекта */
  public devices: IAtmDevice[];
  /** Настройки заголовка списка устройств */
  public devicesHeaderOptions: INwHeaderBarOptions;
  /** Состояние формы просмотра информации об устройстве */
  public toggleFormOpen = false;
  /** Редактируемая запись */
  public model: IMonitoringObjectHcsView;

  /** Настройки компоненты Состояние */
  public statusOptions: IScInputOptions = {
    label: 'Состояние',
    placeholder: 'Состояние',
    disabled: true,
  };
  /** Настройки компоненты Вид снабжения */
  public supplyTypeOptions: IScInputOptions = {
    label: 'Вид снабжения',
    placeholder: 'Вид снабжения',
    disabled: true,
  };
  /** Настройки компоненты Вид интеграции */
  public integrationTypeOptions: IScInputOptions = {
    label: 'Вид интеграции',
    placeholder: 'Вид интеграции',
    disabled: true,
  };

  /** настройки компонента действующий статус */
  public objectStatusOptions: IScSelectOptions = {
    title: 'Действующий статус',
    required: true,
    data: null,
  };

  /** Настройки компоненты Сервер */
  public serverOptions: IScInputOptions = {
    label: 'Сервер',
    placeholder: 'Сервер',
    disabled: true,
  };
  /** Настройки компоненты Организация */
  public organizationOptions: IScInputOptions = {
    label: 'Организация',
    placeholder: 'Организация',
    disabled: true,
  };

  /** ID типа Водоотведения */
  private wasteWaterDisposalId: string = this.settings.getDictionaryByTypeAndSysName(
    'hcsSupplyTypes',
    'wasteWaterDisposal',
  )?.id;

  /** ID типа Водоснабжения */
  private waterSuplyId: string = this.settings.getDictionaryByTypeAndSysName('hcsSupplyTypes', 'waterSupply')?.id;

  /** ID опции "Выведен из экспулатации" */
  private outOfServiceId: string = this.settings.getDictionaryByTypeAndSysName('hcsObjectStatus', 'outOfService')?.id;

  /** ID опции "Вэксплуатации" */
  private exploitationId: string = this.settings.getDictionaryByTypeAndSysName('hcsObjectStatus', 'exploitation')?.id;

  /** ID опции "В резерве" */
  private reserveId: string = this.settings.getDictionaryByTypeAndSysName('hcsObjectStatus', 'Reserve')?.id;

  public override ngOnInit(): void {
    super.ngOnInit();

    if (this.model.id) {
      this.updateObjectStatusData(this.model.supplyTypeId as string);
    }

    if ((<IBaseDictionaryData>this.model.supplyTypeId)?.id) {
      this.hcsObjectKindIdOptions.query = {
        supplyTypeId: (<IBaseDictionaryData>this.model.supplyTypeId)?.id,
      };
    } else {
      this.form.controls.hcsObjectKindId.disable();
      this.hcsObjectKindIdOptions.disabled = true;
    }

    this.devicesHeaderOptions = {
      title: 'Устройства',
      name: 'devicesHeader',
      bgColor: 'white',
      margin: 'collapse',
    };

    this.form.addControl('status', new FormControl(this.model.status));
    this.form.addControl('supplyType', new FormControl((<IBaseDictionaryData>this.model.supplyTypeId)?.name));
    this.form.addControl('integrationType', new FormControl(this.model.integrationType));
    this.form.addControl('hcsObjectStatus', new FormControl(this.model.hcsObjectStatus));
    this.form.addControl('server', new FormControl(this.model.server));
    this.form.addControl('organization', new FormControl(this.model.organization));

    this.form.controls.hcsObjectStatus.setValidators(Validators.required);

    this.form.controls.supplyType.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      this.updateObjectStatusData(value);
    });

    this.service
      .getDevices(this.model.id)
      .pipe(untilDestroyed(this))
      .subscribe((devices: IAtmDevice[]) => (this.devices = devices || []));
  }

  /**
   * Обрабатываем нажатие кнопок в заголовке
   * @param $event - информация о нажатой кнопке
   */
  public onClickActionsButton($event: IElementButton) {
    super.onClickActionsButton($event);

    if (this.form.invalid || $event.options.name === 'cancel') return;

    this.service
      .save({
        id: this.model.id,
        showOnMap: this.model.showOnMap,
        hcsObjectKindId: this.model.hcsObjectKindId,
        hcsObjectTypeId: this.model.hcsObjectTypeId,
        operatingOrganizationId: this.model.operatingOrganizationId,
        ownerOrganizationId: this.model.ownerOrganizationId,
        active: this.model.active,
        hcsObjectStatus: this.model.hcsObjectStatus,
      })
      .pipe(
        catchError((error: Error) => this.catchErrorFn<IAbstractServiceData>(error, 'Ошибка при сохранении записи')),
        untilDestroyed(this),
      )
      .subscribe((data: IAbstractServiceData) => {
        if (data) {
          this.router.navigate(['external-interactions/monitoring-objects-hcs']);
        }
      });
  }

  /**
   * Отображение подробной информации об устройстве
   * id - ID устройства
   */
  public showDevice(id: number) {
    this.router.navigate([{ outlets: { viewForm: ['atmDevice', id] } }], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      state: { ...this.devices[0], objectId: this.model.id },
    });
  }

  /**
   * Обновляем опции селекта Действующий статус объекта
   * в зависимости от значения селекта Вид снабжения
   */
  private updateObjectStatusData(value: string): void {
    let selectOptions = this.settings.getDictForSelect('hcsObjectStatus');
    if (this.form.controls?.hcsObjectStatus?.value) {
      this.form.controls.hcsObjectStatus.reset();
    }
    if (value === this.wasteWaterDisposalId || value === this.waterSuplyId) {
      selectOptions = selectOptions.filter(
        (objectStausType: IScSelectItem) => objectStausType.id !== this.outOfServiceId,
      );
    } else {
      selectOptions = selectOptions.filter((objectStausType: IScSelectItem) =>
        [this.outOfServiceId, this.exploitationId, this.reserveId].includes(objectStausType.id),
      );
    }
    this.objectStatusOptions.data = selectOptions;
  }
}
