import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  SignificantObjectsDashboardComponent,
  SignificantObjectsEditFormComponent,
  SignificantObjectsViewFormComponent
} from './components';
import { DirtyGuard } from '@bg-front/core/guards';

const routes: Routes = [
  {
    path: '', component: SignificantObjectsDashboardComponent,
    children: [
      { path: 'view/:id', component: SignificantObjectsViewFormComponent },
      { path: 'edit/:id', component: SignificantObjectsEditFormComponent, canDeactivate: [DirtyGuard] },
      { path: 'edit', component: SignificantObjectsEditFormComponent, canDeactivate: [DirtyGuard] },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SignificantObjectsRoutingModule {}
