<form [formGroup]="involvedWithoutForm" errorTailor id="idForTest">
  <div class="bg-involve-org-without-commission-form">
    <mat-dialog-content class="mat-typography">
      <div class="bg-row bg-commission-form-input">
        <sc-select [options]="serviceTypeOptions" formControlName="serviceType"></sc-select>
      </div>
      <div class="bg-row bg-commission-form-input">
        <sc-select [options]="optionsIncidentTypesOptions" formControlName="attractOrgData"></sc-select>
      </div>
      <div class="bg-row bg-commission-form-input">
        <sc-input [options]="responseTimeOptions" formControlName="responseTime"></sc-input>
      </div>
      <div class="bg-row bg-commission-form-input">
        <sc-input [options]="timeReactOptions" formControlName="timeReact"></sc-input>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <sc-button [options]="cancelBtnOptions" (clickButton)="closeHandler()"></sc-button>
      <sc-button
        [disabled]="isDisabledSaveBtn"
        [options]="saveBtnOptions"
        (clickButton)="sendHandler($event)"
      ></sc-button>
    </mat-dialog-actions>
  </div>
</form>
