<div class="bg-incident-panel container">
  <div class="bg-row">
    <div class="bg-col p-horizontal-none">
      <div class="bg-incident-panel-edit-from">
        <form [formGroup]="incidentForm" appLCSFormState [params]="attributes" errorTailor id="idForTest">
          <nw-header [options]="headerActionsOptions" (clickButton)="onClickActionsButton($event)"></nw-header>
          <div class="incident-panel">
            <div class="bg-col" style="text-align: right" *ngIf="model?.parentEventId">
              <div class="bg-form-group-incident">
                <a href="javascript:void(0)" (click)="showEventInNewWindow()">
                  Событие&nbsp;{{ model.parentEventId['number'] }}
                </a>
              </div>
            </div>
            <mat-accordion multi="true">
              <!-- MAIN ----------------------------------------- -->
              <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Главное</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="bg-row space-between-container">
                  <div class="bg-col">
                    <span>Регистрация: {{ model.timeCreate | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                  </div>
                  <div class="bg-col">
                    <span>Начало: {{ model.timeStart | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                  </div>
                  <div class="bg-col">
                    <span>Окончание: {{ model.timeFinish | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <div nz-form nzLayout="vertical">
                        <nz-form-item>
                          <nz-form-label nzRequired>Адрес КСиП</nz-form-label>
                          <nz-form-control>
                            <nz-address-kit formControlName="addressFact"></nz-address-kit>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col-6">
                    <div class="bg-form-group">
                      <sc-select
                        [ngStyle]="{ display: !moIdVisible ? 'none' : '' }"
                        [options]="moIdOptions"
                        formControlName="moId"
                        (init)="onMoInit($event)"
                      ></sc-select>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select
                        [ngStyle]="{ display: !districtIdVisible ? 'none' : '' }"
                        [options]="districtIdOptions"
                        formControlName="districtId"
                        (init)="onDistrictInit($event)"
                      ></sc-select>
                    </div>
                  </div>
                </div>
                <placement-details-edit
                  formControlName="detailsFact"
                  [attributes]="attributes"
                ></placement-details-edit>
                <div
                  class="bg-row bg-row-align-content-center space-between-container"
                  *ngIf="!!countOfCloseImportantObjects"
                >
                  <div class="bg-col-min">
                    <i
                      class="sc-icon-attention significant-object-attention"
                      [ngClass]="{ 'significant-object-danger': hasDangerObject }"
                    ></i>
                  </div>
                  <div class="bg-col">
                    <p>{{ closeImportantObjectsMessage }} (подробнее см. раздел "Местоположение")</p>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <sc-select
                        [options]="optionsIncidentTypes"
                        formControlName="incidentTypeId"
                        [data]="loadKsipTypes$"
                      ></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row" *ngIf="isKsipDetails">
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <sc-select
                        [options]="optionsKsipDetails"
                        formControlName="ksipDetailsId"
                        [data]="loadKsipDetails$"
                      ></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row" *ngIf="isAccidentForm">
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <bg-datetime
                        formControlName="workTermFrom"
                        [options]="workTermFromOptions"
                        [controlErrors]="workTermFromErrors"
                      >
                        ></bg-datetime
                      >
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <bg-datetime
                        formControlName="workTermTo"
                        [options]="workTermToOptions"
                        [controlErrors]="workTermToErrors"
                      ></bg-datetime>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea [options]="optionsDescription" formControlName="description"></sc-textarea>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea [options]="optionsComment" formControlName="comment"></sc-textarea>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <div class="bg-row space-between-container">
                        <div class="bg-col" *ngIf="urgentlyVisible">
                          <sc-checkbox [options]="urgentlyOptions" formControlName="urgently"></sc-checkbox>
                        </div>
                        <div class="bg-col" *ngIf="threatsVisible">
                          <sc-checkbox [options]="optionsThreatPopulation" formControlName="danger"></sc-checkbox>
                        </div>
                        <div class="bg-col" *ngIf="threatsVisible">
                          <sc-checkbox [options]="optionsThreatOrganization" formControlName="dangerOrg"></sc-checkbox>
                        </div>
                        <div class="bg-col">
                          <sc-checkbox
                            [options]="optionsSupervisedByCuks"
                            formControlName="supervisedByCuks"
                          ></sc-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select [options]="optionsOrganization" formControlName="organization"></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select
                        [options]="optionsResponsibleUser"
                        showErrorOnStart="true"
                        showErrorOnStartAfter="3000"
                        formControlName="responsibleUser"
                      ></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row" *ngIf="model['regime'] && model['regime']['id']">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select [options]="optionsRegime" formControlName="regime"></sc-select>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <!-- PARAMS ----------------------------------------- -->
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Параметры</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <bg-dds01-fire-parameters
                  name="fireParams"
                  formControlName="fireParams"
                  [attributes]="attributes"
                ></bg-dds01-fire-parameters>
              </mat-expansion-panel>
              <!-- Местоположение ----------------------------------------- -->
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <i
                        *ngIf="!!countOfCloseImportantObjects"
                        class="sc-icon-attention significant-object-attention"
                        [ngClass]="{ 'significant-object-danger': hasDangerObject }"
                      ></i>
                      <div>Местоположение</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-input [options]="coordinatesOption" formControlName="coordinates"></sc-input>
                    </div>
                    <div class="bg-form-group">
                      <sc-textarea
                        class="additional-incident-type-container"
                        [options]="placeDescriptionOptions"
                        formControlName="ksipPlaceDescription"
                      ></sc-textarea>
                    </div>
                    <div class="bg-form-group">
                      <bg-significant-object-list-dialog
                        [coordinates]="incidentForm.controls.coordinates.value"
                        [radius]="500"
                        [disabled]="!this.countOfCloseImportantObjects"
                      ></bg-significant-object-list-dialog>
                    </div>
                    <div class="bg-form-group">
                      <div class="bg-row bg-row-align-content-center space-between-container justify-content">
                        <div class="bg-col-min" *ngIf="countOfCloseImportantObjects">
                          <i
                            class="sc-icon-attention significant-object-attention"
                            [ngClass]="{ 'significant-object-danger': hasDangerObject }"
                          ></i>
                        </div>
                        <div>
                          <p>{{ this.closeImportantObjectsMessage }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group mini-map-container">
                      <bg-mini-map
                        [center]="miniMapCenter"
                        [zoom]="miniMapZoom"
                        [defaultMarkerCoordinate]="defaultMarkerCoordinate"
                        (changeMarkerPosition)="updateCoordinates($event)"
                        (mapReady)="onMapReady()"
                      ></bg-mini-map>
                    </div>
                  </div>
                </div>
                <div class="bg-row" *ngIf="isForestFire()">
                  <div class="bg-col">
                    <div class="bg-form-group readonly-container">
                      <label>Лесохозяйственный объект</label>
                      <p>{{ forestryFacility.name }}</p>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group readonly-container">
                      <label>Заявленный адрес</label>
                      <p>{{ model?.address?.fullText }}</p>
                    </div>
                  </div>
                </div>
                <div class="bg-row" *ngIf="!isForestFire()">
                  <div class="bg-col">
                    <placement-details [model]="model?.details"></placement-details>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel (opened)="openCoverageArea()">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Зона действия</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <bg-coverage-area [address]="factAddress"></bg-coverage-area>
              </mat-expansion-panel>
              <!-- Дополнительная информация ----------------------------------------- -->
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Дополнительная информация</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <sc-checkbox [options]="optionsShowOnPortal" formControlName="showOnPortal"></sc-checkbox>
                </div>
                <bg-dds01-additional-info
                  [options]="model.dds01AdditionalInfo"
                  [attributes]="attributes"
                  name="dds01AdditionalInfo"
                  formControlName="dds01AdditionalInfo"
                ></bg-dds01-additional-info>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-input
                        [options]="totalRegistryPeopleHouseOptions"
                        formControlName="totalRegistryPeopleHouse"
                      ></sc-input>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-input
                        [options]="totalTemporalPeopleHouseOptions"
                        formControlName="totalTemporalPeopleHouse"
                      ></sc-input>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-form-group">
                    <sc-checkbox
                      [options]="individualOwnershipOptions"
                      formControlName="individualOwnership"
                    ></sc-checkbox>
                  </div>
                </div>
                <ng-container [ngSwitch]="projectedRisk">
                  <bg-technological-fire-additional-info
                    *ngSwitchCase="'technologicalFire'"
                    formGroupName="technologicalFireAdditionalInfo"
                  ></bg-technological-fire-additional-info>
                </ng-container>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group readonly-container">
                      <label>Причина возврата происшествия в работу</label>
                      <p>{{ model?.reasonOfBackToWork }}</p>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Задействованные транспортные средства</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <grid-table #vehiclesGrid [grid]="vehiclesGridOptions" class="vehicleGrid"></grid-table>
              </mat-expansion-panel>
              <!-- Потери ----------------------------------------- -->
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Потери</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <bg-looses [emergencyId]="model.id" formGroupName="resolution"></bg-looses>
              </mat-expansion-panel>
              <!-- Прогнозирование ----------------------------------------- -->
              <mat-expansion-panel *ngIf="showForecastingParams && canForecastingRun?.visible">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Прогнозирование</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bg-row" *ngIf="canForecastingRun?.visible">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <button
                        class="btn btn-sm btn-primary"
                        (click)="runForecasting()"
                        [disabled]="!canForecastingRun?.enabled"
                      >
                        Запуск прогнозирования
                      </button>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group h-300">
                      <grid-table [grid]="gridOptions" #gridComponent></grid-table>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <!-- DOCS ----------------------------------------- -->
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Документы</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <multi-file [selfEnabled]="true" formControlName="documents"></multi-file>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Информация о водоисточниках</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group h-600">
                      <grid-table [grid]="waterSourcesGridOptions"></grid-table>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </form>
      </div>
    </div>
    <div class="bg-col bg-incident-panel-steps-list-col bg-incident-panel-steps-list">
      <bg-response-plan-step-list
        *ngIf="involvedServiceEnabled || responsePlanStepService.getListCount('involvedOrgOpts')"
        (stepClickEvent)="showInvolvedOrgForm($event)"
        (createStepEvent)="createNewStepInvolveOrg()"
        [disabled]="involvedServiceEnabled ? isEmergencyEndStepCycle : true"
        [options]="involvedOrgOpts"
      >
      </bg-response-plan-step-list>
      <bg-response-plan-step-list
        (stepClickEvent)="showResponseStepForm($event)"
        (createStepEvent)="createNewStepScriptReact($event)"
        [disabled]="involvedServiceEnabled ? isEmergencyEndStepCycle : true"
        [options]="responseOrgOpts"
      >
      </bg-response-plan-step-list>
    </div>
  </div>
</div>
