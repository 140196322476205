import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { IForecastingResultDto, IForecastingSaveResultDto } from '@bg-front/core/models/interfaces';
import { ForecastingResultsService } from '@bg-front/core/services';
import { IElementButton, INwHeaderBarOptions } from '@smart-city/core/common';
import { Uuid } from '@smart-city/core/utils';
import * as dayjs from 'dayjs';
import { of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { ForecastingService } from '../../../forecasting/services';

@Component({
  selector: 'bg-forecasting-show-form',
  templateUrl: './forecasting-show-form.component.html',
  styleUrls: ['./forecasting-show-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastingShowFormComponent extends BaseComponent implements OnInit {
  /**
   * Конфигурация заголовка
   */
  public headerActionsOptions: INwHeaderBarOptions = undefined;

  /** Ждём загрузки */
  public isReady = false;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly forecastingService: ForecastingService,
    private readonly forecastingResultService: ForecastingResultsService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.isReady = false;
          const modelId = params.get('id');

          // Если ID нет в параметрах, значит форма открыта для создания новой записи
          if (!modelId) {
            return of(undefined);
          }

          // Если ID невалидный не выполнять поиск и передать значение что ничего найдено
          if (!Uuid.isValid(modelId)) {
            return of(undefined);
          }

          // Поиск записи по полученному ID
          return this.forecastingService.getEmergencyForecastingResultById(modelId);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data: IForecastingSaveResultDto) => {
        this.forecastingService.setForecastingFormState(data.params);
        this.forecastingResultService.add(data.result as IForecastingResultDto);

        this.headerActionsOptions = {
          title: `Результат прогнозирования от ${dayjs(data.creationTime).format('DD.MM.YYYY HH:mm:ss')}`,
          name: 'header',
          bgColor: 'white',
          margin: 'collapse',
          buttons: [
            {
              type: 'button',
              options: {
                name: 'cancel',
                icon: 'clear',
              },
            },
          ],
        };

        this.isReady = true;
        this.cdr.detectChanges();
      });
  }

  /**
   * Обрабатываем нажатие кнопки управления состоянием
   * @param $event
   */
  public onClickActionsButton($event: IElementButton) {
    if ($event.options.name === 'cancel') {
      this.closeForm();
      return;
    }
  }

  /** Закрываем форму */
  private closeForm() {
    this.router.navigate([{ outlets: { viewForm: null } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }
}
