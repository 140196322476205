import { Injectable } from '@angular/core';
import { Settings2Service } from '@smart-city/core/services';
import { Workbook, Worksheet } from 'exceljs';
import { HttpClient } from '@angular/common/http';
import * as dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import {
  IForecastingForestFireReport,
  IForecastingTechnologicalFireReport,
  IReportingDevice,
  IUnusableTree,
} from '../../models';

/**
 * Сервис для прогнозирования
 */
@Injectable()
export class ForecastingFiresReportService {
  constructor(private xlsxUploader: HttpClient) {}

  /**
   * Формирование и скачивание отчета по прогнозированию лесного пожаров
   */
  public buildForecastingForestFireReport(report: IForecastingForestFireReport) {
    const wb = new Workbook();
    let ws2: Worksheet = undefined;
    let ws3: Worksheet = undefined;
    let ws4: Worksheet = undefined;
    let ws5: Worksheet = undefined;

    this.xlsxUploader
      .get('/assets/report-templates/forecasting-forest-fire-report.xlsx', {
        responseType: 'arraybuffer',
      })
      .subscribe((buffer) => {
        wb.xlsx.load(buffer).then((workbook) => {
          const dateFormat = 'DD.MM.YYYY HH:mm:ss';

          ws2 = workbook.getWorksheet('Лес. пожар_стр. 2');
          if (ws2) {
            /** Заголовок страницы 2 */
            ws2.getCell(1, 1).value = this.prepareValue(report.ws2Header);

            /** Блок "Используемая методология для прогнозирования" */
            ws2.getCell(4, 1).value = this.prepareValue(report.methodology);

            /** Блок "Местоположение" */
            ws2.getCell(7, 2).value = this.prepareValue(report.coordinates);
            ws2.getCell(8, 2).value = this.prepareValue(report.object);
            ws2.getCell(9, 2).value = this.prepareValue(report.responsible);
            ws2.getCell(10, 2).value = this.prepareValue(report.phone);

            /** Блок "Исходные данные" */
            ws2.getCell(13, 2).value = this.prepareValue(report.incident);
            ws2.getCell(14, 2).value = this.prepareValue(report.occurrenceTime);
            ws2.getCell(15, 2).value = this.prepareValue(report.fireType);
            ws2.getCell(16, 2).value = this.prepareValue(report.fireTime);
            ws2.getCell(17, 2).value = this.prepareValue(report.initOutbreakArea);
            ws2.getCell(18, 2).value = this.prepareValue(report.fireHeight);

            /** Блок "Метеоусловия" */
            ws2.getCell(7, 6).value = this.prepareValue(report.windSpeed);
            ws2.getCell(8, 6).value = this.prepareValue(report.windDirection);
            ws2.getCell(9, 6).value = this.prepareValue(report.weatherFireHazard);

            /** Блок "Характеристика лесных насаждений" */
            ws2.getCell(13, 6).value = this.prepareValue(report.forestType);
            ws2.getCell(14, 6).value = this.prepareValue(report.forestDiameters);
            ws2.getCell(15, 6).value = this.prepareValue(report.burningClass);
            ws2.getCell(16, 6).value = this.prepareValue(report.forestNote);
          }

          ws3 = workbook.getWorksheet('Лес. пожар_стр. 3');
          if (ws3) {
            /** Блок "Результаты расчёта" */
            ws3.getCell(4, 2).value = this.prepareValue(report.typeOfFire);

            ws3.getCell(7, 2).value = this.prepareValue(report.speedFront);
            ws3.getCell(8, 2).value = this.prepareValue(report.speedFlanks);
            ws3.getCell(9, 2).value = this.prepareValue(report.speedRear);

            ws3.getCell(12, 2).value = this.prepareValue(report.fireZoneCombustion);
            ws3.getCell(13, 2).value = this.prepareValue(report.fireZoneArea);
            ws3.getCell(14, 2).value = this.prepareValue(report.standDamage);
            ws3.getCell(15, 2).value = this.prepareValue(report.standState);

            if (!report.unusableTrees) {
              ws3.getCell(18, 1).value = this.prepareValue(
                'Расчёт невозможен, т.к. для лесных насаждений не заданы виды древесных пород.',
              );
            } else {
              report.unusableTrees.forEach((el: IUnusableTree, index: number) => {
                ws3.getCell(18 + index, 1).value = this.prepareValue(el.name);
                ws3.getCell(18 + index, 2).value = this.prepareValue(el.value);
              });
            }
          }

          ws4 = workbook.getWorksheet('Лес. пожар_стр. 4');
          if (ws4) {
            /** Заголовок страницы 4 */
            ws4.getCell(1, 1).value = this.prepareValue(report.ws4Header);

            if (report.buildings?.length) {
              for (let i = 0; i < report.buildings.length; i++) {
                const el = report.buildings[i];
                let str = el.city;

                if (el.street) {
                  str = str?.length ? (str += ', ' + el.street) : el.street;
                }

                if (el.houseNumber) {
                  str = str?.length ? (str += ', д.' + el.houseNumber) : 'д.'+ el.houseNumber;
                }
                ws4.getCell(i + 2, 1).value = str;
              }
            } else {
              ws4.getCell(2, 1).value = 'Здания в зоне возможного распространения лесного пожара не найдены';
            }
          }

          ws5 = workbook.getWorksheet('Лес. пожар_стр. 5');
          if (ws5) {
            /** Заголовок страницы 5 */
            ws5.getCell(1, 1).value = this.prepareValue(report.ws5Header);
            if (report.devices?.length) {
              report.devices.forEach((device: IReportingDevice, index: number) => {
                ws5.getCell(3 + index, 1).value = this.prepareValue(device.type);
                ws5.getCell(3 + index, 2).value = this.prepareValue(device.name);
                ws5.getCell(3 + index, 3).value = this.prepareValue(device.currentSeanceName);
                ws5.getCell(3 + index, 4).value = this.prepareDate(new Date(device.syncTime), dateFormat);
                // TODO добавить динамические стили для границ
                //ws5.getCell(3, 1).style.border = ws5.getCell(2, 1).style.border
              });
            } else {
              ws5.getCell(3, 1).value = 'Не найдены средства оповещения в рассчитанной зоне';
            }
          }

          workbook.xlsx.writeBuffer().then((buf) => {
            saveAs(new Blob([buf]), `Расчёт последствий лесного пожара_${this.getCurrentTime(dateFormat)}.xlsx`);
          });
        });
      });
  }

  /**
   * Формирование и скачивание отчета по прогнозированию техногенного пожаров
   */
  public buildForecastingTechnologicalFireReport(report: IForecastingTechnologicalFireReport) {
    const wb = new Workbook();
    let ws2: Worksheet = undefined;
    let ws3: Worksheet = undefined;
    let ws4: Worksheet = undefined;
    let ws5: Worksheet = undefined;

    this.xlsxUploader
      .get('/assets/report-templates/forecasting-technological-fire-report.xlsx', {
        responseType: 'arraybuffer',
      })
      .subscribe((buffer) => {
        wb.xlsx.load(buffer).then((workbook) => {
          const dateFormat = 'DD.MM.YYYY HH:mm:ss';

          ws2 = workbook.getWorksheet('Тех. пожар_стр. 2');
          if (ws2) {
            /** Блок "Используемая методология для прогнозирования" */
            ws2.getCell(4, 1).value = this.prepareValue(report.methodology);

            /** Блок "Местоположение" */
            ws2.getCell(9, 2).value = this.prepareValue(report.address);
            ws2.getCell(11, 2).value = this.prepareValue(report.coordinates);
            ws2.getCell(12, 2).value = this.prepareValue(report.object);
            ws2.getCell(13, 2).value = this.prepareValue(report.responsible);
            ws2.getCell(14, 2).value = this.prepareValue(report.phone);

            /** Блок "Исходные данные" */
            ws2.getCell(19, 2).value = this.prepareValue(report.incident);
            ws2.getCell(20, 2).value = this.prepareValue(report.occurrenceTime);
            ws2.getCell(21, 2).value = this.prepareValue(report.fireObject);
            ws2.getCell(22, 2).value = this.prepareValue(report.fireDistance);
            ws2.getCell(23, 2).value = this.prepareValue(report.auto ? 'Да' : 'Нет');
            ws2.getCell(24, 2).value = this.prepareValue(report.fireSpeed);
            ws2.getCell(25, 2).value = this.prepareValue(report.fireTime);
            ws2.getCell(26, 2).value = this.prepareValue(report.firstBarrel);

            /** Блок "Данные о зоне горения" */
            let nextBlock = 0;
            (report.fireZones || []).forEach((item, index) => {
              if (index !== 0) {
                for (let i = 8; i < 13; i++) {
                  for (let j = 6; j < 8; j++) {
                    ws2.getCell(nextBlock + i, j).style = ws2.getCell(i, j).style;
                    ws2.getCell(nextBlock + i, j).value = ws2.getCell(i, j).value;
                  }
                }
              }
              ws2.getCell(nextBlock + 9, 7).value = this.prepareValue(item?.sourceCoordinates || '---');
              ws2.getCell(nextBlock + 10, 7).value = this.prepareValue(item?.fireShape || '---');
              ws2.getCell(nextBlock + 11, 7).value = this.prepareValue(item?.extinguishingAgent || '---');
              (item?.params || []).forEach((param: string, index: number) => {
                ws2.getCell(nextBlock + index + 12, 7).value = this.prepareValue(param || '---');
                if (index !== 0) {
                  ws2.getCell(nextBlock + index + 12, 7).style = ws2.getCell(nextBlock + 12, 7).style;
                }
              });
              if ((item?.params || []).length > 1) {
                ws2.mergeCells(nextBlock + 12, 6, nextBlock + 12 + item.params.length - 1, 6);
              }
              nextBlock += 5 + (item.params.length || 1);
            });
          }

          ws3 = workbook.getWorksheet('Тех. пожар_стр. 3');
          if (ws3) {
            ws3.getCell(3, 3).value = this.prepareValue(report.fireDistanceResults);
            ws3.getCell(4, 3).value = this.prepareValue(report.fireAreaResult);
            ws3.getCell(5, 3).value = this.prepareValue(report.fireExtinguishingAgentConsumptionResult);
            ws3.getCell(6, 3).value = this.prepareValue(report.fireExtinguishingAreaResult);

            let nextBlock = 0;
            (report.fireZonesResult || []).forEach((item, index) => {
              if (index !== 0) {
                for (let i = 8; i < 13; i++) {
                  ws3.mergeCells(nextBlock + i, 1, nextBlock + i, 2);
                  ws3.getCell(nextBlock + i, 1).style = ws3.getCell(i, 1).style;
                  ws3.getCell(nextBlock + i, 1).value = ws3.getCell(i, 1).value;
                  ws3.getCell(nextBlock + i, 3).style = ws3.getCell(i, 3).style;
                }
              }
              ws3.getCell(nextBlock + 8, 3).value = this.prepareValue(item.combustionZone);
              ws3.getCell(nextBlock + 9, 3).value = this.prepareValue(item.fireArea);
              ws3.getCell(nextBlock + 10, 3).value = this.prepareValue(item.fireExtinguishingAgentConsumption);
              ws3.getCell(nextBlock + 11, 3).value = this.prepareValue(item.fireExtinguishingArea);
              ws3.getCell(nextBlock + 12, 3).value = this.prepareValue(item.fireExtinguishingAgentSupply);
              nextBlock += 6;
            });
          }

          ws4 = workbook.getWorksheet('Тех. пожар_стр. 4');
          if (ws4) {
            if (report.buildings?.length) {
              for (let i = 0; i < report.buildings.length; i++) {
                const el = report.buildings[i];
                let str = el.city;

                if (el.street) {
                  str = str?.length ? (str += ', ' + el.street) : el.street;
                }

                if (el.houseNumber) {
                  str = str?.length ? (str += ', д.' + el.houseNumber) : 'д.'+ el.houseNumber;
                }

                // const building = `${el.city}, ${el.street}, д.${el.houseNumber}`;
                ws4.getCell(i + 2, 1).value = str;
              }
            } else {
              ws4.getCell(2, 1).value = 'Здания в зоне возможного распространения техногенного пожара не найдены';
            }
          }

          ws5 = workbook.getWorksheet('Тех. пожар_стр. 5');
          if (ws5) {
            if (report.devices?.length) {
              report.devices.forEach((device: IReportingDevice, index: number) => {
                ws5.getCell(3 + index, 1).value = this.prepareValue(device.type);
                ws5.getCell(3 + index, 2).value = this.prepareValue(device.name);
                ws5.getCell(3 + index, 3).value = this.prepareValue(device.currentSeanceName);
                ws5.getCell(3 + index, 4).value = this.prepareDate(new Date(device.syncTime), dateFormat);
                // TODO добавить динамические стили для границ
                // ws5.getCell(3 + index, 1).style.border = ws5.getCell(3 + index - 1, 1).style.border;
                // ws5.getCell(3 + index, 2).style.border = ws5.getCell(3 + index - 1, 2).style.border;
                // ws5.getCell(3 + index, 3).style.border = ws5.getCell(3 + index - 1, 3).style.border;
                // ws5.getCell(3 + index, 4).style.border = ws5.getCell(3 + index - 1, 4).style.border;
              });
            } else {
              ws5.getCell(2, 1).value = 'Не найдены средства оповещения в рассчитанной зоне';
            }
          }

          workbook.xlsx.writeBuffer().then((buf) => {
            saveAs(
              new Blob([buf]),
              `Расчёт параметров развития техногенного пожара_${this.getCurrentTime(dateFormat)}.xlsx`,
            );
          });
        });
      });
  }

  /** Получить текущее время */
  public getCurrentTime(dateFormat: string): string {
    return dayjs().format(dateFormat);
  }

  /** Возвращает дату в нужном формате (ex. 'DD.MM.YYYY hh:mm:ss') */
  private prepareDate(d: Date, dateFormat: string): string {
    return dayjs(d).format(dateFormat);
  }

  /**
   * Подготовка значения для вывода в таблицу
   * @param value значение
   * @param defaultValue значение по умолчанию
   */
  private prepareValue(value: any, defaultValue?: string): string {
    if (value === null || value === undefined || value === '') {
      return defaultValue || '–––';
    }
    return value.toString();
  }
}
