import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { IPolygonDto } from '@bg-front/core/models/interfaces';
import { MapBaseModel } from '@smart-city/maps/sc';
import { forkJoin, Observable } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { IBgRegimeDto } from '../../../../bg/modules/consolidated-registries/modules/regimes/models/bg-regime-dto.interface';
import { IDistrict } from '../../../../bg/modules/dictionaries/modules/districts/models/interfaces';
import { DistrictsService } from '../../../../bg/modules/dictionaries/modules/districts/services';
import { IMunicipality } from '../../../../bg/modules/dictionaries/modules/municipalities/models/municipality.interface';
import { MunicipalitiesService } from '../../../../bg/modules/dictionaries/modules/municipalities/services/municipalities.service';
import { LayersEnum } from '@bg-front/core/models/enums';
import { PolygonsService, RegimesService } from '../../../services';

@Component({
  selector: 'bg-regimes-map-widget',
  templateUrl: './regimes-map-widget.component.html',
  styleUrls: ['./regimes-map-widget.component.scss'],
})
export class RegimesMapWidgetComponent extends BaseComponent implements OnInit {
  /** Модель карты */
  @Input() public mapModel: MapBaseModel;

  /** Отображать или нет виджет */
  public isShowWidget: boolean = false;

  /** Флаг активности виджета*/
  public isActive: boolean = false;

  /** @ignore */
  constructor(
    private readonly regimesService: RegimesService,
    private readonly municipalitiesService: MunicipalitiesService,
    private readonly polygonsService: PolygonsService,
    private readonly districtsService: DistrictsService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    const municipalitiesPolygons: string[] = [];
    const districtsPolygons: string[] = [];
    this.regimesService
      .getRegimeForRegistryPanel()
      .pipe(
        switchMap((regimes: IBgRegimeDto[]) => {
          this.isShowWidget = !!regimes?.length;
          // Получение МО и районов в которых введены режимы функционирования
          return <Observable<[IMunicipality[], IDistrict[]][]>>(
            forkJoin(
              regimes.map((regime: IBgRegimeDto) =>
                forkJoin([
                  this.municipalitiesService.getMunicipalityById(regime.municipalityIds),
                  this.districtsService.getDistrictById(regime.districtIds),
                ]),
              ),
            )
          );
        }),
        switchMap((regimesTerritories: [IMunicipality[], IDistrict[]][]) => {
          regimesTerritories.forEach(([municipalities, districts]: [IMunicipality[], IDistrict[]]) => {
            municipalitiesPolygons.push(
              // Фильтрация МО. Если для МО были определены районы, то вывести только районы без вывода МО,
              // если районы не определены то вывести полигон всего МО
              ...(municipalities || [])
                .filter(
                  (municipality: IMunicipality) =>
                    !(districts || [])?.map((district: IDistrict) => district.municipalId).includes(municipality.id),
                )
                .map((municipality: IMunicipality) => municipality.polygonId),
            );
            districtsPolygons.push(...(districts || [])?.map((district: IDistrict) => district.polygonId));
          });
          return <Observable<IPolygonDto[]>>(
            this.polygonsService.getByIds([...municipalitiesPolygons, ...districtsPolygons])
          );
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((polygons: IPolygonDto[]) => {
        polygons.forEach((polygon: IPolygonDto, index: number) =>
          this.mapModel.addPolygonToLayer(
            municipalitiesPolygons.some((municipalityPolygon: string) => municipalityPolygon === polygon.id)
              ? LayersEnum.municipalities
              : LayersEnum.districts,
            `polygon_${index}`,
            polygon.coordinates.map((point: Coordinates) => point.toArray()),
            { color: '#FC5A5A', weight: 1 },
          ),
        );
      });
  }

  /**
   * Обработка клика по иконке
   */
  public onClick(): void {
    this.isActive = !this.isActive;
    if (this.isActive) {
      this.mapModel.viewLayer(LayersEnum.municipalities, false);
      this.mapModel.viewLayer(LayersEnum.districts, false);
    } else {
      this.mapModel.hideLayer(LayersEnum.municipalities);
      this.mapModel.hideLayer(LayersEnum.districts);
    }
  }
}
