import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { ISelectItem } from '@bg-front/core/models/interfaces';
import { IScInputOptions } from '@smart-city/core/common';
import { Observable } from 'rxjs';

import { ForecastingFiresService } from '../../services';

@Component({
  selector: 'bg-forecasting-extinguishing-agent-consumption-task',
  templateUrl: './forecasting-extinguishing-agent-consumption-task.component.html',
  styleUrls: ['./forecasting-extinguishing-agent-consumption-task.component.scss'],
})
export class ForecastingExtinguishingAgentConsumptionTaskComponent implements OnInit {
  /** Ссылка на форму */
  public form: FormGroup;

  /** id Инцидента */
  @Input()
  public emergencyId: string;

  /** Настройка компоненты "Объект пожара" */
  public fireObjects$: Observable<ISelectItem<string>[]> = this.forecastingService.getFireObjects();

  /** @ignore */
  constructor(public readonly controlContainer: ControlContainer, private readonly forecastingService: ForecastingFiresService) {}

  /** @ignore */
  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }
}
