<form nz-form [formGroup]="searchForm" nzLayout="vertical">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Поиск</nz-form-label>
        <nz-form-control>
          <input nzSize="large" nz-input formControlName="text" />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row *nzModalFooter>
    <div nz-col [nzSpan]="24">
      <button nz-button nzType="default" class="m-r" (click)="close()">Отмена</button>
      <button nz-button nzType="primary" [disabled]="!searchForm.valid" (click)="search()">
        Фильтровать
      </button>
    </div>
  </div>
</form>
