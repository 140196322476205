<div nz-form nzLayout="vertical">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item [nzGutter]="[8, 8]">
        <nz-form-control>
          <nz-select [(ngModel)]="newList" nzShowSearch [nzShowArrow]="true" nzSize="large" nzMode="multiple">
            <nz-option
              [nzValue]="videoDevicesList.id"
              [nzLabel]="videoDevicesList.name"
              *ngFor="let videoDevicesList of videoDevicesLists$ | async"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</div>
<div nz-row *nzModalFooter>
  <div nz-col [nzSpan]="24">
    <button nz-button nzType="default" (click)="close()">Отмена</button>
    <button nz-button nzType="primary" [disabled]="!newList.length" (click)="save()">Добавить</button>
  </div>
</div>
