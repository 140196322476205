import { Injectable } from '@angular/core';
import { BaseService, NotificationService, RestService } from '@smart-city/core/services';
import { ScConsole } from '@smart-city/core/utils';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, TVideoSource } from 'smart-city-types';
import { ONE_DAY, ONE_HOUR } from '@bg-front/core/models/constants';
import { IArchiveTask } from '../../models/interfaces/archive-task.interface';
import { IDownloadButton } from '../../models/interfaces';
import { photoStatuses, videoStatuses } from '../../models/maps';

/** Сервис для работы с кнопками скачать фото/видео */
@Injectable({
  providedIn: 'root',
})
export class DownloadSourcesService extends BaseService {
  /** Статусы для кнопки видео */
  public videoStatuses = videoStatuses;
  /** Статусы для кнопки фото */
  public photoStatuses = photoStatuses;
  /** Список активных тасок */
  private activeTask: string[] = [];
  /** стрим с данными о текущем задании */
  public downLoadTaskStream$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly noteService: NotificationService, private readonly rest: RestService) {
    super();
  }

  /** Метод отправляет экшин на создание архива
   * @param  entityId - id сущности
   * @param tag - тэг для сущности
   * @param params - источники для создания архива
   * @return
   */
  public createArchive(
    entityId: string,
    tag: string,
    params: { video?: TVideoSource[]; photo?: string[]; archivation?: boolean } = {},
    destinationFileName?: string
  ): Observable<IAbstractServiceData> {
    return this.rest
      .serviceRequest({
        action: 'createArchive',
        service: { name: 'Archive' },
        data: {
          entityId,
          tag,
          destinationFileName,
          ...(!!params.video ? { video: params.video } : { video: [] }),
          ...(!!params.photo ? { photo: params.photo } : { photo: [] }),
          ...(!!params.archivation ? { archivation: params.archivation } : {}),
          expirationTimestamp: moment().utcOffset(0, false).unix() * 1000 + ONE_DAY,
          timeout: ONE_HOUR / 2,
        },
      })
      .pipe(
        tap((result: IAbstractServiceData) => {
          const archiveTaskId = result.data && result.data.archiveTaskId;
          if (archiveTaskId) {
            this.activeTask.push(archiveTaskId);
            this.downLoadTaskStream$.next(true);
          }
        }),
      );
  }

  /**
   * Получить задания по архивированию
   * @return
   * @param query
   */
  public getArchiveTask(query: IAnyObject): Observable<IArchiveTask[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Archive' },
        entity: {
          query,
          name: 'ArchiveTasks',
          linksMode: 'raw',
        },
      })
      .pipe(
        catchError((err: Error) => {
          this.noteService.pushError('Ошибка при загрузке заданий');
          ScConsole.error(err.message);
          return of(null);
        }),
        map((result: IAbstractServiceData) => {
          const items = result.data.items;
          if (items.length) {
            return items;
          }
          return null;
        }),
      );
  }

  /**
   * Создать видео архив
   * @param entityId - id сущности
   * @param tag
   * @param video - данные для видео архива
   * @param archivation
   * @return
   */
  public createVideoArchive(
    entityId: string,
    tag: string,
    video: TVideoSource[],
    archivation: boolean,
    fileName: string,
  ): Observable<IAbstractServiceData> {
    return this.createArchive(entityId, tag, { video, ...(archivation ? { archivation } : {}) }, fileName);
  }

  /**
   * Создать видео архив
   * @param entityId - id сущности
   * @param tag
   * @param photo - данные для фото архива
   * @param archivation - флаг нужно ли создавать архив
   * @return
   */
  public createPhotoArchive(
    entityId: string,
    tag: string,
    photo: string[],
    archivation?: boolean,
  ): Observable<IAbstractServiceData> {
    return this.createArchive(entityId, tag, { photo, ...(archivation ? { archivation } : {}) });
  }

  /**
   * Данные по статусу для кнопки Скачать видео по ключу
   * @param key - ключ для статуса
   * @return
   */
  public getVideoStatus(key: string): IDownloadButton {
    return this.videoStatuses.get(key);
  }

  /**
   * Данные по статусу для кнопки Скачать фото по ключу
   * @param key - ключ для статуса
   * @return
   */
  public getPhotoStatus(key: string): IDownloadButton {
    return this.photoStatuses.get(key);
  }

  /**
   * Получить список активных заданий
   * @return
   */
  public getTaskList(): string[] {
    return this.activeTask;
  }

  /**
   * Получить список активных заданий
   * @param uuid - id задания
   */
  public addTaskToList(uuid: string): void {
    this.activeTask.push(uuid);
  }

  /**
   * Получить список активных заданий
   * @param list - изменить список заданий
   */
  public setTaskList(list: string[]): void {
    this.activeTask = list;
  }
}
