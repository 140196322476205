import { Injectable } from '@angular/core';
import {
  BaseService,
  NotificationService,
  RestService,
  Settings2Service,
  SubscriberService,
} from '@smart-city/core/services';
import { ScConsole } from '@smart-city/core/utils';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  IAbstractServiceData,
  IAnyObject,
  IEmergencyDto,
  IInformationStatementDto,
} from 'smart-city-types';
import { dataStepConvertor } from '../../heplers';
import { ICreateEmergencyStepsParams, IEmergencyResponsePlanStep } from '../../models/interfaces';
import { LifeCycleService } from '../life-cycle/life-cycle.service';

@Injectable({
  providedIn: 'root',
})
export class ResponsePlanStepService extends BaseService {
  public stepListSub$ = new Subject<{ list?: IEmergencyResponsePlanStep[]; action?: string; entity?: string }>();

  private selectAttributes = [
    'id',
    'number',
    'description',
    'shortDescription',
    'timeReact',
    'responseTime',
    'serviceType.id',
    'serviceType.shortName',
    'serviceType.name',
    'serviceType.organizationTypeId',
    'status',
    'attractOrgData.id',
    'attractOrgData.name',
    'attractOrgStatus',
    'operatorComment',
    'startTimeStep',
    'finishTimeStep',
    'emergencyId',
    'themeId',
    'informerServiceType',
    'sfsId',
    'stepType',
    'reportId',
    'orderId',
  ];

  constructor(
    private readonly rest: RestService,
    private readonly noteService: NotificationService,
    private readonly settings2: Settings2Service,
    private readonly lifeCycleService: LifeCycleService,
    private readonly subs: SubscriberService,
  ) {
    super();
  }

  /**
   * Метод получения сценария шагов реагирования
   * @param query - фильтр для запроса на получения сценария шагов реагирования
   * @return
   */
  public getResponseScriptsSteps(query: IAnyObject): Observable<IEmergencyResponsePlanStep[]> {
    return this.rest
    .serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        query,
        name: 'ResponseScriptSteps',
      },
    })
    .pipe(
      map((result: IAbstractServiceData) => {
        const items = result.data.items;
        if (!!items.length) {
          return items;
        }
        return [];
      }),
    );
  }

  public editResponseScriptStep(data: IEmergencyResponsePlanStep, action: string): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      data,
      action,
      service: { name: 'Admin' },
      entity: {
        ...(action === 'insert' ? {} : { query: { id: data?.id } }),
        name: 'ResponseScriptSteps',
      },
    });
  }

  /**
   * Получение шагов реагирования по текущему инциденту
   * @param id - ID происшествия
   * @param parentId - ID родительского происшествия
   */
  public getStepsByEmergencyId(id: string, parentId: string): Observable<IEmergencyResponsePlanStep[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Emergency' },
      entity: {
        attributes: this.selectAttributes,
        name: 'ResponsePlanSteps',
        query: {
          $or: [
            {
              // Условие фильтрует шаги "Привлекаемые службы"
              serviceType: { id: { $ne: null } },
              // В случае наличия у происшествия parentId (поручение) в список будет выведены шаги его родительского происшествия
              emergencyId: { $in: [id, parentId].filter(Boolean) },
              // Не выводить шаг родительского происшествия который является текущим происшествием
              orderId: { $ne: id },
            },
            {
              // Условие фильтрует шаги "Реагирование"
              serviceType: { id: { $eq: null } },
              emergencyId: id,
            }
          ],
        },
      },
    }).pipe(map((result: IAbstractServiceData) => result.data.items || []));
  }

  /**
   * TODO: унести создание на бэк, т.к. есть поля с датами
   * Создание шагов в таблице ResponsePlanSteps
   * @param data - шаги реагирования для происшествия
   * @param entity
   * @return
   */
  public createSteps(
    data: IEmergencyResponsePlanStep[],
    entity?: 'responseOrgOpts' | 'involvedOrgOpts',
  ): Observable<IEmergencyResponsePlanStep[]> {
    // Если время реагирования для шага не указано, то взять настройку из конфигурации
    data.forEach((item: IEmergencyResponsePlanStep, index: number) =>
      data[index].timeReact = item.timeReact || this.settings2.getSettings().timeReact
    );
    return this.rest
    .serviceRequest({
      data,
      action: 'insert',
      service: { name: 'Emergency' },
      entity: {
        name: 'ResponsePlanSteps',
      },
    })
    .pipe(
      switchMap((result: IAbstractServiceData) => {
        const ids = result.data.ids;
        if (ids.length) {
          return this.rest
          .serviceRequest({
            action: 'select',
            service: { name: 'Emergency' },
            entity: {
              attributes: this.selectAttributes,
              name: 'ResponsePlanSteps',
              query: {
                id: ids[0],
              },
            },
          })
          .pipe(
            map((result: IAbstractServiceData) => {
              const items = result.data.items;
              if (items.length) {
                return items;
              }
              return [];
            }),
          );
        }
        return of(null);
      }),
      tap((createdData: IEmergencyResponsePlanStep[]) => {
        if (entity && createdData && createdData.length) {
          this.stepListSub$.next({ entity, list: [{ ...createdData[0] }], action: 'add' });
        }
      }),
    );
  }

  /**
   * TODO: Унести на бэк, так как обновляется дата.
   * Обновление шагов в таблице ResponsePlanSteps
   * @param emergencyId - id инцидента
   * @param entity -  сущность
   * @return
   */
  public updateStepsTimeFinish(
    emergencyId,
    entity?: 'responseOrgOpts' | 'involvedOrgOpts',
  ): Observable<IEmergencyResponsePlanStep[]> {
    if (!emergencyId) {
      return of([]);
    }
    const finishTimeStep = new Date().getTime();
    return this.rest
    .serviceRequest({
      data: {
        finishTimeStep,
      },
      action: 'update',
      service: { name: 'Emergency' },
      entity: {
        name: 'ResponsePlanSteps',
        query: {
          emergencyId,
          $not: { startTimeStep: null },
        },
      },
    })
    .pipe(
      switchMap(({ data: { items } }: IAbstractServiceData) => {
        const ids = items.map((item) => item.id);
        if (ids.length) {
          return this.rest
          .serviceRequest({
            action: 'select',
            service: { name: 'Emergency' },
            entity: {
              attributes: this.selectAttributes,
              name: 'ResponsePlanSteps',
              query: {
                id: { $in: ids },
              },
            },
          })
          .pipe(
            map(({ data: { items } }: IAbstractServiceData) => {
              return items;
            }),
          );
        }
        return of(null);
      }),
      tap((updateData: IEmergencyResponsePlanStep[]) => {
        if (entity && updateData && updateData.length) {
          this.stepListSub$.next({ entity, list: updateData, action: 'list' });
        }
      }),
    );
  }

  /**
   * TODO: унести создание на бэк, т.к. есть поля с датами
   * Обновление конкретного шага реагирования для текущего инцидента
   * @param id - id шага
   * @param stepData - данные обновляемого шага
   * @param entity
   * @return
   */
  public updateStepItemById(
    id: string,
    stepData: IEmergencyResponsePlanStep,
    entity: 'responseOrgOpts' | 'involvedOrgOpts',
  ): Observable<IEmergencyResponsePlanStep[]> {
    // Если время реагирования для шага не указано, то взять настройку из конфигурации
    stepData.timeReact = stepData.timeReact || this.settings2.getSettings().timeReact;
    return this.rest
    .serviceRequest({
      action: 'update',
      service: { name: 'Emergency' },
      entity: {
        name: 'ResponsePlanSteps',
        query: {
          id,
        },
      },
      data: stepData,
    })
    .pipe(
      /// TODO: Зарефакторить или вынести отсюда
      catchError((err: Error) => {
        this.noteService.pushError('Ошибка при обновлении шагов реагирования происшествия');
        ScConsole.error([err.message]);
        return of(undefined);
      }),
      switchMap((result: IAbstractServiceData) => {
        if (!!result.data.count) {
          return this.rest
          .serviceRequest({
            action: 'select',
            service: { name: 'Emergency' },
            entity: {
              attributes: this.selectAttributes,
              name: 'ResponsePlanSteps',
              query: {
                id,
              },
            },
          })
          .pipe(
            map((result: IAbstractServiceData) => {
              const items = result.data.items;
              if (items.length) {
                return items;
              }
              return [];
            }),
          );
        }
        return of(null);
      }),
      tap((updatedData: IEmergencyResponsePlanStep[]) => {
        if (updatedData && updatedData.length) {
          this.stepListSub$.next({ entity, list: [{ id, ...updatedData[0] }], action: 'update' });
        }
      }),
    );
  }

  /**
   * Метод получения сценария шагов реагирования
   * @param query - фильтр для запроса на получения сценария шагов реагирования
   * @return
   */
  public selectResponseScriptsSteps(query: IAnyObject): Observable<IEmergencyResponsePlanStep[]> {
    return this.rest
    .serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        query,
        name: 'ResponseScriptSteps',
        attributes: [
          'id',
          'number',
          'description',
          'stepType',
          'timeReact',
          'responseScriptId',
          'shortDescription',
          'responseServiceType.id',
          'responseServiceType.organizationTypeId',
          'responseServiceType.informationInteraction',
        ],
      },
    })
    .pipe(
      map((result: IAbstractServiceData) => {
        const items = result.data.items;
        if (!!items.length) {
          return items;
        }
        return [];
      }),
    );
  }

  /** Метод устанавливает количество шагов для конкретног облока
   * @param entity - имя сущности
   * @param count - количество
   * @return
   */
  public setListCount(entity: string, count: number): void {
    if (entity) {
      const listName = `${entity}Count`;
      this[listName] = count;
    }
  }

  /** Метод получает количество шагов для конкретног облока
   * @param entity - имя сущности
   * @return
   */
  public getListCount(entity: string): number {
    if (entity) {
      const listName = `${entity}Count`;
      return this[listName];
    }
  }

  /**
   * Метод который преобразовывает список шагов в необходимый вид для создания записей в бд
   * @param steps - список полученных шагов
   * @param emergencyId
   * @return
   */
  public mapStepsForCreation(steps: IEmergencyResponsePlanStep[], emergencyId: string): IEmergencyResponsePlanStep[] {
    if (steps.length) {
      const responseStepStatus = this.settings2.getDictObjByTypeSysName('responseStepStatus');
      const inProgressStepStatus = responseStepStatus.inProgress.id;
      const doneStepStatus = responseStepStatus.done.id;
      const extendsParams = {
        emergencyId,
        status: inProgressStepStatus,
        attractOrgStatus: null,
        operatorComment: '',
      };
      const scriptSteps = dataStepConvertor(steps, extendsParams);
      const stepsDataSet: IEmergencyResponsePlanStep[] = [...scriptSteps];
      const result = [];
      const notifStatus = this.settings2.getDictObjByTypeSysName('statusNotificationAttractOrg');
      const commonSteps = new Set([...stepsDataSet]);
      [...commonSteps].forEach((stepItem: any) => {
        const step = {
          ...stepItem,
          ...(stepItem.informationInteraction ? { attractOrgData: stepItem.attractOrgData } : { attractOrgData: null }),
          status: stepItem?.informationInteraction ? doneStepStatus : inProgressStepStatus,
          attractOrgStatus: stepItem?.informationInteraction ? notifStatus?.done?.id : notifStatus?.inProgress.id,
        };
        result.push(step);
      });
      return result;
    }
    return [];
  }

  /**
   * Метод вызывает экшин создания шагов для плана реагирования
   * @param data - параметры вызова экшина
   */
  public createResponseScriptSteps(data: ICreateEmergencyStepsParams): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      data,
      action: 'createEmergencySteps',
      service: { name: 'Emergency' },
      entity: {
        name: 'Emergency',
      },
    });
  }

  public getOrgListOfExistedOrders(parentId, orderDocType: string): Observable<IEmergencyDto[]> {
    return this.rest
    .serviceRequest({
      action: 'select',
      service: { name: 'Emergency' },
      entity: {
        query: {
          parentId,
          docType: orderDocType,
        },
        name: 'Emergency',
        attributes: ['id', 'organization.id', 'organization.orgTypeParam'],
      },
    })
    .pipe(
      map((result: IAbstractServiceData) => {
        return result.data.items.length ? result.data.items : [];
      }),
    );
  }

  /**
   * Получаю существующие шаги реагирования у инцидента и возвращаю id типа всех привлекаемых служб
   * @param emergencyId - id инцидента
   * @return
   */
  public getExistedPlanSteps(emergencyId: string): Observable<string[]> {
    return this.rest
    .serviceRequest({
      action: 'select',
      service: { name: 'Emergency' },
      entity: {
        query: {
          emergencyId,
        },
        name: 'ResponsePlanSteps',
        attributes: ['id', 'serviceType.id'],
      },
    })
    .pipe(
      map(({ data: { items } }: IAbstractServiceData) => {
        return items
        .map((item) => {
          return item?.serviceType?.id;
        })
        .filter((item) => {
          return item;
        });
      }),
    );
  }

  /**
   * Метод возвращает поручение относящее к конкретному инциденту
   * @param orderId
   * @return
   */
  public getOrderStatusByEmergencyOrg(orderId: string): Observable<IEmergencyDto[]> {
    return this.rest
    .serviceRequest({
      action: 'select',
      service: { name: 'Emergency' },
      entity: {
        query: {
          id: orderId,
        },
        name: 'Emergency',
        attributes: ['id', 'organization', 'lifeCycleStepId.status.sysname', 'lifeCycleStepId.status.name'],
      },
    })
    .pipe(
      map((result: IAbstractServiceData) => {
        return !!result.data.items.length
          ? result.data.items.map((item: IEmergencyDto) => ({
            id: item.id,
            organization: item.organization,
            lifeCycleStepId: {
              status: {
                sysname: item['lifeCycleStepId.status.sysname'],
                name: item['lifeCycleStepId.status.name'],
              },
            },
          }))
          : [];
      }),
    );
  }

  /**
   * Получение списка тем донесений
   * @param id - донесения
   * @return
   */
  public getInformationThemeById(id: string): Observable<{ id: string; name: string }> {
    return this.rest
    .serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        attributes: ['id', 'name'],
        name: 'InformationStatementThemes',
        query: {
          id,
        },
      },
    })
    .pipe(map(({ data }: IAbstractServiceData) => (data.items || [])[0]));
  }

  /**
   * Получение информации о донесении
   * @param id - донесения
   * @return
   */
  public getInformationStatementData(id: string): Observable<IInformationStatementDto> {
    return this.rest
    .serviceRequest({
      action: 'getInformationStatementById',
      service: { name: 'Edi' },
      data: { id },
    })
    .pipe(map((result) => result.data));
  }

  /** Получение времени реагирования из уже существующих шагов с таким же типом организации
   * @param emergencyId - id инцидента
   * @param serviceType - Тип службы реагирования orgTypeParams
   * */
  public getLastEmergencyStepForService(
    emergencyId: string,
    serviceType: string,
  ): Observable<IEmergencyResponsePlanStep> {
    return this.rest
    .serviceRequest({
      action: 'select',
      service: { name: 'Emergency' },
      entity: {
        attributes: ['timeReact', 'creationTime'],
        name: 'ResponsePlanSteps',
        query: {
          emergencyId,
          serviceType,
          timeReact: { $ne: null }
        },
        sort: {
          field: 'creationTime',
          direction: 'desc',
        },
      },
    })
    .pipe(map(({ data }: IAbstractServiceData) => (data.items || [])[0]));
  }
}
