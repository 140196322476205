import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IFileOptions,
  IScButtonOptions,
  IScInputOptions,
  IScSelectOptions,
  IScTextareaOptions,
} from '@smart-city/core/common';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { Settings2Service, SfsService } from '@smart-city/core/services';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { IAnyObject, IUploadMetadataOutDTO } from 'smart-city-types';
import { IEmergencyDto, IInformationStatementDto } from 'smart-city-types/interfaces';
import { ILifeCycleStepDto } from 'smart-city-types/interfaces/life-cycle-step-dto.interface';
import { BaseComponent } from '@bg-front/core/components';
import { InformationStatementService } from '../../../bg/modules/consolidated-registries/modules/information-statements/services';
import { IEmergencyResponsePlanStep, IStepDialogDataOptions } from '../../models/interfaces';
import { EmergencyService, LifeCycleService, OrganizationsService, ResponsePlanStepService } from '../../services';
import { CallService } from '../../../call/services';
import { IInformationStatementData } from '../../../bg/modules/consolidated-registries/models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/** Компонент для создания шага реагирования если тип шага выбран Донесение */
@UntilDestroy()
@Component({
  selector: 'bg-inform-response-org-form',
  templateUrl: './inform-response-org-form.component.html',
  styleUrls: ['./inform-response-org-form.component.scss'],
})
export class InformResponseOrgFormComponent extends BaseComponent implements OnInit {
  /** Форма */
  public form: FormGroup;

  /** EventEmitter для закрытия */
  @Output()
  public closeEvent = new EventEmitter<IAnyObject>(true);
  /** EventEmitter по нажатию на кнопку сохранить */
  @Output()
  public saveEvent = new EventEmitter<IEmergencyResponsePlanStep[]>(true);

  @Input() options: IStepDialogDataOptions;

  public formControl: FormGroup;
  /** id файла */
  private sfsId: string;

  /** Настройки для отображения Наименование службы */
  public attractOrgDataOptions: IScSelectOptions = {
    title: 'Наименование службы *',
    clearable: true,
    service: 'Admin',
    entity: 'Organizations',
    modern: true,
    fieldName: 'name',
    width: '100%',
    query: {
      active: true,
    },
  };

  /** Настройка компоненты Тема донесения */
  public themeIdOptions: IScSelectOptions = {
    title: 'Тема донесения',
    clearable: true,
    required: true,
    service: 'Admin',
    entity: 'InformationStatementThemes',
    modern: true,
    fieldName: 'name',
    query: { active: true },
  };

  /** Тип службы получателя донесения */
  public typeServiceInformer: IScSelectOptions = {
    name: 'informerServiceType',
    title: 'Тип службы получателя донесения *',
    clearable: true,
    service: 'Admin',
    entity: 'OrgTypeParams',
    modern: true,
    fieldName: 'shortName',
    query: {
      isInformer: true,
    },
  };

  /** Настройки для отображения Состояние оповещениея привлекаемых служб */
  public attractOrgStatusOptions: IScSelectOptions = {
    title: 'Состояние оповещения привлекаемых служб',
    data: this.settings.getDictForSelect('statusNotificationAttractOrg'),
    fieldName: 'text',
    modern: true,
    clearable: true,
    width: '100%',
  };

  /** Настройки для отображения Описания */
  public optionsDescription: IScTextareaOptions = {
    label: 'Описание',
    name: 'description',
    width: '100%',
    rows: 5,
  };
  /** Настройки для отображения Контактный телефон */
  public phone1Options: IScInputOptions = {
    label: 'Контактный телефон',
    name: 'phone1',
    placeholder: 'Контактный телефон',
    validators: [{ pattern: '\\+?[0-9\\s\\-]*[0-9]+[0-9\\s\\-]*' }],
    disabled: true,
  };

  /** Настройка для отображения: Регламентное время выполнения (мин) */
  public timeReactOptions: IScInputOptions = {
    label: 'Регламентное время выполнения (мин) *',
    hidden: true,
  };

  /** Настройки для отображения Состояние шага реагирования */
  public statusStepOptions: IScSelectOptions = {
    title: 'Состояние шага реагирования',
    name: 'status',
    modern: true,
    fieldName: 'text',
    clearable: true,
    data: this.settings.getDictionaryByTypeSysName('responseStepStatus').map((item) => {
      return {
        id: item.id,
        text: item.name,
      };
    }),
  };

  /** Настройка компоненты файл */
  public fileOptions: IFileOptions = {
    title: 'Иконка',
    name: 'sfsId',
    isShowOptions: false,
    required: true,
  };

  /** Настройки для отображения кнопки сохранить */
  public saveBtnOptions: IScButtonOptions = {
    title: 'Отправить',
    color: 'primary',
  };
  /** Настройки для отображения кнопки отмена */
  public cancelBtnOptions: IScButtonOptions = {
    title: 'Отменить',
  };
  /** Тип шага */
  private stepType: string = null;
  /** Контактный телефон для исходящего вызова */
  public callPhone: string;

  constructor(
    private settings: Settings2Service,
    private readonly emergencyService: EmergencyService,
    public readonly responsePlanStepService: ResponsePlanStepService,
    private readonly orgService: OrganizationsService,
    private readonly callService: CallService,
    private informationStatementService: InformationStatementService,
    private lifeCycleService: LifeCycleService,
    private sfs: SfsService,
    @Inject(MAT_DIALOG_DATA) public data: IStepDialogDataOptions,
    public readonly dialogRef: MatDialogRef<IAnyObject>,
  ) {
    super();
  }


  public ngOnInit(): void {
    const data = this.options || this.data;
    if (data.informationStatementActionSysName === 'request') {
      this.typeServiceInformer.title = 'Тип службы исполнителя донесения *';
    }
    this.stepType = data?.stepType;

    if (data?.saveBtnOptions) {
      this.saveBtnOptions = {
        ...this.options?.saveBtnOptions,
      };
    }
    let attractOrgDataId = null;
    let mainPhone = null;
    const serviceType = data?.serviceType;
    if (data?.step) {
      attractOrgDataId = data?.step['attractOrgData.id'];
      mainPhone = data?.step['attractOrgData.phone1'];
      this.callPhone = mainPhone;
      this.timeReactOptions.hidden = false;
      this.optionsDescription.rows = 9;
    }
    this.formControl = new FormGroup({
      themeId: new FormControl(data.step?.themeId, [Validators.required]),
      attractOrgData: new FormControl(attractOrgDataId, [Validators.required]),
      informerServiceType: new FormControl(data?.step?.informerServiceType, [Validators.required]),
      description: new FormControl(data?.step?.description),
      phone1: new FormControl(mainPhone),
      timeReact: new FormControl(data?.step?.timeReact, !!data?.step ? [Validators.required] : []),
      sfsId: new FormControl(data?.step?.sfsId),
      files: new FormControl([]),
    });
    this.formControl.controls['attractOrgData'].disable();
    if (serviceType) {
      this.attractOrgDataOptions.query = {
        ...this.attractOrgDataOptions.query,
        organizationType: serviceType,
      };
    }
    this.formControl.controls['attractOrgData'].valueChanges
      .pipe(
        switchMap((result) => {
          return this.orgService.getOrganizationBy(result);
        }),
        untilDestroyed(this),
      )
      .subscribe((result) => {
        this.formControl.controls['phone1'].setValue(result?.phone1);
      });
    this.formControl.controls['informerServiceType'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (!result) {
          this.formControl.controls['attractOrgData'].disable();
          return;
        }
        this.attractOrgDataOptions.query = {
          orgTypeParam: result,
        };
        this.formControl.controls['attractOrgData'].enable();
        this.formControl.controls['attractOrgData'].setErrors(null);
      });
  }

  public onClickPhone(phone): void {
    if (!phone) return;
    this.callService.call(phone);
  }

  /**
   * Обработчик по нажатию на кнопку сохранить
   */
  public saveHandler() {
    const data = this.options || this.data;
    // Контрол attractOrgData может находиться в состоянии disabled. При активации контрола после нажатия на кнопку
    // отправить он сразу маркируется как невалидный - так быть не должно. Поэтому вместе с активацией ему сбрасываются
    // все ошибки. Если сразу нажать на кнопку Отправить, то у него не будет ошибок,
    // поэтому надо принудительно его валидировать.
    this.formControl.controls['attractOrgData'].updateValueAndValidity();
    if (this.formControl.valid) {
      const considerationStatus = this.settings.getDictionaryByTypeAndSysName('responseStepStatus', 'consideration');
      const params = {
        reportId: null,
        status: considerationStatus?.id,
        description: this.formControl.controls['description'].value || '',
        attractOrgData: this.formControl.controls['attractOrgData'].value || null,
        themeId: this.formControl.controls['themeId'].value || null,
        informerServiceType: this.formControl.controls['informerServiceType'].value || null,
        timeReact: this.formControl.controls['timeReact'].value || null,
        sfsId: this.sfsId || null,
        // Наличие informationStatementActionSysName указывает что шаг создается вручную,
        // по нажатию на кнопку + в блоке реагирования.
        // Такие шаги должны отображаться выше шагов созданных из сценария реагирования - поэтому number: 0
        number: data.informationStatementActionSysName
          ? 0
          : this.responsePlanStepService.getListCount('responseOrgOpts') + 1,
        finishTimeStep: data.informationStatementActionSysName === 'request'
          ? null
          : Date.now(),
        stepType: this.stepType,
        // По умолчанию считать что донесение создается с типом response
        informationStatementActionId: this.settings.getDictionaryByTypeAndSysName(
          'informationStatementActions',
          data.informationStatementActionSysName || 'response',
        )?.id,
      };

      if (this.formControl.controls['files'].value?.length > 0) {
        this.formControl.disable();
        this.saveBtnOptions.disabled = true;
        this.formControl.controls['attractOrgData'].disable();
        this.noteService.pushInfo('Идёт процесс загрузки документа...');
        this.formControl.controls['files'].value[0].fileTags = 'doc, informResponse';
        return this.sfs
          .upload(this.formControl.controls['files'].value[0])
          .pipe(
            catchError((error: Error) => this.catchErrorFn<IUploadMetadataOutDTO>(error, 'Ошибка скачивания')),
            tap((file: IUploadMetadataOutDTO) => {
              params.sfsId = file?.uuid || null;
            }),
            mergeMap(() => {
              return this.createInformerForOrganization(params).pipe(
                catchError((error: Error) => this.catchErrorFn(error, 'Ошибка формирования поручения')),
                mergeMap((result: IInformationStatementDto) => {
                  params.reportId = result?.id;
                  return this.editStep(params);
                }),
              );
            }),
            untilDestroyed(this),
          )
          .subscribe();
      }
      return this.createInformerForOrganization(params).pipe(
        catchError((error: Error) => this.catchErrorFn(error, 'Ошибка формирования поручения')),
        mergeMap((result: IInformationStatementDto) => {
          params.reportId = result?.id;
          return this.editStep(params);
        }),
        untilDestroyed(this),
      )
      .subscribe();
    }
  }

  /** Метод редактирования записи
   * @param params
   * @return
   */
  public editStep(params): Observable<IEmergencyResponsePlanStep[]> {
    if ((this.options || this.data)?.step?.id) {
      return this.updateStep(params).pipe(
        untilDestroyed(this),
        tap(() => this.noteService.pushInfo('Запись успешно обновлена')),
      );
    }
    return this.createSteps(params);
  }

  /** Обработчик по нажатию на кнопку сохранить
   * @param result - список шагов
   * @return
   */
  public updateStep(result: IEmergencyResponsePlanStep): Observable<IEmergencyResponsePlanStep[]> {
    return this.responsePlanStepService
      .updateStepItemById((this.options || this.data)?.step.id, { ...result }, (this.options || this.data)?.entity)
      .pipe(
        catchError((error: Error) =>
          this.catchErrorFn<IEmergencyResponsePlanStep[]>(error, 'Ошибка получения сценария реагирования происшествия'),
        ),
        tap(() => this.saveEvent.emit()),
        tap(() => this.closeHandler()),
      );
  }

  /**
   * Метод создания шага для служб без информационного взаимодействия
   * @result - данные шага
   * @return
   */
  public createSteps(result: IEmergencyResponsePlanStep): Observable<IEmergencyResponsePlanStep[]> {
    const data = this.options || this.data;
    const step = {
      ...result,
      serviceType: null,
      stepType: data.stepType,
      emergencyId: data.emergencyModel.id,
    };

    // Поиск происшествия связанного с текущим происшествием. Ответственная организация искомого происшествия
    // должна совпадать с той которая выбрана на форме создания донесения.
    return this.emergencyService.getIncidentByParentIdAndOrganization(
      (<IEmergencyDto>data.emergencyModel.parentId)?.id
      || <string>data.emergencyModel.parentId
      || data.emergencyModel.id,
      this.formControl.controls.attractOrgData.value,
    ).pipe(
      // В случае если найдено происшествие надо создать "зеркальное" донесение с противоположным типом работы
      // с донесением
      map((id: string) => id
        ? [
          step,
          {
            ...step,
            emergencyId: id,
            informationStatementActionId: this.settings.getDictionaryByTypeAndSysName(
              'informationStatementActions',
              data.informationStatementActionSysName === 'request' ? 'response' : 'request',
            )?.id
          },
        ]
        : [step],
      ),
      switchMap((steps: IEmergencyResponsePlanStep[]) =>
        this.responsePlanStepService.createSteps(steps, 'responseOrgOpts'),
      ),
      tap(() => this.saveEvent.emit([step])),
      untilDestroyed(this),
    );
  }

  /** Метод создания Донесения на основе родительского инцидента
   * @return
   */
  public createInformerForOrganization(params): Observable<IInformationStatementDto> {
    const data = this.options || this.data;
    const { id, ...emergencyModel } = data.emergencyModel;
    const inform: IInformationStatementData = Object.assign({});

    const type = this.settings
      .getDictionaryByTypeSysName('lifeCycleType')
      .find((item: IDictionaryInfo) => item.sysname === 'informationStatement')?.id;

    inform.themeId = params.themeId;
    inform.id = null;
    inform.parentId = id;
    inform.typeId = this.settings.getDictionaryByTypeAndSysName(
      'informationStatementTypes',
      data.informationStatementActionSysName === 'request' ? 'requestIncidentInformation' : 'incidentInformation',
    )?.id;
    inform.number = `is-${moment(emergencyModel.timeCreate).format('YYYYMMDD-HHmmss')}`;
    inform.description = params.description;
    inform.creationAuthor = this.settings.currentUser.id;

    const lifeCycle = this.lifeCycleService.getLifeCycleByParams(undefined, undefined, undefined, type);
    const lifeStep = lifeCycle.steps.find((step: ILifeCycleStepDto) =>
      step.name === (data.informationStatementActionSysName === 'request' ? 'К выполнению' : 'Отправлено')
    );
    inform.lifeCycleStepId = (lifeStep as ILifeCycleStepDto)?.id;

    if (data.informationStatementActionSysName === 'request') {
      inform.recipientOrganizationId = this.settings.currentUser.organizationId.id;
      inform.recipientId = this.settings.currentUser.id;
      inform.executorOrganizationId = params.attractOrgData;
    } else {
      inform.executorId = this.settings.currentUser.organizationId.id;
      inform.executionTime = Date.now();
      inform.recipientOrganizationId = params.attractOrgData;
      inform.executorOrganizationId = this.settings.currentUser.organizationId.id;
    }

    if (params?.sfsId) {
      inform.documents = [
        {
          file: params.sfsId,
          userId: this.settings.currentUser.id,
        },
      ];
    }
    let resultInformationStatement: IInformationStatementDto;
    return this.informationStatementService.createInformationStatement(inform).pipe(
      mergeMap((res: any) => {
        resultInformationStatement = res;
        // Если было создано донесение, то поставить происшествие на контроль
        if (res.id && !emergencyModel.supervisedByCuks) return this.emergencyService.setSupervision(id, true);
        return of(null);
      }),
      map(() => resultInformationStatement),
    );
  }

  public closeHandler() {
    this.dialogRef.close();
  }
}
