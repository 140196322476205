import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';
import { BgMapService, ForecastingResultsQuery, ForecastingResultsService } from '@bg-front/core/services';
import { IElementButton, INwHeaderBarOptions } from '@smart-city/core/common';
import { ScNavService, Settings2Service } from '@smart-city/core/services';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { IAbstractServiceData } from 'smart-city-types';

/**
 * Компонента отображающая данные о важном объекте
 */
@Component({
  selector: 'bg-forecasting-results',
  templateUrl: './forecasting-results.component.html',
  styleUrls: ['./forecasting-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastingResultsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public isShow = false;

  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions;

  public countTaskSysName: string;
  public forecastingResults: IForecastingResultDto;

  /** Можно ли сохранить результат прогнозирования */
  public canSaveResult: boolean = false;

  private downloadReport = new Subject<void>();
  public downloadReport$ = this.downloadReport.asObservable();

  public hasReport = false;

  /** Отчёт скачивается */
  public isDownload = false;

  // /** @ignore */
  constructor(
    private readonly gisBgService: BgMapService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly scNavService: ScNavService,
    private readonly forecastingQueryService: ForecastingResultsQuery,
    private readonly forecastingService: ForecastingResultsService,
    private readonly settings: Settings2Service,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
    this.forecastingResults = this.forecastingQueryService.getActive() as IForecastingResultDto;
    if (!this.forecastingResults) {
      this.router.navigate(
        [
          {
            outlets: {
              leftPopup: null,
            },
          },
        ],
        {
          relativeTo: this.route.parent,
          queryParamsHandling: 'merge',
        },
      );
    }
  }

  /** @ignore */
  public ngOnInit(): void {
    this.forecastingResults = this.forecastingQueryService.getActive() as IForecastingResultDto;

    this.gisBgService.setPositionMapOnCoordinates(this.forecastingResults.params.coordinates);

    this.canSaveResult = !!this.forecastingResults.params.emergencyId;

    this.countTaskSysName = this.settings.getDictionaryById(this.forecastingResults.counterTaskId).sysname;

    this.headerOptions = {
      margin: 'collapse',
      title: ' ',
      buttons: [
        {
          type: 'button',
          options: {
            name: 'burger',
            icon: 'menu',
            callback: () => {
              this.scNavService.openMenu();
              return of({ actionStream: 'donothing' });
            },
          },
        },
        {
          type: 'button',
          options: {
            name: 'close',
            icon: 'close',
            position: 'suffix',
            callback: () => {
              if (this.route.params['value'].id && this.countTaskSysName === 'forestFireConsequenceRating') {
                this.router.navigate(
                  [
                    {
                      outlets: {
                        leftPopup: ['forestryFacility', this.route.params['value'].id],
                      },
                    },
                  ],
                  {
                    relativeTo: this.route.parent,
                    queryParamsHandling: 'merge',
                  },
                );
              } else {
                this.router.navigate([{ outlets: { leftPopup: null } }], {
                  relativeTo: this.route.parent,
                  queryParamsHandling: 'merge',
                });
              }
              return of();
            },
          },
        },
      ],
    };

    this.cdr.markForCheck();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * Обработка событий из заголовка
   * @param $event событие
   */
  public clickHeaderButton($event: IElementButton): void {
    ($event.options.callback as any)();
  }

  /** Сохранение результата */
  public saveResult() {
    this.canSaveResult = false;
    this.forecastingService
      .saveForecasting(this.forecastingResults)
      .pipe(
        catchError((err: Error) =>
          this.catchErrorFn<IAbstractServiceData>(err, 'Не удалось сохранить результаты расчёта'),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((res: IAbstractServiceData) => {
        if (res) {
          this.noteService.pushSuccess('Расчёт успешно сохранён');
          this.canSaveResult = false;
        } else {
          this.canSaveResult = true;
        }

        this.cdr.markForCheck();
      });

    this.cdr.markForCheck();
  }

  /** Скачивание */
  public report() {
    this.downloadReport.next();
  }

  /** Обработка события */
  public onHasReportEvent(event: boolean): void {
    this.hasReport = event;
    this.cdr.detectChanges();
  }

  /** Обработка события */
  public onDownloadReportEvent(event: boolean): void {
    this.isDownload = event;
    this.cdr.detectChanges();
  }
}
