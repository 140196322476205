import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  IScInputOptions,
  IScSelectLoadParams,
  IScSelectOptions,
} from '@smart-city/core/common';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDds01FireParamsDto, ILifeCycleStepParamDto, IReasonDto } from 'smart-city-types';

@Component({
  selector: 'bg-dds01-fire-parameters',
  templateUrl: './dds01-fire-parameters.component.html',
  styleUrls: ['./dds01-fire-parameters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Dds01FireParametersComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => Dds01FireParametersComponent),
      multi: true,
    },
  ],
})
export class Dds01FireParametersComponent implements OnInit, ControlValueAccessor {
  /** Аттрибуты */
  @Input()
  public attributes: ILifeCycleStepParamDto[];
  /** Реактивная форма, для взаимодействия с внешней реактивной формой */
  public parametersForm: FormGroup;
  /** Настройка компоненты "Вид объекта пожара" */
  public fireObjectType: IScSelectOptions = {
    title: 'Вид объекта пожара',
    clearable: true,
    data: this.settings.getDictForSelect('fireObjectType'),
  };
  /** Настройка компоненты "Объект пожара" */
  public objectFire: IScSelectOptions = {
    title: 'Объект пожара',
    clearable: true,
    loadDataFn: (params) => this.getFireObjects(params),
  };
  /** Настройка компоненты "Причина пожара" */
  public causeFire: IScSelectOptions = {
    title: 'Причина пожара',
    data: this.getReasons(),
    clearable: true,
  };
  /** Настройка компоненты "Руководитель тушения пожара" */
  public leader: IScInputOptions = {
    label: 'Руководитель тушения пожара',
    maxLength: 100,
  };
  /** Настройка компоненты "Виновное лицо" */
  public fireCulprit: IScInputOptions = {
    label: 'Виновное лицо',
    maxLength: 100,
  };
  /** Площадь пожара */
  public fireAreaOption: IScInputOptions = {
    label: 'Площадь пожара',
    mask: '0*',
  };
  /** Настройка компоненты "Ранг пожара" */
  public rank: IScSelectOptions = {
    title: 'Ранг пожара',
    clearable: true,
    data: this.settings.getDictForSelect('fireRank'),
  };

  /** @ignore */
  constructor(private readonly settings: Settings2Service, private readonly rest: RestService) {}

  /** @ignore */
  ngOnInit(): void {
    this.parametersForm = new FormGroup({
      fireObjectType: new FormControl(null),
      objectFire: new FormControl(undefined),
      causeFire: new FormControl(undefined),
      fireCulprit: new FormControl(undefined),
      leader: new FormControl(undefined),
      rank: new FormControl(undefined),
      fireArea: new FormControl(undefined, [Validators.required]),
      relevantOnDate: new FormControl(undefined, [Validators.required]),
    });
  }

  /**
   * Метод срабатывает при клике на селект "Причина пожара" и получает список из настроек.
   * @return список причин для селекта.
   */
  private getReasons(): IScSelectItem[] {
    const groupId = this.settings.reasonGroups.find((group: any) => group.sysName === 'fireReasons').id;
    return this.settings.reasons
      .filter((reason: IReasonDto) => reason.groupId === groupId)
      .map((reason: IReasonDto) => {
        return <IScSelectItem>{
          id: reason.id,
          text: reason.name,
        };
      });
  }

  /**
   * Метод срабатывает при клике на селект "Объект пожара" и загружеат справочник "Объекты пожара".
   * @param params Параметры загрузки данных.
   * @return список объектов пожара для селекта.
   * TODO: Вынести в сервис
   */
  private getFireObjects(params: IScSelectLoadParams): Observable<IScSelectItem[]> {
    if (params.limit && params.limit.paNumber > 0) return of([]);
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'FireObjects',
          sort: {
            field: 'name',
            direction: 'asc',
          },
          query: {
            $text: (params.query || { $text: undefined }).$text
              ? {
                  $search: params.query.$text.$search,
                  $fields: ['name'],
                }
              : undefined,
          },
        },
      })
      .pipe(
        map((data) =>
          data.data.items.map((item) => {
            return { id: item.id, text: item.name };
          }),
        ),
      );
  }

  /**
   * Функция обновляет локальную переменную
   * @param fireParams Новое значение
   */
  public writeValue(fireParams: IDds01FireParamsDto): void {
    if (fireParams) {
      this.parametersForm.patchValue(fireParams);
    }
  }

  /**
   * Регистрируем функцию, которую будем вызывать при изменении/уведомлении значения
   * @param fn Функция
   */
  public registerOnChange(fn: any): void {
    this.parametersForm.valueChanges.subscribe(fn);
  }

  /**
   * Регистрируем функцию, которую будем вызывать если "затронули" данный элемент
   * @param fn Функция
   */
  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /**
   * Активируем или блокируем элемент
   * @param isDisabled новое состояние элемента
   */
  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.parametersForm.disable() : this.parametersForm.enable();
  }

  /** Извещаем о том, что элемент затронут пользователем */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private propagateTouch = () => { return; };


  /**
   * Метод валидации формы. Помечает невалидные контролы.
   * @returns ValidationErrors
   */
  public validate(c: AbstractControl): ValidationErrors | null {
    return this.parametersForm.valid || this.parametersForm.disabled
      ? null
      : { invalidForm: { valid: false, message: 'Не валидное поле в форме.' } };
  }
}
