import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { GisService } from '@smart-city/maps/sc';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { IAnyObject, IChemicalForecastResultsDto } from 'smart-city-types';
import {
  IDestroyChemicalObjectData,
  IDestroyChemicalObjectReport,
  IDestroyChemicalObjectTaskParamDto,
} from '../../models';
import { BaseComponent } from '@bg-front/core/components';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';
import { BgMapService } from '@bg-front/core/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { ForecastingBlowoutReportService, ForecastingBlowoutVisualizationService } from '../../services';
import { KseonUnitsService } from '@bg-front/kseon-units/services';
import { ISignificantObjectData } from '@bg-front/significant-objects/models';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChemicalTypesService } from '../../../stored-chemicals/services';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

@UntilDestroy()
@Component({
  selector: 'bg-destroy-chemical-object-task-result',
  templateUrl: './destroy-chemical-object-task-result.component.html',
  styleUrls: ['./destroy-chemical-object-task-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DestroyChemicalObjectTaskResultComponent extends BaseComponent implements OnInit, OnDestroy {
  /**
   * Режим просмотра.
   * Отображать визуализацию не надо
   * TODO: После реализации формы просмотра убрать
   */
  @Input()
  public isShow = false;

  @Input()
  public forecastingResults: IForecastingResultDto;
  /** Результат по каждому АХОВ */
  public storedChemicalResults: IDestroyChemicalObjectData[];
  public result: IChemicalForecastResultsDto;
  /** Скачать отчёт */
  @Input()
  public downloadReport: Observable<void>;
  /** Уведомляем о наличии отчёта */
  @Output()
  public hasReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  /** Уведомляем о формировании отчёта */
  @Output()
  public isDownloadReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  /** Ссылка на результат отрисовки */
  private displayElement: any;

  /** @ignore */
  constructor(
    private readonly gisService: GisService,
    private readonly gisBgService: BgMapService,
    private readonly significantObjectService: SignificantObjectsService,
    private readonly forecastingService: ForecastingBlowoutVisualizationService,
    private readonly reportingService: ForecastingBlowoutReportService,
    private readonly chemicalTypesService: ChemicalTypesService,
    private readonly cdr: ChangeDetectorRef,
    private readonly unitService: KseonUnitsService,
    private readonly settings2: Settings2Service,
  ) {
    super();
  }

  /**
   * @ignore
   */
  public ngOnInit(): void {
    this.result = this.forecastingResults?.result as IChemicalForecastResultsDto;

    this.gisBgService.setPositionMapOnCoordinates(this.forecastingResults.params.coordinates);

    if (!this.isShow) {
      try {
        this.displayElement = this.forecastingService.displayChemicalResults(
          this.forecastingResults.params.coordinates,
          (this.forecastingResults.params.params as IDestroyChemicalObjectTaskParamDto).windDirection,
          (this.forecastingResults.params.params as IDestroyChemicalObjectTaskParamDto).windVelocity,
          this.result.pollutionDepth,
        );
      } catch (e) {
        this.displayElement = undefined;
      }
    }

    this.storedChemicalResults = (this.forecastingResults.params
      .params as IDestroyChemicalObjectTaskParamDto).storedChemical.map(
      (el: {
        chemicalTypeId: string;
        aggregationStateId: string;
        chemicalAmount: number;
        totalChemicalAmount: boolean;
      }) => {
        return <IDestroyChemicalObjectData>{
          name: this.chemicalTypesService
            .getChemicalTypeNameById(el.chemicalTypeId)
            .pipe(
              map(
                (name: string) =>
                  `${name} ${
                    this.chemicalTypesService.getAggregationStateById(el.aggregationStateId)?.name
                      ? ' (' + this.chemicalTypesService.getAggregationStateById(el.aggregationStateId)?.name + ')'
                      : ''
                  }`,
              ),
            ),
          amount: el.chemicalAmount,
          lethalityDuration: dayjs.duration(Math.round(this.result.lethalityDurations.find(
            (resEl: IAnyObject) => resEl.chemicalTypeId === el.chemicalTypeId,
          )?.lethalityDuration * 60), 'minutes').format('HH ч mm мин'),
        };
      },
    );

    this.hasReport.emit(true);

    this.downloadReport.pipe(untilDestroyed(this)).subscribe(() => {
      const report: IDestroyChemicalObjectReport = {};
      const params = this.forecastingResults.params.params as IDestroyChemicalObjectTaskParamDto;
      const significantObjectObs = this.significantObjectService.getSignificantObjectById(
        this.forecastingResults.objectId,
      );
      // переводим имена АХОВ из Observable в string
      const chemicalTypeObs = [];
      this.storedChemicalResults.forEach((el: IDestroyChemicalObjectData) => {
        chemicalTypeObs.push(el.name);
      });

      let significantObject: ISignificantObjectData;

      this.isDownloadReport.emit(true);
      return forkJoin([significantObjectObs, ...chemicalTypeObs])
        .pipe(
          mergeMap((res) => {
            significantObject = res[0] as ISignificantObjectData;

            // добавляем информацию по результатам расчёта для каждого АХОВ в отчёт
            report.chemicalsData = [];
            this.storedChemicalResults.forEach((el: IDestroyChemicalObjectData, index: number) => {
              const chemData = { ...el };
              chemData.name = res[index + 1] as string;
              report.chemicalsData.push(chemData);
            });

            // приводим координаты к нужному виду
            const coordinates = this.forecastingService.coordinatesChemicalResults(
              this.forecastingResults.params.coordinates,
              (this.forecastingResults.params.params as IDestroyChemicalObjectTaskParamDto).windDirection,
              (this.forecastingResults.params.params as IDestroyChemicalObjectTaskParamDto).windVelocity,
              this.result.pollutionDepth,
            );

            return forkJoin([
              (this.forecastingResults && coordinates
                ? this.gisService.getAddressesByPolygonV2(coordinates)
                : of(null)
              ).pipe(
                catchError((err: Error) => {
                  this.catchErrorFn(err, err.message);
                  return of(null);
                }),
              ),
              (coordinates?.length
                ? this.unitService.getKseonUnit({
                    point: {
                      $polygon: {
                        $points: [
                          ...coordinates.map((item: number[]) => [item[0], item[1]]),
                          ...[[coordinates[0][0], coordinates[0][1]]],
                        ],
                      },
                    },
                  })
                : of(null)
              ).pipe(
                catchError((err: Error) => {
                  this.catchErrorFn(err, err.message);
                  return of(null);
                }),
              ),
            ]);
          }),
          mergeMap(([res, units]) => {
            /** Блок "Местоположение" */
            if (significantObject?.address?.fullText) {
              report.address = significantObject.address.fullText;
            }
            report.coordinates = significantObject?.coordinates;
            report.object = significantObject?.name;
            report.responsible = significantObject?.responsible;
            report.phone = significantObject?.phone;

            /** Блок "Исходные данные" */
            report.emergency = this.forecastingResults.emergencyNumber || 'не задано';
            report.timeToSpread = +params.timeToSpread;

            /** Блок "Метеоусловия в момент аварии" */
            report.windVelocity = +params.windVelocity;
            report.windDirection = +params.windDirection;
            report.isSnowy = params.isSnowy ? 'Да' : 'Нет';
            report.airTemperature = +params.airTemperature;
            report.cloudState = this.settings2
              .getDictionaryByTypeSysName('cloudy')
              .find((item: IDictionaryInfo) => item.id === params.cloudStateId).name;
            report.timeOfDay = this.settings2
              .getDictionaryByTypeSysName('timesOfDay')
              .find((item: IDictionaryInfo) => item.id === params.timeOfDayId).name;

            /** Блок "Используемая методология для прогнозирования" */
            report.methodology = `СП 165.1325800.2014 Инженерно-технические мероприятия по гражданской обороне. Актуализированная редакция
СНиП 2.01.51-90 (с Изменением N 1)
Приложение Б (обязательное). Методика прогнозирования масштабов возможного химического заражения аварийно химически
опасными веществами при авариях на химически опасных объектах и транспорте`;

            /** Блок "Результаты расчёта" */
            report.chemicalForecastResults = { ...(this.forecastingResults.result as IChemicalForecastResultsDto) };

            /** Блок "Здания в зоне поражения" */
            report.buildings = [...res];

            /** Блок "Средства оповещения в зоне возможного химического заражения" */
            report.devices = [
              ...(units || []).map((item) => {
                return {
                  type: item.type,
                  name: item.name,
                  currentSeanceName: item.currentSeanceName,
                  syncTime: item.syncTime,
                };
              }),
            ];

            return of(report);
          }),
          finalize(() => this.isDownloadReport.emit(false)),
          untilDestroyed(this),
        )
        .subscribe((chemicalReport: IDestroyChemicalObjectReport) => {
          this.reportingService.buildForecastingChemicalDestructionReport(chemicalReport);
        });
    });

    this.cdr.markForCheck();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.forecastingService.clearResults(this.displayElement);
  }
}
