import { EventEmitter } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { ONE_SECOND, ONE_HOUR } from '../constants';

export class Timer {
  /** Время начало отсчета */
  private readonly startTime: number;
  /** Время истечения интервала */
  private readonly alarmTime: number;
  /** Отмеряемый период */
  private readonly period: number;
  /** Значение таймера */
  private value: number;
  /** Отписка от тиков таймера */
  private unsubscribe: Subject<void> = new Subject<void>();
  /** Интервал тиков таймера */
  private interval: number = ONE_SECOND;
  /** Знак числа */
  private sign: '' | '-' = '';
  /** Формат времени для таймера */
  private timeFormat: 'HH:mm:ss' | 'mm:ss' = 'HH:mm:ss';

  /** Событие тик таймера */
  public tick = new EventEmitter<string>();

  /** Событие срабатывания таймера */
  public alarm = new EventEmitter<void>();

  /**
   * @param period - Отмеряемый период в мс
   * @param startTime - Время начало отсчета в мс
   */
  constructor(period: number, startTime?: number) {
    // Если время начала не задано, считать от текущего времени
    this.startTime = startTime || moment().utc().valueOf();
    // this.startTime = moment().utc().valueOf();
    this.period = period;
    // this.period = 10000;
    this.alarmTime = this.startTime + this.period;
    this.value = this.alarmTime - moment().utc().valueOf();
  }

  /** Запуск таймера */
  public start() {
    timer(0, this.interval)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.value -= this.interval;

        // Если значение меньше 0
        if (this.value <= 0) {
          // Оповещение о срабатывании таймера
          this.alarm.emit();
          // Пустить таймер в обратную сторону
          this.interval = -this.interval;
          // Выводить минус перед значением
          this.sign = '-';
          // Сделать значение положительным.
          // Значение меньше одной секунды но большее 0, полученное на предыдущем тике, moment.js отформатирует как 00:00.
          // На текущем тике если просто обратить значение, оно так же будет меньше секунды, поэтому нужна еще одна.
          this.value = -this.value + ONE_SECOND;
        }
        // Если значение убывает и оно менее одного часа, в строке выводить минуты и секунды
        if (this.sign === '' && this.value < ONE_HOUR) {
          this.timeFormat = 'mm:ss';
        }
        // Если значение возрастает и оно более одного часа, в строке выводить часы, минуты и секунды
        if (this.sign === '-' && this.value >= ONE_HOUR) {
          this.timeFormat = 'HH:mm:ss';
        }

        this.tick.emit(`${this.sign}${moment(this.value).utc().format(this.timeFormat)}`);
      });
  }

  /** Остановка таймера */
  public stop() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
