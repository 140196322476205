<div class="bg-row">
  <div class="bg-col p-r-0">
    <div class="bg-form-group readonly-container">
      <label>Корпус</label>
      <p>{{ model?.corp }}</p>
    </div>
  </div>
  <div class="bg-col p-h-0">
    <div class="bg-form-group readonly-container">
      <label>Этажность</label>
      <p>{{ model?.storeys }}</p>
    </div>
  </div>
  <div class="bg-col p-h-0">
    <div class="bg-form-group readonly-container">
      <label>Этаж</label>
      <p>{{ model?.floor }}</p>
    </div>
  </div>
  <div class="bg-col p-h-0">
    <div class="bg-form-group readonly-container">
      <label>Подъезд</label>
      <p>{{ model?.entrance }}</p>
    </div>
  </div>
  <div class="bg-col p-h-0">
    <div class="bg-form-group readonly-container">
      <label>Квартира</label>
      <p>{{ model?.room }}</p>
    </div>
  </div>
  <div class="bg-col p-l-0">
    <div class="bg-form-group readonly-container">
      <label>Км трассы</label>
      <p>{{ model?.km }}</p>
    </div>
  </div>
</div>

