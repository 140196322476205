import { IDownloadButton } from '../interfaces/download-sources-statuses.interface';
import { ArchiveStatuses, Prefixes } from '../enums';

const prepareButtonText = 'Подготовить видео';
const downloadButtonText = 'Скачать видео';

const video = Prefixes.video;
const defaultPropName = `${ArchiveStatuses.default}${video}`;
const pendingPropName = `${ArchiveStatuses.pending}${video}`;
const errorPropName = `${ArchiveStatuses.error}${video}`;
const successPropName = `${ArchiveStatuses.success}${video}`;

/** Статус поумолчания*/
const defaultStatus: IDownloadButton = {
  text: prepareButtonText,
  message: 'Запуск формирования видеоматериала',
  icon: 'sc-icon-inform',
  iconColor: '#7B7B7B',
  messageColor: '#7B7B7B',
  disabled: false,
};

/** "Подготовить видео" - статус ошибка */
const prepareVideoError: IDownloadButton = {
  text: prepareButtonText,
  icon: 'sc-icon-inform',
  message: 'Ошибка обратитесь в тех поддержку',
  iconColor: '#FC5A5A',
  messageColor: '#FC5A5A',
  disabled: false,
};
/** "Скачать видео" - статус доступно для скачивания */
const downloadVideoAccess: IDownloadButton = {
  text: downloadButtonText,
  message: 'Видео доступно для скачивания',
  icon: 'sc-icon-check',
  iconColor: '#1CCA84',
  disabled: false,
};

/** "Скачать видео" - статус доступно в процессе */
const downloadVideoInProgress: IDownloadButton = {
  text: downloadButtonText,
  message: 'Идёт процесс получения видео с видеосервера',
  icon: 'sc-icon-waiting',
  iconColor: '#FF9700',
  disabled: true,
};

/** "Скачать видео" - статус ошибка при скачивании */
const downloadVideoInError: IDownloadButton = {
  text: prepareButtonText,
  message: 'Ошибка при скачивании видео. Пожалуйста, обратитесь в техподдержку',
  icon: 'sc-icon-inform',
  iconColor: '#FC5A5A',
  messageColor: '#FC5A5A',
  disabled: false,
};

export const videoStatuses: Map<string, IDownloadButton> = new Map([
  [defaultPropName, defaultStatus],
  [pendingPropName, downloadVideoInProgress],
  [errorPropName, downloadVideoInError],
  [successPropName, downloadVideoAccess],
]);
