<div class="bg-new-involve-org-form">
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <div class="bg-row form-serviceType">
        <sc-select [options]="serviceTypeOptions" formControlName="serviceType"></sc-select>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <sc-button [options]="cancelBtnOptions" (clickButton)="closeHandler()"></sc-button>
    <sc-button [options]="nextBtnOptions" (clickButton)="nextHandler()"></sc-button>
  </mat-dialog-actions>
</div>
