import { Injectable } from '@angular/core';
import '@jjwtay/leaflet.ellipse';
import { RestService } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import 'leaflet-semicircle';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';

/**
 * Сервис для работы с потерями
 */
@Injectable({
  providedIn: 'root',
})
export class LoosesService {
  constructor(private readonly rest: RestService) {}

  /**
   * Получение списка людей для происшествия и конкретного типа потери
   * @param emergencyId
   * @param type
   */
  public getLooses(emergencyId: string, type: string): Observable<IAnyObject[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Humans',
          query: { emergencyId, type },
        },
      })
      .pipe(map((humans) => humans.data.items));
  }

  /**
   * Сохранение списка потерь
   * @param emergencyId
   * @param type
   * @param data
   */
  public saveLooses(emergencyId: string, type: string, data: IAnyObject[]): Observable<IAnyObject> {
    return this.rest
      .serviceRequest({
        action: 'delete',
        service: { name: 'Emergency' },
        entity: {
          name: 'Humans',
          query: {
            emergencyId,
            type,
            id: { $nin: data.map((item) => item.id).filter((item) => item) },
          },
        },
      })
      .pipe(
        switchMap(() =>
          forkJoin(
            data.map((item) =>
              this.rest.serviceRequest({
                action: 'upsert',
                service: { name: 'Emergency' },
                entity: {
                  name: 'Humans',
                  query: { id: item.id || Uuid.newUuid() },
                },
                data: {
                  emergencyId,
                  type,
                  ...item,
                },
              }),
            ),
          ),
        ),
      );
  }
}
