import { Injectable } from '@angular/core';
import { IForecastingResultDto, IForecastingSaveResultDto, IForecastingTaskParamsDto } from '@bg-front/core/models/interfaces';
import { BaseForecastingService, BgMapService } from '@bg-front/core/services';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IForecastingDetailDto } from 'smart-city-types';

/**
 * Сервис для прогнозирования
 */
@Injectable()
export class ForecastingService extends BaseForecastingService {
  // TODO убрать костыль с псевдо-состоянием и передавать данные через роут или акиту
  public forecastingResults: IForecastingResultDto = undefined;
  /** Сохраняем результат предыдущего рассчёта */
  public forecastingParams: IForecastingTaskParamsDto = undefined;
  /**
   * Массив состояний формы запуска прогнозирования для радиационно опасного объекта.
   * После закрытия формы, ее состояние сохраняется для повторного запуска.
   * key – id важного объекта
   * value – значение формы
   * */
  private forecastingForestFireFormStates = new Map();

  constructor(
    private readonly settings2: Settings2Service,
    private readonly bgMapService: BgMapService,
    rest: RestService,
  ) {
    super(rest);
  }

  /**
   * Метод получения параметров для прогнозирования для связной сущности
   * @param id Id происшествия
   */
  public getForecastingParamsById(id: string): Observable<IForecastingDetailDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: {
          name: 'ForecastingDetail',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return res.data.items[0];
        }),
      );
  }

  /**
   * Метод получения параметров для прогнозирования для конкретного происшествия
   * @param id
   */
  public getIncidentForecastingParams(
    id: string,
  ): Observable<{
    coordinates: string;
    params: IForecastingDetailDto;
  }> {
    let coordinates = undefined;
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          attributes: ['forecastingDetailId', 'id', 'coordinates', 'mapMarkerCoordinate'],
          query: {
            id,
          },
        },
      })
      .pipe(
        /**
         * TODO: Объединить в один запрос
         */
        mergeMap((res: IAbstractServiceData) => {
          if (res.data?.items?.length) {
            const item = res.data?.items[0] || {};
            coordinates = item.mapMarkerCoordinate ?? item.coordinates;
            if (item?.forecastingDetailId) {
              return this.getForecastingParamsById(item.forecastingDetailId);
            }
          }
          return of(null);
        }),
        map((params: IForecastingDetailDto) => {
          return {
            coordinates,
            params,
          };
        }),
      );
  }

  /** Выбор задачи для прогнозирования АХОВ */
  public startChemicalForecasting(data: IForecastingTaskParamsDto) {
    return this.rest
      .serviceRequest({
        data,
        action: 'selectChemicalForecastingTask',
        service: { name: 'Forecasting' },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return res.data;
        }),
      );
  }

  /** Выбор задачи для прогнозирования Лесных пожаров */
  public calculateForecasting<T>(data: IForecastingTaskParamsDto): Observable<T> {
    return this.rest
      .serviceRequest({
        data,
        action: 'calculateForecasting',
        service: { name: 'Forecasting' },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return res.data as T;
        }),
      );
  }

  /** Сохранение результата расчёта */
  public saveForecasting(data: IForecastingResultDto): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'insert',
      service: { name: 'Forecasting' },
      entity: {
        name: 'ForecastingResults',
      },
      data: {
        objectId: data.params.objectId,
        incidentId: data.params.emergencyId,
        projectedRiskId: data.params.projectedRiskId,
        countTaskId: data.params.countTaskId,
        params: data.params,
        result: data,
      },
    });
  }

  /** Запрос дополнительной информации об инциденте */
  public getEmergencyForecastingResultById(id: string): Observable<IForecastingSaveResultDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: {
          name: 'ForecastingResults',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((res: IAbstractServiceData): IForecastingSaveResultDto => {
          return (res.data.items[0] || {}) as IForecastingSaveResultDto;
        }),
      );
  }

  /**
   * Возвращает состояние формы запуска прогнозирования
   * */
  public getForecastingFormState(): IForecastingTaskParamsDto {
    return this.forecastingParams;
  }

  /**
   * Устанавливает состояние формы запуска прогнозирования
   * */
  public setForecastingFormState(data: IForecastingTaskParamsDto) {
    this.forecastingParams = data;
  }

  /**
   * Возвращает состояние формы запуска прогнозирования для лесного пожара
   * */
  public getForecastingForestFireFormState(objectId: string) {
    return this.forecastingForestFireFormStates.get(objectId);
  }

  /**
   * Убираем результат
   */
  public clearResults(el: any | any[]) {
    if (el) {
      if (el instanceof Array) {
        el.forEach((item: IAnyObject) => this.bgMapService.mapModel.removeLayer(item));
      } else {
        this.bgMapService.mapModel.removeLayer(el);
      }
    }
  }
}
