<div class="with-additional" *ngIf="mode === 'text' && addButtonOnAfter">
  <p
    class="text button-on-after"
    [class.borderless]="borderless"
    [class.link]="linkStyle"
  >
    {{ internalData }}
  </p>
  <button (click)="onClickHandler()">
    <div nz-icon [nzType]="addButtonOnAfter"></div>
  </button>
</div>

<div class="with-additional add-on-text" *ngIf="mode === 'text' && addTextOnAfter">
  <p
    class="text button-on-after"
    [class.borderless]="borderless"
  >
    {{ internalData }}
  </p>
  <p>{{ addTextOnAfter }}</p>
</div>

<div class="with-additional">
  <div class="color-picker" *ngIf="colorPicker" [ngStyle]="{backgroundColor: colorPicker}"></div>
  <p
    class="text"
    *ngIf="mode === 'text' && !addButtonOnAfter && !addTextOnAfter"
    [class.borderless]="borderless"
    [class.link]="linkStyle"
    [class.button-on-before]="colorPicker"
    (click)="onClickHandler()"
  >
    {{ internalData }}
  </p>
</div>

<p
  class="text tag"
  *ngIf="mode === 'tag'"
>
  <span *ngFor="let tag of internalData">
    {{ tag }}
  </span>
</p>

<textarea
  class="text textarea"
  *ngIf="mode === 'textarea'"
  readonly
  #textarea
>{{ internalData }}</textarea>

<label
  class="checkbox"
  *ngIf="mode === 'checkbox'"
  nz-checkbox
  nzDisabled
  [ngModel]="internalData"
>
  {{ checkboxLabel }}
</label>

<ng-container *ngIf="mode === 'switch'">
  <nz-switch [ngModel]="internalData" nzDisabled></nz-switch>
  <span class="switch-label m-l-15 m-r-15">{{ switchLabel }}</span>
</ng-container>

