import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BgCoreModule } from '@bg-front/core';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { ScComponentsModule } from '@smart-city/core/common';
import { SignificantObjectsStore } from './services/significant-objects-store/store/significant-objects.store';
import {
  SignificantObjectMiniCardComponent,
  SignificantObjectsFilterComponent,
  SignificantObjectsRegistryComponent,
  SignificantObjectInfoComponent,
} from './components';
import { NzViewControlModule } from '@bg-front/nz-view-control';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { RouterModule } from '@angular/router';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';

@NgModule({
  imports: [
    NzDrawerModule,
    CommonModule,
    ScComponentsModule,
    ReactiveFormsModule,
    ErrorTailorModule,
    BgCoreModule,
    NzViewControlModule,
    NzIconModule,
    NzFormModule,
    NzButtonModule,
    NzCollapseModule,
    NzCardModule,
    NzTagModule,
    NzPageHeaderModule,
    NzSpinModule,
    NzSelectModule,
    NzDatePickerModule,
    NzTabsModule,
    RouterModule,
  ],
  declarations: [
    SignificantObjectsRegistryComponent,
    SignificantObjectsFilterComponent,
    SignificantObjectMiniCardComponent,
    SignificantObjectInfoComponent,
  ],
  exports: [SignificantObjectsRegistryComponent, SignificantObjectMiniCardComponent],
  providers: [SignificantObjectsStore]
})
export class SignificantObjectsModule {}
