import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ScConsole, Uuid } from '@smart-city/core/utils';
import { of } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { NotificationObjectsAtmViewFormComponent } from '../notification-objects-atm-view-form/notification-objects-atm-view-form.component';
import { NotificationObjectsBaseViewFormComponent } from '../notification-objects-base-view-form/notification-objects-base-view-form.component';
import { NotificationObjectsTesViewFormComponent } from '../notification-objects-tes-view-form/notification-objects-tes-view-form.component';
import { NotificationsObjectsHcsService } from '../../../../bg/modules/external-interactions/modules/notifications-objects-hcs/services';
import { INotificationObjectsHcsView } from '../../../../bg/modules/external-interactions/modules/notifications-objects-hcs/models/interfaces';
import { BaseComponent } from '@bg-front/core/components';

@Component({
  selector: 'bg-notification-objects-form',
  templateUrl: './notification-objects-form.component.html',
  styleUrls: ['./notification-objects-form.component.scss'],
})
export class NotificationObjectsFormComponent extends BaseComponent implements OnInit {
  /** Флаг инициализации */
  public isLoading = false;

  /** Контейнер */
  @ViewChild('formContainer', { read: ViewContainerRef, static: false }) public formContainer: ViewContainerRef;

  /** Сообщение об ошибке */
  public errors: string;

  /** @ignore */
  constructor(
    private readonly route: ActivatedRoute,
    private readonly service: NotificationsObjectsHcsService,
    private readonly router: Router,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.isLoading = true;
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          // Если ID нет в параметрах или он невалидный вывести сообщение что ничего не найдено
          if (!params.id || !Uuid.isValid(params.id)) {
            this.errors = 'Запись не найдена';
          }

          return this.service.getForView(params.id);
        }),
        catchError((err: Error) => {
          ScConsole.error([err.message]);
          this.errors = 'Ошибка при загрузке информации о записи';
          return of(undefined);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((notification: INotificationObjectsHcsView) => {
        this.isLoading = false;
        if (notification) {
          this.createForm(notification);
        } else {
          this.errors = this.errors || 'Запись не найдена';
          this.closeActiveForm();
        }
      });
  }

  /**
   * Закрытие формы
   */
  private closeActiveForm() {
    this.router.navigate(['external-interactions/notifications-objects-hcs']);
  }

  /**
   * Создание формы
   * @param notification - информация об оповещении
   */
  private createForm(notification: INotificationObjectsHcsView): void {
    this.formContainer.clear();

    const component = this.getFormComponent(notification.integrationType);

    if (component) {
      component.model = notification;
    } else {
      this.noteService.pushError('Не существует формы для просмотра этого вида интеграции');
      this.closeActiveForm();
    }
  }

  /** Определение компонента формы и его создание */
  private getFormComponent(integrationType: string): NotificationObjectsBaseViewFormComponent {
    switch (integrationType) {
      case 'ATM':
        return this.formContainer.createComponent<NotificationObjectsAtmViewFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(NotificationObjectsAtmViewFormComponent),
        ).instance;
      case 'ТЭС':
        return this.formContainer.createComponent<NotificationObjectsTesViewFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(NotificationObjectsTesViewFormComponent),
        ).instance;
    }
  }
}
