import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IAbstractServiceEntitySelectResult, IAnyObject, IUser } from 'smart-city-types';
import { AtsService, IRegistryLoadDataFn, IRegistryLoadDataParams } from '@smart-city/core/common';
import { SfsService, RestService, Settings2Service } from '@smart-city/core/services';

@Injectable({
  providedIn: 'root',
})
export class TaskService {

  constructor(
    private ats: AtsService,
    private sfs: SfsService,
    private readonly rest: RestService,
    private settings2: Settings2Service,
  ) {
  }

  /**
   * Установить статус задания
   * @param taskId задание
   * @param statusName статус
   */
  public setTaskStatus(taskId: string, statusName: string): Observable<IAbstractServiceData> {
    return forkJoin([
      this.rest.serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Dictionary',
          query: {
            'typeid.sysname': 'taskStatus',
            sysname: statusName,
          },
        },
      }),
      this.rest.serviceRequest({
        action: 'select',
        service: { name: 'Calls' },
        entity: {
          name: 'Tasks',
          attributes: ['gasterCallTaskId'],
          query: { id: taskId },
        },
      }),
      this.ats.getAtsByUser(this.settings2.currentUser.id),
      this.ats.getGatewayByUser(this.settings2.currentUser.id),
    ]).pipe(
      switchMap((result) => {
        const statusId: string = result[0]?.data?.items?.[0]?.id;
        const gasterCallTaskId: string = result[1]?.data?.items?.[0]?.gasterCallTaskId;
        switch (statusName) {
          case 'inProgress':
            return this.rest.serviceRequest({
              action: 'work',
              service: { name: 'Gaster' },
              data: {
                id: gasterCallTaskId,
                idATS: result[2]?.id,
                gateway: result[3],
              },
            });
            break;
          case 'pause':
            return this.rest.serviceRequest({
              action: 'pause',
              service: { name: 'Gaster' },
              data: {
                id: gasterCallTaskId,
              },
            });
            break;
          case 'finished':
            return this.rest.serviceRequest({
              action: 'finish',
              service: { name: 'Gaster' },
              data: {
                id: gasterCallTaskId,
              },
            });
            break;
          case 'canceled':
            return this.rest.serviceRequest({
              action: 'cancel',
              service: { name: 'Gaster' },
              data: {
                id: gasterCallTaskId,
              },
            });
            break;
        }
        return this.rest.serviceRequest({
          action: 'update',
          service: { name: 'Gaster' },
          entity: {
            name: 'CallTask',
            query: {
              id: gasterCallTaskId,
            },
          },
          data: {
            state: statusId,
          },
        });
      }),
    );
  }

  /**
   * Метод, обновляющий существующую запись в таблице Tasks сервиса Calls.
   * @param user пользователь, инициировавший изменение.
   * @param value объект с полями из формы.
   * @param id идентификатор записи, которую следует обновить.
   */
  public updateTask(user: IUser, value: IAnyObject, id: string)
    : Observable<IAbstractServiceData> {
    const data: IAnyObject = {
      citizens: value.citizens,
      comment: value.comment,
      type: value.type,
      status: value.status,
      responsible: value.responsible,
      description: value.description,
      executionDate: value.executionDate,
      result: value.result,
      organizationId: value.organizationId,
      name: value.name,
    };

    const gasterCallTask = {
      idSFS: value['gasterCallTaskId.idSFS'],
    };

    return forkJoin([
      this.rest.serviceRequest({
        data,
        action: 'update',
        service: {
          name: 'Calls',
        },
        entity: {
          name: 'Tasks',
          query: {
            id,
          },
        },
      }),
      this.rest.serviceRequest({
        action: 'update',
        service: {
          name: 'Gaster',
        },
        entity: {
          name: 'CallTask',
          query: {
            id: value['gasterCallTaskId.id'],
          },
        },
        data: gasterCallTask,
      }),
    ]).pipe(map(res => res[0]));
  }

  /**
   * Получение объекта задания по ID
   * @param taskId ID выбранного задания
   * */
  public getTask(taskId): Observable<IAnyObject> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Calls' },
      entity: {
        name: 'Tasks',
        attributes: [
          'name',
          'type.id',
          'type.name',
          'type.sysname',
          'state.id',
          'state.name',
          'state.sysname',
          'comment',
          'gasterCallTaskId.state.id',
          'gasterCallTaskId.state.sysname',
          'responsible.id',
          'responsible.fio',
          'executionDate',
          'gasterCallTaskId.id',
          'gasterCallTaskId.idSFS',
        ],
        query: {
          id: taskId,
        },
      },
    }).pipe(map((res: IAbstractServiceData) => {
      return res.data.items.map((item) => {
        const isSms = item['type.id'] === this.settings2.getDictObjByTypeSysName('taskType')['smsSending']?.id;
        item.type = item['type.name'];
        item.state = isSms ?
          this.settings2.getDictObjByTypeSysName('taskStatus')[item['state.id']]?.sysname :
          item['gasterCallTaskId.state.sysname'];
        item.responsible = item['responsible.fio'];
        item.audio = item['gasterCallTaskId.idSFS'] && this.sfs.getStreamUrl(item['gasterCallTaskId.idSFS']);
        return item;
      })[0];
    }));
  }

  /**
   * Получения списка Должностных лиц
   * @param taskId - id задания
   * @return
   */
  public getCitizenFromOfficials(taskId: string): Observable<IAnyObject> {
    return this.rest.serviceRequest({
      service: { name: 'Calls' },
      action: 'select',
      entity: {
        query: {
          taskId,
        },
        name: 'TasksCitizensRelations',
      },
    }).pipe(
      mergeMap(({ data: { items } }: IAbstractServiceData) => {
        if (items.length) {
          return this.rest.serviceRequest({
            service: { name: 'Admin' },
            action: 'select',
            entity: {
              query: {
                citizenId: {
                  $in: items.map(item => item.citizenId),
                },
              },
              name: 'Officials',
            },
          });
        }
        return of({ data: { items: [] } } as IAbstractServiceData);
      }),
      map(({ data: { items } }: IAbstractServiceData) => {
        return items;
      }),
    );
  }

  /**
   * Функция загрузки данных в реестр
   * @param query Условия выборки
   * @param params Параметры сортировки и пагинации
   */
  public loadCitizenRegistryDataFn = (query: IAnyObject, params: IRegistryLoadDataParams)
    : Observable<IAbstractServiceEntitySelectResult> => {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Calls' },
      entity: {
        query,
        name: 'TasksCitizensRelations',
        attributes: [
          'id',
          'citizenId.id',
          'citizenId.surname',
          'citizenId.firstName',
          'citizenId.patronymic',
          'status',
          'status.id',
          'status.name',
          'gasterUnitId.state.sysname',
          'gasterUnitId.state.name',
        ],
      },
      data: {
        isNeedTotal: true,
        // sort: params.sort,
        limit: {
          paNumber: params.paNumber,
          paSize: params.paSize,
        },
        fullName: {
          $expr: {
            $concat: ['$citizenId.surname', ' ', '$citizenId.firstName', ' ', '$citizenId.patronymic'],
          },
        },
        phone: {
          $join: {
            service: 'Citizens',
            entity: 'CitizensPhones',
            attributes: ['citizenId', 'phoneNumber', 'isPrimary'],
            query: {
              $and: [
                { 'phone.isPrimary': true },
                {
                  $expr: {
                    $eq: [
                      { $expr: { $toObjectId: ['$citizenId.id'] } },
                      { $expr: { $toObjectId: ['$phone.citizenId'] } },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
    }).pipe(
      map((data) => {
        const items = data.data?.items ?? [];
        return {
          items: items.map(item => ({
            ...item,
            'state.name': item.state?.name,
            phone: item.phone?.phoneNumber,
          })),
          totalCount: data.data?.totalCount,
        };
      }),
    );
  }

  /**
   *  Обработчик загрузки данных для другого реестра
   *  @param service - имя сервиса
   *  @param entity - имя сущности
   *  @param list - список элементов
   *  @return
   */
  public loadAnotherRegistryData(service: string, entity: string, list: string[]): IRegistryLoadDataFn {
    return (query: IAnyObject, params: IRegistryLoadDataParams)
      : Observable<IAbstractServiceEntitySelectResult> => {
      if (list.length) {
        return this.rest.serviceRequest({
          service: { name: service },
          action: 'select',
          entity: {
            query: {
              ...query,
              id: { $in: list },
            },
            name: entity,
          },
        }).pipe(
          map((data) => {
            const items = data.data?.items ?? [];
            return {
              items,
              totalCount: data.data?.totalCount,
            };
          }),
        );
      }
    };
  }

  public getNumbersFoSmsSending(taskId: string): Observable<{
    id: string,
    phoneNumber,
  }[]> {
    // if (list.length) {
    //   return this.rest.serviceRequest({
    //     service: { name: service },
    //     action: 'select',
    //     entity: {
    //       query: {
    //         id: { $in: list },
    //       },
    //       name: entity,
    //     },
    //     data: {
    //       limit: 999999999,
    //     },
    //   }).pipe(
    //     map((data: IAbstractServiceData) => {
    //       const items = data.data?.items ?? [];
    //       return items.forEach((el: IAnyObject) => el.number as string);
    //     }),
    //   );
    // }

    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Calls' },
      entity: {
        name: 'TasksCitizensRelations',
        attributes: [
          'id',
          'citizenId.id',
          'citizenId.surname',
          'citizenId.firstName',
          'citizenId.patronymic',
          'gasterUnitId.state.sysname',
          'gasterUnitId.state.name',
        ],
        query: {
          taskId,
        },
      },
      data: {
        limit: {
          paNumber: 0,
          paSize: 99999999,
        },
        fullName: {
          $expr: {
            $concat: ['$citizenId.surname', ' ', '$citizenId.firstName', ' ', '$citizenId.patronymic'],
          },
        },
        phone: {
          $join: {
            service: 'Citizens',
            entity: 'CitizensPhones',
            attributes: ['citizenId', 'phoneNumber', 'isPrimary'],
            query: {
              $and: [
                { 'phone.isPrimary': true },
                {
                  $expr: {
                    $eq: [
                      { $expr: { $toObjectId: ['$citizenId.id'] } },
                      { $expr: { $toObjectId: ['$phone.citizenId'] } },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
    }).pipe(
      map((data) => {
        const items = data.data?.items ?? [];
        return items.map((item: IAnyObject) => {
          return {
            id: item.id,
            phoneNumber: item.phone?.phoneNumber,
          };
        });
      }),
    );
  }

  public sendSms(userId: string, phone: string, text: string) {
    return this.rest.serviceRequest({
      action: 'send',
      service: { name: 'sms' },
      system: {
        name: 'gaster',
        queue: undefined,
      },
      data: {
        text,
        number: phone,
      },
    }).pipe(mergeMap((result: IAbstractServiceData) => {
      let status = this.settings2.getDictObjByTypeSysName('smsTaskStatus')['error']?.id;
      if (!((result || { data: null }).data || { message: null }).message) {
        status = this.settings2.getDictObjByTypeSysName('smsTaskStatus')['sent']?.id;
      }

      return this.rest.serviceRequest({
        action: 'update',
        service: { name: 'Calls' },
        entity: {
          name: 'TasksCitizensRelations',
          query: {
            id: userId,
          },
        },
        data: {
          status,
        },
      });
    }));
  }

  public updateTaskState(taskId: string, state: string) {
    return this.rest.serviceRequest({
      action: 'update',
      service: { name: 'Calls' },
      entity: {
        name: 'Tasks',
        query: {
          id: taskId,
        },
      },
      data: {
        state,
      },
    });
  }
}
