import { Injectable } from '@angular/core';
import { isArray } from '@datorama/akita';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MapObject } from '../../../models/classes';
import { MapObjectsStore } from '../store/map-objects.store';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MapObjectsService {
  constructor(private readonly store: MapObjectsStore) {}

  /**
   * Удаление записи
   */
  public delete(id: string | string[]): void {
    this.store.remove(id);
  }

  /**
   * Удаление объектов со слоя
   * @param layerId Идентификатор слоя
   */
  public deleteFromLayer(layerId: string): void {
    this.store.remove((object: MapObject): boolean => object.layerId === layerId);
  }

  /**
   * Добавление слоя
   * @param data - слой
   */
  public add(data: MapObject | MapObject[]): void {
    if (data) {
      if (isArray(data)) {
        this.store.upsertMany(data);
      } else {
        this.store.upsert(data.id, data);
      }
    }
  }

  /**
   * Добавление видимости слоя
   * @param ids - Идентификатор или список идентификаторов
   * @param value - состояние видимости
   */
  public setIsShow(ids: string | string[], value: boolean) {
    this.store.update(ids, { isShow: value });
  }

  /** Установить активную записть */
  public setActive(id: string): void {
    this.store.setActive(id);
  }
}
