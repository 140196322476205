import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@bg-front/core/components';
import { FLOAT_PATTERN } from '@bg-front/core/models/constants';
import { StoredChemicalsService } from '@bg-front/stored-chemicals/services';
import { IWeatherDataDto } from '@bg-front/weather-data/models';
import { WeatherDataService } from '@bg-front/weather-data/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IScCheckboxOptions, IScInputOptions, IScSelectOptions } from '@smart-city/core/common';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import * as dayjs from 'dayjs';
import { noop } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { IForecastingParamsDto, ILifeCycleStepParamDto, IStoredChemicalDto } from 'smart-city-types';

@UntilDestroy()
@Component({
  selector: 'bg-forecasting-radiation-chemically-params-form',
  templateUrl: './forecasting-radiation-chemically-params-form.component.html',
  styleUrls: ['./forecasting-radiation-chemically-params-form.component.scss'],
})
export class ForecastingRadiationChemicallyParamsFormComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Риск, необходим setter для динамического изменения query в significantObjectOptions*/
  @Input()
  set projectedRisk(value) {
    this.projectedRiskValue = value;
    const projectedRiskId = this.settings.getDictionaryByTypeAndSysName('projectedRisks', value)?.id;
    if (projectedRiskId) {
      this.significantObjectOptions.query = {
        $expr: { $contains: ['$projectedRiskIds', JSON.stringify([projectedRiskId])] },
      };
      this.significantObjectOptions = { ...this.significantObjectOptions };
    } else {
      this.significantObjectOptions.query = { $expr: undefined };
      this.significantObjectOptions = { ...this.significantObjectOptions };
    }
  }

  /**
   * Фактический адрес, участвует для конфигурации significantObjectOptions
   */
  @Input()
  set factAddress(value) {
    if (value?.latitude && value?.longitude) {
      this.significantObjectOptions.query = {
        ...this.significantObjectOptions.query,
        point: {
          $radius: {
            $lat: Number(value.latitude),
            $lon: Number(value.longitude),
            $meters: 50,
          },
        },
      };
    } else {
      delete this.significantObjectOptions.query.point;
    }

    this.significantObjectOptions = { ...this.significantObjectOptions };
  }

  @Input()
  public set attributes(attributes: ILifeCycleStepParamDto[]) {
    let val = false;
    const controlsName = ['windVelocity', 'windDirection', 'isSnowy', 'airTemperature', 'cloudStateId', 'timeOfDayId'];
    controlsName.forEach((name: string) => {
      const attr = attributes.find((attr: ILifeCycleStepParamDto) => attr.attribute === name);
      if (attr?.enabled) {
        val = true;
      }
    });
    this.canChangeMeteorologicalData = val;
  }

  /** Форма */
  public form: FormGroup;
  /**
   * Свойство для хранения значения переданного в projectedRisk
   */
  private projectedRiskValue: string;

  public forecastingParams: IForecastingParamsDto = {
    aggregationStateId: undefined,
    chemicalTypeId: undefined,
    significantObjectId: undefined,
  };

  public aggregationStateText = '';

  public tooltipText = `Утро = 2 ч. после восхода солнца;
    Вечер = 2 ч. после захода солнца;
    День = период от восхода до захода солнца (кроме двух утренних часов);
    Ночь = период от захода до восхода солнца (кроме двух вечерних часов).`;

  /** Настройка компоненты Объект, на котором произошла авария*/
  public significantObjectOptions: IScSelectOptions = {
    title: 'Объект, на котором произошла авария',
    service: 'Admin',
    entity: 'SignificantObjects',
    fieldName: 'name',
    modern: true,
    clearable: true,
    attributes: ['type.sysname'],
  };

  public chemicalTypeOptions: IScSelectOptions = {
    title: 'Тип АХОВ',
    service: 'Forecasting',
    entity: 'ChemicalType',
    fieldName: 'name',
    modern: true,
    clearable: true,
    additionalFields: ['aggregationState.name'],
  };
  /** Настройка компоненты Агрегатное состояние */
  public aggregationStateOptions: IScInputOptions = {
    label: 'Агрегатное состояние',
    disabled: true,
  };
  /** Настройка компоненты Количество АХОВ (в т.) */
  public chemicalAmountOptions: IScInputOptions = {
    label: 'Количество АХОВ (в т.)',
  };
  /** Настройка компоненты Общий запас АХОВ на объекте */
  public totalChemicalAmountOptions: IScCheckboxOptions = {
    name: 'Общий запас АХОВ на объекте',
  };
  /** Настройка компоненты Характер разлива*/
  public dischargeTypeOptions: IScSelectOptions = {
    title: 'Характер разлива',
    data: this.settings.getDictForSelect('emergencyChemicalSubstances'),
  };
  /** Настройка компоненты Площадь разлива (в м²) */
  public dischargeAreaOptions: IScInputOptions = {
    label: 'Площадь разлива (в м²)',
  };
  /** Настройка компоненты Доза облучения (в сГр) */
  public irradiationDoseOptions: IScInputOptions = {
    label: 'Доза облучения (в сГр)',
  };
  /** Настройка компоненты Скорость ветра (в м/с) */
  public windVelocityOptions: IScInputOptions = {
    label: 'Скорость ветра (в м/с)',
  };
  /** Настройка компоненты Направление ветра (в °) */
  public windDirectionOptions: IScInputOptions = {
    label: 'Направление ветра (в °)',
  };
  /** Настройка компоненты Наличие снежного покрова */
  public isSnowyOptions: IScCheckboxOptions = {
    name: 'Наличие снежного покрова',
  };
  /** Настройка компоненты Температура воздуха в момент аварии (в градусах Цельсия) */
  public airTemperatureOptions: IScInputOptions = {
    label: 'Температура воздуха в момент аварии (в градусах Цельсия)',
  };
  /** Настройка компоненты Облачность */
  public cloudStateOptions: IScSelectOptions = {
    title: 'Облачность',
    data: this.settings.getDictForSelect('cloudy'),
    clearable: true,
  };
  /** Настройка компоненты Время суток */
  public timeOfDayOptions: IScSelectOptions = {
    title: 'Время суток',
    data: this.settings.getDictForSelect('timesOfDay'),
    clearable: true,
  };

  /** Можно ли запрашивать метеоусловия */
  public canChangeMeteorologicalData: boolean = true;

  /**
   * @ignore
   */
  constructor(
    private readonly settings: Settings2Service,
    private readonly storedChemicalsService: StoredChemicalsService,
    public readonly controlContainer: ControlContainer,
    private readonly weatherService: WeatherDataService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;

    this.getStoredChemicals();

    this.form.controls.significantObjectId.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: string) => {
        if (this.isChemical() && value) {
          this.getStoredChemicals();
          this.form.controls.totalChemicalAmount.enable();
        } else {
          this.form.controls.totalChemicalAmount.disable();
        }
      });

    /** Подписка на изменение типа АХОВ для актуализации агрегатного состояния */
    this.form.controls.chemicalTypeId.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value: string) => {
        // не меняем значение при инициализации формы
        if (this.form.controls.chemicalTypeId.touched) {
          this.resetChemicalControls();
        }

        this.checkTotalChemicalAmountState();

        if (!value) {
          this.form.controls.aggregationStateId.setValue('');
          this.aggregationStateText = undefined;
          this.form.controls.chemicalAmount.disable();
          this.form.controls.chemicalAmount.setValue(null);
        } else {
          this.form.controls.chemicalAmount.enabled ? noop() : this.form.controls.chemicalAmount.enable();

          const selected = this.chemicalTypeOptions.data?.find((el: IScSelectItem) => el.id === value);
          if (selected) {
            this.aggregationStateText = selected.data['aggregationState.name'];
            this.form.controls.aggregationStateId.setValue((selected.data as IStoredChemicalDto).aggregationState);
          }
        }
      });

    /** Подписка на изменение контроля общий запас АХОВ */
    this.form.controls.totalChemicalAmount.valueChanges.pipe(untilDestroyed(this)).subscribe((value: boolean) => {
      if (value) {
        const chemicalTypeId = this.form.controls.chemicalTypeId.value;
        const selected = this.chemicalTypeOptions.data?.find((el: IScSelectItem) => el.id === chemicalTypeId)?.data;
        if (selected) {
          this.form.controls.chemicalAmount.setValue((selected as IStoredChemicalDto).chemicalAmount);
        }
      }
    });
  }

  /** Отображение блока с контролями для "Радиационной обстановки" */
  public isChemical() {
    return this.projectedRiskValue === 'chemicalDischarge';
  }

  /** Отображение блока с контролями для "Выброса АХОВ" */
  public isRadioactive() {
    return this.projectedRiskValue === 'radiationSituation';
  }

  /** Проверка агрегатного состояния АХОВ */
  public isLiquid(): boolean {
    return this.aggregationStateText === 'Жидкость';
  }

  /** Очистить контроли для химического объекта */
  private resetChemicalControls() {
    this.form.controls.chemicalAmount.reset();
    this.form.controls.totalChemicalAmount.reset();
    this.form.controls.dischargeTypeId.reset();
    this.form.controls.dischargeArea.reset();
  }

  /** Проверяем статус контрола */
  private checkTotalChemicalAmountState() {
    if (!!this.form.controls.significantObjectId.value && !!this.form.controls.chemicalTypeId.value) {
      this.form.controls.totalChemicalAmount.enable();
    } else {
      this.form.controls.totalChemicalAmount.setValue(false);
      this.form.controls.totalChemicalAmount.disable();
    }
  }

  /** Трансформируем данные */
  private getStoredChemicals() {
    const val = this.form.controls.significantObjectId.value;
    if (val) {
      this.storedChemicalsService
        .getStoredChemicalsForSignificantObject(val)
        .pipe(
          map((items: IStoredChemicalDto[]) => {
            return items.map((item: IStoredChemicalDto) => {
              return <IScSelectItem>{
                data: item,
                id: item.chemicalType,
                text: item['chemicalType.name'],
              };
            });
          }),
        )
        .subscribe((data: IScSelectItem[]) => {
          this.chemicalTypeOptions = {
            data,
            title: 'Тип АХОВ',
            fieldName: 'name',
            modern: true,
            clearable: true,
          };
        });
    } else {
      this.chemicalTypeOptions = {
        title: 'Тип АХОВ',
        service: 'Forecasting',
        entity: 'ChemicalType',
        fieldName: 'name',
        modern: true,
        clearable: true,
        additionalFields: ['aggregationState.name'],
      };
    }
  }

  /**
   * Запрос погоды
   * Обновляем только те контролы, которые доступны
   */
  public getWeatherData(): void {
    this.weatherService
      .getActualWeather(+dayjs())
      .pipe(untilDestroyed(this))
      .subscribe((data: IWeatherDataDto) => {
        this.form.controls.windVelocity.enabled ? this.form.controls.windVelocity.setValue(data?.windSpeed) : noop();
        this.form.controls.windDirection.enabled
          ? this.form.controls.windDirection.setValue(data?.windDirection)
          : noop();
        this.form.controls.airTemperature.enabled
          ? this.form.controls.airTemperature.setValue(data?.airTemperature)
          : noop();
        this.form.controls.cloudStateId.enabled ? this.form.controls.cloudStateId.setValue(data?.cloudy) : noop();
        this.form.controls.timeOfDayId.enabled ? this.form.controls.timeOfDayId.setValue(data?.timeOfDay) : noop();
        this.form.controls.isSnowy.enabled
          ? this.form.controls.isSnowy.setValue(parseFloat(data?.snowCoverThickness) > 0)
          : noop();
      });
  }

  /** Генерация FormGroup */
  public static generateFormGroup(fb: FormBuilder, params: IForecastingParamsDto) {
    return fb.group({
      significantObjectId: [params?.significantObjectId],
      chemicalTypeId: [params?.chemicalTypeId],
      aggregationStateId: [params?.aggregationStateId],
      chemicalAmount: [
        {
          value: params?.chemicalAmount,
          disabled: !params?.significantObjectId,
        },
        [Validators.pattern(FLOAT_PATTERN)],
      ],
      totalChemicalAmount: [
        {
          value: params?.totalChemicalAmount,
          disabled: !params?.significantObjectId,
        },
      ],
      dischargeTypeId: [params?.dischargeTypeId],
      dischargeArea: [params?.dischargeArea, [Validators.pattern(FLOAT_PATTERN)]],
      irradiationDose: [params?.irradiationDose, [Validators.pattern(FLOAT_PATTERN)]],
      windVelocity: [params?.windVelocity, [Validators.pattern(FLOAT_PATTERN)]],
      windDirection: [params?.windDirection, [Validators.pattern(FLOAT_PATTERN)]],
      isSnowy: [params?.isSnowy],
      airTemperature: [params?.airTemperature, [Validators.pattern(FLOAT_PATTERN)]],
      cloudStateId: [params?.cloudStateId],
      timeOfDayId: [params?.timeOfDayId],
    });
  }
}
