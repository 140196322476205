import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ISurveyAnswerDto } from 'smart-city-types';
import { BaseComponent } from '@bg-front/core/components';
import { IDeclarantInfoFormOptions, IDeclarer } from '../../models/interfaces';

/** Компонент Опрос для формы события справа */
@Component({
  selector: 'bg-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.scss'],
})
export class InterviewComponent extends BaseComponent implements OnInit {
  @Input()
  declarer: IDeclarer;
  @Input()
  disabled: boolean = false;

  /** Форма события */
  @Input()
  public eventForm: FormGroup;

  /** Id события */
  @Input()
  public eventId: string;

  /** Обработано или нет событие */
  @Input()
  public isHandled: boolean;

  /** Событие ответа на вопрос */
  @Output()
  public surveyAnswer = new EventEmitter<ISurveyAnswerDto>();

  /** Событие переответа на вопрос */
  @Output()
  public surveyReAnswer = new EventEmitter<ISurveyAnswerDto>();

  constructor() {
    super();
  }

  /** Сообщение о состояние блока, пришедшее из блока Подробная информация о КСиП */
  public ksipInfoMessage: string = 'parametersIsEmpty';

  public declarantInfoFormOptions: IDeclarantInfoFormOptions;

  /** @ignore */
  ngOnInit(): void {
    this.declarantInfoFormOptions = {
      disabled: this.disabled,
      model: this.declarer,
    };
  }

  /** Обработчик сообщения о состоянии блока Подробная информация о КСиП */
  public onKsipInfoMessage(val: string): void {
    this.ksipInfoMessage = val;
  }

  /** Обработчик события ответа на вопрос из дочернего компонента опроса */
  public onSurveyAnswer(answer: ISurveyAnswerDto): void {
    this.surveyAnswer.emit(answer);
  }

  /** Обработчик события переответа на вопрос из дочернего компонента опроса */
  public onSurveyReAnswer(answer: ISurveyAnswerDto): void {
    this.surveyReAnswer.emit(answer);
  }

}
