<div class="bg-row">
  <div class="bg-col-10">
    <h2>Внимание!</h2>
  </div>
</div>
<div class="bg-row">
  <div class="bg-col">
    <h4 md-dialog-content>{{message}}</h4>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="reject()">Отмена</button>
  <button mat-raised-button color="warn" cdkFocusInitial (click)="confirm()">Удалить</button>
</div>
