import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IScInputOptions, IScSelectOptions } from '@smart-city/core/common';
import { IForecastingTechnologicalFireAreaDto } from 'smart-city-types';
import { Coordinates } from '@bg-front/core/models/classes';
import { BaseComponent, MapDialogComponent } from '@bg-front/core/components';
import { Settings2Service } from '@smart-city/core/services';

@UntilDestroy()
@Component({
  selector: 'bg-forecasting-technological-fire-area-incident-form',
  templateUrl: './forecasting-technological-fire-area-incident-form.component.html',
  styleUrls: ['./forecasting-technological-fire-area-incident-form.component.scss'],
})
export class ForecastingTechnologicalFireAreaIncidentFormComponent extends BaseComponent implements OnInit {
  /** Ссылка на форму */
  private form: FormGroup;

  @Input()
  public coordinates: string;

  /**
   * Настройка компоненты Фактического адреса
   */
  public coordinatesOptions: IScInputOptions = {
    label: 'Координаты источника (широта, долгота)',
    maxLength: 100,
  };

  /** Настройка компонента "Величина угла" */
  public angleValueOptions: IScInputOptions = {
    label: 'Величина угла',
  };

  /** Настройка компоненты "Форма пожара" */
  public fireFormOptions: IScSelectOptions = {
    title: 'Форма пожара',
    clearable: true,
    data: this.settings.getDictForSelect('technologicalFireForm'),
  };

  /** Настройка компонента "Средства тушения пожара" */
  public fireFightingEquipmentsOptions: IScSelectOptions = {
    title: 'Средства тушения пожара',
    clearable: true,
    entity: 'FireFightingEquipment',
    service: 'Forecasting',
    modern: true,
    fieldName: 'name',
    fieldId: 'id',
  };

  /** Настройка компоненты "Вид объекта пожара" */
  public smallerSideOptions: IScInputOptions = {
    label: 'Величина меньшей стороны (в м)',
    mask: '0*,0*',
  };

  public windDirectionOptions: IScInputOptions = {
    label: 'Направление ветра (в *)',
    mask: '0*,0*',
  };

  /** Настройка компоненты "Кол-во направлений распространения огня" */
  public directionsCountOptions: IScSelectOptions = {
    title: 'Кол-во направлений распространения огня',
    clearable: true,
    data: [
      {
        id: 1,
        text: '1',
      },
      {
        id: 2,
        text: '2',
      },
    ],
  };

  /** Настройка компоненты "Способы тушения пожара прямоугольной формы" */
  public fireExtinguishingWaysOptions: IScSelectOptions = {
    title: 'Способы тушения пожара',
    clearable: true,
    data: this.settings.getDictForSelect('rectangularFireExtinguishingMethods'),
    modern: true,
  };

  /** Круглая или Угловая */
  public isRoundedForm = false;

  /** Прямоугольная */
  public isRectangular = false;

  /** @ignore */
  constructor(
    private readonly settings: Settings2Service,
    private readonly dialog: MatDialog,
    public readonly controlContainer: ControlContainer,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
    this.form.controls.technologicalFireFormId.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      this.checkFireForm(value);
    });
    this.checkFireForm(this.form.controls.technologicalFireFormId.value);
  }

  /**
   * Обработчик клика по кнопке Уточнить адрес
   */
  public onClickSpecifyAddress() {
    const exactCoordinates = new Coordinates(this.form.controls.coordinates.value);

    const data = {
      coordinates: exactCoordinates,
    };
    this.openSpecifyMap(data);
  }

  /** Метод который обеспечивает открытие диалога и подписку на закрытие
   * @param data - параметры для диалога
   */
  public openSpecifyMap(data: { coordinates?: Coordinates }): void {
    const dialogRef = this.dialog.open(MapDialogComponent, {
      data,
      width: '600px',
    });

    let dialogSub = dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        if (params?.coordinates) {
          this.form.controls.coordinates.setValue(`${params.coordinates[0]}, ${params.coordinates[1]}`);
        }
        if (dialogSub) {
          dialogSub.unsubscribe();
          dialogSub = null;
        }
      });
  }

  private checkFireForm(value) {
    this.isRoundedForm = false;
    this.isRectangular = false;
    if (value) {
      const dict = this.settings.getDictionaryById(value);
      if (['circular', 'angular'].includes(dict.sysname)) {
        this.isRoundedForm = true;
        if (dict.sysname === 'circular') {
          this.form.controls.angleValue.setValue('360');
          this.form.controls.angleValue.disable();
        } else {
          this.form.controls.angleValue.setValue(undefined);
          this.form.controls.angleValue.enable();
        }
      } else {
        this.isRectangular = true;
      }
    }
  }

  /** Генерация FormGroup */
  public static generateFormGroup(fb: FormBuilder, params: IForecastingTechnologicalFireAreaDto) {
    return fb.group({
      id: [params?.id ?? 1],
      coordinates: [params?.coordinates],
      technologicalFireFormId: [params?.technologicalFireFormId],
      angleValue: [params?.angleValue],
      fireFightingEquipmentId: [params?.fireFightingEquipmentId],
      smallerSide: [params?.smallerSide],
      windDirection: [params?.windDirection],
      directionsCount: [params?.directionsCount],
      fireExtinguishingWayId: [params?.fireExtinguishingWayId],
    });
  }
}
