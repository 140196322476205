import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { INwHeaderBarOptions, IScSelectOptions } from '@smart-city/core/common';
import { IDictionaryInfo, IScSelectItem } from '@smart-city/core/interfaces';
import { takeUntil } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';
import { BaseComponent } from '@bg-front/core/components';
import { Settings2Service } from '@smart-city/core/services';
import { IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { FilterOperationEnum } from '@bg-front/core/models/enums';

/**
 * Компонент фильтрации видеокамер на карте
 *  @example
 *  <map-video-camera-filter
 *    [mapLayerFilters]="data.mapLayersFilters['Видеокамеры']"
 *    (changedFilters)="onFiltersChange($event)"
 *  ></map-video-camera-filter>
 */
@Component({
  selector: 'map-video-camera-filter',
  templateUrl: './map-video-camera-filter.component.html',
  styleUrls: ['./map-video-camera-filter.component.scss'],
})
export class MapVideoCameraFilterComponent extends BaseComponent implements OnInit, OnChanges {
  /** Значения фильтров */
  @Input()
  public mapLayerFilters: IMapLayerEntityFilter[] = [];

  /** Оповещение о изменении значений фильтров */
  @Output()
  public changedFilters: EventEmitter<IMapLayerEntityFilter[] | 'delete'> = new EventEmitter<
    IMapLayerEntityFilter[] | 'delete'
  >();

  /** Форма */
  public form: FormGroup;

  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions = {
    margin: 'collapse',
    title: 'Видеокамеры',
    buttons: [
      {
        type: 'button',
        options: {
          name: 'delete',
          icon: 'delete_outline',
        },
      },
    ],
  };

  /** Настройка компоненты Наименование */
  public nameOptions: IScSelectOptions = {
    title: 'Наименование',
    clearable: true,
    service: 'Admin',
    entity: 'VideoDevices',
    modern: true,
    fieldName: 'name',
  };

  /** Настройка компоненты Состояние */
  public statusOptions: IScSelectOptions = {
    title: 'Состояние',
    clearable: true,
    modern: true,
    fieldName: 'name',
    data: this.settings.getDictForSelect('devicesState'),
  };

  /** Настройка компоненты Категория */
  public categoryIdsOptions: IScSelectOptions = {
    title: 'Категория',
    clearable: true,
    modern: true,
    fieldName: 'name',
    service: 'Admin',
    entity: 'VideoDeviceCategories',
    isMultiple: true,
  };

  /** Настройка компоненты Без категории */
  public isNoCategoryOptions: IScSelectOptions = {
    title: 'Без категории',
    clearable: true,
    data: [
      { id: 'true', text: 'Да' },
      { id: 'false', text: 'Нет' },
    ],
  };

  /** Настройка компоненты Предмет мониторинга */
  public monitoringSubjectOptions: IScSelectOptions = {
    title: 'Предмет мониторинга',
    clearable: true,
    modern: true,
    data: this.settings
      .getDictionaryByTypeSysName('monitoringSubject')
      .filter((el: IDictionaryInfo) => !['1', '2', '8', '32'].includes(el.sysname))
      .map(
        (el: IDictionaryInfo) =>
          <IScSelectItem>{
            id: el.id,
            text: el.name,
          },
      ),
  };

  /** @ignore */
  constructor(private readonly settings: Settings2Service) {
    super();
  }

  /** @ignore */
  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(this.mapLayerFilters.find((item) => item.property === 'id')?.value),
      // Т.к. в таблице хранится sysname записи из справочника,
      // то нужно в фильтр отдавать значение системного наименования, а при инициализации формы брать id.
      // UPD. Не переделывать на getDictionaryByTypeAndSysName.
      // Так как sysname является числом, поиск отрабатывает неверно.
      status: new FormControl(
        this.settings.getDictionaryByTypeSysName('devicesState').find((item: IDictionaryInfo) =>
          +item.sysname === +this.mapLayerFilters.find((item) => item.property === 'status')?.value
        )?.id,
      ),
      monitoringSubject: new FormControl(
        this.mapLayerFilters.find((item) => item.property === 'monitoringSubject')?.value,
      ),
      categoryIds: new FormControl(
        this.mapLayerFilters.find((item) => item.property === 'categoryIds')?.value,
      ),
      isNoCategory: new FormControl(
        this.mapLayerFilters.find((item) => item.property === 'isNoCategory')?.value,
      ),
    });
    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => this.changedFilters.emit(this.getFilters(value)));
  }

  /** @ignore */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue.length === 0) {
      this.form?.reset({}, { emitEvent: false });
    }
  }

  /**
   * Формирование фильтров на основании значения формы
   * @param value значение формы
   */
  getFilters(value: IAnyObject): IMapLayerEntityFilter[] {
    const result: IMapLayerEntityFilter[] = [];
    if (value.name) {
      result.push({
        property: 'id',
        value: value.name,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.status) {
      result.push({
        property: 'status',
        // Т.к. в таблице хранится sysname записи из справочника,
        // то нужно в фильтр отдавать значение системного наименования
        value: this.settings.getDictionaryById(value.status).sysname.toString(),
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.monitoringSubject) {
      result.push({
        property: 'monitoringSubject',
        value: value.monitoringSubject,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.categoryIds) {
      result.push({
        property: 'categoryIds',
        value: value.categoryIds,
        operation: FilterOperationEnum.in,
      });
    }
    if (value.isNoCategory) {
      result.push({
        property: 'isNoCategory',
        value: value.isNoCategory,
        operation: FilterOperationEnum.equal,
      });
    }
    return result;
  }

  public delete() {
    this.changedFilters.emit('delete');
    this.form.reset();
    this.form.enable();
  }
}
