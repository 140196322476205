import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  IElementButton,
  INwHeaderBarOptions,
} from '@smart-city/core/common';
import { ScNavService } from '@smart-city/core/services';
import { of } from 'rxjs';
import { BaseComponent } from '@bg-front/core/components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EmergencyService } from '../../services';
import { IMapBaseIcon, MapBaseModel, MapBaseService } from '@smart-city/maps/sc';
import { BgMapService } from '@bg-front/core/services';
import { IEmergencyInfo } from '../../models/interfaces';
import { GEOLOCATION_MARKER_SVG } from '@bg-front/core/models/constants';
import { Coordinates } from '@bg-front/core/models/classes';
import { LayersEnum } from '@bg-front/core/models/enums';

/** Отображение данных о происшествии на рабочем столе */
@UntilDestroy()
@Component({
  selector: 'emergency-info',
  templateUrl: 'emergency-info.component.html',
  styleUrls: ['emergency-info.component.scss'],
})
export class EmergencyInfoComponent extends BaseComponent implements OnInit {
  /** Данные о происшествии */
  public emergency: IEmergencyInfo = undefined;

  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions;

  /** Флаг. Выводить информацию о датах проведения работ */
  public showWorkTerms = false;

  /** Флаг. Выводить информацию о сроках исполнения */
  public showDeadline = false;

  /** ID происшествия */
  @Input()
  public set emergencyId(id: string) {
    this.emergency = undefined;
    this.service.getEmergencyForInfoComponent(id).subscribe((emergency: IEmergencyInfo) => {
      switch (emergency.docTypeSysName) {
        case 'incident':
          this.icon = 'sc-icon-incident';
          this.iconColor = '#FB4B53';
          break;
        case 'order':
          this.icon = 'sc-icon-order';
          this.iconColor = '#FB4B53';
          break;
        case 'plannedWork':
          this.icon = 'sc-icon-incident';
          this.iconColor = '#FFCC02';
          break;
      }
      this.emergency = emergency;
      // Информация о сроках проведения работ выводится в случае если есть информация о них
      // и в случае если происшествие является инцидентом или плановой работой.
      this.showWorkTerms = this.emergency.workTerms &&
        (this.emergency.docTypeSysName === 'incident' || this.emergency.docTypeSysName === 'plannedWork');
      // Информация о сроке исполнения выводится в случае если есть информация о нем
      // и в случае если происшествие является поручением.
      this.showDeadline = this.emergency.deadline && emergency.docTypeSysName === 'order';
      this.showCoverageArea(false);
    });
  }

  /** Иконка в заголовке */
  public icon: string;
  /** Цвет иконки в заголовке */
  public iconColor: string;
  /** Флаг. Отображается ли в данный момент зона действия происшествия */
  public isShownCoverageArea = false;
  /** Текст на кнопке отображения зоны действия */
  public coverageAreaButtonText = 'Зона действия';
  /** Цвет кнопки отображения зоны действия */
  public coverageAreaButtonColor = '#FFFFFF';
  /** Иконка для геометки */
  private pointIcon: IMapBaseIcon;
  /** Модель карты для инициализации */
  private mapModel: MapBaseModel;

  /** @ignore */
  constructor(
    private readonly service: EmergencyService,
    private readonly scNavService: ScNavService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly mapService: MapBaseService,
    private readonly gisService: BgMapService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.mapModel = new MapBaseModel('baseMapWorkspace', this.mapService);

    this.pointIcon = this.mapService.makeIcon({
      iconSize: 24,
      iconColor: 'black',
      iconSVG: GEOLOCATION_MARKER_SVG,
      shapeType: 'Нет',
      shapeSize: 24,
    });

    this.headerOptions = {
      margin: 'collapse',
      title: ' ',
      buttons: [
        {
          type: 'button',
          options: {
            name: 'burger',
            icon: 'menu',
            callback: () => {
              this.scNavService.openMenu();
              return of({ actionStream: 'donothing' });
            },
          },
        },
        {
          type: 'button',
          options: {
            name: 'close',
            icon: 'close',
            position: 'suffix',
            callback: () => {
              this.mapModel.removeLayer(LayersEnum.clickMarker);

              this.router.navigate([{ outlets: { leftPopup: null, viewForm: null } }], {
                relativeTo: this.route.parent,
                queryParamsHandling: 'merge',
              });
              return of();
            },
          },
        },
      ],
    };

    /** Подписываемся на параметры */
    this.route.params.pipe(untilDestroyed(this)).subscribe((routeParams: Params) =>
      this.emergencyId = routeParams['id']
    );
  }

  /**
   * Обработка событий из заголовка
   * @param $event событие
   */
  public clickHeaderButton($event: IElementButton): void {
    ($event.options.callback as any)();
  }

  /** Отображение зоны действия */
  public showCoverageArea(needShowCoverageArea?: boolean): void {
    this.isShownCoverageArea = needShowCoverageArea ?? !this.isShownCoverageArea;
    if (this.isShownCoverageArea) {
      this.coverageAreaButtonText = 'Скрыть зону действия';
      this.coverageAreaButtonColor = '#B6B3B3';
      this.mapModel.removeObject('circle', 'circleBig');
      this.mapModel.removeObject('circle', 'circleMin');
      this.drawCoverageArea();
    } else {
      this.coverageAreaButtonText = 'Зона действия';
      this.coverageAreaButtonColor = '#FFFFFF';
      this.mapModel.removeLayer('coverageArea');
      this.drawCircles();
    }
  }

  /** Отобразить геометки зоны действия */
  private drawCoverageArea(): void {
    this.emergency.coverageArea
      .map((point: string) => Coordinates.coordinatesToArray(point))
      .filter((point: [number, number]) => point[0] && point[1])
      .map((point: [number, number], index: number) => {
        this.mapModel.addMarkerToLayer(
          'coverageArea',
          String(index),
          point,
          this.pointIcon,
        );
      });
    this.mapModel.viewLayer('coverageArea', false);
  }

  /** Отобразить зонирование 50м/500м */
  private drawCircles() {
    this.mapModel.addCircleToLayer(
      'circle',
      'circleBig',
      Coordinates.coordinatesToArray(this.emergency.coordinates),
      500,
      {
        stroke: false,
        fillColor: '#FDD13A',
        fillOpacity: 0.3,
      },
    );
    this.mapModel.addCircleToLayer(
      'circle',
      'circleMin',
      Coordinates.coordinatesToArray(this.emergency.coordinates),
      50,
      {
        stroke: false,
        fillColor: '#DA1E28',
        fillOpacity: 0.4,
      },
    );
    this.mapModel.viewLayer('circle', false);
  }

  /** Позиционирование на координатах адреса происшествия */
  public selectCoordinates() {
    this.gisService.setPositionMapOnCoordinates(this.emergency.coordinates);
  }

  /** Открыть форму редактирования инцидента */
  public details() {
    this.router.navigate(
      [{ outlets: { editForm: [this.emergency.docTypeSysName, this.emergency.id] } }],
      { relativeTo: this.route.parent, queryParamsHandling: 'merge' },
    );
  }

  /** @ignore */
  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.mapModel.removeObject('circle', 'circleBig');
    this.mapModel.removeObject('circle', 'circleMin');
    this.mapModel.removeLayer('coverageArea');
    this.mapModel.removeLayer(LayersEnum.clickMarker);
  }
}
