import { Component, Input } from '@angular/core';

/**
 * Компонента для отображения предупреждающих диалогов
 */
@Component({
  selector: 'bg-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  /**
   * Контент как разметка
   */
  @Input()
  public isHtml = false;
  /**
   * Контент
   */
  @Input()
  public message: string = undefined;
}
