import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import {
  DialogService,
  IScCheckboxOptions,
  IScInputOptions,
  IScSelectOptions,
  IScTextareaOptions,
} from '@smart-city/core/common';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';
import {
  IAnyObject,
  IDds01FireParamsDto,
  IEmergencyDto,
  IEmergencyResolutionDto,
  ILifeCycleStepDto,
  ILifeCycleStepParamDto,
} from 'smart-city-types';
import { BaseIncidentFormComponent } from '../../../../../app-common/components/base-incident-form/base-incident-form.component';
import { BgLoosesComponent } from '../../../../../app-common/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { coordinatesValidator } from '@bg-front/core/validators';
import { AccessService, INotificationMessage, Settings2Service } from '@smart-city/core/services';
import { EmergencyDto } from '../../../../../app-common/models/classes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AtmIntegrationService,
  BgAdminService,
  EmergencyService,
  MiniMapService,
  OrganizationsService,
  ResponsePlanStepService,
} from '../../../../../app-common/services';
import { MatDialog } from '@angular/material/dialog';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { MultiFileService } from '@bg-front/core/services';
import { ReportingService } from '../../../reporting/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';

@UntilDestroy()
@Component({
  selector: 'bg-dds01-order-edit-form',
  templateUrl: './dds01-order-edit-form.component.html',
  styleUrls: ['./dds01-order-edit-form.component.scss'],
})
export class Dds01OrderEditFormComponent extends BaseIncidentFormComponent implements OnInit {
  /** Новая служба должна привлекаться через ЕДДС */
  protected readonly isInvolveThroughEdds: boolean = true;

  /** Список сообщений об ошибке */
  public errors: INotificationMessage[] = [];

  /** Настройка компоненты Комментарий */
  public optionsComment: IScTextareaOptions = {
    label: 'Комментарий оператора',
    maxLength: 1000,
    rows: 6,
  };
  /** Настройка компоненты Комментарий */
  public optionsDds01Comment: IScTextareaOptions = {
    label: 'Комментарий исполнителя',
    rows: 2,
  };

  /** Настройка компоненты Описание */
  public optionsDescription: IScTextareaOptions = {
    label: 'Описание',
    maxLength: 1000,
    rows: 6,
  };

  /** Настройка компоненты Ответсвенный пользователь */
  public optionsResponsibleUser: IScSelectOptions = {
    title: 'Ответственный пользователь',
    clearable: true,
    service: 'Admin',
    entity: 'Users',
    modern: true,
    fieldId: 'id',
    fieldName: 'fio',
  };

  /** Настройка компоненты Угроза населению */
  public optionsThreatPopulation: IScCheckboxOptions = {
    title: 'Угроза населению',
  };

  /** Настройка компоненты Угроза организации */
  public optionsThreatOrganization: IScCheckboxOptions = {
    title: 'Угроза организации',
  };

  /** Настройка компоненты Фактического адреса */
  public coordinatesOption: IScInputOptions = {
    label: 'Координаты фактического адреса',
    maxLength: 100,
  };

  /** Настройка компоненты Доп информация о месте КСиП */
  public placeDescriptionOptions: IScTextareaOptions = {
    label: 'Дополнительная информация о месте КСиП',
    maxLength: 1000,
    rows: 8,
  };

  /** Количество граждан, зарегистрированных в жилом доме */
  public totalRegistryPeopleHouseOptions: IScInputOptions = {
    label: 'Количество граждан, зарегистрированных в жилом доме',
    type: 'number',
  };

  /** Количество граждан, проживающих в жилом доме временно */
  public totalTemporalPeopleHouseOptions: IScInputOptions = {
    label: 'Количество граждан, проживающих в жилом доме временно',
    type: 'number',
  };
  /** Индивидуальное домовладение */
  public individualOwnershipOptions: IScCheckboxOptions = {
    title: 'Индивидуальное домовладение',
  };

  /** Объект ЖКХ */
  public jkhObjectOptions: IScSelectOptions = {
    title: 'Объект ЖКХ',
    clearable: true,
    modern: true,
    service: 'AtmIntegration',
    entity: 'MonitoringObject',
    fieldName: 'name',
    query: { active: true },
  };

  /** Отправить в "Реформу ЖКХ"  */
  public sendingToJkhReformOptions: IScCheckboxOptions = {
    title: 'Отправить в "Реформу ЖКХ"',
  };

  /** Тип ограничения ресурса  */
  public resourceConstraintTypeOptions: IScSelectOptions = {
    title: 'Тип ограничения ресурса',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'Dictionary',
    query: { 'typeid.sysname': 'resourceLimitationType' },
    fieldName: 'name',
  };

  /** Связанные ограничения  */
  public relatedRestrictionsOptions: IScCheckboxOptions = {
    title: 'Связанные ограничения',
  };

  /** Cфера ЖКХ */
  public jkhDirectionOptions: IScSelectOptions = {
    title: 'Сфера ЖКХ',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'IncidentCategories',
    width: '100%',
    isMultiple: true,
    fieldName: 'name',
    query: { 'sectionId.sysname': 'zhkh' },
  };

  /**
   * Объекты первой категории, затронутые ограничением
   */
  public favorableRestrictionObjectsOptions: IScTextareaOptions = {
    label: 'Объекты первой категории, затронутые ограничением',
    maxLength: 1000,
    rows: 8,
  };

  /**
   * Иные объекты, затронутые ограничением
   */
  public affectedRestrictionObjectsOptions: IScTextareaOptions = {
    label: 'Иные объекты, затронутые ограничением',
    maxLength: 1000,
    rows: 8,
  };

  /** Категория электроснабжения потребителей  */
  public consumerPowerCategoryIdOptions: IScSelectOptions = {
    title: 'Категория электроснабжения потребителей',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'Dictionary',
    query: { 'typeid.sysname': 'powerSupplyConsumerCategory' },
    fieldName: 'name',
  };

  /** Флаг для отображения контролов жкх */
  public isJhk: boolean = false;
  /** флаг на значение 'Эксплуатация жилищного фонда' */
  public isHousingService: boolean;
  /** Видимость Отправить в "Реформу ЖКХ" */
  public isSendingReformToJkhVisible: boolean;

  /** Центр миникарты */
  public miniMapCenter: MapBaseCoordinatesType;

  /** Зум для миникарты */
  public miniMapZoom: number;

  /** Координаты по умолчанию для мини карты */
  public defaultMarkerCoordinate: [number, number];

  /** Активность блока Привлекаемые службы */
  public orderInvolvedServiceEnabled: boolean = false;

  /** @ignore */
  constructor(
    accessService: AccessService,
    atmIntegrationService: AtmIntegrationService,
    bgAdminService: BgAdminService,
    dialog: MatDialog,
    dialogService: DialogService,
    emergencyService: EmergencyService,
    fb: FormBuilder,
    ksipCategoriesQuery: KsipCategoriesQuery,
    ksipDetailsQuery: KsipDetailsQuery,
    ksipSectionsQuery: KsipSectionsQuery,
    ksipTypesQuery: KsipTypesQuery,
    miniMapService: MiniMapService,
    multiFileService: MultiFileService,
    organizationsService: OrganizationsService,
    reportingService: ReportingService,
    route: ActivatedRoute,
    router: Router,
    settings: Settings2Service,
    significantObjectService: SignificantObjectsService,
    responsePlanStepService: ResponsePlanStepService,
  ) {
    super(
      accessService,
      atmIntegrationService,
      bgAdminService,
      dialog,
      dialogService,
      emergencyService,
      fb,
      ksipCategoriesQuery,
      ksipDetailsQuery,
      ksipSectionsQuery,
      ksipTypesQuery,
      miniMapService,
      multiFileService,
      organizationsService,
      reportingService,
      route,
      router,
      settings,
      significantObjectService,
      responsePlanStepService,
    );
  }

  /** @ignore */
  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data: Data) => {
      if (data.model) {
        this.model = data.model as EmergencyDto;
      }
      const parentId = this.model.parentId as IEmergencyDto;
      // Активность блока Привлекаемые службы
      this.orderInvolvedServiceEnabled = this.accessService.accessMap['OrderInvolvedServiceEnabled']?.visible
        && this.accessService.accessMap['OrderInvolvedServiceEnabled']?.enabled;
      this.incidentForm = new FormGroup({
        /** ----- MAIN ----- **/
        byCoordinates: new FormControl(this.model.byCoordinates),
        addressFact: new FormControl({ value: this.model.addressFact, disabled: true }, [Validators.required]),
        coordinatesAddress: new FormControl(this.model.byCoordinates
          ? (this.model.addressFact.fullText || this.model.address.fullText)
          : null
        ),
        moId: new FormControl(this.model.moId),
        districtId: new FormControl({ value: this.model.districtId, disabled: true }),
        detailsFact: new FormControl({ value: this.model.detailsFact, disabled: true }),
        incidentTypeId: new FormControl(
          {
            value: this.model.incidentTypeId || parentId.incidentTypeId,
            disabled: true,
          },
          [Validators.required],
        ),
        ksipDetailsId: new FormControl({ value: this.model.ksipDetailsId || parentId.ksipDetailsId, disabled: true }),
        comment: new FormControl({
          value: parentId.comment || this.model.comment || null,
          disabled: true,
        }),
        description: new FormControl({
          value: parentId.description || this.model.description,
          disabled: true,
        }),
        responsibleUser: new FormControl({ value: this.model.responsible || this.settings.currentUser.id, disabled: true }, [Validators.required]),
        deadline: new FormControl(this.model.deadline),
        organization: new FormControl({ value: this.model.organization, disabled: true }),
        urgently: new FormControl({
          value: (parentId.urgently || this.model.urgently) ?? false,
          disabled: true,
        }),
        danger: new FormControl({
          value: (parentId.resolution || this.model.resolution || <IEmergencyResolutionDto>{}).danger ?? false,
          disabled: true,
        }),
        dangerOrg: new FormControl({
          value: (parentId.resolution || this.model.resolution || <IEmergencyResolutionDto>{}).dangerOrg ?? false,
          disabled: true,
        }),
        supervisedByCuks: new FormControl({ value: this.model.supervisedByCuks, disabled: true }),
        /** ----- PARAMS ----- **/
        fireParams: new FormControl({ value: this.model.fireParams || <IDds01FireParamsDto>{}, disabled: true }),
        /** ----- PLACEMENT ----- **/
        coordinates: new FormControl({ value: parentId.coordinates || this.model.coordinates, disabled: true }, [
          coordinatesValidator(),
        ]),
        ksipPlaceDescription: new FormControl({ value: this.model.ksipPlaceDescription, disabled: true }),
        /** ----- ADDITIONAL ----- **/
        dds01AdditionalInfo: new FormControl({ value: this.model.dds01AdditionalInfo, disabled: true }),
        dds01Comment: new FormControl({
          value: this.model.dds01AdditionalInfo?.comment,
          disabled: true,
        }),
        /** ----- LOOSES ----- **/
        resolution: BgLoosesComponent.generateFormGroup(this.fb, {
          ...this.model.resolution,
          peopleInTheZoneOnDate: this.model.resolution?.peopleInTheZoneOnDate || this.model.timeCreate,
          deathOnDate: this.model.resolution?.deathOnDate || this.model.timeCreate,
          rescuedOnDate: this.model.resolution?.rescuedOnDate || this.model.timeCreate,
          victimOnDate: this.model.resolution?.victimOnDate || this.model.timeCreate,
          hospitalizedOnDate: this.model.resolution?.hospitalizedOnDate || this.model.timeCreate,
          evacuatedOnDate: this.model.resolution?.evacuatedOnDate || this.model.timeCreate,
          missingOnDate: this.model.resolution?.missingOnDate || this.model.timeCreate,
        }),
        /** ----- DOCS ----- **/
        documents: new FormControl({ value: this.model.documents, disabled: true }),
        mapMarkerCoordinate: new FormControl(this.model?.mapMarkerCoordinate),
        totalRegistryPeopleHouse: new FormControl({ value: this.model?.totalRegistryPeopleHouse || 0, disabled: true }),
        totalTemporalPeopleHouse: new FormControl({ value: this.model?.totalTemporalPeopleHouse || 0, disabled: true }),
        individualOwnership: new FormControl({ value: this.model?.individualOwnership, disabled: true }),
        jkhObject: new FormControl({ value: this.model?.jkhObject, disabled: true }),
        favorableRestrictionObjects: new FormControl({
          value: this.model?.favorableRestrictionObjects,
          disabled: true,
        }),
        affectedRestrictionObjects: new FormControl({ value: this.model?.affectedRestrictionObjects, disabled: true }),
        resourceConstraintType: new FormControl({ value: this.model?.resourceConstraintType, disabled: true }),
        relatedRestrictions: new FormControl({ value: this.model?.relatedRestrictions, disabled: true }),
        jkhDirection: new FormControl({ value: this.model?.jkhDirection, disabled: true }),
        consumerPowerCategoryId: new FormControl(this.model.consumerPowerCategoryId, [Validators.required]),
        isSendingToJkhReform: new FormControl({ value: this.model?.isSendingToJkhReform, disabled: true }),
        workTermFrom: new FormControl({ value: this.model?.workTermFrom, disabled: true }),
        workTermTo: new FormControl({ value: this.model?.workTermTo, disabled: true }),
      });

      super.ngOnInit();

      this.optionsResponsibleUser.query = { isActive: true, organizationId: this.model.organization };

      this.actions = (this.model?.lifeCycleStepId as ILifeCycleStepDto)?.actions || [];
      this.generateHeaderBar();

      this.headerActionsOptions.title = `Поручение ${this.model.number ?? ''}`;

      this.attributes = (this.model.lifeCycleStepId as ILifeCycleStepDto).params;

      (this.model.lifeCycleStepId as ILifeCycleStepDto)?.params?.forEach((param: ILifeCycleStepParamDto) => {
        if (param.enabled) {
          this.incidentForm.controls[param.attribute]?.enable({
            emitEvent: false,
          });
        } else {
          this.incidentForm.controls[param.attribute]?.disable({
            emitEvent: false,
          });
        }
      });

      this.isEmergencyEndStepCycle = (this.model.lifeCycleStepId as ILifeCycleStepDto)?.endOfCycle;
      const markerCoordinates = new Coordinates(this.model.mapMarkerCoordinate || this.model.coordinates);
      if (markerCoordinates.isValid()) {
        this.defaultMarkerCoordinate = markerCoordinates.toArray();
      }

      if (this.disabled) {
        this.incidentForm.disable();
      }

      this.initReactPlan();
      super.setViewedField();

      const masterFormValue = this.incidentForm.getRawValue();
      this.ngMasterValue = new BehaviorSubject<IAnyObject>(masterFormValue);
      this.isDirty$ = dirtyCheck(this.incidentForm, this.ngMasterValue.asObservable());


    });
  }

  /** Получение данных из формы и сохранение в модель */
  public getModelData() {
    if (!this.model.id) {
      this.model.timeCreate = dayjs().unix() * 1000;
      this.model.number = `o-${dayjs(this.model.timeCreate).format('YYYYMMDD-HHmmss')}`;
      this.model.organization = this.settings.currentUser.organizationId.id;
      this.model.createdBy = this.settings.currentUser.id;
    }
    if (typeof this.model.parentId === 'object') {
      this.model.parentId = (this.model.parentId as IEmergencyDto).id;
    }
    /** ----- MAIN ----- **/
    this.model.byCoordinates = this.incidentForm.controls['byCoordinates'].value;
    this.model.addressFact = this.model.byCoordinates
      ? this.addressByCoordinates
      : this.incidentForm.controls['addressFact'].value;
    this.model.moId = <string>this.moIdComponent.value;
    this.model.districtId = <string>this.districtIdComponent.value;
    this.model.detailsFact = this.incidentForm.controls['detailsFact'].value;
    this.model.incidentTypeId = this.incidentForm.controls['incidentTypeId'].value;
    this.model.ksipDetailsId = this.incidentForm.controls['ksipDetailsId'].value;
    this.model.comment = this.incidentForm.controls['comment'].value;
    this.model.description = this.incidentForm.controls['description'].value;
    this.model.responsible = this.incidentForm.controls['responsibleUser'].value;
    /** ----- LOOSES ----- **/
    this.model.resolution = this.incidentForm.controls['resolution'].value || <IEmergencyResolutionDto>{};
    this.model.urgently = this.incidentForm.controls['urgently'].value;
    this.model.resolution.danger = this.incidentForm.controls['danger'].value;
    this.model.resolution.dangerOrg = this.incidentForm.controls['dangerOrg'].value;
    this.model.supervisedByCuks = this.incidentForm.controls['supervisedByCuks'].value;
    /** ----- PARAMS ----- **/
    this.model.fireParams = this.incidentForm.controls['fireParams'].value || <IDds01FireParamsDto>{};
    /** ----- PLACEMENT ----- **/
    this.model.coordinates = this.incidentForm.controls['coordinates'].value;
    this.model.ksipPlaceDescription = this.incidentForm.controls['ksipPlaceDescription'].value;
    /** ----- ADDITIONAL ----- **/
    this.model.dds01AdditionalInfo = this.incidentForm.controls['dds01AdditionalInfo'].value || {};
    this.model.dds01AdditionalInfo.comment = this.incidentForm.controls['dds01Comment'].value;
    // Значения по умолчанию
    if (!this.model?.dds01AdditionalInfo?.timeDetection) {
      this.model.dds01AdditionalInfo.timeDetection = this.model.timeCreate;
    }
    /** ----- DOCS ----- **/
    this.model.documents = this.incidentForm.controls['documents'].value;

    this.model.totalRegistryPeopleHouse = Number(this.incidentForm.controls['totalRegistryPeopleHouse'].value) || 0;
    this.model.totalTemporalPeopleHouse = Number(this.incidentForm.controls['totalTemporalPeopleHouse'].value) || 0;
    this.model.individualOwnership = this.incidentForm.controls['individualOwnership'].value;
    this.model.jkhObject = this.incidentForm.controls['jkhObject'].value;
    this.model.favorableRestrictionObjects = this.incidentForm.controls['favorableRestrictionObjects'].value;
    this.model.affectedRestrictionObjects = this.incidentForm.controls['affectedRestrictionObjects'].value;
    this.model.resourceConstraintType = this.incidentForm.controls['resourceConstraintType'].value;
    this.model.relatedRestrictions = this.incidentForm.controls['relatedRestrictions'].value;
    this.model.jkhDirection = this.incidentForm.controls['jkhDirection'].value;
    this.model.consumerPowerCategoryId = this.incidentForm.controls['consumerPowerCategoryId'].value
      ? this.incidentForm.controls['consumerPowerCategoryId'].value
      : undefined;
    this.model.isSendingToJkhReform = this.incidentForm.controls['isSendingToJkhReform'].value;
    this.model.workTermFrom = this.incidentForm.controls['workTermFrom'].value;
    this.model.workTermTo = this.incidentForm.controls['workTermTo'].value;
  }

  /** Обновление формы */
  public updateForm() {
    this.generateHeaderBar();
    this.headerActionsOptions.title = `Поручение ${this.model.number ?? ''}`;

    (this.model.lifeCycleStepId as ILifeCycleStepDto)?.params?.forEach((param: ILifeCycleStepParamDto) => {
      if (param.enabled) {
        this.incidentForm.controls[param.attribute]?.enable({
          emitEvent: false,
        });
      } else {
        this.incidentForm.controls[param.attribute]?.disable({
          emitEvent: false,
        });
      }
    });
  }

  /** Закрытие формы */
  public closeForm() {
    if (this.workspace === 'consolidated-registries') {
      this.router.navigate(['consolidated-registries/order-register']);
    } else {
      this.emergencyService.selectIncident({ id: undefined, docType: 'incident' });
      this.router.navigate([{ outlets: { editForm: null, editEventForm: null } }], {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge',
      });
    }
  }
}
