import { AfterViewInit, Component, ComponentFactoryResolver, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError } from 'rxjs/operators';
import { IEmergencyResponsePlanStep, IOrganizationType, IStepDialogDataOptions } from '../../models/interfaces';
import { OrganizationsService, ResponsePlanStepService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';
import { InvolveOrgWithoutCommissionFormComponent } from '../involve-org-without-commission-form/involve-org-without-commission-form.component';
import { InvolveOrgWithoutInteractionFormComponent } from '../involve-org-without-interaction-form/involve-org-without-interaction-form.component';
import { NewInvolveOrgFormComponent } from '../new-involve-org-form/new-involve-org-form.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrgInvolveRequestFormComponent } from "../org-involve-request-form/org-involve-request-form.component";

/**
 *  Форма создания шага для плана реагирования в боке Привлекаемые службы по кнопки +
 */
@UntilDestroy()
@Component({
  selector: 'bg-create-involve-step',
  templateUrl: './create-involve-step.component.html',
  styleUrls: ['./create-involve-step.component.scss'],
})
export class CreateInvolveStepComponent extends BaseComponent implements AfterViewInit, OnInit {

  /** Контейнер */
  @ViewChild('formContainer', { read: ViewContainerRef, static: false })
  public formContainer: ViewContainerRef;
  /** Заголовок  диалога */
  public title: string = '';
  /** Имя выбранной службы */
  public serviceTypeName: string = '';

  constructor(
    private readonly responsePlanStepService: ResponsePlanStepService,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    public readonly dialogRef: MatDialogRef<IStepDialogDataOptions>,
    private organizationsService: OrganizationsService,
    @Inject(MAT_DIALOG_DATA) public data: IStepDialogDataOptions,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.title = this.data.isInvolveThroughEdds ? 'Запрос на привлечение службы' : 'Новая привлекаемая служба';
  }

  /** @ignore */
  public ngAfterViewInit(): void {
    setTimeout(() => this.data.isInvolveThroughEdds
      ? this.orgInvolveRequestFormInjection()
      : this.newInvolveOrgFormInjection(),
      0);
  }

  /** Инъекция формы с возможностью выбора привлекаемой службы */
  private newInvolveOrgFormInjection() {
    const component = this.formContainer.createComponent<NewInvolveOrgFormComponent>(
      this.componentFactoryResolver.resolveComponentFactory(NewInvolveOrgFormComponent),
    ).instance;
    if (component) {
      component.nextEvent.pipe(untilDestroyed(this)).subscribe((value) => {
        if (value) this.nextHandler(value);
      });
      component.closeEvent.pipe(untilDestroyed(this)).subscribe(() => this.closeHandler());
    }
  }

  /** Инъекция формы с запросом на привлечение службы */
  private orgInvolveRequestFormInjection() {
    const component = this.formContainer.createComponent<OrgInvolveRequestFormComponent>(
      this.componentFactoryResolver.resolveComponentFactory(OrgInvolveRequestFormComponent),
    ).instance;
    if (component) {
      component.options = {
        emergencyModel: this.data.emergencyModel,
        isInvolveThroughEdds: this.data.isInvolveThroughEdds,
      };
      component.closeEvent.pipe(untilDestroyed(this)).subscribe(() => this.closeHandler());
    }
  }

  /** Обработчик для нажатия на кнопку Далее */
  public nextHandler(serviceType): void {
    this.organizationsService.getAttractReactOrganizationById(serviceType)
    .pipe(
      untilDestroyed(this),
      catchError((err: Error) => this.catchErrorFn<IOrganizationType>(err, '')),
    ).subscribe((res) => {
      this.formContainer.clear();
      if (res.informationInteraction) {
        this.createStepWithInformation(res);
      } else {
        this.createStepWithOutInformation(res);
      }
    });
  }

  /** Метод который инжектирует формы для создания шага реагирования для организации без информационного взаимодействия
   * @param serviceType - параметр типа организации
   * @return
   */
  public createStepWithOutInformation(serviceType: IOrganizationType): void {
    // получаем время реагирования из уже существующих шагов с таким же типом организации
    this.responsePlanStepService.getLastEmergencyStepForService(this.data.emergencyModel.id,serviceType.id)
    .pipe(untilDestroyed(this))
    .subscribe((res: IEmergencyResponsePlanStep)=>{
      const component = this.formContainer.createComponent<InvolveOrgWithoutInteractionFormComponent>(
        this.componentFactoryResolver.resolveComponentFactory(InvolveOrgWithoutInteractionFormComponent),
      ).instance;
      component.options = {
        serviceType: serviceType.id,
        emergencyModel: this.data.emergencyModel,
        entity: 'involvedOrgOpts',
        timeReact: res?.timeReact,
        responseTime: res?.responseTime,
      };
      this.serviceTypeName = serviceType.shortName;
      component.closeEvent
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.closeHandler();
      });
      component.saveEvent
      .pipe(untilDestroyed(this))
      .subscribe((list) => {
        this.closeHandler();
        this.formContainer.clear();
      });
    })
  }

  /** Метод который инжектирует форму для создания шага реагирования для организации c информационным взаимодействием
   * @param serviceType - параметр типа организации
   * @return
   */
  public createStepWithInformation(serviceType: IOrganizationType): void {
    // получаем время реагирования из уже существующих шагов с таким же типом организации
    this.responsePlanStepService.getLastEmergencyStepForService(this.data.emergencyModel.id,serviceType.id)
    .pipe(untilDestroyed(this))
    .subscribe((res: IEmergencyResponsePlanStep)=>{
      const component = this.formContainer.createComponent(
        this.componentFactoryResolver.resolveComponentFactory(InvolveOrgWithoutCommissionFormComponent),
      ).instance;
      component.options = {
        serviceType: serviceType.id,
        emergencyModel: this.data.emergencyModel,
        entity: 'involvedOrgOpts',
        timeReact: res?.timeReact,
        responseTime: res?.responseTime,
      };
      component.closeEvent
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.closeHandler();
        this.formContainer.clear();
      });
      component.saveEvent
      .pipe(untilDestroyed(this))
      .subscribe((list) => {
        this.closeHandler();
        this.formContainer.clear();
      });
    })
  }

  /** Метод закрытия диалога */
  public closeHandler(): void {
    this.dialogRef.close();
  }
}
