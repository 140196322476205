import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { KsipTypesState } from '../../../models/states';

/** Хранилище Типов КСиП */
@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'ksip-types' })
export class KsipTypesStore extends EntityStore<KsipTypesState> {
  constructor() {
    super([]);
  }
}
