/** Таблица соответствий имен кнопок и иконок */
export const BUTTON_ICONS = new Map<string, string>(
  [
    ['save', 'done'],
    ['saveIncident', 'done'],
    ['takeInWork', 'event_note'],
    ['declareFalse', 'event_note'],
    ['transferToAnotherMO', 'open_in_browser'],
    ['escalateToCuks', 'send'],
    ['informCuks', 'notifications'],
    ['more_vert', 'more_vert'],
    ['cancel', 'clear'],
    ['approve', 'done'],
    ['disapprove', 'content_paste_off'],
    ['takeOnControl', 'event_available']
  ],
);
