import {
  IExposurePopulationOvercomingCloudTrailTaskDto,
  INuclearPollutionPointTaskDto,
  INuclearPollutionTaskDto,
  IOvercomingPathPointDto,
  IPopulationIrradiationPointTaskDto,
  IRadioactiveCloudTimeTaskDto,
  IThyroidIrradiationTaskDto,
  ITimeAllowedToOvercomeCloudTrailTaskDto,
} from '../interfaces';
import { coordinatesValidator } from '@bg-front/core/validators';
import { FLOAT_PATTERN } from '@bg-front/core/models/constants';
import { FormBuilder, Validators } from '@angular/forms';

export class ForecastingRadioactiveEnvironmentTasksFormFactory {
  /** Генерация FormGroup */
  public static nuclearPollutionTaskFormGroup(fb: FormBuilder, params: INuclearPollutionTaskDto) {
    return fb.group({
      inputTypeId: [params?.inputTypeId, [Validators.required]],
      unitRadiationId: [params?.unitRadiationId, [Validators.required]],
      dose: [params?.dose, [Validators.pattern(FLOAT_PATTERN)]],
      timeSinceAccident: [params?.timeSinceAccident, [Validators.pattern(FLOAT_PATTERN)]],
      windVelocity: [params?.windVelocity, [Validators.pattern(FLOAT_PATTERN)]],
      windDirection: [params?.windDirection, [Validators.pattern(FLOAT_PATTERN)]],
      isSnowy: [params?.isSnowy],
      airTemperature: [params?.airTemperature, [Validators.pattern(FLOAT_PATTERN)]],
      cloudStateId: [params?.cloudStateId],
      timeOfDayId: [params?.timeOfDayId],
    });
  }

  /** Генерация FormGroup */
  public static thyroidIrradiationTaskFormGroup(fb: FormBuilder, params: IThyroidIrradiationTaskDto) {
    return fb.group({
      inputTypeId: [params?.inputTypeId, [Validators.required]],
      unitRadiationId: [params?.unitRadiationId, [Validators.required]],
      dose: [params?.dose, [Validators.pattern(FLOAT_PATTERN)]],
      windVelocity: [params?.windVelocity, [Validators.pattern(FLOAT_PATTERN)]],
      windDirection: [params?.windDirection, [Validators.pattern(FLOAT_PATTERN)]],
      isSnowy: [params?.isSnowy],
      airTemperature: [params?.airTemperature, [Validators.pattern(FLOAT_PATTERN)]],
      cloudStateId: [params?.cloudStateId],
      timeOfDayId: [params?.timeOfDayId],
    });
  }

  /** Генерация FormGroup */
  public static radioactiveCloudTimeTaskFormGroup(fb: FormBuilder, params: IRadioactiveCloudTimeTaskDto) {
    return fb.group({
      distanceToReactor: [params?.distanceToReactor, [Validators.pattern(FLOAT_PATTERN), Validators.required]],
      windVelocity: [params?.windVelocity, [Validators.pattern(FLOAT_PATTERN)]],
      windDirection: [params?.windDirection, [Validators.pattern(FLOAT_PATTERN)]],
      isSnowy: [params?.isSnowy],
      airTemperature: [params?.airTemperature, [Validators.pattern(FLOAT_PATTERN)]],
      cloudStateId: [params?.cloudStateId],
      timeOfDayId: [params?.timeOfDayId],
    });
  }

  /** Генерация FormGroup */
  public static populationIrradiationPointTaskFormGroup(fb: FormBuilder, params: IPopulationIrradiationPointTaskDto) {
    return fb.group({
      timeTillIrradiationStart: [params?.timeTillIrradiationStart, [Validators.min(0.1), Validators.max(2161)]],
      timeTillIrradiationEnd: [params?.timeTillIrradiationEnd, [Validators.min(1), Validators.max(8640)]],
      isRadioactiveCouldApproaching: [params?.isRadioactiveCouldApproaching],
      unitRadiationId: [params?.unitRadiationId, [Validators.required]],
      coordinatesTarget: [
        params?.coordinatesTarget,
        [
          Validators.maxLength(100),
          Validators.pattern(/(-?\d{1,2}[.]\d+)[,][ ]+(-?\d{1,3}[.]\d+)/),
          coordinatesValidator(),
        ],
      ],
      shelterLocationId: [params?.shelterLocationId],
      shelterTypeId: [params?.shelterTypeId],
      shelterFloorId: [params?.shelterFloorId],
      isIodineProphylaxisPerformed: [params?.isIodineProphylaxisPerformed],
      windVelocity: [params?.windVelocity, [Validators.pattern(FLOAT_PATTERN)]],
      windDirection: [params?.windDirection, [Validators.pattern(FLOAT_PATTERN)]],
      isSnowy: [params?.isSnowy],
      airTemperature: [params?.airTemperature, [Validators.pattern(FLOAT_PATTERN)]],
      cloudStateId: [params?.cloudStateId],
      timeOfDayId: [params?.timeOfDayId],
    });
  }

  public static overcomingPathPointFormGroup(fb: FormBuilder, params: IOvercomingPathPointDto) {
    return fb.group({
      coordinates: [params?.coordinates, [Validators.required, coordinatesValidator()]],
      dose: [params?.dose, [Validators.pattern(FLOAT_PATTERN)]],
    });
  }

  /** Генерация FormGroup */
  public static timeAllowedToOvercomeCloudTrailTaskFormGroup(
    fb: FormBuilder,
    params: ITimeAllowedToOvercomeCloudTrailTaskDto,
  ) {
    const array = params?.pathPoints?.length
      ? params?.pathPoints.map((el: IOvercomingPathPointDto) => this.overcomingPathPointFormGroup(fb, el))
      : [this.overcomingPathPointFormGroup(fb, undefined)];
    return fb.group({
      unitRadiationId: [params?.unitRadiationId, [Validators.required]],
      travelSpeed: [params?.travelSpeed, [Validators.pattern(FLOAT_PATTERN)]],
      acceptableDose: [params?.acceptableDose, [Validators.pattern(FLOAT_PATTERN)]],
      timeAfterEjection: [params?.timeAfterEjection, [Validators.pattern(FLOAT_PATTERN)]],
      shelterLocationId: [params?.shelterLocationId],
      shelterTypeId: [params?.shelterTypeId],
      shelterFloorId: [params?.shelterFloorId],
      pathPoints: fb.array([...array]),
    });
  }

  /** Генерация FormGroup для задачи "Расчёт и результатов для оценки степени рад. загрязнения в точке"*/
  public static nuclearPollutionPointTaskFormGroup(fb: FormBuilder, params: INuclearPollutionPointTaskDto) {
    return fb.group({
      unitRadiationId: [params?.unitRadiationId, [Validators.required]],
      coordinatesTarget: [
        params?.coordinatesTarget,
        [
          Validators.maxLength(100),
          Validators.pattern(/(-?\d{1,2}[.]\d+)[,][ ]+(-?\d{1,3}[.]\d+)/),
          coordinatesValidator(),
        ],
      ],
      timeSinceAccident: [params?.timeSinceAccident, [Validators.pattern(FLOAT_PATTERN)]],

      windVelocity: [params?.windVelocity, [Validators.pattern(FLOAT_PATTERN)]],
      windDirection: [params?.windDirection, [Validators.pattern(FLOAT_PATTERN)]],
      isSnowy: [params ? params.isSnowy : false],
      airTemperature: [params?.airTemperature, [Validators.pattern(FLOAT_PATTERN)]],
      cloudStateId: [params?.cloudStateId],
      timeOfDayId: [params?.timeOfDayId],
    });
  }

  /** Генерация FormGroup */
  public static exposurePopulationOvercomingCloudTrailTaskFormGroup(
    fb: FormBuilder,
    params: IExposurePopulationOvercomingCloudTrailTaskDto,
  ) {
    const array = params?.pathPoints?.length
      ? params?.pathPoints.map((el: IOvercomingPathPointDto) => this.overcomingPathPointFormGroup(fb, el))
      : [this.overcomingPathPointFormGroup(fb, undefined)];
    return fb.group({
      unitRadiationId: [params?.unitRadiationId, [Validators.required]],
      travelSpeed: [params?.travelSpeed, [Validators.pattern(FLOAT_PATTERN)]],

      shelterLocationId: [params?.shelterLocationId],
      shelterTypeId: [params?.shelterTypeId],
      shelterFloorId: [params?.shelterFloorId],
      pathPoints: fb.array([...array]),
    });
  }
}
