import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { MapObjectsState } from '../../../models/states';

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'map-base-objects' })
export class MapObjectsStore extends EntityStore<MapObjectsState> {
  constructor() {
    super([]);
  }
}
