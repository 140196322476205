import { Injectable } from '@angular/core';
import { RestService } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData, IWantedRegMarkDto } from 'smart-city-types';

/**
 * Сервис для работы с разыскиваемыми ГРЗ
 */
@Injectable({
  providedIn: 'root',
})
export class RegMarksService {
  constructor(private readonly rest: RestService) {}

  /**
   * Получение данных о разыскиваемом ГРЗ
   * @param markId id ГРЗ
   */
  getWantedRegMask(markId: string): Observable<IWantedRegMarkDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'WantedRegMarks',
          query: {
            id: markId,
          },
        },
      })
      .pipe(map((data: IAbstractServiceData) => data.data.items[0] as IWantedRegMarkDto));
  }
}
