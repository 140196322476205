import { Component, Input, OnChanges, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';

/**
 * Отображает данные о том когда запись создана/обновлена
 *  @example
 *  <create-update-info
 *    [createTitle]="'Создание запроса'"
 *    [createTime]="1300000000"
 *    [updateTitle]="'Формирование отчета'"
 *    [updateTime]="1300000000"
 *  ></create-update-info>
 */
@Component({
  selector: 'create-update-info',
  templateUrl: './create-update-info.component.html',
  styleUrls: ['./create-update-info.component.scss'],
})
export class CreateUpdateInfoComponent implements OnInit, OnChanges {
  @Input() public createTitle: string;
  @Input() public updateTitle: string;
  @Input() public createTime: number | string;
  @Input() public updateTime: number | string;

  public formatCreateTime: string;
  public formatUpdateTime: string;



  /** @ignore */
  public ngOnInit(): void {
    this.formatCreateTime = !!this.createTime ? dayjs(this.createTime).format('DD.MM.YYYY HH:mm:ss') : '';
    this.formatUpdateTime = !!this.updateTime ? dayjs(this.updateTime).format('DD.MM.YYYY HH:mm:ss') : '';
  }

  /** @ignore */
  public ngOnChanges(): void {
    this.formatCreateTime = !!this.createTime ? dayjs(this.createTime).format('DD.MM.YYYY HH:mm:ss') : '';
    this.formatUpdateTime = !!this.updateTime ? dayjs(this.updateTime).format('DD.MM.YYYY HH:mm:ss') : '';
  }
}
