<div class="dialog-container">
  <div class="bg-row">
    <div class="bg-col">
      <h4><strong>Сведения об электронной подписи</strong></h4>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col-5">
      <div class="bg-form-group">
        <strong>Наименование метки:</strong>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        {{data.signature.certificate
          ? 'Подписано усиленной квалифицированной электронной подписью'
          : 'Подписано простой электронной подписью'
        }}
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col-5">
      <div class="bg-form-group">
        <strong>Сертификат:</strong>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        {{data.signature.certificate || '-'}}
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col-5">
      <div class="bg-form-group">
        <strong>ФИО владельца:</strong>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        {{data.signature.ownerFio}}
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col-5">
      <div class="bg-form-group">
        <strong>Организация владельца подписи:</strong>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        {{data.signature.ownerOrganization}}
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col-5">
      <div class="bg-form-group">
        <strong>Срок действия электронной подписи:</strong>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        {{validTo}}
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col-5">
      <div class="bg-form-group">
        <strong>Дата и время использования ЭП:</strong>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        {{signingDate}}
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col-5">
      <div class="bg-form-group">
        <strong>Статус электронной подписи:</strong>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        Действующая
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" cdkFocusInitial (click)="close()">Закрыть</button>
  </div>
</div>
