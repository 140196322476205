<div *ngIf="emergency">
  <nw-header [options]="headerOptions" (clickButton)="clickHeaderButton($event)"></nw-header>
  <div class="emergency-container">
    <div class="bg-row bg-row-align-content-center">
      <div class="bg-col-min col-vertical-center">
        <div class="icon" [ngStyle]="{ color: iconColor }">
          <span class="{{icon}}"></span>
        </div>
      </div>
      <div class="bg-col col-vertical-center">
        <div class="emergency-number">
          <div class="bg-row">
            <div class="bg-col">
              {{ emergency.docTypeName }}
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              {{ emergency.number }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr/>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group pl-10 pr-10">
          <div [matTooltip]="emergency.coverageArea.length === 0 ? 'Зона действия не задана' : ''">
            <div
              class="show-on-map"
              (click)="showCoverageArea()"
              [ngStyle]="{ 'pointer-events': emergency.coverageArea.length === 0 ? 'none' : '',
                background: coverageAreaButtonColor }"
            >
              <span class="sc-icon-place"></span> {{coverageAreaButtonText}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="emergency-main-info-container">
      <div class="bg-row">
        <div class="bg-col">
          <div class="emergency-info">
            <label>Тип КСиП:</label>
            <div class="three-line-ellipsis-overflow" [matTooltip]="emergency.incidentType">
              {{ emergency.incidentType }}
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div class="bg-row">
        <div class="bg-col">
          <div class="emergency-info">
            <label>Детализация КСиП:</label>
            <div class="three-line-ellipsis-overflow" [matTooltip]="emergency.ksipDetails">
              {{ emergency.ksipDetails }}
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div class="bg-row">
        <div class="bg-col">
          <div class="emergency-info">
            <label>Ответственная организация:</label>
            <div class="three-line-ellipsis-overflow" [matTooltip]="emergency.organization">
              {{ emergency.organization }}
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div class="bg-row">
        <div class="bg-col">
          <div class="emergency-info">
            <label>Состояние:</label>
            <div class="hide-overflow" [matTooltip]="emergency.lifeCycleStep">
              {{ emergency.lifeCycleStep }}
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div class="bg-row">
        <div class="bg-col">
          <div class="emergency-info">
            <label>Адрес:</label>
            <div
              class="emergency-info__link three-line-ellipsis-overflow"
              [matTooltip]="emergency.address || emergency.coordinates"
              (click)="selectCoordinates()"
            >
              {{ emergency.address || emergency.coordinates }}
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div class="bg-row">
        <div class="bg-col">
          <div class="emergency-info">
            <label>Дата и время регистрации:</label>
            {{ emergency.timeCreate }}
          </div>
        </div>
      </div>
      <hr/>
      <div class="bg-row" *ngIf="showWorkTerms">
        <div class="bg-col">
          <div class="emergency-info">
            <label>Даты проведения работ:</label>
            {{ emergency.workTerms }}
          </div>
        </div>
      </div>
      <div class="bg-row" *ngIf="showDeadline">
        <div class="bg-col">
          <div class="emergency-info">
            <label>Срок исполнения:</label>
            {{ emergency.deadline }}
          </div>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col align-center">
        <div class="emergency-info">
          <button mat-raised-button color="primary" (click)="details()">Подробнее</button>
        </div>
      </div>
    </div>
  </div>
</div>
