<form nz-form [formGroup]="form" errorTailor id="idForTest" nzLayout="vertical" *ngIf="form">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <ng-template #mainParams>
        <h4 class="m-b-0">Основные параметры расчёта</h4>
      </ng-template>
      <nz-divider [nzText]="mainParams" nzOrientation="left"></nz-divider>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="objectFireId">Объект пожара</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="objectFireId" nzSize="large">
            <nz-option [nzValue]="obj.value" [nzLabel]="obj.text" *ngFor="let obj of fireObjects$ | async"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="linearVelocity">Линейная скорость</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="linearVelocity">
            <input
              nzSize="large"
              nz-input
              formControlName="linearVelocity"
              [controlErrorAnchor]="linearVelocityAnchor"
            />
          </nz-input-group>
          <ng-template controlErrorAnchor #linearVelocityAnchor="controlErrorAnchor"></ng-template>
          <ng-template #linearVelocity> м/мин </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="propagationTime">Время распространения</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="time">
            <input
              nzSize="large"
              nz-input
              formControlName="propagationTime"
              [controlErrorAnchor]="propagationTimeAnchor"
            />
          </nz-input-group>
          <ng-template controlErrorAnchor #propagationTimeAnchor="controlErrorAnchor"></ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzFor="firstSalvoTime">Время подачи 1-го ствола</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="time">
            <input
              nzSize="large"
              nz-input
              formControlName="firstSalvoTime"
              [controlErrorAnchor]="firstSalvoTimeAnchor"
            />
          </nz-input-group>
          <ng-template controlErrorAnchor #firstSalvoTimeAnchor="controlErrorAnchor"></ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzFor="localizationTime">Время локализации</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="time">
            <input
              nzSize="large"
              nz-input
              formControlName="localizationTime"
              [controlErrorAnchor]="localizationTimeAnchor"
            />
          </nz-input-group>
          <ng-template controlErrorAnchor #localizationTimeAnchor="controlErrorAnchor"></ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
<ng-template #time> мин </ng-template>
