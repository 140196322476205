<header class="page-header">
  <nw-header
    class="patients-registry__nw-header"
    [options]="headerOptions"
    (clickButton)="onClickHeaderButton($event)">
  </nw-header>
  <sc-button
    [options]="addSmsButtonOptions"
    (clickButton)="onClickCreateNewSmsTask()">
  </sc-button>
  <sc-button
    [options]="addButtonOptions"
    (clickButton)="onClickCreateNewTask()">
  </sc-button>
</header>
<card-registry
  [tableName]="'Calls_Tasks'"
  [columns]="registryColumns"
  [requestQuery]="filters"
  [loadDataFn]="taskService.loadRegistryDataFn"
  [pageSize]="12"
  (selectionChanged)="onClickTask($event)">
  <ng-template let-task>
    <p class="name" *ngIf="task.name">
      {{task.name}}
    </p>
    <p class="field">
      Тип:
      <ng-container *ngIf="task.type">
        {{task.type}}
      </ng-container>
      <ng-container *ngIf="!task.type">
        не указан
      </ng-container>
    </p>
    <p class="field">
      Срок исполнения:
      <ng-container *ngIf="task.executionDate">
        {{task.executionDate | date: 'dd.MM.yyyy'}}
      </ng-container>
      <ng-container *ngIf="!task.executionDate">
        не задан
      </ng-container>
    </p>
    <p class="field">
      Ответственный:
      <ng-container *ngIf="task.responsible">
        {{task.responsible}}
      </ng-container>
      <ng-container *ngIf="!task.responsible">
        не назначен
      </ng-container>
    </p>
    <p class="field" *ngIf="task.state">
      Статус: {{task.state}}
    </p>
    <mat-progress-bar
      class="progress"
      mode="determinate"
      [value]="task.progress * 100">
    </mat-progress-bar>
  </ng-template>
</card-registry>
