import { Injectable } from '@angular/core';
import { BaseCrudService } from '@bg-front/core/services';
import { Observable, of } from 'rxjs';
import { map, catchError, pluck } from 'rxjs/operators';
import {
  IAlertChildSource,
  IAlertParentSource,
  IAlertSourceForView,
  IFireMonitoringObjectDto,
  ISourceTypeDto,
} from '../models/interfaces';
import { ILimit, ISort, RestService } from '@smart-city/core/services';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';
import { IResponseWithTotal } from '@bg-front/core/models/interfaces';
import { GarFindFlatResponseElement } from '@bg-front/core';

/** Сервис для работы с сущностью Группы причин */
@Injectable({ providedIn: 'root' })
export class FireMonitoringObjectService extends BaseCrudService<IFireMonitoringObjectDto> {
  constructor(rest: RestService) {
    super(
      {
        serviceName: 'SagittariusIntegration',
        entityName: 'FireMonitoringObjects',
      },
      rest,
    );
  }

  /** Импорт данных */
  public import(xmlString: string): Observable<null> {
    return this.rest
      .serviceRequest({
        action: 'loadConfiguration',
        service: {
          'name': 'SagittariusIntegration'
        },
        data: {
          xmlString
        }
      })
      .pipe(
        map((response: IAbstractServiceData) => response.data)
      )
  }

  /**
   * Получение источников оповещения по id объекта пожарного мониторинга
   * @param query запрос
   * @param pageIndex номер страницы
   * @param pageSize кол-во эл-ов на странице
   * @param sort сортировка
   * @param isNeedPagination необходимость в пагинации при получении данных
   */
  public getAlertSourcesByObjectId(
    query: IAnyObject,
    pageIndex: number,
    pageSize: number,
    sort?: ISort,
    isNeedPagination: boolean = true
  ): Observable<IResponseWithTotal<IAlertParentSource[]>> {
    let limit: ILimit = undefined;
    if (isNeedPagination && (pageIndex || pageSize)) {
      limit = {
        paNumber: pageIndex ?? 1,
        paSize: pageSize ?? 15,
      };
    }

    const params = {
      sort,
      limit
    }

    return this.rest
      .serviceRequest({
        action: 'getSourcesWithChildren',
        service: { name: 'SagittariusIntegration' },
        data: {
          isNeedTotal: true,
          query,
          params
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return {
            items: response.data.items || [],
            totalCount: response.data.totalCount ?? 0,
          };
        }),
      );
  }

  /**
   * Получение источников оповещения
   * @param query запрос
   */
  public getSources(query: IAnyObject): Observable<IAlertChildSource[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'SagittariusIntegration' },
        entity: {
          name: 'FireMonitoringSources',
          query,
        },
      })
      .pipe(pluck('data', 'items'));
  }

  /** Получение связанных происшествий */
  public getRelatedEmergencies(objectId: string) {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'SagittariusIntegration' },
        entity: {
          name: 'IntegrationEvents',
          attributes: [
            'id',
            'eventId.ksipTime',
            'eventId.exactCoordinates',
            'eventId.ksipTypeId',
            'eventId.isHandled',
            'incidentId.timeCreate',
            'incidentId.coordinates',
            'incidentId.incidentTypeId',
            'incidentId.lifeCycleStepId.name',
            'incidentId.lifeCycleStepId.status.sysname',
            'incidentId.docType.sysname',
          ],
          query: { objectId },
        }
      })
  }

  /**
   * Получение источника оповещения по id
   * @param id id записи
   * @param attributes атрибуты
   * @returns
   */
  public getAlertSourceById(id: string, attributes?: string[]): Observable<IAlertSourceForView> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'SagittariusIntegration' },
        entity: {
          attributes,
          query: {
            id,
          },
          name: 'FireMonitoringSources',
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || [])[0] as IAlertSourceForView;
        }),
      );
  }

  /** Получение списка организаций для селекта */
  public getOrganizationsForSelect(): Observable<{ id: string, name: string }[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          attributes: ['id', 'name'],
          query: { active: true },
          sort: {
            field: 'name',
            direction: 'asc',
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => response.data.items || []),
        catchError(() => of([])),
      );
  }

  /** Получение адреса по id организации */
  public getOrganizationAddress(id: string): Observable<GarFindFlatResponseElement> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          attributes: ['id', 'address'],
          query: { id },
        }
      })
      .pipe(
        pluck('data', 'items', '0', 'address'),
      )
  }

  /** Получение списка типов источников */
  public getSourceTypes(): Observable<ISourceTypeDto[]> {
    return this.rest.serviceRequest({
        action: 'select',
        service: { name: 'SagittariusIntegration' },
        entity: { name: 'SourceTypes' }
      })
      .pipe(pluck('data', 'items'));
  }

  /** Получение всех источников оповещения по id объекта пожарного мониторинга */
  public getAllAlertSourcesByObjectId(id: string): Observable<IAlertChildSource[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'SagittariusIntegration' },
      entity: {
        name: 'FireMonitoringSources',
        attributes: ['id', 'coordinates'],
        query: { objectId: id },
      },
    })
      .pipe(pluck('data', 'items'));
  }

  /** Сохранение источника пожарного мониторинга */
  public saveAlertSource(data: IAlertChildSource): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'update',
      service: { name: 'SagittariusIntegration' },
      entity: {
        name: 'FireMonitoringSources',
        query: { id: data.id },
      },
      data,
    });
  }
}
