import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { BaseComponent } from '@bg-front/core/components';
import { ISelectItem } from '@bg-front/core/models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as dayjs from 'dayjs';
import { Observable } from 'rxjs';

import { IForecastingDistanceTraveledByFireFrontEmergencyData } from '../../models/interfaces';
import { ForecastingFiresService } from '../../services';

/** Компонента параметров задачи прогнозирования "Расстояние, пройденное фронтом пожара"*/
@UntilDestroy()
@Component({
  selector: 'bg-forecasting-distance-traveled-by-fire-front-task',
  templateUrl: './forecasting-distance-traveled-by-fire-front-task.component.html',
  styleUrls: ['./forecasting-distance-traveled-by-fire-front-task.component.scss'],
})
export class ForecastingDistanceTraveledByFireFrontTaskComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Ссылка на форму */
  public form: FormGroup;

  /** Настройка компоненты "Объект пожара" */
  public fireObjects$: Observable<ISelectItem<string>[]> = this.forecastingService.getFireObjects();

  /**
   * Id инцидента
   * @param val id инцидента
   */
  @Input()
  set emergencyId(val: string | undefined) {
    if (val) {
      this.forecastingService
        .getDistanceTraveledByFireFrontDataFromEmergency(val)
        .pipe(untilDestroyed(this))
        .subscribe((data: IForecastingDistanceTraveledByFireFrontEmergencyData) => {
          if (data) {
            if (data.ksipTime && dayjs(data.ksipTime).isValid()) {
              if (data.timeFirstSalvo && dayjs(data.timeFirstSalvo).isValid()) {
                this.form.controls.firstSalvoTime.setValue(
                  dayjs(data.timeFirstSalvo).diff(dayjs(data.ksipTime), 'minute'),
                );
              }

              if (data.timeLocalization && dayjs(data.timeLocalization).isValid()) {
                this.form.controls.firstSalvoTime.setValue(
                  dayjs(data.timeLocalization).diff(dayjs(data.ksipTime), 'minute'),
                );
              }
            }
          }
        });
    }
  }

  /** @ignore */
  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly forecastingService: ForecastingFiresService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }
}
