<div class="bg-row">
  <div class="bg-col-10">
    <h4><b>Вовлеченные инциденты</b></h4>
  </div>
  <div class="bg-col-2 align-right">
    <button mat-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="bg-row">
  <div class="bg-col">
    <mat-table [dataSource]="data.emergencies" class="mat-elevation-z8">

      <ng-container matColumnDef="number">
        <mat-header-cell *matHeaderCellDef>Номер инцидента</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.number}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef>Статус</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element['lifeCycleStepId.status.name']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="organization">
        <mat-header-cell *matHeaderCellDef>Организация</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.organization?.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="timeCreate">
        <mat-header-cell *matHeaderCellDef>Дата и время регистрации</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.timeCreate}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</div>
