import { AfterViewInit, Directive, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DialogService,
  IElementButton,
  INwHeaderBarOptions,
  IScCheckboxOptions,
  IScFias3Options,
  IScInputOptions,
  IScSelectOptions,
} from '@smart-city/core/common';
import { MapBaseModel, MapBaseService } from '@smart-city/maps/sc';
import { IMapBaseIcon, IMapBaseInitOptions, IMapBaseEvent } from '@smart-city/maps/sc';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import { IAdminMunicipalSchemaDto, IAbstractServiceData } from 'smart-city-types';
import { Coordinates } from '@bg-front/core/models/classes';
import { GEOLOCATION_MARKER_SVG } from '@bg-front/core/models/constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from '@bg-front/core/components';
import {
  IMonitoringObjectHcsDto,
  IMonitoringObjectHcsView,
} from '../../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/models/interfaces';
import { MonitoringObjectHcsService } from '../../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/services';
import { NotificationService, Settings2Service } from '@smart-city/core/services';
import { BgMapService, SubstratesQuery } from '@bg-front/core/services';
import { AddressOwnershipObject } from '../../../models/types';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { AddressOwnershipComponent } from '../../address-ownership/address-ownership.component';
import { ISubstrateDto } from '@bg-front/core/models/interfaces';
import { IMapBaseBaseUrlsOptions } from '@bg-front/map/models/interfaces';
import { GarFindFlatResponseElement } from '@bg-front/core';

/** Базовый класс для форм просмотра объектов мониторинга ЖКХ */
@UntilDestroy()
@Directive()
export class MonitoringObjectBaseEditFormComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Редактируемая запись */
  public model: IMonitoringObjectHcsDto | IMonitoringObjectHcsView;
  /** Форма */
  public form: FormGroup;
  /** Настройки заголовка формы создания/редактирования записи */
  public headerActionsOptions: INwHeaderBarOptions;
  /** Признак что форма открыта только на просмотр */
  public isDisabled: boolean = false;
  /** Настройка карты*/
  public mapOptions = <IMapBaseInitOptions>{};
  /** Модель карты */
  private mapModel: MapBaseModel;
  /** Иконка для точки на карте */
  private pointIcon: IMapBaseIcon;

  /** Компонент с гридом принадлежащих адресов */
  @ViewChild('ownedAddresses', { static: false }) private ownedAddresses: AddressOwnershipComponent;

  public isShowAddressBlock: boolean = false;

  //#region Настройки компонент
  /** Настройки компоненты Наименование */
  public nameOptions: IScInputOptions = {
    label: 'Наименование',
    placeholder: 'Наименование',
    disabled: true,
  };
  /** Настройки компоненты Адрес */
  public addressOptions: IScInputOptions = {
    label: 'Адрес',
    placeholder: 'Адрес',
    disabled: true,
  };
  /** Настройки компоненты Координаты */
  public coordinatesOptions: IScInputOptions = {
    label: 'Координаты',
    placeholder: 'Координаты',
    disabled: true,
  };
  /** Настройки компоненты Отображение на карте */
  public showOnMapOptions: IScCheckboxOptions = {
    title: 'Отображение на карте',
  };
  /** Настройки компоненты Внешний идентификатор объекта */
  public extIdOptions: IScInputOptions = {
    label: 'Внешний идентификатор объекта',
    placeholder: 'Внешний идентификатор объекта',
    disabled: true,
  };
  /**Настройки компоненты Вид объекта ЖКХ */
  public hcsObjectKindIdOptions: IScSelectOptions = {
    title: 'Вид объекта ЖКХ',
    service: 'Directories',
    entity: 'HcsObjectKinds',
    modern: true,
    fieldName: 'name',
    clearable: true,
  };
  /** Настройки компоненты Тип объекта ЖКХ */
  public hcsObjectTypeIdOptions: IScSelectOptions = {
    title: 'Тип объекта ЖКХ',
    service: 'Directories',
    entity: 'HcsObjectTypes',
    modern: true,
    fieldName: 'name',
    clearable: true,
  };
  /** Настройки компоненты Эксплуатирующая организация */
  public operatingOrganizationIdOptions: IScSelectOptions = {
    title: 'Эксплуатирующая организация',
    service: 'Admin',
    entity: 'Organizations',
    modern: true,
    fieldName: 'name',
    clearable: true,
    query: {
      active: true,
    },
  };
  /** Настройки компоненты Собственник объекта */
  public ownerOrganizationIdOptions: IScSelectOptions = {
    title: 'Собственник объекта',
    service: 'Admin',
    entity: 'Organizations',
    modern: true,
    fieldName: 'name',
    clearable: true,
    query: {
      active: true,
    },
  };
  /** Настройки компоненты Привязанный адрес */
  public bindedAddressOptions: IScFias3Options = {
    label: 'Адрес',
    width: '100%',
    isCoordinatesNecessary: true,
  };

  /** Форма хэдера */
  public headerForm: FormGroup;

  //#endregion

  /** @ignore */
  constructor(
    protected readonly router: Router,
    protected readonly service: MonitoringObjectHcsService,
    protected readonly settings: Settings2Service,
    private readonly mapService: MapBaseService,
    private readonly gisService: BgMapService,
    protected readonly route: ActivatedRoute,
    protected readonly dialog: DialogService,
    protected readonly note: NotificationService,
    private readonly substratesQuery: SubstratesQuery,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.isShowAddressBlock = Boolean(localStorage.getItem('isAddressBlockShow'));

    this.isDisabled = this.route.snapshot.data['disabled'];

    this.form = new FormGroup({
      name: new FormControl(this.model.name),
      address: new FormControl(this.model.address),
      coordinates: new FormControl(this.model.coordinates),
      showOnMap: new FormControl(this.model.id ? this.model.showOnMap : true),
      extId: new FormControl(this.model.extId),
      hcsObjectKindId: new FormControl(this.model.hcsObjectKindId),
      hcsObjectTypeId: new FormControl(this.model.hcsObjectTypeId),
      operatingOrganizationId: new FormControl(this.model.operatingOrganizationId),
      ownerOrganizationId: new FormControl(this.model.ownerOrganizationId),
      active: new FormControl(!this.model.active),
    });

    if (this.model.id) this.initMap();

    if (this.isDisabled) {
      this.form.disable();
    }

    if (this.model.hcsObjectKindId) {
      this.hcsObjectTypeIdOptions.query = {
        $expr: { $contains: ['$hcsObjectKindIds', `["${this.model.hcsObjectKindId}"]`] },
      };
    } else {
      this.form.controls.hcsObjectTypeId.disable();
      this.hcsObjectTypeIdOptions.disabled = true;
    }

    this.headerForm = new FormGroup({
      inactive: new FormControl(!this.model?.active),
    });

    this.headerForm.controls.inactive.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: boolean) => this.toggleActivity(!value));

    this.headerActionsOptions = {
      title: this.model.name || 'Новый объект',
      name: 'header',
      bgColor: 'white',
      margin: 'collapse',
      buttons: [
        {
          type: 'button',
          options: {
            name: 'cancel',
            icon: 'clear',
          },
        },
        {
          type: 'button',
          options: {
            name: 'save',
            title: 'Сохранить',
            icon: 'done',
            hidden: this.isDisabled,
            type: 'submit',
          },
        },
      ],
      elements: [
        {
          type: 'checkbox',
          options: {
            name: 'inactive',
            title: 'Деактивировать',
            formGroup: this.headerForm,
          },
        },
      ],
    };
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      // Подписка на изменения вида объекта ЖКХ для активации селекта типа объекта ЖКХ
      this.form.controls.hcsObjectKindId.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
        if (value) {
          this.form.controls.hcsObjectTypeId.setValue(null);
          this.form.controls.hcsObjectTypeId.enable({ emitEvent: false });
          this.form.controls.hcsObjectTypeId.markAsUntouched();
          this.hcsObjectTypeIdOptions.disabled = false;
          this.hcsObjectTypeIdOptions.query = { $expr: { $contains: ['$hcsObjectKindIds', `["${value}"]`] } };
        } else {
          this.form.controls.hcsObjectTypeId.disable();
          this.hcsObjectTypeIdOptions.disabled = true;
          this.form.controls.hcsObjectTypeId.setValue(null);
          this.hcsObjectTypeIdOptions.query = {};
        }
      });
    }, 1000);
  }

  /** Инициализация карты */
  private initMap() {
    this.pointIcon = this.mapService.makeIcon({
      iconSize: 24,
      iconColor: '#0029FF',
      iconSVG: GEOLOCATION_MARKER_SVG,
      shapeType: 'Нет',
      shapeSize: 24,
    });

    const transformSubstratesUrl: (url: string) => string = (url: string): string => {
      return url.startsWith('/', 0) ? `//${window.location.host}${url}` : `//${url}`;
    };

    const substrates = this.substratesQuery.getAll();
    const activeSubstrate = (this.substratesQuery.getActive() as ISubstrateDto) || substrates?.[0];

    this.mapModel = new MapBaseModel('bindingAddresses', this.mapService);
    this.mapOptions.mapId = 'bindingAddresses';
    this.mapOptions.url = substrates?.length > 1 ? undefined : transformSubstratesUrl(activeSubstrate.link);
    this.mapOptions.urls =
      substrates?.length > 1
        ? substrates.map((el: ISubstrateDto): IMapBaseBaseUrlsOptions => {
            return <IMapBaseBaseUrlsOptions>{
              url: transformSubstratesUrl(el.link),
              name: el.name,
              attribution: el.attribution,
              selected: activeSubstrate ? el.id === activeSubstrate.id : el.default,
            };
          })
        : undefined;
    this.mapOptions.maxZoom = 18;
    this.mapOptions.zoom = 16;
    this.mapOptions.mapStyle = { width: '100%', height: '100%' };
    this.mapOptions['attribution'] = substrates?.length > 1 ? undefined : activeSubstrate.attribution;

    this.mapModel
      .getObservableMapEvents()
      .pipe(
        filter(
          (event: IMapBaseEvent) =>
            event.mapObject?.options?.mapId === 'bindingAddresses' && event.typeEvent === 'mapReady',
        ),
        switchMap(() => this.service.getOwnedAddresses(this.model.id)),
        untilDestroyed(this),
      )
      .subscribe((addresses: GarFindFlatResponseElement[]) => {
        addresses.map((address: GarFindFlatResponseElement, index: number) => {
          if (address.latitude && address.longitude) {
            this.mapModel.addMarkerToLayer('points', `${index}`, [address.latitude, address.longitude], this.pointIcon);
          }
        });
        this.mapModel.viewLayer('points', false);
        this.mapModel.setCenter(this.getCenter(addresses));
      });
  }

  /**
   * Обрабатываем нажатие кнопок в заголовке
   * @param $event - информация о нажатой кнопке
   */
  public onClickActionsButton($event: IElementButton) {
    if ($event.options.name === 'cancel') {
      // Если форма заблокирована для редактирования значит она открыта с карты, при закрытии надо обнулить аутлет
      if (this.isDisabled) {
        this.router.navigate([{ outlets: { viewForm: null } }], {
          relativeTo: this.route.parent,
          queryParamsHandling: 'merge',
        });
        // Если форма не заблокирована значит при закрытии надо перейти на грид с объектами мониторинга ЖКХ
      } else {
        this.router.navigate(['dictionaries/monitoring-objects-hcs']);
      }
      return;
    }

    if (this.form.invalid) return;

    this.model = {
      ...this.model,
      ...this.form.value,
    };
  }

  /**
   * Сохранение привязанного адреса
   * @param ownedAddress - данные об адресе
   */
  public saveOwnedAddress(ownedAddress: AddressOwnershipObject) {
    this.service
      .saveOwnedAddress({
        ...ownedAddress,
        monitoringObjectId: this.model.id,
      })
      .pipe(
        catchError((err: Error) =>
          this.catchErrorFn<IAbstractServiceData | string>(err, 'Ошибка при сохранении адреса', 'error'),
        ),
        untilDestroyed(this),
      )
      .subscribe((res: IAbstractServiceData | string) => {
        if (res === 'error') return;
        this.ownedAddresses.refreshGrid();
        this.refreshMap();
      });
  }

  /**
   * Удаление принадлежащих адресов
   * @param ids - список ID для удаления
   */
  public deleteOwnedAddresses(ids: string[]) {
    this.service
      .deleteOwnedAddresses(ids)
      .pipe(
        catchError((err: Error) =>
          this.catchErrorFn<string>(err, 'Невозможно удалить запись, которая используется в системе', 'error'),
        ),
        untilDestroyed(this),
      )
      .subscribe((result: string | IAbstractServiceData) => {
        if (result !== 'error') {
          this.refreshMap();
          this.ownedAddresses.refreshGrid();
          this.noteService.pushInfo('Запись успешно удалена');
        }
      });
  }

  /** Получение координат центра карты */
  private getCenter(addresses: GarFindFlatResponseElement[]): MapBaseCoordinatesType {
    // Попытка получить координаты для позиционирования центра карты из адресов
    const addressesCenter = this.gisService.getCenter(
      addresses
        .filter((address: GarFindFlatResponseElement) => address.latitude && address.longitude)
        .map((address: GarFindFlatResponseElement) => new Coordinates(address.latitude, address.longitude)),
    );
    if (addressesCenter) return Coordinates.coordinatesToArray(addressesCenter);

    if (this.model.coordinates) return Coordinates.coordinatesToArray(this.model.coordinates);

    // Получение координаты для позиционирования центра карты из МО пользователя
    const user = (this.settings || <any>{}).currentUser;
    const mo = this.settings.allMo.find((mo: IAdminMunicipalSchemaDto) => mo.id === user?.organizationId?.mo);
    if (mo && mo.coordinates) return Coordinates.coordinatesToArray(mo.coordinates);

    return Coordinates.coordinatesToArray(this.settings.getConfig().defaultCoordinates);
  }

  /**
   * Обновление карты при изменении количества адресов
   */
  private refreshMap() {
    this.mapModel.removeLayer('points');
    this.service
      .getOwnedAddresses(this.model.id)
      .pipe(untilDestroyed(this))
      .subscribe((addresses: GarFindFlatResponseElement[]) => {
        addresses.map((address: GarFindFlatResponseElement, index: number) => {
          if (address.latitude && address.longitude) {
            this.mapModel.addMarkerToLayer('points', `${index}`, [address.latitude, address.longitude], this.pointIcon);
          }
        });
        this.mapModel.viewLayer('points', false);
        this.mapModel.setCenter(this.getCenter(addresses));
      });
  }

  /** @ignore */
  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    localStorage.removeItem('isAddressBlockShow');
  }

  /** Включение/выключение активности */
  public toggleActivity(val: boolean) {
    this.form.controls.active.setValue(val);
  }
}
