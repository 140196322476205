import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IScButtonOptions, IScSelectOptions } from '@smart-city/core/common';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { IStepDialogDataOptions } from '../../models/interfaces';
import { BaseComponent } from '@bg-front/core/components';
import { Settings2Service } from '@smart-city/core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/** Форма создания шага котором выбираем тип Шага */
@UntilDestroy()
@Component({
  selector: 'bg-new-response-org-form',
  templateUrl: './new-response-org-form.component.html',
  styleUrls: ['./new-response-org-form.component.scss'],
})
export class NewResponseOrgFormComponent extends BaseComponent implements OnInit {
  /** Форма */
  public form: FormGroup;

  @Output()
  public nextEvent: EventEmitter<{
    stepType: string,
    informationStatementActionSysName: string
  }> = new EventEmitter<{
    stepType: string,
    informationStatementActionSysName: string
  }>(true);
  @Output()
  public closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  @Input() options: IStepDialogDataOptions;

  /** Настройки для кнопки сохранить */
  public nextBtnOptions: IScButtonOptions = {
    title: 'Далее',
    color: 'primary',
    disabled: true,
  };
  /** Настройки для кнопки отмена */
  public cancelBtnOptions: IScButtonOptions = {
    title: 'Отменить',
  };

  public informationStatementActions: IDictionaryInfo[] = null;

  constructor(private readonly settings: Settings2Service) {
    super();
  }

  /** Тип шага */
  public stepTypeOptions: IScSelectOptions = {
    title: 'Тип шага *',
    clearable: true,
    data: this.settings
      .getDictionaryByTypeSysName('stepType')
      .filter(({ sysname }) => sysname === 'message' || sysname === 'manual')
      .map((item: IDictionaryInfo) => ({ id: item.id, text: item.name })),
    fieldName: 'name',
  };


  public ngOnInit(): void {
    this.form = new FormGroup({
      stepType: new FormControl(null, [Validators.required]),
      informationStatementActionSysName: new FormControl('response'),
    });

    this.form.controls.stepType.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      this.nextBtnOptions.disabled = !value;
      this.informationStatementActions = this.settings.getDictionaryById(value)?.sysname === 'message'
        ? this.settings.getDictionaryByTypeSysName('informationStatementActions')
        : null;
    });
  }

  /** Обработчик по клику на кнопку далее */
  public nextHandler() {
    this.nextEvent.emit({
      stepType: this.form.controls.stepType.value,
      informationStatementActionSysName: this.form.controls.informationStatementActionSysName.value,
    });
  }

  public closeHandler() {
    this.closeEvent.emit();
  }
}
