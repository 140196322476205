import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Settings2Service } from '@smart-city/core/services';
import * as dayjs from 'dayjs';
import { Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';

import { IForecastingRadioactiveEnvironmentReport } from '../../models';

/**
 * Сервис для прогнозирования
 */
@Injectable({
  providedIn: 'root',
})
export class ForecastingRadioactiveEnvironmentReportService {
  constructor(private readonly settings: Settings2Service, private xlsxUploader: HttpClient) {}

  /**
   * Формирование и скачивание отчета по расчёту зоны возможного радиоактивного загрязнения местности
   */
  public buildForecastingRadioactiveReport(report: IForecastingRadioactiveEnvironmentReport): void {
    const wb = new Workbook();
    let ws2: Worksheet = undefined;
    let ws3: Worksheet = undefined;
    let ws4: Worksheet = undefined;

    this.xlsxUploader
      .get('/assets/report-templates/forecasting-radioactive-report.xlsx', {
        responseType: 'arraybuffer',
      })
      .subscribe((buffer) => {
        wb.xlsx.load(buffer).then((workbook) => {
          ws2 = workbook.getWorksheet('Рад. загрязнение_стр. 2');
          if (ws2) {
            /** Блок "Местоположение" */
            ws2.getCell(5, 2).value = this.prepareValue(report.address);
            ws2.getCell(7, 2).value = this.prepareValue(report.coordinates);
            ws2.getCell(8, 2).value = this.prepareValue(report.object);
            ws2.getCell(9, 2).value = this.prepareValue(report.responsible);
            ws2.getCell(10, 2).value = this.prepareValue(report.phone);

            /** Блок "Исходные данные" */
            ws2.getCell(15, 2).value = this.prepareValue(report.emergency);
            if (this.settings.getDictionaryById(report.unitRadiationId)?.sysname === 'sievert') {
              ws2.getCell(16, 1).value = 'Доза облучения (в Зв)';
            }
            ws2.getCell(16, 2).value = this.prepareValue(report.irradiationDose);
            ws2.getCell(17, 2).value = dayjs().format('DD.MM.YYYY HH:mm:ss');
            ws2.getCell(18, 2).value = this.prepareValue(report.timeToSpread);

            /** Блок "Метеоусловия в момент аварии" */
            ws2.getCell(5, 7).value = this.prepareValue(report.windVelocity);
            ws2.getCell(6, 7).value = this.prepareValue(report.windDirection);
            ws2.getCell(7, 7).value = this.prepareValue(report.isSnowy);
            ws2.getCell(8, 7).value = this.prepareValue(report.cloudState);
            ws2.getCell(9, 7).value = this.prepareValue(report.timeOfDay);
            ws2.getCell(10, 7).value = this.prepareValue(report.radiationForecastResults.verticalAtmosphereStability);

            let prompt = '';
            switch (report.timeOfDay) {
              case 'Утро':
                prompt = '* Утро = 3 ч. после восхода солнца.';
                break;
              case 'Вечер':
                prompt = '* Вечер = 3 ч. после захода солнца.';
                break;
              case 'День':
                prompt = `* День = период от восхода до захода
солнца (кроме трёх утренних часов).`; // строка смещена в начало для корректного форматирования при подстановке в файл
                break;
              case 'Ночь':
                prompt = `* Ночь = период от захода до восхода
солнца (кроме трёх вечерних часов).`; // строка смещена в начало для корректного форматирования при подстановке в файл
                break;
            }
            ws2.getCell(11, 6).value = this.prepareValue(prompt);

            /** Блок "Используемая методология для прогнозирования" */
            ws2.getCell(25, 1).value = this.prepareValue(report.methodology);
          }
          ws3 = workbook.getWorksheet('Рад. загр_стр. 3');
          if (ws3) {
            ws3.getCell(3, 3).value = this.prepareValue(report.radiationForecastResults.zoneLength?.toFixed(2));
            ws3.getCell(4, 3).value = this.prepareValue(report.radiationForecastResults.zoneWidth?.toFixed(2));
            ws3.getCell(5, 3).value = this.prepareValue(report.radiationForecastResults.zoneArea?.toFixed(2));
            ws3.getCell(7, 5).value = this.prepareValue(report.radiationForecastResults.recommendedMeasures);
          }
          ws4 = workbook.getWorksheet('Рад. загр_стр. 4');

          if (ws4) {
            for (let i = 0; i < report?.buildings?.length; i++) {
              const el = report.buildings[i];
              let address = '';
              if (el.city) {
                address = el.city;
              }

              if (el.street) {
                address = address.length ? address + ', ' + el.street : el.street;
              }

              if (el.houseNumber && address.length) {
                address = address + ', д.' + el.houseNumber;
              }
              if (address) {
                ws4.getCell(i + 2, 1).value = this.prepareValue(address);
              }
            }

            workbook.xlsx.writeBuffer().then((buf) => {
              saveAs(
                new Blob([buf]),
                `Расчёт зоны возможного радиоактивного загрязнения местности_${dayjs().format(
                  'DD.MM.YYYY HH_mm_ss',
                )}.xlsx`,
              );
            });
          }
        });
      });
  }

  /**
   * Формирование и скачивание отчета по расчёту прогнозируемых зон облучения щитовидной железы взрослых и детей
   */
  public buildForecastingThyroidReport(report: IForecastingRadioactiveEnvironmentReport, recipient: string) {
    const wb = new Workbook();
    let ws2: Worksheet = undefined;
    let ws3: Worksheet = undefined;
    let ws4: Worksheet = undefined;

    let url = '';
    let savedName = '';

    const dateFormat = 'DD.MM.YYYY HH_mm_ss';

    if (recipient === 'adult') {
      url = '/assets/report-templates/forecasting-thyroid-report-adult.xlsx';
      savedName = `Расчёт прогнозируемых зон облучения щитовидной железы взрослых_${dayjs().format(dateFormat)}.xlsx`;
    } else if (recipient === 'child') {
      url = '/assets/report-templates/forecasting-thyroid-report-child.xlsx';
      savedName = `Расчёт прогнозируемых зон облучения щитовидной железы детей_${dayjs().format(dateFormat)}.xlsx`;
    }

    this.xlsxUploader
      .get(url, {
        responseType: 'arraybuffer',
      })
      .subscribe((buffer) => {
        wb.xlsx.load(buffer).then((workbook) => {
          ws2 = workbook.getWorksheet('Рад. облучение_стр. 2');
          if (ws2) {
            const dateFormat = 'DD.MM.YYYY HH:mm:ss';

            /** Блок "Местоположение" */
            ws2.getCell(4, 2).value = this.prepareValue(report.address);
            ws2.getCell(6, 2).value = this.prepareValue(report.coordinates);
            ws2.getCell(7, 2).value = this.prepareValue(report.object);
            ws2.getCell(8, 2).value = this.prepareValue(report.responsible);
            ws2.getCell(9, 2).value = this.prepareValue(report.phone);

            /** Блок "Исходные данные" */
            ws2.getCell(14, 2).value = this.prepareValue(report.emergency);
            if (this.settings.getDictionaryById(report.unitRadiationId)?.sysname === 'sievert') {
              ws2.getCell(15, 1).value = 'Доза облучения (в Зв)';
            }
            ws2.getCell(15, 2).value = this.prepareValue(report.irradiationDose);
            ws2.getCell(16, 2).value = dayjs().format(dateFormat);

            /** Блок "Метеоусловия в момент аварии" */
            ws2.getCell(4, 7).value = this.prepareValue(report.windVelocity);
            ws2.getCell(5, 7).value = this.prepareValue(report.windDirection);
            ws2.getCell(6, 7).value = this.prepareValue(report.isSnowy);
            ws2.getCell(7, 7).value = this.prepareValue(report.cloudState);
            ws2.getCell(8, 7).value = this.prepareValue(report.timeOfDay);
            if (recipient === 'adult') {
              ws2.getCell(9, 7).value = this.prepareValue(
                report.thyroidForecastResultsAdult.verticalAtmosphereStability,
              );
            } else if (recipient === 'child') {
              ws2.getCell(9, 7).value = this.prepareValue(
                report.thyroidForecastResultsChild.verticalAtmosphereStability,
              );
            }
            let prompt = '';
            switch (report.timeOfDay) {
              case 'Утро':
                prompt = '* Утро = 3 ч. после восхода солнца.';
                break;
              case 'Вечер':
                prompt = '* Вечер = 3 ч. после захода солнца.';
                break;
              case 'День':
                prompt = `* День = период от восхода до захода
солнца (кроме трёх утренних часов).`; // строка смещена в начало для корректного форматирования при подстановке в файл
                break;
              case 'Ночь':
                prompt = `* Ночь = период от захода до восхода
солнца (кроме трёх вечерних часов).`; // строка смещена в начало для корректного форматирования при подстановке в файл
                break;
            }
            ws2.getCell(10, 6).value = this.prepareValue(prompt);

            /** Блок "Используемая методология для прогнозирования" */
            ws2.getCell(21, 1).value = this.prepareValue(report.methodology);
          }
          ws3 = workbook.getWorksheet('Рад. облучение_стр. 3');
          if (ws3) {
            if (recipient === 'adult') {
              ws3.getCell(3, 3).value = this.prepareValue(report.thyroidForecastResultsAdult.zoneLength?.toFixed(2));
              ws3.getCell(4, 3).value = this.prepareValue(report.thyroidForecastResultsAdult.zoneWidth?.toFixed(2));
              ws3.getCell(5, 3).value = this.prepareValue(report.thyroidForecastResultsAdult.zoneArea?.toFixed(2));
              ws3.getCell(6, 3).value = this.prepareValue(
                report.thyroidForecastResultsAdult.isIodineProphylaxisRequired ? 'Да' : 'Нет',
              );
              ws3.getCell(8, 5).value = this.prepareValue(report.thyroidForecastResultsChild.recommendedMeasures);
            } else if (recipient === 'child') {
              ws3.getCell(3, 3).value = this.prepareValue(report.thyroidForecastResultsChild.zoneLength?.toFixed(2));
              ws3.getCell(4, 3).value = this.prepareValue(report.thyroidForecastResultsChild.zoneWidth?.toFixed(2));
              ws3.getCell(5, 3).value = this.prepareValue(report.thyroidForecastResultsChild.zoneArea?.toFixed(2));
              ws3.getCell(6, 3).value = this.prepareValue(
                report.thyroidForecastResultsChild.isIodineProphylaxisRequired ? 'Да' : 'Нет',
              );
              ws3.getCell(8, 5).value = this.prepareValue(report.thyroidForecastResultsChild.recommendedMeasures);
            }
          }
          ws4 = workbook.getWorksheet('Рад. облучение_стр. 4');
          if (ws4) {
            for (let i = 0; i < report?.buildings?.length; i++) {
              const el = report.buildings[i];
              let address = '';
              if (el.city) {
                address = el.city;
              }

              if (el.street) {
                address = address.length ? address + ', ' + el.street : el.street;
              }

              if (el.houseNumber && address.length) {
                address = address + ', д.' + el.houseNumber;
              }
              if (address) {
                ws4.getCell(i + 2, 1).value = this.prepareValue(address);
              }
            }

            workbook.xlsx.writeBuffer().then((buf) => {
              saveAs(new Blob([buf]), savedName);
            });
          }
        });
      });
  }

  /**
   * Подготовка значения для вывода в таблицу
   * @param value значение
   * @param defaultValue значение по умолчанию
   */
  private prepareValue(value: any, defaultValue?: string): string {
    if (value === null || value === undefined || value === '') {
      return defaultValue || '–––';
    }
    return value.toString();
  }
}
