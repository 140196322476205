import { Injectable } from '@angular/core';
import { IResponseWithTotal } from '@bg-front/core/models/interfaces';
import { CommonService } from '@bg-front/core/services';
import { IDictionaryInfo, IScSelectItem } from '@smart-city/core/interfaces';
import {
  AccessService,
  ILimit,
  ISort,
  NotificationService,
  RestService,
  Settings2Service,
  SfsService,
} from '@smart-city/core/services';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';

import { IRegistryPanelDto } from '../../models/interfaces';

/**
 * Сервис для получения данных о реестрах для отображения на панели
 */
@Injectable()
export class RegistryPanelService {
  private iconMap: Map<string, string> = new Map<string, string>();

  constructor(
    private readonly rest: RestService,
    private readonly sfs: SfsService,
    private readonly accessService: AccessService,
    private readonly common: CommonService,
    private readonly note: NotificationService,
    private readonly settings: Settings2Service,
  ) {}

  /** Получение реестров для таблицы */
  public getRegistriesForTable(
    query: IAnyObject,
    pageIndex: number,
    pageSize: number,
    sort?: ISort,
  ): Observable<IResponseWithTotal<IRegistryPanelDto[]>> {
    let limit: ILimit = undefined;
    if (pageIndex || pageSize) {
      limit = {
        paNumber: pageIndex ?? 1,
        paSize: pageSize ?? 15,
      };
    }

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query,
          name: 'RegistriesPanel',
          attributes: ['id', 'name', 'sysName', 'claim', 'order'],
        },
        data: {
          isNeedTotal: true,
          limit,
          sort,
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return {
            items: response.data.items || [],
            totalCount: response.data.totalCount ?? 0,
          };
        }),
        catchError(() => {
          return of({
            totalCount: 0,
            items: [],
          });
        }),
      );
  }

  /**
   * Метод получения информации реестрах доступных пользователю
   */
  public getRegistries(claims?: string[]): Observable<IRegistryPanelDto[]> {
    let registries: IRegistryPanelDto[] = [];
    const query = {
      icon: {
        $ne: null,
      },
    };

    if (claims && claims.length) {
      query['claim'] = {
        $in: claims,
      };
    }

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query,
          name: 'RegistriesPanel',
          sort: {
            field: 'order',
            direction: 'asc',
          },
        },
      })
      .pipe(
        switchMap((response: IAbstractServiceData) => {
          registries = ((response.data.items as IRegistryPanelDto[]) || []).filter(
            // Если нет разрешающего правила (enabled === true), то действие запрещено
            (item: IRegistryPanelDto) => this.accessService.accessMap[item.claim]?.enabled,
          );
          return forkJoin(
            ...registries.map((registry: IRegistryPanelDto) => {
              if (this.iconMap.has(`${registry.icon}${registry.iconColor}`)) {
                return of(this.iconMap.get(`${registry.icon}${registry.iconColor}`));
              }
              return this.rest
                .serviceRequest({
                  action: 'select',
                  service: { name: 'Admin' },
                  entity: {
                    name: 'Icons',
                    query: {
                      id: registry.icon,
                    },
                    attributes: ['fileId'],
                  },
                })
                .pipe(
                  map((response: IAbstractServiceData) => {
                    return response.data?.items?.[0]?.fileId;
                  }),
                  switchMap((sfsId: string) => {
                    return this.sfs.getFileContents(sfsId).pipe(
                      map((arrayBuffer: ArrayBuffer | Blob) =>
                        this.common.generateSvgIcon(arrayBuffer as ArrayBuffer, registry.iconColor),
                      ),
                      tap((icon: string) => this.iconMap.set(`${registry.icon}${registry.iconColor}`, icon)),
                      catchError((err: Error) => {
                        this.note.pushError(`Ошибка загрузки иконки реестра ${registry.name}`);
                        return of('');
                      }),
                    );
                  }),
                );
            }),
          );
        }),
        map((result: string[]) =>
          result.map((icon: string, index: number) => {
            registries[index].icon = icon;
            return registries[index];
          }),
        ),
      );
  }

  /**
   * Метод получения информации реестрах доступных пользователю
   */
  public getRegistryId(claims?: string[]): Observable<string> {
    const query = {
      icon: {
        $ne: null,
      },
    };

    if (claims && claims.length) {
      query['claim'] = {
        $in: claims,
      };
    }

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query,
          name: 'RegistriesPanel',
          linksMode: 'raw',
          sort: {
            field: 'order',
            direction: 'asc',
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          const claim = ((response.data.items[0] as IRegistryPanelDto) || {}).claim;
          return this.accessService.accessMap[claim]?.enabled
            ? (response.data.items[0] as IRegistryPanelDto).id
            : undefined;
        }),
      );
  }

  /**
   * Метод получения информации о реестре по Id
   */
  public getRegistryPanelById(id: string): Observable<IRegistryPanelDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query: {
            id,
            icon: {
              $ne: null,
            },
          },
          name: 'RegistriesPanel',
          linksMode: 'raw',
        },
      })
      .pipe(map((response: IAbstractServiceData) => (response.data?.items || [])[0] as IRegistryPanelDto));
  }

  /**
   * Метод получения информации о реестре по sysName
   */
  public getRegistryPanelBySysName(sysName: string): Observable<IRegistryPanelDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query: {
            sysName,
            icon: {
              $ne: null,
            },
          },
          name: 'RegistriesPanel',
          linksMode: 'raw',
        },
      })
      .pipe(map((response: IAbstractServiceData) => (response.data?.items || [])[0] as IRegistryPanelDto));
  }

  /**
   * Сохраняем реестр
   * @param model
   */
  public save(model: IRegistryPanelDto): Observable<string> {
    let obs: Observable<IAbstractServiceData>;
    if (model.id) {
      obs = this.rest.serviceRequest({
        action: 'update',
        service: { name: 'Admin' },
        entity: {
          name: 'RegistriesPanel',
          query: {
            id: model.id,
          },
        },
        data: model,
      });
    } else {
      obs = this.rest.serviceRequest({
        action: 'insert',
        service: { name: 'Admin' },
        entity: {
          name: 'RegistriesPanel',
        },
        data: model,
      });
    }
    return obs.pipe(
      map((response: IAbstractServiceData) => {
        return (response.data || {}).id;
      }),
    );
  }

  /**
   * Удаляем реестр
   * @param ids - массив id реестров
   */
  public delete(ids: string | string[]): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: 'Admin' },
      entity: {
        name: 'RegistriesPanel',
        query: {
          id: Array.isArray(ids)
            ? {
                $in: ids,
              }
            : ids,
        },
      },
    });
  }

  /** Получение иконок для селекта */
  public getIconsForSelect(): Observable<IScSelectItem[]> {
    const layersTagId = this.settings
      .getDictionaryByTypeSysName('iconTags')
      .find((item: IDictionaryInfo) => item.sysname === 'registries')?.id;
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Icons',
          query: {
            $expr: { $in: ['$tags', layersTagId] },
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || []).map((icon: IAnyObject) => ({
            id: icon.id,
            text: icon.name,
            data: { url: `http://${window.location.host}${this.sfs.getUrl(icon.fileId)}` },
          }));
        }),
      );
  }
}
