/**
 * Типы видеоаналитики. Значения - sysname словаря
 */
export enum VideoEmergencyTypeEnum {
  /**
   * Громкий звук
   */
  loudSound = 1,
  /**
   * Оставленные вещи
   */
  leftThing = 2,
  /**
   * Скопление людей
   */
  crowds = 4,
  /**
   * Саботаж
   */
  sabotage = 8,
  /**
   * Разыскиваемые ГРЗ
   */
  grzWanted = 16,
  /**
   * Дубль ГРЗ
   */
  grzRepeat = 32,
  /** Объект в запретной зоне */
  objRestrictedArea = 128,
  /** Распознавание лиц */
  faceRecognition = 256,
}
