<div class="dialog-container">
  <form [formGroup]="manuallyForm" errorTailor id="idForTest">
    <div>
      <!-- Чекбокс Адрес по координатам -->
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group p-t-0 p-b-0">
            <sc-checkbox [options]="byCoordinatesOptions" formControlName="byCoordinates"></sc-checkbox>
          </div>
        </div>
      </div>
      <!--Адрес-->
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group full-width">
            <sc-input [options]="coordinatesAddressOptions" formControlName="coordinatesAddress"></sc-input>
          </div>
        </div>
      </div>
      <!--Вид здания-->
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-select [options]="buildingKindOptions" formControlName="buildingKindId"></sc-select>
          </div>
        </div>
      </div>
      <div class="bg-row m-b-15">
        <div class="bg-col">
          Количество граждан, зарегистрированных в жилом доме
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col p-r-0">
          <div class="bg-form-group">
            <sc-input  [options]="totalOptions" formControlName="totalRegistered"></sc-input>
          </div>
        </div>
        <div class="bg-col p-h-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="menOptions" formControlName="menRegistered"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-col p-h-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="womanOptions" formControlName="womanRegistered"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-col p-h-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="pensionersOptions" formControlName="pensionersRegistered"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-col p-l-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="childrenOptions" formControlName="childrenRegistered"></sc-input>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-row m-b-15">
        <div class="bg-col">
          Количество граждан, проживающих в жилом доме временно
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col p-r-0">
          <div class="bg-form-group">
            <sc-input  [options]="totalOptions" formControlName="totalResidents"></sc-input>
          </div>
        </div>
        <div class="bg-col p-h-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="menOptions" formControlName="menResidents"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-col p-h-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="womanOptions" formControlName="womanResidents"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-col p-h-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="pensionersOptions" formControlName="pensionersResidents"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-col p-l-0">
          <div class="bg-form-group">
            <div class="property">
              <sc-input [options]="childrenOptions" formControlName="childrenResidents"></sc-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="bg-row align-right">
    <div class="bg-col">
      <button class="btn btn-secondary-outline m-r-15" (click)="close()">Отмена</button>
      <button class="btn btn-primary" (click)="save()">Сохранить</button>
    </div>
  </div>
</div>
