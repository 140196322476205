import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBaseDictionaryData, IDictionaryInfo } from '@smart-city/core/interfaces';
import { NotificationService, RestService, Settings2Service } from '@smart-city/core/services';
import { ScConsole } from '@smart-city/core/utils';
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IDictionaryModelDto } from 'smart-city-types';
import { ONE_MINUTE, ONE_SECOND } from '@bg-front/core/models/constants';
import { AnalystSetupsBitwiseEnum } from '../../models/enums';
import { IVideoDevicesCategoriesLinks, IMonitoringDate, IVideoDeviceBg } from '../../models/interfaces';
import { IServerData } from '@bg-front/core/models/interfaces';

/**
 * Сервис для работы с сущностью Видеокамера
 */
@Injectable({
  providedIn: 'root',
})
export class VideoDevicesService {
  constructor(
    private readonly rest: RestService,
    private readonly settings2: Settings2Service,
    private readonly note: NotificationService,
    private readonly http: HttpClient,
  ) {}

  /**
   * Получение данных о видеокамере
   * @param deviceId id устройства
   * @param attributes аттрибуты
   */
  getVideoDevice(deviceId: string, attributes?: string[]): Observable<IVideoDeviceBg> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'VideoDevices',
          linksMode: 'raw',
          query: {
            id: deviceId,
          },
          attributes,
        },
      })
      .pipe(
        map((data: IAbstractServiceData) => data.data.items[0] as IVideoDeviceBg),
        mergeMap((device: IVideoDeviceBg) => {
          const obs: Observable<IAnyObject>[] = [];
          obs.push(of(device));
          if (device) {
            obs.push(
              this.rest
                .serviceRequest({
                  action: 'select',
                  service: { name: 'Admin' },
                  entity: {
                    attributes: ['monitoringSubject'],
                    name: 'VCASettings',
                    query: { id: { $in: [...(device.vcaSettings ? device.vcaSettings : [])] } },
                  },
                })
                .pipe(map((res: IAbstractServiceData) => res.data.items.map((item) => item.monitoringSubject))),
            );

            if (this.settings2.getConfig().cameraAccessByCategories === 'TRUE') {
              obs.push(
                this.rest
                  .serviceRequest({
                    action: 'select',
                    service: { name: 'Admin' },
                    entity: {
                      name: 'VideoDevicesCategoriesLinks',
                      query: { videoDeviceId: device.id },
                      attributes: ['videoDeviceId', 'categoryId', '$accessRights.userId'],
                    },
                    data: {
                      accessRights: {
                        $join: {
                          service: 'Admin',
                          entity: 'VideoDeviceCategoryAccessRights',
                          attributes: [
                            'id',
                            'cameraAccess',
                            'streamAccess',
                            'archiveAccess',
                            'archiveRequestAccess',
                            'userId',
                            'videoDeviceCategoryId',
                          ],
                          query: { $expr: { $eq: ['$categoryId', '$accessRights.videoDeviceCategoryId'] } },
                        },
                      },
                    },
                  })
                  .pipe(
                    map((res: IAbstractServiceData) =>
                      (res.data?.items || []).length === 0
                        ? // Камера не принадлежит никакой категории. Необходимо будет отобразить все элементы управления.
                        undefined
                        : // Камера может принадлежать нескольким категориям.
                          // Если права хоть в одной из категорий дают доступ к элементу управления, то разрешать.
                        res.data.items
                          .filter((item: IAnyObject) => item.accessRights?.userId === this.settings2.currentUser.id)
                          .reduce(
                            (previousValue, currentValue) => ({
                              cameraAccess: !!previousValue.cameraAccess || !!currentValue.accessRights?.cameraAccess,
                              streamAccess: !!previousValue.streamAccess || !!currentValue.accessRights?.streamAccess,
                              archiveAccess:
                                !!previousValue.archiveAccess || !!currentValue.accessRights?.archiveAccess,
                              archiveRequestAccess:
                                !!previousValue.archiveRequestAccess ||
                                !!currentValue.accessRights?.archiveRequestAccess,
                            }),
                            {
                              cameraAccess:false,
                              streamAccess: false,
                              archiveAccess: false,
                              archiveRequestAccess: false,
                            },
                          ),
                    ),
                  ),
              );
            }
          } else {
            obs.push(undefined);
            obs.push(undefined);
          }
          return forkJoin([...obs]);
        }),
        map((data: IAnyObject[]) => {
          const device: IVideoDeviceBg = data[0] as IVideoDeviceBg;
          if (device) {
            device.monitoringSubjects = data[1] as string[];
            // Если камера не принадлежит никакой из категорий или не используется алгоритм предоставления доступа
            // к камере на основании категорий установить undefined для возможности использовать логику доступа
            // основанную на группах прав.
            device.accessRights = data[2] || undefined;
          }
          return device;
        }),
      );
  }

  /**
   * Получение ссылки на поток камеры
   * @param deviceId
   */
  public getVideoDeviceStreamUrl(deviceId: string): Observable<string> {
    return this.rest
      .serviceRequest({
        action: 'getStreamUrl',
        system: { name: 'VAIntegration' },
        service: { name: 'VideoIntegration' },
        needResponse: true,
        data: {
          deviceId,
        },
      })
      .pipe(map((data: IAbstractServiceData) => data.data.url as string));
  }

  /**
   * Получение ссылки на видео хранимое на сервере
   * @param deviceId
   * @param fromtime
   * @param totime
   */
  getMacroscopExportArchiveURL(deviceId: string, fromtime: number, totime: number): Observable<string> {
    return this.rest
      .serviceRequest({
        action: 'generateMacroscopExportArchiveURL',
        service: { name: 'VideoExport' },
        data: {
          deviceId,
          fromtime: moment(fromtime).utc(false).format('DD.MM.YYYY+HH:mm:ss'),
          totime: moment(totime).utc(false).format('DD.MM.YYYY+HH:mm:ss'),
        },
      })
      .pipe(map((result) => result.data));
  }

  /**
   * Получение ссылки на нарезку видео
   * @param deviceId идентификатор камеры
   * @param startAt время события
   * @param delta период до событя (30 секунд дефолт)
   */
  public getVideoDeviceRangeStreamUrl(
    deviceId: string,
    startAt: number,
    delta: number = 30000,
  ): Observable<string> {
    // Необходимо определить тип сервера (macroscop/flussonic/forestGuard) для выбора метода получения ссылки
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'VideoDevices',
          query: { id: deviceId },
          attributes: [
            'extId',
            'token',
            'mediaUrl',
            'videoServer.useSSL',
            'videoServer.ip',
            'videoServer.port',
            'videoServer.type.sysname',
          ],
        },
      })
      .pipe(
        switchMap((result: IAbstractServiceData) => {
          const camera: IVideoDeviceBg = (result.data?.items || [])[0];
          const server: IServerData = <IServerData>camera.videoServer;

          // Если сервер типа Лесохранитель, необходимо сформировать url и проверить его доступность
          if ((<IBaseDictionaryData>server.type).sysname === 'forestGuard') {
            const url = camera.mediaUrl
              .replace('/embed.html?proto=dash', '')
              .concat(`/archive-${Math.floor(startAt / 1000)}-${Math.floor(delta / 1000)}.mp4`);
            return this.http.head(url).pipe(map(() => url));
          }

          // Если сервер типа flussonic, необходимо сформировать url и проверить его доступность
          if ((<IBaseDictionaryData>server.type).sysname === 'flussonic') {
            const url: string = `${server.useSSL ? 'https' : 'http'}://${server.ip}:${server.port}/${
              camera.extId
            }/archive-${startAt / 1000}-60.mp4?token=${camera.token}`;
            return this.http.head(url).pipe(map(() => url));
          }
          // Если сервер типа macroscop, необходимо запросить url у сервиса видео-интеграции
          return this.rest
            .serviceRequest({
              action: 'getArchiveByRange',
              system: { name: 'VAIntegration' },
              service: { name: 'VideoIntegration' },
              needResponse: true,
              data: {
                deviceId,
                startTime: moment(startAt - delta)
                  .utcOffset(0, false)
                  .format('DD.MM.YYYY+HH:mm:ss'),
              },
            })
            .pipe(map((response: IAbstractServiceData) => response.data.url as string));
        }),
        catchError((err) => {
          ScConsole.error(`${err.message}. Получение ссылки на видеопоток.`);
          return of(undefined);
        }),
      );
  }

  /**
   * Метод обновляет существующую запись в таблице VideoDevices сервиса Admin.
   * @param params — содержит данные переданные формой редактирования записи.
   * @param id
   */
  public updateVideoDevice(params: IAnyObject, id: string): Observable<IAbstractServiceData> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          attributes: ['monitoringSubject'],
          name: 'VCASettings',
          query: {
            id: { $in: [...(params.vcaSettings || [])] },
          },
        },
      })
      .pipe(
        switchMap((res) => {
          const vcaSettings = [...res.data.items];
          let vcaSettingsSysNames;
          if (vcaSettings.length > 0) {
            vcaSettingsSysNames = vcaSettings
              .map((setting) => {
                if (setting.monitoringSubject.id) {
                  return this.settings2
                    .getDictionaryByTypeSysName('monitoringSubject')
                    .find((elem: IDictionaryInfo) => elem.id === setting.monitoringSubject.id).sysname;
                }
              })
              .filter((elem: string) => elem);
          }
          // Преобразует выбранные настройки видео аналитики в побитовую маску.
          const analystSetup =
            vcaSettingsSysNames && vcaSettingsSysNames.length > 0 ? vcaSettingsSysNames.reduce((a, b) => a + b) : null;

          // Преобразует строковый статус устройства в соответствующее справочнику число.
          const status = this.settings2
            .getDictionaryByTypeSysName('devicesState')
            .find((item: IDictionaryInfo) => item.name === params.status)?.sysname;

          // Преобразует строковый режим записи в соответствующее справочнику число.
          const archiveMode = this.settings2
            .getDictionaryByTypeSysName('recordArchive')
            .find((item: IDictionaryInfo) => item.name === params.archiveMode)?.sysname;

          const data: IAnyObject = {
            ...params,
            archiveMode,
            status,
            analystSetup: analystSetup || 0,
          };

          return this.rest.serviceRequest({
            data,
            action: 'update',
            service: { name: 'Admin' },
            entity: {
              name: 'VideoDevices',
              query: { id },
            },
          });
        }),
      );
  }

  /**
   * Создание записи в таблице VideoDevices сервиса Admin.
   * @param data — содержит данные переданные формой создания записи.
   */
  public createVideoDevice(data: IAnyObject): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      data,
      action: 'insert',
      service: { name: 'Admin' },
      entity: { name: 'VideoDevices' },
    });
  }

  /**
   * Метод получения информации сервера по id
   * @param videoServer - id сервера
   * @return
   */
  public getVideoServerById(videoServer: string): Observable<IAbstractServiceData> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'VideoServers',
          query: {
            id: videoServer,
          },
        },
      })
      .pipe(
        catchError((err: Error) => {
          this.note.pushError('Ошибка при получении данных о видео сервере');
          ScConsole.error([err.message]);
          return of(null);
        }),
      );
  }

  /**
   * Метод получения настроек для ВА по предмету мониторинга
   * @param monitoringSubject - тип ВА
   * @return
   */
  public getTypeVaSettingsByMonitoringSubject(monitoringSubject: string): Observable<IMonitoringDate> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          attributes: ['timeBeforeEvent', 'timeAfterEvent'],
          name: 'VCASettings',
          query: {
            monitoringSubject,
          },
        },
      })
      .pipe(
        catchError((err: Error) => {
          this.note.pushError('Ошибка при получении данных о видео сервере');
          ScConsole.error([err.message]);
          return of(null);
        }),
        map((result: IAbstractServiceData) => {
          const res = result.data.items[0];
          if (res) {
            return {
              timeBeforeEvent: res.timeBeforeEvent * ONE_SECOND,
              timeAfterEvent: res.timeAfterEvent* ONE_SECOND,
            };
          }
          return null;
        }),
      );
  }

  /**
   * Получение периода продолжительности видео.
   * @param query - идентификатор камеры
   * @return объект продолжительности видео, миллисекунды
   */
  public getVideoDevicePeriod(
    query: IAnyObject,
  ): Observable<{ id: string; start: number; end: number; dTime: number }> {
    let cameraId = null;
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query,
          name: 'VideoDevices',
          linksMode: 'raw',
        },
      })
      .pipe(
        tap(({ data: { items } }: IAbstractServiceData) => {
          cameraId = items[0]?.id;
        }),
        map((response: IAbstractServiceData) => (response.data.items[0] ? response.data.items[0].vcaSettings : [])),
        switchMap((vcaSettingsIds: string[]) => {
          return this.rest.serviceRequest({
            action: 'select',
            service: { name: 'Admin' },
            entity: {
              attributes: [
                'id',
                'name',
                'monitoringSubject',
                'timeBeforeEvent',
                'timeAfterEvent',
                'needSaveToGRZRegistry',
                'action',
              ],
              query: {
                $or: [
                  { id: { $in: vcaSettingsIds } },
                  { monitoringSubject: { sysname: String(AnalystSetupsBitwiseEnum.searchTransport) } },
                  { monitoringSubject: { sysname: String(AnalystSetupsBitwiseEnum.grzRepeat) } },
                ],
              },
              name: 'VCASettings',
            },
          });
        }),
        map((response: IAbstractServiceData) => response.data.items),
        map((data: IVideoDeviceBg[]) => {
          return data.map((item: IVideoDeviceBg) => {
            item.monitoringSubject = this.settings2
              .getDictionaryByTypeSysName('monitoringSubject')
              .find((monitoring: IDictionaryInfo) => monitoring.id === (item.monitoringSubject as string));
            return item;
          });
        }),
        map((data: IVideoDeviceBg[]) => {
          const recognitionTransport = data.find((item: IVideoDeviceBg) =>
            Number(item['monitoringSubject.sysname'] === AnalystSetupsBitwiseEnum.recognitionTransport),
          );
          if (recognitionTransport) {
            return recognitionTransport;
          }
          // Проверяю есть ли настройки по мимо Разыскиваемый ГРЗ и Дубль ГРЗ
          const listSettings = [AnalystSetupsBitwiseEnum.searchTransport, AnalystSetupsBitwiseEnum.grzRepeat];
          const otherSettings = data.filter((item: IVideoDeviceBg) => {
            const monitoringSubject = Number(item['monitoringSubject.sysname']);
            return !listSettings.includes(monitoringSubject);
          });
          return otherSettings.length > 0 ? otherSettings[0] : data[0];
        }),
        map((elem: IVideoDeviceBg) => {
          const period: { id: string; start: number; end: number; dTime: number } = {
            id: cameraId,
            start: 30 * ONE_SECOND, // Начало периода
            end: 30 * ONE_SECOND, // Окончание периода
            dTime: ONE_MINUTE, // Общее время
          };
          if (!elem) {
            return period;
          }
          const monitoringSubject = elem as IDictionaryModelDto;
          if (this.isVsaSettingsExist(elem, monitoringSubject['monitoringSubject.sysname'])) {
            period.start = elem.timeBeforeEvent * ONE_SECOND;
            period.end = elem.timeAfterEvent * ONE_SECOND;
            period.dTime = (elem.timeBeforeEvent + elem.timeAfterEvent) * ONE_SECOND;
          }
          return period;
        }),
        catchError((err) => {
          ScConsole.error(`${err.message}. Получение периода на видеопоток.`);
          return of({
            id: cameraId,
            start: 30 * ONE_SECOND, // Начало периода
            end: 30 * ONE_SECOND, // Окончание периода
            dTime: 30 * 2 * ONE_SECOND, // Общее время
          });
        }),
      );
  }

  /** Проверка настроек vsaSettings
   * @param elem
   * @param sysName
   * @return
   */
  private isVsaSettingsExist(elem: IVideoDeviceBg, sysName: string): boolean {
    const listSettings1 = [AnalystSetupsBitwiseEnum.recognitionTransport, AnalystSetupsBitwiseEnum.lostObjectDetection];
    const listSettings2 = [AnalystSetupsBitwiseEnum.grzRepeat, AnalystSetupsBitwiseEnum.searchTransport];

    const isTimeValuesInteger = Number.isInteger(elem.timeAfterEvent) && Number.isInteger(elem.timeBeforeEvent);
    const monitoringSysnameAsInteger = Number(sysName);
    return (
      (listSettings1.includes(monitoringSysnameAsInteger) && isTimeValuesInteger) ||
      (listSettings2.includes(monitoringSysnameAsInteger) && isTimeValuesInteger && elem.action)
    );
  }

  /**
   * Формирование периода видео для детектирования
   *  @param extId
   *  @return
   */
  public getPeriodForRecognizedFacesByExtId(
    extId: string,
  ): Observable<{ id: string; start: number; end: number; dTime: number }> {
    return forkJoin([
      this.rest.serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          attributes: ['id', 'name', 'monitoringSubject', 'timeBeforeEvent', 'timeAfterEvent', 'needSaveToGRZRegistry'],
          query: {
            'monitoringSubject.sysname': '256',
          },
          name: 'VCASettings',
        },
      }),
      this.rest.serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query: {
            extId,
          },
          name: 'VideoDevices',
          linksMode: 'raw',
        },
      }),
    ]).pipe(
      map(([vaSettings, videoDevices]: [IAbstractServiceData, IAbstractServiceData]) => {
        const vaItem = vaSettings.data.items[0] || {};
        const deviceId = videoDevices.data.items[0]?.id;
        return {
          id: deviceId,
          start: (vaItem.timeBeforeEvent || 30) * ONE_SECOND,
          end: (vaItem.timeAfterEvent || 30) * ONE_SECOND,
          dTime: ((vaItem.timeBeforeEvent || 30) + (vaItem.timeAfterEvent || 30)) * ONE_SECOND,
        };
      }),
    );
  }

  /**
   * Получение статуса камеры от лесохранителя
   *  @param url
   *  @return
   */
  public getForestGuardCameraStatus(url: string): Observable<IAnyObject> {
    return this.http.get(url.replace('embed.html?proto=dash', 'status.json'))
  }

  /**
   * Подстановка протокола для отображения видео-потока камеры аналогичного протоколу страницы
   * @param mediaUrl - ссылка на видео-поток камеры
   */
  public normalizeMediaUrl(mediaUrl: string): string {
    if (!mediaUrl) return mediaUrl;

    let url = mediaUrl;
    if (mediaUrl.startsWith('http') && mediaUrl.includes('://')) {
      url = mediaUrl.split('://')[1]!;
    }
    return this.settings2.getConfig()?.useSslForArgusVideoArchive === 'TRUE' ? `https://${url}` : `http://${url}`;
  }

  /**
   * Получить категории по id видеокамеры
   * @param id: id видеокамеры
   * @param attributes: аттрибуты
   **/
  public getCategories(id: string, attributes?: string[]): Observable<IVideoDevicesCategoriesLinks[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'VideoDevicesCategoriesLinks',
          query: { videoDeviceId: id },
          attributes: attributes || ['id', 'videoDeviceId', 'categoryId'],
        },
      })
      .pipe(map((result: IAbstractServiceData) => result.data.items || []));
  }
}
