<div
  class="event-mini-card"
  [ngClass]="{ 'event-mini-card_is-handled': isHandled, 'event-mini-card_active': isActive }"
  (click)="selectEvent()"
>
  <div class="event-mini-card__icon-panel" [ngSwitch]="event['direction']">
    <i class="sc-icon-user" *ngSwitchDefault></i>
    <i class="sc-icon-arrow-right-45" *ngSwitchCase="'outgoing'"></i>
    <i
      class="sc-icon-arrow-right-to-left-45"
      *ngSwitchCase="'incoming'"
      [ngClass]="{ 'sc-icon-arrow-left-135': !isHandled }"
    ></i>
    <i class="sc-icon-sort-h" *ngSwitchCase="'local'"></i>
  </div>
  <div class="event-mini-card__main-panel">
    <div class="event-mini-card__header">
      <span class="event-mini-card__time">
        {{ event['timeCreate'] | date: 'dd.MM.yyyy HH:mm:ss' }}
      </span>
      <span class="event-mini-card__state">
        <span>{{ this.eventStatusName }}</span>
      </span>
    </div>
    <p class="event-mini-card__reason" *ngIf="event['closeReasonId.name']">
      <span class="text-underline">Причина закрытия:</span>&nbsp;
      <span>{{ event['closeReasonId.name'] }}</span>
    </p>
    <div class="event-mini-card__comment">
      {{ event['comment'] }}
    </div>
  </div>
</div>
