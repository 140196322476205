import { Component, Input, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseComponent } from '@bg-front/core/components';
import { IAlertParentSource } from "../../../bg/modules/dictionaries/modules/fire-monitoring-objects/models/interfaces";

@UntilDestroy()
@Component({
  selector: 'bg-alert-source-info-mini-card',
  templateUrl: './alert-source-mini-card.component.html',
  styleUrls: ['./alert-source-mini-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertSourceMiniCardComponent extends BaseComponent {
  /** Родительский источник */
  @Input() public parentSource: IAlertParentSource;

  constructor() {
    super();
  }
}
