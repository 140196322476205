import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessService, IAccessAction, Settings2Service } from '@smart-city/core/services';
import { MapBaseModel } from '@smart-city/maps/sc';
import { takeUntil } from 'rxjs/operators';
import { Coordinates } from '@bg-front/core/models/classes';
import { ISystemPolygon } from '../../models/interfaces';
import { PolygonsService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';
import { IPolygonDto } from '@bg-front/core/models/interfaces';

/**
 * Компонента отвечающая за отображение списка доступных полигонов
 */
@Component({
  selector: 'bg-map-polygons-legend',
  templateUrl: './map-polygons-legend.component.html',
  styleUrls: ['./map-polygons-legend.component.scss'],
})
export class MapPolygonsLegendComponent extends BaseComponent implements OnInit {
  /** Флаг: показываем какой либо полигон или нет */
  public showPolygon: string = undefined;
  public systemPolygons: ISystemPolygon[] = this.settings.getDictionaryByTypeSysName('polygonTypes')
  .map((polygon: IDictionaryInfo): ISystemPolygon => ({
    name: polygon.name,
    type: polygon.sysname,
    selected: false
  }));

  @Input()
  public mapModel: MapBaseModel;

  private SHOW_LEGEND = false;
  /** Мышка на элементе или нет */
  private mouseOnCurrentElement = false;

  constructor(
    private readonly polygonsService: PolygonsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly accessService: AccessService,
    private readonly settings: Settings2Service
  ) {
    super();
  }

  /** Показать легенду */
  public get showLegend(): boolean {
    return this.SHOW_LEGEND;
  }

  public set showLegend(value: boolean) {
    this.SHOW_LEGEND = value;
    /** Открываем список полигонов и если был выбран хоть один ранее, сбрасываем выбор */
    if (value) {
      if (this.showPolygon) {
        this.showPolygon = undefined;
        this.clearPolygons();
      }
    } else {
      this.drawPolygons();
    }
  }

  /** Доступность фильтра "Муниципальное образование" */
  public globalMoFilter: IAccessAction = { name: 'globalMoFilter' };

  /**
   * Следим, что мышка на элементе
   */
  @HostListener('mouseenter') onMouseenter() {
    this.mouseOnCurrentElement = true;
  }

  /**
   * Следим, что мышка покинула элемент
   */
  @HostListener('mouseleave') onMouseleave() {
    this.mouseOnCurrentElement = false;
  }

  /**
   * Обрабатываем событие, что кликнули другой элемент
   */
  @HostListener('document:click') clickedOutside() {
    if (!this.mouseOnCurrentElement && this.showLegend) {
      this.showLegend = false;
    }
  }

  public ngOnInit(): void {
    this.globalMoFilter = this.accessService.accessMap[this.globalMoFilter.name];
    /** скрываем выбор полигонов МО если клэйм выключен */
    if (!this.globalMoFilter?.visible) {
      this.systemPolygons = this.systemPolygons.filter((item) => item.type !== '01-municipalPolygons');
    }
  }

  /** Обработчик, вызываемый при клике на шеврон. Открыть легенду. */
  public onShowLegend(): void {
    this.showLegend = !this.showLegend;
  }

  /** Обработка факта выбора полигона */
  public changeRadioButtonHandler($event) {
    this.showPolygon = $event.value;
  }

  /** Добавляем новый пользовательский полигон */
  public newPolygon(): void {
    this.router.navigate([{ outlets: { editForm: ['new-polygon'] } }], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      state: { zoom: this.mapModel.currentZoom, center: this.mapModel.currentCenter },
    });
  }

  /** Очистить полигоны */
  private clearPolygons() {
    if (this.mapModel) {
      this.mapModel.removeLayer('polygon');
    }
  }

  /** Нарисовать полигоны */
  private drawPolygons() {
    if (this.mapModel && this.showPolygon) {
      this.polygonsService
        .getPolygonsBySysname(this.showPolygon)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((polygons: IPolygonDto[]) => {
          polygons.forEach((polygon: IPolygonDto) => {
            this.mapModel.addPolygonToLayer(
              'polygon',
              `p-${polygon.id}`,
              polygon.coordinates.map((coordinate: Coordinates) => coordinate.toArray()),
              { color: '#0072C3', weight: 2 },
            );
          });

          this.mapModel.viewLayer('polygon', false);
        });
    }
  }
}
