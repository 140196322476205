import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { IFiasValueDto } from 'smart-city-types';

import { IFiasServiceRequest, IFiasServiceResponse, IFiasServiceResponseElement } from '../models/interfaces';
import { Settings2Service } from '@smart-city/core/services';

const abbreviationsThatNeedAPoint = [
  'г',
  'обл',
  'Респ',
  'п',
  'тер',
  'у',
  'д',
  'м',
  'с',
  'сл',
  'ст',
  'х',
  'дор',
  'наб',
  'пер',
  'пл',
  'платф',
  'стр',
  'туп',
  'ул',
  'ш',
];

/** Сервис взаимодействия с ФИАС сервером */
@Injectable()
export class FiasService {
  /** Адрес сервиса ФИАС */
  public fiasUrl = '';

  /** @ignore */
  constructor(
    private readonly http: HttpClient,
    private readonly settings: Settings2Service,
  ) {
    this.fiasUrl = this.settings.getConfig().nFiasUrl || '/fias';
  }

  /**
   * Метод запроса данных в ФИАС
   * @param request отправляемый запрос в ФИАС
   */
  private sendRequest<T extends IFiasServiceResponse = IFiasServiceResponse>(request: IFiasServiceRequest): Observable<T> {
    return this.http.post<T>(`${this.getUrl()}${request.command}`, request);
  }

  /** Получить строку с полным адресом */
  private getFullAddress(element: IFiasServiceResponseElement[]): string | undefined {
    if (element !== undefined && Array.isArray(element) && element.length) {
      let house = '';
      const houseField = (element[0]!.house || [])[0];
      if (houseField) {
        house = houseField.number || '';

        if (houseField.strnum) {
          house += `/${houseField.strnum}`;
        }

        if (houseField.buildnum) {
          house += ` к. ${houseField.buildnum}`;
        }
      }
      return [...(element as IFiasServiceResponseElement[])]
        .reverse()
        .map((item: IFiasServiceResponseElement) => {
          let prefix = item.prefix || '';
          if (abbreviationsThatNeedAPoint.includes(prefix)) {
            prefix += '.';
          }
          return `${prefix} ${item.fullname}`;
        })
        .join(', ') + (house ? `, ${house}` : '');
    }
    return undefined;
  }

  /** Получить координаты выбранного адреса */
  public getCoordinates(
    houseGUID: string | undefined,
    aoguid?: boolean,
  ): Observable<{ latitude: number; longitude: number } | undefined> {

    return this.sendRequest({
      command: 'userchoice',
      [aoguid ? 'aoguid' : 'houseGUID']: [houseGUID],
    })
      .pipe(
        map((res: IFiasServiceResponse) => {
          if (res.command === 'success') {
            return {
              latitude: res.latitude,
              longitude: res.longitude,
            };
          }
          if (res.command === 'error') {
            console.error(`FiasService, getCoordinates error: ${res.error}`);
          }
          return undefined;
        }),
      );
  }

  /** Получить адреса по строке поиска */
  public find(term: string): Observable<IFiasValueDto[]> {
    return this.sendRequest({
      command: 'find',
      limit: 10,
      searchstring: term,
      typeaddr: 'all',
    }).pipe(
      map((response: any) => response.element),
      switchMap((addressItems: any[]) => {
        if (addressItems && addressItems.length) {
          const els = addressItems.map((item: any) => {
            const obj = this.createValue(item);
            obj.fullText = this.createLabel(item) || '';
            return obj;
          });

          return of(els);
        }

        return of([]);
      })
    );
  }

  /** Получить полный текст адреса */
  private createLabel(element: any) {
    if (element !== undefined && Array.isArray(element) && element.length) {
      let house = '';
      const houseField = ((element as any[])[0].house || [])[0];
      if (houseField) {
        house = houseField.number;

        if (houseField.strnum) {
          house += `/${houseField.strnum}`;
        }

        if (houseField.buildnum) {
          house += ` к. ${houseField.buildnum}`;
        }
      }
      return (
        [...(element as any[])]
          .reverse()
          .map((item) => {
            let prefix = item.prefix;
            if (abbreviationsThatNeedAPoint.includes(prefix)) {
              prefix += '.';
            }
            return `${prefix} ${item.fullname}`;
          })
          .join(', ') + (house ? `, ${house}` : '')
      );
    }

    return undefined;
  }

  /** Получить объект адреса */
  private createValue(option: any) {
    const value: IFiasValueDto = <IFiasValueDto>{};
    option.forEach((item: any, i: number) => {
      /**
       * Из каждого элемента берётся наименование и записывается в соответствующее поле объекта с итоговым значением.
       */
      const type = item.type as string;
      const prop: string | undefined = this.CODE_TYPE(type);
      if (prop) {
        ((value as any)[prop]) = item.fullname as string;
      }

      /**
       * ОКТМО может не быть на текущем уровне. Если оно так, то значение будет пустым и на следующей итерации
       * будет попытка взять ОКТМО у предка.
       */
      if (!value.oktmo) {
        value.oktmo = item.oktmo;
      }

      /**
       * Из первого элемента достаётся уникальный номер, записывается в uuid.
       * Если выбран дом, то он тоже сохраняется.
       */
      if (i === 0) {
        value.uuid = item.uuid;
        value.region = item.regioncode;

        if (
          item.house !== undefined &&
          Array.isArray(item.house) &&
          item.house.length
        ) {
          value.houseUuid = item.house[0].id;
          value.houseNumber = item.house[0].number;
          value.buildnum = item.house[0].buildnum;
          value.strnum = item.house[0].strnum;
          value.postalCode = item.house[0].postalcode;

          /**
           * Если координаты неизвестны, то придут нули. Лучше ничего не записывать.
           */
          if (item.house[0].latitude && item.house[0].longitude) {
            value.latitude = item.house[0].latitude;
            value.longitude = item.house[0].longitude;
          }
        }
      }
    });
    return value;
  }

  /** Получить тип по коду */
  private CODE_TYPE(value: string): string | undefined {
    if ('1' == value) {
      return 'region';
    }

    if ('3' == value) {
      return 'district';
    }

    if (['4', '5', '6'].includes(value)) {
      return 'city';
    }

    if (['7', '65', '91'].includes(value)) {
      return 'street';
    }

    if ('90' == value) {
      return 'city';
    }

    return undefined;
  }

  /**
   * Определение актуального http-пути до сервиса fias.
   * Если в настройке `fiasUrl` указан локейшн вида /fias, то он добавляется к текущему хосту.
   * В других случаях считаем, что в настройке полный домен, без протокола.
   * Протокол берём с текущего хоста.
   */
  private getUrl(): string {
    const fiasUrl = this.fiasUrl;
    let resultUrl: string;
    if (fiasUrl.startsWith('/')) {
      // Локейшн
      resultUrl = `${
        window.location.protocol
      }//${
        window.location.hostname
      }${
        window.location.port ? `:${window.location.port}` : ''
      }${
        fiasUrl
      }`;
    } else {
      // Домен
      resultUrl = `${window.location.protocol}//${fiasUrl}`;
    }

    if (!resultUrl.endsWith('/')) {
      resultUrl += '/';
    }

    return resultUrl;
  }
}
