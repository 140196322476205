<form *ngIf="form" [formGroup]="form">
  <ng-container formArrayName="list">
    <div class="item-container">
      <div *ngFor="let item of list; let i = index">
        <ng-container [formGroup]="item">
          <div class="item__body">
            <div class="bg-row bg-row-align-content-center">
              <div class="bg-col">
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-input [options]="fioOptions" formControlName="fio"></sc-input>
                    </div>
                  </div>
                  <div class="bg-col-min">
                    <div class="bg-form-group">
                      <sc-input [options]="birthYearOptions" formControlName="birthYear"></sc-input>
                    </div>
                  </div>
                  <div class="bg-col-5">
                    <div class="bg-form-group">
                      <sc-select [options]="shelterIdOptions" formControlName="shelterId"></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <sc-input [options]="relativesAddressOptions" formControlName="relativesAddress"></sc-input>
                  </div>
                  <div class="bg-col-5">
                    <sc-input [options]="commentOptions" formControlName="comment"></sc-input>
                  </div>
                </div>
              </div>
              <div class="bg-col-min">
                <button *ngIf="!!i || list.length > 1" mat-button (click)="delete(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="divider"><hr /></div>
      </div>
      <button mat-flat-button class="add-button" (click)="add()">Добавить</button>
    </div>
  </ng-container>
</form>
