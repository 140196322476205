import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { INwHeaderBarOptions, IScButtonOptions, IScTextareaOptions } from '@smart-city/core/common';
import { BaseComponent } from '@bg-front/core/components';

/**
 * Копмпонента реализует диалоговое окно возврата инцидента/происшествия в работу
 */
@Component({
  selector: 'bg-incident-back-to-work-dialog',
  templateUrl: './incident-back-to-work-dialog.component.html',
  styleUrls: ['./incident-back-to-work-dialog.component.scss'],
})
export class IncidentBackToWorkDialogComponent extends BaseComponent {
  /** Событие сохранения комментария */
  @Output()
  public confirmEvent = new EventEmitter(true);
  /** Событие отмены */
  @Output()
  public rejectEvent = new EventEmitter(true);
  /** Комментарий */
  @Output()
  public commentEvent: string;

  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions = {
    margin: 'collapse',
    title: 'Причина возврата происшествия в работу',
    buttons: [
      {
        type: 'button',
        options: {
          name: 'close',
          icon: 'close',
        },
      },
    ],
  };

  /** Настройки для кнопки сохранить */
  public saveOptions: IScButtonOptions = {
    title: 'Сохранить',
    color: 'primary',
  };
  /** Настройки кнопки отмена */
  public cancelOptions: IScButtonOptions = {
    title: 'Отмена',
  };

  /**
   * Настройка компоненты Комментарий
   */
  public commentOptions: IScTextareaOptions = {
    label: 'Комментарий',
    maxLength: 100,
    rows: 2,
  };

  constructor(
    public dialogRef: MatDialogRef<IncidentBackToWorkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
  }

  /** Закрыть это окно */
  public close(): void {
    this.rejectEvent.emit();
    this.dialogRef.close();
  }

  /** Сохранить комментарий и вернуть в работу */
  public save(): void {
    this.confirmEvent.emit(this.commentEvent);
    this.dialogRef.close();
  }
}
