import { Component } from '@angular/core';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'bg-dictionary-export-dialog',
  templateUrl: './dictionary-export-dialog.component.html',
  styleUrls: ['./dictionary-export-dialog.component.scss'],
})
export class DictionaryExportDialogComponent {
  /** Форма */
  public exportForm: FormGroup<
    ControlsOf<{
      fileType: string;
    }>
  >;

  constructor(private readonly modalRef: NzModalRef) {
    this.exportForm = new FormGroup({
      fileType: new FormControl<string>('xlsx'),
    });
  }

  /** Сохранение фильтра */
  public export() {
    this.modalRef.destroy(this.exportForm.value?.fileType);
  }

  public close(): void {
    this.modalRef.destroy(undefined);
  }
}
