import { Injectable } from '@angular/core';
import { RestService } from '@smart-city/core/services';
import { map } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';
import { Observable } from 'rxjs';
import { IMonitoringObjectHcsDto } from '../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/models/interfaces';

@Injectable({
  providedIn: 'root',
})

/**
 * Cервис для работы с Atm
 */
export class AtmIntegrationService {
  constructor(private readonly rest: RestService) {}

  /**
   * Получение сущности по id
   * @param id
   * @return
   */

  public getById(id: string) {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: {
          name: 'AtmIntegration',
        },
        entity: {
          name: 'MonitoringObject',
          linksMode: 'raw',
          query: {
            id,
          },
        },
      })
      .pipe(
        map(({ data: { items } }: IAbstractServiceData) => {
          return (items || [])[0];
        }),
      );
  }

  /**
   * Получение сущности по query
   * @param query - объект запроса
   */
  public getByQuery(query: IAnyObject): Observable<IMonitoringObjectHcsDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: {
          name: 'AtmIntegration',
        },
        entity: {
          name: 'MonitoringObject',
          linksMode: 'raw',
          query,
        },
      })
      .pipe(
        map(({ data: { items } }: IAbstractServiceData) => {
          return (items || []);
        }),
      );
  }

  /**
   * Проверка наличия связанных адресов у объекта мониторинга ЖКХ
   * @param monitoringObjectId - ID объекта мониторинга ЖКХ
   */
  public isExistBoundAddresses(monitoringObjectId: string): Observable<boolean> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'MonitoringObjectAddressOwnership',
        attributes: ['id'],
        query: { monitoringObjectId }
      }
    }).pipe(map(({ data: { items } }: IAbstractServiceData) => !!items.length));
  }
}
