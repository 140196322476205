import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { MapLayer } from '../../../models/classes';
import { MapLayersStore } from '../store/map-layers.store';

@UntilDestroy()
@Injectable()
export class MapLayersService {
  constructor(private readonly store: MapLayersStore) {}

  /**
   * Удаление записи
   */
  public delete(id: string): void {
    this.store.remove(id);
  }

  /**
   * Добавление слоя
   * @param data - слой
   */
  public add(data: MapLayer): void {
    if (data) {
      this.store.upsert(data.id, data);
    }
  }

  /**
   * Добавление видимости слоя
   * @param id - информация о рассчёте
   * @param value - состояние видимости
   */
  public setIsShow(id: string, value: boolean): void {
    this.store.update((layer: MapLayer): boolean => layer.isShow !== value && layer.id === id, { isShow: value });
  }
}
