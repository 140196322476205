import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

/** Валидатор даты удаления запрошенной информации */
export class RequestDeleteTimeValidator {
  /**
   * Валидация слишком долгого периода хранения
   * @param value - хранить не более указанного значения
   * @param unitOfTime - единицы измерения параметра value
   */
  static tooLong(value: number, unitOfTime: moment.unitOfTime.Diff): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const now = moment(Date.now());
      const deleteTime = moment(control.value);
      if (deleteTime.diff(now, unitOfTime) >= value) {
        return { tooLong: true };
      }
      return null;
    };
  }
}
