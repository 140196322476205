import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SelectAllOptionsA } from '@datorama/akita';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IKsipCategoryDto } from '../../../models/interfaces';
import { KsipCategoriesState } from '../../../models/states';
import { KsipCategoriesStore } from '../store/ksip-categories-store.service';

/** Сервис запросов к хранилищу Категорий КСиП */
@Injectable({
  providedIn: 'root',
})
export class KsipCategoriesQuery extends QueryEntity<KsipCategoriesState> {
  constructor(store: KsipCategoriesStore) {
    super(store);
  }

  /** Запрос по Id*/
  public getById(id: string): IKsipCategoryDto {
    return this.getEntity(id);
  }

  /** Запрос по множеству Id*/
  public getByIds(ids: string[]): Observable<IKsipCategoryDto[]> {
    return this.selectMany(ids);
  }

  /** Запрос по множеству Id*/
  public getForSelect(options?: SelectAllOptionsA<IKsipCategoryDto>): Observable<IScSelectItem[]> {
    return this.selectAll(options || {}).pipe(
      map((items: IKsipCategoryDto[]) =>
        items.map((item: IKsipCategoryDto) => <IScSelectItem>{ id: item.id, text: item.name }),
      ),
    );
  }

  public selectBySectionId(searchSectionId: string): Observable<IKsipCategoryDto[]> {
    // This will perform AND logic
    return this.selectAll({
      filterBy: ({ sectionId }: IKsipCategoryDto) => sectionId === searchSectionId,
    });
  }
}
