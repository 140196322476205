import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

import { FireMonitoringObjectsState } from '../../../models/states';

/**
 * Сервис хранилища Объекты пожарного мониторинга
 */
@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'fire-monitoring-objects-store', resettable: true })
export class FireMonitoringObjectsStore extends EntityStore<FireMonitoringObjectsState> {
  constructor() {
    super([]);
  }
}
