import { Pipe, PipeTransform } from '@angular/core';
import { IOrganization } from '../../models/interfaces';

/**
 * Пайп для отображения тултипа в селекте для организаций
 */

@Pipe({
  name: 'detailOrgSelectorTooltip',
})
export class DetailOrgSelectorTooltipPipe implements PipeTransform {

  /**
   *@param items - найденные организации
   *@return
   */
  transform(items: IOrganization[]): string {
    if (!items?.length) {
      return '';
    }
    return 'Нажмите для получения подробной информации об организации';
  }
}
