import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * Компонента реализует диалоговое окно с подтверждением удаления
 */
@Component({
  selector: 'bg-confirmation-dialog',
  templateUrl: './deleted-dialog.component.html',
  styleUrls: ['./deleted-dialog.component.scss'],
})
export class DeletedDialogComponent {
  constructor(public readonly dialogRef: MatDialogRef<DeletedDialogComponent>) {}

  /** Сообщение подтверждение */
  public message: string;

  /** Подтвердить действие*/
  public confirm() {
    this.dialogRef.close(true);
  }

  /** Отказать */
  public reject() {
    this.dialogRef.close(false);
  }
}
