import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IAnyObject } from 'smart-city-types';
import { IOrganizationType } from '../../models/interfaces';

/**
 * Вспомагательный сервис для работы с блоком Детализация по службам в форме редактирования события
 */

@Injectable({
  providedIn: 'root',
})
export class DetailOrganizationService {
  /**
   * Конвертация привлекаемых служб для модели
   * @return
   */
  public mapAttractionOrgForModel(attractionServices: IOrganizationType[], eventForm: FormGroup): IAnyObject[] {
    return attractionServices
      .filter((result: IOrganizationType) => {
        const control = eventForm.get(result.id);
        return control && control.value && eventForm.get(`org-${result.id}`)?.value;
      })
      .map((item: IOrganizationType) => {
        const orgId = eventForm.get(`org-${item.id}`).value;
        return { [item.id]: [orgId] };
      });
  }

  /**
   * Получение настроки для селектора с организациями в блоке Детализация по организациям
   * @param item - список организаций
   * @param mo - mo
   * @return
   */
  public getDefaultConfigForSelectorWithAttractService(item: IAnyObject, mo: string) {
    const defaultConfig = {
      service: 'Admin',
      entity: 'Organizations',
      query: { mo, active: true, orgTypeParam: item.id },
    };
    const config = {
      data: item.foundOrganizations.map((i) => {
        return { id: i.id, text: i.name, data: { ...i } };
      }),
    };
    return item.foundOrganizations.length ? config : defaultConfig;
  }
}
