import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IScButtonOptions, IScInputOptions, IScSelectOptions } from '@smart-city/core/common';
import { Uuid } from '@smart-city/core/utils';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IAbstractServiceData, IAnyObject, IEmergencyDto } from 'smart-city-types';
import { EmergencyDto } from '../../models/classes';
import {
  ICreateEmergencyStepsParams,
  IEmergencyResponsePlanStep,
  IOrganization,
  IOrganizationType,
  IStepDialogDataOptions,
} from '../../models/interfaces';
import { BgAdminService, EmergencyService, OrganizationsService, ResponsePlanStepService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';
import { Settings2Service } from '@smart-city/core/services';

/**
 * Компонент - флорма для просмотра/редактирования/добавления шага реагирования для привлекаемых служб
 * у которых не было создано поручение
 */
@UntilDestroy()
@Component({
  selector: 'bg-involve-org-without-commission-form',
  templateUrl: './involve-org-without-commission-form.component.html',
  styleUrls: ['./involve-org-without-commission-form.component.scss'],
})
export class InvolveOrgWithoutCommissionFormComponent extends BaseComponent implements OnInit {
  /** Опции для формы пробрасываемые с компонента диалога */
  @Input()
  public options: IStepDialogDataOptions;
  /** EventEmitter закрытия */
  @Output()
  closeEvent: EventEmitter<any> = new EventEmitter<any>();
  /** EventEmitter по нажатию на кнопку сохранить */
  @Output()
  saveEvent: EventEmitter<IEmergencyResponsePlanStep[]> = new EventEmitter<IEmergencyResponsePlanStep[]>();

  /** Форма */
  public involvedWithoutForm: FormGroup;
  /** Настройки для отображения Тип службы реагирования */
  public serviceTypeOptions: IScSelectOptions = {
    title: 'Тип службы реагирования',
    clearable: true,
    service: 'Admin',
    entity: 'OrgTypeParams',
    modern: true,
    fieldName: 'shortName',
    disabled: true,
  };
  /** Настройки для отображения Наименование службы */
  public optionsIncidentTypesOptions: IScSelectOptions = {
    title: 'Наименование службы *',
    service: 'Admin',
    entity: 'Organizations',
    modern: true,
    fieldName: 'name',
    query: {
      active: true,
    },
  };
  /** Настройки для кнопки сохранить */
  public saveBtnOptions: IScButtonOptions = {
    title: 'Отправить',
    color: 'primary',
  };

  /** Настройки для отображения Время реагирования (в ч.) */
  public timeReactOptions: IScInputOptions = {
    label: 'Время реагирования (в ч.)',
    type: 'number',
  };

  /** Настройки для отображения Время реакции (в м.) */
  public responseTimeOptions: IScInputOptions = {
    label: 'Время реакции (в м.)',
    type: 'number',
    maxLength: 10,
  };

  /** Настройки для кнопки отмена */
  public cancelBtnOptions: IScButtonOptions = {
    title: 'Отменить',
  };
  /** Состояние активности кнопки отправить */
  public isDisabledSaveBtn: boolean = false;
  /** id поручения */
  private orderId: string;

  constructor(
    private readonly emergencyService: EmergencyService,
    private readonly responsePlanStepService: ResponsePlanStepService,
    private readonly settings2: Settings2Service,
    private orgService: OrganizationsService,
    private readonly bgAdminService: BgAdminService,
  ) {
    super();
    dayjs.extend(duration);
  }


  public ngOnInit(): void {
    const serviceType = (this.options?.step?.serviceType as IOrganizationType)?.id || this.options?.serviceType;
    if (serviceType) {
      this.optionsIncidentTypesOptions.query['orgTypeParam'] = serviceType;
    }
    let attractOrgDataId = null;
    if (this.options?.step) {
      attractOrgDataId = this.options.step['attractOrgData.id'];
    }
    this.involvedWithoutForm = new FormGroup({
      serviceType: new FormControl(serviceType || null, [Validators.required]),
      attractOrgData: new FormControl(attractOrgDataId || null, [Validators.required]),
      timeReact: new FormControl(this.options?.step?.timeReact || this.options?.timeReact),
      responseTime: new FormControl(this.options?.step?.responseTime || this.options?.responseTime
        ? dayjs.duration(this.options?.step?.responseTime || this.options?.responseTime).asMinutes()
        : Math.ceil(parseFloat(this.settings2.getSettings().responsePlanStep || 0)),
      ),
    });
    if(this.options.disabled) this.involvedWithoutForm.disable();
  }

  /** Метод обновления данных о привлекаемой службе в шаге реагирования
   * @param  attractOrgData - id привлекаемой службы
   * @return
   */
  public editStep(attractOrgData: string): Observable<IEmergencyResponsePlanStep[] | IAbstractServiceData> {
    const responseStepStatus = this.settings2.getDictObjByTypeSysName('responseStepStatus');
    const done = responseStepStatus.done.id;
    if (this.options?.step?.id) {
      const updatedData = {
        attractOrgData,
        timeReact: this.involvedWithoutForm.controls.timeReact.value,
        responseTime: dayjs
          .duration(
            this.involvedWithoutForm.controls.responseTime.value ||
            Math.ceil(parseFloat(this.settings2.getSettings().responsePlanStep) || 0),
            'm',
          )
          .asMilliseconds(),
        status: done,
        attractOrgStatus: null,
        serviceType: this.options?.serviceType,
        // Не обновлять номер если он уже есть
        number: this.options?.step?.number
          ? undefined
          : this.responsePlanStepService.getListCount('involvedOrgOpts') + 1,
        // Если меняются данные о привлекаемой организации, то установить новое значение времени начала шага.
        // В настоящее время можно сделать проще и просто проверить наличие attractOrgData,
        // но на будущее пусть будет так. Меньше вероятность схватить ошибку.
        startTimeStep: this.options?.step?.attractOrgData !== attractOrgData ? new Date().getTime() : undefined,
        finishTimeStep: this.options?.step?.attractOrgData !== attractOrgData ? new Date().getTime() : undefined,
        ...(attractOrgData ? { dateAndTimeAttractService: new Date().getTime() } : {}),
        orderId: this.orderId,
      };
      return this.responsePlanStepService
      .updateStepItemById(this.options.step.id, updatedData, this.options.entity)
      .pipe(
        untilDestroyed(this),
        catchError((error: Error) =>
          this.catchErrorFn<IEmergencyResponsePlanStep[]>(
            error,
            'Ошибка получения сценария реагирования происшествия',
          ),
        ),
      );
    }
    const data = {
      attractOrgData,
      emergencyId: this.options?.emergencyModel?.id,
      serviceType: this.options?.serviceType,
      status: done,
      number: this.responsePlanStepService.getListCount('involvedOrgOpts') + 1,
      startTimeStep: attractOrgData ? new Date().getTime() : undefined,
      stepType: this.settings2.getDictionaryByTypeAndSysName('stepType', 'auto')?.id,
      orderId: this.orderId,
      timeReact: this.involvedWithoutForm?.controls?.timeReact.value,
      responseTime: dayjs.duration(
        this.involvedWithoutForm.controls.responseTime.value ||
        Math.ceil(parseFloat(this.settings2.getSettings().responsePlanStep) || 0),
        'm',
      ).asMilliseconds(),
    };
    return this.responsePlanStepService.createSteps([data], 'involvedOrgOpts').pipe(
      untilDestroyed(this),
      catchError((error: Error) =>
        this.catchErrorFn<IEmergencyResponsePlanStep[]>(error, 'Ошибка при создании шага для привлекаемых служб'),
      ),
      tap((result: IEmergencyResponsePlanStep[]) => {
        if (result?.length) {
          this.saveEvent.emit(result);
        }
      }),
    );
  }

  /** Метод создания поручения на основе родительского инцидента и шаги для него
   * @return
   */
  public createCommisionForOrganization(): Observable<IAnyObject> {
    const { id, ...emergencyModel } = this.options.emergencyModel;
    const order = Object.assign(new EmergencyDto(), emergencyModel);
    const createTime = Date.now();
    const docTypeDict = this.settings2.getDictObjByTypeSysName('docType');
    order.docType = docTypeDict.order.id;
    order.number = `o-${dayjs(emergencyModel.timeCreate).format('YYYYMMDD-HHmmss')}`;
    order.timeCreate = createTime;
    order.timeStart = null;
    order.parentId = this.options.emergencyModel.id;
    order.incidentTypeId = emergencyModel.incidentTypeId;
    order.lifeCycleStepId = null;
    order.organization = this.involvedWithoutForm.controls['attractOrgData'].value;
    order.createdBy = null;
    order.responsible = null;
    order.sourceType = null;
    order.parentEventId = null;
    if (this.involvedWithoutForm.controls['timeReact'].value) {
      order.deadline = createTime + this.involvedWithoutForm.controls['timeReact'].value * 3600000;
    }
    // Для правки бага BG-16564. Должен передаваться ID но передается объект со всем содержимым.
    // Аналитики в курсе, подумают как быть. Надо ли передавать ID или так же оставить пустым.
    order.dds01AdditionalInfo = null;
    this.isDisabledSaveBtn = true;
    return forkJoin([
      this.emergencyService
      .executeIncidentAction({
        action: 'save',
        model: order,
      })
      .pipe(
        untilDestroyed(this),
        catchError((error: Error) => this.catchErrorFn<IEmergencyDto>(error, 'Ошибка при создании поручения')),
        mergeMap((result: IEmergencyDto) => {
          const id = this.involvedWithoutForm.controls['attractOrgData'].value;
          this.orderId = result.id;
          return this.orgService.getOrganizationBy(id).pipe(
            map((org: IOrganization) => {
              return {
                emregency: result,
                moOrg: org.mo,
              };
            }),
          );
        }),
        mergeMap((result: { emregency: IEmergencyDto; moOrg: string }) => {
          const userMoId: string = this.bgAdminService.getUserMo();
          const params: ICreateEmergencyStepsParams = {
            orgTypeParamId: this.involvedWithoutForm.controls['serviceType'].value,
            timeReact: this.involvedWithoutForm.controls.timeReact.value,
            responseTime: dayjs.duration(
              this.involvedWithoutForm.controls.responseTime.value ||
              Math.ceil(parseFloat(this.settings2.getSettings().responsePlanStep) || 0),
              'm',
            ).asMilliseconds(),
            emergencyType: docTypeDict.order.id,
            incidentType: result.emregency.incidentTypeId,
            mo: result.moOrg || userMoId,
            emergencyId: result.emregency.id,
            involedTypeServices: [],
          };
          return this.responsePlanStepService.createResponseScriptSteps(params);
        }),
        tap(() => this.noteService.pushInfo('Служба привлечена к реагированию')),
      ),
    ]);
  }

  /** Метод закрытия диалога */
  public closeHandler(): void {
    this.closeEvent.emit();
  }

  /**
   * Обработчик клика кнопки отправить
   * @param event
   * @return
   */
  public sendHandler(event: Event): void {
    if (this.involvedWithoutForm.valid) {
      this.createCommisionForOrganization()
      .pipe(
        untilDestroyed(this),
        catchError((error: Error) => this.catchErrorFn(error, 'Ошибка при обновлении шага реагирования')),
        switchMap(() => {
          return this.editStep(this.involvedWithoutForm.controls['attractOrgData'].value);
        }),
        tap(() => this.noteService.pushInfo('Запись успешно обновлена')),
        tap(() => this.closeHandler()),
      )
      .subscribe();
    }
  }
}
