import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { IAnyObject, IIntegrationEventDto } from 'smart-city-types';
import { BaseEventEditFormComponent } from '../base-event-edit-form/base-event-edit-form.component';
import { mergeMap } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { IAddressDetailsDto, IDeclarer, IThermopoint } from '../../models/interfaces';
import { of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from "moment";

@UntilDestroy()
@Component({
  selector: 'bg-edds-event-edit-form',
  templateUrl: './edds-event-edit-form.component.html',
  styleUrls: ['./edds-event-edit-form.component.scss'],
})
export class EddsEventEditFormComponent extends BaseEventEditFormComponent implements OnInit, OnDestroy {
  private incidentFilter: IAnyObject;
  private eventFilter: IAnyObject;
  public linkedName: string;
  /** Термоточка */
  public thermoPoint: IThermopoint;
  public thermoPointInnerLink: string;


  /** @ignore */
  public override ngOnInit(): void {
    this.thermoPointInnerLink = `${ window.origin }/cuks/workspace/(thermopoint/${ this.thermoPoint?.id }//leftSidebar:all)`;

    super.ngOnInit();

    if (!this.model.incidentNumber?.startsWith('w')) {
      this.linkedName = `Инцидент ${this.model.incidentNumber}`;
    } else {
      this.linkedName = `Плановая работа ${this.model.incidentNumber}`;
    }

    // Случай когда открыто красное событие (неотвеченный звонок)
    // У модели есть parentId и нет заявителя и времени КСиП
    if (this.model.parentId && !this.checkExistedData()) {
      this.getModelFromIntegrationEvent();
    } else {
      this.initForm();
    }

    this.eventForm.controls['factAddress'].valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
      this.declarerStore.setKsipAddress(result);
    });

    this.eventForm.controls['details'].valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
      this.declarerStore.setBuildingInfo({ room: result.room, entrance: result.entrance, corp: result.corp });
    });

    // Сообщение об открытии формы звонка
    this.registryStateService.callFormOpened();

    // Подписка на изменение типа КСиП
    this.eventForm.controls['ksipTypeId'].valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      // Текущие значения фильтров
      const filters = {};
      this.registryStateService.getValue().$and.forEach((query) => {
        Object.keys(query).forEach((key) => (filters[key] = query[key]));
      });
      const eventsFilters = {};
      this.registryStateService.getEventsValue().$and.forEach((query) => {
        Object.keys(query).forEach((key) => (eventsFilters[key] = query[key]));
      });
      // Добавить значение типа КСиП
      filters['incidentTypeId'] = value;
      eventsFilters['ksipTypeId'] = value;
      if (!value) {
        delete filters['incidentTypeId'];
        delete eventsFilters['ksipTypeId'];
      }
      // Преобразование в query
      const result = [];
      Object.keys(filters).forEach((key) => result.push({ [key]: filters[key] }));
      const eventsResult = [];
      Object.keys(eventsFilters).forEach((key) => eventsResult.push({ [key]: eventsFilters[key] }));

      this.incidentFilter = { $and: result };
      this.eventFilter = { $and: eventsResult };

      this.registryStateService.patchValue(this.incidentFilter);
      this.registryStateService.patchEventsValue(this.eventFilter);
    });

    // Подписка на изменение Адреса
    this.eventForm.controls['factAddress'].valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((values) => {
      const { level } = values || {};
      let detail = this.eventForm.get('details')?.value;
      detail = detail ? { ...detail, storeys: level } : { storeys: level };
      this.eventForm.get('details').setValue(detail);

      const filters: IAnyObject = {};
      this.registryStateService.getValue().$and.forEach((query) => {
        Object.keys(query).forEach((key) => (filters[key] = query[key]));
      });
      this.deletePointQuery(filters.$or);

      const eventsFilters: IAnyObject = {};
      this.registryStateService.getEventsValue().$and.forEach((query) => {
        Object.keys(query).forEach((key) => (eventsFilters[key] = query[key]));
      });

      if (values && values.latitude && values.longitude) {
        filters.point = {
          $radius: {
            $lat: values.latitude,
            $lon: values.longitude,
            $meters: 500,
          },
        };
      } else {
        delete filters.point;
      }

      filters.$or = filters.$or.map((query: IAnyObject) => {
        const hasId = query.hasOwnProperty('id');
        if (!hasId) {
          return query;
        }
      });
      filters.$or = filters.$or.filter((item: IAnyObject) => item);

      if (values?.houseUuid || (values?.latitude && values?.longitude)) {
        let query: IAnyObject;
        if (values.houseUuid) {
          query = {
            $eq: ['$address.houseUuid', values.houseUuid],
          }
        } else {
          query = {
            $and: [
              { $expr: { $eq: ['$address.latitude', values.latitude.toString()] } },
              { $expr: { $eq: ['$address.longitude', values.longitude.toString()] } },
            ],
          }
        }

        this.emergencyService.getAddressDetails({
          $expr: {
            ...query,
          },
        })
          .pipe(untilDestroyed(this))
          .subscribe((res: IAddressDetailsDto[]) => {
            const result: string[] = [];
            res.map((item: IAnyObject) => item.emergencyId).forEach((item: string) => {
              if (!result.includes(item)) {
                result.push(item)
              }
            });

            if (result.length) {
              delete filters.point;
              filters.$or = [
                {
                  $and: [
                    { $or: filters.$or},
                    {
                      point: {
                        $radius: {
                          $lat: values.latitude,
                          $lon: values.longitude,
                          $meters: 500,
                        },
                      }
                    }
                  ]
                },
                { id: { $in: result } },
              ];
            }
            this.updateFilters(filters, eventsFilters);
          })
      } else {
        this.updateFilters(filters, eventsFilters);
      }
    });

    this.eventForm.controls['jkhObject'].valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((jkhObject) => {
      const filters: IAnyObject = {};
      this.registryStateService.getValue().$and.forEach((query) => {
        Object.keys(query).forEach((key) => (filters[key] = query[key]));
      });
      const eventsFilters: IAnyObject = {};
      this.registryStateService.getEventsValue().$and.forEach((query) => {
        Object.keys(query).forEach((key) => (eventsFilters[key] = query[key]));
      });
      const eventsResult = [];
      Object.keys(eventsFilters).forEach((key) => eventsResult.push({ [key]: eventsFilters[key] }));

      const queryEvent = jkhObject
        ? {
            $and: [
              {
                $or: [{ jkhObject }],
              },
              ...eventsResult,
            ],
          }
        : this.eventFilter
      const resultIncident = [];
      Object.keys(filters).forEach((key) => resultIncident.push({ [key]: filters[key] }));
      const queryIncident = jkhObject
        ? {
            $and: [
              {
                $or: [{ jkhObject }],
              },
              { 'docType.sysname': { $ne: 'order' } },
            ],
          }
        : this.incidentFilter;
      this.registryStateService.patchValue(queryIncident);
      this.registryStateService.patchEventsValue(queryEvent);
    });

    const details = this.model.details;
    this.declarerStore.setKsipAddress(this.model.address);
    this.declarerStore.setBuildingInfo({ room: details?.room, entrance: details?.entrance, corp: details?.corp });

    // Подписка на событие инициализации реестра по всем происшествиям.
    // Происходит обновление полей по которым в реестре всех происшествий происходит фильтрация,
    // для актуализации информации в нем.
    this.registryStateService.allEmergencyRegistryInitialized$.pipe(untilDestroyed(this)).subscribe(() => {
      this.eventForm.controls.factAddress.patchValue(this.eventForm.controls.factAddress.value);
      this.eventForm.controls.jkhObject.patchValue(this.eventForm.controls.jkhObject.value);
    });
  }

  public updateFilters(filters: IAnyObject, eventsFilters: IAnyObject): void {
    const result = [];
    const eventsResult = [];
    Object.keys(eventsFilters).forEach((key) => eventsResult.push({ [key]: eventsFilters[key] }));
    Object.keys(filters).forEach((key) => result.push({ [key]: filters[key] }));

    this.incidentFilter = { $and: result };
    this.eventFilter = { $and: eventsResult };

    this.registryStateService.patchValue(this.incidentFilter);
    this.registryStateService.patchEventsValue(this.eventFilter);
  }

  public closeForm() {
    this.router
      .navigate([{ outlets: { editCallForm: null, editEventForm: null, editForm: null } }], {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge',
      })
      .then(() => this.emergencyService.selectEvent(undefined));
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.declare.resetDeclarersStore();
    this.registryStateService.callFormClosed();
  }

  private checkExistedData(): boolean {
    return Boolean(this.model.declarerId) && Boolean(this.model.ksipTime);
  }

  private getModelFromIntegrationEvent() {
    of(this.model.parentId as IIntegrationEventDto)
      .pipe(
        mergeMap((model: IIntegrationEventDto) => {
          const dataModel = model;
          this.model.direction = dataModel.direction;
          this.model.isHandled = false;
          this.model.number = this.model.number || `e-${moment().format('YYYYMMDD-HHmmss')}`;
          this.model.parentId = dataModel?.id;
          this.model.sourceId = this.model.sourceId || dataModel.sourceId;
          this.model.timeCreate = this.model.timeCreate || Date.now();
          this.model.ksipTime = this.model.ksipTime || Date.now();

          if (dataModel?.extnum && dataModel?.extnum !== 'anonymous') {
            return this.declare
              .mapDeclarerData({
                parentId: {
                  extnum: dataModel.extnum,
                },
              })
              .pipe(
                mergeMap((declarer: IDeclarer) => {
                  if (declarer) {
                    this.model.declarerId = declarer;
                  }
                  const declarerId = this.model.declarerId;
                  const data = {
                    phone: declarerId?.phone || dataModel.extnum,
                    ...(declarerId?.fio ? { fio: declarerId.fio } : {}),
                    ...(declarerId?.address ? { address: declarerId.address } : {}),
                    ...(declarerId?.contactPhone ? { contactPhone: declarerId.contactPhone } : {}),
                    building: {
                      ...(declarerId?.building?.room ? { room: declarerId?.building?.room } : {} ),
                      ...(declarerId?.building?.entrance ? { entrance: declarerId?.building?.entrance } : {} ),
                      ...(declarerId?.building?.corp ? { corp: declarerId?.building?.corp } : {} ),
                    },
                  };
                  this.declare.$formData.next(data);
                  this.declarerStore.setLoadedPhone(declarerId?.phone);
                  return of(null);
                }),
              );
          }
          return of(null);
        }),
        takeUntil(this.ngUnsubscribe),
        catchError((err: Error) => {
          console.error(err.message);
          return of(undefined);
        }),
      )
      .subscribe(() => {
        this.initForm();
      });
  }
}
