<ng-container [formGroup]="$any(controlContainer.control)">
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-input [options]="coordinatesOptions" formControlName="coordinates"></sc-input>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        <button class="btn btn-sm btn-primary m-t-15" (click)="onClickSpecifyAddress()">Задать координаты</button>
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-select [options]="fireFormOptions" formControlName="technologicalFireFormId"></sc-select>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-select [options]="fireFightingEquipmentsOptions" formControlName="fireFightingEquipmentId"></sc-select>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isRoundedForm">
    <div class="bg-row">
      <div class="bg-col-6">
        <div class="bg-form-group">
          <sc-input [options]="angleValueOptions" formControlName="angleValue"></sc-input>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isRectangular">
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="smallerSideOptions" formControlName="smallerSide"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="windDirectionOptions" formControlName="windDirection"></sc-input>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select [options]="directionsCountOptions" formControlName="directionsCount"></sc-select>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select [options]="fireExtinguishingWaysOptions" formControlName="fireExtinguishingWayId"></sc-select>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
