import { Component, ElementRef, AfterViewInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/** Компонент color-picker
 *
 * @example
 *
 * <nz-form-control>
 *   <bg-color-picker
 *     formControlName="color"
 *     size='small'
 *   >
 *   </bg-color-picker>
 * </nz-form-control>
 *
 **/
@Component({
  selector: 'bg-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    }
  ]
})
export class ColorPickerComponent implements AfterViewInit, ControlValueAccessor {
  /** Значение цвета колор-пикера */
  public colorPickerValue: string;
  /** Элемент маркера с цветом в инпуте */
  public inputColorMarkerElement: HTMLElement;
  /** Значение в инпуте */
  public value: string = '';
  /** Метод события изменения значения инпута, использутеся для FormGroup */
  public onChange: (val: string) => void;
  /** Метод события прикасновения(blur) инпута, использутеся для FormGroup */
  public onTouched: () => void;
  /** Размер колор-пикера */
  @Input() public size: 'default' | 'large' | 'small' = 'default';

  /** @ignore */
  constructor(
    private el: ElementRef,
  ) {}

  /** @ignore */
  public ngAfterViewInit() {
    this.inputColorMarkerElement = this.el.nativeElement.querySelector('.ant-input-group-addon');
    this.setInputColorMarker();
  }

  /**
   * Метод устанавлевает значение цвета в колор пикере.
   * Срабатывает при инициализации компонента, принимая данные из форм группы,
   * а так же при выборе значения в колор пикере.
   * @param value — значение цвета.
   */
  private setInputValue(value?: string): void {
    this.value = value;
    this.colorPickerValue = value;
  }

  /**
   * Метод записывает значение в компонент.
   * Срабатывает при инициализации компонента.
   * @param value — значение цвета.
   */
  public writeValue(value: string) {
    this.setInputValue(value);
  }

  /** Обработать значение из компонента */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** Обработка потери фокуса */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Обработчик изменения цвета колорпикера */
  public changePickerValue() {
    this.setInputColorMarker();
    this.setInputValue(this.colorPickerValue);
    this.onChange(this.colorPickerValue);
  }

  /** Установить цвет маркера в инпуте */
  private setInputColorMarker() {
    this.inputColorMarkerElement.style.setProperty('background-color', this.colorPickerValue);
  }
}
