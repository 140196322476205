import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import {
  IElementButton,
  INwHeaderBarOptions,
  IRegistryColumn,
  IScButtonOptions,
  RegistryComponent,
} from '@smart-city/core/common';
import { IModifiedData } from '@smart-city/core/interfaces';
import { NotificationService, ScNavService, Settings2Service, SubscriberService } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';

import { TaskDialogComponent } from '../task-dialog/task-dialog.component';
import { TaskService } from '../task.service';

/** Страница просмотра выбранного задания */
@Component({
  selector: 'task-layout',
  templateUrl: './task-layout.component.html',
  styleUrls: ['./task-layout.component.scss'],
})
export class TaskLayoutComponent extends BaseComponent implements OnInit {
  /** ID открытого на просмотр задания */
  public taskId: string;
  /** Объект задания на обзвон */
  public task: IAnyObject = {};
  /** Список элементов Должностынх лиц */
  public adminOfficialList: string[] = [];
  public showCancelButton;
  public showWorkButton;
  public showPauseButton;
  public showStopButton;
  public loadDataFn: () => void;
  /** Настройки хедера боковой панели  */
  public sidebarHeaderOptions: INwHeaderBarOptions = {
    title: 'Задание',
    margin: 'collapse',
    buttons: [
      {
        type: 'button',
        options: {
          name: 'burger',
          icon: 'menu',
        },
      },
    ],
  };
  /** Настройки таблицы номеров для обзвона */
  public registryCitizens: {
    tableName: string;
    columns: IRegistryColumn[];
    query: IAnyObject;
  };
  /** Настройки таблицы номеров для обзвона */
  public registryOfficials: {
    tableName: string;
    columns: IRegistryColumn[];
    query: IAnyObject;
  } = {
    tableName: 'Admin_Officials',
    columns: [
      {
        name: 'id',
        title: 'id',
        hidden: true,
        sort: [],
      },
      {
        name: 'fio',
        title: 'ФИО',
      },
      {
        name: 'position',
        title: 'Должность',
      },
      {
        name: 'phone',
        title: 'Телефон',
        format: 'phone',
      },
    ],
    query: null,
  };
  public buttons = {
    edit: <IScButtonOptions>{
      icon: 'edit',
      hint: 'Редактировать',
    },
    cancel: <IScButtonOptions>{
      hint: 'Отменить',
      icon: 'not_interested',
    },
    work: <IScButtonOptions>{
      hint: 'В работу',
      icon: 'play_arrow',
      color: 'primary',
    },
    pause: <IScButtonOptions>{
      icon: 'pause',
      hint: 'Пауза',
    },
    stop: <IScButtonOptions>{
      icon: 'stop',
      hint: 'Завершить',
      color: 'primary',
    },
    back: <IScButtonOptions>{
      icon: 'arrow_back',
      title: 'Назад',
      color: 'primary',
    },
  };
  /** Доступна история переходов окна */
  public isHistory: boolean = false;
  /** СМС рассылка */
  public isSms: boolean = false;
  /** Реестр подзадач */
  @ViewChild(RegistryComponent)
  private unitsRegistry: RegistryComponent;
  /** Флаг для отображения соотвествующего реестра */
  private isAdminOfficialVisible: boolean;

  constructor(
    private readonly dialog: MatDialog,
    private readonly location: Location,
    private readonly note: NotificationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly scNavService: ScNavService,
    private readonly settings2: Settings2Service,
    private readonly subscriber: SubscriberService,
    public readonly taskService: TaskService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.isHistory = window.history.length > 1;
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.taskId = params.get('id');
          return this.getTaskData(this.taskId);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((task: IAnyObject) => {
        if (!this.isSms) {
          this.subscriber
            .onTableChange('Gaster', 'Unit')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: IModifiedData<IAnyObject>) => {
              this.unitsRegistry.refresh$.emit();
            });
        }
      });
  }

  /**
   * Обработка нажатий на кнопки в хедере боковой панели
   * @param event Нажатая кнопка
   */
  public onClickSidebarHeaderButton(event: IElementButton): void {
    switch (event.options.name) {
      case 'burger':
        this.scNavService.openMenu();
        break;
    }
  }

  /**
   * Обработка нажатия на кнопку Назад
   */
  public onClickBackButton(): void {
    this.location.back();
  }

  /**
   * Обработка нажатия на кнопки перевода статуса задания
   * @param state Новый статус
   */
  public onClickStateButton(state: string) {
    if (this.isSms) {
      this.sendSms();
      return;
    }
    this.taskService
      .setTaskStatus(this.taskId, state)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          this.getTaskData(this.taskId);
        },
        (e) => console.error(e),
      );
  }

  /** Обработка события нажатия на кнопку Редактировать */
  public onClickEditTask(): void {
    this.dialog
      .open(TaskDialogComponent, {
        data: {
          action: 'update',
          type: this.task['type.sysname'],
          value: {
            ...this.task,
          },
        },
        width: '840px',
      })
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (taskData: IAnyObject) => {
          if (taskData) {
            this.taskService
              .updateTask(
                this.settings2.currentUser,
                {
                  'gasterCallTaskId.id': this.task['gasterCallTaskId.id'],
                  ...taskData,
                },
                this.taskId,
              )
              .pipe(
                switchMap(() => this.getTaskData(this.taskId)),
                takeUntil(this.ngUnsubscribe),
              )
              .subscribe(
                () => {
                  return;
                },
                (e) => console.error(e),
              );
          }
        },
        (e) => console.error(e),
      );
  }

  /**
   * Проверка для отображения соответствующего реестра в карточке
   * @return
   */
  public isAdminOfficials(): boolean {
    return this.isAdminOfficialVisible && this.isAdminOfficialVisible !== null;
  }

  /**
   *  Устанавливает query для реестра
   * @return
   */
  public setRegistryQuery(): void {
    const reqistry = this.isAdminOfficialVisible ? 'registryOfficials' : 'registryCitizens';
    this[reqistry].query = !this.isAdminOfficialVisible ? { taskId: this.taskId } : {};
  }

  /**
   * Получение данных задания
   * @param taskId ID задания
   */
  private getTaskData(taskId: string): Observable<IAnyObject> {
    return this.taskService.getCitizenFromOfficials(taskId).pipe(
      takeUntil(this.ngUnsubscribe),
      mergeMap((adminOfficialList: IAnyObject) => {
        this.isAdminOfficialVisible = Boolean(adminOfficialList.length);
        this.adminOfficialList = adminOfficialList.map((item) => item.id);
        return this.taskService.getTask(taskId);
      }),
      tap((task: IAnyObject) => {
        this.task = task;
        this.showCancelButton = task['state.sysname'] === 'new';
        this.showWorkButton = ['new', 'pause'].includes(task['state.sysname']);
        this.showPauseButton = task['state.sysname'] === 'inProgress';
        this.showStopButton = ['inProgress', 'pause'].includes(task['state.sysname']);
        this.isSms = this.task['type.id'] === this.settings2.getDictObjByTypeSysName('taskType')['smsSending']?.id;

        this.registryCitizens = {
          tableName: 'Calls_TasksCitizensRelations',
          columns: [
            {
              name: 'id',
              title: 'id',
              hidden: true,
              sort: [
                {
                  field: 'citizenId.surname',
                  direction: 'desc',
                },
                {
                  field: 'citizenId.firstName',
                  direction: 'desc',
                },
                {
                  field: 'citizenId.patronymic',
                  direction: 'desc',
                },
              ],
            },
            {
              name: 'citizenId.id',
              title: 'citizenId',
              hidden: true,
            },
            {
              name: 'fullName',
              title: 'ФИО',
            },
            {
              name: 'phone',
              title: 'Телефон',
              format: 'phone',
            },
            {
              name: this.isSms ? 'status.name' : 'gasterUnitId.state.name',
              title: this.isSms ? 'Состояние отправки' : 'Состояние звонка',
            },
            {
              name: 'gasterUnitId.state.sysname',
              hidden: true,
            },
          ],
          query: null,
        };

        this.setRegistryQuery();

        return task;
      }),
    );
  }

  private sendSms() {
    this.taskService
      .getNumbersFoSmsSending(this.taskId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (
          data: {
            id: string;
            phoneNumber: string;
          }[],
        ) => {
          this.taskService
            .updateTaskState(this.taskId, this.settings2.getDictObjByTypeSysName('taskStatus')['inProgress']?.id)
            .subscribe();
          data.forEach((el: { id: string; phoneNumber: string }) => {
            this.taskService.sendSms(el.id, el.phoneNumber, this.task.comment).subscribe();
          });

          this.taskService
            .updateTaskState(this.taskId, this.settings2.getDictObjByTypeSysName('taskStatus')['finished']?.id)
            .pipe(
              takeUntil(this.ngUnsubscribe),
              mergeMap(() => {
                return this.getTaskData(this.taskId);
              }),
              tap(() => {
                this.noteService.pushSuccess('Отправка закончена');
              }),
            )
            .subscribe();
        },
      );
  }
}
