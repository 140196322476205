<div class="bg-incident-panel container">
  <div class="bg-row">
    <div class="bg-col p-horizontal-none">
      <div class="bg-incident-panel-edit-from">
        <form [formGroup]="incidentForm" appLCSFormState [params]="attributes" errorTailor id="idForTest">
          <nw-header
            [options]="headerActionsOptions"
            (clickButton)="onClickActionsButton($event)"
            class="b-r-none"
          ></nw-header>
          <div class="incident-panel">
            <div class="bg-col" style="text-align: right" *ngIf="model?.parentEventId">
              <div class="bg-form-group-incident">
                <a href="javascript:void(0)" (click)="showEventInNewWindow()">
                  Событие&nbsp;{{ model.parentEventId['number'] }}
                </a>
              </div>
            </div>
            <mat-accordion multi="true">
              <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Главное</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bg-row space-between-container">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <span>Регистрация: {{ model.timeCreate | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <span>Начало: {{ model.timeStart | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <span>Окончание: {{ model.timeFinish | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                    </div>
                  </div>
                </div>
                <!-- Чекбокс Адрес по координатам -->
                <div class="bg-row">
                  <div class="bg-col align-right">
                    <sc-checkbox [options]="byCoordinatesOptions" formControlName="byCoordinates"></sc-checkbox>
                  </div>
                </div>
                <!-- Адрес задан координатами -->
                <div class="bg-row" *ngIf="byCoordinates; else fiasAddress">
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <sc-input [options]="coordinatesAddressOptions" formControlName="coordinatesAddress"></sc-input>
                    </div>
                  </div>
                </div>
                <!-- Адрес задан в формате ФИАС -->
                <ng-template #fiasAddress>
                  <div class="bg-row">
                    <div class="bg-col">
                      <div class="bg-form-group full-width">
                        <div nz-form nzLayout="vertical">
                          <nz-form-item>
                            <nz-form-label nzRequired>Фактический адрес</nz-form-label>
                            <nz-form-control>
                              <nz-address-kit formControlName="addressFact"></nz-address-kit>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <sc-select
                        [options]="optionsIncidentTypes"
                        formControlName="incidentTypeId"
                        [data]="loadKsipTypes$"
                      ></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea [options]="optionsDescription" formControlName="description"></sc-textarea>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea [options]="optionsComment" formControlName="comment"></sc-textarea>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select [options]="optionsDeviceName" formControlName="deviceName"></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select [options]="optionsResponsibleUser" formControlName="responsible"></sc-select>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Информация о событиях </div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <grid-table [grid]="eventsGridOptions"></grid-table>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Дополнительная информация</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea
                        class="resolution-report-container"
                        [options]="reportOptions"
                        formControlName="report"
                      >
                      </sc-textarea>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
