import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { IPopulationIrradiationCloudTrailResultsDto } from 'smart-city-types';

import { IExposurePopulationOvercomingCloudTrailTaskDto, IOvercomingPathPointDto } from '../../models';
import { ForecastingRadioactiveEnvironmentVisualizationService } from '../../services';

@Component({
  selector: 'bg-exposure-population-overcoming-cloud-trail-task-result',
  templateUrl: './exposure-population-overcoming-cloud-trail-task-result.component.html',
  styleUrls: ['./exposure-population-overcoming-cloud-trail-task-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExposurePopulationOvercomingCloudTrailTaskResultComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @Input()
  public forecastingResults: IForecastingResultDto;

  private elements: any[];

  @Input()
  public downloadReport: Observable<void>;

  /** Уведомляем о наличии отчёта */
  @Output()
  public hasReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  /** @ignore */
  constructor(
    private readonly settings: Settings2Service,
    private readonly forecastingService: ForecastingRadioactiveEnvironmentVisualizationService,
  ) {
    super();
  }

  public get result(): IPopulationIrradiationCloudTrailResultsDto {
    return this.forecastingResults?.result as IPopulationIrradiationCloudTrailResultsDto;
  }

  /** Ссылка на результат отрисовки */
  public get unitName(): string {
    if (this.forecastingResults?.params?.params.unitRadiationId) {
      return this.settings.getDictionaryById(this.forecastingResults?.params?.params.unitRadiationId)?.name;
    }

    return '';
  }

  /**
   * @ignore
   */
  public ngOnInit(): void {
    const points = (this.forecastingResults.params.params as IExposurePopulationOvercomingCloudTrailTaskDto).pathPoints;
    if (new Coordinates(this.forecastingResults.params.coordinates).isValid()) {
      this.elements = this.forecastingService.displayPath(
        points.map((el: IOvercomingPathPointDto) => new Coordinates(el.coordinates).toArray()),
      );
    } else {
      this.noteService.pushWarning('Отсутствуют координаты для визуализации расчёта');
    }

    this.hasReport.emit(false);
  }

  /**
   * @ignore
   */
  public override ngOnDestroy(): void {
    this.forecastingService.clearResults(this.elements);
  }
}
