<div class="map-layers-filter-dialog-container">
  <nw-header [options]="headerOptions" (clickButton)="close()"></nw-header>
  <div mat-dialog-content>
    <ng-container [formGroup]="form">
      <div class="bg-row" *ngIf="!data.hideMyIncidentsFilter && layersWithMyIncidentsFilterExist">
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-checkbox [options]="myIncidentsOptions" formControlName="myIncidents"></sc-checkbox>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="bg-row" *ngIf="isMoFilterVisible">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select [options]="moOptions" (selectionChange)="onMoChange($event)"></sc-select>
        </div>
      </div>
    </div>
    <bg-map-planned-works-filter
      *ngIf="data.mapLayersFilters['Плановые работы'] && !hideFilters['Плановые работы'] && isPlannedWorksLayerVisible"
      [title]="'Плановые работы'"
      [mapLayerFilters]="data.mapLayersFilters['Плановые работы']"
      (changedFilters)="onFiltersChange('Плановые работы', $event)"
    ></bg-map-planned-works-filter>
    <map-emergency-filter
      *ngIf="data.mapLayersFilters['Инциденты'] && !hideFilters['Инциденты']"
      [title]="'Инциденты'"
      [mapLayerFilters]="data.mapLayersFilters['Инциденты']"
      (changedFilters)="onFiltersChange('Инциденты', $event)"
    ></map-emergency-filter>
    <map-emergency-filter
      *ngIf="data.mapLayersFilters['Поручения'] && !hideFilters['Поручения']"
      [title]="'Поручения'"
      [mapLayerFilters]="data.mapLayersFilters['Поручения']"
      (changedFilters)="onFiltersChange('Поручения', $event)"
    ></map-emergency-filter>
    <map-emergency-filter
      *ngIf="data.mapLayersFilters['Неисправности видеокамер'] && !hideFilters['Неисправности видеокамер']"
      [title]="'Неисправности видеокамер'"
      [mapLayerFilters]="data.mapLayersFilters['Неисправности видеокамер']"
      (changedFilters)="onFiltersChange('Неисправности видеокамер', $event)"
    ></map-emergency-filter>
    <map-significant-object-filter
      *ngIf="data.mapLayersFilters['Важные объекты'] && !hideFilters['Важные объекты']"
      [mapLayerFilters]="data.mapLayersFilters['Важные объекты']"
      (changedFilters)="onFiltersChange('Важные объекты', $event)"
    ></map-significant-object-filter>
    <map-video-camera-filter
      *ngIf="data.mapLayersFilters['Видеокамеры'] && !hideFilters['Видеокамеры']"
      [mapLayerFilters]="data.mapLayersFilters['Видеокамеры']"
      (changedFilters)="onFiltersChange('Видеокамеры', $event)"
    ></map-video-camera-filter>
    <map-vehicle-filter
      *ngIf="data.mapLayersFilters['Транспортные средства'] && !hideFilters['Транспортные средства']"
      [mapLayerFilters]="data.mapLayersFilters['Транспортные средства']"
      (changedFilters)="onFiltersChange('Транспортные средства', $event)"
    ></map-vehicle-filter>
    <map-organization-filter
      *ngIf="
        data.mapLayersFilters['Организации Экстренного реагирования'] &&
        !hideFilters['Организации Экстренного реагирования']
      "
      [title]="'Организации Экстренного реагирования'"
      [mapLayerFilters]="data.mapLayersFilters['Организации Экстренного реагирования']"
      [layerFilters]="data.layersFilters['Организации Экстренного реагирования']"
      (changedFilters)="onFiltersChange('Организации Экстренного реагирования', $event)"
    ></map-organization-filter>
    <map-organization-filter
      *ngIf="
        data.mapLayersFilters['ДДС городских служб и организаций'] && !hideFilters['ДДС городских служб и организаций']
      "
      [title]="'ДДС городских служб и организаций'"
      [mapLayerFilters]="data.mapLayersFilters['ДДС городских служб и организаций']"
      [layerFilters]="data.layersFilters['ДДС городских служб и организаций']"
      (changedFilters)="onFiltersChange('ДДС городских служб и организаций', $event)"
    ></map-organization-filter>
    <map-eco-objects-filter
      *ngIf="data.mapLayersFilters['Объекты экомониторинга'] && !hideFilters['Объекты экомониторинга']"
      title="Объекты экомониторинга"
      [mapLayerFilters]="data.mapLayersFilters['Объекты экомониторинга']"
      (changedFilters)="onFiltersChange('Объекты экомониторинга', $event)"
    ></map-eco-objects-filter>
  </div>

  <div mat-dialog-actions>
    <sc-button [options]="cancelOptions" (clickButton)="close()"></sc-button>
    <sc-button [options]="applyOptions" (clickButton)="apply()"></sc-button>
  </div>
</div>
