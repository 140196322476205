import { Injectable } from '@angular/core';
import { BaseEmergencyService } from '@bg-front/core/services';
import { RestService } from '@smart-city/core/services';

/**
 * Сервис для получения данных о реестрах для отображения на панели
 */
@Injectable()
export class RegistryEmergencyService extends BaseEmergencyService {
  constructor(rest: RestService) {
    super(rest);
  }
}
