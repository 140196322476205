import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { ISelectItem } from '@bg-front/core/models/interfaces';
import { WeatherDataService } from '@bg-front/weather-data/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { distinctUntilChanged } from 'rxjs/operators';

import { BaseForecastingRadioactiveEnvironmentComponent } from '../base-forecasting-radioactive-environment/base-forecasting-radioactive-environment.component';

/**
 * Компонент реализует блок параметров прогнозирования "Облучение щит. железы"
 */
@UntilDestroy()
@Component({
  selector: 'bg-thyroid-irradiation-task',
  templateUrl: './thyroid-irradiation-task.component.html',
  styleUrls: ['./thyroid-irradiation-task.component.scss'],
})
export class ThyroidIrradiationTaskComponent
  extends BaseForecastingRadioactiveEnvironmentComponent
  implements OnInit, OnDestroy {
  /** Настройка компонента Способ ввода дозы облучения */
  public inputTypes = this.settings
    .getDictForSelect('radiationDoseInputTypes')
    .filter((item: IScSelectItem) => this.settings.getDictionaryById(item.id)?.sysname !== 'dangerCalculationDoses')
    .map((el: IScSelectItem) => {
      return <ISelectItem>{
        text: el.text,
        value: el.id,
      };
    });

  /** Единица измерения */
  public unitName: string;

  /** Единицы измерения */
  public units: ISelectItem[] = this.getDictionaryForSelect('unitRadiation');

  constructor(
    private readonly controlContainer: ControlContainer,
    settings: Settings2Service,
    weatherService: WeatherDataService,
    notificationService: NzNotificationService,
  ) {
    super(settings, weatherService, notificationService);
  }

  /** @ignore */
  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;

    this.form
      .get('inputTypeId')
      ?.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value) => {
        if (value && this.settings.getDictionaryById(value).sysname === 'manually') {
          this.form.get('dose')?.enable();
        } else {
          this.form.get('dose')?.setValue(null);
          this.form.get('dose')?.disable();
        }
      });

    this.form
      .get('unitRadiationId')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        this.unitName = this.settings.getDictionaryById(value)?.name;
      });
  }
}
