import { Injectable } from '@angular/core';
import { ICustomSelectItem } from '@bg-front/core/models/interfaces';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';

/**
 * Сервис для прогнозирования АХОВ
 */
@Injectable()
export class ForecastingBlowoutService {
  constructor(private readonly settings: Settings2Service, private readonly rest: RestService) {}

  /** Получаем список типов */
  public getChemicalTypesForSelect(): Observable<ICustomSelectItem[]> {
    return this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: 'Forecasting' },
          entity: {
            name: 'ChemicalType',
            sort: { field: 'name', direction: 'asc' },
            attributes: ['id', 'name', 'aggregationState.id', 'aggregationState.name'],
          },
        },
        'http',
      )
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || []).map(
            (el: IAnyObject): ICustomSelectItem =>
              <ICustomSelectItem>{
                text: el.name,
                value: el.id,
                aggregationState: el['aggregationState'],
                aggregationStateName: el['aggregationState.name'],
              },
          );
        }),
      );
  }

  /**
   * Получить все хранимые АХОВ для важного объекта
   * @param significantObject id важного объекта
   */
  public getStoredChemicalsForSignificantObject(significantObject: string): Observable<ICustomSelectItem[]> {
    return this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: 'Forecasting' },
          entity: {
            name: 'StoredChemicals',
            attributes: [
              'chemicalType',
              'chemicalType.id',
              'chemicalType.name',
              'aggregationState.id',
              'aggregationState.name',
              'chemicalAmount',
            ],
            query: {
              significantObject,
              chemicalType: {
                id: {
                  $ne: null,
                },
              },
              aggregationState: {
                id: {
                  $ne: null,
                },
              },
            },
          },
        },
        'http',
      )
      .pipe(
        map((response) => {
          return (response?.data?.items || []).map(
            (el: IAnyObject): ICustomSelectItem =>
              <ICustomSelectItem>{
                text: el['chemicalType.name'],
                value: el['chemicalType.id'],
                aggregationState: el['aggregationState'],
                aggregationStateName: el['aggregationState.name'],
                chemicalAmount: el['chemicalAmount'],
              },
          );
        }),
      );
  }

  /**
   * Получаем количество хранимого АХОВ на предприятии
   * @param id Id типа
   * @returns количество хранимого АХОВ
   */
  public getTotalChemicalTypeAmountById(id: string): Observable<string> {
    return this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: 'Forecasting' },
          entity: {
            name: 'ChemicalType',
            attributes: ['chemicalAmount'],
            query: { id },
          },
        },
        'http',
      )
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || [])[0].chemicalAmount;
        }),
      );
  }
}
