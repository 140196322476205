import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';
import { IAtmDevice, IAtmNodes, IMonitoringObjectHcsDto } from '../models/interfaces';
import { RestService } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import { AddressOwnershipObject } from '../../../../../../app-common/models/types';
import { GarFindFlatResponseElement } from '@bg-front/core';

/**
 * Сервис для работы со справочником объектов мониторинга ЖКХ
 */
@Injectable({ providedIn: 'root' })
export class MonitoringObjectHcsService {
  /** @ignore */
  constructor(private readonly rest: RestService) {}

  /** Получение Объекта мониторинга ЖКХ для формы просмотра/редактирования */
  public getHcsMonitoringObject(id: string): Observable<IMonitoringObjectHcsDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'AtmIntegration' },
        entity: {
          name: 'MonitoringObject',
          query: { id },
          attributes: [
            'id',
            'creationTime',
            'updateTime',
            'name',
            'address',
            'coordinates',
            'showOnMap',
            'statusId.id',
            'statusId.name',
            'supplyTypeId.id',
            'supplyTypeId.name',
            'extId',
            'serverId.integrationTypeId.name',
            'serverId.name',
            'serverId.organizationId.name',
            'serverId.organizationId.phone1',
            'serverId.paramCollectionInterval',
            'serverId.lastTimeUpdate',
            'hcsObjectKindId.id',
            'hcsObjectTypeId.id',
            'hcsObjectStatus',
            'operatingOrganizationId.id',
            'ownerOrganizationId.id',
            'routineMaintenanceId.statusId.name',
            'routineMaintenanceId.extId',
            'routineMaintenanceId.startTime',
            'routineMaintenanceId.plannedFinishTime',
            'routineMaintenanceId.lastUpdateTime',
            'routineMaintenanceId.factFinishTime',
            'routineMaintenanceId.userId.fio',
            'routineMaintenanceId.description',
            'description',
            'active'
          ],
        },
      })
      .pipe(
        map((data: IAbstractServiceData) => {
          return data?.data?.items[0] || null;
        }),
      );
  }

  /**
   * Удаление объекта
   * @id - ID удаляемой записи
   */
  public delete(id: string): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: 'AtmIntegration' },
      entity: {
        name: 'MonitoringObject',
        query: {
          id,
        },
      },
    })
  }

  /** Получение списка устройств на объекте */
  public getDevices(objectId: string): Observable<IAtmDevice[]> {
    return this.rest.serviceRequest({
      action: 'getObject',
      service: { name: 'AtmIntegration' },
      data: { objectId },
    }).pipe(map((res: IAbstractServiceData) => res.data.devices));
  }

  /** Получение списка узлов по устройству на объекте */
  public getNodes(objectId: string, deviceExtId: number): Observable<IAtmNodes[]> {
    return this.rest.serviceRequest({
      action: 'getNodeList',
      service: { name: 'AtmIntegration' },
      data: { objectId, deviceExtId },
    }).pipe(map((res: IAbstractServiceData) => res.data));
  }

  /** Получение списка параметров для узла */
  public getParameters(objectId: string, deviceExtId: number, nodeExtId?: number) {
    return of({ items: [
      { id: 1, descr: '1' },
      { id: 2, descr: '2' },
      { id: 3, descr: '3' },
      { id: 4, descr: '4' },
      { id: 5, descr: '5' },
    ], totalCount: 5 });
    // return this.rest.serviceRequest({
    //   action: 'getValueList',
    //   service: { name: 'AtmIntegration' },
    //   data: { objectId, deviceExtId, nodeExtId },
    // });
  }

  /** Сохранение Объекта мониторинга ЖКХ */
  public save(model: IMonitoringObjectHcsDto): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'upsert',
      service: { name: 'AtmIntegration' },
      entity: { name: 'MonitoringObject', query: { id: model.id || Uuid.newUuid() } },
      data: model,
    });
  }

  /**
   * Сохранение связанного адреса
   * @param ownedAddress - информация об адресе
   */
  public saveOwnedAddress(ownedAddress: AddressOwnershipObject): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'upsert',
      service: { name: 'Admin' },
      entity: {
        name: 'MonitoringObjectAddressOwnership',
        query: { id: ownedAddress.id ? ownedAddress.id : Uuid.newUuid() },
      },
      data: {
        ...ownedAddress,
        fullAddress: ownedAddress.address.fullText
      },
    });
  }

  /**
   * Удаление связанных адресов
   * @param ids - массив ID для удаления
   */
  public deleteOwnedAddresses(ids: string[]): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: 'Admin' },
      entity: {
        name: 'MonitoringObjectAddressOwnership',
        query: {
          id: { $in: ids },
        },
      },
    })
  }

  /**
   * Получение связанных адресов с указанным объектом мониторинга ЖКХ
   * @param monitoringObjectId - ID объекта мониторинга
   */
  public getOwnedAddresses(monitoringObjectId: string): Observable<GarFindFlatResponseElement[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'MonitoringObjectAddressOwnership',
        query: { monitoringObjectId },
        attributes: ['address'],
      }
    }).pipe(map((result: IAbstractServiceData) => result.data?.items?.map((ownedAddress: AddressOwnershipObject) =>
      ownedAddress.address
    )));
  }

  /**
   * Получение связанных адресов с указанным объектом мониторинга ЖКХ
   * @param query - запрос
   */
  public getOwnedAddressesByQuery(query: IAnyObject): Observable<GarFindFlatResponseElement[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'MonitoringObjectAddressOwnership',
        query,
        attributes: ['address'],
      }
    }).pipe(map((result: IAbstractServiceData) => result.data?.items?.map((ownedAddress: AddressOwnershipObject) =>
      ownedAddress.address
    )));
  }
}
