import { Component, HostBinding, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { IScInputOptions, IScSelectLoadParams, IScSelectOptions } from '@smart-city/core/common';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IAbstractServiceData,
  IForecastingDetailDto,
  IForecastingTechnologicalFireParamsDto,
  IReasonDto,
} from 'smart-city-types';
import { IForecastingTechnologicalFireAreaDto } from '../../models/interfaces';
import { BaseComponent } from '@bg-front/core/components';
import { ForecastingTechnologicalFireAreaIncidentFormComponent } from '../forecasting-technological-fire-area-incident-form/forecasting-technological-fire-area-incident-form.component';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { WeatherDataService } from '@bg-front/weather-data/services';

@Component({
  selector: 'bg-forecasting-technological-fire-incident-form',
  templateUrl: './forecasting-technological-fire-incident-form.component.html',
  styleUrls: ['./forecasting-technological-fire-incident-form.component.scss'],
})
export class ForecastingTechnologicalFireIncidentFormComponent extends BaseComponent implements OnInit {
  /** Счётчик для генерации id компоненты */
  public static nextId = 0;

  /** Установка id */
  @HostBinding()
  id = `forecasting-technological-fire-incident-form-${ForecastingTechnologicalFireIncidentFormComponent.nextId}`;
  /** Реактивная форма, для взаимодействия с внешней реактивной формой */
  public form: FormGroup;
  /** Настройка компоненты "Вид объекта пожара" */
  public fireObjectTypeOptions: IScSelectOptions = {
    title: 'Вид объекта пожара',
    clearable: true,
    data: this.settings.getDictForSelect('fireObjectType'),
  };
  /** Настройка компоненты "Объект пожара" */
  public objectFireOptions: IScSelectOptions = {
    title: 'Объект пожара',
    clearable: true,
    loadDataFn: (params: IScSelectLoadParams) => this.getFireObjects(params),
  };
  /** Настройка компоненты "Причина пожара" */
  public causeFireOptions: IScSelectOptions = {
    title: 'Причина пожара',
    data: this.getReasons(),
    clearable: true,
  };
  /** Настройка компоненты "Виновное лицо" */
  public fireCulpritOptions: IScInputOptions = {
    label: 'Виновное лицо',
    maxLength: 100,
  };
  /** Настройка компоненты "Ранг пожара" */
  public rankOptions: IScSelectOptions = {
    title: 'Ранг пожара',
    clearable: true,
    data: this.settings.getDictForSelect('fireRank'),
  };
  /** Площадь пожара */
  public fireAreaOption: IScInputOptions = {
    label: 'Площадь пожара',
    mask: '0*',
  };
  /** Настройка компоненты "Руководитель тушения пожара" */
  public leaderOptions: IScInputOptions = {
    label: 'Руководитель тушения пожара',
    maxLength: 100,
  };

  /** Ссылка на группу фильтров */
  public zonesArray: FormArray;

  public data: IForecastingDetailDto<IForecastingTechnologicalFireParamsDto> = {
    id: undefined,
    params: {},
  };

  /** @ignore */
  constructor(
    private readonly settings: Settings2Service,
    private readonly rest: RestService,
    public readonly controlContainer: ControlContainer,
    private readonly weatherService: WeatherDataService,
    private readonly fb: FormBuilder,
  ) {
    super();

    ForecastingTechnologicalFireIncidentFormComponent.nextId++;
  }

  /** @ignore */
  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
    this.zonesArray = this.form.controls.fireAreaZones as FormArray;
  }

  /**
   * Метод срабатывает при клике на селект "Причина пожара" и получает список из настроек.
   * @return список причин для селекта.
   */
  private getReasons(): IScSelectItem[] {
    const groupId = this.settings.reasonGroups.find((group: any) => group.sysName === 'fireReasons').id;
    return this.settings.reasons
      .filter((reason: IReasonDto) => reason.groupId === groupId)
      .map((reason: IReasonDto) => {
        return <IScSelectItem>{
          id: reason.id,
          text: reason.name,
        };
      });
  }

  /**
   * Метод срабатывает при клике на select "Объект пожара" и загружает справочник "Объекты пожара".
   * @param params Параметры загрузки данных.
   * @return список объектов пожара для select'а.
   * TODO: Вынести в сервис
   */
  private getFireObjects(params: IScSelectLoadParams): Observable<IScSelectItem[]> {
    if (params.limit && params.limit.paNumber > 0) return of([]);
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'FireObjects',
          sort: {
            field: 'name',
            direction: 'asc',
          },
          query: {
            $text: (params.query || { $text: undefined }).$text
              ? {
                  $search: params.query.$text.$search,
                  $fields: ['name'],
                }
              : undefined,
          },
        },
      })
      .pipe(
        map((data: IAbstractServiceData) =>
          data.data.items.map((item) => {
            return { id: item.id, text: item.name };
          }),
        ),
      );
  }

  /** Добавляем новую сущность */
  public addZone() {
    const idx = (this.form.controls.fireAreaZones as FormArray).controls.length;
    const group = this.fb.group(
      ForecastingTechnologicalFireAreaIncidentFormComponent.generateFormGroup(this.fb, {
        id: `${idx + 1}`,
      }),
    );

    this.zonesArray.setControl(idx, group);
  }

  /** Удаляем сущность */
  public removeZone(idx: number) {
    this.zonesArray.removeAt(idx);
  }

  /** Генерация FormGroup */
  public static generateFormGroup(fb: FormBuilder, params: IForecastingTechnologicalFireParamsDto) {
    const array = params?.fireAreaZones?.length
      ? params?.fireAreaZones.map((el: IForecastingTechnologicalFireAreaDto) =>
          ForecastingTechnologicalFireAreaIncidentFormComponent.generateFormGroup(fb, el),
        )
      : [ForecastingTechnologicalFireAreaIncidentFormComponent.generateFormGroup(fb, undefined)];

    return fb.group({
      fireObjectTypeId: [params?.fireObjectTypeId],
      objectFireId: [params?.objectFireId],
      causeFireId: [params?.causeFireId],
      fireCulprit: [params?.fireCulprit],
      leader: [params?.leader],
      rankId: [params?.rankId],
      relevantOnDate: [params?.relevantOnDate],
      fireArea: [params?.fireArea],
      fireAreaZones: fb.array([...array]),
    });
  }
}
