import { Pipe, PipeTransform } from '@angular/core';

import { GrammarHelper } from '../../models/helpers';

/**
 * Пайп для отображения тултипа в селекте для организаций
 */
@Pipe({
  name: 'stringEnding',
})
export class StringEndingPipe implements PipeTransform {
  /**
   * @param value - значение
   * @param forms - варианты окончаний
   * @return string
   */
  transform(value: number, forms: [string, string, string]): string {
    if (value && forms) {
      return `${value} ${GrammarHelper.endings(value, forms)}`;
    }

    return '' + value;
  }
}
