import { IScSelectItem } from '@smart-city/core/interfaces';
import { IAnyObject } from 'smart-city-types';
import { RegistryPanelFilterOperationEnum } from '../enums';

/**
 * Класс реализующий работы с операциями для реестра
 */
export class RegistryPanelFilterHelper {
  /** Возвращает список операций для select */
  public static operationsToArray(): IScSelectItem[] {
    return [
      {
        id: RegistryPanelFilterOperationEnum.equal,
        text: 'Равно',
      },
      {
        id: RegistryPanelFilterOperationEnum.notEqual,
        text: 'Не равно',
      },
      {
        id: RegistryPanelFilterOperationEnum.greatThen,
        text: 'Больше',
      },
      {
        id: RegistryPanelFilterOperationEnum.greatThenOrEqual,
        text: 'Больше или равно',
      },
      {
        id: RegistryPanelFilterOperationEnum.lessThen,
        text: 'Меньше',
      },
      {
        id: RegistryPanelFilterOperationEnum.lessThenOrEqual,
        text: 'Меньше или равно',
      },
      {
        id: RegistryPanelFilterOperationEnum.or,
        text: 'Или',
      },
    ];
  }

  /** Преобразование типа операции и значение в объект для запроса */
  public static createValueByOperation(
    property: string,
    value: string,
    operation: RegistryPanelFilterOperationEnum = RegistryPanelFilterOperationEnum.equal,
  ): IAnyObject {
    const filter = <IAnyObject>{};
    if (property && value) {
      switch (operation) {
        case RegistryPanelFilterOperationEnum.equal:
          filter[property] = value === 'null' ? null : value;
          break;
        case RegistryPanelFilterOperationEnum.notEqual:
          filter[property] = { $ne: value === 'null' ? null : value };
          break;
        case RegistryPanelFilterOperationEnum.lessThen:
          filter[property] = { $lt: value === 'null' ? null : value };
          break;
        case RegistryPanelFilterOperationEnum.lessThenOrEqual:
          filter[property] = { $lte: value === 'null' ? null : value };
          break;
        case RegistryPanelFilterOperationEnum.greatThen:
          filter[property] = { $gt: value === 'null' ? null : value };
          break;
        case RegistryPanelFilterOperationEnum.greatThenOrEqual:
          filter[property] = { $gte: value === 'null' ? null : value };
          break;
        case RegistryPanelFilterOperationEnum.or:
          filter['$or'] = (value || '').split(',').map((val: string) => {
            const lValue = val.trimLeft().trimRight();
            return this.createValueByOperation(property, lValue === 'null' ? null : lValue);
          });
      }
    }
    return filter;
  }
}
