import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';

import { VideoDevicesListsState } from '../../../models/states';
import { VideoDevicesListsStore } from '../store/video-devices-lists-store.service';

@QueryConfig({
  sortBy: 'updateTime',
  sortByOrder: Order.DESC,
})
/** Сервис для работы с хранилищем Типов КСиП */
@Injectable({
  providedIn: 'root',
})
export class VideoDevicesListsQuery extends QueryEntity<VideoDevicesListsState> {
  constructor(protected store: VideoDevicesListsStore) {
    super(store);
  }

  /** Устанавливаем активное значение */
  public setActive(id: string): void {
    this.store.setActive(id);
  }
}
