import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Settings2Service } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import { MapBaseModel, MapBaseService } from '@smart-city/maps/sc';
import {
  IMapBaseEvent,
  IMapBaseInitOptions,
  IMapBasePolygonOptions,
  IMapBasePopup,
  IMapBaseTooltip,
  IMapObjectIconOptions,
} from '@smart-city/maps/sc';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import { debounceTime, map, skipWhile, takeUntil } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';
import { Coordinates } from '@bg-front/core/models/classes';
import { CLICK_MAKER_SHAPE_SVG, ICON_SVG } from '@bg-front/core/models/constants';
import {
  IMiniMapMarkerPositionEvent,
  IMiniMapOptions,
  INewMiniMapMarker,
  IPolygonMiniMapOptions,
} from '../../models/interfaces';
import { MiniMapService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';
import { BgMapService, SubstratesQuery } from '@bg-front/core/services';
import { IPolygonDto, ISubstrateDto } from '@bg-front/core/models/interfaces';
import { IMapBaseBaseUrlsOptions } from '@bg-front/map/models/interfaces';

/**
 * Компонент отвечает за миникарту в форме редактирования происшествия
 */
@Component({
  selector: 'bg-mini-map',
  templateUrl: './mini-map.component.html',
  styleUrls: ['./mini-map.component.scss'],
})
export class MiniMapComponent extends BaseComponent implements OnInit, OnDestroy {
  /**
   * Центр карты
   */
  @Input()
  center: MapBaseCoordinatesType = undefined;

  /**
   * Зум карты
   */
  @Input()
  zoom: number = undefined;

  /**
   * Запрещаем изменение позиции маркера
   */
  @Input()
  disableChangePosition = false;
  /**
   *  Координаты по умолчанию для карты
   */
  @Input()
  defaultMarkerCoordinate: [number, number];

  /**
   * Извещаем о изменении позиции маркера
   */
  @Output()
  changeMarkerPosition: EventEmitter<IMiniMapMarkerPositionEvent> = new EventEmitter<IMiniMapMarkerPositionEvent>(true);
  /**
   * Настройка карты
   */
  public mapOptions = <IMapBaseInitOptions>{};
  /**
   * Модель карты
   */
  private mapModel: MapBaseModel;
  /**
   * Маркеры
   */
  private markers = new Map<string, INewMiniMapMarker>();
  /** Иконка для маркера */
  private imageClickMarkerIcon: HTMLImageElement;
  private imageSignificiantObjectIcon: HTMLImageElement;

  /** Эмиитер события готовности карты для родительских компонент */
  @Output()
  private mapReady = new EventEmitter();
  /** Флаг готовности карты */
  private isReady = false;

  /**
   * @ignore
   * @param mapService
   * @param miniMapService
   * @param substratesQuery
   * @param gisService
   */
  constructor(
    private readonly mapService: MapBaseService,
    private readonly miniMapService: MiniMapService,
    private readonly substratesQuery: SubstratesQuery,
    private readonly gisService: BgMapService,
  ) {
    super();
  }

  /**
   * @ignore
   */
  public ngOnInit(): void {
    const transformSubstratesUrl: (url: string) => string = (url: string): string => {
      return url.startsWith('/', 0) ? `//${window.location.host}${url}` : `//${url}`;
    };

    const substrates = this.substratesQuery.getAll();
    const activeSubstrate = (this.substratesQuery.getActive() as ISubstrateDto) || substrates?.[0];

    this.imageClickMarkerIcon = this.gisService.createClickMarkerIcon();
    this.createSignificiantObjects();
    const mapId = Uuid.newUuid() as any;
    this.mapModel = new MapBaseModel(mapId, this.mapService);
    this.mapOptions.mapId = mapId;
    (this.mapOptions.url = substrates?.length > 1 ? undefined : transformSubstratesUrl(activeSubstrate.link)),
      (this.mapOptions.urls =
        substrates?.length > 1
          ? substrates.map((el: ISubstrateDto): IMapBaseBaseUrlsOptions => {
              return <IMapBaseBaseUrlsOptions>{
                url: transformSubstratesUrl(el.link),
                name: el.name,
                attribution: el.attribution,
                selected: activeSubstrate ? el.id === activeSubstrate.id : el.default,
              };
            })
          : undefined);
    this.mapOptions.zoom = this.zoom || 16;
    this.mapOptions.center = this.center;
    this.mapOptions.maxZoom = 18;
    this.mapOptions.mapStyle = { width: '100%', height: '100%' };

    this.mapModel
      .getObservableMapEvents()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event: IMapBaseEvent) => {
        switch (event.typeEvent) {
          case 'mapClick': {
            if (!this.disableChangePosition) {
              this.changeMarkerPosition.emit({
                coordinates: event.coordinates,
              });
            }
            break;
          }
          case 'mapReady':
            this.isReady = true;
            this.miniMapService.significantObjects$
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((data: IAnyObject) => {
                if (this.defaultMarkerCoordinate) {
                  this.markers.set('addCirclesMarker', {
                    objectId: 'addCirclesMarkerByClick',
                    coordinates: this.defaultMarkerCoordinate,
                  });
                  this.refresh();
                }
                if (data) {
                  this.mapModel.removeLayer('miniMapVewObjects');
                  this.addSignificantObjectLayerToMap(data);
                  this.mapModel.viewLayer('Важные объекты', true);
                }
              });
            this.mapReady.emit();
            break;
          default:
            break;
        }
      });

    this.miniMapService.setMarkerSource$
      .pipe(
        map((data: INewMiniMapMarker) => {
          this.markers.set(data.objectId ? data.objectId : 'newIncident', data);
          return data;
        }),
        debounceTime(1000),
        skipWhile(() => !this.isReady),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.refresh();
      });

    // подписка на добавление одного полигона
    this.miniMapService.polygonCoordinates$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: IPolygonMiniMapOptions) => {
        this.addPolygon(data);
      });

    // подписка на добавление нескольких полигонов
    this.miniMapService.polygonsCoordinates$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IPolygonDto[]) => {
      this.addPolygons(data);
    });

    // подписка на очистку слоя полигонов
    this.miniMapService.clearPolygonsEvent$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: boolean) => {
      this.clearPolygons();
    });

    // подписка на очистку слоя объектов
    this.miniMapService.clearObjectsEvent$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: boolean) => {
      this.clearObjects();
    });

    // подписка на установку масштаба и центра карты
    this.miniMapService.mapOptions$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: IMiniMapOptions) => {
      this.setMapOptions(data);
    });
  }

  /**
   * Установка маркера
   */
  private refresh() {
    this.mapModel.removeLayer('miniMapVewObjects');
    /** Формирую маркер как для нового инцидента по внешнему виду и добавляю в слой на мини карту */
    const iconConfForMarker: IMapObjectIconOptions = {
      iconSVG: ICON_SVG,
      iconSize: 26,
      iconColor: '#FB4B53',
      shapeColor: '#ffffff',
      shapeAnchor: [26, 76],
      shapeSize: 30,
      shapeType: 'Круг',
    };
    const optIcon: IMapObjectIconOptions = {
      shapeImgSize: [52, 76],
      iconColor: '#F3F3F3',
      shapeSVG: CLICK_MAKER_SHAPE_SVG,
      shapeAnchor: [26, 66],
      shapeImg: this.imageClickMarkerIcon,
    };

    this.markers.forEach((marker: INewMiniMapMarker) => {
      this.mapModel.setView(marker.coordinates, 17);
      this.mapModel.removeLayer('miniMapVewObjects');
      this.mapModel.viewLayer('miniMapVewObjects', false);
      this.mapModel.setCenter(marker.coordinates);
      this.mapModel.addMarkerToLayer(
        'miniMapVewObjects',
        marker.objectId ? marker.objectId : 'newIncident',
        marker.coordinates,
        this.mapService.makeClickMarker(optIcon, iconConfForMarker, false),
        marker.popupText ? <IMapBasePopup>{ text: marker.popupText } : null,
        marker.tooltip ? <IMapBaseTooltip>{ text: marker.tooltip } : null,
      );
      this.mapModel.addCircleToLayer('miniMapVewObjects', 'circleBig', marker.coordinates, 500, {
        stroke: false,
        fillColor: '#FDD13A',
        fillOpacity: 0.3,
      });
      this.mapModel.addCircleToLayer('miniMapVewObjects', 'circleMin', marker.coordinates, 50, {
        stroke: false,
        fillColor: '#DA1E28',
        fillOpacity: 0.4,
      });
    });
    this.mapModel.viewLayer('miniMapVewObjects', false);
  }

  /**
   * Генерирует картинку для отображения маркера важных объектов
   */
  private createSignificiantObjects(): void {
    const src =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAABF0lEQVRIS2NkgIL/i2VUGP4zdDD8ZwhmYGD4wMDAwAHFMCXY6B8MDAwgLMDAyLCWgZGhgjH2yR2QQkYQ8X+BtDcDI+M6BgYGNnymECH3i+H//yDGhKdbGcEu/cdwlQqGwuz9xcDEoM34f5HMGqj3iXAQkUoYGdYy/l8o859I5SQpAxn8Hhz46MBtFXaD3lxgYDjXRsiSDyCDv2ON/bjH2DU/2s7AcGM+qtyL4+hqf+AOClwGoxsBMnRXGIYjRg2GB8kABcXFPsyUoV+EmSJIjjyQwRf7EQbJ+zAwqMdhWkaxwYSyBZI8/gyC7mLiDQZnEOxZGpRByDcYnKWxF0ISlhD3YWZXotxNw2KTVgU9zaomWIBRuzIFAPI8mrdcJmK2AAAAAElFTkSuQmCC';
    const img = new Image();
    img.src = src;
    this.imageSignificiantObjectIcon = img;
  }

  /**
   * Добавляет важные объекты
   * @param objects - список объектов для важных объектов
   */
  // TODO добавить тип
  private addSignificantObjectLayerToMap(objects: IAnyObject): void {
    const layer = 'Важные объекты';
    const significantObjectIcon =
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="%23ffa11c"><path d="M9.60016 3.99984L9.3335 2.6665H3.3335V13.9998H4.66683V9.33317H8.40016L8.66683 10.6665H13.3335V3.99984H9.60016Z"/></svg>';
    const iconConfForMarker: IMapObjectIconOptions = {
      iconSVG: significantObjectIcon,
      iconSize: 26,
      iconColor: '#ffa11c',
      shapeColor: '#ffffff',
      shapeAnchor: [26, 76],
      shapeSize: 30,
      shapeType: 'Прямоугольник',
    };
    const optIcon: IMapObjectIconOptions = {
      shapeImgSize: [22, 22],
      iconColor: '#F3F3F3',
      shapeSVG: ICON_SVG,
      shapeAnchor: [11, 11],
      shapeImg: this.imageSignificiantObjectIcon,
    };

    for (const prop in (objects || {})[layer]?.objects) {
      this.mapModel.addMarkerToLayer(
        layer,
        prop,
        objects[layer].objects[prop].coordinates,
        this.mapService.makeClickMarker(optIcon, iconConfForMarker, false),
      );
    }
  }

  /**
   * Добавляет полигон
   * @param options id полигона и массив координат
   *  */
  private addPolygon(options: IPolygonMiniMapOptions) {
    const layer = 'polygons';
    this.mapModel.addPolygonToLayer(
      layer,
      options.id,
      // переводим координаты в number[][]
      options.coordinates.map((el: Coordinates) => el.toArray()),
      <IMapBasePolygonOptions>{
        weight: 3,
        color: '#6F6F6F',
        fillColor: 'rgba(101,101,101,.2)',
      },
    );
    this.mapModel.viewLayer(layer, true);
    this.mapModel.removeLayer('miniMapVewObjects');
    this.mapModel.setZoom(this.mapOptions.zoom);
  }

  /**
   * Добавляет массив полигонов
   * @param options id полигона и массив координат
   */
  private addPolygons(options: IPolygonDto[]): void {
    const layer = 'polygons';
    this.mapModel.removeLayer(layer);
    options.forEach((option: IPolygonDto) => {
      this.mapModel.addPolygonToLayer(
        layer,
        option.id,
        // переводим координаты в number[][]
        (option.coordinates as Coordinates[]).map((el: Coordinates) => el.toArray()),
        <IMapBasePolygonOptions>{
          weight: 3,
          color: '#6F6F6F',
          fillColor: 'rgba(101,101,101,.2)',
        },
      );
    });
    this.mapModel.viewLayer(layer, false);
    this.mapModel.removeLayer('miniMapVewObjects');
    this.mapModel.setZoom(this.mapOptions.zoom);
  }

  /**
   * Очистка слоя полигонов
   */
  private clearPolygons(): void {
    this.mapModel.removeLayer('polygons');
  }

  /**
   * Очистка слоя объектов
   */
  private clearObjects(): void {
    this.mapModel.removeLayer('miniMapVewObjects');
  }

  /**
   * Устанавливает масштаб и центр карты
   * @param options масштаб карты и позиционирование на карте
   * */
  private setMapOptions(options: IMiniMapOptions) {
    this.mapModel.setZoom(options.zoom);
    this.mapModel.setCenter(options.center);
  }
}
