<h3 class="main-header">
  <mat-icon class="header-icon">track_changes</mat-icon>
  Результаты прогноза
</h3>
<hr/>
<div class="bg-col">
  <h4 class="section-header">Облучение населения на заданном маршруте при преодолении следа облака</h4>
  <ul class="results-list">
    <li class="results-list-item">
      <label>Доза внешнего облучения населения ({{ unitName }}):</label>
      <br>
      <label class="results-list-item--var">
        {{result?.outerDose | commaToDot | number: '0.2-2'}}
      </label>
    </li>
  </ul>
</div>
