import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IElementButton,
  INwHeaderBarOptions,
  IScInputOptions,
  IScSelectOptions,
  IScTextareaOptions,
} from '@smart-city/core/common';
import * as moment from 'moment';
import { IAnyObject, IInformationStatementDto } from 'smart-city-types';
import { ILifeCycleStepDto } from 'smart-city-types/interfaces/life-cycle-step-dto.interface';
import { IUserInfoDto } from 'smart-city-types/interfaces/user-info-dto.interface';
import { InformationStatementService } from '../../../bg/modules/consolidated-registries/modules/information-statements/services';
import { BUTTON_ICONS } from '@bg-front/core/models/constants';
import { IDateTimeOptions } from '@bg-front/core/models/interfaces';
import { BgDatetimeValidator } from '@bg-front/core/validators';
import { Settings2Service } from '@smart-city/core/services';
import { BaseComponent } from '@bg-front/core/components';

@Component({
  selector: 'bg-information-statement-view-form',
  templateUrl: './information-statement-view-form.component.html',
  styleUrls: ['./information-statement-view-form.component.scss'],
})

/**
 * Компонента просмотра донесения
 */
export class InformationStatementViewFormComponent extends BaseComponent implements OnInit {
  public informationStatement: IInformationStatementDto;

  /** Событие закрытия формы */
  @Output()
  public closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** Форма */
  public form: FormGroup;

  /** Настройки заголовка формы создания/редактирования донесения */
  public headerActionsOptions: INwHeaderBarOptions;

  /** Настройка компоненты Тип донесения */
  public typeIdOptions: IScSelectOptions = {
    title: 'Тип донесения',
    data: this.settings.getDictForSelect('informationStatementTypes'),
    clearable: true,
    required: true,
  };

  /** Настройка компоненты Тема донесения */
  public themeIdOptions: IScSelectOptions = {
    title: 'Тема донесения',
    service: 'Admin',
    entity: 'InformationStatementThemes',
    modern: true,
    fieldName: 'name',
    query: { active: true },
    clearable: true,
    required: true,
  };

  /** Настройка компоненты Периодичность донесения */
  public periodicityIdOptions: IScSelectOptions = {
    title: 'Периодичность донесения',
    data: this.settings.getDictForSelect('periodicity'),
    clearable: true,
    disabled: true,
  };

  /** Настройка компоненты Описание */
  public optionsDescription: IScTextareaOptions = {
    label: 'Описание *',
    maxLength: 1000,
    rows: 6,
  };

  /** Настройка компоненты Предоставить до */
  public deadlineOptions: IDateTimeOptions = {
    label: 'Предоставить до',
    format: 'DD.MM.YYYY HH:mm',
    dataType: 'unix',
  };

  /** Ошибки компоненты  Предоставить до */
  public deadlineErrors = {
    minDate: 'Не ранее настоящего времени',
  };

  /** Настройка компоненты Комментарий исполнителя */
  public optionsExecutorComment: IScTextareaOptions = {
    label: 'Комментарий исполнителя',
    maxLength: 1000,
    rows: 6,
  };

  /** Настройка компоненты Комментарий получателя */
  public optionsRecipientComment: IScTextareaOptions = {
    label: 'Комментарий получателя',
    maxLength: 1000,
    rows: 6,
  };

  /** Настройка компоненты Организация в блоке Автор */
  public authorOrganizationOptions: IScInputOptions = {
    label: 'Организация',
    placeholder: 'Организация',
  };

  /** Настройка компоненты ФИО в блоке Автор */
  public authorFioOptions: IScInputOptions = {
    label: 'ФИО',
    placeholder: 'ФИО',
  };

  /** Настройка компоненты Дата и время создания */
  public creationTimeOptions: IScInputOptions = {
    label: 'Дата и время создания',
    placeholder: 'Дата и время создания',
  };

  /** Настройка компоненты Организация в блоке Исполнитель */
  public executorOrganizationIdOptions: IScSelectOptions = {
    title: 'Организация',
    clearable: true,
    service: 'Admin',
    entity: 'Organizations',
    modern: true,
    fieldName: 'name',
    query: {
      'orgTypeParam.organizationTypeId.sysname': 'edds',
      active: true,
    },
  };

  /** Настройка компоненты ФИО в блоке Исполнитель */
  public executorIdOptions: IScSelectOptions = {
    title: 'ФИО',
    clearable: true,
    service: 'Admin',
    entity: 'Users',
    modern: true,
    fieldName: 'fio',
  };

  /** Настройка компоненты Дата и время исполнения */
  public executionTimeOptions: IScInputOptions = {
    label: 'Дата и время исполнения',
    placeholder: 'Дата и время исполнения',
  };

  /** Настройка компоненты Организация в блоке Получатель */
  public recipientOrganizationIdOptions: IScSelectOptions = {
    title: 'Организация',
    clearable: true,
    service: 'Admin',
    entity: 'Organizations',
    modern: true,
    fieldName: 'name',
    query: {
      'orgTypeParam.isInformer': true,
      active: true,
    },
  };

  /** Настройка компоненты ФИО в блоке Получатель */
  public recipientIdOptions: IScSelectOptions = {
    title: 'ФИО',
    clearable: true,
    service: 'Admin',
    entity: 'Users',
    modern: true,
    fieldName: 'fio',
  };

  /** Настройка компоненты Дата и время закрытия */
  public closeTimeOptions: IScInputOptions = {
    label: 'Дата и время закрытия',
    placeholder: 'Дата и время закрытия',
  };

  /**
   * @ignore
   */
  constructor(
    private settings: Settings2Service,
    private route: ActivatedRoute,
    private informationStatementService: InformationStatementService,
    private router: Router,
  ) {
    super();
  }

  /**
   * @ignore
   */
  ngOnInit() {
    this.informationStatement = this.route.snapshot.data['model'];
    this.informationStatement.documents = this.informationStatement.documents || [];

    // Настройки заголовка формы создания/редактирования донесения
    this.headerActionsOptions = {
      title: this.informationStatement.number || 'Новое донесение',
      subTitle: this.informationStatement.id ? (<ILifeCycleStepDto>this.informationStatement.lifeCycleStepId).name : '',
      name: 'header',
      bgColor: 'white',
      buttons: this.generateButtonsList(),
    };

    this.form = new FormGroup({
      typeId: new FormControl(this.informationStatement.typeId, [Validators.required]),
      themeId: new FormControl(this.informationStatement.themeId, [Validators.required]),
      periodicityId: new FormControl({ value: this.informationStatement.periodicityId, disabled: true }),
      description: new FormControl(this.informationStatement.description, [Validators.required]),
      deadline: new FormControl(this.informationStatement.deadline, BgDatetimeValidator.minDate(Date.now())),
      executorComment: new FormControl(this.informationStatement.executorComment),
      documents: new FormControl(undefined),
      recipientComment: new FormControl(this.informationStatement.recipientComment),
      authorOrganization: new FormControl(
        (<IUserInfoDto>this.informationStatement.creationAuthor)?.id
          ? (<IUserInfoDto>this.informationStatement.creationAuthor).organizationId
          : this.settings.currentUser.organizationId,
      ),
      authorFio: new FormControl(
        (<IUserInfoDto>this.informationStatement.creationAuthor)?.id
          ? (<IUserInfoDto>this.informationStatement.creationAuthor).fio
          : this.settings.currentUser.id,
      ),
      creationTime: new FormControl(
        this.informationStatement.creationTime
          ? moment(this.informationStatement.creationTime).format('DD.MM.YYYY HH:mm')
          : '',
      ),
      executorOrganizationId: new FormControl(this.informationStatement.executorOrganizationId),
      executorId: new FormControl(this.informationStatement.executorId),
      executionTime: new FormControl(
        this.informationStatement.executionTime
          ? moment(this.informationStatement.executionTime).format('DD.MM.YYYY HH:mm')
          : '',
      ),
      recipientOrganizationId: new FormControl(this.informationStatement.recipientOrganizationId),
      recipientId: new FormControl(this.informationStatement.recipientId),
      closeTime: new FormControl(
        this.informationStatement.closeTime
          ? moment(this.informationStatement.closeTime).format('DD.MM.YYYY HH:mm')
          : '',
      ),
    });

    this.setPeriodicityState(this.informationStatement.typeId);

    if (this.informationStatement.executorOrganizationId) {
      this.executorIdOptions.query = { organizationId: this.informationStatement.executorOrganizationId };
    } else {
      this.form.controls.executorId.disable();
      this.executorIdOptions.disabled = true;
    }
    if (this.informationStatement.recipientOrganizationId) {
      this.recipientIdOptions.query = { organizationId: this.informationStatement.recipientOrganizationId };
    } else {
      this.form.controls.recipientId.disable();
      this.recipientIdOptions.disabled = true;
    }

    this.form.disable();

    // Если тип организации текущего пользователя 'edds', то он и является исполнителем
    this.informationStatementService.getCurrentUserOrgType().subscribe((type: string) => {
      if (type === 'edds' && !this.informationStatement.executorId) {
        this.form.patchValue(
          {
            executorOrganizationId: this.settings.currentUser.organizationId.id,
            executorId: this.settings.currentUser.id,
          },
          { onlySelf: true, emitEvent: false },
        );
        this.executorOrganizationIdOptions.disabled = true;
        this.executorIdOptions.disabled = true;
      }
    });
  }

  /**
   * Генерация списка кнопок
   */
  protected generateButtonsList(): IElementButton[] {
    const btnArray = [];
    btnArray.unshift(<IElementButton>{
      type: 'button',
      options: {
        name: 'cancel',
        icon: BUTTON_ICONS.get('cancel'),
      },
    });
    return btnArray;
  }

  /**
   * Получение иконки для кнопки в зависимости от действия
   * @param buttonName - наименование кнопки
   */
  public getIcon(buttonName: string): string {
    switch (buttonName) {
      case 'save':
        return 'done';
      case 'delete':
        return 'delete_outline';
      default:
        return 'assignment';
    }
  }

  /**
   * Обработка выбора файла на диске
   * @param files - информация о файле
   */
  public onFilesChange(files: IAnyObject[]) {
    this.informationStatement.documents = files;
  }

  /**
   * Активация селекта периодичность в зависимости от типа донесения
   * @param value значение типа донесения
   */
  private setPeriodicityState(value: string) {
    const reportTypeId = this.settings
      .getDictionaryByTypeSysName('informationStatementTypes')
      .find((item) => item.sysname === 'report')?.id;
    if (value === reportTypeId) {
      this.form.controls.periodicityId.enable({ emitEvent: false });
      this.form.controls.periodicityId.markAsUntouched();
      this.periodicityIdOptions.disabled = false;
      this.periodicityIdOptions.title = 'Периодичность донесения *';
      this.form.controls.periodicityId.setValidators([Validators.required]);
      this.form.controls.periodicityId.updateValueAndValidity({ emitEvent: false });
    } else {
      this.form.controls.periodicityId.disable();
      this.periodicityIdOptions.disabled = true;
      this.periodicityIdOptions.title = 'Периодичность донесения';
      this.form.controls.periodicityId.setValue(null, { emitEvent: false });
      this.form.controls.periodicityId.setValidators(null);
    }
  }

  /**
   * Обрабатываем нажатие кнопок в заголовке
   * @param $event
   */
  public onClickActionsButton($event: IElementButton) {
    if ($event.options.name === 'cancel') {
      this.router.navigate([{ outlets: { viewForm: null } }], {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge',
      });
      return;
    }
  }
}
