import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { IMapViewObjectsRequest } from '@smart-city/maps/sc';

import { IMapBaseIcon } from '@bg-front/map/models/interfaces';
import { MapBaseService } from '@bg-front/map/services';
import { GarFindFlatResponseElement } from '../../models/types';
import { GarService } from '../../services';
import { Coordinates } from '@bg-front/core/models/classes';

@UntilDestroy()
@Component({
  selector: 'search-address',
  templateUrl: './search-address.component.html',
  styleUrls: ['./search-address.component.scss'],
})
export class SearchAddressComponent {
  /** Элемент ввода строки поиска */
  @ViewChild('searchInput') private searchInput!: ElementRef;

  /** Идентификтор карты */
  @Input() public mapId!: string;

  /** Вид взаимодействия с компонентом */
  @Input() public use: 'map' | 'events' = 'map';

  /** Событие поиска */
  @Output() public searchEvent = new EventEmitter<IMapViewObjectsRequest>()

  /** Класс активного поиска */
  @HostBinding('class.active') private active = false;

  /** Список адресов для выбора */
  public autocomlete: GarFindFlatResponseElement[] = [];

  /** Выбранный адрес */
  public current: GarFindFlatResponseElement | undefined;

  /** Форма ввода строки поиска */
  public form!: FormGroup;

  /** Выводимая иконка для выбранного адреса */
  public icon: IMapBaseIcon | undefined;

  /** @ignore */
  constructor(
    private readonly garService: GarService,
    private readonly mapService: MapBaseService,
  ) {
    this.initForm();
    this.icon = {
      iconSize: [52, 78],
      iconAnchor: [26, 78],
      iconUrl: '/assets/icons/search-icon.svg',
    };
  }

  /** Инициализация формы */
  private initForm(): void {
    this.form = new FormGroup({
      search: new FormControl(),
    });

    this.form.get('search')?.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe((value: string | GarFindFlatResponseElement) => {
        if (value && typeof value === 'string') {

          // Проверка что введенное значение - координаты
          if (value.match(/(-?\d{1,2}[.]\d+)[,][ ]*(-?\d{1,3}[.]\d+)/) !== null) {
            const coordinates = value.split(',').map((coordinate: string) => parseFloat(coordinate.trim()));
            if (!new Coordinates(coordinates[0], coordinates[1]).isValid()) return;
            this.autocomlete = [];
            this.clearMarker();
            this.showMarker({
              latitude: coordinates[0],
              longitude: coordinates[1],
            });
          } else {
            this.garService
              .find(value)
              .pipe(untilDestroyed(this))
              .subscribe((result: GarFindFlatResponseElement[]) => (this.autocomlete = result));
          }
        } else {
          this.current = value as GarFindFlatResponseElement;
          this.autocomlete = [];
          this.clearMarker();
          if (this.current.latitudeHouse && this.current.longitudeHouse) {
            this.showMarker({
              latitude: this.current.latitudeHouse,
              longitude: this.current.longitudeHouse,
            });
          } else if (this.current.houseGUID || this.current.aoguid) {
            this.garService
              .getCoordinates(this.current.houseGUID || this.current.aoguid, !this.current.houseGUID)
              .pipe(untilDestroyed(this))
              .subscribe((result) => {
                if (result?.latitude && result?.longitude) this.showMarker(result);
              });
          }
        }
      });
  }

  /** Реакция на клик по кнопке поиска */
  public clickSearchButton(): void {
    this.active = !this.active;
    if (this.active) {
      this.searchInput.nativeElement.focus();
    } else {
      this.clearSearch();
    }
  }

  /** Очистка поиска */
  public clearSearch(): void {
    this.form.setValue({ search: '' });
    this.clearMarker();
  }

  /** Отображение маркера выбранного адреса */
  private showMarker(coordinates: { latitude: number; longitude: number }): void {
    if (this.use === 'map') {
      this.clearMarker();
      this.mapService.addLayer(this.mapId, { id: 'search', cluster: false, isShow: true });
      this.mapService.addMarker(this.mapId, {
        layerId: 'search',
        objectId: 'search',
        coordinates: [coordinates.latitude, coordinates.longitude],
        objectOptions: {
          icon: this.icon,
        },
      });
      this.mapService.setZoom(this.mapId, 18);
      this.mapService.setCenter(this.mapId, [coordinates.latitude, coordinates.longitude]);
    } else {
      this.searchEvent.emit(<IMapViewObjectsRequest>{
        typeRequest: 'searchCoordinates',
        coordinates: [coordinates.latitude, coordinates.longitude],
      });
    }
  }

  /** Скрытие маркера адреса */
  private clearMarker(): void {
    if (this.use === 'map') {
      this.mapService.removeLayer(this.mapId, 'search');
    } else {
      this.searchEvent.emit(<IMapViewObjectsRequest>{
        typeRequest: 'searchCancel',
      });
    }
  }
}
