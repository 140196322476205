<div nz-form [formGroup]="filterForm" nzLayout="vertical">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzRequired>Атрибут</nz-form-label>
        <nz-form-control>
          <nz-select nzShowSearch formControlName="property" nzSize="large">
            <nz-option
              [nzValue]="attribute.id"
              [nzLabel]="attribute.text"
              *ngFor="let attribute of attributes"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzRequired>Действие</nz-form-label>
        <nz-form-control>
          <nz-select nzShowSearch formControlName="operation" nzSize="large">
            <nz-option
              [nzValue]="operation.id"
              [nzLabel]="operation.text"
              *ngFor="let operation of operationsDict"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="value">Значение</nz-form-label>
        <nz-form-control>
          <input nzSize="large" nz-input formControlName="value" />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</div>
