import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { IScButtonOptions } from '@smart-city/core/common';
import { SfsService, SubscriberService } from '@smart-city/core/services';
import * as moment from 'moment';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { catchError, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';
import { IAbstractServiceData } from 'smart-city-types/services';
import { ArchiveStatuses, Prefixes } from '../../models/enums';
import { IArchiveTask } from '../../models/interfaces/archive-task.interface';
import { IDownloadButton, IDownloadSourcesOptions } from '../../models/interfaces';
import { VideoDevicesService } from '../../services';
import { DownloadSourcesService } from '../../services/download-sources/download-sources.service';
import { BaseComponent } from '@bg-front/core/components';

/**
 * Компонент который отображает кнопки создания/скачивания архива фотографий/видео
 */
@Component({
  selector: 'bg-download-sources',
  templateUrl: './download-sources.component.html',
  styleUrls: ['./download-sources.component.scss'],
})
export class DownloadSourcesComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  /** Опции компонента */
  @Input() public options: IDownloadSourcesOptions;
  /** Данные текущего статуса для кнопки с видео */
  public currentVideoStatus$ = new BehaviorSubject<IDownloadButton>(null);
  /** Данные текущего статуса для кнопки с фото */
  public currentPhotoStatus$ = new BehaviorSubject<IDownloadButton>(null);
  /** Обработчик для кнопки скачивания видео */
  public videoHandler: () => void
  /** Обработчик для кнопки скачивания фото */
  public photoHandler: () => void
  /** Опции для кнопки "Скачать фото" */
  public downLoadPhotoOptions: IScButtonOptions = {
    color: 'primary',
    title: 'Подготовить фото',
    disabled: false,
  };
  /** Опции для кнопки "Скачать видео" */
  public downLoadVideoOptions: IScButtonOptions = {
    color: 'primary',
    title: 'Скачать видео',
    disabled: false,
  };
  /** Свойство для расположение кнопок поумолчанию */
  public defaultDirection = 'row';
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input()
  public disabled = false;
  /**
   * Ссылка на икону с сообщением для видео
   */
  @ViewChild('tooltipVideo', { read: ViewContainerRef, static: false })
  public readonly tooltipVideo: ViewContainerRef;
  /**
   * Ссылка на икону с сообщением для фото
   */
  @ViewChild('tooltipPhoto', { read: ViewContainerRef, static: false })
  public readonly tooltipPhoto: ViewContainerRef;
  /** Видимость кнопки для работы с видео */
  public isShowVideoButton: boolean = true;
  /** Видимость кнопки для работы с фото */
  public isShowPhotoButton: boolean = true;
  /** id архива в sfs для видео */
  private archiveVideoSfsId: string;
  /** id архива в sfs для фото */
  private archivePhotoSfsId: string;
  /** Поток для отслеживания изменния сущности */
  private streamSub: Subscription;
  /** id задания для фото */
  private taskPhotoId: string;
  /** id задания для видео */
  private taskVideoId: string;
  /** Свойство для хранения название сущности для видео */
  private videoEntityName = 'video';
  /** Свойство для хранения название сущности для фото */
  private photoEntityName = 'photo';
  /** Свойство для хранения статуса для видео */
  private dataVideoStatus: IDownloadButton = this.downloadService.getVideoStatus(
    this.concatStatusName('default', 'video'),
  );
  /** Свойство для хранения статуса для видео */
  private dataPhotoStatus: IDownloadButton = this.downloadService.getPhotoStatus(
    this.concatStatusName('default', 'photo'),
  );
  /** Дефолтный индекс для контрола */
  private defaultIndex: string = '1';
  /** ОБъект для хранения диапазона видео */
  private videoRange: { fromtime: string; totime: string } = {
    fromtime: '',
    totime: '',
  };

  /** Формат видео */
  private videoFormat = '.mp4';

  constructor(
    private readonly downloadService: DownloadSourcesService,
    private readonly subs: SubscriberService,
    private readonly sfs: SfsService,
    private readonly videoDevicesService: VideoDevicesService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngUnsubscribe.next();
    this.ngOnInit();
  }

  /** @ignore */
  ngOnInit(): void {
    if (this.options) {
      this.isShowVideoButton =
        typeof this.options?.isShowVideoButton === 'undefined'
          ? this.isShowVideoButton
          : this.options?.isShowVideoButton;
      this.isShowPhotoButton =
        typeof this.options?.photoList === 'undefined' ? this.isShowPhotoButton : !!this.options?.photoList?.length;
      this.videoRange.fromtime = moment(this.options.fromTime).utcOffset(0, false).format('DD.MM.YYYY HH:mm:ss');
      this.videoRange.totime = moment(this.options.toTime).utcOffset(0, false).format('DD.MM.YYYY HH:mm:ss');

      /**
       * Если изменяется статус изменяю опции кнопки с видео
       */
      this.currentVideoStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: IDownloadButton) => {
        if (result) {
          this.downLoadVideoOptions = {
            ...this.downLoadVideoOptions,
            disabled: result.disabled,
            title: result.text,
          };
        }
      });

      this.currentPhotoStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: IDownloadButton) => {
        if (result) {
          this.downLoadPhotoOptions = {
            ...this.downLoadPhotoOptions,
            disabled: result.disabled,
            title: result.text,
          };
        }
      });

      /** Получаю данные о задание по entityId для отслеживания статуса архивирования видео */
      const query = {
        entityId: this.options.entityId,
      };

      this.downloadService
        .getArchiveTask(query)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((tasks: IArchiveTask[]) => {
          /** Устанавливаю обработчик для кнопкок */
          this.videoHandler = this.videoPrepareHandler;
          this.photoHandler = this.photoPrepareHandler;
          if (tasks) {
            /** Сортирую по убыванию от созданной даты */
            const sortedItems = this.sortDesc(tasks);
            /** Генерирую уникальный тег для тасок в зависимости от сорсов */
            const videoTag = this.createTagForEntity('video');
            const photoTag = this.createTagForEntity('photo');
            /** Фильтрую по видео/фото для того чтобы взять последнее задание на случай если их несколько */
            const videoItems = sortedItems.filter((item: IAnyObject) => item.tag === videoTag);
            const photoItems = sortedItems.filter((item: IAnyObject) => item.tag === photoTag);

            this.setButtonOptions(videoItems, this.videoEntityName);
            this.setButtonOptions(photoItems, this.photoEntityName);
            this.downloadService.setTaskList(tasks.map((item: IArchiveTask) => item.id));

            /** TODO Временно для обработки ошибки пока не разделили логику */
            if (videoItems[0] && videoItems[0].errors) {
              this.dataVideoStatus = this.downloadService.getVideoStatus(this.concatStatusName('error', 'video'));
            }
            if (photoItems[0] && photoItems[0].errors) {
              this.dataPhotoStatus = this.downloadService.getPhotoStatus(this.concatStatusName('error', 'photo'));
            }
          }
          this.currentVideoStatus$.next(this.dataVideoStatus);
          this.currentPhotoStatus$.next(this.dataPhotoStatus);
        });

      this.subs
        .onTableChange('Archive', 'ArchiveTasks')
        .pipe(
          takeUntil(this.ngUnsubscribe),
          mergeMap((result: IAnyObject) => {
            const data = result.data;
            const status = data.status;
            /** Проверка для скачивание видео */
            if (this.taskVideoId === data.id) {
              const curVideoStatus = this.concatStatusName(status, 'video');
              this.dataVideoStatus = this.downloadService.getVideoStatus(curVideoStatus);
              this.currentVideoStatus$.next(this.dataVideoStatus);
              const videoSfsIdsList = data?.video;
              if (videoSfsIdsList && videoSfsIdsList.length) {
                this.archiveVideoSfsId = videoSfsIdsList[0];
                this.videoHandler = this.downloadVideoHandler;
              }
              if (typeof data?.errors === 'string') {
                this.noteService.pushError(data.errors);
              }
            }
            /** Проверка для скачивание фото */
            if (this.taskPhotoId === data.id) {
              const curPhotoStatus = this.concatStatusName(status, 'photo');
              this.dataPhotoStatus = this.downloadService.getPhotoStatus(curPhotoStatus);
              if (data?.archiveSfsId) {
                this.photoHandler = this.downloadPhotoHandler;
                this.archivePhotoSfsId = data.archiveSfsId;
                this.currentPhotoStatus$.next(this.dataPhotoStatus);
              }
              if (typeof data?.errors === 'string') {
                this.noteService.pushError(data.errors);
              }
            }
            if (data?.errors && this.taskPhotoId === data.id) {
              const curPhotoStatus = this.concatStatusName(status, 'photo');
              this.dataPhotoStatus = this.downloadService.getPhotoStatus(curPhotoStatus);
              this.currentPhotoStatus$.next(this.dataPhotoStatus);
            }
            if (data?.errors && this.taskVideoId === data.id) {
              const curVideoStatus = this.concatStatusName(status, 'video');
              this.dataVideoStatus = this.downloadService.getVideoStatus(curVideoStatus);
              this.currentVideoStatus$.next(this.dataVideoStatus);
            }
            return of('');
          }),
        )
        .subscribe();
    }
  }

  /** Обработчик по нажатию на кнопку скачать видео */
  public downloadVideoHandler() {
    this.sfs.directDownload(this.archiveVideoSfsId, `${this.options.videoFileName}${this.videoFormat}`);
  }

  /** Обработчик по нажатию на кнопку скачать фото */
  public downloadPhotoHandler(): void {
    this.sfs.directDownload(this.archivePhotoSfsId, this.options.photoFileName);
  }

  /**
   * Обработчик который запускает создание архива с видео
   */
  public videoPrepareHandler(): void {
    if (!this.disabled) {
      if (this.options.serverData.length) {
        const archiveTag = this.createTagForEntity('video');
        const serverDataItem = this.options.serverData[0];
        const videoData = [
          {
            ip: serverDataItem.ip,
            port: serverDataItem.port,
            login: serverDataItem.login,
            channelid: serverDataItem.extId,
            password: serverDataItem.password,
            sound: 'on',
            expectedContentType: 'application/octet-stream',
            fileName: this.options.videoFileName
              ? `${this.options.videoFileName}${this.videoFormat}`
              // Оставлено во-избежание возможных ошибок.
              // Но на момент написания комментария не найдено примеров где необходимо такое именование файла.
              : `r-${moment(this.options.timeCreate).format('YYYYMMDD-HHmmss')}.mp4`,
            ...this.videoRange,
          },
        ];
        this.downloadService
          .createVideoArchive(
            this.options.entityId,
            archiveTag,
            videoData,
            false,
            `${this.options.videoFileName}${this.videoFormat}`
          )
          .pipe(
            takeUntil(this.ngUnsubscribe),
            tap(() => this.noteService.pushInfo('Получение видео с видеосервера запущено')),
            mergeMap((result: IAbstractServiceData) => {
              if (result.data && result.data.archiveTaskId) {
                const params = { id: result.data.archiveTaskId, tag: archiveTag };
                return this.downloadService.getArchiveTask(params);
              }
              return of(null);
            }),
            catchError((err: Error) =>
              this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка при создании архива видео'),
            ),
          )
          .subscribe((result: IArchiveTask[]) => {
            let videoStatusName = 'pending';
            if (result.length) {
              this.taskVideoId = result[0].id;
              videoStatusName = result[0].status;
            }
            const videoStatus = this.downloadService.getVideoStatus(this.concatStatusName(videoStatusName, 'video'));
            this.currentVideoStatus$.next(videoStatus);
          });
      }
    }
  }

  /**
   * Обработчик создания архива и скачивания
   */
  public photoPrepareHandler(): void {
    if (!this.disabled) {
      const archiveTag = this.createTagForEntity('photo');
      const photo = this.options.photoList;
      this.downloadService
        .createArchive(
          this.options.entityId,
          archiveTag,
          { photo, video: [], archivation: true },
          `${this.options.photoFileName}.zip`,
        )
        .pipe(
          takeUntil(this.ngUnsubscribe),
          tap(() => this.noteService.pushInfo('Получение фото архива запущено')),
          mergeMap((result: IAbstractServiceData) => {
            if (result.data && result.data.archiveTaskId) {
              const params = { id: result.data.archiveTaskId, tag: archiveTag };
              return this.downloadService.getArchiveTask(params);
            }
            return of(null);
          }),
          catchError((err: Error) => this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка при создании архива фото')),
        )
        .subscribe((result: IArchiveTask[]) => {
          let photoStatusName = 'pending';
          if (result.length) {
            this.taskPhotoId = result[0].id;
            photoStatusName = result[0].status;
          }
          if (photoStatusName === 'success') {
            this.photoHandler = this.downloadPhotoHandler;
          }

          const photoStatus = this.downloadService.getPhotoStatus(this.concatStatusName(photoStatusName, 'photo'));
          this.currentPhotoStatus$.next(photoStatus);
        });
    }
  }

  /** @ignore */
  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.streamSub = null;
  }

  /** Установка опций для кнопки в зависимости от сущности video/photo */
  private setButtonOptions(items: IAnyObject, entityName: string | 'video' | 'photo') {
    if (items.length) {
      /** конкатенирую название методов и опций в зависимости от сущности */
      const firstLetterUpCaseEntityName = entityName.charAt(0).toUpperCase() + entityName.slice(1);
      const successStatusName = this.concatStatusName('success', entityName);
      const statusHandlerName = `get${firstLetterUpCaseEntityName}Status`;
      const archiveSfsIdName = `archive${firstLetterUpCaseEntityName}SfsId`;
      const handlerPropName = `${entityName}Handler`;
      const handlerName = `download${firstLetterUpCaseEntityName}Handler`;
      const dataVideoStatusName = `data${firstLetterUpCaseEntityName}Status`;

      const curItem = items[0];
      /** Текущий статус */
      const status = this.concatStatusName(curItem.status, entityName);
      this[dataVideoStatusName] = this.downloadService[statusHandlerName](status);
      if (status === successStatusName) {
        this[archiveSfsIdName] = curItem.archiveSfsId || curItem.video[0];
        this[handlerPropName] = this[handlerName];
      }
    }
  }

  /** Метод сортировки и убыванию
   * @param items - список сортируемых элементов
   * @return
   */
  private sortDesc(items: IAnyObject): IAnyObject[] {
    return items.sort((a, b) => b.createdAt - a.createdAt);
  }

  /** Собирает статус для сущности
   * @param status - string
   * @param entityName - имя сущности
   * @return
   */
  private concatStatusName(status: string, entityName: string): string {
    return `${ArchiveStatuses[status]}${Prefixes[entityName]}`;
  }

  /** Собирает уникальный тег для сузности
   * @param name - string
   * @return
   */
  private createTagForEntity(name: string): string {
    const videoEntityName = `${name}EntityName`;
    return `${this[videoEntityName]}_${this.options.entityId}_${this.options.index || this.defaultIndex}`;
  }
}
