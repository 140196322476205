<div class="bg-row">
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Наименование устройства</label>
      <p>{{ name }}</p>
    </div>
  </div>
</div>
<div class="bg-row ">
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Кодовое наименование</label>
      <p>{{ mark?.codeName }}</p>
    </div>
  </div>
</div>
<div class="bg-row">
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Организация</label>
      <p>{{ mark?.organization }}</p>
    </div>
  </div>
</div>
<div class="bg-row">
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Ответственный сотрудник</label>
      <p>{{ mark?.responsible }}</p>
    </div>
  </div>
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Контактный телефон</label>
      <p (click)="onClickPhone(mark?.contactPhone)">{{ mark?.contactPhone }}</p>
    </div>
  </div>
</div>
<div class="bg-row" *ngIf="canPhotoAndVideo?.visible">
  <div class="bg-col">
    <div class="bg-form-group">
      <div class="photos-and-videos" (click)="showPhotosAndVideos()">Фото- и видеоматериалы</div>
    </div>
  </div>
</div>
<ng-template #dialogRef>
  <mat-tab-group class="sc-tab-group" [disableRipple]="true">
    <mat-tab [label]="tabNames[idx]" *ngFor="let imageUrl of imagesUrls; let idx = index">
      <bg-match-image *ngIf="isMatchImageVisible && idx === 1" [path]="imageUrl" [frame]="frame"></bg-match-image>
      <img
        *ngIf="!isMatchImageVisible || idx !== 1"
        [src]="imageUrl | safe: 'url'"
        [alt]="'Изображение инцидента ' + (idx + 1)"
        class="image"
      />
    </mat-tab>
    <mat-tab [label]="'Видеоинтервал'">
      <span *ngIf="!sfsId; else player">{{videoStatus}}</span>
      <ng-template #player>
        <bg-hls-player
          [sourceType]="'sfs'"
          [sfsId]="sfsId"
        ></bg-hls-player>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-template>
<div class="bg-row" *ngIf="canPreparePhotoAndVideoForWantedGrz?.visible">
  <div class="bg-col">
    <bg-download-sources
      *ngIf="downloadSourcesOptions"
      [options]="downloadSourcesOptions"
      [disabled]="!canPreparePhotoAndVideoForWantedGrz?.enabled"></bg-download-sources>
  </div>
</div>
