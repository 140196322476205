import { Component, Inject, OnInit } from '@angular/core';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Settings2Service } from '@smart-city/core/services';
import { IAdminMunicipalSchemaDto, IAnyObject } from 'smart-city-types';
import { Coordinates } from '@bg-front/core/models/classes';
import { GEOLOCATION_MARKER_SVG } from '@bg-front/core/models/constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAddressDetailsDto } from '../../models/interfaces';
import { IMapBaseIcon, IMapBaseInitOptions, MapBaseModel, MapBaseService, IMapBaseEvent } from '@smart-city/maps/sc';
import { Uuid } from '@smart-city/core/utils';
import { AddressDetailsViewDialogComponent } from '../address-details-view-dialog/address-details-view-dialog.component';
import { ISubstrateDto } from '@bg-front/core/models/interfaces';
import { IMapBaseBaseUrlsOptions } from '@bg-front/map/models/interfaces';
import { SubstratesQuery } from '@bg-front/core/services';
import { GarFindFlatResponseElement } from '@bg-front/core';

@UntilDestroy()
@Component({
  selector: 'bg-address-details-map-view',
  templateUrl: './address-details-map-view.component.html',
  styleUrls: ['./address-details-map-view.component.scss'],
})
export class AddressDetailsMapViewComponent implements OnInit {
  /** Настройка карты */
  public mapOptions = <IMapBaseInitOptions>{};

  /** Модель карты */
  private mapModel: MapBaseModel;
  /** Иконка для точки поинта */
  private pointIcon: IMapBaseIcon;

  constructor(
    private readonly settings: Settings2Service,
    private readonly mapService: MapBaseService,
    private readonly substratesQuery: SubstratesQuery,
    public dialogRef: MatDialogRef<AddressDetailsViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      address: GarFindFlatResponseElement;
      addressDetails: IAddressDetailsDto[];
      visualizationMethod: string;
    },
  ) {}

  public ngOnInit(): void {
    this.pointIcon = this.mapService.makeIcon({
      iconSize: 24,
      iconColor: 'black',
      iconSVG: GEOLOCATION_MARKER_SVG,
      shapeType: 'Нет',
      shapeSize: 24,
    });

    const defaultCenterCoordinates: string =
      this.data.address.latitude && this.data.address.longitude
        ? this.data.address.latitude + ' ,' + this.data.address.longitude
        : this.settings.allMo.find(
            (mo: IAdminMunicipalSchemaDto) => mo.id === this.settings.currentUser.organizationId.mo,
          ).coordinates;

    const transformSubstratesUrl: (url: string) => string = (url: string): string => {
      return url.startsWith('/', 0) ? `//${window.location.host}${url}` : `//${url}`;
    };

    const substrates = this.substratesQuery.getAll();
    const activeSubstrate = (this.substratesQuery.getActive() as ISubstrateDto) || substrates?.[0];

    const mapId = Uuid.newUuid() as any;
    this.mapModel = new MapBaseModel(mapId, this.mapService);
    this.mapOptions = {
      mapId,
      url: substrates?.length > 1 ? undefined : transformSubstratesUrl(activeSubstrate.link),
      urls:
        substrates?.length > 1
          ? substrates.map((el: ISubstrateDto): IMapBaseBaseUrlsOptions => {
              return <IMapBaseBaseUrlsOptions>{
                url: transformSubstratesUrl(el.link),
                name: el.name,
                attribution: el.attribution,
                selected: activeSubstrate ? el.id === activeSubstrate.id : el.default,
              };
            })
          : undefined,
      maxZoom: 18,
      zoom: this.settings.getConfig().defaultZoom,
      center: new Coordinates(defaultCenterCoordinates ?? this.settings.getConfig().defaultCoordinates)?.toArray(),
      mapStyle: { width: '100%', height: '100%' },
    };
    this.mapOptions['attribution'] = substrates?.length > 1 ? undefined : activeSubstrate.attribution;

    this.mapModel
      .getObservableMapEvents()
      .pipe(untilDestroyed(this))
      .subscribe((event: IMapBaseEvent) => {
        switch (event.typeEvent) {
          case 'mapReady': {
            this.reDrawPoints();
          }
        }
      });
  }

  /** Отрисовать поинты */
  public reDrawPoints(): void {
    this.mapModel.removeLayer('lines');

    const addressDetails: Array<[number, number]> = this.data.addressDetails
      .map((item: IAnyObject) => Coordinates.coordinatesToArray(item.coordinates))
      .filter((point: [number, number]) => point[0] && point[1]);

    addressDetails.map((point: [number, number], index: number) => {
      this.mapModel.addMarkerToLayer('details', String(index), point, this.pointIcon);
    });

    const visualizationMethodName = this.settings
      .getDictionaryByTypeSysName('areaVisualizationMethod')
      .find((item: IDictionaryInfo) => item.id === this.data.visualizationMethod)?.sysname;

    if (visualizationMethodName === 'line') {
      this.mapModel.addPolylineToLayer('lines', 'lines', addressDetails, { color: 'black', weight: 1 });
    }
    this.mapModel.viewLayer('lines', false);
    this.mapModel.viewLayer('details', false);
  }

  public close(): void {
    this.dialogRef.close();
  }
}
