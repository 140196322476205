import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';

import { IOrganization } from '../../../models/interfaces/organization.interface';
import { OrganizationState } from '../../../models/states';
import { OrganizationsStore } from '../store/organizations-store.service';

/**
 * Сервис по работе с хранилищем Организаций
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class OrganizationsQuery extends QueryEntity<OrganizationState> {
  constructor(store: OrganizationsStore) {
    super(store);
  }

  /**
   * Получение списка привлекаемых организаций
   * @param ids массив ID организаций
   * @returns массив объектов организаций
   */
  public getOrganizationsForAttraction(ids: string[]): Observable<IOrganization[]> {
    if (ids?.length) {
      return of(
        this.getAll({
          filterBy: ({ id }) => ids.includes(id),
        }),
      );
    }
    return of([]);
  }

  /**
   * Получение списка привлекаемых организаций
   * @param ids массив ID организаций
   * @returns массив объектов организаций
   */
  public getActiveOrganizations(): IOrganization[] {
    return this.getAll({
      filterBy: ({ active }: { active: boolean }) => active,
    });
  }
}
