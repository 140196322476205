<div class="header">
  <h4>{{header}}</h4>
</div>
<div mat-dialog-content>
  <ng-container #container></ng-container>
</div>
<div mat-dialog-actions>
  <button mat-flat-button class="cancel-button" (click)="cancel()">Отмена</button>
  <button mat-flat-button class="save-button" (click)="save()">Сохранить</button>
</div>
