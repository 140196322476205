import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BgCoreModule } from '@bg-front/core';
import { ScComponentsModule } from '@smart-city/core/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import {
  KseonDeviceInfoComponent,
  KseonDeviceListComponent,
  UnitServerInfoComponent,
  UnitServerInfoV2Component,
} from './components';

@NgModule({
  imports: [
    CommonModule,
    ScComponentsModule,
    ReactiveFormsModule,
    BgCoreModule,
    NzPageHeaderModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    NzSpaceModule,
    NzIconModule,
    NzToolTipModule,
    NzButtonModule,
    NzTableModule,
    NzModalModule,
    NzCollapseModule,
    NzDrawerModule,
    NzCardModule,
    NzTagModule,
    NzNotificationModule,
  ],
  declarations: [
    UnitServerInfoComponent,
    UnitServerInfoV2Component,
    KseonDeviceListComponent,
    KseonDeviceInfoComponent,
  ],
  exports: [UnitServerInfoComponent, UnitServerInfoV2Component, KseonDeviceListComponent, KseonDeviceInfoComponent],
})
export class KseonUnitsModule {}
