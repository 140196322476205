import { Injectable } from '@angular/core';
import { SELECTOR_SHELTER_FLOOR } from '@bg-front/core/models/constants';
import { IForecastingTaskParamsDto, ISelectItem } from '@bg-front/core/models/interfaces';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { map } from 'rxjs/operators';
import { IAbstractServiceData } from 'smart-city-types';

/**
 * Сервис для прогнозирования
 */
@Injectable({
  providedIn: 'root',
})
export class ForecastingRadioactiveEnvironmentService {
  constructor(private readonly settings: Settings2Service, private readonly rest: RestService) {}
  /**
   *  Фильтрую список данных для селектора shelterType если выбран "Город, улица местного значения"
   *  в контроле "Расположение укрытия"
   *  @return
   */
  public shelterTypeForLocationAppointment(): ISelectItem[] {
    const exeptedItems = [
      'manufactory',
      'industrialThreeFloorBuilding',
      'stonesOneFloorBuilding',
      'stonesTwoFloorBuilding',
      'stonesThreeFloorBuilding',
      'stonesFiveFloorBuilding',
      'woodOneFloorBuilding',
      'woodTwoFloorBuilding',
    ];
    return this.settings
      .getDictionaryByTypeSysName('typeShelterFromRadiation')
      .filter(({ sysname }) => {
        return exeptedItems.includes(sysname);
      })
      .map((item) => {
        return <ISelectItem>{ value: item.id, text: item.name };
      });
  }

  /**
   *  Получение списка этажей для конкретного типа укрытия для рогнозирования
   *  @param  sysname - ситемное наименование
   *  @return
   */
  public shelterFloorForType(sysname: string): ISelectItem[] {
    const shelterFloorList: string[] = SELECTOR_SHELTER_FLOOR[sysname];
    return this.settings
      .getDictionaryByTypeSysName('floorShelterFromRadiation')
      .filter((item) => {
        if (!shelterFloorList) {
          return item;
        }
        return shelterFloorList.includes(item.sysname);
      })
      .map((item) => {
        return <ISelectItem>{ value: item.id, text: item.name };
      });
  }

  /** Выбор задачи для прогнозирования Радиоактивной обстановки */
  public startRadioactiveForecasting(data: IForecastingTaskParamsDto) {
    return this.rest
      .serviceRequest({
        data,
        action: 'selectRadiationForecastingTask',
        service: { name: 'Forecasting' },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return res.data;
        }),
      );
  }
}
