import { Component, ElementRef, forwardRef, Input, OnChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';

import { ITreeSelectOption } from '@bg-front/core/models/interfaces';

@Component({
  selector: 'bg-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TreeSelectComponent),
      multi: true,
    },
  ],
})
export class TreeSelectComponent implements OnChanges, ControlValueAccessor {
  /** Список возможных значений */
  @Input() public values: ITreeSelectOption[] = [];

  /** Режим просмотра */
  @Input() public viewMode: '' | boolean;

  /** Поле для отображения видимых тегов */
  @ViewChild('hover') public hoverRef: ElementRef;

  /** Дубликат поля для рассчёта отображаемых тегов */
  @ViewChild('duplicate') public duplicateRef: ElementRef;

  /** Значение инпута */
  public value: string[] | null = [];

  /** Выводимое дерево возможных значений */
  public nodes: Array<NzTreeNodeOptions> = [];

  /** Блокировка ввода в инпут */
  public disabled: boolean;

  /** Выбранные теги */
  public tags: ITreeSelectOption[] = [];

  /** Отображаемые теги */
  public viewTags: ITreeSelectOption[] = [];

  /** Состояние открытия селекта */
  public opened: boolean;

  /** Количество скрытых тегов */
  public hidden = 0;

  /** Высота поля ввода */
  public height = 20;

  /** Режим отображения всех выбранных тегов */
  public fullMode = false;

  /** @ignore */
  public ngOnChanges(): void {
    /** На входе viewMode имеет или пустую строку, или вообще отсутствует
     * Для корректной работы компонента он приводится к типу boolean
     * Если такой тип уже установлен, значит в этой итерации не было изменения входного значения */
    this.viewMode = typeof this.viewMode === 'boolean' ? this.viewMode : this.viewMode === '';
    this.nodes = [];
    /** Формирование дерева выбора значений селекта */
    (this.values || []).forEach((item: ITreeSelectOption) => {
      const groupName = item.group || 'Без группы';
      /** Поиск группы, в которую необходимо поместить текущее значение */
      let group: NzTreeNodeOptions = this.nodes
        .find((node) => node.title === groupName);
      /** Если в дереве нет необходимой группы, то добавляем её */
      if (!group) {
        group = { key: groupName, title: groupName, checked: false, children: [] };
        this.nodes.push(group);
      }
      /** Добавление значения в соответствующую группу */
      group.children.push({ key: item.value, title: item.label, isLeaf: true });
      /** Сортировка значений по алфавиту */
      group.children.sort((a: NzTreeNodeOptions, b: NzTreeNodeOptions) => a.title.localeCompare(b.title));
    });
    /** Сортировка групп по алфавиту */
    this.nodes.sort((a: NzTreeNodeOptions, b: NzTreeNodeOptions) => a.title.localeCompare(b.title));
    this.calcTags(this.value, false);
  }

  /**
   * Расчёт выбранных тегов. В поле контрола содержатся только идентификаторы, а выводить необходимо наименование,
   * поэтому формируется список выводимых тегов для блока #duplicate
   * @param value Список идентификаторов
   * @param changes Флаг отправки изменений в контрол
   */
  private calcTags(value: string[], changes = true): void {
    this.tags = [];
    (value || []).forEach((item: string) => {
      // Поиск выбранного значения в списке возможных значений
      const value = (this.values || []).find((value: ITreeSelectOption) => value.value === item);
      if (value) {
        this.tags.push(value);
      } else {
        // Если значение не найдено, значит выбрана группа, добавляем все записи этой группы
        const values = (this.values || []).filter((value: ITreeSelectOption) => value.group === item);
        if (values?.length) this.tags.push(...values);
      }
    });
    // Сортировка тегов по алфавиту
    // Если не сортировать, то порядок тегов может отличаться от сохранённого,
    // это приведёт к изменению формы при открытии
    this.tags.sort((a, b) => a.label.localeCompare(b.label));
    // В контрол отправляются изменения, если сейчас не инициализация начальных данных,
    // иначе форма будет считаться изменённой
    if (changes) {
      this.value = this.tags.map((item: ITreeSelectOption) => item.value);
      this.onChange(this.value);
    }
    // Необходимо дать время на отрисовку тегов в блоке #duplicate для их корректного расчёта
    setTimeout(() => this.calcViewTags());
  }

  /** Расчёт отображаемых тегов. Проводится расчёт количества выводимых тегов в зависимости от ширины поля */
  private calcViewTags(): void {
    // Ширина элемента ввода
    let totalWidth = this.duplicateRef.nativeElement.getBoundingClientRect().width;
    // Ширина каждого тега
    const tagsWidth = [...this.duplicateRef.nativeElement.querySelectorAll('.ant-select-selection-item')]
      .map((item: HTMLElement) => item.getBoundingClientRect().width);
    // Ширина элемента ввода без дополнительного тега и отступа для иконки очистки
    totalWidth -= tagsWidth[tagsWidth.length - 1] + 36;
    // Количество отображаемых тегов
    let count = 0;
    // Общая ширина отображаемых тегов
    let width = tagsWidth[0];
    while (width < totalWidth && count < tagsWidth.length - 1) {
      count++;
      // Добавить ширину следующего тега плюс отступ
      width += tagsWidth[count] + 4;
    }
    this.hidden = this.tags.length - count;
    // Если скрыт только один элемент и его ширина меньше дополнительного тега, то отображаем все теги
    if (this.hidden === 1 && tagsWidth[count] < tagsWidth[tagsWidth.length - 1]) {
      count++;
      this.hidden--;
    }
    // В зависимости от режима отображаются все теги или только рассчитанное количество
    this.viewTags = [...this.tags].slice(0, this.fullMode ? this.tags.length : count);
    setTimeout(() => this.height = this.hoverRef.nativeElement.getBoundingClientRect().height);
  }

  /**
   * Изменение значения в селекте
   * @param value Новое значение
   */
  public onChangeModel(value: string[]): void {
    this.calcTags(value);
  }

  /**
   * Удаление элемента
   * @param event Событие мыши
   * @param value Значение удаляемого элемента
   */
  public removeItem(event: MouseEvent, value: string): void {
    event.stopPropagation();
    const index = this.tags.findIndex((item: ITreeSelectOption) => item.value === value);
    if (index !== -1) {
      this.tags.splice(index, 1);
      // Формирование значения контрола из имеющихся выбранных тегов
      this.value = this.value.filter((item: string) => this.tags.find((tag: ITreeSelectOption) => tag.value === item));
      this.onChangeModel(this.value);
    }
  }

  /**
   * Очистка значения
   * @param event Событие мыши
   */
  public clearValue(event: MouseEvent): void {
    event.stopPropagation();
    this.value = null;
    this.onChangeModel(this.value);
  }

  /**
   * Изменение состояния открытия
   * @param event Текущее состояние
   */
  public changeOpen(event: boolean): void {
    // Для отсеивания ложных срабатываний
    setTimeout(() => this.opened = event);
  }

  /** Изменение режима полного отображения */
  public toggleFullMode(event: MouseEvent): void {
    event.stopPropagation();
    this.fullMode = !this.fullMode;
    this.calcViewTags();
  }

  /** @ignore */
  public writeValue(value: string[] | null): void {
    this.value = value;
    this.calcTags(value, false);
  }

  /** @ignore */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** @ignore */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** @ignore */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** @ignore */
  public onChange(value: string[] | null): void {
    //
  }

  /** @ignore */
  public onTouched(): void {
    //
  }
}
