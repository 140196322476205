<div class="hls-player-area">
  <video
    #player
    (canplay)="canplay($event)"
    disablePictureInPicture
    [controlsList]="'nodownload'"
    controls
  ></video>
  <div class="stroke" [ngStyle]="strokeStyle" *ngIf="needStroke"></div>
</div>
