import { Injectable } from '@angular/core';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { formatPhoneDb, Uuid } from '@smart-city/core/utils';
import * as dayjs from 'dayjs';
import { forkJoin, iif, Observable, of } from 'rxjs';
import { map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IUserInfoDto } from 'smart-city-types';
import { CallService } from '../../../call/services';
import {
  IOrganizationCoverage,
  IOrganization,
  IOrganizationType,
  IOrganizationAddressOwnershipObject,
} from '../../models/interfaces';
import { AddressOwnershipObject } from '../../models/types';
import { GarFindFlatResponseElement } from '@bg-front/core';

/**
 * Сервис работы с организациями
 */
@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  /** @ignore */
  constructor(
    private readonly rest: RestService,
    private readonly settings: Settings2Service,
    private readonly call: CallService,
  ) {}

  /**
   * Запрос активных привлекаемых служб
   * @return Список организаций отсортированный по принципу: с начала ДДС *, потом все остальные по алфавиту
   */
  public getAttractToReactOrganizations(attractToReact: boolean, active: boolean): Observable<IOrganizationType[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'OrgTypeParams',
          query: {
            attractToReact,
            active,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          const org: IOrganizationType[] = response.data?.items ?? [];

          /** Не самая лучшая реализация, но работает, задаётся порядок
           * ДДС 01 -> ДДС 04 (отсутствие службы учитывается) и последняя ЕДДС
           */
          const dds = org
            .filter((org: IOrganizationType) => org.shortName.indexOf('ДДС') !== -1)
            .reduce((acc: IOrganizationType[], value: IOrganizationType) => {
              const arr = value.shortName.split(' ');
              if (arr.length > 1) {
                const idx = Number.parseInt(arr[1], 10);
                acc[idx] = value;
              } else {
                acc[5] = value;
              }
              return acc;
            }, [])
            .filter((val: IOrganizationType) => val);
          const other = org
            .filter((org: IOrganizationType) => org.shortName.indexOf('ДДС') === -1)
            .sort((item1: IOrganizationType, item2: IOrganizationType) => {
              return item1.shortName < item2.shortName ? -1 : item1.shortName > item2.shortName ? 1 : 0;
            });
          return [...dds, ...other];
        }),
      );
  }

  /**
   * Запрос активных привлекаемых служб
   * @return Список организаций отсортированный по принципу: с начала ДДС *, потом все остальные по алфавиту
   */
  public getAllAttractToReactOrganizations(): Observable<IOrganizationType[]> {
    // Если будешь править, ищи остальные реализации этого метода (они одинаковые) и тоже дорабатывай.
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'OrgTypeParams',
          query: { shortName: { $ne: null } },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          const org: IOrganizationType[] = response.data?.items ?? [];

          const dds = org
            .filter((org: IOrganizationType) => org.shortName.indexOf('ДДС') !== -1)
            .sort()
            .reverse();
          const other = org.filter((org: IOrganizationType) => org.shortName.indexOf('ДДС') === -1).sort();
          return [...dds, ...other];
        }),
      );
  }

  /**
   * Получение типа организации по id
   * @param id
   * @param attributes
   * @return
   */
  public getAttractReactOrganizationById(id: string, attributes?: string[]): Observable<IOrganizationType> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          ...(attributes ? { attributes } : {}),
          name: 'OrgTypeParams',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response.data?.items ? response.data?.items[0] : {};
        }),
      );
  }

  /**
   * Метод получения типа по query
   * @return
   */
  public getOrgTypeByQuery(query): Observable<IOrganizationType[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query,
          name: 'OrgTypeParams',
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response.data?.items ? response.data?.items : undefined;
        }),
      );
  }

  /**
   * Получения параметра типа организации для ЖЦ
   * @param orgSysname
   * @return
   */
  public getOrgTypeParamForLifeCycle(orgSysname: string): Observable<IOrganizationType[]> {
    const mapResult = this.settings
      .getDictionaryByTypeSysName('organizationType')
      .filter((el: IDictionaryInfo) => el.sysname === orgSysname)
      .map((el: IDictionaryInfo) => el.id);
    const query = {
      organizationTypeId: {
        $in: mapResult,
      },
    };
    return this.getOrgTypeByQuery(query);
  }

  /** Обновление дерева подведомственных организаций
   * @param editedOrganizationId id организации которая редактируется.
   * @param parentOrganizationId id головной организации до редактирования(если была изменена или удалена).
   */
  public updateSubOrganizationsTree(editedOrganizationId: string, parentOrganizationId: string) {
    const data = {
      editedOrganizationId,
      parentOrganizationId,
    };
    return this.rest.serviceRequest({
      data,
      action: 'updateSubOrganizationsTree',
      service: { name: 'Admin' },
    });
  }

  /**
   * Запрос организации по Id
   * @param id Id записи
   */
  public getOrganizationBy(id: string): Observable<IOrganization> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items[0] as IOrganization;
        }),
      );
  }

  /**
   * Получить организацию по query
   * @return
   */
  public getOrganizationByQuery(query: IAnyObject = {}): Observable<IOrganization[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          sort: {
            field: 'name',
            direction: 'asc',
          },
          query,
          name: 'Organizations',
        },
      })
      .pipe(map(({ data: { items } }: IAbstractServiceData) => items));
  }

  /**
   * Проверка наличие одной организации для привлечения к реагированию
   * @param mo Id Муниципального образования
   * @param orgTypeParam Id Типа организации
   */
  public getSingleOrganizationForAttraction(mo: string, orgTypeParam: string): Observable<IOrganization[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          query: {
            mo,
            orgTypeParam,
          },
        },
      })
      .pipe(
        mergeMap(({ data: { items } }) => {
          if (!items.length) {
            return this.rest
              .serviceRequest({
                action: 'select',
                service: { name: 'Admin' },
                entity: {
                  name: 'Organizations',
                  query: {
                    orgTypeParam,
                  },
                },
              })
              .pipe(
                map(({ data: { items } }) => {
                  return items;
                }),
              );
          }
          return of(items);
        }),
      );
  }

  /**
   * Получение привлекаемых органиаций
   * @param query
   * @return
   */
  public getOrganizationsForAttraction(query: IAnyObject): Observable<IOrganization[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          query: {
            ...query,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items || [];
        }),
      );
  }

  /**
   * Метод, обновляющий существующую запись в таблице Organizations сервиса Admin.
   * @param user пользователь, инициировавший изменение.
   * @param value объект с полями из формы.
   * @param id идентификатор записи, которую следует обновить.
   */
  public updateOrganization(user: IUserInfoDto, value: IAnyObject, id: string) {
    const baseObject = this.getBaseObjectForOrganization(user, 'update');
    const data: IAnyObject = {
      ...baseObject,
      ...{
        ...value,
      },
    };
    data.phone1 = formatPhoneDb(data.phone1);

    return this.rest.serviceRequest({
      data,
      action: 'update',
      service: {
        name: 'Admin',
      },
      entity: {
        name: 'Organizations',
        query: {
          id,
        },
      },
    });
  }

  /**
   * Метод, создающий новую запись в таблице Organizations сервиса Admin.
   * @param user пользователь, инициировавший создание.
   * @param value объект с полями из формы.
   */
  public createOrganization(user: IUserInfoDto, value: IAnyObject): Observable<IAbstractServiceData> {
    const baseObject = this.getBaseObjectForOrganization(user, 'insert');
    const data: IAnyObject = {
      ...baseObject,
      ...{
        active: true,
        ...value,
      },
    };
    data.phone1 = formatPhoneDb(data.phone1);

    return this.rest.serviceRequest({
      data,
      action: 'insert',
      service: {
        name: 'Admin',
      },
      entity: {
        name: 'Organizations',
      },
    });
  }

  /**
   * Метод для удаления организации.
   * @param id - id организации.
   */
  public deleteOrganization(id: string): Observable<IOrganization> {
    return this.rest
      .serviceRequest({
        action: 'delete',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return ((response.data.items ?? [])[0] || {}) as IOrganization;
        }),
      );
  }

  /** Получить типа организации по id организации */
  public getOrganizationTypeParam(organizationId: string): Observable<IOrganizationType> {
    return this.getOrganizationBy(organizationId).pipe(
      switchMap((organization: IOrganization) => {
        return iif(
          () => !!organization?.orgTypeParam,
          this.rest
            .serviceRequest({
              action: 'select',
              service: { name: 'Admin' },
              entity: {
                name: 'OrgTypeParams',
                query: { id: organization?.orgTypeParam },
              },
            })
            .pipe(
              map((response: IAbstractServiceData) => {
                return ((response.data.items ?? [])[0] || {}) as IOrganizationType;
              }),
            ),
          of({} as IOrganizationType),
        );
      }),
    );
  }

  /**
   * Получение организаций по mo и типу. С учетом выборки по адресу организации для предзаполнения.
   * @param servicesIds - список ids типов привлекаемых служб
   * @param mo - mo
   * @param factAddress - выбранный фактический адресс
   * @param excludeList - список исключений
   * @return
   */
  public getOrganizationByAddress(
    servicesIds: string[],
    mo: string,
    factAddress: GarFindFlatResponseElement,
  ): Observable<{ [key: string]: IOrganization[] }> {
    const orgList: { [key: string]: IOrganization[] } = {};
    let addressesMap: IAnyObject;

    if (!servicesIds.length) {
      return of({});
    }
    /** Получаем общий список организаций для каждого доступного типа службы */
    return forkJoin(
      ...servicesIds.map((id: string) => {
        orgList[id] = [];
        return this.getOrganizationsForAttraction({ mo, orgTypeParam: id, active: true }).pipe(
          map((result) => {
            return result;
          }),
        );
      }),
    ).pipe(
      switchMap((result: IAnyObject) => {
        const organizationsIds: string[] = result.flat().reduce((prev, org: IOrganization) => [...prev, org.id], []);
        return this.rest
          .serviceRequest({
            action: 'select',
            service: { name: 'Admin' },
            entity: {
              name: 'AddressOwnership',
              query: { organizationId: { $in: organizationsIds } },
            },
          })
          .pipe(
            map((response: IAbstractServiceData) => {
              const addressOwnershipArray: AddressOwnershipObject[] = response.data.items ?? [];
              const addressesObj: IAnyObject = {};
              /** Преобразуем массив объектов адресов в мап объект, где ключ - id организации,
               * значение - массив fias адресов
               */
              organizationsIds.forEach((orgId: string) => {
                const addresses: GarFindFlatResponseElement[] = addressOwnershipArray
                  .filter((item: IOrganizationAddressOwnershipObject) => item.organizationId === orgId)
                  .map((item: IOrganizationAddressOwnershipObject) => item.address);

                if (addresses.length) {
                  addressesObj[orgId] = addresses;
                }
              });
              addressesMap = addressesObj;

              return result;
            }),
          );
      }),
      map((result: IAnyObject) => {
        result.forEach((orgTypeList) => {
          if (orgTypeList.length) {
            const typeId = orgTypeList[0].orgTypeParam;
            // запускаем фильтрацию организаций по адресам на предмет заполненности полей
            const filteredOrgList = orgTypeList
              .filter((org) => {
                return addressesMap[org.id]?.length && addressesMap[org.id]?.filter((item) => !!item).length;
              })
              .map((org) => {
                return {
                  ...org,
                  addressOwnership: addressesMap[org.id]
                    .filter((addrEl) => addrEl)
                    // TODO логика для поддержания старого варианта сохранения данных в поле addressOwnership
                    .map((addrEl) => (addrEl['address'] ? addrEl['address'] : addrEl)),
                };
              }) // Запуск алгоритма сравнения организаций из списка адресов (из блока принадлежность адресов)
              // и фактического адреса
              .filter((curOrg) => this.filterOrgAddressOwnership(curOrg.addressOwnership, factAddress));

            // если в результате фильтрации отсутствуют элементы, то берем полностью найденный массив организаций
            orgList[typeId] = filteredOrgList.length ? filteredOrgList : orgTypeList;
          }
        });
        return orgList;
      }),
    );
  }

  /**
   *  Вспомогательный метод фильтрации организации по адресу, который сравнивает адреса организаций
   *  в массиве addressOwnership и выбранный фактический адрес.
   *  Алгоритм сравнения: сравниваем сначала houseUuid фактического адреса и houseUuid элемента из addressOwnership
   *  если совпадений нет то сравниваем uuid.
   *  @return
   */
  public filterOrgAddressOwnership(
    addresses: GarFindFlatResponseElement[],
    factAddress: GarFindFlatResponseElement,
  ): boolean {
    const uuid = factAddress?.aoguid;
    const houseUuid = factAddress?.houseGUID;
    let result = [];
    result = addresses.filter((addr: GarFindFlatResponseElement) => {
      return addr?.houseGUID ? addr?.houseGUID === houseUuid : uuid && addr?.aoguid === uuid;
    });
    if (result.length) {
      result = addresses.filter((addr: GarFindFlatResponseElement) => {
        return addr?.aoguid === uuid;
      });
    }
    return Boolean(result.length);
  }

  /**
   * Сохранение связанного адреса
   * @param ownedAddress - информация об адресе
   */
  public saveOwnedAddress(ownedAddress: AddressOwnershipObject): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'upsert',
      service: { name: 'Admin' },
      entity: {
        name: 'AddressOwnership',
        query: { id: ownedAddress.id ? ownedAddress.id : Uuid.newUuid() },
      },
      data: ownedAddress,
    });
  }

  /**
   * Удаление связанных адресов
   * @param ids - массив ID для удаления
   */
  public deleteOwnedAddresses(ids: string[]): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: 'Admin' },
      entity: {
        name: 'AddressOwnership',
        query: {
          id: { $in: ids },
        },
      },
    })
  }

  /**
   * Получение зоны действия по id организации.
   * @param id
   */
  public getCoverageAreaByOrgId(id: string): Observable<IOrganizationCoverage> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'OrganizationCoverage',
          query: { organizationId: id },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return ((response.data.items ?? [])[0] || {}) as IOrganizationCoverage;
        }),
      );
  }

  /**
   * Сохранение зоны действия организации.
   * @param data - объект с зоной действия организации
   */
  public saveCoverageArea(data: IOrganizationCoverage): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      data,
      action: 'upsert',
      service: { name: 'Admin' },
      entity: {
        name: 'OrganizationCoverage',
        query: { id: data.id || Uuid.newUuid() },
      },
    });
  }

  /**
   * Метод, создающий объект с общим набором полей:
   * - автор создания (при создании записи);
   * - время создания (при создании записи);
   * - автор последнего изменения;
   * - время последнего изменения.
   * @param user пользователь, запросивший создание/изменение записи.
   * @param action выполняемое действие — создание записи или обновление.
   * @param isoString приводить дату к формату ISO
   */
  private getBaseObjectForOrganization(user, action: 'insert' | 'update', isoString?: boolean): IAnyObject {
    const data: IAnyObject = {
      updateAuthor: user.id,
      updateTime: isoString ? dayjs().toISOString() : Date.now(),
    };
    if (action === 'insert') {
      data.creationAuthor = data.updateAuthor;
      data.creationTime = data.updateTime;
    }
    return data;
  }

  /** Исключить номера пользователей из очередей при удалении данных очередей у организации
   * @param queues - массив ID удаляемых очередей
   * @param organizationId -ID организации
   **/
  public excludeQueues(queues: string[], organizationId: string): Observable<IAbstractServiceData> {
    // получаем пользователей у которых присутствуют удаляемые очереди
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Users',
          linksMode: 'raw',
          query: {
            atsQueue: { $in: queues },
            organizationId,
          },
          attributes: ['atsPhone.phoneNumber', 'atsQueue.id', 'atsQueue.extName'],
        },
      })
      .pipe(
        pluck('data', 'items'),
        switchMap((users) => {
          // посылаем в гастер команду на выход из очереди
          users.forEach((user: IUserInfoDto) => {
            this.call.excludePhoneFromQueue(user.atsPhone['phoneNumber'], user.atsQueue['extName']);
          });
          const userIds: string[] = users
            .filter((user: IUserInfoDto) => user.atsQueue && user.atsPhone)
            .map((user: IUserInfoDto) => user.id);
          // Удаляем очереди у пользователей в бд
          return this.rest.serviceRequest({
            action: 'update',
            service: { name: 'Admin' },
            entity: {
              name: 'Users',
              linksMode: 'raw',
              query: {
                id: { $in: userIds },
              },
            },
            data: { atsQueue: null },
          });
        }),
      );
  }

  /** Отправка запроса для деактивации организации в АИУС */
  public sendDeleteOrganizationRequest(organizationId: string): Observable<IAbstractServiceData> {
    return this.rest
      .serviceRequest({
        action: 'sendDeleteOrganization',
        service: { name: 'RiskAtlasIntegration' },
        data: { organizationId }
      });
  }
}
