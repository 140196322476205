<div class="bg-row">
  <div class="bg-col-10">
    <h4><b>Доступные отчеты</b></h4>
  </div>
  <div class="bg-col-2 align-right">
    <button mat-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="bg-row">
  <div class="bg-col">
    <mat-table [dataSource]="reports" class="mat-elevation-z8">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Наименование отчета</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container cdkColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell class="mat-cell-actions" *cdkCellDef="let element">
          <button *ngIf="element.editable" class="action-button edit-button" (click)="edit(element)">
            <mat-icon>edit</mat-icon>
          </button>
          <button
            class="action-button download-button"
            [class.disabled]="!element.download"
            (click)="element.download && download(element)"
          >
            <sc-icon [name]="'arrow_downward'"></sc-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
