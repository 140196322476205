import { IOrganization, IPhonesResolver } from '@smart-city/calls/call-manager';
import { BaseService, NotificationService, RestService, Settings2Service } from '@smart-city/core/services';
import { ScConsole } from '@smart-city/core/utils';
import { AtsService } from '@smart-city/core/common';
import { forkJoin, Observable, of, Subject, Subscriber } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';
import { Injectable } from '@angular/core';
import { GAsterService } from '@smart-city/calls/services';
import { ICaller, ICallerData } from '../../models/interfaces';
import { PhoneStateEnum } from '../../models/enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const NUMBER_OF_RECORDS_HISTORY = 10;

/**
 * Сервис для работы со звонками в целом и для работы компонента {@link CallManagerComponent}:
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CallService extends BaseService {
  /** Объект, содержащий в себе информацию об абоненте */
  public caller: ICaller = {
    phone: null,
    phoneType: null,
    name: null,
    type: null,
    citizenId: null,
    citizens: [],
  };
  /** Номер телефона, на который осуществляется исходящий вызов */
  public outgoingPhoneNumber: string | null = null;
  /** Событие звонка */
  private onRing: Subject<string> = new Subject<string>();
  /** Позволяет подписаться на событие звонка */
  public onRing$ = this.onRing.asObservable();
  /** Событие снятия трубки, при входящем звонке */
  public onPickupIn: Subject<{
    callId: string,
    destCaller: string,
  }> = new Subject<{ callId: string, destCaller: string }>();
  /** Позволяет подписаться на событие снятия трубки, при входящем звонке */
  public onPickupIn$ = this.onPickupIn.asObservable();
  /** Событие снятия трубки, при исходящем звонке */
  private onPickupOut: Subject<{
    callId?: string,
    destCaller?: string
  }> = new Subject<{
    callId?: string,
    destCaller?: string,
  }>();
  /** Позволяет подписаться на событие снятия трубки, при исходящем звонке */
  public onPickupOut$ = this.onPickupOut.asObservable();
  /** Событие завершения разговора, при входящем звонке */
  private onHungupIn: Subject<void> = new Subject<void>();
  /** Позволяет подписаться на событие завершения разговора, при входящем звонке */
  public onHungupIn$ = this.onHungupIn.asObservable();
  /** Событие завершения разговора, при исходящем звонке */
  private onHungupOut: Subject<void> = new Subject<void>();
  /** Позволяет подписаться на событие завершения разговора, при исходящем звонке */
  public onHungupOut$ = this.onHungupOut.asObservable();
  /** Событие получения уведомления */
  private onNotification: Subject<IAnyObject> = new Subject<IAnyObject>();
  /** Позволяет подписаться на событие уведомления */
  public onNotification$ = this.onNotification.asObservable();
  /** Текущий пользователь, залогиненный в системе */
  private user: IAnyObject;
  /** Текущее состояние, полученное от Гастера */
  private currentState: PhoneStateEnum = PhoneStateEnum.hangupIn;
  /** Номер телефона, с которого идёт вызов */
  private currentCallId: string | null = null;
  /** Наименование очереди, в которой находится пользователь */
  private queueName: string | undefined;
  /** IP ATC */
  private atsIp: string;
  /** Идентификатор карточки от системы 112 */
  private globalId: string;

  /** @ignore */
  constructor(
    private readonly rest: RestService,
    private readonly ats: AtsService,
    private settings2: Settings2Service,
    private gaster: GAsterService,
    private note: NotificationService,
  ) {
    super();

    this.gaster.onGAsterMessage$.pipe(
      filter((message: IAnyObject) => !!message),
      takeUntil(this.ngUnsubscribe),
    )
    .subscribe((message: IAnyObject) => {
      if (message.command === 'changeNumber') {
        /**
         * Пользователю необходимо сменить номер на пришедший, а также удалить данный номер у другого пользователя,
         * если имеется. После чего надо выполнить регистрацию данного номера в Гастере.
         */
        let atsPhone: IAnyObject;
        this.rest.serviceRequest({
          action: 'select',
          service: { name: 'Admin' },
          entity: {
            name: 'AtsPhones',
            query: {
              phoneNumber: message.number,
            },
            linksMode: 'object',
          },
          data: {},
        })
          .pipe(switchMap((res: IAbstractServiceData) => {
            if (res.data.items.length === 0) {
              this.note.pushError(`Номер ${message.number} не зарегистрирован в системе`);
              return of(null);
            }
            /** удаляем  номер у других пользователей */
            atsPhone = res.data.items[0].id;

            return this.rest.serviceRequest({
              action: 'update',
              service: { name: 'Admin' },
              entity: {
                name: 'Users',
                query: { atsPhone },
              },
              data: {
                atsPhone: null,
              },
            });
          }))
          /** Добавляем номер текущему пользователю и удаляем код подтверждения*/
          .pipe(switchMap((res: IAbstractServiceData | null) => {
            if (!res) return of(null);
            return this.rest.serviceRequest({
              action: 'update',
              service: { name: 'Admin' },
              entity: {
                name: 'Users',
                query: {
                  id: this.settings2.currentUser.id,
                },
              },
              data: { atsPhone },
            });
          }))
          .subscribe(
            (res: IAbstractServiceData | null) => {
              if (res) {
                this.note.pushSuccess(`Присвоен номер ${message.number}`);
                this.settings2.currentUser.atsPhone = {
                  id: atsPhone,
                  phoneNumber: message.number,
                };
                this.settings2.currentUser.registrationCode = null;
                this.gaster.registerPhone(this.settings2.currentUser);
              }
            },
            (err) => {
              ScConsole.error(err);
            },
          );
      }

      this.currentState = message.state !== PhoneStateEnum.notification ? message.state : this.currentState;
      switch (message.state) {
        case PhoneStateEnum.ring:
          this.onRing.next(message.destCaller);
          break;
        case PhoneStateEnum.pickupIn:
          this.currentCallId = message.parentuniqueid || message.callId;
          this.onPickupIn.next({ callId: this.currentCallId, destCaller: message.destCaller });
          break;
        case PhoneStateEnum.pickupOut:
          this.currentCallId = message.parentuniqueid || message.callId;
          this.onPickupOut.next({ callId: message.callId, destCaller: message.destCaller });
          break;
        case PhoneStateEnum.monitoring:
        case PhoneStateEnum.hangupIn:
          this.currentCallId = null;
          this.onHungupIn.next();
          break;
        case PhoneStateEnum.hangupOut:
          this.currentCallId = null;
          this.onHungupOut.next();
          break;
        case PhoneStateEnum.notification:
          if (message.notification?.command === 'register') {
            this.queueName = message.notification.queueName;

            const user = this.settings2.currentUser;
            // Если при авторизации у пользователя отсутствует очередь, нужно выйти из всех очередей в атс,
            // т.к. если очередь была убрана администратором, есть вероятность что номер остался зареган в очереди в гастере.
            if (!user.atsQueue && user.atsPhone) {
              this.ats.getAtsByUser(user.id).pipe(untilDestroyed(this))
                .subscribe((ats: IAnyObject) => {
                  if (ats.queues?.length) {
                    ats.queues.forEach((queue: { name: string, phones: string[] }) => {
                      this.excludePhoneFromQueue(user.atsPhone?.phoneNumber, queue.name);
                    });
                  }
                });
            }
          }
          this.onNotification.next(message.notification);
      }
    });
  }



  /**
   * Получить наименование очереди, в которой находится пользователь.
   */
  public getQueueName(): string {
    return this.queueName;
  }

  /**
   * Метод, возвращающий callId звонка, если он в процессе, и null, если звонок завершён (не был начат).
   */
  public getCurrentCallId(): string | null {
    return this.currentCallId;
  }

  /**
   * Метод, возвращающий последнее полученное состояние от Гастера.
   * Необходимо для корректной инициализации компонента.
   * Например, при переходе между страницами надо знать актуальное состояние.
   */
  public getCurrentState(): PhoneStateEnum {
    return this.currentState;
  }

  /**
   * Метод для получения наблюдателя, возвращающего историю вызовов для конкретного пользователя.
   */
  public getCallsHistory(): Observable<IAnyObject[]> {
    if (!this.user) {
      return this.settings2.loadCurrentUser().pipe(
        switchMap((user) => {
          this.user = user;
          return this.getUserCallsHistory();
        }));
    }
    return this.getUserCallsHistory();
  }

  private getUserCallsHistory(): Observable<IAnyObject[]> {
    if (!this.user?.atsPhone && this.user?.atsPhone?.atsId) {
      return of([]);
    }
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'Ats',
        query: { id: this.user.atsPhone.atsId },
      },
    }).pipe(
      switchMap((res) => {
        const atc = res.data?.items?.[0]?.ip;
        return this.rest.serviceRequest({
          service: { name: 'Emergency' },
          action: 'select',
          entity: {
            dbtype: 'clickhouse',
            dbname: 'asterisk',
            name: 'cdr',
            limit: NUMBER_OF_RECORDS_HISTORY,
            sort: {
              direction: 'desc',
              field: 'starttime',
            },
            query: {
              $and: [
                {
                  atc,
                },
                {
                  $or: [
                    { source: this.user.atsPhone.phoneNumber },
                    { destination: this.user.atsPhone.phoneNumber },
                  ],
                },
              ],
            },
            linksMode: 'raw',
          },
        });
      }),
      map((res: IAbstractServiceData) => {
        return res.data.items;
      }),
    );
  }

  /**
   * Вывод уведомлений о состоянии подключения к телефонии
   * @param user текущий пользователь
   */
  public showStatusNotification(user: IAnyObject): void {
    if (user?.registrationCode) {
      this.note.pushWarning('Нужно зарегистрировать номер');
    }
  }

  /**
   * Метод для совершения звонка.
   * @param phone номер телефона, на который необходимо позвонить
   */
  public call(phone: string): void {
    this.outgoingPhoneNumber = phone;
    this.gaster.call(phone);
  }

  /**
   * Метод для перевода вызова.
   * @param phone номер телефона, на который необходимо перевести звонок
   */
  public transfer(phone: string): void {
    this.gaster.transfer(phone);
  }

  /**
   * Метод для перевода вызова c сопровождением оператором.
   * @param phone номер телефона, на который необходимо перевести звонок
   */
  public supportedTransfer(phone: string): void {
    this.gaster.supportedTransfer(phone);
  }

  /**
   * Метод для создания конференции.
   * @param phone номер телефона, который необходимо добавить к звонку
   */
  public conference(phone: string): void {
    this.gaster.conference(phone);
  }

  /**
   * Исключить номер из очереди.
   * @param phone номер телефона, который необходимо исключить
   * @param queue наименование очереди
   */
  public excludePhoneFromQueue(phone: string, queue: string) {
    this.gaster.excludePhoneFromQueue(phone, queue);
  }

  /**
   * Возобновить работу номера в очереди.
   * @param phone номер телефона, который необходимо включить
   * @param queue наименование очереди
   */
  public includePhoneInQueue(phone: string, queue: string) {
    this.gaster.includePhoneInQueue(phone, queue);
  }

  /*
   * FIXME:
   *  Это длинное описание здесь было. В настоящее время реализация аналогична sc-calls, описание такое же.
   *  Оставляю для того чтоб при необходимости в дальнейшем не продумывать логику определения звонка.
   *  Когда будет уверенность что логика полная, описание надо скорректировать.
   */
  /**
   * Метод для поиска владельца номера телефона.
   * Приоритезация поисков следующая:
   * - сначала ищем владельца среди Граждан,
   * - потом среди Координаторов (Пользователей системы),
   * - далее в Организациях (по внутреннему и внешнему номеру).
   * Дабы не ходить в базы по очереди, что замедлит поиск, используется forkJoin, который вернёт результаты
   * поиска по каждому из мест.
   *
   * В результатах поиска надо найти первый непустой массив, откуда взять определённое поле:
   * - для Организаций это Краткое наименование организации;
   * - для Граждан и Координаторов (Пользователей) — ФИО.
   * Если результат не найден, возвращается null.
   *
   * Кроме того, найденное значение сохранится в самом сервисе, для доступа к нему другим сервисам/компонентам.
   * @param phone номер телефона звонящего, может быть не определён.
   */
  public findOutPhoneOwner(phone: string): Observable<ICallerData> {
    return this.citizensPhonesResolver([phone]).pipe(map(citizenPhones => (citizenPhones[phone] || [])[0]));
  }

  /**
   * Получение информации о владельцах номеров телефонов
   * @param phones список номеров телефонов
   */
  public citizensPhonesResolver: IPhonesResolver = (phones: string[]) => this.rest.serviceRequest({
    action: 'select',
    service: { name: 'Citizens' },
    entity: {
      name: 'CitizensPhones',
      attributes: [
        'citizenId.id',
        'citizenId.surname',
        'citizenId.firstName',
        'citizenId.patronymic',
        'phoneNumber',
      ],
      query: {
        phoneNumber: { $in: phones },
      },
      linksMode: 'raw',
    },
    data: {
      fio: {
        $expr: {
          $concat: ['$citizenId.surname', ' ', '$citizenId.firstName', ' ', '$citizenId.patronymic'],
        },
      },
    },
  }).pipe(map(data => ((data.data.items ?? []).reduce((acc, item) => ({
    ...acc,
    [item.phoneNumber]: acc[item.phoneNumber]
      ? [...acc[item.phoneNumber], { type: 'citizen', name: item.fio }]
      : [{ type: 'citizen', name: item.fio }],
  }), {}))))

  /**
   * Метод для получения наблюдателя, возвращающего массив организаций, который будет использован
   * в справочнике организаций.
   */
  public getOrganizations(): Observable<IOrganization[]> {
    return new Observable((subscriber: Subscriber<IOrganization[]>) => {
      this.rest.serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          linksMode: 'raw',
        },
      }).pipe(map((res: IAbstractServiceData) => {
        return res.data.items.map((item: IAnyObject) => {
          return {
            name: item.name,
            phone: item.phone1,
          } as IOrganization;
        });
      })).subscribe((res) => {
        subscriber.next(res);
      }, () => { return; });
    });
  }

  /**
   * Метод для получения массива организаций, в поиске по истории звонков.
   * @param searchTerm Текст, введённый в строке поиска
   */
  public getOrganizationsOnSearch(searchTerm: string): Observable<IOrganization[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'Organizations',
        linksMode: 'raw',
      },
      data: {
        search: searchTerm,
      },
    }).pipe(map((res: IAbstractServiceData) => {
      return res.data.items.map((item: IAnyObject) => {
        return {
          name: item.name,
          phone: item.phone1,
        } as IOrganization;
      });
    }));
  }

  /**
   * Метод для поиска владельцев по списку номера телефона.
   * Приоритезация поисков следующая:
   * - в Организациях (по внутреннему и внешнему номеру),
   * - далее — среди Пользователей.
   *
   * При поиске запрашиваются из таблиц все записи, содержащие указанные телефоны.
   * Найденные соответствия записываются в структуру { 'телефон': 'имя' }.
   * Сначала записываются значения из менее приоритетных таблиц,
   * затем они перетираются значениями из более приоритетных.
   *
   * Функция возвращает результат в формате { 'телефон': 'имя' }, никуда его не сохраняет.
   *
   * @param phones номера телефонов
   */
  public findOutPhoneOwners(phones: string[]): Observable<{ [index: string]: string }> {
    const uniqPhones = [...new Set(phones)];
    return forkJoin([
      this.rest.serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          query: {
            $or: [
              { phone1: { $in: uniqPhones } },
              { phone2: { $in: uniqPhones } },
            ],
          },
          linksMode: 'raw',
        },
      }),
      this.rest.serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Users',
          query: {
            phone: { $in: uniqPhones },
          },
          linksMode: 'raw',
        },
      }),
    ]).pipe(map((results: IAbstractServiceData[]) => {
      const callerNames: { [index: string]: string } = {};
      const data = results.map(result => result.data.items);

      /** Найден владелец номера телефона среди Пользователей */
      if (data[1].length) {
        callerNames[data[1][0].phone] = data[1][0].fio;
      }
      /** Найден владелец номера телефона среди Организаций */
      if (data[0].length) {
        if (data[0][0].phone1) callerNames[data[0][0].phone1] = data[0][0].name;
        if (data[0][0].phone2) callerNames[data[0][0].phone2] = data[0][0].name;
      }
      return callerNames;
    }));
  }
}
