import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Coordinates } from '@bg-front/core/models/classes';
import { BgDatetimeValidator, coordinatesValidator } from '@bg-front/core/validators';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IAnyObject, IEmergencyResolutionDto, ILifeCycleStepDto, IEmergencyEventDto } from 'smart-city-types';
import { IAppealCommentDto } from 'smart-city-types/interfaces/appeal-comment-dto.interface';
import { IAppealDetailDto, IDictionaryModelDto, ILifeCycleStepActionDto } from 'smart-city-types/interfaces';

import { IMiniMapMarkerPositionEvent } from '../../models/interfaces';
import { BaseIncidentFormComponent } from '../base-incident-form/base-incident-form.component';
import { BgLoosesComponent } from '../bg-looses/bg-looses.component';
import { IHcsJournalDto } from '@bg-front/interaction-hcs-journal/models/interfaces';
import { IBaseDictionaryData } from '@smart-city/core/interfaces';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import {
  AtmIntegrationService,
  BgAdminService,
  EmergencyService,
  MiniMapService,
  OrganizationsService,
  ResponsePlanStepService,
} from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '@smart-city/core/common';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { MultiFileService } from '@bg-front/core/services';
import { ReportingService } from '../../../bg/modules/reporting/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { GarFindFlatResponseElement } from '@bg-front/core';

@UntilDestroy()
@Component({
  selector: 'bg-incident-ulk-edit-form',
  templateUrl: './incident-ulk-edit-form.component.html',
  styleUrls: ['./incident-ulk-edit-form.component.scss'],
})
/**
 * Компонента реализует возможность просмотра и редактирования инцидента ДДС01
 */
export class IncidentUlkEditFormComponent
  extends BaseIncidentFormComponent
  implements OnInit, AfterViewInit, OnDestroy {
  /** Центр миникарты */
  public miniMapCenter: MapBaseCoordinatesType;

  /** Зум для миникарты */
  public miniMapZoom: number;

  /** Координаты по умолчанию для мини карты */
  public defaultMarkerCoordinate: [number, number];

  /** Свойство рассчитывающее общее количество задействованных машин */
  public get summaryCars(): number {
    if (this.model.resolution) {
      return (
        (this.model.resolution.fireCar || 0) +
        (this.model.resolution.ambulance || 0) +
        (this.model.resolution.policeCar || 0)
      );
    }
    return 0;
  }

  /** Флаг видимости блока "Информация для интернет портала" */
  public showInternetPortalInfo: boolean = false;
  /** Сохраняем состояние комментариев, что бы не задваивать при ошибках */
  private currentIPComments: IAppealCommentDto[];

  /** Иконка результата обмена информацией с реформой ЖКХ */
  public hcsInteractionResultIcon: string;
  /** Цвет иконки результата обмена информацией с реформой ЖКХ */
  public hcsInteractionResultIconColor: string;
  /** Хинт иконки результата обмена информацией с реформой ЖКХ */
  public hcsInteractionResultIconHint: string;

  /** @ignore */
  constructor(
    accessService: AccessService,
    atmIntegrationService: AtmIntegrationService,
    bgAdminService: BgAdminService,
    dialog: MatDialog,
    dialogService: DialogService,
    emergencyService: EmergencyService,
    fb: FormBuilder,
    ksipCategoriesQuery: KsipCategoriesQuery,
    ksipDetailsQuery: KsipDetailsQuery,
    ksipSectionsQuery: KsipSectionsQuery,
    ksipTypesQuery: KsipTypesQuery,
    miniMapService: MiniMapService,
    multiFileService: MultiFileService,
    organizationsService: OrganizationsService,
    reportingService: ReportingService,
    route: ActivatedRoute,
    router: Router,
    settings: Settings2Service,
    significantObjectService: SignificantObjectsService,
    responsePlanStepService: ResponsePlanStepService,
  ) {
    super(
      accessService,
      atmIntegrationService,
      bgAdminService,
      dialog,
      dialogService,
      emergencyService,
      fb,
      ksipCategoriesQuery,
      ksipDetailsQuery,
      ksipSectionsQuery,
      ksipTypesQuery,
      miniMapService,
      multiFileService,
      organizationsService,
      reportingService,
      route,
      router,
      settings,
      significantObjectService,
      responsePlanStepService,
    );
  }

  /** @ignore */
  ngOnInit(): void {
    this.showInternetPortalInfo = this.accessService.accessMap['showInternetPortalInfo']?.visible;
    /** Проверка состояния флагов в зависимости от типа события для отображения полей плановых сроков работ
     * 001 - авария
     * 003 - плановое **/
    const ksipType = this.ksipTypesQuery.getById(this.model.incidentTypeId);
    this.isAccidentForm = this.settings.getDictionaryById(ksipType.eventTypeId)?.sysname === '001';
    this.isPlanWorkForm = this.settings.getDictionaryById(ksipType.eventTypeId)?.sysname === '003';
    if (ksipType.ksipDetails) {
      this.isAccidentFormDetails = this.settings.getDictionaryById(ksipType.ksipDetails[0])?.sysname === '001';
    }

    this.incidentForm = new FormGroup({
      /** ----- MAIN ----- **/
      byCoordinates: new FormControl(this.model.byCoordinates),
      addressFact: new FormControl(this.model.addressFact, [Validators.required]),
      coordinatesAddress: new FormControl(
        this.model.byCoordinates ? this.model.addressFact.fullText || this.model.address.fullText : null,
      ),
      moId: new FormControl(this.model.moId),
      districtId: new FormControl({ value: this.model.districtId, disabled: true }),
      detailsFact: new FormControl(this.model.detailsFact),
      incidentTypeId: new FormControl(this.model.incidentTypeId, [Validators.required]),
      ksipDetailsId: new FormControl(this.model.ksipDetailsId),
      comment: new FormControl(this.model.comment),
      description: new FormControl(this.model.description),
      responsibleUser: new FormControl(this.model.responsible || this.settings.currentUser.id, [Validators.required]),
      organization: new FormControl(this.model.organization),
      urgently: new FormControl(this.model.urgently),
      danger: new FormControl(this.model.resolution?.danger),
      dangerOrg: new FormControl(this.model.resolution?.dangerOrg),
      supervisedByCuks: new FormControl(this.model.supervisedByCuks),
      /** ----- PLACEMENT ----- **/
      coordinates: new FormControl(this.model.coordinates, [coordinatesValidator()]),
      ksipPlaceDescription: new FormControl(this.model.ksipPlaceDescription),
      /** ----- ADDITIONAL ----- **/
      totalCars: new FormControl({ value: this.summaryCars, disabled: true }),
      report: new FormControl(this.model.resolution?.report),
      /** ----- Regime ----- **/
      regime: new FormControl(this.model.regimeId),
      /** ----- LOOSES ----- **/
      resolution: BgLoosesComponent.generateFormGroup(this.fb, {
        ...this.model.resolution,
        peopleInTheZoneOnDate: this.model.resolution?.peopleInTheZoneOnDate || this.model.timeCreate,
        deathOnDate: this.model.resolution?.deathOnDate || this.model.timeCreate,
        rescuedOnDate: this.model.resolution?.rescuedOnDate || this.model.timeCreate,
        victimOnDate: this.model.resolution?.victimOnDate || this.model.timeCreate,
        hospitalizedOnDate: this.model.resolution?.hospitalizedOnDate || this.model.timeCreate,
        evacuatedOnDate: this.model.resolution?.evacuatedOnDate || this.model.timeCreate,
        missingOnDate: this.model.resolution?.missingOnDate || this.model.timeCreate,
      }),
      /** ----- DOCS ----- **/
      documents: new FormControl(this.model.documents),
      totalRegistryPeopleHouse: new FormControl(this.model.totalRegistryPeopleHouse),
      totalTemporalPeopleHouse: new FormControl(this.model.totalTemporalPeopleHouse),
      individualOwnership: new FormControl(this.model.individualOwnership),
      jkhObject: new FormControl(this.model?.jkhObject),
      favorableRestrictionObjects: new FormControl(this.model?.favorableRestrictionObjects),
      affectedRestrictionObjects: new FormControl(this.model?.affectedRestrictionObjects),
      resourceConstraintType: new FormControl(this.model?.resourceConstraintType),
      relatedRestrictions: new FormControl(this.model?.relatedRestrictions),
      jkhDirection: new FormControl(this.model?.jkhDirection),
      consumerPowerCategoryId: new FormControl(this.model?.consumerPowerCategoryId, [Validators.required]),
      countSocialFacilities: new FormControl(this.model?.countSocialFacilities),
      isSendingToJkhReform: new FormControl(this.model?.isSendingToJkhReform),
      workTermFrom: new FormControl(this.model?.workTermFrom),
      workTermTo: new FormControl(this.model?.workTermTo),
      showOnPortal: new FormControl(this.model.showOnPortal),
      internetPortalComment: new FormControl(undefined),
      internetPortalDesc: new FormControl(this.model?.internetPortalDesc || this.model?.description?.substring(0, 300)),
    });

    this.eventsGridQuery = { incidentId: this.model.id };

    this.vehiclesGridQuery = { id: { $in: this.model.involvedVehicles || [] } };

    super.ngOnInit();

    if (this.model.isSendingToJkhReform) {
      this.emergencyService.getLastHcsInteractionResult(this.model.id).subscribe((hcsInteractionResult: IHcsJournalDto) => {
        switch ((<IBaseDictionaryData>hcsInteractionResult.result).sysname) {
          case 'sent':
            this.hcsInteractionResultIcon = 'schedule';
            this.hcsInteractionResultIconColor = '#7B7B7B';
            this.hcsInteractionResultIconHint = `${
              (<IBaseDictionaryData>hcsInteractionResult.result).name
            } ${
              dayjs(hcsInteractionResult.submitDate).format('DD.MM.YYYY HH:mm:ss')
            }`;
            break;
          case 'sendingError':
            this.hcsInteractionResultIcon = 'cancel';
            this.hcsInteractionResultIconColor = '#FC5A5A';
            this.hcsInteractionResultIconHint = (<string[]>hcsInteractionResult.reformHcsResponse)?.join('\n');
            break;
          case 'delivered':
            this.hcsInteractionResultIcon = 'check_circle';
            this.hcsInteractionResultIconColor = '#1CCA84';
            this.hcsInteractionResultIconHint = (<IBaseDictionaryData>hcsInteractionResult.result).name
            break;
        }
      });
    }

    if (this.isPlanWorkForm) {
      let minDate;
      const dateDefault = new Date(Date.now()).setHours(0, 0, 0, 0);
      if (this.model.timeCreate < dateDefault) {
        minDate = new Date(this.model?.timeCreate).setHours(0, 0, 0, 0);
      } else {
        minDate = dayjs().startOf('day').valueOf();
      }
      const workTermToInitValidators = [
        Validators.required,
        BgDatetimeValidator.minDate(minDate),
        BgDatetimeValidator.minDateOf(this.incidentForm.controls.workTermFrom.value),
      ];
      const validators = this.isPlanWorkForm ? [Validators.required, BgDatetimeValidator.minDate(minDate)] : [];
      this.incidentForm.controls.workTermFrom.setValidators(validators);
      this.incidentForm.controls.workTermFrom.setErrors(null);
      this.incidentForm.controls.workTermTo.setValidators(workTermToInitValidators);
      this.incidentForm.controls.workTermFrom.valueChanges.pipe(untilDestroyed(this)).subscribe((value: number) => {
        if (value) {
          this.incidentForm.controls.workTermTo.setValidators([
            Validators.required,
            BgDatetimeValidator.minDate(minDate),
            BgDatetimeValidator.minDateOf(value),
          ]);
        } else {
          this.incidentForm.controls.workTermTo.setValidators(validators);
        }
        this.incidentForm.controls.workTermTo.reset(this.incidentForm.controls.workTermTo.value, {
          emitEvent: true,
          onlySelf: false,
        });
      });
      this.incidentForm.controls.workTermTo.setErrors(null);
    }

    /** Добавляем работу с комментарием для интернет портала */
    if (this.needShowInternetPortalComment) {
      this.currentIPComments = [
        ...(((this.model.parentEventId as IEmergencyEventDto)?.appealDetail as IAppealDetailDto)?.comments ?? []),
      ];
    }

    /** Получение координат и зума для МО */
    this.miniMapCenter = this.getMunicipalCoordinates();
    this.miniMapZoom = parseInt(this.getMunicipal().zoom, 10);

    /** Подписка на изменения фактического адреса, для дальнейшего получения координат */
    this.incidentForm.controls['addressFact'].valueChanges
      .pipe(debounceTime(1500), takeUntil(this.ngUnsubscribe))
      .subscribe((val: GarFindFlatResponseElement) => {
        /** проверяю, если есть координаты в установленном значение значит пиши их в контрол*/
        if (val.latitude && val.longitude) {
          this.incidentForm.controls['coordinates'].setValue(`${val.latitude}, ${val.longitude}`);
        }
      });

    this.incidentForm.controls['coordinates'].valueChanges
      .pipe(debounceTime(1500), takeUntil(this.ngUnsubscribe))
      .subscribe((val: string) => {
        this.redrawEventMarker(val);
      });

    this.showLinkToJkhObjectMonitoring(Boolean(this.model.jkhObject));
    this.setMonitoringObjectId(this.model.jkhObject);

    this.incidentForm.controls['jkhObject'].valueChanges
      .pipe(debounceTime(1500), takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.showLinkToJkhObjectMonitoring(Boolean(value));
        this.setMonitoringObjectId(value);
        this.setAddressObservableForLink();
      });

    this.initReactPlan();

    const markerCoordinates = new Coordinates(this.model.mapMarkerCoordinate || this.model.coordinates);
    if (markerCoordinates.isValid()) {
      this.defaultMarkerCoordinate = markerCoordinates.toArray();
    }

    this.optionsResponsibleUser.query = { isActive: true, organizationId: this.model.organization };

    this.updateForm();

    this.ngMasterValue = new BehaviorSubject<IAnyObject>(this.incidentForm.getRawValue());
    this.isDirty$ = dirtyCheck(this.incidentForm, this.ngMasterValue.asObservable());
  }

  /** @ignore */
  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Установка маркера
   * @param miniMapEvent событие
   */
  public updateCoordinates(miniMapEvent: IMiniMapMarkerPositionEvent) {
    const markerCoordinates = new Coordinates(this.incidentForm.controls['mapMarkerCoordinate'].value);
    const newMarkerCoordinates = new Coordinates(miniMapEvent?.coordinates[0], miniMapEvent?.coordinates[1]);
    if (!markerCoordinates.equal(newMarkerCoordinates)) {
      this.incidentForm.controls['mapMarkerCoordinate'].setValue(newMarkerCoordinates.toString());
      this.redrawEventMarker(this.incidentForm.controls['mapMarkerCoordinate'].value);
      this.model.mapMarkerCoordinate = this.incidentForm.controls['mapMarkerCoordinate'].value;
    }
  }

  /**
   * Получение данных из формы и сохранение в модель
   * @param action - действие которое выполняется на форме
   */
  public getModelData(action: string) {
    if (!this.model.id) {
      this.model.timeCreate = dayjs().unix() * 1000;
      this.model.number = `i-${dayjs(this.model.timeCreate).format('YYYYMMDD-HHmmss')}`;
      this.model.organization = this.settings.currentUser.organizationId.id;
      this.model.createdBy = this.settings.currentUser.id;
    }
    /** ----- MAIN ----- **/
    this.model.byCoordinates = this.incidentForm.controls['byCoordinates'].value;
    this.model.addressFact = this.model.byCoordinates
      ? this.addressByCoordinates
      : this.incidentForm.controls['addressFact'].value;
    this.model.moId = <string>this.moIdComponent.value;
    this.model.districtId = <string>this.districtIdComponent.value;
    this.model.detailsFact = this.incidentForm.controls['detailsFact'].value;
    this.model.incidentTypeId = this.incidentForm.controls['incidentTypeId'].value;
    this.model.ksipDetailsId = this.incidentForm.controls['ksipDetailsId'].value;
    this.model.comment = this.incidentForm.controls['comment'].value;
    this.model.description = this.incidentForm.controls['description'].value;
    this.model.responsible = this.incidentForm.controls['responsibleUser'].value;
    this.model.resolution = this.model.resolution || <IEmergencyResolutionDto>{};
    this.model.urgently = this.incidentForm.controls['urgently'].value;
    this.model.resolution.danger = this.incidentForm.controls['danger'].value;
    this.model.resolution.dangerOrg = this.incidentForm.controls['dangerOrg'].value;
    this.model.supervisedByCuks = this.incidentForm.controls['supervisedByCuks'].value;
    /** ----- PLACEMENT ----- **/
    this.model.coordinates = this.incidentForm.controls['coordinates'].value;
    this.model.ksipPlaceDescription = this.incidentForm.controls['ksipPlaceDescription'].value;
    /** ----- ADDITIONAL ----- **/
    this.model.resolution.report = this.incidentForm.controls['report'].value;
    /** ----- LOOSES ----- **/
    this.model.resolution = {
      ...this.model.resolution,
      ...(this.incidentForm.controls['resolution'].value || <IEmergencyResolutionDto>{}),
    };
    /** ----- DOCS ----- **/
    this.model.documents = this.incidentForm.controls['documents'].value;

    this.model.totalRegistryPeopleHouse = this.incidentForm.controls['totalRegistryPeopleHouse'].value;
    this.model.totalTemporalPeopleHouse = this.incidentForm.controls['totalTemporalPeopleHouse'].value;
    this.model.individualOwnership = this.incidentForm.controls['individualOwnership'].value;
    this.model.jkhObject = this.incidentForm.controls['jkhObject'].value;
    this.model.favorableRestrictionObjects = this.incidentForm.controls['favorableRestrictionObjects'].value;
    this.model.affectedRestrictionObjects = this.incidentForm.controls['affectedRestrictionObjects'].value;
    this.model.resourceConstraintType = this.incidentForm.controls['resourceConstraintType'].value;
    this.model.relatedRestrictions = this.incidentForm.controls['relatedRestrictions'].value;
    this.model.jkhDirection = this.incidentForm.controls['jkhDirection'].value;
    this.model.consumerPowerCategoryId = this.incidentForm.controls['consumerPowerCategoryId'].value
      ? this.incidentForm.controls['consumerPowerCategoryId'].value
      : undefined;
    this.model.isSendingToJkhReform = this.incidentForm.controls['isSendingToJkhReform'].value;
    this.model.showOnPortal = this.incidentForm.controls['showOnPortal'].value;
    if (this.model.showOnPortal) {
      this.model.internetPortalDesc = this.incidentForm.controls['internetPortalDesc'].value;
    }

    if (this.needShowInternetPortalComment && this.incidentForm.controls['internetPortalComment'].value) {
      // Текущий статус
      const currentStatus = (<IDictionaryModelDto>(<ILifeCycleStepDto>this.model.lifeCycleStepId).status)?.id
        ? (<IDictionaryModelDto>(<ILifeCycleStepDto>this.model.lifeCycleStepId).status).id
        : <string>(<ILifeCycleStepDto>this.model.lifeCycleStepId).status;
      // Получение информации о шаге ЖЦ в который переходит происшествие
      const nextStep = (this.settings.lifeCycleStep[(<ILifeCycleStepDto>this.model.lifeCycleStepId).id].actions || []).find(
        (i: ILifeCycleStepActionDto) => i.name === action,
      )?.nextStep;
      const nextLifeCycleStep = nextStep
        ? this.settings.lifeCycleStep[nextStep]
        : <ILifeCycleStepDto>this.model.lifeCycleStepId;
      // Если отсутствует следующий шаг, то происшествие остается в текущем шаге
      const newStatus = !!<string>nextLifeCycleStep?.status
        ? this.settings.getDictionaryById(<string>nextLifeCycleStep.status)
        : this.settings.getDictionaryById(currentStatus);
      // Инициализация текущего списка комментариев для интернет портала
      ((this.model.parentEventId as IEmergencyEventDto)
        .appealDetail as IAppealDetailDto).comments = this.currentIPComments;
      // Добавление нового комментария для интернет портала
      ((this.model.parentEventId as IEmergencyEventDto).appealDetail as IAppealDetailDto).comments.push({
        lifeCycleStepId: {
          id: nextLifeCycleStep.id,
          name: nextLifeCycleStep.name,
          status: <IDictionaryModelDto>{
            id: nextLifeCycleStep.status,
            name: newStatus.name,
            sysname: newStatus.sysname,
          },
        },
        text: this.incidentForm.controls['internetPortalComment'].value,
        createTime: Date.now(),
        userId: this.settings.currentUser.id,
      });
    }
  }

  /** Обновление формы */
  public updateForm() {
    // Обновление заголовка формы
    this.generateHeaderBar();
    // Актуализация активности полей на форме
    this.attributes = (this.model.lifeCycleStepId as ILifeCycleStepDto).params;
  }

  /** Показывает ссылку на объект монитроинга */
  private showLinkToJkhObjectMonitoring(value: boolean): void {
    this.linkToJkhObjectVisible = this.isJhk && value;
  }

  /**
   * @ignore
   */
  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    localStorage.removeItem('isAddressBlockShow');
  }
}
