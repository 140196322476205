import { AfterViewInit, Component, forwardRef, HostBinding, Input, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { BaseComponent } from '@bg-front/core/components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';

import { RegistryPanelFilterHelper } from '../../models/classes';
import { RegistryPanelFilterOperationEnum } from '../../models/enums';
import { IAttributeFilterValue, IRegistryPanelEntityFilter } from '../../models/interfaces';

/**
 * Компонент отвечает за фильтр "Сущности" в реестре
 * С помощью набора установленных фильтров будет проиходить настройка реестра
 */
@UntilDestroy()
@Component({
  selector: 'bg-registry-panel-entity-filter',
  templateUrl: './registry-panel-entity-filter.component.html',
  styleUrls: ['./registry-panel-entity-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegistryPanelEntityFilterComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RegistryPanelEntityFilterComponent),
      multi: true,
    },
  ],
})
export class RegistryPanelEntityFilterComponent
  extends BaseComponent
  implements OnDestroy, AfterViewInit, ControlValueAccessor, Validator
{
  /** Счётчик для генерации id компоненты */
  public static nextId = 0;
  /** Аттрибуты */
  @Input() public attributes: IAttributeFilterValue[] = [];

  /** Установка id */
  @HostBinding() id = `registry-panel-entity-${RegistryPanelEntityFilterComponent.nextId}`;

  /** Флаг готовности компоненты */
  private isReady = false;

  /** Список операций для селекта */
  public operationsDict = RegistryPanelFilterHelper.operationsToArray();

  /** Форма */
  public filterForm: FormGroup = new FormGroup({
    property: new FormControl('', [Validators.required]),
    operation: new FormControl(RegistryPanelFilterOperationEnum.equal, [Validators.required]),
    value: new FormControl('', [Validators.required]),
  });

  /** @ignore */
  constructor() {
    super();
    RegistryPanelEntityFilterComponent.nextId++;
  }

  /** @ignore */
  public ngAfterViewInit(): void {
    this.isReady = true;
  }

  /** @ignore */
  public registerOnChange(fn: any): void {
    this.filterForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(fn);
  }

  /** @ignore */
  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /** @ignore */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public setDisabledState(isDisabled: boolean): void {}

  /** @ignore */
  public writeValue(val: IRegistryPanelEntityFilter): void {
    if (val) {
      this.filterForm.controls.property.setValue(val.property, { emitEvent: true });
      this.filterForm.controls.operation.setValue(val.operation ?? RegistryPanelFilterOperationEnum.equal, {
        emitEvent: true,
      });
      this.filterForm.controls.value.setValue(val.value, { emitEvent: true });
    }
  }

  /** Валидатор формы, для проброса состояния это формы в родительскую*/
  public validate(c: AbstractControl): ValidationErrors | null {
    if (this.isReady) {
      return this.filterForm.valid
        ? null
        : {
            registryFilter: {
              valid: false,
              message: 'Фильтр имеет некорректное значение',
            },
          };
    }

    return null;
  }

  /** Подписка на нажатия */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private propagateTouch = () => { return; };
}
