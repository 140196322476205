<ng-container [formGroup]="form" *ngIf="form">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzFor="coordinatesTarget">Координаты</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" nzSearch [nzAddOnAfter]="suffixIconButton">
            <input
              type="text"
              placeholder="Широта, Долгота"
              nz-input
              formControlName="coordinates"
              maxlength="50"
              [controlErrorAnchor]="coordinatesAnchor"
            />
          </nz-input-group>
          <ng-template controlErrorAnchor #coordinatesAnchor="controlErrorAnchor"></ng-template>
          <ng-template #suffixIconButton>
            <button
              nz-button
              nzSize="large"
              nzSearch
              nzType="primary"
              type="button"
              alt="Задать координаты"
              (click)="onClickSpecifyAddress()"
            >
              <i nz-icon nzType="aim"></i>
            </button>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="technologicalFireFormId">Форма пожара</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="technologicalFireFormId" nzSize="large" nzAllowClear="true">
            <nz-option [nzValue]="form.value" [nzLabel]="form.text" *ngFor="let form of fireForms" maxLen></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="fireFightingEquipmentId">Средства тушения пожара</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="fireFightingEquipmentId" nzSize="large" nzAllowClear="true">
            <nz-option
              [nzValue]="eqipment.value"
              [nzLabel]="eqipment.text"
              *ngFor="let eqipment of fireFightingEquipments$ | async"
              maxLen
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12" *ngIf="isRoundedForm">
      <nz-form-item>
        <nz-form-label nzFor="angleValue">Величина угла</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="degrees">
            <input nzSize="large" nz-input formControlName="angleValue" [controlErrorAnchor]="angleValueAnchor" />
          </nz-input-group>
          <ng-template controlErrorAnchor #angleValueAnchor="controlErrorAnchor"></ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <ng-container *ngIf="isRectangular">
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="smallerSide">Величина меньшей стороны</nz-form-label>
          <nz-form-control>
            <nz-input-group nzSize="large" [nzSuffix]="meters">
              <input nzSize="large" nz-input formControlName="smallerSide" [controlErrorAnchor]="smallerSideAnchor" />
            </nz-input-group>
            <ng-template controlErrorAnchor #smallerSideAnchor="controlErrorAnchor"></ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzFor="windDirection">Направление ветра</nz-form-label>
          <nz-form-control>
            <nz-input-group nzSize="large" [nzSuffix]="degrees">
              <input
                nzSize="large"
                nz-input
                formControlName="windDirection"
                [controlErrorAnchor]="windDirectionAnchor"
              />
            </nz-input-group>
            <ng-template controlErrorAnchor #windDirectionAnchor="controlErrorAnchor"></ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="directionsCount">Кол-во направлений распространения огня</nz-form-label>
          <nz-form-control>
            <nz-select formControlName="directionsCount" nzSize="large" nzAllowClear="true">
              <nz-option
                [nzValue]="direction.value"
                [nzLabel]="direction.text"
                *ngFor="let direction of directionsCounts"
                maxLen
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="fireExtinguishingWayId">Способы тушения пожара</nz-form-label>
          <nz-form-control>
            <nz-select formControlName="fireExtinguishingWayId" nzSize="large" nzAllowClear="true">
              <nz-option
                [nzValue]="way.value"
                [nzLabel]="way.text"
                *ngFor="let way of fireExtinguishingWays"
                maxLen
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #degrees> ° </ng-template>
<ng-template #meters> м </ng-template>
