import { Injectable } from '@angular/core';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  IAbstractServiceData,
  ISignificantObjectDto,
  IStorageSelectOptionsSort,
  ISurveyAnswerDto,
  ISurveyQuestionDto,
} from 'smart-city-types';

import { IDictionaryModel } from '../../models/interfaces';

/**
 * Сервис для взаимодействия с Категориями КСиП
 */
@Injectable({
  providedIn: 'root',
})
export class SurveyQuestionsService {
  constructor(private readonly rest: RestService, private readonly settings2: Settings2Service) {}

  /** Запрос списка Вопросов для опроса. По умолчанию только активные */
  public getAllSurveyQuestions(): Observable<ISurveyQuestionDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'SurveyQuestions',
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items as ISurveyQuestionDto[];
        }),
      );
  }

  /** Запрос Вопроса по Id */
  public getSurveyQuestionById(id: string): Observable<ISurveyQuestionDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'SurveyQuestions',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || [])[0] as ISurveyQuestionDto;
        }),
      );
  }

  /**
   * Удаление записи
   */
  public deleteSurveyQuestion(id: string): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: 'Admin' },
      entity: {
        name: 'SurveyQuestions',
        query: {
          id,
        },
      },
    });
  }

  /**
   * Сохранение модели
   * @param model модель
   */
  public save(model: ISurveyQuestionDto): Observable<IAbstractServiceData> {
    let obs: Observable<IAbstractServiceData>;

    if (model.id) {
      obs = this.rest.serviceRequest({
        action: 'update',
        service: { name: 'Admin' },
        entity: {
          name: 'SurveyQuestions',
          query: {
            id: model.id,
          },
        },
        data: model,
      });
    } else {
      obs = this.rest.serviceRequest({
        action: 'insert',
        service: { name: 'Admin' },
        entity: {
          name: 'SurveyQuestions',
        },
        data: model,
      });
    }
    return obs;
  }

  /** Запрос списка Вопросов по типу КСиП Id, ОКТМО и важным объектам */
  public getSurveyQuestions(
    id: string,
    oktmo: string = undefined,
    significantObjects: ISignificantObjectDto[] = [],
    answerData = undefined,
  ): Observable<ISurveyQuestionDto[]> {
    /** Результирующий набор найденных вопросов */
    let resultQuestions: ISurveyQuestionDto[] = [];

    /** Если не передан id КСиП, то ни один вопрос не будет соответствовать правилам выборки */
    if (!id) {
      return of(resultQuestions);
    }

    /** Запрос для определения, что вопрос содержит переданный КСиП id */
    const queryQuestionIncludesIncidentType = {
      $expr: {
        $in: ['$incidentTypes', id],
      },
    };

    /** Запросы для поиска по МО */
    let queryQuestionIncludesMO;

    /** Запрос для определения, что вопрос содержит переданный МО (по ОКТМО) */
    queryQuestionIncludesMO = {
      $expr: {
        $or: [],
      },
    };

    /** Определяем какой из МО соответствует переданному ОКТМО из адреса и входит в МО вопроса */
    const allMo = this.settings2.allMo;
    if (allMo.length) {
      for (const mo of allMo) {
        queryQuestionIncludesMO.$expr.$or.push({
          $expr: {
            $and: [{ $expr: { $eq: [mo.OKTMO, oktmo] } }, { $expr: { $in: ['$mo', mo.id] } }],
          },
        });
      }
    } else {
      queryQuestionIncludesMO = false;
    }

    /** Запрос на вопросы без МО */
    const queryQuestionEmptyMO = {
      $expr: {
        $or: [{ $expr: { $eq: ['$mo', []] } }, { $expr: { $eq: ['$mo', null] } }],
      },
    };

    /** Запросы для поиска по Важным объектам */
    let queryQuestionIncludesSO;

    /** Запрос для определения, что вопрос содержит переданные Важные объекты */
    queryQuestionIncludesSO = {
      $expr: {
        $or: [],
      },
    };

    if (significantObjects.length) {
      for (const so of significantObjects) {
        queryQuestionIncludesSO.$expr.$or.push({
          $expr: {
            $in: ['$significantObjects', so.id],
          },
        });
      }
    } else {
      queryQuestionIncludesSO = false;
    }

    /** Запрос на вопросы без Важных объектов */
    const queryQuestionEmptySO = {
      $expr: {
        $or: [{ $expr: { $eq: ['$significantObjects', []] } }, { $expr: { $eq: ['$significantObjects', null] } }],
      },
    };

    const attributes = [
      'id',
      'text',
      'incidentTypes',
      'mo',
      'significantObjects',
      'answerType',
      'answerType.id',
      'answerType.sysname',
      'canMiss',
      'optionOther',
      'answerOptions',
      'questionConditions',
      'creationTime',
      'order',
    ];

    const sort: IStorageSelectOptionsSort[] = [
      {
        field: 'creationTime',
        direction: 'asc',
      },
      {
        field: 'order',
        direction: 'asc',
      },
    ];

    /** Вопросы c МО */
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          attributes,
          sort,
          name: 'SurveyQuestions',
          query: {
            $expr: {
              $and: [queryQuestionIncludesIncidentType, queryQuestionIncludesMO],
            },
          },
        },
      })
      .pipe(
        /** Вопросы без МО */
        switchMap((response: IAbstractServiceData) => {
          /** Если нашли вопросы с соответствующими МО, то возвращаем этот результат */
          if (response?.data?.items.length) {
            return of(response);
          }
          /** Если не нашли вопросы с соответствующими МО, ищем вопросы без МО */
          return this.rest.serviceRequest({
            action: 'select',
            service: { name: 'Admin' },
            entity: {
              attributes,
              sort,
              name: 'SurveyQuestions',
              query: {
                $expr: {
                  $and: [queryQuestionIncludesIncidentType, queryQuestionEmptyMO],
                },
              },
            },
          });
        }),
        /** Вопросы с соответсвенными Важными объектами */
        switchMap((response: IAbstractServiceData) => {
          resultQuestions = response?.data?.items;

          /** если на предыдущем шаге ничего не найдено, то прекращаем поиск с пустым набором вопросов */
          if (!response?.data?.items.length) {
            return of([]);
          }

          return this.rest
            .serviceRequest({
              action: 'select',
              service: { name: 'Admin' },
              entity: {
                attributes,
                sort,
                name: 'SurveyQuestions',
                query: {
                  $expr: {
                    $and: [queryQuestionIncludesIncidentType, queryQuestionIncludesSO],
                  },
                },
              },
            })
            .pipe(
              /** Вопросы без важных объектов */
              switchMap((response: IAbstractServiceData) => {
                /** Если нашли вопросы с соответствующими Важными объектами, то возвращаем этот результат */
                if (response?.data?.items.length) {
                  return of(response);
                }
                /** Если не нашли вопросы с соответствующими Важными объектами, ищем вопросы без Важных объектов */
                return this.rest.serviceRequest({
                  action: 'select',
                  service: { name: 'Admin' },
                  entity: {
                    attributes,
                    sort,
                    name: 'SurveyQuestions',
                    query: {
                      $expr: {
                        $and: [queryQuestionIncludesIncidentType, queryQuestionEmptySO],
                      },
                    },
                  },
                });
              }),
              map((response: IAbstractServiceData) => {
                return response?.data?.items as ISurveyQuestionDto[];
              }),
              map((items: ISurveyQuestionDto[]) => {
                const resultQuestionsIds = this.getIdsFromItems(resultQuestions);
                return items.filter((item) => {
                  return resultQuestionsIds.includes(item.id);
                });
              }),
              map((items: ISurveyQuestionDto[]) => {
                return items.map((item) => {
                  return {
                    id: item.id,
                    text: item.text,
                    incidentTypes: item.incidentTypes,
                    mo: item.mo,
                    significantObjects: item.significantObjects,
                    answerType:
                      typeof item.answerType === 'string'
                        ? (item.answerType as string)
                        : ({
                            id: item.answerType.id,
                            sysname: item.answerType.sysname,
                          } as IDictionaryModel),
                    canMiss: item.canMiss,
                    optionOther: item.optionOther,
                    answerOptions: item.answerOptions,
                    questionConditions: item.questionConditions,
                  };
                });
              }),
              /**
               * Фильтруем вопросы, которые не имеют условий перехода (например,
               * первоначальный поиск) или с условиями, но при ответе.
               */
              map((items: ISurveyQuestionDto[]) => {
                return items.filter((item) => {
                  return !item.questionConditions || !item.questionConditions?.length
                    ? true
                    : answerData
                    ? true
                    : false;
                });
              }),
              /** Фильтруем вопросы, удовлетворяющие условиям перехода по вопросам */
              map((items: ISurveyQuestionDto[]) => {
                return !answerData
                  ? items
                  : items.filter((item: ISurveyQuestionDto) => {
                      let resultByCondition: boolean = false;
                      for (const questionCondition of item.questionConditions) {
                        /** Если id нового вопроса и id вопроса не совпали, то этот вопрос не подходит */
                        if (questionCondition.question !== answerData.question?.id) {
                          continue;
                        }
                        /** Если точное совпадение ответа не было, то этот вопрос не подходит */
                        if (
                          questionCondition.matchType === 'exact' &&
                          !answerData.answer?.includes(questionCondition.answerOption)
                        ) {
                          continue;
                        }
                        resultByCondition = true;
                      }
                      return resultByCondition;
                    });
              }),
            );
        }),
      );
  }

  /** Запрос списка Ответов */
  public getSurveyAnswers(eventId: string): Observable<ISurveyAnswerDto[]> {
    /** Если не передан id КСиП, то ни один ответ не будет соответствовать правилам выборки */
    if (!eventId) {
      return of([]);
    }

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'SurveyAnswers',
          attributes: [
            'id',
            'eventId',
            'questionId.id',
            'questionId.text',
            'questionId.answerType',
            'questionId.answerType.id',
            'questionId.answerType.sysname',
            'questionId.canMiss',
            'questionId.optionOther',
            'questionId.answerOptions',
            'questionId.questionConditions',
            'answer',
            'otherAnswer',
            'creationTime',
            'order',
          ],
          sort: [
            {
              field: 'creationTime',
              direction: 'desc',
            },
            {
              field: 'order',
              direction: 'desc',
            },
          ],
          query: {
            eventId,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          const result: ISurveyAnswerDto[] = response?.data?.items.map((item) => {
            return {
              id: item.id,
              eventId: item.eventId,
              question: {
                id: item.questionId.id,
                text: item.questionId.text,
                answerType: {
                  id: item['questionId.answerType.id'],
                  sysname: item['questionId.answerType.sysname'],
                },
                canMiss: item.questionId.canMiss,
                optionOther: item.questionId.optionOther,
                answerOptions: item.questionId.answerOptions,
                questionConditions: item.questionId.questionConditions,
              },
              answer: item.answer,
              otherAnswer: item.otherAnswer,
              order: item.order,
            };
          });
          return result;
        }),
      );
  }

  /** Запрос Типа ответа на вопрос по Id */
  public getAnswerTypeById(id: string): Observable<IDictionaryModel> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Dictionary',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || [])[0] as IDictionaryModel;
        }),
      );
  }

  /**
   * Сохранение нового ответа/ответов
   * @param model модель
   */
  public insertAnswers(models: ISurveyAnswerDto[]): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'insert',
      service: { name: 'Admin' },
      entity: {
        name: 'SurveyAnswers',
      },
      data: models,
    });
  }

  /**
   * Изменение старого ответа
   * @param model модель
   */
  public updateAnswers(models: ISurveyAnswerDto[]): Observable<IAbstractServiceData[]> {
    const observables: Observable<IAbstractServiceData>[] = [];
    if (!models.length) {
      return of([]);
    }

    for (const model of models) {
      observables.push(
        this.rest.serviceRequest({
          action: 'update',
          service: { name: 'Admin' },
          entity: {
            name: 'SurveyAnswers',
            query: {
              id: model.id,
            },
          },
          data: model,
        }),
      );
    }

    return forkJoin(observables);
  }

  /** Вспомогательная функция для получения списка id объектов из списка */
  public getIdsFromItems(items): string[] {
    return items.map((item) => {
      return item.id;
    });
  }
}
