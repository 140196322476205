import { Injectable } from '@angular/core';
import { ILifeCycle2Dto, Settings2Service, RestService } from '@smart-city/core/services';
import { IAnyObject, ILifeCycleStepDto, IAbstractServiceData } from 'smart-city-types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LifeCycleService {
  constructor(
    private readonly settings: Settings2Service,
    private readonly rest: RestService,
  ) {}

  /**
   * Получение ЖЦ по параметрам
   * @param docType Тип происшествия @required
   * @param sourceType Источник
   * @param organizationTypeParam Параметры организации
   * @param type Тип ЖЦ
   */
  public getLifeCycleByParams(
    docType: string,
    sourceType?: string,
    organizationTypeParam?: string,
    type?: string,
  ): ILifeCycle2Dto {
    for (const lifeCycleId in this.settings.lifeCycle) {
      if (
        (this.settings.lifeCycle[lifeCycleId].docType === docType || docType === undefined) &&
        (this.settings.lifeCycle[lifeCycleId].type === type || type === undefined) &&
        (this.settings.lifeCycle[lifeCycleId].sourceType === sourceType || sourceType === undefined) &&
        (this.settings.lifeCycle[lifeCycleId].orgTypeParam === organizationTypeParam ||
          organizationTypeParam === undefined)
      ) {
        return this.settings.lifeCycle[lifeCycleId];
      }
    }

    return undefined;
  }

  /** Получение уникальных id состояний из всех шагов в ЖЦ
   * @param type - тип документа
   * @return
   */
  public getUniqStatusesFromAllLifecycleByType(type: string): string[] {
    const docTypeId = this.settings.getDictionaryByTypeAndSysName('docType', type).id;
    const lifeCycleList: IAnyObject[] = Object.values(this.settings.lifeCycle);
    const allIdsList: string[] = (lifeCycleList
      .filter(({ docType }) => docType === docTypeId)
      .map(({ steps }) => {
        return steps;
      })
      .filter((step) => {
        return step;
      }) as IAnyObject)
      .flat()
      .map((st) => st.status);
    return [...new Set(allIdsList)];
  }

  /** Получение шага ЖЦ по query
   * @param query - запрос
   * @param attributes - аттрибуты
   * */
  public getLifeCycleStepByQuery(query: IAnyObject, attributes?: string[]): Observable<ILifeCycleStepDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'LifeCycleStep',
          query,
          attributes: attributes || [],
        },
      }).pipe(
        map((data: IAbstractServiceData) => {
          return (data.data.items || [])[0];
        }),
      );
  }
}
