<div
  class="all-emergency-registry"
  id="all-emergency-registry"
  [@expandedPanel]="state"
  (@expandedPanel.done)="onAnimationEventDone($event)"
>
  <div class="bookmark" [class.hide]="state !== 'hide'" (click)="clickBookmark()"></div>
  <nw-header [options]="headerOptions" (clickButton)="clickHeaderButton($event)"></nw-header>
  <mat-tab-group class="sc-tab-group" [disableRipple]="true" (selectedTabChange)="selectedTabChange($event)">
    <mat-tab [label]="'Происшествия'">
      <div class="emergency-tab-content">
        <div class="filter-area">
          <div class="search-container">
             <sc-input [options]="incidentsSearchOption"></sc-input>
          </div>
          <div class="filter-container" [formGroup]="formGroup">
            <div class="buttons-filter">
              <div class="buttons-filter__button-panel">
                <button
                    class="buttons-filter__element"
                    [ngClass]="{ 'buttons-filter__element_active': getState('new') }"
                    (click)="selectFilter('new')"
                >
                  Новый
                </button>
                <button
                    class="buttons-filter__element "
                    [ngClass]="{ 'buttons-filter__element_active': getState('inWork') }"
                    (click)="selectFilter('inWork')"
                >
                  В работе
                </button>
                <button
                    class="buttons-filter__element"
                    [ngClass]="{ 'buttons-filter__element_active': getState('finished') }"
                    (click)="selectFilter('finished')"
                >
                  Завершены
                </button>
                <button class="buttons-filter__icon" *ngIf="!showAllFilters">
                  <i class="sc-icon-three-dots"></i>
                </button>
              </div>
              <div class="buttons-filter__button-icon">
                <button
                    class="buttons-filter__icon"
                    [ngClass]="{ 'buttons-filter__icon_active': showAllFilters }"
                    (click)="hide()"
                >
                  <i class="sc-icon-filter"></i>
                </button>
              </div>
            </div>
            <div class="buttons-filter" *ngIf="showAllFilters">
              <div class="buttons-filter__button-panel">
                <button
                    class="buttons-filter__element"
                    [ngClass]="{ 'buttons-filter__element_active': getState('close') }"
                    (click)="selectFilter('close')"
                >
                  Закрыты
                </button>
                <button
                    class="buttons-filter__element"
                    [ngClass]="{ 'buttons-filter__element_active': getState('sentToCuks') }"
                    (click)="selectFilter('sentToCuks')"
                >
                  Переданы в ЦУКС
                </button>
                <button
                    class="buttons-filter__element"
                    [ngClass]="{ 'buttons-filter__element_active': getState('createdByMe') }"
                    (click)="selectFilter('createdByMe')"
                >
                  Созданы мной
                </button>
              </div>
            </div>

            <div class="inputs-filter" *ngIf="showAllFilters">
              <sc-select [options]="optionsMO"></sc-select>
              <sc-select [options]="optionsIncidentTypes"></sc-select>
              <sc-select [options]="optionsSourceType"></sc-select>
              <div class="inputs-filter__datepicker-element">
                <bg-datetime
                    label="Время регистрации с"
                    formControlName="dateTimeFrom"
                    [defaultHour]="0"
                    [defaultMinute]="0"
                    [defaultSecond]="0"
                ></bg-datetime>
                <bg-datetime
                    label="Время регистрации по"
                    formControlName="dateTimeTo"
                    [defaultHour]="23"
                    [defaultMinute]="59"
                    [defaultSecond]="59"
                ></bg-datetime>
              </div>
            </div>
          </div>
        </div>
        <div class="emergency-scroll-container">
          <scrollable-container #allIncidentsScroll [options]="optionsIncidents"></scrollable-container>
        </div>
      </div>
    </mat-tab>

    <mat-tab [label]="'События'">
      <div class="emergency-tab-content">
        <div class="filter-area">
          <div class="search-container">
            <sc-input [options]="eventsSearchOption"></sc-input>
          </div>
          <div class="filter-container" [formGroup]="formGroupEvents">
            <div class="buttons-filter">
              <div class="buttons-filter__button-panel">
                <button
                    class="buttons-filter__element"
                    [ngClass]="{ 'buttons-filter__element_active': getState('new', true) }"
                    (click)="selectFilterEvents('new')"
                >
                  Новые
                </button>
                <button
                  class="buttons-filter__element "
                  [ngClass]="{ 'buttons-filter__element_active': getState('closeWithoutReaction', true) }"
                  (click)="selectFilterEvents('closeWithoutReaction')"
                >
                  Закрыт без реагирования
                </button>
                <button
                    class="buttons-filter__element "
                    [ngClass]="{ 'buttons-filter__element_active': getState('createdByMe', true) }"
                    (click)="selectFilterEvents('createdByMe')"
                >
                  Созданы мной
                </button>
                <button class="buttons-filter__icon" *ngIf="!showAllFiltersEvents">
                  <i class="sc-icon-three-dots"></i>
                </button>
              </div>
              <div class="buttons-filter__button-icon">
                <button
                    class="buttons-filter__icon"
                    [ngClass]="{ 'buttons-filter__icon_active': showAllFiltersEvents }"
                    (click)="hideFilterEvents()"
                >
                  <i class="sc-icon-filter"></i>
                </button>
              </div>
            </div>
            <div class="buttons-filter" *ngIf="showAllFiltersEvents">
              <div class="buttons-filter__button-panel">
                <button
                    class="buttons-filter__element"
                    [ngClass]="{ 'buttons-filter__element_active': getState('currentDeclarant', true) }"
                    (click)="selectFilterEvents('currentDeclarant')"
                >
                  Текущий заявитель
                </button>
              </div>
            </div>
            <div class="inputs-filter" *ngIf="showAllFiltersEvents">
              <div class="inputs-filter__datepicker-element">
                <bg-datetime
                    label="Время регистрации с"
                    formControlName="dateTimeFrom"
                    [defaultHour]="0"
                    [defaultMinute]="0"
                    [defaultSecond]="0"
                ></bg-datetime>
                <bg-datetime
                    label="Время регистрации по"
                    formControlName="dateTimeTo"
                    [defaultHour]="23"
                    [defaultMinute]="59"
                    [defaultSecond]="59"
                ></bg-datetime>
              </div>
            </div>
          </div>
        </div>
        <div class="emergency-scroll-container">
          <scrollable-container #allEventsScroll [options]="optionsEvents"></scrollable-container>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
