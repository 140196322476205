import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Validators } from '@angular/forms';
import { ControlsOf, FormArray, FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import {
  IChemicalTypeForSelect,
  ISignificantObjectForEdit,
  ISignificantObjectsDto,
  IStoredChemicalForForm,
  IFireFightingCardsPlansDto,
} from '../../models/interfaces';
import { formatPhoneDb } from '@smart-city/core/utils';
import { SignificantObjectsService } from '../../services';
import { Settings2Service } from '@smart-city/core/services';
import { catchError, map, pluck, switchMap } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { Uuid } from '@smart-city/core/utils';
import {
  IAbstractServiceData,
  IAnyObject,
  IDictionaryModelDto,
  IFiasValueDto,
  IStoredChemicalDto,
} from 'smart-city-types';
import { MultiFileService } from '@bg-front/core/services';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { coordinatesValidator } from '@bg-front/core/validators';
import { IFileUpload } from '@bg-front/core/models/interfaces';
import { StoredChemicalsService } from '@bg-front/stored-chemicals/services';
import { IFileInfo } from '@bg-front/core/models/interfaces/file-info.interface';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ISignificantObjectCategory } from '@bg-front/core/models/interfaces';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import { GarFindFlatResponseElement } from '@bg-front/core';

/** Компонент формы редактирования важного объекта
 *  Библиотечная реализация и реализация в Бурятии совпадает за исключением функционала ПТП/КТП
 */
@UntilDestroy()
@Component({
  selector: 'bg-significant-objects-edit-form',
  templateUrl: './significant-objects-edit-form.component.html',
  styleUrls: ['./significant-objects-edit-form.component.scss'],
})
export class SignificantObjectsEditFormComponent extends BaseComponent implements OnInit {
  /** RegExp для валидации координат */
  public coordinatesPattern: RegExp = /(-?\d{1,2}[.]\d+)[,][ ]+(-?\d{1,3}[.]\d+)/;

  /** Данные для селекта Категория */
  public categoriesForSelect$ = this.service.getCategoriesForSelect();

  /** Данные для селекта Мо */
  public moForSelect$ = this.service.getMoForSelect();

  /** Данные для селекта Организаций */
  public organizationsForSelect$ = this.service.getOrganizationsForSelect();

  /** Данные для селекта Тип */
  public typeForSelect = this.settings.getDictForSelect('significantObjectTypes');

  /** Данные для селекта Категория электроснабжения потребителя */
  public powerSupplyCategoryForSelect = this.settings.getDictForSelect('powerSupplyConsumerCategory');

  /** Данные для селекта Состояние */
  public stateForSelect = this.settings.getDictForSelect('significantObjectStatus');

  /** Данные для селекта Риск для прогнозирования */
  public projectedRiskForSelect = this.settings.getDictForSelect('projectedRisks');

  /** Опции автокомплита ФИАС */
  public options: IFiasValueDto[];

  /** Событие при закрытии формы */
  public closeAction: 'save' | 'delete';

  /** Модель */
  public model: ISignificantObjectsDto;

  /** Форма */
  public form: FormGroup<ControlsOf<ISignificantObjectForEdit>>;

  /** Флаг того, является ли данный объект химически опасным */
  public isChemicalObject: boolean = false;

  /** Ожидание загрузки данных */
  public isLoading: boolean;

  /** Заголовок формы */
  public title: string = 'Создание важного объекта';

  /** Карточки/план пожаротушения */
  public fireFightingCardPlans: IFireFightingCardsPlansDto[];

  /** Подписка на проверку изменений в форме */
  public isDirty$: Observable<boolean> = of(false);

  /** Эталонное значение формы */
  private ngMasterValue: BehaviorSubject<IAnyObject>;

  /** Словарь названий обязательных полей вкладки Описание */
  public descriptionRequiredControlsMap: Map<string, string> = new Map<string, string>([
    ['category', 'Категория'],
    ['name', 'Наименование'],
    ['shortName', 'Краткое наименование'],
    ['mo', 'Муниципальное образование'],
    ['state', 'Состояние'],
    ['phone', 'Контактный телефон'],
  ]);

  /** Словарь названий обязательных полей вкладки Параметры */
  public paramsRequiredControlsMap: Map<string, string> = new Map<string, string>([
    ['storedChemicals', 'Хранимые АХОВ'],
  ]);

  /** @ignore */
  constructor(
    notificationService: NzNotificationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly service: SignificantObjectsService,
    private readonly settings: Settings2Service,
    private readonly fb: FormBuilder,
    private readonly multiFileService: MultiFileService,
    private readonly storedChemicalsService: StoredChemicalsService,
    private readonly modalService: NzModalService,
  ) {
    super(notificationService);
    this.form = new FormGroup<ControlsOf<ISignificantObjectForEdit>>({
      category: new FormControl(null, [Validators.required]),
      type: new FormControl(),
      name: new FormControl(null, [Validators.required]),
      shortName: new FormControl(null, [Validators.required]),
      mo: new FormControl(null, [Validators.required]),
      address: new FormControl(),
      corp: new FormControl(),
      coordinates: new FormControl(null, [Validators.pattern(this.coordinatesPattern), coordinatesValidator()]),
      level: new FormControl(),
      powerSupplyConsumerCategory: new FormControl(),
      state: new FormControl(null, [Validators.required]),
      responsible: new FormControl(),
      phone: new FormControl(null, [Validators.required]),
      dutyServices: new FormControl(),
      comment: new FormControl(),
      projectedRiskIds: new FormControl<string[]>(null),
      storedChemicals: new FormArray<IStoredChemicalForForm>([]),
      documents: new FormControl<IFileUpload[]>(null),
      organizationId: new FormControl(),
    });
  }

  /** @ignore */
  public ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.model = undefined;
          this.form.reset();
          const objectId = params.get('id');

          if (!objectId) {
            return of({});
          }

          // Если ID невалидный не выполнять поиск и передать значение, что ничего найдено
          if (!Uuid.isValid(objectId)) {
            return of(undefined);
          }

          this.isLoading = true;
          this.title = 'Редактирование важного объекта';
          return this.service.getById(objectId, [
            'category.id',
            'category.name',
            'category.objectTypes',
            'type',
            'name',
            'shortName',
            'mo',
            'address',
            'corp',
            'coordinates',
            'powerSupplyConsumerCategory',
            'state',
            'responsible',
            'phone',
            'dutyServices',
            'comment',
            'projectedRiskIds',
            'documents',
            'organizationId',
          ]);
        }),
        switchMap((object: ISignificantObjectsDto) => {
          this.isLoading = false;
          if (!object) {
            this.notificationService.error('Ошибка', 'Запись не найдена');
            this.close();
          }

          this.model = object;

          return <Observable<IFileUpload[]>>this.multiFileService.getFilesFromSfs(this.model.documents);
        }),
        switchMap((documents: IFileUpload[]) => {
          this.form.patchValue({
            ...this.model,
            address: this.model.address?.fullText,
            level: this.model.address?.level,
            documents,
          });

          // Инициализация списка типов важного объекта, в зависимости от его категории
          if (!(<string>this.model.category)) {
            this.form.controls.type.disable();
          } else {
            this.typeForSelect = this.settings
              .getDictForSelect('significantObjectTypes')
              .filter((item: IScSelectItem) =>
                ((<ISignificantObjectCategory>this.form.controls.category.value).objectTypes || []).includes(item.id),
              );
          }

          // Убрал пока отсутствует ПТП/КТП
          // this.form.controls.organizationId.valueChanges.pipe(
          //   switchMap((value: IOrganization | string | null) =>
          //     value ? this.service.getFireFightingCardPlan(<string>value) : of(null)
          //   ),
          //   untilDestroyed(this)
          // ).subscribe((value: IFireFightingCardsPlansDto[]) => this.fireFightingCardPlans = value);
          // this.form.controls.organizationId.updateValueAndValidity();

          /** Подписка на изменение категории для актуализации данных в селекте тип */
          this.form.controls.category.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((value: string | ISignificantObjectCategory) => {
              this.form.controls.type.setValue(null);
              if (!value) {
                this.form.controls.type.disable();
                return;
              }
              this.form.controls.type.enable();
              this.typeForSelect = this.settings
                .getDictForSelect('significantObjectTypes')
                .filter((item: IScSelectItem) =>
                  ((<ISignificantObjectCategory>value).objectTypes || []).includes(item.id),
                );
            });

          this.form.controls.type.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((value: string | IDictionaryModelDto) => {
              this.isChemicalObject =
                this.settings.getDictionaryById(<string>value)?.sysname?.trim() === 'chemicallyHazard';
              // Для валидации
              if (this.isChemicalObject) {
                this.form.controls.storedChemicals.enable();
              } else {
                this.form.controls.storedChemicals.disable();
              }
            });

          this.form.controls.address.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((value: string | GarFindFlatResponseElement) => {
              if (
                (value as GarFindFlatResponseElement)?.level &&
                !this.form.controls.level.value &&
                this.form.controls.level.value !== 0
              ) {
                this.form.controls.level.setValue(+(value as GarFindFlatResponseElement).level);
              }
            });

          // Является ли текущее значение типа ВО, химически опасным
          this.isChemicalObject =
            this.settings.getDictionaryById(<string>this.model.type)?.sysname?.trim() === 'chemicallyHazard';
          // Для валидации
          if (this.isChemicalObject) {
            this.form.controls.storedChemicals.enable();
          } else {
            this.form.controls.storedChemicals.disable();
          }

          return !!this.isChemicalObject
            ? // Если объект химически опасный запросить хранимые АХОВ для него
              this.storedChemicalsService.getStoredChemicalsForSignificantObject(this.model.id)
            : // Если объект не химически опасный вернуть пустое значение
              of(null);
        }),
        map((storedChemicals: IStoredChemicalDto[]) =>
          storedChemicals && !!storedChemicals.length
            ? storedChemicals
            : // Если на предыдущем шаге ничего не нашлось, то сделать заготовку под массив хранимых АХОВ
              [
                {
                  id: undefined,
                  chemicalType: null,
                  aggregationState: null,
                  chemicalAmount: 0,
                  containerPressure: null,
                  containerType: null,
                  containerHeight: null,
                },
              ],
        ),
        catchError((err: Error) => {
          this.isLoading = false;
          return this.catchErrorFn<IStoredChemicalDto[]>(err, 'Ошибка при загрузке данных объекта');
        }),
        untilDestroyed(this),
      )
      .subscribe((storedChemicals: IStoredChemicalDto[]) => {
        storedChemicals?.forEach((item: IStoredChemicalDto) => {
          const storedChemical: IStoredChemicalForForm = {
            id: item.id,
            chemicalType: !!item.chemicalType
              ? new FormControl<IChemicalTypeForSelect>({
                  id: <string>item.chemicalType,
                  aggregationStateName: this.settings.getDictionaryById(item.aggregationState)?.name || '',
                  aggregationStateSysName: this.settings.getDictionaryById(item.aggregationState)?.sysname,
                })
              : null,
            chemicalAmount: item.chemicalAmount,
            containerPressure: item.containerPressure,
            containerType: item.containerType,
            containerHeight: item.containerHeight,
          };
          this.form.controls.storedChemicals.setControl(
            this.form.controls.storedChemicals.controls.length,
            this.fb.group(storedChemical),
          );
        });
        this.ngMasterValue = new BehaviorSubject<IAnyObject>(this.form.getRawValue());
        this.isDirty$ = dirtyCheck(this.form, this.ngMasterValue.asObservable());
      });
  }

  /** Сохранить запись */
  public save(): void {
    this.form.controls.storedChemicals.controls.forEach(
      (storedChemical: FormGroup<ControlsOf<IStoredChemicalForForm>>) =>
        storedChemical.controls.chemicalType.updateValueAndValidity(),
    );

    if (this.form.invalid) {
      const errorLabel: string = [
        this.generateTabErrorsString('Описание', this.descriptionRequiredControlsMap),
        this.generateTabErrorsString('Параметры', this.paramsRequiredControlsMap),
      ].join(' ');

      this.notificationService.error('Ошибка!', errorLabel, {
        nzPlacement: 'bottomRight',
        nzDuration: 0,
      });
    } else {
      const addressControlValue = this.form.get('address')?.value;
      const address: GarFindFlatResponseElement =
        typeof addressControlValue === 'string' ? { fullText: addressControlValue } : addressControlValue;
      address.level = this.form.get('level')?.value ?? address?.level;
      this.multiFileService
        .saveFilesToSfs(this.form.value.documents)
        .pipe(
          switchMap((documents: IFileInfo[]) => {
            return this.service.save({
              ...this.form.value,
              category: (<ISignificantObjectCategory>this.form.controls.category.value).id,
              documents: documents,
              id: this.model.id,
              address,
              phone: formatPhoneDb(this.form.get('phone')?.value),
            });
          }),
          // В случае создания записи результат вернется именно в таком виде. Необходимо извлечь информацию об ID
          // важного объекта. Для дальнейшего использования в хранимых АХОВ.
          // В случае редактирования результат будет в другом виде, но ID важного используется из модели.
          pluck('data', 'id'),
          switchMap((significantObjectId: string) => this.updateStoredChemical(significantObjectId)),
          catchError((error: Error) => this.catchErrorFn<string>(error, 'Ошибка при сохранении записи')),
          untilDestroyed(this),
        )
        .subscribe(() => {
          this.isDirty$ = of(false);
          this.closeAction = 'save';
          this.close();
        });
    }
  }

  /** Закрыть форму */
  public close(): void {
    this.router.navigate(this.model?.id ? ['../..'] : ['..'], { relativeTo: this.route });
  }

  /** Добавление хранимого АХОВ */
  public addChemical() {
    const idx = this.form.controls.storedChemicals.controls.length;
    const group = this.fb.group({
      id: undefined,
      chemicalType: null,
      aggregationState: null,
      chemicalAmount: 0,
      containerPressure: null,
      containerType: null,
      containerHeight: null,
    });
    this.form.controls.storedChemicals.setControl(idx, group);
  }

  /** Удаление хранимого АХОВ
   * @param idx - индекс удаляемого элемента
   */
  public removeChemical(idx: number) {
    this.form.controls.storedChemicals.removeAt(idx);
  }

  /**
   * Обновление хранимых АХОВ
   * @param significantObjectId - ID важного объекта
   */
  private updateStoredChemical(significantObjectId: string) {
    return (
      this.isChemicalObject
        ? forkJoin(
            this.form.value.storedChemicals.map((storedChemical: IStoredChemicalForForm) => {
              return this.storedChemicalsService.saveStoredChemical({
                id: storedChemical.id,
                significantObject: significantObjectId || this.model.id,
                chemicalAmount: storedChemical.chemicalAmount,
                // На самом деле тут IChemicalTypeForSelect, но метод думает что там FormControl<IChemicalTypeForSelect>
                // TODO: Надо привести типизацию в порядок
                chemicalType: (<IAnyObject>storedChemical.chemicalType).id,
                aggregationState: this.settings.getDictionaryByTypeAndSysName(
                  'stateEmergencyChemicalSubstances',
                  (<IAnyObject>storedChemical.chemicalType).aggregationStateSysName,
                ).id,
                containerPressure:
                  (<IAnyObject>storedChemical.chemicalType).aggregationStateSysName === 'gas'
                    ? storedChemical.containerPressure
                    : undefined,
                containerType:
                  (<IAnyObject>storedChemical.chemicalType).aggregationStateSysName === 'liquid'
                    ? storedChemical.containerType
                    : undefined,
                containerHeight:
                  (<IAnyObject>storedChemical.chemicalType).aggregationStateSysName === 'liquid'
                    ? storedChemical.containerHeight
                    : undefined,
              });
            }),
          )
        : of(null)
    ).pipe(
      switchMap((updatedChemicals: IAbstractServiceData[]) => {
        // Логику удаления реализовать только если объект открыт на редактирование
        if (!this.model.id) {
          return of(null);
        }
        // Если объект химически опасный, то получить список ID созданных/измененных АХОВ, для их исключения при
        // удалении, в противном случае удалить все АХОВ для объекта
        const updatedChemicalIds = this.isChemicalObject
          ? updatedChemicals?.map(
              (updatedChemical: IAbstractServiceData) =>
                updatedChemical.data.id || (updatedChemical.data.items || [])[0]?.id,
            )
          : undefined;
        return this.storedChemicalsService.deleteStoredChemicalsForSignificantObject(this.model.id, updatedChemicalIds);
      }),
    );
  }

  /** Открытие окна подтверждения удаления записи */
  public showDeleteConfirm(): void {
    this.modalService.confirm({
      nzTitle: 'Запись будет удалена. Вы действительно хотите продолжить?',
      nzOkText: 'Удалить',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzMaskClosable: true,
      nzOnOk: () => this.delete(),
      nzCancelText: 'Отмена',
    });
  }

  /** Удаление записи */
  public delete() {
    this.service
      .delete(this.model.id)
      .pipe(
        catchError((error: Error) => {
          this.notificationService.error('Ошибка', 'Запись не удалена');
          return throwError(error);
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.notificationService.success('Успешно', 'Запись удалена');
        this.closeAction = 'delete';
        this.close();
      });
  }

  /** Открыть форму редактирования карточки/плана пожаротушения в новой вкладке */
  public openfireFightingCardPlan(id: string): void {
    let targetUrl = `http://${window.location.host}`;
    targetUrl += `/dictionaries/fire-fighting-cards-plans/edit/${id}`;
    window.open(targetUrl);
  }

  /** Метод сравнения для корректной подстановки значения в селект категории важного объекта */
  public compareFn(item1: ISignificantObjectCategory, item2: ISignificantObjectCategory): boolean {
    return item1 && item2 ? item1.id === item2.id : item1 === item2;
  }

  /**
   * Генерация строки ошибок на вкладке
   *
   * @param name - название вкладки
   * @param controls - словарь, где ключ - имя контрола, значение - лэйбл
   */
  private generateTabErrorsString(name: string, controls: Map<string, string>): string {
    const invalidControls = [];
    controls.forEach((value: string, key: string) => {
      if (this.form.controls[key].invalid) invalidControls.push(value);
    });
    return invalidControls.length
      ? `На вкладке "${name}" не заполнены обязательные поля: ${invalidControls.join(', ')}.`
      : '';
  }
}
