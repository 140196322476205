import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@bg-front/core/components';
import { IScButtonOptions, IScInputOptions, IScSelectOptions, IScTextareaOptions } from '@smart-city/core/common';
import { Settings2Service } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';

import { IEmergencyResponsePlanStep, IStepDialogDataOptions } from '../../models/interfaces';
import { ResponsePlanStepService } from '../../services';

/** Форма для диалога шага реагирования */
@Component({
  selector: 'bg-response-org-form',
  templateUrl: './response-org-form.component.html',
  styleUrls: ['./response-org-form.component.scss'],
})
export class ResponseOrgFormComponent extends BaseComponent implements OnInit {
  /** Форма */
  public form: FormGroup;

  @Output()
  public saveEvent: EventEmitter<string> = new EventEmitter<string>(true);
  @Output()
  public closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor(
    public readonly responsePlanStepService: ResponsePlanStepService,
    public readonly dialogRef: MatDialogRef<IAnyObject>,
    private readonly settings: Settings2Service,
    @Inject(MAT_DIALOG_DATA) public data: IStepDialogDataOptions,
  ) {
    super();
  }

  /** Настройка для отображения: Описания */
  public descriptionOptions: IScTextareaOptions = {
    label: 'Описание',
    name: 'description',
    width: '100%',
    rows: 4,
  };
  /** Настройка для отображения: Комментарий оператора ЕДДС */
  public shortDescriptionOptions: IScTextareaOptions = {
    label: 'Краткое описание *',
    name: 'shortDescription',
    width: '100%',
  };
  /** Настройка для отображения: Состояние шага реагирования */
  public statusStepOptions: IScSelectOptions = {
    title: 'Состояние шага реагирования *',
    name: 'status',
    modern: true,
    fieldName: 'text',
    clearable: true,
    data: this.settings
      .getDictForSelect('responseStepStatus')
      .filter(
        (item) => item.id !== this.settings.getDictionaryByTypeAndSysName('responseStepStatus', 'consideration')?.id,
      ),
  };
  /** Настройка для отображения: Регламентное время выполнения (мин) */
  public timeReactOptions: IScInputOptions = {
    label: 'Регламентное время выполнения (мин) *',
  };
  /** Настройка для кнопки Сохранить */
  public saveBtnOptions: IScButtonOptions = {
    title: 'Сохранить',
    color: 'primary',
  };
  /** Настройка для кнопки Отменить */
  public cancelBtnOptions: IScButtonOptions = {
    title: 'Отменить',
  };

  /** Тип шага реагирования */
  private stepType: string = null;

  /** @ignore */
  public ngOnInit(): void {
    this.timeReactOptions.disabled = !!this.data?.isEdit;
    this.stepType = this.data?.stepType || this.settings.getDictionaryByTypeAndSysName('stepType', 'manual')?.id;
    const inProgressStatusId = this.settings.getDictionaryByTypeAndSysName('responseStepStatus', 'inProgress')?.id;

    this.form = new FormGroup({
      shortDescription: new FormControl(this.data?.step?.shortDescription, [Validators.required]),
      description: new FormControl(this.data?.step?.description),
      timeReact: new FormControl(this.data?.step?.timeReact, [Validators.required]),
      status: new FormControl(this.data?.step?.status, [Validators.required]),
    });
    if (this.data?.step?.status && this.data?.step?.status !== inProgressStatusId) {
      this.form.disable();
    }
  }

  /** Метод закрытия диалога
   * @return
   */
  public closeHandler(): void {
    this.dialogRef.close();
  }

  /** Обработчик клика на кнопку Сохранить
   * @param event
   * @return
   */
  public saveHandler(event: Event) {
    if (this.form.valid) {
      const status = this.settings.getDictionaryById(this.form.controls.status.value).sysname;
      const params = {
        status: this.form.controls['status'].value || null,
        description: this.form.controls['description'].value || '',
        shortDescription: this.form.controls['shortDescription'].value || null,
        timeReact: this.form.controls['timeReact'].value || null,
        stepType: this.stepType,
        ...(status === 'inProgress' ? { startTimeStep: new Date().getTime() } : {}),
        ...(status !== 'inProgress' ? { finishTimeStep: new Date().getTime() } : {}),
      };
      if (this.data?.isEdit) {
        this.updateStep(params)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            tap(() => this.noteService.pushInfo('Запись успешно обновлена')),
          )
          .subscribe(() => {
            this.closeHandler();
          });
      } else {
        this.createStep(params).subscribe(() => {
          this.closeHandler();
        });
      }
    }
  }

  /** Метод обновления шага
   * @param result - новые данные которые нужно обновить
   * @return
   */
  public updateStep(result: IEmergencyResponsePlanStep): Observable<IEmergencyResponsePlanStep[]> {
    // Обновлять дату начала шага не надо
    delete result.startTimeStep;
    return this.responsePlanStepService
      .updateStepItemById(this.data?.step?.id, { ...result }, this.data?.entity)
      .pipe(
        catchError((error: Error) =>
          this.catchErrorFn<IEmergencyResponsePlanStep[]>(error, 'Ошибка получения сценария реагирования происшествия'),
        ),
      );
  }

  /** Метод добавления шага
   * @return
   */
  public createStep(data: IEmergencyResponsePlanStep): Observable<IEmergencyResponsePlanStep[]> {
    data.number = this.responsePlanStepService.getListCount('responseOrgOpts') + 1;
    data.emergencyId = this.data?.emergencyModel?.id;
    return this.responsePlanStepService
      .createSteps([data], 'responseOrgOpts')
      .pipe(
        catchError((error: Error) => this.catchErrorFn<IEmergencyResponsePlanStep[]>(error, 'Ошибка создания шага')),
      );
  }
}
