<div class="bg-incident-panel container">
  <div class="bg-row">
    <div class="bg-col p-horizontal-none">
      <div class="bg-incident-panel-edit-from">
        <form [formGroup]="incidentForm" appLCSFormState [params]="attributes" errorTailor id="idForTest">
          <nw-header [options]="headerActionsOptions" (clickButton)="onClickActionsButton($event)"></nw-header>
          <div class="incident-panel">
            <div class="bg-col" style="text-align: right" *ngIf="model?.parentEventId">
              <div class="bg-form-group-incident">
                <a href="javascript:void(0)" (click)="openEmergency()"> Событие&nbsp;{{ model.parentEventId['number'] }} </a>
              </div>
            </div>
            <mat-accordion multi="true">
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Главное</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <span>Регистрация: {{ model.timeCreate | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <span>Начало: {{ model.timeStart | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <span>Окончание: {{ model.timeFinish | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                    </div>
                  </div>
                </div>

                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group readonly-container">
                      <label>Фактический адрес</label>
                      <p>{{ model.addressFact?.fullText }}</p>
                    </div>
                  </div>
                </div>
                <div class="bg-row bg-row-align-content-center space-between-container" *ngIf="!!countOfCloseImportantObjects">
                  <div class="bg-col-min">
                    <i
                      class="sc-icon-attention significant-object-attention"
                      [ngClass]="{ 'significant-object-danger': hasDangerObject }"
                    ></i>
                  </div>
                  <div class="bg-col">
                    <p>{{ closeImportantObjectsMessage }} (подробнее см. раздел "Местоположение")</p>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group readonly-container">
                      <label>Тип КСиП</label>
                      <p>{{ (ksipType$ | async)?.name }}</p>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea [options]="optionsDescription" formControlName="description" class="textarea"></sc-textarea>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea [options]="optionsComment" formControlName="comment" class="textarea"></sc-textarea>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col" *ngIf="isLoudSound() || isLeftThing() || isCrowds() || isFaceRecognition()">
                    <div class="bg-form-group">
                      <sc-checkbox [options]="optionsThreatPopulation" formControlName="danger"></sc-checkbox>
                      <sc-checkbox [options]="optionsThreatOrganization" formControlName="dangerOrg"></sc-checkbox>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select [options]="optionsResponsibleUser" formControlName="responsibleUser"></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row" *ngIf="model['regime'] && model['regime']['id']">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select [options]="optionsRegime" formControlName="regime"></sc-select>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel *ngIf="hasVaDetail()">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Результаты видеоаналитики</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <bg-video-result-wanted-grz
                  [entityId]="model.id"
                  [vaDetail]="vaDetail"
                  [incidentNumber]="model.number"
                  *ngIf="isGrzWanted()"
                ></bg-video-result-wanted-grz>
                <bg-video-result-repeat-grz
                  [entityId]="model.id"
                  [vaDetail]="vaDetail"
                  [incidentNumber]="model.number"
                  *ngIf="isGrzRepeat()"
                ></bg-video-result-repeat-grz>
                <bg-video-result-loud-sound
                  [entityId]="model.id"
                  [vaDetail]="vaDetail"
                  [incidentNumber]="model.number"
                  *ngIf="isLoudSound() || isObjRestrictedArea()"
                ></bg-video-result-loud-sound>
                <bg-video-result-left-thing
                  [entityId]="model.id"
                  [vaDetail]="vaDetail"
                  [incidentNumber]="model.number"
                  *ngIf="isLeftThing()"
                ></bg-video-result-left-thing>
                <bg-video-result-sabotage
                  [entityId]="model.id"
                  [vaDetail]="vaDetail"
                  [incidentNumber]="model.number"
                  *ngIf="isSabotage()"
                ></bg-video-result-sabotage>
                <bg-video-result-crowds
                  [entityId]="model.id"
                  [vaDetail]="vaDetail"
                  [incidentNumber]="model.number"
                  *ngIf="isCrowds()"
                ></bg-video-result-crowds>
                <bg-video-result-face-recognition
                  [entityId]="model.id"
                  [incidentNumber]="model.number"
                  [vaDetail]="vaDetail"
                  *ngIf="isFaceRecognition()"
                ></bg-video-result-face-recognition>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <i
                        *ngIf="!!countOfCloseImportantObjects"
                        class="sc-icon-attention significant-object-attention"
                        [ngClass]="{ 'significant-object-danger': hasDangerObject }"
                      ></i>
                      <div>Местоположение</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-input [options]="coordinatesOption" formControlName="coordinates"></sc-input>
                    </div>
                    <div class="bg-form-group">
                      <div class="additional-incident-type-container">
                        <sc-textarea [options]="placeDescriptionOptions" formControlName="ksipPlaceDescription"></sc-textarea>
                      </div>
                    </div>
                    <div class="bg-form-group">
                      <bg-significant-object-list-dialog
                        [coordinates]="incidentForm.controls.coordinates.value"
                        [radius]="500"
                        [disabled]="!this.countOfCloseImportantObjects"
                      ></bg-significant-object-list-dialog>
                    </div>
                    <div class="bg-form-group">
                      <div class="bg-row bg-row-align-content-center space-between-container">
                        <div class="bg-col-min" *ngIf="countOfCloseImportantObjects">
                          <i
                            class="sc-icon-attention significant-object-attention"
                            [ngClass]="{ 'significant-object-danger': hasDangerObject }"
                          ></i>
                        </div>
                        <div class="bg-col">
                          <p>{{ this.closeImportantObjectsMessage }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group mini-map-container">
                      <sc-input [options]="mapMarkerCoordOptions" formControlName="mapMarkerCoordinate"></sc-input>
                      <bg-mini-map
                        [center]="miniMapCenter"
                        [zoom]="miniMapZoom"
                        [defaultMarkerCoordinate]="defaultMarkerCoordinate"
                        [disableChangePosition]="isGrzRepeat()"
                        (changeMarkerPosition)="updateCoordinates($event)"
                        (mapReady)="onMapReady()"
                      ></bg-mini-map>
                    </div>
                  </div>
                </div>
                <div *ngIf="isGrzRepeat()">
                  <div class="bg-row">
                    <div class="bg-col">
                      <h3>Первая регистрация ГРЗ ТС</h3>
                    </div>
                  </div>
                  <div class="bg-row">
                    <div class="bg-col">
                      <div class="bg-form-group readonly-container">
                        <div class="readonly-container__column">
                          <label>Координаты</label>
                          <p>{{ firstCamera?.coordinates }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="bg-col">
                      <div class="bg-form-group readonly-container">
                        <div class="readonly-container__column">
                          <label>Место установки видеокамеры</label>
                          <div class="two-line-ellipsis-overflow">{{ firstCamera?.address?.fullText }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel (opened)="openCoverageArea()">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Зона действия</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <bg-coverage-area [address]="factAddress"></bg-coverage-area>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Дополнительная информация</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea [options]="reportOptions" formControlName="report" class="textarea"></sc-textarea>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Документы</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <multi-file [selfEnabled]="true" formControlName="documents"></multi-file>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </form>
      </div>
    </div>
    <div class="bg-col bg-incident-panel-steps-list-col bg-incident-panel-steps-list">
      <bg-response-plan-step-list
        *ngIf="involvedServiceEnabled || responsePlanStepService.getListCount('involvedOrgOpts')"
        (stepClickEvent)="showInvolvedOrgForm($event)"
        (createStepEvent)="createNewStepInvolveOrg()"
        [disabled]="involvedServiceEnabled ? isEmergencyEndStepCycle : true"
        [options]="involvedOrgOpts"
      >
      </bg-response-plan-step-list>
      <bg-response-plan-step-list
        (stepClickEvent)="showResponseStepForm($event)"
        (createStepEvent)="createNewStepScriptReact($event)"
        [disabled]="involvedServiceEnabled ? isEmergencyEndStepCycle : true"
        [options]="responseOrgOpts"
      >
      </bg-response-plan-step-list>
    </div>
  </div>
</div>
