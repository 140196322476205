<div class="row-bubble" [formGroup]="form">
  <ng-container [ngSwitch]="componentType">
    <sc-input *ngSwitchCase="'text'" [options]="textOptions" formControlName="answer"></sc-input>
    <div *ngSwitchCase="'radioButtons'" class="radio-container">
      <div id="radio-group-label" class="radio-group-label">{{ question.text }}</div>
      <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" formControlName="answer">
        <mat-radio-button
          *ngFor="let answerOption of question?.answerOptions"
          class="radio-button"
          [value]="answerOption"
          >{{ answerOption }}</mat-radio-button
        >
        <mat-radio-button *ngIf="form.controls.otherAnswer" class="radio-button" [value]="'otherAnswer'"
          >Другое
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <ng-container *ngSwitchCase="'checkboxes'" formArrayName="answer">
      <div class="checkbox-label">{{ question.text }}</div>
      <sc-checkbox
        *ngFor="let control of checkboxesArray.controls; let i = index"
        [formControl]="$any(control)"
        [label]="question.answerOptions[i] || 'Другое'"
      ></sc-checkbox>
    </ng-container>
    <sc-select *ngSwitchCase="'select'" [options]="selectOptions" formControlName="answer"></sc-select>
    <sc-select *ngSwitchCase="'multiSelect'" [options]="multiSelectOptions" formControlName="answer"></sc-select>
  </ng-container>
  <ng-container *ngIf="wasChosenOptionOther()">
    <mat-divider></mat-divider>
    <sc-input [options]="otherTextOptions" formControlName="otherAnswer"></sc-input>
  </ng-container>
</div>
<div class="miss-question-container" *ngIf="question.canMiss">
  <div class="miss-question" (click)="onMiss()">Пропустить вопрос >></div>
</div>
