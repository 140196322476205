<div class="bg-row bg-event-panel">
  <div class="bg-col bg-event-panel-edit-from">
    <nw-header [options]="headerActionsOptions" (clickButton)="onClickActionsButton($event)"></nw-header>
    <div class="drawer-content">
      <div class="event-view-panel">
        <div class="bg-col" style="text-align: right" *ngIf="model?.incidentId">
          <div class="bg-form-group">
            <a href="javascript:void(0)" (click)="openEmergency()"> {{ linkedName }} </a>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col align-right">
            <div class="bg-form-group p-t-0 p-b-0">
              <sc-checkbox [options]="byCoordinatesOptions" [ngModel]="model.mainInfo?.byCoordinates"></sc-checkbox>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group readonly-container">
              <label>Адрес КСиП</label>
              <p>{{ model.mainInfo?.address }}</p>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group">
              <placement-details [model]="model.mainInfo?.details"></placement-details>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group readonly-container">
              <label>Тип КСиП</label>
              <p>{{ model.mainInfo?.ksipTypeName }}</p>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group readonly-container column_description">
              <label>Описание</label>
              <p>{{ model.mainInfo?.description }}</p>
            </div>
            <div class="bg-form-group readonly-container">
              <label>Дата и время возникновения КСиП</label>
              <p>{{ model.mainInfo?.ksipTime | date: 'dd.MM.yyyy HH:mm:ss' }}</p>
            </div>
          </div>
          <div class="bg-col">
            <div class="bg-form-group readonly-container column_ksipInfo">
              <label>Дополнительная информация о месте КСиП</label>
              <p class="ksip-info-textarea">{{ model.mainInfo?.ksipInfo }}</p>
            </div>
            <div class="bg-form-group readonly-container column_ksipInfo">
              <label>Комментарий</label>
              <p>{{ model.mainInfo?.comment }}</p>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group readonly-container column_user">
              <label>Наименование устройства</label>
              <p>{{ deviceName }}</p>
            </div>
          </div>
          <div class="bg-col">
            <div class="bg-form-group readonly-container column_user">
              <label>Ответственный пользователь</label>
              <p>{{ model.mainInfo?.responsibleUser }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
