<div class="bg-row">
  <div class="bg-col-10">
    <h4><b>Инциденты</b></h4>
  </div>
  <div class="bg-col-2 align-right">
    <button mat-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="bg-row">
  <div class="bg-col">
    <div class="grid-container">
      <grid-table [grid]="gridOptions" #gridComponent></grid-table>
    </div>
  </div>
</div>
