<nw-header [options]="headerOptions" (clickButton)="close()"></nw-header>
<div mat-dialog-content>
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-textarea style="background: #f7f7f7" [options]="commentOptions" [(ngModel)]="commentEvent"></sc-textarea>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <sc-button [options]="cancelOptions" (clickButton)="close()"></sc-button>
  <sc-button [options]="saveOptions" (clickButton)="save()"></sc-button>
</div>
