import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { Observable } from 'rxjs';

import { INuclearPollutionPointTaskDto } from '../../models';
import { ForecastingRadioactiveEnvironmentVisualizationService } from '../../services';

@Component({
  selector: 'bg-nuclear-pollution-point-task-result',
  templateUrl: './nuclear-pollution-point-task-result.component.html',
  styleUrls: ['./nuclear-pollution-point-task-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NuclearPollutionPointTaskResultComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public forecastingResults: IForecastingResultDto;

  /** Ссылка на элемент */
  private element: any;

  public unitsName: string;

  /** Скачать отчёт */
  @Input()
  public downloadReport: Observable<void>;

  /** Уведомляем о наличии отчёта */
  @Output()
  public hasReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor(
    private readonly forecastingService: ForecastingRadioactiveEnvironmentVisualizationService,
    private readonly settings: Settings2Service,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    const { coordinatesTarget } = this.forecastingResults.params.params as INuclearPollutionPointTaskDto;
    if (new Coordinates(this.forecastingResults.params.coordinates).isValid()) {
      this.element = this.forecastingService.displayPoint(coordinatesTarget);
    } else {
      this.noteService.pushWarning('Отсутствуют координаты для визуализации расчёта');
    }

    this.unitsName = this.settings.getDictionaryById('unitRadiation')?.name;

    this.hasReport.emit(false);

    this.cdr.markForCheck();
  }

  /**
   * @ignore
   */
  public override ngOnDestroy(): void {
    this.forecastingService.clearResults(this.element);
  }
}
