<div *ngIf="significantObject">
  <nw-header [options]="headerOptions" (clickButton)="clickHeaderButton($event)"></nw-header>
  <div class="significant-object-container">
    <div class="content">
      <div class="bg-row">
        <div class="bg-col-min">
          <div class="icon">
            <span class="sc-icon-flag"></span>
          </div>
        </div>
        <div class="bg-col">
          <div [attr.tooltip]="significantObject.name" [attr.flow]="'down'">
            <div class="significant-object__name two-line-ellipsis-overflow">
              {{ significantObject.shortName }}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="bg-row">
        <div class="bg-col">
          <div class="significant-object-main-info-container">
            <div class="bg-row">
              <div class="bg-col">
                <div class="significant-object-info">
                  <label>Адрес:</label>
                  <p class="significant-object-info__link" (click)="selectCoordinates()">
                    {{ significantObject.address?.fullText || coordinates }}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div class="bg-row">
              <div class="bg-col">
                <div class="video-device-info">
                  <label>Категория:</label>
                  <p>{{ significantObjectCategory }}</p>
                </div>
              </div>
            </div>
            <hr />
            <div class="bg-row">
              <div class="bg-col">
                <div class="video-device-info">
                  <label>Тип:</label>
                  <p>{{ significantObjectType }}</p>
                </div>
              </div>
            </div>
            <hr />
            <div class="bg-row">
              <div class="bg-col">
                <div class="video-device-info">
                  <label>Состояние:</label>
                  <p>{{ significantObjectState }}</p>
                </div>
              </div>
            </div>
            <hr />
            <div class="bg-row">
              <div class="bg-col">
                <div class="video-device-info">
                  <label>Ответственный</label>
                  <div [attr.tooltip]="significantObject.responsible" [attr.flow]="'down'">
                    <div class="hide-overflow">
                      {{ significantObject.responsible }}
                    </div>
                  </div>
                  <div
                    *ngIf="significantObject.phone"
                    class="significant-object-info__link"
                    (click)="onClickPhone(significantObject.phone)"
                  >{{ formatPhoneFn(significantObject.phone) }}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'16px'" [expandedHeight]="'16px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Дежурные службы</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <span style="white-space: pre-line">{{ significantObject['dutyServices'] }}</span>
              </mat-expansion-panel>
            </mat-accordion>
            <ng-container *ngIf="canSeeDocuments">
              <hr />
              <mat-accordion>
                <!--
                 mat-expansion-panel по умолчанию имеет overflow: hidden, это приводит к тому что тултип на названии
                 документа скрывается внизу, т.к. выходит за рамки. В то же время overflow: hidden необходим когда
                 аккордеон свернут. Поэтому нужно отлавливать закрытие/открытие аккордеона и менять значение overflow.
                -->
                <mat-expansion-panel [ngClass]="{ 'documents-expansion-panel': showDocuments }">
                  <mat-expansion-panel-header
                    [collapsedHeight]="'16px'"
                    [expandedHeight]="'16px'"
                    (click)="toggleDocuments()"
                  >
                    <mat-panel-title>
                      <div class="header-container">
                        <div>Документы</div>
                        <div class="header-container__divider">
                          <hr />
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let document of documents">
                    <div [attr.tooltip]="document.fileName" [attr.flow]="'up'">
                      <div
                        class="significant-object-info__link hide-overflow"
                        (click)="downloadDocument(document.uuid)"
                      >
                        {{ document.fileName }}
                      </div>
                    </div>
                  </ng-container>
                </mat-expansion-panel>
              </mat-accordion>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <hr />
      <div class="significant-object-main-info-container" style="padding-right: 5px">
        <div class="bg-row">
          <div class="bg-col">
            <label>Связанные инциденты</label>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col no-padding">
            <div class="related-emergencies-container">
              <ng-template ngFor let-emergency [ngForOf]="emergencies">
                <bg-incident-mini-card [data]="emergency"></bg-incident-mini-card>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="canForecastingRun?.visible" class="significant-object-main-info-container">
        <div *ngIf="isForecasting" class="bg-row">
          <div class="bg-col">
            <hr />
            <button mat-raised-button color="primary" (click)="runForecasting()">Запуск прогнозирования</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
