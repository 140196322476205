<div>
  <div>
    <div class="dialog-header">
      <div class="bg-row dialog-header-title">
        <h2 mat-dialog-title>{{ data?.title }}</h2>
      </div>
    </div>
  </div>
  <bg-involve-org-without-commission-form
    [options]="data"
    (closeEvent)="closeHandler()"
  ></bg-involve-org-without-commission-form>
</div>
