import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  IElementButton,
  INwHeaderBarOptions,
  IScrollableContainerLoadDataFnParams,
  IScrollableContainerLoadDataFnResult,
  IScrollableContainerOptions,
  ScrollableContainerComponent,
} from '@smart-city/core/common';
import { ScNavService } from '@smart-city/core/services';
import { of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { AtmNotificationMiniCardComponent } from '../atm-notification-mini-card/atm-notification-mini-card.component';
import { IHcsObjectKindsDto } from '../../../../bg/modules/dictionaries/modules/hcs-object-kinds/models/interfaces';
import { IHcsObjectTypesDto } from '../../../../bg/modules/dictionaries/modules/hcs-object-types/models/interfaces';
import { IMonitoringHcsServerDto } from '../../../../bg/modules/utility/modules/monitoring-hcs-server/interfaces';
import {
  IMonitoringObjectHcsDto,
  IMonitoringObjectHcsView,
  IRoutineMaintenanceDto
} from '../../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/models/interfaces/monitoring-object-hcs.interface';
import { IBaseDictionaryData, IUser } from '@smart-city/core/interfaces';
import * as moment from 'moment';
import { NotificationsObjectsHcsService } from '../../../../bg/modules/external-interactions/modules/notifications-objects-hcs/services';
import { IOrganization } from '../../../models/interfaces';
import { BaseComponent } from '@bg-front/core/components';
import { MonitoringObjectHcsService } from '../../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/services';
import { ONE_SECOND } from '@bg-front/core/models/constants';
import { CallService } from '../../../../call/services';
import { formatPhone } from '@smart-city/core/utils';
import { INotificationObjectsHcsView } from '../../../../bg/modules/external-interactions/modules/notifications-objects-hcs/models/interfaces';
import { MapBaseModel, MapBaseService } from '@smart-city/maps/sc';
import { LayersEnum } from '@bg-front/core/models/enums';

// TODO: переименовать. Т.к. используется и для объектов электроснабжения
@Component({
  selector: 'heat-supply-facility-info',
  templateUrl: 'heat-supply-facility-info.component.html',
  styleUrls: ['heat-supply-facility-info.component.scss'],
})
export class HeatSupplyFacilityInfoComponent extends BaseComponent implements OnInit, AfterViewInit {
  /** Данные объекте ЖКХ */
  public heatSupplyFacility: IMonitoringObjectHcsView = undefined;

  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions;

  /** Настройки скрола для оповещений */
  public notificationsOptions: IScrollableContainerOptions;

  /** Id объекта теплоснабжения */
  @Input()
  public set heatSupplyFacilityId(id: string) {
    if (id) {
      this.heatSupplyFacility = undefined;
      this.service
        .getHcsMonitoringObject(id)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          catchError((err: Error) => this.catchErrorFn<IMonitoringObjectHcsView>(err, 'Ошибка при загрузке данных')),
        )
        .subscribe((monitoringObject: IMonitoringObjectHcsDto) => {
          const serverLastTimeUpdate = (<IMonitoringHcsServerDto>(monitoringObject)?.serverId)?.lastTimeUpdate;
          const serverParamCollectionInterval = (
            <IMonitoringHcsServerDto>(monitoringObject)?.serverId
          )?.paramCollectionInterval || 0;
          const routineMaintenanceStartTime = +(
            <IRoutineMaintenanceDto>monitoringObject.routineMaintenanceId
          )?.startTime;
          const routineMaintenancePlannedFinishTime = +(
            <IRoutineMaintenanceDto>monitoringObject.routineMaintenanceId
          )?.plannedFinishTime;
          const routineMaintenanceLastUpdateTime = +(
            <IRoutineMaintenanceDto>monitoringObject.routineMaintenanceId
          )?.lastUpdateTime;
          const routineMaintenanceFactFinishTime = +(
            <IRoutineMaintenanceDto>monitoringObject.routineMaintenanceId
          )?.factFinishTime;

          this.heatSupplyFacility = <IMonitoringObjectHcsView>{
            id: monitoringObject.id,
            creationTime: monitoringObject.creationTime,
            updateTime: monitoringObject.updateTime,
            name: monitoringObject.name,
            address: monitoringObject.address,
            coordinates: monitoringObject.coordinates,
            showOnMap: monitoringObject.showOnMap,
            hcsObjectKindId: (<IHcsObjectKindsDto>monitoringObject.hcsObjectKindId)?.id,
            hcsObjectTypeId: (<IHcsObjectTypesDto>monitoringObject.hcsObjectTypeId)?.id,
            operatingOrganizationId: (<IOrganization>monitoringObject.operatingOrganizationId)?.id,
            ownerOrganizationId: (<IOrganization>monitoringObject.ownerOrganizationId)?.id,
            status: (<IBaseDictionaryData>monitoringObject.statusId)?.name,
            supplyTypeId: monitoringObject.supplyTypeId,
            extId: monitoringObject.extId,
            integrationType: (<IBaseDictionaryData>(<IMonitoringHcsServerDto>monitoringObject.serverId)?.integrationTypeId)?.name,
            server: (<IMonitoringHcsServerDto>monitoringObject.serverId)?.name,
            organization: (<IOrganization>(<IMonitoringHcsServerDto>monitoringObject.serverId)?.organizationId)?.name,
            phone: (<IOrganization>(<IMonitoringHcsServerDto>monitoringObject.serverId)?.organizationId)?.phone1,
            serverLastTimeUpdate: serverLastTimeUpdate ? moment(serverLastTimeUpdate).unix() * ONE_SECOND : 0,
            serverParamCollectionInterval: serverParamCollectionInterval,
            routineMaintenanceStatus: (
              <IBaseDictionaryData>(<IRoutineMaintenanceDto>monitoringObject.routineMaintenanceId)?.statusId
            )?.name,
            routineMaintenanceExtId: (<IRoutineMaintenanceDto>monitoringObject.routineMaintenanceId)?.extId,
            routineMaintenanceStartTime: routineMaintenanceStartTime
            ? moment(routineMaintenanceStartTime).format('DD.MM.YYYY HH:mm:ss')
            : '',
            routineMaintenancePlannedFinishTime: routineMaintenancePlannedFinishTime
            ? moment(routineMaintenancePlannedFinishTime).format('DD.MM.YYYY HH:mm:ss')
            : '',
            routineMaintenanceLastUpdateTime: routineMaintenanceLastUpdateTime
            ? moment(routineMaintenanceLastUpdateTime).format('DD.MM.YYYY HH:mm:ss')
            : '',
            routineMaintenanceFactFinishTime: routineMaintenanceFactFinishTime
            ? moment(routineMaintenanceFactFinishTime).format('DD.MM.YYYY HH:mm:ss')
            : '',
            routineMaintenanceUser: (
              <IUser>(<IRoutineMaintenanceDto>monitoringObject.routineMaintenanceId)?.userId
            )?.fio,
            routineMaintenanceDescription: (
              <IRoutineMaintenanceDto>monitoringObject.routineMaintenanceId
            )?.description,
            hcsObjectStatus: monitoringObject.hcsObjectStatus,
          };
        });
    }
  }

  /** Список оповещений */
  @ViewChildren('notifications') public notificationsScroll: QueryList<ScrollableContainerComponent>;

  /** Ссылка на элемент */
  public notifications: ScrollableContainerComponent;

  /** Тип объекта снабжения */
  public objectType: string;

  /** Иконка в заголовке */
  public icon: string;

  /** Модель карты */
  private mapModel: MapBaseModel;

  /** @ignore */
  constructor(
    private readonly service: MonitoringObjectHcsService,
    private readonly scNavService: ScNavService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly hcsNotificationService: NotificationsObjectsHcsService,
    private readonly callService: CallService,
    private readonly mapService: MapBaseService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.mapModel = new MapBaseModel('baseMapWorkspace', this.mapService);

    this.objectType = this.route.snapshot.data['type'];
    if (this.objectType === 'electricity') {
      this.icon = 'sc-icon-lightning';
    } else if (this.objectType === 'heat') {
      this.icon = 'sc-icon-heating';
    }

    this.headerOptions = {
      margin: 'collapse',
      title: ' ',
      buttons: [
        {
          type: 'button',
          options: {
            name: 'burger',
            icon: 'menu',
            callback: () => {
              this.scNavService.openMenu();
              return of({ actionStream: 'donothing' });
            },
          },
        },
        {
          type: 'button',
          options: {
            name: 'close',
            icon: 'close',
            position: 'suffix',
            callback: () => {
              this.mapModel.removeLayer(LayersEnum.clickMarker);

              this.router.navigate([{ outlets: { leftPopup: null, viewForm: null } }], {
                relativeTo: this.route.parent,
                queryParamsHandling: 'merge',
              });
              return of();
            },
          },
        },
      ],
    };

    /** Подписываемся на параметры */
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((routeParams: Params) => {
      if (routeParams['id']) {
        this.notificationsOptions = {
          loadDataFn: (scrollParams: IScrollableContainerLoadDataFnParams) => {
            return this.hcsNotificationService
              .getForRegistry(this.heatSupplyFacility, { paNumber: scrollParams.page, paSize: scrollParams.limit })
              .pipe(
                map((notifications: INotificationObjectsHcsView[]) => {
                  if (notifications?.length) {
                    return <IScrollableContainerLoadDataFnResult>{
                      currentPage: scrollParams.page,
                      data: notifications.map((notification: INotificationObjectsHcsView) => {
                        return {
                          data: notification,
                          type: AtmNotificationMiniCardComponent,
                        };
                      }),
                    };
                  }
                  return <IScrollableContainerLoadDataFnResult>{
                    currentPage: scrollParams.page,
                    data: [null],
                  };
                }),
                catchError((err: Error) =>
                  this.catchErrorFn<IScrollableContainerLoadDataFnResult>(err, 'Ошибка при запросе списка оповещений'),
                ),
                takeUntil(this.ngUnsubscribe),
              );
          },
        };
        return (this.heatSupplyFacilityId = routeParams['id']);
      }
    });
  }

  /** @ignore */
  public ngAfterViewInit() {
    this.notificationsScroll.changes
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((comps: QueryList<ScrollableContainerComponent>) => {
        if (comps.first) {
          this.notifications = comps.first;
          this.notifications.getNextData();
        } else {
          this.notifications?.ngOnDestroy();
          this.notifications = undefined;
        }
      });
  }

  /**
   * Обработчик, срабатываемый при нажатии на номер телефона в истории вызовов.
   * @param phone номер телефона
   */
  public onClickPhone(phone: string): void {
    this.callService.call(phone);
  }

  /** Приведение номера к формату +7 */
  public formatPhoneFn(phone: string): string {
    return formatPhone(phone);
  }

  /**
   * Обработка событий из заголовка
   * @param $event событие
   */
  public clickHeaderButton($event: IElementButton): void {
    ($event.options.callback as any)();
  }

  public details() {
    this.router.navigate([{ outlets: { viewForm: ['heatSupplyFacilityDetails', this.heatSupplyFacility.id] } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }
}
