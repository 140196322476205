import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { IScInputOptions, IScSelectOptions } from '@smart-city/core/common';

import { LoosesListComponent } from '../looses-list/looses-list.component';

@Component({
  selector: 'evacuated-list',
  templateUrl: './evacuated-list.component.html',
  styleUrls: ['./evacuated-list.component.scss'],
})
export class EvacuatedListComponent extends LoosesListComponent implements OnInit {
  /** Настройка компоненты Наименования ПВР */
  public shelterIdOptions = <IScSelectOptions>{
    title: 'Наименование ПВР',
    clearable: true,
    service: 'Directories',
    entity: 'Shelters',
    modern: true,
    fieldName: 'name',
    query: {
      'state.sysname': 'active',
    },
  };

  /** Настройка компоненты Адрес родственников */
  public relativesAddressOptions: IScInputOptions = {
    label: 'Адрес родственников',
    placeholder: 'Адрес родственников',
    maxLength: 100,
  };

  /** @ignore */
  public override ngOnInit(): void {
    super.ngOnInit();
    this.listData.forEach((item) =>
      (<FormArray>this.form.controls.list).push(
        new FormGroup({
          id: new FormControl(item.id),
          fio: new FormControl(item.fio),
          birthYear: new FormControl(item.birthYear),
          shelterId: new FormControl(item.shelterId),
          relativesAddress: new FormControl(item.relativesAddress),
          comment: new FormControl(item.comment),
        }),
      ),
    );
  }

  /**
   * Добавление записи
   */
  public add() {
    (<FormArray>this.form.controls.list).push(
      new FormGroup({
        fio: new FormControl(),
        birthYear: new FormControl(),
        shelterId: new FormControl(),
        relativesAddress: new FormControl(),
        comment: new FormControl(),
      }),
    );
  }
}
