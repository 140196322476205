import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ResponsiblesState } from '../../../models/states';
import { ResponsiblesStore } from '../store/responsibles.store';

/**
 * Сервис по работе с хранилищем Организаций
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ResponsiblesQuery extends QueryEntity<ResponsiblesState> {
  constructor(store: ResponsiblesStore) {
    super(store);
  }
}
