import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { from, Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SfsService } from '@smart-city/core/services';

@Component({
  selector: 'bg-sfs-image',
  templateUrl: './sfs-image.component.html',
  styleUrls: ['./sfs-image.component.scss'],
})
export class SfsImageComponent implements OnChanges {
  /** Идентификатор изображения */
  @Input() public uuid?: string;

  /** Ссылка на изображение */
  @Input() public link?: string;

  /** Отображение во весь размер */
  @Input() public fullSize?: string | boolean;

  /** Не отображать ошибку */
  @Input() public noError?: string | boolean;

  /** Готовность элемента */
  @Output() public imageReady = new EventEmitter<boolean>();

  /** Полученное изображение */
  public image?: string;

  /** Процесс загрузки изображения */
  public loading = true;

  /** Ошибка загрузки изображения */
  public error?: string;

  /** Поток со ссылкой на изображение */
  private imageLink$ = new Subject<string | undefined>();

  /** @ignore */
  constructor(
    private readonly sfs: SfsService,
  ) {
    from(this.imageLink$)
      .pipe(
        switchMap((link: string | undefined) => {
          this.error = '';
          if (link) {
            this.loading = true;
            return from(fetch(link))
              .pipe(
                switchMap((response: Response) => {
                  if ((response.headers.get('content-type') || '').includes('application/json')) {
                    return from(response.json().then(error => {
                      this.error = error.message;
                      return '';
                    }));
                  } else {
                    return from(response.blob())
                      .pipe(
                        switchMap((blob: Blob) => {
                          const reader = new FileReader();
                          reader.readAsDataURL(blob);

                          return new Observable((observer) => {
                            reader.onloadend = () => {
                              observer.next(reader.result as string);
                              observer.complete();
                            };
                            reader.onerror = (error: ProgressEvent<FileReader>) => {
                              this.error = error.type;
                              observer.next('');
                              observer.complete();
                            };
                          });
                        }),
                      );
                  }
                }),
              )
          } else {
            this.error = 'Отсутствует ссылка на изображение';
            return of('');
          }
        }),
      )
      .subscribe((image: string | unknown) => {
        this.loading = false;
        this.image = typeof image === 'string' ? image : '';
        this.imageReady.emit(!!image);
      });
  }

  /** @ignore */
  public ngOnChanges(): void {
    this.fullSize = typeof this.fullSize === 'string' ? true : !!this.fullSize;
    this.noError = typeof this.noError === 'string' ? true : !!this.noError;
    if (this.uuid || this.link !== undefined) {
      setTimeout(() => this.getImage());
    }
  }

  /** Получение изображения */
  private getImage(): void {
    this.imageLink$.next(this.link || this.sfs.getUrl(this.uuid!));
  }
}
