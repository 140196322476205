import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as dayjs from 'dayjs';

import { ISignature } from '../../models/interfaces';

// @dynamic
/** Диалоговое окно с информацией об электронной подписи */
@Component({
  selector: 'signature-info-dialog',
  templateUrl: './signature-info-dialog.component.html',
  styleUrls: ['./signature-info-dialog.component.scss'],
})
export class SignatureInfoDialogComponent {
  /** Геттер времени подписания */
  get signingDate() {
    return dayjs(this.data.signature.signingDate).format('DD.MM.YYYY HH:mm:ss');
  }

  get validTo() {
    return this.data.signature.validTo ? dayjs(this.data.signature.validTo).format('DD.MM.YYYY HH:mm:ss') : '-';
  }

  /** @ignore */
  constructor(
    private readonly dialogRef: MatDialogRef<SignatureInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { signature: ISignature },
  ) {}

  /** Отмена */
  public close() {
    this.dialogRef.close();
  }
}
