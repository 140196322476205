import { IAnyObject } from "smart-city-types";

/**
 * Преобразует объект вида { fullname: 'ya', ... }, в массив вида [ { [keyName]: fullname, [valueName]: 'ya' }]
 * @param object - текущей объект
 * @param keyName - props ключа исходного объекта в новом элементе массива
 * @param valueName - props значений исходного в новом элементе массива
 * @return
 */
export const convertObjectToArray = <T>(object: { [key: string]: IAnyObject[] }, keyName: string, valueName: string)
  : T[] => {
  const values = Object.values(object);
  const keys = Object.keys(object);
  const data = [];
  keys.forEach((item:string, index: number) => {
    data.push({ [keyName]: item, [valueName]: values[index] });
  });
  return data;
};
