import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { IImageDto } from '@bg-front/images/models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GAsterService } from '@smart-city/calls/services';
import { IShowSoundNotificationConf, SoundNotificationService } from '@smart-city/core/common';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { PltNotificationCarbonParamsPartial, PltNotificationCarbonService } from '@smart-city/core/plt-notification';
import {
  NotificationService,
  RestService,
  Settings2Service,
  SfsService,
  SubscriberService,
} from '@smart-city/core/services';
import * as dayjs from 'dayjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IEmergencyDto, ILifeCycleStepDto } from 'smart-city-types';

import { IEmergencyResponsePlanStep } from '../../../app/modules/app-common/models/interfaces';
import { EmergencyService, PagesService } from '../../modules/app-common/services';
import {
  ISoundNotification,
  ISourcesAndSignals,
} from '../../modules/bg/modules/administration/modules/sound-notifications/models/interfaces';
import { IInformationStatementData } from '../../modules/bg/modules/consolidated-registries/models/interfaces';
import { InformationStatementService } from '../../modules/bg/modules/consolidated-registries/modules/information-statements/services';
import { SoundNotificationsService } from './../../modules/bg/modules/administration/modules/sound-notifications/services/sound-notifications.service';
import { CallService } from './../../modules/call/services/call/call.service';

@UntilDestroy()
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Шаблон уведомления */
  @ViewChild('notification', { static: false }) notificationTemplate?: TemplateRef<IAnyObject>;
  /** Шаблон уведомления с изображением */
  @ViewChild('notificationImage', { static: false }) notificationImage: TemplateRef<IAnyObject>;

  public soundNotificationOptions: IShowSoundNotificationConf = null;
  /** Подписка на изменение в инцидентах */
  private emergencyStreamSub: Subscription;
  /** Подписка на изменение в событиях */
  private eventStreamSub: Subscription;
  /** Подписка на изменение в поручениях */
  private informationStatementsStreamSub: Subscription;
  /** Текущий пользователь */
  private currentUser: IAnyObject = this.settings.currentUser;
  /** Массив звуковых уведомлений */
  public soundNotificationsSettings: ISoundNotification[];
  /** Массив настроек ЗУ */
  public sourcesAndSignals: ISourcesAndSignals[];
  /** Отображаемые уведомления */ private notificationMap: Map<string, string> = new Map();
  /** Путь сфс к фото */
  public notificationFilePath: string;

  constructor(
    private readonly subs: SubscriberService,
    private readonly soundNotService: SoundNotificationService,
    private readonly bgSoundNotService: SoundNotificationsService,
    private readonly router: Router,
    private readonly notification: NotificationService,
    private readonly rest: RestService,
    private readonly settings: Settings2Service,
    private readonly call: CallService,
    private readonly informationStatement: InformationStatementService,
    private readonly emergency: EmergencyService,
    private readonly subscriberService: SubscriberService,
    private readonly notificationCarbonService: PltNotificationCarbonService,
    private readonly pages: PagesService,
    private readonly gaster: GAsterService,
    private readonly modal: NzModalService,
    private readonly sfs: SfsService,
    notificationService: NzNotificationService,
  ) {
    super(notificationService);
  }

  /** @ignore */
  ngOnInit() {
    /** Всегда отображаем иконку ЗУ */
    this.soundNotService.isSoundNotificationControlVisible$.subscribe((val: boolean) => {
      if (!val) {
        setTimeout(() => this.soundNotService.displayIcon(true), 500);
      }
    });

    this.getSoundNotificationsSettings();
    this.getSourcesAndSignalsSettings();

    this.subs
      .onTableChange('Emergency', 'SoundNotifications')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.getSoundNotificationsSettings());

    this.subs
      .onTableChange('Emergency', 'SourcesAndSignals')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.getSourcesAndSignalsSettings());

    // TODO: Подписка происходит трижды. Нужно подписаться один раз. Обработку различных action через свитч реализовать
    //       в отдельных функциях.
    this.subscriberService
      .getStream()
      .pipe(
        filter(
          (event: IAbstractServiceData) =>
            event.action === 'emergencyWorksNotDoneEvent' &&
            event.data.organization === this.currentUser.organizationId.id,
        ),
      )
      .subscribe((event: IAbstractServiceData) => {
        const params: PltNotificationCarbonParamsPartial = {
          message: 'Не выполнены работы по поручению ',
          timestamp: false,
          type: 'error',
          autoClose: false,
          showClose: true,
          theme: 'white',
          links: [
            {
              href: `http://${window.location.host}/consolidated-registries/order-register/${event.data.id}`,
              text: event.data.number,
              target: '_blank',
              closeOnClick: true,
            },
          ],
        };
        this.notificationCarbonService.pushNotification(params);
        this.notificationCarbonService.setPosition('bottom-right');
      });

    this.soundNotificationOptions = {
      showSoundNotification: true,
      options: {
        initCb: () => {
          this.soundNotService.soundStream$.pipe(untilDestroyed(this)).subscribe((isSoundOn: boolean) => {
            if (isSoundOn) {
              if (!this.emergencyStreamSub) {
                this.emergencyStreamSub = this.subs
                  .onTableChange('Emergency', 'Emergency')
                  .pipe(untilDestroyed(this))
                  .subscribe((result: IAnyObject) => {
                    const incident = result.data || {};
                    const targetUrl = `${window.location.origin}/consolidated-registries/incident-register/(editForm:incident/${incident.id})`;

                    /** Триггер 1: "Создана новая запись в таблице Emergency.Emergency" */
                    if (
                      result.action === 'insert' &&
                      incident.createdBy !== this.currentUser.id &&
                      incident.creationAuthor !== this.currentUser.id &&
                      this.playNotification()
                    ) {
                      const notifications: ISoundNotification[] = this.findNotifications(
                        'emergency',
                        'responsibleOrganization',
                        incident,
                      );
                      if (notifications.length) {
                        const selectedSources = this.sourcesAndSignals.filter(
                          (source: ISourcesAndSignals) => source.soundNotification === notifications[0].id,
                        );

                        const sources = selectedSources.filter((source: ISourcesAndSignals) => {
                          const lifeCycleStepId: string = Object.values(this.settings.lifeCycle)
                            .map((item: IAnyObject) => item.steps)
                            .reduce((prev, curr) => [...prev, ...curr], [])
                            .find((step: ILifeCycleStepDto) => step.id === incident.lifeCycleStepId).status;

                          return (
                            (source.sourceIds === null ||
                              !source.sourceIds.length ||
                              source.sourceIds.find((item: string) => item === incident.sourceType)) &&
                            (source.incidentTypeIds === null ||
                              !source.incidentTypeIds.length ||
                              source.incidentTypeIds.find((item: string) => item === incident.incidentTypeId)) &&
                            (source.docTypeIds === null ||
                              !source.docTypeIds.length ||
                              source.docTypeIds.find((item: string) => item === incident.docType)) &&
                            (source.statusLifeCycleStepIds === null ||
                              !source.statusLifeCycleStepIds.length ||
                              source.statusLifeCycleStepIds.find((item: string) => item === lifeCycleStepId))
                          );
                        });
                        if (sources?.length) {
                          const source = sources[0];
                          if (!!source.pushNotificationTypeId) {
                            this.createNotification(
                              targetUrl,
                              source.pushNotificationTypeId as string,
                              source.pushNotificationText,
                              incident.number,
                            );
                          } else if (!!source.imageId) {
                            this.notificationFilePath = this.sfs.getUrl((source.imageId as IImageDto).sfsId);
                            this.showImageNotification();
                          }

                          if (!!source.signal) {
                            const signalId = source.signal;
                            this.soundNotService.playSound(signalId).pipe(untilDestroyed(this)).subscribe();
                          }
                        }
                      }
                    }

                    /** Триггер 2: "Изменение значения поля "На контроле ЦУКС" в таблице Emergency.Emergency" */
                    if (
                      result.action === 'update' &&
                      incident.createdBy !== this.currentUser.id &&
                      incident.creationAuthor !== this.currentUser.id &&
                      this.playNotification()
                    ) {
                      const notifications: ISoundNotification[] = this.findNotifications(
                        'emergency',
                        'supervisor',
                        incident,
                      );
                      if (notifications.length) {
                        const selectedSources = this.sourcesAndSignals.filter(
                          (source: ISourcesAndSignals) => source.soundNotification === notifications[0].id,
                        );

                        const sources = selectedSources.filter((source: ISourcesAndSignals) => {
                          return (
                            (source.sourceIds === null ||
                              !source.sourceIds.length ||
                              source.sourceIds.find((item: string) => item === incident.sourceType)) &&
                            (source.incidentTypeIds === null ||
                              !source.incidentTypeIds.length ||
                              source.incidentTypeIds.find((item: string) => item === incident.incidentTypeId)) &&
                            (source.docTypeIds === null ||
                              !source.docTypeIds.length ||
                              source.docTypeIds.find((item: string) => item === incident.docType))
                          );
                        });
                        if (sources?.length && incident.id) {
                          this.emergency
                            .getIncidentById(incident.id)
                            .pipe(untilDestroyed(this))
                            .subscribe((response: IEmergencyDto) => {
                              const prevValuesString: string = response['previousValues'];
                              if (prevValuesString) {
                                // Парсинг предыдущего значения флага 'На контроле цукс' из строки
                                const supervisedByCuksArr: string[] =
                                  prevValuesString
                                    .substring(1, prevValuesString.length - 1)
                                    .split(',')
                                    .map((item: string) => item.split(':'))
                                    .find((item: string[]) => item[0] === 'supervisedByCuks') || [];

                                const supervisedByCuksPreviousValue: boolean = supervisedByCuksArr.length
                                  ? supervisedByCuksArr[1].includes('true')
                                  : false;

                                if (!supervisedByCuksPreviousValue && incident.supervisedByCuks) {
                                  const source = sources[0];
                                  if (!!source.pushNotificationTypeId) {
                                    this.createNotification(
                                      targetUrl,
                                      source.pushNotificationTypeId as string,
                                      source.pushNotificationText,
                                      incident.number,
                                    );
                                  } else if (!!source.imageId) {
                                    this.notificationFilePath = this.sfs.getUrl((source.imageId as IImageDto).sfsId);
                                    this.showImageNotification();
                                  }

                                  if (!!source.signal) {
                                    const signalId = source.signal;
                                    this.soundNotService.playSound(signalId).pipe(untilDestroyed(this)).subscribe();
                                  }
                                }
                              }
                            });
                        }
                      }
                    }

                    /** Триггер: "Изменения состояний поручений в таблице Emergency.Emergency" */
                    // Запись словаря с типом поручение
                    const order = this.settings
                      .getDictionaryByTypeSysName('docType')
                      .find((value: IDictionaryInfo) => value.sysname === 'order');
                    if (
                      result.action === 'update' &&
                      incident.updateAuthor !== this.currentUser.id &&
                      this.playNotification() &&
                      incident.docType === order.id
                    ) {
                      this.emergency
                        .getIncidentById(incident.parentId)
                        .pipe(untilDestroyed(this))
                        .subscribe((parentIncident: IEmergencyDto) => {
                          const notifications: ISoundNotification[] = this.findNotifications(
                            'emergency',
                            'ordersControl',
                            parentIncident,
                          );

                          if (notifications.length) {
                            const selectedSources = this.sourcesAndSignals.filter(
                              (source: ISourcesAndSignals) => source.soundNotification === notifications[0].id,
                            );
                            const lifeCycleStepId: string = Object.values(this.settings.lifeCycle)
                              .map((item: IAnyObject) => item.steps)
                              .reduce((prev, curr) => [...prev, ...curr], [])
                              .find((step: ILifeCycleStepDto) => step.id === incident.lifeCycleStepId).status;

                            const sources = selectedSources.filter((source: ISourcesAndSignals) => {
                              return (
                                (source.incidentTypeIds === null ||
                                  !source.incidentTypeIds.length ||
                                  source.incidentTypeIds.find((item: string) => item === incident.incidentTypeId)) &&
                                (source.statusLifeCycleStepIds === null ||
                                  !source.statusLifeCycleStepIds.length ||
                                  source.statusLifeCycleStepIds.find((item: string) => item === lifeCycleStepId))
                              );
                            });

                            if (sources?.length) {
                              this.emergency
                                .getIncidentById(incident.id)
                                .pipe(untilDestroyed(this))
                                .subscribe((response: IEmergencyDto) => {
                                  const prevValuesString: string = response['previousValues'];
                                  if (prevValuesString) {
                                    const prevValuesArray: string[][] = prevValuesString
                                      .substring(1, prevValuesString.length - 1)
                                      .split(',')
                                      .map((item: string) => item.split(':'))
                                      .filter((item: string[]) => item[0].includes('lifeCycleStepId'));
                                    const lifeCycleStepIdPreviousValue: string =
                                      prevValuesArray[prevValuesArray?.length - 1][1];
                                    const wasStatusChanged: boolean = !lifeCycleStepIdPreviousValue.includes(
                                      incident.lifeCycleStepId,
                                    );

                                    if (wasStatusChanged) {
                                      const source = sources[0];
                                      if (!!source.pushNotificationTypeId) {
                                        this.createNotification(
                                          targetUrl,
                                          source.pushNotificationTypeId as string,
                                          source.pushNotificationText,
                                          incident.number,
                                        );
                                      } else if (!!source.imageId) {
                                        this.notificationFilePath = this.sfs.getUrl(
                                          (source.imageId as IImageDto).sfsId,
                                        );
                                        this.showImageNotification();
                                      }

                                      if (!!source.signal) {
                                        const signalId = source.signal;
                                        this.soundNotService.playSound(signalId).pipe(untilDestroyed(this)).subscribe();
                                      }
                                    }
                                  }
                                });
                            }
                          }
                        });
                    }
                  });
              }

              if (!this.eventStreamSub) {
                this.eventStreamSub = this.subs
                  .onTableChange('Emergency', 'Events')
                  .pipe(untilDestroyed(this))
                  .subscribe((result: IAnyObject) => {
                    const event = result.data || {};
                    const targetUrl = `${window.location.origin}/consolidated-registries/event-register/${event.id}`;

                    /** Триггер 3: "Создана новая запись в таблице Emergency.Events, с атрибутом isHandled = false" */
                    if (
                      result.action === 'insert' &&
                      event.isHandled === false &&
                      event.creationAuthor !== this.currentUser.id &&
                      this.playNotification()
                    ) {
                      const notifications: ISoundNotification[] = this.findNotifications(
                        'event',
                        'responsibleOrganization',
                        event,
                      );

                      if (notifications.length) {
                        const selectedSources = this.sourcesAndSignals.filter(
                          (source: ISourcesAndSignals) => source.soundNotification === notifications[0].id,
                        );

                        const sources = selectedSources.filter((source: ISourcesAndSignals) => {
                          return (
                            (source.sourceIds === null ||
                              !source.sourceIds.length ||
                              source.sourceIds.find((item: string) => item === event.sourceId)) &&
                            (source.incidentTypeIds === null ||
                              !source.incidentTypeIds.length ||
                              source.incidentTypeIds.find((item: string) => item === event.ksipTypeId))
                          );
                        });

                        if (sources?.length) {
                          const source = sources[0];
                          if (!!source.pushNotificationTypeId) {
                            this.createNotification(
                              targetUrl,
                              source.pushNotificationTypeId as string,
                              source.pushNotificationText,
                              event.number,
                            );
                          } else if (!!source.imageId) {
                            this.notificationFilePath = this.sfs.getUrl((source.imageId as IImageDto).sfsId);
                            this.showImageNotification();
                          }

                          if (!!source.signal) {
                            const signalId = source.signal;
                            this.soundNotService.playSound(signalId).pipe(untilDestroyed(this)).subscribe();
                          }
                        }
                      }
                    }
                  });
              }

              if (!this.informationStatementsStreamSub) {
                this.informationStatementsStreamSub = this.subs
                  .onTableChange('Edi', 'InformationStatements')
                  .pipe(untilDestroyed(this))
                  .subscribe((result: IAnyObject) => {
                    const informationStatement = result.data || {};
                    const targetUrl = `${window.location.origin}/consolidated-registries/information-statements/${informationStatement.id}`;

                    /** Триггер 4 - "Создание новой записи в таблице Edi.InformationStatements" */
                    if (
                      result.action === 'insert' &&
                      informationStatement.executorId !== this.currentUser.id &&
                      informationStatement.creationAuthor !== this.currentUser.id &&
                      this.playNotification()
                    ) {
                      const notifications: ISoundNotification[] = this.findInformationStatementNotifications(
                        'informationStatement',
                        'executor',
                        informationStatement,
                      );

                      if (notifications.length) {
                        const selectedSources = this.sourcesAndSignals.filter(
                          (source: ISourcesAndSignals) => source.soundNotification === notifications[0].id,
                        );

                        const sources = selectedSources.filter((source: ISourcesAndSignals) => {
                          return (
                            (source.informationStatementTypeIds === null ||
                              !source.informationStatementTypeIds.length ||
                              source.informationStatementTypeIds.find(
                                (item: string) => item === informationStatement.typeId,
                              )) &&
                            (source.informationStatementThemeIds === null ||
                              !source.informationStatementThemeIds.length ||
                              source.informationStatementThemeIds.find(
                                (item: string) => item === informationStatement.themeId,
                              )) &&
                            (source.statusLifeCycleStepIds === null ||
                              !source.statusLifeCycleStepIds.length ||
                              source.statusLifeCycleStepIds.find(
                                (item: string) => item === informationStatement.lifeCycleStepId,
                              ))
                          );
                        });

                        if (sources?.length) {
                          const source = sources[0];
                          if (!!source.pushNotificationTypeId) {
                            this.createNotification(
                              targetUrl,
                              source.pushNotificationTypeId as string,
                              source.pushNotificationText,
                              informationStatement.number,
                            );
                          } else if (!!source.imageId) {
                            this.notificationFilePath = this.sfs.getUrl((source.imageId as IImageDto).sfsId);
                            this.showImageNotification();
                          }

                          if (!!source.signal) {
                            const signalId = source.signal;
                            this.soundNotService.playSound(signalId).pipe(untilDestroyed(this)).subscribe();
                          }
                        }
                      }
                    }

                    /** Триггер 5 - "Изменение значения состояния донесения в таблице Edi.InformationStatements" */
                    if (
                      result.action === 'update' &&
                      informationStatement.executorId !== this.currentUser.id &&
                      this.playNotification()
                    ) {
                      const notifications = this.soundNotificationsSettings.filter((note: IAnyObject) => {
                        return (
                          note.soundNotificationEntityId.sysname === 'informationStatement' &&
                          note.soundNotificationOrganizationRoleId.sysname === 'recipient' &&
                          note.isActive === true &&
                          (note.organizations?.length
                            ? note.organizations?.find((org: string) => this.currentUser.organizationId.id === org) &&
                              informationStatement.recipientOrganizationId === this.currentUser.organizationId.id
                            : informationStatement.recipientOrganizationId === this.currentUser.organizationId.id) &&
                          (note.groups?.length
                            ? note.groups?.find((group: string) => this.currentUser.groups.includes(group))
                            : true)
                        );
                      });

                      if (notifications.length) {
                        const selectedSources = this.sourcesAndSignals.filter(
                          (source: ISourcesAndSignals) => source.soundNotification === notifications[0].id,
                        );

                        const sources = selectedSources.filter((source: ISourcesAndSignals) => {
                          return (
                            (source.informationStatementTypeIds === null ||
                              !source.informationStatementTypeIds.length ||
                              source.informationStatementTypeIds?.find(
                                (item: string) => item === informationStatement.typeId,
                              )) &&
                            (source.informationStatementThemeIds === null ||
                              !source.informationStatementThemeIds.length ||
                              source.informationStatementThemeIds?.find(
                                (item: string) => item === informationStatement.themeId,
                              )) &&
                            (source.statusLifeCycleStepIds === null ||
                              !source.statusLifeCycleStepIds.length ||
                              source.statusLifeCycleStepIds?.find(
                                (item: string) => item === informationStatement.lifeCycleStepId,
                              ))
                          );
                        });

                        if (sources?.length) {
                          // Если для данного триггера настроены ЗУ, получаем информацию о предыдущем состоянии донесения
                          this.informationStatement
                            .getInformationStatementByQuery({ id: result.data.id })
                            .pipe(untilDestroyed(this))
                            .subscribe((response: IInformationStatementData[]) => {
                              const prevValuesString: string = response[0].previousValues;
                              if (prevValuesString) {
                                // Парсинг предыдущего значения донесения из строки
                                const prevValuesObj: IInformationStatementData = prevValuesString
                                  .substring(1, prevValuesString.length - 1)
                                  .split(',')
                                  .map((item: string) => {
                                    const arr = item.split(':');
                                    return { [arr[0]]: arr[1].substring(1, arr[1].length - 1) };
                                  })
                                  .reduce((prev: IAnyObject, curr: IAnyObject) => {
                                    return { ...prev, ...curr };
                                  }, {});

                                if (prevValuesObj.lifeCycleStepId !== informationStatement.lifeCycleStepId) {
                                  const source = sources[0];
                                  if (!!source.pushNotificationTypeId) {
                                    this.createNotification(
                                      targetUrl,
                                      source.pushNotificationTypeId as string,
                                      source.pushNotificationText,
                                      informationStatement.number,
                                    );
                                  } else if (!!source.imageId) {
                                    this.notificationFilePath = this.sfs.getUrl((source.imageId as IImageDto).sfsId);
                                    this.showImageNotification();
                                  }

                                  if (!!source.signal) {
                                    const signalId = source.signal;
                                    this.soundNotService.playSound(signalId).pipe(untilDestroyed(this)).subscribe();
                                  }
                                }
                              }
                            });
                        }
                      }
                    }
                  });
              }
            } else {
              if (this.emergencyStreamSub) {
                this.emergencyStreamSub.unsubscribe();
                this.emergencyStreamSub = null;
              }

              if (this.eventStreamSub) {
                this.eventStreamSub.unsubscribe();
                this.eventStreamSub = null;
              }

              if (this.informationStatementsStreamSub) {
                this.informationStatementsStreamSub.unsubscribe();
                this.informationStatementsStreamSub = null;
              }
            }
          });
        },
      },
    };

    // TODO: Подписка происходит трижды. Нужно подписаться один раз. Обработку различных action через свитч реализовать
    //       в отдельных функциях.
    this.subscriberService
      .getStream()
      .pipe(
        filter(
          (event: IAbstractServiceData) =>
            event.action === 'stepFuckUp' && this.settings.getSettings().orderExecutionNotifications === 'true',
        ),
        switchMap((event: IAbstractServiceData) =>
          this.rest.serviceRequest({
            action: 'select',
            service: { name: 'Emergency' },
            entity: {
              name: 'ResponsePlanSteps',
              query: {
                id: { $in: event.data.ids },
                $or: [
                  { 'emergencyId.organization.id': this.settings.currentUser.organizationId.id },
                  { attractOrgData: this.settings.currentUser.organizationId.id },
                ],
              },
              attributes: ['orderId.number', 'orderId.id'],
            },
          }),
        ),
      )
      .subscribe((responsePlanSteps: IAbstractServiceData) =>
        (responsePlanSteps.data?.items || []).forEach((responsePlanStep: IAnyObject) => {
          if (!responsePlanStep.orderId?.id) return;

          const params: PltNotificationCarbonParamsPartial = {
            message: 'Превышено время взятия в работу поручения ',
            timestamp: false,
            type: 'error',
            autoClose: false,
            showClose: true,
            theme: 'white',
            links: [
              {
                href: `http://${window.location.host}/consolidated-registries/order-register/${responsePlanStep.orderId.id}`,
                text: responsePlanStep.orderId.number,
                target: '_blank',
                closeOnClick: true,
              },
            ],
          };
          this.notificationCarbonService.pushNotification(params);
          this.notificationCarbonService.setPosition('bottom-right');
        }),
      );

    // TODO: Подписка происходит трижды. Нужно подписаться один раз. Обработку различных action через свитч реализовать
    //       в отдельных функциях.
    this.subscriberService
      .getStream()
      .pipe(
        filter(
          (event: IAbstractServiceData) =>
            event.action === 'deadlineIsExpired' && this.settings.getSettings().orderExecutionNotifications === 'true',
        ),
        switchMap((event: IAbstractServiceData) =>
          this.rest.serviceRequest({
            action: 'select',
            service: { name: 'Emergency' },
            entity: {
              name: 'Emergency',
              query: {
                id: { $in: event.data.ids },
                'docType.sysname': 'order',
                $or: [
                  { 'organization.id': this.settings.currentUser.organizationId.id },
                  { 'parentId.organization.id': this.settings.currentUser.organizationId.id },
                ],
              },
              attributes: ['number'],
            },
          }),
        ),
      )
      .subscribe((emergencies: IAbstractServiceData) =>
        (emergencies.data?.items || []).forEach((emergency: IAnyObject) => {
          const params: PltNotificationCarbonParamsPartial = {
            message: `Превышен срок исполнения поручения `,
            timestamp: false,
            type: 'error',
            autoClose: false,
            showClose: true,
            theme: 'white',
            links: [
              {
                href: `http://${window.location.host}/consolidated-registries/order-register/${emergency.id}`,
                text: emergency.number,
                target: '_blank',
                closeOnClick: true,
              },
            ],
          };
          this.notificationCarbonService.pushNotification(params);
          this.notificationCarbonService.setPosition('bottom-right');
        }),
      );

    /** Push-уведомление о запросе на привлечение службы (триггер 7) */
    this.subs
      .onTableChange('Emergency', 'ResponsePlanSteps')
      .pipe(
        filter(
          (data: IAnyObject) =>
            data.action === 'insert' && this.settings.getSettings().orderExecutionNotifications === 'true',
        ),
        untilDestroyed(this),
      )
      .subscribe((result: IAnyObject) => {
        const autoTypeId = this.settings.getDictObjByTypeSysName('stepType')?.auto.id;
        const considerationStatusId = this.settings.getDictObjByTypeSysName('responseStepStatus')?.consideration.id;

        const obs: Observable<IEmergencyDto>[] = [];

        result.data?.forEach((step: IEmergencyResponsePlanStep) => {
          if (!step.attractOrgData && step.status === considerationStatusId && step.stepType === autoTypeId) {
            obs.push(this.emergency.getIncidentById(step.emergencyId));
          }
        });

        let emergencies = [];
        if (obs.length) {
          forkJoin(obs)
            .pipe(
              switchMap((result: IEmergencyDto[]) => {
                emergencies = result;
                return this.pages.defineWorkspace();
              }),
              untilDestroyed(this),
            )
            .subscribe((workspace: string) => {
              emergencies
                .filter(
                  (emergency: IEmergencyDto) => emergency.organization === this.settings.currentUser.organizationId.id,
                )
                .map((emergency: IEmergencyDto): PltNotificationCarbonParamsPartial => {
                  return {
                    message: 'Поступил запрос на привлечение службы в происшествии ',
                    timestamp: false,
                    type: 'error',
                    autoClose: false,
                    showClose: true,
                    theme: 'white',
                    links: [
                      {
                        href: workspace
                          ? `http://${window.location.host}/${workspace}/workspace/(editForm:incident/${emergency.id}//leftSidebar:all)`
                          : `http://${window.location.host}/consolidated-registries/order-register/${emergency.id}`,
                        text: emergency.number,
                        target: '_blank',
                        closeOnClick: true,
                      },
                    ],
                  };
                })
                .forEach((noteParam: PltNotificationCarbonParamsPartial) => {
                  this.notificationCarbonService.pushNotification(noteParam);
                  this.notificationCarbonService.setPosition('bottom-right');
                });
            });
        }
      });

    /** Обновляем текущего пользователя в системе, при изменении данных атс или номера в таблице
     * и регистрируем пользователя в гастере
     */
    this.subs
      .onTableChange('Admin', 'Users')
      .pipe(
        filter(
          (res: IAbstractServiceData) =>
            res.data.id === this.settings.currentUser.id && res.data.atsPhone && res.data.atsQueue,
        ),
        filter((res: IAbstractServiceData) => {
          const prevUserData = this.settings.currentUser;
          const newUserData = res.data;
          return (
            prevUserData?.atsPhone?.id !== newUserData.atsPhone || prevUserData?.atsQueue?.id !== newUserData.atsQueue
          );
        }),
        switchMap(() => this.settings.loadCurrentUser()),
        untilDestroyed(this),
      )
      .subscribe((user: IAnyObject) => {
        this.settings.currentUser = user;
        this.gaster.registerPhone(this.settings.currentUser);
      });
  }

  /** Получаем все звуковые уведомления */
  public getSoundNotificationsSettings(): void {
    this.bgSoundNotService
      .getEmergencySoundNotificationByQuery()
      .pipe(untilDestroyed(this))
      .subscribe((response) => (this.soundNotificationsSettings = response));
  }

  /** Получаем все настройки ЗУ */
  public getSourcesAndSignalsSettings(): void {
    this.bgSoundNotService
      .getSourcesAndSignalsByQuery()
      .pipe(untilDestroyed(this))
      .subscribe((response) => (this.sourcesAndSignals = response));
  }

  /** Поиск подходящих триггеру уведомлений  */
  public findNotifications(entity: string, role: string, emergency: IAnyObject): ISoundNotification[] {
    return this.soundNotificationsSettings.filter((note: IAnyObject) => {
      return (
        note.soundNotificationEntityId?.sysname === entity &&
        note.soundNotificationOrganizationRoleId?.sysname === role &&
        note.isActive === true &&
        (note.groups?.length ? note.groups.find((group: string) => this.currentUser.groups.includes(group)) : true) &&
        // Если поле Организация не заполнено, проигрываем уведомление только для пользователей, у которых
        // организация - является ответственной организацией происшествия
        (note.organizations?.length
          ? note.organizations?.find((org: string) => this.currentUser.organizationId.id === org)
          : emergency.organization === this.currentUser.organizationId.id ||
            emergency.organizationId === this.currentUser.organizationId.id)
      );
    });
  }

  /** Поиск звуковых уведомлений для донесений */
  public findInformationStatementNotifications(
    entity: string,
    role: string,
    informationStatement: IAnyObject,
  ): ISoundNotification[] {
    /**
     * Если организации в ЗУ заполнены, то смотрим, совпадает ли организация с организацией в ЗУ и орг. исполнителем в донесении.
     * Если не заполнены, проигрываем для пользователей организации, указанной исполнителем в донесении.
     **/
    return this.soundNotificationsSettings.filter((note: IAnyObject) => {
      return (
        note.soundNotificationEntityId?.sysname === entity &&
        note.soundNotificationOrganizationRoleId?.sysname === role &&
        note.isActive === true &&
        (note.groups?.length ? note.groups.find((group: string) => this.currentUser.groups.includes(group)) : true) &&
        (note.organizations?.length
          ? note.organizations?.find((org: string) => this.currentUser.organizationId.id === org) &&
              informationStatement.executorOrganizationId === this.currentUser.organizationId.id
          : // Если поле Организация не заполнено, проигрываем уведомление только для пользователей, у которых
          // организация - является организацией исполнителем/получателем донесения
          role === 'executor'
            ? informationStatement.executorOrganizationId === this.currentUser.organizationId.id
            : informationStatement.recipientOrganizationId === this.currentUser.organizationId.id)
      );
    });
  }

  /** Не проигрывать уведомление, если вкладка не активна, или идет вызов */
  public playNotification(): boolean {
    return !window.document.hidden && this.call.getCurrentState() === 'hangup';
  }

  /**
   * Создание оповещения
   *
   * @param  targetUrl Ссылка на происшествие
   * @param  pushNotificationTypeId Тип оповещения для подбора цвета
   * @param  pushNotificationText Текст оповещения
   * @param  eventNumber Номер происшествия
   */
  private createNotification(
    targetUrl: string,
    pushNotificationTypeId: string,
    pushNotificationText: string,
    eventNumber: string,
  ) {
    let notificationColor: string;

    switch (this.settings.getDictionaryById(pushNotificationTypeId)?.sysname) {
      case 'threat':
        notificationColor = '#FF4D4F';
        break;
      case 'warning':
        notificationColor = '#FAAD14';
        break;
      case 'alert':
        notificationColor = '#1890FF';
        break;
    }
    const notificationTemplate = this.notificationService.template(this.notificationTemplate!, {
      nzData: {
        targetUrl,
        eventNumber,
        date: dayjs(),
        pushNotificationText,
      },
      nzDuration: 0,
      nzStyle: {
        backgroundColor: notificationColor,
        textAlign: 'center',
        borderRadius: '10px',
        color: 'white',
      },
    });
    this.notificationMap.set(eventNumber, notificationTemplate.messageId);
  }

  /**
   * Отображение события в новой вкладке
   * @param targetUrl - ссылка на происшествие
   */
  public showEventInNewTab(targetUrl: string, eventNumber: string) {
    this.notificationService.remove(this.notificationMap.get(eventNumber));
    this.notificationMap.delete(eventNumber);
    window.open(targetUrl);
  }

  /** Отображение оповещения с изображением */
  private showImageNotification() {
    this.modal.create({
      nzContent: this.notificationImage,
      nzFooter: null,
      nzClassName: 'imageBackground',
      nzCentered: true,
      nzClosable: false,
      nzMaskStyle: { background: 'transparent' },
    });
  }
}
