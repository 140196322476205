import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@bg-front/core/components';
import { GridTableComponent, IGridTableOptions } from '@smart-city/core/common';
import * as moment from 'moment';
import { of } from 'rxjs';
import { IAnyObject } from 'smart-city-types';
import { RegimesService } from '../../../services';

/**
 * Копмпонента реализует диалоговое окно со списком инцидентов для связи с режимом функционирования
 */
@Component({
  selector: 'bg-emergencies-linking-dialog',
  templateUrl: './emergencies-linking-dialog.component.html',
  styleUrls: ['./emergencies-linking-dialog.component.scss'],
})
export class EmergenciesLinkingDialogComponent extends BaseComponent {
  /** Компонент грида */
  @ViewChild('gridComponent', { static: false }) private gridComponent: GridTableComponent;

  /** Настройки грида */
  public gridOptions: IGridTableOptions = {
    height: '100%',
    name: 'emergency',
    restVersion: 'modern',
    service: { name: 'Emergency' },
    entity: 'Emergency',
    query: {
      $expr: {
        $and: [
          {
            $expr: {
              $or: [
                // { $expr: { $eq: ['$regimeId.id', ''] } },
                { $expr: { $eq: ['$regimeId.id', null] } },
              ],
            },
          },
          { $expr: { $eq: ['$docType.sysname', 'incident'] } },
        ],
      },
    },
    sort: {
      field: 'creationTime',
      direction: 'desc',
    },
    isInfiniteScroll: true,
    showTotalRecords: true,
    allowSelectCheckbox: true,
    allowSelect: false,
    controls: [
      {
        name: 'reload',
        icon: 'refresh',
        hint: 'Обновить',
        callback: () => of({ actionStream: 'reload' }),
      },
      {
        name: 'insert',
        icon: 'add',
        color: 'primary',
        hint: 'Связать инциденты',
        callback: () => {
          this.linkIncidents();
          return of({ actionStream: 'donothing' });
        },
      },
    ],
    onSelectRow: (row: IAnyObject) => null,
    deleteFn: (params: { ids: string[]; grid: GridTableComponent }) => of(null),
    fields: [
      { name: 'addressFact.fullText', title: 'Адрес' },
      { name: 'incidentTypeId.name', title: 'Тип КСиП' },
      { name: 'timeCreate', title: 'Дата начала' },
      { name: 'organization.name', title: 'Организация' },
    ],
    onLoadData: (data: IAnyObject[]) => {
      return data.map((datum: IAnyObject) => {
        datum.timeCreate = datum.timeCreate ? moment(datum.timeCreate).format('DD.MM.YYYY HH:mm:ss') : '';

        return datum;
      });
    },
  };

  constructor(
    public dialogRef: MatDialogRef<EmergenciesLinkingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private regimesService: RegimesService,
  ) {
    super();
  }

  /** Закрыть это окно */
  public close(): void {
    this.dialogRef.close();
  }

  /** Привязать события к режиму функционирования */
  public linkIncidents(): void {
    this.dialogRef.close(this.gridComponent.getCheckedIds());
  }
}
