import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IUserInfoDto } from 'smart-city-types';
import { Observable, of } from 'rxjs';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { RestService } from '@smart-city/core/services';
import { BgAdminService } from '../bg-admin/bg-admin.service';

/**
 * Сервис по определению открытого рабочего стола пользователя
 */
@Injectable({
  providedIn: 'root',
})
export class PagesService {
  constructor(
    private readonly settings: Settings2Service,
    private readonly rest: RestService,
    private readonly admin: BgAdminService,
  ) {}

  /** Алгоритм определения рабочего стола для открытия формы обработки звонка */
  public defineWorkspace(): Observable<string> {
    const user = this.settings.currentUser;

    return this.admin.getUserById(user.id).pipe(
      switchMap((res: IUserInfoDto) => {
        // Рабочий стол пользователя по умолчанию
        const defaultWorkspace: string = res.defaultWorkspace;
        /** Ессли у пользователя указан рабочий стол по умолчанию, возвращаем его */
        if (defaultWorkspace) {
          const workSpaceSysName = this.settings.getDictionaryByTypeSysName('sourceCallsWorkspace')
            .filter((item: IDictionaryInfo) => item.id === defaultWorkspace)[0].sysname;
          if (workSpaceSysName === 'eddsWorkspace') {
            return of('edds');
          } else if (workSpaceSysName === 'cuksWorkspace') {
            return of('cuks');
          }
        } else {
          /** Иначе ищем рабочие столы в группе прав пользователя */
          return this.rest.serviceRequest({
            action: 'select',
            service: { name: 'Admin' },
            entity: {
              name: 'RuleGroups',
              query: {
                id: { $in: user.groups },
              },
            }
          }).pipe(
            switchMap((res: IAbstractServiceData) => {
              const userGroups = res.data.items;
              const pages: IAnyObject[] = userGroups.reduce((prev, group) => [...prev, group.pages], []);
              const pageIds: string[] = pages[0].map(page => page.pageId);

              return this.rest.serviceRequest({
                action: 'select',
                service: { name: 'Admin' },
                entity: {
                  name: 'Pages',
                  query: {
                    id: { $in: pageIds },
                    alias: 'workspace',
                  },
                },
              })
            }),
            switchMap((res: IAbstractServiceData) => {
              const workspacePages: IAnyObject[] = res.data.items;
              /** Если найдена только одна страница, возвращаем ее */
              if (workspacePages && workspacePages.length === 1) {
                return this.rest.serviceRequest({
                  action: 'select',
                  service: { name: 'Admin' },
                  entity: {
                    name: 'Pages',
                    query: {
                      id: workspacePages[0].parentid,
                    },
                  },
                });
              }
              return of(null);
            }),
            switchMap((res: IAbstractServiceData) => {
              if (res?.data?.items) {
                const workspace: IAnyObject = res.data.items[0];
                return of(workspace.alias);
              }
              return of(null);
            })
          )
        }
      })
    )
  }
}
