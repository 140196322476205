import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { IAnyObject, ILifeCycleStepActionDto, ILifeCycleStepDto  } from 'smart-city-types';
import { catchError, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseRequestsV2Component } from '../base-requests-v2/base-requests-v2.component';
import { EmergencyService, LifeCycleService } from '../../services';
import { ONE_DAY, ONE_MINUTE } from '@bg-front/core/models/constants';
import { IInformationRequestForm } from '../../models/interfaces';

/** Компонент формы запроса видеоархива */
@UntilDestroy()
@Component({
  selector: 'request-video',
  templateUrl: './request-video.component.html',
})
export class RequestVideoComponent extends BaseRequestsV2Component implements OnInit {
  /** id камеры для запроса */
  public cameraId: string;
  /** Форма */
  public form: FormGroup<ControlsOf<IInformationRequestForm>>;

  /** @ignore */
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly lifeCycle: LifeCycleService,
    private readonly emergencyService: EmergencyService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe((params: ParamMap) => {
      this.cameraId = params.get('id');
    });

    this.form = new FormGroup<ControlsOf<IInformationRequestForm>>({
      from: new FormControl(undefined, [Validators.required]),
      requestDeadline: new FormControl(undefined),
      durationInput: new FormControl(1, [Validators.max(60)]),
      reason: new FormControl(undefined, [Validators.required]),
      deleteTime: new FormControl(undefined),
      remindDate: new FormControl({ value: undefined, disabled: true }),
      permanent: new FormControl(undefined),
    });

    /** Получение шага ЖЦ */
    this.lifeCycle.getLifeCycleStepByQuery({
        'lifeCycleId.type.sysname': 'request',
        'lifeCycleId.orgTypeParam.organizationTypeId.sysname': 'securityForces',
        'status.sysname': 'new',
      },
      ['id', 'params'],
    ).pipe(
      switchMap((step: ILifeCycleStepDto) => {
        this.disableControls(step?.params);
        return this.emergencyService.getLifeCycleButtonsById(step.id)
      }),
      catchError((err: Error) => {
        return this.catchErrorFn<ILifeCycleStepActionDto[]>(
          err,
          'Ошибка при создании запроса. Проверьте настройку жизненного цикла',
          [],
        );
      }),
      untilDestroyed(this)
    )
      .subscribe((actions: ILifeCycleStepActionDto[]) => {
        this.actions = actions;
      });

    super.ngOnInit();
  }

  /** Конвертация даты в timestamp(ms) */
  public convertDate(date: Date) {
    return date ? +date.valueOf() : null;
  }

  /** Получение модели запроса */
  public getRequestModel(action: string): IAnyObject {
    const from = this.convertDate(this.form.controls.from.value);
    const deleteTime = this.form.controls.deleteTime.value
      ? this.convertDate(this.form.controls.deleteTime.value) - this.timezoneOffset
      : null;

    return {
      cameraId: this.cameraId,
      from: from,
      to: from + this.form.controls.durationInput.value * ONE_MINUTE,
      requestDeadline: this.convertDate(this.form.controls.requestDeadline.value),
      reason: this.form.controls.reason.value,
      permanent: this.form.controls.permanent.value,
      deleteTime: deleteTime,
      remindDate: deleteTime
        ? deleteTime - this.form.controls.remindDate.value * ONE_DAY
        : null,
      lifeCycleStepId: this.actions.find((item: ILifeCycleStepActionDto) => item.name === action).nextStep,
    }
  }

  /** Выполнить действие с запросом */
  public performRequest(action: string): void {
    this.performAction(action, this.getRequestModel(action))
      .pipe(
        catchError((error: Error) =>
          this.catchErrorFn(error, 'Ошибка создания запроса на получения доступа к видеоархиву'),
        ),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.noteService.pushInfo(this.getInfo(action, 'create'));
        this.close();
      });
  }

  /** Сохранить запрос */
  public save(): void {
    this.performRequest('toDraft');
  }

  /** Отправить запрос */
  public sendRequest(): void {
    this.performRequest('toGeneration');
  }

  /** Закрытие формы */
  public close() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }
}
