import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatSlider } from '@angular/material/slider';
import {
  DialogService,
  ICallbackFnParams,
  IElementButton,
  INwFormResult,
  INwHeaderBarOptions,
  IScCheckboxOptions,
  IScInputOptions,
  IScSelectOptions,
  IScTextareaOptions,
  NwFormComponent,
  NwFormPopupService,
} from '@smart-city/core/common';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import * as dayjs from 'moment';
import { Observable, Observer, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, ILifeCycleStepActionDto, ILifeCycleStepParamDto } from 'smart-city-types';

import { BaseComponent } from '@bg-front/core/components';
import { RequestsService, VideoDevicesService } from '../../services';
import { IInformationRequestData } from '../../models/interfaces/information-request-data.interface';
import { IRequestActionResult } from '../../models/interfaces';
import { AppInjector } from '@bg-front/core/models/classes';
import { MultiFileService } from '@bg-front/core/services';
import { ONE_DAY, ONE_MINUTE } from '@bg-front/core/models/constants';
import { IDateTimeOptions } from '@bg-front/core/models/interfaces';
import { BgDatetimeValidator, RemindDateValidator, RequestDeleteTimeValidator } from '@bg-front/core/validators';

/**
 * Базовый класс компонентов запросов на предоставление информации
 * Реализует логику создания/удаления/изменения записей запросов
 */
@Component({
  template: '',
})
export abstract class BaseRequestsComponent extends BaseComponent implements OnInit {
  /** Ссылка на компонент слайдер */
  @ViewChild('durationSlider', { static: false }) public durationSlider: MatSlider;

  /** Кнопки/Действия шага ЖЦ запроса */
  @Input()
  public actions: ILifeCycleStepActionDto[] = [];

  @Input()
  public request: IInformationRequestData;

  /** Событие закрытия формы */
  @Output()
  public closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected settings: Settings2Service;
  private dialog: DialogService;
  private form: NwFormPopupService;
  private requestService: RequestsService;
  private videoDevicesService: VideoDevicesService;
  private multiFileService: MultiFileService;
  private access: AccessService;
  /** Максимальное значение слайдера */
  public durationMax: number;
  /** Форма */
  public requestForm: FormGroup;
  /** Список аттрибутов */
  public attributes: ILifeCycleStepParamDto[] = [];
  /** Смещение часового пояса */
  protected timezoneOffset: number = new Date().getTimezoneOffset() * ONE_MINUTE;

  /** Настройка компоненты Видеокамера */
  public cameraIdOptions: IScSelectOptions = {
    title: 'Видеокамера *',
    service: 'Admin',
    entity: 'VideoDevices',
    fieldName: 'name',
    modern: true,
    query: { active: true },
  };

  /** Настройка компоненты Дата и время начала */
  public fromOptions: IDateTimeOptions = {
    label: 'Дата и время начала *',
    format: 'DD.MM.YYYY HH:mm:ss',
    dataType: 'unix',
  };

  /** Настройка компоненты Обработать запрос до */
  public requestDeadlineOptions: IDateTimeOptions = {
    label: 'Обработать запрос до',
    format: 'DD.MM.YYYY',
    dataType: 'unix',
  };

  /** Настройка компоненты Основание */
  public reasonOptions: IScTextareaOptions = {
    label: 'Основание *',
    placeholder: 'Основание',
    rows: 6,
    minRows: 6,
    maxRows: 6,
    maxLength: 1000,
  };

  /** Настройка компоненты Долговременное хранение */
  public permanentOptions: IScCheckboxOptions = {
    title: 'Долговременное хранение',
  };

  /** Настройка компоненты даты удаления видео */
  public deleteTimeOptions: IDateTimeOptions = {
    label: 'Хранить информацию до',
    format: 'DD.MM.YYYY',
    dataType: 'unix',
  };

  /** Настройка компоненты Напомнить за (в днях) */
  public remindDateOptions: IScInputOptions = {
    label: 'Напомнить за (в днях)',
    placeholder: 'Напомнить за (в днях)',
    type: 'number',
    // mask: 'P',
    // customPatterns: { P: { pattern: new RegExp('[1-7]') } },
  };

  /** Сообщения валидатора поля "Напомнить за (в днях)" */
  public remindDateErrors = {
    inRange: 'Введите значение от 1 до 7',
  };

  /** Сообщения валидатора поля "Хранить информацию до" */
  public deleteTimeErrors = {
    tooLong: 'Доступный период хранения информации не более одного года',
    minDate: 'Не ранее текущей даты',
  };

  public requestDeadlineErrors = {
    minDate: 'Не ранее текущей даты',
  };

  /** Ограничитель максимального значения для времени с которого должно начинаться видео. */
  public fromMaxDate = dayjs().unix() * 1000 - ONE_MINUTE;
  /** Ограничитель даты для контрола "Хранить информацию до" */
  public deleteTimeMinDate = dayjs().hour(0).minute(0).second(0).unix() * 1000;
  /** Огрничитель минимальной даты для "Обработать до" */
  public minDateTimeResolveTo = dayjs().hour(0).minute(0).second(0).unix() * 1000;

  /** Конфигурация заголовка */
  public headerActionsOptions: INwHeaderBarOptions;

  /** @ignore */
  constructor() {
    super();
    const injector = AppInjector.getInjector();
    this.settings = injector.get(Settings2Service);
    this.dialog = injector.get(DialogService);
    this.form = injector.get(NwFormPopupService);
    this.requestService = injector.get(RequestsService);
    this.videoDevicesService = injector.get(VideoDevicesService);
    this.multiFileService = injector.get(MultiFileService);
    this.access = injector.get(AccessService);
  }

  /** @ignore */
  ngOnInit() {
    // Подписка на изменение даты удаления видео для актуализации значения даты напоминания.
    // Исключение возможности указать прошлое.
    this.requestForm.controls.deleteTime.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      if (!value) {
        this.setRemindDateStateAndValidation(0);
        return;
      }
      // Определение максимального значения напоминания
      let max = (value - new Date().setHours(0, 0, 0, 0)) / ONE_DAY;
      // Если значение больше семи то установить значение семь
      max = max > 7 ? 7 : max;

      // Обновление сообщения об ошибке, в соответствии с максимальным значением
      this.remindDateErrors = {
        inRange: `Введите значение от 1 до ${max}`,
      };
      this.setRemindDateStateAndValidation(max);
    });
  }

  /**
   * Установка состояния и валидации контрола Напомнить за (в днях) в зависимости от максимального значения напоминания
   * @param max максимальное значение за которое необходимо начать напоминание
   * @param isInit признак что выполняется первичная инициализация формы
   */
  protected setRemindDateStateAndValidation(max: number, isInit: boolean = false) {
    // Если значение менее или равно одному дню, то выводить уведомление не требуется
    // При открытии запроса на просмотр, возможна ситуация когда дата напоминания была указана ранее
    // и на текущий момент она больше чем максимальное значение. Надо вывести ее, и не сбрасывать значение контрола.
    if (max <= 1 && !isInit) {
      if (max === 1) {
        this.requestForm.controls.remindDate.setValue(1);
      } else {
        this.requestForm.controls.remindDate.setValue('');
      }
      this.remindDateOptions.label = 'Напомнить за (в днях)';
      this.remindDateOptions.placeholder = 'Напомнить за (в днях)';
      this.requestForm.controls.remindDate.disable();
      this.requestForm.controls.remindDate.clearValidators();
      this.requestForm.controls.remindDate.setErrors(undefined, { emitEvent: false });
      // В противном случае уведомление необходимо
    } else {
      this.requestForm.controls.remindDate.enable({ emitEvent: false });
      this.remindDateOptions.label = 'Напомнить за (в днях) *';
      this.remindDateOptions.placeholder = 'Напомнить за (в днях) *';
      // Инициализация deleteTime приводит к срабатыванию подписки и как следствие к нежелательной установке значения
      // в remindDate.
      // Для исключения этого добавлена проверка - сбросить значение только если до этого не было значений.
      if (!this.requestForm.controls.remindDate.value) {
        this.requestForm.controls.remindDate.setValue(1, { emitEvent: false });
      }
      // Обновление валидатора
      this.requestForm.controls.remindDate.setValidators([Validators.required, RemindDateValidator.inRange(1, max)]);

      // Валидация remindDate. setTimeout необходим чтобы применилось новая формулировка ошибки remindDateErrors
      if (this.requestForm.controls.remindDate.value && !isInit) {
        setTimeout(() => {
          this.requestForm.controls.remindDate.updateValueAndValidity();
        }, 0);
      }
    }
  }

  /**
   * Метод создания/сохранения/удаления записи
   * @request - иформация об запросе
   */
  public performAction(action: string, request: IInformationRequestData): Observable<IRequestActionResult> {
    // Флаг для обозначения необходимости запустить процедуру передачи видео в долговременное хранение
    // после получения обновленных данных о запросе.
    let needPerformVideo = false;
    return new Observable((o: Observer<string | void>) => {
      // Если не "Сохранить" то указать дату обновления статуса
      if (action !== 'toDraft') {
        request.updateTime = Date.now();
      }
      // Если "Отозвать" то затереть причину отказа,
      // пользователя который запросил и пользователя который обработал запрос
      if (action === 'recall') {
        request.rejectionReason = null;
        request.executorId = null;
        request.initiatorId = null;

        // При отзыве надо попытаться удалить видео из архива.
        // Его может не быть, но нужно пометить запись как неактивную
        this.requestService
          .removeArchive(this.request.id)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            // Если в процессе удаления произошла ошибка (видео не было), то игнорировать это.
            // Главное что запись помечена как неактивная
            catchError(() => {
              return of(null);
            }),
          )
          .subscribe();
      }
      // Если "Одобрить" или "Отклонить" то укажем сотрудника который обработал запрос
      if (action === 'approve' || action === 'reject') {
        request.executorId = this.settings.currentUser.id;
      }
      // Если "Одобрить", то затереть причину отказа
      if (action === 'approve') {
        request.rejectionReason = null;
      }
      // Если "Отправить в обработку", то укажем сотрудника который запросил информацию
      if (action === 'inProcess') {
        request.initiatorId = this.settings.currentUser.id;
        if (!request.permanent) {
          const text = [
            'Не выбран режим долговременного хранения. Видео будет доступно в зависимости от настроек хранения архива на видеосервере.',
            'Вы действительно хотите отправить запрос в обработку?',
          ];
          // Диалог подтверждающий сохранение без даты удаления запроса
          this.openDialog(text, 'yesno').subscribe((data) => {
            if (data.actionStream === 'yes') {
              o.next();
              o.complete();
            }
          });
          return;
        }
      }
      // Если "Удалить", то откроем диалог подтверждающий удаление
      if (action === 'delete') {
        const text = ['Вы действительно хотите удалить эту запись?'];
        // Диалог подтверждающий удаление запроса
        this.openDialog(text, 'yesno').subscribe((data) => {
          if (data.actionStream === 'yes') {
            o.next();
            o.complete();
          }
        });
        return;
      }
      // Если "Отклонить", то откроем форму с заполнением причины отказа
      if (action === 'reject') {
        this.reject().subscribe((data) => {
          if (data.action === 'ok') {
            request.rejectionReason = data.form.formGroup.value.rejectionReason;
            // При отклонении надо попытаться удалить видео из архива.
            // Его может не быть, но нужно пометить запись как неактивную
            this.requestService
              .removeArchive(this.request.id)
              .pipe(
                takeUntil(this.ngUnsubscribe),
                // Если в процессе удаления произошла ошибка (видео не было), то игнорировать это.
                // Главное что запись помечена как неактивная
                catchError(() => {
                  return of(null);
                }),
              )
              .subscribe();
            o.next();
            o.complete();
          }
        });
        return;
      }
      // Если "Отправить в обработку"(Сформировать запрос), то укажем сотрудника который запросил информацию
      if (action === 'toGeneration') {
        request.initiatorId = this.settings.currentUser.id;
        if (!request.permanent) {
          const text = [
            'Не выбран режим долговременного хранения. Видео будет доступно в зависимости от настроек хранения архива на видеосервере.',
            'Вы действительно хотите отправить запрос в обработку?',
          ];
          // Диалог подтверждающий сохранение без даты удаления запроса
          this.openDialog(text, 'yesno').subscribe((data) => {
            if (data.actionStream === 'yes') {
              // Так же в этом случае нужно попробовать сформировать ссылку на видео, для определения в какое состояние
              // перевести запрос 'Успех' или 'Ошибка'
              this.videoDevicesService
                .getVideoDeviceRangeStreamUrl(request.cameraId as string, request.from as number, 1000)
                .subscribe((url: string) => {
                  o.next(url || null);
                  o.complete();
                });
            }
          });
          return;
        }
        // Если дата удаления запроса указана, надо запустить микросервис фонового скачивания видео
        const text = [
          'После успешного сохранения видео запрос будет автоматически отправлен в обработку. В противном случае запрос будет отклонён и Вам необходимо будет скорректировать параметры сохранения или обратиться в техподдержку',
        ];
        this.openDialog(text, 'ok').subscribe((data) => {
          if (data.actionStream === 'ok') {
            needPerformVideo = true;
            o.next();
            o.complete();
          }
        });
        return;
      }
      // Если "Сохранить" в статусе "Одобрен" и изменилось значение долговременного хранения
      if (action === 'save' && this.request.permanent !== request.permanent) {
        if (request.permanent) {
          const text = [
            'Видео будет отправлено на долговременное хранение.',
            'Необходимо время для получения видео с видеосервера и загрузки в Систему.',
            'При возникновении ошибок обратитесь в техподдержку',
          ];
          this.openDialog(text, 'ok').subscribe((data) => {
            if (data.actionStream === 'ok') {
              if (!this.request.video.sfsId) {
                // Если нет подготовленного видео, то укажем необходимость запустить процесс его формирования
                needPerformVideo = true;
              } else {
                // Если есть подготовленное видео, то обновим сроки его хранения.
                this.requestService
                  .updateArchive(this.request.id, <number>request.deleteTime)
                  .pipe(
                    takeUntil(this.ngUnsubscribe),
                    catchError((err: Error) => {
                      return this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка изменения срока хранения видео');
                    }),
                  )
                  .subscribe();
              }
              o.next();
              o.complete();
            }
          });
          return;
        }

        const text = [
          'Видео будет удалено из хранилища для долговременного хранения.',
          'Просмотр и экспорт будут доступны в зависимости от настроек хранения архива на видеосервере.',
          'Продолжить?',
        ];
        // Диалог предупреждающий, что видео будет удалено из хранилища
        this.openDialog(text, 'yesno').subscribe((data) => {
          if (data.actionStream === 'yes') {
            this.requestService
              .removeArchive(this.request.id)
              .pipe(
                takeUntil(this.ngUnsubscribe),
                catchError((err: Error) => {
                  return this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка удаления видео из архива');
                }),
              )
              .subscribe();
            o.next();
            o.complete();
          }
        });
        return;
      }
      // Если "Сохранить" в статусе "Одобрен" и изменилась дата удаления видео при условии что флаг долговременного
      // хранения не изменился и остался true (данное условие перехвачено в блоке выше)
      if (action === 'save' && this.request.deleteTime !== request.deleteTime) {
        this.requestService
          .updateArchive(this.request.id, <number>request.deleteTime)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            catchError((err: Error) => {
              return this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка изменения срока хранения видео');
            }),
          )
          .subscribe();
        o.next();
        o.complete();
      }
      o.next();
      o.complete();
    }).pipe(
      switchMap((result) => {
        // Если результат не равен undefined, то была попытка сформировать URL на видео с камеры.
        // В зависимости от результата надо перевести запрос в состояние Успех или Ошибка.
        if (result !== undefined) {
          request.videoUrl = result ? result : null;
          // В случае ошибки нужно заполнить причину отказа.
          request.rejectionReason = !result
            ? 'Не удалось получить видео с видеосервера. Пожалуйста, обратитесь в техподдержку.'
            : null;
          const action = !!request.videoUrl ? 'success' : 'fail';
          request.lifeCycleStepId = this.actions.find((a) => a.name === action).nextStep;
        }
        // Выполним необходимое действие
        return action === 'delete'
          ? this.requestService.delete(request.id)
          : this.multiFileService.saveFilesToSfs(request.documents).pipe(
              switchMap((documents) => {
                request.documents = documents;
                return this.requestService.save(request);
              }),
            );
      }),
      // Если реквест изменился, запросим обновленную информацию о нем
      switchMap((result: IAbstractServiceData) =>
        action === 'delete'
          ? of({})
          : // Если выполняется действие Формирование запроса необходимо попытаться
            // получить ссылку на видео
            this.requestService.getRequestData(result.data.id, action === 'toGeneration'),
      ),
      map((data: IRequestActionResult) => {
        // Если по предыдущей логике надо отправить видео в долговременное хранение, то сделать это
        if (needPerformVideo) {
          this.requestService
            .prepareVideo(data.request)
            .pipe(
              takeUntil(this.ngUnsubscribe),
              catchError((err: Error) => {
                return this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка создания видеоархива');
              }),
            )
            .subscribe();
        }
        data.action = action;
        return data;
      }),
      catchError((err: Error) => {
        return this.catchErrorFn<IAbstractServiceData>(
          err,
          action === 'delete'
            ? 'Невозможно удалить запись, которая используется в системе.'
            : 'В процессе выполнения действия произошла ошибка',
          { action: 'donothing' },
        );
      }),
    );
  }

  /**
   * Открытие диалога для подтверждения действия
   * @param text - текст диалога
   * @param type - тип диалога. Кнопки Да/Нет или одна кнопка ОК
   */
  private openDialog(text: string[], type: 'yesno' | 'ok'): Observable<IAnyObject> {
    return this.dialog.open({
      text,
      title: 'Внимание!',
      buttons:
        type === 'yesno'
          ? [
              {
                name: 'no',
                title: 'Нет',
                type: 'mat-flat-button',
                color: 'primary',
                callback: () => of({ action: 'no' }),
              },
              {
                name: 'yes',
                title: 'Да',
                type: 'mat-flat-button',
                color: 'warn',
                callback: () => of({ action: 'yes' }),
              },
            ]
          : [
              {
                name: 'ok',
                title: 'OK',
                type: 'mat-flat-button',
                color: 'primary',
                callback: () => of({ action: 'ok' }),
              },
            ],
    });
  }

  /**
   * Открывает форму заполнения причины отказа
   */
  public reject(): Observable<INwFormResult> {
    return this.form.openForm({
      value: {},
      form: {
        layoutForm: {
          layout: {
            columns: [
              {
                width: '500px',
                elements: [
                  {
                    type: 'textarea',
                    options: {
                      name: 'rejectionReason',
                      label: 'Причина отказа',
                      placeholder: 'Причина отказа',
                      rows: 6,
                      minRows: 6,
                      maxRows: 6,
                      validators: [{ required: true, maxLength: 1000 }],
                      required: true,
                      maxLength: 1000,
                    },
                  },
                  {
                    type: 'button',
                    options: {
                      name: 'cancel',
                      title: 'Отмена',
                      stdBehavior: true,
                    },
                  },
                  {
                    type: 'button',
                    options: {
                      name: 'ok',
                      title: 'OK',
                      callback: (params: ICallbackFnParams) => {
                        (<NwFormComponent>params.formComponent).onCloseForm({ action: 'ok' });
                        return of({ action: 'ok' });
                      },
                      color: 'primary',
                    },
                  },
                ],
              },
            ],
          },
        },
      },
    });
  }

  /**
   * Получение информационного сообщения в зависимости от действия
   * @param stepAction - действие над запросом
   * @param formAction - действие формы
   */
  public getInfo(stepAction: string, formAction?: string): string {
    switch (stepAction) {
      case 'toDraft':
        return formAction === 'create' ? 'Запись успешно создана' : 'Запись успешно изменена';
      case 'save':
        return 'Запись успешно изменена';
      case 'inProcess':
        return 'Запись отправлена в обработку';
      case 'approve':
        return 'Запись успешно одобрена';
      case 'recall':
        return 'Запись успешно отозвана';
      case 'reject':
        return 'Запись успешно отклонена';
      case 'close':
        return 'Запись успешно закрыта';
      case 'toGeneration':
        return 'Запись отправлена на формирование';
      case 'fail':
        return 'Ошибка при формировании видео';
      case 'success':
        return 'Запись отправлена в обработку';
    }
  }

  /**
   * Получение иконки для кнопки в зависимости от действия
   * @param buttonName - наименование кнопки
   */
  public getIcon(buttonName: string): string {
    switch (buttonName) {
      case 'toDraft':
        return 'done';
      case 'delete':
        return 'delete_outline';
      default:
        return 'assignment';
    }
  }

  /**
   * Обработка изменения значения слайдера для актуализации значения инпута продолжительности видео
   */
  public onDurationSliderChange(value: number) {
    this.requestForm.controls.durationInput.setValue(value, { emitEvent: false });
  }

  /**
   * Обработка изменения значения инпута продолжительности видео для актуализации значения слайдера и
   * блокировки ввода значения которое превышает максимальную продолжительность видео.
   */
  public onDurationInputChange() {
    if (this.requestForm.controls.durationInput.value > this.durationMax) {
      this.requestForm.controls.durationInput.patchValue(
        Math.floor(this.requestForm.controls.durationInput.value / 10),
      );
    } else if (this.requestForm.controls.durationInput.value < 1) {
      this.requestForm.controls.durationInput.patchValue(1);
    }
    this.requestForm.controls.durationSlider.patchValue(this.requestForm.controls.durationInput.value);
  }

  /**
   * Генерация списка кнопок
   */
  protected generateButtonsList(): IElementButton[] {
    const buttons = this.actions
      .sort((a: ILifeCycleStepActionDto, b: ILifeCycleStepActionDto) => b.order - a.order)
      .map((btn: ILifeCycleStepActionDto) => {
        return <IElementButton>{
          type: 'button',
          options: {
            name: btn.name,
            title: btn.title,
            icon: this.getIcon(btn.name),
            hidden: btn.autoChange,
          },
        };
      });
    buttons.unshift(<IElementButton>{
      type: 'button',
      options: {
        name: 'cancel',
        icon: 'clear',
      },
    });
    // Если запрос открыт на редактирование а не на создание (т.е. есть данные об авторе запроса,
    // то определить активность полей в зависимости от пользователя и ЖЦ.
    if (this.request?.creationAuthor) {
      buttons.forEach((item: IElementButton) => {
        // Кнопки Отозвать, Закрыть, Сохранить доступны только автору запроса
        if (
          ['recall', 'close', 'toDraft', 'save'].includes(item.options.name) &&
          this.request.creationAuthor === this.settings.currentUser.id
        ) {
          item.options.hidden = false;
        } else {
          // Видимость других кнопок или в случае работы с чужим запросом определяется на основании настроек роли.
          // Если явно не указано что кнопка доступна, то считаем что она недоступна
          item.options.hidden = !this.access.accessMap[item.options.name]?.enabled;
        }
      });
    }
    return buttons;
  }

  /**
   * Обработка изменения даты и времени начала запрашиваемого видео для установки его максимальной продолжительности
   */
  public onFromChange() {
    this.durationMax = Math.floor((this.fromMaxDate - this.requestForm.controls.from.value) / ONE_MINUTE) || 1;
    this.durationMax = this.durationMax > 60 ? 60 : this.durationMax;
    if (this.requestForm.controls.durationSlider.value > this.durationMax) {
      this.requestForm.controls.durationSlider.setValue(this.durationMax);
      this.requestForm.controls.durationInput.setValue(this.durationMax);
    }
    this.durationSlider.max = this.durationMax;
  }

  /**
   * Обработка изменения чекбокса долговременного хранения информации
   * @param value - текущее значение чекбокса
   */
  protected onPermanentChange(value) {
    if (value) {
      this.requestForm.controls.deleteTime.enable({ emitEvent: false });
      this.requestForm.controls.deleteTime.setValidators([
        Validators.required,
        RequestDeleteTimeValidator.tooLong(1, 'year'),
        // Установка минимальной даты на конец текущего дня. 23ч 59м 59с 999мс
        BgDatetimeValidator.minDate(new Date().setHours(23, 59, 59, 999)),
      ]);
      this.deleteTimeOptions.label = 'Хранить информацию до *';
    } else {
      this.requestForm.controls.deleteTime.setValue(undefined, { emitEvent: false });
      this.requestForm.controls.deleteTime.setErrors(undefined, { emitEvent: false });
      this.requestForm.controls.deleteTime.setValidators([
        RequestDeleteTimeValidator.tooLong(1, 'year'),
        BgDatetimeValidator.minDate(new Date().setHours(0, 0, 0, 0)),
      ]);
      this.requestForm.controls.deleteTime.disable();
      this.deleteTimeOptions.label = 'Хранить информацию до';
    }
  }

  /**
   * Блокировка контролов на форме в зависимости от настроек шага ЖЦ
   */
  protected disableControls(params?: ILifeCycleStepParamDto[]) {
    // Активные аттрибуты берем либо из входного значения либо из ЖЦ
    const attributes = params || this.request.lifeCycleStep.params;
    // Если это не запрос пользователя, то взять редактирование поля так же на основании роли пользователя
    if (this.request && this.request.creationAuthor !== this.settings.currentUser.id) {
      attributes.forEach(
        (item) =>
          // Если явно не указано что поле активно, то считаем что оно неактивно
          (item.enabled = this.access.accessMap[item.attribute]?.enabled && item.enabled),
      );
    }

    if (attributes.find((item) => item.attribute === 'to' && item.enabled)) {
      attributes.push({ attribute: 'durationInput', enabled: true }, { attribute: 'durationSlider', enabled: true });
    }
    if (!this.request?.permanent) {
      if (attributes.find((item) => item.attribute === 'deleteTime')) {
        attributes.find((item) => item.attribute === 'deleteTime').enabled = false;
      }
      if (attributes.find((item) => item.attribute === 'remindDate')) {
        attributes.find((item) => item.attribute === 'remindDate').enabled = false;
      }
    } else {
      // Определение максимального значения напоминания
      const max = (this.requestForm.controls.deleteTime.value - new Date().setHours(0, 0, 0, 0)) / ONE_DAY;
      // При открытии запроса на просмотр, возможна ситуация когда дата напоминания была указана ранее
      // и на текущий момент она больше чем максимальное значение. В этом случае блокировать контрол не надо.
      if (max <= 1 && this.requestForm.controls.remindDate.value <= 1) {
        if (attributes.find((item) => item.attribute === 'remindDate')) {
          attributes.find((item) => item.attribute === 'remindDate').enabled = false;
        }
      }
    }
    this.attributes = attributes;
  }
}
