import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  GridTableComponent,
  IGridTableOptions,
  INwForm,
} from '@smart-city/core/common';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { AddressEditDialogComponent } from '../address-edit-dialog/address-edit-dialog.component';
import { IAnyObject } from 'smart-city-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddressOwnershipObject } from '../../models/types';
import * as dayjs from 'dayjs';
import { BaseComponent } from '@bg-front/core/components';
/** Компонент для отображения адресов в форме просмотра организации */
@UntilDestroy()
@Component({
  selector: 'bg-address-ownership',
  templateUrl: './address-ownership.component.html',
  styleUrls: ['./address-ownership.component.scss']
})
export class AddressOwnershipComponent extends BaseComponent implements OnInit {
  /** Сервис из которого берутся данные об адресах */
  @Input()
  public service: string;
  /** Сущность из которой берутся данные об адресах */
  @Input()
  public entity: string;
  /** Параметры фильтрации адресов */
  @Input()
  public query: IAnyObject;
  /** Признак что нельзя редактировать записи в таблице */
  @Input()
  public viewOnly: boolean;
  /** Сообщение о нажатии на кнопку сохранить в модальном окне */
  @Output()
  saveEvent: EventEmitter<AddressOwnershipObject> = new EventEmitter<AddressOwnershipObject>();
  /** Сообщение о удалении записей */
  @Output()
  deleteEvent: EventEmitter<string[]> = new EventEmitter<string[]>();
  /** Компонент грида */
  @ViewChild('grid', { static: false }) private grid: GridTableComponent;

  /** @ignore */
  constructor(private readonly dialog: MatDialog) {
    super();
  }

  /** Форма поиска */
  private searchForm: INwForm = {
    type: 'nw-form',
    options: {
      name: 'search',
      title: 'Поиск',
      layoutForm: {
        layout: {
          background: 'white',
          columns: [
            {
              width: '100%',
              elements: [
                {
                  type: 'input',
                  options: {
                    name: 'search',
                    placeholder: 'Поиск',
                  },
                },
                {
                  type: 'button',
                  options: {
                    name: 'search',
                    title: 'Искать',
                    stdBehavior: true,
                    color: 'primary',
                  },
                },
                {
                  type: 'button',
                  options: {
                    name: 'cancel',
                    title: 'Отмена',
                    stdBehavior: true,
                  },
                },
              ],
            },
          ],
        },
      },
    },
  };

  /** Настройки грида */
  public gridOptions: IGridTableOptions;

  /** @ignore */
  public ngOnInit(): void {
    this.gridOptions = {
      height: '100%',
      name: 'addressOwnership',
      restVersion: 'modern',
      title: 'Адреса',
      service: { name: this.service },
      query: this.query,
      entity: this.entity,
      sort: {
        field: 'address.fullText',
        direction: 'asc',
      },
      allowSelect: !this.viewOnly,
      allowSelectCheckbox: !this.viewOnly,
      deleteFn: ({ ids }: { ids: string[] }) => {
        this.deleteEvent.emit(ids);
        return of(null);
      },
      showTotalRecords: true,
      onSelectRow: (row) => {
        if (!!row) this.saveAddress(row);
      },
      controls: [
        {
          name: 'reload',
          icon: 'refresh',
          hint: 'Обновить',
          callback: () => of({ actionStream: 'reload' }),
        },
        {
          name: 'search',
          icon: 'search',
        },
        {
          name: 'insert',
          icon: 'add',
          color: 'primary',
          hidden: this.viewOnly,
          callback: () => {
            this.saveAddress();
            return of({ actionStream: 'donothing' });
          },
        },
      ],
      fields: [
        { name: 'address.fullText', title: 'Адрес' },
        { name: 'updateAuthor.fio', title: 'Автор' },
        { name: 'updateTime', title: 'Дата и время' },
      ],
      onLoadData: (results: IAnyObject[]): IAnyObject[] => {
        results.forEach(
          (result: IAnyObject) => (result.updateTime = dayjs(result.updateTime).format('DD.MM.YYYY HH:mm:ss')),
        );
        return results;
      },
      forms: [this.searchForm],
    }
  }

  /** Сохранение адреса */
  private saveAddress(row?: IAnyObject): void {
    const dialogRef = this.dialog.open(AddressEditDialogComponent, {
      disableClose: true,
      minWidth: 750,
      data: {
        updateTime: row?.updateTime,
        updateAuthor: row?.updateAuthor?.fio,
        address: row?.address,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe((result: AddressOwnershipObject) => {
      this.grid.selectId = undefined;
      if (result) {
        this.saveEvent.emit({
          id: row?.id,
          address: result.address,
        });
      }
    });
  }

  /**
   * Обновление грида
   */
  public refreshGrid() {
    this.grid.refresh().pipe(untilDestroyed(this)).subscribe();
  }
}
