import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';
import { ISignificantObjectData } from '@bg-front/significant-objects/models';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Settings2Service } from '@smart-city/core/services';
import { GisService, IGisServiceResult } from '@smart-city/maps/sc';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap } from 'rxjs/operators';
import { IRadiationForecastResultsDto } from 'smart-city-types';

import { IForecastingRadioactiveEnvironmentReport, INuclearPollutionTaskDto } from '../../models';
import {
  ForecastingRadioactiveEnvironmentReportService,
  ForecastingRadioactiveEnvironmentVisualizationService,
} from '../../services';
import { RadioactiveEnvironmentExtraParamsModalFormComponent } from '../radioactive-environment-extra-params-modal-form/radioactive-environment-extra-params-modal-form.component';

/**
 * Радиоактивное загрязнение
 */
@UntilDestroy()
@Component({
  selector: 'bg-nuclear-pollution-task-result',
  templateUrl: './nuclear-pollution-task-result.component.html',
  styleUrls: ['./nuclear-pollution-task-result.component.scss'],
})
export class NuclearPollutionTaskResultComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public forecastingResults: IForecastingResultDto;

  public params: INuclearPollutionTaskDto;
  public results: IRadiationForecastResultsDto[] = [];
  public inputTypeSysname: string;
  private elements: any[];

  /** Скачать отчёт */
  @Input()
  public downloadReport: Observable<void>;

  /** Уведомляем о наличии отчёта */
  @Output()
  public hasReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  /** Уведомляем о формировании отчёта */
  @Output()
  public isDownloadReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  /** Системное имя задачи */
  public counterTaskSysname: string;

  public unitName: string;

  /** Настройки тултипа */
  public tooltipText =
    'Совпадает с временем подхода радиоактивного облака к заданной точке. Можно заменить на произвольное значение';

  constructor(
    private readonly gisService: GisService,
    private readonly significantObjectService: SignificantObjectsService,
    private readonly forecastingVisualisationService: ForecastingRadioactiveEnvironmentVisualizationService,
    public readonly settings: Settings2Service,
    private readonly reportingService: ForecastingRadioactiveEnvironmentReportService,
    private readonly modalService: NzModalService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.params = this.forecastingResults.params.params as INuclearPollutionTaskDto;
    this.results = this.forecastingResults.result as IRadiationForecastResultsDto[];

    this.unitName = this.settings.getDictionaryById(this.params.unitRadiationId)?.name;

    this.counterTaskSysname = this.settings.getDictionaryById(this.forecastingResults.counterTaskId).sysname;

    this.inputTypeSysname = this.settings.getDictionaryById(this.params.inputTypeId)?.sysname;
    if (!this.forecastingResults.result['error']) {
      this.hasReport.emit(this.inputTypeSysname === 'manually');
      if (new Coordinates(this.forecastingResults.params.coordinates).isValid()) {
        switch (this.inputTypeSysname) {
          case 'manually':
            this.elements = this.forecastingVisualisationService.displayRadioactiveResults(this.forecastingResults);
            break;
          case 'shelterCalculatingDoses':
            this.elements = this.forecastingVisualisationService.shelterCalculatingDoses(this.forecastingResults);
            return;
          case 'propagationTimeDoses':
            this.elements = this.forecastingVisualisationService.propagationTimeDoses(this.forecastingResults);
            return;
          case 'dangerCalculationDoses':
            this.elements = this.forecastingVisualisationService.dangerCalculationDoses(this.forecastingResults);
            break;
        }
      } else {
        this.noteService.pushWarning('Отсутствуют координаты для визуализации расчёта');
      }
    } else {
      this.noteService.pushWarning(this.forecastingResults.result['error']);
      this.hasReport.emit(false);
    }

    this.downloadReport
      .pipe(
        mergeMap(() => {
          return this.makeReport();
        }),
        untilDestroyed(this),
      )
      .subscribe((report: IForecastingRadioactiveEnvironmentReport) => {
        this.reportingService.buildForecastingRadioactiveReport(report);
      });
  }

  public override ngOnDestroy(): void {
    this.forecastingVisualisationService.clearResults(this.elements);
  }

  /** Собрать данные для отчета */
  public makeReport(): Observable<IForecastingRadioactiveEnvironmentReport> {
    const report: IForecastingRadioactiveEnvironmentReport = {};

    this.isDownloadReport.emit(true);

    return this.significantObjectService.getSignificantObjects({ id: this.forecastingResults.params.objectId }).pipe(
      catchError((err: Error) =>
        this.catchErrorFn<ISignificantObjectData[]>(err, 'Ошибка при загрузке данных о важном объекте'),
      ),
      map((significantObjects: ISignificantObjectData[]) => (significantObjects || [])[0]),
      switchMap((significantObject: ISignificantObjectData) => {
        /** Блок "Местоположение" */
        if (significantObject.address.fullText) {
          report.address = significantObject.address.fullText;
        }
        report.coordinates = significantObject.coordinates;
        report.object = significantObject.name;
        report.responsible = significantObject.responsible;
        report.phone = significantObject.phone;

        /** Блок "Исходные данные" */
        report.emergency = this.forecastingResults.emergencyNumber || 'не задано';
        report.irradiationDose = +this.params.dose;
        report.unitRadiationId = this.params.unitRadiationId;
        report.timeToSpread = +this.params.timeSinceAccident;

        /** Блок "Метеоусловия в момент аварии" */
        report.windVelocity = +this.params.windVelocity;
        report.windDirection = +this.params.windDirection;
        report.isSnowy = this.params.isSnowy ? 'Да' : 'Нет';
        report.cloudState = this.settings.getDictionaryById(this.params['cloudStateId'])?.name;
        report.timeOfDay = this.settings.getDictionaryById(this.params['timeOfDayId'])?.name;

        /** Блок "Используемая методология для прогнозирования" */
        report.methodology = `ГОСТ Р 22.2.11-2018 Безопасность в чрезвычайных ситуациях. Методика оценки радиационной обстановки при
        запроектной аварии на атомной станции`;

        /** Блок "Результаты расчёта" */
        report.radiationForecastResults = { ...(this.forecastingResults.result as IRadiationForecastResultsDto) };

        const coordinates = this.forecastingVisualisationService.coordinatesRadioactiveResults(this.forecastingResults);

        if (this.forecastingResults && coordinates) {
          return this.gisService.getAddressesByPolygonV2(coordinates);
        }
        return of(null);
      }),
      mergeMap((res: IGisServiceResult[]) => {
        /** Блок "Здания в зоне поражения" */
        report.buildings = [...res];
        return of(report);
      }),
      finalize(() => this.isDownloadReport.emit(false)),
      untilDestroyed(this),
    );
  }

  /** Отркытие формы для расчета доп. параметров  */
  public openExtraParams() {
    this.modalService
      .create({
        nzTitle: 'Расчёт допустимых времени пребывания и начала работ на загряз. территории',
        nzContent: RadioactiveEnvironmentExtraParamsModalFormComponent,
        nzFooter: null,
        nzComponentParams: {
          data: this.forecastingResults,
        },
        nzStyle: {
          width: '800px',
        },
      })
      .afterClose.pipe(untilDestroyed(this))
      .subscribe();
  }
}
