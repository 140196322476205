/**
 * Статусы архивирования
 */
export enum ArchiveStatuses {
  default = 'DEFAULT',
  /** Успешное выполение */
  success = 'SUCCESS',
  /** Ожидание */
  pending = 'PENDING',
  /** Ошибка */
  error = 'ERROR',
}
