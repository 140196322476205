import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { INwHeaderBarOptions, IScSelectOptions } from '@smart-city/core/common';
import { takeUntil } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';
import { BaseComponent } from '@bg-front/core/components';
import { AccessService } from '@smart-city/core/services';
import { IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { FilterOperationEnum } from '@bg-front/core/models/enums';

/**
 * Общая компонента для фильтров разных происшествий
 *  @example
 *  <map-emergency-filter
 *    [title]="'Поручения'"
 *    [mapLayerFilters]="data.mapLayersFilters['Поручения']"
 *    (changedFilters)="onFiltersChange($event)"
 *  ></map-emergency-filter>
 */
@Component({
  selector: 'map-emergency-filter',
  templateUrl: './map-emergency-filter.component.html',
  styleUrls: ['./map-emergency-filter.component.scss'],
})
export class MapEmergencyFilterComponent extends BaseComponent implements OnInit, OnChanges {
  /** Заголовок фильтра */
  @Input()
  public title: string;

  /** Значения фильтров */
  @Input()
  public mapLayerFilters: IMapLayerEntityFilter[] = [];

  /** Оповещение о изменении значений фильтров */
  @Output()
  public changedFilters: EventEmitter<IMapLayerEntityFilter[] | 'delete'> = new EventEmitter<
    IMapLayerEntityFilter[] | 'delete'
  >();

  /** Форма */
  public form: FormGroup;

  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions;

  /** Настройка компоненты Тип КСиП */
  public incidentTypeIdOptions: IScSelectOptions = {
    title: 'Тип КСиП',
    clearable: true,
    service: 'Admin',
    entity: 'IncidentTypes',
    modern: true,
    fieldName: 'name',
    query: { active: true },
  };

  /** Настройка компоненты Состояние */
  public stateOptions: IScSelectOptions = {
    title: 'Состояние',
    clearable: true,
    data: [
      { id: 'new', text: 'Новый' },
      { id: 'inWork', text: 'В работе' },
    ],
  };

  /** Настройка компоненты Срочные */
  public urgentlyOptions: IScSelectOptions = {
    title: 'Срочные',
    clearable: true,
    data: [
      { id: 'true', text: 'Да' },
      { id: 'false', text: 'Нет' },
    ],
    hidden: false,
  };

  constructor(private readonly access: AccessService) {
    super();
    // Для Неисправностей видеокамер фильтр по срочности не нужен.
    // Можно ввести дополнительный входной параметр, который будет отвечать за видимость фильтра.
    // Но пока вариант лишь один, ограничусь тем что есть.
    this.urgentlyOptions.hidden =
      !this.access.accessMap['UrgentlyAvailable']?.visible || this.title === 'Неисправности видеокамер';
  }

  /** @ignore */
  ngOnInit(): void {
    this.headerOptions = {
      margin: 'collapse',
      title: this.title,
      buttons: [
        {
          type: 'button',
          options: {
            name: 'delete',
            icon: 'delete_outline',
          },
        },
      ],
    };

    this.form = new FormGroup({
      incidentTypeId: new FormControl(this.mapLayerFilters.find((item) => item.property === 'incidentTypeId')?.value),
      state: new FormControl(
        this.mapLayerFilters.find((item) => item.property === 'lifeCycleStepId.status.sysname')?.value,
      ),
      urgently: new FormControl(
        this.mapLayerFilters.find((item) => item.property === 'urgently')?.value === 'false, null'
          ? 'false'
          : this.mapLayerFilters.find((item) => item.property === 'urgently')?.value,
      ),
      timeCreateFrom: new FormControl(
        this.mapLayerFilters.find(
          (item) => item.property === 'timeCreate' && item.operation === FilterOperationEnum.greatThenOrEqual,
        )?.value,
      ),
      timeCreateTo: new FormControl(
        this.mapLayerFilters.find(
          (item) => item.property === 'timeCreate' && item.operation === FilterOperationEnum.lessThenOrEqual,
        )?.value,
      ),
    });

    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => this.changedFilters.emit(this.getFilters(value)));
  }

  /** @ignore */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue.length === 0) {
      this.form?.reset({}, { emitEvent: false });
    }
  }

  /**
   * Формирование фильтров на основании значения формы
   * @param value значение формы
   */
  getFilters(value: IAnyObject): IMapLayerEntityFilter[] {
    const result: IMapLayerEntityFilter[] = [];
    if (value.incidentTypeId) {
      result.push({
        property: 'incidentTypeId',
        value: value.incidentTypeId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.state) {
      result.push({
        property: 'lifeCycleStepId.status.sysname',
        value: value.state,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.urgently) {
      result.push({
        property: 'urgently',
        value: value.urgently === 'true' ? 'true' : 'false, null',
        operation: FilterOperationEnum.or,
      });
    }
    if (value.timeCreateFrom) {
      result.push({
        property: 'timeCreate',
        value: value.timeCreateFrom,
        operation: FilterOperationEnum.greatThenOrEqual,
      });
    }
    if (value.timeCreateTo) {
      result.push({
        property: 'timeCreate',
        value: value.timeCreateTo,
        operation: FilterOperationEnum.lessThenOrEqual,
      });
    }
    return result;
  }

  public delete() {
    this.changedFilters.emit('delete');
    this.form.reset();
    this.form.enable();
  }
}
