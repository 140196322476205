<div class="container" *ngIf="emergency">
  <form [formGroup]="form">
    <div class="bg-row">
      <div class="bg-col m-b-15">Общее количество жителей</div>
    </div>
    <div class="bg-row m-b-15">
      <div class="bg-col highlighted">Всего: {{totalRegistered + totalResidents}}</div>
      <div class="bg-col">в т.ч. зарегистрировано: {{totalRegistered}}</div>
      <div class="bg-col">в т.ч. временно проживает: {{totalResidents}}</div>
      <div class="bg-col">в т.ч. детей: {{totalChildren}}</div>
    </div>
    <grid-table [grid]="gridOptions" class="grid"></grid-table>
    <div class="bg-row m-t-30">
      <div class="bg-col-6">
        <div class="bg-form-group">
          <sc-select [options]="areaVisualizationMethodOptions" formControlName="areaVisualizationMethod"></sc-select>
        </div>
      </div>
      <div class="bg-col align-right">
        <div class="bg-form-group">
          <button class="btn btn-primary" (click)="showOnMap()">На карте</button>
        </div>
      </div>
    </div>
  </form>
</div>
