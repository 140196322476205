import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccessService, IAccessAction, SfsService } from '@smart-city/core/services';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { IArchiveTaskDto, IReasonDto, IVideoDeviceDto } from 'smart-city-types';
import { IAbstractServiceData } from 'smart-city-types/services';
import { ONE_SECOND, THIRTY_SECONDS } from '@bg-front/core/models/constants';
import { IDownloadSourcesOptions, IMonitoringDate, IVideoDeviceBg, IWantedPerson } from '../../models/interfaces';
import { EmergencyService, RegMarksService, VideoDevicesService, VideoResultsService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';
import { IScInputOptions } from '@smart-city/core/common';
import { IEmergencyVaDetailData, IOrganization } from '@bg-front/core/models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IUser } from '@smart-city/core/interfaces';
import { CallService } from '../../../call/services';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'bg-video-result-face-recognition',
  templateUrl: './video-result-face-recognition.component.html',
  styleUrls: ['./video-result-face-recognition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class VideoResultFaceRecognitionComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Перриод просмотра, для нового плеера*/
  public customPeriod;
  /** id камеры */
  public cameraId: string | IVideoDeviceDto;
  /** Ссылка на фотографию */
  public imageUrl: string;
  /** Ссылка на эталонное фото */
  public referenceImageUrl: string;
  /** Внешний id камеры */
  public extCameraId: string = undefined;
  /** Опция id - сущности */
  @Input()
  public entityId: string;

  @ViewChild('dialogRef', { static: false }) dialogRef: TemplateRef<any>;
  /** Имя камеры */
  public name: string = undefined;
  /** ФИО разыскиваемого */
  public wantedPersonName: string = undefined;
  public wantedPersonNameOptions:  IScInputOptions = {
    label: 'ФИО разыскиваемого',
  };

  /** Идентификатор из досье */
  public matchedDossierId: string = undefined;
  /** Списки наблюдения */
  public dossierLists: string = undefined;

  public eventDetails: string = '';

  /** Опции для кнопок скачать фото/видео */
  public downloadSourcesOptions: IDownloadSourcesOptions;
  /** Доступность скачивания материалов */
  public canPreparePhotoAndVideo: IAccessAction = { visible: false, enabled: false, name: 'CanPreparePhotoAndVideo' };
  /** Доступность просмотра фото и видео */
  public canPhotoAndVideo: IAccessAction = {
    visible: false,
    enabled: false,
    name: 'CanPhotoAndVideo',
  };

  /** Параметры рамки */
  public frame: {
    x: number;
    y: number;
    alarm: number;
    width: number;
    height: number;
  };

  @Input()
  public incidentNumber: string;

  /** ID файла с видео в хранилище SFS */
  public sfsId: string = undefined;

  /** Статус получения видео */
  public videoStatus: string = 'Запущен процесс получения видео';

  /** Разыскиваемое лицо */
  public wantedPerson: IWantedPerson;

  constructor(
    private readonly emergency: EmergencyService,
    private readonly regMarksService: RegMarksService,
    private readonly videoDevicesService: VideoDevicesService,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly sfs: SfsService,
    private readonly accessService: AccessService,
    private readonly videoResultsService: VideoResultsService,
    private readonly callService: CallService,
    private readonly parentF: FormGroupDirective,
  ) {
    super();
  }

  /**
   * Данные о видеопроисшествии
   * @param val данные
   */
  @Input()
  public set vaDetail(val: IEmergencyVaDetailData) {
    if (val) {
      this.parentF.form.addControl(
        'wantedPersonFormGroup',
        new FormGroup({
          wantedPersonName: new FormControl(val.wantedPersonName),
        }),
      );

      this.eventDetails = val.eventDetails;
      this.matchedDossierId = val.matchedDossier;

      this.videoResultsService.getMatchedListsNames(val.matchedLists).subscribe((dossierLists: string[]) =>
        this.dossierLists = dossierLists?.join(', '),
      );

      const vaTypeId = typeof val.vaTypeId === 'string' ? val.vaTypeId : val.vaTypeId.id;

      this.cameraId = val.camera1Id;
      this.frame = val.frame;

      const videoServerData = this.videoDevicesService.getVideoDevice(val.camera1Id as string).pipe(
        untilDestroyed(this),
        mergeMap((result: IVideoDeviceBg) => {
          if (result) {
            this.extCameraId = result.extId;
            this.name = result.name;
            return this.videoDevicesService.getVideoServerById(<string>result.videoServer);
          }
          return of(null);
        }),
        catchError((error: Error) => this.catchErrorFn<IVideoDeviceBg>(error, 'Ошибка загрузки данных о камере')),
      );

      if (val.matchedDossier) {
        this.videoResultsService.getDossierById(
          val.matchedDossier,
          [
            'reasonWantedId.name',
            'pseudonym',
            'fio',
            'responsibleUserId.fio',
            'responsibleOrganizationId.name',
            'responsiblePhone',
            'imageSfsId',
            'creationAuthor'
          ],
        ).pipe(untilDestroyed(this))
          .subscribe((dossier: IWantedPerson) => {
            this.wantedPerson = {
              ...dossier,
              reasonWantedId: (<IReasonDto>dossier?.reasonWantedId)?.name,
              responsibleUserId: (<IUser>dossier?.responsibleUserId)?.fio,
              responsibleOrganizationId: (<IOrganization>dossier?.responsibleOrganizationId)?.name,
            };

            /** Если в vaDetail отсутствует wantedPersonName, взять поле fio из WantedPersons */
            if (!val.wantedPersonName) {
              (this.parentF.form.controls.wantedPersonFormGroup as FormGroup).controls.wantedPersonName.setValue(dossier.fio);
            }

            this.referenceImageUrl = this.sfs.getUrl(dossier?.imageSfsId);
            this.cdr.detectChanges();
          });
      }

      this.videoDevicesService
        .getTypeVaSettingsByMonitoringSubject(vaTypeId)
        .pipe(
          switchMap((monitoringData: IMonitoringDate) => {
            return forkJoin([
              of(monitoringData),
              this.videoDevicesService.getVideoDeviceRangeStreamUrl(
                val.camera1Id as string,
                // Время, когда произошло событие
                val.camera1Time,
                // Период до события
                monitoringData.timeBeforeEvent,
              ),
              this.videoDevicesService.getMacroscopExportArchiveURL(
                val.camera1Id as string,
                val.camera1Time - (monitoringData?.timeBeforeEvent || THIRTY_SECONDS),
                val.camera1Time + (monitoringData?.timeAfterEvent || THIRTY_SECONDS),
              ),
              videoServerData,
            ]);
          }),
          switchMap(
            (result: [IMonitoringDate, string, string, IAbstractServiceData]): Observable<any> => {
              const fromTime = val.camera1Time - (result[0]?.timeBeforeEvent || THIRTY_SECONDS);
              const toTime = val.camera1Time + (result[0]?.timeAfterEvent || THIRTY_SECONDS);
              const items = result[3]?.data?.items || [];
              const serverData = [];
              if (items.length) {
                serverData[0] = {
                  ...items[0],
                  extId: this.extCameraId,
                };
              } else {
                this.noteService.pushWarning('Ошибка получения данных о видеосервере');
              }

              this.downloadSourcesOptions = {
                vaTypeId,
                fromTime,
                toTime,
                serverData,
                entityId: this.entityId,
                photoList: JSON.parse(val.file1Id),
                timeCreate: +val.camera1Time,
                isShowVideoButton: !!result[1],
                photoFileName: `Фотоматериалы_${this.incidentNumber}`,
                videoFileName: `Видеоматериалы_${this.incidentNumber}`,
                url: result[1],
                contentType: 'video/mp4',
              };

              this.customPeriod = {
                fromtime: fromTime,
                totime: toTime,
              };

              this.cdr.detectChanges();
              return this.videoResultsService.createArchive(result[2], this.entityId);
            },
          ),
          untilDestroyed(this),
        )
        .subscribe((archive: IArchiveTaskDto) => {
          switch (archive.status) {
            case 'error':
              this.videoStatus = 'Ошибка запроса видео';
              this.cdr.detectChanges();
              return;
            case 'success':
              this.sfsId = archive.video[0];
              this.cdr.detectChanges();
              return;
          }
        });
    }

    this.imageUrl = JSON.parse(val.file1Id)?.map((id: string) => this.sfs.getStreamUrl(id))[0];
    this.cdr.detectChanges();
  }

  public showPhotosAndVideos() {
    this.dialog.open(this.dialogRef);
  }

  public ngOnInit(): void {
    this.canPreparePhotoAndVideo = this.accessService.accessMap[this.canPreparePhotoAndVideo.name];

    this.canPhotoAndVideo = this.accessService.accessMap[this.canPhotoAndVideo.name];

    this.downloadSourcesOptions = {
      ...this.downloadSourcesOptions,
      photoFileName: `Фотоматериалы_${this.incidentNumber}`,
      videoFileName: `Видеоматериалы_${this.incidentNumber}`,
    };
  }

  /** Исходящий вызов по номеру */
  public onClickPhone(phone: string): void {
    if (!phone) return;
    this.callService.call(phone);
  }

  /** Открываем форму разыскиваемого лица */
  public openWantedPerson(): void {
    window.open(`${window.location.origin}/security-forces/wanted-persons/view/${this.matchedDossierId}`);
  }
}
