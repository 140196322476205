import { Injectable } from '@angular/core';
import { BaseCrudService } from '@bg-front/core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IModifiedData } from '@smart-city/core/interfaces';
import { RestService, SubscriberService } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { IAbstractServiceData } from 'smart-city-types';

import { IKsipDetailsDto } from '../../../models/interfaces';
import { KsipDetailsStore } from '../store/ksip-details-store.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class KsipDetailsService extends BaseCrudService<IKsipDetailsDto> {
  private isInit = false;

  constructor(rest: RestService, private readonly store: KsipDetailsStore, private readonly subs: SubscriberService) {
    super(
      {
        serviceName: 'Directories',
        entityName: 'KsipDetails',
      },
      rest,
    );
  }

  /*
   * Загружаем список Типов инцидентов
   */
  public initStore(): Observable<boolean> {
    this.store.setLoading(true);

    if (!this.isInit) {
      this.isInit = true;
      this.subs
        .onTableChange<IKsipDetailsDto>('Directories', 'KsipDetails')
        .pipe(untilDestroyed(this))
        .subscribe((result: IModifiedData<IKsipDetailsDto>) => {
          this.store.setLoading(true);
          if (result.action !== 'delete') {
            this.store.upsert(
              [result.data.id],
              (oldState: Partial<IKsipDetailsDto>) => (<IKsipDetailsDto>{ ...oldState, ...result.data }),
              (id: string, newState: IKsipDetailsDto) => (<IKsipDetailsDto>{ id, ...newState }),
            );
          } else {
            this.store.remove(result.data.id);
          }
          this.store.setLoading(false);
        });
    }

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Directories' },
        entity: {
          name: 'KsipDetails',
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          this.store.add(response?.data?.items as IKsipDetailsDto[]);
          return true;
        }),
        catchError(() => {
          return of(false);
        }),
        finalize(() => {
          this.store.setLoading(false);
        }),
      );
  }
}
