<div class="bg-new-response-org-form">
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <div class="bg-row form-serviceType">
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-select [options]="stepTypeOptions" formControlName="stepType"></sc-select>
          </div>
        </div>
      </div>
      <mat-radio-group
        *ngIf="informationStatementActions"
        class="radio-group"
        formControlName="informationStatementActionSysName"
      >
        <mat-radio-button
          *ngFor="let informationStatementAction of informationStatementActions"
          class="radio-button"
          [value]="informationStatementAction.sysname"
          [checked]="informationStatementAction.sysname === form.controls.informationStatementActionSysName.value"
        >
          {{informationStatementAction.name}}
        </mat-radio-button>
      </mat-radio-group>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <sc-button [options]="cancelBtnOptions" (clickButton)="closeHandler()"></sc-button>
    <sc-button [options]="nextBtnOptions" (clickButton)="nextHandler()"></sc-button>
  </mat-dialog-actions>
</div>
