<form [formGroup]="eventForm">
  <div class="bg-row">
    <div class="bg-col">
      <nw-header [options]="headerActionsOptions" (clickButton)="onClickActionsButton($event)"></nw-header>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col">
      <div class="video-event-show-form">
        <div class="bg-row">
          <div class="bg-col" *ngIf="isLeftThing() || isCrowds() || isFaceRecognition()">
            <div class="bg-form-group">
              <a href="javascript:void(0)" (click)="showImageVideoRecording()" *ngIf="canShowPhotoAndVideo?.visible">
                Фото-и видеоматериалы
              </a>
            </div>
          </div>
          <div class="bg-col" style="text-align: right" *ngIf="incident">
            <div class="bg-form-group">
              <a href="javascript:void(0)" (click)="openEmergency()">{{ linkedName }} </a>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group readonly-container">
              <div class="readonly-container__column">
                <label>Адрес КСиП *</label>
                <p>{{ model?.address?.fullText }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group">
              <placement-details [model]="incident?.details"></placement-details>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <sc-input [options]="exactCoordinatesOptions" formControlName="exactCoordinates"></sc-input>
            <div class="bg-form-group specify-address-on-map-button">
              <button
                mat-raised-button
                [color]="optionsSpecifyAddressOnMap.color"
                class="bg-button-round"
                (click)="onClickSpecifyAddress($event)"
              >
                {{ optionsSpecifyAddressOnMap.title }}
              </button>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group readonly-container">
              <div class="readonly-container__column">
                <label>Тип КСиП *</label>
                <p>{{ ksipTypeName$ | async }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group readonly-container">
              <div class="readonly-container__column_description">
                <label>Описание</label>
                <p>{{ model?.description }}</p>
              </div>
            </div>
          </div>
          <div class="bg-col">
            <div class="bg-form-group">
              <sc-textarea [options]="optionsKsipInfo" formControlName="ksipInfo" class="textarea"></sc-textarea>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group readonly-container">
              <div class="readonly-container__column">
                <label>Дата и время возникновения КСиП</label>
                <p>{{ model.ksipTime | date: 'dd.MM.yyyy HH:mm:ss' }}</p>
              </div>
            </div>
          </div>
          <div class="bg-col">
            <div class="bg-form-group">
              <sc-textarea [options]="optionsComment" formControlName="comment" class="textarea"></sc-textarea>
            </div>
          </div>
        </div>
        <div class="bg-row" *ngIf="isCrowds()">
          <div class="bg-col">
            <div class="bg-form-group readonly-container">
              <div class="readonly-container__column">
                <label>Зафиксировано количество людей</label>
                <p>{{ vaDetail.persons }}</p>
              </div>
            </div>
          </div>
          <div class="bg-col">
            <div class="bg-form-group readonly-container">
              <div class="readonly-container__column">
                <label>Превышение допустимого количества людей</label>
                <p>{{ greateThenMaxPersons }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group readonly-container">
              <div class="readonly-container__column">
                <label>Наименование устройства</label>
                <p>{{ videoDeviceName }}</p>
              </div>
            </div>
          </div>
          <div class="bg-col">
            <div class="bg-form-group readonly-container">
              <div class="readonly-container__column">
                <label>Ответственный пользователь</label>
                <p>{{ (userName$ | async)?.fio }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-row bg-event-download-sources" *ngIf="canPreparePhotoAndVideo?.visible">
          <bg-download-sources [options]="downloadSourcesOptions"></bg-download-sources>
        </div>
      </div>
    </div>
  </div>
</form>
