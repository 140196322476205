import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IFileUploadData,
  ILayout,
  ILayoutFormEvent,
  IRegistryColumn,
  IRegistryLoadDataParams,
  IScTextButtonOptions,
} from '@smart-city/core/common';
import { ICreate, NotificationService, RestService, Settings2Service, SfsService } from '@smart-city/core/services';
import { getFullName } from '@smart-city/core/utils';
import { Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { IAbstractServiceEntitySelectResult, IAnyObject, IUploadMetadataOutDTO } from 'smart-city-types';

import { TasksService } from '../tasks.service';
import { BaseComponent } from '@bg-front/core/components';

/** Форма создания/редактирования задания для отображения в диалоговом окне */
@Component({
  selector: 'task-dialog',
  templateUrl: 'task-dialog.component.html',
  styleUrls: ['task-dialog.component.scss'],
})
export class TaskDialogComponent extends BaseComponent {

  /** Реактивная форма редактирования задания */
  public formGroup: FormGroup = new FormGroup({});

  /** Раскладка элементов редактирования задания */
  public layout: ILayout = {
    justify: 'center',
    background: 'white',
    columns: [
      {
        width: '100%',
        align: 'center',
        elements: [
          {
            type: 'input',
            options: {
              name: 'name',
              label: 'Наименование',
              color: 'light-gray',
              width: '50%',
            },
          },
          {
            type: 'select',
            options: {
              name: 'type.id',
              title: 'Тип',
              data: this.settings2.getDictForSelect('taskType'),
              value: this.settings2.getDictObjByTypeSysName('taskType')[this.dialogData?.type]?.id,
              width: '50%',
              disabled: true,
            },
          },
          {
            type: 'select',
            options: {
              name: 'responsible.id',
              width: '50%',
              title: 'Ответственный',
              entity: 'Users',
              service: 'Admin',
              fieldName: 'fio',
              loadDataFn: (params) => {
                if ((params.limit || { paNumber: null }).paNumber > 0) return of([]);
                return this.taskService.getResponsibleSelectData(params.query, this.settings2.currentUser.id);
              },
              modern: true,
            },
          },
          {
            type: 'datepicker',
            options: {
              name: 'executionDate',
              label: 'Срок исполнения',
              width: 'calc(100% / 2)',
              fieldType: 'iso',
            },
          },
          {
            type: 'file',
            options: {
              title: 'Загрузить файл',
              name: 'file',
              isShowOptions: false,
              isShowFileManager: false,
              accept: '.wav',
              hidden: this.dialogData?.type === 'smsSending',
              required: this.dialogData?.type !== 'smsSending',
            },
          },
          {
            type: 'textarea',
            options: {
              label: 'Текст сообщения',
              name: 'comment',
              hidden: this.dialogData?.type !== 'smsSending',
              width: '100%',
              required: this.dialogData?.type === 'smsSending',
            },
          },
        ],
      },
    ],
  };

  public layoutFormOptions = { layout: this.layout };

  /** Реактивная форма выбора условий фильтрации Заявитеей */
  public citizensFilterFormGroup: FormGroup = new FormGroup({});
  /** Реактивная форма выбора условий фильтрации Должностных Лиц */
  public officialsFilterFormGroup: FormGroup = new FormGroup({});

  /** Раскладка элементов выбора условий фильтрации для Заявителей */
  public citizensFilterLayout: ILayout = {
    gap: '20px',
    columns: [
      {
        width: '100%',
        elements: [
          {
            type: 'fias3',
            options: {
              name: 'address',
              label: 'Адрес',
              width: '50%',
            },
          },
          {
            type: 'input',
            options: {
              name: 'phone',
              label: 'Телефон',
              width: '50%',
            },
          },
        ],
      },
    ],
  };
  /** Раскладка элементов выбора условий фильтрации для должностныз лиц */
  public officialsFilterLayout: ILayout = {
    gap: '20px',
    columns: [
      {
        width: '100%',
        elements: [
          {
            type: 'input',
            options: {
              name: 'fio',
              label: 'Имя',
              width: '50%',
            },
          },
          {
            type: 'input',
            options: {
              name: 'phone',
              label: 'Телефон',
              width: '50%',
            },
          },
          {
            type: 'input',
            options: {
              name: 'position',
              label: 'Должность',
              width: '50%',
            },
          },
        ],
      },
    ],
  };

  /** Настройки опций для формы поиска Завявители */
  public citizensFilterFormOptions = { layout: this.citizensFilterLayout };
  /** Настройки опций для формы поска Должностные лица */
  public officialsFilterFormOptions = { layout: this.officialsFilterLayout };

  /** Параметры реестра граждан */
  public registryCitizens: {
    columns: IRegistryColumn[],
    query: IAnyObject,
    afterLoad: (data: IAnyObject[]) => Observable<IAnyObject[]>,
  } = {
    columns: [
      {
        name: 'id',
        title: 'id',
        hidden: true,
        sort: [
          {
            field: 'surname',
            direction: 'asc',
          },
          {
            field: 'firstName',
            direction: 'asc',
          },
          {
            field: 'patronymic',
            direction: 'asc',
          },
        ],
      },
      {
        name: 'surname',
        title: 'Фамилия',
        hidden: true,
      },
      {
        name: 'firstName',
        title: 'Имя',
        hidden: true,
      },
      {
        name: 'patronymic',
        title: 'Отчество',
        hidden: true,
      },
      {
        name: 'fullName',
        title: 'ФИО',
        requested: false,
      },
      {
        name: 'phone',
        title: 'Телефон',
        format: 'phone',
        requested: false,
      },
    ],
    query: {},
    afterLoad: (data: IAnyObject[]) => {
      return of(data.map((datum) => {
        datum.fullName = getFullName(datum.surname, datum.firstName, datum.patronymic);
        delete datum.surname;
        delete datum.firstName;
        delete datum.patronymic;
        return datum;
      }));
    },
  };

  /** Параметры реестра граждан */
  public registryOfficials: {
    columns: IRegistryColumn[],
    query: IAnyObject,
    afterLoad: (data: IAnyObject[]) => Observable<IAnyObject[]>,
  } = {
    columns: [
      {
        name: 'id',
        title: 'id',
        hidden: true,
        sort: [
          {
            field: 'fio',
            direction: 'asc',
          },
          {
            field: 'phone',
            direction: 'asc',
          },
          {
            field: 'position',
            direction: 'asc',
          },
        ],
      },
      {
        name: 'fio',
        title: 'ФИО',
        requested: false,
      },
      {
        name: 'position',
        title: 'Должность',
        requested: false,
      },
      {
        name: 'phone',
        title: 'Телефон',
        format: 'phone',
        requested: false,
      },
    ],
    query: {},
    afterLoad: (data: IAnyObject[]) => {
      return of(data.map((datum) => {
        return datum;
      }));
    },
  };

  /** Настойки кнопки Отмена */
  public buttonCancelOptions: IScTextButtonOptions = {
    name: 'cancel',
    title: 'Отмена',
  };

  /** Настойки кнопки Сохранить */
  public buttonSaveOptions: IScTextButtonOptions = {
    name: 'save',
    title: 'Сохранить',
    color: 'primary',
  };

  public radioButtonEntities = new Map([['Citizens', 'Citizens'], ['Admin', 'Officials']]);

  public radioButtonChoose: string = this.radioButtonEntities.get('Citizens');
  /** Условия фильтрации реестра граждан по номеру телефона */
  public phoneQuery: IAnyObject = {};
  /** Условия фильтрации по должности */
  public positionQuery: IAnyObject = {};
  /** Условия фильтрации по имени */
  public fioQuery: IAnyObject = {};
  /** Условия фильтрации реестра граждан по адресу */
  public addressQuery: IAnyObject = {};

  constructor(
    private sfs: SfsService,
    private readonly rest: RestService,
    private note: NotificationService,
    private taskService: TasksService,
    private settings2: Settings2Service,
    private dialogRef: MatDialogRef<TaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {
    super();
  }

  /**
   * Обработка события изменения значений полей фильрации реестра граждан
   * @param $event Событие формы
   */
  public onChangeCitizensFilterField($event: ILayoutFormEvent): void {
    const value = $event.formGroup.value;
    const phone = (value?.phone ?? '').replace(/[^\d]/g, '');
    this.phoneQuery = value?.phone
      ? {
        $text: {
          $search: phone,
          $fields: ['phone.phoneNumber'],
        },
      }
      : {};
    this.addressQuery = value?.address?.fullText
      ? {
        $or: [
          { 'registrationAddress.uuid': value.address.uuid },
          { 'registrationAddress.city': value.address.city, 'registrationAddress.street': value.address.street },
        ],
      }
      : {};
    this.registryCitizens.query = { ...this.phoneQuery, ...this.addressQuery };
  }

  /**
   * Обработка события изменения значений полей фильтрации реестра Должностных лиц
   * @param $event Событие формы
   */
  public onChangeOfficialsFilterField($event: ILayoutFormEvent): void {
    const value = $event.formGroup.value;
    const phone = (value?.phone ?? '').replace(/[^\d]/g, '');
    const position = (value?.phone ?? '').replace(/[^\d]/g, '');
    const fio = (value?.fio ?? '').replace(/[^\d]/g, '');
    const resultQuery = {
      $and: [],
    };

    if (value?.phone) {
      const query = {
        $text: {
          $search: value?.phone,
          $fields: ['phone'],
        },
      };
      resultQuery.$and.push(query);
    }
    if (value?.position) {
      const query = {
        $text: {
          $search: value?.position,
          $fields: ['position'],
        },
      };
      resultQuery.$and.push(query);
    }
    if (value?.fio) {
      const query = {
        $text: {
          $search: value?.fio,
          $fields: ['fio'],
        },
      };
      resultQuery.$and.push(query);
    }
    this.registryOfficials.query = {
      ...resultQuery,
    };
  }

  /** Обработка нажатия на кнопку Сохранить */
  public onClickButtonSave(): void {

    const loadFunction = this.radioButtonChoose === this.radioButtonEntities.get('Admin') ?
      this.loadOfficialsRegistryDataFn(this.registryOfficials.query) :
      this.loadCitizenRegistryDataFn(this.registryCitizens.query);

    loadFunction
    .pipe(switchMap((res) => {
      let obs = of(null);
      if (this.dialogData.type === 'autodialing') {
        obs = this.uploadFile(this.formGroup.value.file);
      }
      return obs
      .pipe(map(sfsData => ({
        sfsId: sfsData?.uuid,
        citizenIds: (res.items || []).map(item => item.id),
      })));
    }))
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data) => {
      if (!this.formGroup.value?.name) {
        this.note.pushError('Не заполнено Наименование');
        return;
      }
      if (this.dialogData.action === 'create' && !data.sfsId && this.dialogData.type === 'autodialing') {
        this.note.pushError('Не выбран файл');
        return;
      }

      // if (this.dialogData.action === 'create' && !data.text && this.dialogData.type === 'autodialing') {
      //   this.note.pushError('Не выбран файл');
      //   return;
      // }

      if (this.dialogData.action === 'create' && !data.citizenIds?.length) {
        this.note.pushError('Пустой список для обзвона');
        return;
      }

      const formValue = this.formGroup.getRawValue();
      this.dialogRef.close({
        comment: formValue.comment ?? null,
        executionDate: formValue?.executionDate ?? undefined,
        name: formValue?.name ?? undefined,
        responsible: (formValue || {})['responsible.id'] ?? undefined,
        state: (formValue || {})['state.id'],
        type: (formValue || {})['type.id'],
        'gasterCallTaskId.idSFS': data.sfsId ?? undefined,
        citizensQuery: { id: { $in: data.citizenIds } },
      });
    }, (err) => {
      console.error(err);
    });
  }

  /** Обработка нажатия на кнопку Отменить */
  public onClickButtonCancel(): void {
    this.dialogRef.close();
  }

  /**
   * Функция загрузки данных в реестр для Заявителей
   * @param query Условия выборки
   * @param params Параметры сортировки и пагинации
   */
  public loadCitizenRegistryDataFn = (query: IAnyObject, params?: IRegistryLoadDataParams)
    : Observable<IAbstractServiceEntitySelectResult> => {

    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'Officials',
        attributes: ['id', 'citizenId'],
        query: {
          citizenId: {
            $nin: null,
          },
        },
      },
    }).pipe(
      mergeMap(({ data: { items } }) => {
        return this.rest.serviceRequest({
          action: 'select',
          service: { name: 'Citizens' },
          entity: {
            query: {
              id: {
                $nin: items.map(item => item.citizenId),
              },
              ...this.addressQuery,
            },
            name: 'Citizens',
            attributes: ['id', 'surname', 'fisrtName', 'patronymic'],
          },
          data: {
            isNeedTotal: true,
            ...params
              ? {
                sort: params.sort,
                limit: {
                  paNumber: params.paNumber,
                  paSize: params.paSize,
                },
              }
              : {},
            fullName: {
              $expr: {
                $concat: ['$surname', ' ', '$firstName', ' ', '$patronymic'],
              },
            },
            phone: {
              $join: {
                type: 'inner',
                service: 'Citizens',
                entity: 'CitizensPhones',
                attributes: ['citizenId', 'phoneNumber', 'isPrimary'],
                query: {
                  $and: [
                    { 'phone.isPrimary': true },
                    {
                      $expr: {
                        $eq: [
                          { $expr: { $toObjectId: ['$id'] } },
                          { $expr: { $toObjectId: ['$phone.citizenId'] } },
                        ],
                      },
                    },
                    this.phoneQuery,
                  ],
                },
              },
            },
          },
        }).pipe(
          map((data) => {
            const items = data.data?.items ?? [];
            return {
              items: items.map(item => ({
                ...item,
                phone: item.phone?.phoneNumber,
              })),
              totalCount: data.data?.totalCount,
            };
          }),
        );
      }),
    );
  }

  /**
   * Функция загрузки данных в реестр для Должностных лиц
   * @param query Условия выборки
   * @param params Параметры сортировки и пагинации
   */
  public loadOfficialsRegistryDataFn = (query: IAnyObject, params?: IRegistryLoadDataParams)
    : Observable<IAbstractServiceEntitySelectResult> => {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        query,
        name: 'Officials',
        attributes: ['id', 'fio', 'phone', 'position', 'citizenId'],
      },
      data: {
        isNeedTotal: true,
        ...params
          ? {
            sort: params.sort,
            limit: {
              paNumber: params.paNumber,
              paSize: params.paSize,
            },
          }
          : {},
        citizenId: {
          $ne: null,
        },
      },
    }).pipe(
      map((data) => {
        const items = data.data?.items ?? [];
        return {
          items: items.map(item => ({
            id: item.citizenId,
            fio: item.fio,
            phone: item.phone,
            position: item.position,
          })),
          totalCount: data.data?.totalCount,
        };
      }),
    );
  }

  /** Обработка события завершения загрузки формы */
  public onFormInit(): void {
    this.formGroup.patchValue({
      ...this.dialogData.value,
    });
  }

  /**
   * Обработчик изменения радио баттона для отображения соответствующего реестра
   * @param $event - объект события
   * @return
   */
  public changeRadioButtonHandler($event): void {
    this.radioButtonChoose = $event.value;
  }

  /**
   * Загрузка звукового сигнала в хранилище
   * @param uploadData - объект файла для загрузки и его метаданные
   * @returns Данные о загруженном файле
   */
  private uploadFile(uploadData: IFileUploadData): Observable<IUploadMetadataOutDTO> {
    // Если в uploadData строка значит запись была открыта на редактирование и файл не перевыбирался.
    // Нужно вернуть undefined чтобы информация не обновилась в базе
    if (typeof uploadData === 'string') {
      return of(<ICreate>{ uuid: undefined });
    }

    // Если файл выбран из SFS, то вернуть ID из SFS
    if (uploadData?.fileUuid) {
      return of(<ICreate>{ uuid: uploadData.fileUuid });
    }

    // Если в uploadData данные о файле, то выполнить его загрузку в SFS
    if (((uploadData || {})['file'] || {}).size) {
      return this.sfs.upload({
        file: uploadData.file,
        fileName: uploadData.fileName,
        fileTags: 'audio',
        // Если uuid файла вернулся пустой, то вернуть null для очистки значения в базе
      }).pipe(map((file: IUploadMetadataOutDTO) => file.uuid ? file : <IUploadMetadataOutDTO>{ uuid: null }));
    }

    // Нет информации о файле, вернуть null для очистки значения в базе
    return of(<IUploadMetadataOutDTO>{ uuid: null });
  }
}
