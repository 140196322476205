import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { ComponentType } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { IButtonFilter } from '@bg-front/core/models/interfaces';
import {
  IElementButton,
  INwHeaderBarOptions,
  IScInputOptions,
  IScrollableContainerLoadDataFnParams,
  IScrollableContainerLoadDataFnResult,
  IScrollableContainerOptions,
  IScSelectOptions,
  ScrollableContainerComponent,
} from '@smart-city/core/common';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { catchError, debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { IAbstractServiceData, IAdminMunicipalSchemaDto, IAnyObject, IEmergencyDto, IEmergencyEventDto } from 'smart-city-types';

import { RegistryStateService } from '../../services';
import { RegistryEmergencyService } from '../../services/emergency/registry-emergency.service';

// TODO: вынести в общие компоненты. Т.к. используется так же в ЦУКС и ДДС 01
/**
 * Реестр всех происшествий. Отображается при работе с карточкой звонка.
 * Отображается по подписке рабочего стола на поднятие формы звонка.
 * Закрывается по собственной подписке в {@link RegistryStateService} на закрытие формы звонка.
 */
@Component({
  selector: 'bg-all-emergency-registry',
  templateUrl: './all-emergency-registry.component.html',
  styleUrls: ['./all-emergency-registry.component.scss'],
  animations: [
    trigger('expandedPanel', [
      state('opened', style({ transform: 'translateX(0)' })),
      state('closed', style({ transform: 'translateX(-100%)' })),
      state('hide', style({ transform: 'translateX(-100%)' })),
      transition('opened <=> closed', animate('0.3s')),
      transition('hide <=> opened', animate('0.3s')),
    ]),
  ],
})
export class AllEmergencyRegistryComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions;
  /** Признак смены состояния(открыт/закрыт) реестра */
  public isOpen: boolean = false;
  /** Состояние реестра (открыт/свернут/закрыт) */
  public state: 'opened' | 'hide' | 'closed' = 'closed';
  /** Настройки скролла для Инцидентов */
  public optionsIncidents: IScrollableContainerOptions;
  /** Настройки скролла для Событий */
  public optionsEvents: IScrollableContainerOptions;
  /** Количество загружаемых записей */
  public pageSize = 50;
  /** Максимальное количество страниц в скролле */
  protected maxPage = 5;
  /** Список для хранения номера станицы подгружаемых элементов в гриде для соответсвующей вкладки */
  public listPageCounter = new Map([
    ['incidents', 0],
    ['events', 0],
  ]);
  /** Фильтр инцидентов */
  private incidentsFilter: IAnyObject;
  /** Фильтр событий */
  private eventsFilter: IAnyObject;
  /** Ссылка на компонент отображающий происшествия */
  @ViewChildren('allIncidentsScroll')
  public allIncidentsScrollList: QueryList<ScrollableContainerComponent>;
  /** Ссылка на элемент */
  public allIncidentsScroll: ScrollableContainerComponent;
  /** Ссылка на компонент отображающий события */
  @ViewChildren('allEventsScroll')
  public allEventsScrollList: QueryList<ScrollableContainerComponent>;
  /** Ссылка на элемент */
  public allEventsScroll: ScrollableContainerComponent;

  /**
   * Фильтры происшествий
   */
  /** Признак показа всех фильтров */
  public showAllFilters = false;
  /** Настрйоки фильтра по МО */
  public optionsMO: IScSelectOptions;
  /** Натройки фильтра по Типу инцидентов */
  public optionsIncidentTypes: IScSelectOptions;
  /** Настройки фильтра по Источнику по поступления */
  public optionsSourceType: IScSelectOptions;
  /** Описание формгруппы */
  public formGroup: FormGroup = new FormGroup({
    municipal: new FormControl(undefined),
    incidentTypeId: new FormControl(undefined),
    sourceType: new FormControl(undefined),
    dateTimeFrom: new FormControl(undefined),
    dateTimeTo: new FormControl(undefined),
  });
  /** Массив кнопок */
  private buttons: IButtonFilter[] = [];
  /** Имя справочника */
  private dictionaryName = 'statusLifeCycleStep';
  /** Настройка строки поиска инцидентов */
  public incidentsSearchOption: IScInputOptions;
  /** Группа для подписки на изменения */
  public searchFormGroup: FormGroup = new FormGroup({
    'incidents-search': new FormControl(''),
    'events-search': new FormControl(''),
  });

  /**
   * Фильтры событий
   */
  /** Признак показа всех фильтров */
  public showAllFiltersEvents = false;
  /** Описание формгруппы */
  public formGroupEvents: FormGroup = new FormGroup({
    municipal: new FormControl(undefined),
    incidentTypeId: new FormControl(undefined),
    sourceType: new FormControl(undefined),
    dateTimeFrom: new FormControl(''),
    dateTimeTo: new FormControl(''),
  });
  /** Массив кнопок */
  private eventButtons: IButtonFilter[] = [];
  /** Номер заявителя */
  private phoneNumber = undefined;
  /** Настройка строки поиска событий */
  public eventsSearchOption: IScInputOptions;

  public incidentCard: ComponentType<BaseComponent>;
  public eventCard: ComponentType<BaseComponent>;

  /** @ignore */
  constructor(
    private registryState: RegistryStateService,
    private cdr: ChangeDetectorRef,
    private settings: Settings2Service,
    private route: ActivatedRoute,
    private router: Router,
    private emergencyService: RegistryEmergencyService,
  ) {
    super();

    this.buttons = this.settings.getDictionaryByTypeSysName(this.dictionaryName).map((item) => {
      return <IButtonFilter>{
        sysname: item.sysname,
        state: ['new', 'inWork', 'finished'].includes(item.sysname),
        filter: {
          'lifeCycleStepId.status.id': item.id,
        },
      };
    });
    this.buttons.push(<IButtonFilter>{
      sysname: 'sentToCuks',
      state: false,
      filter: {
        supervisedByCuks: true,
      },
    });
    this.buttons.push(<IButtonFilter>{
      sysname: 'createdByMe',
      state: false,
      filter: {
        createdBy: this.settings.currentUser.id,
      },
    });
    this.eventButtons.push(<IButtonFilter>{
      sysname: 'new',
      state: false,
      filter: {
        isHandled: false,
      },
    });
    this.eventButtons.push(<IButtonFilter>{
      sysname: 'closeWithoutReaction',
      state: false,
      filter: {
        isHandled: true,
      },
    });
    this.eventButtons.push(<IButtonFilter>{
      sysname: 'createdByMe',
      state: false,
      filter: {
        responsibleId: this.settings.currentUser.id,
      },
    });
    this.eventButtons.push(<IButtonFilter>{
      sysname: 'currentDeclarant',
      state: false,
      filter: {
        extnum: this.phoneNumber,
      },
    });
  }

  /** @ignore */
  public ngOnInit(): void {
    /** Поиск происшествий */
    this.incidentsSearchOption = {
      name: 'incidents-search',
      formGroup: this.searchFormGroup,
      type: 'search',
      prefixIcon: 'search',
      placeholder: 'Поиск происшествий',
      color: 'white',
    };

    /** Поиск событий */
    this.eventsSearchOption = {
      name: 'events-search',
      formGroup: this.searchFormGroup,
      type: 'search',
      prefixIcon: 'search',
      placeholder: 'Поиск событий',
      color: 'white',
    };

    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      // Инициализация реестра Происшествий в сервисе состояний
      this.registryState.initRegistryValues(this.getQueryFilters('incidents'));
      this.registryState.initIncidentsFiltersValues(this.getIncidentsFilterObject());
      // Инициализация реестра событий в сервисе состояний
      this.registryState.initEventsRegistryValues(this.getQueryFilters('events'));
      this.registryState.initEventsFiltersValues(this.getEventsFilterObject());

      this.headerOptions = {
        title: 'Все происшествия',
        margin: 'collapse',
        buttons: [
          {
            type: 'button',
            options: {
              name: 'cancel',
              icon: 'chevron_left',
            },
          },
        ],
      };

      this.optionsIncidents = {
        loadDataFn: (params: IScrollableContainerLoadDataFnParams) => {
          const query = this.registryState.getValue();
          // Удаляем поле id, которое добавляется при фильтрации по радиусу 50м
          query.$and = query.$and?.filter((item: IAnyObject) => !item.id);

          // Если не указан адрес КСиП, ведется стандартный поиск в радиусе 500м
          let obs = this.emergencyService.getIncidents(
            query,
            {
              paNumber: params.page,
              paSize: params.limit,
            },
            {
              field: 'timeCreate',
              direction: 'desc',
            },
          ).pipe(map((response: IAbstractServiceData) => response?.data?.items));

          const point = this.findPointObject(query);

          // Если адрес указан и есть координаты
          if (point?.$radius) {
            let incidentsWithin50Radius: IEmergencyDto[];

            // Сначала выполняем поиск инцидентов в радиусе 50м
            point.$radius.$meters = 50;
            obs = this.emergencyService.getIncidents(
              query,
              {
                paNumber: params.page,
                paSize: params.limit,
              },
              {
                field: 'timeCreate',
                direction: 'desc',
              },
            ).pipe(
              switchMap((response: IAbstractServiceData) => {
                incidentsWithin50Radius = response?.data?.items || [];

                if (incidentsWithin50Radius.length) {
                  query.$and.push({
                    id: {
                      $nin: incidentsWithin50Radius.map((incident: IEmergencyDto) => incident.id),
                    },
                  });
                }

                // Затем выполняем поиск остальных инцидентов в радиусе 500м
                point.$radius.$meters = 500;
                return this.emergencyService.getIncidents(
                  query,
                  {
                    paNumber: params.page,
                    paSize: params.limit,
                  },
                  {
                    field: 'timeCreate',
                    direction: 'desc',
                  },
                );
              }),
              map((response: IAbstractServiceData) =>
                [...incidentsWithin50Radius, ...(response?.data?.items || [])]
              ),
            );
          }

          return obs
            .pipe(
              map((incidents: IEmergencyDto[]) => {
                if (incidents?.length) {
                  return <IScrollableContainerLoadDataFnResult>{
                    currentPage: params.page,
                    data: incidents.map((el: IEmergencyDto) => {
                      return {
                        data: el,
                        // TODO: В будущем, когда все перейдет на праймари роуты надо отказаться от получения компонента из маршрута.
                        type: this.route.snapshot.data.incidentCard || this.incidentCard,
                      };
                    }),
                  };
                }
                return <IScrollableContainerLoadDataFnResult>{
                  currentPage: params.page,
                  data: [null],
                };
              }),
              catchError((err: Error) =>
                this.catchErrorFn<IScrollableContainerLoadDataFnResult>(err, 'Ошибка при запросе списка инцидентов'),
              ),
              takeUntil(this.ngUnsubscribe),
            );
        },
        limit: this.pageSize,
        maxPage: this.maxPage,
      };

      this.optionsEvents = {
        loadDataFn: (params: IScrollableContainerLoadDataFnParams) => {
          return this.emergencyService
            .getEvents(
              this.registryState.getEventsValue(),
              {
                paNumber: params.page,
                paSize: params.limit,
              },
              {
                field: 'timeCreate',
                direction: 'desc',
              },
            )
            .pipe(
              map((response: IAbstractServiceData) => {
                if (response.data && response.data.items.length) {
                  return <IScrollableContainerLoadDataFnResult>{
                    currentPage: params.page,
                    data: response.data.items.map((el: any) => {
                      return {
                        data: el,
                        // TODO: В будущем, когда все перейдет на праймари роуты надо отказаться от получения компонента из маршрута.
                        type: this.route.snapshot.data.eventCard || this.eventCard,
                      };
                    }),
                  };
                }

                return <IScrollableContainerLoadDataFnResult>{
                  currentPage: params.page,
                  data: [null],
                };
              }),
              catchError((err: Error) =>
                this.catchErrorFn<IScrollableContainerLoadDataFnResult>(err, 'Ошибка при запросе списка событий'),
              ),
              takeUntil(this.ngUnsubscribe),
            );
        },
        limit: this.pageSize,
        maxPage: this.maxPage,
      };

      this.toggleState();
      this.cdr.detectChanges();

      this.optionsMO = <IScSelectOptions>{
        name: 'municipal',
        title: 'Муниципальное образование',
        data: this.settings.allMo.map((mo: IAdminMunicipalSchemaDto) => {
          return <IScSelectItem>{
            id: mo.id,
            text: mo.name,
          };
        }),
        formGroup: this.formGroup,
        clearable: true,
      };

      this.optionsIncidentTypes = <IScSelectOptions>{
        name: 'incidentTypeId',
        title: 'Тип КСиП',
        service: 'Admin',
        entity: 'IncidentTypes',
        modern: true,
        fieldName: 'name',
        formGroup: this.formGroup,
        clearable: true,
      };

      this.optionsSourceType = <IScSelectOptions>{
        name: 'sourceType',
        title: 'Источник поступления данных',
        data: this.settings.getDictForSelect('sourceTypes'),
        formGroup: this.formGroup,
        clearable: true,
      };

      // Подписка на изменения формы происшествий
      this.formGroup.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.registryState.patchFiltersValue(this.getIncidentsFilterObject());
      });

      this.searchFormGroup.controls['incidents-search'].valueChanges
        .pipe(debounceTime(200), takeUntil(this.ngUnsubscribe))
        .subscribe((val) => {
          if (val !== null && val !== undefined) {
            this.registryState.patchSearchValue(val);
          }
        });

      // Подписка на изменения формы событий
      this.formGroupEvents.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.registryState.patchEventFiltersValue(this.getEventsFilterObject());
      });

      this.searchFormGroup.controls['events-search'].valueChanges
        .pipe(debounceTime(200), takeUntil(this.ngUnsubscribe))
        .subscribe((val) => {
          if (val !== null && val !== undefined) {
            this.registryState.patchEventSearchValue(val);
          }
        });
    });
    this.registryState.informAllEmergencyRegistryInitialized();
  }

  /**
   * Поиск объекта c ключем "point" в запросе (для поиска происшествий в заданном радиусе)
   * @param query - объект запроса
   **/
  private findPointObject(query: IAnyObject): IAnyObject {
    let point;

    (function findPoint(obj: IAnyObject) {
      for (const key in obj) {
        if (key === '$or' || key === '$and') {
          for (const item of obj[key]) {
            findPoint(item);
          }
        } else if (key === 'point') {
          point = obj[key];
        }
      }
    })(query);

    return point;
  }

  /**
   * Получаем состояние кнопки
   * @param sysName системное имя кнопки
   * @param forEvent
   */
  public getState(sysName: string, forEvent: boolean = false): boolean {
    if (forEvent) {
      const btn = this.getButton(sysName, forEvent);
      return btn ? btn.state : false;
    }
    const btn = this.getButton(sysName);
    return btn ? btn.state : false;
  }

  /** Обработка нажатия на кнопку */
  public selectFilter(sysName: string): void {
    const btn = this.getButton(sysName);
    if (btn) {
      /** Проверяем, что данная кнопка не является единственной выбранной */
      if (btn.state) {
        if (this.buttons.filter((btn: IButtonFilter) => btn.state).length === 1) {
          this.noteService.pushWarning('Должно быть выбрано хотя бы одно состояние инцидента');
          return;
        }
      }

      btn.state = !btn.state;
      this.registryState.patchFiltersValue(this.getIncidentsFilterObject());
    }
  }

  /** Обработка фильтров событий */
  public selectFilterEvents(sysName: string): void {
    const btn = this.getButton(sysName, true);
    if (btn) {
      btn.state = !btn.state;
      this.registryState.patchEventFiltersValue(this.getEventsFilterObject());
    }
  }

  /**
   * Получаем кнопку по системному имени
   * @param sysName системное имя
   * @param forEvent
   */
  private getButton(sysName: string, forEvent: boolean = false): IButtonFilter {
    if (forEvent) {
      return this.eventButtons.find((btn: IButtonFilter) => btn.sysname === sysName);
    }
    return this.buttons.find((btn: IButtonFilter) => btn.sysname === sysName);
  }

  /** Формируем объект фильтра происшествий */
  private getIncidentsFilterObject(): IAnyObject {
    const filter: IAnyObject = {
      $and: [],
    };

    const orFilter = { $or: [] };

    this.buttons
      .filter((btn: IButtonFilter) => btn.state && btn.filter)
      .forEach((btn: IButtonFilter) => {
        /** Кнопка "создано мной" попадает под условие И */
        if (btn.sysname !== 'createdByMe') {
          orFilter.$or.push(btn.filter);
        } else {
          filter.$and.push(btn.filter);
        }
      });

    if (orFilter.$or.length) {
      filter.$and.push(orFilter);
    }

    if (this.formGroup.controls['municipal'].value) {
      filter.$and.push({
        'organization.mo.id': this.formGroup.controls['municipal'].value,
      });
    }

    if (this.formGroup.controls['incidentTypeId'].value) {
      filter.$and.push({
        incidentTypeId: this.formGroup.controls['incidentTypeId'].value,
      });
    }

    if (this.formGroup.controls['sourceType'].value) {
      filter.$and.push({
        sourceType: this.formGroup.controls['sourceType'].value,
      });
    }

    if (this.formGroup.controls['dateTimeFrom'].value) {
      filter.$and.push({
        timeCreate: {
          $gte: this.formGroup.controls['dateTimeFrom'].value,
        },
      });
    }

    if (this.formGroup.controls['dateTimeTo'].value) {
      filter.$and.push({
        timeCreate: {
          $lte: this.formGroup.controls['dateTimeTo'].value,
        },
      });
    }

    return filter.$and.length ? filter : undefined;
  }

  /** Формируем объект фильтра событий */
  private getEventsFilterObject(): IAnyObject {
    const selected = this.eventButtons.filter((btn: IButtonFilter) => btn.state);
    const filter: IAnyObject = {
      $and: [{ $or: [] }],
    };

    selected.forEach((btn: IButtonFilter) => {
      /** Кнопка "созданно мной" попадает под условие И */
      if (btn.sysname !== 'createdByMe') {
        filter.$and[0].$or.push(btn.filter);
      } else {
        filter.$and.push(btn.filter);
      }
    });

    if (this.formGroupEvents.controls['dateTimeFrom'].value) {
      filter.$and.push({
        timeCreate: {
          $gte: this.formGroupEvents.controls['dateTimeFrom'].value,
        },
      });
    }

    if (this.formGroupEvents.controls['dateTimeTo'].value) {
      filter.$and.push({
        timeCreate: {
          $lte: this.formGroupEvents.controls['dateTimeTo'].value,
        },
      });
    }

    return filter;
  }

  /** @ignore */
  public ngAfterViewInit(): void {
    // Подписка на закрытие формы создания события
    this.registryState.callFormState$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: 'open' | 'close') => {
      if (state === 'close') {
        this.toggleState();
      }
    });

    // Подписка на изменения в реестре и обновление данных реестра происшествий
    this.allIncidentsScrollList.changes
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((comps: QueryList<ScrollableContainerComponent>) => {
        if (comps.first) {
          this.allIncidentsScroll = comps.first;
          this.allIncidentsScroll.getNextData();
        } else {
          this.allIncidentsScroll?.ngOnDestroy();
          this.allIncidentsScroll = undefined;
        }
        this.cdr.markForCheck();
      });

    // Подписка на изменения в реестре и обновление данных реестра событий
    this.allEventsScrollList.changes
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((comps: QueryList<ScrollableContainerComponent>) => {
        if (comps.first) {
          this.allEventsScroll = comps.first;
          this.allEventsScroll.getNextData();
        } else {
          this.allEventsScroll?.ngOnDestroy();
          this.allEventsScroll = undefined;
        }

        this.cdr.markForCheck();
      });

    // Подписка на изменения объекта фильтрации происшествий(query)
    this.registryState.filtersChanged$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((filters: IAnyObject) => {
      this.changedIncidentsFilter(filters);
    });

    // Подписка на изменения объекта фильтрации событий(query)
    this.registryState.eventFiltersChanged$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((filters: IAnyObject) => {
      this.changedEventsFilter(filters);
    });
  }

  /**
   * Возвращает объект запроса для фильтрции происшествий
   * @param entityName - тип фильтра (происшествия или события)
   * @return query - массив параметров
   */
  public getQueryFilters(entityName: 'incidents' | 'events'): IAnyObject[] {
    switch (entityName) {
      case 'incidents':
        return [
          {
            $or: [{ 'docType.sysname': 'incident' }, { 'docType.sysname': 'plannedWork' }],
          },
          { 'sourceType.sysname': { $ne: 'va' } },
        ];
      case 'events':
        return [{ direction: 'incoming' }, { 'sourceId.sysname': { $ne: 'va' } }];
    }
  }

  /**
   * Подписка на изменения в фильтрах происшествий
   * @param $event - объект события фильтра
   */
  public changedIncidentsFilter($event): void {
    this.incidentsFilter = $event;
    this.listPageCounter.set('incidents', 0);
    // Обновление реестра происшествий
    if (this.allIncidentsScroll) {
      this.allIncidentsScroll.refresh().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    }
  }

  /**
   * Подписка на изменения в фильтрах событий
   * @param $event - объект события фильтра
   */
  public changedEventsFilter($event): void {
    this.eventsFilter = $event;
    this.listPageCounter.set('events', 0);
    // Обновление реестра событий
    if (this.allEventsScroll) {
      this.allEventsScroll.refresh().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    }
  }

  /** Обработка клика по табу */
  public selectedTabChange({ index }: { index: number }): void {
    /** Обновляю данные при переключение между табами */
    const container = index ? this.allEventsScroll : this.allIncidentsScroll;
    container?.refresh().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
  }

  /**
   * Обработка нажатия кнопки в заголовке реестра
   * @param $event - объект события
   */
  public clickHeaderButton($event?: IElementButton): void {
    this.state = 'hide';
  }

  /** Обработка нажатия по закладке. Разворачивает реестр */
  public clickBookmark(): void {
    this.state = 'opened';
  }

  /**
   * Метод срабаытвает при завершение анимации отбражения/скрытия реестра.
   * @param $event - объект события завершения анимации.
   */
  public onAnimationEventDone($event: AnimationEvent): void {
    // Реестр скрыт
    if ($event.toState === 'closed') {
      this.ngOnDestroy();
    }
    // Реестр отображается
    if ($event.toState === 'opened') {
      // Инициализация компонентов реестра
      this.initRegistry();
    }
  }

  /** Скрытие/Открытие фильтра */
  public hide() {
    this.showAllFilters = !this.showAllFilters;
  }

  /** Скрытие/Открытие фильтра событий */
  public hideFilterEvents() {
    this.showAllFiltersEvents = !this.showAllFiltersEvents;
  }

  /**
   * Инициализация компонентов реестра.
   * Срабатывает при завершении анимации открытия реестра.
   */
  private initRegistry(): void {
    // Инициализация листа происшествий
    if (this.allIncidentsScrollList && this.allIncidentsScrollList) {
      this.allIncidentsScroll = this.allIncidentsScrollList.first;
      this.allIncidentsScroll.getNextData();
    } else {
      this.allIncidentsScroll?.ngOnDestroy();
      this.allIncidentsScroll = undefined;
    }
    // Инициализация листа событий
    if (this.allEventsScrollList && this.allEventsScrollList) {
      this.allEventsScroll = this.allEventsScrollList.first;
      this.allEventsScroll.getNextData();
    } else {
      this.allEventsScroll?.ngOnDestroy();
      this.allEventsScroll = undefined;
    }
  }

  /** Метод переключения отображения реестра */
  private toggleState(): void {
    this.isOpen = !this.isOpen;
    this.state = this.isOpen ? 'opened' : 'closed';
  }

  /** @ignore */
  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.router.navigate([{ outlets: { allRegistry: null } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }
}
