import { FormBuilder, Validators } from "@angular/forms";
import { FLOAT_PATTERN } from "@bg-front/core/models/constants";
import { coordinatesValidator } from "@bg-front/core/validators";
import { IAccidentOnChemicalObjectTaskParamDto, IDestroyChemicalObjectTaskParamDto, ITimeApproachCloudToPointTaskParamDto } from "../interfaces";

export class ForecastingBlowoutTasksFormFactory {
  /** Авария на химическом объекте */
  public static accidentOnChemicalObjectTaskFormGroup(fb: FormBuilder, params: IAccidentOnChemicalObjectTaskParamDto) {
    return fb.group({
      chemicalTypeId: [params?.chemicalTypeId],
      aggregationStateId: [params?.aggregationStateId],
      timeToSpread: [params?.timeToSpread],
      chemicalAmount: [
        {
          value: params?.chemicalAmount,
          disabled: !params?.chemicalTypeId,
        },
        [Validators.pattern(FLOAT_PATTERN)],
      ],
      totalChemicalAmount: [{ value: false, disabled: !params?.chemicalTypeId }],
      dischargeTypeId: [params?.dischargeTypeId],
      dischargeArea: [params?.dischargeArea, [Validators.pattern(FLOAT_PATTERN)]],
      irradiationDose: [params?.irradiationDose, [Validators.pattern(FLOAT_PATTERN)]],
      windVelocity: [params?.windVelocity, [Validators.pattern(FLOAT_PATTERN)]],
      windDirection: [params?.windDirection, [Validators.pattern(FLOAT_PATTERN)]],
      isSnowy: [params?.isSnowy],
      airTemperature: [params?.airTemperature, [Validators.pattern(FLOAT_PATTERN)]],
      cloudStateId: [params?.cloudStateId, [Validators.required]],
      timeOfDayId: [params?.timeOfDayId],
    });
  }

  /** Генерация FormGroup */
  public static destroyChemicalObjectTaskFormGroup(fb: FormBuilder, params: IDestroyChemicalObjectTaskParamDto) {
    return fb.group({
      timeToSpread: [params?.timeToSpread],
      storedChemical: [params?.storedChemical, [Validators.required]],
      windVelocity: [params?.windVelocity, [Validators.pattern(FLOAT_PATTERN)]],
      windDirection: [params?.windDirection, [Validators.pattern(FLOAT_PATTERN)]],
      isSnowy: [params?.isSnowy],
      airTemperature: [params?.airTemperature, [Validators.pattern(FLOAT_PATTERN)]],
      cloudStateId: [params?.cloudStateId, [Validators.required]],
      timeOfDayId: [params?.timeOfDayId],
    });
  }

  /** Генерация FormGroup */
  public static timeApproachCloudToPointTaskFormGroup(fb: FormBuilder, params: ITimeApproachCloudToPointTaskParamDto) {
    return fb.group({
      coordinates: [params?.coordinates, [coordinatesValidator()]],
      windVelocity: [params?.windVelocity, [Validators.pattern(FLOAT_PATTERN)]],
      windDirection: [params?.windDirection, [Validators.pattern(FLOAT_PATTERN)]],
      isSnowy: [params?.isSnowy],
      airTemperature: [params?.airTemperature, [Validators.pattern(FLOAT_PATTERN)]],
      cloudStateId: [params?.cloudStateId, [Validators.required]],
      timeOfDayId: [params?.timeOfDayId],
    });
  }
}
