<div class="dialog-container">
  <form [formGroup]="form" errorTailor id="idForTest">
    <div class="bg-row">
      <div class="bg-col">
        <h4><strong>Добавить подпись</strong></h4>
      </div>
    </div>
    <div class="bg-row content">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select [options]="typeIdOptions" formControlName="typeId"></sc-select>
        </div>
      </div>
    </div>
    <div class="bg-row content" *ngIf="isDigitalSignature">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select [options]="certificateOptions" formControlName="certificate"></sc-select>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button (click)="cancel()">Отмена</button>
      <button mat-raised-button color="primary" cdkFocusInitial (click)="sign()">Подписать</button>
    </div>
  </form>
</div>
