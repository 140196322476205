<div nz-form [formGroup]="entityForm" nzLayout="vertical">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzRequired>Сервис</nz-form-label>
        <nz-form-control>
          <nz-select nzShowSearch formControlName="service" nzSize="large">
            <nz-option [nzValue]="service.id" [nzLabel]="service.text" *ngFor="let service of serviceDict"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzRequired>Сущность</nz-form-label>
        <nz-form-control>
          <nz-select nzShowSearch formControlName="entity" nzSize="large">
            <nz-option [nzValue]="entity.id" [nzLabel]="entity.text" *ngFor="let entity of entityDict"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="sysName">Системное наименование</nz-form-label>
        <nz-form-control>
          <input nzSize="large" nz-input formControlName="sysName" />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <div formArrayName="filters" *ngIf="hideFilter">
        <div *ngFor="let el of filtersArray.controls; let i = index">
          <div nz-row [nzGutter]="24" class="m-t-20">
            <div nz-col [nzSpan]="22">
              <bg-registry-panel-entity-filter [attributes]="attributes" [formControl]="$any(el)">
              </bg-registry-panel-entity-filter>
            </div>
            <div nz-col [nzSpan]="2" *ngIf="filtersArray.controls.length > 1">
              <i (click)="removeFilter(i)" nz-icon nzType="minus-circle-o" class="dynamic-delete-button"> </i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <button nz-button nzType="primary" type="button" class="m-b-20 m-t-15" (click)="addFilter()">
        Добавить фильтр
      </button>
    </div>
  </div>
</div>
