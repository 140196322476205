import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { POLYGON_MARKER_SVG } from '@bg-front/core/models/constants';
import { IPolygonDto, ISubstrateDto } from '@bg-front/core/models/interfaces';
import {
  IElementButton,
  INwHeaderBarOptions,
  IScCheckboxOptions,
  IScInputOptions,
  IScTextareaOptions,
} from '@smart-city/core/common';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { MapBaseModel, MapBaseService } from '@smart-city/maps/sc';
import { IMapBaseEvent } from '@smart-city/maps/sc';
import { IMapBaseIcon, IMapBaseInitOptions } from '@smart-city/maps/sc';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import { forkJoin, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { catchError, map } from 'rxjs/operators';
import { IAbstractServiceData, IAdminMunicipalSchemaDto } from 'smart-city-types';

import { IPassportOfficeDataDto } from '../../../bg/modules/external-interactions/modules/passport-office-data/interfaces';
import { ReportingService } from '../../../bg/modules/reporting/services';
import { IMiniMapOptions, IOrganization, IRegisteredCitizensReport } from '../../models/interfaces';
import { OrganizationsService, PolygonsService } from '../../services';
import { IMapBaseBaseUrlsOptions } from '@bg-front/map/models/interfaces';
import { SubstratesQuery } from '@bg-front/core/services';

@Component({
  selector: 'bg-new-polygon',
  templateUrl: './new-polygon.component.html',
  styleUrls: ['./new-polygon.component.scss'],
})
export class NewPolygonComponent extends BaseComponent implements OnInit {
  /** Форма */
  public form: FormGroup;
  /** Настройка карты*/
  public mapOptions = <IMapBaseInitOptions>{};
  /** Флаг создания полигона */
  public startCreatePolygon: boolean = false;
  /** Форма */
  /** Настройки заголовка формы создания/редактирования записи */
  public headerActionsOptions: INwHeaderBarOptions;
  /** Полигон */
  public objectPolygon: Coordinates[] = [];
  /** Сущность полигон */
  private polygon: IPolygonDto;
  /** Модель карты */
  private mapModel: MapBaseModel;
  /** Иконка для точки полигона */
  private pointIcon: IMapBaseIcon;
  /** Иконка для последней точки полигона */
  private lastPointIcon: IMapBaseIcon;

  /** Настройки компоненты Наименование */
  public nameOptions: IScInputOptions = {
    label: 'Наименование',
    placeholder: 'Наименование',
    maxLength: 100,
    required: true,
  };

  /**
   * Настройка компоненты Описание
   */
  public optionsDescription: IScTextareaOptions = {
    label: 'Описание',
    maxLength: 250,
    rows: 2,
  };

  /**
   * Настройка компоненты Данные из полигона
   */
  public optionsPolygonData: IScCheckboxOptions = {
    title: 'Получить данные из полигона',
  };

  constructor(
    private readonly dialog: MatDialog,
    private readonly mapService: MapBaseService,
    private readonly orgService: OrganizationsService,
    private readonly polygonsService: PolygonsService,
    private readonly reportingService: ReportingService,
    private readonly rest: RestService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly settings: Settings2Service,
    private readonly location: Location,
    private readonly substratesQuery: SubstratesQuery,
  ) {
    super();
  }

  /** Текущий пользователь, авторизованный в системе */
  private get user() {
    return (this.settings || <any>{}).currentUser;
  }

  public ngOnInit(): void {
    this.pointIcon = this.mapService.makeIcon({
      iconSize: 8,
      iconColor: 'black',
      iconSVG: POLYGON_MARKER_SVG,
      shapeType: 'Круг',
      shapeSize: 10,
      shapeColor: 'black',
    });
    this.lastPointIcon = this.mapService.makeIcon({
      iconSize: 8,
      iconColor: 'blue',
      iconSVG: POLYGON_MARKER_SVG,
      shapeType: 'Круг',
      shapeSize: 12,
      shapeColor: 'blue',
    });

    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      description: new FormControl(null),
      polygonData: new FormControl(true),
    });

    this.headerActionsOptions = {
      title: 'Новый полигон',
      name: 'header',
      bgColor: 'white',
      margin: 'collapse',
      buttons: [
        {
          type: 'button',
          options: {
            name: 'cancel',
            icon: 'clear',
          },
        },
        {
          type: 'button',
          options: {
            name: 'save',
            title: 'Сохранить',
            icon: 'done',
          },
        },
      ],
    };

    const transformSubstratesUrl: (url: string) => string = (url: string): string => {
      return url.startsWith('/', 0) ? `//${window.location.host}${url}` : `//${url}`;
    };

    const substrates = this.substratesQuery.getAll();
    const activeSubstrate = (this.substratesQuery.getActive() as ISubstrateDto) || substrates?.[0];

    const mapId = 'newUserPolygon';
    this.mapModel = new MapBaseModel(mapId, this.mapService);
    this.mapOptions.mapId = mapId;
    this.mapOptions.url = substrates?.length > 1 ? undefined : transformSubstratesUrl(activeSubstrate.link);
    this.mapOptions.urls =
      substrates?.length > 1
        ? substrates.map((el: ISubstrateDto): IMapBaseBaseUrlsOptions => {
            return <IMapBaseBaseUrlsOptions>{
              url: transformSubstratesUrl(el.link),
              name: el.name,
              attribution: el.attribution,
              selected: activeSubstrate ? el.id === activeSubstrate.id : el.default,
            };
          })
        : undefined;
    this.mapOptions.maxZoom = 18;
    this.mapOptions.mapStyle = { width: '100%', height: '100%' };

    // получаем настройки карты из рабочего стола
    const workspaceMapOptions = <IMiniMapOptions>this.location.getState();
    // TODO можно вынести "зум карты по умолчанию" в настройки пользователя, т.к. он используется и в base-workspace
    this.mapOptions.zoom = workspaceMapOptions?.zoom ?? 16;
    this.mapOptions.center = workspaceMapOptions?.center ?? this.getMunicipalCoordinates();

    this.mapModel
      .getObservableMapEvents()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event: IMapBaseEvent) => {
        switch (event.typeEvent) {
          case 'mapClick': {
            if (event?.coordinates) {
              if (this.startCreatePolygon) {
                if (this.objectPolygon.length) {
                  this.mapModel.addMarkerToLayer(
                    'polygon-points',
                    `${this.objectPolygon.length - 1}`,
                    this.objectPolygon[this.objectPolygon.length - 1].toArray(),
                    this.pointIcon,
                  );
                }
                this.mapModel.addMarkerToLayer(
                  'polygon',
                  `${this.objectPolygon.length}`,
                  event.coordinates,
                  this.lastPointIcon,
                );
                this.objectPolygon.push(new Coordinates(event.coordinates[0], event.coordinates[1]));

                if (this.objectPolygon.length > 1) {
                  this.mapModel.addPolygonToLayer(
                    'polygon',
                    'polygon',
                    this.objectPolygon.map((point: Coordinates) => point.toArray()),
                    { color: 'black', weight: 1 },
                  );
                }
                this.mapModel.viewLayer('polygon-points', false);
                this.mapModel.viewLayer('polygon', false);
              }
            }
            break;
          }
          case 'mapReady':
            /** Очищаем карту */
            this.clearPolygon();

            if (this.polygon) {
              this.objectPolygon = this.polygon.coordinates;
            }
            break;
          default:
            break;
        }
      });
  }

  /**
   * Обрабатываем нажатие кнопок в заголовке
   * @param $event - информация о нажатой кнопке
   */
  public onClickActionsButton($event: IElementButton) {
    if ($event.options.name === 'cancel') {
      this.router.navigate([{ outlets: { editForm: null } }], {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge',
      });
      return;
    }

    if (this.objectPolygon.length < 3 || !this.form.valid) {
      return;
    }

    this.polygon = {
      id: undefined,
      coordinates: this.objectPolygon,
      description: this.form.controls.description.value,
      name: this.form.controls.name.value,
      polygonTypeId: this.settings.getDictionaryByTypeAndSysName('polygonTypes', '04-userPolygons')?.id,
    };

    if (this.form.controls.polygonData.value) {
      this.createReport(this.objectPolygon);
    }

    this.polygonsService
      .save(this.polygon)
      .pipe(
        catchError((error: Error) => {
          this.catchErrorFn(error, 'Ошибка сохранения полигона');
          return of(null);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data: IAbstractServiceData) => {
        if (data) {
          this.router.navigate([{ outlets: { editForm: null } }], {
            relativeTo: this.route.parent,
            queryParamsHandling: 'merge',
          });
        }
      });
  }

  /** Скачивание отчёта */
  public createReport(polygon: Coordinates[]): void {
    const coordinates: number[][] = (polygon || []).map((item: Coordinates) => [item.lat, item.lon]);
    coordinates.push([coordinates[0][0], coordinates[0][1]]);
    forkJoin(
      this.rest
        .serviceRequest({
          action: 'select',
          service: { name: 'PassportOfficeIntegration' },
          entity: {
            name: 'LoadData',
            query: {
              point: {
                $polygon: {
                  $points: coordinates,
                },
              },
            },
          },
        })
        .pipe(
          map((result: IAbstractServiceData) => <IPassportOfficeDataDto[]>result.data.items),
          catchError((err: Error) => {
            this.catchErrorFn(err, err.message);
            return of(null);
          }),
        ),
      this.orgService.getOrganizationBy(
        this.settings.currentUser.organizationId?.id
          ? this.settings.currentUser.organizationId?.id
          : this.settings.currentUser.organizationId,
      ),
    )
      .pipe(
        map(([list, organization]: [IPassportOfficeDataDto[], IOrganization]) => {
          const report = <IRegisteredCitizensReport>{};
          report.userFio = this.settings.currentUser.fio;
          report.userOrganization = organization.name;
          report.list = list?.length
            ? list.map((item: IPassportOfficeDataDto) => ({
                address: item.address.fullText,
                citizens: item.totalRegistryPeopleHouse,
              }))
            : [];
          return report;
        }),
      )
      .subscribe((report: IRegisteredCitizensReport) => {
        this.reportingService.buildRegisteredCitizensReport(report);
      });
  }

  /** Редактируем/Создаём полигон */
  public editPolygon() {
    this.startCreatePolygon = true;
  }

  /** Редактируем/Создаём полигон */
  public savePolygon() {
    this.startCreatePolygon = false;
  }

  /** Отмена редактирования полигона. Удаляем последнюю точку, пока они есть, затем закрываем редактирование. */
  public cancelPolygon() {
    if (this.objectPolygon.length > 1) {
      this.mapModel.removeObject('polygon-points', `${this.objectPolygon.length - 1}`);
      this.mapModel.removeObject('polygon', `${this.objectPolygon.length - 1}`);

      this.objectPolygon.pop();

      this.mapModel.addMarkerToLayer(
        'polygon',
        `${this.objectPolygon.length - 1}`,
        this.objectPolygon[this.objectPolygon.length - 1].toArray(),
        this.lastPointIcon,
      );
      this.mapModel.addPolygonToLayer(
        'polygon',
        'polygon',
        this.objectPolygon.map((point: Coordinates) => point.toArray()),
        { color: 'black', weight: 1 },
      );
    } else {
      this.startCreatePolygon = false;
      if (this.polygon?.id) {
        this.objectPolygon = this.polygon.coordinates;
      } else {
        this.clearPolygon();
      }
    }
  }

  /** Очищаем полигон */
  public clearPolygon() {
    this.objectPolygon = [];
    this.mapModel.removeLayer('polygon-points');
    this.mapModel.removeLayer('polygon');
  }

  /** Получение МО активного пользователя */
  private getMunicipal(): IAdminMunicipalSchemaDto {
    return this.settings.allMo.find((mo: IAdminMunicipalSchemaDto) => mo.id === this.user?.organizationId?.mo);
  }

  /** Получение координат МО */
  private getMunicipalCoordinates(): MapBaseCoordinatesType {
    const mo = this.getMunicipal();
    if (mo && mo.coordinates) {
      return Coordinates.coordinatesToArray(mo.coordinates);
    }
    return Coordinates.coordinatesToArray(this.settings.getConfig().defaultCoordinates);
  }
}
