/** Константы для селектора: Тип Укрытия в прогнозировании*/
const FIRST_FLOOR_LIST = ['firstFloor', 'basement'];
const SECOND_FLOOR_LIST = ['firstFloor', 'secondFloor', 'basement'];
const THIRD_FLOOR_LIST = ['firstFloor', 'secondFloor', 'thirdFloor', 'basement'];
const INDUSTRIAL_THIRD_FLOOR_LIST = ['firstFloor', 'secondFloor', 'thirdFloor'];
const FIFTH_FLOOR_LIST = ['firstFloor', 'secondFloor', 'thirdFloor', 'fourthFloor', 'fifthFloor', 'basement'];

export const SELECTOR_SHELTER_FLOOR = {
  industrialThreeFloorBuilding: INDUSTRIAL_THIRD_FLOOR_LIST,
  stonesOneFloorBuilding: FIRST_FLOOR_LIST,
  woodOneFloorBuilding: FIRST_FLOOR_LIST,
  stonesTwoFloorBuilding: SECOND_FLOOR_LIST,
  woodTwoFloorBuilding: SECOND_FLOOR_LIST,
  stonesThreeFloorBuilding: THIRD_FLOOR_LIST,
  stonesFiveFloorBuilding: FIFTH_FLOOR_LIST,
};
