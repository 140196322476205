<h2 mat-dialog-title>Новое задание</h2>

<mat-dialog-content class="mat-typography">
  <layout-form
    [formGroup]="formGroup"
    [options]="layoutFormOptions"
    (formInit)="onFormInit()">
  </layout-form>

  <ng-container *ngIf="dialogData.action === 'create'">
    <h3>Формирование списка абонентов для задания</h3>
    <mat-radio-group class="radio-btn-container" (change)="changeRadioButtonHandler($event)">
      <mat-radio-button checked [value]="radioButtonEntities.get('Citizens')">Заявители</mat-radio-button>
      <mat-radio-button [value]="radioButtonEntities.get('Admin')">Должностные лица</mat-radio-button>
    </mat-radio-group>

      <div *ngIf="radioButtonChoose === radioButtonEntities.get('Citizens') ">
        <layout-form
          [formGroup]="citizensFilterFormGroup"
          [options]="citizensFilterFormOptions"
          (onChangeFieldValue)="onChangeCitizensFilterField($event)">
        </layout-form>
        <registry
          [tableName]="'Citizens_Citizens'"
          [columns]="registryCitizens.columns"
          [requestQuery]="registryCitizens.query"
          [afterLoad]="registryCitizens.afterLoad"
          [pageSize]="10"
          [loadDataFn]="loadCitizenRegistryDataFn">
        </registry>
      </div>

      <div *ngIf="radioButtonChoose === radioButtonEntities.get('Admin') ">
        <layout-form
          [formGroup]="officialsFilterFormGroup"
          [options]="officialsFilterFormOptions"
          (onChangeFieldValue)="onChangeOfficialsFilterField($event)">
        </layout-form>
        <registry
          [tableName]="'Admin_Officials'"
          [columns]="registryOfficials.columns"
          [requestQuery]="registryOfficials.query"
          [afterLoad]="registryOfficials.afterLoad"
          [pageSize]="10"
          [loadDataFn]="loadOfficialsRegistryDataFn">
        </registry>
      </div>

  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <sc-button
    [options]="buttonCancelOptions"
    (clickButton)="onClickButtonCancel()">
  </sc-button>
  <sc-button
    [options]="buttonSaveOptions"
    (clickButton)="onClickButtonSave()">
  </sc-button>
</mat-dialog-actions>
