<form [formGroup]="form" errorTailor id="idForTest">
  <div>
    <div class="dialog-header" [class]="{ 'dialog-header-padding': data.title }">
      <div class="bg-row dialog-header-title">
        <h2 mat-dialog-title>{{ data.title }}</h2>
      </div>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="bg-row">
      <div class="bg-col-6">
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group">
              <sc-input
                [options]="shortDescriptionOptions"
                formControlName="shortDescription"
                maxlength="100"
              ></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-col-12 bg-form-group-padding-reset">
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-textarea
                  class="bg-form-description"
                  [options]="descriptionOptions"
                  formControlName="description"
                  maxlength="1000"
                ></sc-textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-col-6">
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group">
              <sc-input [options]="timeReactOptions" formControlName="timeReact"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group">
              <sc-select [options]="statusStepOptions" formControlName="status"></sc-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <sc-button [options]="cancelBtnOptions" (clickButton)="closeHandler()"></sc-button>
    <sc-button *ngIf="!form.disabled" [options]="saveBtnOptions" (clickButton)="saveHandler($event)"></sc-button>
  </mat-dialog-actions>
</form>
