import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';

import {
  IMapBaseEvent,
  IMapBaseIcon,
  IMapBaseInitOptions,
  MapBaseModel,
  MapBaseService,
} from '@smart-city/maps/sc';

import { IPolygonDto, ISubstrateDto } from '@bg-front/core/models/interfaces';
import { Coordinates } from '@bg-front/core/models/classes';
import { POLYGON_MARKER_SVG } from '@bg-front/core/models/constants';
import { BgMapService, PolygonsService, SubstratesQuery } from '@bg-front/core/services';
import { IMapBaseBaseUrlsOptions } from '@bg-front/map/models/interfaces';

@UntilDestroy()
@Component({
  selector: 'bg-polygon-edit',
  templateUrl: './polygon-edit.component.html',
  styleUrls: ['./polygon-edit.component.scss'],
})
export class PolygonEditComponent implements OnInit {
  /** Идентификатор полигона */
  @Input() distictId: string | undefined;

  /** Заголовок */
  @Input() title: string;

  /** Настройка карты */
  public mapOptions = <IMapBaseInitOptions>{};

  /** Модель карты */
  private mapModel: MapBaseModel;

  /** Сущность полигон */
  private polygon: IPolygonDto;

  /** Точки полигона */
  public polygonCoordinates: Coordinates[] = [];

  /** Флаг редактирования полигона */
  public editingPolygon: boolean = false;

  /** Этап сохранения полигона */
  public savingPolygon: boolean = false;

  /** Иконка для точки полигона */
  private pointIcon: IMapBaseIcon = this.mapService.makeIcon({
    iconSize: 8,
    iconColor: 'black',
    iconSVG: POLYGON_MARKER_SVG,
    shapeType: 'Круг',
    shapeSize: 10,
    shapeColor: 'black',
  });

  /** Иконка для последней точки полигона */
  private lastPointIcon: IMapBaseIcon = this.mapService.makeIcon({
    iconSize: 8,
    iconColor: 'blue',
    iconSVG: POLYGON_MARKER_SVG,
    shapeType: 'Круг',
    shapeSize: 12,
    shapeColor: 'blue',
  });

  /** @ignore */
  constructor(
    private readonly bgMapService: BgMapService,
    private readonly mapService: MapBaseService,
    private readonly notificationService: NzNotificationService,
    private readonly polygonsService: PolygonsService,
    private readonly substratesQuery: SubstratesQuery,
  ) {
  }

  /** @ignore */
  public ngOnInit() {
    this.getPolygon().subscribe(() => this.initMap());
  }

  /** @ignore */
  public initMap(): void {
    const transformSubstratesUrl: (url: string) => string = (url: string): string => {
      return url.startsWith('/', 0) ? `//${window.location.host}${url}` : `//${url}`;
    };

    const substrates = this.substratesQuery.getAll();
    const activeSubstrate = (this.substratesQuery.getActive() as ISubstrateDto) || substrates?.[0];

    const mapId = 'editPolygon';
    this.mapModel = new MapBaseModel(mapId, this.mapService);
    this.mapOptions.mapId = mapId;
    this.mapOptions.url = substrates?.length > 1 ? undefined : transformSubstratesUrl(activeSubstrate.link);
    this.mapOptions.urls =
      substrates?.length > 1
        ? substrates.map((el: ISubstrateDto): IMapBaseBaseUrlsOptions => {
          return <IMapBaseBaseUrlsOptions>{
            url: transformSubstratesUrl(el.link),
            name: el.name,
            attribution: el.attribution,
            selected: activeSubstrate ? el.id === activeSubstrate.id : el.default,
          };
        })
        : undefined;
    this.mapOptions.maxZoom = 18;
    this.mapOptions.mapStyle = { width: '100%', height: '100%' };
    this.mapOptions['attribution'] = substrates?.length > 1 ? undefined : activeSubstrate.attribution;
    this.mapOptions.zoom = 10;

    this.mapModel
      .getObservableMapEvents()
      .pipe(untilDestroyed(this))
      .subscribe((event: IMapBaseEvent) => {
        switch (event.typeEvent) {
          case 'mapClick': {
            if (event?.coordinates && this.editingPolygon) {
              this.polygonCoordinates.push(new Coordinates(event.coordinates[0], event.coordinates[1]));
              this.reDrawPolygon();
            }
            break;
          }
          case 'mapReady':
            this.reDrawPolygon();
            this.centerPolygon();
            break;
          default:
            break;
        }
      });
  }

  /** Получение данных полигона */
  private getPolygon(): Observable<null> {
    return this.distictId
      ? this.polygonsService.getById(this.distictId)
        .pipe(
          map((polygon: IPolygonDto | IPolygonDto[]) => {
            this.polygon = <IPolygonDto>polygon;
            this.polygonCoordinates = [...this.polygon.coordinates];
            this.reDrawPolygon();
            this.centerPolygon();
            return null;
          }),
        )
      : of(null);
  }

  /** Редактирование полигона */
  public editPolygon(): void {
    this.editingPolygon = true;
    this.reDrawPolygon();
  }

  /** Отмена редактирования полигона */
  public cancelEditPolygon(): void {
    if (!this.polygonCoordinates.length) {
      this.editingPolygon = false;
      this.polygonCoordinates = [...this.polygon.coordinates];
      this.reDrawPolygon();
    } else {
      this.polygonCoordinates.pop();
      this.reDrawPolygon();
    }
  }

  /** Сохранение полигона */
  public savePolygon(): void {
    this.savingPolygon = true;
    this.polygon.coordinates = this.polygonCoordinates;
    this.polygonsService.save(this.polygon)
      .pipe(
        switchMap(() => {
          this.editingPolygon = false;
          return this.getPolygon();
        }),
        catchError((error: Error) => {
          this.notificationService.error('Ошибка', 'Ошибка при сохранении полигона');
          return throwError(error);
        }),
        finalize(() => this.savingPolygon = false),
        untilDestroyed(this),
      )
      .subscribe(() => this.notificationService.success('Запрос выполнен', 'Полигон успешно сохранён'));
  }

  /** Перерисовка полигона */
  public reDrawPolygon(): void {
    if (this.mapOptions.mapId) {
      this.mapModel.removeLayer('polygon-points');
      this.mapModel.removeLayer('polygon');
      this.mapModel.addPolygonToLayer(
        'polygon',
        'polygon',
        this.polygonCoordinates.map((point: Coordinates) => point.toArray()),
        { color: 'black', weight: 1 },
      );
      this.polygonCoordinates.map((point: Coordinates, index: number) => {
        this.mapModel.addMarkerToLayer(
          'polygon-points',
          `${index}`,
          point.toArray(),
          index === (this.editingPolygon && this.polygonCoordinates.length - 1) ? this.lastPointIcon : this.pointIcon,
        );
      });
      this.mapModel.viewLayer('polygon', false);
      this.mapModel.viewLayer('polygon-points', false);
    }
  }

  /** Центрирование карты на полигоне */
  public centerPolygon(): void {
    if (this.mapOptions.mapId && this.polygonCoordinates?.length) {
      const center = this.bgMapService.getCenter(this.polygonCoordinates).split(',')
        .map((coord: string) => +coord);
      this.mapOptions.center = [center[0], center[1]];
    }
  }
}
