<div>
  <h4 nz-typography>
    <i nz-icon nzType="field-time" nzTheme="outline"></i>
    Результаты прогноза
  </h4>
  <nz-divider></nz-divider>
  <nz-descriptions nzTitle="Параметры пожара" nzBordered nzSize="small" [nzColumn]="1">
    <nz-descriptions-item nzTitle="Расстояние, пройденное огнём">
      {{ result?.fireCoveredDistance | commaToDot | number: '0.2-2' }} м
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Общая площадь пожара" *ngIf="result?.totalFireArea && +result?.totalFireArea > 0">
      {{ result?.totalFireArea | commaToDot | number: '0.2-2' }} м²
    </nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="Общая площадь пожара"
      *ngIf="result?.totalExtinguishingAgentConsumption && +result?.totalExtinguishingAgentConsumption > 0"
    >
      {{ result?.totalExtinguishingAgentConsumption | commaToDot | number: '0.2-2' }} л/с
    </nz-descriptions-item>
    <nz-descriptions-item
      nzTitle="Общая площадь тушения пожара"
      *ngIf="result?.totalFireExtinguishingArea && +result?.totalFireExtinguishingArea > 0"
    >
      {{ result?.totalFireExtinguishingArea | commaToDot | number: '0.2-2' }} м²
    </nz-descriptions-item>
  </nz-descriptions>
  <ng-container *ngFor="let zone of result.calculatedFireAreaZones">
    <h4 nz-typography>
      <i nz-icon nzType="field-time" nzTheme="outline"></i>
      Результаты прогноза
    </h4>
    <nz-divider></nz-divider>
    <nz-descriptions nzTitle="Зона горения: {{ zoneFireType.get(zone.id) }}" nzBordered nzSize="small" [nzColumn]="1">
      <nz-descriptions-item nzTitle="Площадь пожара">
        {{ zone.fireArea | commaToDot | number: '0.2-2' }} м²
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Расход огнетушащих средств">
        {{ zone.extinguishingAgentConsumption | commaToDot | number: '0.2-2' }} л/с
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Площадь тушения пожара">
        {{ zone.fireExtinguishingArea | commaToDot | number: '0.2-2' }} м²
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Подача огнетушащего вещества">
        {{ zone.extinguishingAgentSupply }}
      </nz-descriptions-item>
    </nz-descriptions>
  </ng-container>
</div>
