import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BgCoreModule } from '@bg-front/core';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { ScMapsModule } from '@smart-city/maps/sc';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

import {
  AccidentOnChemicalObjectTaskComponent,
  AccidentOnChemicalObjectTaskResultComponent,
  DestroyChemicalObjectTaskComponent,
  DestroyChemicalObjectTaskResultComponent,
  TimeApproachCloudToPointTaskComponent,
  TimeApproachCloudToPointTaskResultComponent,
} from './modules/forecasting-blowout/components';
import { ForecastingBlowoutService } from './modules/forecasting-blowout/services';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ScMapsModule,
    ErrorTailorModule,
    BgCoreModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzTypographyModule,
    NzDividerModule,
    NzNotificationModule,
    NzModalModule,
    NzDescriptionsModule,
  ],
  providers: [ForecastingBlowoutService],
  declarations: [
    TimeApproachCloudToPointTaskResultComponent,
    TimeApproachCloudToPointTaskComponent,
    DestroyChemicalObjectTaskResultComponent,
    DestroyChemicalObjectTaskComponent,
    AccidentOnChemicalObjectTaskResultComponent,
    AccidentOnChemicalObjectTaskComponent,
  ],
  exports: [
    TimeApproachCloudToPointTaskResultComponent,
    TimeApproachCloudToPointTaskComponent,
    DestroyChemicalObjectTaskResultComponent,
    DestroyChemicalObjectTaskComponent,
    AccidentOnChemicalObjectTaskResultComponent,
    AccidentOnChemicalObjectTaskComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ForecastingBlowoutModule {}
