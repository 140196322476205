import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccessService, IAccessAction, SfsService } from '@smart-city/core/services';
import { forkJoin, of } from 'rxjs';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { IEmergencyVaDetailDto } from 'smart-city-types';
import { IAbstractServiceData } from 'smart-city-types/services';
import { THIRTY_SECONDS } from '@bg-front/core/models/constants';
import { IDownloadSourcesOptions, IMonitoringDate, IVideoDeviceBg } from '../../models/interfaces';
import { VideoDevicesService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';
import { ImageVideoRecordingDialogComponent } from '../image-video-recording-dialog/image-video-recording-dialog.component';

@Component({
  selector: 'bg-video-result-repeat-grz',
  templateUrl: './video-result-repeat-grz.component.html',
  styleUrls: ['./video-result-repeat-grz.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoResultRepeatGrzComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Название первой камеры */
  public firstCameraName: string;
  /** Название второй камеры */
  public secondCameraName: string;
  /** Опции для кнопок скачать фото/видео с первой камеры */
  public optionsFromFirstCam: IDownloadSourcesOptions;
  /** Опции для кнопок скачать фото/видео со второй камеры*/
  public optionsFromSecondCam: IDownloadSourcesOptions;
  /** Внешний id камеры */
  public extCamera1Id: string = undefined;
  /** Внешний id камеры */
  public extCamera2Id: string = undefined;
  /** Опция id - сущности */
  @Input()
  public entityId: string;
  /** Номер инцидента*/
  @Input()
  public incidentNumber: string;
  /** Доступность скачивания материалов */
  public canPreparePhotoAndVideoForRepeatGrz: IAccessAction = {
    visible: false,
    enabled: false,
    name: 'CanPreparePhotoAndVideoForRepeatGrz',
  };
  /** Доступность просмотра фото и видео */
  public canPhotoAndVideo: IAccessAction = {
    visible: false,
    enabled: false,
    name: 'CanPhotoAndVideo',
  };

  private internalVaDetail: IEmergencyVaDetailDto;
  private timeBeforeEvent: number = THIRTY_SECONDS;
  private timeAfterEvent: number = THIRTY_SECONDS;

  constructor(
    private readonly videoDevicesService: VideoDevicesService,
    private readonly cdr: ChangeDetectorRef,
    private readonly sfs: SfsService,
    private readonly accessService: AccessService,
    private readonly dialog: MatDialog,
  ) {
    super();
  }

  public get vaDetail(): IEmergencyVaDetailDto {
    return this.internalVaDetail;
  }

  /**
   * Данные о видео происшествии
   * @param val данные
   */
  @Input()
  public set vaDetail(val: IEmergencyVaDetailDto) {
    if (val) {
      this.internalVaDetail = val;
      const vaTypeId = typeof val.vaTypeId === 'string' ? val.vaTypeId : val.vaTypeId.id;

      const videoServerDataCamera1 = this.videoDevicesService.getVideoDevice(val.camera1Id as string).pipe(
        takeUntil(this.ngUnsubscribe),
        mergeMap((device: IVideoDeviceBg) => {
          if (device) {
            this.firstCameraName = (device || <IVideoDeviceBg>{}).name;
            this.extCamera1Id = device.extId;
            return this.videoDevicesService.getVideoServerById(<string>device.videoServer);
          }
          return of(null);
        }),
        catchError((error: Error) => this.catchErrorFn<IVideoDeviceBg>(error, 'Ошибка загрузки данных о камере')),
      );

      const videoServerDataCamera2 = this.videoDevicesService
        .getVideoDevice(this.internalVaDetail.camera2Id as string)
        .pipe(
          mergeMap((device: IVideoDeviceBg) => {
            if (device) {
              this.extCamera2Id = device.extId;
              this.secondCameraName = (device || <IVideoDeviceBg>{}).name;
              return this.videoDevicesService.getVideoServerById(<string>device.videoServer);
            }
            return of(null);
          }),
          catchError((error: Error) => this.catchErrorFn<IVideoDeviceBg>(error, 'Ошибка загрузки данных камеры 2')),
          takeUntil(this.ngUnsubscribe),
        );

      forkJoin([
        this.videoDevicesService.getTypeVaSettingsByMonitoringSubject(vaTypeId),
        this.videoDevicesService.getVideoDeviceRangeStreamUrl(val.camera1Id as string, val.camera1Time),
        this.videoDevicesService.getVideoDeviceRangeStreamUrl(val.camera2Id as string, val.camera2Time),
        videoServerDataCamera1,
        videoServerDataCamera2,
      ])
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((result: [IMonitoringDate, string, string, IAbstractServiceData, IAbstractServiceData]) => {
          this.timeBeforeEvent = result[0]?.timeBeforeEvent || THIRTY_SECONDS;
          this.timeAfterEvent = result[0]?.timeAfterEvent || THIRTY_SECONDS;

          let fromTime = val.camera1Time - this.timeBeforeEvent;
          let toTime = val.camera1Time + this.timeAfterEvent;

          const items1 = result ? result[3].data.items : [];
          const serverData1 = [];
          if (items1.length) {
            serverData1[0] = {
              ...items1[0],
              extId: this.extCamera1Id,
            };
          }

          const items2 = result ? result[4].data.items : [];
          const serverData2 = [];
          if (items2.length) {
            serverData2[0] = {
              ...items2[0],
              extId: this.extCamera2Id,
            };
          }

          this.optionsFromFirstCam = {
            vaTypeId,
            fromTime,
            toTime,
            entityId: this.entityId,
            photoList: JSON.parse(val.file1Id),
            serverData: serverData1,
            direction: 'column',
            timeCreate: +val.camera1Time,
            index: '1',
            photoFileName: `Фотоматериалы_${this.incidentNumber} первая регистрация ГРЗ ТС`,
            videoFileName: `Видеоматериалы_${this.incidentNumber} первая регистрация ГРЗ ТС`,
            isShowVideoButton: !!result[1],
          };

          fromTime = val.camera2Time - (result[0]?.timeAfterEvent || THIRTY_SECONDS);
          toTime = val.camera2Time + (result[0]?.timeBeforeEvent || THIRTY_SECONDS);

          this.optionsFromSecondCam = {
            vaTypeId,
            fromTime,
            toTime,
            entityId: this.entityId,
            photoList: JSON.parse(val.file2Id),
            serverData: serverData2,
            direction: 'column',
            timeCreate: +val.camera2Time,
            index: '2',
            photoFileName: `Фотоматериалы_${this.incidentNumber} вторая регистрация ГРЗ ТС`,
            videoFileName: `Видеоматериалы_${this.incidentNumber} вторая регистрация ГРЗ ТС`,
            isShowVideoButton: !!result[2],
          };

          this.cdr.detectChanges();
        });

      this.cdr.detectChanges();
    }
  }

  public ngOnInit(): void {
    this.canPreparePhotoAndVideoForRepeatGrz = this.accessService.accessMap[
      this.canPreparePhotoAndVideoForRepeatGrz.name
    ];

    this.canPhotoAndVideo = this.accessService.accessMap[this.canPhotoAndVideo.name];

    this.optionsFromFirstCam = {
      ...this.optionsFromFirstCam,
      photoFileName: `Фотоматериалы_${this.incidentNumber} первая регистрация ГРЗ ТС`,
      videoFileName: `Видеоматериалы_${this.incidentNumber} первая регистрация ГРЗ ТС`,
    };
    this.optionsFromSecondCam = {
      ...this.optionsFromSecondCam,
      photoFileName: `Фотоматериалы_${this.incidentNumber} вторая регистрация ГРЗ ТС`,
      videoFileName: `Видеоматериалы_${this.incidentNumber} вторая регистрация ГРЗ ТС`,
    };

    this.cdr.detectChanges();
  }

  /**
   * Данные фото-видеофиксации
   * @param cameraId
   * @param fileId
   * @param registrationTime
   * @param entityId
   * @param entity
   */
  public showImageVideoRecording(
    cameraId: string,
    fileId: string,
    registrationTime: number,
    entityId: string,
    entity: string,
  ) {
    this.dialog.open(ImageVideoRecordingDialogComponent, {
      closeOnNavigation: true,
      panelClass: 'image-video-recording-dialog-container',
      data: {
        cameraId,
        entityId,
        entity,
        fromTime: registrationTime - this.timeBeforeEvent,
        toTime: registrationTime + this.timeAfterEvent,
        imageId: fileId,
      },
    });
  }
}
