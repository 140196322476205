import { Injectable } from '@angular/core';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IAbstractServiceData, IChemicalTypeDto } from 'smart-city-types';
import { BaseCrudService } from '@bg-front/core/services';

/**
 * Сервис для создания/редактирования/удаления типов АХОВ
 */
@Injectable({
  providedIn: 'root',
})
export class ChemicalTypesService extends BaseCrudService<IChemicalTypeDto> {
  constructor(rest: RestService, private readonly settings2: Settings2Service) {
    super({
      serviceName: 'Forecasting',
      entityName: 'ChemicalType'
    },
    rest)
  }

  /** Получает тип АХОВ по id */
  public getChemicalTypeNameById(id: string): Observable<string> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: {
          name: 'ChemicalType',
          attributes: ['name'],
          query: {
            id,
          },
        },
      })
      .pipe(
        map((res) => {
          return res?.data?.items[0].name as string;
        }),
      );
  }

  /** Получает тип АХОВ по id */
  public getChemicalTypeByIds(ids: string[]): Observable<IChemicalTypeDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: {
          name: 'ChemicalType',
          query: {
            id: {
              $in: ids,
            },
          },
        },
      })
      .pipe(
        map((res) => {
          return res?.data?.items as IChemicalTypeDto[];
        }),
      );
  }

  /** Определяет агрегатное состояние */
  public getAggregationStateById(id: string): IDictionaryInfo {
    return this.settings2
      .getDictionaryByTypeSysName('stateEmergencyChemicalSubstances')
      .find((item: IDictionaryInfo) => item.id === id);
  }

  /** Возвращает типы АХОВ, хранящиеся на важном объекте */
  public getChemicalTypesForSignificantObject(significantObject: string): Observable<IChemicalTypeDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: {
          name: 'StoredChemicals',
          query: {
            significantObject,
          },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return res?.data?.items.map((el) => el.chemicalType);
        }),
        mergeMap((res: string[]) => {
          return this.rest.serviceRequest({
            action: 'select',
            service: { name: 'Forecasting' },
            entity: {
              attributes: ['id', 'name', 'aggregationState.name', 'aggregationState.id'],
              name: 'ChemicalType',
              query: {
                id: { $in: res },
              },
            },
          });
        }),
        map((res: IAbstractServiceData) => {
          return res?.data?.items;
        }),
      );
  }

  /**
   * Позволяет следить за обновлением типа АХОВ
   */
  private chemicalTypeUpdated = new Subject<string>();
  public chemicalTypeUpdatedUpdated$ = this.chemicalTypeUpdated.asObservable();

  /**
   * Кидаем событие, когда тип АХОВ обновился
   * @param chemicalTypeId - ID типа АХОВ
   */
  public updateChemicalType(chemicalTypeId: string) {
    this.chemicalTypeUpdated.next(chemicalTypeId);
  }

  /**
   * Позволяет следить за добавлением типа АХОВ в реестр
   */
  private chemicalTypeAdded = new Subject<string>();
  public chemicalTypeAdded$ = this.chemicalTypeAdded.asObservable();

  /**
   * Кидаем событие, когда тип АХОВ добавляется в реестр
   * @param chemicalType - ID типа АХОВ
   */
  public addNewChemicalType(chemicalType: any) {
    this.chemicalTypeAdded.next(chemicalType);
  }
}
