import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { INwHeaderBarOptions, IScSelectOptions } from '@smart-city/core/common';
import { takeUntil } from 'rxjs/operators';
import { IAnyObject, IDictionaryModelDto } from 'smart-city-types';
import { BaseComponent } from '@bg-front/core/components';
import { Settings2Service } from '@smart-city/core/services';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import { IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { OperationFilterHelper } from '@bg-front/core/models/classes';

@Component({
  selector: 'map-vehicle-filter',
  templateUrl: './map-vehicle-filter.component.html',
  styleUrls: ['./map-vehicle-filter.component.scss']
})
export class MapVehicleFilterComponent extends BaseComponent implements OnInit, OnChanges {
  /** Значения фильтров */
  @Input()
  public mapLayerFilters: IMapLayerEntityFilter[] = [];

  /** Оповещение о изменении значений фильтров */
  @Output()
  public changedFilters: EventEmitter<IMapLayerEntityFilter[] | 'delete'> = new EventEmitter<IMapLayerEntityFilter[] | 'delete'>();

  /** Форма */
  public form: FormGroup;

  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions = {
    margin: 'collapse',
    title: 'Транспортные средства',
    buttons: [
      {
        type: 'button',
        options: {
          name: 'delete',
          icon: 'delete_outline',
        },
      },
    ],
  };

  /** Настройка компоненты Тип */
  public typeOptions: IScSelectOptions = {
    title: 'Тип',
    fieldName: 'name',
    isMultiple: true,
    modern: true,
    data: this.settings.getDictForSelect('vehicleType')
      .filter((item: IDictionaryModelDto) => (this.settings.currentUser.vehicleTypeIds ?? []).includes(item.id)),
  };

  /** Настройка компоненты Наименование */
  public brandOptions: IScSelectOptions = {
    title: 'Марка',
    entity: 'Brands',
    service: 'Vehicles',
    fieldName: 'name',
    isMultiple: true,
    modern: true,
  };

  /** Настройка компоненты Организация */
  public organizationOptions: IScSelectOptions = {
    title: 'Организация',
    entity: 'Organizations',
    service: 'Admin',
    fieldName: 'name',
    isMultiple: true,
    modern: true,
  };

  /** Настройка компоненты Государственный номер */
  public stateNumberOptions: IScSelectOptions = {
    title: 'Государственный номер',
    entity: 'Dictionary',
    service: 'Vehicles',
    fieldName: 'stateNumber',
    query: { typeId: { $in: this.settings.currentUser.vehicleTypeIds ?? [] } },
    isMultiple: true,
    modern: true,
  };


  constructor(private settings: Settings2Service) {
    super();
  }

  /** @ignore */
  ngOnInit(): void {
    this.form = new FormGroup({
      typeId: new FormControl(this.mapLayerFilters.find((item: IMapLayerEntityFilter) => item.property === 'typeId')?.value),
      brandId: new FormControl(this.mapLayerFilters.find((item: IMapLayerEntityFilter) => item.property === 'brandId')?.value),
      organizationId: new FormControl(this.mapLayerFilters.find((item: IMapLayerEntityFilter) => item.property === 'organizationId')?.value),
      stateNumber: new FormControl(this.mapLayerFilters.find((item: IMapLayerEntityFilter) => item.property === 'id')?.value),
    });

    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => this.changedFilters.emit(this.getFilters(value)));
  }

  /** @ignore */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue.length === 0) {
      this.form?.reset({}, { emitEvent: false });
    }
  }

  /**
   * Формирование фильтров на основании значения формы
   * @param value значение формы
   */
  getFilters(value: IAnyObject): IMapLayerEntityFilter[] {
    const result: IMapLayerEntityFilter[] = [];
    if (value.typeId?.length) {
      result.push({
        property: 'typeId',
        value: value.typeId,
        operation: FilterOperationEnum.in,
      });
    }
    if (value.brandId?.length) {
      result.push({
        property: 'brandId',
        value: value.brandId,
        operation: FilterOperationEnum.in,
      });
    }
    if (value.organizationId?.length) {
      result.push({
        property: 'organizationId',
        value: value.organizationId,
        operation: FilterOperationEnum.in,
      });
    }
    if (value.stateNumber?.length) {
      result.push({
        property: 'id',
        value: value.stateNumber,
        operation: FilterOperationEnum.in,
      });
    }
    return result;
  }

  public delete() {
    this.changedFilters.emit('delete');
    this.form.reset();
    this.form.enable();
  }
}
