import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  IForecastingDistanceTraveledByFireFrontDto,
  IForecastingDistanceTraveledByFireFrontResultDto,
} from '../../models/interfaces';
import { BaseComponent } from '@bg-front/core/components';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';

@Component({
  selector: 'bg-forecasting-distance-traveled-by-fire-front-result',
  templateUrl: './forecasting-distance-traveled-by-fire-front-result.component.html',
  styleUrls: ['./forecasting-distance-traveled-by-fire-front-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastingDistanceTraveledByFireFrontResultComponent extends BaseComponent implements OnInit {
  /**
   * Режим просмотра.
   * Отображать визуализацию не надо
   * TODO: После реализации формы просмотра убрать
   */
  @Input()
  public isShow = false;

  /** Результат рассчёта */
  @Input()
  public forecastingResults:
    IForecastingResultDto<IForecastingDistanceTraveledByFireFrontDto, IForecastingDistanceTraveledByFireFrontResultDto>;

  /** Скачать отчёт */
  @Input()
  public downloadReport: Observable<void>;

  /** Уведомляем о наличии отчёта */
  @Output()
  public hasReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
  }

  /** Результат прогнозирования */
  public get result(): IForecastingDistanceTraveledByFireFrontResultDto {
    return this.forecastingResults.result;
  }

  /** Параметры */
  public get params(): IForecastingDistanceTraveledByFireFrontDto {
    return this.forecastingResults.params.params as IForecastingDistanceTraveledByFireFrontDto;
  }

  public ngOnInit(): void {
    this.hasReport.emit(false);
    this.downloadReport.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.createReport);

    this.cdr.markForCheck();
  }

  /** Скачивание */
  public createReport() {
    throw ('Реализовать метод');
  }
}
