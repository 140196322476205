import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import {
  ICustomSelectItem,
  IForecastingResultDto,
  IForecastingTaskParamsDto,
  ISelectItem,
} from '@bg-front/core/models/interfaces';
import { ForecastingResultsService } from '@bg-front/core/services';
import { ForestryFacilitiesService } from '@bg-front/forestry-facilities/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDictionaryInfo, IScSelectItem } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeMap, switchMap } from 'rxjs/operators';
import { IForecastingDetailDto } from 'smart-city-types';

import { IForecastingStartFormOptions } from '../../models/interfaces';
import { BaseEmergencyService } from '../../services';
import { BaseForecastingService } from '../../services/base-forecasting/base-forecasting.service';
import { IForestryFacilityDto } from '@bg-front/forestry-facilities/models/interfaces';

@UntilDestroy()
@Directive()
export abstract class BaseForecastingFormComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public isShow: boolean;
  @Input()
  public data: IForecastingStartFormOptions;

  public form: FormGroup;

  /** Модель формы прогнозирования */
  public forecastingParams: IForecastingTaskParamsDto = {
    countTaskId: undefined,
    params: undefined,
  };

  public forecastingResults: IForecastingResultDto = {
    params: undefined,
    result: undefined,
  };

  /** Настройка компонента Объект, на котором произошла авария*/
  public objects$: Observable<ISelectItem[]>;
  // TODO: Фильтровать ещё по ксип
  /** Настройка компонента Инцидент */
  public incidents$!: Observable<ICustomSelectItem[]>;
  /** Настройка компонента Событие */
  public events$!: Observable<ICustomSelectItem[]>;

  /** Настройка компонента Расчётная задача */
  public countTasks = [];
  /** Настройка компоненты "Прогнозируемые риски" */
  public projectedRisks = this.settings.getDictForSelect('projectedRisks');
  /** Расчётная задача */
  public countTaskSysname: string;
  /** Id происшествия */
  public emergencyId: string;
  /** Id события */
  public eventId: string;

  public emergencyParams: IForecastingDetailDto;
  /** Наличие предыдущего расчёта по этой задаче */
  public hasPreviousCalculation = false;
  /** Текст поля number у происшествия */
  private emergencyNumber: string;

  constructor(
    public readonly settings: Settings2Service,
    private readonly forecastingService: BaseForecastingService,
    public readonly emergencyService: BaseEmergencyService,
    private readonly router: Router,
    public readonly fb: FormBuilder,
    private readonly forecastingResultsService: ForecastingResultsService,
    public readonly significantObjectsService: SignificantObjectsService,
    public readonly forestryFacilitiesService: ForestryFacilitiesService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    if (!this.data) {
      this.data = {};
    }

    this.forecastingParams.emergencyId = this.data?.emergencyId;
    this.forecastingParams.eventId = this.data?.eventId;
    this.forecastingParams.coordinates = this.data?.coordinates?.toString();
    this.forecastingParams.objectId = this.data?.object?.id;
    this.forecastingParams.projectedRiskId = this.data?.projectedRiskId;

    if (this.isShow) {
      this.forecastingParams = this.forecastingService.getForecastingFormState();
    }

    if (this.forecastingParams.objectId) {
      this.projectedRisks = this.settings
        .getDictForSelect('projectedRisks')
        .filter((el: IScSelectItem) => this.data.object?.projectedRiskIds?.includes(el.id));

      if (this.data?.isForestry) {
        this.objects$ = this.forestryFacilitiesService.getForestryFacilitiesForForecasting().pipe(untilDestroyed(this));
      } else {
        this.objects$ = this.significantObjectsService.getSignificantObjectsForForecasting().pipe(untilDestroyed(this));
      }
    } else {
      if (this.forecastingParams?.eventId) {
        this.events$ = this.getEventsForSelect();
        this.emergencyService
          .getEventProjectedRiskId(this.forecastingParams.eventId)
          .pipe(
            map((projectedRiskId: string | undefined) => {
              this.projectedRisks = this.settings
                .getDictForSelect('projectedRisks')
                .filter((el: IScSelectItem) => el.id === projectedRiskId);

              this.forecastingParams.projectedRiskId = projectedRiskId;
              this.form.get('projectedRiskId')?.patchValue(this.forecastingParams.projectedRiskId, {
                emitEvent: false,
              });
            }),
            switchMap(() => this.forecastingService.getEventForecastingParams(this.forecastingParams.eventId)),
            untilDestroyed(this),
          )
          .subscribe((result: { coordinates: string; params: IForecastingDetailDto }) => {
            if (result?.coordinates) {
              this.forecastingParams.coordinates = result.coordinates;

              // Если риск для прогнозироваания - "Лесной пожар", ищем ЛХО, в полигон которого попадает событие,
              // для последующего предзаполнения формы запуска прогнозирования.
              if (this.data?.isForestry) {
                this.forestryFacilitiesService.getForestryFacilityByCoordinates(Coordinates.coordinatesToArray(result.coordinates))
                  .pipe(untilDestroyed(this))
                  .subscribe((object: IForestryFacilityDto) => {
                    if (object) {
                      this.forecastingParams.objectId = object.id;
                      this.data.object = object;
                      this.form.controls.objectId.patchValue(object.id);
                    }
                  });
              }
            }
            if (result?.params) {
              this.emergencyParams = result.params;
            }

            this.initControls(this.forecastingParams.projectedRiskId);
          });
      } else if (this.forecastingParams?.emergencyId) {
        this.incidents$ = this.getIncidentsForSelect().pipe(untilDestroyed(this));
        if (this.data?.isForestry) {
          this.objects$ = this.forestryFacilitiesService
            .getForestryFacilitiesForForecasting({ id: this.forecastingParams.objectId });

          this.forestryFacilitiesService.getForestryFacilityForEmergency(this.forecastingParams.emergencyId)
            .pipe(untilDestroyed(this))
            .subscribe((object: IForestryFacilityDto) => {
              if (object) {
                this.forecastingParams.objectId = object.id;
                this.data.object = object;
              }
            });
        }

        this.emergencyService
          .getEmergencyProjectedRiskId(this.forecastingParams.emergencyId)
          .pipe(
            map((projectedRiskId: string | undefined) => {
              this.projectedRisks = this.settings
                .getDictForSelect('projectedRisks')
                .filter((el: IScSelectItem) => el.id === projectedRiskId);

              this.forecastingParams.projectedRiskId = projectedRiskId;
              this.form.get('projectedRiskId')?.patchValue(this.forecastingParams.projectedRiskId, {
                emitEvent: false,
              });
            }),
            switchMap(() => this.forecastingService.getIncidentForecastingParams(this.forecastingParams.emergencyId)),
            untilDestroyed(this),
          )
          .subscribe((result: { coordinates: string; params: IForecastingDetailDto }) => {
            if (result?.coordinates) {
              this.forecastingParams.coordinates = result.coordinates;
            }
            if (result?.params) {
              this.emergencyParams = result.params;
            }

            this.initControls(this.forecastingParams.projectedRiskId);
          });
      } else {
        this.projectedRisks = this.settings.getDictForSelect('projectedRisks');
        this.objects$ = this.forestryFacilitiesService.getForestryFacilitiesForForecasting();

        // Если риск для прогнозироваания - "Лесной пожар", ищем ЛХО, в полигон которого попадают указанные для
        // нового события координаты, для предзаполнения формы запуска прогнозирования.
        if (this.data?.isForestry && this.forecastingParams.coordinates) {
          this.forestryFacilitiesService.getForestryFacilityByCoordinates(Coordinates.coordinatesToArray(this.forecastingParams.coordinates))
            .pipe(untilDestroyed(this))
            .subscribe((object: IForestryFacilityDto) => {
              if (object) {
                this.forecastingParams.objectId = object.id;
                this.data.object = object;
                this.form.controls.objectId.patchValue(object.id);
              }
            });
        }
      }
    }

    this.initForm();
  }

  /**
   * Обработчик клика на кнопку Отмена
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public close(): void {}

  /**
   * Обработчик клика на кнопку Очистить
   */
  public clear(): void {
    this.form.reset();
    this.ngOnInit();
  }

  /** Начать расчет и перейти к результатам. */
  public startForecasting() {
    // this.form.updateValueAndValidity();
    if (this.form.valid) {
      this.getModel();
      return this.forecastingService
        .calculateForecasting(this.forecastingParams)
        .pipe(
          untilDestroyed(this),
          catchError((err: Error) => this.catchErrorFn(err, err.message)),
        )
        .subscribe((res) => {
          if (res) {
            this.forecastingResults.params = this.forecastingParams;
            this.forecastingResults.result = res;
            this.forecastingResults.objectId = this.forecastingParams.objectId;
            this.forecastingResults.emergencyNumber = this.emergencyNumber;
            this.forecastingResults.counterTaskId = this.forecastingParams.countTaskId;
            this.forecastingResultsService.add(this.forecastingResults);
            if (this.forecastingParams.objectId) {
              this.forecastingService.setPreviousCalculation(this.forecastingParams.countTaskId, {
                ...this.forecastingParams.params,
              });
            }
            if (this.forecastingParams.objectId) {
              this.router.navigate(
                [{ outlets: { leftPopup: ['forecastingResults', this.forecastingParams.objectId] } }],
                {
                  relativeTo: this.data.route.parent,
                  queryParamsHandling: 'merge',
                },
              );
            } else {
              this.router.navigate([{ outlets: { leftPopup: ['forecastingResults'] } }], {
                relativeTo: this.data.route.parent,
                queryParamsHandling: 'merge',
              });
            }
            this.close();
          }
        });
    }
  }

  /** Запрос предыдущего расчёта */
  public getPreviousData() {
    this.form.controls.forecasting.patchValue(
      this.forecastingService.getPreviousCalculation(this.form.controls.countTaskId.value),
    );
  }

  /** Инициализация формы */
  private initForm() {
    this.countTaskSysname = this.settings.getDictionaryById(this.forecastingParams.countTaskId)?.sysname;
    this.form = new FormGroup({
      projectedRiskId: new FormControl(this.forecastingParams.projectedRiskId, Validators.required),
      countTaskId: new FormControl(this.forecastingParams.countTaskId, Validators.required),
      emergencyId: new FormControl({
        value: this.forecastingParams.emergencyId,
        disabled: !!this.forecastingParams.emergencyId,
      }),
      eventId: new FormControl({
        value: this.forecastingParams.eventId,
        disabled: !!this.forecastingParams.eventId,
      }),
      objectId: new FormControl({
        value: this.forecastingParams.objectId,
        disabled: !!this.forecastingParams.objectId,
      }),
      forecasting: this.initChildForm(this.countTaskSysname),
      coordinates: new FormControl(this.forecastingParams.coordinates),
    });

    this.initControls(this.forecastingParams.projectedRiskId);

    if (this.isShow) {
      this.form.disable();
    } else {
      this.form.controls.emergencyId.valueChanges
        .pipe(
          distinctUntilChanged(),
          mergeMap((id: string) => {
            this.emergencyId = id;
            if (id) {
              // this.emergencyNumber = this.emergencyOptions.data.find((item: IScSelectItem) => item.id === id)?.text;
              return this.forecastingService.getIncidentForecastingParams(id);
            }
            return of(null);
          }),
          untilDestroyed(this),
        )
        .subscribe((result: { coordinates: string; params: IForecastingDetailDto }) => {
          if (result?.coordinates) {
            this.forecastingParams.coordinates = result.coordinates;
          }
          if (result?.params) {
            this.emergencyParams = result.params;
          }
        });

      this.form.controls.countTaskId.valueChanges
        .pipe(distinctUntilChanged(), untilDestroyed(this))
        .subscribe((value: string) => {
          if (value) {
            this.form.setControl('forecasting', this.initChildForm(this.settings.getDictionaryById(value)?.sysname));
            this.countTaskSysname = this.settings.getDictionaryById(value)?.sysname;
            this.hasPreviousCalculation = this.forecastingService.hasPreviousCalculation(value);
            /** Работать с компонентой можно только в случае, если не передавали конкретный инцидент */
            if (!this.forecastingParams.emergencyId) {
              this.form.controls.emergencyId.enable();
            }
            /** Работать с компонентой можно только в случае, если не передавали конкретный объект */
            if (!this.forecastingParams.objectId) {
              this.form.controls.objectId.enable();
            }
          } else {
            this.hasPreviousCalculation = false;
            /** Работать с компонентой можно только в случае, если не передавали конкретный инцидент */
            if (!this.forecastingParams.emergencyId) {
              this.form.controls.emergencyId.enable();
            }
            /** Работать с компонентой можно только в случае, если не передавали конкретный объект */
            if (!this.forecastingParams.objectId) {
              this.form.controls.objectId.enable();
            }
            this.countTaskSysname = undefined;
          }
        });

      this.form.controls.projectedRiskId.valueChanges
        .pipe(distinctUntilChanged(), untilDestroyed(this))
        .subscribe((value: string) => {
          if (value) {
            this.form.controls.countTaskId.setValue(null);
            /** Работать с компонентой можно только в случае, если не передавали конкретный инцидент */
            if (!this.forecastingParams.emergencyId) {
              this.form.controls.emergencyId.enable();
            }
            /** Работать с компонентой можно только в случае, если не передавали конкретный объект */
            if (!this.forecastingParams.objectId) {
              this.form.controls.objectId.enable();
            }
          } else {
            if (!this.forecastingParams.emergencyId) {
              this.form.controls.emergencyId.disable();
              this.form.controls.emergencyId.setValue(null);
            }
            if (!this.forecastingParams.objectId) {
              this.form.controls.objectId.disable();
            }
          }
          this.initControls(value);
        });

      if (this.forecastingParams.emergencyId) {
        this.forecastingService
          .getIncidentForecastingParams(this.forecastingParams.emergencyId)
          .pipe(untilDestroyed(this))
          .subscribe((result: { coordinates: string; params: IForecastingDetailDto }) => {
            this.forecastingParams.params = result?.params?.params;
            if (this.forecastingParams.params) {
              this.form.controls.forecasting.patchValue(this.forecastingParams.params);
            }
          });
      }

      // Подписка на изменение ЛХО, для заполнения характеристик лесных насаждений
      this.form.controls.objectId.valueChanges
        .pipe(
          switchMap((id: string) => {
            return id
              ? this.forestryFacilitiesService.getById(id)
              : of (null);
          }),
          untilDestroyed(this),
        )
        .subscribe((data: IForestryFacilityDto | null) => {
          this.form.controls.forecasting.patchValue({
            flammabilityClass: data?.flammabilityClass || null,
            forestTypeId: data?.forestTypeId || null,
            trunkDiameterId: data?.trunkDiameterId || null,
          });
        });
    }
  }

  public initControls(value: string) {
    if (value) {
      const projectedRiskSysName = this.settings
        .getDictionaryByTypeSysName('projectedRisks')
        .find((el: IDictionaryInfo) => el.id === value).sysname;
      switch (projectedRiskSysName) {
        case 'chemicalDischarge': {
          this.countTasks = this.settings.getDictForSelect('typeCountingTasks');
          this.setEmergencyQueryForChemicalDischarge();
          break;
        }
        case 'radiationSituation': {
          this.countTasks = this.settings.getDictForSelect('radioactiveForecastingTaskTypes');
          this.setEmergencyQueryForRadiationSituation();
          break;
        }
        default: {
          this.countTasks = [];
        }
      }
    } else {
      this.countTasks = [];
    }
  }

  /** Получить информацию из формы */
  private getModel(): void {
    this.forecastingParams.projectedRiskId = this.form.controls.projectedRiskId.value;
    this.forecastingParams.emergencyId = this.form.controls.emergencyId.value;
    this.forecastingParams.countTaskId = this.form.controls.countTaskId.value;
    this.forecastingParams.objectId = this.form.controls.objectId.value;
    this.forecastingParams.params = (this.form.controls.forecasting as FormGroup).getRawValue();
  }

  /**
   * Метод устанавливает query для выборки инцидентов для АХОВ
   * @return
   */
  private setEmergencyQueryForChemicalDischarge(): void {
    const coordinates = new Coordinates(
      this.data?.coordinates || this.data?.object?.coordinates || this.forecastingParams?.coordinates,
    );
    const incidentDocType = this.settings
      .getDictionaryByTypeSysName('docType')
      .find((item: IDictionaryInfo) => item.sysname === 'incident')?.id;
    const statusIds = this.settings
      .getDictionaryByTypeSysName('statusLifeCycleStep')
      .filter((item: IDictionaryInfo) => {
        return item.sysname === 'new' || item.sysname === 'inWork';
      })
      .map((item: IDictionaryInfo) => item.id);

    if (!this.forecastingParams?.emergencyId) {
      this.form.get('emergencyId')?.enable();
      this.form.get('emergencyId')?.setValue(undefined, {
        emitEvent: false,
      });
    } else {
      this.form.get('emergencyId')?.disable();
      this.form.get('emergencyId')?.setValue(this.forecastingParams?.emergencyId, {
        emitEvent: false,
      });
    }

    const emergencyQuery = {
      docType: incidentDocType,
      lifeCycleStepId: {
        status: {
          id: { $in: statusIds },
        },
      },
      point: coordinates.isValid()
        ? {
            $radius: {
              $meters: 50,
              $lat: coordinates.lat,
              $lon: coordinates.lon,
            },
          }
        : undefined,
      incidentTypeId: {
        projectedRisk: this.form?.get('projectedRiskId')?.value,
      },
      id: this.forecastingParams?.emergencyId,
    };

    this.incidents$ = this.emergencyService
      .getIncidentsListForForecasting(emergencyQuery, ['addressFact.fullText'])
      .pipe(untilDestroyed(this));

    const query = {
      $expr: {
        $in: ['$projectedRiskIds', this.form.controls.projectedRiskId.value],
      },
      id: this.forecastingParams?.objectId,
    };

    this.objects$ = this.significantObjectsService.getSignificantObjectsForForecasting().pipe(untilDestroyed(this));
  }

  /**
   * Метод устанавливает query для выборки инцидентов для Радиационной обстановки
   * @return
   */
  private setEmergencyQueryForRadiationSituation(): void {
    const coordinates = new Coordinates(
      this.data?.coordinates || this.data?.object?.coordinates || this.forecastingParams?.coordinates,
    );
    const incidentDocType = this.settings
      .getDictionaryByTypeSysName('docType')
      .find((item: IDictionaryInfo) => item.sysname === 'incident')?.id;
    const statusIds = this.settings
      .getDictionaryByTypeSysName('statusLifeCycleStep')
      .filter((item: IDictionaryInfo) => {
        return item.sysname === 'new' || item.sysname === 'inWork';
      })
      .map((item: IDictionaryInfo) => item.id);

    if (!this.forecastingParams?.emergencyId) {
      this.form.get('emergencyId')?.enable();
      this.form.get('emergencyId')?.setValue(undefined, {
        emitEvent: false,
      });
    } else {
      this.form.get('emergencyId')?.disable();
      this.form.get('emergencyId')?.setValue(this.forecastingParams?.emergencyId, {
        emitEvent: false,
      });
    }

    const emergencyQuery = {
      docType: incidentDocType,
      lifeCycleStepId: {
        status: {
          id: { $in: statusIds },
        },
      },
      point: coordinates.isValid()
        ? {
            $radius: {
              $meters: 50,
              $lat: coordinates.lat,
              $lon: coordinates.lon,
            },
          }
        : undefined,
      incidentTypeId: {
        projectedRisk: this.form?.get('projectedRiskId')?.value,
      },
      id: this.forecastingParams?.emergencyId,
    };

    this.incidents$ = this.emergencyService
      .getIncidentsListForForecasting(emergencyQuery, ['addressFact.fullText'])
      .pipe(untilDestroyed(this));

    const query = {
      $expr: {
        $in: ['$projectedRiskIds', this.form.controls.projectedRiskId.value],
      },
      id: this.forecastingParams?.objectId,
    };

    this.objects$ = this.significantObjectsService.getSignificantObjectsForForecasting().pipe(untilDestroyed(this));
  }

  /** Генерируем FormGroup для элемента */
  public abstract initChildForm(countTaskSysname: string): AbstractControl;

  /** Начальное получение инцидентов для селекта */
  public getIncidentsForSelect(): Observable<ICustomSelectItem<string>[]> {
    const coordinates = new Coordinates(
      this.data?.coordinates || this.data?.object?.coordinates || this.forecastingParams?.coordinates,
    );
    const incidentDocType = this.settings
      .getDictionaryByTypeSysName('docType')
      .find((item: IDictionaryInfo) => item.sysname === 'incident')?.id;
    const statusIds = this.settings
      .getDictionaryByTypeSysName('statusLifeCycleStep')
      .filter((item: IDictionaryInfo) => {
        return item.sysname === 'new' || item.sysname === 'inWork';
      })
      .map((item: IDictionaryInfo) => item.id);

    const emergencyQuery = {
      docType: incidentDocType,
      lifeCycleStepId: {
        status: {
          id: { $in: statusIds },
        },
      },
      point: coordinates.isValid()
        ? {
            $radius: {
              $meters: 50,
              $lat: coordinates.lat,
              $lon: coordinates.lon,
            },
          }
        : undefined,
    };

    return this.emergencyService
      .getIncidentsListForForecasting(emergencyQuery, ['addressFact.fullText']);
  }

  /** Начальное получение событий для селекта */
  public getEventsForSelect(): Observable<ICustomSelectItem<string>[]> {
    return this.emergencyService
      .getEventsListForForecasting({ id: this.forecastingParams.eventId }, ['address.fullText']);
  }
}
