import { Component, Input } from '@angular/core';
import { IPlacementDetailsDto } from 'smart-city-types';

@Component({
  selector: 'placement-details',
  templateUrl: './placement-details.component.html',
  styleUrls: ['./placement-details.component.scss'],
})
/**
 * Компонент реализует возможность просмотра деталей местоположения
 * таких, как Корпус, Этажность, Этаж, Подъезд, Квартира, Удаленность от трассы.
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 *  @example
 *
 *  Parent Component Class:
 *
 *  this.model = {
 *    corp: '12',
 *    storeys: '9',
 *    floor: '8',
 *    entrance: '1',
 *    room: '34',
 *    km: '1.5',
 *  };
 *
 *  Parent Component Template:
 *
 *  <placement-details [model]="model"></placement-details>
 */
export class PlacementDetailsComponent {

  /**
   * Модель
   */
  @Input()
  public model: IPlacementDetailsDto;
}
