import {
  IEmergencyResponsePlanStep,
  IAdminResponseScriptSteps,
  IOrganizationType,
  IAttractionOrganizationsList,
} from '../models/interfaces';

export const dataStepConvertor = (steps: (IAdminResponseScriptSteps)[], extendParams: {
  status: string, attractOrgStatus: string,
  operatorComment: string,
  emergencyId: string,
}): IEmergencyResponsePlanStep[] => {
  return steps.map((step: (IEmergencyResponsePlanStep | IAdminResponseScriptSteps)) => {
    const responseServiceType = ((step as IAdminResponseScriptSteps)?.responseServiceType as IOrganizationType);
    const informationInteraction = responseServiceType?.informationInteraction
      || !!(step as IAttractionOrganizationsList)?.organizationType;

    return {
      informationInteraction,
      number: isNaN(Number(step?.number)) ? 0 : Number(step?.number),
      description: step?.description,
      shortDescription: step?.shortDescription,
      timeReact: isNaN(Number(step?.timeReact)) ? 0 : Number(step?.timeReact),
      serviceType: responseServiceType?.id || (step as IAttractionOrganizationsList)?.organizationType,
      status: extendParams.status || null,
      attractOrgStatus: extendParams.attractOrgStatus || undefined,
      attractOrgData: responseServiceType?.organizationTypeId || step.id,
      operatorComment: extendParams.operatorComment,
      emergencyId: extendParams.emergencyId || null,
    };
  });
};
