import { Injectable } from '@angular/core';
import { IOrganization, ISignificantObjectCategory } from '@bg-front/core/models/interfaces';
import { BaseCrudService, CommonService } from '@bg-front/core/services';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';

import {
  IChemicalTypeForSelect,
  ISignificantObjectsDto,
  IFireFightingCardsPlansDto,
} from '../models/interfaces';
import { IScSelectItem } from '@smart-city/core/interfaces';

/**
 * Сервис для создания/редактирования/удаления важных объектов
 */
@Injectable({
  providedIn: 'root',
})
export class SignificantObjectsService extends BaseCrudService<ISignificantObjectsDto> {
  /** @ignore */
  constructor(
    rest: RestService,
    private readonly commonService: CommonService,
    private readonly settings: Settings2Service,
  ) {
    super({ serviceName: 'Admin', entityName: 'SignificantObjects' }, rest);
  }

  /**
   * Удаление важного объекта
   * @param ids - IDS важного объекта
   */
  public override delete(ids: string | string[]): Observable<IAbstractServiceData> {
    // Сначала удаляем хранимые АХОВ для важного объекта.
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: 'Forecasting' },
      entity: {
        name: 'StoredChemicals',
        query: { significantObject: Array.isArray(ids) ? { $in: ids } : ids },
      },
    }).pipe(switchMap(() => super.delete(ids)));
  }

  /** Получение списка категорий для селекта. */
  public getCategoriesForSelect(): Observable<ISignificantObjectCategory[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        attributes: ['id', 'name', 'objectTypes'],
        name: 'SignificantObjectCategories',
      },
    }).pipe(pluck('data', 'items'));
  }

  /** Получение списка МО для селекта. Если пользователь админ, то он видит все МО, если нет, то только своё */
  public getMoForSelect(): Observable<{ id: string; name: string }[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        attributes: ['id', 'name'],
        name: 'Municipal',
        query: {
          active: true,
          id: this.commonService.isAdmin() ? undefined : this.settings.currentUser.organizationId?.mo
        },
      },
    }).pipe(pluck('data', 'items'));
  }

  /** Получение типов АХОВ для селекта */
  public getChemicalTypeForSelect(): Observable<IChemicalTypeForSelect[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Forecasting' },
      entity: {
        attributes: ['id', 'name', 'aggregationState.name', 'aggregationState.sysname'],
        name: 'ChemicalType',
      },
    }).pipe(
      pluck('data', 'items'),
      map((chemicalTypes: IAnyObject[]) => chemicalTypes.map((chemicalType: IAnyObject) => ({
        id: chemicalType.id,
        name: chemicalType.name,
        aggregationStateName: chemicalType.aggregationState?.name,
        aggregationStateSysName: chemicalType.aggregationState?.sysname,
      }))),
    );
  }

  public getOrganizationsForSelect(): Observable<IScSelectItem[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        attributes: ['id', 'name'],
        name: 'Organizations',
        query: { active: true },
      },
    }).pipe(map((data: IAbstractServiceData) =>
        data.data.items.map((item: IOrganization) => {
          return { id: item.id, text: item.name };
        }),
      ),);
  }

  public getFireFightingCardPlan(organizationId: string): Observable<IFireFightingCardsPlansDto[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Directories' },
      entity: {
        attributes: ['id', 'number'],
        name: 'FireFightingCardsPlans',
        query: { objectOrganizationId: organizationId },
      },
    })
    .pipe(pluck('data', 'items'));
  }
}
