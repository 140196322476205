import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { takeUntil } from 'rxjs/operators';

/**
 * Компонент спиннер. Отображется при загрузке данных.
 */
@Component({
  selector: 'bg-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent extends BaseComponent implements OnInit {
  /** Событие перехода по роуту */
  @Output() public navigationEnd = new EventEmitter(true);

  /** @ignore */
  constructor(private router: Router) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
      }
      if (event instanceof NavigationEnd) {
        this.navigationEnd.emit();
      }

      // События сбоя или отмены запроса
      if (event instanceof NavigationCancel) {
      }
      if (event instanceof NavigationError) {
      }
    });
  }
}
