import { Pipe, PipeTransform } from '@angular/core';

/**
 * Пайп для конвертации boolean в текст (по умолчанию 'Активно/Не активно')
 */
@Pipe({
  name: 'booleanToText'
})
export class BooleanToTextPipe implements PipeTransform {

  /**
   * @param value - значение boolean
   * @param args - текстовые значения
   * @return string - значение string
   */
  transform(value: boolean, ...args: string[]): string {
    if (!args.length) {
      return value ? 'Активно' : 'Не активно';
    }

    return value ? args[0] : args[1];
  }
}
