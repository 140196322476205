<div class="bg-row bg-event-panel">
  <div class="bg-col bg-event-panel-edit-from">
    <div class="drawer-content">
      <form [formGroup]="eventForm" errorTailor id="idForTest">
        <nw-header
          [options]="headerActionsOptions"
          (clickButton)="onClickActionsButton($event)"
          class="header-fixed"
          (controlInit)="onHeaderInit($event)"
        ></nw-header>
        <div class="call-event-edit-form">
          <div class="bg-col" style="text-align: right" *ngIf="model?.incidentId">
            <div class="bg-form-group-incident">
              <a href="javascript:void(0)" (click)="unbindIncident()">
                <mat-icon>link_off</mat-icon>
              </a>
              <a href="javascript:void(0)" (click)="openEmergency()"> {{ linkedName }} </a>
            </div>
          </div>
          <mat-accordion multi="true">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title>
                  <div class="header-container">
                    <div>Главное</div>
                    <div class="header-container__divider">
                      <hr />
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="bg-row">
                <div class="bg-col-3">
                  <div class="bg-form-group full-width">
                    <sc-audio class="sc-audio" [src]="audioUrl" *ngIf="audioUrl"></sc-audio>
                  </div>
                </div>
                <div class="bg-col-3">
                  <div class="bg-form-group full-width">
                    <sc-audio class="sc-audio" [src]="audio112Url" *ngIf="audio112Url" [allowDownload]="false">
                    </sc-audio>
                  </div>
                </div>
              </div>
              <div class="bg-row">
                <div class="bg-col align-right">
                  <sc-checkbox [options]="byCoordinatesOptions" formControlName="byCoordinates"></sc-checkbox>
                </div>
              </div>
              <div class="bg-row" *ngIf="byCoordinates; else fiasAddress">
                <div class="bg-col">
                  <div class="bg-form-group full-width">
                    <sc-input [options]="coordinatesAddressOptions" formControlName="coordinatesAddress"></sc-input>
                  </div>
                </div>
              </div>
              <ng-template #fiasAddress>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <div nz-form nzLayout="vertical">
                        <nz-form-item>
                          <nz-form-label nzRequired>Адрес КСиП</nz-form-label>
                          <nz-form-control>
                            <nz-address-kit formControlName="factAddress"></nz-address-kit>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <div class="bg-row">
                <div class="bg-col-6">
                  <div class="bg-form-group">
                    <sc-select
                      [ngStyle]="{ display: !moIdVisible ? 'none' : '' }"
                      [options]="moIdOptions"
                      formControlName="moId"
                      (init)="onMoInit($event)"
                    ></sc-select>
                  </div>
                </div>
                <div class="bg-col">
                  <div class="bg-form-group">
                    <sc-select
                      [ngStyle]="{ display: !districtIdVisible ? 'none' : '' }"
                      [options]="districtIdOptions"
                      formControlName="districtId"
                      (init)="onDistrictInit($event)"
                    ></sc-select>
                  </div>
                </div>
              </div>
              <placement-details-edit formControlName="details"></placement-details-edit>
              <div class="bg-row">
                <div class="bg-col-2">
                  <div class="bg-form-group">
                    <sc-input [options]="exactCoordinatesOptions" formControlName="exactCoordinates"></sc-input>
                    <div class="bg-form-group specify-address-on-map-button">
                      <button
                        mat-raised-button
                        [color]="optionsSpecifyAddressOnMap.color"
                        class="bg-button-round"
                        (click)="onClickSpecifyAddress($event)"
                      >
                        {{ optionsSpecifyAddressOnMap.title }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="bg-col-7">
                  <div class="bg-form-group">
                    <bg-significant-object-list-dialog
                      [coordinates]="coordinates"
                      [radius]="500"
                      [disabled]="!this.countOfCloseImportantObjects"
                    ></bg-significant-object-list-dialog>
                  </div>
                </div>
                <div class="bg-col-1">
                  <div class="bg-form-group">
                    <sc-input [options]="declarerOptions"></sc-input>
                    <div class="bg-form-group specify-address-on-map-button">
                      <button
                        mat-raised-button
                        [color]="optionsDeclarerAddress.color"
                        class="bg-button-round declarant-copy-button"
                        (click)="copyHandler()"
                      > <div class='icon-copy-container'>
                        <div class="icon-copy"></div>
                      </div>
                        {{ optionsDeclarerAddress.title }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="bg-col-6">
                  <div class="bg-form-group">
                    <div class="bg-row bg-row-align-content-center space-between-container justify-content">
                      <div class="bg-col-min" *ngIf="countOfCloseImportantObjects">
                        <i
                          class="sc-icon-attention significant-object-attention"
                          [ngClass]="{ 'significant-object-danger': hasDangerObject }"
                        ></i>
                      </div>
                      <div class="bg-col">
                        <p>{{ this.closeImportantObjectsMessage }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-row">
                <div class="bg-col">
                  <div class="bg-form-group full-width">
                    <sc-select
                      [options]="optionsIncidentTypes"
                      formControlName="ksipTypeId"
                      [data]="loadKsipTypes$"
                    ></sc-select>
                  </div>
                </div>
              </div>

              <div class="bg-row">
                <div class="bg-col">
                  <div class="bg-form-group full-width">
                    <sc-select
                      [options]="optionsIncidentTypesDetails"
                      formControlName="ksipDetailsId"
                      [data]="loadKsipDetails$"
                    ></sc-select>
                  </div>
                </div>
              </div>
              <div class="bg-row" *ngIf = "isJhk">
                <div class="bg-col">
                  <div class="bg-form-group">
                    <sc-select [options]="jkhObjectOptions" formControlName="jkhObject"></sc-select>
                  </div>
                </div>
              </div>
              <div class="bg-row" *ngIf="linkToJkhObjectVisible">
                <div class="bg-col">
                  <div class="bg-form-group" *ngIf="isVisibleAtmLink$ | async; else objectLinkContent">
                    <a
                      class="blink--link"
                      target="_blank"
                      [state]="{ data: { isAddressBlockShow: true } }"
                      [routerLink]="[
                              '/dictionaries/monitoring-objects-hcs/monitoringObject',
                              monitoringObjectId
                            ]"
                    >
                      Адреса, принадлежащие объекту
                    </a>
                  </div>
                  <ng-template #objectLinkContent>
                    <p>Адреса, принадлежащие объекту, не найдены</p>
                  </ng-template>
                </div>
              </div>
              <div class="bg-row" *ngIf="isWorkTerm || isAccidentFormDetails">
                <div class="bg-col">
                  <div class="bg-form-group full-width">
                    <bg-datetime formControlName="workTermFrom"
                                 [minDate]='minFromDate'
                                 [options]="workTermFromOptions"
                                 [controlErrors]="workTermFromErrors">
                    </bg-datetime>
                  </div>
                </div>
                <div class="bg-col">
                  <div class="bg-form-group full-width">
                    <bg-datetime formControlName="workTermTo"
                                 [minDate]='minFromDate'
                                 [options]="workTermToOptions"
                                 [controlErrors]="workTermToErrors">
                    </bg-datetime>
                  </div>
                </div>
              </div>
              <div class="bg-row"></div>
              <div class="bg-row">
                <div class="bg-col">
                  <div class="bg-form-group">
                    <sc-textarea [options]="optionsDescription" formControlName="description"></sc-textarea>
                  </div>
                  <div class="bg-form-group">
                    <bg-datetime
                      label="Дата и время возникновения КСиП"
                      showErrorOnStart="true"
                      showErrorOnStartAfter="4000"
                      formControlName="ksipTime"
                    ></bg-datetime>
                  </div>
                </div>
                <div class="bg-col">
                  <div class="bg-form-group">
                    <sc-textarea [options]="optionsKsipInfo" formControlName="ksipInfo" class="textarea"></sc-textarea>
                  </div>
                  <div class="bg-form-group">
                    <sc-textarea [options]="optionsComment" formControlName="comment"></sc-textarea>
                  </div>
                </div>
              </div>
              <div class="bg-row">
                <div class="bg-col" *ngIf="urgentlyVisible">
                  <div class="bg-form-group">
                    <sc-checkbox [options]="optionsUrgently" formControlName="urgently"></sc-checkbox>
                  </div>
                </div>
                <div class="bg-col" *ngIf="threatsVisible">
                  <div class="bg-form-group">
                    <sc-checkbox [options]="optionsThreatPopulation" formControlName="threatPopulation"></sc-checkbox>
                  </div>
                </div>
                <div class="bg-col" *ngIf="threatsVisible">
                  <div class="bg-form-group">
                    <sc-checkbox
                      [options]="optionsThreatOrganization"
                      formControlName="threatOrganization"
                    ></sc-checkbox>
                  </div>
                </div>
              </div>
              <div class="bg-row" *ngIf="canChangedOrganization?.visible">
                <div class="bg-col">
                  <div class="bg-form-group full-width">
                    <sc-select
                      [options]="optionsOrganization"
                      (selectionChange)="organizationChange($event)"
                      formControlName="organizationId"
                    ></sc-select>
                  </div>
                </div>
                <div class="bg-col-min">
                  <div class="bg-form-group">
                    <mat-icon
                      class="mt-25 pointer"
                      [matTooltip]="responsibleOrganizations | detailOrgSelectorTooltip"
                      matTooltipPosition="right"
                      matTooltipClass="bg-org-selector-multiline-tooltip"
                      (click)="openDetailOrgDialog(responsibleOrganizations)"
                    >
                      info
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div class="bg-row">
                <div class="bg-col">
                  <div class="bg-form-group">
                    <sc-select
                      [options]="optionsResponsibleUser"
                      formControlName="responsibleUser"
                    ></sc-select>
                  </div>
                </div>
              </div>
              <div class="bg-row" *ngIf="attractOrganizationVisible">
                <div class="bg-col">
                  <h4><strong>Привлекаемые службы </strong></h4>
                </div>
              </div>
              <div class="bg-row" *ngIf="attractOrganizationVisible">
                <div class="bg-col bg-service-checkbox" *ngFor="let atrService of attractionServices; let idx = index">
                  <sc-checkbox
                    (changeValue)="attractionServiceChange($event, atrService.id)"
                    [label]="atrService.shortName"
                    [formControlName]="atrService.id"
                  ></sc-checkbox>
                  <div
                    class="bg-service-icon"
                    *ngIf="orgTypeParamOrganizList[atrService.id]?.length > 1 && showAttractionIcon"
                  >
                    <mat-icon [matTooltip]="serviceIconTooltipText">error_outline</mat-icon>
                  </div>
                </div>
              </div>
              <!--/form-->
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="attractOrganizationVisible">
            <mat-expansion-panel>
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title>
                  <div class="header-container">
                    <div>Детализация по службам</div>
                    <div class="header-container__divider">
                      <hr />
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="container">
                <div class="bg-row">
                  <ng-container *ngFor="let detailItem of detailOrganizationList; index as i">
                    <div class="bg-col">
                      <div class="bg-row">
                        <div class="bg-col">
                          <div class="bg-form-group">
                            <sc-select
                              [options]="detailItem.options"
                              (selectionChange)="attractOrganizationChanged($event, i)"
                              [formControlName]="detailItem.controlName"
                            ></sc-select>
                          </div>
                        </div>
                        <div class="bg-col-min">
                          <div class="bg-form-group">
                            <mat-icon
                              class="mt-25 pointer"
                              [matTooltip]="detailItem.foundOrganizations | detailOrgSelectorTooltip"
                              matTooltipPosition="right"
                              matTooltipClass="bg-org-selector-multiline-tooltip"
                              (click)="openDetailOrgDialog(detailItem.foundOrganizations)"
                            >
                              info
                            </mat-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="display: block; width: 100%" *ngIf="i % 2"></div>
                  </ng-container>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="isJhk">
            <mat-expansion-panel>
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title>
                  <div class="header-container">
                    <div>Дополнительная информация</div>
                    <div class="header-container__divider">
                      <hr />
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="bg-row" *ngIf="isSendingReformToJkhVisible">
                <div class="bg-col">
                  <div class="bg-form-group">
                    <sc-checkbox
                      [options]="sendingToJkhReformOptions"
                      formControlName="isSendingToJkhReform"
                    ></sc-checkbox>
                  </div>
                </div>
                <div class="bg-col-min" *ngIf="hcsReformSendingStatus">
                  <div class="bg-form-group">
                    <mat-icon
                      class="mt-25 pointer"
                      [matTooltip]="hcsReformSendingStatus"
                      [ngStyle]="{ color: hcsReformSendingStatusIconColor }"
                      matTooltipPosition="right"
                      matTooltipClass="bg-org-selector-multiline-tooltip"
                    >
                      info
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div *ngIf="isSendingReformToJkhValue">
                <div class="bg-row">
                  <div class="bg-col" *ngIf="isHousingService">
                    <div class="bg-form-group">
                      <sc-select
                        [options]="resourceConstraintTypeOptions"
                        formControlName="resourceConstraintType"
                      ></sc-select>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-checkbox
                        [options]="nestedRestrictionsOptions"
                        formControlName="isNestedRestrictions"
                      ></sc-checkbox>
                    </div>
                  </div>
                </div>

                <div class="bg-row" *ngIf="isNestedRestrictionsValue">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select [options]="jkhDirectionOptions" formControlName="jkhDirection"></sc-select>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion *ngIf="showForecastingParams">
            <mat-expansion-panel>
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title>
                  <div class="header-container">
                    <div>Параметры</div>
                    <div class="header-container__divider">
                      <hr />
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container [ngSwitch]="projectedRisk">
                <bg-forecasting-forest-fire-params-form formGroupName="forecasting" *ngSwitchCase="'forestFire'">
                </bg-forecasting-forest-fire-params-form>
                <bg-forecasting-technological-fire-event-form
                  formGroupName="forecasting"
                  *ngSwitchCase="'technologicalFire'"
                >
                </bg-forecasting-technological-fire-event-form>
                <bg-forecasting-radiation-chemically-params-form
                  *ngSwitchDefault
                  formGroupName="forecasting"
                  [projectedRisk]="projectedRisk"
                  [factAddress]="factAddress"
                >
                </bg-forecasting-radiation-chemically-params-form>
              </ng-container>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title>
                  <div class="header-container">
                    <div>Документы</div>
                    <div class="header-container__divider">
                      <hr />
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <multi-file [selfEnabled]="true" formControlName="documents"></multi-file>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </form>
    </div>
  </div>
  <div class="bg-col bg-col-3 bg-event-panel-side-block">
    <bg-interview
      [eventForm]="eventForm"
      [eventId]="model.id"
      [isHandled]="model.isHandled"
      [declarer]="model.declarerId"
      (surveyAnswer)="onSurveyAnswer($event)"
      (surveyReAnswer)="onSurveyReAnswer($event)"
    >
    </bg-interview>
  </div>
</div>
