<form nz-form [formGroup]="exportForm" nzLayout="vertical">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label>Тип файла</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="fileType" nzSize="large">
            <nz-option
              nzValue="xlsx"
              nzLabel="xlsx"
            ></nz-option>
            <nz-option
              nzValue="xls"
              nzLabel="xls"
            ></nz-option>
            <nz-option
              nzValue="csv"
              nzLabel="csv"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row *nzModalFooter>
    <div nz-col [nzSpan]="24">
      <button nz-button nzType="default" class="m-r" (click)="close()">Отмена</button>
      <button nz-button nzType="primary" (click)="export()">
        Экспортировать
      </button>
    </div>
  </div>
</form>
