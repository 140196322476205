import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  takeUntil } from 'rxjs/operators';
import { RegimesService } from '../../../services';
import { BaseComponent } from '@bg-front/core/components';

/**
 * Сообщение о существовании режимов функционирования
 */
@Component({
  selector: 'app-regimes-message',
  templateUrl: './regimes-message.component.html',
  styleUrls: ['./regimes-message.component.scss'],
})
export class RegimesMessageComponent extends BaseComponent implements OnInit, OnDestroy {

  /** Показывать или нет сообщение о наличие режимов функционирования */
  public regimesMessageShow: boolean = false;

  constructor(

    private route: ActivatedRoute,
    private router: Router,
    private regimesService: RegimesService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.regimesService.getRegimeForRegistryPanel()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((regimes) => {
      if (regimes?.length) {
        this.regimesMessageShow = true;
      } else {
        this.regimesMessageShow = false;
      }
    });
  }

  /** Событие нажатия на сообщении о существовании введенных режимов функционирования */
  public onRegimesMessageClick(): void {
    this.router.navigate([{ outlets: { leftSidebar: ['emergencyRegimes'], leftPopup: null } }], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }
}
