<form [formGroup]="form" errorTailor id="idForTest">
  <div class="panel-wrapper">
    <nw-header [options]="headerActionsOptions" (clickButton)="onClickActionsButton($event)"></nw-header>
    <div class="container">
      <div class="content">
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group">
              <sc-input [options]="nameOptions" formControlName="name"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group">
              <sc-textarea [options]="optionsDescription" formControlName="description"></sc-textarea>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group">
              <sc-checkbox [options]="optionsPolygonData" formControlName="polygonData"></sc-checkbox>
            </div>
          </div>
        </div>
        <div class="bg-row">
          <div class="bg-col">
            <div class="bg-form-group mini-map-container" [ngClass]="{ 'map-cursor': startCreatePolygon }">
              <map-base [mapOptions]="mapOptions"></map-base>
              <div class="elements">
                <button class="edit-button" *ngIf="!startCreatePolygon" (click)="editPolygon()">Редактировать</button>
                <button
                  class="edit-button"
                  *ngIf="startCreatePolygon && objectPolygon?.length >= 3"
                  (click)="savePolygon()"
                >
                  Сохранить
                </button>
                <button class="cancel-button" (click)="cancelPolygon()" *ngIf="startCreatePolygon">Отмена</button>
                <button class="clear-button" (click)="clearPolygon()">Удалить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
