import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IModifiedData } from '@smart-city/core/interfaces';
import { RestService, SubscriberService } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { IAbstractServiceData } from 'smart-city-types';
import { IKsipDetailsDto } from '../../../models/interfaces';
import { KsipDetailsStore } from '../store/ksip-details-store.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class KsipDetailsService {
  private isInit = false;

  constructor(
    private readonly rest: RestService,
    private readonly store: KsipDetailsStore,
    private readonly subs: SubscriberService,
  ) {}

  /*
   * Загружаем список Типов инцидентов
   */
  public initStore(): Observable<boolean> {
    this.store.setLoading(true);

    if (!this.isInit) {
      this.isInit = true;
      this.subs
        .onTableChange<IKsipDetailsDto>('Directories', 'KsipDetails')
        .pipe(untilDestroyed(this))
        .subscribe((result: IModifiedData<IKsipDetailsDto>) => {
          this.store.setLoading(true);
          if (result.action !== 'delete') {
            this.store.upsert(
              result.data.id,
              (oldState: IKsipDetailsDto) => ({ ...oldState, ...result.data }),
              (id: string, newState: IKsipDetailsDto) => ({ id, ...newState }),
            );
          } else {
            this.store.remove(result.data.id);
          }
          this.store.setLoading(false);
        });
    }

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Directories' },
        entity: {
          name: 'KsipDetails',
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          this.store.add(response?.data?.items as IKsipDetailsDto[]);
          return true;
        }),
        catchError(() => {
          return of(false);
        }),
        finalize(() => {
          this.store.setLoading(false);
        }),
      );
  }

  /**
   * Удаление записи
   */
  public delete(id: string): Observable<boolean> {
    this.store.setLoading(true);

    return this.rest
      .serviceRequest({
        action: 'delete',
        service: { name: 'Directories' },
        entity: {
          name: 'KsipDetails',
          query: {
            id,
          },
        },
      })
      .pipe(
        map(() => {
          return true;
        }),
        catchError(() => {
          return of(false);
        }),
        finalize(() => {
          this.store.setLoading(false);
        }),
      );
  }

  /**
   * Сохранение детализации КСиП
   * @param data - информация о детализации КСиП
   */
  public save(data: IKsipDetailsDto): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      data,
      action: 'upsert',
      service: { name: 'Directories' },
      entity: { name: 'KsipDetails', query: { id: data.id ? data.id : Uuid.newUuid() } },
    });
  }
}
