import { Injectable } from '@angular/core';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IUserInfoDto } from 'smart-city-types';
import { IUserInfo } from '../../../call/models/interfaces';

/**
 * Сервис по запросу кастомных данных из AdminService
 */
@Injectable({
  providedIn: 'root',
})
export class BgAdminService {
  constructor(private readonly rest: RestService, private readonly settings2: Settings2Service) {}

  /**
   * Запрос списка пользователей
   */
  public getUsersList(): Observable<IUserInfo[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'Users',
        linksMode: 'raw',
        attributes: [
          'atsPhone',
          'atsPhone.phoneNumber',
          'phone',
          'fio',
          'atsQueue',
          'atsQueue.id',
          'atsQueue.extName',
          'organizationId',
          'groups',
          'defaultWorkspace',
        ],
      },
    })
      .pipe(
        map((data: IAbstractServiceData) => {
          return (data.data.items || []);
        }),
      );
  }

  /**
   * Запрос пользователя по Id
   */
  public getUserById(id: string): Observable<IUserInfoDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Users',
          linksMode: 'raw',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((data: IAbstractServiceData) => {
          return (data.data.items || [])[0];
        }),
      );
  }

  /**
   * Метод на получение расширенных настроек по query
   * @param query - запрос
   * @return
   */
  public getExtendedSettings(query: IAnyObject): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        query,
        name: 'Settings',
        linksMode: 'raw',
      },
    });
  }

  /**
   * Получение МО пользователя
   * @return
   */
  public getUserMo(): string {
    return typeof this.settings2.currentUser.organizationId.mo === 'string'
      ? this.settings2.currentUser.organizationId.mo
      : this.settings2.currentUser?.organizationId?.mo?.id;
  }
}
