import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IModifiedData } from '@smart-city/core/interfaces';
import { RestService, SubscriberService } from '@smart-city/core/services';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';

import { IOrganization } from '../../../models/interfaces/organization.interface';
import { OrganizationsStore } from '../store/organizations-store.service';

/**
 * Сервис по обновлению хранилища организаций
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class OrganizationsStoreService {
  /** Флаг инициализации хранилища */
  private isInit = false;

  constructor(
    private readonly rest: RestService,
    private readonly store: OrganizationsStore,
    private readonly subs: SubscriberService,
  ) {}

  /**
   * Загружаем список Типов инцидентов
   */
  public initStore(): Observable<boolean> {
    this.store.setLoading(true);

    if (!this.isInit) {
      this.isInit = true;
      this.subs
        .onTableChange<IOrganization>('Admin', 'Organizations')
        .pipe(untilDestroyed(this))
        .subscribe((result: IModifiedData<IOrganization>) => {
          this.store.setLoading(true);
          if (result.action !== 'delete') {
            this.store.upsert(
              result.data.id,
              (oldState: Partial<IOrganization>) => ({ ...oldState, ...result.data }),
              (id: string, newState: Partial<IOrganization>) => {
                return <IOrganization>{ id, ...newState };
              },
            );
          } else {
            this.store.remove(result.data.id);
          }
          this.store.setLoading(false);
        });
    }

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          sort: { field: 'name', direction: 'asc' },
        },
      })
      .pipe(
        map((response: IAbstractServiceData): boolean => {
          this.store.add(response?.data?.items as IOrganization[]);
          return true;
        }),
        catchError(() => {
          return of(false);
        }),
        finalize(() => {
          this.store.setLoading(false);
        }),
      );
  }
  /** Получение организаций */
  public getOrganizationByQuery(query: IAnyObject): Observable<IOrganization[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          sort: { field: 'name', direction: 'asc' },
          query,
        },
      })
      .pipe(
        map((data: IAbstractServiceData) => {
          return data?.data?.items;
        }),
      );
  }
}
