import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { IRegistryPanelDashboardFilter } from '../../models/interfaces';

@Component({
  selector: 'bg-registry-panel-dashboard-filter',
  templateUrl: './registry-panel-dashboard-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistryPanelDashboardFilterComponent implements OnInit {
  @Input()
  public filter: IRegistryPanelDashboardFilter;
  /** Форма */
  public searchForm: FormGroup;

  /** @ignore */
  constructor(private readonly modalRef: NzModalRef) {
    this.searchForm = new FormGroup({
      text: new FormControl(undefined),
    })
  }

  /** @ignore */
  public ngOnInit(): void {
    this.searchForm.patchValue(this.filter);
  }

  /** Сохранение фильтра */
  public search(): void {
    this.modalRef.destroy({ ...this.searchForm.getRawValue() });
  }

  /** Закрытие формы */
  public close(): void {
    this.modalRef.destroy();
  }
}
