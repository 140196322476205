import { Injectable } from '@angular/core';
import { IExportOptions } from '@smart-city/core/common';
import { RestService } from '@smart-city/core/services';
import { Buffer } from 'buffer/';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';

@Injectable()
export class ExportService {
  constructor(public readonly rest: RestService) {}

  /**
   * Отправка запроса на экспорт табицы
   * @param options Настройки экспорта
   * @param attributes Список атрибутов
   * @param bookType Тип файла
   * @param query Запрос на выборку
   */
  public exportEntity(
    options: IExportOptions,
    attributes: string[],
    bookType: string,
    query?: IAnyObject,
  ): Observable<any> {
    return this.rest
      .serviceRequest({
        action: 'exportEntity',
        system: options.systemName ? { name: options.systemName } : undefined,
        service: {
          name: 'ImportExport',
        },
        data: {
          query,
          bookType,
          serviceName: options.serviceName,
          entityName: options.entityName,
          attributes: options.attributes || attributes || [],
          attributesFinal: attributes || [],
          titles: options.titles || {},
          limit: options.limit,
          sort: options.sort,
          utcOffset: options.utcOffset,
        },
      })
      .pipe(
        map((data: IAbstractServiceData) => {
          const blob: Blob = new Blob([Buffer.from(data.data.data)], {
            type: 'application/octet-stream',
          });
          saveAs(new Blob([blob]), `${options.title || `${options.serviceName}_${options.entityName}`}.${bookType}`);
          return data;
        }),
      );
  }
}
