import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BaseComponent } from '@bg-front/core/components';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { GisService, IGisServiceResult } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';

import {
  IForecastingTechnologicalFireAreaDto,
  IForecastingTechnologicalFireAreasTaskAreaResultDto,
  IForecastingTechnologicalFireAreasTaskDto,
  IForecastingTechnologicalFireAreasTaskResultDto,
  IForecastingTechnologicalFireReport,
} from '../../models/interfaces';
import { KseonUnitsService } from '../../../../../../kseon-units/src/lib/services';

import {
  ForecastingFiresReportService,
  ForecastingFiresService,
  ForecastingFiresVisualizationService,
} from '../../services';

@UntilDestroy()
@Component({
  selector: 'bg-forecasting-technological-fire-areas-task-result',
  templateUrl: './forecasting-technological-fire-areas-task-result.component.html',
  styleUrls: ['./forecasting-technological-fire-areas-task-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastingTechnologicalFireAreasTaskResultComponent extends BaseComponent implements OnInit, OnDestroy {
  /**
   * Режим просмотра.
   * Отображать визуализацию не надо
   * TODO: После реализации формы просмотра убрать
   */
  @Input()
  public isShow = false;

  @Input()
  public forecastingResults: IForecastingResultDto<
    IForecastingTechnologicalFireAreasTaskDto,
    IForecastingTechnologicalFireAreasTaskResultDto
  >;

  /** Используется для отображения типа зоны */
  public zoneFireType = new Map<string, string>();

  /** Скачать отчёт */
  @Input()
  public downloadReport: Observable<void>;

  /** Уведомляем о наличии отчёта */
  @Output()
  public hasReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  /** Уведомляем о формировании отчёта */
  @Output()
  public isDownloadReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  /** Ссылка на результат отрисовки */
  private displayElement: any;
  private displayCoordinates: any;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly forecastingService: ForecastingFiresService,
    private readonly forecastingVisualizationService: ForecastingFiresVisualizationService,
    private readonly gisService: GisService,
    private readonly reportingService: ForecastingFiresReportService,
    private readonly rest: RestService,
    private readonly significantObjectService: SignificantObjectsService,
    private readonly settings: Settings2Service,
  ) {
    super();
  }

  /** Результат прогнозирования */
  public get result(): IForecastingTechnologicalFireAreasTaskResultDto {
    return this.forecastingResults.result;
  }

  /** Параметры */
  public get params(): IForecastingTechnologicalFireAreasTaskDto {
    return this.forecastingResults.params.params;
  }

  public ngOnInit(): void {
    if (!this.isShow) {
      try {
        const calculation = this.forecastingVisualizationService.displayTechnologicalFireArea(
          this.forecastingResults.result,
        );
        this.displayElement = calculation.elements;
        this.displayCoordinates = calculation.coordinates;
      } catch (err) {
        this.catchErrorFn(err, 'Ошибка при отображении зоны на карте.');
      }
    }

    this.params.fireAreaZones.forEach((zone: IForecastingTechnologicalFireAreaDto) => {
      if (zone.id) {
        const { name, sysname }: { name: string; sysname: string } = this.settings.getDictionaryById(
          zone.technologicalFireFormId,
        );
        let str = name;
        if (sysname === 'rectangular') {
          str += `, сторона = ${zone.smallerSide} м`;
        } else {
          str += `, угол = ${zone.angleValue}*`;
        }
        this.zoneFireType.set(zone.id, str);
      }
    });

    this.hasReport.emit(true);
    this.downloadReport.pipe(untilDestroyed(this)).subscribe(() => this.createReport());

    this.cdr.markForCheck();
  }

  /** Скачивание */
  public createReport() {
    this.isDownloadReport.emit(true);
    const report: IForecastingTechnologicalFireReport = {};
    const params = this.params;
    const result = this.result;

    const coordinates = this.forecastingResults?.result
      ? this.displayCoordinates
          .map((coords: IAnyObject[]) => {
            if (
              coords.length &&
              coords[0][0] !== coords[coords.length - 1][0] &&
              coords[0][1] !== coords[coords.length - 1][1]
            ) {
              coords.push(coords[0]);
            }
            return coords;
          })
          .filter((item: number[][]) => !!item.length)
      : null;

    return forkJoin([
      this.forecastingResults.params.emergencyId
        ? this.forecastingService.getIncidentData(this.forecastingResults.params.emergencyId).pipe(
            catchError((err: Error) => {
              this.catchErrorFn(err, err.message);
              return of(null);
            }),
          )
        : of(undefined),
      (this.forecastingResults.objectId
        ? this.significantObjectService.getSignificantObjectById(this.forecastingResults.objectId)
        : of(null)
      ).pipe(
        catchError((err: Error) => {
          this.catchErrorFn(err, err.message);
          return of(null);
        }),
      ),
      (params.objectFireId
        ? this.rest.serviceRequest({
            action: 'select',
            service: { name: 'Admin' },
            entity: {
              name: 'FireObjects',
              query: { id: params.objectFireId },
            },
          })
        : of(null)
      ).pipe(
        map((result: IAbstractServiceData) => result.data.items[0]),
        catchError((err: Error) => {
          this.catchErrorFn(err, err.message);
          return of(null);
        }),
      ),
      this.rest
        .serviceRequest({
          action: 'select',
          service: { name: 'Forecasting' },
          entity: {
            name: 'FireFightingEquipment',
            query: {
              $or: (params?.fireAreaZones || []).map((item: IForecastingTechnologicalFireAreaDto) => ({
                id: item.fireFightingEquipmentId,
              })),
            },
          },
        })
        .pipe(
          map((data: IAbstractServiceData) => {
            return (data?.data?.items || []).reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
          }),
        ),
      (coordinates?.length
        ? forkJoin(coordinates.map((item: number[][]) => this.gisService.getAddressesByPolygonV2(item)))
        : of(null)
      ).pipe(
        catchError((err: Error) => {
          this.catchErrorFn(err, err.message);
          return of(null);
        }),
      ),
      (coordinates?.length
        ? forkJoin(
            coordinates.map((item: number[][]) =>
              this.forecastingService.getKseonUnit({
                point: {
                  $polygon: {
                    $points: item,
                  },
                },
              }),
            ),
          )
        : of(null)
      ).pipe(
        catchError((err: Error) => {
          this.catchErrorFn(err, err.message);
          return of(null);
        }),
      ),
    ])
      .pipe(
        mergeMap(([emergency, significantObject, fireObject, equipments, buildings, units]) => {
          /** Блок "Используемая методология для прогнозирования" */
          report.methodology = 'Методика и формулы расчёта сил и средств для тушения пожара. Проведение АСР';

          /** Блок "Местоположение" */
          report.address = significantObject?.address?.fullText || emergency?.addressFact?.fullText;
          report.coordinates =
            significantObject?.coordinates ||
            (emergency?.addressFact ? `${emergency.addressFact.latitude}, ${emergency.addressFact.longitude}` : '') ||
            'не заданы';
          report.object = significantObject?.name || '---';
          report.responsible = significantObject?.responsible || '---';
          report.phone = significantObject?.phone || '---';

          /** Блок "Исходные данные" */
          report.incident = this.forecastingResults.emergencyNumber || 'не задано';
          report.occurrenceTime = emergency?.timeCreate
            ? dayjs(emergency.timeCreate).format('DD.MM.YYYY')
            : 'не задано';
          report.fireObject = fireObject?.name;
          report.fireDistance = +params.fireCoveredDistance;
          report.auto = !!params.autoCalculateDistance;
          report.fireSpeed = +params.linearVelocity;
          report.fireTime = +params.propagationTime;
          report.firstBarrel = +params.firstSalvoTime;

          /** Блок "Данные о зоне горения" */
          report.fireZones = [];
          (params.fireAreaZones || []).forEach((item: IForecastingTechnologicalFireAreaDto) => {
            const zone: IAnyObject = {};
            const shape = this.settings.getDictionaryById(item.technologicalFireFormId)?.name;
            zone.sourceCoordinates = item.coordinates;
            zone.fireShape = shape || '---';
            zone.extinguishingAgent = equipments[item.fireFightingEquipmentId]?.name || '---';
            zone.params = [`Величина угла (в °): ${item.angleValue || 0}`];
            if (shape === 'Прямоугольная') {
              zone.params = [
                `Величина меньшей стороны (в м): ${(+(item.smallerSide || 0)).toFixed(2)}`,
                `Кол-во направлений распространения огня: ${item.directionsCount || 0}`,
                `Способ тушения огня: ${this.settings.getDictionaryById(item.fireExtinguishingWayId)?.name || ''}`,
                `Направление ветра (в °): ${item.windDirection}\``,
              ];
            }
            report.fireZones.push(zone);
          });

          /** Блок "Результаты расчёта" */
          report.fireDistanceResults = (+(result.fireCoveredDistance || 0)).toFixed(2);
          report.fireAreaResult = (+(result.totalFireArea || 0)).toFixed(2);
          report.fireExtinguishingAgentConsumptionResult = (+(result.totalExtinguishingAgentConsumption || 0)).toFixed(
            2,
          );
          report.fireExtinguishingAreaResult = (+(result.totalFireExtinguishingArea || 0)).toFixed(2);

          report.fireZonesResult = [];
          (result.calculatedFireAreaZones || []).forEach(
            (item: IForecastingTechnologicalFireAreasTaskAreaResultDto, index: number) => {
              const zone: IAnyObject = {};
              const shape = this.settings.getDictionaryById(
                (params.fireAreaZones || [])[index]?.technologicalFireFormId,
              )?.name;
              zone.combustionZone = `Зона горения: ${shape}`;
              if (shape === 'Прямоугольная') {
                zone.combustionZone += `, сторона = ${(params.fireAreaZones || [])[index]?.smallerSide} м`;
              }
              if (shape === 'Угловая') {
                zone.combustionZone += `, угол = ${(params.fireAreaZones || [])[index]?.angleValue}°`;
              }
              zone.fireArea = (+(item.fireArea || 0)).toFixed(2);
              zone.fireExtinguishingAgentConsumption = (item.extinguishingAgentConsumption || 0).toFixed(2);
              zone.fireExtinguishingArea = (item.fireExtinguishingArea || 0).toFixed(2);
              zone.fireExtinguishingAgentSupply = item.extinguishingAgentSupply;
              report.fireZonesResult.push(zone);
            },
          );

          /** Блок "Здания в зоне возможного распространения техногенного пожара" */
          report.buildings = [
            ...(buildings || []).reduce(
              (acc: IGisServiceResult[][], item: IGisServiceResult[]) => [...acc, ...item],
              [],
            ),
          ];

          /** Блок "Средства оповещения в зоне возможного распространения техногенного пожара" */
          report.devices = [
            ...(units[0] || []).map((item) => {
              return {
                type: item.type,
                name: item.name,
                currentSeanceName: item.currentSeanceName,
                syncTime: item.syncTime,
              };
            }),
          ];

          return of(report);
        }),
        finalize(() => this.isDownloadReport.emit(false)),
        untilDestroyed(this),
      )
      .subscribe((fireReport: IForecastingTechnologicalFireReport) => {
        this.reportingService.buildForecastingTechnologicalFireReport(fireReport);
      });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.forecastingVisualizationService.clearResults(this.displayElement);
  }
}
