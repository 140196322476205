import { AfterViewInit, Directive, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertDialogComponent, BaseComponent } from '@bg-front/core/components';
import { AppInjector, Coordinates, FormModal } from '@bg-front/core/models/classes';
import { BUTTON_ICONS } from '@bg-front/core/models/constants';
import { GrammarHelper } from '@bg-front/core/models/helpers';
import { IDateTimeOptions } from '@bg-front/core/models/interfaces';
import { IFileInfo } from '@bg-front/core/models/interfaces/file-info.interface';
import { MultiFileService } from '@bg-front/core/services';
import { IForestryFacilityDto } from '@bg-front/forestry-facilities/models/interfaces';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { RegistryPanelService } from '@bg-front/registry-panel/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  DialogService,
  GridTableComponent,
  IElementButton,
  IGridTableOptions,
  INwForm,
  INwHeaderBarOptions,
  IScCheckboxOptions,
  IScFias3Options,
  IScInputOptions,
  IScPopupMenuItem,
  IScSelectLoadParams,
  IScSelectOptions,
  IScTextareaOptions,
  ScSelectComponent,
} from '@smart-city/core/common';
import { IScDatepickerOptions } from '@smart-city/core/common/components/sc-datepicker/sc-datepicker.component';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { AccessService, IAccessAction, INotificationMessage, Settings2Service } from '@smart-city/core/services';
import { ScConsole, Uuid } from '@smart-city/core/utils';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { BehaviorSubject, forkJoin, noop, Observable, Observer, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  IAbstractServiceData,
  IAdminMunicipalSchemaDto,
  IAnyObject,
  IEmergencyDto,
  IEmergencyEventDto,
  IExecuteActionEventDto,
  IForecastingForestFireParamsDto,
  IForecastingTechnologicalFireParamsDto,
  IKsipTypeDto,
  ILifeCycleStepActionDto,
  ILifeCycleStepDto,
  ILifeCycleStepParamDto,
} from 'smart-city-types';

import { GarFindFlatResponseElement } from '@bg-front/core';
import { IMonitoringObjectHcsDto } from '../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/models/interfaces';
import { ReportingService } from '../../../bg/modules/reporting/services';
import { EmergencyDto } from '../../models/classes';
import { EmergencyReportsDialogResultEnum } from '../../models/enums';
import {
  IEmergencyResponsePlanStep,
  IIncidentEditForm,
  IKsipDetailsDto,
  IMiniMapMarkerPositionEvent,
  IOrganization,
  IOrganizationType,
  IStepPlanListOptions,
} from '../../models/interfaces';
import { ResponsePlanStepClickEvent } from '../../models/types';
import {
  AtmIntegrationService,
  BgAdminService,
  EmergencyService,
  MiniMapService,
  OrganizationsService,
  ResponsePlanStepService,
} from '../../services';
import { CreateInvolveStepComponent } from '../create-involve-step/create-involve-step.component';
import { CreateResponseStepComponent } from '../create-response-step/create-response-step.component';
import { EmergencyReportsDialogComponent } from '../emergency-reports-dialog/emergency-reports-dialog.component';
import { ForecastingForestFireParamsFormComponent } from '../forecasting-forest-fire-params-form/forecasting-forest-fire-params-form.component';
import { ForecastingRadiationChemicallyParamsFormComponent } from '../forecasting-radiation-chemically-params-form/forecasting-radiation-chemically-params-form.component';
import { ForecastingTechnologicalFireIncidentFormComponent } from '../forecasting-technological-fire-incident-form/forecasting-technological-fire-incident-form.component';
import { IncidentBackToWorkDialogComponent } from '../incident-back-to-work-dialog/incident-back-to-work-dialog.component';
import { IncidentReportsDialogComponent } from '../incident-reports-dialog/incident-reports-dialog.component';
import { InformResponseOrgFormComponent } from '../inform-response-org-form/inform-response-org-form.component';
import { InvolveOrgWithoutCommissionDialogComponent } from '../involve-org-without-commission-dialog/involve-org-without-commission-dialog.component';
import { InvolveOrgWithoutInteractionDialogComponent } from '../involve-org-without-interaction-dialog/involve-org-without-interaction-dialog.component';
import { InvolvedVehiclesEditDialogComponent } from '../involved-vehicles-edit-dialog/involved-vehicles-edit-dialog.component';
import { OrgInvolveRequestDialogComponent } from '../org-involve-request-dialog/org-involve-request-dialog.component';
import { ResponseOrgFormComponent } from '../response-org-form/response-org-form.component';

@UntilDestroy()
@Directive()
export abstract class BaseIncidentFormComponent
  extends BaseComponent
  implements IIncidentEditForm, OnInit, AfterViewInit
{
  /**
   * Форма
   */
  public incidentForm: FormGroup;
  /**
   * Модель
   */
  @Input()
  public model: EmergencyDto = new EmergencyDto();
  /**
   * Возможные действия с формой
   */
  @Input()
  public actions: ILifeCycleStepActionDto[] = [];
  /** Список аттрибутов */
  public attributes: ILifeCycleStepParamDto[] = [];
  /**
   * Признак блокировки формы
   */
  @Input()
  public disabled: boolean = false;

  /** Список сообщений об ошибке */
  public errors: INotificationMessage[] = [];

  /** Новая служба должна привлекаться через ЕДДС */
  protected readonly isInvolveThroughEdds: boolean = false;

  /**
   * Конфигурация заголовка
   */
  public headerActionsOptions: INwHeaderBarOptions = undefined;
  /**
   * Центр миникарты
   */
  public miniMapCenter: MapBaseCoordinatesType;
  /**
   * Зум для миникарты
   */
  public miniMapZoom: number;
  /**
   * Настройка компоненты ОтветственныйЫ пользователь
   */
  public optionsResponsibleUser: IScSelectOptions;
  /**
   * В радиусе 50 метров есть важные объекты
   */
  public hasDangerObject: boolean;
  /**
   * Количество важных объектов рядом с инцидентом
   */
  public countOfCloseImportantObjects: number;
  /**
   * Сообщение о важных объектах рядом с инцидентом
   */
  public closeImportantObjectsMessage: string;
  /** Мап контролов, Для вывода сообщения о невалидных контролах */
  public validationControlMap = new Map<string, string>();
  /** Проверка изменений */
  public isDirty$: Observable<boolean> = of(false);
  /** Виден ли комментарий для интернет портала */
  public needShowInternetPortalComment: boolean = false;
  public linkToJkhObjectVisible: boolean;
  public monitoringObjectId: string;
  public isVisibleAtmLink$: Observable<boolean> = of(false);
  /** Флаг для формы "Авария" */
  public isAccidentForm: boolean = false;
  /**
   * Настройка компоненты фактического адреса
   */
  public addressFactOptions: IScFias3Options = {
    label: 'Фактический адрес *',
    width: '100%',
    isCoordinatesNecessary: true,
  };
  /** Адрес заданный по координатам */
  public coordinatesAddressOptions: IScInputOptions = {
    label: 'Фактический адрес *',
    maxLength: 100,
  };
  /** RegExp для валидации координат */
  public coordinatesPattern: RegExp = /(-?\d{1,2}[.]\d+)[,][ ]+(-?\d{1,3}[.]\d+)/;
  /** Настройки чекбокса переключения на адрес по координатам */
  public byCoordinatesOptions: IScCheckboxOptions = {
    title: 'Адрес задан координатами',
  };
  /** Флаг - способ задания адреса */
  public byCoordinates: boolean = false;
  /** Адрес по координатам */
  public addressByCoordinates: GarFindFlatResponseElement;

  /**
   * Настройка компоненты муниципальное образование
   */
  public moIdOptions: IScSelectOptions = {
    title: 'Муниципальное образование',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'Municipal',
    fieldName: 'name',
    query: { active: true },
    afterLoadDataFn: (data: IScSelectItem[]) => {
      // Если только одно значение можно выбрать из селекта
      if (data.length === 1) {
        // Если текущее значение МО отличается от того который остался в селекте
        if (this.moIdComponent.value !== data[0].id) {
          this.moIdComponent.value = data[0].id;
          this.districtIdComponent.value = undefined;
          this.districtIdOptions.query = { active: true, municipalId: data[0].id };
          this.districtIdComponent.typeahead.next(undefined);
          this.incidentForm.controls.districtId?.enable();
        }
      } else {
        // Если значений не одно, надо проверить что текущее значение входит в список значений селекта.
        // В негативном случае сбросить значения. Так же надо сбросить если значений нет
        if (
          ![...data, ...(this.moIdOptions.data || [])].find(
            (item: IScSelectItem) => item.id === this.moIdComponent.value,
          )
        ) {
          this.moIdComponent.value = undefined;
          this.districtIdComponent.value = undefined;
          this.incidentForm.controls.districtId?.disable();
        }
      }
      // Видимость селекта МО
      this.moIdVisible = this.accessService.accessMap['EventEmergencyMoVisible']?.visible;
      return of(data);
    },
  };

  /**
   * Настройка компоненты район
   */
  public districtIdOptions: IScSelectOptions = {
    title: 'Район',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'Districts',
    fieldName: 'name',
    query: { active: true },
    beforeLoadDataFn: (params: IScSelectLoadParams) => {
      // Для сохранения текущего значения фильтра по введенной строке
      this.districtIdOptions.query = {
        ...this.districtIdOptions.query,
        ...params.query
      };
      return of(params);
    },
    afterLoadDataFn: (data: IScSelectItem[]) => {
      // Не скрывать контрол, если имеется фильтр по введенной строке
      if (!this.districtIdOptions.query.$text) {
        // Если нечего вывести в селекте, то скрыть его
        this.districtIdVisible =
          this.accessService.accessMap['EventEmergencyDistrictVisible']?.visible && data.length > 0;
        if (data.length === 0) this.districtIdComponent.value = null;
      }
      delete this.districtIdOptions.query.$text;
      return of(data);
    },
  };

  /**
   * Настройка компоненты Отчёт о выполнении инцидента
   */
  public reportOptions: IScTextareaOptions = {
    label: 'Отчёт о выполнении инцидента',
    maxLength: 1000,
    rows: 8,
  };
  /**
   * Настройка компоненты Доп информация о месте КСиП
   */
  public placeDescriptionOptions: IScTextareaOptions = {
    label: 'Дополнительная информация о месте КСиП',
    maxLength: 1000,
    rows: 8,
  };
  /**
   * Настройка компоненты Дополнительна информация о технике
   */
  public additionalInfoAboutCarsOptions: IScTextareaOptions = {
    label: 'Дополнительная информация о технике',
    maxLength: 1000,
    rows: 4,
  };
  /**
   * Настройка компоненты Контрольное время устранения
   */
  public timeLimitOptions: IScDatepickerOptions = {
    label: 'Контрольное время устранения',
  };
  /**
   * Настройка компоненты Тип КСиП
   */
  public optionsIncidentTypes: IScSelectOptions = {
    title: 'Тип КСиП *',
    clearable: true,
  };
  /**
   * Настройка компоненты Описание
   */
  public optionsDescription: IScTextareaOptions = {
    label: 'Описание',
    maxLength: 1000,
    rows: 6,
  };

  /**
   * Настройка компоненты отобразить на портале
   */
  public optionsShowOnPortal: IScCheckboxOptions = {
    title: 'Отобразить на Портале',
  };

  /**
   * Настройка компоненты Комментарий
   */
  public optionsComment: IScTextareaOptions = {
    label: 'Комментарий оператора',
    maxLength: 1000,
    rows: 6,
  };
  /**
   * Настройка компоненты Ответственный пользователь
   */
  public optionsResponsible: IScSelectOptions = {
    title: 'Ответственный пользователь',
    clearable: true,
    service: 'Admin',
    entity: 'Users',
    modern: true,
    fieldId: 'id',
    fieldName: 'fio',
  };

  /**
   * Настройка компоненты Срок исполнения
   */
  public optionsDeadline: IDateTimeOptions = {
    label: 'Срок исполнения',
    format: 'DD.MM.YYYY HH:mm:ss',
    dataType: 'unix',
  };

  /**
   * Настройка компоненты Ответственная организация
   */
  public optionsOrganization: IScSelectOptions = {
    title: 'Ответственная организация',
    clearable: true,
    service: 'Admin',
    entity: 'Organizations',
    query: { active: true },
    modern: true,
    fieldId: 'id',
    fieldName: 'name',
  };

  /**
   * Настройка компоненты Режим функционирования
   */
  public optionsRegime: IScSelectOptions = {
    title: 'Введен режим функционирования',
    service: 'Emergency',
    entity: 'Regimes',
    modern: true,
    fieldId: 'id',
    fieldName: 'type.name',
    query: { isActive: true },
    disabled: true,
    afterLoadDataFn: (regimes: IScSelectItem[]) =>
      of(
        regimes.map((regime: IScSelectItem) => {
          regime.text =
            regime.text +
            (this.settings.getDictionaryById(this.model['regime']?.state)?.sysname === 'withdrawal' ? ' (снят)' : '');
          return regime;
        }),
      ),
  };

  /** Настройка компоненты На контроле ЦУКС */
  public optionsSupervisedByCuks: IScCheckboxOptions = {
    title: 'На контроле ЦУКС',
  };

  /** Настройка компоненты Фактического адреса */
  public coordinatesOption: IScInputOptions = {
    label: 'Координаты фактического адреса',
    maxLength: 100,
  };

  /**
   * Настройка компоненты Срочно
   */
  public urgentlyOptions: IScCheckboxOptions = {
    title: 'Срочно',
  };

  /**
   * Настройка компоненты Угроза населению
   */
  public optionsThreatPopulation: IScCheckboxOptions = {
    title: 'Угроза населению',
  };

  /**
   * Настройка компоненты Угроза организации
   */
  public optionsThreatOrganization: IScCheckboxOptions = {
    title: 'Угроза организации',
  };

  /**
   * Настройка компоненты Доп информация о месте КСиП
   */
  public internetPortalCommentOptions: IScTextareaOptions = {
    label: 'Комментарий для заявителя',
    maxLength: 1000,
    rows: 8,
    placeholder: 'Комментарий для заявителей во всех связанных обращениях на Интернет-портале',
  };

  /** Количество граждан, зарегистрированных в жилом доме */
  public internetPortalDescOptions: IScTextareaOptions = {
    label: 'Описание для интернет-портала',
    maxLength: 300,
    rows: 8,
  };

  /** Количество граждан, зарегистрированных в жилом доме */
  public totalRegistryPeopleHouseOptions: IScInputOptions = {
    label: 'Количество граждан, зарегистрированных в жилом доме',
    type: 'number',
  };

  /** Количество граждан, проживающих в жилом доме временно */
  public totalTemporalPeopleHouseOptions: IScInputOptions = {
    label: 'Количество граждан, проживающих в жилом доме временно',
    type: 'number',
  };
  /** Индивидуальное домовладение */
  public individualOwnershipOptions: IScCheckboxOptions = {
    title: 'Индивидуальное домовладение',
  };

  /** Категория электроснабжения потребителей  */
  public consumerPowerCategoryIdOptions: IScSelectOptions = {
    title: 'Категория электроснабжения потребителей',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'Dictionary',
    query: { 'typeid.sysname': 'powerSupplyConsumerCategory' },
    fieldName: 'name',
  };

  /** Объект ЖКХ */
  public jkhObjectOptions: IScSelectOptions = {
    title: 'Объект ЖКХ',
    clearable: true,
    modern: true,
    service: 'AtmIntegration',
    entity: 'MonitoringObject',
    fieldName: 'name',
    query: { active: true },
  };

  /** Отправить в "Реформу ЖКХ"  */
  public sendingToJkhReformOptions: IScCheckboxOptions = {
    title: 'Отправить в "Реформу ЖКХ"',
  };

  /** Тип ограничения ресурса  */
  public resourceConstraintTypeOptions: IScSelectOptions = {
    title: 'Тип ограничения ресурса',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'Dictionary',
    query: { 'typeid.sysname': 'resourceLimitationType' },
    fieldName: 'name',
  };

  /** Связанные ограничения  */
  public relatedRestrictionsOptions: IScCheckboxOptions = {
    title: 'Связанные ограничения',
  };

  /** Cфера ЖКХ */
  public jkhDirectionOptions: IScSelectOptions = {
    title: 'Сфера ЖКХ',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'IncidentCategories',
    width: '100%',
    isMultiple: true,
    fieldName: 'name',
    query: { 'sectionId.sysname': 'zhkh' },
  };

  /**
   * Объекты первой категории, затронутые ограничением
   */
  public favorableRestrictionObjectsOptions: IScTextareaOptions = {
    label: 'Объекты первой категории, затронутые ограничением',
    maxLength: 1000,
    rows: 8,
  };

  /**
   * Иные объекты, затронутые ограничением
   */
  public affectedRestrictionObjectsOptions: IScTextareaOptions = {
    label: 'Иные объекты, затронутые ограничением',
    maxLength: 1000,
    rows: 8,
  };

  /** Опции для настройки блока "Параметры" */
  public projectedRisk: string;
  /** Флаг для отображения блока "Параметры" */
  public showForecastingParams: boolean = false;
  /**
   * Настройка компоненты Тип КСиП
   */
  public optionsKsipDetails: IScSelectOptions = {
    title: 'Детализация КСиП',
  };
  /** Настройки для настройки отображения блока: Привлекаемые службы */
  public involvedOrgOpts: IStepPlanListOptions;
  /** Настройки для настройки отображения блока: Реагирование */
  public responseOrgOpts: IStepPlanListOptions;
  /** Поле для списка привлекаемых служб */
  public involvedOrgStepsList: IEmergencyResponsePlanStep[] = [];
  /** Поле для списка служб которым было отправдено донесение */
  public responseOrgStepsList: IEmergencyResponsePlanStep[] = [];
  /**
   * Отображение блока Привлекаемые службы.
   * Так же влияет на активность блока для происшествий, которые не являются поручениями.
   */
  public involvedServiceEnabled: boolean = false;
  /** Проверка на конец цикла инцидента */
  public isEmergencyEndStepCycle: boolean = false;
  /** Опции грида */
  public gridOptions: IGridTableOptions = {};
  /** Опции грида c событиями */
  public eventsGridOptions: IGridTableOptions = {};
  /** Водоисточники */
  public waterSourcesGridOptions: IGridTableOptions = {};
  /** Опции грида c задействованным транспортом */
  public vehiclesGridOptions: IGridTableOptions = {};
  /** Условие отбора записей для грида с событиями */
  protected eventsGridQuery: IAnyObject = {};
  /** Условие отбора записей для грида с задействованным транспортом */
  protected vehiclesGridQuery: IAnyObject = {};
  /** Форма поиска ТС */
  private vehiclesSearchForm: INwForm;
  /** Доступность контрола Организация */
  public canForecastingRun: IAccessAction = { visible: false, enabled: false, name: 'CanForecastingRun' };
  /** Лесохозяйственный объект */
  public forestryFacility: IForestryFacilityDto;
  /** Фактический адрес */
  public factAddress: GarFindFlatResponseElement;
  /** Функция загрузки Типов КСиП */
  public loadKsipTypes$: Observable<IScSelectItem[]>;
  /** Функция загрузки Детализации Типа КСиП */
  public loadKsipDetails$: Observable<IScSelectItem[]>;
  /** флаг на значение 'Эксплуатация жилищного фонда' */
  public isHousingService: boolean;
  /** Видимость Отправить в "Реформу ЖКХ" */
  public isSendingReformToJkhVisible: boolean;
  /** Видимость чекбокса Срочно */
  public urgentlyVisible: boolean = true;
  /** Видимость чекбоксов Угроза населению и Угроза организациям */
  public threatsVisible: boolean = true;
  /** Флаг для отображения контролов жкх */
  public isJhk: boolean = false;
  /** Эталонное значение формы */
  protected ngMasterValue: BehaviorSubject<IAnyObject>;
  /** id ДДС01  */
  private dds01Id: string;
  /** Тип источника получения */
  private sourceType: string;
  /** Рабочий стол */
  protected workspace: string;
  /** Флаг для формы "Плановые работы" */
  public isPlanWorkForm: boolean = false;
  /** Блок "Параметры" */
  @ViewChild(ForecastingRadiationChemicallyParamsFormComponent)
  private forecastingParamsForm: ForecastingRadiationChemicallyParamsFormComponent;
  /** Компонент грида */
  @ViewChild('gridComponent', { static: false }) private gridComponent: GridTableComponent;
  /** Компонент грида ТС*/
  @ViewChild('vehiclesGrid', { static: false }) private vehiclesGridComponent: GridTableComponent;

  /** Настройка компоненты Плановый срок проведения работ с */
  public workTermFromOptions: IDateTimeOptions = {
    label: 'Плановый срок проведения работ с *',
    format: 'DD.MM.YYYY HH:mm',
    dataType: 'unix',
  };

  /** Ошибки компоненты  Плановый срок проведения работ с */
  public workTermFromErrors = {
    minDate: 'Не может быть ранее текущей даты',
  };

  /** Настройка компоненты Плановый срок проведения работ с */
  public workTermToOptions: IDateTimeOptions = {
    label: 'Плановый срок проведения работ по *',
    format: 'DD.MM.YYYY HH:mm',
    dataType: 'unix',
  };

  /** Ошибки компоненты  Плановый срок проведения работ с */
  public workTermToErrors = {
    minDate: 'Не может быть ранее текущей даты',
    minDateOf: 'Не может быть ранее даты начала работ',
  };
  /** Флаг для формы "Авария" Детализации Ксип */
  public isAccidentFormDetails: boolean = false;
  /** Статус отправки информации в реформу ЖКХ */
  public hcsReformSendingStatus: string = 'Чтобы отправить в Реформу ЖКХ заполните поле Объект ЖКХ';
  /** Цвет иконки для статуса отправки информации в реформу ЖКХ */
  public hcsReformSendingStatusIconColor: string = undefined;
  /** Компонент селекта МО */
  protected moIdComponent: ScSelectComponent;
  /** Компонент селекта Район */
  protected districtIdComponent: ScSelectComponent;
  /** Видимость селекта МО */
  public moIdVisible: boolean = true;
  /** Видимость селекта Район */
  public districtIdVisible: boolean = true;

  /**
   * @ignore
   */
  protected constructor(
    protected readonly accessService: AccessService,
    protected readonly atmIntegrationService: AtmIntegrationService,
    protected readonly bgAdminService: BgAdminService,
    protected readonly dialog: MatDialog,
    protected readonly dialogService: DialogService,
    protected readonly emergencyService: EmergencyService,
    protected readonly fb: FormBuilder,
    protected readonly ksipCategoriesQuery: KsipCategoriesQuery,
    protected readonly ksipDetailsQuery: KsipDetailsQuery,
    protected readonly ksipSectionsQuery: KsipSectionsQuery,
    protected readonly ksipTypesQuery: KsipTypesQuery,
    protected readonly miniMapService: MiniMapService,
    protected readonly multiFileService: MultiFileService,
    protected readonly organizationsService: OrganizationsService,
    protected readonly reportingService: ReportingService,
    protected readonly route: ActivatedRoute,
    protected readonly router: Router,
    protected readonly settings: Settings2Service,
    protected readonly significantObjectService: SignificantObjectsService,
    public readonly responsePlanStepService: ResponsePlanStepService,
  ) {
    super();
  }

  /**
   * @ignore
   */
  public ngOnInit(): void {
    this.byCoordinates = this.model.byCoordinates;

    this.workspace = this.route.snapshot.data.workspace;
    // Видимость чекбокса Срочно
    this.urgentlyVisible = this.accessService.accessMap['UrgentlyAvailable']?.visible;
    // Видимость чекбоксов Угроза населению и Угроза организациям
    this.threatsVisible = this.accessService.accessMap['ThreatsAvailable']?.visible;
    // Отображение блока Привлекаемые службы.
    this.involvedServiceEnabled =
      this.accessService.accessMap['CanAttractionServicesShow']?.visible &&
      this.accessService.accessMap['CanAttractionServicesShow']?.enabled;
    // Установка полей для связанных с ЖКХ
    this.setFieldsByKsipType(this.model.incidentTypeId);
    this.canForecastingRun = this.accessService.accessMap[this.canForecastingRun.name];

    this.sourceType = this.settings.getDictObjsIdsByType('sourceTypes')[<string>this.model.sourceType]?.sysname;

    this.factAddress = this.model?.addressFact;

    // Инициализация контролов МО и Района
    this.moAndDistrictInit();

    this.isEmergencyEndStepCycle = (this.model.lifeCycleStepId as ILifeCycleStepDto)?.endOfCycle;
    if (this.sourceType === 'internetPortal') {
      this.needShowInternetPortalComment = true;
    }

    /**
     * Подписываемся на изменения фактического адреса (если контрол добавлен), для дальнейшего получения координат
     */
    this.incidentForm.controls.addressFact?.valueChanges
      .pipe(debounceTime(1500), untilDestroyed(this))
      .subscribe((val: GarFindFlatResponseElement) => {
        const { level } = val || {};
        let detail = this.incidentForm.get('detailsFact')?.value;
        detail = detail ? { ...detail, storeys: level } : { storeys: level };
        this.incidentForm.get('detailsFact').setValue(detail);

        this.factAddress = val;
        /** проверяю, если есть координаты в установленном значение */
        if (val?.latitude && val?.longitude) {
          this.incidentForm.controls['coordinates'].setValue(`${val.latitude}, ${val.longitude}`);
          this.setJkhObjectByAddress(val);
          this.setCloseImportantObjectsSettings(new Coordinates(`${val.latitude}, ${val.longitude}`));
        } else {
          this.incidentForm.controls['coordinates'].setValue(null);
          this.setCloseImportantObjectsSettings(null);
        }

        // Актуализация селекта МО в зависимости от значения ОКТМО адреса
        if (val?.oktmo) {
          const oktmoSearchString = val.oktmo.substr(0, this.settings.getConfig().oktmoCheckLength || undefined);
          this.moIdOptions.query = {
            $expr: { $regexMatch: ['$OKTMO', `^${oktmoSearchString}`] },
            active: true,
          };
          this.moIdOptions.data = [];
          this.moIdComponent.typeahead.next(undefined);
        } else {
          this.moIdOptions.query = { active: true };
          this.moIdOptions.data = [];
          this.moIdComponent.typeahead.next(undefined);
        }
      });

    this.organizationsService
      .getAllAttractToReactOrganizations()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        const dds01 = res.find((item: IOrganizationType) => item.shortName === 'ДДС 01');
        this.dds01Id = dds01?.id;
      });

    this.miniMapCenter = this.model.mapMarkerCoordinate
      ? Coordinates.coordinatesToArray(this.model.mapMarkerCoordinate)
      : this.getMunicipalCoordinates();
    this.miniMapZoom = Number.parseInt(this.getMunicipal().zoom, 10);

    this.generateHeaderBar();

    this.loadKsipTypes$ = this.ksipTypesQuery
      .selectAll({
        filterBy: [({ active }) => active],
        sortBy: 'name',
      })
      .pipe(
        map((items: IKsipTypeDto[]) => {
          return items.map(
            (ksip: IKsipTypeDto) =>
              <IScSelectItem>{
                id: ksip.id,
                text: ksip.name,
              },
          );
        }),
      );

    const ksip = this.ksipTypesQuery.getById(this.model.incidentTypeId);
    if (ksip?.ksipDetails?.length) {
      this.loadKsipDetails$ = this.ksipDetailsQuery.selectMany(ksip.ksipDetails).pipe(
        map((items: IKsipDetailsDto[]) =>
          items.map(
            (detail: IKsipDetailsDto) =>
              <IScSelectItem>{
                id: detail.id,
                text: detail.name,
              },
          ),
        ),
      );
    } else {
      this.loadKsipDetails$ = of([]);
    }

    /**
     * Ответственный пользователь
     */
    this.optionsResponsibleUser = {
      title: 'Ответственный пользователь',
      clearable: true,
      service: 'Admin',
      entity: 'Users',
      modern: true,
      fieldId: 'id',
      fieldName: 'fio',
      query: { isActive: true, organizationId: this.model.organization },
    };

    this.emergencyService.closeItemForm$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.closeForm();
    });

    this.gridOptions = <IGridTableOptions>{
      height: '100%',
      name: 'forestry-facilities',
      restVersion: 'modern',
      title: 'Результаты расчёта',
      service: { name: 'Forecasting' },
      entity: 'ForecastingResults',
      query: {
        incidentId: this.model.id,
      },
      sort: {
        field: 'creationTime',
        direction: 'desc',
      },
      onSelectRow: (row: IAnyObject) => this.showForecastingResult(row),
      showTotalRecords: true,

      controls: [
        {
          name: 'reload',
          icon: 'refresh',
          hint: 'Обновить',
          callback: () => of({ actionStream: 'reload' }),
        },
      ],
      fields: [
        { name: 'projectedRiskId.name', title: 'Риск для прогнозирования' },
        { name: 'countTaskId.name', title: 'Расчётная задача' },
        { name: 'creationAuthor.fio', title: 'ФИО пользователя' },
        { name: 'creationTime', title: 'Дата и время' },
      ],
      onLoadData: (results: IAnyObject[]): IAnyObject[] => {
        results.forEach(
          (result: IAnyObject) => (result.creationTime = dayjs(result.creationTime).format('DD.MM.YYYY HH:mm:ss')),
        );

        return results;
      },
    };

    this.eventsGridOptions = <IGridTableOptions>{
      height: '100%',
      name: '',
      restVersion: 'modern',
      service: { name: 'Emergency' },
      entity: 'Events',
      query: this.eventsGridQuery,
      sort: {
        field: 'creationTime',
        direction: 'desc',
      },
      onRowDblClick: (row: IAnyObject) => this.showEventInNewWindow(row.id),
      controls: [
        {
          name: 'reload',
          icon: 'refresh',
          hint: 'Обновить',
          callback: () => of({ actionStream: 'reload' }),
        },
      ],
      fields: [
        { name: 'id', title: 'ID', disabled: true },
        { name: 'timeCreate', title: 'Время создания', widthColumn: '150px' },
        { name: 'sourceId.name', title: 'Источник' },
        { name: 'declarerId.fio', title: 'ФИО заявителя' },
        { name: 'declarerId.contactPhone', title: 'Контактный номер заявителя' },
        { name: 'number', title: 'Событие' },
      ],
      onLoadData: (data: IAnyObject[]): IAnyObject[] =>
        data.map((item: IAnyObject) => {
          item.timeCreate = dayjs(item.timeCreate).format('DD.MM.YYYY HH:mm:ss');
          return item;
        }),
    };

    this.waterSourcesGridOptions = <IGridTableOptions>{
      height: '100%',
      name: '',
      title: 'Водоисточники в радиусе 500м',
      restVersion: 'modern',
      service: { name: 'Directories' },
      entity: 'WaterSources',
      query: this.incidentForm.get('addressFact')?.value
        ? {
            point: {
              $radius: {
                $lat: this.incidentForm.get('addressFact')?.value?.latitude ?? 0,
                $lon: this.incidentForm.get('addressFact')?.value?.longitude ?? 0,
                $meters: 500,
              },
            },
            active: true,
          }
        : { id: Uuid.getEmpty() },
      controls: [
        {
          name: 'reload',
          icon: 'refresh',
          hint: 'Обновить',
          callback: () => of({ actionStream: 'reload' }),
        },
      ],
      fields: [
        { name: 'id', disabled: true },
        { name: 'name', title: 'Наименование', widthColumn: '200px' },
        { name: 'waterSourceTypeId.name', title: 'Тип водоисточника' },
        { name: 'coordinates', title: 'Расстояние (м)' },
        { name: 'lastCheckDate', title: 'Дата последней проверки' },
        { name: 'statusId.name', title: 'Состояние' },
        { name: 'faultDiscoveryDate', title: 'Дата обнаружения неисправности' },
        { name: 'faultReason.name', title: 'Причина неисправности' },
      ],
      onRowDblClick: (row: IAnyObject) => this.showWaterSourceInNewWindow(row.id),
      onLoadData: (data: IAnyObject[]): IAnyObject[] => {
        const mappedData = data.map((item: IAnyObject) => {
          dayjs(item.lastCheckDate).isValid()
            ? (item.lastCheckDate = dayjs(item.lastCheckDate).format('DD.MM.YYYY HH:mm:ss'))
            : noop();
          dayjs(item.faultDiscoveryDate).isValid()
            ? (item.faultDiscoveryDate = dayjs(item.faultDiscoveryDate).format('DD.MM.YYYY HH:mm:ss'))
            : noop();
          if (item.coordinates) {
            item.coordinates = (
              Coordinates.distanceInKmBetweenEarthCoordinates([
                new Coordinates(item.coordinates).toArray(),
                [
                  this.incidentForm.get('addressFact')?.value?.latitude,
                  this.incidentForm.get('addressFact')?.value?.longitude,
                ],
              ]) * 1000
            ).toFixed(0);
          }
          return item;
        });

        return mappedData.sort(
          (itemPrev: IAnyObject, itemNext: IAnyObject) => +itemPrev.coordinates - +itemNext.coordinates,
        );
      },
    };

    /** Форма поиска */
    this.vehiclesSearchForm = {
      type: 'nw-form',
      options: {
        name: 'search',
        title: 'Поиск',
        layoutForm: {
          layout: {
            background: 'white',
            columns: [
              {
                width: '100%',
                elements: [
                  {
                    type: 'select',
                    options: {
                      name: 'typeId',
                      title: 'Тип',
                      fieldName: 'name',
                      isMultiple: true,
                      modern: true,
                      data: this.settings.getDictForSelect('vehicleType'),
                    },
                  },
                  {
                    type: 'select',
                    options: {
                      name: 'brandId',
                      title: 'Марка',
                      entity: 'Brands',
                      service: 'Vehicles',
                      fieldName: 'name',
                      isMultiple: true,
                      modern: true,
                    },
                  },
                  {
                    type: 'select',
                    options: {
                      name: 'organizationId',
                      title: 'Организация',
                      entity: 'Organizations',
                      service: 'Admin',
                      fieldName: 'name',
                      isMultiple: true,
                      modern: true,
                    },
                  },
                  {
                    type: 'input',
                    options: {
                      name: 'search',
                      placeholder: 'Поиск',
                    },
                  },
                  {
                    type: 'button',
                    options: {
                      name: 'search',
                      title: 'Фильтровать',
                      stdBehavior: true,
                      color: 'primary',
                    },
                  },
                  {
                    type: 'button',
                    options: {
                      name: 'cancel',
                      title: 'Отмена',
                      stdBehavior: true,
                    },
                  },
                ],
              },
            ],
          },
        },
      },
    };
    this.vehiclesGridOptions = <IGridTableOptions>{
      title: 'Транспортные средства',
      restVersion: 'modern',
      service: { name: 'Vehicles' },
      entity: 'Dictionary',
      query: this.vehiclesGridQuery,
      sort: {
        field: 'stateNumber',
        direction: 'desc',
      },
      controls: [
        {
          name: 'reload',
          icon: 'refresh',
          hint: 'Обновить записи',
          callback: () => of({ actionStream: 'reload' }),
        },
        {
          name: 'search',
          icon: 'search',
          hint: 'Фильтр',
          callback: () => {
            return of(null);
          },
        },
        {
          name: 'insert',
          icon: 'add',
          color: 'primary',
          hint: 'Добавить',
          callback: () => {
            this.addVehicle();
            return of({ actionStream: 'donothing' });
          },
        },
      ],
      fields: [
        { name: 'id', title: 'ID', disabled: true },
        { name: 'stateNumber', title: 'Государственный номер' },
        { name: 'typeId.name', title: 'Тип' },
        { name: 'brandId.name', title: 'Марка' },
        { name: 'modelId.name', title: 'Модель' },
        { name: 'organizationId.name', title: 'Организация' },
      ],
      allowSelectCheckbox: true,
      forms: [this.vehiclesSearchForm],
      deleteFn: (params: { ids: string[]; grid: GridTableComponent }): Observable<IAnyObject> => {
        this.model.involvedVehicles = this.model.involvedVehicles.filter((item: string) => !params.ids.includes(item));
        this.refreshGrid();
        return of(null);
      },
      onRowDblClick: (row: IAnyObject) => this.showVehicleInNewWindow(row?.id),
      buildQueryFn: (value: IAnyObject, grid: GridTableComponent): Observable<IAnyObject> => {
        /** Приведение свойств объекта к undefined, значения которых
         *  равны null или пустой строке, так как запрос к базе не игнорирует
         *  эти значения, и в результате получаем пустую выборку.
         */
        for (const key in value) {
          if (value[key] === null || value[key] === '') value[key] = undefined;
        }

        const and = [];

        if (value.$text) {
          and.push({ $text: value.$text });
        }

        if (value.typeId) {
          const orQuery = [];
          for (const type of value.typeId) {
            orQuery.push({ typeId: type });
          }
          if (orQuery.length) and.push({ $or: orQuery });
        }

        if (value.brandId) {
          const orQuery = [];
          for (const brand of value.brandId) {
            orQuery.push({ 'brandId.id': brand });
          }
          if (orQuery.length) and.push({ $or: orQuery });
        }

        if (value.organizationId) {
          const orQuery = [];
          for (const organization of value.organizationId) {
            orQuery.push({ organizationId: organization });
          }
          if (orQuery.length) and.push({ $or: orQuery });
        }

        const result = and.length ? { $and: and } : {};
        return of(result);
      },
    };
    this.setCloseImportantObjectsSettings(this.model.coordinates ? new Coordinates(this.model.coordinates) : null);

    this.validationControlMap = new Map<string, string>([
      ['addressFact', 'Главное. Фактический адрес'],
      ['coordinatesAddress', 'Главное. Фактический адрес по координатам'],
      ['incidentTypeId', 'Главное. Тип КСиП'],
      ['organizationId', 'Главное. Ответственная организация'],
      ['responsible', 'Главное. Ответственный пользователь'],
      ['responsibleUser', 'Главное. Ответственный пользователь'],
      ['deathOnDate', 'Потери. Актуально на'],
      ['rescuedOnDate', 'Спасённые. Актуально на'],
      ['victimOnDate', 'Жертвы. Актуально на'],
      ['hospitalizedOnDate', 'Жертвы. Актуально на'],
      ['missingOnDate', 'Пропавшие. Актуально на'],
      ['internetPortalComment', 'Главное. Отобразить на интернет-портале'],
      ['technologicalFireFormId', 'Параметры. Форма пожара'],
      ['angleValue', 'Параметры. Величина угла'],
      ['coordinates', 'Главное. Координаты'],
      ['workTermFrom', 'Главное. Плановый срок проведения работ с'],
      ['workTermTo', 'Главное. Плановый срок проведения работ по'],
      ['size', 'Параметры. Значение'],
      ['height', 'Параметры. Средняя высота нагара (в м)'],
      ['chemicalTypeId', 'Параметры. Тип АХОВ'],
      ['chemicalAmount', 'Параметры. Количество АХОВ (в т.)'],
      ['dischargeArea', 'Параметры. Площадь разлива (в м²)'],
      ['irradiationDose', 'Параметры. Доза облучения (в сГр)'],
      ['windVelocity', 'Параметры. Скорость ветра'],
      ['windDirection', 'Параметры. Направление ветра'],
      ['airTemperature', 'Параметры. Температура воздуха '],
      ['totalCars', 'Дополнительная информация. Задействовано техники'],
      ['trunks', 'Дополнительная информация. Задействовано стволов'],
      ['personal', 'Дополнительная информация. Задействовано личного состава'],
      ['consumerPowerCategoryId', 'Дополнительная информация. Категория электроснабжения потребителей'],
    ]);

    this.setViewedField();
    this.incidentForm.controls.incidentTypeId?.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value: string) => {
        this.setFieldsByKsipType(value);
        this.getForecastingParamsInfo(value);
        this.updateKsipDetails(value);
        this.incidentForm.controls?.ksipDetailsId?.setValue(null);
      });
    this.incidentForm.controls.isSendingToJkhReform?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.model.isSendingToJkhReform = Boolean(value);
    });

    /** Подписка на изменения чекбокса - Адрес по координатам */
    this.incidentForm.controls.byCoordinates?.valueChanges.pipe(untilDestroyed(this)).subscribe((value: boolean) => {
      this.byCoordinates = value;
      const factAddressControl = this.incidentForm.controls.addressFact;
      const coordinatesAddressControl = this.incidentForm.controls.coordinatesAddress;
      factAddressControl.reset();
      if (value) {
        factAddressControl.clearValidators();
        coordinatesAddressControl.setValidators([Validators.required, Validators.pattern(this.coordinatesPattern)]);
      } else {
        coordinatesAddressControl.reset();
        coordinatesAddressControl.clearValidators();
        factAddressControl.setValidators(Validators.required);
      }
      factAddressControl.updateValueAndValidity();
      coordinatesAddressControl.updateValueAndValidity();
    });

    /** Подписка на изменение значения адреса по координатам */
    this.incidentForm.controls.coordinatesAddress?.valueChanges
      .pipe(
        filter((value: string) => !!Coordinates.coordinatesToArray(value)?.length),
        tap((value: string) => {
          const coordinates: number[] = Coordinates.coordinatesToArray(value);
          this.addressByCoordinates = {
            fullText: value,
            latitude: coordinates[0],
            longitude: coordinates[1],
          };
          this.setJkhObjectByAddress(this.addressByCoordinates);
        }),
        debounceTime(1500),
        untilDestroyed(this),
      )
      .subscribe(() => {
        // установка значений блока "Важные объекты рядом с КСиП"
        if (!this.addressByCoordinates?.latitude && !this.addressByCoordinates?.longitude) {
          this.incidentForm.controls['coordinates'].setValue(null);
          this.setCloseImportantObjectsSettings(null);
        }
        if (this.addressByCoordinates?.latitude && this.addressByCoordinates?.longitude) {
          this.factAddress = this.addressByCoordinates;
          this.incidentForm.controls['coordinates'].setValue(
            `${this.addressByCoordinates.latitude}, ${this.addressByCoordinates.longitude}`,
          );
          this.setCloseImportantObjectsSettings(
            new Coordinates(this.addressByCoordinates.latitude, this.addressByCoordinates.longitude),
          );
        }
      });

    this.incidentForm.controls.jkhObject?.valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((value: string) => {
          if (!value) {
            this.incidentForm.controls.isSendingToJkhReform.disable();
            this.incidentForm.controls.isSendingToJkhReform.setValue(false);
            this.hcsReformSendingStatus = 'Чтобы отправить в Реформу ЖКХ заполните поле Объект ЖКХ';
            this.hcsReformSendingStatusIconColor = undefined;
          } else {
            this.setAddressByJkhObject(value);
          }
          this.checkHcsReformSendAbility();
          if (value) {
            return this.atmIntegrationService.getById(value);
          }
          return of(null);
        }),
        untilDestroyed(this),
      )
      .subscribe((value: string) => {
        if (value) {
          this.setAddressByJkhObject(value);
        }
      });

    if (this.isJhk && !this.model.jkhObject && this.model.address) {
      this.setJkhObjectByAddress(this.model.address);
    }
    if (this.model.ksipDetailsId) {
      const detail = this.ksipDetailsQuery.getById(this.model.ksipDetailsId)?.eventType;
      this.isAccidentFormDetails = this.settings.getDictionaryById(detail)?.sysname === '001';
    }
    this.incidentForm.controls.ksipDetailsId?.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value: string) => {
        const detail = this.ksipDetailsQuery.getById(value)?.eventType;
        this.isAccidentFormDetails = this.settings.getDictionaryById(detail)?.sysname === '001';
      });
  }

  /**
   * Установка полей на форме в зависимости от типа КСиП
   * @param ksipType - тип КСиП
   */
  private setFieldsByKsipType(ksipType: string) {
    const ksip = this.ksipTypesQuery.getById(ksipType);
    const category = this.ksipCategoriesQuery.getById(ksip?.incidentCategory);
    if (category) {
      const section = this.ksipCategoriesQuery.getById(category.id)?.sectionId;
      this.isJhk = this.ksipSectionsQuery.getById(section)?.sysname === 'zhkh';
      this.isHousingService = this.settings.getDictionaryById(category.supplyTypeHcs)?.sysname !== 'housingService';
      this.jkhObjectOptions.query.supplyTypeId = category.supplyTypeHcs || undefined;
    } else {
      this.isJhk = false;
      this.isHousingService = false;
      this.jkhObjectOptions.query = { active: true };
    }
    this.isSendingReformToJkhVisible = Boolean(ksip?.submitToCommServ);
    if (this.incidentForm.controls.jkhObject?.value) {
      this.incidentForm.controls.isSendingToJkhReform?.enable();
    } else {
      this.incidentForm.controls.isSendingToJkhReform?.disable();
    }
  }

  /** Отображение блока с контролями для "Параметры АХОВ" */
  public isChemical() {
    return this.projectedRisk === 'chemicalDischarge';
  }

  /** Отображение блока с контролями для "Параметры АХОВ" */
  public isRadioactive() {
    return this.projectedRisk === 'radiationSituation';
  }

  /** Отображение блока с контролями для "Параметры Лесного пожара" */
  public isForestFire() {
    return this.projectedRisk === 'forestFire';
  }

  /**
   * @ignore
   */
  public ngAfterViewInit(): void {
    // TODO: Выпилить. Лог для разработки и тестирования
    (() => {
      if (this.model.lifeCycleStepId) {
        console.warn('Таблица ЖЦ для происшествия');
        console.table({
          // tslint:disable-next-line
          ЖЦ: {
            name: (this.settings.lifeCycle[this.model.lifeCycleStepId['lifeCycleId']] || {}).name,
            id: (this.settings.lifeCycle[this.model.lifeCycleStepId['lifeCycleId']] || {}).id,
          },
          // tslint:disable-next-line
          ЖЦ_шага: {
            name: (this.settings.lifeCycleStep[this.model.lifeCycleStepId['id']] || {}).name,
            id: (this.settings.lifeCycleStep[this.model.lifeCycleStepId['id']] || {}).id,
          },
        });
      } else if (this.model) {
        console.warn('Шаг жизненного цикла не определен');
        console.log('Модель данных:\n', this.model);
      } else {
        console.warn('Модель происшествия не определена');
      }
    })();
  }

  /**
   * Обработка событий формы инцидента
   * @param $event IExecuteActionEvent<IEmergencyDto>
   * @return IEmergencyDto
   */
  public executeAction($event: IExecuteActionEventDto<IEmergencyDto>): Observable<IEmergencyDto> {
    delete $event.model['regime'];
    return this.confirmAction($event.action).pipe(
      switchMap((confirmAction: boolean) => {
        if (!confirmAction) {
          return of(null);
        }

        let updatedEvent: IEmergencyDto;
        return this.multiFileService
          .saveFilesToSfs($event.model.documents)
          .pipe(
            switchMap((documents) => {
              $event.model.documents = documents;
              return this.emergencyService.executeIncidentAction($event);
            }),
            switchMap((emergency: IEmergencyDto) => {
              updatedEvent = emergency;
              let needToSendEmergencyAtlas: boolean
              if ($event.action === 'save') {
                needToSendEmergencyAtlas = this.model.needToSendEmergencyAtlas && !this.model.sentEmergencyAtlas
              } else {
                needToSendEmergencyAtlas = this.model.needToSendEmergencyAtlas
              }
              return (needToSendEmergencyAtlas ? this.emergencyService.sendEmergencyToAtlas(this.model.id) : of(null))
            }),
            switchMap(() => of(updatedEvent))
          )
          .pipe(
            takeUntil(this.ngUnsubscribe),
            catchError((err: Error) =>
              this.catchErrorFn<IEmergencyDto>(err, 'Ошибка при обработке запроса. Действие не выполнено'),
            ),
          );
      }),
    );
  }

  /** Проверяем можем ли мы экспортировать в интернет портал */
  public updateKsipDetails(ksipTypeId: string) {
    const ksip = ksipTypeId ? this.ksipTypesQuery.getById(ksipTypeId) : undefined;
    if (ksip?.ksipDetails?.length) {
      this.loadKsipDetails$ = this.ksipDetailsQuery.selectMany(ksip.ksipDetails).pipe(
        map((items: IKsipDetailsDto[]) =>
          items.map(
            (detail: IKsipDetailsDto) =>
              <IScSelectItem>{
                id: detail.id,
                text: detail.name,
              },
          ),
        ),
      );
    } else {
      this.loadKsipDetails$ = of([]);
    }
  }

  /**
   * Генерация кнопок действий
   */
  public generateHeaderBar() {
    const sortActionsFn = (a: ILifeCycleStepActionDto, b: ILifeCycleStepActionDto) => {
      if (a.order > b.order) {
        return 1;
      }
      if (a.order < b.order) {
        return -1;
      }

      return 0;
    };

    // Сейчас по завершению инцидента (action: finish) при обновление формы и формирование ее хедера
    // не приходят следующие возможные экшены от бэка(см. метод executeIncidentAction),
    // на основание которых и формируются кнопки в хедере формы. Что приводил к ошибке генерации всей формы.
    // Добавлена обработка такого кейса. Форма будет содержать только станднарную кнопку закрытия после обновления.
    // TODO: Удалить сообщение 'warning' в консоли после отработки механизмов работы с экшенами.
    if (!this.actions) {
      this.actions = [];
      ScConsole.warning('При обновление формы и генерации кнопок небыли получены "Экшены".');
    }

    // Признак что происшествие находится на согласовании
    const isApproval =
      this.settings
        .getDictionaryById(<string>(<ILifeCycleStepDto>this.model.lifeCycleStepId)?.status)
        ?.sysname?.includes('Approval') || false;
    // Признак что происшествие просматривает согласующая организация
    const isApprovalOrganization =
      (<IEmergencyDto>this.model.parentId)?.organization === this.settings.currentUser.organizationId.id;
    // Если происшествие находится на согласовании и его просматривает не согласующая организация то не выводить кнопки
    const btn =
      isApproval && !isApprovalOrganization
        ? []
        : this.actions
            .sort(sortActionsFn)
            .filter((item: ILifeCycleStepActionDto) =>
              !this.accessService.accessMap['showAcceptDeclineButtons']?.visible
                ? item.name !== 'disapprove' && item.name !== 'approve'
                : item,
            )
            .map((btn: ILifeCycleStepActionDto) => {
              return <IElementButton>{
                type: 'button',
                options: {
                  name: btn.name,
                  title: btn.title,
                  icon: BUTTON_ICONS.get(btn.name),
                  type: 'submit',
                  color: btn.name === 'takeOnControl' ? 'primary' : '',
                },
              };
            });

    const btnArray = btn.slice(0, this.actions.length >= 4 ? 4 : this.actions.length).reverse();

    /** Вставляем кнопку "Отчеты" */
    if (this.accessService.accessMap.IncidentReports?.visible) {
      btnArray.splice(0, 0, {
        type: 'button',
        options: {
          icon: 'arrow_downward',
          name: 'export',
          title: 'Отчеты',
          type: 'button',
        },
      });
    }

    if (this.actions.length > 4) {
      btnArray.push(<IElementButton>{
        type: 'button',
        options: {
          popup: {
            items: btn
              .slice(4, this.actions.length)
              .reverse()
              .map((btn: IAnyObject) => {
                return <IScPopupMenuItem>{
                  type: 'button',
                  name: btn.options.name,
                  title: btn.options.title,
                  icon: btn.options.icon,
                };
              }),
          },
          icon: BUTTON_ICONS.get('more_vert'),
        },
      });
    }

    btnArray.unshift(<IElementButton>{
      type: 'button',
      options: {
        name: 'cancel',
        icon: BUTTON_ICONS.get('cancel'),
      },
    });

    this.headerActionsOptions = {
      title: `Инцидент ${this.model.number ?? ''}`,
      name: 'header',
      margin: 'collapse',
      bgColor: 'white',
      buttons: btnArray,
    };

    if ((this.model.lifeCycleStepId as ILifeCycleStepDto)?.lifeCycleId) {
      const lifeCycleId = (this.model.lifeCycleStepId as ILifeCycleStepDto)?.lifeCycleId as string;
      const docTypeId = this.settings.lifeCycle[lifeCycleId]?.docType;
      if (this.settings.getDictionaryById(docTypeId)?.sysname === 'plannedWork') {
        this.isPlanWorkForm = true;
        this.urgentlyVisible = false;
        this.model.number = (this.model.number || '').replace('i', 'w');
        this.headerActionsOptions.title = `Плановая работа ${this.model.number}`;
      }
    }
  }

  /**
   * Обрабатываем нажатие кнопки управления состоянием
   * @param $event
   */
  public onClickActionsButton($event: IElementButton) {
    if (this.needShowInternetPortalComment && this.isEndState($event.options.name)) {
      this.incidentForm.controls['internetPortalComment'].setValidators([Validators.required]);
    }

    if ($event.options.name === 'cancel') {
      this.closeForm();
      return;
    }

    if ($event.options.name === 'export') {
      let dialogRef;
      if (this.accessService.accessMap.IncidentReports?.visible) {
        const isOrder = this.settings.getDictObjByTypeSysName('docType').order.id === this.model.docType;
        dialogRef = this.dialog.open(IncidentReportsDialogComponent, {
          disableClose: false,
          width: '914px',
          height: '80vh',
          data: {
            emergencyId: isOrder ? (<EmergencyDto>this.model.parentId)?.id || this.model.parentId : this.model.id,
          },
        });
        dialogRef.componentInstance.downloadEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((report) => {
          switch (report[0]) {
            case EmergencyReportsDialogResultEnum.vehicleSearch:
            case EmergencyReportsDialogResultEnum.eddsCallEdds112:
              this.reportingService.buildEddsCallEdds112Report(this.model.id);
              break;
            case EmergencyReportsDialogResultEnum.reportEmergency:
              this.reportingService.buildReportEmergency(this.model, report[1]);
              break;
            case EmergencyReportsDialogResultEnum.lpuPvrReport:
              this.reportingService.buildLpuPvrReport(this.model, report[1]);
              break;
            case EmergencyReportsDialogResultEnum.reportVictims:
              this.reportingService.buildVictimsReport(this.model, report[1]);
              break;
            case EmergencyReportsDialogResultEnum.listOfVictims:
              this.reportingService.buildListOfVictimsReport(this.model);
              break;
          }
        });
      } else if ('va' === this.sourceType) {
        let vaTypeSysname: string;
        this.emergencyService.getIncidentByQuery(
          { id: this.model.id },
          ['parentEventId.vaDetail.vaTypeId.sysname'],
        )
          .pipe(untilDestroyed(this))
          .subscribe((response: IEmergencyDto[]) => {
            vaTypeSysname = response[0]['parentEventId.vaDetail.vaTypeId.sysname'];
            dialogRef = this.dialog.open(EmergencyReportsDialogComponent, {
              disableClose: false,
              width: '914px',
              minHeight: 510,
              data: {
                vaTypeSysname,
                sourceType: this.sourceType,
              },
            });
            dialogRef.componentInstance.downloadEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((report) => {
              switch (report[0]) {
                case EmergencyReportsDialogResultEnum.vehicleSearch:
                  this.reportingService.buildVehicleSearchReport(this.model.id);
                  break;
                case EmergencyReportsDialogResultEnum.duplicateGRZ:
                  this.reportingService.buildDuplicateGRZReport(this.model.id);
                  break;
                case EmergencyReportsDialogResultEnum.loudSound:
                case EmergencyReportsDialogResultEnum.objRestrictedArea:
                  this.reportingService.buildLoudSoundReport(this.model.id);
                  break;
                case EmergencyReportsDialogResultEnum.crowds:
                  this.reportingService.buildCrowdsReport(this.model.id);
                  break;
                case EmergencyReportsDialogResultEnum.sabotage:
                  this.reportingService.buildSabotageReport(this.model.id);
                  break;
                case EmergencyReportsDialogResultEnum.thing:
                  this.reportingService.buildThingReport(this.model.id);
                  break;
              }
            });
          });
      }
      return;
    }

    // Необходимо принудительно валидировать динамические контролы,
    // т.е. контролы, которые появляются в зависимости от значения других контролов на форме
    this.incidentForm.controls.fireParams?.updateValueAndValidity({ emitEvent: false });

    if (this.incidentForm.valid || this.incidentForm.disabled) {
      this.getModelData($event.options.name);
      this.model.lifeCycleStepId =
        typeof this.model.lifeCycleStepId === 'object'
          ? (<ILifeCycleStepDto>this.model.lifeCycleStepId)?.id
          : this.model.lifeCycleStepId;
      this.model['updateAuthor'] = this.settings.currentUser.id;
      // Срок исполнения не меняется на основании изменений фронта, только согласно логике на бэке.
      // Однако, его значение может быть получено при открытии записи на редактирование.
      // Необходимо исключить вероятность замены актуального значения срока исполнения.
      this.model.deadline = undefined;

      const changedLifeCycleStepId = (this.settings.lifeCycleStep[this.model.lifeCycleStepId].actions || []).find(
        (i: ILifeCycleStepActionDto) => i.name === $event.options.name,
      )?.nextStep;
      this.isEmergencyEndStepCycle = this.settings.lifeCycleStep[changedLifeCycleStepId]?.endOfCycle;

      if (this.isEmergencyEndStepCycle) {
        this.model.timeFinish = new Date().getTime();
      }

      this.model.parentId =
        typeof this.model.parentId === 'object' ? (<EmergencyDto>this.model.parentId)?.id : this.model.parentId;

      this.executeAction({
        model: this.model,
        action: $event.options.name,
      })
        .pipe(
          takeUntil(this.ngUnsubscribe),
          mergeMap((emergency: IEmergencyDto) => {
            if (emergency) {
              this.model = emergency as EmergencyDto;
              this.actions = (emergency.lifeCycleStepId as ILifeCycleStepDto).actions;
              this.isDirty$ = of(false);
              if (this.isEmergencyEndStepCycle) {
                return this.completeIncident().pipe(
                  takeUntil(this.ngUnsubscribe),
                  map(() => true),
                );
              }
              return of(true);
            }
            return of(false);
          }),
        )
        .subscribe((result) => {
          if (result) {
            this.noteService.pushSuccess('Запись успешно изменена');
            this.updateForm();
          }
        });
    } else {
      /** Рекурсивно перебираем все контролы, на предмет контролов с ошибкой, для вывода информационного сообщения*/
      const labels: string[] = [];
      const recursiveFunc = (form: FormGroup | FormArray) => {
        Object.keys(form.controls).forEach((field: string) => {
          const control = form.get(field);
          if (control.invalid) {
            if (control instanceof FormGroup || control instanceof FormArray) {
              recursiveFunc(control);
            } else {
              labels.push(this.validationControlMap.get(field) ?? field);
            }
          }
        });
      };
      recursiveFunc(this.incidentForm);
      if (labels.length) {
        this.noteService.pushError(`Следующие поля содержат ошибки:\n ${labels.join(',\n')}`);
      }
    }

    if (this.needShowInternetPortalComment && this.isEndState($event.options.name)) {
      this.incidentForm.controls['internetPortalComment'].clearValidators();
    }
  }

  /**
   * Карта готова для использования
   */
  public onMapReady() {
    this.redrawEventMarker(this.model.mapMarkerCoordinate || this.model.coordinates);
  }

  /**
   * Инициализация плана реагирования
   */
  public initReactPlan() {
    this.involvedOrgOpts = {
      headerTitle: 'Привлекаемые службы',
      stepList: [],
    };

    this.responseOrgOpts = {
      headerTitle: 'Реагирование',
      isHeaderFix: true,
      stepList: [],
    };

    const parentId = (<EmergencyDto>this.model.parentId)?.id || <string>this.model.parentId || null;
    this.responsePlanStepService
      .getStepsByEmergencyId(this.model.id, parentId)
      .pipe(
        catchError((error: Error) =>
          this.catchErrorFn<IEmergencyResponsePlanStep[]>(error, 'Ошибка получения сценария реагирования происшествия'),
        ),
        untilDestroyed(this),
      )
      .subscribe((responsePlanStep: IEmergencyResponsePlanStep[]) => {
        this.involvedOrgStepsList = responsePlanStep
          .filter((item: IEmergencyResponsePlanStep) => item.serviceType)
          .sort((a: IEmergencyResponsePlanStep, b: IEmergencyResponsePlanStep) => Number(b.number) - Number(a.number));
        this.responseOrgStepsList = responsePlanStep
          .filter((item: IEmergencyResponsePlanStep) => !item.serviceType)
          .sort((a: IEmergencyResponsePlanStep, b: IEmergencyResponsePlanStep) => Number(b.number) - Number(a.number));

        this.responsePlanStepService.stepListSub$.next({
          action: 'list',
          list: this.responseOrgStepsList,
          entity: 'responseOrgOpts',
        });
        this.responsePlanStepService.stepListSub$.next({
          action: 'list',
          list: this.involvedOrgStepsList,
          entity: 'involvedOrgOpts',
        });
      });

    this.responsePlanStepService.stepListSub$
      .pipe(untilDestroyed(this))
      .subscribe((result: { list?: IEmergencyResponsePlanStep[]; action?: string; entity?: string }) => {
        if (result.action === 'list') {
          this.responsePlanStepService.setListCount(result?.entity, result?.list?.length);
        }
        if ((result.action === 'add' || result.action === 'update') && (result.list || [])[0]?.informerServiceType) {
          this.incidentForm.controls.supervisedByCuks?.setValue(true);
        }
        const methodName = `${result.action}StepListOpts`;
        if (typeof this[methodName] === 'function') {
          this[methodName](result.list, result.entity);
        }
      });
  }

  /** Показываем форму поручения если Информационное взаимодействие = true и Есть поручения по данному типу службы */
  public showCommissionForm(step: IEmergencyResponsePlanStep) {
    if ((step.serviceType as IOrganizationType)?.id === this.dds01Id) {
      this.router.navigate([{ outlets: { viewForm: ['order', step.orderId] } }], {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate([{ outlets: { viewForm: ['orderUlk', step.orderId] } }], {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge',
      });
    }
  }

  /** Открывает форму просмотра созданного донесения по клику на шаг реагирования */
  public showInformationStatementForm(step, statementId) {
    this.router.navigate([{ outlets: { viewForm: ['informationStatement', statementId] } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }

  /** Показываем форму поручения если Информационное взаимодействие = false
   * и нет данных о службе реагирования(serviceType) */
  public showInvolvedOrgInteractionForm(step: IEmergencyResponsePlanStep) {
    const title = step.attractOrgData ? 'Привлекаемая служба' : 'Новая привлекаемая служба';
    const dialogOptions = {
      panelClass: 'org-panel',
    };
    const dataOptions = {
      step,
      title,
      entity: 'involvedOrgOpts',
      // Если отображается шаг не текущего происшествия (например родительского), то заблокировать форму.
      disabled: step.emergencyId !== this.model.id,
    };
    const dialog = new FormModal(InvolveOrgWithoutInteractionDialogComponent, dialogOptions, dataOptions);
    dialog.open();
  }

  /** Показываем форму поручения если есть Информационное взаимодействие и нет поручения по данному типу службы
   * @param step - шаг
   * @return
   */
  public showInvolvedOrgWithoutCommissionForm(step: IEmergencyResponsePlanStep): void {
    const dialogOptions = {
      width: '477px',
      panelClass: 'org-panel',
    };
    const dataOptions = {
      step,
      title: 'Новая привлекаемая служба',
      emergencyModel: this.model,
      entity: 'involvedOrgOpts',
      // Если отображается шаг не текущего происшествия (например родительского), то заблокировать форму.
      disabled: step.emergencyId !== this.model.id,
    };
    const dialog = new FormModal(InvolveOrgWithoutCommissionDialogComponent, dialogOptions, dataOptions);
    dialog.open();
  }

  /**
   * Отображение формы запроса на привлечение службы к реагированию.
   */
  private showOrgInvolveRequestForm(step: IEmergencyResponsePlanStep) {
    const dialogOptions = { panelClass: 'org-panel' };
    const dataOptions = {
      step,
      disabled: true,
      isInvolveThroughEdds: this.isInvolveThroughEdds,
      emergencyModel: this.model,
    };
    const dialog = new FormModal(OrgInvolveRequestDialogComponent, dialogOptions, dataOptions);
    dialog.open();
  }

  /** Метод показа соответствующей формы для блока: Привлекаемые службы
   * @param $event событие нажатия на шаг реагирования
   * @return
   */
  public showInvolvedOrgForm($event: ResponsePlanStepClickEvent): void {
    // Условия которые определяют шаг запроса на привлечение службы, для отображения соответствующей формы
    if (
      !((<IOrganization>$event.step.attractOrgData)?.id || <string>$event.step.attractOrgData) &&
      ['consideration', 'fail'].includes(this.settings.getDictionaryById($event.step.status)?.sysname) &&
      this.settings.getDictionaryById($event.step.stepType)?.sysname === 'auto'
    ) {
      this.showOrgInvolveRequestForm($event.step);
      return;
    }

    this.organizationsService
      .getAttractReactOrganizationById($event.step['serviceType.id'])
      .pipe(untilDestroyed(this))
      .subscribe((result: IOrganizationType) => {
        if (result.informationInteraction && $event.step.orderId) {
          this.showCommissionForm($event.step);
        }
        if (result.informationInteraction && !$event.step.orderId) {
          this.showInvolvedOrgWithoutCommissionForm($event.step);
        }
        if (!result.informationInteraction) {
          this.showInvolvedOrgInteractionForm($event.step);
        }
      });
  }

  /** Метод показа соответствующей формы для блока: Реагирование
   * @param $event событие нажатия на шаг реагирования
   * @return
   */
  public showResponseStepForm($event: ResponsePlanStepClickEvent) {
    // Шаг реагирования не донесение
    if (!$event.step.informerServiceType) {
      const dialogOptions = {
        width: '906px',
        panelClass: 'org-panel',
      };
      const dataOptions = {
        step: $event.step,
        title: 'Шаг реагирования',
        entity: 'responseOrgOpts',
        isEdit: true,
        emergencyModel: this.model,
      };
      const dialog = new FormModal(ResponseOrgFormComponent, dialogOptions, dataOptions);
      dialog.open();
    } else if ($event.step.informerServiceType && $event.step.attractOrgData) {
      // Шаг реагирования отправленное донесение
      this.showInformationStatementForm($event, $event.step.reportId);
    } else if ($event.step.informerServiceType && !$event.step.attractOrgData) {
      // Шаг реагирования неотправленное донесение
      const dialogOptions = {
        width: '906px',
        panelClass: 'org-panel',
      };
      const dataOptions = {
        step: $event.step,
        title: 'Шаг реагирования',
        entity: 'responseOrgOpts',
        isEdit: true,
        emergencyModel: this.model,
      };
      const dialog = new FormModal(InformResponseOrgFormComponent, dialogOptions, dataOptions);
      dialog.open();
    }
  }

  /**
   *  Метод для создания шага реагирования для привлекаемых служб
   */
  public createNewStepInvolveOrg(): void {
    this.multiFileService
      .saveFilesToSfs(this.model.documents)
      .pipe(untilDestroyed(this))
      .subscribe((documents: IFileInfo[]) => {
        const dialogOptions = {
          panelClass: 'org-panel',
        };

        const dataOptions = {
          title: 'Новая привлекаемая служба',
          emergencyModel: { ...this.model, documents },
          isInvolveThroughEdds: this.isInvolveThroughEdds,
        };
        const dialog = new FormModal(CreateInvolveStepComponent, dialogOptions, dataOptions);
        dialog.open();
      });
  }

  /**
   *  Метод для создания шага сценария для реагирования
   *  @param $event
   *  @return
   */
  public createNewStepScriptReact($event): void {
    if (this.route.snapshot.data?.workspace === 'cuks') {
      this.showDialogWithTypeAnotherForSteps();
    } else {
      this.showCreateDialogForSteps();
    }
  }

  /** Закрытие формы */
  public closeForm() {
    this.emergencyService.selectIncident({ id: undefined, docType: 'incident' });
    this.router.navigate([{ outlets: { editForm: null, editEventForm: null } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Метод который выполняет функционал с шагами реагирования после закрытия инцидента
   * @return
   */
  public completeIncident(): Observable<IEmergencyResponsePlanStep[]> {
    if ((this.model.lifeCycleStepId as ILifeCycleStepDto).endOfCycle && this.responseOrgStepsList.length) {
      return this.responsePlanStepService.updateStepsTimeFinish(this.model.id, 'responseOrgOpts');
    }
    return of([]);
  }

  /** Функция запуска прогнозирования */
  public runForecasting(): void {
    if (this.canForecastingRun?.enabled) {
      // устанавливаем входные параметры

      // открываем нужную форму в зависимости от типа ВО
      if (this.isRadioactive() || this.isChemical()) {
        if ((this.incidentForm.controls.forecasting as FormGroup).controls.significantObjectId.value) {
          this.openSignificantObjectURL();
        }
      }
      if (this.isForestFire()) {
        this.openForestryFacilityURL();
        return;
      }

      this.openRegistryPanelURL();
    }
  }

  /** Конфигурация блока "Параметры" */
  public setForecastingParamsOptions(): void {
    if (this.model.incidentTypeId) {
      this.getForecastingParamsInfo(this.model.incidentTypeId);
    } else {
      this.showForecastingParams = false;
    }
  }

  /** Проверка типа КСиП для отображения блока "Параметры" */
  public getForecastingParamsInfo(id: string): void {
    const ksip = this.ksipTypesQuery.getById(id);
    this.isAccidentForm = this.settings.getDictionaryById(ksip.eventTypeId)?.sysname === '001';
    this.showForecastingParams = !!ksip.projectedRisk;

    const params = this.model.forecastingDetailId?.params ? this.model.forecastingDetailId?.params : undefined;
    const projectedRisk = this.settings.getDictionaryById(ksip.projectedRisk)?.sysname || '';

    switch (projectedRisk) {
      case 'forestFire': {
        this.incidentForm.setControl(
          'forecasting',
          ForecastingForestFireParamsFormComponent.generateFormGroup(
            this.fb,
            params as IForecastingForestFireParamsDto,
          ),
        );
        break;
      }
      case 'technologicalFire': {
        this.incidentForm.setControl(
          'forecasting',
          ForecastingTechnologicalFireIncidentFormComponent.generateFormGroup(
            this.fb,
            params as IForecastingTechnologicalFireParamsDto,
          ),
        );
        break;
      }
      case 'chemicalDischarge':
      case 'radiationSituation': {
        /* Если текущий риск один уже отрисован, то ничего не делаем */
        if (this.projectedRisk === 'chemicalDischarge' || this.projectedRisk === 'radiationSituation') {
          noop();
        } else {
          this.incidentForm.setControl(
            'forecasting',
            ForecastingRadiationChemicallyParamsFormComponent.generateFormGroup(this.fb, params),
          );
        }
        break;
      }
      default:
        this.incidentForm.setControl('forecasting', new FormControl(this.model.forecastingDetailId));
    }

    this.projectedRisk = projectedRisk;
  }

  /**
   * Установка маркера
   * @param miniMapEvent событие
   */
  public updateCoordinates(miniMapEvent: IMiniMapMarkerPositionEvent) {
    const markerCoordinates = new Coordinates(this.incidentForm.controls['mapMarkerCoordinate'].value);
    const newMarkerCoordinates = new Coordinates(miniMapEvent?.coordinates[0], miniMapEvent?.coordinates[1]);
    if (!markerCoordinates.equal(newMarkerCoordinates)) {
      this.incidentForm.controls['mapMarkerCoordinate'].setValue(newMarkerCoordinates.toString());
      this.redrawEventMarker(this.incidentForm.controls['mapMarkerCoordinate'].value);
      this.model.mapMarkerCoordinate = this.incidentForm.controls['mapMarkerCoordinate'].value;
    }
  }

  /**
   * Получение МО активного пользователя
   */
  protected getMunicipal(): IAdminMunicipalSchemaDto {
    return this.settings.allMo.find(
      (mo: IAdminMunicipalSchemaDto) => mo.id === this.settings.currentUser.organizationId.mo,
    );
  }

  /**
   * Получение координат МО
   */
  protected getMunicipalCoordinates(): MapBaseCoordinatesType {
    const mo = this.getMunicipal();
    if (mo && mo.coordinates) {
      const elm = mo.coordinates.split(', ');
      return [Number.parseFloat(elm[0]), Number.parseFloat(elm[1])];
    }
    const elm = this.settings.getConfig().defaultCoordinates.split(', ');
    return [Number.parseFloat(elm[0]), Number.parseFloat(elm[1])];
  }

  /**
   * Абстрактная функция получения данных
   * @param action - действие которое выполняется на форме
   */
  protected abstract getModelData(action?: string);

  /**
   * Абстрактная функция обновления формы
   */
  protected abstract updateForm();

  /**
   * Настройка параметров отображения информации о важных объектах рядом с инцидентом
   * @param coordinates - объект координат
   */
  protected setCloseImportantObjectsSettings(coordinates: Coordinates) {
    if (!coordinates?.isValid()) {
      this.countOfCloseImportantObjects = 0;
      this.closeImportantObjectsMessage = 'Важные объекты не найдены';
      return;
    }

    forkJoin([
      this.significantObjectService.getCloseObject(coordinates, 500),
      this.significantObjectService.getCloseObject(coordinates, 50),
    ]).subscribe((result: IAbstractServiceData[]) => {
      this.countOfCloseImportantObjects = +result[0].data.totalCount;
      if (!!this.countOfCloseImportantObjects) {
        this.closeImportantObjectsMessage = `${GrammarHelper.endings(this.countOfCloseImportantObjects, [
          'Найден',
          'Найдено',
          'Найдено',
        ])} ${this.countOfCloseImportantObjects} ${GrammarHelper.endings(this.countOfCloseImportantObjects, [
          'важный',
          'важных',
          'важных',
        ])} ${GrammarHelper.endings(this.countOfCloseImportantObjects, ['объект', 'объекта', 'объектов'])}`;
      } else {
        this.closeImportantObjectsMessage = 'Важные объекты не найдены';
      }
      this.hasDangerObject = !!+result[1].data.totalCount;
    });
  }

  /**
   * Перерисовка маркера события
   */
  protected redrawEventMarker(coordinates: any) {
    const coords = new Coordinates(coordinates);
    if (coords.isValid()) {
      this.miniMapService.setMarker(coords.toArray());
    }
  }

  /** Формирую ссылку для перехода на объект монитоинга
   * @return
   */
  protected setMonitoringObjectId(monitoringObjectId: string): void {
    this.monitoringObjectId = monitoringObjectId;
  }

  protected setAddressObservableForLink() {
    if (this.linkToJkhObjectVisible) {
      this.isVisibleAtmLink$ = this.atmIntegrationService.isExistBoundAddresses(this.model.jkhObject).pipe(
        tap((isExistBoundAddresses: boolean) => {
          if (isExistBoundAddresses) localStorage.setItem('isAddressBlockShow', 'true');
        }),
      );
    }
  }

  /**
   * Подтверждение действия над происшествием
   * @param action - действие над происшествием
   */
  private confirmAction(action: string): Observable<boolean> {
    // Получение шага ЖЦ в которое собирается перейти происшествие и системного наименования его статуса.
    const nextStepId = this.actions.find((a: ILifeCycleStepActionDto) => a.name === action)?.nextStep;
    // Если следующий шаг не определен, то происшествие остается в текущем шаге.
    // Поэтому можно подтвердить выполнение действия не выполняя логику ниже.
    if (!nextStepId) {
      return of(true);
    }
    const nextStep = this.settings.lifeCycleStep[nextStepId];
    const nextStepStatus = this.settings.getDictObjsIdsByType('statusLifeCycleStep')[<string>nextStep?.status]?.sysname;

    if (action === 'backToWork' || action === 'disapprove') {
      return new Observable((o: Observer<boolean>) => {
        const dialogRef = this.dialog.open(IncidentBackToWorkDialogComponent, {
          panelClass: 'back-to-work-dialog-container',
          disableClose: true,
          data: {
            emergencyId: this.model.id,
          },
        });
        dialogRef.componentInstance.confirmEvent.pipe(untilDestroyed(this)).subscribe((data: string) => {
          this.model.backToWork = true;
          this.model.reasonOfBackToWork = data;
          o.next(true);
          o.complete();
        });
        dialogRef.componentInstance.rejectEvent.pipe(untilDestroyed(this)).subscribe(() => {
          o.next(false);
          o.complete();
        });
      });
    }

    // Если происшествие будет завершено или перейдет в шаг который является концом цикла,
    // то надо инициировать проверку на наличие открытых поручений.
    const obs =
      nextStepStatus === 'finished' || nextStep?.endOfCycle
        ? this.emergencyService.isExistOpenOrders(this.model.id)
        : of(false);

    return obs.pipe(
      switchMap((existOrders: boolean) => {
        if (!existOrders) {
          return of(true);
        }
        // Если имеются открытые поручения и будующий шаг является концом цикла,
        // то показать ошибку и не выполнять действие
        if (nextStep.endOfCycle) {
          this.noteService.pushError(
            `Невозможно перевести происшествие в состояние ${nextStep.name}, т.к. существуют незакрытые связанные поручения.`,
          );
          return of(false);
        }
        // Если имеются открытые поручения и происшествие переходит в шаг "Завершен",
        // то показать диалог на подтверждение действия.
        return new Observable((o: Observer<boolean>) => {
          this.openDialog()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: IAnyObject) => {
              if (data.actionStream === 'yes') {
                o.next(true);
                o.complete();
              }
              if (data.actionStream === 'OnDestroy' || data.actionStream === 'no') {
                o.next(false);
                o.complete();
              }
            });
        });
      }),
    );
  }

  private isEndState(action: string) {
    // Получение шага ЖЦ в которое собирается перейти происшествие и системного наименования его статуса.
    const nextStepId = this.actions.find((a: ILifeCycleStepActionDto) => a.name === action)?.nextStep;
    // Если следующий шаг не определен, то происшествие остается в текущем шаге.
    // Поэтому можно подтвердить выполнение действия не выполняя логику ниже.
    if (!nextStepId) {
      return of(true);
    }
    const nextStep = this.settings.lifeCycleStep[nextStepId];
    const nextStepStatus = this.settings.getDictObjsIdsByType('statusLifeCycleStep')[<string>nextStep?.status]?.sysname;

    // Если происшествие будет завершено или перейдет в шаг который является концом цикла,
    // то надо инициировать проверку на наличие открытых поручений.
    return nextStepStatus === 'complete' || nextStep?.endOfCycle;
  }

  /**
   * Открытие диалога для подтверждения действия
   */
  private openDialog(): Observable<IAnyObject> {
    return this.dialogService.open({
      text: 'Существуют незакрытые связанные поручения. Продолжить?',
      title: 'Внимание!',
      buttons: [
        {
          name: 'no',
          title: 'Нет',
          type: 'mat-flat-button',
          color: 'primary',
          callback: () => of({ action: 'no' }),
        },
        {
          name: 'yes',
          title: 'Да',
          type: 'mat-flat-button',
          color: 'warn',
          callback: () => of({ action: 'yes' }),
        },
      ],
    });
  }

  /** Метод добавляет элемент в список шагов для соответствующей сущности
   *  @param list - список шагов
   *  @param entity - название сущности
   *  @return
   */
  private addStepListOpts(list: IEmergencyResponsePlanStep[], entity: string): void {
    this[entity].stepList.push(list[0]);
  }

  /** Метод обновляет элемент в список шагов для соответствующей сущности
   *  @param list - список шагов
   *  @param entity - название сущности
   *  @return
   */
  private updateStepListOpts(list: IEmergencyResponsePlanStep[], entity: string) {
    const index = this[entity].stepList.findIndex((step) => {
      return step.id === list[0].id;
    });
    const updatedItem = this[entity].stepList[index];
    const { id, ...updatedData } = list[0];
    this[entity].stepList[index] = { ...updatedItem, ...updatedData };
  }

  /** Метод обновляет весь список шагов для соответствующей сущности
   *  @param list - список шагов
   *  @param entity - название сущности
   *  @return
   */
  private listStepListOpts(list: IEmergencyResponsePlanStep[], entity: 'involvedOrgOpts' | 'responseOrgOpts') {
    this[entity].stepList = list;
  }

  /** Получить ссылку на рабочий стол с открытой формой ВО */
  private openSignificantObjectURL(): void {
    const registriesService = AppInjector.getInjector().get(RegistryPanelService);
    registriesService
      .getRegistryId(['SignificantObjectsRegistry'])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((registryId: string) => {
        let targetUrl = window.location.origin;
        targetUrl += `/${this.route.snapshot.data.workspace}`;
        targetUrl += '/workspace';
        targetUrl += `/(leftSidebar:significantObjectsRegistry/${registryId})`;
        // queryParams очищаются сразу после открытия формы прогнозирования, поэтому их не видно в URL после перехода
        targetUrl += `?openForecastingForm=true&emergencyId=${this.model.id}`;
        window.open(targetUrl);
      });
  }

  /** Получить ссылку на рабочий стол с открытой формой ЛХО */
  private openForestryFacilityURL() {
    const registriesService = AppInjector.getInjector().get(RegistryPanelService);
    registriesService
      .getRegistryId(['ForestryFacilitiesRegistry'])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((registryId: string) => {
        let targetUrl = window.location.origin;
        targetUrl += `/${this.route.snapshot.data.workspace}`;
        targetUrl += '/workspace';
        targetUrl += `/(leftSidebar:forestryFacilitiesRegistry/${registryId})`;
        // queryParams очищаются сразу после открытия формы прогнозирования, поэтому их не видно в URL после перехода
        targetUrl += `?openForecastingForm=true&emergencyId=${this.model.id}`;
        window.open(targetUrl);
      });
  }

  public openRegistryPanelURL() {
    let targetUrl = window.location.origin;
    targetUrl += `/${this.route.snapshot.data.workspace}`;
    targetUrl += '/workspace';
    targetUrl += '/(leftSidebar:all)';
    // queryParams очищаются сразу после открытия формы прогнозирования, поэтому их не видно в URL после перехода
    targetUrl += `?openForecastingForm=true&emergencyId=${this.model.id}`;
    window.open(targetUrl);
  }

  private showForecastingResult(row: IAnyObject) {
    if (row?.id) {
      this.router.navigate([{ outlets: { viewForm: ['forecasting-show', row.id] } }], {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge',
      });
    }
  }

  /**
   *  Диалог для создания шага реагирования в блоке Реагирования c типом другой нужен для ЦУКС
   *  @return
   */
  private showDialogWithTypeAnotherForSteps(): void {
    const dialogOptions = {
      width: '906px',
      panelClass: 'org-panel',
    };
    const dataOptions = {
      title: 'Новый шаг реагирования',
      emergencyModel: this.model,
      entity: 'involvedOrgOpts',
    };
    const dialog = new FormModal(ResponseOrgFormComponent, dialogOptions, dataOptions);
    dialog.open();
  }

  /**
   * Диалог для создания шага реагирования в блоке Реагирования c выбором типа шага
   * @return
   */
  private showCreateDialogForSteps(): void {
    const dialogOptions = {
      width: '906px',
      panelClass: 'org-panel',
    };
    const dataOptions = {
      title: 'Новый шаг реагирования',
      entity: 'responseOrgOpts',
      emergencyModel: this.model,
      orgTypes: this.responseOrgStepsList
        .map((item: IEmergencyResponsePlanStep) => item['informerServiceType'])
        .filter((item: string) => !!item),
    };
    const dialog = new FormModal(CreateResponseStepComponent, dialogOptions, dataOptions);
    dialog.open();
  }

  /**
   * Устанавливает поле  "Просмотренно"
   * @return
   */
  public setViewedField(): void {
    if (!this.model.viewed && this.settings.currentUser.organizationId?.id === this.model.organization) {
      this.emergencyService
        .updateViewedField(this.model.id, 'Emergency')
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe();
    }
  }

  /**
   * Отображение события в новой вкладке/окне
   * @param eventId - ID события
   */
  public showEventInNewWindow(eventId: string = undefined) {
    const id =
      eventId ||
      (typeof this.model.parentEventId === 'object'
        ? (<IEmergencyEventDto>this.model.parentEventId)?.id
        : this.model.parentEventId);
    let targetUrl = window.location.origin;
    targetUrl += `/consolidated-registries/event-register/${id}`;
    window.open(targetUrl);
  }

  /**
   * Отображение транспортного средства в новой вкладке
   * @param vehicleId Идентификатор ТС
   */
  public showVehicleInNewWindow(vehicleId: string) {
    // Выбираем всех родителей доступных рабочих столов
    const pages = Object.values(this.settings.allPages)
      .filter((page: IAnyObject) => page.alias === 'workspace')
      .map((page: IAnyObject) => page.parentid);
    // Составляем список доступных рабочих областей, в которых есть форма просмотра ТС
    const workspaces = Object.values(this.settings.allPages)
      .filter((page: IAnyObject) => pages.includes(page.id) && ['edds', 'cuks'].includes(page.alias))
      .map((page: IAnyObject) => page.alias);
    // Выбираем доступную рабочую область для отображения формы просмотра ТС
    const workspace = ['edds', 'cuks'].includes(this.route.snapshot.data.workspace)
      ? this.route.snapshot.data.workspace
      : workspaces[0] || '';
    if (vehicleId && workspace) {
      window.open(`/${workspace}/workspace/(vehicle/${vehicleId}//leftSidebar:all)`, '_blank');
    }
  }

  /**
   * Отображение транспортного средства в новой вкладке
   * @param vehicleId Идентификатор ТС
   */
  public showWaterSourceInNewWindow(waterSourceId: string) {
    // Выбираем всех родителей доступных рабочих столов
    const pages = Object.values(this.settings.allPages)
      .filter((page: IAnyObject) => page.alias === 'workspace')
      .map((page: IAnyObject) => page.parentid);
    // Составляем список доступных рабочих областей, в которых есть форма просмотра ТС
    const workspaces = Object.values(this.settings.allPages)
      .filter((page: IAnyObject) => pages.includes(page.id))
      .map((page: IAnyObject) => page.alias);
    // Выбираем доступную рабочую область для отображения формы просмотра ТС
    const workspace = ['edds', 'cuks'].includes(this.route.snapshot.data.workspace)
      ? this.route.snapshot.data.workspace
      : workspaces[0] || '';
    if (waterSourceId && workspace) {
      window.open(
        `/${
          this.route.snapshot.data.workspace ? this.route.snapshot.data.workspace : workspaces[0] || ''
        }/workspace/(waterSource/${waterSourceId}//leftSidebar:all)`,
        '_blank',
      );
    }
  }

  /** Раскрытие блока Зона действия */
  public openCoverageArea(): void {
    this.emergencyService.openCoverageArea(this.model);
  }

  /**
   * Обработка инициализации компонента МО
   * @param component - инстанс компонента
   */
  public onMoInit(component: ScSelectComponent) {
    this.moIdComponent = component;
    this.moIdComponent.typeahead.next(undefined);
  }

  /**
   * Обработка инициализации компонента Район
   * @param component - инстанс компонента
   */
  public onDistrictInit(component: ScSelectComponent) {
    this.districtIdComponent = component;
    this.districtIdComponent.typeahead.next(undefined);
  }

  /**
   * Инициализация компонентов МО и Район
   */
  private moAndDistrictInit() {
    // Предварительная настройка селекта Район в зависимости от значения МО
    if (this.model.moId) {
      this.districtIdOptions.query = { active: true, municipalId: this.model.moId };
      this.incidentForm.controls.districtId?.enable();
    }

    // Видимость селекта Район
    this.districtIdVisible = this.accessService.accessMap['EventEmergencyDistrictVisible']?.visible;

    // Подписка на изменение МО для актуализации значений в поле Район
    this.incidentForm.controls.moId?.valueChanges.pipe(untilDestroyed(this)).subscribe((moId: string) => {
      if (this.districtIdOptions.query.municipalId === moId) return;
      this.districtIdComponent.value = null;
      if (moId) {
        this.districtIdOptions.query = { active: true, municipalId: moId };
        this.districtIdComponent.typeahead.next(undefined);
        this.incidentForm.controls.districtId?.enable();
      } else {
        this.districtIdOptions.query = { active: true };
        this.incidentForm.controls.districtId?.disable();
      }
    });
  }

  /**
   * Предустановка адреса КСиП из объекта ЖКХ
   */
  public setAddressByJkhObject(objectId: string): void {
    const factAddressControl = this.incidentForm.controls.addressFact;
    const coordinatesAddressControl = this.incidentForm.controls.coordinatesAddress;

    if (!coordinatesAddressControl?.value && !factAddressControl?.value) {
      this.atmIntegrationService
        .getById(objectId)
        .pipe(untilDestroyed(this))
        .subscribe((object: IMonitoringObjectHcsDto) => {
          if (this.byCoordinates) {
            coordinatesAddressControl.patchValue(object.coordinates);
          } else {
            const coordinates = Coordinates.coordinatesToArray(object.coordinates);
            if (object.address || object?.coordinates) {
              factAddressControl.patchValue({
                fullText: object.address || object?.coordinates,
                latitude: coordinates[0],
                longitude: coordinates[1],
              });
            }
          }
        });
    }
  }

  /**
   * Предустановка селекта объект ЖКХ при изменении подписки на адрес КСиП
   */
  public setJkhObjectByAddress(address: GarFindFlatResponseElement): void {
    const jkhObjectControl = this.incidentForm.controls.jkhObject;
    // Если отображаются контролы жкх и объект не задан, ищем подходящий объект по координатам
    if (!jkhObjectControl?.value && address?.latitude && address?.longitude) {
      this.atmIntegrationService
        .getByQuery({
          point: {
            $radius: {
              $lat: address.latitude,
              $lon: address.longitude,
              $meters: 50,
            },
          },
          active: true,
        })
        .pipe(untilDestroyed(this))
        .subscribe((objects: IMonitoringObjectHcsDto[]) => {
          if (objects.length) {
            this.jkhObjectOptions.query = {
              $and: [{ active: true }, { id: { $in: objects.map((obj: IMonitoringObjectHcsDto) => obj.id) } }],
            };
            if (objects.length === 1) jkhObjectControl.patchValue(objects[0]?.id);
          }
        });
    }
  }

  /** Открытия модалки добавления ТС */
  private addVehicle(): void {
    const dialogRef = this.dialog.open(InvolvedVehiclesEditDialogComponent, {
      disableClose: true,
      minWidth: 750,
      data: {
        involvedVehicles: this.model.involvedVehicles,
      },
      autoFocus: false,
    });
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result: string[]) => {
        if (result?.length) {
          this.model.involvedVehicles
            ? this.model.involvedVehicles.push(...result)
            : (this.model.involvedVehicles = result);
          this.refreshGrid();
        }
      });
  }

  /** Обновление грида ТС */
  public refreshGrid() {
    this.vehiclesGridOptions.query = { id: { $in: this.model.involvedVehicles || [] } };
    this.vehiclesGridComponent.refresh().pipe(untilDestroyed(this)).subscribe();
  }

  /**
   * Проверка возможности отправки информации о событии/происшествии в Реформу ЖКХ
   */
  private checkHcsReformSendAbility() {
    if (this.isSendingReformToJkhVisible) {
      const address = this.incidentForm.controls['byCoordinates'].value
        ? this.addressByCoordinates
        : this.incidentForm.controls['addressFact'].value;

      this.hcsReformSendingStatus = 'Выполняется проверка возможности отправки информации в Реформу ЖКХ';
      this.emergencyService
        .checkHcsReformSendAbility(
          this.incidentForm.controls.incidentTypeId.value,
          this.incidentForm.controls.jkhObject?.value,
          this.incidentForm.controls.ksipDetailsId.value,
          address,
          this.model.id,
        )
        .pipe(
          catchError(() => of({ data: 'ok' })),
          untilDestroyed(this),
        )
        .subscribe((res) => {
          if (res.data !== 'object' && res.data !== 'double') {
            // Если проверка прошла, но не заполнен объект ЖКХ, надо вывести сообщение о необходимости заполнения.
            if (!this.incidentForm.controls.jkhObject?.value) {
              this.hcsReformSendingStatus = 'Чтобы отправить в Реформу ЖКХ заполните поле Объект ЖКХ';
              this.hcsReformSendingStatusIconColor = undefined;
              return;
            }
            this.incidentForm.controls.isSendingToJkhReform.enable();
            this.hcsReformSendingStatus = undefined;
            this.hcsReformSendingStatusIconColor = undefined;
            if (this.model.isSendingToJkhReform) this.incidentForm.controls.isSendingToJkhReform.setValue(true);
            return;
          }

          if (this.incidentForm.controls.isSendingToJkhReform?.value) {
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              width: '600px',
            });
            dialogRef.componentInstance.isHtml = true;
            dialogRef.componentInstance.message = `
              С текущими параметрами отправка происшествия в Реформу ЖКХ невозможна.<br>
              Подробнее см. раздел "Дополнительная информация"
            `;
          }

          if (res.data === 'object') this.hcsReformSendingStatus = 'Дубль объекта';
          if (res.data === 'double') this.hcsReformSendingStatus = 'Дубль происшествия';

          this.incidentForm.controls.isSendingToJkhReform?.setValue(false);
          this.incidentForm.controls.isSendingToJkhReform?.disable();
          this.hcsReformSendingStatusIconColor = '#FC5A5A';
        });
    }
  }
}
