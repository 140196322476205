<h3 class="main-header">
  <mat-icon class="header-icon">track_changes</mat-icon>
  Результаты прогноза
</h3>
<hr/>
<div class="bg-col">
  <h4 class="section-header">Облучение населения в заданной точке на следе облака</h4>
  <ul class="results-list">
    <li class="results-list-item">
      <label>Доза внешнего облучения населения ({{unitsName}})</label><br>
      <label class="results-list-item--var">
        {{forecastingResults?.result?.doseOuterIrradiation | commaToDot | number: '0.2-2'}}
      </label>
    </li>
    <li class="results-list-item">
      <label>Доза внутреннего облучения при ингаляционном поступлении радиоактивных веществ ({{unitsName}})</label>
      <label class="results-list-item--var">
        {{forecastingResults?.result?.doseInnerIrradiation | commaToDot | number: '0.2-2'}}
      </label>
    </li>
    <li class="results-list-item">
      <label>Доза облучения щитовидной железы взрослых ({{unitsName}})</label><br>
      <label class="results-list-item--var">
        {{forecastingResults?.result?.doseThyroidIrradiationAdult | commaToDot | number: '0.2-2'}}
      </label>
    </li>
    <li class="results-list-item">
      <label>Доза облучения щитовидной железы детей ({{unitsName}})</label><br>
      <label class="results-list-item--var">
        {{forecastingResults?.result?.doseThyroidIrradiationChild | commaToDot | number: '0.2-2'}}
      </label>
    </li>
  </ul>
</div>
<hr/>
