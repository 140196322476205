import { Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { BaseComponent } from '../base-component/base.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FlatPickrOutputOptions } from 'angularx-flatpickr/flatpickr.directive';
import { IDateTimeOptions, IInputMaskSettings } from '../../models/interfaces';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatetimeFlatFormat, DatetimeOptionsDataType, DatetimeOptionsFormat } from '../../models/types/datetime.types';
import { ONE_SECOND } from '../../models/constants/time.constant';

/**
 * Компонент инпута даты и времени.
 * Используются библиотеки: dayjs, flatpickr, inputmask.
 *
 * @summary
 * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *  Используется flatpickr версии не выше "~4.5.0"
 *  Причина: баг при сбросе даты
 * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 *  @example
 *  <form [formGroup]="formGroup">
 *    <bg-datetime
 *      [options]="options"
 *      [label]="Дата и время"
 *      [dataType]="unix"
 *      [format]="'DD.MM.YYYY HH:mm'"
 *      formControlName="dateTime">
 *    </bg-datetime>
 *  </form>
 */
@Component({
  selector: 'bg-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimeComponent),
      multi: true,
    },
  ],
})
export class DatetimeComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  /** Настройки пикера по умолчанию */
  @Input() public options: IDateTimeOptions = {
    label: 'Дата и время',
    format: 'DD.MM.YYYY HH:mm:ss',
    dataType: 'unix',
  };
  /** Значение инпута */
  public value: string = '';
  /** Метод события изменения значения инпута, использутеся для FormGroup */
  public onChange: (val: Dayjs | number | string) => void;
  /** Метод события прикасновения(blur) инпута, использутеся для FormGroup */
  public onTouched: () => void;
  /** Блокировка ввода в инпут, использутеся для FormGroup */
  @HostBinding('class.disabled') public disabled: boolean;
  /** Настройки маски инпута */
  public inputMask: IInputMaskSettings;
  /** Вормат даты для флет пикера, отображется в инпуте */
  public dateFormat: DatetimeFlatFormat;
  /** Вклюяает флет пикеру отображение времени */
  public enableTime: boolean = false;
  /**
   * Отображение секунд
   */
  public enableSeconds: boolean = false;
  /** Разрешает флет пикеру ввод с клавиатуры */
  @Input()
  public allowInput: boolean = true;

  /**
   * Часы по умолчанию.
   * Часы устанавлеваемые, если контрол не был инициализирован
   */
  @Input()
  public defaultHour: number = undefined;
  /**
   * Минуты по умолчанию.
   * Минуты устанавлеваемые, если контрол не был инициализирован
   */
  @Input()
  public defaultMinute: number = undefined;
  /**
   * Секунды по умолчанию.
   * Секунды устанавлеваемые, если контрол не был инициализирован
   */
  @Input()
  public defaultSecond: number = undefined;

  /**
   * Минимально возможная дата
   * @param val значение в unix
   */
  private internalMinDate: Date;

  @Input()
  public set minDate(val: number | Date) {
    this.internalMinDate = val ? dayjs(val).toDate() : undefined;
  }

  public get minDate() {
    return this.internalMinDate;
  }

  /**
   * Максимально возможная дата
   * @param val значение в unix
   */
  private internalMaxDate: Date;

  @Input()
  public set maxDate(val: number | Date) {
    this.internalMaxDate = val ? dayjs(val).toDate() : undefined;
  }

  public get maxDate() {
    return this.internalMaxDate;
  }

  /**
   * Автоматическое преобразование значения ngModel из строки в объект даты для флет пикера.
   * Очистет флет пикер при удалении значения в инпуте.
   */
  public fpConvertModelValue: boolean = true;

  /** Лэбл(он же плейсхолдер) инпута, изменяет настройку компонента */
  @Input()
  public set label(value: string) {
    if (value) this.options.label = value;
  }

  public get label() {
    return this.options.label;
  }

  /** Формат даты и времени для moment, изменяет настройку компонента */
  @Input()
  public set format(value: DatetimeOptionsFormat) {
    if (value) {
      this.options.format = value;
      this.updateFormat();
    }
  }

  public get format() {
    return this.options.format;
  }

  /** Тип входных/выходных данных, изменяет настройку компонента */
  @Input()
  public set dataType(value: DatetimeOptionsDataType) {
    if (value) this.options.dataType = value;
  }

  public get dataType() {
    return this.options.dataType;
  }

  constructor() {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    dayjs.locale('ru');
    dayjs.extend(customParseFormat);
    // dayjs.updateLocale(dayjs.locale(), { invalidDate: 'Ошибка' });
    this.updateFormat();
  }

  /**
   * Метод устанавлевает значение даты и времени в инпуте.
   * Срабатывает при инициализации компонента, принимая данные из форм группы,
   * а так же при выборе значения в флет пикере.
   * @param value — значения даты и времени.
   */
  private setInputValue(value?: Dayjs | number | string): void {
    if (!value) {
      this.value = '';
      return;
    }
    if (this.dataType === 'moment') {
      this.value = dayjs(value).format(this.options.format);
      return;
    }
    if (typeof value === 'number') {
      this.value = dayjs(value).format(this.options.format);
    } else if (typeof value === 'string') {
      this.value = dayjs(value, this.options.format).format(this.options.format);
    } else if (dayjs.isDayjs(value)) {
      this.value = value.format(this.options.format);
    } else {
      this.value = '';
    }
  }

  /**
   * Метод переопределяет форматирование даты и времени для использования флет пикером.
   * Вызывается при инициализации компонента.
   * @param format — форматирование даты и времене из момента.
   * @return форматирование даты и времене для флет пикера.
   */
  private getDateFlatFormat(format: DatetimeOptionsFormat): DatetimeFlatFormat {
    return format
      .replace('DD', 'd')
      .replace('MM', 'm')
      .replace('YYYY', 'Y')
      .replace('HH', 'H')
      .replace('mm', 'i')
      .replace('ss', 'S') as DatetimeFlatFormat;
  }

  /**
   * Метод оперделяет настройки для маски инпута.
   * Вызывается при инициализации компонента.
   * @param format — форматирование даты и времене из момента.
   * @return объект настроек.
   */
  private getInputMaskOptions(format: string): IInputMaskSettings {
    return {
      mask: format.replace(/\w/g, '9'),
      placeholder: format
        .replace(/Y/g, 'г')
        .replace(/M/g, 'м')
        .replace(/D/g, 'д')
        .replace(/H/g, 'ч')
        .replace(/m/g, 'м')
        .replace(/s/g, 'с'),
    };
  }

  /**
   * Метод устанавливает значение даты и времени в форм группе и инпуте.
   * Срабатывает при событии изменения значения в флет пикере.
   * @param event — объект события от флет пикера.
   */
  public onChangePicker(event: FlatPickrOutputOptions): void {
    const date = dayjs(event.dateString, this.options.format);
    if (this.options.dataType === 'moment') {
      this.onChange(date);
    } else {
      this.onChange(date.unix() * 1000);
    }
  }

  /***
   * Метод обрабатывает событие вставки
   * @param event - объект события
   */
  public onPaste(event: ClipboardEvent) {
    this.handleDateChange(event.clipboardData.getData('text'));
  }

  /**
   * Метод обрабатывает собитие изменения интпута.
   * @param event — объект события.
   */
  public onInput(event: Event): void {
    this.handleDateChange(event.target['value']);
  }

  /**
   * Метод обрабатывает изменение даты
   * @param newDate - дата в формате строки
   */
  private handleDateChange(newDate: string) {
    /** Сброс значения в форм группе и флет пикере */
    if (newDate === '') {
      this.value = '';
      this.onChange(undefined);
      return;
    }
    /** Если введено корректное значение, обновить значение пикера */
    if (
      dayjs(newDate, this.options.format).isValid() &&
      newDate.replace(/[^0-9]/gi, '').length === this.options.format.replace(/[^a-zа-я]/gi, '').length
    ) {
      const value = newDate;
      const formatValue = dayjs(value, this.options.format);
      this.setInputValue(formatValue);
      if (this.options.dataType === 'moment') {
        this.onChange(formatValue);
      } else {
        this.onChange(formatValue.unix() * ONE_SECOND);
      }
    }
  }

  /**
   * Обновляем формат
   */
  private updateFormat() {
    /** Настройка флет пикера */
    this.dateFormat = this.getDateFlatFormat(this.options.format);
    /** Настройка маски инпута */
    this.inputMask = this.getInputMaskOptions(this.options.format);
    /**
     * Из формата извлекаем разрешение на показ времени
     */
    this.enableTime = this.options.format.indexOf('HH') !== -1;
    /**
     * Из формата извлекаем разрешение на показ секунд
     */
    this.enableSeconds = this.options.format.indexOf('ss') !== -1;
  }

  /**
   * Метод записывает значение в компонент (из ts в html), основная функция.
   * Срабатывает при инициализации компонента.
   */
  public writeValue(value: Dayjs | number | string): void {
    this.setInputValue(value);
  }

  /** Обработать значение из компонента (из html в ts), основная функция fn */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** Обработать, когда потрогали поле (потеря фокуса) */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Обработка недоступности инпута */
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
