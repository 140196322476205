import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { ConfirmDialogResultEnum } from '../../models/enums/confirm-dialog-result.enum';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';

/**
 * Компонента реализует диалоговое окно с подтверждением и закрытием без реакции
 */
@Component({
  selector: 'bg-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent extends AlertDialogComponent {
  /** Есть ли возможность закрыть окно, без реагирования */
  public haveCloseWithoutReaction = true;

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
    super();
  }

  /** Подтвердить действие*/
  public confirm() {
    this.dialogRef.close(ConfirmDialogResultEnum.confirm);
  }

  /** Отказать */
  public reject() {
    this.dialogRef.close(ConfirmDialogResultEnum.reject);
  }

  /** Закрыть без реагирования */
  public closeWithoutReaction() {
    this.dialogRef.close(ConfirmDialogResultEnum.closeWithoutReaction);
  }
}
