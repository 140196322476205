<ng-container [formGroup]="form">
  <input type="text" nz-input formControlName="address" [nzAutocomplete]="auto" />
  <nz-autocomplete #auto>
    <nz-auto-option
      *ngFor="let item of autocomlete"
      [nzValue]="item"
      [nzLabel]="item.fullText || ''"
      [nz-tooltip]="item.fullText || ''"
    >
      {{ item.fullText }}
    </nz-auto-option>
  </nz-autocomplete>
</ng-container>
