import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IScCheckboxOptions, IScTextareaOptions } from '@smart-city/core/common';
import { RestService } from '@smart-city/core/services';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { IAbstractServiceData, ILifeCycleStepActionDto } from 'smart-city-types';
import { ONE_DAY, ONE_MINUTE } from '@bg-front/core/models/constants';
import { EmergencyService } from '../../services';
import { BaseRequestsComponent } from '../base-requests/base-requests.component';

/**
 * Компонента реализует диалог запроса видеоархива
 */
@Component({
  selector: 'bg-video-device-request-video-dialog',
  templateUrl: './video-device-request-video-dialog.component.html',
  styleUrls: ['./video-device-request-video-dialog.component.scss'],
})
export class VideoDeviceRequestVideoDialogComponent extends BaseRequestsComponent implements OnInit {
  /**
   * Настройка компоненты Онование запроса
   */
  public optionsDescription: IScTextareaOptions = {
    label: 'Основание *',
    maxLength: 1000,
    minRows: 3,
    maxRows: 6,
  };
  /**
   * Настройка компоненты Долгвременное хранение
   */
  public optionsPermanent: IScCheckboxOptions = {
    title: 'Долговременное хранение',
  };

  /**
   * @ignore
   * @param dialogRef
   * @param cameraId
   * @param rest
   * @param emergencyService
   */
  constructor(
    public dialogRef: MatDialogRef<VideoDeviceRequestVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public cameraId: string,
    private readonly rest: RestService,
    private emergencyService: EmergencyService,
  ) {
    super();
  }

  /**
   * Закрытие
   */
  close() {
    this.dialogRef.close();
  }

  /**
   * Закрытие с передачей данных
   * @param action - действие которое необходимо выполнить с запросом
   */
  save(action: string) {
    // Необходимо принудительно валидировать контролы с динамической валидацией.
    // Т.е. валидность которых зависит от значения других контролов.
    this.requestForm.controls.deleteTime.updateValueAndValidity({ emitEvent: false });
    this.requestForm.controls.remindDate.updateValueAndValidity({ emitEvent: false });
    if (this.requestForm.valid) {
      this.performAction(action, {
        cameraId: this.cameraId,
        from: this.requestForm.controls['from'].value,
        to: this.requestForm.controls['from'].value + this.requestForm.controls['durationSlider'].value * ONE_MINUTE,
        requestDeadline: this.requestForm.controls['requestDeadline'].value,
        reason: this.requestForm.controls['reason'].value,
        permanent: this.requestForm.controls['permanent'].value,
        deleteTime: this.requestForm.controls['deleteTime'].value
          ? this.requestForm.controls['deleteTime'].value - this.timezoneOffset
          : null,
        remindDate: this.requestForm.controls['deleteTime'].value
          ? this.requestForm.controls['deleteTime'].value -
            this.requestForm.controls['remindDate'].value * ONE_DAY -
            this.timezoneOffset
          : null,
        lifeCycleStepId: this.actions.find((item: ILifeCycleStepActionDto) => item.name === action).nextStep,
      })
        .pipe(
          catchError((error: Error) =>
            this.catchErrorFn(error, 'Ошибка создания запроса на получения доступа к видеоархиву'),
          ),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(() => {
          this.noteService.pushInfo(this.getInfo(action, 'create'));
          this.dialogRef.close();
        });
    }
  }

  ngOnInit(): void {
    this.requestForm = new FormGroup({
      from: new FormControl(undefined, [Validators.required]),
      durationInput: new FormControl(1),
      durationSlider: new FormControl(1),
      requestDeadline: new FormControl(undefined),
      reason: new FormControl('', [Validators.required]),
      permanent: new FormControl(false),
      deleteTime: new FormControl(undefined),
      remindDate: new FormControl(undefined),
    });

    this.requestForm.controls['permanent'].valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value: boolean) => {
        this.onPermanentChange(value);
      });

    this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'LifeCycleStep',
          query: {
            'lifeCycleId.type.sysname': 'request',
            'lifeCycleId.orgTypeParam.organizationTypeId.sysname': 'securityForces',
            'status.sysname': 'new',
          },
          attributes: ['id', 'params'],
        },
      })
      .pipe(
        switchMap((step: IAbstractServiceData) => {
          this.disableControls(((step.data.items || [])[0] || {}).params);

          return this.emergencyService
            .getLifeCycleButtonsById(((step.data.items || [])[0] || {}).id)
            .pipe(takeUntil(this.ngUnsubscribe));
        }),
        catchError((err: Error) => {
          return this.catchErrorFn<ILifeCycleStepActionDto[]>(
            err,
            'Ошибка при создании запроса. Проверьте настройку жизненного цикла',
            [],
          );
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((actions: ILifeCycleStepActionDto[]) => {
        this.actions = actions;
      });

    super.ngOnInit();
  }
}
