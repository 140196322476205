import { Units, point, polygon } from '@turf/helpers';
import rhumbDistance from '@turf/rhumb-distance';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

import { ICoordinates } from '../interfaces';

/**
 * Класс реализующий работу с координатами
 */
export class Coordinates {
  lat: number = undefined;
  lon: number = undefined;

  /**
   * Конструктор по умолчанию
   */
  constructor();
  /**
   * @param value - объект с координатами
   */
  constructor(value: ICoordinates);
  /**
   * @param lat - строка координат
   */
  constructor(lat: string);
  /**
   * @param lat - широта
   * @param lon - долгота
   */
  constructor(lat: string | number, lon: string | number);
  /**
   * @param lat - широта
   * @param lon - долгота
   */
  constructor(lat?: string | number | ICoordinates, lon?: string | number) {
    if (lat) {
      if (typeof lat === 'string') {
        const elms = lat.split(',');
        if (elms.length === 2) {
          if (elms[0]) {
            this.lat = this.validLat(this.setValue(elms[0]));
          }
          if (elms[1]) {
            this.lon = this.validlong(this.setValue(elms[1]));
          }
          return;
        }
        this.lat = this.validlong(this.setValue(lat));
      }

      if (typeof lat === 'number') {
        this.lat = this.validLat(this.setValue(lat));
      }

      /**
       Обработка данных, если в конструктор пришел объект (или массив)
       */
      if (typeof lat === 'object') {
        if (!Array.isArray(lat)) {
          this.lat = this.validLat(this.setValue(lat.lat));
          this.lon = this.validlong(this.setValue(lat.lon));
        } else {
          this.lat = this.validLat(this.setValue(lat[0]));
          this.lon = this.validlong(this.setValue(lat[1]));
        }
      }
    }

    if (lon) {
      this.lon = this.validlong(this.setValue(lon));
    }
  }

  /**
   * Преобразуем строку координат к массиву
   * @param coordinates
   */
  public static coordinatesToArray(coordinates: string): [number, number] {
    try {
      const elm = coordinates.split(/\s*,\s*/);
      return new Coordinates(elm[0], elm[1]).toArray();
    } catch (e) {
      return undefined;
    }
  }

  /** Получаем координату с самым большой широтой */
  public static maxLat(coordinates: Coordinates[]): Coordinates | undefined {
    if (coordinates?.length) {
      let max = coordinates[0];
      for (let i = 1; i < coordinates.length; i += 1) {
        if (coordinates[i].lat > max.lat) {
          max = coordinates[i];
        }
      }

      return max;
    }

    return undefined;
  }

  // TODO: Сделать статичным для проверки значений передаваемых в конструктор
  /** Проверка валидности данных */
  public isValid(): boolean {
    return !(this.lat === undefined || this.lon === undefined);
  }

  /** Преобразование к массиву */
  public toArray(): [number, number] {
    if (this.lat && this.lon) {
      return [this.lat, this.lon];
    }

    return undefined;
  }

  /** Массив строк */
  public toStringArray(): [string, string] {
    if (this.lat && this.lon) {
      return [this.lat.toString(), this.lon.toString()];
    }

    return undefined;
  }

  /** Переопределяем функцию toString */
  public toString(): string {
    return this.toStringArray()?.join(', ');
  }

  /** Сравниваем с объектом Coordinates */
  public equal(coords: Coordinates): boolean {
    try {
      return this.isValid() && coords.isValid() && this.toString() === coords.toString();
    } catch (e) {
      return false;
    }
  }

  /** Проверка валидности координат широты */
  private validLat(lat: number): number | undefined {
    const minLat = -90;
    const maxLat = 90;
    if (minLat <= lat && lat <= maxLat) {
      return lat;
    }
    return undefined;
  }

  /** Проверка валидности координат широты */
  private validlong(lon: number): number | undefined {
    const minLong = -180;
    const maxLong = 180;
    if (minLong <= lon && lon <= maxLong) {
      return lon;
    }
    return undefined;
  }

  /** Устанавливаем значение */
  private setValue(val: string | number): number | undefined {
    switch (typeof val) {
      case 'string':
        return Number.parseFloat(val.trim());
      case 'number':
        return val;
    }

    return undefined;
  }

  /**
   * Расчет расстояние между координатами
   * @param coordinates список координат
   */
  static distanceInKmBetweenEarthCoordinates(coordinates: [[number, number], [number, number]]): number {
    const earthRadiusKm = 6367;
    let distance = 0;
    for (let i = 0; i < coordinates.length - 1; i++) {
      const dLat = this.degreesToRadians(coordinates[i + 1][0] - coordinates[i][0]);
      const dLon = this.degreesToRadians(coordinates[i + 1][1] - coordinates[i][1]);

      const newLat1 = this.degreesToRadians(coordinates[i][0]);
      const newLat2 = this.degreesToRadians(coordinates[i + 1][0]);

      const a =
        Math.pow(Math.sin(dLat / 2), 2) + Math.cos(newLat1) * Math.cos(newLat2) * Math.pow(Math.sin(dLon / 2), 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      distance += earthRadiusKm * c;
    }
    return distance;
  }

  /**
   * Рассчёт расстояния. По умолчанию в метрах
   * @param from - координаты точки от которой измеряем
   * @param to - координаты точки до которой вычесляем расстояние
   */
  public static distanceBetweenCoordinates(
    from: Coordinates,
    to: Coordinates,
    units: Units = 'meters',
  ): number | undefined {
    if (from && to && from instanceof Coordinates && to instanceof Coordinates) {
      const options: { units: Units } = { units };

      return rhumbDistance(from.toArray().reverse(), to.toArray().reverse(), options);
    }

    return undefined;
  }

  /**
   * Конвертация градусов в радианы
   * @param degrees
   */
  static degreesToRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
  }

  /**
   * Входит ли точка в полигон
   * @param pointCoordinates координаты точки
   * @param polygonCoordinates список координат полигона
   */
  public static isPointInPolygon(pointCoordinates: [number, number], polygonCoordinates: [number, number][]): boolean {
    return booleanPointInPolygon(point(pointCoordinates), polygon([polygonCoordinates]))
  }
}
