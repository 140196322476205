import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Settings2Service } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';
import { ForecastingRadioactiveEnvironmentVisualizationService } from '../../services';
import { IPopulationIrradiationPointTaskDto } from '../../models';
import { IFormFieldData } from '@smart-city/core/interfaces';

@Component({
  selector: 'bg-population-irradiation-point-task-result',
  templateUrl: './population-irradiation-point-result.component.html',
  styleUrls: ['./population-irradiation-point-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopulationIrradiationPointResultComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Результат */
  @Input()
  public forecastingResults: IForecastingResultDto<IPopulationIrradiationPointTaskDto>;
  /** Скачать отчёт */
  @Input()
  public downloadReport: Observable<void>;
  /** Ссылка на элемент */
  private element: any;
  /** Ссылка на элемент */
  public unitsName: string;

  /** Уведомляем о наличии отчёта */
  @Output()
  public hasReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor(
    private readonly forecastingService: ForecastingRadioactiveEnvironmentVisualizationService,
    private readonly settings: Settings2Service,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    const params = this.forecastingResults.params.params as IPopulationIrradiationPointTaskDto;
    const point = params.coordinatesTarget;
    if (new Coordinates(this.forecastingResults.params.coordinates).isValid()) {
      this.element = this.forecastingService.displayPoint(point);
    } else {
      this.noteService.pushWarning('Отсутствуют координаты для визуализации расчёта');
    }
    this.unitsName = this.settings.getDictionaryById(params.unitRadiationId)?.name;

    this.hasReport.emit(false);

    this.cdr.markForCheck();
  }

  /**
   * @ignore
   */
  public override ngOnDestroy(): void {
    this.forecastingService.clearResults(this.element);
  }
}
