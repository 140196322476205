<div class="bg-row">
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Наименование устройства</label>
      <p>{{ name }}</p>
    </div>
  </div>
</div>
<div class="bg-row">
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Псевдоним</label>
      <p>{{ wantedPerson?.pseudonym }}</p>
    </div>
  </div>
  <div class="bg-col">
    <div class="bg-form-group">
      <div formGroupName="wantedPersonFormGroup">
        <sc-input [options]="wantedPersonNameOptions" formControlName="wantedPersonName"></sc-input>
      </div>
    </div>
  </div>
</div>

<div class="bg-row">
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Причина розыска</label>
      <p>{{ wantedPerson?.reasonWantedId }}</p>
    </div>
  </div>
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Списки наблюдения</label>
      <p>{{ dossierLists }}</p>
    </div>
  </div>
</div>
<div class="bg-row">
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Организация ответственного</label>
      <p>{{ wantedPerson?.responsibleOrganizationId }}</p>
    </div>
  </div>
</div>
<div class="bg-row">
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Ответственный сотрудник</label>
      <p>{{ wantedPerson?.responsibleUserId }}</p>
    </div>
  </div>
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Контактный телефон</label>
      <p (click)="onClickPhone(wantedPerson?.responsiblePhone)">{{ wantedPerson?.responsiblePhone }}</p>
    </div>
  </div>
</div>

<div class="bg-row" *ngIf="canPhotoAndVideo?.visible">
  <div class="bg-col">
    <mat-tab-group class="sc-tab-group" [disableRipple]="true">
      <mat-tab [label]="'Фото'">
        <bg-match-image *ngIf="frame; else noFrame" [path]="imageUrl" [frame]="frame"></bg-match-image>
        <ng-template #noFrame>
          <img [src]="imageUrl | safe: 'url'" [alt]="'Изображение инцидента'" class="image" />
        </ng-template>
      </mat-tab>
      <mat-tab [label]="'Эталонное фото'">
        <img [src]="referenceImageUrl | safe: 'url'" [alt]="'Эталонное фото'" class="image" />
      </mat-tab>
      <mat-tab [label]="'Видеоинтервал'">
        <span *ngIf="!sfsId; else player">{{ videoStatus }}</span>
        <ng-template #player>
          <bg-hls-player [sourceType]="'sfs'" [sfsId]="sfsId" [frame]="frame"></bg-hls-player>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<div class="bg-row" *ngIf="canPreparePhotoAndVideo?.visible">
  <div class="bg-col">
    <bg-download-sources
      [options]="downloadSourcesOptions"
      [disabled]="!canPreparePhotoAndVideo?.enabled"
    ></bg-download-sources>
  </div>
  <div *ngIf="matchedDossierId" class="bg-col align-right col-vertical-center">
    <a target="_blank" (click)="openWantedPerson()">Подробнее в досье</a>
  </div>
</div>
