import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SelectAllOptionsA } from '@datorama/akita';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IKsipSectionsDto } from '../../../models/interfaces';
import { KsipSectionsState } from '../../../models/states';
import { KsipSectionsStore } from '../store/ksip-sections-store.service';

@Injectable({
  providedIn: 'root',
})
export class KsipSectionsQuery extends QueryEntity<KsipSectionsState> {
  constructor(store: KsipSectionsStore) {
    super(store);
  }

  /** Запрос КСиП по Id */
  public getById(id: string): IKsipSectionsDto {
    return this.getEntity(id);
  }

  /** Используется в Интернет-портале или нет */
  public canUseInInternetPortal(id: string): boolean {
    return this.getEntity(id).useInternetPortal;
  }

  /** Запрос по множеству Id*/
  public getForSelect(options?: SelectAllOptionsA<IKsipSectionsDto>): Observable<IScSelectItem[]> {
    return this.selectAll(options || {}).pipe(
      map((items: IKsipSectionsDto[]) =>
        items.map((item: IKsipSectionsDto) => <IScSelectItem>{ id: item.id, text: item.name }),
      ),
    );
  }
  public getForSelectActive(options?: SelectAllOptionsA<IKsipSectionsDto>): Observable<IScSelectItem[]> {
    return this.selectAll(options || {}).pipe(
      map((items: IKsipSectionsDto[]) =>
        items
          .filter((item) => item.active)
          .map((item: IKsipSectionsDto) => <IScSelectItem>{ id: item.id, text: item.name }),
      ),
    );
  }
  public getIdSysName(): string {
    return this.getAll().find((item: IKsipSectionsDto) => item.sysname === 'zhkh')?.id;
  }
}
