import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BgCoreModule } from '@bg-front/core';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { ScComponentsModule } from '@smart-city/core/common';
import { PltPipesModule } from '@smart-city/core/pipes';
import { ScMapsModule } from '@smart-city/maps/sc';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

import {
  ExposurePopulationOvercomingCloudTrailTaskComponent,
  ExposurePopulationOvercomingCloudTrailTaskResultComponent,
  NuclearPollutionFormComponent,
  NuclearPollutionPointTaskComponent,
  NuclearPollutionPointTaskResultComponent,
  NuclearPollutionTaskResultComponent,
  OvercomingPathPointComponent,
  PopulationIrradiationPointResultComponent,
  PopulationIrradiationPointTaskComponent,
  RadioactiveCloudTimeTaskComponent,
  RadioactiveCloudTimeTaskResultComponent,
  RadioactiveEnvironmentExtraParamsModalFormComponent,
  ThyroidIrradiationTaskComponent,
  ThyroidIrradiationTaskResultComponent,
  TimeAllowedToOvercomeCloudTrailResultComponent,
  TimeAllowedToOvercomeCloudTrailTaskComponent,
} from './components';
import {
  ForecastingRadioactiveEnvironmentReportService,
  ForecastingRadioactiveEnvironmentService,
  ForecastingRadioactiveEnvironmentVisualizationService,
} from './services';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    ScComponentsModule,
    ScMapsModule,
    ErrorTailorModule,
    PltPipesModule,
    BgCoreModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzTypographyModule,
    NzDividerModule,
    NzNotificationModule,
    NzModalModule,
  ],
  providers: [
    ForecastingRadioactiveEnvironmentService,
    ForecastingRadioactiveEnvironmentVisualizationService,
    ForecastingRadioactiveEnvironmentReportService,
  ],
  declarations: [
    ExposurePopulationOvercomingCloudTrailTaskComponent,
    ExposurePopulationOvercomingCloudTrailTaskResultComponent,
    NuclearPollutionPointTaskComponent,
    NuclearPollutionPointTaskResultComponent,
    NuclearPollutionFormComponent,
    NuclearPollutionTaskResultComponent,
    OvercomingPathPointComponent,
    RadioactiveEnvironmentExtraParamsModalFormComponent,
    ThyroidIrradiationTaskResultComponent,
    ThyroidIrradiationTaskComponent,
    RadioactiveCloudTimeTaskComponent,
    RadioactiveCloudTimeTaskResultComponent,
    PopulationIrradiationPointTaskComponent,
    PopulationIrradiationPointResultComponent,
    TimeAllowedToOvercomeCloudTrailTaskComponent,
    TimeAllowedToOvercomeCloudTrailResultComponent,
  ],
  exports: [
    ExposurePopulationOvercomingCloudTrailTaskComponent,
    ExposurePopulationOvercomingCloudTrailTaskResultComponent,
    NuclearPollutionPointTaskComponent,
    NuclearPollutionPointTaskResultComponent,
    NuclearPollutionFormComponent,
    NuclearPollutionTaskResultComponent,
    ThyroidIrradiationTaskResultComponent,
    ThyroidIrradiationTaskComponent,
    RadioactiveCloudTimeTaskComponent,
    RadioactiveCloudTimeTaskResultComponent,
    PopulationIrradiationPointTaskComponent,
    PopulationIrradiationPointResultComponent,
    TimeAllowedToOvercomeCloudTrailTaskComponent,
    TimeAllowedToOvercomeCloudTrailResultComponent,
  ],
})
export class ForecastingRadioactiveEnvironmentModule {}
