import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MjpegPlayerEventEnum } from '../../models/enums';
import { IMjpegPlayerObjectUrl, IMjpegPlayerOptions } from '../../models/interfaces';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImgStream } from '../../models/classes';
import { ScConsole } from '@smart-city/core/utils';
import { BaseComponent } from '@bg-front/core/components';
import { SafePipe } from '@bg-front/core/pipes';
import { NotificationService } from '@smart-city/core/services';
import { fromEvent } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import * as dayjs from 'dayjs';

/** Компонент "Видеоплеера mjpeg" */
/** @description
 * Приведены обязательные параметры при работе с разными режимами
 * ОСТАВЛЕННЫЙ ПРЕДМЕТ
 * @param needStroke - для включения рамки необходимо в needStroke передать значение true
 * ! Координаты для нее захардкодены сейчас !
 *
 * АРХИВ
 * Для работы с архивом, требуется соответсвтующая ссылка, дата и время в которой должны высчитываться:
 * startTime = (текущей даты пользователя) - (часовой пояс) - (четыре часа на продолжительность архива)
 * Ограничения из-зи фейкового видео на данный момент.
 * @param options - объект конфигурации плеера
 * Обязательные параметры:
 * @param source - ссылка на видееопоток
 * @param period - требуемая продолжительность архива в миллисекундах (напр. для 30 минут следует передать 30 * 60 * 1000)
 * @param playbackMode - режим воспроизведения
 * @param serverType - тип видеосервера
 */

@Component({
  selector: 'bg-mjpeg-player',
  templateUrl: './mjpeg-player.component.html',
  styleUrls: ['./mjpeg-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MjpegPlayerComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Ссылка на Canvas элемент */
  @ViewChild('player', { static: true }) public player: ElementRef<HTMLCanvasElement>;
  /** Ссылка на обертку для получения размеров */
  @ViewChild('wrapper', { static: true }) public wrapper: ElementRef;

  /** Настройки плеера по умолчанию */
  @Input()
  public options: IMjpegPlayerOptions = {
    source: '',
    playbackMode: 'life',
    needStroke: false,
    autoPlaying: false,
  };
  /** Генерируется событие при нажатии на кнопку фильтра для ILayout */
  // Без генерации этих событий не работает остановка и запуск воспроизведения.
  // После закрытия возникают ошибки. this.cdr.detectChanges вроде не решает проблему.
  // Оставил пока так, в будущем можно попробовать разобраться.
  @Output() public playerEvent = new EventEmitter<MjpegPlayerEventEnum>();
  /** Ссылка на контекст canvas елемента */
  public cvsContext: CanvasRenderingContext2D;
  /** Ссылка на видеопоток, и его управление */
  public imgStream: ImgStream;
  /** Установка/отключение автоматического проигрование видеопотока */
  public autoPlay: boolean = false;
  /** Признак проигрывания видеопотока */
  public isPlaying: boolean = false;
  /** Безопасная ссылка на видеопоток */
  private safeUrl: string;

  /** @ignore */
  constructor(
    private note: NotificationService,
    private sanitizer: DomSanitizer,
    private readonly safePipe: SafePipe,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalService: NzModalService,
  ) {
    super();
  }

  /** Флаг необходимости отобразить кнопки управления */
  public showControls: boolean = false;

  /** Извещение о закрытии диалога с развернутым плеером **/
  @Output() public closeEvent = new EventEmitter<void>();

  /** @ignore */
  public ngOnInit(): void {
    // Сменить кнопку в шаблоне, если автопроигрование включено
    this.isPlaying = this.autoPlay = this.options.autoPlaying ? this.options.autoPlaying : this.autoPlay;
    // Запуск плеера, в случае передачи ссылки на поток через опции
    if (this.options.source) {
      this.playerInit(this.autoPlay);
    }

    fromEvent(this.player.nativeElement, 'mousemove').pipe(
      tap(() => {
        this.showControls = true;
        this.cdr.detectChanges();
      }),
      debounceTime(3000),
    ).subscribe(() => {
      this.showControls = false;
      this.cdr.detectChanges();
    });
  }

  /**
   * Метод останавливает/воспроизводит поток видео.
   * Срабатывает при клике на кнопки pause/play_arrow.
   */
  public togglePlaying(): void {
    this.isPlaying = !this.isPlaying;
    if (!this.imgStream) {
      ScConsole.warning(`Видеопоток неопределен, ${
          !this.isPlaying ? 'остановка не может быть выполнена' : 'воспроизведение не может быть выполнено'
        }`,
      );
      return;
    }
    if (!this.isPlaying) {
      this.imgStream.stop();
      return this.playerEvent.emit(MjpegPlayerEventEnum.pause);
    }
    this.imgStream.start();
    return this.playerEvent.emit(MjpegPlayerEventEnum.playing);
  }

  /**
   * Метод перематывает вперед видеопоток.
   * Срабатывает при клике на кнопку forward_30.
   */
  public moveTimeForward(): void {
    if (!this.isPlaying) return;
    this.imgStream.moveForward();
    return this.playerEvent.emit(MjpegPlayerEventEnum.timeForward);
  }

  /**
   * Метод перематывает назад видеопоток.
   * Срабатывает при клике на кнопку replay_30.
   */
  public moveTimeBack(): void {
    if (!this.isPlaying) return;
    this.imgStream.moveBack();
    return this.playerEvent.emit(MjpegPlayerEventEnum.timeBack);
  }

  /**
   * Получение безопасного Url потока
   */
  public getUrl(): SafeUrl {
    if (!this.options.source) {
      ScConsole.error('Источник на видеопоток неопределен, или не был передан');
    }
    return this.safePipe.transform(this.options.source, 'url');
  }

  /**
   * Инициализация видеопотока и проигрывателя. При вызове плеера в диалоговом окне,
   * его инициализация выполняется непосредственно в компоненте диалога.
   * Метод отрабатывает в момент определения переданного источника(source) для потока.
   * @param autoPlay — установка/отключение автоматического проигрования видеопотока.
   */
  public playerInit(autoPlay?: boolean): void {
    this.safeUrl = this.getUrl()['changingThisBreaksApplicationSecurity'] || '';
    if (!this.safeUrl) {
      ScConsole.error('Ссылка на видеопоток не безопасна и не передна проигрователю');
    } else {
      ScConsole.info(`Ссылка на видеопоток:\n${this.safeUrl}`);
    }

    this.player.nativeElement.width = this.wrapper.nativeElement.offsetWidth;
    this.player.nativeElement.height = this.wrapper.nativeElement.offsetHeight;

    this.cvsContext = this.player.nativeElement.getContext('2d');
    this.imgStream = new ImgStream({
      url: this.safeUrl,
      player: this.player,
      context: this.cvsContext,
      wrapper: this.wrapper,
      autoPlay: autoPlay || false,
      objectUrl: this.parseUrl(this.safeUrl),
      stroke: this.options.needStroke
        ? ['0,184', '0,592', '0,084', '0,154'] // X, Y, WIDTH, HEIGHT - from macroscop
        : [],
      strokeClr: this.options.strokeColor || '#FF0000',
      encodeStartTime: (startTime: number): string => {
        if (this.options.serverType === 'intellect') {
          return dayjs(startTime).toISOString();
        }
        if (this.options.serverType === 'macroscop') {
          // Получение часового пояса в мс
          const offset = -(new Date().getTimezoneOffset() * 60 * 1000);
          // Смещение на часовой пояс, для точной даты в запросе
          const newDate = new Date(startTime + offset).toJSON().slice(0, 19);
          // Преобразование строки с датой в формат для Макрскопа
          const date = newDate.split('T')[0].split('-');
          const time = newDate.split('T')[1].split(':');
          return `${date[2]}.${date[1]}.${date[0]}+${time[0]}:${time[1]}:${time[2]}`;
        }
      },
      startTimeKey: this.options.serverType === 'intellect' ? 'time_from' : 'startTime',
      createUrl: (query: string, objectUrl: IMjpegPlayerObjectUrl): string => {
        if (this.options.serverType === 'intellect') {
          return `${ objectUrl.local.pathname }?${ query }`;
        }
        if (this.options.serverType === 'macroscop') {
          return `
            ${ objectUrl.local.protocol }://
            ${ objectUrl.local.host }:
            ${ objectUrl.local.port }
            ${ objectUrl.local.pathname }?
            ${ query }
          `;
        }
      }
    });

    /**
     * Если поток определен, подисываемся на событие ошибки,
     * в случае возникновения которой происходит переключинеие кнопок проигрованая и всплывает упедомление.
     */
    if (this.imgStream) {
      this.imgStream.subject.subscribe((type: string): void => {
        if (type === 'error' && this.isPlaying) {
          this.togglePlaying();
          this.note.pushWarning('Обрыв видеопотока. Возможная причина: канал перегружен.');
        }
      });
    }

    this.cdr.detectChanges();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (!this.imgStream) {
      ScConsole.warning('Видеопоток не определен, и не может быть остановлен');
    } else {
      this.imgStream.stop();
      ScConsole.info('Видеопоток остановлен');
    }
  }

  /**
   * Метод разбирает строковую ссылка видео-потока на отдельные параметры.
   * @param url — адрес видео-потока.
   * @return объект с параметрами запроса и адреса потока.
   */
  private parseUrl(url: string): IMjpegPlayerObjectUrl {
    const objectUrl = {
      local: new URL(url.startsWith('http') ? url : window.origin + url),
      params: {},
    };

    objectUrl.local.searchParams.forEach((val: string, key: string) => {
      objectUrl.params[key] = val;
      if (key === 'startTime') {
        objectUrl.params[key] = this.parseTime(val);
        if (this.options.period) {
          objectUrl.params['period'] = this.parseTime(val).time + this.options.period;
        } else {
          objectUrl.params['period'] = 0;
        }
      }
    });

    return objectUrl;
  }

  /**
   * Метод разбирает переданные дату и время из запроса к видео-потоку, и взвращает объект даты и врмени в мс.
   * @param dateTime — дата и время строкой из запроса видио-потока.
   * @return объект с даты и временем в мс.
   */
  private parseTime(dateTime: string): { date: Date | string; time: number } {
    const date = dateTime
      .split('+')[0]
      .split('.')
      .map((elm: string) => parseInt(elm, 10));
    const time = dateTime
      .split('+')[1]
      .split(':')
      .map((elm: string) => parseInt(elm, 10));
    date[1] = date[1] - 1 < 0 ? 11 : date[1] - 1;
    return {
      date: new Date(date[2], date[1], date[0], time[0], time[1], time[2]) || '',
      time: new Date(date[2], date[1], date[0], time[0], time[1], time[2]).getTime() || 0,
    };
  }

  /** Показать кнопки перемотки в режиме "архив" */
  public showRewindButtons(): boolean {
    return this.options.playbackMode === 'archive';
  }

  /** Функция вызывает диалог с плеером */
  public toggleDialog() {
    if (this.options.fullScreen) {
      this.closeEvent.emit();
      return;
    }

    // Остановить воспроизведение потока
    if (this.isPlaying) this.togglePlaying();

    const modal = this.modalService.create({
      nzContent: MjpegPlayerComponent,
      nzClosable: false,
      nzFooter: null,
      nzBodyStyle: { padding: '0', top: '0', height: '100%' },
      nzComponentParams: { options: { ...this.options, fullScreen: true } },
      nzCentered: true,
      nzWidth: '100%',
      nzClassName: 'fullscreen-mjpeg-player',
      // Для отладки
      // nzWrapClassName: 'fullscreen-mjpeg-player-wrap'
    });
    modal.componentInstance.closeEvent.subscribe(() => modal.close());
  }
}
