import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { MapDialogV2Component } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { ISelectItem } from '@bg-front/core/models/interfaces';
import { WeatherDataService } from '@bg-front/weather-data/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { ForecastingRadioactiveEnvironmentService } from '../../services';
import { BaseForecastingRadioactiveEnvironmentComponent } from '../base-forecasting-radioactive-environment/base-forecasting-radioactive-environment.component';

/**
 * Форма запуска расчёта для облучения населения на следе облака в точке
 */
@UntilDestroy()
@Component({
  selector: 'bg-population-irradiation-point-task',
  templateUrl: './population-irradiation-point-task.component.html',
  styleUrls: ['./population-irradiation-point-task.component.scss'],
})
export class PopulationIrradiationPointTaskComponent
  extends BaseForecastingRadioactiveEnvironmentComponent
  implements OnInit
{
  /** Единицы измерения */
  public units: ISelectItem[] = this.getDictionaryForSelect('unitRadiation');
  public tooltipIodineText = 'Своевременно проведенная йодная профилактика снижает дозу на щитовидную железу в 100 раз';

  /** Тип укрытия */
  public shelterTypes: ISelectItem[] = this.forecastingService.shelterTypeForLocationAppointment();

  /** Этажи */
  public shelterFloors = this.getDictionaryForSelect('floorShelterFromRadiation');
  /** Расположение укрытия */
  public shelterLocations: ISelectItem[] = this.getDictionaryForSelect('locationShelterFromRadiation');

  /** Режим просмотра */
  public override isShow = false;

  constructor(
    public readonly controlContainer: ControlContainer,
    private readonly forecastingService: ForecastingRadioactiveEnvironmentService,
    private readonly modalService: NzModalService,
    settings: Settings2Service,
    weatherService: WeatherDataService,
    notificationService: NzNotificationService,
  ) {
    super(settings, weatherService, notificationService);
  }

  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;

    this.form.controls['isRadioactiveCouldApproaching'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((result: string) => {
        const method = result ? 'disable' : 'enable';
        this.form.controls['timeTillIrradiationStart'][method]();
      });

    this.form
      .get('shelterLocationId')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((uuid: string) => {
        const localStreetId = this.settings
          .getDictionaryByTypeSysName('locationShelterFromRadiation')
          .find((item: IDictionaryInfo) => {
            return item.sysname === 'localAppointmentStreet';
          }).id;
        this.shelterTypes =
          localStreetId === uuid
            ? this.getDictionaryForSelect('typeShelterFromRadiation')
            : this.forecastingService.shelterTypeForLocationAppointment();
      });

    this.form
      .get('shelterTypeId')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((uuid: string) => {
        const shelterTypeSysname: string = this.settings
          .getDictionaryByTypeSysName('typeShelterFromRadiation')
          .filter(({ id }) => {
            return id === uuid;
          })[0]?.sysname;
        this.shelterFloors = this.forecastingService.shelterFloorForType(shelterTypeSysname);
      });
  }

  /**
   * Обработчки по клику на кнопку задать координаты
   * @param $event - объект события
   * @return
   */
  public onClickAppointCoordinates($event: Event) {
    const currentCoordinates = this.form.controls['coordinatesTarget'].value || '';
    const splited = currentCoordinates.split(',');
    const data = {
      coordinates: new Coordinates(),
    };
    if (splited[0] && splited[1]) {
      data.coordinates = new Coordinates(Number(splited[0]), Number(splited[1]));
    }
    this.openSpecifyMap(data);
  }

  /** Метод который обеспечивает открытие диалога и подписку на закрытие
   * @param data - параметры для диалога
   */
  public openSpecifyMap(data: { coordinates?: Coordinates }): void {
    const modal = this.modalService.create({
      nzTitle: 'Укажите координату',
      nzContent: MapDialogV2Component,
      nzComponentParams: data,
      nzWidth: '600px',
    });

    let subs = modal.afterClose.pipe(untilDestroyed(this)).subscribe((params: any) => {
      if (params?.coordinates) {
        this.form.controls.coordinatesTarget.setValue(`${params.coordinates.join(', ')}`);
      }

      subs.unsubscribe();
      subs = undefined;
    });
  }
}
