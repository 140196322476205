import { Injectable } from '@angular/core';
import { DialogService, NwFormPopupService } from '@smart-city/core/common';
import { NotificationService, RestService, Settings2Service, SfsService } from '@smart-city/core/services';
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, ILifeCycleStepActionDto } from 'smart-city-types';
import { ONE_DAY, ONE_HOUR } from '@bg-front/core/models/constants';
import { VideoDevicesService } from '../video-devices/video-devices.service';
import { IInformationRequestData } from '../../models/interfaces/information-request-data.interface';
import { EmergencyService } from '../emergency/emergency.service';
import { IRequestActionResult } from '../../models/interfaces';
import { MultiFileService } from '@bg-front/core/services';

/**
 * Сервис для создания/редактирования/удаления важных объектов
 */
@Injectable({
  providedIn: 'root',
})
export class RequestsService {
  constructor(
    private readonly rest: RestService,
    private readonly settings: Settings2Service,
    private readonly note: NotificationService,
    private readonly dialog: DialogService,
    private readonly form: NwFormPopupService,
    private readonly emergencyService: EmergencyService,
    private readonly videoDevicesService: VideoDevicesService,
    private readonly sfs: SfsService,
    private readonly multiFileService: MultiFileService,
  ) {}

  /**
   * Сохранение запроса
   * @param request - данные о запросе
   */
  public save(request: IInformationRequestData): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'saveRequest',
      service: { name: 'Emergency' },
      entity: {
        name: 'Requests',
      },
      data: request,
    });
  }

  /**
   * Удаление запроса
   * @param id - ID удаляемой записи
   */
  public delete(id: string): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: 'Emergency' },
      entity: {
        name: 'Requests',
        query: { id },
      },
    });
  }

  /**
   * Получение информаци о запросе
   * @param id ID запроса
   * @param needVideo нужно ли запрашивать видео. По умолчанию false
   */
  public getRequestData(id: string, needVideo: boolean = false): Observable<IRequestActionResult> {
    const data: IRequestActionResult = {};
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Requests',
          query: { id },
          linksMode: 'raw',
          attributes: [
            'id',
            'number',
            'createTime',
            'updateTime',
            'cameraId.id',
            'cameraId.extId',
            'cameraId.token',
            'cameraId.mediaUrl',
            'cameraId.videoServer.ip',
            'cameraId.videoServer.port',
            'cameraId.videoServer.useSSL',
            'cameraId.videoServer.login',
            'cameraId.videoServer.password',
            'cameraId.videoServer.type.sysname',
            'from',
            'to',
            'requestDeadline',
            'reason',
            'permanent',
            'deleteTime',
            'remindDate',
            'fileId',
            'rejectionReason',
            'initiatorId.id',
            'initiatorId.fio',
            'initiatorId.phone',
            'initiatorId.organizationId.name',
            'executorId.id',
            'executorId.fio',
            'executorId.phone',
            'executorId.organizationId.name',
            'lifeCycleStepId.id',
            'lifeCycleStepId.name',
            'lifeCycleStepId.params',
            'lifeCycleStepId.status.sysname',
            'documents',
            'creationAuthor',
          ],
        },
      })
      .pipe(
        map((requests: IAbstractServiceData) => requests.data.items[0]),
        switchMap((request: IAnyObject) => {
          return this.multiFileService.getFilesFromSfs(request.documents).pipe(
            map((documents) => {
              request.documents = documents;
              return request;
            }),
          );
        }),
        switchMap((request: IAnyObject) => {
          data.request = <IInformationRequestData>{};
          data.request.id = request['id'];
          data.request.number = request['number'];
          data.request.createTime = request['createTime'];
          data.request.updateTime = request['updateTime'];
          // Необходимо вынести id из объекта камеры для возможности инициализации контрола. Контрол должен называться
          // именно cameraId для возможности его блокировки через параметры ЖЦ.
          data.request.cameraId = request['cameraId.id'];
          data.request.from = request['from'];
          data.request.to = request['to'];
          data.request.requestDeadline = request['requestDeadline'];
          data.request.reason = request['reason'];
          data.request.permanent = request['permanent'];
          data.request.deleteTime = request['deleteTime'];
          data.request.remindDate = request['remindDate'];
          data.request.fileId = request['fileId'];
          data.request.rejectionReason = request['rejectionReason'];
          data.request.initiator = {
            id: request['initiatorId.id'],
            fio: request['initiatorId.fio'],
            phone: request['initiatorId.phone'],
            organization: request['initiatorId.organizationId.name'],
          };
          data.request.executor = {
            id: request['executorId.id'],
            fio: request['executorId.fio'],
            phone: request['executorId.phone'],
            organization: request['executorId.organizationId.name'],
          };
          data.request.lifeCycleStep = {
            id: request['lifeCycleStepId.id'],
            name: request['lifeCycleStepId.name'],
            sysname: request['lifeCycleStepId.status.sysname'],
            params: request['lifeCycleStepId.params'] || [],
          };
          data.request.videoOptions = {
            cameraExtId: request['cameraId.extId'],
            cameraMediaUrl: request['cameraId.mediaUrl'],
            cameraToken: request['cameraId.token'],
            videoServerIP: request['cameraId.videoServer.ip'],
            videoServerPort: request['cameraId.videoServer.port'],
            videoServerType: request['cameraId.videoServer.type.sysname'],
            videoServerUseSSL: request['cameraId.videoServer.useSSL'],
            videoServerLogin: request['cameraId.videoServer.login'],
            videoServerPassword: request['cameraId.videoServer.password'],
          };
          data.request.documents = request['documents'];
          data.request.creationAuthor = request['creationAuthor'];
          return forkJoin([
            this.emergencyService.getLifeCycleButtonsById(data.request.lifeCycleStep.id),
            this.getArchive({ entityId: data.request.id }),
          ]);
        }),
        switchMap((result: [ILifeCycleStepActionDto[], IAbstractServiceData]) => {
          data.actions = result[0];

          data.request.video = {};
          data.request.video.sfsId = (result[1].data.items[0]?.video || [])[0];
          data.request.video.errors = result[1].data.items[0]?.errors;
          data.request.video.status = result[1].data.items[0]?.status;
          data.request.video.isExpired = result[1].data.items[0]?.isExpired;
          data.request.video.isActive = result[1].data.items[0]?.isActive;
          data.request.video.expirationDt = result[1].data.items[0]?.expirationDt;

          // Если найденная запись неактивная, то видео было удалено из хранилища. Удалим информацию о видео.
          if (!result[1].data.items[0]?.isActive) {
            data.request.video.sfsId = null;
          }

          if ((!!data.request.executor.id && !data.request.rejectionReason) || needVideo) {
            return this.videoDevicesService.getVideoDeviceRangeStreamUrl(
              data.request.cameraId as string,
              data.request.from as number,
              1000,
            );
          }
          return of('');
        }),
        map((url: string) => {
          data.request.videoUrl = url;
          return data;
        }),
      );
  }

  /**
   * Получить архив по ID запроса или задачи на архивирование
   * @param query значения по которым ищется задача на архивирование.
   * id -ID задачи на архивирование. entityId - ID запроса
   */
  public getArchive(query: { id?: string; entityId?: string }): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Archive' },
      entity: {
        name: 'ArchiveTasks',
        query: query.id ? { id: query.id } : { entityId: query.entityId },
        // Так как возможна ситуация с наличием нескольких ошибок по одному entityId (т.е. ID запроса на получение)
        // информации, отсортируем результат выборки по убыванию даты создания задачи на архивирование и возьмем первое
        // значение.
        sort: {
          field: 'createdAt',
          direction: 'desc',
        },
      },
    });
  }

  /**
   * Функция подготовки скачивания видео
   * @param request информация о запросе
   */
  public prepareVideo(request: IInformationRequestData): Observable<IAbstractServiceData> {
    this.note.pushInfo('Получение видео из архива видеосервера запущено');

    let videoOptions = undefined;

    switch (request.videoOptions.videoServerType) {
      case 'forestGuard':
        videoOptions = {
          method: 'get',
          url: request.videoOptions.cameraMediaUrl
            .replace('/embed.html?proto=dash', '')
            .concat(`/archive-${+request.from / 1000}-${(+request.to - +request.from) / 1000}.mp4`),
          destinationFileName: `${request.number}.mp4`,
        }
        break;
      case 'flussonic':
        videoOptions = {
          method: 'get',
          url: `${request.videoOptions.videoServerUseSSL ? 'https' : 'http'}://${
            request.videoOptions.videoServerIP
          }:${request.videoOptions.videoServerPort}/${request.videoOptions.cameraExtId}/archive-${
            +request.from / 1000
          }-${(+request.to - +request.from) / 1000}.mp4?token=${request.videoOptions.cameraToken}`,
          destinationFileName: `${request.number}.mp4`,
        };
        break;
      case 'macroscop':
        videoOptions = {
          ip: request.videoOptions.videoServerIP,
          port: request.videoOptions.videoServerPort,
          login: request.videoOptions.videoServerLogin,
          password: request.videoOptions.videoServerPassword,
          channelid: request.videoOptions.cameraExtId,
          fromtime: moment(request.from).utcOffset(0, false).format('DD.MM.YYYY HH:mm:ss'),
          totime: moment(request.to).utcOffset(0, false).format('DD.MM.YYYY HH:mm:ss'),
          sound: 'on',
          expectedContentType: 'application/octet-stream',
          fileName: `${request.number}.mp4`,
        };
        break;
    }
    return this.rest.serviceRequest({
      action: 'createArchive',
      service: { name: 'Archive' },
      data: {
        service: 'Emergency',
        entity: 'Requests',
        entityId: request.id,
        video: [videoOptions],
        photo: [],
        expirationTimestamp: request.deleteTime || moment().utcOffset(0, false).unix() * 1000 + ONE_DAY,
        timeout: ONE_HOUR / 2,
      },
    });
  }

  /**
   * Функция удаления видео из архива
   * @param requestId ID запроса
   */
  public removeArchive(requestId: string): Observable<IAbstractServiceData> {
    return this.getArchive({ entityId: requestId }).pipe(
      switchMap((archiveTask) =>
        this.rest.serviceRequest({
          action: 'removeArchive',
          service: { name: 'Archive' },
          data: {
            id: (archiveTask.data.items || [])[0]?.id,
          },
        }),
      ),
    );
  }

  /**
   * Функция изменения срока хранения видео
   * @param requestId ID запроса
   * @param deleteTime обновленное время хранения архива
   */
  public updateArchive(requestId: string, deleteTime: number): Observable<IAbstractServiceData> {
    return this.getArchive({ entityId: requestId }).pipe(
      switchMap((archiveTask) =>
        this.rest.serviceRequest({
          action: 'updateArchive',
          service: { name: 'Archive' },
          data: {
            id: (archiveTask.data.items || [])[0]?.id,
            expirationTimestamp: deleteTime,
          },
        }),
      ),
    );
  }

  /**
   * Функция скачивания видео
   * @param sfsId UUID файла в SFS хранилище
   */
  public downLoadVideo(sfsId: string) {
    this.note.pushInfo('Скачивание видео запущено');
    this.sfs.directDownload(sfsId);
  }
}
