import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppInjector } from './app-injector';
import { IAnyObject } from 'smart-city-types';
import { ComponentType } from '@angular/cdk/portal';
import { TemplateRef } from '@angular/core';

/**
 * Общий класс для формы
 */
export class FormModal {
  private dialogComponent: ComponentType<IAnyObject> | TemplateRef<IAnyObject>;
  private config: MatDialogConfig;
  public readonly dialog: MatDialog;

  constructor(component: ComponentType<IAnyObject> | TemplateRef<IAnyObject>,
              dialogOptions: MatDialogConfig,
              dataOptions: IAnyObject) {
    this.dialogComponent = component;
    this.config = {
      ...dialogOptions,
      data: {
        ...dataOptions,
      },
    };
    const injector = AppInjector.getInjector();
    this.dialog = injector.get(MatDialog);
  }

  public open() {
    return this.dialog.open(this.dialogComponent, this.config);
  }

}
