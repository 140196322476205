import { Injectable } from '@angular/core';
import { ILimit, ISort, RestService } from '@smart-city/core/services';
import { ScConsole } from '@smart-city/core/utils';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';

import { IKseonDevice, IKseonServerDto, IKseonEventDto } from '../../models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class KseonUnitsService {
  constructor(private readonly rest: RestService, private readonly noteService: NzNotificationService) {}

  protected catchErrorFn<T>(
    err: Error,
    content: string = 'Ошибка при запросе',
    defaultValue: T = undefined,
  ): Observable<T> {
    this.noteService.error('Ошибка', content);
    ScConsole.error([err.message]);
    return of(defaultValue);
  }

  /** TODO написать интерфейс, избавиться от any
   * Получить сервер мониторинга с требуемыми полями.
   * @param query фильтр для запроса
   * @param sort сортировка записей запроса
   * @param limit ограничения запрашиваемых данных
   */
  public getKseonUnit(query?: IAnyObject, limit?: ILimit, sort?: ISort): Observable<any> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Ksion' },
        entity: {
          query,
          name: 'Units',
          attributes: [
            'extId',
            'name',
            'address',
            'server',
            'coordinates',
            'currentSeance.id',
            'currentSeance.name',
            'type.name',
            'syncTime',
          ],
        },
        data: {
          limit,
          sort,
        },
      })
      .pipe(
        map((unit) => {
          return unit?.data?.items?.map((item: IAnyObject) => ({
            id: item.id,
            name: item.name,
            extId: item.extId,
            address: item.address,
            coordinates: item.coordinates,
            server: item.server,
            currentSeanceId: item.currentSeance?.id,
            currentSeanceName: item.currentSeance?.name,
            type: item.type?.name,
            syncTime: item.syncTime,
          }));
        }),
      );
  }

  /** Получить сервер сервера мониторинга с требуемыми полями.
   * @param query фильтр для запроса
   * @param sort сортировка записей запроса
   * @param limit ограничения запрашиваемых данных
   */
  public getKseonServer(query?: IAnyObject, limit?: ILimit, sort?: ISort): Observable<IKseonServerDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Ksion' },
        entity: {
          query,
          name: 'Servers',
          attributes: ['name'],
        },
        data: {
          limit,
          sort,
        },
      })
      .pipe(
        map((response: IAbstractServiceData): IKseonServerDto => (response?.data?.items || [])[0] as IKseonServerDto),
      );
  }

  /**
   * Получить оборудование (определенный тип) сервера.
   * @param options объект с опциями для запроса
   */
  public getUnitEquipments(
    query: IAnyObject,
    pageIndex: number,
    pageSize: number,
    sort?: ISort,
  ): Observable<IAbstractServiceData> {
    let limit: ILimit = undefined;
    if (pageIndex || pageSize) {
      limit = {
        paNumber: pageIndex ?? 1,
        paSize: pageSize ?? 15,
      };
    }

    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Ksion' },
      entity: {
        name: 'Aggregated',
        attributes: ['id', 'categoryId.name', 'state.name', 'type.name', 'description'],
        query,
      },
      data: {
        isNeedTotal: true,
        limit,
        sort,
      },
    });
  }

  /** Получение данных устройства по id */
  public getDeviceData(id: string): Observable<IKseonDevice> {
    return this.rest
      .serviceRequest({
        action: 'getDeviceData',
        service: {
          name: 'Ksion',
        },
        data: {
          id,
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => response?.data || []),
        catchError((error: Error) => this.catchErrorFn<IKseonDevice>(error, 'Ошибка при запросе устройства КСЭОН')),
      );
  }

  /** Получение шагов ЖЦ с незаконченным состоянием */
  public getNoEndOfCycleSteps(): Observable<any> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'LifeCycleStep',
          attributes: ['id', 'name'],
          query: { endOfCycle: false },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => response?.data || []),
        catchError((error: Error) => this.catchErrorFn<any>(error, 'Ошибка при запросе шагов ЖЦ')),
      );
  }

  /**
   * Получить событие интеграции Ksion
   * @param eventId - id события
   * @param attributes - аттрибуты запроса
   */
  public getKsionEvent(eventId: string, attributes?: string[]): Observable<IKseonEventDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Ksion' },
        entity: {
          attributes: attributes || [],
          name: 'IntegrationEvents',
          query: {
            eventId,
          },
        },
      })
      .pipe(
        map((res: IAbstractServiceData): IKseonEventDto => {
          return (res.data.items[0] || {}) as IKseonEventDto;
        }),
      );
  }
}
