import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@bg-front/core/components';
import { Settings2Service } from '@smart-city/core/services';
import { RestService, AccessService } from '@smart-city/core/services';
import { map, takeUntil } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IReportEmergencyDto } from 'smart-city-types';
import { EmergencyReportsDialogResultEnum } from '../../models/enums';
import { EditReportsDialogComponent } from '../edit-reports-dialog/edit-reports-dialog.component';

/**
 * Копмпонента реализует диалоговое окно с выбором формирования отчета
 */
@Component({
  selector: 'bg-incident-reports-dialog',
  templateUrl: './incident-reports-dialog.component.html',
  styleUrls: ['./incident-reports-dialog.component.scss'],
})
export class IncidentReportsDialogComponent extends BaseComponent implements OnInit {
  public displayedColumns: string[] = ['name', 'actions'];
  public reports = [];

  @Output()
  public downloadEvent = new EventEmitter<[EmergencyReportsDialogResultEnum, any]>(true);

  /** Событие скачивания донесения из кеша (сохраненного в базу донесения) */
  @Output()
  public downloadCashEvent = new EventEmitter<IReportEmergencyDto>(true);

  constructor(
    public readonly dialogRef: MatDialogRef<IncidentReportsDialogComponent>,
    private readonly settings: Settings2Service,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialog: MatDialog,
    private readonly rest: RestService,
    private readonly accessService: AccessService,
  ) {
    super();

    this.reports.push({
      name: 'Отчет по форме инцидента',
      actions: 'eddsCallEdds112',
      editable: false,
      download: true,
    });
    if (this.accessService.accessMap['formReportEmergency']?.visible) {
      this.reports = this.reports.concat([
        {
          name: 'Донесение по форме 1/ЧС',
          actions: 'reportEmergency',
          sysname: 'chs1',
          subtitle: 'Доступные отчеты/редактирование',
          editable: true,
          download: false,
        },
        {
          name: 'Донесение по форме 2/ЧС',
          actions: 'reportEmergency',
          sysname: 'chs2',
          subtitle: 'Доступные отчеты/редактирование',
          editable: true,
          download: false,
        },
        {
          name: 'Донесение по форме 3/ЧС',
          actions: 'reportEmergency',
          sysname: 'chs3',
          subtitle: 'Доступные отчеты/редактирование',
          editable: true,
          download: false,
        },
        {
          name: 'Донесение по форме 4/ЧС',
          actions: 'reportEmergency',
          sysname: 'chs4',
          subtitle: 'Доступные отчеты/редактирование',
          editable: true,
          download: false,
        },
        {
          name: 'Донесение по форме 5/ЧС',
          actions: 'reportEmergency',
          sysname: 'chs5',
          subtitle: 'Доступные отчеты/редактирование',
          editable: true,
          download: false,
        },
      ]);
    }
    if (this.accessService.accessMap['tpiTppReadinessInfoReport']?.visible) {
      this.reports.push({
        name: 'Сведения о готовности ЛПУ и ПВР',
        actions: 'lpuPvrReport',
        sysname: 'lpuPvrReport',
        subtitle: 'Доступные отчеты/редактирование',
        editable: true,
        download: false,
      })
    }
    if (this.accessService.accessMap['hospitalizedListReport']?.visible) {
      this.reports.push({
        name: 'Список госпитализированных',
        actions: 'reportVictims',
        sysname: 'hospitalized',
        subtitle: 'Доступные отчеты/редактирование',
        editable: false,
        download: true,
      })
    }
    if (this.accessService.accessMap['deadListReport']?.visible) {
      this.reports.push({
        name: 'Список погибших',
        actions: 'reportVictims',
        sysname: 'death',
        subtitle: 'Доступные отчеты/редактирование',
        editable: false,
        download: true,
      })
    }
    if (this.accessService.accessMap['evacuatedListReport']?.visible) {
      this.reports.push({
        name: 'Список эвакуированных',
        actions: 'reportVictims',
        sysname: 'evacuated',
        subtitle: 'Доступные отчеты/редактирование',
        editable: false,
        download: true,
      })
    }
    if (this.accessService.accessMap['injuredListReport']?.visible) {
      this.reports.push({
        name: 'Список пострадавших',
        actions: 'listOfVictims',
        sysname: 'listOfVictims',
        subtitle: 'Доступные отчеты/редактирование',
        editable: false,
        download: true,
      })
    }
  }

  ngOnInit() {
    this.checkDownloadAbility();
  }

  /** Скачать отчет */
  public download(report): void {
    this.downloadEvent.emit([EmergencyReportsDialogResultEnum[report.actions as string], report]);
  }

  /** Редактировать отчет */
  public edit(report: any): void {
    const dialogRef = this.dialog.open(EditReportsDialogComponent, {
      disableClose: true,
      width: '914px',
      height: '80vh',
      panelClass: 'modal-dialog-gray',
      data: {
        reportSysname: report.sysname,
        emergencyId: this.data.emergencyId,
        subtitle: report.subtitle,
      },
    });

    dialogRef.componentInstance.saveEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.checkDownloadAbility();
    });

    dialogRef.afterClosed().subscribe((value) => {
      if (value === 'crosshair') {
        this.close();
      }
    });
  }

  /** Закрыть это окно */
  public close(): void {
    this.dialogRef.close(EmergencyReportsDialogResultEnum.close);
  }

  /** Проверка какие отчеты доступны к скачиванию. Которые сохранены, те доступны к скачиванию */
  public checkDownloadAbility() {
    this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Report' },
        entity: {
          query: {
            emergencyId: this.data.emergencyId,
          },
          attributes: ['typeId.sysname'],
          name: 'ReportEmergency',
        },
      })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((response: IAbstractServiceData) => {
          return response?.data?.items as IAnyObject[];
        }),
      )
      .subscribe((items) => {
        this.reports.map((report) => {
          for (const item of items) {
            if (item.typeId?.sysname === report.sysname) {
              report.download = true;
              return report;
            }
          }
          return report;
        });
      });
  }
}
