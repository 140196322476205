import { Injectable } from '@angular/core';
import { KsipCategoriesService } from '@bg-front/ksip-categories/services';
import { KsipSectionsService } from '@bg-front/ksip-sections/services';
import { KsipTypesService } from '@bg-front/ksip-types/services';
import { KsipDetailsService } from '@bg-front/ksip-details/services';
import { GAsterService } from '@smart-city/calls/services';
import { FiasService, WsFiasService } from '@smart-city/core/common';
import {
  AdminService,
  BaseService,
  NotificationService,
  RestService,
  Settings2Service,
  SfsService,
  WsRestService,
} from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { SubstratesService } from '@bg-front/core/services';

@Injectable({
  providedIn: 'root',
})
export class AppLoaderService extends BaseService {
  private services: any[];

  constructor(
    private readonly rest: RestService,
    private readonly settings2Service: Settings2Service,
    private readonly admin: AdminService,
    private readonly wsRest: WsRestService,
    private readonly note: NotificationService,
    private readonly fias: FiasService,
    private readonly sfs: SfsService,
    private readonly wsFias: WsFiasService,
    private readonly gasterService: GAsterService,
    private readonly ksipTypesService: KsipTypesService,
    private readonly ksipDetailsService: KsipDetailsService,
    private readonly ksipCategoriesService: KsipCategoriesService,
    private readonly ksipSectionsService: KsipSectionsService,
    private readonly substratesService: SubstratesService,
  ) {
    super();
    // services registers here

    this.fias.setOptions({
      httpNfiasUrl: environment.UrlNFias,
      nfiasProtocol: environment.nfias,
      region: environment.region,
    });
    this.rest.setOptions({
      httpPrefix: environment.httpRestUrlPrefix,
      debug: true,
    });
    this.wsRest.setOptions({
      url: environment.wsRestUrl,
    });
    this.gasterService.setOptions({
      localUrl: environment.gasterUrl,
    });

    this.note.setOptions({
      timer: environment.notification.timer,
      infoBufferMax: environment.notification.infoBufferMax,
      errorBufferMax: environment.notification.errorBufferMax,
      infoStackLength: environment.notification.infoStackLength,
      errorStackLength: environment.notification.errorStackLength,
      stackPosition: environment.notification.stackPosition,
    });
    this.services = [this.settings2Service, this.admin, this.sfs];
  }

  invokePromise(method: string): Promise<boolean>[] {
    const methods = this.services
      .filter((service: any) => typeof service[method] === 'function')
      .map((service: any) => service[method].call(service).toPromise());

    methods.push(this.ksipTypesService.initStore().toPromise());
    methods.push(this.ksipDetailsService.initStore().toPromise());
    methods.push(this.ksipCategoriesService.initStore().toPromise());
    methods.push(this.ksipSectionsService.initStore().toPromise());
    methods.push(this.substratesService.initStore().toPromise());
    return methods;
  }

  load() {
    // сокет для rest запросов
    return this.wsRest
      .connect()
      .toPromise()
      .then(() => {
        return this.admin
          .auth()
          .toPromise()
          .then((isAuth) => {
            if (isAuth) {
              // Запускаем обновление токена
              this.admin.updateToken();
              // this.subscriberService.listen();
              // Устанавливаем флаг авторизации
              this.admin.setLoggedIn(isAuth);
              return (
                Promise.all(this.invokePromise('load'))
                  // Инициализация URL для WsFiasService
                  .then(() => {
                    /** Если локальная сборка и конфигурация с относительныым путём, используем ссылку из окружения,
                     * иначе - конфиг
                     */
                    let url = this.settings2Service.getConfig().nFiasUrl as string;
                    url = window.location.hostname === 'localhost' && url.startsWith('/') ? environment.UrlNFias : url;

                    this.wsFias.setOptions({ url });
                  })
                  // Поднимаем сокет до гастера
                  .then(() => this.gasterService.afterLoad())
                  // Коннект WsFiasService
                  .then(() => this.wsFias.connect().pipe(takeUntil(this.ngUnsubscribe)).subscribe())
                  // Инициализация URL для SfsService
                  .then(() => this.sfs.setOptions({ url: this.settings2Service.getConfig().sfsUrl }))
                  .then(() => Promise.resolve(true))
              );
            }
          });
      })
      .catch((error: Error) => {
        this.note.pushError(error.message);
      });
  }

  isLogged(): Observable<any> {
    let result = false;
    return this.admin
      .auth()
      .pipe(
        tap(
          () => {
            result = true;
          },
          () => {
            result = false;
          },
        ),
      )
      .pipe(map(() => result));
  }
}
