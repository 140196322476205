<ng-container [formGroup]="form" *ngIf="form" errorTailor>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzRequired>Тип АХОВ</nz-form-label>
        <nz-form-control>
          <nz-select
            #chemicalType
            nzShowSearch
            nzAllowClear
            formControlName="chemicalType"
            nzPlaceHolder="Выберите тип АХОВ"
            [compareWith]="compareFn"
          >
            <nz-option [nzValue]="item" [nzLabel]="item.name" *ngFor="let item of chemicalTypeForSelect$ | async">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label>Агрегатное состояние</nz-form-label>
        <nz-form-control>
          <input nz-input disabled [value]="chemicalType.listOfValue[0]?.aggregationStateName" />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <ng-container *ngIf="chemicalType.listOfValue[0]?.aggregationStateSysName === 'gas'">
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label>Количество АХОВ (в т.)</nz-form-label>
          <nz-form-control>
            <input nz-input formControlName="chemicalAmount" mask="0*.0*" [dropSpecialCharacters]="false" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label>Давление в ёмкости (в атм.)</nz-form-label>
          <nz-form-control>
            <input nz-input formControlName="containerPressure" mask="0*.0*" [dropSpecialCharacters]="false" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="chemicalType.listOfValue[0]?.aggregationStateSysName === 'liquid'">
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label>Количество АХОВ (в т.)</nz-form-label>
          <nz-form-control>
            <input nz-input formControlName="chemicalAmount" mask="0*.0*" [dropSpecialCharacters]="false" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label>Тип ёмкости</nz-form-label>
          <nz-form-control>
            <nz-select nzShowSearch nzAllowClear formControlName="containerType" nzPlaceHolder="Выберите тип ёмкости">
              <nz-option [nzValue]="item.id" [nzLabel]="item.text" *ngFor="let item of containerTypeForSelect">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label>Высота поддона/обваловки (в м.)</nz-form-label>
          <nz-form-control>
            <input nz-input formControlName="containerHeight" mask="0*.0*" [dropSpecialCharacters]="false" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </ng-container>
</ng-container>
