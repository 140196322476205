import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import { DialogService, IScCheckboxOptions, IScInputOptions, IScTextareaOptions } from '@smart-city/core/common';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { MapBaseService } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, takeUntil } from 'rxjs/operators';
import {
  IAnyObject,
  IEmergencyEventDto,
  IEmergencyResolutionDto,
  IKsipTypeDto,
  ILifeCycleStepDto,
} from 'smart-city-types';
import { Coordinates } from '@bg-front/core/models/classes';
import { VideoEmergencyTypeEnum } from '../../models/enums';
import { IIncidentEditForm, IMiniMapMarkerPositionEvent } from '../../models/interfaces';
import { IVideoDeviceBg } from '../../models/interfaces';
import { BgAdminService, VideoDevicesService } from '../../services';
import { BaseIncidentFormComponent } from '../base-incident-form/base-incident-form.component';
import { AccessService, IAccessAction, RestService, Settings2Service } from '@smart-city/core/services';
import { BgMapService, MultiFileService } from '@bg-front/core/services';
import { IEmergencyVaDetailData } from '@bg-front/core/models/interfaces';
import {
  AtmIntegrationService,
  EmergencyService,
  MiniMapService,
  OrganizationsService,
  ResponsePlanStepService,
} from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { ReportingService } from '../../../bg/modules/reporting/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';

@Component({
  selector: 'bg-video-incident-edit-form',
  templateUrl: './video-incident-edit-form.component.html',
  styleUrls: ['./video-incident-edit-form.component.scss'],
})
/**
 * Компонента реализует возможность просмотра и редактирования инцидента
 */
export class VideoIncidentEditFormComponent
  extends BaseIncidentFormComponent
  implements IIncidentEditForm, OnInit, AfterViewInit {
  //#region Настройки компонент

  /**
   * Настройка компоненты Доп информация о месте КСиП
   */
  public placeDescriptionOptions: IScTextareaOptions = {
    label: 'Дополнительная информация о месте КСиП',
    maxLength: 1000,
    disabled: true,
  };

  /**
   * Настройка компоненты Комментарий
   */
  public optionsComment: IScTextareaOptions = {
    label: 'Комментарий',
    maxLength: 1000,
  };

  /**
   * Настройка компоненты Описание
   */
  public optionsDescription: IScTextareaOptions = {
    label: 'Описание',
    maxLength: 1000,
  };

  /**
   * Настройка компоненты Фактического адреса
   */
  public coordinatesOption: IScInputOptions = {
    label: 'Координаты фактического адреса',
    maxLength: 100,
  };

  /**
   * Настройка компоненты Угроза населению
   */
  public optionsThreatPopulation: IScCheckboxOptions = {
    title: 'Угроза населению',
  };

  /**
   * Настройка компоненты Угроза организации
   */
  public optionsThreatOrganization: IScCheckboxOptions = {
    title: 'Угроза организации',
  };

  /**
   * Настройка компоненты Отчёт о выполнении инцидента
   */
  public reportOptions: IScTextareaOptions = {
    label: 'Отчёт о выполнении инцидента',
    maxLength: 1000,
  };
  /** Настройки для координат маркера на миникарте */
  public mapMarkerCoordOptions: IScInputOptions = {
    hidden: true,
  };
  //#endregion

  /**
   * Данные о КСиП
   */
  public ksipType$: Observable<IKsipTypeDto>;

  /**
   * Данные об видеоинциденте
   */
  public vaDetail: IEmergencyVaDetailData = undefined;
  /**
   * Данные о камере
   */
  public firstCamera: IVideoDeviceBg = undefined;
  /**
   * Координаты по умолчанию для мини карты
   */
  public defaultMarkerCoordinate: [number, number];
  /** Доступность просмотра материалов */
  public canShowPhotoAndVideo: IAccessAction = { visible: false, enabled: false, name: 'CanPhotoAndVideo' };
  /** Доступность скачивания материалов */
  public canPreparePhotoAndVideo: IAccessAction = { visible: false, enabled: false, name: 'CanPreparePhotoAndVideo' };
  /**
   * Справочник видеоаналитики
   */
  private vaTypesDict: IDictionaryInfo[];
  /** Флаг, является ли источник Видеоаналитикой */
  public isVaSource: boolean;

  /**
   * @ignore
   */
  constructor(
    private readonly gisService: BgMapService,
    private readonly mapService: MapBaseService,
    private readonly videoDeviceService: VideoDevicesService,
    accessService: AccessService,
    atmIntegrationService: AtmIntegrationService,
    bgAdminService: BgAdminService,
    dialog: MatDialog,
    dialogService: DialogService,
    emergencyService: EmergencyService,
    fb: FormBuilder,
    ksipCategoriesQuery: KsipCategoriesQuery,
    ksipDetailsQuery: KsipDetailsQuery,
    ksipSectionsQuery: KsipSectionsQuery,
    ksipTypesQuery: KsipTypesQuery,
    miniMapService: MiniMapService,
    multiFileService: MultiFileService,
    organizationsService: OrganizationsService,
    reportingService: ReportingService,
    route: ActivatedRoute,
    router: Router,
    settings: Settings2Service,
    significantObjectService: SignificantObjectsService,
    responsePlanStepService: ResponsePlanStepService,
  ) {
    super(
      accessService,
      atmIntegrationService,
      bgAdminService,
      dialog,
      dialogService,
      emergencyService,
      fb,
      ksipCategoriesQuery,
      ksipDetailsQuery,
      ksipSectionsQuery,
      ksipTypesQuery,
      miniMapService,
      multiFileService,
      organizationsService,
      reportingService,
      route,
      router,
      settings,
      significantObjectService,
      responsePlanStepService,
    );
  }

  /**
   * @ignore
   */
  ngOnInit() {
    this.incidentForm = new FormGroup({
      comment: new FormControl(this.model.comment),
      description: new FormControl(this.model.description),
      responsibleUser: new FormControl(this.model.responsible || this.settings.currentUser.id, [Validators.required]),
      coordinates: new FormControl({ value: this.model.coordinates, disabled: this.isGrzRepeat() }),
      ksipPlaceDescription: new FormControl({ value: this.model.ksipPlaceDescription, disabled: this.isGrzRepeat() }),
      report: new FormControl(this.model.resolution?.report),
      danger: new FormControl(this.model.resolution?.danger),
      dangerOrg: new FormControl(this.model.resolution?.dangerOrg),
      mapMarkerCoordinate: new FormControl(this.model.mapMarkerCoordinate),
      regime: new FormControl(this.model.regimeId),
      documents: new FormControl(this.model.documents),
    });

    super.ngOnInit();

    this.vaTypesDict = this.settings.getDictObjsIdsByType('monitoringSubject');

    this.vaDetail = (this.model.parentEventId as IEmergencyEventDto).vaDetail as IEmergencyVaDetailData;

    // this.cameraId = this.vaDetail.camera1Id;
    // this.customPeriod = { fromtime: this.vaDetail.camera1Time, totime: +this.vaDetail.camera1Time + ONE_MINUTE };

    // if (this.disabled) {
    //   this.incidentForm.disable();
    // }

    /**
     * Для распознанных ГРЗ загружаем данные о первой камере
     */
    if (this.isGrzRepeat()) {
      this.loadCamera();
    }

    this.incidentForm.controls['coordinates'].valueChanges
      .pipe(debounceTime(1500), takeUntil(this.ngUnsubscribe))
      .subscribe((val: string) => {
        this.redrawEventMarker(val);
      });

    this.ksipType$ = of(this.ksipTypesQuery.getById(this.model.incidentTypeId));

    const markerCoordinates = new Coordinates(this.model.mapMarkerCoordinate || this.model.coordinates);
    if (markerCoordinates.isValid()) {
      this.defaultMarkerCoordinate = markerCoordinates.toArray();
    }

    this.initReactPlan();

    this.ngMasterValue = new BehaviorSubject<IAnyObject>(this.incidentForm.getRawValue());
    this.isDirty$ = dirtyCheck(this.incidentForm, this.ngMasterValue.asObservable());

    this.updateForm();
  }

  /**
   * Установка маркера
   * @param miniMapEvent событие
   */
  public updateCoordinates(miniMapEvent: IMiniMapMarkerPositionEvent) {
    const markerCoordinates = new Coordinates(this.incidentForm.controls['mapMarkerCoordinate'].value);
    const newMarkerCoordinates = new Coordinates(miniMapEvent?.coordinates[0], miniMapEvent?.coordinates[1]);
    if (!markerCoordinates.equal(newMarkerCoordinates)) {
      this.incidentForm.controls['mapMarkerCoordinate'].setValue(newMarkerCoordinates.toString());
      this.redrawEventMarker(this.incidentForm.controls['mapMarkerCoordinate'].value);
      this.model.mapMarkerCoordinate = this.incidentForm.controls['mapMarkerCoordinate'].value;
    }
  }

  /**
   * @ignore
   */
  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Получение данных из формы и сохранение в модель
   */
  public getModelData() {
    if (!this.model.id) {
      this.model.timeCreate = dayjs().unix() * 1000;
      this.model.number = `i-${dayjs(this.model.timeCreate).format('YYYYMMDD-HHmmss')}`;
      this.model.organization = this.settings.currentUser.organizationId;
      this.model.createdBy = this.settings.currentUser.id;
    }
    this.model.resolution = this.model.resolution ? this.model.resolution : <IEmergencyResolutionDto>{};

    this.model.comment = this.incidentForm.controls['comment'].value;
    this.model.description = this.incidentForm.controls['description'].value;
    this.model.responsible = this.incidentForm.controls['responsibleUser'].value;
    this.model.coordinates = new Coordinates(this.incidentForm.controls['coordinates'].value).toString();
    this.model.ksipPlaceDescription = this.incidentForm.controls['ksipPlaceDescription'].value;
    this.model.resolution.report = this.incidentForm.controls['report'].value;
    this.model.resolution.danger = this.incidentForm.controls['danger'].value;
    this.model.resolution.dangerOrg = this.incidentForm.controls['dangerOrg'].value;
    this.model.mapMarkerCoordinate = this.incidentForm.controls['mapMarkerCoordinate'].value;
    if (
      this.model.parentEventId &&
      typeof this.model.parentEventId === 'object' &&
      this.incidentForm.controls.reasonFormGroup
    ) {
      ((this.model.parentEventId as IEmergencyEventDto).vaDetail as IEmergencyVaDetailData).reasonId = (this.incidentForm
        .controls.reasonFormGroup as FormGroup).controls.reasonId.value;
    }
    /// FIXME
    this.model.lifeCycleStepId = (<ILifeCycleStepDto>this.model.lifeCycleStepId).id;
    this.model.documents = this.incidentForm.controls['documents'].value;

    if (
      this.model.parentEventId &&
      typeof this.model.parentEventId === 'object' &&
      this.incidentForm.controls.wantedPersonFormGroup
    ) {
      ((this.model.parentEventId as IEmergencyEventDto).vaDetail as IEmergencyVaDetailData).wantedPersonName =
        (this.incidentForm.controls.wantedPersonFormGroup as FormGroup).controls.wantedPersonName.value;
    }
  }

  /**
   * Проверка типа аналитики - ГРЗ
   */
  public isGrzWanted(): boolean {
    if (this.vaTypesDict && this.vaDetail) {
      return (
        (this.vaTypesDict[this.vaDetail.vaTypeId as string] || {}).sysname?.toString() ===
        VideoEmergencyTypeEnum.grzWanted.toString()
      );
    }

    return false;
  }

  /**
   * Проверка типа аналитики - Дубль ГРЗ
   */
  public isGrzRepeat(): boolean {
    if (this.vaTypesDict && this.vaDetail) {
      return (
        (this.vaTypesDict[this.vaDetail.vaTypeId as string] || {}).sysname?.toString() ===
        VideoEmergencyTypeEnum.grzRepeat.toString()
      );
    }

    return false;
  }

  /**
   * Проверка типа аналитики - Громкий звук
   */
  public isLoudSound(): boolean {
    if (this.vaTypesDict && this.vaDetail) {
      return (
        (this.vaTypesDict[this.vaDetail.vaTypeId as string] || {}).sysname?.toString() ===
        VideoEmergencyTypeEnum.loudSound.toString()
      );
    }

    return false;
  }

  /**
   * Проверка типа аналитики - Саботаж
   */
  public isSabotage(): boolean {
    if (this.vaTypesDict && this.vaDetail) {
      return (
        (this.vaTypesDict[this.vaDetail.vaTypeId as string] || {}).sysname?.toString() ===
        VideoEmergencyTypeEnum.sabotage.toString()
      );
    }

    return false;
  }

  /**
   * Проверка типа аналитики - Оставленные предметы
   */
  public isLeftThing(): boolean {
    if (this.vaTypesDict && this.vaDetail) {
      return (
        (this.vaTypesDict[this.vaDetail.vaTypeId as string] || {}).sysname?.toString() ===
        VideoEmergencyTypeEnum.leftThing.toString()
      );
    }

    return false;
  }

  /**
   * Проверка типа аналитики - Массовое скопление
   */
  public isCrowds(): boolean {
    if (this.vaTypesDict && this.vaDetail) {
      return (
        (this.vaTypesDict[this.vaDetail.vaTypeId as string] || {}).sysname?.toString() ===
        VideoEmergencyTypeEnum.crowds.toString()
      );
    }

    return false;
  }

  /**
   * Проверка типа аналитики - объект в запретной зоне
   */
  public isObjRestrictedArea(): boolean {
    if (this?.vaTypesDict && this?.vaDetail) {
      return (
        (this.vaTypesDict[this.vaDetail?.vaTypeId as string] || {})?.sysname?.toString() ===
        VideoEmergencyTypeEnum.objRestrictedArea.toString()
      );
    }

    return false;
  }

  /**
   * Проверка типа аналитики - Распознавание лиц
   */
  public isFaceRecognition(): boolean {
    if (this.vaTypesDict && this.vaDetail) {
      return (
        (this.vaTypesDict[this.vaDetail.vaTypeId as string] || {}).sysname?.toString() ===
        VideoEmergencyTypeEnum.faceRecognition.toString()
      );
    }
    return false;
  }

  /**
   * Проверяем наличие результатов ВА
   */
  public hasVaDetail() {
    return typeof (this.model.parentEventId as IEmergencyEventDto)?.vaDetail === 'object';
  }

  /**
   * Обновление формы
   */
  public updateForm() {
    /**
     * Обновляем заголовок
     */
    this.generateHeaderBar();

    /**
     * Обновляем доступность контролов
     */
    this.attributes = (this.model.lifeCycleStepId as ILifeCycleStepDto).params;
  }

  /**
   * Загружаем данные о камере
   */
  private loadCamera() {
    if (this.vaDetail && this.vaDetail.camera1Id) {
      this.videoDeviceService
        .getVideoDevice(this.vaDetail.camera1Id as string)
        .pipe(
          catchError((error: Error) => this.catchErrorFn<IVideoDeviceBg>(error, 'Ошибка загрузки данных о камере')),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((device: IVideoDeviceBg) => {
          this.firstCamera = device;
          if (this.firstCamera?.coordinates) {
            this.miniMapService.setMarker(
              Coordinates.coordinatesToArray(this.firstCamera.coordinates),
              'firstCamera',
              'Место первой фиксации',
              'Место первой фиксации',
            );
          }
        });
    }
  }

  /** Получение ссылки для перехода к событию */
  private getWorkspaceURL(): string {
    const id = typeof this.model.parentEventId === 'object' ? this.model.parentEventId['id'] : this.model.parentEventId;
    let targetUrl = `http://${window.location.host}`;
    targetUrl += `/consolidated-registries/event-register/${id}`;
    return targetUrl;
  }

  public openEmergency() {
    const targetUrl = this.getWorkspaceURL();
    window.open(targetUrl);
  }

  /**
   * Метод показа блока дополнительная информация
   * @return
   */
  public showExtendedPanel(): boolean {
    return (
      this.isLoudSound() || this.isSabotage() || this.isLeftThing() || this.isCrowds() || this.isObjRestrictedArea()
      || this.isFaceRecognition()
    );
  }
}
