<div *ngIf="!model" class="center">
  <mat-spinner strokeWidth="5"></mat-spinner>
  <div class="spinner-label">Загрузка...</div>
</div>
<div class="panel-wrapper" *ngIf="model">
  <nw-header [options]="headerActionsOptions" (clickButton)="onClickActionsButton($event)"></nw-header>
  <form [formGroup]="form">
    <div class="content">
      <mat-accordion>
        <mat-expansion-panel [expanded]="'true'">
          <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
            <mat-panel-title>
              <div class="header-container">
                <div>Описание</div>
                <div class="header-container__divider">
                  <hr />
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="bg-row">
            <div class="bg-col-8">
              <div class="bg-form-group">
                <sc-input [options]="monitoringObjectOptions" formControlName="monitoringObject"></sc-input>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="supplyTypeOptions" formControlName="supplyType"></sc-input>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col-8">
              <div class="bg-form-group">
                <sc-input [options]="addressOptions" formControlName="address"></sc-input>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="coordinatesOptions" formControlName="coordinates"></sc-input>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col-4">
              <div class="bg-form-group">
                <sc-input [options]="typeOptions" formControlName="type"></sc-input>
              </div>
            </div>
            <div class="bg-col-4">
              <div class="bg-form-group">
                <sc-input [options]="stateOptions" formControlName="state"></sc-input>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="extIdOptions" formControlName="extId"></sc-input>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col-4">
              <div class="bg-form-group">
                <sc-input [options]="failureExtIdOptions" formControlName="failureExtId"></sc-input>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="descriptionPathOptions" formControlName="descriptionPath"></sc-input>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col-4">
              <div class="bg-form-group">
                <sc-input [options]="latchDtOptions" formControlName="latchDt"></sc-input>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="latchMessageOptions" formControlName="latchMessage"></sc-input>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col-4">
              <div class="bg-form-group">
                <sc-input [options]="unlatchDtOptions" formControlName="unlatchDt"></sc-input>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="unlatchMessageOptions" formControlName="unlatchMessage"></sc-input>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col-8">
              <div class="bg-form-group">
                <sc-input [options]="organizationOptions" formControlName="organization"></sc-input>
              </div>
            </div>
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="integrationTypeOptions" formControlName="integrationType"></sc-input>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel [expanded]="'false'">
          <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
            <mat-panel-title>
              <div class="header-container">
                <div>Сообщения</div>
                <div class="header-container__divider">
                  <hr />
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container formArrayName="messages">
            <div *ngFor="let message of messages">
              <ng-container [formGroup]="message">
                <div class="message-container">
                  <div class="bg-row">
                    <div class="bg-col">
                      <div class="bg-form-group">
                        <sc-input [options]="messageDtOptions" formControlName="messageDt"></sc-input>
                      </div>
                    </div>
                    <div class="bg-col">
                      <div class="bg-form-group">
                        <sc-input [options]="stateOptions" formControlName="state"></sc-input>
                      </div>
                    </div>
                  </div>
                  <div class="bg-row">
                    <div class="bg-col">
                      <sc-input [options]="textOptions" formControlName="text"></sc-input>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </form>
</div>
<router-outlet name="allRegistry"></router-outlet>
<nw-drawer
  [toggleDrawer]="toggleFormOpen"
  [widthDrawer]="'calc(100vw - var(--sidebar-width))'"
  [heightDrawer]="'100vh'"
  [marginDrawer]="'0'"
  style="z-index: 10001"
>
  <router-outlet name="editCallForm" (activate)="toggleFormOpen = true" (deactivate)="toggleFormOpen = false">
  </router-outlet>
</nw-drawer>
