import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HlsPlayerComponent } from './components/hls-player.component';
import { HlsPlayerService } from './services';

@NgModule({
  imports: [CommonModule],
  declarations: [HlsPlayerComponent],
  exports: [HlsPlayerComponent],
  providers: [HlsPlayerService]
})
export class HlsPlayerModule {}
