<div>
  <h4 nz-typography>
    <i nz-icon nzType="field-time" nzTheme="outline"></i>
    Результаты прогноза
  </h4>
  <nz-divider></nz-divider>
  <nz-descriptions [nzTitle]="title" nzBordered nzSize="small" [nzColumn]="1">
    <nz-descriptions-item nzTitle="{{ params.firstSalvoTime }} минут (подача 1-го ствола)"
      >{{ result?.firstSalvoTimeDistance }} м</nz-descriptions-item
    >
    <nz-descriptions-item nzTitle="{{ params.propagationTime }} минут"
      >{{ result?.arbitraryTimeDistance }} м</nz-descriptions-item
    >
    <nz-descriptions-item nzTitle="{{ params.localizationTime }} минут (локализация)"
      >{{ result?.localizationTimeDistance }} м</nz-descriptions-item
    >
  </nz-descriptions>
</div>

<ng-template #title>
  <h5 nz-typography>
    Расстояние, пройденное огнём<br />При линейной скорости {{ params.linearVelocity }} м/мин на момент времени
  </h5>
</ng-template>
