<div class="dialog-container">
  <div class="bg-row">
    <div class="bg-col-10">
      <h4><strong>Подтверждение действия</strong></h4>
    </div>
    <div class="bg-col-2 align-right">
      <button mat-button (click)="closeWithoutReaction()" *ngIf="haveCloseWithoutReaction">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="bg-row content">
    <div class="bg-col">
      <div class="bg-form-group">
        <div *ngIf="isHtml">
          <span [innerHTML]="message | safe: 'html'"></span>
        </div>
        <div *ngIf="!isHtml">
          <span>{{message}}</span>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="reject()">Нет</button>
    <button mat-raised-button color="primary" cdkFocusInitial (click)="confirm()">Да</button>
  </div>
</div>
