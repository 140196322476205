import { Injectable } from '@angular/core';
import { IResponseWithTotal } from '@bg-front/core/models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IModifiedData } from '@smart-city/core/interfaces';
import { ILimit, ISort, RestService, SubscriberService } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';

import { IKsipCategoryDto } from '../../../models/interfaces';
import { KsipCategoriesStore } from '../store/ksip-categories-store.service';

/** Сервис обновления состояния таблицы и хранилища Категорий КСиП */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class KsipCategoriesService {
  private isInit = false;

  constructor(
    private readonly rest: RestService,
    private readonly store: KsipCategoriesStore,
    private readonly subs: SubscriberService,
  ) {}

  /*
   * Загружаем список Категорий типов инцидентов
   */
  public initStore(): Observable<boolean> {
    this.store.setLoading(true);

    if (!this.isInit) {
      this.isInit = true;
      this.subs
        .onTableChange<IKsipCategoryDto>('Admin', 'IncidentCategories')
        .pipe(untilDestroyed(this))
        .subscribe((result: IModifiedData<IKsipCategoryDto>) => {
          this.store.setLoading(true);
          if (result.action !== 'delete') {
            this.store.upsert(result.data.id, (oldState: Partial<IKsipCategoryDto>): IKsipCategoryDto => {
              return <IKsipCategoryDto>{ ...oldState, ...result.data };
            });
          } else {
            this.store.remove((result.data as IAnyObject).ids);
          }
          this.store.setLoading(false);
        });
    }

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'IncidentCategories',
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          this.store.add(response?.data?.items as IKsipCategoryDto[]);
          return true;
        }),
        catchError(() => {
          return of(false);
        }),
        finalize(() => {
          this.store.setLoading(false);
        }),
      );
  }

  /**
   * Удаление записи
   */
  public delete(ids: string | string[]): Observable<boolean> {
    this.store.setLoading(true);

    return this.rest
      .serviceRequest({
        action: 'upsert',
        service: { name: 'Admin' },
        entity: {
          name: 'IncidentTypes',
          query: { incidentCategory: Array.isArray(ids) ? { $in: ids } : ids },
        },
        data: {
          active: false,
          incidentCategory: null,
        },
      })
      .pipe(
        catchError(() => of(undefined)),
        switchMap((response: IAbstractServiceData | undefined) => {
          if (!response) {
            return of(response);
          }
          return this.rest.serviceRequest({
            action: 'delete',
            service: { name: 'Admin' },
            entity: {
              name: 'IncidentCategories',
              query: { id: Array.isArray(ids) ? { $in: ids } : ids },
            },
          });
        }),
        catchError(() => of(undefined)),
        map((value: IAnyObject | undefined) => !!value),
        finalize(() => this.store.setLoading(false)),
      );
  }

  /**
   * Сохранение детализации КСиП
   * @param data - информация о детализации КСиП
   */
  public save(data: IKsipCategoryDto): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      data,
      action: 'upsert',
      service: { name: 'Admin' },
      entity: { name: 'IncidentCategories', query: { id: data.id ? data.id : Uuid.newUuid() } },
    });
  }
  /** Изменение категорий из Разделов */
  public saveFromSections(id: string, active: boolean): Observable<IAbstractServiceData> {
    this.store.setLoading(true);
    const obs = this.rest.serviceRequest({
      action: 'update',
      service: { name: 'Admin' },
      entity: {
        name: 'IncidentCategories',
        query: { sectionId: id },
      },
      data: {
        active: active,
      },
    });
    return obs.pipe(
      map((response: IAbstractServiceData) => {
        this.store.update(({ sectionId }) => sectionId === id, {
          active: active,
        });
        return response;
      }),
      finalize(() => {
        this.store.setLoading(false);
      }),
    );
  }

  /** Получение списка элементов для таблицы */
  public getKsipCategoriesForTable(
    query: IAnyObject,
    pageIndex: number,
    pageSize: number,
    sort?: ISort,
  ): Observable<IResponseWithTotal> {
    let limit: ILimit = undefined;
    if (pageIndex || pageSize) {
      limit = {
        paNumber: pageIndex ?? 1,
        paSize: pageSize ?? 15,
      };
    }
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query,
          name: 'IncidentCategories',
          attributes: ['id', 'name', 'sectionId.name', 'useInternetPortal', 'active'],
        },
        data: {
          isNeedTotal: true,
          limit,
          sort,
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return {
            items: response.data.items || [],
            totalCount: response.data.totalCount ?? 0,
          };
        }),
        catchError(() => {
          return of({
            totalCount: 0,
            items: [],
          });
        }),
      );
  }

  /** Запрашиваем данные для формы редактирования */
  public getById(id: string): Observable<IKsipCategoryDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'IncidentCategories',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || [])[0] as IKsipCategoryDto;
        }),
        catchError(() => {
          return of(<IKsipCategoryDto>{});
        }),
      );
  }
}
