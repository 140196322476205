import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IScCheckboxOptions, IScSelectOptions } from '@smart-city/core/common';
import { Certificate } from 'crypto-pro';
import { map } from 'rxjs/operators';

import { SignatureService } from '../../services/signature/signature.service';

/** Диалоговое окно подписания электронной цифровой подписью */
@UntilDestroy()
@Component({
  selector: 'sign-and-download-dialog',
  templateUrl: './sign-and-download-dialog.component.html',
  styleUrls: ['./sign-and-download-dialog.component.scss'],
})
export class SignAndDownloadDialogComponent {
  /** Форма */
  public form: FormGroup;

  /** Настройка чекбокса с указанием необходимости подписать выгружаемый файл */
  public needSignOptions: IScCheckboxOptions = {
    title: 'Усиленная квалифицированная электронная подпись',
  };

  /** Настройка компоненты Сертификат */
  public certificateOptions: IScSelectOptions = {
    name: 'certificate',
    title: 'Сертификат',
    required: true,
    loadDataFn: () =>
      this.signatureService
        .getUserCertificates()
        .pipe(
          map((result: Certificate[]) =>
            result.map((certificate: Certificate) => ({ id: certificate.thumbprint, text: certificate.name })),
          ),
        ),
  };

  public okButtonTitle = 'Скачать';

  /** @ignore */
  constructor(
    private readonly dialogRef: MatDialogRef<SignAndDownloadDialogComponent>,
    private readonly signatureService: SignatureService,
  ) {}

  /** @ignore */
  public ngOnInit(): void {
    this.form = new FormGroup({
      needSign: new FormControl(undefined),
      certificate: new FormControl({ value: undefined, disabled: true }, Validators.required),
    });

    this.form.controls.needSign.valueChanges.pipe(untilDestroyed(this)).subscribe((needSign: boolean) => {
      if (needSign) this.form.controls.certificate.enable();
      if (!needSign) this.form.controls.certificate.disable();
      this.okButtonTitle = needSign ? 'Скачать и подписать' : 'Скачать';
    });
  }

  /** Отмена */
  public cancel() {
    this.dialogRef.close();
  }

  /** Скачать */
  public download() {
    if (this.form.invalid) return;
    this.dialogRef.close(this.form.controls.certificate.value || '');
  }
}
