import { Injectable } from '@angular/core';
import { RestService } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData } from 'smart-city-types';
import { IPassportOfficeDataDto } from '../../../bg/modules/external-interactions/modules/passport-office-data/interfaces';

/**
 * Сервис для работы с интеграцией паспортного стола
 */
@Injectable({
  providedIn: 'root',
})
export class PassportOfficeIntegrationService {
  constructor(private readonly rest: RestService) {}

  /** Получение данных паспортного стола по координатам */
  public getPassportOfficeByCoordinates(coordinates: number[]): Observable<IPassportOfficeDataDto[]> {
    const coordinatesString: string = coordinates.toString();
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'PassportOfficeIntegration' },
        entity: {
          name: 'LoadData',
          query: {
            coordinates: {
              $eq: coordinatesString,
            },
          },
        },
      })
      .pipe(
        map((result: IAbstractServiceData) => <IPassportOfficeDataDto[]>result.data.items || []),
      );
  };
}
