import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IModifiedData, IScSelectItem } from '@smart-city/core/interfaces';
import { ILimit, ISort, RestService, Settings2Service, SubscriberService } from '@smart-city/core/services';
import { NEVER, Observable, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';

import { applyTransaction } from '@datorama/akita';
import { IVideoDeviceBg } from '../../../../../../app-common/models/interfaces';
import { VideoDevicesList } from '../../../models/classes';
import { IVideoDevicesListDto, IVideoDevisesListLinksDto } from '../../../models/interfaces';
import { VideoDevicesListsStore } from '../store/video-devices-lists-store.service';

@UntilDestroy()
@Injectable()
export class VideoDevicesListService {
  private isInit = false;

  private lastQuery: IAnyObject = undefined;

  constructor(
    private readonly rest: RestService,
    private readonly store: VideoDevicesListsStore,
    private readonly subs: SubscriberService,
    private readonly settings: Settings2Service,
  ) {}

  /** Получение списка */
  public getVideoDevicesListById(id: string): Observable<VideoDevicesList> {
    return forkJoin([
      this.rest
        .serviceRequest(
          {
            action: 'select',
            service: { name: 'Admin' },
            entity: {
              name: 'VideoDevicesLists',
              query: {
                id,
              },
            },
          },
          'http',
        )
        .pipe(
          map((response: IAbstractServiceData) => {
            return response.data.items[0] as IVideoDevicesListDto;
          }),
        ),
      this.rest
        .serviceRequest(
          {
            action: 'select',
            service: { name: 'Admin' },
            entity: {
              name: 'VideoDevicesListCamerasLinks',
              query: {
                videoDevicesListId: id,
              },
            },
          },
          'http',
        )
        .pipe(
          map((response: IAbstractServiceData): IVideoDevisesListLinksDto[] => {
            return response.data.items as IVideoDevisesListLinksDto[];
          }),
        ),
    ]).pipe(
      map((response: [IVideoDevicesListDto, IVideoDevisesListLinksDto[]]) => {
        const model: VideoDevicesList = Object.assign<VideoDevicesList, IVideoDevicesListDto>(
          new VideoDevicesList(),
          response[0],
        ) as VideoDevicesList;
        model.cameras = response[1];
        return model;
      }),
    );
  }

  /** Получение списка устройств */
  public getVideoDevices(query?: IAnyObject): Observable<IVideoDeviceBg[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'VideoDeviceCategoryAccessRights',
          attributes: ['id', 'cameraAccess', 'userId', 'videoDeviceCategoryId'],
          query: {
            cameraAccess: true,
            userId: this.settings.currentUser.id,
          },
        },
      })
      .pipe(
        switchMap((videoDeviceCategories: IAbstractServiceData) => {
          const allowedCategories = videoDeviceCategories.data.items?.map((item) => item.videoDeviceCategoryId) || [];
          return this.rest.serviceRequest(
            {
              action: 'getVideoDevices',
              service: { name: 'Admin' },
              entity: {
                name: 'VideoDevices',
              },
              data: {
                query: {
                  active: true,
                  categories: allowedCategories,
                  // Внимание! Переменная называется неверно. По логике должна называться emptyCategory.
                  // Требует доработки бэка.
                  hasCategory: true,
                  ...query,
                },
              },
            },
            'http',
          );
        }),
        map((response: IAbstractServiceData): IVideoDeviceBg[] => {
          return response.data?.items as IVideoDeviceBg[];
        }),
      );
  }

  /** Получение списка устройств */
  public getVideoDevicesCoordinatesByListId(listId: string): Observable<IVideoDeviceBg[]> {
    return this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: 'Admin' },
          entity: {
            name: 'VideoDevicesListCamerasLinks',
            attributes: ['deviceId', 'deviceId.id', 'deviceId.coordinates', 'deviceId.name'],
            distinct: ['id'],
            query: {
              videoDevicesListId: listId,
              // $not: { 'deviceId.coordinates': null },
              $or: [
                { $expr: { $eq: ['$videoDevicesCategories.categoryId', null] } },
                {
                  $and: [
                    { $expr: { $eq: ['$accessRights.cameraAccess', true] } },
                    { $expr: { $eq: ['$accessRights.userId', this.settings.currentUser.id] } },
                  ],
                },
              ],
            },
          },
          data: {
            videoDevicesCategories: {
              $join: {
                service: 'Admin',
                entity: 'VideoDevicesCategoriesLinks',
                attributes: ['id', 'videoDeviceId', 'categoryId'],
                query: { $expr: { $eq: ['$deviceId', '$videoDevicesCategories.videoDeviceId'] } },
              },
            },
            accessRights: {
              $join: {
                service: 'Admin',
                entity: 'VideoDeviceCategoryAccessRights',
                attributes: ['id', 'cameraAccess', 'userId', 'videoDeviceCategoryId'],
                query: {
                  $expr: { $eq: ['$videoDevicesCategories.categoryId', '$accessRights.videoDeviceCategoryId'] },
                },
              },
            },
          },
        },
        'http',
      )
      .pipe(
        map((response: IAbstractServiceData): IVideoDeviceBg[] => {
          return (response.data?.items as IVideoDeviceBg[]).map((el: IAnyObject) => el['deviceId']);
        }),
      );
  }

  /** Получение списка устройств */
  public getVideoDeviceCategories(): Observable<IScSelectItem[]> {
    return this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: 'Admin' },
          entity: {
            name: 'VideoDeviceCategories',
          },
        },
        'http',
      )
      .pipe(
        map((response: IAbstractServiceData): IScSelectItem[] => {
          return response.data?.items as IScSelectItem[];
        }),
      );
  }

  /** Удаление списка устройств */
  public delete(id: string): Observable<boolean> {
    return forkJoin([
      this.rest.serviceRequest(
        {
          action: 'delete',
          service: { name: 'Admin' },
          entity: {
            name: 'VideoDevicesListCamerasLinks',
            query: {
              videoDevicesListId: id,
            },
          },
        },
        'http',
      ),
      this.rest.serviceRequest(
        {
          action: 'delete',
          service: { name: 'Admin' },
          entity: {
            name: 'VideoDevicesLists',
            query: {
              id,
            },
          },
        },
        'http',
      ),
    ]).pipe(map(() => true));
  }

  /** Сохранение списка устройств */
  public save(model: IVideoDevicesListDto, cameras: IVideoDevisesListLinksDto[]): Observable<IAbstractServiceData> {
    return this.rest
      .serviceRequest(
        {
          action: 'upsert',
          service: { name: 'Admin' },
          entity: {
            name: 'VideoDevicesLists',
            query: {
              id: model.id,
            },
          },
          data: model,
        },
        'http',
      )
      .pipe(
        catchError((err: Error) => {
          console.log('Ошибка сохранения списка');
          return NEVER;
        }),
        switchMap(() => {
          return this.rest.serviceRequest(
            {
              action: 'delete',
              service: { name: 'Admin' },
              entity: {
                name: 'VideoDevicesListCamerasLinks',
                query: {
                  videoDevicesListId: model.id,
                },
              },
            },
            'http',
          );
        }),
        catchError((err: Error) => {
          console.log('Ошибка удаления связей');
          return of(undefined);
        }),
        switchMap(() => {
          return this.rest.serviceRequest(
            {
              action: 'insert',
              service: { name: 'Admin' },
              entity: {
                name: 'VideoDevicesListCamerasLinks',
              },
              data: cameras,
            },
            'http',
          );
        }),
      );
  }

  // TODO: При добавлении не заполняются все необходимые поля (дата и время создания или изменения, автор создания или изменения)
  /** Добавление видеокамеры в списки
   * @params deviceId - id видеокамеры
   * @params lists - массив id списков для добавления
   **/
  public addVideoDeviceToLists(deviceId: string, lists: string[]): Observable<IAbstractServiceData[]> {
    const obs: Observable<IAbstractServiceData>[] = [];
    lists.forEach((listId: string) => {
      obs.push(
        this.rest.serviceRequest({
          action: 'insert',
          service: { name: 'Admin' },
          entity: {
            name: 'VideoDevicesListCamerasLinks',
          },
          data: {
            deviceId,
            videoDevicesListId: listId,
          },
        }),
      );
    });
    return forkJoin(obs);
  }

  /** Запрос списка списков устройств */
  public getVideoDevicesLists(query?: IAnyObject, limit?: ILimit, sort?: ISort): Observable<IVideoDevicesListDto[]> {
    return this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: 'Admin' },
          entity: {
            query,
            name: 'VideoDevicesLists',
          },
          data: {
            limit,
            sort,
          },
        },
        'http',
      )
      .pipe(
        map(
          (response: IAbstractServiceData): IVideoDevicesListDto[] => response?.data?.items as IVideoDevicesListDto[],
        ),
      );
  }

  /** Запрос списка списков устройств */
  public getVideoDevicesForMiniCardByListId(listId: string): Observable<{ id: string; name: string }[]> {
    return this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: 'Admin' },
          entity: {
            name: 'VideoDevicesListCamerasLinks',
            attributes: ['id', 'deviceId', 'deviceId.id', 'deviceId.name'],
            distinct: ['id'],
            query: {
              videoDevicesListId: listId,
              $or: [
                { $expr: { $eq: ['$videoDevicesCategories.categoryId', null] } },
                {
                  $and: [
                    { $expr: { $eq: ['$accessRights.cameraAccess', true] } },
                    { $expr: { $eq: ['$accessRights.userId', this.settings.currentUser.id] } },
                  ],
                },
              ],
            },
          },
          data: {
            videoDevicesCategories: {
              $join: {
                service: 'Admin',
                entity: 'VideoDevicesCategoriesLinks',
                attributes: ['id', 'videoDeviceId', 'categoryId'],
                query: { $expr: { $eq: ['$deviceId', '$videoDevicesCategories.videoDeviceId'] } },
              },
            },
            accessRights: {
              $join: {
                service: 'Admin',
                entity: 'VideoDeviceCategoryAccessRights',
                attributes: ['id', 'cameraAccess', 'userId', 'videoDeviceCategoryId'],
                query: {
                  $expr: { $eq: ['$videoDevicesCategories.categoryId', '$accessRights.videoDeviceCategoryId'] },
                },
              },
            },
          },
        },
        'http',
      )
      .pipe(
        map((response: IAbstractServiceData): { id: string; name: string }[] =>
          ((response?.data?.items as IAnyObject[]) || []).map((el: IAnyObject) => ({
            id: el['deviceId.id'],
            name: el['deviceId.name'],
          })),
        ),
      );
  }

  /**
   * Инициализируем список инцидентов
   */
  public initStore(query?: IAnyObject, limit?: ILimit, sort?: ISort): void {
    this.store.setLoading(true);

    if (!this.isInit) {
      this.isInit = true;
      const videoDevicesListIds: Set<string> = new Set<string>();
      this.subs
        .onTableChange<IVideoDevicesListDto>('Admin', 'VideoDevicesLists')
        .pipe(
          filter(
            (result: IModifiedData<IVideoDevicesListDto>) =>
              result.data.userId === this.settings.currentUser.id || result.action === 'delete',
          ),
          tap(
            (
              response: IModifiedData<{
                id?: string | Record<string, unknown>;
                ids?: string[];
              }>,
            ) => {
              const id: string = response.data['id'] as string;
              if (id) {
                videoDevicesListIds.add(id);
              } else {
                if (response.data.ids?.length) {
                  response.data.ids.forEach((id: string) => {
                    videoDevicesListIds.add(id);
                  });
                }
              }
            },
          ),
          debounceTime(1000),
          untilDestroyed(this),
        )
        .subscribe(() => {
          const updateIds = Array.from(videoDevicesListIds);
          videoDevicesListIds.clear();
          this.getVideoDevicesLists(query)
            .pipe(untilDestroyed(this))
            .subscribe((elements: IVideoDevicesListDto[]) => {
              const addElements: IVideoDevicesListDto[] = [];
              const removeElements: string[] = [];
              updateIds.forEach((id: string) => {
                const element: IVideoDevicesListDto | undefined = elements.find(
                  (el: IVideoDevicesListDto) => el.id === id,
                );
                element ? addElements.push(element) : removeElements.push(id);
              });
              applyTransaction(() => {
                this.store.upsertMany(addElements);
                this.store.remove(removeElements);
              });
            });
        });
    }

    this.loadData(query, limit, sort).pipe(untilDestroyed(this)).subscribe();
  }

  /**
   * Инициализируем список Списков видеоустройств
   */
  public loadData(query?: IAnyObject, limit?: ILimit, sort?: ISort): Observable<number> {
    this.store.setLoading(true);

    this.lastQuery = query;

    return this.getVideoDevicesLists(query, limit, sort).pipe(
      map((response: IVideoDevicesListDto[]) => {
        applyTransaction(() => {
          this.store.reset();
          this.store.add(response);
          this.store.setLoading(false);
        });

        return response.length;
      }),
    );
  }

  /** Запрос количества камер в списке */
  public getVideoDevicesListCount(listId: string): Observable<number> {
    return this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: 'Admin' },
          entity: {
            attributes: ['id', 'count'],
            query: {
              id: listId,
            },
            name: 'VideoDevicesLists',
          },
        },
        'http',
      )
      .pipe(
        map((devices: IAbstractServiceData) => {
          const el = (devices.data.items || [])[0];
          return el['count'];
        }),
      );
  }

  /** Получение списков в которых находится видеокамера
   * @params deviceId - id видеокамеры
   **/
  public getListsByDeviceId(deviceId: string): Observable<string[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          attributes: ['videoDevicesListId'],
          query: {
            deviceId,
          },
          name: 'VideoDevicesListCamerasLinks',
        },
      })
      .pipe(
        map((result: IAbstractServiceData) => {
          return result.data.items.map((item: IAnyObject) => item.videoDevicesListId) || [];
        }),
      );
  }

  private;
}
