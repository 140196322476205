<div *ngIf="!model" class="center">
  <mat-spinner strokeWidth="5"></mat-spinner>
  <div class="spinner-label">Загрузка...</div>
</div>
<div class="panel-wrapper" *ngIf="model">
  <nw-header [options]="headerActionsOptions" (clickButton)="onClickActionsButton($event)"></nw-header>
  <form [formGroup]="form">
    <div class="content">
      <div class="bg-row">
        <div class="bg-col-8">
          <div class="bg-form-group">
            <sc-input [options]="monitoringObjectOptions" formControlName="monitoringObject"></sc-input>
          </div>
        </div>
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-input [options]="supplyTypeOptions" formControlName="supplyType"></sc-input>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col-8">
          <div class="bg-form-group">
            <sc-input [options]="addressOptions" formControlName="address"></sc-input>
          </div>
        </div>
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-input [options]="coordinatesOptions" formControlName="coordinates"></sc-input>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-input [options]="typeOptions" formControlName="type"></sc-input>
          </div>
        </div>
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-input [options]="stateOptions" formControlName="state"></sc-input>
          </div>
        </div>
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-input [options]="extIdOptions" formControlName="extId"></sc-input>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-checkbox [options]="activeOptions" formControlName="active"></sc-checkbox>
          </div>
        </div>
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-checkbox [options]="deactivationOptions" formControlName="deactivation"></sc-checkbox>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-input [options]="failureExtIdOptions" formControlName="failureExtId"></sc-input>
          </div>
        </div>
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-input [options]="conditionOptions" formControlName="condition"></sc-input>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-input [options]="latchDtOptions" formControlName="latchDt"></sc-input>
          </div>
        </div>
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-input [options]="latchMessageOptions" formControlName="latchMessage"></sc-input>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-input [options]="unlatchDtOptions" formControlName="unlatchDt"></sc-input>
          </div>
        </div>
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-input [options]="unlatchMessageOptions" formControlName="unlatchMessage"></sc-input>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-input [options]="deviceNameOptions" formControlName="deviceName"></sc-input>
          </div>
        </div>
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-input [options]="deviceTypeOptions" formControlName="deviceType"></sc-input>
          </div>
        </div>
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-input [options]="deviceExtIdOptions" formControlName="deviceExtId"></sc-input>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-input [options]="nodeNameOptions" formControlName="nodeName"></sc-input>
          </div>
        </div>
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-input [options]="nodeResourceTypeOptions" formControlName="nodeResourceType"></sc-input>
          </div>
        </div>
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-input [options]="nodeExtIdOptions" formControlName="nodeExtId"></sc-input>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col-8">
          <div class="bg-form-group">
            <sc-input [options]="organizationOptions" formControlName="organization"></sc-input>
          </div>
        </div>
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-input [options]="integrationTypeOptions" formControlName="integrationType"></sc-input>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<router-outlet name="allRegistry"></router-outlet>
<nw-drawer
  [toggleDrawer]="toggleFormOpen"
  [widthDrawer]="'calc(100vw - var(--sidebar-width))'"
  [heightDrawer]="'100vh'"
  [marginDrawer]="'0'"
  style="z-index: 10001"
>
  <router-outlet name="editCallForm" (activate)="toggleFormOpen = true" (deactivate)="toggleFormOpen = false">
  </router-outlet>
</nw-drawer>
