import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';
import { ISignificantObjectData } from '@bg-front/significant-objects/models/interfaces';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { Settings2Service } from '@smart-city/core/services';
import { GisService, IGisServiceResult } from '@smart-city/maps/sc';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { IRadiationForecastResultsDto } from 'smart-city-types';

import { IForecastingRadioactiveEnvironmentReport, IThyroidIrradiationTaskDto } from '../../models';
import {
  ForecastingRadioactiveEnvironmentReportService,
  ForecastingRadioactiveEnvironmentVisualizationService,
} from '../../services';

@Component({
  selector: 'bg-thyroid-irradiation-task-result',
  templateUrl: './thyroid-irradiation-task-result.component.html',
  styleUrls: ['./thyroid-irradiation-task-result.component.scss'],
})
export class ThyroidIrradiationTaskResultComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public forecastingResults: IForecastingResultDto;

  public params: IThyroidIrradiationTaskDto;
  public results: IRadiationForecastResultsDto[];
  public inputTypeSysname: string;
  public thyroidTypeForm: FormGroup;
  private elements: any[] = [];

  /** Скачать отчёт */
  @Input()
  public downloadReport: Observable<void>;

  /** Уведомляем о наличии отчёта */
  @Output()
  public hasReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  /** Уведомляем о формировании отчёта */
  @Output()
  public isDownloadReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  public unitName: string;

  constructor(
    private readonly gisService: GisService,
    private readonly significantObjectService: SignificantObjectsService,
    private readonly forecastingService: ForecastingRadioactiveEnvironmentVisualizationService,
    public readonly settings: Settings2Service,
    private readonly reportingService: ForecastingRadioactiveEnvironmentReportService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
    this.thyroidTypeForm = new FormGroup({
      recipient: new FormControl('adult'),
    });
  }

  public ngOnInit(): void {
    this.results = this.forecastingResults.result as IRadiationForecastResultsDto[];
    this.params = this.forecastingResults.params.params as IThyroidIrradiationTaskDto;
    this.inputTypeSysname = this.settings.getDictionaryById(this.params.inputTypeId)?.sysname;
    this.unitName = this.settings.getDictionaryById(this.params.unitRadiationId)?.name;

    if (new Coordinates(this.forecastingResults.params.coordinates).isValid()) {
      switch (this.inputTypeSysname) {
        case 'manually':
          this.elements = this.forecastingService.displayThyroidResultsForManual(
            this.forecastingResults,
            this.thyroidTypeForm.controls.recipient.value,
          );
          break;
        case 'shelterCalculatingDoses':
          this.elements = this.forecastingService.shelterCalculatingDoses(this.forecastingResults);
          break;
        case 'propagationTimeDoses':
          this.elements = this.forecastingService.displayThyroidResults(
            this.forecastingResults,
            this.thyroidTypeForm.controls.recipient.value,
          );
          break;
        case 'iodineProphylaxisCalculationDoses':
          this.elements = this.forecastingService.iodineProphylaxisCalculationDoses(
            this.forecastingResults,
            this.thyroidTypeForm.controls.recipient.value,
          );
          break;
      }
    } else {
      this.noteService.pushWarning('Отсутствуют координаты для визуализации расчёта');
    }

    this.hasReport.emit(this.inputTypeSysname === 'manually');

    this.thyroidTypeForm.controls.recipient.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((recipient) => {
      this.forecastingService.clearResults(this.elements);

      switch (this.inputTypeSysname) {
        case 'manually':
          this.elements = this.forecastingService.displayThyroidResultsForManual(this.forecastingResults, recipient);
          break;
        case 'shelterCalculatingDoses':
          this.elements = this.forecastingService.shelterCalculatingDoses(this.forecastingResults);
          break;
        case 'propagationTimeDoses':
          this.elements = this.forecastingService.displayThyroidResults(this.forecastingResults, recipient);
          break;
        case 'iodineProphylaxisCalculationDoses':
          this.elements = this.forecastingService.iodineProphylaxisCalculationDoses(this.forecastingResults, recipient);
          break;
      }
      this.cdr.markForCheck();
    });

    this.downloadReport
      .pipe(
        mergeMap(() => {
          return this.makeReport().pipe(takeUntil(this.ngUnsubscribe));
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((report: IForecastingRadioactiveEnvironmentReport) => {
        const recipient = this.thyroidTypeForm.controls.recipient.value;
        this.reportingService.buildForecastingThyroidReport(report, recipient);
      });

    this.cdr.markForCheck();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.forecastingService.clearResults(this.elements);
  }

  /** Собрать данные для отчета */
  public makeReport(): Observable<IForecastingRadioactiveEnvironmentReport> {
    const report: IForecastingRadioactiveEnvironmentReport = {};
    this.isDownloadReport.emit(true);
    return this.significantObjectService.getSignificantObjects({ id: this.forecastingResults.params.objectId }).pipe(
      catchError((err: Error) =>
        this.catchErrorFn<ISignificantObjectData[]>(err, 'Ошибка при загрузке данных о важном объекте'),
      ),
      map((significantObjects: ISignificantObjectData[]) => (significantObjects || [])[0]),
      switchMap((significantObject: ISignificantObjectData) => {
        /** Блок "Местоположение" */
        if (significantObject.address.fullText) {
          report.address = significantObject.address.fullText;
        }
        report.coordinates = significantObject.coordinates;
        report.object = significantObject.name;
        report.responsible = significantObject.responsible;
        report.phone = significantObject.phone;

        /** Блок "Исходные данные" */
        report.emergency = this.forecastingResults.emergencyNumber || 'не задано';
        report.irradiationDose = +this.params.dose;
        report.unitRadiationId = this.params.unitRadiationId;

        /** Блок "Метеоусловия в момент аварии" */
        report.windVelocity = +this.params.windVelocity;
        report.windDirection = +this.params.windDirection;
        report.isSnowy = this.params.isSnowy ? 'Да' : 'Нет';
        report.cloudState = this.settings.getDictionaryById(this.params['cloudStateId'])?.name;
        report.timeOfDay = this.settings.getDictionaryById(this.params['timeOfDayId'])?.name;

        /** Блок "Используемая методология для прогнозирования" */
        report.methodology = `ГОСТ Р 22.2.11-2018 Безопасность в чрезвычайных ситуациях. Методика оценки радиационной обстановки при
запроектной аварии на атомной станции`;

        /** Блок "Результаты расчёта" */
        for (const result of this.results) {
          if (result.recipient === 'adult') {
            report.thyroidForecastResultsAdult = { ...(result as IRadiationForecastResultsDto) };
          } else if (result.recipient === 'child') {
            report.thyroidForecastResultsChild = { ...(result as IRadiationForecastResultsDto) };
          }
        }

        const coordinates = this.forecastingService.coordinatesThyroidResultsForManual(
          this.forecastingResults,
          this.thyroidTypeForm.controls.recipient.value,
        );

        if (this.forecastingResults && coordinates) {
          return this.gisService.getAddressesByPolygonV2(coordinates);
        }
        return of(null);
      }),
      mergeMap((res: IGisServiceResult[]) => {
        /** Блок "Здания в зоне поражения" */
        report.buildings = [...res];
        return of(report);
      }),
      finalize(() => this.isDownloadReport.emit(false)),
      takeUntil(this.ngUnsubscribe),
    );
  }
}
