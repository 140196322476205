<div class="bg-incident-panel">
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-incident-panel-edit-from">
        <nw-header [options]="headerActionsOptions" (clickButton)="onClickActionsButton($event)"></nw-header>
        <form [formGroup]="incidentForm">
          <div class="incident-panel">
            <mat-accordion multi="true">
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Главное</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="bg-row space-between-container">
                  <div class="bg-col">
                    <span>Регистрация: {{ model.timeCreate | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                  </div>
                  <div class="bg-col">
                    <span>Начало: {{ model.timeStart | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                  </div>
                  <div class="bg-col">
                    <span>Окончание: {{ model.timeFinish | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                  </div>
                </div>
                <!-- Чекбокс Адрес по координатам -->
                <div class="bg-row">
                  <div class="bg-col align-right">
                    <sc-checkbox [options]="byCoordinatesOptions" formControlName="byCoordinates"></sc-checkbox>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <sc-input [options]="addressFactOptions" formControlName="addressFact"></sc-input>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col-6">
                    <div class="bg-form-group">
                      <sc-select
                        [ngStyle]="{ display: !moIdVisible ? 'none' : '' }"
                        [options]="moIdOptions"
                        formControlName="moId"
                        (init)="onMoInit($event)"
                      ></sc-select>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select
                        [ngStyle]="{ display: !districtIdVisible ? 'none' : '' }"
                        [options]="districtIdOptions"
                        formControlName="districtId"
                        (init)="onDistrictInit($event)"
                      ></sc-select>
                    </div>
                  </div>
                </div>
                <placement-details [model]="model?.detailsFact"></placement-details>
                <div
                  class="bg-row bg-row-align-content-center space-between-container"
                  *ngIf="!!countOfCloseImportantObjects"
                >
                  <div class="bg-col-min">
                    <i
                      class="sc-icon-attention significant-object-attention"
                      [ngClass]="{ 'significant-object-danger': hasDangerObject }"
                    ></i>
                  </div>
                  <div class="bg-col">
                    <p>{{ closeImportantObjectsMessage }} (подробнее см. раздел "Местоположение")</p>
                  </div>
                </div>

                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <sc-select
                        [options]="optionsIncidentTypes"
                        formControlName="incidentTypeId"
                        [data]="loadKsipTypes$"
                      ></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <sc-select
                        [options]="optionsKsipDetailsUlk"
                        formControlName="ksipDetailsId"
                        [data]="loadKsipDetails$"
                      ></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row" *ngIf="isPlanWorkForm || isAccidentForm || isAccidentFormDetails">
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <bg-datetime
                        formControlName="workTermFrom"
                        [options]="workTermFromOptions"
                        [controlErrors]="workTermFromErrors">
                        ></bg-datetime>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <bg-datetime formControlName="workTermTo"
                                   [options]="workTermToOptions"
                                   [controlErrors]="workTermToErrors"></bg-datetime>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea [options]="optionsDescription" formControlName="description"></sc-textarea>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea [options]="optionsComment" formControlName="comment"></sc-textarea>
                    </div>
                  </div>
                </div>
                <div class="bg-row" *ngIf="thermoPoint">
                  <div class="bg-col d-flex d-flex-right">
                    <a
                      class="blink--link"
                      target="_blank"
                      [href]="thermoPointInnerLink"
                    >
                      <mat-icon>link_on</mat-icon>
                      Термоточка в АПК БГ
                    </a>
                    <a
                      class="blink--link"
                      target="_blank"
                      [href]="'https://firenotification.mchs.gov.ru/thermal-point/' + thermoPoint.extId"
                    >
                      <mat-icon>link_on</mat-icon>
                      Термоточка в АИУС РСЧС
                    </a>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select
                        [options]="optionsOrganization"
                        formControlName="organization"
                      ></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-checkbox [options]="optionsThreatPopulation" formControlName="threatPopulation"></sc-checkbox>
                      <sc-checkbox
                        [options]="optionsThreatOrganization"
                        formControlName="threatOrganization"
                      ></sc-checkbox>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select [options]="optionsResponsibleUser" formControlName="responsibleUser"></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row" *ngIf="model['regime'] && model['regime']['id']">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select [options]="optionsRegime" formControlName="regime"></sc-select>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel *ngIf="showParams">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Параметры</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <i
                        *ngIf="!!countOfCloseImportantObjects"
                        class="sc-icon-attention significant-object-attention"
                        [ngClass]="{ 'significant-object-danger': hasDangerObject }"
                      ></i>
                      <div>Местоположение</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-input [options]="coordinatesOption" formControlName="coordinates"></sc-input>
                    </div>
                    <div class="bg-form-group">
                      <sc-textarea
                        class="additional-incident-type-container"
                        [options]="placeDescriptionOptions"
                        formControlName="ksipPlaceDescription"
                      ></sc-textarea>
                    </div>
                    <div class="bg-form-group">
                      <bg-significant-object-list-dialog
                        [coordinates]="incidentForm.controls.coordinates.value"
                        [radius]="500"
                        [disabled]="!this.countOfCloseImportantObjects"
                      ></bg-significant-object-list-dialog>
                    </div>
                    <div class="bg-form-group">
                      <div class="bg-row bg-row-align-content-center space-between-container justify-content">
                        <div class="bg-col-min" *ngIf="countOfCloseImportantObjects">
                          <i
                            class="sc-icon-attention significant-object-attention"
                            [ngClass]="{ 'significant-object-danger': hasDangerObject }"
                          ></i>
                        </div>
                        <div>
                          <p>{{ this.closeImportantObjectsMessage }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group mini-map-container">
                      <bg-mini-map
                        [center]="miniMapCenter"
                        [zoom]="miniMapZoom"
                        [defaultMarkerCoordinate]="defaultMarkerCoordinate"
                        (mapReady)="onMapReady()"
                      ></bg-mini-map>
                    </div>
                  </div>
                </div>
                <div class="readonly-container">
                  <div class="readonly-container__column">
                    <label>Заявленный адрес</label>
                    <p>{{ model?.address?.fullText }}</p>
                  </div>
                </div>
                <placement-details [model]="model?.details"></placement-details>
              </mat-expansion-panel>
              <mat-expansion-panel *ngIf="emergencyEvent.vaDetail !== null">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Информация с устройств</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <form *ngIf="!!cameraForm" [formGroup]="cameraForm">
                  <div class="bg-row content">
                    <div class="bg-col">
                      <div class="bg-form-group">
                        <sc-input [options]="videoDeviceNameOptions" formControlName="videoDeviceName"></sc-input>
                      </div>
                    </div>
                    <div class="bg-col-min">
                      <div class="bg-form-group">
                        <mat-icon
                          class="mt-25 pointer"
                          [matTooltip]="videoDeviceTooltipText"
                          matTooltipPosition="right"
                          matTooltipClass="bg-org-selector-multiline-tooltip"
                        >
                          info
                        </mat-icon>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="bg-row">
                  <div class="bg-col">
                    <mat-tab-group class="sc-tab-group" [disableRipple]="true">
                      <mat-tab *ngIf="$any(emergencyEvent?.vaDetail)?.file1Id" label="Фото">
                        <bg-match-image
                          [path]="filePath"
                          [frame]="$any(emergencyEvent?.vaDetail)?.frame"
                          mode="coordinates">
                        </bg-match-image>
                      </mat-tab>
                      <mat-tab label="Видеопоток">
                        <iframe
                          *ngIf="source"
                          [src]="source"
                          frameborder="0"
                          allowfullscreen
                          scrolling="no"
                          class="streamFrame"
                        ></iframe>
                        <span *ngIf="!source">В настоящий момент видеокамера не работает</span>
                      </mat-tab>
                    </mat-tab-group>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel (opened)="openCoverageArea()">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Зона действия</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <bg-coverage-area [address]="factAddress"></bg-coverage-area>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Дополнительная информация</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="row readonly-container">
                      <div class="readonly-container__column">
                        <label>Задействованная техника</label>
                      </div>
                      <div class="readonly-container__column readonly-container__column_cars-count">
                        <p>{{ summaryCars }}</p>
                      </div>
                    </div>
                    <div class="row readonly-container">
                      <div class="readonly-container__column">
                        <label>Пожарные машины</label>
                      </div>
                      <div class="readonly-container__column readonly-container__column_cars-count">
                        <p>{{ totalCars?.totalFireCars }}</p>
                      </div>
                    </div>
                    <div class="row readonly-container">
                      <div class="readonly-container__column">
                        <label>Полицейские машины</label>
                      </div>
                      <div class="readonly-container__column readonly-container__column_cars-count">
                        <p>{{ totalCars?.totalPoliceCars }}</p>
                      </div>
                    </div>
                    <div class="row readonly-container">
                      <div class="readonly-container__column">
                        <label>Машины скорой помощи</label>
                      </div>
                      <div class="readonly-container__column readonly-container__column_cars-count">
                        <p>{{ totalCars?.totalAmbulanceCars }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="row container-without-border white">
                      <div class="container-without-border__column">
                        <label for="otherCars">Другая техника</label>
                      </div>
                      <div class="container-without-border__column container-without-border__column_cars-count">
                        <input id="otherCars" formControlName="otherCars" [mask]="'0*'" />
                      </div>
                    </div>
                    <div class="row">
                      <sc-textarea
                        class="additional-cars-container"
                        [options]="additionalInfoAboutCarsOptions"
                        formControlName="additionalInfoAboutCars"
                      ></sc-textarea>
                    </div>
                    <div class="row container-without-border white">
                      <div class="container-without-border__column">
                        <label>Контрольное время устранения</label>
                      </div>
                      <div class="container-without-border__column container-without-border__column_cars-count">
                        <bg-datetime [label]="timeLimitOptions.label" formControlName="timeLimit"></bg-datetime>
                      </div>
                    </div>
                  </div>
                </div>
                <sc-textarea
                  class="resolution-report-container"
                  [options]="reportOptions"
                  formControlName="report"
                ></sc-textarea>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <mat-panel-title>
                      <div class="header-container">
                        <div>Потери</div>
                        <div class="header-container__divider">
                          <hr />
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-row container-without-border white">
                      <div class="container-without-border__column">
                        <label for="death">Погибшие</label>
                      </div>
                      <div class="container-without-border__column container-without-border__column_cars-count">
                        <input id="death" formControlName="death" [mask]="'0*'" />
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-row container-without-border white">
                      <div class="container-without-border__column">
                        <label for="deathChildren">В т.ч. дети</label>
                      </div>
                      <div class="container-without-border__column container-without-border__column_cars-count">
                        <input id="deathChildren" formControlName="deathChildren" [mask]="'0*'" />
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-row white">
                      <div class="container-without-border__column container-without-border__column_actual-datetime">
                        <bg-datetime label="Актуально на" formControlName="deathOnDate"></bg-datetime>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea
                        class="resolution-report-container"
                        [options]="deathPlaceOptions"
                        formControlName="deathPlace"
                      ></sc-textarea>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-row container-without-border white">
                      <div class="container-without-border__column">
                        <label for="rescued">Спасённые</label>
                      </div>
                      <div class="container-without-border__column container-without-border__column_cars-count">
                        <input id="rescued" formControlName="rescued" [mask]="'0*'" />
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-row container-without-border white">
                      <div class="container-without-border__column">
                        <label for="rescuedChildren">В т.ч. дети</label>
                      </div>
                      <div class="container-without-border__column container-without-border__column_cars-count">
                        <input id="rescuedChildren" formControlName="rescuedChildren" [mask]="'0*'" />
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-row white">
                      <div class="container-without-border__column container-without-border__column_actual-datetime">
                        <bg-datetime label="Актуально на" formControlName="rescuedOnDate"></bg-datetime>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea
                        class="resolution-report-container"
                        [options]="rescuedPlaceOptions"
                        formControlName="rescuedPlace"
                      ></sc-textarea>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-row container-without-border white">
                      <div class="container-without-border__column">
                        <label for="victim">Пострадавшие</label>
                      </div>
                      <div class="container-without-border__column container-without-border__column_cars-count">
                        <input id="victim" formControlName="victim" [mask]="'0*'" />
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-row container-without-border white">
                      <div class="container-without-border__column">
                        <label for="victimChildren">В т.ч. дети</label>
                      </div>
                      <div class="container-without-border__column container-without-border__column_cars-count">
                        <input id="victimChildren" formControlName="victimChildren" [mask]="'0*'" />
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-row white">
                      <div class="container-without-border__column container-without-border__column_actual-datetime">
                        <bg-datetime label="Актуально на" formControlName="victimOnDate"></bg-datetime>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea
                        class="resolution-report-container"
                        [options]="victimPlaceOptions"
                        formControlName="victimPlace"
                      ></sc-textarea>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-row container-without-border white">
                      <div class="container-without-border__column">
                        <label for="hospitalized">Госпитализированные</label>
                      </div>
                      <div class="container-without-border__column container-without-border__column_cars-count">
                        <input id="hospitalized" formControlName="hospitalized" [mask]="'0*'" />
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-row container-without-border white">
                      <div class="container-without-border__column">
                        <label for="hospitalizedChildren">В т.ч. дети</label>
                      </div>
                      <div class="container-without-border__column container-without-border__column_cars-count">
                        <input id="hospitalizedChildren" formControlName="hospitalizedChildren" [mask]="'0*'" />
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-row white">
                      <div class="container-without-border__column container-without-border__column_actual-datetime">
                        <bg-datetime label="Актуально на" formControlName="hospitalizedOnDate"></bg-datetime>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-row container-without-border white">
                      <div class="container-without-border__column">
                        <label for="missing">Пропавшие без вести</label>
                      </div>
                      <div class="container-without-border__column container-without-border__column_cars-count">
                        <input id="missing" formControlName="missing" [mask]="'0*'" />
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-row container-without-border white">
                      <div class="container-without-border__column">
                        <label for="missingChildren">В т.ч. дети</label>
                      </div>
                      <div class="container-without-border__column container-without-border__column_cars-count">
                        <input id="missingChildren" formControlName="missingChildren" [mask]="'0*'" />
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-row white">
                      <div class="container-without-border__column container-without-border__column_actual-datetime">
                        <bg-datetime label="Актуально на" formControlName="missingOnDate"></bg-datetime>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <mat-panel-title>
                      <div class="header-container">
                        <div>Документы</div>
                        <div class="header-container__divider">
                          <hr />
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <multi-file [selfEnabled]="true" formControlName="documents"></multi-file>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
