<form [formGroup]="form" appLCSFormState [params]="attributes">
  <div class="bg-row">
    <div class="bg-col p-r-0">
      <div class="bg-form-group">
        <sc-input [options]="corpOptions" formControlName="corp"></sc-input>
      </div>
    </div>
    <div class="bg-col p-h-0">
      <div class="bg-form-group">
        <div class="property">
          <sc-input [options]="storeysOptions" formControlName="storeys"></sc-input>
        </div>
      </div>
    </div>
    <div class="bg-col p-h-0">
      <div class="bg-form-group">
        <div class="property">
          <sc-input [options]="floorOptions" formControlName="floor"></sc-input>
        </div>
      </div>
    </div>
    <div class="bg-col p-h-0">
      <div class="bg-form-group">
        <div class="property">
          <sc-input [options]="entranceOptions" formControlName="entrance"></sc-input>
        </div>
      </div>
    </div>
    <div class="bg-col p-h-0">
      <div class="bg-form-group">
        <div class="property">
          <sc-input [options]="roomOptions" formControlName="room"></sc-input>
        </div>
      </div>
    </div>
    <div class="bg-col p-l-0">
      <div class="bg-form-group">
        <div class="property">
          <sc-input [options]="kmOptions" formControlName="km"></sc-input>
        </div>
      </div>
    </div>
  </div>
</form>
