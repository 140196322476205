import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RestService } from '@smart-city/core/services';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { IAbstractServiceData } from 'smart-city-types';

import { ISubstrateDto } from '../../../models/interfaces';
import { SubstratesStore } from '../store/substrates.store';

/**
 * Сервис по обновлению хранилища организаций
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SubstratesService {
  /** Флаг инициализации хранилища */
  private isInit = false;

  constructor(private readonly rest: RestService, private readonly store: SubstratesStore) {}

  /**
   * Загружаем список подложек
   */
  public initStore(): Observable<boolean> {
    this.store.setLoading(true);

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Substrates',
          sort: { field: 'default', direction: 'asc' },
        },
      })
      .pipe(
        map((response: IAbstractServiceData): boolean => {
          const defaultSubstrate: ISubstrateDto = response.data.items?.find(
            (substrate: ISubstrateDto) => substrate.default,
          );
          if (defaultSubstrate) {
            this.store.setActive(defaultSubstrate.id);
          }
          this.store.add(response?.data?.items as ISubstrateDto[]);
          return true;
        }),
        catchError(() => {
          return of(false);
        }),
        finalize(() => {
          this.store.setLoading(false);
        }),
      );
  }
  /** Устанавливаем выбранную в текущий момент подложку */
  public setActive(id: string): void {
    this.store.setActive(id);
  }
}
