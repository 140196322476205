<div class="org-involve-request-form">
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
      <div class="bg-row form-serviceType">
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-select [options]="serviceTypeOptions" formControlName="serviceType"></sc-select>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-textarea
              [options]="commentOptions"
              formControlName="comment"
            >
            </sc-textarea>
          </div>
        </div>
      </div>
      <div *ngIf="rejected" class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-textarea
              [options]="rejectionReasonOptions"
              formControlName="rejectionReason"
            >
            </sc-textarea>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="!hideActions" align="end">
    <sc-button [options]="cancelBtnOptions" (clickButton)="cancelHandler()"></sc-button>
    <sc-button [options]="okBtnOptions" (clickButton)="okHandler()"></sc-button>
  </mat-dialog-actions>
</div>
