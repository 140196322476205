<div *ngIf="!isReady" class="center">
  <mat-spinner strokeWidth="5"></mat-spinner>
  <div class="spinner-label">Загрузка...</div>
</div>
<div class="bg-row" *ngIf="isReady">
  <div class="bg-col">
    <div class="forecasting-container">
      <div class="bg-row">
        <div class="bg-col">
          <nw-header [options]="headerActionsOptions" (clickButton)="onClickActionsButton($event)"></nw-header>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col">
          <bg-forecasting-form [isShow]="true"></bg-forecasting-form>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-col result-column">
    <bg-forecasting-results [isShow]="true"></bg-forecasting-results>
  </div>
</div>
