import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { AppInjector, Coordinates } from '@bg-front/core/models/classes';
import {
  CLICK_MAKER_SHAPE_SVG,
  CLICK_MARKER,
  LAYER_DATA_LOAD_LIMIT,
  NEW_CLICK_MARKER,
  PATH_POINT_MARKER_SVG,
  SEARCH_MARKER,
} from '@bg-front/core/models/constants';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import {
  IBgMapExtFilterOptions,
  IMapLayer,
  IMapLayerData,
  IMapLayerEntityFilter,
  IMiniCardSelectionEvent,
  IPictogramSettingsDto,
  IPictogramSettingsValueDto,
  IPolygonDto,
  ISubstrateDto,
} from '@bg-front/core/models/interfaces';
import {
  BgMapService,
  CommunicationService,
  LayersDataService,
  OperationsService,
  SubstratesQuery,
} from '@bg-front/core/services';
import { RegistryPanelService } from '@bg-front/registry-panel/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { INwHeaderBarOptions } from '@smart-city/core/common';
import { IDictionaryInfo, IModifiedData } from '@smart-city/core/interfaces';
import { ScNavService, Settings2Service, SidebarContainerService, SubscriberService } from '@smart-city/core/services';
import { ScConsole } from '@smart-city/core/utils';
import {
  IconShapeType,
  IMapBaseEvent,
  IMapBaseIcon,
  IMapBaseObjectEvent,
  IMapObjectIconOptions,
  IMapViewObjects,
  IMapViewObjectsEvent,
  IMapViewObjectsOptions,
  IMapViewObjectsRequest,
  MapBaseCoordinatesType,
  MapBaseModel,
  MapBaseService,
  TIconShape,
} from '@smart-city/maps/sc';
import * as clone from 'clone';
import * as L from 'leaflet';
import { NzModalService } from 'ng-zorro-antd/modal';
import { fromWorker } from 'observable-webworker';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, map, repeat, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { IAdminMunicipalSchemaDto, IAnyObject, IEmergencyDto, IMapObjectDto } from 'smart-city-types';

import { IMonitoringObjectHcsDto } from '../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/models/interfaces';
import { ForecastingModalFormComponent } from '../../../forecasting/components';
import { RnisExternalService } from '../../../rnis/services';
import { LayersEnum } from '@bg-front/core/models/enums';
import { IBgMapViewObjectsMarkerData, IVideoDeviceBg } from '../../models/interfaces';
import { EmergencyService, MiniMapService, VideoDevicesService } from '../../services';
import { LegendDialogComponent } from '../legend-dialog/legend-dialog.component';
import { MapLayersFiltersDialogComponent } from '../map-layers-filters-dialog/map-layers-filters-dialog.component';
import { IMapBaseBaseUrlsOptions } from '@bg-front/map/models/interfaces';

/**
 * Базовый класс для всех компонент рабочих столов
 * Реализует отписку от событий и базовый ngOnDestroy
 */
@UntilDestroy()
@Component({
  template: '',
})
export abstract class BaseWorkspaceComponent extends BaseComponent implements OnInit, OnDestroy {
  /**
   * Настройки заголовка
   */
  public headerOptions: INwHeaderBarOptions = {
    title: 'Рабочий стол оператора',
    name: 'header',
    margin: 'collapse',
    bgColor: 'white',
    buttons: [
      {
        type: 'button',
        options: {
          name: 'burger',
          icon: 'menu',
        },
      },
    ],
  };

  /**
   * Запрос от родительского компонента
   */
  public request: IMapViewObjectsRequest;

  /**
   * Ссылка на сервис настроек
   */
  public readonly settings: Settings2Service;

  /**c
   * Ссылка на сервис регистров
   */
  public readonly registryPanelService: RegistryPanelService;

  /**
   * Ссылка на сервис бокового меню
   */
  public readonly sidebarContainerService: SidebarContainerService;
  /** Сервис межкомпонентного взаимодействия */
  public readonly communicationService: CommunicationService;
  /**
   * Сервис прослушивания изменений
   */
  public readonly subs: SubscriberService;
  /** Сервис для общения с миникартой */
  public readonly miniMapService: MiniMapService;

  /**
   * Сервис для работы с сущностью Видеокамеры
   */
  public videoDevicesService: VideoDevicesService;

  /**
   * Ссылка на ГИС сервис
   */
  public readonly gisService: LayersDataService;
  /**
   * Ссылка на сервис работы с инцидентами
   */
  public readonly emergencyService: EmergencyService;
  /**
   * Ссылка на сервис Карты
   */
  public readonly bgMapService: BgMapService;
  /**
   * Ссылка на роутер
   */
  public readonly router: Router;

  /**
   * Ссылка на диалог с фильтрами объектов на карте
   */
  public readonly dialog: MatDialog;

  /**
   * Состояние формы инцидента
   */
  public toggleFormOpen = false;

  /**
   * Состояние формы просмотра поручения из плана реагирования
   */
  public toggleViewFormOpen = false;

  /**
   * Создание формы нового события с кнопки
   */
  public toggleFormCreateEvent = false;
  /**
   * Состояние формы события звонка
   * True определяет route-outlet реестра
   */
  public toggleFormOpenCall = false;
  /**
   * Состояние формы редактирования события
   * True определяет route-outlet реестра
   */
  public toggleEditEventFormOpen = false;
  /**
   * Состояние popup'а
   */
  public isPopupOpen = false;
  /**
   * Настройки карты
   */
  public mapViewObjectsOptions: IMapViewObjectsOptions;
  /**
   * Центр карты (координаты)
   */
  public centerMap: MapBaseCoordinatesType;
  /**
   * Зум карты
   */
  public zoomMap: number;
  /**
   * Настройки маркеров
   */
  public markersOptions: IBgMapViewObjectsMarkerData[] = [];
  /**
   * Настройки слоёв
   */
  public layersOptions: IBgMapExtFilterOptions[] = [];
  /**
   * Объект содержащий объекты на карте
   */
  public mapObjects: IMapViewObjects;
  /**
   * Хранилище слоёв и объектов на этих слоях
   */
  public mapLayersData: Map<string, IMapLayerData> = new Map();
  /** Модель карты для инициализации */
  public mapModel: MapBaseModel;
  /** Настоящая модель карты */
  public mapObjectModel: L.Map;
  /** Хранилище фильтров для слоев */
  public mapLayersFilters = {};
  public mapId: string;
  /** Состояние кнопки линейка */
  public isYardstick = false;
  /** Состояние кнопки построение маршрута */
  public isBuildingRoute = false;
  /** Массив точек для линейки */
  protected pointsArray: MapBaseCoordinatesType[] = [];
  /**
   * Объект содержащий все видимые объекты на карте на всех слоях
   */
  private allMapObjects = {};
  /**
   * Ссылка на сервис навигационной панели
   */
  private readonly scNavService: ScNavService;
  /**
   * Список слоёв
   */
  private layers: IMapLayer[];
  /** Сервис работы с картой */
  private readonly mapService: MapBaseService;
  /** Список svg иконок из конфигураций слоев для легенды */
  public svgIconMap: IAnyObject;
  /** Слои доступные для легенды */
  public availableForLegendLayers: IMapLayer[];
  /** Флаг, указывает открыта ли легенда */
  private isLegendOpen: boolean = false;
  /** @ignore */
  protected constructor(
    public readonly route: ActivatedRoute,
    public readonly operationsService: OperationsService,
    private readonly modalService: NzModalService,
    public readonly substratesQuery: SubstratesQuery,
    public readonly rnisExternal?: RnisExternalService,
  ) {
    super();
    const injector = AppInjector.getInjector();
    this.settings = injector.get(Settings2Service);
    this.registryPanelService = injector.get(RegistryPanelService);
    this.sidebarContainerService = injector.get(SidebarContainerService);
    this.gisService = injector.get(LayersDataService);
    this.scNavService = injector.get(ScNavService);
    this.emergencyService = injector.get(EmergencyService);
    this.subs = injector.get(SubscriberService);
    this.miniMapService = injector.get(MiniMapService);
    this.router = injector.get(Router);
    this.videoDevicesService = injector.get(VideoDevicesService);
    this.dialog = injector.get(MatDialog);
    this.mapService = injector.get(MapBaseService);
    this.bgMapService = injector.get(BgMapService);
    this.communicationService = injector.get(CommunicationService);
  }

  /**
   * Счетчик примененных фильтров
   */
  public get filtersCount(): number {
    return Object.values<IMapLayerEntityFilter[]>(this.mapLayersFilters).filter(
      (item: IMapLayerEntityFilter[]) => item.length,
    ).length;
  }

  /** Текущий пользователь, авторизованный в системе */
  public get user(): IAnyObject {
    return (this.settings || <any>{}).currentUser;
  }

  public isAdmin(): boolean {
    const moId = this.user.organizationId?.mo ?? this.settings.currentUser.mo?.id;
    const userMo = this.settings.allMo.find((mo: IAdminMunicipalSchemaDto) => {
      return mo.id === moId;
    });

    if (!userMo) {
      return false;
    }
    return !userMo.municipal;
  }

  public ngOnInit(): void {
    this.mapModel = new MapBaseModel('baseMapWorkspace', this.mapService);
    this.bgMapService.baseMapModel = this.mapModel;

    this.communicationService.runForecasting$.pipe(untilDestroyed(this)).subscribe((data: IAnyObject) => {
      this.modalService
        .create({
          nzTitle: 'Параметры прогнозирования',
          nzContent: ForecastingModalFormComponent,
          nzFooter: null,
          nzComponentParams: {
            data,
          },
          nzStyle: {
            width: '900px',
          },
        })
        .afterClose.pipe(untilDestroyed(this))
        .subscribe();
    });

    const moFilterValue = {};
    // Если МО текущего пользователя не является родительским,
    // то установить значение фильтра равное данному МО.
    if (!this.isAdmin()) {
      [
        LayersEnum.incidents,
        LayersEnum.orders,
        LayersEnum.camerasMalfunctions,
        LayersEnum.significantObjects,
        LayersEnum.kseon,
        LayersEnum.eos,
        LayersEnum.dds,
      ].forEach((key: LayersEnum) => {
        let property;
        const forOrganizationMoProperty = [LayersEnum.incidents, LayersEnum.orders, LayersEnum.camerasMalfunctions];
        if (forOrganizationMoProperty.includes(key)) {
          property = 'organization.mo';
        }
        const forMoProperty = [LayersEnum.significantObjects, LayersEnum.kseon, LayersEnum.eos, LayersEnum.dds];
        if (forMoProperty.includes(key)) {
          property = 'mo';
        }
        moFilterValue[key] = {
          property,
          value: this.user.organizationId?.mo ?? this.settings.currentUser.mo?.id,
          operation: FilterOperationEnum.equal,
        };
      });
    }
    Object.defineProperty(this.mapLayersFilters, 'mo', { value: moFilterValue, enumerable: false });
    Object.defineProperty(this.mapLayersFilters, 'responsible', { value: {}, enumerable: false });

    /** Запрос доступных слоев в конструкторе слоев */
    this.gisService
      .getLayers()
      .pipe(
        switchMap((layers: IMapLayer[]) => {
          this.availableForLegendLayers = layers?.filter((layer: IMapLayer) => layer.pictogramSettings?.length);
          return forkJoin([of(layers), this.gisService.getSvgIconsMap(this.availableForLegendLayers)]);
        }),
        untilDestroyed(this),
        catchError((err: Error) => this.catchErrorFn<[IMapLayer[], IAnyObject]>(err, 'Ошибка при загрузке слоёв')),
      )
      .subscribe(([layers, svgIconMap]: [IMapLayer[], IAnyObject]) => {
        const options = [];
        this.layers = layers;
        this.svgIconMap = svgIconMap;

        this.bgMapService.mapLayersReadyInform();

        (layers || []).reverse().forEach((layer: IMapLayer) => {
          const markerShape = ((layer.markerShape || {}) as IDictionaryInfo).sysname;
          let size = 17;
          const color = '#ffffff';
          let shapeSize = 30;
          let shapeLineRadius = null;

          // TODO: вынести настройки в конструктор слоев. тикет на анализ(BG-14190).
          switch (markerShape) {
            case 'circle':
              shapeSize = 38;
              size = 22;
              break;
            case 'rectangle':
              shapeLineRadius = 5;
              shapeSize = 34;
              break;
            case 'diamond':
              shapeSize = 32;
              size = 18;
              break;
          }

          options.push(<IBgMapViewObjectsMarkerData>{
            name: layer.nameOnMap,
            nameOnMap: layer.nameOnMap,
            selected: layer.isDefaultActive,
            iconOptions: {
              iconSVG: layer.icon as string,
              iconSize: size,
              iconColor: layer.iconColor ?? color,
              shapeType: ((layer.markerShape || {}) as IDictionaryInfo).name,
              shapeColor: layer.markerColor,
              shapeSize: shapeSize,
              strokeWidth: 3,
              strokeColor: layer.borderColor,
              shapeLineRadius: shapeLineRadius,
            },
          });
        });
        options.push(CLICK_MARKER);
        options.push(NEW_CLICK_MARKER);
        options.push(SEARCH_MARKER);
        this.markersOptions = options;
        this.layersOptions = this.markersOptions.map(
          (data: IBgMapViewObjectsMarkerData) =>
            <IBgMapExtFilterOptions>{
              name: data.name,
              iconOptions: Object.assign({}, data.iconOptions),
              selected: data.selected,
              iconColor: data.iconOptions.iconColor,
              shapeColor: data.iconOptions.shapeColor,
            },
        );
        /** После прохода по слоям и формирования всех опций, еще раз проходимся и грузим данные */
        (layers || []).reverse().forEach((layer: IMapLayer) => {
          if (layer.isDefaultActive) {
            switch (layer.nameOnMap) {
              // Исключить загрузку данных ТС, если слой в конструкторе включен по-умолчанию
              case LayersEnum.rnis:
                this.mapLayersFilters[layer.nameOnMap] = [];
                break;
              default:
                this.getLayerData(layer, moFilterValue[layer.nameOnMap] ? [moFilterValue[layer.nameOnMap]] : []);
                this.mapLayersFilters[layer.nameOnMap] = [];
            }
          }
        });

        // Блок ниже закомментированного куска реализует ту же логику. Так как функционал критичный - оставлено для
        // возможности быстро откатиться.
        // this.subs
        //   .onTableChange<IEmergencyDto>('Emergency', 'Emergency')
        //   .pipe(untilDestroyed(this))
        //   .subscribe((response: IModifiedData<IEmergencyDto>) => {
        //     let emergency: IEmergencyDto = this.mapLayersData.has(response.data.id)
        //       ? (this.mapLayersData.get(response.data.id).element as IEmergencyDto)
        //       : <IEmergencyDto>{};
        //     emergency = { ...emergency, ...response.data };
        //     (this.layers || [])
        //       .filter(
        //         (layer: IMapLayer) =>
        //           layer.entityFilters.entity === 'Emergency' &&
        //           layer.entityFilters.service === 'Emergency' &&
        //           emergency[layer.attribute] === layer.attributeValue &&
        //           emergency.coordinates,
        //       )
        //       .forEach((layer: IMapLayer) => {
        //         const statusDict = this.settings.getDictionaryByTypeSysName('statusLifeCycleStep');
        //         const status = statusDict.find(
        //           (status: IBaseDictionaryData) =>
        //             status.id === this.settings.lifeCycleStep[emergency.lifeCycleStepId as string]?.status,
        //         );
        //         if (status) {
        //           /**
        //            * Проверяем надо ли удалить элемент с карты
        //            */
        //           const needRemove: boolean = !['new', 'inWork'].includes(status.sysname);
        //
        //           /**
        //            * Определяем действие
        //            */
        //           const action: string = this.mapLayersData.has(layer.nameOnMap) ? response.action : 'insert';
        //
        //           /**
        //            * Если действие добавления, но статус при этом не "Новый" или "В работе", то не обрабатываем
        //            */
        //           if (action === 'insert' && needRemove) {
        //             return;
        //           }
        //
        //           const item: IMapObjectDto = {
        //             id: emergency.id,
        //             coordinates: new Coordinates(emergency.coordinates).toArray(),
        //             name: emergency.number,
        //           };
        //
        //           /**
        //            * Добавляем на карту
        //            */
        //
        //           if (!item.coordinates) {
        //             return;
        //           }
        //
        //           this.mapModel.removeObject(layer.nameOnMap, item.id);
        //
        //           if (action === 'insert' || !needRemove) {
        //             this.mapModel.addMarkerToLayer(
        //               layer.nameOnMap,
        //               item.id,
        //               item.coordinates,
        //               this.mapService.makeIcon(this.generateIcon(layer, emergency)),
        //               null,
        //               null,
        //               { text: item['street'] ? item['street'] : item.name, direction: 'top' },
        //             );
        //           } else {
        //             this.mapLayersData.delete(item.id);
        //           }
        //         }
        //       });
        //   });

        // Массив для накопления ID записей которые изменились за секунду
        let emergencyIds = [];
        /** Подписка на поток изменений в таблице происшествий */
        this.subs
          .onTableChange<IEmergencyDto>('Emergency', 'Emergency')
          .pipe(
            tap((response) => {
              const id: string | IAnyObject = response.data.id;
              if (typeof id === 'string') {
                emergencyIds.push(id);
              } else if ((id as IAnyObject).$in?.length) {
                emergencyIds.push(...(id as IAnyObject).$in);
              }
            }),
            debounceTime(1000),
            untilDestroyed(this),
          )
          .subscribe(() => {
            if (!emergencyIds.length) {
              return;
            }
            (this.layers || [])
              .filter(
                (layer: IMapLayer) =>
                  layer.entityFilters.entity === 'Emergency' && layer.entityFilters.service === 'Emergency',
              )
              .forEach((layer: IMapLayer) => {
                // Удаление с карты измененных объектов
                emergencyIds.forEach((id: string) => {
                  this.mapModel.hideObject(layer.nameOnMap, id);
                  this.mapModel.removeObject(layer.nameOnMap, id);
                });

                if (!this.mapLayersFilters[layer.nameOnMap]) return;
                const mapLayerFilters = clone(this.mapLayersFilters[layer.nameOnMap], { includeNonEnumerable: true });
                // Если для слоя есть фильтр по МО, то учесть его при получении объектов
                if (this.mapLayersFilters['mo'][layer.nameOnMap]) {
                  mapLayerFilters.push(this.mapLayersFilters['mo'][layer.nameOnMap]);
                }
                // Если для слоя есть фильтр по ответственному, то учесть его при получении объектов
                if (this.mapLayersFilters['responsible'][layer.nameOnMap]) {
                  mapLayerFilters.push(this.mapLayersFilters['responsible'][layer.nameOnMap]);
                }
                // Запросить только данные по обновленным записям
                mapLayerFilters.push({
                  property: 'id',
                  value: emergencyIds,
                  operation: FilterOperationEnum.in,
                });
                // Очистка массива изменявшихся ID
                emergencyIds = [];

                this.getLayerData(layer, mapLayerFilters, false);
              });
          });

        const videoDeviceLayer: IMapLayer = this.layers.find((layer: IMapLayer) => layer.claim === 'WebcamsLayer');
        if (videoDeviceLayer) {
          // Массив для накопления ID записей которые изменились за секунду
          let videoDeviceIds = [];
          // Подписка на поток событий по видеоустройствам
          this.subs
            .onTableChange<IVideoDeviceBg>('Admin', 'VideoDevices')
            .pipe(
              tap((response) => videoDeviceIds.push(response.data.id)),
              debounceTime(1000),
              untilDestroyed(this),
            )
            .subscribe(() => {
              if (!videoDeviceIds.length) {
                return;
              }

              // Удаление с карты измененных объектов
              videoDeviceIds.forEach((id: string) => {
                this.mapModel.hideObject('Видеокамеры', id);
                this.mapModel.removeObject('Видеокамеры', id);
              });

              const mapLayerFilters = clone(this.mapLayersFilters['Видеокамеры'], { includeNonEnumerable: true });
              // Если для слоя есть фильтр по МО, то учесть его при получении объектов
              if (this.mapLayersFilters['mo']['Видеокамеры']) {
                mapLayerFilters.push(this.mapLayersFilters['mo']['Видеокамеры']);
              }
              // Если для слоя есть фильтр по ответственному, то учесть его при получении объектов
              if (this.mapLayersFilters['responsible']['Видеокамеры']) {
                mapLayerFilters.push(this.mapLayersFilters['responsible']['Видеокамеры']);
              }
              // Запросить только данные по обновленным записям
              mapLayerFilters.push({
                property: 'id',
                value: videoDeviceIds,
                operation: FilterOperationEnum.in,
              });
              // Очистка массива изменявшихся ID
              videoDeviceIds = [];

              this.getLayerData(
                this.layers.find((layer: IMapLayer) => layer.nameOnMap === 'Видеокамеры'),
                mapLayerFilters,
                false,
              );
            });
        }

        const electricitySupplyFacilitiesLayer: IMapLayer = this.layers.find(
          (layer: IMapLayer) => layer.claim === 'ElectricitySupplyFacilitiesLayer',
        );

        if (electricitySupplyFacilitiesLayer) {
          // Блок ниже закомментированного куска реализует ту же логику. Так как функционал критичный - оставлено для
          // возможности быстро откатиться.
          // const atmIntegration = this.settings.getDictionaryByTypeAndSysName('hcsIntegrationTypes', 'atmIntegration');
          // this.integrationService
          //   .getHcsIntegrationServerId(atmIntegration.id)
          //   .pipe(takeUntil(this.ngUnsubscribe))
          //   .subscribe((serverId: string) => {
          //     this.subs
          //       .onTableChange('AtmIntegration', 'MonitoringObject')
          //       .pipe(
          //         filter((object: IModifiedData<IAnyObject>) => object?.data?.serverId === serverId),
          //         takeUntil(this.ngUnsubscribe),
          //       )
          //       .subscribe((response: IModifiedData<IAnyObject>) => {
          //         let object: IAnyObject = this.mapLayersData.has(response.data.id)
          //           ? this.mapLayersData.get(response.data.id).element
          //           : <IEmergencyDto>{};
          //         object = { ...object, ...response.data };
          //         const item: IMapObjectDto = {
          //           id: object.id,
          //           coordinates: Coordinates.coordinatesToArray(object.coordinates),
          //           name: object.name,
          //         };
          //
          //         /**
          //          * Удаляем объект
          //          * Редкий кейс
          //          */
          //         this.mapModel.removeObject(electricitySupplyFacilitiesLayer.nameOnMap, item.id);
          //         if (response.action === 'delete') {
          //           this.mapLayersData.delete(item.id);
          //           return;
          //         }
          //
          //         if (!item.coordinates) {
          //           return;
          //         }
          //
          //         this.mapModel.addMarkerToLayer(
          //           electricitySupplyFacilitiesLayer.nameOnMap,
          //           item.id,
          //           item.coordinates,
          //           this.mapService.makeIcon(this.generateIcon(electricitySupplyFacilitiesLayer, object)),
          //           null,
          //           null,
          //           { text: item.name, direction: 'top' },
          //         );
          //       });
          //   });

          // Массив для накопления ID записей которые изменились за секунду
          let electricitySupplyFacilityIds = [];
          // Подписка на поток событий по объектам электроснабжения
          this.subs
            .onTableChange<IMonitoringObjectHcsDto>('AtmIntegration', 'MonitoringObject')
            .pipe(
              tap((response) => electricitySupplyFacilityIds.push(response.data.id)),
              debounceTime(1000),
              untilDestroyed(this),
            )
            .subscribe(() => {
              if (!electricitySupplyFacilityIds.length) {
                return;
              }

              // Удаление с карты измененных объектов
              electricitySupplyFacilityIds.forEach((id: string) => {
                this.mapModel.hideObject(electricitySupplyFacilitiesLayer.nameOnMap, id);
                this.mapModel.removeObject(electricitySupplyFacilitiesLayer.nameOnMap, id);
              });

              const mapLayerFilters = clone(this.mapLayersFilters[electricitySupplyFacilitiesLayer.nameOnMap], {
                includeNonEnumerable: true,
              });
              // Если для слоя есть фильтр по МО, то учесть его при получении объектов
              if (this.mapLayersFilters['mo'][electricitySupplyFacilitiesLayer.nameOnMap]) {
                mapLayerFilters.push(this.mapLayersFilters['mo'][electricitySupplyFacilitiesLayer.nameOnMap]);
              }
              // Если для слоя есть фильтр по ответственному, то учесть его при получении объектов
              if (this.mapLayersFilters['responsible'][electricitySupplyFacilitiesLayer.nameOnMap]) {
                mapLayerFilters.push(this.mapLayersFilters['responsible'][electricitySupplyFacilitiesLayer.nameOnMap]);
              }
              // Запросить только данные по обновленным записям
              mapLayerFilters.push({
                property: 'id',
                value: electricitySupplyFacilityIds,
                operation: FilterOperationEnum.in,
              });
              // Очистка массива изменявшихся ID
              electricitySupplyFacilityIds = [];

              this.getLayerData(electricitySupplyFacilitiesLayer, mapLayerFilters, false);
            });
        }

        const heatSupplyFacilitiesLayer: IMapLayer = this.layers.find(
          (layer: IMapLayer) => layer.claim === 'HeatSupplyFacilitiesLayer',
        );

        if (heatSupplyFacilitiesLayer) {
          // Блок ниже закомментированного куска реализует ту же логику. Так как функционал критичный - оставлено для
          // возможности быстро откатиться.
          // const itegration = this.settings.getDictionaryByTypeAndSysName('hcsIntegrationTypes', 'tesIntegration');
          // this.integrationService
          //   .getHcsIntegrationServerId(itegration.id)
          //   .pipe(takeUntil(this.ngUnsubscribe))
          //   .subscribe((serverId: string) => {
          //     this.subs
          //       .onTableChange('AtmIntegration', 'MonitoringObject')
          //       .pipe(
          //         mergeMap((object: IModifiedData<IAnyObject>) => {
          //           // Сервис удален. Вместо него при необходимости использовать AtmIntegrationService,
          //           return this.integrationService.getMonitoringObjectById(object.data.id).pipe(
          //             map((item: IAnyObject) => {
          //               (<IAnyObject>object).data = item;
          //               return object;
          //             }),
          //             takeUntil(this.ngUnsubscribe),
          //           );
          //         }),
          //         filter((object: IModifiedData<IAnyObject>) => object?.data?.serverId === serverId),
          //         takeUntil(this.ngUnsubscribe),
          //       )
          //       .subscribe((response: IModifiedData<IAnyObject>) => {
          //         let object: IAnyObject = this.mapLayersData.has(response.data.id)
          //           ? this.mapLayersData.get(response.data.id).element
          //           : <IEmergencyDto>{};
          //         const item: IMapObjectDto = {
          //           id: object.id,
          //           coordinates: Coordinates.coordinatesToArray(object.coordinates),
          //           name: object.name,
          //         };
          //
          //         /**
          //          * Удаляем объект
          //          * Редкий кейс
          //          */
          //         this.mapModel.removeObject(heatSupplyFacilitiesLayer.nameOnMap, item.id);
          //         if (response.action === 'delete') {
          //           this.mapLayersData.delete(item.id);
          //           return;
          //         }
          //
          //         if (!item.coordinates) {
          //           return;
          //         }
          //
          //         this.mapModel.addMarkerToLayer(
          //           heatSupplyFacilitiesLayer.nameOnMap,
          //           item.id,
          //           item.coordinates,
          //           this.mapService.makeIcon(this.generateIcon(heatSupplyFacilitiesLayer, object)),
          //           null,
          //           null,
          //           { text: item.name, direction: 'top' },
          //         );
          //       });
          //   });

          // Массив для накопления ID записей которые изменились за секунду
          let heatSupplyFacilityIds = [];
          // Подписка на поток событий по объектам теплоснабжения
          this.subs
            .onTableChange<IMonitoringObjectHcsDto>('AtmIntegration', 'MonitoringObject')
            .pipe(
              tap((response) => heatSupplyFacilityIds.push(response.data.id)),
              debounceTime(1000),
              untilDestroyed(this),
            )
            .subscribe(() => {
              if (!heatSupplyFacilityIds.length) {
                return;
              }

              // Удаление с карты измененных объектов
              heatSupplyFacilityIds.forEach((id: string) => {
                this.mapModel.hideObject(heatSupplyFacilitiesLayer.nameOnMap, id);
                this.mapModel.removeObject(heatSupplyFacilitiesLayer.nameOnMap, id);
              });

              const mapLayerFilters = clone(this.mapLayersFilters[heatSupplyFacilitiesLayer.nameOnMap], {
                includeNonEnumerable: true,
              });
              // Если для слоя есть фильтр по МО, то учесть его при получении объектов
              if (this.mapLayersFilters['mo'][heatSupplyFacilitiesLayer.nameOnMap]) {
                mapLayerFilters.push(this.mapLayersFilters['mo'][heatSupplyFacilitiesLayer.nameOnMap]);
              }
              // Если для слоя есть фильтр по ответственному, то учесть его при получении объектов
              if (this.mapLayersFilters['responsible'][heatSupplyFacilitiesLayer.nameOnMap]) {
                mapLayerFilters.push(this.mapLayersFilters['responsible'][heatSupplyFacilitiesLayer.nameOnMap]);
              }
              // Запросить только данные по обновленным записям
              mapLayerFilters.push({
                property: 'id',
                value: heatSupplyFacilityIds,
                operation: FilterOperationEnum.in,
              });
              // Очистка массива изменявшихся ID
              heatSupplyFacilityIds = [];

              this.getLayerData(heatSupplyFacilitiesLayer, mapLayerFilters, false);
            });
        }
      });

    const transformSubstratesUrl: (url: string) => string = (url: string): string => {
      return url.startsWith('/', 0) ? `//${window.location.host}${url}` : `//${url}`;
    };

    const substrates = this.substratesQuery.getAll();
    const activeSubstrate = (this.substratesQuery.getActive() as ISubstrateDto) || substrates?.[0];
    this.mapViewObjectsOptions = {
      mapId: 'baseMapWorkspace',
      url: substrates?.length > 1 ? undefined : transformSubstratesUrl(activeSubstrate.link),
      urls:
        substrates?.length > 1
          ? substrates.map((el: ISubstrateDto): IMapBaseBaseUrlsOptions => {
              return <IMapBaseBaseUrlsOptions>{
                url: transformSubstratesUrl(el.link),
                name: el.name,
                attribution: el.attribution,
                selected: activeSubstrate ? el.id === activeSubstrate.id : el.default,
              };
            })
          : undefined,
      center: this.getMunicipalCoordinates(),
      zoom: 16,
      maxZoom: 20,
      zoomControl: true,
      mapStyle: { width: '100%', height: '100%' },
    };

    this.mapViewObjectsOptions['attribution'] = substrates?.length > 1 ? undefined : activeSubstrate.attribution;

    /** Инициализируем модель карты для отображения результатов прогнозирования */
    this.mapModel
      .getObservableMapEvents()
      .pipe(
        filter(
          (event: IMapBaseEvent) =>
            event['mapObject']?.options?.mapId === 'baseMapWorkspace' || event.mapId === 'baseMapWorkspace',
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((event: IMapBaseEvent) => {
        switch (event.typeEvent) {
          case 'mapClick': {
            // Иконка для маркера точки пути
            const icon = this.mapService.makeIcon({
              iconSize: 8,
              iconColor: 'black',
              iconSVG: PATH_POINT_MARKER_SVG,
              shapeType: 'Круг',
              shapeSize: 10,
              shapeColor: 'black',
            });

            if (this.isYardstick && !this.isBuildingRoute) {
              this.pointsArray.push(event.coordinates);

              this.mapModel.addMarkerToLayer(
                'yardstick',
                `${this.pointsArray.length}`,
                event.coordinates,
                icon || null,
              );

              if (this.pointsArray.length >= 2) {
                const distance = Coordinates.distanceInKmBetweenEarthCoordinates(
                  this.pointsArray as [[number, number], [number, number]],
                ).toFixed(3);
                this.mapModel.removeObject('yardstick', 'path');
                this.mapModel.removeObject('yardstick', 'tooltip');
                this.mapModel.addPolylineToLayer(
                  'yardstick',
                  'path',
                  this.pointsArray,
                  { color: 'black', weight: 1 },
                  null,
                );
                this.mapModel.addMarkerToLayer('yardstick', 'tooltip', event.coordinates, icon || null, null, null, {
                  text: `${distance} км.`,
                  permanent: true,
                  className: 'yardstick-tooltip',
                });
              }
              this.mapModel.viewLayer('yardstick', false);
            }
            break;
          }
          case 'mapReady': {
            this.mapObjectModel = event.mapObject;
            this.bgMapService.mapModel = event.mapObject as L.Map;
            break;
          }
          default:
            break;
        }
      });

    this.bgMapService.positionMapOnCoordinates$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((coord: string) => {
      this.centerMap = new Coordinates(coord).toArray();
    });

    this.gisService.clickMarker$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        ({ coordinates, iconName, objectId }: { coordinates: Coordinates; iconName: string; objectId: string }) => {
          if (coordinates) {
            /** Формирую маркер как для нового инцидента по внешнему виду и добавляю в слой на мини карту */
            const markerIcon: IMapObjectIconOptions = {
              iconSVG: this.layers.find((item) => item.name === iconName).icon as string,
              iconSize: 26,
              iconColor: '#FB4B53',
              shapeColor: '#ffffff',
              shapeAnchor: [26, 76],
              shapeSize: 30,
              shapeType: 'Круг',
            };

            const markerShape: IMapObjectIconOptions = {
              shapeImgSize: [52, 76],
              iconColor: '#F3F3F3',
              shapeSVG: CLICK_MAKER_SHAPE_SVG,
              shapeAnchor: [26, 66],
              shapeImg: this.bgMapService.createClickMarkerIcon(),
            };

            this.mapModel.removeLayer(`${iconName} click marker`);
            this.mapModel.addMarkerToLayer(
              `${iconName} click marker`,
              objectId,
              coordinates.toArray(),
              this.mapService.makeClickMarker(markerShape, markerIcon, false),
            );
            this.mapModel.viewLayer(`${iconName} click marker`, false);
          } else if (objectId) {
            this.mapModel.removeObject(`${iconName} click marker`, objectId);
          } else {
            this.mapModel.removeLayer(`${iconName} click marker`);
          }
        },
      );

    /** TODO: Засинхронить с другими */
    this.bgMapService.polygon$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((coordinates: Coordinates[]) => {
      this.mapModel.removeLayer(LayersEnum.forestryFacilities);
      if (!coordinates) return;
      this.mapModel.addPolygonToLayer(
        LayersEnum.forestryFacilities,
        LayersEnum.forestryFacilities,
        coordinates.map((coordinate: Coordinates) => coordinate.toArray()),
        { color: '#0072C3', weight: 2 },
      );
      this.mapModel.viewLayer(LayersEnum.forestryFacilities, false);
    });

    // подписка на очистку слоя ЛХО/пользовательских полигонов
    this.bgMapService.forestryFacilitiesRemoved$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.mapModel.removeLayer(LayersEnum.forestryFacilities);
    });

    this.bgMapService.municipalityPolygon$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((polygon: IPolygonDto) => {
      if (!polygon) {
        this.mapModel.removeLayer(LayersEnum.municipalities);
        return;
      }
      this.mapModel.addPolygonToLayer(
        LayersEnum.municipalities,
        polygon.id,
        polygon.coordinates.map((coordinate: Coordinates) => coordinate.toArray()),
        { color: '#0072C3', weight: 2 },
      );
      this.mapModel.viewLayer(LayersEnum.municipalities, false);
    });

    this.bgMapService.districtPolygon$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((polygon: IPolygonDto) => {
      if (!polygon) {
        this.mapModel.removeLayer(LayersEnum.districts);
        return;
      }
      this.mapModel.addPolygonToLayer(
        LayersEnum.districts,
        polygon.id,
        polygon.coordinates.map((coordinate: Coordinates) => coordinate.toArray()),
        { color: '#0072C3', weight: 2 },
      );
      this.mapModel.viewLayer(LayersEnum.districts, false);
    });

    this.emergencyService.incidentSelectItem$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((incident: IMiniCardSelectionEvent) => {
        const toggle = this.toggleFormOpenCall || this.toggleFormCreateEvent || this.toggleEditEventFormOpen;
        const callForm = incident.callForm === undefined ? toggle : incident.callForm;
        this.router.navigate(
          [
            {
              outlets: {
                editForm: [incident.docType, incident.id, { callForm }],
              },
            },
          ],
          {
            relativeTo: this.route,
            queryParamsHandling: 'merge',
          },
        );
      });

    this.emergencyService.eventSelectItem$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((eventId: string) => {
      const editEventForm = eventId ? ['event', eventId] : null;
      this.router.navigate([{ outlets: { editEventForm } }], {
        relativeTo: this.route,
        queryParamsHandling: 'merge',
      });
    });

    this.emergencyService.incidentAddressLinkClicked$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((coordinates: Coordinates) => {
        this.selectCoordinates(coordinates);
      });

    /**
     * Подписка на изменения в инцидентах
     * */
    this.subs
      .onTableChange('Emergency', 'Emergency')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: IModifiedData<any>) => {
        if (result.action === 'update') {
          this.emergencyService.updateIncident(result.data.id);
        } else if (result.action === 'insert') {
          this.emergencyService.addNewIncident(result.data.id);
        }
      });
    /**
     * Подписка на изменения в событиях
     * */
    this.subs
      .onTableChange('Emergency', 'Events')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: IModifiedData<any>) => {
        if (result.action === 'update') {
          this.emergencyService.updateEvent(result.data.id);
        } else if (result.action === 'insert') {
          this.emergencyService.addNewEvent(result.data.id);
        }
      });
  }

  /**
   * Обработка событий карты
   * @param $event
   */
  public onMapViewObjectsEvent($event: IMapBaseObjectEvent | IMapBaseEvent | IMapViewObjectsEvent) {
    const event = $event as IMapBaseObjectEvent;
    if (event.mapId !== 'baseMapWorkspace' || this.isYardstick || this.isBuildingRoute) {
      return;
    }

    switch (event.typeEvent) {
      case 'click':
        this.showObjectInfo(event.layerId, event.objectId as string);
        if ((this.request && this.request.typeRequest === 'newEvent') || !event.coordinates) {
          return;
        }
        break;
    }
  }

  /**
   * Функция обработки событий фильтра
   * @param filter наименование и статус фильтра
   */
  public onFilterChanged(filter: { nameOnMap: string; selected: boolean }) {
    this.mapObjects = <IMapViewObjects>{};
    if (filter.selected) {
      const mapLayerFilters = [];
      /**
       * Обработка слоя "Транспортные средства"
       * Исключить getLayerData, поскольку данные поступают по интеграции, а не из справочника,
       */
      if (filter.nameOnMap === LayersEnum.rnis) {
        this.mapLayersFilters[filter.nameOnMap] = [];
        return;
      }

      // Если для слоя есть фильтр по МО, то учесть его при получении объектов
      if (this.mapLayersFilters['mo'][filter.nameOnMap]) {
        mapLayerFilters.push(this.mapLayersFilters['mo'][filter.nameOnMap]);
      }

      // Запрос данных, так как при отключенный слой мог иметь фильтры.
      // Из-за особенностей работы со слоями на карте одновременно запросить новые данные при очистке фильтров
      // и скрыть слой затруднительно.
      this.getLayerData(
        this.layers.find((layer: IMapLayer) => layer.nameOnMap === filter.nameOnMap),
        mapLayerFilters,
        true,
      );
      this.mapLayersFilters[filter.nameOnMap] = [];
    } else {
      this.mapObjects[filter.nameOnMap] = {
        isCluster: true,
        layerOperation: 'hide',
      };
      delete this.mapLayersFilters[filter.nameOnMap];
    }
  }

  /**
   * Отображение диалога с фильтрами слоев
   */
  public showLayerFilterDialog(hideMyIncidentsFilter?: boolean) {
    const dialogRef = this.dialog.open(MapLayersFiltersDialogComponent, {
      closeOnNavigation: true,
      panelClass: 'map-layers-filters-dialog-container',
      data: {
        mapLayersFilters: clone(this.mapLayersFilters, { includeNonEnumerable: true }),
        layersFilters: this.layers.reduce(
          (layersFilters, layer) => ({
            ...layersFilters,
            [layer.nameOnMap]: layer.entityFilters.filters,
          }),
          {},
        ),
        hideMyIncidentsFilter,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((mapLayersFilters: IAnyObject) => {
        // Диалог был закрыт
        if (!mapLayersFilters) {
          return;
        }

        // На диалоге была нажата кнопка Применить
        this.mapLayersFilters = clone(mapLayersFilters, { includeNonEnumerable: true });
        this.gisService.selectMapLayerFilter(this.mapLayersFilters);

        // Сообщить РНИС об изменении в фильтрах
        if (Object.keys(this.mapLayersFilters).includes(LayersEnum.rnis)) {
          this.rnisExternal?.sendMessage({
            action: 'onSetFilters',
            data: {
              filters: this.mapLayersFilters[LayersEnum.rnis],
            },
          });
        }
        const mapObjects = {};
        this.layers
          .filter((layer: IMapLayer) => layer.nameOnMap !== LayersEnum.rnis) /** Исключить слой ТС */
          .filter((layer: IMapLayer) => mapLayersFilters[layer.nameOnMap])
          .forEach((layer: IMapLayer) => (mapObjects[layer.nameOnMap] = { isCluster: true, layerOperation: 'remove' }));
        this.mapObjects = mapObjects;
        this.request = <IMapViewObjectsRequest>{ typeRequest: 'removeCirclesFromObject' };
        setTimeout(() => (this.request = <IMapViewObjectsRequest>{ typeRequest: 'removeClickMarker' }), 0);

        this.layers
          .filter((layer: IMapLayer) => layer.nameOnMap !== LayersEnum.rnis) /** Исключить слой ТС */
          .filter((layer: IMapLayer) => mapLayersFilters[layer.nameOnMap])
          .forEach((layer: IMapLayer) => {
            const mapLayerFilters = mapLayersFilters[layer.nameOnMap];
            if (mapLayersFilters['mo'][layer.nameOnMap]) {
              mapLayerFilters.push(mapLayersFilters['mo'][layer.nameOnMap]);
            }
            if (mapLayersFilters['responsible'][layer.nameOnMap]) {
              mapLayerFilters.push(mapLayersFilters['responsible'][layer.nameOnMap]);
            }
            this.getLayerData(layer, mapLayerFilters, true);
          });
      });
  }

  /**
   * Вызов меню
   */
  public onClickHeaderButton(): void {
    this.scNavService.openMenu();
  }

  /**
   * Поиск по адресу
   */
  public onSearch($event: IMapViewObjectsRequest): void {
    this.request = $event;
  }

  /**
   * Метод перехода/центрирования по координатам
   * @param coordinates координаты формата "55.0, 36.0"
   */
  public selectCoordinates(coordinates: Coordinates): void {
    if (coordinates.isValid()) {
      this.bgMapService.setPositionMapOnCoordinates(coordinates.toString());
      this.request = <IMapViewObjectsRequest>{
        typeRequest: 'addCirclesToObject',
        coordinates: coordinates.toArray(),
        layerId: 'Инциденты',
      };
    }
  }

  /**
   *  Позиционирование карты на коллекции объектов на карте
   *  @param objects объекты для позиционирования
   */
  public setPositionMapOnObjects(objects: IMapObjectDto[]): void {
    const allCoordinates = objects
      .filter((object: IMapObjectDto) => object.coordinates)
      .map((object: IMapObjectDto) => object.coordinates);

    if (!allCoordinates.length) {
      return;
    }

    // fitBounds and flyTo
    if (allCoordinates.length === 1) {
      this.mapObjectModel.flyToBounds(allCoordinates);
    }
  }

  /**
   * Обработка нажатия на кнопку линейка
   */
  public onYardstickEvent(): void {
    if (this.isBuildingRoute) {
      return;
    }
    this.isYardstick = !this.isYardstick;
    if (!this.isYardstick) {
      this.mapModel.removeLayer('yardstick');
      this.pointsArray = [];
    }
  }

  protected showObjectInfo(layerId: string, objectId: string) {
    switch (layerId) {
      case 'Неисправности видеокамер':
        this.emergencyService.selectIncident({
          id: objectId,
          callForm: null,
          docType: 'fault',
        });
        break;
      case 'Поручения':
        this.router.navigate([{ outlets: { leftPopup: ['emergency', objectId] } }], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Плановые работы':
        this.router.navigate([{ outlets: { leftPopup: ['emergency', objectId] } }], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Инциденты':
      case 'Инциденты ВА':
        this.router.navigate([{ outlets: { leftPopup: ['emergency', objectId] } }], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Видеокамеры': {
        this.router.navigate(['webCam', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      }
      case 'Важные объекты': {
        this.router.navigate(['significantObject', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      }
      case 'КСЭОН': {
        this.router.navigate(['kseon', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      }
      case 'ДДС городских служб и организаций':
      case 'Организации Экстренного реагирования':
        this.router.navigate(['eos', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Объекты теплоснабжения': {
        this.router.navigate([{ outlets: { leftPopup: ['heatSupplyFacility', objectId] } }], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      }
      case 'Объекты электроснабжения': {
        this.router.navigate([{ outlets: { leftPopup: ['electricitySupplyFacility', objectId] } }], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      }
      /**
       * Кейс "Транспортные средства" переехал в модуль РНИС {@link RnisModule}
       * Смотри {@link RnisControlLayerComponent}
       */
      case 'Объекты экомониторинга':
        this.router.navigate(['ecoMonitoringObject', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Пожароопасные объекты':
        this.router.navigate(['fireRiskObject', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Объекты пожарного мониторинга':
        this.router.navigate(['fireMonitoringObject', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Водоисточники':
        this.router.navigate(['waterSource', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      case 'Термоточки': {
        this.router.navigate(['thermopoint', objectId], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
        });
        break;
      }
    }
  }

  /**
   * Получение МО активного пользователя
   */
  private getMunicipal(): IAdminMunicipalSchemaDto {
    return this.settings.allMo.find((mo: IAdminMunicipalSchemaDto) => mo.id === this.user?.organizationId?.mo);
  }

  /**
   * Получение координат МО
   */
  private getMunicipalCoordinates(): MapBaseCoordinatesType {
    const mo = this.getMunicipal();
    if (mo && mo.coordinates) {
      return Coordinates.coordinatesToArray(mo.coordinates);
    }
    return Coordinates.coordinatesToArray(this.settings.getConfig().defaultCoordinates);
  }

  /**
   * Запрос данных по слоям
   * @param layer информация о слое
   * @param mapLayerFilters информация о фильтрах настроенных пользователем на карте
   * @param positioningMapOnObjects
   */
  private getLayerData(layer: IMapLayer, mapLayerFilters?: IMapLayerEntityFilter[], positioningMapOnObjects?: boolean) {
    /** Для слоя, по которому придут данные, делает иконку фильтра недоступной */
    const layerOption = this.layersOptions.find((layerOpt) => layerOpt.name === layer.nameOnMap);
    layerOption.disableFilterIcon = true;

    /** Ограничение загрузки */
    const limit = {
      paNumber: 0,
      paSize: LAYER_DATA_LOAD_LIMIT,
    };
    const attributes = layer.pictogramSettings?.map((settings: IPictogramSettingsDto): string => settings.indicator);

    of({})
      .pipe(
        switchMap(() => {
          return this.gisService.getLayerData(layer.entityFilters, limit, mapLayerFilters, attributes);
        }),
        map((items: IMapObjectDto[]) => {
          if ((items || []).length) {
            const input$ = of({
              data: [...items],
              settings: {
                layer,
                pictogramElements: this.settings.getDictionaryByTypeSysName('pictogramElements'),
                markerShapes: this.settings.getDictionaryByTypeSysName('markerShape'),
                userId: this.settings.currentUser.id,
                svgIconMap: this.svgIconMap,
              },
            } as any);

            const worker: Subscription = fromWorker<
              {
                data: IMapObjectDto[];
                settings: {
                  pictogramElements: IDictionaryInfo[];
                  markerShapes: IDictionaryInfo[];
                  userId: string;
                  svgIconMap: IAnyObject;
                  layer: IMapLayer;
                };
              },
              Map<string, IMapLayerData & { resultIcon: IMapBaseIcon }>
            >(
              () =>
                new Worker(new URL('../../../../../workers/initialize-map-objects.worker.ts', import.meta.url), {
                  type: 'module',
                }),
              input$,
            ).subscribe((response: Map<string, IMapLayerData & { resultIcon: IMapBaseIcon }>) => {
              let woCoords = 0;
              items.forEach((item: IMapObjectDto) => {
                if (item) {
                  if (item.coordinates) {
                    if ((item.coordinates[0] ?? 0) > 0 && (item.coordinates[1] ?? 0) > 0) {
                      const icon = response.get(item.id).resultIcon;
                      this.mapModel.addMarkerToLayer(layer.nameOnMap, item.id, item.coordinates, icon, null, null, {
                        text: item['street'] ? item['street'] : item.name,
                        direction: 'top',
                      });

                      this.mapLayersData.set(item.id, {
                        icon: response.get(item.id).icon,
                        element: item,
                        layerId: layer.nameOnMap,
                      });
                    } else {
                      woCoords++;
                    }
                  }
                }
              });

              this.mapModel.viewLayer(layer.nameOnMap, true);

              if (woCoords > 0) {
                ScConsole.warning(`Map. Слой ${layer.nameOnMap} объектов без координат ${woCoords}`);
              }

              worker.unsubscribe();
            });

            if (layer.nameOnMap === 'Важные объекты') {
              this.miniMapService.setSignificantObjects(items);
            }

            if (positioningMapOnObjects) {
              this.setPositionMapOnObjects(items);
            }
          }

          /** Инкриментируем страницу */
          limit.paNumber++;

          return items;
        }),
        repeat(),
        takeWhile((objects: IMapObjectDto[]) => {
          /** Если не достигли лимита, значит это была последняя/единственная "пачка" данных,
           * делаем иконку этого слоя доступной
           */
          if (objects.length !== LAYER_DATA_LOAD_LIMIT) {
            layerOption.disableFilterIcon = false;
          }

          return objects.length === LAYER_DATA_LOAD_LIMIT;
        }),
        takeUntil(this.ngUnsubscribe),
        catchError((err: Error) => {
          layerOption.disableFilterIcon = false;

          return this.catchErrorFn<IMapObjectDto[]>(err, `Ошибка при загрузке слоя "${layer.nameOnMap}"`);
        }),
      )
      .subscribe();
  }

  /** Открыть диалог с легендой */
  public openLegendDialog() {
    if (!this.availableForLegendLayers?.length) {
      return;
    }

    const pictDict = this.settings.getDictionaryByTypeSysName('pictogramElements');
    const markerShapeDict = this.settings.getDictionaryByTypeSysName('markerShape');

    const layersConfig = this.availableForLegendLayers
      .sort((a: IMapLayer, b: IMapLayer) => a.name.localeCompare(b.name))
      .map((layer: IMapLayer) => {
        const pictogramSettingsArray = layer.pictogramSettings.map((setting: IAnyObject) => {
          const pictogramSysName = pictDict.find((item) => item.id === setting.pictogramElement)?.sysname;

          const pictogramSettingsValuesArray = setting.pictogramSettingsValues.map((config: IAnyObject) => {
            const iconOptions: IMapObjectIconOptions = {
              iconSVG: layer.icon as string,
              iconSize: 17,
              iconColor: layer.iconColor,
              shapeType: ((layer.markerShape as IDictionaryInfo)?.name as IconShapeType) || 'Нет',
              shapeColor: layer.markerColor,
              shapeSize: 27,
              strokeColor: layer.borderColor,
            };
            switch (pictogramSysName) {
              case 'icon':
                iconOptions.iconSVG = this.svgIconMap[config.element];
                break;
              case 'iconColor':
                iconOptions.iconColor = config.element;
                break;
              case 'markerForm':
                iconOptions.shapeType = markerShapeDict.find((item) => item.id === config.element)
                  ?.name as IconShapeType;
                break;
              case 'markerColor':
                iconOptions.shapeColor = config.element;
                break;
              case 'borderColor':
                iconOptions.strokeColor = config.element;
                break;
            }

            return {
              title: config.valueAlias,
              icon: this.mapService.makeIcon(iconOptions),
            };
          });

          return {
            title: setting.alias,
            pictogramSettingsValuesArray: pictogramSettingsValuesArray,
          };
        });

        return {
          title: layer.nameOnMap,
          pictogramSettingsArray: pictogramSettingsArray,
        };
      });

    if (this.isLegendOpen) return;

    this.dialog
      .open(LegendDialogComponent, {
        minWidth: '350px',
        minHeight: '300px',
        maxHeight: '600px',
        disableClose: true,
        hasBackdrop: false,
        position: { left: '450px', bottom: '16px' },
        data: {
          configurations: layersConfig,
        },
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => (this.isLegendOpen = false));

    this.isLegendOpen = true;
  }

  /** Генерация иконки для объекта карты. Метод использовался в подписках на изменение таблиц,
   * для актуализации информации на карте. В настоящее время метод не используется, оставить его нужно, но не здесь.
   * Вынести в какой-нибудь сервис.
   */
  private generateIcon(layer: IMapLayer, element: IAnyObject): IMapObjectIconOptions {
    let icon: IMapObjectIconOptions;
    if (!this.mapLayersData.has(element.id)) {
      const markerShape = ((layer.markerShape || {}) as IDictionaryInfo).sysname;
      let size = 17;
      const color = '#ffffff';
      let shapeSize = 30;
      let shapeLineRadius = null;

      // TODO: вынести настройки в конструктор слоев. тикет на анализ(BG-14190).
      switch (markerShape) {
        case 'circle':
          shapeSize = 38;
          size = 22;
          break;
        case 'rectangle':
          shapeLineRadius = 5;
          shapeSize = 34;
          break;
        case 'diamond':
          shapeSize = 32;
          size = 18;
          break;
      }

      icon = {
        iconSVG: layer.icon as string,
        iconSize: size,
        iconColor: layer.iconColor ?? color,
        shapeType: ((layer.markerShape || {}) as IDictionaryInfo).name as TIconShape,
        shapeColor: layer.markerColor,
        shapeSize: shapeSize,
        strokeWidth: 3,
        strokeColor: layer.borderColor,
        shapeLineRadius: shapeLineRadius,
      };
    } else {
      icon = this.mapLayersData.get(element.id).icon;
    }

    if (layer.pictogramSettings) {
      const pictDict = this.settings.getDictionaryByTypeSysName('pictogramElements');
      const markerShapeDict = this.settings.getDictionaryByTypeSysName('markerShape');

      layer.pictogramSettings.map((setting: IPictogramSettingsDto) => {
        const pictogramSysName = pictDict.find(
          (item: IDictionaryInfo) => item.id === setting.pictogramElement,
        )?.sysname;
        setting.pictogramSettingsValues.map((config: IPictogramSettingsValueDto) => {
          switch (pictogramSysName) {
            case 'icon':
              if (
                this.operationsService.checkValueByOperation(
                  element[setting.indicator],
                  config.value,
                  config.property as FilterOperationEnum,
                )
              ) {
                icon.iconSVG = this.svgIconMap[config.element];
              }
              break;
            case 'iconColor':
              if (
                this.operationsService.checkValueByOperation(
                  element[setting.indicator],
                  config.value,
                  config.property as FilterOperationEnum,
                )
              ) {
                icon.iconColor = config.element;
              }
              break;
            case 'markerForm':
              if (
                this.operationsService.checkValueByOperation(
                  element[setting.indicator],
                  config.value,
                  config.property as FilterOperationEnum,
                )
              ) {
                icon.shapeType = markerShapeDict.find((item) => item.id === config.element)?.name as IconShapeType;
              }
              break;
            case 'markerColor':
              if (
                this.operationsService.checkValueByOperation(
                  element[setting.indicator],
                  config.value,
                  config.property as FilterOperationEnum,
                )
              ) {
                icon.shapeColor = config.element;
              }
              break;
            case 'borderColor':
              if (
                this.operationsService.checkValueByOperation(
                  element[setting.indicator],
                  config.value,
                  config.property as FilterOperationEnum,
                )
              ) {
                icon.strokeColor = config.element;
              }
              break;
          }
        });
      });
    }

    this.mapLayersData.set(element.id, {
      icon,
      element,
      layerId: layer.nameOnMap,
    });

    return icon;
  }
}
