import { IScSelectItem } from '@smart-city/core/interfaces';
import { IAnyObject } from 'smart-city-types';
import { FilterOperationEnum } from '../enums';

/**
 * Класс реализующий работы с операциями для реестра
 */
export class OperationFilterHelper {
  /** Возвращает список операций для select */
  public static operationsToArray(): IScSelectItem[] {
    return [
      {
        id: FilterOperationEnum.equal,
        text: 'Равно',
      },
      {
        id: FilterOperationEnum.notEqual,
        text: 'Не равно',
      },
      {
        id: FilterOperationEnum.greatThen,
        text: 'Больше',
      },
      {
        id: FilterOperationEnum.greatThenOrEqual,
        text: 'Больше или равно',
      },
      {
        id: FilterOperationEnum.lessThen,
        text: 'Меньше',
      },
      {
        id: FilterOperationEnum.lessThenOrEqual,
        text: 'Меньше или равно',
      },
      {
        id: FilterOperationEnum.or,
        text: 'Или',
      },
      {
        id: FilterOperationEnum.in,
        text: 'В',
      },
    ];
  }

  /** Преобразование типа операции и значение в объект для запроса */
  public static createValueByOperation(
    property: string,
    value: string,
    operation: FilterOperationEnum = FilterOperationEnum.equal,
  ): IAnyObject {
    const filter = <IAnyObject>{};
    if (property && value) {
      switch (operation) {
        case FilterOperationEnum.equal:
          filter[property] = value === 'null' ? null : value;
          break;
        case FilterOperationEnum.notEqual:
          filter[property] = { $ne: value === 'null' ? null : value };
          break;
        case FilterOperationEnum.lessThen:
          filter[property] = { $lt: value === 'null' ? null : value };
          break;
        case FilterOperationEnum.lessThenOrEqual:
          filter[property] = { $lte: value === 'null' ? null : value };
          break;
        case FilterOperationEnum.greatThen:
          filter[property] = { $gt: value === 'null' ? null : value };
          break;
        case FilterOperationEnum.greatThenOrEqual:
          filter[property] = { $gte: value === 'null' ? null : value };
          break;
        case FilterOperationEnum.in:
          filter[property] = { $in: value  };
          break;
        case FilterOperationEnum.or:
          filter['$or'] = (value || '').split(',').map((val: string) => {
            const lValue = val.trimLeft().trimRight();
            return this.createValueByOperation(property, lValue === 'null' ? null : lValue);
          });
      }
    }
    return filter;
  }
}
