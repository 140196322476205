<div class="row-bubble" [formGroup]="form">
  <ng-container [ngSwitch]="componentType">
    <sc-input *ngSwitchCase="'text'" [options]="textOptions" formControlName="answer"></sc-input>
    <div *ngSwitchCase="'radioButtons'" class="radio-container">
      <div id="radio-group-label" class="radio-group-label">{{ answer.question.text }}</div>
      <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" formControlName="answer">
        <mat-radio-button
          class="radio-button"
          *ngFor="let answerOption of answer?.question.answerOptions"
          [value]="answerOption"
          [disabled]="isHandled"
          >{{ answerOption }}
        </mat-radio-button>
        <mat-radio-button
          *ngIf="form.controls.otherAnswer"
          class="radio-button"
          [value]="'otherAnswer'"
          [disabled]="isHandled"
          >Другое
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <ng-container *ngSwitchCase="'checkboxes'" formArrayName="answer">
      <div class="checkbox-label">{{ answer.question.text }}</div>
      <sc-checkbox
        *ngFor="let control of checkboxesArray.controls; let i = index"
        [formControl]="$any(control)"
        [label]="(answer.question.answerOptions && answer.question.answerOptions[i]) || 'Другое'"
        [options]="checkboxesOptions[i]"
      >
      </sc-checkbox>
    </ng-container>
    <sc-select *ngSwitchCase="'select'" [options]="selectOptions" formControlName="answer"></sc-select>
    <sc-select *ngSwitchCase="'multiSelect'" [options]="multiSelectOptions" formControlName="answer"></sc-select>
  </ng-container>
  <ng-container *ngIf="wasChosenOptionOther()">
    <mat-divider></mat-divider>
    <sc-input [options]="otherTextOptions" formControlName="otherAnswer"></sc-input>
  </ng-container>
</div>
