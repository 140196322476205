import { Injectable } from '@angular/core';
import { ILimit, RestService, Settings2Service } from '@smart-city/core/services';
import { IBaseDictionaryData } from '@smart-city/core/interfaces';
import * as dayjs from 'dayjs';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData } from 'smart-city-types';
import { IMonitoringObjectHcsDto, IMonitoringObjectHcsView } from '../../../../dictionaries/modules/monitoring-objects-hcs/models/interfaces';
import { INotificationObjectsHcsDto, INotificationObjectsHcsView } from '../models/interfaces';
import { IOrganization } from '../../../../../../app-common/models/interfaces';
import { IMonitoringHcsServerDto } from '../../../../utility/modules/monitoring-hcs-server/interfaces';
import { IMiniCardSelectionEvent } from '@bg-front/core/models/interfaces';
import { ONE_MINUTE } from '@bg-front/core/models/constants';

/**
 * Сервис для работы со справочником объектов мониторинга ЖКХ
 */
@Injectable({ providedIn: 'root' })
export class NotificationsObjectsHcsService {
  /** Выбором одной из карточек реестра оповещений */
  private notificationsObjectHcsSelectItem = new Subject<IMiniCardSelectionEvent>();
  public notificationsObjectHcsSelectItem$ = this.notificationsObjectHcsSelectItem.asObservable();

  /** @ignore */
  constructor(private readonly rest: RestService, private readonly settings: Settings2Service) {}

  /**
   * Получение списка оповещений ЖКХ для реестра оповещений
   * @param heatSupplyFacility - информация об объекте мониторинга
   * @param limit - ограничения выборки информации
   */
  public getForRegistry(
    heatSupplyFacility: IMonitoringObjectHcsView,
    limit: ILimit,
  ): Observable<INotificationObjectsHcsView[]> {
    let query = {};
    let sort = [];
    if (heatSupplyFacility.integrationType === 'ATM') {
      query = {
        'monitoringObjectId.id': heatSupplyFacility.id,
        $not: { deactivation: true },
        $or: [
          {
            'typeId.sysname': '1',
            unlatchDt: 0,
          },
          {
            'typeId.sysname': '0',
            $and: [
              {
                latchDt: {
                  $gte:
                    heatSupplyFacility.serverLastTimeUpdate -
                    heatSupplyFacility.serverParamCollectionInterval * ONE_MINUTE,
                },
              },
              { latchDt: { $lte: heatSupplyFacility.serverLastTimeUpdate } },
            ],
          },
        ],
      };
      sort = [
        { field: 'typeId.sysname', direction: 'desc' },
        { field: 'latchDt', direction: 'desc' },
      ];
    }
    if (heatSupplyFacility.integrationType === 'ТЭС') {
      query = {
        'monitoringObjectId.id': heatSupplyFacility.id,
        $not: { 'stateId.sysname': '120' },
      };
      sort = [{ field: 'latchDt', direction: 'desc' }];
    }
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'AtmIntegration' },
      entity: {
        query,
        name: 'HcsNotification',
        attributes: [
          'id',
          'latchDt',
          'latchMessage',
          'typeId.name',
        ],
      },
      data: {
        sort,
        limit,
      },
    }).pipe(map((data: IAbstractServiceData) => {
      const notifications: INotificationObjectsHcsDto[] = data?.data?.items || [];
      return notifications.map((notification: INotificationObjectsHcsDto) => {
        return {
          id: notification.id,
          type: (<IBaseDictionaryData>notification.typeId).name,
          latchDt: notification.latchDt
            ? dayjs(notification.latchDt).format('DD.MM.YYYY HH:mm:ss')
            : '',
          latchMessage: notification.latchMessage,
        };
      });
    }));
  }

  /** Получение оповещения ЖКХ для формы просмотра/редактирования */
  public getForView(id: string): Observable<INotificationObjectsHcsView> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'AtmIntegration' },
      entity: {
        name: 'HcsNotification',
        query: { id },
        attributes: [
          'id',
          'monitoringObjectId.name',
          'monitoringObjectId.supplyTypeId.name',
          'monitoringObjectId.address',
          'monitoringObjectId.coordinates',
          'typeId.name',
          'stateId.name',
          'monitoringObjectId.extId',
          'active',
          'deactivation',
          'failureExtId',
          'condition',
          'latchDt',
          'latchMessage',
          'unlatchDt',
          'unlatchMessage',
          'deviceName',
          'deviceType',
          'deviceExtId',
          'nodeExtId',
          'nodeName',
          'nodeResourceTypeId.name',
          'monitoringObjectId.serverId.organizationId.name',
          'monitoringObjectId.serverId.integrationTypeId.name',
          'descriptionPath',
          'messages',
        ],
      },
    }).pipe(map((data: IAbstractServiceData) => {
      const notification: INotificationObjectsHcsDto = data?.data?.items[0];
      return !notification
        ? null
        : {
          id: notification.id,
          monitoringObject: (<IMonitoringHcsServerDto>notification.monitoringObjectId)?.name,
          supplyType: (<IBaseDictionaryData>(
              <IMonitoringHcsServerDto>(<IMonitoringObjectHcsDto>notification.monitoringObjectId)?.serverId
            )?.supplyTypeId
          )?.name,
          address: (<IMonitoringObjectHcsDto>notification.monitoringObjectId)?.address,
          coordinates: (<IMonitoringObjectHcsDto>notification.monitoringObjectId)?.coordinates,
          type: (<IBaseDictionaryData>notification.typeId)?.name,
          state: (<IBaseDictionaryData>notification.stateId)?.name,
          extId: (<IMonitoringObjectHcsDto>notification.monitoringObjectId)?.extId,
          active: notification.active,
          deactivation: notification.deactivation,
          failureExtId: notification.failureExtId,
          condition: notification.condition,
          latchDt: notification.latchDt
            ? dayjs(notification.latchDt).format('DD.MM.YYYY HH:mm:ss')
            : '',
          latchMessage: notification.latchMessage,
          unlatchDt: notification.unlatchDt
            ? dayjs(notification.unlatchDt).format('DD.MM.YYYY HH:mm:ss')
            : '',
          unlatchMessage: notification.unlatchMessage,
          deviceName: notification.deviceName,
          deviceType: notification.deviceType,
          deviceExtId: notification.deviceExtId,
          nodeExtId: notification.nodeExtId,
          nodeName: notification.nodeName,
          nodeResourceType: (<IBaseDictionaryData>notification.nodeResourceTypeId)?.name,
          organization: (<IOrganization>(
              <IMonitoringHcsServerDto>(<IMonitoringObjectHcsDto>notification.monitoringObjectId).serverId
            )?.organizationId
          )?.name,
          integrationType: (<IBaseDictionaryData>(
              <IMonitoringHcsServerDto>(<IMonitoringObjectHcsDto>notification.monitoringObjectId).serverId
            )?.integrationTypeId
          )?.name,
          descriptionPath: notification.descriptionPath,
          messages: (notification.messages || []).map(message => ({
            text: message.text,
            messageDt: message.messageDt
              ? dayjs(message.messageDt).format('DD.MM.YYYY HH:mm:ss')
              : '',
            state: this.settings.getDictionaryById(message.stateId)?.name,
          })),
        };
    }));
  }

  /**
   * Событие. Выбор оповещения в реестре оповещений ЖКХ
   */
  public selectNotificationsObjectHcs(event: IMiniCardSelectionEvent) {
    this.notificationsObjectHcsSelectItem.next(event);
  }
}
