import {
  IEmergencyDto,
  IEmergencyEventDto,
  IEmergencyVaDetailDto,
  IFiasValueDto,
  IIntegrationEventDto,
  IPlacementDetailsDto,
  IAppealDetailDto,
  IFileInfoDto,
  IForecastingDetailDto,
  IAnyObject,
} from 'smart-city-types';

/**
 * Модель нового события
 */
export class EmergencyEventDto implements IEmergencyEventDto {
  address?: IFiasValueDto = undefined;
  closeReasonId?: string = undefined;
  comment?: string = undefined;
  description?: string = undefined;
  details?: IPlacementDetailsDto = undefined;
  direction?: string = undefined;
  id: string = undefined;
  incidentId?: string | IEmergencyDto = undefined;
  isHandled?: boolean = undefined;
  ksipInfo?: string = undefined;
  ksipTypeId?: string = undefined;
  ksipDetailsId?: string = undefined;
  number?: string = undefined;
  parentId?: string | IIntegrationEventDto = undefined;
  responsibleId?: string = undefined;
  sourceId?: string = undefined;
  state?: string = undefined;
  timeCreate?: number = undefined;
  timeEnd?: string = undefined;
  timeStart?: number = undefined;
  timeTotal?: number = undefined;
  attractedServices?: string[] = undefined;
  attractionOrganizations?: IAnyObject[] = undefined;
  ksipTime?: number = undefined;
  sfsId?: string = undefined;
  threatOrganization?: boolean = undefined;
  threatPopulation?: boolean = undefined;
  vaDetail: string | IEmergencyVaDetailDto = undefined;
  appealDetail: string | IAppealDetailDto = undefined;
  organizationId: string = undefined;
  documents?: IFileInfoDto[] = [];
  forecastingDetailId: string | IForecastingDetailDto = undefined;
  workTermFrom?: number;
  workTermTo?: number;
  isSendingToJkhReform?: boolean;
  resourceConstraintType?: string;
  isNestedRestrictions?: boolean;
  jkhDirection?: string[];
  jkhObject?: string;
  viewed?: number;
  creationAuthor?: string;
  moId?: string;
  districtId?: string;
  byCoordinates?: boolean;
}
