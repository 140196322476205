import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { MapBaseService } from '@smart-city/maps/sc';
import { IMapExtFilterOptions } from '@smart-city/maps/sc/lib/components/map-extFilter/map-extFilter.component';
import { IAnyObject } from 'smart-city-types';
import { LayersEnum } from '@bg-front/core/models/enums';

/**
 * Панель фильтров слоев карты
 * @example
 * <bg-map-extFilter
 *   [options]="options"
 *   [matBadgeCount]="filtersCount"
 *   (filterEvent)="onFilterChanged($event)"
 *   (kebabEvent)="showLayerFilterDialog()"
 * ></bg-map-extFilter>
 */

@Component({
  selector: 'bg-map-extFilter',
  templateUrl: './map-extFilter.component.html',
  styleUrls: ['./map-extFilter.component.scss'],
})
export class BgMapExtFilterComponent implements OnInit, OnChanges {
  /** Параметры инициализации фильтра карты */
  @Input() public options: IMapExtFilterOptions[];

  /** Счетчик для примененных фильтров */
  @Input() public matBadgeCount: number = 0;

  /** Событие нажатия на кнопку фильтра */
  @Output() public filterEvent = new EventEmitter<{ nameOnMap: string; selected: boolean }>();

  /** Событие нажатия на кебаб */
  @Output() public kebabEvent = new EventEmitter<void>();

  /** Иконки для фильтра */
  public filters: IAnyObject[];

  /** Флаг подключения РНИС */
  public isRnis: boolean = false;

  constructor(private mapService: MapBaseService) {}

  /**
   * Инициализация фильтра слоев карты
   */
  public ngOnInit(): void {
    this.filters = this.options;
  }

  /**
   * Обработчик изменения входящих значений.
   * @param changes объект на карте
   */
  public ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes['options']?.currentValue) {
      this.filters = changes['options'].currentValue;
      this.filters.forEach((filter: IAnyObject) => this.changeIconView(filter));
      this.isRnis = this.filters.find((filter: IAnyObject) => filter.name === LayersEnum.rnis)?.selected;
    }
  }

  /**
   * Обработчик события выбор слоя карты, который генерирует событие на верх
   * @param filter данные о нажатом фильтре
   */
  public onFilterClick(filter: IAnyObject): void {
    filter.selected = !filter.selected;
    this.changeIconView(filter);

    /** Работа со слоем Транспортных средств и вкл/выкл РНИС */
    if (LayersEnum.rnis === filter.name) {
      this.isRnis = filter.selected;
    }
    this.filterEvent.emit({ nameOnMap: filter.name, selected: filter.selected });
  }

  /**
   * Обработчик события клика на кебаб для открытия формы фильтрации
   */
  public onKebabClick(): void {
    this.kebabEvent.emit();
  }

  private changeIconView(filter: IAnyObject) {
    if (filter.selected) {
      filter.iconOptions.iconColor = filter.iconColor;
      filter.iconOptions.shapeColor = filter.shapeColor;
    } else {
      filter.iconOptions.iconColor = '#FFFFFF';
      filter.iconOptions.shapeColor = '#8C8C8C';
    }
    filter.icon = this.mapService.makeIcon(filter.iconOptions);
  }
}
