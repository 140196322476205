import { FileSizeUnitEnum } from '@bg-front/core/models/enums';

export class FileSizeHelper {
  private static readonly base = 2;

  /** Конвертация из заданного unit в B
   * @param size - размер
   * @param unit - единица измерения для конвертации
   **/
  public static convertUnitToB(size: number, unit: FileSizeUnitEnum): number {
    switch (unit) {
      case FileSizeUnitEnum.b:
        return size;
      case FileSizeUnitEnum.kb:
        return size * Math.pow(this.base, 10);
      case FileSizeUnitEnum.mb:
        return size * Math.pow(this.base, 20);
      case FileSizeUnitEnum.gb:
        return size * Math.pow(this.base, 30);
    }
  }

  /**
   * @param fileSize - рзмер файла (B)
   * @param minSize - min размер файла в unit
   * @param unit - заданная единица измерения
   **/
  public static isMoreSize(fileSize: number, minSize: number, unit: FileSizeUnitEnum = FileSizeUnitEnum.b): boolean {
    return fileSize > this.convertUnitToB(minSize, unit);
  }

  /**
   * @param fileSize - рзмер файла (B)
   * @param maxSize - max размер файла в unit
   * @param unit - заданная единица измерения
   **/
  public static isLessSize(fileSize: number, maxSize: number, unit: FileSizeUnitEnum = FileSizeUnitEnum.b): boolean {
    return fileSize < this.convertUnitToB(maxSize, unit);
  }

  /**
   * @param fileSize - рзмер файла (B)
   * @param minSize - min размер файла в unit
   * @param maxSize - max размер файла в unit
   * @param unit - заданная единица измерения
   **/
  public static isBetweenSize(fileSize: number, minSize: number, maxSize: number, unit: FileSizeUnitEnum = FileSizeUnitEnum.b): boolean {
    return fileSize > this.convertUnitToB(minSize, unit) && fileSize < this.convertUnitToB(maxSize, unit);
  }

  /**
   * @param fileSize - рзмер файла (B)
   * @param minSize - min размер файла в unit
   * @param unit - заданная единица измерения
   **/
  public static isMoreSizeOrEqual(fileSize: number, minSize: number, unit: FileSizeUnitEnum = FileSizeUnitEnum.b): boolean {
    return fileSize >= this.convertUnitToB(minSize, unit);
  }

  /**
   * @param fileSize - рзмер файла (B)
   * @param maxSize - min размер файла в unit
   * @param unit - заданная единица измерения
   **/
  public static isLessSizeOrEqual(fileSize: number, maxSize: number, unit: FileSizeUnitEnum = FileSizeUnitEnum.b): boolean {
    return fileSize <= this.convertUnitToB(maxSize, unit);
  }

  /**
   * @param fileSize - рзмер файла (B)
   * @param minSize - min размер файла в unit
   * @param maxSize - max размер файла в unit
   * @param unit - заданная единица измерения
   **/
  public static isBetweenSizeOrEqual(fileSize: number, minSize: number, maxSize: number, unit: FileSizeUnitEnum = FileSizeUnitEnum.b): boolean {
    return fileSize >= this.convertUnitToB(minSize, unit) && fileSize <= this.convertUnitToB(maxSize, unit);
  }
}

