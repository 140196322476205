import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

/** Компонент диалогового окна для кастомного экспорта */
@Component({
  selector: 'bg-export-dialog',
  templateUrl: './export-dialog.component.html',
})
export class ExportDialogComponent {
  /** Заголовок */
  @Input() title?: string;

  /** Настройки селекта тип файла */
  @Input() public fileTypeDict?: { id: string, text: string }[] = [
    { id: 'xlsx', text: 'xlsx' },
    { id: 'xls', text: 'xls' },
    { id: 'csv', text: 'csv' },
  ];

  /** Тип файла */
  public fileType: string = null;

  /** @ignore */
  constructor(private modal: NzModalRef) {
  }

  /** Экспорт */
  public onExportHandler(): void {
    this.modal.destroy(this.fileType);
  }

  /** Отмена */
  public onCloseHandler(): void {
    this.modal.destroy();
  }
}
