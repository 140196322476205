import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { IAnyObject, IEmergencyDto } from 'smart-city-types';
import { BaseComponent } from '@bg-front/core/components';
import { IEmergencyForMiniCard } from '@bg-front/core/models/interfaces';
import { Uuid } from '@smart-city/core/utils';
import { IVehicle } from '../../../bg/modules/external-interactions/modules/vehicles/models/interfaces';
import { VehiclesService } from '../../../bg/modules/external-interactions/modules/vehicles/services/vehicles.service';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { EmergencyService } from '../../services';
import { RnisExternalService, RnisService } from '../../../rnis/services';
import { IRnisExternalMessage, IRnisResponse, IRnisState } from '../../../rnis/models/interfaces';
import * as dayjs from 'dayjs';
import { BgMapService } from '@bg-front/core/services';
import { Settings2Service } from '@smart-city/core/services';
import { Coordinates } from '@bg-front/core/models/classes';
import { MapBaseModel, MapBaseService } from '@smart-city/maps/sc';
import { LayersEnum } from '@bg-front/core/models/enums';
import { NzNotificationService } from 'ng-zorro-antd/notification';

/** Компонент формы просмотра Транспортного средства на карте */
@UntilDestroy()
@Component({
  selector: 'bg-vehicle-info',
  templateUrl: './vehicle-info.component.html',
  styleUrls: ['./vehicle-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleInfoComponent extends BaseComponent implements OnInit {
  /** Флаг открытия дровера */
  public openDrawer = false;
  /** Модель */
  public model: IVehicle;
  /** Форма маршрута */
  public form;
  /** Связанные происшествия */
  public relatedEmergencies: IEmergencyForMiniCard[] = [];
  /** Смещение по оси X */
  public offsetX = 364;

  /** Ограничение ввода начальной даты */
  public disabledFromDates = (startValue: Date): boolean => {
    if (!startValue) {
      return false;
    }
    const dateTimeTo = this.form?.controls.dateTimeTo.value;
    // Не позднее конечной даты, при её отсутствии не позднее текущего времени
    return !!dateTimeTo ? +dayjs(startValue) > +dayjs(dateTimeTo) : +dayjs(startValue) > +dayjs();
  };

  /** Ограничение ввода конечной даты */
  public disabledToDates = (endValue: Date): boolean => {
    if (!endValue) {
      return false;
    }
    const dateTimeFrom = this.form?.controls.dateTimeFrom.value;
    // Не позднее текущего времени и не раньше начальной даты
    return !!dateTimeFrom
      ? +dayjs(endValue) < +dayjs(dateTimeFrom) || +dayjs(endValue) > +dayjs()
      : +dayjs(endValue) > +dayjs();
  };

  /** Состояние датапикера начальной даты */
  public isFromDatePickerOpen: boolean = false;

  /** Состояние датапикера конечной даты */
  public isToDatePickerOpen: boolean = false;

  /** Модель карты */
  private mapModel: MapBaseModel;

  /** @ignore */
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly service: VehiclesService,
    private readonly cdr: ChangeDetectorRef,
    private readonly emergencyService: EmergencyService,
    private readonly rnisExternal: RnisExternalService,
    private readonly gisService: BgMapService,
    private readonly rnisService: RnisService,
    private readonly settings: Settings2Service,
    private readonly mapService: MapBaseService,
    notificationService: NzNotificationService,
  ) {
    super(notificationService);
  }

  /** @ignore */
  public ngOnInit(): void {
    this.mapModel = new MapBaseModel('baseMapWorkspace', this.mapService);

    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.model = undefined;
        const vehicleId = params.get('id');

        // Если ID невалидный не выполнять поиск и передать значение, что ничего найдено
        if (!vehicleId || !Uuid.isValid(vehicleId)) return of(undefined);

        return this.service.getById(vehicleId,[
          'id',
          'typeId.name',
          'modelId.name',
          'brandId.name',
          'stateNumber',
          'organizationId.name',
          'attId',
          'originalAtt',
        ]);
      }),
      switchMap((vehicle: IVehicle): Observable<IEmergencyDto[]> => {
        if (!vehicle) {
          this.noteService.pushError('Транспортное средство не найдено')
          this.close();
        }

        this.form = new FormGroup({
          inRealTime: new FormControl(true),
          dateTimeFrom: new FormControl({value: undefined, disabled: true}),
          dateTimeTo: new FormControl({value: undefined, disabled: true}),
        });

        // Очистка и блокировка полей при переключении радиобаттона
        this.form.controls.inRealTime.valueChanges.pipe(untilDestroyed(this))
        .subscribe((value: boolean) => {
          if (value) {
            this.form.controls.dateTimeFrom.setValue(undefined, { emitEvent: false });
            this.form.controls.dateTimeFrom.disable();
            this.form.controls.dateTimeTo.setValue(undefined, { emitEvent: false });
            this.form.controls.dateTimeTo.disable();
          } else {
            this.form.controls.dateTimeFrom.enable();
            this.form.controls.dateTimeTo.enable();
          }
          this.changeFormValue();
        });
        this.model = vehicle;
        return this.emergencyService.selectEmergencyByQuery({
            $and: [
              { $not: { 'lifeCycleStepId.endOfCycle': true } },
              { $expr: { $in: ['$involvedVehicles', this.model.id] } },
            ]
          },
          [
            'id',
            'coordinates',
            'timeCreate',
            'lifeCycleStepId.name',
            'incidentTypeId.name',
            'docType.sysname',
          ])
      }),
      catchError((err: Error) => {
        return this.catchErrorFn<IVehicle>(err, 'Ошибка при загрузке данных Транспортного средства');
      }),
      untilDestroyed(this),
    )
    .subscribe((res: any) => {

      // Получение координат и позиционирование на транспортном средстве
      if (!!this.model.originalAtt) {

        this.rnisService.getCurrent(this.settings.currentUser.id, [this.model.originalAtt]).pipe(
          catchError((err: Error) => {
            return this.catchErrorFn<IRnisState[]>(err, 'Ошибка получении координат Транспортного средства');
          }),
          untilDestroyed(this),
        ).subscribe((objects: IRnisState[]) => {
          if (objects?.length) {
            const coordinates = new Coordinates(
              objects[0].correctLatitude || objects[0].latitude,
              objects[0].correctLongitude || objects[0].longitude,
            );
            if (coordinates.isValid()) {
              this.gisService.setPositionMapOnCoordinates(coordinates.toString());
            }
          }
        });
      }

      this.relatedEmergencies = (res ?? []).map((item: IEmergencyDto) => {
        return {
          id: item.id,
          timeCreate: item.timeCreate,
          coordinates: item.coordinates,
          lifeCycleStepName: item.lifeCycleStepId['name'],
          incidentTypeName: item.incidentTypeId['name'],
          docTypeId: item.docType['id'],
        }
      })
      this.openDrawer = true;
      this.cdr.detectChanges();
      this.changeFormValue();
    });

    /** При открытии формы инцидента/события или другого объекта слоя, закрываем форму просмотра ТС */
    this.router.events
      .pipe(
        filter((event: IAnyObject) => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe((event: IAnyObject) => {
        if (event.url.match(/editForm|editEventForm|editCallForm|leftPopup/)) {
          this.close();
        }
      });

    this.rnisService.onRnisMessage$
      .pipe(untilDestroyed(this))
      .subscribe((message: IRnisResponse) => {
        if (message.command === 'find' && message.error) {
          this.notificationService.error('', 'Ошибка построения маршрута!');
        }
      })
  }

  /** Возвращает наименование свойства */
  public getPropertyName(property: string) {
    return this.model?.[property]?.name || '';
  }

  /** Закрытие формы просмотра */
  public close(): void {
    this.sendMessageToRnis({
      action: 'onRemoveTracking',
    });
    this.mapModel.removeLayer(LayersEnum.clickMarker);
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  /**
   * Функция вызывается при изменении состояния датапикера "Начальные дата и время".
   * Если датапикер закрыт, выбрана дата, значение "Конечные дата и время" пусто и
   * не выбирается в данный момент, ТОГДА через 5 секунд поле "Конечные дата и время" заполняется текущей датой
   */
  public onDateFromPickerOpenChange(value: boolean): void {
    this.isFromDatePickerOpen = value;
    const dateTimeFrom = this.form.controls.dateTimeFrom.value;
    if (!value && dateTimeFrom) this.changeFormValue();

    // изменение конечной даты через 5 секунд
    setTimeout(() => {
      const dateTimeTo = this.form.controls.dateTimeTo.value;
      if (!value && !this.isToDatePickerOpen && !dateTimeTo && dateTimeFrom) {
        this.form.controls.dateTimeTo.setValue(new Date(), { emitEvent: false });
        this.changeFormValue()
      }
    }, 5000);
  }

  /**
   * Функция вызывается при изменении состояния датапикера "Конечные дата и время"
   * Если датапикер закрыт, выбрана дата, значение "Начальные дата и время" пусто и
   * не выбирается в данный момент, ТОГДА через 5 секунд поле "Начальные дата и время" заполняется датой
   * на 1 час раньше чем "Конечные дата и время"
   */
  public onDateToPickerOpenChange(value: boolean): void {
    this.isToDatePickerOpen = value;
    const dateTimeTo = this.form.controls.dateTimeFrom.value;
    if (!value && dateTimeTo) this.changeFormValue();

    // изменение начальной даты через 5 секунд
    setTimeout(() => {
      const dateTimeFrom = this.form.controls.dateTimeFrom.value;
      if (!value && !this.isFromDatePickerOpen && !dateTimeFrom && dateTimeTo) {
        const oneHoreAgo = dateTimeTo.setHours( dateTimeTo.getHours() - 1 );
        this.form.controls.dateTimeFrom.setValue(oneHoreAgo, { emitEvent: false });
        this.changeFormValue()
      }
    }, 5000);
  }

  /**
   * Отправка сообщений компоненту модуля РНИС
   * @param message объект сообщения
   */
  private sendMessageToRnis(message: IRnisExternalMessage): void {
    this.rnisExternal.sendMessage(message);
  }

  /**
   * Событие изменения формы.
   * Инициирует отправку сообщений компоненту модуля РНИС
   */
  private changeFormValue(): void {
    if (!this.form.value?.inRealTime
      && this.form.value?.dateTimeTo
      && this.form.value?.dateTimeFrom
    ) {
      this.sendMessageToRnis({
        action: 'onIntervalTracking',
        data: {
          inRealTime: this.form.value.inRealTime,
          dateTimeTo: this.form.value.dateTimeTo.valueOf(),
          dateTimeFrom: this.form.value.dateTimeFrom.valueOf(),
          vehicle: this.model,
        },
      });
    }
    if (this.form.value?.inRealTime
      && !this.form.value?.dateTimeTo
      && !this.form.value?.dateTimeFrom
    ) {
      this.sendMessageToRnis({
        action: 'onRealTimeTracking',
        data: {
          inRealTime: this.form.value.inRealTime,
          vehicle: this.model,
        },
      });
    }
  }
}
