import { Pipe, PipeTransform } from '@angular/core';

/** Пайп для устранения запятой в отображении чисел */
@Pipe({
  name: 'noComma'
})
export class NoCommaPipe implements PipeTransform {
  transform(val: number | string): string {
    if (val !== undefined && val !== null) {
      return val.toString().replace(/,/g, '');
    } else {
      return '';
    }
  }
}
