import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@bg-front/core/components';
import { IElementButton, INwHeaderBarOptions, IScCheckboxOptions } from '@smart-city/core/common';
import { IEventViewFormDataDto, IKsionEventDto, IKsionAggregatedDto } from '../../models/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { EmergencyService, UnitServersService } from '../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/** Форма просмотра события КСЭОН */
@UntilDestroy()
@Component({
  selector: 'bg-ksion-event-view-form',
  templateUrl: './ksion-event-view-form.component.html',
  styleUrls: ['./ksion-event-view-form.component.scss']
})
export class KsionEventViewFormComponent extends BaseComponent implements OnInit {
  /** Событие по которому создали инцидент */
  @Input()
  public model: IEventViewFormDataDto;
  /** Конфигурация заголовка */
  public headerActionsOptions: INwHeaderBarOptions = undefined;
  /** Имя ссылки на неисправность */
  public linkedName: string;
  /** Настройки чекбокса переключения на адрес по координатам */
  public byCoordinatesOptions: IScCheckboxOptions = {
    title: 'Адрес задан координатами',
    disabled: true,
  };
  /** Наименование устройства */
  public deviceName: string;

  /** @ignore */
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly emergencyService: EmergencyService,
    private readonly unitService: UnitServersService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.unitService.getKsionEvent(this.model.id, ['extId.name']).pipe(untilDestroyed(this))
      .subscribe((event: IKsionEventDto) => this.deviceName = (event.extId as IKsionAggregatedDto)?.name);

    this.linkedName = `Неисправность ${this.model.incidentNumber}`;
    this.headerActionsOptions = {
      title: `Событие ${this.model.number}`,
      name: 'header',
      margin: 'collapse',
      bgColor: 'white',
      buttons: [
        {
          type: 'button',
          options: {
            name: 'cancel',
            icon: 'clear',
          },
        },
      ],
    }
  }

  /** Открываем форму неисправности */
  public openEmergency(): void {
    const id = typeof this.model.incidentId === 'object' ? this.model.incidentId['id'] : this.model.incidentId;
    const targetUrl = `http://${window.location.host}/edds/workspace/(editForm:incident/${ id }//leftSidebar:all)`;
    window.open(targetUrl);
  }

  /**
   * Обрабатываем нажатие кнопки управления состоянием
   * @param $event
   */
  public onClickActionsButton($event: IElementButton) {
    this.emergencyService.selectEvent(undefined);
    if ($event.options.name === 'cancel') {
      this.closeActiveForm();
    }
  }

  public closeActiveForm() {
    const outlet = this.route.outlet;
    this.router
      .navigate([{ outlets: { [outlet]: null } }], {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge',
      })
      .then(() => this.emergencyService.selectEvent(undefined));
  }
}
