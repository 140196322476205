import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IPolygonDto } from '@bg-front/core/models/interfaces';
import { BgMapService, OperationsService } from '@bg-front/core/services';
import { BaseRegistryComponent } from '@bg-front/registry-panel/components';
import { IRegistryPanelDto } from '@bg-front/registry-panel/models';
import { RegistryPanelService } from '@bg-front/registry-panel/services';
import {
  IElementButton,
  INwHeaderBarOptions,
  IScrollableContainerLoadDataFnParams,
  IScrollableContainerLoadDataFnResult,
  IScrollableContainerOptions,
  ScrollableContainerComponent,
} from '@smart-city/core/common';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';

import { PolygonsService } from '../../services';
import { UserPolygonMiniCardComponent } from '../user-polygon-mini-card/user-polygon-mini-card.component';

/**
 * Контейнер для отображения списка пользовательских полигонов
 */
@Component({
  selector: 'bg-user-polygon-registry',
  templateUrl: './user-polygon-registry.component.html',
  styleUrls: ['./user-polygon-registry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OperationsService],
})
export class UserPolygonRegistryComponent extends BaseRegistryComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Настройки скролла для Полигонов */
  public userPolygonsOptions: IScrollableContainerOptions;

  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions = {
    title: 'Пользовательские полигоны',
    margin: 'collapse',
    buttons: [
      {
        type: 'button',
        options: {
          name: 'cancel',
          icon: 'chevron_left',
        },
      },
    ],
  };

  /** Ссылка на компонент отображающий пользовательские полигоны */
  @ViewChildren('userPolygonsScroll')
  public userPolygonsScrollList: QueryList<ScrollableContainerComponent>;

  /** Ссылка на элемент */
  public userPolygonsScroll: ScrollableContainerComponent;

  /** Метод сортировки по убыванию
   * @param items - список сортируемых элементов
   */
  private sortDesc(items: IAnyObject[]): IAnyObject[] {
    return items.sort((a, b) => b.data.creationTime - a.data.creationTime);
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly polygonsService: PolygonsService,
    private readonly gisService: BgMapService,
    private panelService: RegistryPanelService,
    route: ActivatedRoute,
    router: Router,
    operationsService: OperationsService,
  ) {
    super(route, router, operationsService);
  }

  /** @ignore */
  public ngOnInit(): void {
    /** Подписываемся на обновление списка полигонов */
    this.polygonsService.polygonListUpdate$
      .pipe(
        mergeMap(() => this.userPolygonsScroll.refresh()),
        catchError((err: Error) =>
          this.catchErrorFn<IScrollableContainerLoadDataFnResult>(err, 'Ошибка при обновлении списка полигонов'),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();

    this.route.params
      .pipe(
        switchMap((params: Params) => {
          return this.panelService.getRegistryPanelById(params['id']);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((registry: IRegistryPanelDto) => {
        this.registry = registry;

        super.ngOnInit();

        this.userPolygonsOptions = {
          loadDataFn: (params: IScrollableContainerLoadDataFnParams) => {
            return this.polygonsService.getPolygonsBySysname('04-userPolygons').pipe(
              map((userPolygons: IPolygonDto[]) => {
                if (userPolygons.length) {
                  const polygonCards = userPolygons.map((el) => {
                    return {
                      data: el,
                      type: UserPolygonMiniCardComponent,
                    };
                  });
                  const sortedPolygonCards = this.sortDesc(polygonCards);

                  return <IScrollableContainerLoadDataFnResult>{
                    currentPage: params.page,
                    data: sortedPolygonCards,
                  };
                }

                return <IScrollableContainerLoadDataFnResult>{
                  currentPage: params.page,
                  data: [null],
                };
              }),
              catchError((err: Error) =>
                this.catchErrorFn<IScrollableContainerLoadDataFnResult>(err, 'Ошибка при запросе полигонов'),
              ),
              takeUntil(this.ngUnsubscribe),
            );
          },
          limit: this.pageSize,
          maxPage: this.maxPage,
        };
        this.cdr.detectChanges();
      });
  }

  /** @ignore */
  public ngAfterViewInit() {
    this.userPolygonsScrollList.changes
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((comps: QueryList<ScrollableContainerComponent>) => {
        if (comps.first) {
          this.userPolygonsScroll = comps.first;
          this.userPolygonsScroll.getNextData();
        } else {
          this.userPolygonsScroll?.ngOnDestroy();
          this.userPolygonsScroll = undefined;
        }
      });
  }

  /** @ignore */
  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.gisService.removeForestryFacilities();
  }

  /**
   * Обработка событий из заголовка
   * @param $event событие
   */
  public override clickHeaderButton($event: IElementButton): void {
    this.router.navigate([{ outlets: { leftSidebar: ['all'] } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }
}
