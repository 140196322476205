import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormGroup } from '@angular/forms';
import { ISelectItem } from '@bg-front/core/models/interfaces';
import { WeatherDataService } from '@bg-front/weather-data/services';
import { FormBuilder } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { ForecastingRadioactiveEnvironmentTasksFormFactory } from '../../models';
import { ForecastingRadioactiveEnvironmentService } from '../../services';
import { BaseForecastingRadioactiveEnvironmentComponent } from '../base-forecasting-radioactive-environment/base-forecasting-radioactive-environment.component';

@UntilDestroy()
@Component({
  selector: 'bg-time-allowed-to-overcome-cloud-trail-task',
  templateUrl: './time-allowed-to-overcome-cloud-trail-task.component.html',
  styleUrls: ['./time-allowed-to-overcome-cloud-trail-task.component.scss'],
})
export class TimeAllowedToOvercomeCloudTrailTaskComponent
  extends BaseForecastingRadioactiveEnvironmentComponent
  implements OnInit, OnDestroy
{
  /***********************************
   * Настройка опций контролей формы *
   ***********************************/
  /** Единицы измерения */
  public units: ISelectItem[] = this.getDictionaryForSelect('unitRadiation');

  public unitName: string;

  /** Расположение укрытия */
  public shelterLocations: ISelectItem[] = this.getDictionaryForSelect('locationShelterFromRadiation');

  /** Ссылка на группу фильтров */
  public pathPointsArray: FormArray;
  /** Тип укрытия */
  public shelterTypes: ISelectItem[] = this.forecastingService.shelterTypeForLocationAppointment();

  /** Этажи */
  public shelterFloors = this.getDictionaryForSelect('floorShelterFromRadiation');

  constructor(
    private readonly forecastingService: ForecastingRadioactiveEnvironmentService,
    public readonly controlContainer: ControlContainer,
    private fb: FormBuilder,
    settings2: Settings2Service,
    weatherService: WeatherDataService,
    notificationService: NzNotificationService,
  ) {
    super(settings2, weatherService, notificationService);
  }

  /** @ignore */
  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
    this.pathPointsArray = this.form.get('pathPoints') as FormArray;

    if (!this.form.controls.unitRadiationId.value) {
      this.form.controls.unitRadiationId.setValue(
        this.settings.getDictionaryByTypeAndSysName('unitRadiation', 'centigray')?.id,
      );

      this.unitName = this.settings.getDictionaryByTypeAndSysName('unitRadiation', 'centigray')?.text;
    }

    this.form
      .get('unitRadiationId')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        this.unitName = this.settings.getDictionaryById(value)?.name;
      });

    this.form
      .get('shelterLocationId')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((uuid: string) => {
        const localStreetId = this.settings
          .getDictionaryByTypeSysName('locationShelterFromRadiation')
          .find((item: IDictionaryInfo) => {
            return item.sysname === 'localAppointmentStreet';
          }).id;
        this.shelterTypes =
          localStreetId === uuid
            ? this.getDictionaryForSelect('typeShelterFromRadiation')
            : this.forecastingService.shelterTypeForLocationAppointment();
      });

    this.form
      .get('shelterTypeId')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((uuid: string) => {
        const shelterTypeSysname: string = this.settings
          .getDictionaryByTypeSysName('typeShelterFromRadiation')
          .filter(({ id }) => {
            return id === uuid;
          })[0]?.sysname;
        this.shelterFloors = this.forecastingService.shelterFloorForType(shelterTypeSysname);
      });
  }

  /** Добавляем новую сущность */
  public addPoint() {
    const idx = this.pathPointsArray.controls.length;
    this.pathPointsArray.setControl(
      idx,
      ForecastingRadioactiveEnvironmentTasksFormFactory.overcomingPathPointFormGroup(this.fb, undefined),
    );
  }

  /** Удаляем сущность */
  public removeFilter(idx: number) {
    this.pathPointsArray.removeAt(idx);
  }
}
