import { Injectable } from '@angular/core';
import { Settings2Service } from '@smart-city/core/services';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { RestService } from '@smart-city/core/services';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IStoredChemicalDto } from 'smart-city-types';

/**
 * Сервис для создания/редактирования/удаления хранимых АХОВ
 */
@Injectable({
  providedIn: 'root',
})
export class StoredChemicalsService {
  /**
   * Позволяет следить за удалением хранимых АХОВ
   */
  private storedChemicalDeleted = new Subject<number>();
  public storedChemicalDeleted$ = this.storedChemicalDeleted.asObservable();

  constructor(private readonly rest: RestService, private readonly settings2: Settings2Service) {}

  /**
   * Метод создания/сохранения записи
   * @param data информация о хранимом АХОВ
   */
  public saveStoredChemical(data: IStoredChemicalDto): Observable<IAbstractServiceData> {
    if (data?.id) {
      return this.rest.serviceRequest({
        data,
        action: 'update',
        service: { name: 'Forecasting' },
        entity: {
          name: 'StoredChemicals',
          query: {
            ...(data.id ? { id: data.id } : {}),
            ...(data.forecastingParams ? { forecastingParams: data.forecastingParams } : {}),
          },
        },
      });
    }
    if (data?.forecastingParams) {
      return this.rest
        .serviceRequest({
          action: 'select',
          service: { name: 'Forecasting' },
          entity: {
            name: 'StoredChemicals',
            query: {
              forecastingParams: data.forecastingParams,
            },
          },
        })
        .pipe(
          mergeMap(
            ({
              data: {
                items: [item],
              },
            }: IAbstractServiceData) => {
              if (item) {
                return this.rest.serviceRequest({
                  data,
                  action: 'update',
                  service: { name: 'Forecasting' },
                  entity: {
                    name: 'StoredChemicals',
                    query: {
                      ...(data.forecastingParams ? { forecastingParams: data.forecastingParams } : {}),
                    },
                  },
                });
              }
              return this.rest.serviceRequest({
                data,
                action: 'insert',
                service: { name: 'Forecasting' },
                entity: {
                  name: 'StoredChemicals',
                },
              });
            },
          ),
        );
    }
    return this.rest.serviceRequest({
      data,
      action: 'insert',
      service: { name: 'Forecasting' },
      entity: {
        name: 'StoredChemicals',
      },
    });
  }

  /**
   * Получить все хранимые АХОВ для важного объекта
   * @param significantObject id важного объекта
   */
  public getStoredChemicalsForSignificantObject(significantObject: string): Observable<IStoredChemicalDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: {
          name: 'StoredChemicals',
          attributes: [
            'id',
            'significantObject',
            'forecastingParams',
            'chemicalType',
            'chemicalType.id',
            'chemicalType.name',
            'aggregationState',
            'aggregationState.id',
            'aggregationState.name',
            'chemicalAmount',
            'containerPressure',
            'containerType',
            'containerType.id',
            'containerType.name',
            'containerHeight',
          ],
          query: {
            significantObject,
            chemicalType: {
              id: {
                $ne: null,
              },
            },
            aggregationState: {
              id: {
                $ne: null,
              },
            },
          },
        },
      })
      .pipe(
        map((res) => {
          return (res?.data?.items || []).map((el: IAnyObject) => {
            const item = <IStoredChemicalDto>{
              id: el['id'],
              significantObject: el['significantObject'],
              forecastingParams: el['forecastingParams'],
              chemicalType: el['chemicalType']?.id,
              aggregationState: el['aggregationState']?.id,
              chemicalAmount: el['chemicalAmount'],
              containerPressure: el['containerPressure'],
              containerType: el['containerType']?.id,
              containerHeight: el['containerHeight'],
            };
            item['chemicalType.name'] = el['chemicalType.name'];
            item['aggregationState.name'] = el['aggregationState.name'];
            item['containerType.name'] = el['containerType.name'];
            return item;
          }) as IStoredChemicalDto[];
        }),
      );
  }

  /**
   * Получить конкретный АХОВ, хранимый на важном объекте
   * @param significantObject id важного объекта
   * @param chemicalType id типа АХОВ
   */
  public getStoredChemicalFromSignificantObject(
    significantObject: string,
    chemicalType: string,
  ): Observable<IStoredChemicalDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: {
          name: 'StoredChemicals',
          query: {
            significantObject,
            chemicalType,
          },
        },
      })
      .pipe(
        map((res) => {
          return res?.data?.items[0] as IStoredChemicalDto;
        }),
      );
  }

  /**
   * Получить АХОВ для параметров прогнозирования
   * @param forecastingParams id параметров прогнозирования
   */
  public getStoredChemicalForForecastingParams(forecastingParams: string): Observable<IStoredChemicalDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: {
          name: 'StoredChemicals',
          query: {
            forecastingParams,
          },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return res?.data?.items[0] as IStoredChemicalDto;
        }),
      );
  }

  /** Проверяет свободный тип разлива */
  public isFreeDischarge(id: string): boolean {
    const result = this.settings2
      .getDictionaryByTypeSysName('emergencyChemicalSubstances')
      .find((item: IDictionaryInfo) => item.id === id);
    return result.sysname === 'free';
  }

  /** Проверяет самостоятельный тип ёмкости для АХОВ */
  public hasOwnPallet(id: string): boolean {
    const result = this.settings2
      .getDictionaryByTypeSysName('typeEmergencyChemicalSubstances')
      .find((item: IDictionaryInfo) => item.id === id);
    return result.sysname === 'selfPallet';
  }

  /**
   * Удалить хранимый АХОВ.
   * @param id id АХОВ
   */
  public deleteStoredChemical(id: string) {
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: 'Forecasting' },
      entity: {
        name: 'StoredChemicals',
        query: {
          id,
        },
      },
    });
  }

  /**
   * Удалить хранимые АХОВ для важного объекта.
   * @param significantObject id важного объекта
   * @param excludeIds список ID которые не требуется удалять
   */
  public deleteStoredChemicalsForSignificantObject(significantObject: string, excludeIds?: string[]) {
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: 'Forecasting' },
      entity: {
        name: 'StoredChemicals',
        query: {
          id: !!excludeIds && !!excludeIds.length
            ? { $nin: excludeIds }
            : undefined,
          significantObject,
        },
      },
    });
  }

  /**
   * Возвращает агрегатное состояние для типа АХОВ
   * @param chemicalTypeId Id типа АХОВ
   * */
  public getAggregationState(chemicalTypeId: string): Observable<string> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: {
          name: 'ChemicalType',
          attributes: ['aggregationState.name'],
          query: { id: chemicalTypeId },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return (res.data.items[0] || {})['aggregationState.name'];
        }),
      );
  }

  /**
   * Кидаем событие на удаление хранимого АХОВ
   */
  public throwStoredChemicalDeleteEvent(id: number) {
    this.storedChemicalDeleted.next(id);
  }
}
