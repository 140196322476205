import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BaseComponent } from '@bg-front/core/components';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { GisService, IGisServiceResult } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';

import {
  IEmergencyForReport,
  IForecastingConsequencesOfForestFiresResultDto,
  IForecastingConsequencesOfForestFiresTaskParamsDto,
  IForecastingForestFireReport,
} from '../../models/interfaces';
import {
  ForecastingFiresReportService,
  ForecastingFiresService,
  ForecastingFiresVisualizationService,
} from '../../services';

@UntilDestroy()
@Component({
  selector: 'bg-forecasting-consequences-of-forest-fires-result',
  templateUrl: './forecasting-consequences-of-forest-fires-result.component.html',
  styleUrls: ['./forecasting-consequences-of-forest-fires-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastingConsequencesOfForestFiresResultComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public isShow = false;

  @Input()
  public forecastingResults: IForecastingResultDto<
    IForecastingConsequencesOfForestFiresTaskParamsDto,
    IForecastingConsequencesOfForestFiresResultDto
  >;

  /** Скачать отчёт */
  @Input()
  public downloadReport: Observable<void>;

  /** Уведомляем о наличии отчёта */
  @Output()
  public hasReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  /** Уведомляем о формировании отчёта */
  @Output()
  public isDownloadReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  /** Ссылка на слой результата отрисовки */
  private displayElement: any;
  /** Ссылка на координаты */
  private displayCoordinates: any;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly forecastingService: ForecastingFiresService,
    private readonly forecastingVisualizationService: ForecastingFiresVisualizationService,
    private readonly gisService: GisService,
    private readonly reportingService: ForecastingFiresReportService,
    private readonly rest: RestService,
    private readonly settings: Settings2Service,
  ) {
    super();
  }

  /** Результат прогнозирования */
  public get result(): IForecastingConsequencesOfForestFiresResultDto {
    return this.forecastingResults.result;
  }

  /** Параметры */
  public get params(): IForecastingConsequencesOfForestFiresTaskParamsDto {
    return this.forecastingResults.params.params;
  }

  public ngOnInit(): void {
    if (!this.isShow) {
      this.forecastingVisualizationService
        .displayConsequencesOfForestFires(this.forecastingResults.result, this.forecastingResults.params.params)
        .pipe(
          catchError((err: Error) => this.catchErrorFn<any[]>(err, 'Ошибка при отображении зоны на карте')),
          untilDestroyed(this),
        )
        .subscribe((res: IAnyObject) => {
          this.displayElement = res?.elements;
          this.displayCoordinates = res?.coordinates;
          if (this.displayCoordinates) {
            this.hasReport.emit(true);
          }
        });
    }

    this.downloadReport.pipe(untilDestroyed(this)).subscribe(() => this.createReport());

    this.cdr.markForCheck();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.forecastingVisualizationService.clearResults(this.displayElement);
  }

  /** Скачивание */
  public createReport(): void {
    const params = this.params;
    const result = this.result;
    // const reportObs = [];
    this.isDownloadReport.emit(true);
    [...this.displayCoordinates].forEach((coords: IAnyObject[], index: number) => {
      if (!!coords && coords?.length) {
        coords = [...coords, coords[0]];
      }
      return forkJoin([
        (this.forecastingResults?.objectId
          ? this.rest.serviceRequest({
              action: 'select',
              service: { name: 'Forecasting' },
              entity: {
                name: 'ForestryFacilities',
                query: { id: this.forecastingResults.objectId },
              },
            })
          : of(undefined)
        ).pipe(
          map((res: IAbstractServiceData) => res?.data?.items?.[0]),
          catchError((err: Error) => {
            this.catchErrorFn(err, err.message);
            return of(null);
          }),
        ),
        this.forecastingResults.params.emergencyId
          ? this.forecastingService.getIncidentData(this.forecastingResults.params.emergencyId).pipe(
              catchError((err: Error) => {
                this.catchErrorFn(err, err.message);
                return of(null);
              }),
            )
          : of(undefined),
        (coords?.length ? this.gisService.getAddressesByPolygonV2(coords as number[][]) : of(null)).pipe(
          catchError((err: Error) => {
            this.catchErrorFn(err, err.message);
            return of(null);
          }),
        ),
        (coords?.length
          ? this.forecastingService.getKseonUnit({
              point: {
                $polygon: {
                  $points: coords,
                },
              },
            })
          : of(null)
        ).pipe(
          catchError((err: Error) => {
            this.catchErrorFn(err, err.message);
            return of(null);
          }),
        ),
      ])
        .pipe(
          mergeMap(
            ([forestryFacility, emergency, buildings, units]: [
              IAnyObject,
              IEmergencyForReport,
              IGisServiceResult[],
              IAnyObject,
            ]) => {
              const report: IForecastingForestFireReport = {};
              const header: string =
                this.settings.getDictionaryById(params.fireTypeId)?.sysname === 'upstream'
                  ? 'верхового пожара'
                  : 'низового пожара';

              /** Заголовок страницы 2 */
              report.ws2Header = `Расчёт последствий ${header}`;

              /** Заголовок страницы 4 */
              report.ws4Header = `Здания в зоне возможного распространения ${header}`;

              /** Заголовок страницы 5 */
              report.ws5Header = `Средства оповещения в зоне возможного распространения ${header}`;

              /** Блок "Используемая методология для прогнозирования" */
              report.methodology = 'Методика оценки последствий крупных лесных пожаров, Москва, ВНИИ ГОЧС';

              /** Блок "Местоположение" */
              report.coordinates = `${params.coordinates}`;
              report.object = forestryFacility?.name;
              report.responsible = forestryFacility?.responsible;
              report.phone = forestryFacility?.phone;

              /** Блок "Исходные данные" */
              report.incident = emergency?.number || 'не задано';
              report.occurrenceTime = emergency?.timeCreate
                ? dayjs(emergency?.timeCreate).format('DD.MM.YYYY HH:mm')
                : 'не задано';
              report.fireType = this.settings.getDictionaryById(params.fireTypeId)?.name;
              report.fireTime = +(params.burningTimes[index].burningTime || 0);
              report.initOutbreakArea = (+(params.size || 0)).toFixed(2);
              report.fireHeight = (+(params.height || 0)).toFixed(2);

              /** Блок "Метеоусловия" */
              report.windSpeed = (+(params.windVelocity || 0)).toFixed(1);
              report.windDirection = (+(params.windDirection || 0)).toFixed(0);
              report.weatherFireHazard = this.settings.getDictionaryById(params.fireHazardTypeId)?.name;

              /** Блок "Характеристика лесных насаждений" */
              report.forestType = this.settings.getDictionaryById(params.forestTypeId)?.name;
              report.forestDiameters = this.settings.getDictionaryById(params.trunkDiameterId)?.name;
              report.burningClass = +params.flammabilityClass === 1 ? 'I' : 'II';
              report.forestNote =
                +params.flammabilityClass === 1
                  ? 'Чистые и с примесью лиственных пород хвойные насаждения (кроме лиственничных)'
                  : 'Чистые с примесью хвойных пород лиственные насаждения, а также лиственничные насаждения';

              const fireType = this.settings.getDictionaryById(params.fireTypeId)?.name;
              /** Блок "Результаты расчёта" */
              report.typeOfFire = `${(result.fireForce || '').toLowerCase()} ${fireType.toLowerCase()} ${(
                result.upperFireType || ''
              ).toLowerCase()} пожар`;
              report.speedFront = result.frontSpeed;
              report.speedFlanks = result.wingsSpeed;
              report.speedRear = result.backsideSpeed;
              report.fireZoneCombustion = (+(result.firePerimeters[index] || 0)).toFixed(2);
              report.fireZoneArea = (+(result.fireSizes[index] || 0)).toFixed(2);
              report.standDamage = result.damageDegree;
              report.standState = result.stateTreesText?.replace(/\s\s+/g, ' ');
              report.unusableTrees = result.unusableTrees;

              /** Блок "Здания в зоне возможного распространения техногенного пожара" */
              report.buildings = [...(buildings || [])];

              /** Блок "Средства оповещения в зоне возможного распространения лесного пожара" */
              report.devices = [
                ...(units || []).map((item) => {
                  return {
                    type: item.type,
                    name: item.name,
                    currentSeanceName: item.currentSeanceName,
                    syncTime: item.syncTime,
                  };
                }),
              ];

              this.reportingService.buildForecastingForestFireReport(report);
              return of();
            },
          ),
          finalize(() => this.isDownloadReport.emit(false)),
          untilDestroyed(this),
        )
        .subscribe();
    });
  }
}
