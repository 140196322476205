import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { INwHeaderBarOptions } from '@smart-city/core/common';
import { ISort, NotificationService, ScNavService } from '@smart-city/core/services';
import { ScConsole } from '@smart-city/core/utils';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Observable, of } from 'rxjs';
import { IAnyObject } from 'smart-city-types';

import { ITableSortableColumns } from '../../models/interfaces/table-sortable-columns.interface';

/**
 * Базовый класс для всех компонент
 * Реализует отписку от событий и базовый ngOnDestroy
 */
@UntilDestroy()
@Directive()
export abstract class BaseDashboardComponent<T = IAnyObject, K = IAnyObject> implements OnInit {
  public filterCount = 0;
  public data: T[] = [];

  public currentPageData: readonly T[] = [];

  public setOfCheckedId: Set<string> = new Set<string>();

  public indeterminateSelect: boolean = false;
  public selectAll: boolean = false;
  public isLoading: boolean = false;
  /** Общее количествл */
  public totalCount: number = 0;
  public pageSize: number = 15;
  public pageIndex: number = 1;
  public filter: K = <K>{};

  /** Ссылка на элемент на котором вызвано контекстное меню */
  public contextMenuSelectedRow: T;
  /** Сортируемые колонки */
  public sortableColumns: ITableSortableColumns = {};
  /**
   * Настройки заголовка
   */
  public headerOptions!: INwHeaderBarOptions;
  /** Выбранный элемент */
  public selected: T;

  /** ID выбранной записи при редактировании или удалении через контекстное меню */
  protected currentId: string;

  constructor(
    public readonly notificationService: NotificationService,
    public readonly nzContextMenuService: NzContextMenuService,
    public readonly route: ActivatedRoute,
    protected readonly scNavService: ScNavService,
  ) {}

  public ngOnInit(): void {
    /**
     * Настройки заголовка
     */
    this.headerOptions = {
      title: this.route.snapshot.data?.title,
      name: 'header',
      margin: 'collapse',
      bgColor: 'white',
      buttons: [
        {
          type: 'button',
          options: {
            name: 'burger',
            icon: 'menu',
          },
        },
      ],
    };
  }

  /**
   * Функция обработки ошибок в запросах к бэку
   * Реализует стратегию "Перехватить и заменить"
   * @param err Ошибка
   * @param defaultMessage сообщение, которое будет показано
   * @param defaultValue
   */
  protected catchErrorFn<T>(
    err: Error,
    defaultMessage: string = 'Ошибка при запросе',
    defaultValue: T = undefined,
  ): Observable<T> {
    this.notificationService.pushError(defaultMessage);
    ScConsole.error([err.message]);
    return of(defaultValue);
  }

  /** Обновление статуса  */
  public refreshCheckedStatus(): void {
    if (this.currentPageData?.length) {
      this.selectAll = this.currentPageData.every((el: T) => this.setOfCheckedId.has((el as IAnyObject).id));
      this.indeterminateSelect =
        this.currentPageData.some((el: T) => this.setOfCheckedId.has((el as IAnyObject).id)) && !this.selectAll;
    } else {
      this.selectAll = false;
      this.indeterminateSelect = false;
    }
  }

  /** Событие выбора элемента */
  public onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  /** Выбор всех доступных на странице элементов */
  public onAllChecked(checked: boolean): void {
    this.currentPageData.forEach((el: T) => this.updateCheckedSet((el as IAnyObject).id, checked));
    this.refreshCheckedStatus();
  }

  /** Обновление статуса выбора элемента*/
  private updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  /** Сохраняем текущий список элементов */
  public onCurrentPageDataChange(listOfCurrentPageData: readonly T[]): void {
    this.currentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  /** Обработка события изменения параметров таблицы */
  public onQueryParamsChange(params: NzTableQueryParams): void {
    this.reload();
  }

  /** Преобразование сортировки */
  public getSort(): ISort {
    const currentSort = Object.keys(this.sortableColumns).find(
      (key: string) => this.sortableColumns[key].order !== null,
    );

    if (currentSort) {
      let sortOrder = undefined;
      switch (this.sortableColumns[currentSort].order) {
        case 'ascend': {
          sortOrder = 'asc';
          break;
        }
        case 'descend': {
          sortOrder = 'desc';
          break;
        }
      }

      if (sortOrder) {
        return {
          field: this.sortableColumns[currentSort].key,
          direction: sortOrder,
        };
      }
    }

    return undefined;
  }

  /** Абстрактная функция обновления */
  public abstract reload(params?: NzTableQueryParams): void;

  /** Абстрактная функция обновления */
  public abstract delete(ids: string | string[]): void;

  /** Массовое удаление выделенных элементов */
  public deleteSelected() {
    if (this.setOfCheckedId.size) {
      this.delete(Array.from(this.setOfCheckedId));
    }
  }

  /** Функция очистки списка выделенных записей после удаления
   * так же устанавливает текущий индекс страницы
   * @param numberOfDeletedIds - кол-во удаленных записей
   * @returns void
   */
  public clearCheckedIds(numberOfDeletedIds?: number): void {
    /** Вычитаемое от общего кол-ва записей, если параметр передали, то параметр,
     * если нет, то по умолчанию размер сета выделенных записей
     */
    const subtrahend = numberOfDeletedIds || this.setOfCheckedId.size;

    const newPageIndex = Math.ceil((+this.totalCount - subtrahend) / this.pageSize);
    if (newPageIndex < this.pageIndex) {
      this.pageIndex = newPageIndex || 1;
    }

    /** При сохраненном id удаляем только его, если его нет - множественное удаление */
    if (this.currentId) {
      this.setOfCheckedId.delete(this.currentId);
      this.currentId = undefined;
    } else {
      this.setOfCheckedId.clear();
    }
  }

  /**
   * Функция подготовки запроса
   *
   * По умолчанию возвращает undefined
   * @return IAnyObject | undefined
   */
  public prepareQuery(): IAnyObject | undefined {
    return undefined;
  }

  /**
   * Функция сброса сортировки
   * @returns void
   * */
  public resetOrder(): void {
    Object.keys(this.sortableColumns).forEach((key: string) => (this.sortableColumns[key].order = null));
  }

  /**
   * Функция вызова контекстного меню для отдельно взятой строки
   * @param {MouseEvent} $event - Событие нажатия на ПКМ
   * @param menu
   * @param item
   * @return Void
   * */
  public contextMenuOpen($event: MouseEvent, menu: NzDropdownMenuComponent, item?: T): void {
    this.nzContextMenuService.create($event, menu);
    this.contextMenuSelectedRow = item;
  }

  /** Устанавливаем значения фильтра или сбрасываем его */
  public setFilterValue(value?: K) {
    this.filter = value;
    this.pageIndex = 1;
    this.reload();
  }

  /**
   * Вызов меню
   */
  public onClickHeaderButton() {
    this.scNavService.openMenu();
  }

  /** Функция отслеживания для trackBy */
  public trackByFunc(index: number, item: T): any {
    return item['id'] ?? item['name'] ?? index;
  }

  //TODO подмать над переносом метода деактивации формы (onFormDeactivate) редактирования в базовый класс
}
