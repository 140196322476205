import { Component, OnInit } from '@angular/core';
import { LoosesListComponent } from '../looses-list/looses-list.component';
import { IScInputOptions } from '@smart-city/core/common';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'death-list',
  templateUrl: './death-list.component.html',
  styleUrls: ['./death-list.component.scss'],
})
export class DeathListComponent extends LoosesListComponent implements OnInit {
  /** Настройка компоненты Планируемое место захоронения */
  public burialPlaceOptions: IScInputOptions = {
    label: 'Планируемое место захоронения',
    placeholder: 'Планируемое место захоронения',
    maxLength: 100,
  };

  /** Настройка компоненты Место нахождения тела */
  public bodyLocationOptions: IScInputOptions = {
    label: 'Место нахождения тела',
    placeholder: 'Место нахождения тела',
    maxLength: 100,
  };

  /** @ignore */
  public override ngOnInit(): void {
    super.ngOnInit();
    this.listData.forEach((item) =>
      (<FormArray>this.form.controls.list).push(
        new FormGroup({
          id: new FormControl(item.id),
          fio: new FormControl(item.fio),
          birthYear: new FormControl(item.birthYear),
          burialPlace: new FormControl(item.burialPlace),
          bodyLocation: new FormControl(item.bodyLocation),
          comment: new FormControl(item.comment),
        }),
      ),
    );
  }

  /**
   * Добавление записи
   */
  public add() {
    (<FormArray>this.form.controls.list).push(
      new FormGroup({
        fio: new FormControl(),
        birthYear: new FormControl(),
        burialPlace: new FormControl(),
        bodyLocation: new FormControl(),
        comment: new FormControl(),
      }),
    );
  }
}
