import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SelectAllOptionsA } from '@datorama/akita';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IKsipDetailsDto } from '../../../models/interfaces';
import { KsipDetailsState } from '../../../models/states';
import { KsipDetailsStore } from '../store/ksip-details-store.service';

@Injectable({
  providedIn: 'root',
})
export class KsipDetailsQuery extends QueryEntity<KsipDetailsState> {
  constructor(store: KsipDetailsStore) {
    super(store);
  }

  /** Запрос по Id*/
  public getById(id: string): IKsipDetailsDto {
    return this.getEntity(id);
  }

  /** Запрос по множеству Id*/
  public getByIds(ids: string[]): Observable<IKsipDetailsDto[]> {
    return this.selectMany(ids);
  }

  /** Запрос всех элементов */
  public getAllData(): Observable<IKsipDetailsDto[]> {
    return this.selectAll();
  }

  /** Запрос по множеству Id*/
  public getForSelect(options?: SelectAllOptionsA<IKsipDetailsDto>): Observable<IKsipDetailsDto[]> {
    return this.selectAll(options || {}).pipe(
      map((items: IKsipDetailsDto[]) =>
        items.map((item: IKsipDetailsDto) => <IScSelectItem>{ id: item.id, text: item.name }),
      ),
    );
  }
}
