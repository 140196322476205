import { Pipe, PipeTransform } from '@angular/core';

import { OrganizationsQuery } from '../../services/organizations-store/query/organizations-query.service';

/**
 * Пайп для отображения тултипа в селекте для организаций
 */
@Pipe({
  name: 'organization',
})
export class OrganizationPipe implements PipeTransform {
  constructor(private organizationsQuery: OrganizationsQuery) {}

  /**
   *@param id - Id организации
   *@return string
   */
  transform(id: string): string {
    if (id) {
      return this.organizationsQuery.getEntity(id)?.name;
    }

    return id;
  }
}
