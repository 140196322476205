import { Component } from '@angular/core';
import { ControlContainer, FormBuilder, FormControl } from '@angular/forms';
import { IScInputOptions, IScTextareaOptions } from '@smart-city/core/common';
import { ITechnologicalFireAdditionalInfo } from '../../models/interfaces';

/**
 * Компонента реализует возможность просмотра и редактирования дополнительной информации при прогнозировании
 * техногенного пожара
 */
@Component({
  selector: 'bg-technological-fire-additional-info',
  templateUrl: './technological-fire-additional-info.component.html',
  styleUrls: ['./technological-fire-additional-info.component.scss'],
})
export class TechnologicalFireAdditionalInfoComponent {
  /** Задействовано техники */
  public totalCarsOption: IScInputOptions = {
    label: 'Задействовано техники',
    mask: '0*',
  };

  /** Задействовано стволов */
  public trunksOption: IScInputOptions = {
    label: 'Задействовано стволов',
    mask: '0*',
  };

  /** Задействовано личного состава */
  public personalOption: IScInputOptions = {
    label: 'Задействовано личного состава',
    mask: '0*',
  };

  /**
   * Настройка компоненты Ведение пожара
   */
  public actualOptions: IScTextareaOptions = {
    label: 'Ведение пожара',
    maxLength: 1000,
    rows: 14,
  };

  /** @ignore */
  constructor(public controlContainer: ControlContainer) {
  }

  /** Генерация FormGroup */
  public static generateFormGroup(fb: FormBuilder, data: ITechnologicalFireAdditionalInfo) {
    return fb.group({
      totalCars: new FormControl(data.totalCars),
      trunks: new FormControl(data.trunks),
      personal: new FormControl(data.personal),
      actual: new FormControl(data.actual),
      timeDetection: new FormControl(data.timeDetection),
      timeDeparture: new FormControl(data.timeDeparture),
      timeArrival: new FormControl(data.timeArrival),
      timeFirstSalvo: new FormControl(data.timeFirstSalvo),
      timeLocalization: new FormControl(data.timeLocalization),
      timeLiquidation: new FormControl(data.timeLiquidation),
      timeRecovery: new FormControl(data.timeRecovery),
    });
  }
}
