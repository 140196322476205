import { Injectable } from '@angular/core';
import { IRnisState } from '../../models/interfaces';
import { ScConsole } from '@smart-city/core/utils';

/**
 * Вспомогательный сервис по работе с РНИС для хранения навигационных данных от ТС
 */
@Injectable({ providedIn: 'root' })
export class RnisTelematicsService {
  /** Реестр активных объектов */
  private naviObjects = new Map<string, IRnisState>();

  /**
   * Записывает все переданные объекты в реестр
   * @param objs объект/массив объектов с активными АТТ
   */
  public register(objs: IRnisState | IRnisState[] | undefined): void {
    if (!objs) return;
    const naviArray = Array.isArray(objs) ? objs : [objs];
    naviArray.forEach((obj: IRnisState) => {
      /**
       * Проверка наличия координат в объекте
       */
      const isCorrectCoordinates = obj.correctLatitude && obj.correctLongitude;
      const latitude = isCorrectCoordinates ? obj.correctLatitude : obj.latitude;
      const longitude = isCorrectCoordinates ? obj.correctLongitude : obj.longitude;
      if (latitude && longitude) {
        this.addNaviObjects({
          ...obj,
          correctLatitude: latitude,
          correctLongitude: longitude,
        });
      }
    });
  }

  /**
   * Добавить объект в реестр
   * @param obj объект данных активного АТТ
   */
  private addNaviObjects(obj: IRnisState): void {
    if (obj.id) {
      this.naviObjects.set(obj.id, obj);
    } else {
      ScConsole.warning('[NaviArchiveService] Попытка добавить объект к активным АТТ без id');
    }
  }

  /**
   * Получить объект(массив объектов) по ключу
   * @param key идентификатор(id объекта) устройства АТТ
   * @returns массив объектов
   */
  public getNaviObjects(key?: string | undefined): IRnisState | IRnisState[] | undefined {
    if (!key) return Array.from(this.naviObjects.values());
    return this.naviObjects.get(key);
  }

  /**
   * Получить объект(массив объектов) по ключу
   * @param ids идентификатор(id объекта) устройства АТТ
   * @returns массив объектов
   */
  public getNaviObjectsByIds(ids: string[]): IRnisState[] {
    return Array.from(this.naviObjects.keys())
      .filter((value: string) => ids.includes(value))
      .map((id: string) => this.naviObjects.get(id)!);
  }

  /**
   * Получить объект по идентификатору АТТ, являющемся частью ключа массива объектов
   * @param attId идентификатору АТТ, привязанный к ТС
   * @returns объект из реестра активных объектов
   */
  public findNaviObjects(attId: string): IRnisState | undefined {
    if (!attId) return undefined;
    let obj: IRnisState | undefined = undefined;

    for (const key of this.naviObjects.keys()) {
      if (key.includes(attId)) {
        obj = { ...(this.getNaviObjects(key) as IRnisState) };
      }
    }
    return obj;
  }

  /**
   * Очищает реестр активных объектов если для новой подписки
   */
  public clearNaviObjects(): void {
    this.naviObjects = new Map<string, IRnisState>();
  }
}
