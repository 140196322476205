<div #wrapper style="width: 100%; height: 100%; position: relative; background-color: black">
  <canvas #player style="width: 100%; max-height: 100vh; background-color: black; display: block"></canvas>
  <div *ngIf="showControls" style="position: absolute; left: 50%; bottom: 14px; transform: translateX(-50%)">
    <nz-space>
      <ng-container *ngIf="showRewindButtons()">
        <button *nzSpaceItem nz-button (click)="moveTimeBack()">
          <span nz-icon nzType="step-backward"></span>
        </button>
      </ng-container>
      <button *nzSpaceItem nz-button (click)="togglePlaying()">
        <i *ngIf="!isPlaying" nz-icon nzType="caret-right"></i>
        <i *ngIf="isPlaying" nz-icon nzType="pause"></i>
      </button>
      <button *nzSpaceItem nz-button (click)="toggleDialog()">
        <i nz-icon nzType="fullscreen" *ngIf="!options.fullScreen"></i>
        <i nz-icon nzType="fullscreen-exit" *ngIf="options.fullScreen"></i>
      </button>
      <ng-container *ngIf="showRewindButtons()">
        <button *nzSpaceItem nz-button (click)="moveTimeForward()">
          <span nz-icon nzType="step-forward"></span>
        </button>
      </ng-container>
    </nz-space>
  </div>
</div>

<!--<div class="mjpeg-player" [ngClass]="playerStyles">-->
<!--  <div class="mjpeg-player__wrapper">-->
<!--    <canvas #player class="mjpeg-player__img"></canvas>-->
<!--    <div class="mjpeg-player__controls">-->
<!--      <div class="mjpeg-player__timing" *ngIf="showTimeButtons">-->
<!--        <button type="button" class="mgpeg-player__replay" (click)="moveTimeBack()">-->
<!--          <mat-icon>replay_30</mat-icon>-->
<!--        </button>-->
<!--        <button type="button" class="mgpeg-player__forward" (click)="moveTimeForward()">-->
<!--          <mat-icon>forward_30</mat-icon>-->
<!--        </button>-->
<!--      </div>-->
<!--      <div class="mjpeg-player__options">-->
<!--        <button-->
<!--          type="button"-->
<!--          class="mgpeg-player__fullscreen"-->
<!--          *ngIf="!options.fullScreen"-->
<!--          (click)="openVideoOnDialog()"-->
<!--        >-->
<!--          <mat-icon>fullscreen</mat-icon>-->
<!--        </button>-->
<!--        <button type="button" class="mgpeg-player__fullscreen" *ngIf="options.fullScreen" (click)="closeDialog()">-->
<!--          <mat-icon>fullscreen_exit</mat-icon>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
