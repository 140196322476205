import { IVideoDevisesListLinksDto } from "../interfaces";

/** Модель для работы с сущность Список видеокамер */
export class VideoDevicesList {
  public id: string;
  /** Имя списка */
  public name: string;

  /** Ссылка на пользователя */
  public userId: string;

  /** Список связанных камер */
  public cameras: IVideoDevisesListLinksDto[];
  /** Id автора */
  public creationAuthor?: string;
  /** Время создания */
  public creationTime?: number;
  /** Id автора изменения */
  public updateAuthor?: string;
  /** Время обновления */
  public updateTime?: number;
}


