import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Settings2Service } from '@smart-city/core/services';
import { IScSelectOptions, IScCheckboxOptions, IScInputOptions } from '@smart-city/core/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmergencyDto } from '../../models/classes';
import { IAddressDetailsDto } from '../../models/interfaces';

/** Компонент формы просмотра/редактирования записи детализации по адресам */
@Component({
  selector: 'bg-address-details-view-dialog',
  templateUrl: './address-details-view-dialog.component.html',
  styleUrls: ['./address-details-view-dialog.component.scss'],
})
export class AddressDetailsViewDialogComponent implements OnInit {
  /** Форма */
  public manuallyForm: FormGroup;
  /** Признак если адрес задан координатами */
  public byCoordinates: boolean;
  /** Настройки чекбокса переключения на адрес по координатам */
  public byCoordinatesOptions: IScCheckboxOptions = {
    title: 'Адрес задан координатами',
    disabled: true,
  };
  /** Настройка компоненты адреса */
  public coordinatesAddressOptions: IScInputOptions = {
    label: 'Адрес *',
    maxLength: 100,
    disabled: true,
  };
  /** Настройка селекта Вид здания */
  public buildingKindOptions: IScSelectOptions = {
    title: 'Вид здания',
    data: this.settings.getDictForSelect('buildingKind'),
    clearable: true,
  };
  /** Настройка инпутов - количество граждан */
  public totalOptions: IScInputOptions = {
    label: 'Всего',
    type: 'number',
    autocomplete: false,
  }
  public menOptions: IScInputOptions = {
    label: 'в т.ч. мужчин',
    type: 'number',
    autocomplete: false,
  }
  public womanOptions: IScInputOptions = {
    label: 'в т.ч. женщин',
    type: 'number',
    autocomplete: false,
  }
  public pensionersOptions: IScInputOptions = {
    label: 'в т.ч. пенсионеров',
    type: 'number',
    autocomplete: false,
  }
  public childrenOptions: IScInputOptions = {
    label: 'в т.ч. детей',
    type: 'number',
    autocomplete: false,
  }
  /** Id происшествия */
  public emergencyId: string;

  constructor(
    private readonly settings: Settings2Service,
    public dialogRef: MatDialogRef<AddressDetailsViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { emergency: EmergencyDto, addressDetails: IAddressDetailsDto, isOrder: boolean },
  ) { }

  public ngOnInit(): void {
    this.emergencyId = this.data.isOrder ? this.data.emergency.parentId['id'] : this.data.emergency.id;
    const details = this.data?.addressDetails;
    const buildingKindId = details['buildingKindId.id'];
    this.manuallyForm = new FormGroup({
      byCoordinates: new FormControl(this.data?.addressDetails.byCoordinates),
      address: new FormControl(undefined, [Validators.required]),
      coordinatesAddress: new FormControl(this.data?.addressDetails.address),
      buildingKindId: new FormControl(buildingKindId || null),
      totalRegistered: new FormControl(details.numberOfRegistered?.total),
      menRegistered: new FormControl(details.numberOfRegistered?.men),
      womanRegistered: new FormControl(details.numberOfRegistered?.woman),
      pensionersRegistered: new FormControl(details.numberOfRegistered?.pensioners),
      childrenRegistered: new FormControl(details.numberOfRegistered?.children),
      totalResidents: new FormControl(details.numberOfResidents?.total),
      menResidents: new FormControl(details.numberOfResidents?.men),
      womanResidents: new FormControl(details.numberOfResidents?.woman),
      pensionersResidents: new FormControl(details.numberOfResidents?.pensioners),
      childrenResidents: new FormControl(details.numberOfResidents?.children),
    });
  }

  /** Закрытие формы */
  public close(): void {
    this.dialogRef.close();
  }

  /** Сохранение адреса */
  public save(): void {
    const addressDetails: IAddressDetailsDto = {
      id: this.data.addressDetails.id,
      emergencyId: this.emergencyId,
      numberOfRegistered: {
        total: Number(this.manuallyForm.controls.totalRegistered.value),
        men: Number(this.manuallyForm.controls.menRegistered.value),
        woman: Number(this.manuallyForm.controls.womanRegistered.value),
        pensioners: Number(this.manuallyForm.controls.pensionersRegistered.value),
        children: Number(this.manuallyForm.controls.childrenRegistered.value),
      },
      numberOfResidents: {
        total: Number(this.manuallyForm.controls.totalResidents.value),
        men: Number(this.manuallyForm.controls.menResidents.value),
        woman: Number(this.manuallyForm.controls.womanResidents.value),
        pensioners: Number(this.manuallyForm.controls.pensionersResidents.value),
        children: Number(this.manuallyForm.controls.childrenResidents.value),
      },
      buildingKindId: this.manuallyForm.controls.buildingKindId.value,
    }

    this.dialogRef.close(addressDetails);
  }
}
