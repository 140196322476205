import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from '@bg-front/core/components';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';
import { Uuid } from '@smart-city/core/utils';
import { of } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { IThermopoint } from '../../models/interfaces';
import { ThermopointsService } from '../../services';
import { BgMapService } from '@bg-front/core/services';
import { IEmergencyForMiniCard } from '@bg-front/core/models/interfaces';
import { IDictionaryModelDto, IEmergencyDto, IEmergencyEventDto, ILifeCycleStepDto } from 'smart-city-types';
import { Coordinates } from '@bg-front/core/models/classes';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { LayersEnum } from '@bg-front/core/models/enums';
import { MapBaseModel, MapBaseService } from '@smart-city/maps/sc';

/** Компонент формы просмотра термоточки на карте */
@UntilDestroy()
@Component({
  selector: 'bg-thermopoint-info',
  templateUrl: './thermopoint-info.component.html',
  styleUrls: ['./thermopoint-info.component.scss']
})
export class ThermopointInfoComponent extends BaseComponent implements OnInit {
  /** Флаг открытия дровера */
  public openDrawer = false;
  /** Модель */
  // TODO Добавить тип IThermopointForView и заменить 
  public model: Partial<IThermopoint>;
  /** Связанные происшествия */
  public relatedIncidents: IEmergencyForMiniCard[] = [];
  /** Связанные происшествия */
  public relatedEvents: IEmergencyForMiniCard[] = [];
  /** Модель карты */
  private mapModel: MapBaseModel;


  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly service: ThermopointsService,
    private readonly gisService: BgMapService,
    private readonly mapService: MapBaseService,
    notificationService: NzNotificationService,
  ) {
    super(notificationService);
  }

  /** @ignore */
  public ngOnInit(): void {
    this.mapModel = new MapBaseModel('baseMapWorkspace', this.mapService);

    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.model = undefined;
        const sourceId = params.get('id');

        // Если ID невалидный или отсутствует не выполнять поиск и передать значение, что ничего найдено
        if (!sourceId || !Uuid.isValid(sourceId)) return of(undefined);

        const attributes = [
          'id',
          'statusId.name',
          'typeId.name',
          'detectionDateTime',
          'statusUpdateDateTime',
          'reportDateTime',
          'inWorkDateTime',
          'extId',
          'centerCoordinates',
          'polygonCoordinates',
          'burnedPolygonCoordinates',
          'federalDistrict',
          'federalSubject',
          'municipal',
          'closestSettlement',
          'azimuth',
          'distance',
          'landCategoryId.name',
          'owner',
          'ownerPhone',
          'attentionScore',
          'possibleType',
          'unit',
          'personnelCount',
          'equipmentCount',
        ];

        return this.service.getById(sourceId, attributes);
      }),
      switchMap((data: Partial<IThermopoint>) => {
        if (!data) {
          this.notificationService.error('Ошибка', 'Термоточка не найдена');
          this.close();
        }

        this.model = data;

        return this.service.getEmergenciesByThermoPointId(data.extId)
      }),
      catchError((err: Error) => {
        return this.catchErrorFn<[IEmergencyDto[], IEmergencyEventDto[]]>(err, 'Ошибка при загрузке данных термоточки');
      }),
      untilDestroyed(this),
    )
      .subscribe(([emergencies, events]: [IEmergencyDto[], IEmergencyEventDto[]]) => {
        this.relatedIncidents = (emergencies ?? [])
        .filter((item: IEmergencyDto) => ((item.lifeCycleStepId as ILifeCycleStepDto).status as IDictionaryModelDto).sysname === 'inWork'
          || ((item.lifeCycleStepId as ILifeCycleStepDto).status as IDictionaryModelDto).sysname === 'new')
        .map((item: IEmergencyDto) => ({
          id: item.id,
          timeCreate: item.timeCreate,
          coordinates: item.coordinates,
          lifeCycleStepName: (item.lifeCycleStepId as ILifeCycleStepDto)?.name,
          incidentTypeId: item.incidentTypeId,
          docType: (item.docType as unknown as IDictionaryInfo)?.sysname,
        }))
        this.relatedEvents = (events ?? [])
          .filter((item: IEmergencyEventDto) => !item.isHandled)
          .map((item: IEmergencyEventDto) => ({
            id: item.id,
            timeCreate: item.ksipTime,
            coordinates: item.exactCoordinates,
            lifeCycleStepName: 'Новый',
            incidentTypeId: item.ksipTypeId,
          }))
        this.openDrawer = true;

        this.setPositionMapOnCoordinates();
        this.gisService.setClickMarker('Термоточки', this.model.id, new Coordinates(this.model.centerCoordinates));
      })
  }

  /** Закрытие формы просмотра */
  public close(): void {
    this.mapModel.removeLayer(LayersEnum.clickMarker);

    this.router.navigate(['../..'], {
      relativeTo: this.route,
    });
  }

  /** Позиционировать карту на координатах */
  public setPositionMapOnCoordinates(): void {
    this.gisService.setPositionMapOnCoordinates(this.model.centerCoordinates);
  }

}
