import { RestService } from '@smart-city/core/services';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IForecastingDetailDto } from 'smart-city-types';

import { IForecastingTaskParamsDto } from '../../models/interfaces';

/**
 * Абстрактный сервис, реализующий загрузку инцидентов
 */
export abstract class BaseForecastingService {
  /** Сохраняем результат предыдущего рассчёта */
  public forecastingParams: IForecastingTaskParamsDto = undefined;
  /** Предыдущие расчёты */
  private previousCalculation = new Map<string, IAnyObject>();

  constructor(public readonly rest: RestService) {}

  /**
   * Возвращает состояние формы запуска прогнозирования
   * */
  public getForecastingFormState(): IForecastingTaskParamsDto {
    return this.forecastingParams;
  }

  /** Сохраняем данные предыдущего расчёта задачи */
  public setPreviousCalculation(countTaskId: string, params: IAnyObject) {
    this.previousCalculation.set(countTaskId, params);
  }

  /** Запрашиваем данные предыдущего расчёта задачи */
  public getPreviousCalculation<T = IAnyObject>(countTaskId: string): T {
    return this.previousCalculation.get(countTaskId) as T;
  }

  /** Запрашиваем данные предыдущего расчёта задачи */
  public hasPreviousCalculation(countTaskId: string): boolean {
    return this.previousCalculation.has(countTaskId);
  }

  /** Выбор задачи для прогнозирования Лесных пожаров */
  public calculateForecasting<T>(data: IForecastingTaskParamsDto): Observable<T> {
    return this.rest
      .serviceRequest({
        data,
        action: 'calculateForecasting',
        service: { name: 'Forecasting' },
      }, 'http')
      .pipe(
        map((res: IAbstractServiceData) => {
          return res.data as T;
        }),
      );
  }

  /**
   * Метод получения параметров для прогнозирования для конкретного происшествия
   * @param id
   */
  public getIncidentForecastingParams(
    id: string,
  ): Observable<{
    coordinates: string;
    params: IForecastingDetailDto;
  }> {
    let coordinates = undefined;
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          attributes: ['forecastingDetailId', 'id', 'coordinates', 'mapMarkerCoordinate'],
          query: {
            id,
          },
        },
      })
      .pipe(
        switchMap((res: IAbstractServiceData) => {
          if (res.data?.items?.length) {
            const item = res.data?.items[0] || {};
            coordinates = item.mapMarkerCoordinate ?? item.coordinates;
            if (item?.forecastingDetailId) {
              return this.getForecastingParamsById(item.forecastingDetailId);
            }
          }
          return of(null);
        }),
        map((params: IForecastingDetailDto) => {
          return {
            coordinates,
            params,
          };
        }),
      );
  }

  /**
     * Метод получения параметров для прогнозирования для конкретного события
     * @param id
     */
  public getEventForecastingParams(
    id: string,
  ): Observable<{
    coordinates: string;
    params: IForecastingDetailDto;
  }> {
    let coordinates = undefined;
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Events',
          attributes: ['forecastingDetailId', 'id', 'address', 'exactCoordinates'],
          query: {
            id,
          },
        },
      })
      .pipe(
        switchMap((res: IAbstractServiceData) => {
          if (res.data?.items?.length) {
            const item = res.data?.items[0] || {};
            coordinates = item.address.latitude && item.address.longitude
              ? `${item.address.latitude}, ${item.address.longitude}`
              : item.exactCoordinates;
            if (item?.forecastingDetailId) {
              return this.getForecastingParamsById(item.forecastingDetailId);
            }
          }
          return of(null);
        }),
        map((params: IForecastingDetailDto) => {
          return {
            coordinates,
            params,
          };
        }),
      );
  }

  /**
   * Метод получения параметров для прогнозирования для связной сущности
   * @param id Id происшествия
   */
  public getForecastingParamsById(id: string): Observable<IForecastingDetailDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: {
          name: 'ForecastingDetail',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return res.data.items[0];
        }),
      );
  }
}
