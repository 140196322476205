import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UntilDestroy } from '@ngneat/until-destroy';

import { FireMonitoringMessageTypeState } from '../../../models/states';
import { FireMonitoringMessageTypeStore } from '../store/fire-monitoring-message-type.store';

/**
 * Сервис по работе с хранилищем типы извещений пожарного мониторинга
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class FireMonitoringMessageTypeQuery extends QueryEntity<FireMonitoringMessageTypeState> {
  constructor(store: FireMonitoringMessageTypeStore) {
    super(store);
  }
}
