<div class="user-polygon-container">
  <div class="user-polygon" (click)="onClickUserPolygon($event)">
    <div class="user-polygon__header">
      <div class="user-polygon__icon">
        <span class="sc-icon-polygon"></span>
      </div>
      <div class="user-polygon__name">{{ userPolygon.name }}</div>
      <div>{{ userPolygon.description }}</div>
    </div>

    <div class="pr-10 pl-10 pb-10">
      <div class="show-on-map" (click)="showOnMap($event)">
        <mat-icon
          _ngcontent-lgs-c418=""
          role="img" matsuffix=""
          class="mat-icon
          notranslate
          material-icons
          mat-icon-no-color
          ng-tns-c150-49"
          aria-hidden="true"
          data-mat-icon-type="font">
          location_on
        </mat-icon>
        Показать на карте
      </div>
    </div>
  </div>
</div>
