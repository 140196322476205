<nz-card class="card"
  [nzTitle]="titleTemplate" [nzExtra]="extraTemplate">
  <p>{{ incidentTypeName }}</p>
  <p class="incident__coordinates" (click)="setPositionMapOnEmergency()">{{ emergency.coordinates }}</p>

  <ng-template #titleTemplate>
    <div class="incident__title">
      <p>{{ emergency.timeCreate | date:'dd.MM.YYYY HH:mm:ss' }}</p>
      <span [ngClass]="{
          'incident__icon': true,
          'icon__red': emergency.docTypeId === docTypeIncidentTypeId,
          'icon__orange': emergency.docTypeId !== docTypeIncidentTypeId
        }"
        nz-icon nzType="exclamation-circle" nzTheme="fill"></span>
    </div>
  </ng-template>

  <ng-template #extraTemplate>
    <nz-tag [nzColor]="statusColor">
      {{ emergency.lifeCycleStepName }}
    </nz-tag>
  </ng-template>
</nz-card>
