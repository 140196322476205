import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { IFiasValueDto } from 'smart-city-types';

import { GarFindFlatResponseElement, RequestType } from '../../models/types';
import { FiasService, GarService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'nz-address-kit',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements ControlValueAccessor {
  /** Тип используемого запроса */
  @Input() requestType: RequestType = 'gar';

  /**
   * Использовать только один сервис для запроса
   * Если false, то при ошибке в первом сервисе, запрос будет выполнен через второй
   */
  @Input() onlyOne: boolean = true;

  /** Событие изменения значения селекта */
  @Output() public changeValue = new EventEmitter<GarFindFlatResponseElement | null>();

  /** Форма ввода адреса */
  public form!: FormGroup;

  /** Блокировка контрола */
  public disabled = false;

  /** Текущее значение */
  public value: GarFindFlatResponseElement | null = null;

  /** Список элементов, полученных из сервиса по поисковой строке */
  public autocomlete: GarFindFlatResponseElement[] = [];

  /** @ignore */
  constructor(
    @Self() @Optional() public ngControl: NgControl | null,
    private readonly fiasService: FiasService,
    private readonly garService: GarService,
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }

    this.initForm();
  }

  /** @ignore */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** @ignore */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** @ignore */
  public writeValue(value: GarFindFlatResponseElement | string | null): void {
    if (typeof value === 'string') this.value = { fullText: value };
    else this.value = value;
    this.form.get('address').patchValue(this.value?.fullText, { emitEvent: false });
  }

  /** @ignore */
  public setDisabledState(isDisabled: boolean): void {
    this.form.disable({ emitEvent: false });
    this.disabled = isDisabled;
  }

  /** @ignore */
  public onChange(value: GarFindFlatResponseElement | null): void {
    //
  }

  /** @ignore */
  public onTouched(): void {
    //
  }

  /** Инициализация формы */
  private initForm(): void {
    this.form = new FormGroup({
      address: new FormControl(),
    });

    this.form
      .get('address')
      ?.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value: string | GarFindFlatResponseElement) => {
        if (!value || typeof value === 'string') {
          this.searchElements(value as string);
        } else {
          this.selectElement(value as GarFindFlatResponseElement);
        }
      });
  }

  /** Поиск элементов по содержимому инпута */
  public searchElements(value: string): void {
    this.writeAddress({ fullText: value });

    if (!value) {
      this.clearValue();
      return;
    }

    of(this.requestType)
      .pipe(
        switchMap((type: string) => (type === 'gar' ? this.garService.find(value) : this.fiasService.find(value))),
        switchMap((result: GarFindFlatResponseElement[] | IFiasValueDto[]) =>
          result?.length ? of(result) : this.secondRequest(value).pipe(catchError(() => of([]))),
        ),
        catchError(() => this.secondRequest(value)),
        catchError(() => of([])),
        untilDestroyed(this),
      )
      .subscribe((result: GarFindFlatResponseElement[] | IFiasValueDto[]) => (this.autocomlete = result));
  }

  /** Повторный запрос при ошибке в первом запросе */
  private secondRequest(value: string): Observable<GarFindFlatResponseElement[] | IFiasValueDto[]> {
    return this.onlyOne
      ? of([])
      : this.requestType === 'gar'
      ? this.fiasService.find(value)
      : this.garService.find(value);
  }

  /** Записать новое значение и вызвать соответствующие события */
  private writeAddress(value: GarFindFlatResponseElement | null): void {
    this.changeValue.emit(value);
    this.onChange(value ?? null);
    this.writeValue(value);
  }

  /** Очистить значение контрола и сбросить состояние компонента */
  private clearValue(): void {
    if (this.value) {
      this.onChange(null);
      this.changeValue.emit(null);
    }

    this.autocomlete = [];
  }

  /** Очистить значение контрола и сбросить состояние компонента */
  private selectElement(element: GarFindFlatResponseElement): void {
    this.value = element;
    this.autocomlete = [];
    this.writeAddress(this.value);
    if ((!this.value.latitudeHouse || !this.value.longitudeHouse) && (this.value.houseGUID || this.value.aoguid)) {
      this.garService
        .getCoordinates(this.value.houseGUID || this.value.aoguid, !this.value.houseGUID)
        .pipe(untilDestroyed(this))
        .subscribe((result) => {
          // В координаты необходимо сохранить уточненные значения для дома
          if (result?.latitude && result?.longitude) {
            this.value!.latitude = result.latitude;
            this.value!.longitude = result.longitude;
            this.writeAddress(this.value);
          }
        });
    }
    // В координаты необходимо сохранить уточненные значения для дома
    if (this.value.latitudeHouse && this.value.longitudeHouse) {
      this.value!.latitude = this.value.latitudeHouse;
      this.value!.longitude = this.value.longitudeHouse;
      this.writeAddress(this.value);
    }
  }
}
