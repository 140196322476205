<div class="bg-row">
  <div class="bg-col-10">
    <h4>
      <b>{{ reportType?.name }}</b>
    </h4>
    <div class="subtitle">{{ data.subtitle }}</div>
  </div>
  <div class="bg-col-2 align-right">
    <button mat-button (click)="close('crosshair')">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content>
  <div class="bg-row">
    <div class="bg-col">
      <div *ngIf="isLpuPvrReport">
        <form [formGroup]="form">
          <div class="container">
            <div class="bg-row">
              <div class="bg-form-group lpu-pvr-group">
                <sc-select [options]="lpuOptions" formControlName="lpu"></sc-select>
              </div>
            </div>
            <div class="bg-row">
              <div class="bg-form-group lpu-pvr-group">
                <sc-select [options]="pvrOptions" formControlName="pvr"></sc-select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <form [formGroup]="form" *ngIf="!isLpuPvrReport">
        <ng-container formArrayName="reportFields">
          <ng-container *ngFor="let group of model?.data; let i = index; let first = first">
            <div class="fields" *ngIf="group.type === 'field'">
              <ng-container *ngFor="let field of group.fields; let y = index">
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-input
                        [attr.title]="field.name"
                        [formControl]="reportControls[i][y].control"
                        [options]="reportControls[i][y].options"
                      >
                      </sc-input>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="group.type === 'subtitle'" class="subtitle">{{ group.name }}</div>
            <mat-accordion *ngIf="group.type !== 'subtitle' && group.type !== 'field'">
              <!-- раскрываем первый аккордеон если перед ним есть сабтайтл -->
              <mat-expansion-panel [expanded]="first || (i === 1 && model?.data[0].type === 'subtitle')">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div class='header-title'>{{ group.name }}</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let field of group.fields; let y = index">
                  <div *ngIf="field.type === 'subtitle'" class="subtitle">{{ field.name }}</div>
                  <sc-input
                    *ngIf="field.type !== 'subtitle'"
                    [formControl]="reportControls[i][y].control"
                    [options]="reportControls[i][y].options"
                    [attr.title]="field.name"
                  >
                  </sc-input>
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </ng-container>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <sc-button
    *ngIf="model?.data?.length || isLpuPvrReport"
    [options]="cancelBtnOptions"
    (clickButton)="close('cancel')"
  ></sc-button>
  <sc-button
    *ngIf="model?.data?.length || isLpuPvrReport"
    [options]="saveBtnOptions"
    (clickButton)="save()"
  ></sc-button>
</mat-dialog-actions>
