<div [ngStyle]="mapStyles">
  <map-viewObjects
    [options]="mapViewObjectsOptions"
    [objects]="mapObjects"
    [request]="request"
    [markersOptions]="markersOptions"
    (events)="onMapViewObjectsEvent($event, mapViewObjectsOptions.mapId)"
  >
  </map-viewObjects>
</div>
<div *nzModalFooter>
  <div nz-row nzJustify="end">
    <div nz-col>
      <button nz-button nzType="default" (click)="onCloseHandler()">Отмена</button>
      <button nz-button nzType="primary" (click)="onSaveHandler()">Сохранить</button>
    </div>
  </div>
</div>
