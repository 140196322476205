import { Injectable } from '@angular/core';
import { EntityActions, QueryEntity } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject, UnaryFunction, pipe } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MapObject } from '../../../models/classes';
import { MapObjectsState } from '../../../models/states';
import { MapObjectsStore } from '../store/map-objects.store';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MapObjectsQuery extends QueryEntity<MapObjectsState> {
  constructor(protected override store: MapObjectsStore) {
    super(store);
  }

  /**
   *
   * @param comparerFn - функция для проверки вхождения объекта в bbox карты, если объект входит, то генерируем событие обновления
   * @returns Возвращает подписку на событие необходимости обновить карту
   */
  public selectNeedUpdate(comparerFn: (MapObject) => boolean = () => true): Observable<void> {
    const refresh: Subject<void> = new Subject();

    const checkPipe = () =>
      pipe(
        switchMap((addedIds: string[]) => {
          return this.selectMany(addedIds);
        }),
        map((objects: MapObject[]) => {
          return objects.some(comparerFn);
        }),
        untilDestroyed(this),
      );

    this.selectEntityAction(EntityActions.Add)
      .pipe(checkPipe())
      .subscribe((needUpdate: boolean) => {
        if (needUpdate) {
          refresh.next();
        }
      });

    this.selectEntityAction(EntityActions.Update)
      .pipe(checkPipe())
      .subscribe((needUpdate: boolean) => {
        if (needUpdate) {
          refresh.next();
        }
      });

    this.selectEntityAction(EntityActions.Remove)
      .pipe(checkPipe())
      .subscribe((needUpdate: boolean) => {
        if (needUpdate) {
          refresh.next();
        }
      });

    return refresh.asObservable();
  }
}
