<form nz-form [formGroup]="form" errorTailor id="idForTest" nzLayout="vertical" *ngIf="form">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <ng-template #mainParams>
        <h4 class="m-b-0">Основные параметры расчёта</h4>
      </ng-template>
      <nz-divider [nzText]="mainParams" nzOrientation="left"></nz-divider>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="objectFireId">Объект пожара</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="objectFireId" nzSize="large" nzAllowClear="true">
            <nz-option [nzValue]="obj.value" [nzLabel]="obj.text" *ngFor="let obj of fireObjects$ | async"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzFor="fireCoveredDistance">Площадь участка</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="distance">
            <input
              nzSize="large"
              nz-input
              formControlName="fireCoveredDistance"
              [controlErrorAnchor]="fireCoveredDistanceAnchor"
            />
          </nz-input-group>
          <ng-template controlErrorAnchor #fireCoveredDistanceAnchor="controlErrorAnchor"></ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label></nz-form-label>
        <nz-form-control>
          <label nz-checkbox nzSize="large" formControlName="autoCalculateDistance"
            >Рассчитать расстояние автоматически</label
          >
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24" *ngIf="isAutoCalculateDistance">
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="linearVelocity">Линейная скорость</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="speed">
            <input
              nzSize="large"
              nz-input
              formControlName="linearVelocity"
              [controlErrorAnchor]="linearVelocityAnchor"
            />
          </nz-input-group>
          <ng-template controlErrorAnchor #linearVelocityAnchor="controlErrorAnchor"></ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="propagationTime">Время распространения</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="time">
            <input
              nzSize="large"
              nz-input
              formControlName="propagationTime"
              [controlErrorAnchor]="propagationTimeAnchor"
            />
          </nz-input-group>
          <ng-template controlErrorAnchor #propagationTimeAnchor="controlErrorAnchor"></ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="firstSalvoTime">Время подачи 1-го ствола</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="time">
            <input
              nzSize="large"
              nz-input
              formControlName="firstSalvoTime"
              [controlErrorAnchor]="firstSalvoTimeAnchor"
            />
          </nz-input-group>
          <ng-template controlErrorAnchor #firstSalvoTimeAnchor="controlErrorAnchor"></ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <ng-container formArrayName="fireAreaZones">
    <div class="zone">
      <div nz-row [nzGutter]="24" *ngFor="let el of zonesArray?.controls; let i = index" [formGroupName]="i">
        <div nz-col [nzSpan]="22">
          <bg-forecasting-technological-fire-area [coordinates]="coordinates"></bg-forecasting-technological-fire-area>
        </div>
        <div nz-col [nzSpan]="2" *ngIf="zonesArray?.controls.length > 1">
          <nz-form-item>
            <nz-form-label></nz-form-label>
            <nz-form-control>
              <i nz-icon nzType="minus-circle-o" class="dynamic-delete-button" (click)="removeZone(i)"></i>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="24">
        <nz-form-item>
          <nz-form-control [nzXs]="{ span: 24, offset: 0 }" [nzSm]="{ span: 15, offset: 9 }">
            <button type="button" nz-button nzType="dashed" class="add-button" (click)="addZone()">
              <i nz-icon nzType="plus"></i>
              Добавить зону
            </button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </ng-container>
</form>
<ng-template #distance> м </ng-template>
<ng-template #speed> м/мин </ng-template>
<ng-template #time> мин </ng-template>
