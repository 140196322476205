import { Directive, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { IAnyObject } from 'smart-city-types';
import { IScInputOptions } from '@smart-city/core/common';
import { Settings2Service } from '@smart-city/core/services';

/** Базовый класс для списков потерь */
@Directive()
export class LoosesListComponent implements OnInit {

  public form: FormGroup;

  /** Настройка компоненты ФИО */
  public fioOptions: IScInputOptions = {
    label: 'ФИО',
    placeholder: 'ФИО',
    maxLength: 100,
  };

  /** Настройка компоненты Год рождения */
  public birthYearOptions: IScInputOptions = {
    label: 'Год рождения',
    placeholder: 'Год рождения',
    mask: '0*',
    maxLength: 4,
  };

  /** Настройка компоненты Примечание */
  public commentOptions: IScInputOptions = {
    label: 'Примечание',
    placeholder: 'Примечание',
    maxLength: 100,
  };

  /** todo типизация */
  public listData: IAnyObject[];

  /** Геттер массива формгрупп со спасенными */
  get list() {
    return <FormGroup[]>(<FormArray>this.form.controls.list).controls;
  }

  /** @ignore */
  constructor(protected settings: Settings2Service) {
  }

  /** @ignore */
  public ngOnInit(): void {
    this.listData = this.listData.length ? this.listData : [{}];
    this.form = new FormGroup({
      list: new FormArray([]),
    });
  }

  /**
   * Удаление записи
   * @param index - индекс записи
   */
  public delete(index: number) {
    (<FormArray>this.form.controls.list).removeAt(index);
  }
}
