<ng-container [formGroup]="form">
  <nz-input-group nzSearch [nzSuffix]="inputClearTpl" [nzAddOnAfter]="suffixIconButton">
    <input
      #searchInput
      type="text"
      formControlName="search"
      nz-input
      placeholder="Введите адрес или координаты..."
      [nzAutocomplete]="auto"
    />
    <nz-autocomplete #auto>
      <nz-auto-option
        *ngFor="let item of autocomlete"
        [nzValue]="item"
        [nzLabel]="item.fullText || ''"
        [nz-tooltip]="item.fullText || ''"
      >
        {{ item.fullText }}
      </nz-auto-option>
    </nz-autocomplete>
  </nz-input-group>
  <ng-template #inputClearTpl>
    <span *ngIf="form.get('search')?.value" nz-icon nzTheme="fill" nzType="close-circle" (click)="clearSearch()">
    </span>
  </ng-template>
</ng-container>
<ng-template #suffixIconButton>
  <button nz-button nzType="default" nzSize="large" nzSearch (click)="clickSearchButton()">
    <span nz-icon nzType="search"></span>
  </button>
</ng-template>
