import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SfsService } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { IArchiveTaskDto, IVideoDeviceDto } from 'smart-city-types';
import { TWO_MINUTES } from '@bg-front/core/models/constants';
import { IImageVideoRecordingDialogData } from '../../models/interfaces';
import { VideoDevicesService, VideoResultsService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';

@Component({
  selector: 'bg-image-video-recording-dialog',
  templateUrl: './image-video-recording-dialog.component.html',
  styleUrls: ['./image-video-recording-dialog.component.scss'],
})
export class ImageVideoRecordingDialogComponent extends BaseComponent {
  /**
   * Ссылка на картинку
   */
  public imagesUrls: string[] = undefined;
  /**
   * Период
   */
  public period = TWO_MINUTES;

  /** Период просмотра, для нового плеера*/
  public customPeriod;

  /** id камеры */
  public cameraId: string | IVideoDeviceDto;

  /** ID файла с видео в хранилище SFS */
  public sfsId: string = undefined;

  /** Статус получения видео */
  public videoStatus: string = 'Запущен процесс получения видео';

  /** Параметры рамки */
  public frame: {
    x: number;
    y: number;
    alarm: number;
    width: number;
    height: number;
  };

  constructor(
    private videoDevicesService: VideoDevicesService,
    private sfs: SfsService,
    private videoResultsService: VideoResultsService,
    @Inject(MAT_DIALOG_DATA) public data: IImageVideoRecordingDialogData,
  ) {
    super();

    try {
      this.imagesUrls = JSON.parse(data.imageId).map((id: string) => this.sfs.getStreamUrl(id));
    } catch (e) {
      this.imagesUrls = [];
    }

    this.cameraId = data.cameraId;
    this.customPeriod = { fromtime: this.data.fromTime, totime: this.data.toTime };
    this.frame = this.data.frame;

    this.videoDevicesService
      .getMacroscopExportArchiveURL(this.cameraId as string, this.data.fromTime, this.data.toTime)
      .pipe(
        switchMap(
          (url: string): Observable<IArchiveTaskDto> =>
            this.videoResultsService.createArchive(url, this.data.entityId, this.data.entity),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((archive: IArchiveTaskDto) => {
        switch (archive.status) {
          case 'error':
            this.videoStatus = 'Ошибка запроса видео';
            return;
          case 'success':
            this.sfsId = archive.video[0];
            return;
        }
      });
  }
}
