import { Injectable } from '@angular/core';
import { IAnyObject } from 'smart-city-types';
import { Coordinates } from '@bg-front/core/models/classes';
import { SEARCH_MARKER } from '@bg-front/core/models/constants';
import { BgMapService } from '@bg-front/core/services';
import rhumbDestination from '@turf/rhumb-destination';
import ellipse from '@turf/ellipse';
import * as L from 'leaflet';

/**
 * Сервис для прогнозирования
 */
@Injectable({
  providedIn: 'root',
})
export class ForecastingBlowoutVisualizationService {
  constructor(private mapService: BgMapService) {}

  /**
   * Кидаем событие, когда нужно отображать результаты выброса АХОВ на карте
   * @param coordinates - результаты
   * @param windDirection
   * @param windVelocity
   * @param pollutionDepth
   */
  public displayChemicalResults(
    coordinates: string,
    windDirection: number,
    windVelocity: number,
    pollutionDepth: number,
  ): [L.semiCircle, L.circle] {
    let degrees = +windDirection;
    const length = pollutionDepth * 1000;
    const angularDiameter = this.getAngularDiameter(windVelocity);
    if (angularDiameter === 359.9) {
      degrees += 180;
    }
    degrees = degrees - 90;
    const coord = (coordinates as string).split(',').map((el: string) => +el);

    return [
      L.semiCircle(coord, {
        radius: length,
        fillColor: '#FDF7E4',
        fillOpacity: 0.6,
        color: '#067AAB',
      })
        .setDirection(degrees - 90, angularDiameter)
        .addTo(this.mapService.mapModel),
      L.circle(coord, {
        radius: 5,
        fillColor: '#067AAB',
        fillOpacity: 1,
        color: '#067AAB',
      }).addTo(this.mapService.mapModel),
    ];
  }

  /**
   * Возвращает массив координат сектора АХОВ.
   * Координаты идут по часовой стрелке с шагом 22.5°, начиная с координат важного объекта.
   * @param coordinates - координаты важного объекта.
   * @param windDirection - направление ветра
   * @param windVelocity - скорость ветра
   * @param pollutionDepth - глубина зоны
   */
  public coordinatesChemicalResults(
    coordinates: string,
    windDirection: number,
    windVelocity: number,
    pollutionDepth: number,
  ): number[][] {
    const degrees = +windDirection - 180;
    const length = pollutionDepth;
    const coord = (coordinates as string).split(',').map((el: string) => +el);

    const result: number[][] = [];

    if (windVelocity > 2) {
      // сектор 45°
      result.push(coord);
      result.push(...this.getCoordinatesForSemiCircle(coord, degrees, 22.5, 22.5, length));
      result.push(coord);
      return result;
    }
    if (windVelocity > 1) {
      // сектор 90°
      result.push(coord);
      result.push(...this.getCoordinatesForSemiCircle(coord, degrees, 45, 22.5, length));
      result.push(coord);
      return result;
    }
    if (windVelocity > 0.5) {
      // сектор 180°
      result.push(coord);
      result.push(...this.getCoordinatesForSemiCircle(coord, degrees, 90, 22.5, length));
      result.push(coord);
      return result;
    }
    // сектор 360°;
    result.push(...this.getCoordinatesForSemiCircle(coord, degrees, 157.5, 22.5, length));
    result.push(rhumbDestination([...coord].reverse(), length, degrees + 180).geometry.coordinates.reverse());
    result.push(result[0]);
    return result;
  }

  public displayPath(path: [number, number][]) {
    const elems = [
      L.polyline(path, {
        color: '#000',
      }).addTo(this.mapService.mapModel),
    ];

    path.forEach((el: [number, number]) => {
      elems.push(this.displayPoint(el.join(', ')));
    });

    return elems;
  }

  /**
   * Отображение маркера на карте
   * @param coordinates координаты
   * @param iconSize (optional) размеры иконки
   * */
  public displayPoint(coordinates: string, iconSize: [number, number] = [40, 40]) {
    const icon = L.divIcon({
      iconSize,
      className: 'leaflet-node-marker',
      html: L.Util.template(SEARCH_MARKER.iconOptions.shapeSVG),
      iconAnchor: [12, 32],
      popupAnchor: [0, -28],
    });
    //
    return L.marker(new Coordinates(coordinates).toArray(), {
      icon,
    }).addTo(this.mapService.mapModel);
  }

  /**
   * Убираем результат
   */
  public clearResults(el: any | any[]) {
    if (el) {
      if (el instanceof Array) {
        el.forEach((item: IAnyObject) => this.mapService.mapModel.removeLayer(item));
      } else {
        this.mapService.mapModel.removeLayer(el);
      }
    }
  }

  /**
   * Вспомогательный метод получения координат сектора круга.
   * @param coord координаты центра сектора круга
   * @param degrees направление оси сектора круга
   * @param range градусная мера сектора круга
   * @param stepLength длина шага, с которым происходит вычисление координат
   * @param length радиус сектора круга
   */
  private getCoordinatesForSemiCircle(
    coord: number[],
    degrees: number,
    range: number,
    stepLength: number,
    length: number,
  ): number[][] {
    const result: number[][] = [];
    for (let i = degrees - range; i <= degrees + range; i += stepLength) {
      result.push(rhumbDestination([...coord].reverse(), length, i).geometry.coordinates.reverse());
    }
    return result;
  }

  /** Расчет углового размера зоны выброса АХОВ */
  private getAngularDiameter(windVelocity: number): number {
    if (windVelocity > 2) {
      return 45;
    }
    if (windVelocity > 1) {
      return 90;
    }
    if (windVelocity > 0.5) {
      return 180;
    }
    return 359.9;
  }
}
