<form [formGroup]="formControl" errorTailor id="idForTest">
  <div class="bg-involve-org-without-interaction">
    <mat-dialog-content class="mat-typography">
      <div class="bg-row">
        <div class="bg-col-6">
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="themeIdOptions" formControlName="themeId"></sc-select>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-textarea
                  class="informer-org-textarea"
                  [options]="optionsDescription"
                  formControlName="description"
                ></sc-textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-col-6">
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="typeServiceInformer" formControlName="informerServiceType"></sc-select>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-select [options]="attractOrgDataOptions" formControlName="attractOrgData"></sc-select>
              </div>
            </div>
          </div>
          <div class="bg-row">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input
                  [options]="phone1Options"
                  formControlName="phone1"
                  (click)="onClickPhone(callPhone)"
                ></sc-input>
              </div>
            </div>
          </div>
          <div class="bg-row" *ngIf="!timeReactOptions.hidden">
            <div class="bg-col">
              <div class="bg-form-group">
                <sc-input [options]="timeReactOptions" formControlName="timeReact"></sc-input>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-col">
          <div class="bg-form-group">
            <multi-file formControlName="files" [fileLimit]="1"></multi-file>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <sc-button [options]="cancelBtnOptions" (clickButton)="closeHandler()"></sc-button>
      <sc-button [options]="saveBtnOptions" (clickButton)="saveHandler()"></sc-button>
    </mat-dialog-actions>
  </div>
</form>
