<div class="container">
  <nw-header [options]="headerOptions" (clickButton)="delete()"></nw-header>
  <form [formGroup]="form">
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select formControlName="incidentTypeId" [options]="incidentTypeIdOptions"></sc-select>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select formControlName="state" [options]="stateOptions"></sc-select>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <bg-datetime
            formControlName="timeCreateFrom"
            label="Время регистрации с"
            [defaultHour]="0"
            [defaultMinute]="0"
            [defaultSecond]="0"
          ></bg-datetime>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <bg-datetime
            formControlName="timeCreateTo"
            label="Время регистрации по"
            [defaultHour]="23"
            [defaultMinute]="59"
            [defaultSecond]="59"
          ></bg-datetime>
        </div>
      </div>
    </div>
  </form>
</div>
