import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UntilDestroy } from '@ngneat/until-destroy';

import { VideoDeviceState } from '../../../models/states';
import { VideoDevicesStore } from '../store/video-devices.store';

/**
 * Сервис по работе с хранилищем Видеоустройств
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class VideoDevicesStoreQuery extends QueryEntity<VideoDeviceState> {
  constructor(store: VideoDevicesStore) {
    super(store);
  }
}
