import { Injectable } from '@angular/core';
import { IForecastingResultDto } from '../../../models/interfaces';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RestService } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import { Observable } from 'rxjs';
import { IAbstractServiceData } from 'smart-city-types';

import { ForecastingResultsStore } from '../store/forecasting-results-store.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ForecastingResultsService {
  constructor(private readonly store: ForecastingResultsStore, private readonly rest: RestService) {}

  /**
   * Удаление записи
   */
  public delete(id: string): void {
    this.store.setLoading(true);
    this.store.remove(id);
    this.store.setLoading(false);
  }

  /**
   * Добавление результата рассчёта
   * @param data - информация о рассчёте
   */
  public add(data: IForecastingResultDto) {
    if (data) {
      if (!data.id) {
        data.id = Uuid.newUuid();
      }
      this.store.upsert(data.id, data);
      this.store.setActive(data.id);
    }
  }

  /** Сохранение результата расчёта */
  public saveForecasting(data: IForecastingResultDto): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'insert',
      service: { name: 'Forecasting' },
      entity: {
        name: 'ForecastingResults',
      },
      data: {
        objectId: data.params.objectId,
        incidentId: data.params.emergencyId,
        eventId: data.params.eventId,
        projectedRiskId: data.params.projectedRiskId,
        countTaskId: data.params.countTaskId,
        params: data.params,
        result: data,
      },
    });
  }
}
