import { registerLocaleData } from '@angular/common';
import ru from '@angular/common/locales/ru';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import {
  IElementButton,
  INwForm,
  INwHeaderBarOptions,
  IRegistryColumn, IScButtonOptions,
  NwFormPopupService,
} from '@smart-city/core/common';
import { ScNavService, Settings2Service, SubscriberService } from '@smart-city/core/services';
import { merge, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';

import { TaskDialogComponent } from '../task-dialog/task-dialog.component';
import { TasksService } from '../tasks.service';

registerLocaleData(ru, 'ru');

/** Страница Реестра заданий */
@Component({
  selector: 'tasks-layout',
  templateUrl: './tasks-layout.component.html',
  styleUrls: ['./tasks-layout.component.scss'],
})
export class TasksLayoutComponent extends BaseComponent implements AfterViewInit {

  /** Пагинатор */
  @ViewChild(MatPaginator, { static: false }) public paginator: MatPaginator;

  public addButtonOptions = <IScButtonOptions>{
    icon: 'add',
    title: 'Создать обзвон',
    color: 'primary',
  };

  public addSmsButtonOptions = <IScButtonOptions>{
    icon: 'add',
    title: 'Создать рассылку',
    color: 'primary',
  };

  /** Хедер с заголовком страницы */
  public headerOptions: INwHeaderBarOptions = {
    title: 'Задания',
    margin: 'collapse',
    buttons: [
      {
        type: 'button',
        options: {
          name: 'burger',
          icon: 'menu',
        },
      },
      {
        type: 'button',
        options: {
          name: 'search',
          icon: 'search',
          callback: () => {
            this.onSearchClick();
            return of(null);
          },
        },
      },
    ],
  };

  /** Поисковые фильтры */
  public filters: IAnyObject = {};

  /** Столбцы реестра */
  public registryColumns: IRegistryColumn[] = [
    { name: 'id' },
    { name: 'name' },
    { name: 'executionDate' },
    { name: 'responsible' },
    { name: 'state' },
    { name: 'type' },
    { name: 'progress' },
  ];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private scNavService: ScNavService,
    private settings2: Settings2Service,
    private titleService: Title,
    private popupService: NwFormPopupService,
    private subs: SubscriberService,
    public taskService: TasksService,
  ) {
    super();
    titleService.setTitle('Задания');
  }

  /** @ignore */
  public ngAfterViewInit(): void {
    merge(
      this.subs.onTableChange('Calls', 'Tasks'),
      this.subs.onTableChange('Calls', 'TasksCitizensRelations'),
    )
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(() => {
      this.filters = { ...this.filters };
    }, (error) => {
      console.error(error);
    });
  }

  /**
   * Обработка нажатия на кнопку в хедере
   * @param event Нажатая кнопка
   */
  public onClickHeaderButton(event: IElementButton): void {
    switch (event.options.name) {
      case 'burger':
        this.scNavService.openMenu();
        break;
      case 'search':
        this.onSearchClick();
        break;
    }
  }

  /**
   * Обработка нажатия на кнопку "Создать задание"
   */
  public onClickCreateNewTask(): void {
    this.dialog.open(TaskDialogComponent, {
      data: {
        action: 'create',
        type: 'autodialing',
      },
      width: '840px',
    })
    .afterClosed()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((taskData: IAnyObject) => {
      if (taskData) {
        this.taskService.createTask(this.settings2.currentUser, taskData, true)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.filters = { ...this.filters };
        }, e => console.error(e));
      }
    }, e => console.error(e));
  }

  /**
   * Обработка нажатия на кнопку "Создать задание"
   */
  public onClickCreateNewSmsTask(): void {
    this.dialog.open(TaskDialogComponent, {
      data: {
        action: 'create',
        type: 'smsSending',
      },
      width: '840px',
    })
    .afterClosed()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((taskData: IAnyObject) => {
      if (taskData) {
        this.taskService.createSmsTask(this.settings2.currentUser, taskData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.filters = { ...this.filters };
        }, e => console.error(e));
      }
    }, e => console.error(e));
  }

  /**
   * Обработка выбора задания в реестре
   */
  public onClickTask(task: IAnyObject): void {
    this.router.navigate([`pbx/tasks/${task.id}`]);
  }

  /** Форма поиска */
  public searchForm: INwForm = {
    type: 'nw-form',
    options: {
      name: 'search',
      title: 'Поиск',
      service: { name: 'Calls' },
      entity: { name: 'CallTasks' },
      layoutForm: {
        layout: {
          columns: [
            {
              width: '100%',
              elements: [
                {
                  type: 'input',
                  options: {
                    name: 'search',
                    label: 'Поиск',
                    width: '100%',
                  },
                },
                {
                  type: 'select',
                  options: {
                    name: 'gasterCallTaskId.state.id',
                    title: 'Статус',
                    data: this.settings2.getDictForSelect('taskStatus'),
                    clearable: true,
                    width: '50%',
                  },
                },
                {
                  type: 'select',
                  options: {
                    name: 'responsible.id',
                    width: '50%',
                    title: 'Ответственный',
                    entity: 'Users',
                    service: 'Admin',
                    fieldName: 'fio',
                    loadDataFn: (params) => {
                      if ((params.limit || { paNumber: null }).paNumber > 0) return of([]);
                      return this.taskService.getResponsibleSelectData(params.query, this.settings2.currentUser.id);
                    },
                    clearable: true,
                    modern: true,
                  },
                },
                {
                  type: 'button',
                  options: {
                    name: 'search',
                    title: 'Искать',
                    stdBehavior: true,
                    color: 'primary',
                  },
                },
                {
                  type: 'button',
                  options: {
                    name: 'cancel',
                    title: 'Отмена',
                    stdBehavior: true,
                  },
                },
              ],
            },
          ],
        },
      },
    },
  };

  /**
   * Подымает форму для поиска(фильтрации)
   */
  public onSearchClick(): void {
    const formSubs = this.popupService.openForm({
      form: this.searchForm.options,
      value: {
        ...this.filters,
        search: this.filters?.$text?.$search,
      },
    })
    .subscribe((res) => {
      if (res.action === 'search') {
        const filterFormValue = res.form.getFormValue();
        for (const key in filterFormValue) {
          if (filterFormValue[key] === null) delete filterFormValue[key];
        }
        this.filters = {
          'gasterCallTaskId.state.id': filterFormValue['gasterCallTaskId.state.id'],
          'type.id': filterFormValue['type.id'],
          $text: {
            $search: filterFormValue.search,
            $fields: ['name'],
          },
        };
      }
      if (res.action === 'OnDestroy') {
        formSubs.unsubscribe();
      }
    }, (err) => {
      formSubs.unsubscribe();
      console.error(err);
    });
  }
}
