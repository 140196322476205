import { IResponseWithTotal } from '@bg-front/core/models/interfaces';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IAnyObject, ILifeCycleStepDto } from 'smart-city-types';
import { BaseComponent } from '@bg-front/core/components';
import { Uuid } from '@smart-city/core/utils';
import { IFireMonitoringObjectDto, IAlertParentSource } from '../../../bg/modules/dictionaries/modules/fire-monitoring-objects/models/interfaces';
import { FireMonitoringObjectService } from '../../../bg/modules/dictionaries/modules/fire-monitoring-objects/services';
import { IEmergencyForMiniCard } from '@bg-front/core/models/interfaces';
import { BgMapService } from '@bg-front/core/services';
import { MapBaseModel, MapBaseService } from '@smart-city/maps/sc';
import { LayersEnum } from '@bg-front/core/models/enums';
import { IDictionaryInfo } from '@smart-city/core/interfaces';

/** Компонент формы просмотра Объекта пожарного мониторинга */
@UntilDestroy()
@Component({
  selector: 'bg-fire-monitoring-object-info',
  templateUrl: './fire-monitoring-object-info.component.html',
  styleUrls: ['./fire-monitoring-object-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FireMonitoringObjectInfoComponent extends BaseComponent implements OnInit {
  /** Флаг открытия дровера */
  public openDrawer = false;
  /** Модель */
  public model: IFireMonitoringObjectDto;
  /** Форма маршрута */
  public form;
  /** Связанные происшествия */
  public relatedIncidents: IEmergencyForMiniCard[] = [];
  /** Связанные происшествия */
  public relatedEvents: IEmergencyForMiniCard[] = [];
  /** Источники оповещений */
  public alertSources: IAlertParentSource[] = [];

   /** Модель карты */
   private mapModel: MapBaseModel;

  /** @ignore */
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly service: FireMonitoringObjectService,
    private readonly cdr: ChangeDetectorRef,
    private readonly gisService: BgMapService,
    private readonly mapService: MapBaseService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.mapModel = new MapBaseModel('baseMapWorkspace', this.mapService);  

    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.model = undefined;
        const fireObjectId = params.get('id');

        // Если ID невалидный не выполнять поиск и передать значение, что ничего найдено
        if (!fireObjectId || !Uuid.isValid(fireObjectId)) return of(undefined);

        return this.service.getById(fireObjectId,[
          'id',
          'name',
          'externalAddress',
          'coordinates',
          'organizationId.name',
        ]);
      }),
      switchMap((fireObject: IFireMonitoringObjectDto) => {
        if (!fireObject) {
          this.noteService.pushError('Объект пожарного мониторинга не найден')
          this.close();
        }

        this.model = fireObject;
        return this.service.getRelatedEmergencies(fireObject.id)
      }),
      switchMap((res: any) => {
        this.relatedIncidents = (res.data.items ?? [])
        .filter((item) => item.incidentId.lifeCycleStepId.status.sysname === 'inWork'
          || item.incidentId.lifeCycleStepId.status.sysname === 'new')
        .map((item) => ({
          id: item.incidentId.id,
          timeCreate: item.incidentId.timeCreate,
          coordinates: item.incidentId.coordinates,
          lifeCycleStepName: (item.incidentId.lifeCycleStepId as ILifeCycleStepDto)?.name,
          incidentTypeId: item.incidentId.incidentTypeId,
          docType: (item.incidentId.docType as unknown as IDictionaryInfo)?.sysname,
        }))
        this.relatedEvents = (res.data.items ?? [])
          .filter((item) => !item.eventId.isHandled)
          .map((item) => ({
            id: item.eventId.id,
            timeCreate: item.eventId.ksipTime,
            coordinates: item.eventId.exactCoordinates,
            lifeCycleStepName: 'Новый',
            incidentTypeId: item.eventId.ksipTypeId,
          }))
        return this.service.getAlertSourcesByObjectId({ objectId: this.model.id }, -1, -1, { field: 'name', direction: 'asc' }, false);
      }),
      catchError((err: Error) => {
        return this.catchErrorFn<IResponseWithTotal<IAlertParentSource[]>>(err, 'Ошибка при загрузке данных Транспортного средства');
      }),
      untilDestroyed(this),
    )
    .subscribe((res: IResponseWithTotal<IAlertParentSource[]>) => {
      this.alertSources = res?.items ?? [];

      this.openDrawer = true;
      this.cdr.detectChanges();
    });

    /** При открытии формы инцидента/события или другого объекта слоя, закрываем форму просмотра */
    this.router.events
      .pipe(
        filter((event: IAnyObject) => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe((event: IAnyObject) => {
        if (event.url.match(/editForm|editEventForm|editCallForm|leftPopup/)) {
          this.close();
        }
      });
  }

  /** Возвращает наименование свойства */
  public getPropertyName(property: string) {
    return this.model?.[property]?.name || '';
  }

  /** Закрытие формы просмотра */
  public close(): void {
    this.mapModel.removeLayer(LayersEnum.clickMarker);
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  /**
   * Метод перехода/центрирования по координатам
   */
   public selectCoordinates() {
    this.gisService.setPositionMapOnCoordinates(this.model.coordinates);
  }
}

