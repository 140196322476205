import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NzViewControlComponent,
  NzViewIconTextControlComponent
} from './components';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

@NgModule({
  declarations: [
    NzViewControlComponent,
    NzViewIconTextControlComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NzCheckboxModule,
    NzIconModule,
    NzSwitchModule
  ],
  exports: [
    NzViewControlComponent,
    NzViewIconTextControlComponent
  ]
})
export class NzViewControlModule { }
