<div [formGroup]="form">
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-select
          [options]="fireObjectTypeOptions" formControlName="fireObjectTypeId"></sc-select>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-select [options]="objectFireOptions" formControlName="objectFireId"></sc-select>
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-input [options]="leaderOptions" formControlName="leader"></sc-input>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-input [options]="fireCulpritOptions" formControlName="fireCulprit"></sc-input>
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-select [options]="causeFireOptions" formControlName="causeFireId"></sc-select>
      </div>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-select [options]="rankOptions" formControlName="rankId"></sc-select>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-input [options]="fireAreaOption" formControlName="fireArea"></sc-input>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-form-group">
        <bg-datetime label="Актуально на" formControlName="relevantOnDate"></bg-datetime>
      </div>
    </div>
  </div>
  <div formArrayName="fireAreaZones">
    <div *ngFor="let el of zonesArray?.controls; let i = index" [formGroupName]="i" class="zone">
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <button (click)="removeZone(i)" [disabled]="!zonesArray.controls.length"
                    class="btn btn-sm btn-secondary-outline"><span class="material-icons">delete</span>
            </button>
          </div>
        </div>
      </div>
      <bg-forecasting-technological-fire-area-incident-form></bg-forecasting-technological-fire-area-incident-form>
    </div>
  </div>
  <div class="bg-row bg-row-align-content-end">
    <div class="bg-form-group">
      <button
        class="btn btn-sm btn-primary"
        (click)="addZone()"
      >
        Добавить зону
      </button>
    </div>
  </div>
</div>
