<div class="legend">
  <div class="legend__title" (click)="onShowLegend()">
    <div class="legend__title__icon">
      <span class="sc-icon-circles"></span>
    </div>
    Зонирование
    <div class="legend__title__toggle"
         [ngClass]="{ legend__title__toggle__open: !showLegend }"
    >
      <span class="sc-icon-chevron"></span>
    </div>
  </div>

  <div *ngIf="showLegend">
    <div class="legend__item">
      <div class="legend__item__bullet">
        <span style='color:#FDD13A;'>&#8226;</span>
      </div>
      500 м
    </div>
    <div class="legend__item">
      <div class="legend__item__bullet">
        <span style='color:#DA1E28;'>&#8226;</span>
      </div>
      50 м
    </div>
  </div>
</div>
