import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { MapDialogV2Component } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { WeatherDataService } from '@bg-front/weather-data/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Settings2Service } from '@smart-city/core/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { BaseForecastingBlowoutHazardousChemicalsComponent } from '../base-forecasting-blowout-hazardous-chemicals/base-forecasting-blowout-hazardous-chemicals.component';

@UntilDestroy()
@Component({
  selector: 'bg-time-approach-cloud-to-point-task',
  templateUrl: './time-approach-cloud-to-point-task.component.html',
  styleUrls: ['./time-approach-cloud-to-point-task.component.scss'],
})
export class TimeApproachCloudToPointTaskComponent
  extends BaseForecastingBlowoutHazardousChemicalsComponent
  implements OnInit, OnDestroy
{
  /** Режим просмотра */
  @Input()
  public override isShow: boolean = false;

  /**
   * @ignore
   */
  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly modalService: NzModalService,
    notificationService: NzNotificationService,
    settings: Settings2Service,
    weatherService: WeatherDataService,
  ) {
    super(settings, weatherService, notificationService);
  }

  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
    this.isShow = this.form.disabled;
  }

  /**
   * Обработчик клика по кнопке Уточнить адрес
   * @param $event
   */
  public onClickAppointCoordinates($event) {
    const exactCoordinates = new Coordinates(this.form.controls.coordinates.value);

    const data = {
      coordinates: exactCoordinates,
    };
    this.openSpecifyMap(data);
  }

  /** Метод который обеспечивает открытие диалога и подписку на закрытие
   * @param data - параметры для диалога
   */
  public openSpecifyMap(data: { coordinates?: Coordinates }): void {
    const modal = this.modalService.create({
      nzTitle: 'Укажите координату',
      nzContent: MapDialogV2Component,
      nzComponentParams: data,
      nzWidth: '600px',
    });

    let subs = modal.afterClose.pipe(untilDestroyed(this)).subscribe((params: any) => {
      if (params?.coordinates) {
        this.form.controls.coordinates.setValue(`${params.coordinates.join(', ')}`);
      }

      subs.unsubscribe();
      subs = undefined;
    });
  }
}
