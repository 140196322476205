<div [formGroup]="additionalForm" appLCSFormState [params]="attributes">
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-input [options]="totalCarsOption" formControlName="totalCars"></sc-input>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-input [options]="trunksOption" formControlName="trunks"></sc-input>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <sc-input [options]="personalOption" formControlName="personal"></sc-input>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group resolution-report-actual">
            <sc-textarea
              [options]="actualOptions"
              formControlName="actual"></sc-textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-col">
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <bg-datetime label="Дата и время обнаружения пожара" formControlName="timeDetection"
                         class="min-height-none"></bg-datetime>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <bg-datetime label="Дата и время выезда" formControlName="timeDeparture"
                         class="min-height-none"></bg-datetime>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <bg-datetime label="Дата и время прибытия" formControlName="timeArrival"
                         class="min-height-none"></bg-datetime>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <bg-datetime label="Дата и время подачи 1-го ствола" formControlName="timeFirstSalvo"
                         class="min-height-none"></bg-datetime>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <bg-datetime label="Дата и время локализации" formControlName="timeLocalization"
                         class="min-height-none"></bg-datetime>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <bg-datetime label="Дата и время ликвидации" formControlName="timeLiquidation"
                         class="min-height-none"></bg-datetime>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group">
            <bg-datetime label="Дата и время ликвидации последствий" formControlName="timeRecovery"
                         class="min-height-none"></bg-datetime>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
