import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IScSelectOptions, IScTextareaOptions, IElementButton, DialogService } from '@smart-city/core/common';
import {
  IReasonDto,
  IAnyObject,
  IUserInfoDto,
  IEmergencyVaDetailDto,
  ILifeCycleStepDto,
  IEmergencyResolutionDto,
  IEmergencyEventDto,
  IEmergencyDto,
  ILifeCycleStepActionDto,
} from 'smart-city-types';
import { IScSelectItem, IDictionaryInfo } from '@smart-city/core/interfaces';
import { map, mergeMap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import * as dayjs from 'dayjs';
import { BaseIncidentFormComponent } from '../base-incident-form/base-incident-form.component';
import { SecurityForcesService } from '../../../bg/modules/security-forces/services';
import { EmergencyDto } from '../../models/classes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VideoEmergencyTypeEnum } from '../../models/enums';
import { AccessService, RestService, Settings2Service } from '@smart-city/core/services';
import {
  AtmIntegrationService,
  BgAdminService,
  EmergencyService,
  MiniMapService,
  OrganizationsService,
  ResponsePlanStepService,
} from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { MultiFileService } from '@bg-front/core/services';
import { ReportingService } from '../../../bg/modules/reporting/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';


/** Форма для отображения инцидента неисправности видеокамеры */
@UntilDestroy()
@Component({
  selector: 'bg-webcam-failure-incident-edit-form',
  templateUrl: './webcam-failure-incident-edit-form.component.html',
  styleUrls: ['./webcam-failure-incident-edit-form.component.scss']
})
export class WebcamFailureIncidentEditFormComponent extends BaseIncidentFormComponent implements OnInit {

  /** Настройка селекта Наименование устройства */
  public optionsCamera1Id: IScSelectOptions = {
    title: 'Наименование устройства',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'VideoDevices',
    fieldName: 'name',
    disabled: true,
  };

  /**
   * Настройка селекта Причина неисправности
   */
  public optionsReasons: IScSelectOptions = {
    title: 'Причина неисправности',
    data: this.getReasons(),
    clearable: true,
  };

  /**
   * Настройка селекта Причина неисправности
   */
  public optionsResponsible: IScSelectOptions = {
    title: 'Ответственный пользователь',
    loadDataFn: () => this.getResponsible(),
    clearable: true,
    modern: true,
  };

  /**
   * Настройка селекта Отчёт об устранении неисправности
   */
  public reportOptions: IScTextareaOptions = {
    label: 'Отчёт об устранении неисправности',
    maxLength: 1000,
    rows: 8,
  };

  public vaDetail: IEmergencyVaDetailDto;

  /** Флаг, является ли источник Видеоаналитикой */
  public isVaSource: boolean;

  /**  Справочник видеоаналитики */
  private vaTypesDict: IDictionaryInfo[];

  /**
   * @ignore
   */
  constructor(
    private readonly securityForcesService: SecurityForcesService,
    accessService: AccessService,
    atmIntegrationService: AtmIntegrationService,
    bgAdminService: BgAdminService,
    dialog: MatDialog,
    dialogService: DialogService,
    emergencyService: EmergencyService,
    fb: FormBuilder,
    ksipCategoriesQuery: KsipCategoriesQuery,
    ksipDetailsQuery: KsipDetailsQuery,
    ksipSectionsQuery: KsipSectionsQuery,
    ksipTypesQuery: KsipTypesQuery,
    miniMapService: MiniMapService,
    multiFileService: MultiFileService,
    organizationsService: OrganizationsService,
    reportingService: ReportingService,
    route: ActivatedRoute,
    router: Router,
    settings: Settings2Service,
    significantObjectService: SignificantObjectsService,
    responsePlanStepService: ResponsePlanStepService,
  ) {
    super(
      accessService,
      atmIntegrationService,
      bgAdminService,
      dialog,
      dialogService,
      emergencyService,
      fb,
      ksipCategoriesQuery,
      ksipDetailsQuery,
      ksipSectionsQuery,
      ksipTypesQuery,
      miniMapService,
      multiFileService,
      organizationsService,
      reportingService,
      route,
      router,
      settings,
      significantObjectService,
      responsePlanStepService,
    );
  }

  /**
   * @ignore
   */
  public ngOnInit(): void {
    this.vaDetail = (this.model.parentEventId as IEmergencyEventDto).vaDetail as IEmergencyVaDetailDto;
    this.vaTypesDict = this.settings.getDictObjsIdsByType('monitoringSubject');

    this.incidentForm = new FormGroup({
      byCoordinates: new FormControl(this.model.byCoordinates),
      addressFact: new FormControl(this.model.addressFact, [Validators.required]),
      coordinatesAddress: new FormControl(this.model.byCoordinates ? (this.model.addressFact.fullText || this.model.address.fullText) : null),
      incidentTypeId: new FormControl(this.model.incidentTypeId, [Validators.required]),
      comment: new FormControl(this.model.comment),
      description: new FormControl(this.model.description),
      responsible: new FormControl(this.model.responsible || this.settings.currentUser.id, [Validators.required]),
      report: new FormControl(this.model.resolution?.report),
      camera1Id: new FormControl(this.vaDetail?.camera1Id || undefined),
      reasonId: new FormControl(this.vaDetail?.reasonId || undefined),
    })

    this.eventsGridQuery = { incidentId: this.model.id };

    super.ngOnInit();
    this.headerActionsOptions.title = `Неисправность ${(this.model.number ?? '').replace('i', 'f')}`;
    this.eventsGridOptions.fields = [
      { name: 'id', title: 'ID', disabled: true },
      { name: 'timeCreate', title: 'Время создания', widthColumn: '150px' },
      { name: 'sourceId.name', title: 'Источник' },
      { name: 'creationAuthor.fio', title: 'ФИО заявителя' },
      { name: 'creationAuthor.organizationId.name', title: 'Организация' },
      { name: 'number', title: 'Событие' },
    ];

    this.attributes = (this.model.lifeCycleStepId as ILifeCycleStepDto).params;

    if (this.isVaSource) {
      this.addressFactOptions.disabled = true;
    }

    this.initReactPlan();
  }

  /**
   * Проверяем наличие результатов ВА
   */
  public hasVaDetail() {
    return typeof (this.model.parentEventId as IEmergencyEventDto)?.vaDetail === 'object';
  }

  /**
   * Проверка типа аналитики - Саботаж
   */
  public isSabotage(): boolean {
    if (this.vaTypesDict && this.vaDetail) {
      return (
        (this.vaTypesDict[this.vaDetail.vaTypeId as string] || {}).sysname?.toString() ===
        VideoEmergencyTypeEnum.sabotage.toString()
      );
    }
    return false;
  }

  /** Получение данных из формы и сохранение в модель */
  public getModelData() {
    if (!this.model.address) {
      this.model.address = this.model.byCoordinates
        ? this.addressByCoordinates
        : this.incidentForm.controls['addressFact'].value;
    }
    this.model.byCoordinates = this.incidentForm.controls['byCoordinates'].value;
    this.model.addressFact = this.model.byCoordinates
      ? this.addressByCoordinates
      : this.incidentForm.controls['addressFact'].value;
    if (!this.model.id) {
      this.model.timeCreate = dayjs().unix() * 1000;
      this.model.number = `f-${dayjs(this.model.timeCreate).format('YYYYMMDD-HHmmss')}`;
      this.model.organization = this.settings.currentUser.organizationId.id;
      this.model.createdBy = this.settings.currentUser.id;
    }
    this.model.incidentTypeId = this.incidentForm.controls['incidentTypeId'].value;
    this.model.comment = this.incidentForm.controls['comment'].value;
    this.model.description = this.incidentForm.controls['description'].value;
    this.model.responsible = this.incidentForm.controls['responsible'].value;
    this.model.resolution = this.model.resolution || <IEmergencyResolutionDto>{};
    this.model.resolution.report = this.incidentForm.controls['report'].value;

    (this.model.parentEventId as IEmergencyEventDto).vaDetail = {
      reasonId: this.incidentForm.controls['reasonId'].value,
      camera1Id: this.incidentForm.controls['camera1Id'].value,
      id: this.vaDetail.id || null,
    };
  }

  public onClickActionsButton($event: IElementButton) {
    if ($event.options.name === 'cancel' || $event.options.name === 'export') {
      super.onClickActionsButton($event);
      return;
    }

    if (this.incidentForm.valid || this.incidentForm.disabled) {
      this.getModelData();
      this.model.lifeCycleStepId =
        typeof this.model.lifeCycleStepId === 'object'
          ? (<ILifeCycleStepDto>this.model.lifeCycleStepId)?.id
          : this.model.lifeCycleStepId;
      this.model['updateAuthor'] = this.settings.currentUser.id;
      this.model.deadline = undefined;

      const changedLifeCycleStepId = (this.settings.lifeCycleStep[this.model.lifeCycleStepId].actions || []).find(
        (i: ILifeCycleStepActionDto) => i.name === $event.options.name,
      )?.nextStep;
      this.isEmergencyEndStepCycle = this.settings.lifeCycleStep[changedLifeCycleStepId]?.endOfCycle;

      if (this.isEmergencyEndStepCycle) {
        this.model.timeFinish = new Date().getTime();
      }

      this.model.parentId =
        typeof this.model.parentId === 'object' ? (<EmergencyDto>this.model.parentId)?.id : this.model.parentId;

      this.executeAction({
        model: this.model,
        action: $event.options.name,
      }).pipe(
        mergeMap((emergency: IEmergencyDto) => {
          if (emergency) {
            this.model = emergency as EmergencyDto;
            this.actions = (emergency.lifeCycleStepId as ILifeCycleStepDto).actions;
            this.isDirty$ = of(false);
            if (this.isEmergencyEndStepCycle) {
              return this.completeIncident().pipe(
                untilDestroyed(this),
                map(() => true),
              );
            }
            return of(true);
          }
          return of(false);
        }),
        untilDestroyed(this),
      )
        .subscribe((result) => {
        if (result) {
          this.noteService.pushSuccess('Запись успешно изменена');
          this.updateForm();
        }
      });
    } else {
      /** Рекурсивно перебираем все контролы, на предмет контролов с ошибкой, для вывода информационного сообщения*/
      const labels: string[] = [];
      const recursiveFunc = (form: FormGroup | FormArray) => {
        Object.keys(form.controls).forEach((field: string) => {
          const control = form.get(field);
          if (control.invalid) {
            if (control instanceof FormGroup || control instanceof FormArray) {
              recursiveFunc(control);
            } else {
              labels.push(this.validationControlMap.get(field) ?? field);
            }
          }
        });
      };
      recursiveFunc(this.incidentForm);
      if (labels.length) {
        this.noteService.pushError(`Следующие поля содержат ошибки:\n ${labels.join(',\n')}`);
      }
    }
  }

  /**
   * Список причин
   */
  private getReasons() {
    const groupId = this.settings.reasonGroups.find((group: any) => group.sysName === 'errorsCameraReasons').id;
    return this.settings.reasons
      .filter((reason: IReasonDto) => reason.groupId === groupId)
      .map((reason: IReasonDto) => {
        return <IScSelectItem>{
          id: reason.id,
          text: reason.name,
        };
      });
  }

  /** Список Ответственные пользователи */
  private getResponsible(): Observable<{id: any, text: any}[]> {
    return forkJoin([
      this.securityForcesService.getRuleGroups().pipe(
        map((ruleGroups: IAnyObject[]) => {
          return ruleGroups.filter((group: IAnyObject) => {
            const hasGroupClaim = group?.pages
              ?.find((page: { pageId: string, actions: IAnyObject[] }) => page.actions
                ?.find((action: IAnyObject) =>
                  (action.name === 'responsibleFailureUser') && action.value && action.visible))
            return hasGroupClaim;
          })
            .map((group: IAnyObject) => group.id);
        })
      ),
      this.securityForcesService.getUsersByQuery(),
    ]).pipe(
      map(([groupsIds, users]: [string[], IUserInfoDto[]]) => {
        return users?.filter((user: IUserInfoDto) => {
          return user.groups?.find((groupId: string) => {
            return groupsIds?.find((id: string) => groupId === id);
          });
        })?.map((user: IUserInfoDto) => ({
          id: user.id,
          text: user.fio,
        })) || [];
      }),
    );
  }

  /**
   * Обновление формы
   */
  public updateForm() {
    /** Обновляем заголовок */
    this.generateHeaderBar();
    this.attributes = (this.model.lifeCycleStepId as ILifeCycleStepDto).params;
  }

  /** Закрытие формы */
  public closeForm() {
    this.emergencyService.selectIncident({ id: undefined, docType: 'incident' });
    if (this.route.snapshot.outlet === 'primary') {
      this.router.navigate(['consolidated-registries/incident-register']);
    } else {
      this.router.navigate([{ outlets: { editForm: null, editEventForm: null } }], {
        relativeTo: this.route.parent,
        queryParamsHandling: 'merge',
      });
    }
  }
}
