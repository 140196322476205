import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IAbstractServiceData,
  ILifeCycleStepDto,
  IDictionaryModelDto,
  IAnyObject,
  ILifeCycleDto,
} from 'smart-city-types';
import { RestService } from '@smart-city/core/services';

import { IButtonFilter, IStatusesFiltersQuery } from '../../models/interfaces';
import { TWorkspaceSysname } from '../../models/types';

/** Сервис для получения кнопок фильтра в реестрах */
@Injectable({
  providedIn: 'root',
})
export class RegistryFilterService {
  constructor(private readonly rest: RestService) {
  }

  /** Получение объекта запроса */
  private getRequestQuery(query: IStatusesFiltersQuery, workspaceSysname: TWorkspaceSysname): IAnyObject {
    // Особый запрос для ЦУКС
    if (workspaceSysname === 'cuks') {
      return {
        'lifeCycleId.type.sysname': query.lifeCycleTypeSysname,
        'lifeCycleId.docType.sysname': query.lifeCycleDocTypeSysname,
        $or: [
          { 'lifeCycleId.orgTypeParam.organizationTypeId.sysname': { $in: ['cuks', 'edds', 'dds01'] } },
          { 'lifeCycleId.orgTypeParam.isUlkWork': true },
        ],
      }
    }

    // Особый запрос для Центра управления регионом
    if (workspaceSysname === 'region-control-center') {
      return {
        'lifeCycleId.type.sysname': query.lifeCycleTypeSysname,
        'lifeCycleId.docType.sysname': query.lifeCycleDocTypeSysname,
      }
    }

    return {
      'lifeCycleId.type.sysname': query.lifeCycleTypeSysname,
      'lifeCycleId.docType.sysname': query.lifeCycleDocTypeSysname,
      'lifeCycleId.sourceType.sysname': query.lifeCycleSourceTypeSysname,
      'lifeCycleId.orgTypeParam.organizationTypeId.sysname': query.lifeCycleOrgTypeParamOrgTypeSysname,
    }
  }

  /** Получение кнопок для фильтров в реестре по состояниям шагов ЖЦ */
  public getStatusesFilters(query: IStatusesFiltersQuery, workspaceSysname: TWorkspaceSysname): Observable<IButtonFilter[]> {
    const requestQuery: IAnyObject = this.getRequestQuery(query, workspaceSysname);

    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'LifeCycleStep',
        query: requestQuery,
        attributes: [
          'status.name',
          'status.sysname',
          'status.id',
          'order',
          'defaultFilter'
        ],
      },
      data: {
        sort: { field: 'order', direction: 'asc' },
      },
    })
      .pipe(map((response: IAbstractServiceData<{ items: (ILifeCycleStepDto & { defaultFilter: boolean })[] }>) =>
        response.data.items.reduce((acc, step) => {
          if (acc.findIndex((item: IButtonFilter) => item.sysname === (<IDictionaryModelDto>step.status).sysname) === -1) {
            acc.push({
              name: (<IDictionaryModelDto>step.status).name,
              sysname: (<IDictionaryModelDto>step.status).sysname,
              state: step.defaultFilter || false,
              filter: {
                'lifeCycleStepId.status.id': (<IDictionaryModelDto>step.status).id,
                ...(query.lifeCycleDocTypeSysname
                  ? { 'lifeCycleStepId.lifeCycleId.docType.sysname': query.lifeCycleDocTypeSysname }
                  : {}),
              },
              groupName: query.lifeCycleDocTypeSysname,
            });
          }

          return acc;
        }, [])
      ));
  }

  /** Получение кнопок для фильтров в реестре УЛК по состояниям шагов ЖЦ */
  public getUlkStatusesFilters(query: IStatusesFiltersQuery): Observable<IButtonFilter[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'LifeCycleStep',
        query: {
          'lifeCycleId.type.sysname': query.lifeCycleTypeSysname,
          'lifeCycleId.docType.sysname': query.lifeCycleDocTypeSysname,
          $or: [
            {
              'lifeCycleId.orgTypeParam.organizationTypeId.sysname': query.lifeCycleDocTypeSysname === 'incident'
                ? 'other'
                : 'edds'
            },
            { 'lifeCycleId.orgTypeParam.isUlkWork': true },
          ],
        },
        attributes: [
          'status.name',
          'status.sysname',
          'status.id',
          'order',
          'defaultFilter',
          'lifeCycleId.docType.sysname',
        ],
      },
      data: {
        sort: { field: 'order', direction: 'asc' },
      },
    })
      .pipe(map((response: IAbstractServiceData<{ items: (ILifeCycleStepDto & { defaultFilter: boolean })[] }>) =>
        response.data.items.reduce((acc, step) => {
          if (acc.findIndex((item: IButtonFilter) => item.sysname === (<IDictionaryModelDto>step.status).sysname) === -1) {
            acc.push({
              name: (<IDictionaryModelDto>step.status).name,
              sysname: (<IDictionaryModelDto>step.status).sysname,
              state: step.defaultFilter || false,
              filter: { 'lifeCycleStepId.status.id': (<IDictionaryModelDto>step.status).id },
              groupName: (<IDictionaryModelDto><unknown>(<ILifeCycleDto>step.lifeCycleId).docType).sysname,
            });
          }

          return acc;
        }, [])));
  }

  /** Получение кнопок для фильтров в реестре МВД по состояниям шагов ЖЦ */
  public getMvdStatusesFilters(): Observable<IButtonFilter[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'LifeCycleStep',
        query: {
          'lifeCycleId.type.sysname': 'incident',
          'lifeCycleId.docType.sysname': { $in: ['incident', 'order'] },
          'lifeCycleId.orgTypeParam.shortName': 'ДДС 02',
        },
        attributes: [
          'status.name',
          'status.sysname',
          'status.id',
          'order',
          'defaultFilter',
          'lifeCycleId.docType.sysname',
        ],
      },
      data: {
        sort: { field: 'order', direction: 'asc' },
      },
    })
      .pipe(
        map((response: IAbstractServiceData<{ items: (ILifeCycleStepDto & { defaultFilter: boolean })[] }>) =>
          response.data.items.reduce((acc, step) => {
            const docType = (<IDictionaryModelDto>(<unknown>(<ILifeCycleDto>step.lifeCycleId).docType)).sysname;
            if (acc.findIndex((item: IButtonFilter) => item.sysname === (<IDictionaryModelDto>step.status).sysname
              && item.groupName === docType) === -1) {
              acc.push({
                name: (<IDictionaryModelDto>step.status).name,
                sysname: (<IDictionaryModelDto>step.status).sysname,
                state: step.defaultFilter || false,
                filter: {
                  'lifeCycleStepId.status.id': (<IDictionaryModelDto>step.status).id,
                  'lifeCycleStepId.lifeCycleId.docType.sysname': docType,
                },
                groupName: docType,
              });
            }

            return acc;
          }, [])),
      );
  }
}
