import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IScIconButtonOptions } from '@smart-city/core/common';
import { IEmergencyResponsePlanStep, IStepPlanListOptions } from '../../models/interfaces';
import { ResponsePlanStepClickEvent } from '../../models/types';

/**
 * Компонент для отображения блока со списками шагов
 */
@Component({
  selector: 'bg-response-plan-step-list',
  templateUrl: './response-plan-step-list.component.html',
  styleUrls: ['./response-plan-step-list.component.scss'],
})
export class ResponsePlanStepListComponent implements OnInit {
  /**
   * Уведомляем о нажатии на шаг реагирования
   * Эмиттер асинхронный
   */
  @Output()
  public stepClickEvent: EventEmitter<ResponsePlanStepClickEvent> = new EventEmitter<ResponsePlanStepClickEvent>(true);

  @Output()
  public createStepEvent: EventEmitter<ResponsePlanStepClickEvent> = new EventEmitter<ResponsePlanStepClickEvent>(true);

  @Input()
  /** Опции для компонента список шагов */
  public options: IStepPlanListOptions;
  /** Активность */
  @Input() disabled: boolean;
  /** Класс для фиксированного заголовка */
  public fixedHeaderClass: string = '';

  public createBtnOptions: IScIconButtonOptions;



  /** @ignore */
  public ngOnInit(): void {
    if (this.options?.isHeaderFix) {
      this.fixedHeaderClass = 'response-plan-header-title-fixed';
    }
    this.createBtnOptions = {
      icon: 'add',
    };
  }

  /** Обработчик клика по шагу
   * @param event
   * @param step - текущий шан
   */
  public stepClickHandler(event: Event, step: IEmergencyResponsePlanStep): void {
    this.stepClickEvent.emit({ event, step });
  }

  public createStepHandler(event: Event): void {
    this.createStepEvent.emit({ event });
  }
}
