import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BaseComponent } from '@bg-front/core/components';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';
import { KseonUnitsService } from '@bg-front/kseon-units/services';
import { ISignificantObjectData } from '@bg-front/significant-objects/models';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { GisService } from '@smart-city/maps/sc';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { IChemicalForecastResultsDto, IChemicalTypeDto } from 'smart-city-types';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';

import { ChemicalTypesService } from '../../../stored-chemicals/services';
import {
  IAccidentOnChemicalObjectReport,
  IAccidentOnChemicalObjectTaskParamDto,
  IChemicalForecastResultsView
} from '../../models/interfaces';
import { ForecastingBlowoutReportService, ForecastingBlowoutVisualizationService } from '../../services';

dayjs.extend(duration);

@UntilDestroy()
@Component({
  selector: 'bg-accident-on-chemical-object-task-result',
  templateUrl: './accident-on-chemical-object-task-result.component.html',
  styleUrls: ['./accident-on-chemical-object-task-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccidentOnChemicalObjectTaskResultComponent extends BaseComponent implements OnInit, OnDestroy {
  /**
   * Режим просмотра.
   * Отображать визуализацию не надо
   * TODO: После реализации формы просмотра убрать
   */
  @Input()
  public isShow = false;

  @Input()
  public forecastingResults: IForecastingResultDto;
  /** Скачать отчёт */
  @Input()
  public downloadReport: Observable<void>;
  /** Уведомляем о наличии отчёта */
  @Output()
  public hasReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  /** Уведомляем о формировании отчёта */
  @Output()
  public isDownloadReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  /** Ссылка на результат отрисовки */
  private displayElement: any;

  /** @ignore */
  constructor(
    private readonly gisService: GisService,
    private readonly significantObjectService: SignificantObjectsService,
    private readonly forecastingService: ForecastingBlowoutVisualizationService,
    private readonly reportingService: ForecastingBlowoutReportService,
    private readonly chemicalTypesService: ChemicalTypesService,
    private readonly settings2: Settings2Service,
    private readonly cdr: ChangeDetectorRef,
    private readonly unitService: KseonUnitsService,
  ) {
    super();
  }

  public get result(): IChemicalForecastResultsView {
    return <IChemicalForecastResultsView>{
      ...this.forecastingResults.result,
      lethalityDuration: dayjs.duration(Math.round(this.forecastingResults.result.lethalityDuration * 60), 'minutes')
        .format('HH ч mm мин'),
    };
  }

  /**
   * @ignore
   */
  public ngOnInit(): void {
    if (!this.isShow) {
      this.displayElement = this.forecastingService.displayChemicalResults(
        this.forecastingResults.params.coordinates,
        (this.forecastingResults.params.params as IAccidentOnChemicalObjectTaskParamDto).windDirection,
        (this.forecastingResults.params.params as IAccidentOnChemicalObjectTaskParamDto).windVelocity,
        this.result.pollutionDepth,
      );
    }
    this.hasReport.emit(true);

    this.downloadReport.pipe(untilDestroyed(this)).subscribe(() => {
      const report: IAccidentOnChemicalObjectReport = {};
      const params = this.forecastingResults.params.params as IAccidentOnChemicalObjectTaskParamDto;
      const significantObjectObs = this.significantObjectService.getSignificantObjectById(
        this.forecastingResults.objectId,
      );
      const chemicalTypeObs = this.chemicalTypesService.getById(params.chemicalTypeId);
      let significantObject: ISignificantObjectData;

      this.isDownloadReport.emit(true);
      return forkJoin([significantObjectObs, chemicalTypeObs])
        .pipe(
          mergeMap((res: [ISignificantObjectData, IChemicalTypeDto]) => {
            significantObject = res[0];
            const chemicalType = res[1];
            report.chemicalType = chemicalType.name;

            // приводим координаты к нужному виду
            const coordinates = this.forecastingService.coordinatesChemicalResults(
              this.forecastingResults.params.coordinates,
              params.windDirection,
              params.windVelocity,
              this.result.pollutionDepth,
            );
            return forkJoin([
              (this.forecastingResults && coordinates
                ? this.gisService.getAddressesByPolygonV2(coordinates)
                : of(null)
              ).pipe(
                catchError((err: Error) => {
                  this.catchErrorFn(err, err.message);
                  return of(null);
                }),
              ),
              (coordinates?.length
                  ? this.unitService.getKseonUnit({
                    point: {
                      $polygon: {
                        $points: [
                          ...coordinates.map((item: number[]) => [item[0], item[1]]),
                          ...[[coordinates[0][0], coordinates[0][1]]],
                        ],
                      },
                    },
                  })
                : of(null)
              ).pipe(
                catchError((err: Error) => {
                  this.catchErrorFn(err, err.message);
                  return of(null);
                }),
              ),
            ]);
          }),
          mergeMap(([res, units]) => {
            /** Блок "Местоположение" */
            if (significantObject?.address?.fullText) {
              report.address = significantObject.address.fullText;
            }
            report.coordinates = significantObject?.coordinates;
            report.object = significantObject.name;
            report.responsible = significantObject?.responsible;
            report.phone = significantObject?.phone;

            /** Блок "Исходные данные" */
            report.emergency = this.forecastingResults.emergencyNumber || 'не задано';
            report.chemicalAmount = +params.chemicalAmount;
            report.timeToSpread = +params.timeToSpread;
            report.dischargeType = this.settings2.getDictionaryById(params.dischargeTypeId)?.name;
            report.dischargeArea = +params.dischargeArea;

            /** Блок "Метеоусловия в момент аварии" */
            report.windVelocity = +params.windVelocity;
            report.windDirection = +params.windDirection;
            report.isSnowy = params.isSnowy ? 'Да' : 'Нет';
            report.airTemperature = +params.airTemperature;
            report.cloudState = this.settings2
              .getDictionaryByTypeSysName('cloudy')
              .find((item: IDictionaryInfo) => item.id === params.cloudStateId).name;
            report.timeOfDay = this.settings2
              .getDictionaryByTypeSysName('timesOfDay')
              .find((item: IDictionaryInfo) => item.id === params.timeOfDayId).name;

            /** Блок "Используемая методология для прогнозирования" */
            report.methodology = `СП 165.1325800.2014 Инженерно-технические мероприятия по гражданской обороне. Актуализированная редакция
  СНиП 2.01.51-90 (с Изменением N 1)
  Приложение Б (обязательное). Методика прогнозирования масштабов возможного химического заражения аварийно химически
  опасными веществами при авариях на химически опасных объектах и транспорте`;

            /** Блок "Результаты расчёта" */
            report.chemicalForecastResults = { ...(this.forecastingResults.result as IChemicalForecastResultsDto) };

            /** Блок "Здания в зоне поражения" */
            report.buildings = [...res];

            /** Блок "Средства оповещения в зоне возможного химического заражения" */
            report.devices = [
              ...(units || []).map((item) => {
                return {
                  type: item.type,
                  name: item.name,
                  currentSeanceName: item.currentSeanceName,
                  syncTime: item.syncTime,
                };
              }),
            ];

            return of(report);
          }),
          finalize(() => this.isDownloadReport.emit(false)),
          untilDestroyed(this),
        )
        .subscribe((chemicalReport: IAccidentOnChemicalObjectReport) => {
          this.reportingService.buildForecastingChemicalReport(chemicalReport);
        });
    });

    this.cdr.markForCheck();
  }

  public override ngOnDestroy(): void {
    this.forecastingService.clearResults(this.displayElement);
    super.ngOnDestroy();
  }
}
