<div class="bg-row">
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Распознанный ГРЗ</label>
      <p>{{ vaDetail?.mark }}</p>
    </div>
  </div>
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Расстояние между видеокамерами (м)</label>
      <p>{{ (vaDetail?.distance | number: '.0-0')?.replace(',', '') }}</p>
    </div>
  </div>
</div>
<div class="bg-row">
  <div class="bg-col">
    <h3>Первая регистрация ГРЗ ТС</h3>
  </div>
  <div class="bg-col">
    <h3>Вторая регистрация ГРЗ ТС</h3>
  </div>
</div>
<div class="bg-row">
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Наименование устройства</label>
      <p class="two-line-ellipsis-overflow">{{ firstCameraName }}</p>
    </div>
  </div>
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Наименование устройства</label>
      <p class="two-line-ellipsis-overflow">{{ secondCameraName }}</p>
    </div>
  </div>
</div>

<div class="bg-row">
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <label>Дата и время фиксации ТС</label>
      <p>{{ vaDetail?.camera1Time | date: 'dd.MM.yyyy HH:mm:ss' }}</p>
    </div>
  </div>
  <div class="bg-col">
    <div class="bg-form-group readonly-container">
      <div class="readonly-container__column">
        <label>Дата и время фиксации ТС</label>
        <p>{{ vaDetail?.camera2Time | date: 'dd.MM.yyyy HH:mm:ss' }}</p>
      </div>
    </div>
  </div>
</div>
<div class="bg-row">
  <div class="bg-col" *ngIf="canPreparePhotoAndVideoForRepeatGrz?.visible">
    <div class="bg-form-group" >
      <p
        class="image-video-link"
        (click)="showImageVideoRecording(
          vaDetail?.camera1Id?.toString(),
          vaDetail?.file1Id,
          vaDetail?.camera1Time,
          entityId,
          'Camera_1'
        )"
      >
        Фото- и видеоматериалы
      </p>
    </div>
    <div class="bg-row" *ngIf="canPreparePhotoAndVideoForRepeatGrz?.visible">
      <div class="bg-col">
        <bg-download-sources [options]="optionsFromFirstCam"
                             [disabled]="!canPreparePhotoAndVideoForRepeatGrz?.enabled"></bg-download-sources>
      </div>
    </div>
  </div>
  <div class="bg-col" *ngIf="canPreparePhotoAndVideoForRepeatGrz?.visible">
    <div class="bg-form-group">
      <p
        class="image-video-link"
        (click)="showImageVideoRecording(
          vaDetail?.camera2Id?.toString(),
          vaDetail?.file2Id,
          vaDetail?.camera2Time,
          entityId,
          'Camera_2'
        )"
      >
        Фото- и видеоматериалы
      </p>
    </div>
    <div class="bg-row" *ngIf="canPreparePhotoAndVideoForRepeatGrz?.visible">
      <div class="bg-col">
        <bg-download-sources [options]="optionsFromSecondCam"
                             [disabled]="!canPreparePhotoAndVideoForRepeatGrz?.enabled"></bg-download-sources>
      </div>
    </div>
  </div>
</div>
