<div>
  <h4 nz-typography>
    <i nz-icon nzType="field-time" nzTheme="outline"></i>
    Результаты прогноза
  </h4>
  <nz-divider></nz-divider>
  <nz-descriptions nzTitle="Расход огнетушащего вещества" nzBordered nzSize="small" [nzColumn]="1">
    <nz-descriptions-item nzTitle="на ликвидацию горения">
      {{ result?.extinguishingAgentLiquidateConsumption | commaToDot | number: '0.2-2' }} л/с
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="на защиту объекта">
      {{ result?.extinguishingAgentProtectConsumption | commaToDot | number: '0.2-2' }} л/с
    </nz-descriptions-item>
  </nz-descriptions>
</div>
