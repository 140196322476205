import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { INwHeaderBarOptions, IScSelectOptions } from '@smart-city/core/common';
import { AccessService } from '@smart-city/core/services';
import { takeUntil } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';
import { BaseComponent } from '@bg-front/core/components';
import { IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { FilterOperationEnum } from '@bg-front/core/models/enums';

@Component({
  selector: 'bg-map-planned-works-filter',
  templateUrl: './map-planned-works-filter.component.html',
  styleUrls: ['./map-planned-works-filter.component.scss']
})
export class MapPlannedWorksFilterComponent extends BaseComponent implements OnInit, OnChanges {
  /** Заголовок фильтра */
  @Input()
  public title: string;

  /** Значения фильтров */
  @Input()
  public mapLayerFilters: IMapLayerEntityFilter[] = [];

  /** Оповещение о изменении значений фильтров */
  @Output()
  public changedFilters: EventEmitter<IMapLayerEntityFilter[] | 'delete'> = new EventEmitter<
    IMapLayerEntityFilter[] | 'delete'
    >();

  /** Форма */
  public form: FormGroup;

  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions;

  /** Настройка компоненты Тип КСиП */
  public incidentTypeIdOptions: IScSelectOptions = {
    title: 'Тип КСиП',
    clearable: true,
    service: 'Admin',
    entity: 'IncidentTypes',
    modern: true,
    fieldName: 'name',
    query: {
      active: true,
      plannedWork: true,
    },
  };

  /** Настройка компоненты Состояние */
  public stateOptions: IScSelectOptions = {
    title: 'Состояние',
    clearable: true,
    data: [
      { id: 'new', text: 'Новый' },
      { id: 'inWork', text: 'В работе' },
    ],
  };

  constructor(private readonly access: AccessService) {
    super();

  }



  ngOnInit(): void {
    this.headerOptions = {
      margin: 'collapse',
      title: this.title,
      buttons: [
        {
          type: 'button',
          options: {
            name: 'delete',
            icon: 'delete_outline',
          },
        },
      ],
    };

    this.form = new FormGroup({
      incidentTypeId: new FormControl(this.mapLayerFilters.find((item) => item.property === 'incidentTypeId')?.value),
      state: new FormControl(
        this.mapLayerFilters.find((item) => item.property === 'lifeCycleStepId.status.sysname')?.value,
      ),
      timeCreateFrom: new FormControl(
        this.mapLayerFilters.find(
          (item) =>
            item.property === 'timeCreate' && item.operation === FilterOperationEnum.greatThenOrEqual,
        )?.value,
      ),
      timeCreateTo: new FormControl(
        this.mapLayerFilters.find(
          (item) =>
            item.property === 'timeCreate' && item.operation === FilterOperationEnum.lessThenOrEqual,
        )?.value,
      ),
    });

    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => this.changedFilters.emit(this.getFilters(value)));
  }

  /** @ignore */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue.length === 0) {
      this.form?.reset({}, { emitEvent: false });
    }
  }

  /**
   * Формирование фильтров на основании значения формы
   * @param value значение формы
   */
  getFilters(value: IAnyObject): IMapLayerEntityFilter[] {
    const result: IMapLayerEntityFilter[] = [];
    if (value.incidentTypeId) {
      result.push({
        property: 'incidentTypeId',
        value: value.incidentTypeId,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.state) {
      result.push({
        property: 'lifeCycleStepId.status.sysname',
        value: value.state,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.timeCreateFrom) {
      result.push({
        property: 'timeCreate',
        value: value.timeCreateFrom,
        operation: FilterOperationEnum.greatThenOrEqual,
      });
    }
    if (value.timeCreateTo) {
      result.push({
        property: 'timeCreate',
        value: value.timeCreateTo,
        operation: FilterOperationEnum.lessThenOrEqual,
      });
    }
    return result;
  }

  public delete() {
    this.changedFilters.emit('delete');
    this.form.reset();
    this.form.enable();
  }

}
