import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseComponent } from '@bg-front/core/components';
import { Settings2Service } from '@smart-city/core/services';
import { IEmergencyForMiniCard } from '../../models/interfaces';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { MapBaseModel, MapBaseService } from '@smart-city/maps/sc';
import { BgMapService } from '@bg-front/core/services';
import { Coordinates } from '@bg-front/core/models/classes';

/** Компонент карточки происшествия, для списка связанных происшествий */
@UntilDestroy()
@Component({
  selector: 'bg-emergency-mini-card',
  templateUrl: './emergency-mini-card.component.html',
  styleUrls: ['./emergency-mini-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmergencyMiniCardComponent extends BaseComponent implements OnInit {
  /** Происшествие */
  @Input() public emergency: IEmergencyForMiniCard;

  /** Модель карты для инициализации */
  private mapModel: MapBaseModel;

  /** Наименование типа КСИП */
  public incidentTypeName: string;

  /** Цвет статуса ЖЦ инцидента */
  public statusColor: string;

  /** Id типа происшествия Инцидент, необходимо для определения цвета иконки */
  public docTypeIncidentTypeId: string = this.setting2.getDictionaryByTypeAndSysName('docType', 'incident')?.id;

  /** @ignore */
  constructor(
    private readonly setting2: Settings2Service,
    private readonly ksipTypeQuery: KsipTypesQuery,
    private readonly mapService: MapBaseService,
    private readonly gisService: BgMapService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.mapModel = new MapBaseModel('baseMapWorkspace', this.mapService);
    this.incidentTypeName = this.ksipTypeQuery.getById(this.emergency.incidentTypeId)?.name;

    switch (this.emergency?.lifeCycleStepStatusSysName) {
      case 'new':
        this.statusColor = 'red'
        break;
      default:
        this.statusColor = 'default';
    }
  }

  /**
   * Метод перехода/центрирования по координатам
   * Если это координаты инцидента, отрисовываваются круги
   */
  public setPositionMapOnEmergency() {
    if (this.emergency.docTypeId) {
      this.mapModel.addCircleToLayer(
        'circle',
        'circleBig',
        Coordinates.coordinatesToArray(this.emergency.coordinates),
        500,
        {
          stroke: false,
          fillColor: '#FDD13A',
          fillOpacity: 0.3,
        },
      );
      this.mapModel.addCircleToLayer(
        'circle',
        'circleMin',
        Coordinates.coordinatesToArray(this.emergency.coordinates),
        50,
        {
          stroke: false,
          fillColor: '#DA1E28',
          fillOpacity: 0.4,
        },
      );
      this.mapModel.viewLayer('circle', false);
    }
    this.gisService.setPositionMapOnCoordinates(this.emergency.coordinates);
  }
}
