<ng-container [formGroup]="$any(controlContainer.control)">
  <div class="dds01-looses">
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select [options]="damageOptions" formControlName="damageId"></sc-select>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="sumDamageOption" formControlName="sumDamage"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="savedTangiblesOption" formControlName="savedTangibles"></sc-input>
        </div>
      </div>
    </div>

    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Строений</th>
                <th>Техники</th>
                <th>Скота</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Уничтожено</td>
                <td align="center">
                  <input class="count-loses" type="number" min="0" step="1" formControlName="destroyedBuildings" />
                </td>
                <td align="center">
                  <input class="count-loses" type="number" min="0" step="1" formControlName="destroyedTech" />
                </td>
                <td align="center">
                  <input class="count-loses" type="number" min="0" step="1" formControlName="destroyedCattle" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="peopleInTheZoneOption" formControlName="peopleInTheZone"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="peopleInTheZoneChildrenOption" formControlName="peopleInTheZoneChildren"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <bg-datetime
            label="Актуально на"
            formControlName="peopleInTheZoneOnDate"
            class="min-height-none"
          ></bg-datetime>
        </div>
      </div>
    </div>

    <div class="bg-row bg-row-align-content-center">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="rescuedOption" formControlName="rescued"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="rescuedChildrenOption" formControlName="rescuedChildren"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <bg-datetime label="Актуально на" formControlName="rescuedOnDate" class="min-height-none"></bg-datetime>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <button mat-flat-button class="add-button" (click)="addButtonClick('rescued')">
            Заполнить список спасённых
          </button>
        </div>
      </div>
    </div>

    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="victimOption" formControlName="victim"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="victimChildrenOption" formControlName="victimChildren"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <bg-datetime label="Актуально на" formControlName="victimOnDate" class="min-height-none"></bg-datetime>
        </div>
      </div>
    </div>

    <div class="bg-row bg-row-align-content-center">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="evacuatedOption" formControlName="evacuated"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="evacuatedChildrenOption" formControlName="evacuatedChildren"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <bg-datetime label="Актуально на" formControlName="evacuatedOnDate" class="min-height-none"></bg-datetime>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <button mat-flat-button class="add-button" (click)="addButtonClick('evacuated')">
            Заполнить список эвакуированных
          </button>
        </div>
      </div>
    </div>

    <div class="bg-row bg-row-align-content-center">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="hospitalizedOption" formControlName="hospitalized"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="hospitalizedChildrenOption" formControlName="hospitalizedChildren"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <bg-datetime label="Актуально на" formControlName="hospitalizedOnDate" class="min-height-none"></bg-datetime>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <button mat-flat-button class="add-button" (click)="addButtonClick('hospitalized')">
            Заполнить список госпитализированных
          </button>
        </div>
      </div>
    </div>

    <div class="bg-row bg-row-align-content-center">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="deathOption" formControlName="death"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="deathChildrenOption" formControlName="deathChildren"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <bg-datetime label="Актуально на" formControlName="deathOnDate" class="min-height-none"></bg-datetime>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <button mat-flat-button class="add-button" (click)="addButtonClick('death')">
            Заполнить список погибших
          </button>
        </div>
      </div>
    </div>

    <div class="bg-row bg-row-align-content-center">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="missingOption" formControlName="missing"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-input [options]="missingChildrenOption" formControlName="missingChildren"></sc-input>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <bg-datetime label="Актуально на" formControlName="missingOnDate" class="min-height-none"></bg-datetime>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <button mat-flat-button class="add-button" (click)="addButtonClick('missing')">
            Заполнить список пропавших без вести
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
