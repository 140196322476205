/**
 * Результат возвращаемый диалогом отчетов проишествий
 */
export enum EmergencyReportsDialogResultEnum {
  /** Закрытие */
  close = 0,
  /** Розыск ТС */
  vehicleSearch = 1,
  /** Дубликат ГРЗ */
  duplicateGRZ = 2,
  /** Громкий звук и оставленный предмет */
  loudSound = 3,
  /** Массовое скопление */
  crowds = 4,
  /** Саботаж */
  sabotage = 5,
  /** Отчет по форме инцидента */
  eddsCallEdds112 = 6,
  /** Отчет по форме инцидента (оставленный предмет) */
  thing = 7,
  /** Донесение по форме */
  reportEmergency = 8,
  /** Сведения о готовности ЛПУ и ПВР */
  lpuPvrReport = 9,
  /** Список госпитализированных/погибших/эвакуированных/пострадавших */
  reportVictims = 10,
  /** Список госпитализированных/погибших/эвакуированных/пострадавших */
  listOfVictims = 11,

  objRestrictedArea = 13,
}
