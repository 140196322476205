<div class="container">
  <div class="sidebar">
    <div class="sidebar__header">
      <nw-header
        [options]="sidebarHeaderOptions"
        (clickButton)="onClickSidebarHeaderButton($event)">
      </nw-header>
      <sc-button
        [options]="buttons.edit"
        (clickButton)="onClickEditTask()">
      </sc-button>
      <sc-button
        *ngIf="showCancelButton"
        [options]="buttons.cancel"
        (clickButton)="onClickStateButton('canceled')">
      </sc-button>
      <sc-button
        *ngIf="showWorkButton"
        [options]="buttons.work"
        (clickButton)="onClickStateButton('inProgress')">
      </sc-button>
      <sc-button
        *ngIf="showPauseButton"
        [options]="buttons.pause"
        (clickButton)="onClickStateButton('pause')">
      </sc-button>
      <sc-button
        *ngIf="showStopButton"
        [options]="buttons.stop"
        (clickButton)="onClickStateButton('finished')">
      </sc-button>
    </div>


    <div class="task-panel">
      <audio *ngIf="task.audio && !isSms" [src]="task.audio" controls>
      </audio>
      <p *ngIf="task.name" class="task-panel__name">
        {{task.name}}
      </p>
      <p *ngIf="task.type" class="task-panel__field">
        <span>
          Тип задания
        </span>
        {{task.type}}
      </p>
      <p *ngIf="task.responsible" class="task-panel__field">
        <span>
          Ответственный
        </span>
        {{task.responsible}}
      </p>
      <p *ngIf="task.executionDate" class="task-panel__field">
        <span>
          Срок исполнения
        </span>
        {{task.executionDate | date: 'dd.MM.yyyy'}}
      </p>
      <p *ngIf="task.comment" class="task-panel__field">
        <span>
          Текст сообщения
        </span>
        {{task.comment}}
      </p>
    </div>

    <sc-button
      class="back-button"
      *ngIf="isHistory"
      [options]="buttons.back"
      (clickButton)="onClickBackButton()">
    </sc-button>
  </div>

  <div class="inner-container" *ngIf="!isAdminOfficials()">
    <registry #units
      *ngIf="registryCitizens?.query"
      [tableName]="registryCitizens.tableName"
      [columns]="registryCitizens.columns"
      [requestQuery]="registryCitizens.query"
      [loadDataFn]="taskService.loadCitizenRegistryDataFn"
      [autoRefresh]="true">
    </registry>
  </div>

  <div class="inner-container" *ngIf="isAdminOfficials()">
    <registry #units
              *ngIf="registryOfficials?.query"
              [tableName]="registryOfficials.tableName"
              [columns]="registryOfficials.columns"
              [requestQuery]="registryOfficials.query"
              [loadDataFn]="taskService.loadAnotherRegistryData('Admin', 'Officials', adminOfficialList)"
              [autoRefresh]="true">
    </registry>
  </div>

  <!--
    кал-менеджер здесь не нужен, т.к. обзвон не ручной
    на ЦОВ-демо он присутствовал т.к. там ручной обзвон
    сейчас из-за него (не только из-за него) NullInjectorError
  -->
</div>
