<div mat-dialog-content>
  <div class="bg-row">
    <div class="bg-col">
      <h3>Выберите причину закрытия</h3>
    </div>
  </div>
  <div class="bg-row">
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-select [options]="optionsReasons" [(ngModel)]="reasonId"></sc-select>
      </div>
    </div>
  </div>
  <div class="bg-row" *ngIf="needComment">
    <div class="bg-col">
      <div class="bg-form-group">
        <sc-textarea [options]="optionsComment" [(ngModel)]="comment" class="textarea"></sc-textarea>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="close()">Отмена</button>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="!isValid()" cdkFocusInitial>Сохранить</button>
</div>
