<form nz-form [formGroup]="form" errorTailor id="idForTest" nzLayout="vertical" *ngIf="form">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <ng-template #mainParams>
        <h4 class="m-b-0">Основные параметры расчёта</h4>
      </ng-template>
      <nz-divider [nzText]="mainParams" nzOrientation="left"></nz-divider>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="objectFireId">Объект пожара</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="objectFireId" nzSize="large">
            <nz-option [nzValue]="obj.value" [nzLabel]="obj.text" *ngFor="let obj of fireObjects$ | async"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="fireArea">Площадь участка</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="fireArea">
            <input nzSize="large" nz-input formControlName="fireArea" [controlErrorAnchor]="fireAreaAnchor"/>
          </nz-input-group>
          <ng-template controlErrorAnchor #fireAreaAnchor="controlErrorAnchor"></ng-template>
          <ng-template #fireArea> м² </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
