<div *ngIf="regime">
  <nw-header [options]="headerOptions" (clickButton)="clickHeaderButton($event)"></nw-header>
  <div class="regime-container">
    <div class="content">
      <div class="bg-row">
        <div class="bg-col-min">
          <div class="icon">
            <span class="sc-icon-polygon"></span>
          </div>
        </div>
        <div class="bg-col">
          <div [attr.tooltip]="type" [attr.flow]="'down'">
            <div class="regime-name">
              {{ type }}
            </div>
          </div>
        </div>
        <div class="bg-col-min">
          <mat-icon
            class="regime-link"
            *ngIf="linkedEmergencies?.length"
            (click)="showLinkedEmergencies(linkedEmergencies)"
          >
            link
          </mat-icon>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col">
          <div class="bg-form-group pl-10 pr-10">
            <div class="show-on-map" (click)="showOnMap()" *ngIf="isShowOnMapButton">
              <span class="sc-icon-place"></span> Показать на карте
            </div>
          </div>
        </div>
      </div>
      <div class="bg-row" *ngIf="introducingReasonFile?.uuid">
        <div class="bg-col">
          <div class="bg-form-group">
            <div class="regime-introducing-reason" (click)="download(introducingReasonFile.uuid)">
              {{ introducingReasonFile.fileName }}
            </div>
          </div>
        </div>
      </div>
      <div class="bg-row">
        <div class="bg-col">
          <div class="regime-main-info-container">
            <div class="bg-row">
              <div class="bg-col">
                <label>Дата и время введения:</label>
                <p>{{ introducingTime }}</p>
              </div>
            </div>
            <hr/>
            <ng-container *ngIf="responseLevel">
              <div class="bg-row">
                <div class="bg-col">
                  <label>Уровень реагирования:</label>
                  <p>{{responseLevel.name}}</p>
                </div>
              </div>
              <hr/>
            </ng-container>
            <ng-container *ngIf="coverageArea">
              <div class="bg-row" (click)="onClickCoverageArea($event)">
                <div class="bg-col" >
                  <label>Зона действия:</label>
                  <div [attr.tooltip]="coverageArea">
                    <p class="coverage-area" [ngClass]="{'link': isFormInfo}">{{coverageArea}}</p>
                  </div>
                </div>
              </div>
              <hr/>
            </ng-container>
            <ng-container *ngIf="coordinates">
              <div class="bg-row">
                <div class="bg-col">
                  <label>Координаты:</label>
                  <p class="link" (click)="selectCoordinates()">{{coordinates}}</p>
                </div>
              </div>
              <hr/>
            </ng-container>
            <div class="bg-row">
              <div class="bg-col">
                <label>Описание:</label>
                <p>{{ regime.description }}</p>
              </div>
            </div>
            <hr/>
            <ng-container *ngIf="regime.comment">
              <div class="bg-row">
                <div class="bg-col">
                  <label>Комментарий:</label>
                  <p>{{regime.comment}}</p>
                </div>
              </div>
              <hr/>
            </ng-container>
            <mat-accordion *ngIf="significantObjects?.length">
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'16px'" [expandedHeight]="'16px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Важные объекты ({{ significantObjects.length }})</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let significantObject of significantObjects">
                  <bg-significant-object-mini-card [data]="significantObject"></bg-significant-object-mini-card>
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
