/** Ключи операций */
export enum FilterOperationEnum {
  equal = 'equal',
  notEqual = 'notEqual',
  greatThen = 'greatThen',
  greatThenOrEqual = 'greatThenOrEqual',
  lessThen = 'lessThen',
  lessThenOrEqual = 'lessThenOrEqual',
  or = 'or',
  in = 'in',
}
