import { Injectable, OnDestroy } from '@angular/core';
import { DirtyCheckGuard } from '@ngneat/dirty-check-forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { ConfirmDialogResultEnum } from '../models/enums/confirm-dialog-result.enum';

@Injectable()
export class DirtyGuard extends DirtyCheckGuard implements OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private modal: NzModalService) {
    super();
  }

  public confirmChanges(): Observable<boolean> {
    const dialogRef = this.modal.create({
      nzTitle: 'Подтвердите действие',
      nzContent: 'Есть не сохранённые изменения. Остаться на странице?',
      nzClosable: false,
      nzCancelText: 'Нет',
      nzOkText: 'Да',
      nzOnOk: () => ConfirmDialogResultEnum.confirm,
      nzOnCancel: () => ConfirmDialogResultEnum.reject,
    });

    return dialogRef.afterClose.pipe(
      switchMap((result: ConfirmDialogResultEnum): Observable<boolean> => {
        switch (result) {
          case ConfirmDialogResultEnum.reject:
          case ConfirmDialogResultEnum.closeWithoutReaction:
            return of(true);
          case ConfirmDialogResultEnum.confirm:
            return of(false);
            break;
        }

        return of(false);
      }),
      takeUntil(this.ngUnsubscribe),
    );
  }

  /** @ignore */
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
