import { Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from '@bg-front/core/components';
import { ISelectItem } from '@bg-front/core/models/interfaces';
import { IWeatherDataDto } from '@bg-front/weather-data/models';
import { WeatherDataService } from '@bg-front/weather-data/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import * as dayjs from 'dayjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { catchError, finalize } from 'rxjs/operators';

/**
 * Базовый класс для всех компонент
 * Реализует отписку от событий и базовый ngOnDestroy
 */
@UntilDestroy()
@Directive()
export class BaseForecastingRadioactiveEnvironmentComponent extends BaseComponent {
  public form!: FormGroup;

  /** Настройка компоненты Облачность */
  public clouds: ISelectItem[] = this.getDictionaryForSelect('cloudy');
  /** Настройка компоненты Время суток */
  public timeOfDays: ISelectItem[] = this.getDictionaryForSelect('timesOfDay');

  public tooltipText = `Утро = 2 ч. после восхода солнца;
    Вечер = 2 ч. после захода солнца;
    День = период от восхода до захода солнца (кроме двух утренних часов);
    Ночь = период от захода до восхода солнца (кроме двух вечерних часов).`;
  /** Режим просмотра */
  public isShow = false;

  /** Принак загрузки погодных данных */
  public isLoadWeather: boolean = false;

  constructor(
    public readonly settings: Settings2Service,
    public readonly weatherService: WeatherDataService,
    notificationService: NzNotificationService,
  ) {
    super(notificationService);
  }

  /** Формирование справочника */
  public getDictionaryForSelect(name: string): ISelectItem[] {
    return this.settings.getDictForSelect(name).map(
      (el: IScSelectItem): ISelectItem<string> =>
        <ISelectItem>{
          text: el.text,
          value: el.id,
        },
    );
  }

  /** Запрос погоды */
  public getWeatherData(): void {
    this.isLoadWeather = true;
    this.weatherService
      .getActualWeather(+dayjs())
      .pipe(
        catchError((err: Error) => this.catchErrorFn<IWeatherDataDto>(err, 'Ошибка загрузки метеоусловий')),
        finalize(() => (this.isLoadWeather = false)),
        untilDestroyed(this),
      )
      .subscribe((data: IWeatherDataDto) => {
        if (data) {
          this.form.get('windVelocity')?.setValue(data?.windSpeed);
          this.form.get('windDirection')?.setValue(data?.windDirection);
          this.form.get('airTemperature')?.setValue(data?.airTemperature);
          this.form.get('cloudStateId').setValue(data?.cloudy);
          this.form.get('timeOfDayId').setValue(data?.timeOfDay);
          this.form.get('isSnowy')?.setValue(parseFloat(data?.snowCoverThickness) > 0);
        }
      });
  }
}
