import { Injectable } from '@angular/core';
import {
  AccessService,
  NotificationService,
  RestService,
  Settings2Service,
  SfsService,
} from '@smart-city/core/services';
import { GisService, IGisServiceResult } from '@smart-city/maps/sc';
import * as L from 'leaflet';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Coordinates } from '../../models/classes';
import { CLICK_MAKER_SHAPE_SVG } from '../../models/constants/map-config';
import { IBasePolygonDto } from '../../models/interfaces/base-polygon-dto.interface';
import { CommonService } from '../common/common.service';
import { MapBaseModel } from '@smart-city/maps/sc';

/**
 * Сервис для загрузки ГЕО-данных
 */
@Injectable({
  providedIn: 'root',
})
export class BgMapService {
  private iconMap: Map<string, string> = new Map<string, string>();

  /** Подписка на позиционирование карты по координатам */
  private positionMapOnCoordinates: Subject<string> = new Subject<string>();
  public positionMapOnCoordinates$: Observable<string> = this.positionMapOnCoordinates.asObservable();

  /** Подписка на информацию о полигоне для его дальнейшего отображения */
  private polygon: Subject<Coordinates[]> = new Subject<Coordinates[]>();
  public polygon$: Observable<Coordinates[]> = this.polygon.asObservable();

  // TODO возможно, стоит разделить слои ЛХО и пользовательских полигонов
  /** Подписка на удаление слоя ЛХО/пользовательских полигонов */
  private forestryFacilitiesRemoved: Subject<boolean> = new Subject<boolean>();
  public forestryFacilitiesRemoved$: Observable<boolean> = this.forestryFacilitiesRemoved.asObservable();

  /** Подписка на информацию о полигоне муниципального образования для его дальнейшего отображения */
  private municipalityPolygon: Subject<IBasePolygonDto> = new Subject<IBasePolygonDto>();
  public municipalityPolygon$: Observable<IBasePolygonDto> = this.municipalityPolygon.asObservable();

  /** Подписка на информацию о полигоне района для его дальнейшего отображения */
  private districtPolygon: Subject<IBasePolygonDto> = new Subject<IBasePolygonDto>();
  public districtPolygon$: Observable<IBasePolygonDto> = this.districtPolygon.asObservable();

  /** Подписка на информацию о маркере для его отображения на карте */
  private clickMarker: Subject<{ coordinates: Coordinates; iconName: string; objectId: string }> = new Subject<{
    coordinates: Coordinates;
    iconName: string;
    objectId: string;
  }>();

  public clickMarker$: Observable<{
    coordinates: Coordinates;
    iconName: string;
    objectId: string;
  }> = this.clickMarker.asObservable();

  /** Подписка на факт загрузки слоев на карте */
  private mapLayersReady: Subject<void> = new Subject<void>();
  public mapLayersReady$: Observable<void> = this.mapLayersReady.asObservable();

  /** Настоящая модель карты */
  private mapObjectModel: L.Map;

  public set mapModel(model: L.Map) {
    this.mapObjectModel = model;
  }

  public get mapModel(): L.Map {
    return this.mapObjectModel;
  }

  /** Ссылка на оболочку карты */
  public baseMapModel: MapBaseModel;

  constructor(
    private readonly rest: RestService,
    private readonly sfs: SfsService,
    private readonly accessService: AccessService,
    private readonly common: CommonService,
    private readonly settings: Settings2Service,
    private readonly note: NotificationService,
    private readonly gisService: GisService,
  ) {}

  /**
   * Получение координат по введённому адресу
   * @param address строка адреса
   */
  public getCoordinatesByAddress(address: string): Observable<IGisServiceResult> {
    return this.gisService
      .getCoordinatesByAddressV2(this.settings.getConfig().nfiasRegion, address)
      .pipe(map((res: IGisServiceResult[]) => res[0]));
  }

  /** Кидаем событие на позиционирование */
  public setPositionMapOnCoordinates(coord: string): void {
    this.positionMapOnCoordinates.next(coord);
  }

  /**
   * Получение центра в массиве координат
   * @param coordinates - координаты вершин полигона
   */
  public getCenter(coordinates: Coordinates[]): string {
    if (!(coordinates || [])[0]) return undefined;

    let maxLat = coordinates[0].lat;
    let maxLon = coordinates[0].lon;
    let minLat = coordinates[0].lat;
    let minLon = coordinates[0].lon;
    for (let i = 1; i < coordinates.length; i += 1) {
      if (coordinates[i].lat > maxLat) {
        maxLat = coordinates[i].lat;
      }
      if (coordinates[i].lon > maxLon) {
        maxLon = coordinates[i].lon;
      }
      if (coordinates[i].lat < minLat) {
        minLat = coordinates[i].lat;
      }
      if (coordinates[i].lon < minLon) {
        minLon = coordinates[i].lon;
      }
    }

    return `${((maxLat + minLat) / 2).toFixed(7)}, ${((maxLon + minLon) / 2).toFixed(7)}`;
  }

  /**
   * Кидаем событие на рисование полигона
   * @param coordinates - массив координат для рисования полигона
   * TODO: Переименовать
   */
  public drawPolygon(coordinates?: Coordinates[]): void {
    this.polygon.next(coordinates);
  }

  /**
   * Кидаем событие на рисование полигона муниципального образования
   * @param polygon - информация о полигоне
   */
  public drawMunicipalityPolygon(polygon?: IBasePolygonDto): void {
    this.municipalityPolygon.next(polygon);
  }

  /**
   * Кидаем событие на рисование полигона района
   * @param polygon - информация о полигоне
   */
  public drawDistrictPolygon(polygon?: IBasePolygonDto): void {
    this.districtPolygon.next(polygon);
  }

  /**
   * Кидаем событие на рисование маркера на карте
   * @param iconName - наименование иконки маркера
   * @param objectId - id объекта на карте
   * @param coordinates - координаты маркера
   */
  public setClickMarker(iconName: string, objectId?: string, coordinates?: Coordinates): void {
    this.clickMarker.next({ coordinates, iconName, objectId });
  }

  /**
   * Информирование о готовности слоев на карте
   */
  public mapLayersReadyInform(): void {
    this.mapLayersReady.next();
  }

  /**
   * Кидаем событие на удаление слоя ЛХО/пользовательских полигонов
   */
  public removeForestryFacilities(): void {
    this.forestryFacilitiesRemoved.next(true);
  }

  /**
   * Создание иконки для маркера
   */
  public createClickMarkerIcon(): HTMLImageElement {
    const canvas = document.createElement('canvas');
    canvas.width = 52;
    canvas.height = 76;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
    };
    img.src = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(CLICK_MAKER_SHAPE_SVG)}`;
    return img;
  }
}
