<form nz-form [formGroup]="form" errorTailor id="idForTest" nzLayout="vertical" *ngIf="form">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <ng-template #mainParams>
        <h4 class="m-b-0">Основные параметры расчёта</h4>
      </ng-template>
      <nz-divider [nzText]="mainParams" nzOrientation="left"></nz-divider>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzFor="travelSpeed">Скорость движения</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="travelSpeed">
            <input nzSize="large" nz-input formControlName="travelSpeed" />
          </nz-input-group>
          <ng-template #travelSpeed> км/ч </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="unitRadiationId">Единицы измерения</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="unitRadiationId" nzSize="large">
            <nz-option [nzValue]="unit.value" [nzLabel]="unit.text" *ngFor="let unit of units"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzFor="shelterLocationId">Расположение укрытия</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="shelterLocationId" nzSize="large">
            <nz-option
              [nzValue]="location.value"
              [nzLabel]="location.text"
              *ngFor="let location of shelterLocations"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzFor="shelterTypeId">Тип укрытия</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="shelterTypeId" nzSize="large">
            <nz-option
              [nzValue]="shelterType.value"
              [nzLabel]="shelterType.text"
              *ngFor="let shelterType of shelterTypes"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label nzFor="shelterFloorId">Этаж</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="shelterFloorId" nzSize="large">
            <nz-option [nzValue]="floor.value" [nzLabel]="floor.text" *ngFor="let floor of shelterFloors"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <ng-template #path>
        <h4 class="m-b-0">Маршрут движения</h4>
      </ng-template>
      <nz-divider [nzText]="path" nzOrientation="left"></nz-divider>
    </div>
  </div>
  <ng-container formArrayName="pathPoints">
    <ng-container *ngFor="let el of pathPointsArray?.controls; let i = index" [formGroupName]="i">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="24">
          <nz-form-item class="m-b-0">
            <nz-form-label nzFor="timeAfterEjection">
              <h5>Точка {{ i + 1 }}</h5>
            </nz-form-label>
            <nz-form-control>
              <bg-overcoming-path-point
                [canDelete]="i > 0"
                [formGroup]="$any(el)"
                [unit]="unitName"
                (removerItem)="removeFilter(i)"
              ></bg-overcoming-path-point>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-control [nzXs]="{ span: 24, offset: 0 }" [nzSm]="{ span: 15, offset: 9 }">
          <button nz-button nzType="dashed" class="add-button" (click)="addPoint()">
            <i nz-icon nzType="plus"></i>
            Добавить точку
          </button>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
