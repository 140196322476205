import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessService, IAccessAction } from '@smart-city/core/services';
import { forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Coordinates } from '@bg-front/core/models/classes';
import { EmergencyService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';
import { CallService } from '../../../call/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { RegistryPanelService } from '@bg-front/registry-panel/services';

/**
 * Компонент для отображения диалога со списком важных объектов расположенных рядом с заданной координатой
 * и не дальше указанного радиуса
 *
 *  @example
 *  <bg-significant-object-list-dialog
 *    [coordinates]="55.55, 55.55"
 *    [radius]="500"
 *  >
 *  </bg-significant-object-list-dialog>
 */
@Component({
  selector: 'bg-significant-object-list-dialog',
  templateUrl: './significant-object-list-dialog.component.html',
  styleUrls: ['./significant-object-list-dialog.component.scss'],
})
export class SignificantObjectListDialogComponent extends BaseComponent {
  /** Координаты инцидента */
  private coord: Coordinates;
  /** Радиус, в котором нужно искать ВО */
  private rad: number;
  /** ID инцидента */
  private emergencyId: string;
  /** ID реестра Важных Объектов */
  private significantObjectRegistryId: string;
  /** Флаг проверки того, запускается ли диалог из инцидента
   *  docType.sysname === 'incident' */
  private isIncident: boolean = false;

  /** Конфигурация таблицы */
  public displayedColumns: string[] = ['name', 'state.name', 'responsible', 'phone', 'buttons'];
  public dataSource = [];

  /** Доступность кнопки "Запуск прогнозирования" */
  public canForecastingRun: IAccessAction = { visible: false, enabled: false, name: 'CanForecastingRun' };

  /** Ссылка на диалог */
  @ViewChild('dialogRef', { static: false }) dialogRef: TemplateRef<any>;

  /** Входной параметр. Координаты для поиска ближайших объектов */
  @Input()
  public set coordinates(value: string | Coordinates) {
    this.coord = typeof value === 'object' ? value : new Coordinates(value);
  }

  /** Входной параметр. Расстояние не далее которого искать объекты */
  @Input()
  public set radius(value: number) {
    this.rad = value;
  }

  /** Признак блокировки компонента */
  @Input()
  public set disabled(value: boolean) {
    this.dialogOpeningButtonDisabled = value;
  }

  public dialogOpeningButtonDisabled: boolean;

  /** @ignore */
  constructor(
    private readonly dialog: MatDialog,
    private readonly significantObjectService: SignificantObjectsService,
    private readonly registryPanelService: RegistryPanelService,
    private readonly emergencyService: EmergencyService,
    private readonly accessService: AccessService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly callService: CallService,
  ) {
    super();
  }

  /** Открытие диалога */
  public open() {
    this.emergencyId = this.route.snapshot.params.id;
    this.canForecastingRun = this.accessService.accessMap[this.canForecastingRun.name];

    forkJoin([
      this.significantObjectService.getCloseObject(this.coord, this.rad),
      this.registryPanelService.getRegistries(['SignificantObjectsRegistry']),
      this.emergencyService.getIncidentByIdForMiniCard(this.emergencyId),
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.dataSource = res[0].data.items;
        this.significantObjectRegistryId = res[1][0].id;
        this.isIncident = res[2]?.docType?.sysname === 'incident';

        this.dialog.open(this.dialogRef, {
          autoFocus: false,
        });
      });
  }

  /** Закрытие диалога */
  public close() {
    this.dialog.closeAll();
  }

  /** Открывает рабочий стол с открытой формой запуска прогнозирования в новой вкладке */
  public openForecastingTab(significantObjectId: string) {
    let targetUrl = this.getWorkspaceURL(significantObjectId);
    // queryParams очищаются сразу после открытия формы прогнозирования, поэтому их не видно в URL после перехода
    targetUrl += `?openForecastingForm=true&emergencyId=${this.emergencyId}`;
    window.open(targetUrl);
  }

  /** Открывает рабочий стол с открытой формой просмотра важного объекта в новой вкладке  */
  public openSignificantObjectTab(significantObjectId: string) {
    const targetUrl = this.getWorkspaceURL(significantObjectId);
    window.open(targetUrl);
  }

  /** Получить ссылку на рабочий стол с открытой формой ВО */
  private getWorkspaceURL(significantObjectId: string): string {
    let targetUrl = `http://${window.location.host}`;
    targetUrl += `/${this.route.snapshot.data.workspace}`;
    targetUrl += '/workspace';
    targetUrl += `/(significantObject/${significantObjectId};showOnMap=true`;
    targetUrl += `//leftSidebar:significantObjectsRegistry/${this.significantObjectRegistryId})`;
    return targetUrl;
  }

  /** Совершает вызов по номеру при клике на строке таблицы */
  public onClickRowPhone(row): void {
    if (!row.phone) return;
    this.callService.call(row.phone);
  }
}
