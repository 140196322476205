<div class="bg-row">
  <div class="bg-col">
    <!-- Чекбокс Адрес по координатам -->
    <div class="bg-row">
      <div class="bg-col align-right">
        <div class="bg-form-group p-t-0 p-b-0">
          <sc-checkbox [options]="byCoordinatesOptions" [ngModel]="model?.byCoordinates"></sc-checkbox>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group readonly-container">
          <label>Адрес КСиП</label>
          <p>{{ model?.address }}</p>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col-6">
        <div class="bg-form-group">
          <div class="bg-form-group readonly-container" [ngStyle]="{ display: !moIdVisible ? 'none' : '' }">
            <label>Муниципальное образование</label>
            <p>{{ model?.moId }}</p>
          </div>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group">
          <div class="bg-form-group readonly-container" [ngStyle]="{ display: !districtIdVisible ? 'none' : '' }">
            <label>Район</label>
            <p>{{ model?.districtId }}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-row bg-row-align-content-center space-between-container"
      *ngIf="!!countOfCloseImportantObjects"
    ></div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <placement-details [model]="model?.details"></placement-details>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col-3" *ngIf="!eventInfo?.isHandled">
        <div class="bg-form-group specify-address-on-map-button">
          <button
            mat-raised-button
            [color]="optionsSpecifyAddressOnMap.color"
            class="bg-button-round"
            (click)="onClickSpecifyAddress($event)"
          >
            {{ optionsSpecifyAddressOnMap.title }}
          </button>
        </div>
      </div>
      <div class="bg-col-6">
        <div class="bg-form-group">
          <bg-significant-object-list-dialog
            [coordinates]="model?.coordinates"
            [radius]="500"
            [disabled]="!this.countOfCloseImportantObjects"
          ></bg-significant-object-list-dialog>
        </div>
      </div>
      <div class="bg-col-6">
        <div class="bg-form-group">
          <div class="bg-row bg-row-align-content-center space-between-container justify-content">
            <div class="bg-col-min" *ngIf="countOfCloseImportantObjects">
              <i
                class="sc-icon-attention significant-object-attention"
                [ngClass]="{ 'significant-object-danger': hasDangerObject }"
              ></i>
            </div>
            <div class="bg-col">
              <p>{{ this.closeImportantObjectsMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group readonly-container">
          <label>Тип КСиП</label>
          <p>{{ model?.ksipTypeName }}</p>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group readonly-container">
          <label>Детализация КСиП</label>
          <p>{{ model?.ksipDetailsName }}</p>
        </div>
      </div>
    </div>
    <div class="bg-row" *ngIf="model?.jkhObject">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select [ngModel]="model?.jkhObject" [options]="jkhObjectOptions"></sc-select>
        </div>
      </div>
    </div>
    <div class="bg-row" *ngIf="linkToJkhObjectVisible">
      <div class="bg-col">
        <div class="bg-form-group" *ngIf="isVisibleAtmLink$ | async; else objectLinkContent">
          <a
            class="blink--link"
            target="_blank"
            [state]="{ data: { isAddressBlockShow: true } }"
            [routerLink]="[
                              '/dictionaries/monitoring-objects-hcs/monitoringObject',
                              monitoringObjectId
                            ]"
          >
            Адреса, принадлежащие объекту
          </a>
        </div>
        <ng-template #objectLinkContent>
          <p>Адреса, принадлежащие объекту, не найдены</p>
        </ng-template>
      </div>
    </div>
    <div class="bg-row" *ngIf = 'isPlanWorkForm || isAccidentForm'>
      <div class="bg-col" *ngIf="model?.workTermFrom">
        <div class="bg-form-group full-width">
          <bg-datetime
            [disabled]="true"
            [ngModel]="model?.workTermFrom"
            label="Плановый срок проведения работ с"
          ></bg-datetime>
        </div>
      </div>
      <div class="bg-col" *ngIf="model?.workTermTo">
        <div class="bg-form-group full-width">
          <bg-datetime
            [disabled]="true"
            [ngModel]="model?.workTermTo"
            label="Плановый срок проведения работ по"
          ></bg-datetime>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group readonly-container column_description">
          <label>Описание</label>
          <p>{{ model?.description }}</p>
        </div>
        <div class="bg-form-group readonly-container">
          <label>Дата и время возникновения КСиП</label>
          <p>{{ model?.ksipTime | date: 'dd.MM.yyyy HH:mm:ss' }}</p>
        </div>
      </div>
      <div class="bg-col">
        <div class="bg-form-group readonly-container column_ksipInfo">
          <label>Дополнительная информация о месте КСиП</label>
          <p class="ksip-info-textarea">{{ model?.ksipInfo }}</p>
        </div>
        <div class="bg-form-group readonly-container column_ksipInfo">
          <label>Комментарий</label>
          <p>{{ model?.comment }}</p>
        </div>
      </div>
    </div>
    <div class="bg-row" *ngIf="thermoPoint">
      <div class="bg-col d-flex d-flex-right">
        <a
          class="blink--link"
          target="_blank"
          [href]="thermoPointInnerLink"
        >
          <mat-icon>link_on</mat-icon>
          Термоточка в АПК БГ
        </a>
        <a
          class="blink--link"
          target="_blank"
          [href]="'https://firenotification.mchs.gov.ru/thermal-point/' + thermoPoint.extId"
        >
          <mat-icon>link_on</mat-icon>
          Термоточка в АИУС РСЧС
        </a>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col" *ngIf="urgentlyVisible">
        <div class="bg-form-group p-t-0 p-b-0">
          <sc-checkbox [options]="optionsUrgently" [ngModel]="model?.urgently"></sc-checkbox>
        </div>
      </div>
      <div class="bg-col" *ngIf="threatsVisible">
        <div class="bg-form-group p-t-0 p-b-0">
          <sc-checkbox [options]="optionsThreatPopulation" [ngModel]="model?.threatPopulation"></sc-checkbox>
        </div>
      </div>
      <div class="bg-col" *ngIf="threatsVisible">
        <div class="bg-form-group p-t-0 p-b-0">
          <sc-checkbox [options]="optionsThreatOrganization" [ngModel]="model?.threatOrganization"></sc-checkbox>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group readonly-container column_user">
          <label>Ответственная организация</label>
          <p>{{ model?.organizationId }}</p>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group readonly-container column_user">
          <label>Ответственный пользователь</label>
          <p>{{ model?.responsibleUser }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
