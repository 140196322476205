<div class="container">
  <nw-header [options]="headerOptions" (clickButton)="delete()"></nw-header>
  <form [formGroup]="form">
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select formControlName="type" [options]="typeOptions"></sc-select>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select formControlName="name" [options]="nameOptions"></sc-select>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select formControlName="state" [options]="stateOptions"></sc-select>
        </div>
      </div>
    </div>
  </form>
</div>
