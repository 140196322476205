import { Injectable } from '@angular/core';
import { RestService } from '@smart-city/core/services';
import { IAbstractServiceData } from 'smart-city-types';
import { map } from 'rxjs/operators';
import { iif, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

/** Сервис для работы с аудиозаписью */
@Injectable({
  providedIn: 'root',
})
export class RecordService {
  constructor(private readonly rest: RestService) {}

  /**
   * Получение звукозапси от 112
   * @param  uuid
   * @return
   */
  public getVoiceRecord(uuid: string): Observable<any> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Interaction112Journal',
          query: {
            uuid,
            nCallId: { $ne: null }
          },
        },
      })
      .pipe(
        mergeMap(({ data }: IAbstractServiceData) => {
          const nCallId = data.items[0]?.nCallId;
          return iif(
            () => nCallId,
            this.rest
              .serviceRequest({
                data: {
                  nCallId,
                },
                action: 'receiveVoiceRecord',
                service: { name: 'Emergency' },
              })
              .pipe(
                map(({ data }: IAbstractServiceData) => {
                  return data;
                }),
              ),
            of(null),
          );
        }),
      );
  }
}
