import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Settings2Service } from '@smart-city/core/services';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { Observable } from 'rxjs';
import { ITimeToOvercomeCloudTrailResultsDto } from 'smart-city-types';
import { Coordinates } from '@bg-front/core/models/classes';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';
import { IOvercomingPathPointDto, ITimeAllowedToOvercomeCloudTrailTaskDto } from '../../models';
import { BaseComponent } from '@bg-front/core/components';
import { ForecastingRadioactiveEnvironmentVisualizationService } from '../../services';

/**
 * Допустимое время преодоления облака
 * sysname - timeAllowedToOvercomeCloudTrail
 */
@Component({
  selector: 'bg-time-allowed-to-overcome-cloud-trail-task-result',
  templateUrl: './time-allowed-to-overcome-cloud-trail-result.component.html',
  styleUrls: ['./time-allowed-to-overcome-cloud-trail-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeAllowedToOvercomeCloudTrailResultComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public forecastingResults: IForecastingResultDto;

  private element: any;

  /** Скачать отчёт */
  @Input()
  public downloadReport: Observable<void>;

  /** Уведомляем о наличии отчёта */
  @Output()
  public hasReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor(
    private readonly settings: Settings2Service,
    private readonly forecastingService: ForecastingRadioactiveEnvironmentVisualizationService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public get result(): ITimeToOvercomeCloudTrailResultsDto {
    return this.forecastingResults?.result as ITimeToOvercomeCloudTrailResultsDto;
  }

  public get params(): ITimeAllowedToOvercomeCloudTrailTaskDto {
    return this.forecastingResults?.params.params as ITimeAllowedToOvercomeCloudTrailTaskDto;
  }

  /** Ссылка на результат отрисовки */
  public get unitName(): string {
    if (this.forecastingResults?.params?.params['unitRadiation']) {
      return this.settings.getDictionaryById(this.forecastingResults?.params?.params['unitRadiationId'])?.name;
    }

    return '';
  }

  ngOnInit(): void {
    if (new Coordinates(this.forecastingResults.params.coordinates).isValid()) {
      this.element = this.forecastingService.displayPath(
        this.params?.pathPoints.map((el: IOvercomingPathPointDto) => new Coordinates(el.coordinates).toArray()),
      );
    } else {
      this.noteService.pushWarning('Отсутствуют координаты для визуализации расчёта');
    }

    this.hasReport.emit(false);

    this.cdr.markForCheck();
  }

  public override ngOnDestroy(): void {
    this.forecastingService.clearResults(this.element);
  }
}
