import { Injectable } from '@angular/core';
import {
  FiasService,
  IFiasServiceResponse,
  IScFias3ServiceResponseElement,
  IScFias3ServiceResponseHouse,
} from '@smart-city/core/common';
import { PltFiasElement } from '@smart-city/core/plt-fias';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScConsole, Uuid } from '@smart-city/core/utils';
import { IFiasValueDto } from 'smart-city-types';
import { switchMap } from 'rxjs/operators';

const CODE_TYPES: Record<number, keyof IFiasValueDto> = {
  1: 'region',
  3: 'district',
  4: 'city',
  5: 'city',
  6: 'city',
  7: 'street',
  65: 'street',
  90: 'city',
  91: 'street',
};

const abbreviationsThatNeedAPoint = [
  'г',
  'обл',
  'Респ',
  'п',
  'тер',
  'у',
  'д',
  'м',
  'с',
  'сл',
  'ст',
  'х',
  'дор',
  'наб',
  'пер',
  'пл',
  'платф',
  'стр',
  'туп',
  'ул',
  'ш',
];

/**
 * Сервис взаимодействия с ФИАС сервером.
 */
@Injectable({
  providedIn: 'root',
})
export class FiasBgService {

  constructor(private readonly fiasService: FiasService) {}

  /**
   * Преобразовать элемент в формат ФИАС и добавить координаты в случае установленного
   * флага {@link isCoordinatesNecessary}
   */
  private getFiasDto(element: PltFiasElement | null, isCoordinatesNecessary: boolean = false): Observable<IFiasValueDto | null> {
    if (!element) return of(null);

    const value: IFiasValueDto = { fullText: element.fullText };

    const parentCoordinates: { latitude?: number, longitude?: number} = {};
    element.serviceResponse.forEach((item: IScFias3ServiceResponseElement, i: number) => {
      /**
       * Из каждого элемента берётся наименование и записывается в соответствующее поле объекта с итоговым значением.
       */
      const codeType = CODE_TYPES[item.type];
      if (codeType) {
        (value[codeType] as string | number) = item.fullname;
      }

      /**
       * ОКТМО может не быть на текущем уровне. Если оно так, то значение будет пустым и на следующей итерации
       * будет попытка взять ОКТМО у предка.
       */
      if (!value.oktmo) {
        value.oktmo = item.oktmo;
      }

      /**
       * Из первого элемента достаётся уникальный номер, записывается в uuid.
       * Если выбран дом, то он тоже сохраняется.
       */
      if (i === 0) {
        value.uuid = item.uuid;
        value.region = item.regioncode;

        if (item.house !== undefined && Array.isArray(item.house) && item.house.length) {
          value.houseUuid = item.house[0]!.id;
          value.houseNumber = item.house[0]!.number;
          value.buildnum = item.house[0]!.buildnum;
          value.strnum = item.house[0]!.strnum;
          value.postalCode = item.house[0]!.postalcode;

          /**
           * Если координаты неизвестны, то придут нули. Лучше ничего не записывать.
           */
          if (item.house[0]!.latitude && item.house[0]!.longitude) {
            value.latitude = item.house[0]!.latitude;
            value.longitude = item.house[0]!.longitude;
          }
        }
      }

      if (!parentCoordinates.latitude && !parentCoordinates.longitude && item.latitude && item.longitude) {
        parentCoordinates.latitude = item.latitude;
        parentCoordinates.longitude = item.longitude;
      }
    });

    if (!isCoordinatesNecessary || (value.latitude && value.longitude)) {
      return of(value);
    }

    /**
     * Если координаты необходимы, а они не были получены, то следует сделать отдельный запрос
     * для нахождения этих координат в стороннем сервисе.
     */
    return this.getCoordinates({
      uuid: value.uuid!,
      houseUuid: value.houseUuid!,
    }).pipe(map((data) => {
      if (data) {
        value.latitude = data.latitude;
        value.longitude = data.longitude;
      } else {
        value.latitude = parentCoordinates.latitude;
        value.longitude = parentCoordinates.longitude;
      }
      return value;
    }));
  }

  /** Метод для получения адреса по координатам */
  public getGeocode(latitude: number, longitude: number): Observable<IFiasValueDto> {
    const limit = 10;
    return this.fiasService.sendRequest({
      latitude,
      longitude,
      command: 'geocode',
      id: Uuid.newUuid(),
    }).pipe(
      switchMap((res) => {
        /**
         * В массиве house стал приходить не один элемент.
         * Реализован адаптер, чтобы не трогать все остальные части (шаблон, пайп, сервис).
         * @see COV2019-667
         */
        const adaptedElements: PltFiasElement[] = [];
        if (!Array.isArray(res.element)) return of(null);
        (res.element as IScFias3ServiceResponseElement[][]).forEach((el: IScFias3ServiceResponseElement[]) => {
          if (adaptedElements.length <= limit) {
            if ((el[0]!.house || []).length > 1) {
              el[0]!.house?.forEach((house: IScFias3ServiceResponseHouse, i: number) => {
                if (adaptedElements.length <= limit) {
                  const tempEl: IScFias3ServiceResponseElement[] = JSON.parse(JSON.stringify(el));
                  tempEl[0]!.house = [el[0]!.house![i]!];
                  adaptedElements.push(this.getFiasElement(tempEl));
                }
              });
            } else {
              adaptedElements.push(this.getFiasElement(el));
            }
          }
        });
        return this.getFiasDto(adaptedElements[0]);
      }),
    );
  }

  /** Получить объект `PltFiasElement` из `IScFias3ServiceResponseElement` */
  private getFiasElement(el: IScFias3ServiceResponseElement[]): PltFiasElement {
    return {
      serviceResponse: el,
      fullText: this.getFullAddress(el)!,
    };
  }

  /** Получить строку с полным адресом */
  private getFullAddress(element: IScFias3ServiceResponseElement[]): string | undefined {
    if (element !== undefined && Array.isArray(element) && element.length) {
      let house = '';
      const houseField = (element[0]!.house || [])[0];
      if (houseField) {
        house = houseField.number;

        if (houseField.strnum) {
          house += `/${houseField.strnum}`;
        }

        if (houseField.buildnum) {
          house += ` к. ${houseField.buildnum}`;
        }
      }
      return [...(element as IScFias3ServiceResponseElement[])]
        .reverse()
        .map((item) => {
          let prefix = item.prefix;
          if (abbreviationsThatNeedAPoint.includes(prefix)) {
            prefix += '.';
          }
          return `${prefix} ${item.fullname}`;
        })
        .join(', ') + (house ? `, ${house}` : '');
    }
  }

  /**
   * Получить координаты выбранного адреса.
   */
  private getCoordinates(data: { uuid: string, houseUuid: string }): Observable<{
    latitude: number,
    longitude: number,
  }> {

    return this.fiasService.sendRequest({
      command: 'userchoice',
      aoguid: [data.uuid],
      guid: data.houseUuid,
    }).pipe(map((res: IFiasServiceResponse) => {
      if (res.command === 'success') {
        return {
          latitude: res.latitude,
          longitude: res.longitude,
        };
      }
      if (res.command === 'error') {
        ScConsole.error(`FiasService, getCoordinates error: ${res.error}`);
      }
    }));
  }

  /**
   * Получить адреса по строке поиска.
   */
   public find(term: string): Observable<IFiasValueDto[]> {
    return this.fiasService.sendRequest({
      command: 'find',
      limit: 10,
      searchstring: term,
      typeaddr: 'all',
    }).pipe(
      map((response: any) => response.element),
      switchMap((addressItems: any[]) => {
        if (addressItems && addressItems.length) {
          const els = addressItems.map((item: any) => {
            const obj = this.createValue(item);
            obj.fullText = this.createLabel(item);
            return obj;
          });

          return of(els);
        }

        return of([]);
      })
    );
  }

  /**
   * Получить полный текст адреса.
   */
  private createLabel(element: any) {
    if (element !== undefined && Array.isArray(element) && element.length) {
      let house = '';
      const houseField = ((element as any[])[0].house || [])[0];
      if (houseField) {
        house = houseField.number;

        if (houseField.strnum) {
          house += `/${houseField.strnum}`;
        }

        if (houseField.buildnum) {
          house += ` к. ${houseField.buildnum}`;
        }
      }
      return (
        [...(element as any[])]
          .reverse()
          .map((item) => {
            let prefix = item.prefix;
            if (abbreviationsThatNeedAPoint.includes(prefix)) {
              prefix += '.';
            }
            return `${prefix} ${item.fullname}`;
          })
          .join(', ') + (house ? `, ${house}` : '')
      );
    }

    return undefined;
  }

  /**
   * Получить объект адреса.
   */
  private createValue(option: any) {
    const value: IFiasValueDto = <IFiasValueDto>{};
    option.forEach((item: any, i: number) => {
      /**
       * Из каждого элемента берётся наименование и записывается в соответствующее поле объекта с итоговым значением.
       */
      const type = item.type as string;
      const prop: string | undefined = this.CODE_TYPE(type);
      if (prop) {
        ((value as any)[prop]) = item.fullname as string;
      }

      /**
       * ОКТМО может не быть на текущем уровне. Если оно так, то значение будет пустым и на следующей итерации
       * будет попытка взять ОКТМО у предка.
       */
      if (!value.oktmo) {
        value.oktmo = item.oktmo;
      }

      /**
       * Из первого элемента достаётся уникальный номер, записывается в uuid.
       * Если выбран дом, то он тоже сохраняется.
       */
      if (i === 0) {
        value.uuid = item.uuid;
        value.region = item.regioncode;

        if (
          item.house !== undefined &&
          Array.isArray(item.house) &&
          item.house.length
        ) {
          value.houseUuid = item.house[0].id;
          value.houseNumber = item.house[0].number;
          value.buildnum = item.house[0].buildnum;
          value.strnum = item.house[0].strnum;
          value.postalCode = item.house[0].postalcode;

          /**
           * Если координаты неизвестны, то придут нули. Лучше ничего не записывать.
           */
          if (item.house[0].latitude && item.house[0].longitude) {
            value.latitude = item.house[0].latitude;
            value.longitude = item.house[0].longitude;
          }
        }
      }
    });
    return value;
  }

  /**
   * Получить тип по коду.
   */
  private CODE_TYPE(value: string): string | undefined {
    if ('1' == value) {
      return 'region';
    }

    if ('3' == value) {
      return 'district';
    }

    if (['4', '5', '6'].includes(value)) {
      return 'city';
    }

    if (['7', '65', '91'].includes(value)) {
      return 'street';
    }

    if ('90' == value) {
      return 'city';
    }

    return undefined;
  }
}
