<div class="legend">
  <div class="legend__title" (click)="onShowLegend()">
    <div class="d-flex d-flex-row d-flex-vertical-center">
      <div class="legend__title__icon">
        <span class="sc-icon-polygon" [ngClass]="{ legend__title__selected: showPolygon }"></span>
      </div>
      Полигоны
    </div>
    <div class="legend__title__toggle" [ngClass]="{ legend__title__toggle__open: !showLegend }">
      <span class="sc-icon-chevron"></span>
    </div>
  </div>

  <div *ngIf="showLegend" class="legend__wrapper">
    <div class="content">
      <hr/>
      <mat-radio-group
        class="radio-btn-container"
        (change)="changeRadioButtonHandler($event)"
        [(ngModel)]="showPolygon"
      >
        <ng-container *ngFor="let polygon of systemPolygons">
          <div class="legend__item">
            {{ polygon.name }}
            <div class="legend__item__bullet">
              <mat-radio-button [value]="polygon.type"></mat-radio-button>
            </div>
          </div>
        </ng-container>
      </mat-radio-group>
      <button class="new-polygon" (click)="newPolygon()">Новый полигон</button>
    </div>
  </div>
</div>
