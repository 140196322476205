<h3 class="main-header">
  <mat-icon class="header-icon">track_changes</mat-icon>
  Результаты прогноза
</h3>
<hr />

<ng-container [ngSwitch]="inputTypeSysname">
  <ng-container *ngSwitchCase="'manually'">
    <div class="bg-row">
      <div class="bg-col" [formGroup]="thyroidTypeForm">
        <h4 class="section-header">Размеры зон облучения щитовидной железы</h4>
        <mat-radio-group color="primary" formControlName="recipient">
          <ng-container *ngFor="let result of results">
            <ng-container *ngIf="result.recipient === 'adult'">
              <mat-radio-button value="adult">
                <h5 class="radio-header">Опасность для взрослых</h5>
              </mat-radio-button>
              <br />
              <br />
              Для дозы {{ result.dose }}
              {{ unitName }}
              <ng-container
                *ngTemplateOutlet="resultBlock; context: { result: result, prevention: true }"
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="result.recipient === 'child'">
              <mat-radio-button value="child">
                <h5 class="radio-header">Опасность для детей</h5>
              </mat-radio-button>
              <br />
              <br />
              Для дозы {{ result.dose }}
              {{ unitName }}
              <ng-container
                *ngTemplateOutlet="resultBlock; context: { result: result, prevention: true }"
              ></ng-container>
            </ng-container>
          </ng-container>
        </mat-radio-group>
        <div class="recommended-measures-container">
          <h5>Рекомендованная мера защиты при указанной дозе за первые 10 суток:</h5>
          <p *ngIf="results">{{ results[1].recommendedMeasures }}</p>
        </div>
      </div>
    </div>
    <hr />
  </ng-container>

  <ng-container *ngSwitchCase="'shelterCalculatingDoses'">
    <div class="bg-row">
      <div class="bg-col">
        <h4 class="section-header">Размеры зон укрытия/эвакуации при облучении щитовидной железы</h4>
        <ng-container *ngFor="let result of results; let i = index">
          <ng-container *ngIf="i === 0">
            Укрытие (доза {{ result.dose }}
            {{ unitName }})
          </ng-container>
          <ng-container *ngIf="i === 1">
            Эвакуация, уровень А (доза {{ result.dose }}
            {{ unitName }})
          </ng-container>
          <ng-container *ngIf="i === 2">
            Эвакуация, уровень Б (доза {{ result.dose }}
            {{ unitName }})
          </ng-container>
          <ng-container *ngTemplateOutlet="resultBlock; context: { result: result }"></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'iodineProphylaxisCalculationDoses'">
    <div class="bg-row">
      <div class="bg-col" [formGroup]="thyroidTypeForm">
        <h4 class="section-header">Размеры зон йодной профилактики при облучении щитовидной железы</h4>
        <mat-radio-group color="primary" formControlName="recipient">
          <mat-radio-button value="adult">
            <h5 class="radio-header">Йодная профилактика взрослых</h5>
          </mat-radio-button>
          <br />
          <br />
          <ng-container *ngFor="let result of results; let i = index">
            <ng-container *ngIf="result.recipient === 'adult'">
              <ng-container *ngIf="i % 2 === 0">
                Уровень А (доза {{ result.dose }}
                {{ unitName }}
                )
              </ng-container>
              <ng-container *ngIf="i % 2 !== 0">
                Уровень Б (доза {{ result.dose }}
                {{ unitName }}
                )
              </ng-container>
              <ng-container *ngTemplateOutlet="resultBlock; context: { result: result }"></ng-container>
            </ng-container>
          </ng-container>
          <mat-radio-button value="child">
            <h5 class="radio-header">Йодная профилактика детей</h5>
          </mat-radio-button>
          <br />
          <br />
          <ng-container *ngFor="let result of results; let i = index">
            <ng-container *ngIf="result.recipient === 'child'">
              <ng-container *ngIf="i % 2 === 0">
                Уровень А (доза {{ result.dose }}
                {{ unitName }}
                )
              </ng-container>
              <ng-container *ngIf="i % 2 !== 0">
                Уровень Б (доза {{ result.dose }}
                {{ unitName }}
                )
              </ng-container>
              <ng-container *ngTemplateOutlet="resultBlock; context: { result: result }"></ng-container>
            </ng-container>
          </ng-container>
        </mat-radio-group>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'propagationTimeDoses'">
    <div class="bg-row">
      <form class="bg-col" [formGroup]="thyroidTypeForm">
        <h4 class="section-header">Размеры зон облучения щитовидной железы</h4>
        <mat-radio-group color="primary" formControlName="recipient">
          <mat-radio-button value="adult">
            <h5 class="radio-header">Опасность для взрослых</h5>
          </mat-radio-button>
          <br />
          <br />
          <ng-container *ngFor="let result of results; let i = index">
            <ng-container *ngIf="result.recipient === 'adult'">
              Уровень дозы {{ result.dose }}
              {{ unitName }}
              <ng-container *ngTemplateOutlet="resultBlock; context: { result: result }"></ng-container>
            </ng-container>
          </ng-container>
          <mat-radio-button value="child">
            <h5 class="radio-header">Опасность для детей</h5>
          </mat-radio-button>
          <br />
          <br />
          <ng-container *ngFor="let result of results; let i = index">
            <ng-container *ngIf="result.recipient === 'child'">
              Уровень дозы {{ result.dose }}
              {{ unitName }}
              <ng-container *ngTemplateOutlet="resultBlock; context: { result: result }"></ng-container>
            </ng-container>
          </ng-container>
        </mat-radio-group>
      </form>
    </div>
  </ng-container>
</ng-container>

<ng-template #resultBlock let-result="result" let-prevention="prevention">
  <ng-container *ngIf="!result.error">
    <ul class="results-list radio-list">
      <li class="results-list-item">
        <label> Длина зоны (км):</label>
        <label class="results-list-item--var">
          {{ result.zoneLength | commaToDot | number: '0.2-2' }}
        </label>
      </li>
      <li class="results-list-item">
        <label>Ширина зоны (км):</label>
        <label class="results-list-item--var">
          {{ result.zoneWidth | commaToDot | number: '0.2-2' }}
        </label>
      </li>
      <li class="results-list-item">
        <label>Площадь зоны (км²):</label>
        <label class="results-list-item--var">
          {{ result.zoneArea | commaToDot | number: '0.2-2' }}
        </label>
      </li>
      <li class="results-list-item" *ngIf="prevention">
        <label>Йодная профилактика:</label>
        <label class="results-list-item--var">
          {{ result.isIodineProphylaxisRequired ? 'Да' : 'Нет' }}
        </label>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="result.error">
    <p>{{ result.error }}</p>
    <br />
  </ng-container>
</ng-template>
