/**
* Общие интерфейсы и структуры данных для видео устройств (видеокамер)
*/

/**
 * Перечисление настроек видеоаналитики.
 * Ключи соответствуют системным именам справочника "Предмет мониторинга"(sysName: monitoringSubject).
 */
export enum AnalystSetupsBitwiseEnum {
  /** Громкий звук */
  loudNoiseDetection = 1,
  /** Оставленные предметы */
  lostObjectDetection = 2,
  /** Массовое скопление */
  crowdDetection = 4,
  /** Саботаж */
  sabotage = 8,
  /** Разыскиваемый ГРЗ */
  searchTransport = 16,
  /** Дубль ГРЗ */
  grzRepeat = 32,
  /** Распознавание ГРЗ */
  recognitionTransport = 64,
}

/**
 * Перечисление статусов устройства.
 * Ключи соответствуют системным именам справочника "Состояния устройств"(sysName: devicesState).
 */
export enum StatusesEnum {
  /** Не работает */
  stateOff = 0,
  /** Работает и не записывает */
  stateOn = 1,
  /** Работает и записывает */
  stateOnRecord = 2,
}

/**
 * Перечисление режима записи.
 * Ключи соответствуют системным именам справочника "Режим записи видеокамеры"(sysName: recordArchive).
 */
export enum ArchiveModesEnum {
  /** Непрерывно */
  constantly = 0,
  /** По расписанию */
  onSchedule = 1,
  /** По событию */
  onEvent = 2,
  /** По команде от внешней системы */
  onExternalSystem = 3,
}
