import { Component, forwardRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseComponent } from '@bg-front/core/components';
import { IScInputOptions } from '@smart-city/core/common';
import { takeUntil } from 'rxjs/operators';
import { ILifeCycleStepParamDto, IPlacementDetailsDto } from 'smart-city-types';

@Component({
  selector: 'placement-details-edit',
  templateUrl: './placement-details-edit.component.html',
  styleUrls: ['./placement-details-edit.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlacementDetailsEditComponent),
      multi: true,
    },
  ],
})
/**
 * Компонент реализует возможность редактировать детали местоположения
 * таких, как Корпус, Этажность, Этаж, Подъезд, Квартира, Удаленносить от трассы.
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 *
 *  @example
 *
 *  Parent Component Class:
 *
 *  this.model = {
 *    corp: '12',
 *    storeys: '9',
 *    floor: '8',
 *    entrance: '1',
 *    room: '34',
 *    km: '1.5',
 *  };
 *
 *  this.form = new FormGroup({
 *    placementDetails: new FormControl(this.model),
 *  });
 *
 *  Parent Component Template:
 *
 *  form [formGroup]="form">
 *    <placement-details-edit
 *      formControlName="placementDetails"
 *      disabled=true
 *      ></placement-details-edit>
 *  </form>
 */
export class PlacementDetailsEditComponent extends BaseComponent implements OnInit, ControlValueAccessor, OnDestroy {
  /** Признак блокировки компонента */
  @Input() public disabled: boolean = false;

  /** Счётчик для генерации id компоненты */
  public static nextId = 0;
  /** Аттрибуты */
  @Input()
  public attributes: ILifeCycleStepParamDto[];
  /**
   * Форма
   */
  public form: FormGroup;
  /** Установка id */
  @HostBinding() id = `placement-details-edit-${PlacementDetailsEditComponent.nextId}`;
  /**
   * Настройка компоненты Корпус
   */
  public corpOptions: IScInputOptions = {
    label: 'Корпус',
    maxLength: 10,
  };
  /**
   * Настройка компоненты Подъезд
   */
  public entranceOptions: IScInputOptions = {
    label: 'Подъезд',
    mask: '0*',
  };
  /**
   * Настройка компоненты Этажность
   */
  public storeysOptions: IScInputOptions = {
    label: 'Этажность',
    mask: '0*',
  };
  /**
   * Настройка компоненты Этаж
   */
  public floorOptions: IScInputOptions = {
    label: 'Этаж',
    mask: '0*',
  };
  /**
   * Настройка компоненты Квартира
   */
  public roomOptions: IScInputOptions = {
    label: 'Квартира',
    maxLength: 10,
  };
  /**
   * Настройка компоненты Км. трассы
   */
  public kmOptions: IScInputOptions = {
    label: 'Км. трассы',
    maxLength: 10,
  };

  constructor() {
    super();
    PlacementDetailsEditComponent.nextId++;
  }

  /** @ignore */
  public ngOnInit(): void {
    this.form = new FormGroup({
      corp: new FormControl(),
      entrance: new FormControl(),
      storeys: new FormControl(),
      floor: new FormControl(),
      room: new FormControl(),
      km: new FormControl(),
    });

    if (this.disabled) {
      this.form.disable();
    }

    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => this.propagateChange(this.isFullNullObject(value) ? null : value));
  }

  /**
   * Записать значение в компонент (из ts в html), основная функция
   */
  public writeValue(value: IPlacementDetailsDto): void {
    if (value) {
      this.form.patchValue(value, { emitEvent: false });
    }
  }

  /**
   * Обработать значение из компонента (из html в ts), основная функция fn
   */
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Обработать, когда потрогали поле (потеря фокуса)
   */
  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /** Блокировка формы */
  public setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  /** @ignore */
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

/** Подписка на изменения */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private propagateChange = (_: any) => { return; };

  /** Подписка на нажатия */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private propagateTouch = () => { return; };

  /** Проверяет, является ли объект со всеми значениями, равными null */
  public isFullNullObject(object: any): boolean {
    return typeof object === 'object' && object && !Object.values(object).some((i) => i !== null);
  }
}
