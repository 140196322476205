import { Injectable } from '@angular/core';
import { BaseService, RestService } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData } from 'smart-city-types';
import { IScheduleBreaks, IScheduleUsers } from '../../models/interfaces';

/**
 * Сервис по взаимодействию с расписанием смены операторов.
 */
@Injectable({
  providedIn: 'root',
})
export class ScheduleService extends BaseService {
  constructor(
    private readonly rest: RestService,
  ) {
    super();
  }

  /**
   * Метод, создающий новую запись в таблице Users сервиса Schedule
   * @param data объект с полями из формы
   * @return идентификатор записи смены
   */
  public createSchedule(data: Partial<IScheduleUsers>): Observable<string> {
    return this.rest.serviceRequest({
      data,
      action: 'insert',
      service: {
        name: 'Schedule',
      },
      entity: {
        name: 'Users',
      },
    }).pipe(map((response: IAbstractServiceData) => response.data.id));
  }

  /**
   * Метод, обновляющий существующую запись в таблице Users сервиса Schedule
   * @param data данных
   * @return результат записи
   */
  public updateSchedule(data: Partial<IScheduleUsers>): Observable<IScheduleUsers> {
    return this.rest.serviceRequest({
      data,
      action: 'update',
      service: {
        name: 'Schedule',
      },
      entity: {
        name: 'Users',
        query: {
          id: data.id,
        },
      },
    }).pipe(map((res: IAbstractServiceData) => res.data.items[0]));
  }

  /**
   * Получить последние расписания смены оператора по идентификатору пользователя
   * @param userId идентификатор пользователя(оператора) системы
   * @param limit ограничение выбора (по-умолчанию 10 записей)
   * @return массив смен
   */
  public getSchedule(userId: string, limit: number = 10): Observable<IScheduleUsers[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Schedule' },
      entity: {
        limit,
        name: 'Users',
        sort: {
          field: 'timeStart',
          direction: 'desc',
        },
        query: { userId },
      },
    }).pipe(
      map((res: IAbstractServiceData) => res.data.items as IScheduleUsers[]),
    );
  }

  /**
   * Получить последнюю(и не закрытую) смену оператора по идентификатору пользователя
   * @param userId идентификатор пользователя(оператора) системы
   * @return данные смены
   */
  public getOpenedSchedule(userId: string): Observable<IScheduleUsers> {
    return this.getSchedule(userId).pipe(
      map((scheduleUsers: IScheduleUsers[]) => {
        if (scheduleUsers[0] && !scheduleUsers[0].timeEnd) return scheduleUsers[0];
        return undefined;
      }),
    );
  }

  /**
   * Метод, создающий новую запись в таблице Breaks сервиса Schedule
   * @param data объект с полями из формы
   * @return результат записи
   */
  public createScheduleBreak(data: Partial<IScheduleBreaks>): Observable<string> {
    return this.rest.serviceRequest({
      data,
      action: 'insert',
      service: {
        name: 'Schedule',
      },
      entity: {
        name: 'Breaks',
      },
    }).pipe(map((response: IAbstractServiceData) => response.data.id));
  }

  /**
   * Метод, обновляющий существующую запись в таблице Breaks сервиса Schedule
   * @param data данных
   * @return результат записи
   */
  public updateScheduleBreak(data: Partial<IScheduleBreaks>): Observable<IScheduleBreaks> {
    return this.rest.serviceRequest({
      data,
      action: 'update',
      service: {
        name: 'Schedule',
      },
      entity: {
        name: 'Breaks',
        query: {
          id: data.id,
        },
      },
    }).pipe(map((res: IAbstractServiceData) => res.data.items[0]));
  }

  /**
   * Получить последние перерывы оператора
   * @param userId идентификатор пользователя(оператора) системы
   * @param scheduleId идентификатор смены(не обязательный)
   * @param limit ограничение выбора (по-умолчанию 10 записей)
   * @return массив перерывов
   */
  public getScheduleBreaks(userId: string, scheduleId?: string, limit: number = 10): Observable<IScheduleBreaks[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Schedule' },
      entity: {
        limit,
        name: 'Breaks',
        sort: {
          field: 'timeStart',
          direction: 'desc',
        },
        query: { userId, scheduleId },
      },
    }).pipe(map((res: IAbstractServiceData) => res.data.items as IScheduleBreaks[]));
  }

  /**
   * Получить последний(и не закрытый) перерыв оператора по идентификатору пользователя
   * @param userId идентификатор пользователя(оператора) системы
   * @param scheduleId идентификатор смены(не обязательный)
   * @return данные перерыва
   */
  public getOpenedScheduleBreaks(userId: string, scheduleId?: string): Observable<IScheduleBreaks> {
    return this.getScheduleBreaks(userId, scheduleId).pipe(
      map((scheduleBreaks: IScheduleBreaks[]) => {
        if (scheduleBreaks[0] && !scheduleBreaks[0].timeEnd) return scheduleBreaks[0];
        return undefined;
      }),
    );
  }
}
