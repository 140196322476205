import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService, ScNavService } from '@smart-city/core/services';
import { BaseDashboardComponent, ExportDialogComponent } from '@bg-front/core/components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzContextMenuService } from 'ng-zorro-antd/dropdown';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SignificantObjectsService } from '../../services'
import { IAbstractServiceData, IAnyObject, IDictionaryModelDto } from 'smart-city-types';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { IResponseWithTotal, ISignificantObjectCategory } from '@bg-front/core/models/interfaces';
import {
  ISignificantObjectsDto,
  ISignificantObjectsFilter,
  ISignificantObjectsForTable,
} from '../../models/interfaces';
import { SignificantObjectsFilterComponent } from '../significant-objects-filter/significant-objects-filter.component'
import { NEVER, of } from 'rxjs';
import { ExportService } from '@bg-front/core/services';
import * as dayjs from 'dayjs';
import { FormControl, FormGroup } from '@angular/forms';
import { NzDrawerComponent } from 'ng-zorro-antd/drawer';

@UntilDestroy()
@Component({
  selector: 'significant-objects-dashboard',
  templateUrl: './significant-objects-dashboard.component.html',
  styleUrls: ['./significant-objects-dashboard.component.scss'],
})
export class SignificantObjectsDashboardComponent
extends BaseDashboardComponent<ISignificantObjectsForTable, ISignificantObjectsFilter> {
  /** Форма в шапке */
  public headerForm: FormGroup;

  /** Строка поиска */
  public searchValue: string;

  /** Подсвечиваемая строка */
  public highlightString: string;

  /** Элемент строки поиска */
  @ViewChild('searchValue') public searchValueRef: ElementRef;

  /** @ignore */
  constructor(
    scNavService: ScNavService,
    route: ActivatedRoute,
    nzContextMenuService: NzContextMenuService,
    notificationService: NotificationService,
    private readonly service: SignificantObjectsService,
    private readonly router: Router,
    private readonly modalService: NzModalService,
    private readonly navService: ScNavService,
    private readonly noteService: NzNotificationService,
    private readonly exportService: ExportService,
  ) {
    super(notificationService, nzContextMenuService, route, scNavService);

    this.sortableColumns = {
      name: { key: 'name', order: 'ascend' },
      address: { key: 'address.fullText', order: null },
      state: { key: 'state.name', order: null },
      category: { key: 'category.name', order: null },
      type: { key: 'type.name', order: null },
      responsible: { key: 'responsible', order: null }
    };

    this.headerForm = new FormGroup({
      search: new FormControl(),
    });
  }

  /** Открытие меню */
  public menu(): void {
    this.navService.openMenu();
  }

  /** Функция загрузки и обновления данных */
  public reload(): void {
    this.isLoading = true;
    this.service.getPageItems(
      this.prepareQuery(),
      this.pageIndex ? this.pageIndex - 1 : 0,
      this.pageSize,
      this.getSort(),
      ['name', 'address.fullText', 'coordinates', 'state.name', 'category.name', 'type.name', 'responsible', 'phone'],
    ).pipe(
      catchError(() => {
        this.noteService.error('Ошибка', 'Информация для таблицы не получена');
        return of({ totalCount: 0, items: <ISignificantObjectsDto[]>[] });
      }),
      finalize(() => this.isLoading = false),
      untilDestroyed(this),
    ).subscribe((response: IResponseWithTotal<ISignificantObjectsDto[]>) => {
      this.totalCount = response.totalCount;
      this.data = response.items.map((item: ISignificantObjectsDto) => ({
        id: item.id,
        name: item.name,
        addressFullText: item.address?.fullText,
        coordinates: item.coordinates,
        stateName: (item.state as IDictionaryModelDto)?.name,
        categoryName: (item.category as ISignificantObjectCategory)?.name,
        typeName: (item.type as IDictionaryModelDto)?.name,
        responsible: item.responsible,
        phone: item.phone,
      }));
      this.highlightString = this.searchValue;
    });
  }

  /** Функция подготовки запроса */
  public override prepareQuery(): IAnyObject | undefined {
    const $and = [];

    if (this.filter) {
      if (this.filter.state) {
        $and.push({ state: this.filter.state });
      }

      if (this.filter.category) {
        $and.push({ category: this.filter.category });
      }

      if (this.filter.type) {
        $and.push({ type: this.filter.type });
      }

      if (this.filter.mo) {
        $and.push({ mo: this.filter.mo });
      }
    }

    this.filterCount = $and.length;

    if (this.searchValue) {
      $and.push({ $text: { $search: this.searchValue } });
    }

    return $and.length ? { $and  } : undefined;
  }

  /** Функция удаления записей */
  public delete(ids: string | string[]): void {
    this.service
      .delete(ids)
      .pipe(
        untilDestroyed(this),
        catchError((err: Error) => {
          return this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка при удалении');
        }),
      )
      .subscribe((result: IAnyObject) => {
        if (!result) return;
        if (result.data.count > 1) this.notificationService.pushInfo('Объекты успешно удалены')
        else this.notificationService.pushInfo('Объект успешно удален');
        this.clearCheckedIds();
        this.reload();
      });
  }

  /** Открытие окна подтверждения удаления записи */
  public showDeleteConfirm(): void {
    this.modalService.confirm({
      nzTitle: 'Удалить выбранные объекты?',
      nzOkText: 'Удалить',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzMaskClosable: true,
      nzOnOk: () => this.deleteSelected(),
      nzCancelText: 'Отмена',
    });
  }

  public search(): void {
    this.searchValue = this.headerForm.controls.search.value;
    this.pageIndex = 1;
    this.reload();
  }

  public openFilterDialog(): void {
    this.modalService.create({
      nzTitle: 'Параметры фильтрации',
      nzContent: SignificantObjectsFilterComponent,
      nzComponentParams: {
        filter: this.filter,
      },
    })
      .afterClose.pipe(untilDestroyed(this))
      .subscribe((value: ISignificantObjectsFilter) => {
        if (value) {
          this.setFilterValue(value);
        }
      });
  }

  /** Добавление новой записи */
  public addRecord(): void {
    this.router.navigate(['edit'], { relativeTo: this.route });
  }

  /**
   * Просмотр важного объекта
   * @params id Идентификатор просматриваемой записи
   */
  public viewRecord(id: string): void {
    this.router.navigate(['view', id], { relativeTo: this.route });
  }

  /** Изменение страницы */
  public changePage(page: number): void {
    this.pageIndex = page;
    this.reload();
  }

  /** Функция выполняемая при закрытии формы редактирования */
  public onFormDeactivate(form: IAnyObject, drawer: NzDrawerComponent): void {
    drawer.close();
    if (form.closeAction === 'delete') {
      this.clearCheckedIds(1);
    } else {
      this.currentId = undefined;
    }
    if (!!form.closeAction) this.reload();
  }

  /** Функция экспорта */
  public export(): void {

    const modal = this.modalService.create({
      nzTitle: 'Параметры экспорта',
      nzContent: ExportDialogComponent,
      nzFooter: null,
    });

    modal.afterClose
      .pipe(
        switchMap((fileType: string) => {
          if (fileType) {
            this.noteService.info('Внимание!', 'Файл формируется');

            return this.exportService.exportEntity(
              {
                title: `Важные объекты_${dayjs().format('DD.MM.YYYY HH_mm_ss')}`,
                attributes: [
                  'name',
                  'shortName',
                  'mo.name',
                  'address.fullText',
                  'corp',
                  'coordinates',
                  'powerSupplyConsumerCategory.name',
                  'state.name',
                  'category.name',
                  'type.name',
                  'responsible',
                  'phone',
                  'projectedRiskIds',
                  'dutyServices',
                ],
                titles: {
                  'name': 'Наименование',
                  'shortName': 'Краткое наименование',
                  'mo.name': 'Муниципальное образование',
                  'address.fullText': 'Адрес',
                  'corp': 'Корпус',
                  'coordinates': 'Координаты',
                  'powerSupplyConsumerCategory.name': 'Категория электроснабжения потребителей',
                  'state.name': 'Состояние',
                  'category.name': 'Категория',
                  'type.name': 'Тип',
                  'responsible': 'Ответственный',
                  'phone': 'Контактный телефон',
                  'projectedRiskIds': 'Риск для прогнозирования',
                  'dutyServices': 'Дежурные службы',
                },
                limit: 500,
                serviceName: 'Admin',
                entityName: 'SignificantObjects',
              },
              [
                'name',
                'shortName',
                'mo.name',
                'address.fullText',
                'corp',
                'coordinates',
                'powerSupplyConsumerCategory.name',
                'state.name',
                'category.name',
                'type.name',
                'responsible',
                'phone',
                'projectedRiskIds',
                'dutyServices',
              ],
              fileType,
              this.prepareQuery(),
            );
          }
          return NEVER;
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

}
