import { Injectable } from '@angular/core';
import { RestService } from '@smart-city/core/services';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IVehicle } from '../../../bg/modules/external-interactions/modules/vehicles/models/interfaces';
import { FilterOperationEnum } from '@bg-front/core/models/enums';

/**
 *  Вспомогательный сервис по работе с данными вне РНИС
 */
@Injectable({ providedIn: 'root' })
export class RnisHelpersService {

  constructor(
    private readonly rest: RestService,
  ) { }

  /**
   * Получить список АТТ из справочника ТС по доступным типам ТС
   * @param typeIds список идентификаторов типов ТС
   * @return список устройств
   */
  public getAttByType(typeIds: string[]): Observable<string[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Vehicles' },
      entity: {
        name: 'Dictionary',
        attributes: ['typeId', 'originalAtt'],
        query: {
          typeId: { $in: typeIds },
          originalAtt: { $ne: null },
        },
      },
    }).pipe(
      map((response: IAbstractServiceData) => {
        return response.data.items.map((item: IVehicle) => item?.originalAtt);
      }),
    );
  }

  /**
   * Получить данные ТС по идентификатору АТТ
   * @param originalAtt идентификатор АТТ
   * @return объект данных ТС
   */
  public getVehiclesByAtt(originalAtt: string): Observable<IVehicle> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Vehicles' },
      entity: {
        name: 'Dictionary',
        query: { originalAtt },
      },
    }).pipe(
      map((response: IAbstractServiceData) => response.data.items[0] ?? {}),
    );
  }

  /**
   * Получить список АТТ из справочника ТС по объекту запроса
   * @param query Объект запроса
   * @return список устройств
   */
  public getAttByQuery(query: IAnyObject): Observable<string[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Vehicles' },
      entity: {
        query,
        name: 'Dictionary',
      },
    }).pipe(
      map((response: IAbstractServiceData) => {
        return response.data.items.map((item: IVehicle) => item?.originalAtt);
      }),
    );
  }

  /**
   * Преобразование типа операции и значение в объект для запроса
   * @param property
   * @param value
   * @param operation
   */
  public createValueByOperation(
    property: string,
    value: string | string[],
    operation: FilterOperationEnum = FilterOperationEnum.equal,
  ): IAnyObject {
    const filter = <IAnyObject>{};
    if (property && value) {
      switch (operation) {
        case FilterOperationEnum.equal:
          filter[property] = value === 'null' ? null : value;
          break;
        case FilterOperationEnum.notEqual:
          filter[property] = { $ne: value === 'null' ? null : value };
          break;
        case FilterOperationEnum.lessThen:
          filter[property] = { $lt: value === 'null' ? null : value };
          break;
        case FilterOperationEnum.lessThenOrEqual:
          filter[property] = { $lte: value === 'null' ? null : value };
          break;
        case FilterOperationEnum.greatThen:
          filter[property] = { $gt: value === 'null' ? null : value };
          break;
        case FilterOperationEnum.greatThenOrEqual:
          filter[property] = { $gte: value === 'null' ? null : value };
          break;
        case FilterOperationEnum.in:
          filter[property] = { $in: value  };
          break;
      }
    }
    return filter;
  }
}
