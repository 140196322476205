import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IAnyObject } from 'smart-city-types';

/**
 * Общий сервис для межкомпонентного взаимодействия
 */
@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  private runForecastingSubject: Subject<IAnyObject> = new Subject<IAnyObject>();
  public runForecasting$: Observable<IAnyObject> = this.runForecastingSubject.asObservable();
  /** Запускаем форму прогнозирования */
  public runForecasting(options: IAnyObject) {
    this.runForecastingSubject.next(options);
  }
}
