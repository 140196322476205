import { Component, HostListener } from '@angular/core';

// TODO: Переверстать
/**
 * Легенда кругов на карте
 */
@Component({
  selector: 'map-circle-legend',
  templateUrl: './map-circle-legend.component.html',
  styleUrls: ['./map-circle-legend.component.scss'],
})
export class MapCircleLegendComponent {
  /** Показать легенду */
  public showLegend: boolean = false;
  /** Мышка на элементе или нет */
  private mouseOnCurrentElement = false;

  /**
   * Следим, что мышка на элементе
   */
  @HostListener('mouseenter') onMouseenter() {
    this.mouseOnCurrentElement = true;
  }

  /**
   * Следим, что мышка покинула элемент
   */
  @HostListener('mouseleave') onMouseleave() {
    this.mouseOnCurrentElement = false;
  }

  /**
   * Обрабатываем событие, что кликнули другой элемент
   */
  @HostListener('document:click') clickedOutside() {
    if (!this.mouseOnCurrentElement && this.showLegend) {
      this.showLegend = false;
    }
  }

  /** Обработчик, вызываемый при клике на шеврон. Открыть легенду. */
  public onShowLegend(): void {
    this.showLegend = !this.showLegend;
  }
}
