import { Injectable } from '@angular/core';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { AccessService, RestService, Settings2Service, SfsService } from '@smart-city/core/services';
import { ScConsole } from '@smart-city/core/utils';
import { GisService, IGisServiceResult } from '@smart-city/maps/sc';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { forkJoin, Observable, of, pipe, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IMapObjectDto } from 'smart-city-types';

import { Coordinates } from '../../models/classes/coordinates';
import { OperationFilterHelper } from '../../models/classes/operation-filter.helper';
import { IIconDto, IMapLayer, IMapLayerEntity, IMapLayerEntityFilter } from '../../models/interfaces';
import { CommonService } from '../common/common.service';

/**
 * Сервис для загрузки ГЕО-данных
 */
@Injectable({
  providedIn: 'root',
})
export class LayersDataService {
  private iconMap: Map<string, string> = new Map<string, string>();

  /** Подписка на информацию о маркере для его отображения на карте */
  private clickMarker: Subject<{ coordinates: Coordinates; iconName: string; objectId: string }> = new Subject<{
    coordinates: Coordinates;
    iconName: string;
    objectId: string;
  }>();
  public clickMarker$: Observable<{
    coordinates: Coordinates;
    iconName: string;
    objectId: string;
  }> = this.clickMarker.asObservable();

  /** Подписка на факт загрузки слоев на карте */
  private mapLayersReady: Subject<void> = new Subject<void>();
  public mapLayersReady$: Observable<void> = this.mapLayersReady.asObservable();

  /** Подписка на изменения фильтра слоев на карте */
  private mapLayerFilterSelected = new Subject<IAnyObject>();
  public mapLayerFilterSelected$ = this.mapLayerFilterSelected.asObservable();

  private handleLayers(obs: Observable<IMapLayer[]>): Observable<IMapLayer[]> {
    let layers: IMapLayer[] = [];
    return obs.pipe(
      switchMap((data: IMapLayer[]) => {
        if (!data.length) {
          return of([]);
        }
        layers = data;
        return forkJoin(
          ...layers.map((layer: IMapLayer) => {
            layer.icon = (layer.icon as IIconDto)?.fileId;

            if (this.iconMap.has(`${layer.icon}${layer.markerColor}`)) {
              return of(this.iconMap.get(`${layer.icon}${layer.markerColor}`));
            }
            if (layer.icon) {
              return this.sfs.getFileContents(layer.icon).pipe(
                map((arrayBuffer: ArrayBuffer | Blob) =>
                  this.common.generateSvgIcon(arrayBuffer as ArrayBuffer, layer.markerColor),
                ),
                tap((icon: string) => this.iconMap.set(`${layer.icon}${layer.markerColor}`, icon)),
                catchError(() => {
                  this.note.error('Ошибка загрузки', `Ошибка загрузки иконки слоя ${layer.name}`);
                  return of('');
                }),
              );
            }
            this.note.warning('Предупреждение', `Отсутствует иконка слоя ${layer.name}`);
            return of('');
          }),
        );
      }),
      map((result: string[]) => {
        if (!result?.length) {
          return [];
        }
        return result.map((icon: string, index: number) => {
          layers[index].icon = icon;
          return layers[index];
        });
      }),
    );
  }

  constructor(
    private readonly rest: RestService,
    private readonly sfs: SfsService,
    private readonly accessService: AccessService,
    private readonly common: CommonService,
    private readonly settings: Settings2Service,
    private readonly note: NzNotificationService,
    private readonly gisService: GisService,
  ) {}

  /** Изменение фильтра на карте */
  public selectMapLayerFilter(filter: IAnyObject): void {
    this.mapLayerFilterSelected.next(filter);
  }

  /**
   * Получение координат по введённому адресу
   * @param address строка адреса
   */
  public getCoordinatesByAddress(address: string): Observable<IGisServiceResult> {
    return this.gisService
      .getCoordinatesByAddressV2(this.settings.getConfig().nfiasRegion, address)
      .pipe(map((res: IGisServiceResult[]) => res[0]));
  }

  /**
   * Запрос доступных слоёв
   */
  public getLayers(): Observable<IMapLayer[]> {
    const obs = this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Gis' },
        entity: {
          name: 'MapLayers',
          linksMode: 'object',
          query: {
            icon: {
              $ne: null,
            },
            entityFilters: {
              $ne: null,
            },
          },
          sort: {
            field: 'order',
            direction: 'asc',
          },
        },
      })
      .pipe(
        map((response) => {
          return ((response.data.items as IMapLayer[]) || []).filter(
            // Если нет разрешающего правила (enabled === true), то действие запрещено
            (item: IMapLayer) => {
              return this.accessService.accessMap[item.claim]?.enabled;
            },
          );
        }),
      );

    return this.handleLayers(obs);
  }

  /** Получение списка svg иконок для легенды на карте */
  public getSvgIconsMap(layers: IMapLayer[]): Observable<IAnyObject> {
    if (!layers) return of(null);
    const pictogramDict = this.settings.getDictionaryByTypeSysName('pictogramElements');
    const iconElemId = pictogramDict.find((elem: IDictionaryInfo) => elem.sysname === 'icon')?.id;

    const changedIcons: { iconId: string; markerColor: string }[] = [];
    layers.forEach((layer: IMapLayer) => {
      layer.pictogramSettings?.forEach((settings: IAnyObject) => {
        if (settings.pictogramElement === iconElemId) {
          settings.pictogramSettingsValues?.forEach((item) =>
            changedIcons.push({ iconId: item.element, markerColor: layer.markerColor }),
          );
        }
      });
    });

    const changedIconsIds = changedIcons.map((item: { iconId: string; markerColor: string }) => item.iconId);
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Icons',
          attributes: ['fileId'],
          query: {
            id: { $in: changedIconsIds },
          },
        },
      })
      .pipe(
        switchMap((response: IAbstractServiceData) => {
          const mapIcon = response.data.items?.reduce((prev, curr: { id: string; fileId: string }) => {
            return { ...prev, [curr.id]: { fileId: curr.fileId } };
          }, {});

          const obs: Observable<ArrayBuffer | Blob>[] = [];
          changedIconsIds.forEach((iconId: string) => obs.push(this.sfs.getFileContents(mapIcon[iconId].fileId)));

          if (obs.length) {
            return forkJoin(obs);
          }
          return of([]);
        }),
        catchError(() => {
          this.note.error('Ошибка загрузки', `Ошибка загрузки иконки из конфигурации пиктограмм`);
          return of([]);
        }),
        map((arrayBuffer: (ArrayBuffer | Blob)[]) => {
          return changedIcons
            .map((icon: { iconId: string; markerColor: string }, idx: number) => {
              return {
                ...icon,
                svgIcon: this.common.generateSvgIcon(arrayBuffer[idx] as ArrayBuffer, icon.markerColor),
              };
            })
            .reduce((prev: IAnyObject, curr: { iconId: string; markerColor: string; svgIcon: string }) => {
              prev[curr.iconId] = curr.svgIcon;
              return prev;
            }, {});
        }),
      );
  }

  /**
   * Запрос доступных слоёв
   */
  public getLayerByClaim(claim: string): Observable<IMapLayer | undefined> {
    const obs = this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: 'Gis' },
          entity: {
            name: 'MapLayers',
            linksMode: 'object',
            query: {
              claim,
              icon: {
                $ne: null,
              },
              entityFilters: {
                $ne: null,
              },
            },
            sort: {
              field: 'order',
              direction: 'asc',
            },
          },
        },
        'http',
      )
      .pipe(map((response) => (response.data.items as IMapLayer[]) || []));

    return this.handleLayers(obs).pipe(
      map((val: IMapLayer[]) => {
        return val[0];
      }),
    );
  }

  /**
   * Запрос данных по слоям
   */
  public getLayerData(
    entityFilter: IMapLayerEntity,
    limit: {
      paNumber: number;
      paSize: number;
    },
    mapLayerFilters: IMapLayerEntityFilter[] = [],
    additionalAttributes: string[] = [],
  ): Observable<IMapObjectDto[]> {
    let entityAttributes: string[];
    let query: IAnyObject;
    let mapPipe;
    const joinedTables: IAnyObject = {};
    let distinct = undefined;
    switch (`${entityFilter.service}_${entityFilter.entity}`) {
      case 'Admin_SignificantObjects':
      case 'AtmIntegration_MonitoringObject':
        entityAttributes = ['id', 'name', 'coordinates', 'statusId'];
        query = { coordinates: { $ne: null } };
        mapPipe = map((response: IAbstractServiceData) => {
          const items = (response.data || {}).items || [];
          return items.map((item: IAnyObject) => {
            return <IAnyObject>{
              ...item,
              coordinates: new Coordinates(item.coordinates).toArray(),
            };
          });
        });
        break;
      case 'Integration_AddressPlan':
        entityAttributes = ['id', 'coordinates', 'street_1'];
        query = { coordinates: { $ne: null } };
        mapPipe = map((response: IAbstractServiceData) => {
          const items = (response.data || {}).items || [];
          return items.map((item: IAnyObject) => {
            return {
              ...item,
              coordinates: new Coordinates(item.coordinates).toArray(),
            };
          });
        });
        break;
      case 'Admin_VideoDevices':
        entityAttributes = ['id', 'name', 'coordinates', 'active'];
        const filterByMonitoringSubjectIndex: number = mapLayerFilters.findIndex(
          (item: IMapLayerEntityFilter) => item.property === 'monitoringSubject',
        );

        const filterByVideoDeviceCategoriesIndex: number = mapLayerFilters.findIndex(
          (item: IMapLayerEntityFilter) => item.property === 'categoryIds',
        );

        const filterByVideoDeviceIsNoCategoryIndex: number = mapLayerFilters.findIndex(
          (item: IMapLayerEntityFilter) => item.property === 'isNoCategory',
        );

        // Доступ к камере на основании фильтра по предмету мониторинга
        const filterByMonitoringSubject: IAnyObject = {};
        if (filterByMonitoringSubjectIndex !== -1) {
          filterByMonitoringSubject.$expr = {
            $eq: ['$vcaSettingsTable.monitoringSubject', mapLayerFilters[filterByMonitoringSubjectIndex].value],
          };
          joinedTables.vcaSettingsTable = {
            $join: {
              service: 'Admin',
              entity: 'VCASettings',
              attributes: ['id', 'monitoringSubject'],
              query: {
                $expr: { $contains: ['$vcaSettings', { $expr: { $toJsonb: ['$vcaSettingsTable.id'] } }] },
              },
            },
          };
        }

        if (
          filterByVideoDeviceIsNoCategoryIndex !== -1 ||
          filterByVideoDeviceCategoriesIndex !== -1 ||
          this.settings.getConfig().cameraAccessByCategories === 'TRUE'
        ) {
          joinedTables.categories = {
            $join: {
              service: 'Admin',
              entity: 'VideoDevicesCategoriesLinks',
              attributes: ['videoDeviceId', 'categoryId'],
              query: { $expr: { $eq: ['$id', '$categories.videoDeviceId'] } },
            },
          };
        }

        // Доступ к камере на основании фильтров связанных с категориями
        const filterByVideoDeviceCategories: IAnyObject = { $or: [] };
        // Доступ к камере на основании фильтра по наличию/отсутствию категории
        if (filterByVideoDeviceIsNoCategoryIndex !== -1) {
          filterByVideoDeviceCategories.$or.push({
            $expr:
              mapLayerFilters[filterByVideoDeviceIsNoCategoryIndex].value === 'true'
                ? { $eq: ['$categories.categoryId', null] }
                : { $ne: ['$categories.categoryId', null] },
          });
        }
        // Доступ к камере на основании фильтра по категориям
        if (filterByVideoDeviceCategoriesIndex !== -1) {
          filterByVideoDeviceCategories.$or.push({
            $expr: {
              $in: [
                `["${mapLayerFilters[filterByVideoDeviceCategoriesIndex].value.join('","')}"]`,
                '$categories.categoryId',
              ],
            },
          });
        }

        // Доступ к камере на основании данных о категории.
        // Отображаются камеры либо с разрешенной категорией, либо без категории
        const filterByVideoDeviceCategoriesAccess: IAnyObject = {};
        if (this.settings.getConfig().cameraAccessByCategories === 'TRUE') {
          filterByVideoDeviceCategoriesAccess.$or = [
            { $expr: { $eq: ['$categories.categoryId', null] } },
            {
              $and: [
                { $expr: { $eq: ['$accessRights.cameraAccess', true] } },
                { $expr: { $eq: ['$accessRights.userId', this.settings.currentUser.id] } },
              ],
            },
          ];
          joinedTables.accessRights = {
            $join: {
              service: 'Admin',
              entity: 'VideoDeviceCategoryAccessRights',
              attributes: ['id', 'cameraAccess', 'userId', 'videoDeviceCategoryId'],
              query: { $expr: { $eq: ['$categories.categoryId', '$accessRights.videoDeviceCategoryId'] } },
            },
          };
        }

        query = {
          coordinates: { $ne: null },
          active: true,
          $and: [filterByMonitoringSubject, filterByVideoDeviceCategories, filterByVideoDeviceCategoriesAccess],
        };

        if (filterByMonitoringSubjectIndex !== -1) {
          mapLayerFilters[filterByMonitoringSubjectIndex] = <IMapLayerEntityFilter>{};
        }
        if (filterByVideoDeviceCategoriesIndex !== -1) {
          mapLayerFilters[filterByVideoDeviceCategoriesIndex] = <IMapLayerEntityFilter>{};
        }
        if (filterByVideoDeviceIsNoCategoryIndex !== -1) {
          mapLayerFilters[filterByVideoDeviceIsNoCategoryIndex] = <IMapLayerEntityFilter>{};
        }

        mapPipe = pipe(
          map((response: IAbstractServiceData) => {
            const items = (response.data || {}).items || [];
            return items.map((item) => {
              return {
                ...item,
                coordinates: new Coordinates(item.coordinates).toArray(),
              };
            });
          }),
        );
        break;
      case 'Emergency_Emergency':
        const statusIds = this.settings
          .getDictionaryByTypeSysName('statusLifeCycleStep')
          .filter((item: IDictionaryInfo) => {
            return ['new', 'inWork', 'onControl'].includes(item.sysname);
          })
          .map((item: IDictionaryInfo) => item.id);
        entityAttributes = ['id', 'number', 'coordinates'];
        query = {
          coordinates: { $ne: null },
          lifeCycleStepId: { status: { id: { $in: statusIds } } },
        };
        mapPipe = pipe(
          map((response: IAbstractServiceData) => {
            const items = (response.data || {}).items || [];
            return items.map((item: IAnyObject) => {
              return {
                ...item,
                name: item.number,
                coordinates: new Coordinates(item.coordinates).toArray(),
              };
            });
          }),
        );
        break;
      case 'Ksion_Aggregated':
        entityAttributes = ['id', 'name', 'coordinates'];
        query = { coordinates: { $ne: null } };
        mapPipe = pipe(
          map((response: IAbstractServiceData) => {
            const items = (response.data || {}).items || [];
            return items.map((item: IAnyObject) => {
              return {
                ...item,
                coordinates: new Coordinates(item.coordinates).toArray(),
              };
            });
          }),
        );
        break;
      case 'Admin_Organizations':
        entityAttributes = ['id', 'name', 'coordinates'];
        query = { coordinates: { $ne: null } };
        mapPipe = pipe(
          map((response: IAbstractServiceData) => {
            const items = (response.data || {}).items || [];
            return items.map((item: IAnyObject) => {
              return {
                ...item,
                coordinates: new Coordinates(item.coordinates).toArray(),
              };
            });
          }),
        );
        break;
      case 'PccIntegration_MonitoringObjects':
        entityAttributes = ['id', 'name', 'coordinates'];
        query = { coordinates: { $ne: null } };
        mapPipe = map((response: IAbstractServiceData) => {
          const items = (response.data || {}).items || [];
          return items.map((item: IAnyObject) => {
            return <IAnyObject>{
              ...item,
              coordinates: new Coordinates(item.coordinates).toArray(),
            };
          });
        });
        break;
      case 'Emergency_Events': {
        entityAttributes = ['id', 'address'];
        query = { address: { $ne: null } };
        mapPipe = map((response: IAbstractServiceData) => {
          const items = (response.data || {}).items || [];
          return items.map((item: IAnyObject) => {
            return <IAnyObject>{
              ...item,
              coordinates: new Coordinates(item.address.latitude, item.address.longitude).toArray(),
            };
          });
        });
        break;
      }
      case 'EcoMonitoringIntegration_Aggregated':
      case 'NeboLiveIntegration_Aggregated':
        distinct = ['id'];
        entityAttributes = ['id', 'measurementPointName', 'coordinates'];

        const filterByMeasurerIndex: number = mapLayerFilters.findIndex(
          (item: IMapLayerEntityFilter) => item.property === 'measurerId',
        );

        const filterByMeasurer: IAnyObject = {};
        if (filterByMeasurerIndex !== -1) {
          filterByMeasurer.$expr = {
            $eq: ['$measurer.measurerId', mapLayerFilters[filterByMeasurerIndex].value],
          };
          joinedTables.measurementPoint = {
            $join: {
              service: `${ entityFilter.service }`,
              entity: 'MeasurementPoints',
              attributes: ['id'],
              query: {
                $expr: { $eq: ['$measurementPointId', '$measurementPoint.id'] },
              },
            },
          };

          joinedTables.measurer = {
            $join: {
              service: `${ entityFilter.service }`,
              entity: 'MeasurementPointsMeasurersLinks',
              attributes: ['id', 'measurementPointId', 'measurerId'],
              query: {
                $expr: { $eq: ['$measurementPoint.id', '$measurer.measurementPointId'] },
              },
            },
          };
        }

        query = {
          coordinates: { $ne: null },
          ...filterByMeasurer,
        };

        if (filterByMeasurerIndex !== -1) {
          mapLayerFilters[filterByMeasurerIndex] = <IMapLayerEntityFilter>{};
        }

        mapPipe = map((response: IAbstractServiceData) => {
          const items = (response.data || {}).items || [];
          return items.map((item: IAnyObject) => {
            return <IAnyObject>{
              ...item,
              name: item.measurementPointName,
              coordinates: new Coordinates(item.coordinates).toArray(),
            };
          });
        });
        break;
      case 'Directories_WaterSources':
        entityAttributes = ['id', 'coordinates', 'street_1', 'name'];
        query = { coordinates: { $ne: null } };
        mapPipe = map((response: IAbstractServiceData) => {
          const items = (response.data || {}).items || [];
          return items.map((item: IAnyObject) => {
            return {
              ...item,
              name: item.name,
              coordinates: new Coordinates(item.coordinates).toArray(),
            };
          });
        });
        break;
      case 'SagittariusIntegration_FireMonitoringObjects':
        entityAttributes = ['id', 'name', 'coordinates'];
        query = { coordinates: { $ne: null } };
        mapPipe = map((response: IAbstractServiceData) => {
          const items = (response.data || {}).items || [];
          return items.map((item: IAnyObject) => {
            return {
              ...item,
              coordinates: new Coordinates(item.coordinates).toArray(),
            };
          });
        });
        break;
      case 'FireMonitoringIntegration_FireMonitoringObjects':
          entityAttributes = ['id', 'name', 'coordinates'];
          query = { coordinates: { $ne: null } };
          mapPipe = map((response: IAbstractServiceData) => {
            const items = (response.data || {}).items || [];
            return items.map((item: IAnyObject) => {
              return {
                ...item,
                coordinates: new Coordinates(item.coordinates).toArray(),
              };
            });
          });
          break;
      case 'RiskAtlasIntegration_Thermopoints':
        entityAttributes = ['id', 'extId', 'centerCoordinates'];
        query = { centerCoordinates: { $ne: null } };
        mapPipe = map((response: IAbstractServiceData) => {
          const items = (response.data || {}).items || [];
          return items.map((item: IAnyObject) => {
            return {
              id: item.id,
              name: item.extId,
              coordinates: new Coordinates(item.centerCoordinates).toArray(),
            };
          });
        });
        break;
      default:
        mapPipe = pipe();
        ScConsole.warning(`Неизвестный слой ${entityFilter.entity}`);
    }

    return this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: entityFilter.service },
          entity: {
            name: entityFilter.entity,
            distinct,
            attributes: [...entityAttributes, ...additionalAttributes],
            query: {
              $and: [query, ...this.getEntityFilters(entityFilter.filters), ...this.getEntityFilters(mapLayerFilters)],
            },
          },
          data: { limit, ...joinedTables },
        },
        'http',
      )
      .pipe(mapPipe);
  }

  /** Получение слоя по Id */
  public getLayerById(id: string): Observable<IMapLayer | undefined> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Gis' },
        entity: {
          name: 'MapLayers',
          query: {
            id,
          },
        },
      })
      .pipe(map((res: IAbstractServiceData): IMapLayer => res.data.items[0] as IMapLayer));
  }

  /**
   * Удаляем слой
   * @param ids идентификатор или массив идентификаторов слоя
   */
  public delete(ids: string | string[]): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: 'Gis' },
      entity: {
        name: 'MapLayers',
        query: {
          id: Array.isArray(ids)
            ? {
                $in: ids,
              }
            : ids,
        },
      },
    });
  }

  /** Загрузка данных слоя согласно настройкам и фильтрам */
  public getLayerItems(
    service: string,
    entity: string,
    attributes: string[],
    query: IAnyObject[],
    limit: {
      paNumber: number;
      paSize: number;
    },
    joinedTables: IAnyObject,
    distinct: string[],
  ): Observable<IAnyObject[]> {
    return this.rest
      .serviceRequest(
        {
          action: 'select',
          service: { name: service },
          entity: {
            attributes,
            distinct,
            name: entity,
            query: {
              $and: [...query],
            },
          },
          data: { limit, ...joinedTables },
        },
        'http',
      )
      .pipe(map((response: IAbstractServiceData) => response.data?.items));
  }

  /** Получаем фильтры */
  public getEntityFilters(filters: IMapLayerEntityFilter[]): IAnyObject[] {
    return filters?.length
      ? filters.map((filter: IMapLayerEntityFilter) =>
          OperationFilterHelper.createValueByOperation(filter.property, filter.value, filter.operation),
        )
      : [];
  }
}
