import { Injectable } from '@angular/core';
import { IPolygonDto } from '@bg-front/core/models/interfaces';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IAnyObject } from 'smart-city-types';
import { IMiniMapOptions, IPolygonMiniMapOptions } from '../../models/interfaces';

/**
 * Сервис для взаимодействия с миникартой
 */
@Injectable({
  providedIn: 'root',
})
export class MiniMapService {
  /**
   * Позволяет следить за изменением поля «Тип КСиП» в информационной карте.
   */
  private setMarkerSource = new Subject<{
    coordinates: MapBaseCoordinatesType;
    objectId?: string;
    popupText?: string;
    tooltip?: string;
  }>();

  public setMarkerSource$: Observable<{
    coordinates: MapBaseCoordinatesType;
    objectId?: string;
    popupText?: string;
    tooltip?: string;
  }> = this.setMarkerSource.asObservable();

  /** Свойство для хранения важных объектов  */
  private significantObjects: BehaviorSubject<IAnyObject> = new BehaviorSubject<IAnyObject>(undefined);
  public significantObjects$: Observable<IAnyObject> = this.significantObjects.asObservable();

  /** Координаты полигона для отображения на мини-карте */
  private polygonCoordinates = new Subject<IPolygonMiniMapOptions>();
  public polygonCoordinates$: Observable<IPolygonMiniMapOptions> = this.polygonCoordinates.asObservable();

  /** Координаты полигонов для отображения на мини-карте */
  private polygonsCoordinates = new Subject<IPolygonDto[]>();
  public polygonsCoordinates$: Observable<IPolygonDto[]> = this.polygonsCoordinates.asObservable();

  /** Масштаб и позиционирование на карте */
  private mapOptions = new Subject<IMiniMapOptions>();
  public mapOptions$: Observable<IMiniMapOptions> = this.mapOptions.asObservable();

  /** Очистка слоя полигонов на мини-карте */
  private clearPolygonsEvent = new Subject<boolean>();
  public clearPolygonsEvent$: Observable<boolean> = this.clearPolygonsEvent.asObservable();

  /** Очистка слоя объектов на мини-карте */
  private clearObjectsEvent = new Subject<boolean>();
  public clearObjectsEvent$: Observable<boolean> = this.clearObjectsEvent.asObservable();



  /**
   * Добавление параметра
   * @param coordinates
   * @param objectId
   * @param popupText
   * @param tooltip
   */
  public setMarker(coordinates: MapBaseCoordinatesType, objectId?: string, popupText?: string, tooltip?: string) {
    this.setMarkerSource.next({
      coordinates,
      objectId,
      popupText,
      tooltip,
    });
  }

  /**
   * Устанавливает список важных объектов для миникарты
   * @param objects - список важных объектов
   */
  public setSignificantObjects(objects: IAnyObject): void {
    this.significantObjects.next({
      'Важные объекты': objects,
    });
  }

  /**
   * Устанавливает координаты полигона для отображения на мини-карте
   * @param options - id полигона и массив координат
   * */
  public setPolygonCoordinates(options: IPolygonMiniMapOptions) {
    this.polygonCoordinates.next(options);
  }
  /**
   * Устанавливает координаты полигонов для отображения на мини-карте
   * @param options - id полигонов и массивы координат
   * */
  public setPolygonsCoordinates(options: IPolygonDto[]) {
    this.polygonsCoordinates.next(options);
  }

  /**
   * Устанавливает масштаб и центр карты
   * @param options - масштаб карты и позиционирование на карте
   * */
  public setMapOptions(options: IMiniMapOptions) {
    this.mapOptions.next(options);
  }

  /** Очищает слой полигонов */
  public clearPolygons() {
    this.clearPolygonsEvent.next(true);
  }

  /** Очищает слой объектов */
  public clearObjects() {
    this.clearObjectsEvent.next(true);
  }
}
