<nz-drawer
  [nzVisible]="openDrawer"
  [nzWidth]="450"
  [nzOffsetX]="offsetX"
  [nzClosable]="false"
  [nzMask]="false"
  [nzPlacement]="'left'"
>
  <ng-container *nzDrawerContent [ngTemplateOutlet]="content">

  </ng-container>
</nz-drawer>

<ng-template #content>
  <div nz-row [nzGutter]="[12, 24]" id="vehicleInfoIdForTest">
    <div nz-col nzSpan="2">
      <i nz-icon nzType="close" nzTheme="outline" (click)="close()" class="close-btn"></i>
    </div>
    <div nz-col nzSpan="2">
      <div class="icon-vehicle"></div>
    </div>
    <div nz-col nzSpan="20">
      <strong>{{model?.stateNumber}}</strong>
    </div>
    <div nz-col [nzSpan]="24">
      <div class="form-label">Абонентский телематический терминал:</div>
      <div class="form-control-container">
        <div class="form-control">{{model?.attId}}</div>
      </div>
    </div>
    <div nz-col [nzSpan]="24">
      <div class="form-label">Тип:</div>
      <div class="form-control-container">
        <div class="form-control">{{getPropertyName('typeId')}}</div>
      </div>
    </div>
    <div nz-col [nzSpan]="24">
      <div class="form-label">Марка:</div>
      <div class="form-control-container">
        <div class="form-control">{{getPropertyName('brandId')}}</div>
      </div>
    </div>
    <div nz-col [nzSpan]="24">
      <div class="form-label">Модель:</div>
      <div class="form-control-container">
        <div class="form-control">{{getPropertyName('modelId')}}</div>
      </div>
    </div>
    <div nz-col [nzSpan]="24">
      <div class="form-label">Организация:</div>
      <div class="form-control-container">
        <div class="form-control">{{getPropertyName('organizationId')}}</div>
      </div>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-collapse>
        <nz-collapse-panel [nzActive]="true" nzHeader="Маршрут">
          <form nz-form *ngIf="form" [formGroup]="form" nzLayout="vertical" errorTailor id="idForTest">
            <nz-form-item>
              <nz-radio-group formControlName="inRealTime">
                <label nz-radio [nzValue]="true">В реальном времени</label>
                <label nz-radio [nzValue]="false">Указать период</label>
              </nz-radio-group>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label nzFor="dateTimeFrom">Начальные дата и время</nz-form-label>
              <nz-form-control>
                <nz-date-picker
                  nzShowTime
                  [nzDisabledDate]="disabledFromDates"
                  [nzAutoFocus]="true"
                  nzFormat="dd.MM.yyyy HH:mm:ss"
                  formControlName="dateTimeFrom"
                  (nzOnOpenChange)="onDateFromPickerOpenChange($event)"
                ></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label nzFor="dateTimeTo">Конечные дата и время</nz-form-label>
              <nz-form-control>
                <nz-date-picker
                  nzShowTime
                  [nzDisabledDate]="disabledToDates"
                  nzFormat="dd.MM.yyyy HH:mm:ss"
                  formControlName="dateTimeTo"
                  (nzOnOpenChange)="onDateToPickerOpenChange($event)"
                ></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </form>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div nz-col [nzSpan]="24">
      <nz-collapse>
        <nz-collapse-panel
          [nzHeader]="'Связанные происшествия'"
          [nzActive]="true"
          class="related__emergencies"
        >
          <bg-emergency-mini-card
            *ngFor="let emergency of relatedEmergencies"
            [emergency]="emergency">
          </bg-emergency-mini-card>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>
</ng-template>
