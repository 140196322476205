<form nz-form [formGroup]="form" errorTailor id="idForTest" nzLayout="vertical" *ngIf="form">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <ng-template #mainParams>
        <h4 class="m-b-0">Основные параметры расчёта</h4>
      </ng-template>
      <nz-divider [nzText]="mainParams" nzOrientation="left"></nz-divider>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="fireTypeId">Вид пожара</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="fireTypeId" nzSize="large">
            <nz-option [nzValue]="type.value" [nzLabel]="type.text" *ngFor="let type of fireTypes"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <ng-container formArrayName="burningTimes">
    <div nz-row [nzGutter]="24" *ngFor="let el of timesArray?.controls; let i = index" [formGroupName]="i">
      <div nz-col [nzSpan]="16">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="burningTime">Время распространения</nz-form-label>
          <nz-form-control>
            <nz-input-group nzSize="large" [nzSuffix]="fireArea">
              <input nzSize="large" nz-input formControlName="burningTime" [controlErrorAnchor]="anchor" />
            </nz-input-group>
            <ng-template controlErrorAnchor #anchor="controlErrorAnchor"></ng-template>
            <ng-template #fireArea> ч </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="2" *ngIf="timesArray.controls.length > 1 && timesArray.enabled">
        <nz-form-item>
          <nz-form-label></nz-form-label>
          <nz-form-control>
            <i nz-icon nzType="minus-circle-o" class="dynamic-delete-button" (click)="removeTime(i)"></i>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </ng-container>
  <div nz-row [nzGutter]="24" *ngIf="timesArray?.enabled">
    <div nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-control [nzXs]="{ span: 24, offset: 0 }" [nzSm]="{ span: 19, offset: 5 }">
          <button type="button" nz-button nzType="dashed" class="add-button" (click)="addTime()">
            <i nz-icon nzType="plus"></i>
            Добавить время
          </button>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <ng-template #subTitle>
        <h4 class="m-b-0">Данные об очаге пожара</h4>
      </ng-template>
      <nz-divider [nzText]="subTitle" nzOrientation="left"></nz-divider>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="16">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="coordinates">Координаты</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" nzRequired nzSearch [nzAddOnAfter]="suffixIconButton">
            <input type="text" placeholder="Широта, Долгота" nz-input formControlName="coordinates" />
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button
              nz-button
              nzSize="large"
              nzSearch
              nzType="primary"
              type="button"
              alt="Задать координаты"
              (click)="onClickSpecifyAddress($event)"
            >
              <i nz-icon nzType="aim"></i>
            </button>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="sizingTypeId">Способ определения размера</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="sizingTypeId" nzSize="large">
            <nz-option nzValue="perimeter" nzLabel="Начальный периметр (в м)"></nz-option>
            <nz-option nzValue="square" nzLabel="Начальная площадь (в га)"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzRequired nzFor="size">Значение</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="unitNameSuffix">
            <input nzSize="large" nz-input formControlName="size" [controlErrorAnchor]="sizeAnchor" />
          </nz-input-group>
          <ng-template controlErrorAnchor #sizeAnchor="controlErrorAnchor"></ng-template>
          <ng-template #unitNameSuffix> {{ unitName }} </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8" *ngIf="isDownstream">
      <nz-form-item>
        <nz-form-label nzFor="height">Средняя высота нагара</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="height">
            <input nzSize="large" nz-input formControlName="height" [controlErrorAnchor]="heightAnchor" />
          </nz-input-group>
          <ng-template controlErrorAnchor #heightAnchor="controlErrorAnchor"></ng-template>
          <ng-template #height> м </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <ng-template #forestText>
        <h4 class="m-b-0">Характеристики лесных насаждений</h4>
      </ng-template>
      <nz-divider [nzText]="forestText" nzOrientation="left"></nz-divider>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="forestTypeId">Вид</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="forestTypeId" nzSize="large">
            <nz-option [nzValue]="type.value" [nzLabel]="type.text" *ngFor="let type of forestTypes"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="trunkDiameterId">Средний диаметр</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="trunkDiameterId" nzSize="large">
            <nz-option [nzValue]="type.value" [nzLabel]="type.text" *ngFor="let type of trunkDiameters"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="flammabilityClass" nzRequired>Класс горимости</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="flammabilityClass" nzSize="large">
            <nz-option [nzValue]="type.value" [nzLabel]="type.text" nzCustomContent *ngFor="let type of flammabilities">
              {{ type.text }}
              <br />
              <p
                nz-typography
                nzType="secondary"
                [nzTooltipTitle]="type.description"
                nzTooltipPlacement="top"
                nz-tooltip
              >
                {{ type.description }}
              </p>
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="24">
      <ng-template #weatherText>
        <h4 class="m-b-0">Метеоусловия в момент аварии</h4>
      </ng-template>
      <nz-divider [nzText]="weatherText" nzOrientation="left"></nz-divider>
    </div>
  </div>
  <div nz-row [nzGutter]="[24, 24]" *ngIf="!isShow">
    <div nz-col [nzSpan]="24">
      <button nz-button nzType="primary" type="button" (click)="getWeatherData()" [nzLoading]="isLoadWeather">
        Получить метеоусловия
      </button>
    </div>
  </div>
  <div nz-row [nzGutter]="[24, 24]">
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="windVelocity">Скорость ветра</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="windVelocitySuffix">
            <input nzSize="large" nz-input formControlName="windVelocity" [controlErrorAnchor]="windVelocityAnchor" />
          </nz-input-group>
          <ng-template #windVelocitySuffix> м/с </ng-template>
        </nz-form-control>
        <ng-template controlErrorAnchor #windVelocityAnchor="controlErrorAnchor"></ng-template>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="windDirection">Направление ветра</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSize="large" [nzSuffix]="degrees">
            <input nzSize="large" nz-input formControlName="windDirection" [controlErrorAnchor]="windDirectionAnchor" />
          </nz-input-group>
        </nz-form-control>
        <ng-template controlErrorAnchor #windDirectionAnchor="controlErrorAnchor"></ng-template>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="8">
      <nz-form-item>
        <nz-form-label nzFor="fireHazardTypeId">Класс пожарной опасности погоды</nz-form-label>
        <nz-form-control>
          <nz-select formControlName="fireHazardTypeId" nzSize="large">
            <nz-option [nzValue]="type.value" [nzLabel]="type.text" *ngFor="let type of fireHazardTypes"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
<ng-template #degrees> ° </ng-template>
