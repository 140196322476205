import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { SignificantObjectsState } from '../../../models/states';

@Injectable()
@StoreConfig({ name: 'significant-objects-store', resettable: true })
export class SignificantObjectsStore extends EntityStore<SignificantObjectsState> {
  constructor() {
    super([]);
  }
}
