<div *ngIf="registry">
  <nw-header [options]="headerOptions" (clickButton)="clickHeaderButton($event)"></nw-header>
  <div class="scroll-container">
    <div *ngFor="let regime of regimes" class="regime-mini-card" (click)="regimeClick($event, regime[0].id)">
      <div class="regime-header">
        <div
          matTooltipPosition="right"
          matTooltip="{{regime[0].introducingTime}}">
          <div class="regime-introducing-time">
            {{regime[0].introducingTime}}
          </div>
        </div>
        <div class="regime-type">{{regime[0].type.name}}</div>
        <mat-icon
          class="regime-link"
          *ngIf="regime[2].length"
          (click)="showLinkedEmergencies($event, regime[2])">
          link
        </mat-icon>
      </div>
      <div
        class="regime-description"
        matTooltip="{{regime[0].description}}"
        matTooltipPosition="right">
        {{regime[0].description}}
      </div>
      <div class="regime-introducing-reason"(click)="download($event, regime[1][0]?.file?.uuid)">
        {{regime[1][0]?.file?.fileName}}
      </div>
    </div>

  </div>
</div>
