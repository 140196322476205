import { AfterViewInit, Component, ComponentFactoryResolver, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Settings2Service } from '@smart-city/core/services';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { IStepDialogDataOptions } from '../../models/interfaces';
import { ResponsePlanStepService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';
import { InformResponseOrgFormComponent } from '../inform-response-org-form/inform-response-org-form.component';
import { NewResponseOrgFormComponent } from '../new-response-org-form/new-response-org-form.component';
import { ResponseOrgFormComponent } from '../response-org-form/response-org-form.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/** Компонент для создания шага реагирования для блока реагирования по нажатию на кнопку +*/
@UntilDestroy()
@Component({
  selector: 'bg-create-response-step',
  templateUrl: './create-response-step.component.html',
  styleUrls: ['./create-response-step.component.scss'],
})
export class CreateResponseStepComponent extends BaseComponent implements AfterViewInit {
  /** Контейнер */
  @ViewChild('formContainer', { read: ViewContainerRef, static: false })
  public formContainer: ViewContainerRef;
  /** Тип шага реагирования */
  public stepType: IDictionaryInfo;
  /** Заголовок диалога */
  public title: string = '';

  constructor(
    private readonly responsePlanStepService: ResponsePlanStepService,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly settings: Settings2Service,
    public readonly dialogRef: MatDialogRef<IStepDialogDataOptions>,
    @Inject(MAT_DIALOG_DATA) public data: IStepDialogDataOptions,
  ) {
    super();
  }

  /** @ignore */
  public ngAfterViewInit(): void {
    const component = this.formContainer.createComponent<NewResponseOrgFormComponent>(
      this.componentFactoryResolver.resolveComponentFactory(NewResponseOrgFormComponent),
    ).instance;
    this.title = 'Новый шаг реагирования';
    if (component) {
      component.options = {
        title: 'Новый шаг реагирования',
        emergencyModel: this.data?.emergencyModel,
        // orgTypes: this.data?.orgTypes,
      };

      component.nextEvent.pipe(untilDestroyed(this)).subscribe(
        ({ stepType, informationStatementActionSysName}: {stepType: string, informationStatementActionSysName: string}) => {
          this.title = '';
          if (stepType) {
            this.stepType = this.settings.getDictionaryById(stepType);
            this.nextHandler(informationStatementActionSysName);
          }
        }
      );

      component.closeEvent.pipe(untilDestroyed(this)).subscribe(() => this.closeHandler());
    }
  }

  /** Обработчик для нажатия на кнопку Далее */
  public nextHandler(informationStatementActionSysName: string): void {
    this.formContainer.clear();
    this.title = 'Новый шаг реагирования';
    if (this.stepType.sysname === 'message') {
      this.showNewInformForm(informationStatementActionSysName);
    } else {
      this.showResponseStepForm();
    }
  }

  /** Метод закрытия диалога */
  public closeHandler(): void {
    this.dialogRef.close();
  }

  /** Метод который, подгружает форму в диалог для создания шага если тип шага выбран Донесение */
  public showNewInformForm(informationStatementActionSysName: string) {
    const component = this.formContainer.createComponent<InformResponseOrgFormComponent>(
      this.componentFactoryResolver.resolveComponentFactory(InformResponseOrgFormComponent),
    ).instance;
    component.options = {
      informationStatementActionSysName,
      entity: 'responseOrgOpts',
      emergencyModel: this.data.emergencyModel,
      stepType: this.stepType.id,
    };
    component.closeEvent.pipe(untilDestroyed(this)).subscribe(() => {
      this.closeHandler();
    });
    component.saveEvent.pipe(untilDestroyed(this)).subscribe(() => {
      this.closeHandler();
      this.formContainer.clear();
    });
  }

  /** Метод который, подгружает форму в диалог для создания шага если тип шага выбран Другой */
  private showResponseStepForm() {
    const component = this.formContainer.createComponent<ResponseOrgFormComponent>(
      this.componentFactoryResolver.resolveComponentFactory(ResponseOrgFormComponent),
    ).instance;
    component.data = {
      title: '',
      emergencyModel: this.data.emergencyModel,
      entity: 'involvedOrgOpts',
      stepType: this.stepType.id,
    };
    component.closeEvent.pipe(untilDestroyed(this)).subscribe(() => {
      this.closeHandler();
    });
    component.saveEvent.pipe(untilDestroyed(this)).subscribe(() => {
      this.closeHandler();
      this.formContainer.clear();
    });
  }
}
