<nz-drawer
  [nzVisible]="openDrawer"
  [nzWidth]="450"
  [nzOffsetX]="364"
  [nzClosable]="false"
  [nzMask]="false"
  [nzPlacement]="'left'"
>
  <ng-container *nzDrawerContent>
    <nz-tabset>
      <nz-tab nzTitle="Описание объекта">
        <div nz-row [nzGutter]="[12, 24]">
          <div nz-col nzSpan="2">
            <i nz-icon nzType="close" nzTheme="outline" (click)="close()" class="close-btn"></i>
          </div>
          <div nz-col nzSpan="2">
            <div class="icon-fire"></div>
          </div>
          <div nz-col nzSpan="20">
            <strong>{{model?.name}}</strong>
          </div>
          <div nz-col [nzSpan]="24">
            <div class="form-label">Адрес объекта из внешней системы</div>
            <div class="form-control-container">
              <div class="form-control">{{model?.externalAddress}}</div>
            </div>
          </div>
          <div nz-col [nzSpan]="24">
            <div class="form-label">Координаты</div>
            <div class="form-control-container">
              <div class="form-control" (click)="selectCoordinates()">
                <span class="form-control__coordinates">{{model?.coordinates}}</span>
              </div>
            </div>
          </div>
          <div nz-col [nzSpan]="24">
            <div class="form-label">Организация</div>
            <div class="form-control-container">
              <div class="form-control">{{getPropertyName('organizationId')}}</div>
            </div>
          </div>
          <div nz-col [nzSpan]="24">
            <nz-collapse>
              <nz-collapse-panel
                nzHeader="Связанные происшествия"
                class="related__emergencies"
              >
                <bg-emergency-mini-card
                  *ngFor="let emergency of relatedIncidents"
                  [emergency]="emergency">
                </bg-emergency-mini-card>
              </nz-collapse-panel>
              <nz-collapse-panel
                nzHeader="Связанные события"
                class="related__emergencies"
              >
                <bg-emergency-mini-card
                  *ngFor="let emergency of relatedEvents"
                  [emergency]="emergency">
                </bg-emergency-mini-card>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Источники оповещения">
        <bg-alert-source-info-mini-card
          *ngFor="let parentSource of alertSources"
          [parentSource]="parentSource"
        >
        </bg-alert-source-info-mini-card>
      </nz-tab>
    </nz-tabset>
  </ng-container>
</nz-drawer>

