import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { AppInjector } from '@bg-front/core/models/classes';
import { OperationsService } from '@bg-front/core/services';
import { IElementButton, INwHeaderBarOptions } from '@smart-city/core/common';
import { Settings2Service, SidebarContainerService } from '@smart-city/core/services';
import { IAdminMunicipalSchemaDto, IAnyObject } from 'smart-city-types';

import { IRegistryPanelDto, IRegistryPanelEntity, IRegistryPanelEntityFilter } from '../../models/interfaces';

/**
 * Базовый класс для компонент реестра
 */
@Component({
  template: '',
})
export abstract class BaseRegistryComponent extends BaseComponent implements OnInit {
  /** Данные реестра */
  public registry: IRegistryPanelDto = undefined;
  /**
   * Настройки заголовка
   */
  public headerOptions: INwHeaderBarOptions;

  /**
   * Количество загружаемых записей
   */
  public pageSize = 50;
  /** Ссылка на сервис */
  public readonly sidebarService: SidebarContainerService;
  public readonly settings: Settings2Service;
  /**
   * Максимальное количество страниц в скролле
   */
  protected maxPage = 5;

  protected constructor(
    public readonly route: ActivatedRoute,
    public readonly router: Router,
    private readonly operationsService: OperationsService,
  ) {
    super();
    const injector = AppInjector.getInjector();
    this.sidebarService = injector.get(SidebarContainerService);
    this.settings = injector.get(Settings2Service);
  }

  public get currentUser() {
    return this.settings.currentUser;
  }

  public ngOnInit(): void {
    this.headerOptions = {
      title: this.registry?.name,
      margin: 'collapse',

      buttons: [
        {
          type: 'button',
          options: {
            name: 'cancel',
            icon: 'chevron_left',
          },
        },
      ],
    };
  }

  /**
   * Возвращает объект с фильтрами
   * @param entityName
   */
  public getEntityFilters(entityName: string): IAnyObject[] {
    if (!(this.registry.entities && this.registry.entities.length)) {
      console.warn(
        `Внимание: данные реестра могут быть не корректны. Возвращен пустой массив фильтров\nПричина: не найдена сущность для реестра "${this.registry.name}"\nУбедитесь, что верно указаны настроки в таблице: АТ-Администрирования > Конструктор панели реестров`,
      );
      return [];
    }
    const entity = this.registry.entities.find((entity: IRegistryPanelEntity) => entity.sysName === entityName);
    return entity?.filters.map((filter: IRegistryPanelEntityFilter) => {
      return this.operationsService.createValueByOperation(filter.property, filter.value, filter.operation);
    });
  }

  public isAdmin(): boolean {
    const moId = this.settings.currentUser.organizationId?.mo ?? this.settings.currentUser.mo?.id;
    const userMo = this.settings.allMo.find((mo: IAdminMunicipalSchemaDto) => {
      return mo.id === moId;
    });

    if (!userMo) {
      return false;
    }
    return !userMo.municipal;
  }

  /**
   * Обработка событий из заголовка
   * @param $event событие
   */
  public clickHeaderButton($event: IElementButton): void {
    this.router.navigate([{ outlets: { leftSidebar: ['all'], editForm: null } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }

    /** Для отлова изменений */
    public trackByFunc(index: number, item: IAnyObject) {
      return item.id;
    }
}
