import { AbstractControl, ValidatorFn } from '@angular/forms';

export function coordinatesValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
      if (control.value.split(',')[0] && (control.value.split(',')[1])) {
        const lat = Number(control.value.split(',')[0]);
        const long = Number(control.value.split(',')[1]);
        const minLat = -90;
        const maxLat = 90;
        const minLong = -180;
        const maxLong = 180;
        if ((minLat <= lat && lat <= maxLat) && (minLong <= long && long <= maxLong)) {
          return null;
        }
        return { coordinates: { value: control.value } };
      }
    }
  };
}
