<div mat-dialog-content>
  <div class="bg-row">
    <div class="bg-col">
      <mat-tab-group class="sc-tab-group" [disableRipple]="true">
        <mat-tab [label]="'Фото ' + (idx + 1)" *ngFor="let imageUrl of imagesUrls; let idx = index">
          <img [src]="imageUrl | safe: 'url'" [alt]="'Изображение инцидента ' + (idx + 1)" class="image" />
        </mat-tab>
        <mat-tab [label]="'Видеоинтервал'">
          <span *ngIf="!sfsId; else player">{{videoStatus}}</span>
          <ng-template #player>
            <bg-hls-player
              [sourceType]="'sfs'"
              [sfsId]="sfsId"
            ></bg-hls-player>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
