import { Injectable } from '@angular/core';
import { RestService } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';
import { IAbstractServiceData } from 'smart-city-types/services';
import { IAdminResponseScripts } from '../../../../../../app-common/models/interfaces';
import { IResponseScript } from '../models/response-script.interface';

/**
 * Сервис для создания/редактирования сценариев реагирования
 */
@Injectable({
  providedIn: 'root',
})
export class ResponseScriptsService {
  /** @ignore */
  constructor(private readonly rest: RestService) {}

  /**
   * Проверка дубликата существования сценария реагирования с такими значениями
   * @param values - значения полей сценария реагирования для проверки
   */
  public checkDuplicate(values: IResponseScript): Observable<boolean> {
    const mo = values.mo || null;
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'ResponseScripts',
          query: {
            mo,
            incidentType: values.incidentType,
            orgTypeParam: values.orgTypeParam,
            emergencyType: values.emergencyType,
            $not: values.id ? { id: values.id } : undefined,
          },
        },
      })
      .pipe(map((result) => !!result.data.items.length));
  }

  /**
   * Функция создания/редактирования сценария реагирования
   * @param values - значения полей сценария реагирования
   */
  public editResponseScript(values: IResponseScript): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: values.id ? 'update' : 'insert',
      service: { name: 'Admin' },
      entity: {
        name: 'ResponseScripts',
        query: values.id ? { id: values.id } : undefined,
      },
      data: {
        incidentType: values.incidentType,
        ksipDetails: values.ksipDetails,
        organizationType: values.organizationType,
        emergencyType: values.emergencyType,
        orgTypeParam: values.orgTypeParam,
        mo: values.mo,
      },
    });
  }

  /**
   * Метод получения сценария реагирования
   * @param query - фильтр для запроса на получения сценария реагирования
   * @return
   */
  public selectResponseScripts(query: IAnyObject): Observable<IAdminResponseScripts> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query,
          name: 'ResponseScripts',
        },
      })
      .pipe(
        map((result: IAbstractServiceData) => {
          const items = result.data.items;
          if (!!items.length) {
            return items[0];
          }
          return null;
        }),
      );
  }
}
