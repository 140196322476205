import { Injectable } from '@angular/core';
import { RestService, SfsService } from '@smart-city/core/services';
import { Certificate, createAttachedSignature, getCertificate, getUserCertificates } from 'crypto-pro';
import { saveAs } from 'file-saver';
import { forkJoin, from, Observable, Observer, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';

import { IFileInfo } from '../../models/interfaces/file-info.interface';
import { ISignature } from '../../models/interfaces/signature.interface';

/** Сервис для получения списка загруженных файлов */
@Injectable({ providedIn: 'root' })
export class SignatureService {
  /** @ignore */
  constructor(private readonly rest: RestService, private readonly sfs: SfsService) {}

  /**
   * Создает присоединенную подпись сообщения по отпечатку сертификата
   * @param thumbprint - отпечаток сертификата
   * @param message - подписываемое сообщение
   */
  public createAttachedSignature(thumbprint: string, message: string | ArrayBuffer): Observable<string> {
    /** Данный метод возвращает Promise, для его преобразования в Observable используется метод from */
    return from(createAttachedSignature(thumbprint, message));
  }

  /**
   * Получение доступных сертификатов ЭЦП. Возвращает список сертификатов, доступных пользователю в системе
   */
  public getUserCertificates(): Observable<Certificate[]> {
    /** Данный метод возвращает Promise, для его преобразования в Observable используется метод from */
    return from(getUserCertificates());
  }

  /**
   * Возвращает сертификат по отпечатку
   * @param certificate - отпечаток сертификата
   */
  public getCertificate(certificate: string): Observable<Certificate> {
    /** Данный метод возвращает Promise, для его преобразования в Observable используется метод from */
    return from(getCertificate(certificate));
  }

  /**
   * Получение полей из сертификата
   * @param fieldValues - значение полей сертификата
   * @param fieldName - наименование поля
   */
  public getCertificateField(fieldValues: string, fieldName: string): string {
    const fieldNameLength = fieldName.length;
    const startIndex = fieldValues.indexOf(fieldName);
    if (startIndex === -1) return '';
    const finishIndex =
      fieldValues.indexOf(',', startIndex + fieldNameLength) !== -1
        ? fieldValues.indexOf(',', startIndex + fieldNameLength)
        : undefined;
    return fieldValues.substring(startIndex + fieldNameLength, finishIndex);
  }

  /**
   * Получение информации о подписи по ID подписанной сущности
   * @param entityId - ID подписанной сущности
   */
  public getSignatureByEntityId(entityId: string): Observable<ISignature> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Edi' },
        entity: { name: 'Signatures', query: { entityId } },
        data: { sort: { field: 'creationTime', direction: 'desc' } },
      })
      .pipe(map((signatures: IAbstractServiceData) => signatures.data?.items[0]));
  }

  /**
   * Подписание файла
   * @param fileInfo - информация о файле
   */
  public signFile(fileInfo: IFileInfo): Observable<string> {
    // Файл выбран с жесткого диска
    if (fileInfo.file instanceof File) {
      const file = fileInfo.file;
      const fileReader = new FileReader();
      if (!file) return of('');
      if (typeof fileReader.readAsArrayBuffer !== 'function') return of('');

      fileReader.readAsArrayBuffer(file);
      return new Observable((observer: Observer<ArrayBuffer>) => {
        fileReader.onload = (fileReaderEvent: ProgressEvent<FileReader>) => {
          observer.next(<ArrayBuffer>fileReaderEvent.target.result);
          observer.complete();
        };
      }).pipe(
        switchMap((arrayBuffer: ArrayBuffer) =>
          this.createAttachedSignature(fileInfo.signature.certificate, arrayBuffer),
        ),
      );
      // Файл выбран из хранилища SFS
    } else if ((<IAnyObject>fileInfo.file).uuid) {
      return this.sfs
        .getFileContents((<IAnyObject>fileInfo.file).uuid)
        .pipe(
          switchMap((arrayBuffer: ArrayBuffer | Blob) =>
            this.createAttachedSignature(fileInfo.signature.certificate, <ArrayBuffer>arrayBuffer),
          ),
        );
    }
    return of('');
  }

  /**
   * TODO: Переработать. Сменить название. Сохранять в месте вызова.
   * Подписание отчета
   * @param report - содержимое отчета
   * @param certificateUid - уникальный номер сертификата
   */
  public signReport(report: ArrayBuffer, certificateUid: string) {
    return forkJoin([
      this.createAttachedSignature(certificateUid, report),
      this.getCertificate(certificateUid),
    ]).subscribe(([signature, certificate]: [string, Certificate]) => {
      const byteCharacters = atob(signature);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const blob: Blob = new Blob([new Uint8Array(byteNumbers)], {
        type: 'application/octet-stream',
      });
      const ownerFio = this.getCertificateField(certificate.subjectName, 'CN=');
      saveAs(blob, `Подпись_${ownerFio}.sig`);
    });
  }
}
