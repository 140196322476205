import { Pipe, PipeTransform } from '@angular/core';
import { Settings2Service } from '@smart-city/core/services';

/**
 * Пайп для отображения тултипа в селекте для организаций
 */
@Pipe({
  name: 'dictionary',
})
export class DictionaryPipe implements PipeTransform {
  constructor(private settings: Settings2Service) {}

  /**
   *@param id - Id справочника
   *@return string
   */
  transform(id: string): string {
    if (id) {
      return this.settings.getDictionaryById(id)?.name;
    }

    return id;
  }
}
