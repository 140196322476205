import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { IPolygonDto, IMunicipality } from '@bg-front/core/models/interfaces';
import { BgMapService, MultiFileService } from '@bg-front/core/services';
import { IForestryFacilityDto } from '@bg-front/forestry-facilities/models/interfaces';
import { ForestryFacilitiesService } from '@bg-front/forestry-facilities/services';
import { ISignificantObjectData } from '@bg-front/significant-objects/models';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { IElementButton, INwHeaderBarOptions } from '@smart-city/core/common';
import { IBaseDictionaryData } from '@smart-city/core/interfaces';
import { ScNavService, Settings2Service, SfsService } from '@smart-city/core/services';
import * as dayjs from 'dayjs';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { IAnyObject, IEmergencyDto, IFileInfoDto, ISfsFilesDto } from 'smart-city-types';
import { MapBaseModel, MapBaseService } from '@smart-city/maps/sc';
import { IBgRegimeDto } from '../../../../bg/modules/consolidated-registries/modules/regimes/models/bg-regime-dto.interface';
import { IDistrict } from '../../../../bg/modules/dictionaries/modules/districts/models/interfaces';
import { MunicipalitiesService } from '../../../../bg/modules/dictionaries/modules/municipalities/services/municipalities.service';
import { DistrictsService } from '../../../../bg/modules/dictionaries/modules/districts/services';
import { IOrganization } from '../../../models/interfaces';
import { OrganizationsService, PolygonsService, RegimesService } from '../../../services';
import { RegimesEmergenciesDialogComponent } from '../regimes-emergencies-dialog/regimes-emergencies-dialog.component';
import { LayersEnum } from '@bg-front/core/models/enums';

/**
 * Компонента отображающая данные о режиме функционирования
 */
@Component({
  selector: 'bg-regime-info',
  templateUrl: './regime-info.component.html',
  styleUrls: ['./regime-info.component.scss'],
})
export class RegimeInfoComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Центр для позиционирования на карте */
  private center: string = undefined;

  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions;

  /** Данные режиме функционирования */
  public regime: IBgRegimeDto = undefined;

  /** UUID файла с информацией о введении режима функционирования */
  public introducingReasonFile: ISfsFilesDto = undefined;

  /** Список важных объектов которые попадают в полигоны режима */
  public significantObjects: ISignificantObjectData[] = undefined;

  /** Связанные инциденты */
  public linkedEmergencies: IAnyObject[] = undefined;

  /** Координаты полигонов МО */
  private municipalitiesPolygons: IPolygonDto[] = undefined;

  /** Координаты полигонов районов */
  private districtsPolygons: IPolygonDto[] = undefined;

  /** Координаты полигоноа Лесохозяйственного объекта */
  private forestryFacilityPolygon: IPolygonDto = undefined;

  /** Видимость кнопки Показать на карте */
  public isShowOnMapButton: boolean = false;

  /** Уровни реагирования */
  public responseLevel: IAnyObject;

  /** Координаты объекта или организации */
  public coordinates: string;

  /** Зона действия */
  public coverageArea: string;

  /** Маркер, показывает можно ли открыть форму просмотра для сущности*/
  public isFormInfo: boolean = false;

  /** Геттер для типа режима функционирования */
  public get type(): string {
    return (<IBaseDictionaryData>this.regime.type)?.name;
  }

  /** Геттер времени введения режима */
  public get introducingTime(): string {
    return dayjs(this.regime.introducingTime).format('DD.MM.YYYY HH:MM');
  }

  /** Модель карты */
  private mapModel: MapBaseModel;

  /** @ignore */
  constructor(
    private readonly gisService: BgMapService,
    private readonly regimeService: RegimesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly scNavService: ScNavService,
    private readonly multiFileService: MultiFileService,
    private readonly regimesService: RegimesService,
    private readonly sfs: SfsService,
    private readonly municipalitiesService: MunicipalitiesService,
    private readonly districtsService: DistrictsService,
    private readonly polygonsService: PolygonsService,
    private readonly significantObjectService: SignificantObjectsService,
    private readonly organizationsService: OrganizationsService,
    private readonly dialog: MatDialog,
    private readonly settings2: Settings2Service,
    private readonly forestryFacilitiesService: ForestryFacilitiesService,
    private readonly mapService: MapBaseService,
  ) {
    super();
  }

  /** ID режима функционирования */
  @Input()
  public set regimeId(val: string) {
    if (val) {
      this.regime = undefined;
      this.regimeService
        .getRegimeForInformationCard(val)
        .pipe(
          switchMap((regime: IBgRegimeDto) => {
            this.regime = regime;

            if (regime.districtIds || regime.municipalityIds || regime.forestryFacilitiesId) {
              this.isShowOnMapButton = true;
            }

            this.responseLevel = this.settings2.getDictObjsIdsByType('responseLevels')[this.regime?.responseLevelsId];

            return <
              Observable<
                [
                  IEmergencyDto[],
                  IFileInfoDto[],
                  IMunicipality[],
                  IDistrict[],
                  ISignificantObjectData,
                  IForestryFacilityDto,
                  IOrganization,
                ]
              >
            >forkJoin([
              this.regimesService.getEmergenciesByRegimeId(regime.id),
              this.multiFileService.getFilesFromSfs(this.regime.introducingReason),
              this.municipalitiesService.getMunicipalityById(this.regime.municipalityIds),
              this.districtsService.getDistrictById(this.regime.districtIds),
              this.significantObjectService.getSignificantObjectById(this.regime.significantObjectId),
              this.forestryFacilitiesService.getById(this.regime.forestryFacilitiesId),
              this.organizationsService.getOrganizationBy(this.regime.organizationId),
            ]);
          }),
          switchMap(
            ([
              emergencies,
              [introducingReason],
              municipalities,
              districts,
              significantObject,
              forestryFacility,
              organization,
            ]: [
              IEmergencyDto[],
              IFileInfoDto[],
              IMunicipality[],
              IDistrict[],
              ISignificantObjectData,
              IForestryFacilityDto,
              IOrganization,
            ]) => {
              this.linkedEmergencies = emergencies.map((emergency: IAnyObject) => {
                emergency.timeCreate = dayjs(emergency.timeCreate).format('DD.MM.YYYY HH:mm');
                return emergency;
              });
              this.introducingReasonFile = <ISfsFilesDto>introducingReason?.file;

              if (districts) this.coverageArea = districts.map((district: IDistrict) => district?.name).join();
              if (municipalities && !districts) {
                this.coverageArea = municipalities.map((obj: IMunicipality) => obj?.name).join();
              }
              if (significantObject) {
                this.coverageArea = significantObject.shortName;
                this.coordinates = significantObject.coordinates;
                this.isFormInfo = true;
              }
              if (forestryFacility) this.coverageArea = forestryFacility.name;
              if (organization) {
                this.isFormInfo = true;
                this.coverageArea = organization.name;
                this.coordinates = organization.coordinates;
              }

              return <Observable<[IPolygonDto[], IPolygonDto[], IPolygonDto]>>forkJoin([
                this.polygonsService.getByIds([
                  // Фильтрация МО. Если для МО были определены районы, то учитывать только районы без МО,
                  // если районы не определены то рассмотреть весь МО
                  ...(municipalities || [])
                    .filter(
                      (municipality: IMunicipality) =>
                        !(districts || []).map((district: IDistrict) => district.municipalId).includes(municipality.id),
                    )
                    .map((municipality: IMunicipality) => municipality.polygonId),
                ]),
                this.polygonsService.getByIds([...(districts || []).map((district: IDistrict) => district.polygonId)]),
                forestryFacility ? this.polygonsService.getById(forestryFacility?.polygonId as string) : of(null),
              ]);

              //
            },
          ),
          switchMap(
            ([municipalitiesPolygons, districtsPolygons, forestryFacilityPolygon]: [
              IPolygonDto[],
              IPolygonDto[],
              IPolygonDto,
            ]) => {
              this.center = this.gisService.getCenter(
                [...municipalitiesPolygons, ...districtsPolygons, forestryFacilityPolygon]
                  .map((polygon: IPolygonDto) => polygon?.coordinates)
                  .reduce((acc: Coordinates[], val: Coordinates[]) => acc.concat(val || []), []),
              );
              this.municipalitiesPolygons = municipalitiesPolygons;
              this.districtsPolygons = districtsPolygons;
              this.forestryFacilityPolygon = forestryFacilityPolygon;

              const polygons: IPolygonDto[] = [...this.municipalitiesPolygons, ...this.districtsPolygons];

              if (this.forestryFacilityPolygon) polygons.push(this.forestryFacilityPolygon);

              // forkJoin на будущее для запроса камер, КСЭОН и т.п.
              return forkJoin([this.significantObjectService.getSignificantObjectsForRegime(polygons)]);
            },
          ),
          takeUntil(this.ngUnsubscribe),
          catchError((err: Error) => {
            return this.catchErrorFn<[ISignificantObjectData[]]>(
              err,
              'Ошибка получения информации о режиме функционирования',
              [null],
            );
          }),
        )
        .subscribe(([significantObjects]: [ISignificantObjectData[]]) => {
          this.significantObjects = significantObjects;
        });
    }
  }

  /**
   * @ignore
   */
  public ngOnInit(): void {
    this.mapModel = new MapBaseModel('baseMapWorkspace', this.mapService);

    this.headerOptions = {
      margin: 'collapse',
      title: ' ',
      buttons: [
        {
          type: 'button',
          options: {
            name: 'burger',
            icon: 'menu',
            callback: () => {
              this.scNavService.openMenu();
              return of({ actionStream: 'donothing' });
            },
          },
        },
        {
          type: 'button',
          options: {
            name: 'close',
            icon: 'close',
            position: 'suffix',
            callback: () => {
              this.mapModel.removeLayer(LayersEnum.clickMarker);

              this.router.navigate(
                [
                  {
                    outlets: { leftPopup: null },
                  },
                ],
                {
                  relativeTo: this.route.parent,
                  queryParamsHandling: 'merge',
                },
              );
              return of();
            },
          },
        },
      ],
    };

    /** Подписываемся на параметры */
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params: Params) => {
      if (params['id']) {
        return (this.regimeId = params['id']);
      }
    });
  }

  /** Открытие формы просмотра объекта/организации при клике на названии в блоке "Зона действия"*/
  public onClickCoverageArea($event): void {
    if (!$event.defaultPrevented) {
      if (this.regime.significantObjectId) {
        this.router.navigate(
          ['significantObject', this.regime.significantObjectId],
          {
            relativeTo: this.route.parent,
            queryParamsHandling: 'merge',
          },
        );
      }
      if (this.regime.organizationId) {
        this.router.navigate([{ outlets: { leftPopup: ['eos', this.regime.organizationId] } }], {
          relativeTo: this.route.parent,
          queryParamsHandling: 'merge',
        });
      }
    }
  }

  /**
   * Обработка событий из заголовка
   * @param $event событие
   */
  public clickHeaderButton($event: IElementButton): void {
    if ($event.options.name === 'close') {
      this.gisService.drawMunicipalityPolygon();
      this.gisService.drawDistrictPolygon();
      this.gisService.drawPolygon();
    }
    ($event.options.callback as any)();
  }

  /**
   * Метод перехода/центрирования по координатам
   */
  public selectCoordinates(): void {
    this.gisService.setPositionMapOnCoordinates(this.coordinates);
  }

  /**
   * Скачивание файла
   * @param uuid - UUID файла в хранилище
   */
  public download(uuid: string): void {
    this.sfs.directDownload(uuid);
  }

  /**
   * Метод перехода/центрирования по координатам
   */
  public showOnMap() {
    this.municipalitiesPolygons?.forEach((polygon: IPolygonDto) => this.gisService.drawMunicipalityPolygon(polygon));
    this.districtsPolygons?.forEach((polygon: IPolygonDto) => this.gisService.drawDistrictPolygon(polygon));
    this.gisService.drawPolygon(this.forestryFacilityPolygon?.coordinates);

    this.gisService.setPositionMapOnCoordinates(this.center);
  }

  /**
   * Открыть диалоговое окно со связанными инцидентами
   * @param emergencies - список связанных инцидентов
   */
  public showLinkedEmergencies(emergencies: IAnyObject[]): void {
    this.dialog.open(RegimesEmergenciesDialogComponent, {
      disableClose: false,
      width: '914px',
      height: '80vh',
      data: { emergencies: emergencies },
    });
  }
}
