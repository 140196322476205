import { AfterViewInit, Component, forwardRef, HostBinding, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { BaseComponent } from '@bg-front/core/components';
import { MAX_DEEPS_LEVEL } from '@bg-front/core/models/constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Settings2Service } from '@smart-city/core/services';
import { debounceTime } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';

import { IAttributeFilterValue, IRegistryPanelEntity, IRegistryPanelEntityFilter } from '../../models/interfaces';

/**
 * Компонента отвечает за редактирование "Сущности" реестра
 */
@UntilDestroy()
@Component({
  selector: 'bg-registry-panel-entity',
  templateUrl: './registry-panel-entity.component.html',
  styleUrls: ['./registry-panel-entity.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegistryPanelEntityComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RegistryPanelEntityComponent),
      multi: true,
    },
  ],
})
export class RegistryPanelEntityComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, ControlValueAccessor, Validator
{
  /** Счётчик для генерации id компоненты */
  public static nextId = 0;

  /** Установка Id */
  @HostBinding() id = `registry-panel-entity-${RegistryPanelEntityComponent.nextId}`;

  /** Настройки компоненты Сервис */
  public serviceDict = this.settings.getAppServicesForSelect();

  /** Настройки компоненты Сущность */
  public entityDict: IAnyObject[];

  /** Аттрибуты */
  public attributes: IAttributeFilterValue[] = [];

  /**
   * Валидаторы поля entity без/с required. Это нужно, чтобы убирать только required
   * при отсутствии значения поля service, чтобы не показывать, что активный контрол
   * нуждается в значении. И при этом сохранять другие валидаторы (на будущее).
   */
  public entityValidatorsWithoutRequired = [];
  public entityValidators = this.entityValidatorsWithoutRequired.concat([Validators.required]);

  /** Форма */
  public entityForm: FormGroup = new FormGroup({
    service: new FormControl('', [Validators.required]),
    entity: new FormControl('', this.entityValidators),
    sysName: new FormControl('', [Validators.required]),
    filters: this.fb.array([]),
  });

  /** Ссылка на группу фильтров */
  public filtersArray: FormArray;

  /** Флаг готовности компоненты */
  private isReady = false;

  /** Флаг отображения грида фильтрации */
  public hideFilter = false;

  /** @ignore */
  constructor(private settings: Settings2Service, private fb: FormBuilder) {
    super();
    RegistryPanelEntityComponent.nextId++;
  }

  /** @ignore */
  public ngOnInit(): void {
    this.filtersArray = this.entityForm.controls.filters as FormArray;
    this.entityForm.controls.service.valueChanges.pipe(untilDestroyed(this)).subscribe((val: string) => {
      /**
       * Сбрасываем сущность, при смене сервиса, так в наборе
       * селекта будет другой набор сущностей
       */
      this.entityForm.controls.entity.setValue(null, { emitEvent: false, onlySelf: true });

      /**
       * Убираем требование значения у entity, если service не выбран
       */
      if (!val) {
        this.entityForm.controls.entity.setValidators(this.entityValidatorsWithoutRequired);
      } else {
        this.entityForm.controls.entity.setValidators(this.entityValidators);
      }
      this.entityForm.controls.entity.updateValueAndValidity();

      this.entityDict = this.settings.getEntitiesByServiceForSelect(val);

      this.filtersArray.clear();
    });

    this.entityForm.controls.entity.valueChanges.pipe(untilDestroyed(this)).subscribe((val: string) => {
      this.filtersArray.clear();
      /** Проверяем существование сущности при её сбросе, при смене сервиса */
      if (val) {
        this.getAttributesFromLink(this.settings.getEntityAttrs(this.entityForm.controls.service.value, val));
      }
      this.addFilter();
    });
  }

  /** Добавляем новую сущность */
  public addFilter() {
    this.filtersArray.push(new FormControl(undefined, [Validators.required]));
    this.hideFilter = true;
  }

  /** Удаляем сущность */
  public removeFilter(idx: number) {
    this.filtersArray.removeAt(idx);
  }

  /** @ignore */
  public registerOnChange(fn: any): void {
    this.entityForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(fn);
  }

  /** @ignore */
  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /** @ignore */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public setDisabledState(isDisabled: boolean): void {}

  /** @ignore */
  public writeValue(val: IRegistryPanelEntity): void {
    if (val) {
      this.entityForm.controls.service.setValue(val.service, { emitEvent: !!val.service });
      this.entityForm.controls.entity.setValue(val.entity, { emitEvent: false });
      this.entityForm.controls.sysName.setValue(val.sysName, { emitEvent: false });
      if (val.service && val.entity) {
        this.getAttributesFromLink(this.settings.getEntityAttrs(val.service, val.entity));
      }
      (val.filters || [undefined]).forEach((item: IRegistryPanelEntityFilter) => {
        this.filtersArray.push(new FormControl(item, [Validators.required]));
      });
    }
  }

  /** Валидатор формы, для проброса состояния это формы в родительскую*/
  public validate(c: AbstractControl): ValidationErrors | null {
    if (this.isReady) {
      return this.entityForm.valid
        ? null
        : {
            registryEntity: {
              valid: false,
              message: 'Сущность имеет не валидное значение',
            },
          };
    }

    return null;
  }

  /** Подписка на нажатия */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private propagateTouch = () => { return; };

  /** Формируем список аттрибутов */
  private getAttributesFromLink(
    attributes: IAnyObject[],
    parentText?: string,
    parentId?: string,
    level: number = 0,
  ): void {
    attributes?.forEach((attr: IAnyObject) => {
      if (attr.name === 'id' && level > 0) {
        return;
      }
      const item: IAttributeFilterValue = Object.assign(<IAttributeFilterValue>{}, attr);
      const itemText = attr.title ? attr.title : attr.name;
      /**
       * Сюда пишем читаемый текст
       */
      item.text = parentText ? `${parentText}.${itemText}` : itemText;
      /**
       * Сюда пишем собранное имя фильтра
       */
      item.id = parentId ? `${parentId}.${attr.name}` : attr.name;
      if (attr.link) {
        if (level < MAX_DEEPS_LEVEL) {
          this.getAttributesFromLink(
            this.settings.getEntityAttrs(attr.link.service, attr.link.entity),
            item.text,
            item.id,
            level + 1,
          );
        }
      } else {
        this.attributes.push(item);
      }
    });
  }

  /** @ignore */
  public ngAfterViewInit(): void {
    this.isReady = true;
  }
}
