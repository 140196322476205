import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { BaseComponent, MapDialogComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { GrammarHelper } from '@bg-front/core/models/helpers';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IScCheckboxOptions, IScSelectOptions, IScTextButtonOptions } from '@smart-city/core/common';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IAbstractServiceData, IEmergencyDto } from 'smart-city-types';

import { IEventMainInfo, IEventViewFormDataDto, IMainViewEventFormDto, IThermopoint } from '../../models/interfaces';
import { EmergencyService, AtmIntegrationService } from '../../services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';

@UntilDestroy()
@Component({
  selector: 'bg-main-view-event-form',
  templateUrl: './main-view-event-form.component.html',
  styleUrls: ['./main-view-event-form.component.scss'],
})
export class MainViewEventFormComponent extends BaseComponent implements OnInit {
  @Input()
  public model: IMainViewEventFormDto;
  @Input()
  public modelCommon: IEventViewFormDataDto;
  /** Термоточка */
  @Input()
  public thermoPoint?: IThermopoint;
  /** Ссылка на открытие термоточки в новой вкладке */
  public thermoPointInnerLink: string;
  /**
   * Настройка компоненты Срочность
   */
  public optionsUrgently: IScCheckboxOptions = {
    title: 'Срочность',
    disabled: true,
  };
  /**
   * Настройка компоненты Угроза населению
   */
  public optionsThreatPopulation: IScCheckboxOptions = {
    title: 'Угроза населению',
    disabled: true,
  };
  /**
   * Настройка компоненты Угроза организации
   */
  public optionsThreatOrganization: IScCheckboxOptions = {
    title: 'Угроза организации',
    disabled: true,
  };

  /** Конфигурация для кнопки уточнить адрес */
  public optionsSpecifyAddressOnMap: IScTextButtonOptions = {
    title: 'Уточнить адрес на карте',
    color: 'primary',
  };

  /** Объект ЖКХ */
  public jkhObjectOptions: IScSelectOptions = {
    title: 'Объект ЖКХ',
    clearable: true,
    modern: true,
    service: 'AtmIntegration',
    entity: 'MonitoringObject',
    fieldName: 'name',
    disabled: true,
  };

  public linkToJkhObjectVisible: boolean;
  public monitoringObjectId: string;
  public isVisibleAtmLink$: Observable<boolean> = of(false);

  /** Настройки чекбокса переключения на адрес по координатам */
  public byCoordinatesOptions: IScCheckboxOptions = {
    title: 'Адрес задан координатами',
    disabled: true,
  };

  /**
   * В радиусе 50 метров есть важные объекты
   */
  public hasDangerObject: boolean;
  /**
   * Количество важных объектов рядом с инцидентом
   */
  public countOfCloseImportantObjects: number;
  /**
   * Сообщение о важных объектах рядом с инцидентом
   */
  public closeImportantObjectsMessage: string;

  /** Оснвная информация о событии */
  public eventInfo: IEventMainInfo = null;
  /** id */
  private id: string;

  /** Видимость чекбокса Срочно */
  public urgentlyVisible: boolean = true;
  /** Видимость чекбоксов Угроза населению и Угроза организациям */
  public threatsVisible: boolean = true;
  /** Флаг для формы "Плановые работы" */
  public isPlanWorkForm: boolean = false;
  /** Флаг для формы "Плановые работы" */
  public isAccidentForm: boolean = false;
  /** Видимость МО */
  public moIdVisible: boolean = true;
  /** Видимость Район */
  public districtIdVisible: boolean = true;

  constructor(
    private readonly dialog: MatDialog,
    private readonly significantObjectService: SignificantObjectsService,
    private readonly route: ActivatedRoute,
    private readonly emergencyService: EmergencyService,
    private readonly settings2: Settings2Service,
    private readonly access: AccessService,
    private readonly atmIntegrationService: AtmIntegrationService,
    private readonly ksipTypesQuery: KsipTypesQuery,
    private readonly ksipDetailsQuery: KsipDetailsQuery,
  ) {
    super();
  }

  /**
   * Настройка параметров отображения информации о важных объектах рядом с инцидентом
   * @param coordinates - Объект координат инцидента
   */
  private setCloseImportantObjectsSettings(coordinates: Coordinates) {
    if (!coordinates?.isValid()) {
      this.countOfCloseImportantObjects = 0;
      this.closeImportantObjectsMessage = 'Важные объекты не найдены';
      return;
    }

    forkJoin([
      this.significantObjectService.getCloseObject(coordinates, 500),
      this.significantObjectService.getCloseObject(coordinates, 50),
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: IAbstractServiceData[]) => {
        this.countOfCloseImportantObjects = +result[0].data.totalCount;
        if (!!this.countOfCloseImportantObjects) {
          this.closeImportantObjectsMessage = `${GrammarHelper.endings(this.countOfCloseImportantObjects, [
            'Найден',
            'Найдено',
            'Найдено',
          ])} ${this.countOfCloseImportantObjects} ${GrammarHelper.endings(this.countOfCloseImportantObjects, [
            'важный',
            'важных',
            'важных',
          ])} ${GrammarHelper.endings(this.countOfCloseImportantObjects, ['объект', 'объекта', 'объектов'])}`;
        } else {
          this.closeImportantObjectsMessage = 'Важные объекты не найдены';
        }
        this.hasDangerObject = !!+result[1].data.totalCount;
      });
  }

  /** Обработчик клика по кнопку Уточнить адрес
   * @param $event
   */
  public onClickSpecifyAddress($event) {
    const data = {
      coordinates: new Coordinates(this.model.exactCoordinates.lat, this.model.exactCoordinates.lon),
      disabled: true,
    };
    this.openSpecifyMap(data);
  }

  /** Метод который обеспечивает открытие диалога и подписку на закрытие
   * @param data - параметры для диалога
   */
  public openSpecifyMap(data: { coordinates?: Coordinates; disabled?: boolean }): void {
    const dialogRef = this.dialog.open(MapDialogComponent, {
      data,
      width: '600px',
    });

    let dialogSub: Subscription = dialogRef
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        if (dialogSub) {
          dialogSub.unsubscribe();
          dialogSub = null;
        }
      });
  }

  public ngOnInit(): void {
    this.thermoPointInnerLink = `${ window.origin }/cuks/workspace/(thermopoint/${ this.thermoPoint?.id }//leftSidebar:all)`;
    this.emergencyService
      .getIncidentById(this.modelCommon.incidentId['id'])
      .pipe(untilDestroyed(this))
      .subscribe((item: IEmergencyDto) => {
        const ksip = this.ksipTypesQuery.getById(item.incidentTypeId);
        this.isPlanWorkForm =
          this.settings2.getDictionaryById(ksip.eventTypeId)?.sysname === '003' ||
          this.settings2.getDictionaryById(ksip.eventTypeId)?.sysname === '001';
        if (ksip.ksipDetails) {
          const detail = this.ksipDetailsQuery.getById(ksip.ksipDetails[0]);
          this.isAccidentForm = this.settings2.getDictionaryById(detail.eventType)?.sysname === '001';
        }
      });
    // Видимость чекбокса Срочно
    this.urgentlyVisible = this.access.accessMap['UrgentlyAvailable']?.visible;
    // Видимость чекбоксов Угроза населению и Угроза организациям
    this.threatsVisible = this.access.accessMap['ThreatsAvailable']?.visible;
    // Видимость МО
    this.moIdVisible = this.access.accessMap['EventEmergencyMoVisible']?.visible;
    // Видимость Район
    this.districtIdVisible = this.access.accessMap['EventEmergencyDistrictVisible']?.visible;

    this.setCloseImportantObjectsSettings(this.model?.coordinates);

    this.route.params
      .pipe(
        switchMap((params: Params) => {
          if (params.id) {
            this.id = params.id;
            return this.emergencyService.getEventMainById(params.id);
          }
          return of(undefined);
        }),
        catchError((err: Error) => {
          return this.catchErrorFn<IEventMainInfo>(err, 'Ошибка при загрузке инцидента');
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((info: IEventMainInfo) => {
        this.eventInfo = info;
        this.setViewedField();
      });
    this.showLinkToJkhObjectMonitoring(Boolean(this.model.jkhObject));
    this.setMonitoringObjectId(this.model.jkhObject);
    this.setAddressObservableForLink();
  }

  /**
   * Устанавливает поле  "Просмотренно"
   * @return
   */
  private setViewedField(): void {
    if (!this.model.viewed && this.settings2.currentUser.organizationId?.id === this.model.organizationId) {
      this.emergencyService.updateViewedField(this.id, 'Events').pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    }
  }

  private setAddressObservableForLink() {
    if (this.linkToJkhObjectVisible) {
      this.isVisibleAtmLink$ = this.atmIntegrationService.isExistBoundAddresses(this.model.jkhObject).pipe(
        tap((isExistBoundAddresses: boolean) => {
          if (isExistBoundAddresses) localStorage.setItem('isAddressBlockShow', 'true');
        }),
      );
    }
  }

  /** Формирую ссылку для перехода на объект монитоинга
   * @return
   */
  private setMonitoringObjectId(monitoringObjectId: string): void {
    this.monitoringObjectId = monitoringObjectId;
  }

  /** Показывает ссылку на объект монитроинга */
  private showLinkToJkhObjectMonitoring(value: boolean): void {
    this.linkToJkhObjectVisible = value;
  }
}
