import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { INwHeaderBarOptions, IScSelectOptions } from '@smart-city/core/common';
import { takeUntil } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';
import { Settings2Service } from '@smart-city/core/services';
import { BaseComponent } from '@bg-front/core/components';
import { IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { FilterOperationEnum } from '@bg-front/core/models/enums';

/**
 * Компонент фильтрации важных объектов на карте
 *  @example
 *  <map-significant-object-filter
 *    [mapLayerFilters]="data.mapLayersFilters['Важные объекты']"
 *    (changedFilters)="onFiltersChange($event)"
 *  ></map-significant-object-filter>
 */
@Component({
  selector: 'map-significant-object-filter',
  templateUrl: './map-significant-object-filter.component.html',
  styleUrls: ['./map-significant-object-filter.component.scss'],
})
export class MapSignificantObjectFilterComponent extends BaseComponent implements OnInit, OnChanges {
  /** Значения фильтров */
  @Input()
  public mapLayerFilters: IMapLayerEntityFilter[] = [];

  /** Оповещение о изменении значений фильтров */
  @Output()
  public changedFilters: EventEmitter<IMapLayerEntityFilter[] | 'delete'> = new EventEmitter<
    IMapLayerEntityFilter[] | 'delete'
  >();

  /** Форма */
  public form: FormGroup;

  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions = {
    margin: 'collapse',
    title: 'Важные объекты',
    buttons: [
      {
        type: 'button',
        options: {
          name: 'delete',
          icon: 'delete_outline',
        },
      },
    ],
  };

  /** Настройка компоненты Тип */
  public typeOptions: IScSelectOptions = {
    title: 'Тип',
    clearable: true,
    data: this.settings.getDictForSelect('significantObjectTypes'),
  };

  /** Настройка компоненты Наименование */
  public nameOptions: IScSelectOptions = {
    title: 'Наименование',
    clearable: true,
    service: 'Admin',
    entity: 'SignificantObjects',
    modern: true,
    fieldName: 'name',
  };

  /** Настройка компоненты Состояние */
  public stateOptions: IScSelectOptions = {
    title: 'Состояние',
    clearable: true,
    data: this.settings.getDictForSelect('significantObjectStatus'),
  };

  /** @ignore */
  constructor(private settings: Settings2Service) {
    super();
  }

  /** @ignore */
  ngOnInit(): void {
    this.form = new FormGroup({
      type: new FormControl(this.mapLayerFilters.find((item) => item.property === 'type')?.value),
      name: new FormControl(this.mapLayerFilters.find((item) => item.property === 'id')?.value),
      state: new FormControl(this.mapLayerFilters.find((item) => item.property === 'state')?.value),
    });

    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => this.changedFilters.emit(this.getFilters(value)));
  }

  /** @ignore */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue.length === 0) {
      this.form?.reset({}, { emitEvent: false });
    }
  }

  /**
   * Формирование фильтров на основании значения формы
   * @param value значение формы
   */
  getFilters(value: IAnyObject): IMapLayerEntityFilter[] {
    const result: IMapLayerEntityFilter[] = [];
    if (value.type) {
      result.push({
        property: 'type',
        value: value.type,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.name) {
      result.push({
        property: 'id',
        value: value.name,
        operation: FilterOperationEnum.equal,
      });
    }
    if (value.state) {
      result.push({
        property: 'state',
        value: value.state,
        operation: FilterOperationEnum.equal,
      });
    }
    return result;
  }

  public delete() {
    this.changedFilters.emit('delete');
    this.form.reset();
    this.form.enable();
  }
}
