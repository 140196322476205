import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IScCheckboxOptions, IScInputOptions } from '@smart-city/core/common';
import { NotificationObjectsBaseViewFormComponent } from '../notification-objects-base-view-form/notification-objects-base-view-form.component';

@Component({
  selector: 'bg-notification-objects-atm-view-form',
  templateUrl: './notification-objects-atm-view-form.component.html',
  styleUrls: ['./notification-objects-atm-view-form.component.scss'],
})
export class NotificationObjectsAtmViewFormComponent
  extends NotificationObjectsBaseViewFormComponent
  implements OnInit {
  //#region Настройки компонент
  /** Настройки компоненты Активность */
  public activeOptions: IScCheckboxOptions = {
    title: 'Активность',
    disabled: true,
  };
  /** Настройки компоненты Деактивация после оповещения */
  public deactivationOptions: IScCheckboxOptions = {
    title: 'Деактивация после оповещения',
    disabled: true,
  };
  /** Настройки компоненты Строка условие */
  public conditionOptions: IScInputOptions = {
    label: 'Строка условие',
    placeholder: 'Строка условие',
    disabled: true,
  };
  /** Настройки компоненты Наименование устройства */
  public deviceNameOptions: IScInputOptions = {
    label: 'Наименование устройства',
    placeholder: 'Наименование устройства',
    disabled: true,
  };
  /** Настройки компоненты Тип устройства */
  public deviceTypeOptions: IScInputOptions = {
    label: 'Тип устройства',
    placeholder: 'Тип устройства',
    disabled: true,
  };
  /** Настройки компоненты Внешний идентификатор устройства */
  public deviceExtIdOptions: IScInputOptions = {
    label: 'Внешний идентификатор устройства',
    placeholder: 'Внешний идентификатор устройства',
    disabled: true,
  };
  /** Настройки компоненты Внешний идентификатор узла учета */
  public nodeExtIdOptions: IScInputOptions = {
    label: 'Внешний идентификатор узла учета',
    placeholder: 'Внешний идентификатор узла учета',
    disabled: true,
  };
  /** Настройки компоненты Наименование узла учета */
  public nodeNameOptions: IScInputOptions = {
    label: 'Наименование узла учета',
    placeholder: 'Наименование узла учета',
    disabled: true,
  };
  /** Настройки компоненты Тип ресурса узла учета */
  public nodeResourceTypeOptions: IScInputOptions = {
    label: 'Тип ресурса узла учета',
    placeholder: 'Тип ресурса узла учета',
    disabled: true,
  };

  //#endregion

  /** @ignore */
  public override ngOnInit(): void {
    super.ngOnInit();

    this.form.addControl('active', new FormControl(this.model.active));
    this.form.addControl('deactivation', new FormControl(this.model.deactivation));
    this.form.addControl('condition', new FormControl(this.model.condition));
    this.form.addControl('deviceName', new FormControl(this.model.deviceName));
    this.form.addControl('deviceType', new FormControl(this.model.deviceType));
    this.form.addControl('deviceExtId', new FormControl(this.model.deviceExtId));
    this.form.addControl('nodeExtId', new FormControl(this.model.nodeExtId));
    this.form.addControl('nodeName', new FormControl(this.model.nodeName));
    this.form.addControl('nodeResourceType', new FormControl(this.model.nodeResourceType));
  }
}
