<div class="map-dialog-container">
  <div mat-dialog-title class="map-header">Уточнить адрес на карте</div>
  <div class="bg-row" *ngIf="data?.showAddressCopyToKsip">
    <div class="bg-col align-right">
      <sc-checkbox [options]="copyAddressOptions" (changeValue)="copyAddressChange($event)"></sc-checkbox>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="map">
      <map-viewObjects
        [options]="mapViewObjectsOptions"
        [objects]="mapObjects"
        [request]="request"
        [markersOptions]="markersOptions"
        (events)="onMapViewObjectsEvent($event, mapViewObjectsOptions.mapId)"
      >
      </map-viewObjects>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="bg-row map_buttons">
      <div class="bg-col">
        <sc-button [options]="cancelOptions" (clickButton)="onCloseHandler($event)"> </sc-button>
      </div>
      <div class="bg-col" *ngIf="!data.disabled">
        <sc-button [options]="saveOptions" (clickButton)="onSaveHandler($event)"> </sc-button>
      </div>
    </div>
  </mat-dialog-actions>
</div>
