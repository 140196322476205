import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Uuid } from '@smart-city/core/utils';
import { SignificantObjectsService } from '../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ISignificantObjectForView,
  ISignificantObjectsDto,
  IStoredChemicalForView,
  IFireFightingCardsPlansDto,
} from '../../models/interfaces';
import { IDictionaryModelDto, IStoredChemicalDto } from 'smart-city-types';
import {
  IFileUpload,
  IMunicipality,
  IOrganization,
  ISignificantObjectCategory,
} from '@bg-front/core/models/interfaces';
import { formatPhone } from '@smart-city/core/utils';
import { Settings2Service } from '@smart-city/core/services';
import { StoredChemicalsService } from '@bg-front/stored-chemicals/services';
import { MultiFileService } from '@bg-front/core/services';

@UntilDestroy()
@Component({
  selector: 'bur-significant-objects-view-form',
  templateUrl: './significant-objects-view-form.component.html',
  styleUrls: ['./significant-objects-view-form.component.scss'],
})
export class SignificantObjectsViewFormComponent extends BaseComponent implements OnInit {
  /** Ожидание загрузки данных */
  public isLoading: boolean;

  /** Модель */
  public model: ISignificantObjectForView;

  /** Карточки/план пожаротушения */
  public fireFightingCardPlans: IFireFightingCardsPlansDto[];

  /** Флаг того, является ли данный объект химически опасным */
  public isChemicalObject: boolean = false;

  /** Хранимые АХОВ */
  public storedChemicals: IStoredChemicalForView[] = [];

  /** Информация о документах */
  public documents: IFileUpload[] = [];

  /** @ignore */
  constructor(
    notificationService: NzNotificationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly service: SignificantObjectsService,
    private readonly settings: Settings2Service,
    private readonly storedChemicalsService: StoredChemicalsService,
    private readonly multiFileService: MultiFileService,
  ) {
    super(notificationService);
  }

  /** @ignore */
  public ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.model = undefined;
        const objectId = params.get('id');

        if (!objectId) {
          return of({});
        }

        // Если ID невалидный не выполнять поиск и передать значение, что ничего найдено
        if (!Uuid.isValid(objectId)) {
          return of(undefined);
        }

        this.isLoading = true;
        return this.service.getById(objectId, [
          'id',
          'category.name',
          'type.name',
          'type.sysname',
          'name',
          'shortName',
          'organizationId.id',
          'organizationId.name',
          'mo.name',
          'address',
          'corp',
          'coordinates',
          'powerSupplyConsumerCategory.name',
          'state.name',
          'responsible',
          'phone',
          'dutyServices',
          'comment',
          'projectedRiskIds',
          'documents',
        ]);
      }),
      switchMap((significantObject: ISignificantObjectsDto) => {
        this.isLoading = false;
        this.model = {
          id: significantObject.id,
          category: (<ISignificantObjectCategory>significantObject.category)?.name,
          type: (<IDictionaryModelDto>significantObject.type)?.name,
          name: significantObject.name,
          shortName: significantObject.shortName,
          organization: {
            id: (<IOrganization>significantObject.organizationId)?.id,
            name: (<IOrganization>significantObject.organizationId)?.name,
          },
          mo: (<IMunicipality>significantObject.mo)?.name,
          address: significantObject.address?.fullText,
          level: significantObject.address?.level,
          corp: significantObject.corp,
          coordinates: significantObject.coordinates,
          powerSupplyConsumerCategory: (<IDictionaryModelDto>significantObject.powerSupplyConsumerCategory)?.name,
          state: (<IDictionaryModelDto>significantObject.state)?.name,
          responsible: significantObject.responsible,
          phone: formatPhone(significantObject.phone),
          dutyServices: significantObject.dutyServices,
          comment: significantObject.comment,
          projectedRisks: (significantObject.projectedRiskIds || [])
            .map((projectedRisk: string) => this.settings.getDictionaryById(projectedRisk).name),
          documents: significantObject.documents,
        };

        this.isChemicalObject = (<IDictionaryModelDto>significantObject.type)?.sysname.trim() === 'chemicallyHazard';

        // Убрал пока отсутствуют ПТП/КТП
        // return !!this.model.organization?.id
        //   ? this.service.getFireFightingCardPlan(this.model.organization.id)
        //   : of([]);

        return of([])
      }),
      switchMap((fireFightingCardsPlans: IFireFightingCardsPlansDto[]) => {
        this.fireFightingCardPlans = fireFightingCardsPlans;
        return !!this.isChemicalObject
          // Если объект химически опасный запросить хранимые АХОВ для него
          ? this.storedChemicalsService.getStoredChemicalsForSignificantObject(this.model.id)
          // Если объект не химически опасный вернуть пустое значение
          : of(null);
      }),
      switchMap((storedChemicals: IStoredChemicalDto[]) => {
        this.storedChemicals = storedChemicals?.map((storedChemical: IStoredChemicalDto) => ({
          chemicalType: storedChemical['chemicalType.name'],
          aggregationStateName: storedChemical['aggregationState.name'],
          aggregationStateSysName: this.settings.getDictionaryById(storedChemical.aggregationState).sysname,
          chemicalAmount: storedChemical.chemicalAmount,
          containerPressure: storedChemical.containerPressure,
          containerType: storedChemical['containerType.name'],
          containerHeight: storedChemical.containerHeight,
        }));
        return <Observable<IFileUpload[]>>this.multiFileService.getFilesFromSfs(this.model.documents);
      }),
      catchError((err: Error) => {
        this.isLoading = false;
        return this.catchErrorFn<IFileUpload[]>(err, 'Ошибка при загрузке данных объекта');
      }),
      untilDestroyed(this),
    ).subscribe((documents: IFileUpload[]) => {
      this.documents = documents;
    });
  }

  /** Закрыть форму */
  public close(): void {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  /** Открыть форму редактирования карточки/плана пожаротушения в новой вкладке */
  public openfireFightingCardPlan(id: string): void {
    let targetUrl = `http://${window.location.host}`;
    targetUrl += `/dictionaries/fire-fighting-cards-plans/edit/${id}`;
    window.open(targetUrl);
  }

  /** Открыть форму редактирования */
  public openEditForm(): void {
    this.router.navigate(['edit', this.model.id], { relativeTo: this.route.parent.parent });
  }
}
