<nz-spin nzTip="Загрузка..." [nzSpinning]="isLoading" class="nz-spin-center">
  <form nz-form nzLayout="vertical">
    <nz-layout>
      <nz-header>
        <nz-page-header nzTitle="Важный объект" nzBackIcon="arrow-left" (nzBack)="close()">
          <nz-page-header-extra>
            <button nz-button nzType="primary" type="button" (click)="openEditForm()">Редактировать</button>
          </nz-page-header-extra>
        </nz-page-header>
      </nz-header>
      <nz-content>
        <nz-tabset>
          <nz-tab nzTitle="Описание">
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label>Категория</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.category" [mode]="'text'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label>Тип</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.type" [mode]="'text'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label>Наименование</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.name" [mode]="'text'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label>Краткое наименование</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.shortName" [mode]="'text'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label>Ответственная организация</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.organization?.name" [mode]="'text'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label>Муниципальное образование</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.mo" [mode]="'text'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label>Адрес</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.address" [mode]="'text'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="8">
                <nz-form-item>
                  <nz-form-label>Корпус</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.corp" [mode]="'text'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="8">
                <nz-form-item>
                  <nz-form-label>Координаты</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.coordinates" [mode]="'text'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="8">
                <nz-form-item>
                  <nz-form-label>Этажность</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.level" [mode]="'text'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label>Категория электроснабжения потребителя</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.powerSupplyConsumerCategory" [mode]="'text'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label>Состояние</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.state" [mode]="'text'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label>Ответственный</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.responsible" [mode]="'text'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="12">
                <nz-form-item>
                  <nz-form-label>Контактный телефон</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.phone" [mode]="'text'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label>Дежурные службы</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.dutyServices" [mode]="'textarea'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label>Комментарий</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.comment" [mode]="'textarea'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- <div nz-col [nzSpan]="24">
                План/карточка тушения пожара
                <p *ngFor="let card of fireFightingCardPlans">
                  <a class="link" href="javascript:void(0)" (click)="openfireFightingCardPlan(card.id)">
                    {{ card.number }}
                  </a>
                </p>
              </div> -->
            </div>
          </nz-tab>
          <nz-tab nzTitle="Параметры">
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <nz-form-label>Риски для прогнозирования</nz-form-label>
                  <nz-form-control>
                    <nz-view-control [data]="model?.projectedRisks" [mode]="'tag'"></nz-view-control>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="24" *ngIf="isChemicalObject">
                <nz-collapse>
                  <nz-collapse-panel nzHeader="Хранимые АХОВ">
                    <div nz-row class="chemical" [nzGutter]="24" *ngFor="let storedChemical of storedChemicals">
                      <div nz-col nzFlex="1 0">Параметры АХОВ</div>
                      <div nz-col [nzSpan]="24"><hr /></div>
                      <div nz-col [nzSpan]="12">
                        <nz-form-item>
                          <nz-form-label>Тип АХОВ</nz-form-label>
                          <nz-form-control>
                            <nz-view-control [data]="storedChemical.chemicalType" [mode]="'text'"></nz-view-control>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                      <div nz-col [nzSpan]="12">
                        <nz-form-item>
                          <nz-form-label>Агрегатное состояние</nz-form-label>
                          <nz-form-control>
                            <nz-view-control [data]="storedChemical.aggregationStateName" [mode]="'text'">
                            </nz-view-control>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                      <ng-container *ngIf="storedChemical.aggregationStateSysName === 'gas'">
                        <div nz-col [nzSpan]="12">
                          <nz-form-item>
                            <nz-form-label>Количество АХОВ (в т.)</nz-form-label>
                            <nz-form-control>
                              <nz-view-control [data]="storedChemical.chemicalAmount" [mode]="'text'"></nz-view-control>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="12">
                          <nz-form-item>
                            <nz-form-label>Давление в ёмкости (в атм.)</nz-form-label>
                            <nz-form-control>
                              <nz-view-control [data]="storedChemical.containerPressure" [mode]="'text'">
                              </nz-view-control>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="storedChemical.aggregationStateSysName === 'liquid'">
                        <div nz-col [nzSpan]="12">
                          <nz-form-item>
                            <nz-form-label>Количество АХОВ (в т.)</nz-form-label>
                            <nz-form-control>
                              <nz-view-control [data]="storedChemical.chemicalAmount" [mode]="'text'"></nz-view-control>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="12"></div>
                        <div nz-col [nzSpan]="12">
                          <nz-form-item>
                            <nz-form-label>Тип ёмкости</nz-form-label>
                            <nz-form-control>
                              <nz-view-control [data]="storedChemical.containerType" [mode]="'text'"></nz-view-control>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="12">
                          <nz-form-item>
                            <nz-form-label>Высота поддона/обваловки (в м.)</nz-form-label>
                            <nz-form-control>
                              <nz-view-control
                                [data]="storedChemical.containerHeight"
                                [mode]="'text'"
                              ></nz-view-control>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </ng-container>
                    </div>
                  </nz-collapse-panel>
                </nz-collapse>
              </div>
            </div>
          </nz-tab>
          <nz-tab nzTitle="Устройства"> </nz-tab>
          <nz-tab nzTitle="Документы">
            <div nz-row *ngIf="!!documents && !!documents.length" [nzGutter]="24">
              <div nz-col [nzSpan]="24">
                <nz-form-item>
                  <file-upload readOnly [files]="documents"></file-upload>
                </nz-form-item>
              </div>
            </div>
          </nz-tab>
        </nz-tabset>
      </nz-content>
      <nz-footer class="empty"></nz-footer>
    </nz-layout>
  </form>
</nz-spin>
