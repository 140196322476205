import { Injectable } from '@angular/core';
import { IResponseWithTotal } from '@bg-front/core/models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IModifiedData } from '@smart-city/core/interfaces';
import { ILimit, ISort, RestService, SubscriberService } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';

import { IKsipSectionsDto } from '../../../models/interfaces';
import { KsipSectionsStore } from '../store/ksip-sections-store.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class KsipSectionsService {
  private isInit = false;

  constructor(
    private readonly rest: RestService,
    private readonly store: KsipSectionsStore,
    private readonly subs: SubscriberService,
  ) {}

  /**
   * Загружаем список Типов инцидентов
   */
  public initStore(): Observable<boolean> {
    this.store.setLoading(true);

    if (!this.isInit) {
      this.isInit = true;
      this.subs
        .onTableChange<IKsipSectionsDto>('Admin', 'IncidentSections')
        .pipe(untilDestroyed(this))
        .subscribe((result: IModifiedData<IKsipSectionsDto>) => {
          this.store.setLoading(true);
          if (result.action !== 'delete') {
            this.store.upsert(
              result.data.id,
              (oldState: Partial<IKsipSectionsDto>) => ({ ...oldState, ...result.data }),
              (id: string, newState: IKsipSectionsDto) => ({ id, ...newState }),
            );
          } else {
            this.store.remove((result.data as IAnyObject).ids);
          }
          this.store.setLoading(false);
        });
    }

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'IncidentSections',
          sort: { field: 'name', direction: 'asc' },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          this.store.add(response?.data?.items as IKsipSectionsDto[]);
          return true;
        }),
        catchError(() => {
          return of(false);
        }),
        finalize(() => {
          this.store.setLoading(false);
        }),
      );
  }

  /**
   * Удаление записи
   */
  public delete(ids: string | string[]): Observable<IAbstractServiceData> {
    this.store.setLoading(true);
    return this.rest.serviceRequest({
      action: 'upsert',
      service: { name: 'Admin' },
      entity: {
        name: 'IncidentTypes',
        query: {
          'incidentCategory.sectionId': Array.isArray(ids) ? { $in: ids } : ids,
        },
      },
      data: {
        active: false,
        incidentCategory: null,
      },
    }).pipe(
      catchError(() => of(undefined)),
      switchMap((response: IAbstractServiceData | undefined) => {
        if (!response) {
          return of(response);
        }
        return this.rest.serviceRequest({
          action: 'update',
          service: { name: 'Admin' },
          entity: {
            name: 'IncidentCategories',
            query: {
              sectionId: Array.isArray(ids) ? { $in: ids } : ids,
            },
          },
          data: {
            active: false,
            sectionId: null,
          },
        });
      }),
      catchError(() => of(undefined)),
      switchMap((response: IAbstractServiceData | undefined) => {
        if (!response) {
          return of(response);
        }
        return this.rest.serviceRequest({
          action: 'delete',
          service: { name: 'Admin' },
          entity: {
            name: 'IncidentSections',
            query: {
              id: Array.isArray(ids) ? { $in: ids } : ids,
            },
          },
        });
      }),
      tap(() => this.store.setLoading(false)),
    );
  }

  /**
   * Сохранение детализации КСиП
   * @param data - информация о детализации КСиП
   */
  public save(data: IKsipSectionsDto): Observable<IAbstractServiceData> {
    return this.rest
      .serviceRequest({
        data,
        action: 'upsert',
        service: { name: 'Admin' },
        entity: { name: 'IncidentSections', query: { id: data.id ? data.id : Uuid.newUuid() } },
      })
      .pipe(
        catchError(() => of(undefined)),
        switchMap((response: IAbstractServiceData | undefined) => {
          if (response && data.id) {
            return this.rest.serviceRequest({
              action: 'update',
              service: { name: 'Admin' },
              entity: { name: 'IncidentCategories', query: { sectionId: data.id, active: !data.active } },
              data: {
                active: data.active,
              },
            });
          }
          return of(response);
        }),
        catchError(() => of(undefined)),
        switchMap((response: IAbstractServiceData | undefined) => {
          if (response && data.id) {
            return this.rest.serviceRequest({
              action: 'upsert',
              service: { name: 'Admin' },
              entity: { name: 'IncidentTypes', query: { 'incidentCategory.sectionId': data.id, active: !data.active } },
              data: {
                active: data.active,
              },
            });
          }
          return of(response);
        }),
      );
  }

  /** Получение списка элементов для таблицы */
  public getKsipSectionsForTable(
    query: IAnyObject,
    pageIndex: number,
    pageSize: number,
    sort?: ISort,
  ): Observable<IResponseWithTotal> {
    let limit: ILimit = undefined;
    if (pageIndex || pageSize) {
      limit = {
        paNumber: pageIndex ?? 1,
        paSize: pageSize ?? 15,
      };
    }
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query,
          name: 'IncidentSections',
          attributes: ['id', 'name', 'sysname', 'comment', 'useInternetPortal', 'active'],
        },
        data: {
          isNeedTotal: true,
          limit,
          sort,
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return {
            items: response.data.items || [],
            totalCount: response.data.totalCount ?? 0,
          };
        }),
        catchError(() => {
          return of({
            totalCount: 0,
            items: [],
          });
        }),
      );
  }

  /** Запрашиваем данные для формы редактирования */
  public getById(id: string): Observable<IKsipSectionsDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'IncidentSections',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response?.data?.items || [])[0] as IKsipSectionsDto;
        }),
        catchError(() => {
          return of(<IKsipSectionsDto>{});
        }),
      );
  }
}
