import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { IPolygonDto } from '@bg-front/core/models/interfaces';
import { BgMapService } from '@bg-front/core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IElementButton, INwHeaderBarOptions } from '@smart-city/core/common';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { ScNavService, Settings2Service } from '@smart-city/core/services';
import { formatPhone } from '@smart-city/core/utils';
import { MapBaseModel, MapBaseService } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { IEmergencyDto } from 'smart-city-types';
import { IDistrict } from '../../../bg/modules/dictionaries/modules/districts/models/interfaces';
import { DistrictsService } from '../../../bg/modules/dictionaries/modules/districts/services';
import { IMunicipality } from '../../../bg/modules/dictionaries/modules/municipalities/models/municipality.interface';
import { MunicipalitiesService } from '../../../bg/modules/dictionaries/modules/municipalities/services/municipalities.service';
import { IForcesAndResourcesDto } from '../../../bg/modules/forces-and-resources/modules/forces-and-resources/models/interfaces';
import { ForcesAndResourcesService } from '../../../bg/modules/forces-and-resources/modules/forces-and-resources/services';
import { CallService } from '../../../call/services';
import { IOrganization, IOrganizationCoverage } from '../../models/interfaces';
import { OrganizationsService, PolygonsService } from '../../services';
import { LayersEnum } from '@bg-front/core/models/enums';

@UntilDestroy()
@Component({
  selector: 'bg-eos-info',
  templateUrl: './eos-info.component.html',
  styleUrls: ['./eos-info.component.scss'],
})
export class EosInfoComponent extends BaseComponent implements OnInit, OnDestroy {
  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions;

  /** Данные важном объекте */
  public organization = undefined;

  /** Данные сил и средств */
  public forceResource = undefined;

  /** Координыты */
  public coordinates: string = undefined;

  /** Список связанных происшевствий */
  public emergencies: IEmergencyDto[] = undefined;

  /** Сеанс блока мониторинга */
  public state;

  /** Модель карты */
  private mapModel: MapBaseModel;

  /** Зона действия организации */
  public organizationCoverage: IOrganizationCoverage;

  /** Флаг открытия дровера */
  public openDrawer = false;

  /** Смещение по оси X */
  public offsetX = 364;

  /** @ignore */
  constructor(
    private readonly gisService: BgMapService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly scNavService: ScNavService,
    private readonly polygonsService: PolygonsService,
    private readonly mapService: MapBaseService,
    private readonly callService: CallService,
    private readonly organizationService: OrganizationsService,
    private readonly settings: Settings2Service,
    private readonly municipalitiesService: MunicipalitiesService,
    private readonly districtsService: DistrictsService,
    private readonly forcesAndResources: ForcesAndResourcesService,
  ) {
    super();
  }

  /** ID сервера */
  @Input()
  public set eosId(val: string) {
    if (val) {
      const organizationId = val;
      this.organizationService
        .getOrganizationBy(organizationId)
        .pipe(
          catchError((err: Error) =>
            this.catchErrorFn<IOrganization>(err, 'Ошибка при загрузке данных Организаций экстренного реагирования'),
          ),
          tap((organization: IOrganization) => {
            this.organization = organization;
            this.state = this.organization.active ? 'Действующее' : 'Неактивное';

            if (new Coordinates(this.organization.coordinates).isValid()) {
              this.coordinates = this.organization.coordinates;
            } else if (this.organization.address?.latitude && this.organization.address?.longitude) {
              this.coordinates = new Coordinates(
                this.organization.address.latitude,
                this.organization.address.longitude,
              ).toString();
            }
            this.openDrawer = true;
          }),
          untilDestroyed(this),
        )
        .subscribe();

      this.forcesAndResources
        .getByQuery({ organizationId: organizationId })
        .pipe(
          catchError((err: Error) =>
            this.catchErrorFn<IForcesAndResourcesDto[]>(err, 'Ошибка при загрузке данных Сил и средств'),
          ),
          untilDestroyed(this),
        )
        .subscribe((response: IForcesAndResourcesDto[]) => {
          this.forceResource = response;
          if (this.forceResource) {
            this.forceResource.onDate = this.formatDate(this.forceResource.onDate);
          }
        });

      this.organizationService
        .getCoverageAreaByOrgId(organizationId)
        .pipe(untilDestroyed(this))
        .subscribe((res: IOrganizationCoverage) => {
          if (res) {
            this.organizationCoverage = res;
          }
        });
    }
  }

  ngOnInit(): void {
    this.mapModel = new MapBaseModel('baseMapWorkspace', this.mapService);

    this.headerOptions = {
      margin: 'collapse',
      title: ' ',
      buttons: [
        {
          type: 'button',
          options: {
            name: 'close',
            icon: 'close',
            position: 'suffix',
            callback: () => {
              this.route.parent.data.pipe(untilDestroyed(this)).subscribe((registries) => {
                this.mapModel.removeLayer(LayersEnum.clickMarker);
                this.router.navigate(['../..'], {
                  relativeTo: this.route,
                  queryParamsHandling: 'merge',
                });
                this.clearMap();
              });
              return of();
            },
          },
        },
      ],
    };

    /** Очистить полигоны на карте */
    this.clearMap();

    /** Подписываемся на параметры */
    this.route.params.pipe(untilDestroyed(this)).subscribe((params: Params) => {
      if (params['id']) {
        return (this.eosId = params['id']);
      }
    });
  }

  /**
   * Обработчик, срабатываемый при нажатии на номер телефона в истории вызовов.
   * @param phone номер телефона
   */
  public onClickPhone(phone: string): void {
    this.callService.call(phone);
  }

  /** Приведение номера к формату +7 */
  public formatPhoneFn(phone: string) {
    return formatPhone(phone);
  }

  /**
   * Обработчик, вызываемый при клике на кнопке Зона действия организации.
   * @param event - информация о событии
   */
  public showOnMap(event: Event): void {
    event.preventDefault();

    // Определяем метод формирования зоны
    const dict: IDictionaryInfo[] = this.settings.getDictionaryByTypeSysName('coverageCreationMethod');
    const creationMethod = dict.find((value: IDictionaryInfo) => value.id === this.organizationCoverage.creationMethod)
      ?.sysname;

    this.getPolygons(creationMethod)
      .pipe(
        mergeMap((polygonsIds: string[]) => {
          if (polygonsIds) {
            return this.polygonsService.getMultiplePolygonsByIds(polygonsIds);
          }
          return of([]);
        }),
        mergeMap((polygonObjCoordinates: IPolygonDto[]) => {
          polygonObjCoordinates.map((polygon: IPolygonDto) => {
            this.mapModel.addPolygonToLayer(
              'polygon',
              `p-${polygon.id}`,
              polygon.coordinates.map((coordinate: Coordinates) => coordinate.toArray()),
              { color: '#0072C3', weight: 2 },
            );
          });
          const centerCoordinates: Coordinates[] = polygonObjCoordinates[0]?.coordinates;
          const mapCenter = new Coordinates(this.gisService.getCenter(centerCoordinates)).toArray();

          this.mapModel.viewLayer('polygon', false);
          this.mapModel.setZoom(this.settings.getConfig().defaultZoom);
          this.mapModel.setCenter(mapCenter);
          return of(null);
        }),
        untilDestroyed(this),
      )
      .subscribe();

    /** Подписываемся на события роутера */
    this.router.events.pipe(untilDestroyed(this)).subscribe(() => this.clearMap());
  }

  /** Получаем полигоны для отображения на карте, в зависимости от способа задания зоны действия */
  public getPolygons(creationMethod: string): Observable<string[]> {
    // получаем id полигонов мо
    if (creationMethod === 'createByMunicipal' && this.organizationCoverage.moIds.length) {
      return this.municipalitiesService.getMultipleMunicipalitiesCoordinates(this.organizationCoverage.moIds).pipe(
        map((municipalities: IMunicipality[]) => {
          return municipalities.map((mo: IMunicipality) => mo.polygonId);
        }),
      );
    }
    // получаем id полигонов районов
    if (creationMethod === 'createByDistricts' && this.organizationCoverage.districtIds.length) {
      return this.districtsService.getMultipleDistrictsCoordinates(this.organizationCoverage.districtIds).pipe(
        map((districts: IDistrict[]) => {
          return districts.map((district: IDistrict) => district.polygonId);
        }),
      );
    }
    if (creationMethod === 'createByHand' && this.organizationCoverage.manualCreatedPolygonId) {
      return of([this.organizationCoverage.manualCreatedPolygonId]);
    }
    return of(null);
  }

  /** Очистить полигоны на карте */
  public clearMap(): void {
    this.mapModel.removeLayer('polygon');
  }

  /** Форматирует дату создания происшествия в необходимый для отображения вид */
  public formatDate(date: number): string {
    if (date) {
      return dayjs(date).format('DD.MM.YYYY');
    }
    return '';
  }

  /**
   * Обработка событий из заголовка
   * @param $event событие
   */
  public clickHeaderButton($event: IElementButton): void {
    ($event.options.callback as any)();
  }

  /**
   * Метод перехода/центрирования по координатам
   */
  public selectCoordinates(): void {
    this.gisService.setPositionMapOnCoordinates(this.coordinates);
  }
}
