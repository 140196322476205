<div>
  <div>
    <div class="dialog-header">
      <div class="bg-row dialog-header-title">
        <div class="bg-col-10 title">
          <h2 mat-dialog-title>{{ data?.title }}</h2>
        </div>
        <div class="bg-col-2 align-right close-button" (click)="closeHandler()">
            <mat-icon >close</mat-icon>
        </div>
      </div>
      <div class="bg-row dialog-header-service-name">
        {{ serviceTypeName }}
      </div>
    </div>
  </div>
  <bg-involve-org-without-interaction-form
    (closeEvent)="closeHandler()"
    [options]="options">
  </bg-involve-org-without-interaction-form>
</div>
