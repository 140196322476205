import { Injectable } from '@angular/core';
import { IScSelectLoadParams } from '@smart-city/core/common';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { NotificationService, RestService, Settings2Service, SfsService } from '@smart-city/core/services';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, map, switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IUploadMetadataOutDTO, IUserInfoDto } from 'smart-city-types';
import { IInformationStatementData } from '../../../models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class InformationStatementService {
  constructor(
    private readonly rest: RestService,
    private readonly settings: Settings2Service,
    private readonly sfs: SfsService,
    private readonly note: NotificationService,
  ) {}

  /**
   * Сохранение донесения
   */
  public saveInformationStatement(informationStatement: IInformationStatementData) {
    // Список файлов для удаления.
    const deleteFiles = (informationStatement.documents || []).filter(
      (document) => (<IAnyObject>document.file).uuid && !(<IAnyObject>document.file).isActive,
    );

    // Список файлов для загрузки.
    const uploadFiles = (informationStatement.documents || []).filter((document) => document.file instanceof File);

    // В результирующий массив попадут файлы не помеченные к удалению.
    informationStatement.documents = informationStatement.documents
      .filter((document) => (<IAnyObject>document).file.uuid && (<IAnyObject>document).file.isActive)
      .map((document) => ({ file: (<IAnyObject>document).file.uuid, userId: (<IUserInfoDto>document.userId)?.id }));

    // Загрузка файлов в хранилище
    return forkJoin(
      uploadFiles.map((document) =>
        this.sfs.upload({
          file: <File>document.file,
          fileName: (<File>document.file).name,
          fileTags: 'doc, informStatement',
        }),
      ),
    ).pipe(
      defaultIfEmpty([]),
      switchMap((documents: IUploadMetadataOutDTO[]) => {
        // Добавить к результирующему массиву загруженные файлы
        informationStatement.documents.push(
          ...documents.map((document) => ({ file: document.uuid, userId: this.settings.currentUser.id })),
        );
        // Удаление файлов из хранилища
        return forkJoin(deleteFiles.map((item) => this.sfs.delete((<IAnyObject>item.file).uuid)));
      }),
      defaultIfEmpty(null),
      // Если в процессе работы с SFS произошла ошибка, то вывести сообщение но сохранение выполнить.
      catchError(() => {
        this.note.pushError('В процессе сохранения/удаления файлов произошла ошибка.');
        return of(null);
      }),
      switchMap(() => {
        // TODO: Перенести множественное создание донесений на бэк
        const executors = informationStatement.executorOrganizationIds
          ? informationStatement.executorOrganizationIds
          : [informationStatement.executorOrganizationId];
        delete informationStatement.executorOrganizationId;
        return forkJoin(
          executors.map((executor) =>
            this.rest.serviceRequest({
              service: { name: 'Edi' },
              action: 'saveInformationStatement',
              data: { ...informationStatement, executorOrganizationId: executor },
            }),
          ),
        );
      }),
    );
  }

  /**
   * Получение информации о донесении
   * @param id ID донесения
   */
  public getInformationStatementData(id: string): Observable<IInformationStatementData> {
    return this.rest
      .serviceRequest({
        action: 'getInformationStatementById',
        service: { name: 'Edi' },
        data: { id },
      })
      .pipe(map((result) => result.data));
  }

  /**
   * Получение типа организации текущего пользователя
   */
  public getCurrentUserOrgType(): Observable<string> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'OrgTypeParams',
          query: { id: this.settings.currentUser.organizationId.orgTypeParam },
          attributes: ['organizationTypeId.sysname'],
        },
      })
      .pipe(map((result) => result.data.items[0]?.organizationTypeId.sysname));
  }

  /**
   * Получение статусов ЖЦ донесений для фильтра
   */
  public getInformationStatementStatuses(params: IScSelectLoadParams): Observable<IScSelectItem[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'LifeCycleStep',
          query: {
            'lifeCycleId.type.sysname': 'informationStatement',
            $text: (params.query || { $text: undefined }).$text
              ? {
                  $search: params.query.$text.$search,
                  $fields: ['name'],
                }
              : undefined,
          },
          attributes: ['status.sysname', 'status.name'],
        },
      })
      .pipe(
        map((steps: IAbstractServiceData) =>
          steps.data.items.reduce((acc, step) => {
            if (
              acc.findIndex((item) => item.id === step.status.sysname) === -1 &&
              params.options.data.findIndex((item) => item.id === step.status.sysname) === -1
            ) {
              acc.push({
                id: step.status.sysname,
                text: step.status.name,
              });
            }
            return acc;
          }, []),
        ),
      );
  }

  /**
   *  Метод создает Донесение
   *  @param data - даныне
   *  @return
   */
  public createInformationStatement(data: IAnyObject) {
    return this.rest
      .serviceRequest({
        data,
        service: { name: 'Edi' },
        action: 'saveInformationStatement',
      })
      .pipe(map((result: IAbstractServiceData) => result.data));
  }

  public getInformationThemeList(): Observable<{ id: string; name: string }[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          attributes: ['id', 'name'],
          name: 'InformationStatementThemes',
        },
      })
      .pipe(map(({ data }: IAbstractServiceData) => data.items || []));
  }

  /** Получение информации о донесении по query */
  public getInformationStatementByQuery(query: IAnyObject) {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Edi' },
      entity: {
        query,
        name: 'InformationStatements',
      },
    })
      .pipe(map(({ data }: IAbstractServiceData) => data.items || []));
  }
}
