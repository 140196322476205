<button class="dialog-opening-button" (click)="open()" [disabled]="dialogOpeningButtonDisabled">Важные объекты рядом с
  КСиП
</button>
<ng-template #dialogRef>
  <div class="bg-row">
    <div class="bg-col">
      <h3>Важные объекты рядом с КСиП</h3>
    </div>
    <div class="bg-col-min">
      <button mat-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-table
    matSort
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="90"
    [scrollWindow]="false"
    [dataSource]="dataSource"
    class="card-table"
  >
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> <h5>Наименование</h5> </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="state.name">
      <mat-header-cell *matHeaderCellDef> <h5>Состояние</h5> </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.state.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="responsible">
      <mat-header-cell *matHeaderCellDef> <h5>Ответственный</h5> </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.responsible}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="phone">
      <mat-header-cell *matHeaderCellDef> <h5>Контакты</h5> </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.phone}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="buttons">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let significantObject">
        <div class="action-button-container">
          <button
            mat-raised-button
            (click)="openSignificantObjectTab(significantObject.id)"
            matTooltip="Просмотр параметров"
            [matTooltipPosition]="'above'"
            class="action-button"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
          <div *ngIf="canForecastingRun?.visible">
            <button
              *ngIf="significantObject.projectedRiskIds && significantObject.projectedRiskIds.length > 0"
              mat-raised-button
              (click)="openForecastingTab(significantObject.id)"
              matTooltip="Запуск прогнозирования"
              [matTooltipPosition]="'above'"
              class="action-button"
            >
              <mat-icon>track_changes</mat-icon>
            </button>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onClickRowPhone(row)"></mat-row>
  </mat-table>
</ng-template>
