import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

import { VideoDeviceState } from '../../../models/states';

/**
 * Сервис хранилища организаций
 */
@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'video-devices-store', resettable: true })
export class VideoDevicesStore extends EntityStore<VideoDeviceState> {
  constructor() {
    super([]);
  }
}
