import { Injectable } from '@angular/core';
import { RestService } from '@smart-city/core/services';
import { formatPhoneDb } from '@smart-city/core/utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap, tap, switchMap } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IUserInfoDto } from 'smart-city-types';
import { ICitizens, IDeclarer, IDeclarerForm } from '../../models/interfaces';
import { DeclarerStoreService } from './declarer.store.service';

/** Сервис для работы с компонентом Информация о заявители */

@Injectable({
  providedIn: 'root',
})
export class DeclarerService {
  /**  Обсервбл для Данных формы */
  public $formData = new BehaviorSubject<IDeclarerForm>(null);

  constructor(private readonly rest: RestService, private readonly declarerStore: DeclarerStoreService) {}

  /**
   * Получение заявителя по номеру телефона
   * @param query - запрос
   * @return
   */
  public getDecklarerPhonesByQuery(query: IAnyObject): Observable<IAnyObject[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Citizens' },
        entity: {
          name: 'CitizensPhones',
          linksMode: 'raw',
          query: {
            ...query,
          },
        },
      })
      .pipe(
        map((data: IAbstractServiceData) => {
          return data.data.items || [];
        }),
      );
  }

  /**
   * Получение заявителя по номеру телефона
   * @param id - Id заявителя
   * @return
   */
  public getDeclarerById(id: string): Observable<ICitizens> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Citizens' },
        entity: {
          name: 'Citizens',
          linksMode: 'raw',
          query: {
            id,
          },
        },
      })
      .pipe(
        mergeMap((data: IAbstractServiceData) => {
          const declarerInfo = data.data.items[0];
          if (declarerInfo) {
            return this.getDecklarerPhonesByQuery({
              citizenId: data.data.items[0]?.id,
            }).pipe(
              map((dataPhones) => {
                if (dataPhones.length) {
                  const phone = dataPhones.find(({ isPrimary }) => isPrimary)?.phoneNumber || '';
                  const contactPhone = dataPhones.filter(({ isPrimary }) => !isPrimary)[0]?.phoneNumber || '';
                  return {
                    ...declarerInfo,
                    phone,
                    contactPhone,
                  };
                }
                return declarerInfo;
              }),
            );
          }
          return of(declarerInfo);
        }),
      );
  }

  /**
   * Обновляю запись данные заявителя по номеру телефона
   * @return
   */
  public updateEmergencyDeclarer(id: string): Observable<IAbstractServiceData> {
    const data = this.declarerStore.getFormData();
    return this.rest.serviceRequest({
      data,
      action: 'update',
      service: { name: 'Emergency' },
      entity: {
        name: 'Declarers',
        linksMode: 'raw',
        query: {
          id,
        },
      },
    });
  }

  /**
   * Метод, создающий новую запись в таблице Citizens сервиса Citizens.
   * @param user пользователь, инициировавший создание.
   * @param value объект с полями из формы.
   */
  public createCitizen(user: IUserInfoDto, value: IAnyObject): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      data: value,
      action: 'insert',
      service: {
        name: 'Citizens',
      },
      entity: {
        name: 'Citizens',
      },
    }).pipe(tap((res: IAbstractServiceData) => {
      this.createCitizenPhones(res.data.id, value.phones);
      this.createCitizenKinship(res.data.id, value.kinship);
    }));
  }

  /**
   * Метод, создающий новую запись в таблице CitizensPhones сервиса Citizens.
   * @param id идентификатор гражданина
   * @param value объект с полями из формы
   */
  public createCitizenPhones(id: string, value: IAnyObject): Observable<IAnyObject> {
    if (value) {
      /** Значение с радиобаттона */
      const primary = value.primary;
      const phonesArr: IAnyObject[] = [];
      /** Сгенерируем массив объектов из объекта */
      Object.keys(value).forEach((prop) => {
        if (prop === 'id' || prop === 'primary') return;
        phonesArr.push({
          phoneNumber: formatPhoneDb(value[prop]),
          isPrimary: prop === primary,
        });
      });
      /** Вставляем записи из массива в базу */
      this.rest.serviceRequest({
        data: phonesArr.map((phone) => ({
          citizenId: id,
          phoneNumber: phone.phoneNumber,
          isPrimary: phone.isPrimary,
        })),
        action: 'insert',
        service: {
          name: 'Citizens',
        },
        entity: {
          name: 'CitizensPhones',
        },
      }).subscribe(
        () => { return; },
        (err) => {
          console.error(err);
        },
      );
    }
    return of({ action: 'update' });
  }

  /**
   * Создаёт родственные связи для нового гражданина.
   * @param citizenId идентификатор гражданина
   * @param kinship объект с полями из composite-object
   */
  public createCitizenKinship(citizenId: string, kinship: IAnyObject[]): void {
    this.rest.serviceRequest({
      action: 'insert',
      service: { name: 'Citizens' },
      entity: { name: 'Kinship' },
      data: (kinship ?? []).map((relative) => ({
        citizenId,
        relativeCitizenId: relative.relativeCitizenId,
        relationshipType: relative.relationshipType,
      })),
    }).subscribe(
      () => { return; },
      (err) => {
        console.error(err);
      },
    );
  }

  /**
   * Метод, обновляющий существующую запись в таблице Citizens сервиса Citizens.
   * @param user пользователь, инициировавший изменение.
   * @param value объект с полями из формы.
   * @param id идентификатор записи, которую следует обновить.
   */
  public updateCitizen(user: IUserInfoDto, value: IAnyObject, id: string): Observable<IAnyObject> {
    return this.rest.serviceRequest({
      data: value,
      action: 'update',
      service: {
        name: 'Citizens',
      },
      entity: {
        name: 'Citizens',
        query: {
          id,
        },
      },
    }).pipe(switchMap(() => {
      this.updateCitizenKinship(id, value.kinship);
      if (!value.phones) return of({ action: 'update' });
      return this.updateCitizenPhones(id, value.phones);
    }));
  }

  /**
   * Метод, обновляющий существующие записи в таблице CitizensPhones сервиса Citizens.
   * @param id идентификатор гражданина, у которого обновляются телефоны.
   * @param value объект с полями из формы.
   */
  public updateCitizenPhones(id: string, value: IAnyObject): Observable<IAnyObject> {
    /** Удаляем все телефоны гражданина и вставляем новые */
    if (id) {
      return this.rest.serviceRequest({
        action: 'delete',
        service: {
          name: 'Citizens',
        },
        entity: {
          name: 'CitizensPhones',
          query: { citizenId: (id || null) },
        },
      }).pipe(
        switchMap(() => this.createCitizenPhones(id, value)),
        switchMap(() => of({ action: 'update' })),
      );
    }
    return of({ action: 'update' });
  }

  /**
   * Обновляет родственные связи гражданина.
   * @param citizenId идентификатор гражданина
   * @param kinship объект с полями из composite-object
   */
  public updateCitizenKinship(citizenId: string, kinship: IAnyObject[]): void {
    if (kinship === undefined) return;
    this.rest.serviceRequest({
      action: 'delete',
      service: {
        name: 'Citizens',
      },
      entity: {
        name: 'Kinship',
        query: { citizenId },
      },
    })
      .pipe(switchMap(() => {
        if (Array.isArray(kinship) && kinship.length) {
          return this.rest.serviceRequest({
            action: 'insert',
            service: { name: 'Citizens' },
            entity: { name: 'Kinship' },
            data: kinship.map((relative) => ({
              citizenId,
              relativeCitizenId: relative.relativeCitizenId,
              relationshipType: relative.relationshipType,
            })),
          });
        }
        return of(null);
      }))
      .subscribe(
        () => { return; },
        (err) => {
          console.error(err);
        },
      );
  }

  /**
   *  Создание заявителя в Citizens_Citizens вместе с телефонами
   *  @param data - данные заявителя
   *  @return
   */
  public createCitizenDeclarer(data: IDeclarer) {
    const changedData = this.convertDataForCitizen(data);
    const phoneList = [data.contactPhone, data.phone];
    return this.rest
      .serviceRequest({
        data: changedData,
        action: 'insert',
        service: { name: 'Citizens' },
        entity: {
          name: 'Citizens',
          linksMode: 'raw',
        },
      })
      .pipe(
        mergeMap((result: IAbstractServiceData) => {
          if (phoneList.length) {
            const dataPhoneList = phoneList
              .map((phoneNumber, index) => {
                return {
                  phoneNumber: formatPhoneDb(phoneNumber),
                  isPrimary: index === 1,
                  citizenId: result.data?.id,
                };
              })
              .filter(({ phoneNumber }) => phoneNumber);
            return this.rest.serviceRequest({
              data: dataPhoneList,
              action: 'insert',
              service: { name: 'Citizens' },
              entity: {
                name: 'CitizensPhones',
                linksMode: 'raw',
              },
            });
          }
        }),
      );
  }

  /**
   *  Создание заявителя в Admin_Declarers
   *  @param id - id записи
   *  @return
   */
  public updateCitizenDeclarer(id: string): Observable<IAbstractServiceData> {
    const data = this.declarerStore.getFormData();
    const phone = formatPhoneDb(data?.phone) || null;
    const contactPhone = formatPhoneDb(data?.contactPhone) || null;
    const changedData = this.convertDataForCitizen(data);
    const phoneList = [phone, contactPhone];

    if (!id) {
      return this.rest
        .serviceRequest({
          data: changedData,
          action: 'insert',
          service: { name: 'Citizens' },
          entity: {
            name: 'Citizens',
            linksMode: 'raw',
          },
        })
        .pipe(
          mergeMap((result: IAbstractServiceData) => {
            const item = result.action === 'insert' ? result.data : result.data.items[0];
            if (phoneList.length) {
              const dataCitizenPhones = phoneList
                .map((phoneNumber: string, index: number) => {
                  return {
                    phoneNumber,
                    isPrimary: index === 0,
                    citizenId: item?.id,
                  };
                })
                .filter((item) => {
                  return !!item.phoneNumber;
                });
              return this.rest.serviceRequest({
                data: dataCitizenPhones,
                action: 'insert',
                service: { name: 'Citizens' },
                entity: {
                  name: 'CitizensPhones',
                  linksMode: 'raw',
                },
              });
            }
          }),
        );
    }
    const clearData = Object.entries(changedData).reduce((a: IAnyObject, [k, v]) => (v ? ((a[k] = v), a) : a), {});
    return this.rest
      .serviceRequest({
        data: clearData,
        action: 'update',
        service: { name: 'Citizens' },
        entity: {
          name: 'Citizens',
          linksMode: 'raw',
          query: {
            id,
          },
        },
      })
      .pipe(
        mergeMap((result: IAbstractServiceData) => {
          const insertPhones = phoneList
            .map((phoneNumber, index) => {
              return {
                phoneNumber,
                isPrimary: index === 0,
                citizenId: id,
              };
            })
            .filter((item) => {
              return !!item.phoneNumber;
            });

          if (insertPhones.length === 0) {
            return of(null);
          }

          let query: IAnyObject = {
            phoneNumber: { $in: insertPhones.map((phone) => phone.phoneNumber) },
          };

          if (insertPhones.length === 2) {
            query = {
              citizenId: id,
            };
          }

          return this.rest
            .serviceRequest({
              action: 'delete',
              service: { name: 'Citizens' },
              entity: {
                query,
                name: 'CitizensPhones',
                linksMode: 'raw',
              },
            })
            .pipe(
              mergeMap(() => {
                return this.rest.serviceRequest({
                  data: insertPhones,
                  action: 'insert',
                  service: { name: 'Citizens' },
                  entity: {
                    name: 'CitizensPhones',
                    linksMode: 'raw',
                  },
                });
              }),
            );
        }),
      );
  }

  /**
   *  Создание заявителя в Emergency_Declarers
   *  @param data - данные заявителя
   *  @return
   */
  public createEmergencyDeclarer(data: IDeclarer): Observable<string> {
    return this.rest
      .serviceRequest({
        data,
        action: 'insert',
        service: { name: 'Emergency' },
        entity: {
          name: 'Declarers',
          linksMode: 'raw',
        },
      })
      .pipe(
        map((data: IAbstractServiceData) => {
          return data.data.id;
        }),
      );
  }

  /**
   *  Создание заявителя в Emergency_Declarers и обновление ссылки в Emergency_Events
   *  @param data - данные заявителя
   *  @param eventId - id события
   *  @return
   */
  public createEmergencyDeclarerWithLink(data: IDeclarer, eventId: string): Observable<IAbstractServiceData> {
    return this.createEmergencyDeclarer(data).pipe(
      mergeMap((id) => {
        const dataEvent = {
          declarerId: id,
        };
        return this.rest.serviceRequest({
          data: dataEvent,
          action: 'update',
          service: { name: 'Emergency' },
          entity: {
            name: 'Events',
            linksMode: 'raw',
            query: {
              id: eventId,
            },
          },
        });
      }),
    );
  }

  /**
   *  Метод проверяет существования Заявителя по номеру телефона
   *  @param phoneNumber - телефон
   *  @return
   */
  public checkDeclarerByPhone(phoneNumber: string): Observable<IAnyObject[]> {
    if (!phoneNumber) {
      return of([]);
    }
    const phone = formatPhoneDb(phoneNumber);
    return this.getDecklarerPhonesByQuery({ phoneNumber: phone }).pipe(
      map((result) => {
        return result;
      }),
    );
  }

  /**
   * Функция подготавливает данные о заявители для блока информация о заявители
   * @return
   * @param data
   */
  public mapDeclarerData(data: IAnyObject): Observable<IDeclarer> {
    const declarerData: IDeclarer = {
      phone: data?.parentId?.extnum || '',
    };
    return this.checkDeclarerByPhone(declarerData.phone).pipe(
      mergeMap((declarer: IAnyObject) => {
        const phoneNumber = declarer[0]?.phoneNumber || declarerData?.phone;
        const contactPhone = declarerData?.contactPhone || null;
        if (declarer[0]?.citizenId) {
          return this.getDeclarerById(declarer[0]?.citizenId).pipe(
            map((resDeclarer: IAnyObject) => {
              const result = resDeclarer ? resDeclarer : declarerData;
              const fio = [result?.surname, result?.firstName, result?.patronymic].filter((item) => item).join(' ');
              return {
                fio,
                phone: result?.phone,
                contactPhone: result?.contactPhone,
                building: {
                  corp: result?.housing,
                  entrance: result?.entrance,
                  room: result?.registrationApartment,
                },
                ...(result?.registrationAddress ? { address: result?.registrationAddress } : {}),
              };
            }),
          );
        }
        const mergeData = {
          contactPhone,
          fio: '',
          phone: phoneNumber,
          building: {
            corp: '',
            entrance: null,
            room: null,
          },
        };
        return of(mergeData);
      }),
    );
  }

  /**
   *  Проверяю присутствует 8 в номере если да то удаляю ее
   *  @param number - номер
   *  @return
   */
  public sliceFirstNumber(number: string): string {
    const phone = number?.toString();
    if (typeof phone === 'string' && phone[0] === '8' && phone.length > 10) {
      return phone.slice(1);
    }
    return number || null;
  }

  /** Метод разбора имени
   *  @param fio - фио
   *  @return
   */
  private parseFio(fio: string): { surname: string; firstName: string; patronymic: string } {
    const splitedFio = fio ? fio.split(' ') : [];
    return {
      surname: splitedFio[0] ?? '',
      firstName: splitedFio[1] ?? '',
      patronymic: splitedFio[2] ?? '',
    };
  }

  /** Метод конвертирует данные для сохранения в табилицу Citizen
   *  @param data - данные о заявители
   *  @return
   */
  private convertDataForCitizen(data: IDeclarer): ICitizens {
    const fio = this.parseFio(data?.fio);
    return {
      ...(data?.id ? { id: data.id } : {}),
      ...fio,
      registrationAddress: data?.address,
      additionalAddress: data?.address,
      registrationApartment: data?.building?.room,
      entrance: data?.building?.entrance,
      housing: data?.building?.corp,
    };
  }

  /** Создание записи заявителя в разных таблицах
   * @return
   */
  public saveDeclarerInfo(): Observable<IDeclarer> {
    const declareFormData = this.declarerStore.getFormData();
    const phone = this.declarerStore.getLoadedPhone() || declareFormData?.phone || declareFormData?.contactPhone;
    if (phone) {
      return this.checkDeclarerByPhone(phone).pipe(
        mergeMap((res: IAnyObject) => {
          const updatedData = {
            ...declareFormData,
            phone: formatPhoneDb(phone),
            contactPhone: formatPhoneDb(declareFormData.contactPhone || ''),
          };
          if (res.length) {
            return this.updateCitizenDeclarer(res[0]?.citizenId).pipe(map(() => updatedData));
          }
          return this.createCitizenDeclarer(updatedData).pipe(map(() => updatedData));
        }),
        tap(() => this.resetDeclarersStore()),
      );
    }
    return of(null).pipe(tap(() => this.resetDeclarersStore()));
  }

  /**
   * Сбрасывает состояние заявителя в сторе
   * @return
   */
  public resetDeclarersStore(): void {
    this.declarerStore.setFormData(null);
    this.declarerStore.setLoadedPhone('');
    this.$formData.next(null);
  }
}
