<div class="bg-declarer">
  <form [formGroup]="form">
    <div class="bg-row row-bubble">
      <div class="bg-declarer-row bg-declarer-resetPadding">
        <div [ngClass]="{'declarer-phone': !isDeclarerEditable}">
          <sc-input
            [options]="phoneSubscriberOptions"
            formControlName="phone"
            (click)="onClickDeclarerPhone(declarerPhone)"
          ></sc-input>
        </div>
      </div>
    </div>
    <div class="bg-row row-bubble">
      <div class="bg-declarer-row">
        <div class="bg-declarer-input">
          <sc-input [options]="personalInfoOptions" formControlName="fio"></sc-input>
        </div>
      </div>
      <div class="bg-declarer-row">
        <div class="bg-declarer-input">
          <div [ngClass]="{'declarer-phone': options.disabled}">
            <sc-input
              [options]="contactPhoneOptions"
              formControlName="contactPhone"
              (click)="onClickContactPhone(contactPhone)"
            ></sc-input>
          </div>
        </div>
      </div>
      <div class="bg-declarer-row">
        <div class="bg-declarer-input" *ngIf="!options?.disabled">
          <div nz-form nzLayout="vertical">
            <nz-form-item>
              <nz-form-label nzRequired>Адрес</nz-form-label>
              <nz-form-control>
                <nz-address-kit formControlName="address"></nz-address-kit>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="bg-declarer-input" *ngIf="options?.disabled">
          <sc-input [options]="addressOptions" formControlName="address" [attr.title]="addressText"></sc-input>
        </div>
      </div>
      <div class="bg-row bg-declarer-row">
        <div class="bg-col p-r-0">
          <div class="bg-form-group bg-declarer-form-group">
            <sc-input [options]="corpOptions" formControlName="corp"></sc-input>
          </div>
        </div>
        <div class="bg-col p-h-0">
          <div class="bg-form-group bg-declarer-form-group">
            <div class="property">
              <sc-input [options]="entranceOptions" formControlName="entrance"></sc-input>
            </div>
          </div>
        </div>
        <div class="bg-col p-h-0">
          <div class="bg-form-group bg-declarer-form-group">
            <div class="property">
              <sc-input [options]="roomOptions" formControlName="room"></sc-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-row bg-declarer-button" *ngIf="!options?.disabled">
      <sc-button class='declarer-copy-button' [options]="copyAddressOptions" (clickButton)="copyHandler()">
        <div class='icon-copy-container'>
          <div class="icon-copy"></div>
        </div>
        {{ copyAddressOptions.title }}
      </sc-button>
    </div>
  </form>
</div>
