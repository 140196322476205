import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { IKsipTypeDto } from 'smart-city-types';
import { KsipTypesState } from '../../../models/states';
import { KsipTypesStore } from '../store/ksip-types-store.service';

/** Сервис для работы с хранилищем Типов КСиП */
@Injectable({
  providedIn: 'root',
})
export class KsipTypesQuery extends QueryEntity<KsipTypesState> {
  constructor(store: KsipTypesStore) {
    super(store);
  }

  /** Запрос списка КСиП. По умолчанию только активные */
  public getAllKsipTypes(onlyActive: boolean = true): Observable<IKsipTypeDto[]> {
    return this.selectAll({
      filterBy: ({ active }: IKsipTypeDto) => active === onlyActive,
    });
  }

  /** Запрос КСиП по Id */
  public getById(id: string): IKsipTypeDto {
    return this.getEntity(id);
  }

  /** Используется в Интернет-портале или нет */
  public canUseInInternetPortal(id: string): boolean {
    return this.getEntity(id).useInternetPortal;
  }

  /** Запрос детализации КСиП */
  public getKsipTypeDetails(ids: string[]): string[] {
    return (this.getAll({ filterBy: (entity: IKsipTypeDto) => ids.includes(entity.id) }) || [])
      .map((ksipType: IKsipTypeDto) => ksipType.ksipDetails)
      .reduce((acc, val) => acc.concat(val), [])
      .filter((item) => item);
  }
}
