import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/** Пайп для подсвечивания слов в строке */
@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  /** @ignore */
  constructor(private sanitizer: DomSanitizer) {
  }

  /** @ignore */
  transform(value: string, ...args: string[]): SafeHtml {
    if (!args || !args[0]) {
      return value;
    }

    // Разделение выражения на слова по пробелам
    const words: string[] = args[0].split(' ');
    words.forEach((word: string) => {
      if (word) {
        const re = new RegExp(word, 'gi');
        value = value?.replace(re, `<span class="highlight">$&</span>`) || '';
      }
    });

    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
