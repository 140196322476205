import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  Optional,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AccessService, Settings2Service, SfsService } from '@smart-city/core/services';
import { BgMapService, LayersDataService } from '@bg-front/core/services';
import { BaseComponent } from '@bg-front/core/components';
import { Uuid } from '@smart-city/core/utils';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';
import { IVideoDeviceBg, IVideoDevicesCategoriesLinks } from '../../models/interfaces';
import { VideoDevicesService } from '../../services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LayersEnum } from '@bg-front/core/models/enums';
import { MapBaseModel, MapBaseService } from '@smart-city/maps/sc';
import { Coordinates } from '@bg-front/core/models/classes';
import { IMapLayerEntityFilter, IServerData } from '@bg-front/core/models/interfaces';
import { NzModalService } from 'ng-zorro-antd/modal';
import { VideoDevicesListService } from '../../../bg/modules/security-forces/services';
import { AddCameraDialogComponent } from '../add-camera-dialog/add-camera-dialog.component';

/** Компонент формы просмотра видеокамеры */
@UntilDestroy()
@Component({
  selector: 'bg-video-device-info',
  templateUrl: './video-device-info.component.html',
  styleUrls: ['./video-device-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoDeviceInfoComponent extends BaseComponent implements OnInit {
  @ViewChild('liveStream', { static: false }) liveStream: TemplateRef<any>;
  @ViewChild('archiveStream', { static: false }) archiveStream: TemplateRef<any>;

  /** Флаг открытия дровера */
  public openDrawer = false;
  /** Смещение по оси X */
  public offsetX = 364;
  /** Модель видеокамеры */
  public model: IVideoDeviceBg = undefined;
  /** Список категорий видеокамеры */
  public categories: string[] = [];
  /** Список предметов мониторинга */
  public monitoringSubjects: string[] = [];
  /** Системное наименование типа видеосервера */
  public videoServerTypeSysName: string;
  /** Доступность видеопотока */
  public canShowVideo: boolean = false;
  /** Доступность видеоархива */
  public canShowArchiveVideo: boolean = false;
  /** Доступность экспорта видео */
  public canRequestVideoBtn: boolean = false;
  /** Ссылка на поток */
  public source: string | SafeResourceUrl = undefined;
  /** Ссылка на архив */
  public sourceArch: string | SafeResourceUrl = undefined;
  /** Дата начала показа архива */
  public archiveDateStart: number = Date.now();
  /** Режим записи */
  public videoCameraArchiveMode: string;
  /** Статус камеры */
  public videoCameraStatus: string;
  /** Модель карты */
  private mapModel: MapBaseModel;
  /** Ссылка на эталонное фото */
  public referencePhotoUrl: string;

  public canAddToVideoDeviceList = false;

  public blockContext = { style: 'width: 640px; height: 380px; transform: scale(0.433); transform-origin: 0 0' };
  public dialogContext = { style: 'width: 1280px; height: 760px' };
  /** Активный раздел с видеоплеером */
  public activeVideoCollapsePanel: 'live' | 'archive' = 'live';

  /** @ignore */
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly settings2: Settings2Service,
    private readonly videoDevicesService: VideoDevicesService,
    private readonly gisService: BgMapService,
    private readonly accessService: AccessService,
    private readonly sanitizer: DomSanitizer,
    private readonly cdr: ChangeDetectorRef,
    private readonly layersDataService: LayersDataService,
    private readonly mapService: MapBaseService,
    private readonly sfs: SfsService,
    private readonly modalService: NzModalService,
    @Optional() private readonly videoDevicesListService: VideoDevicesListService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.canAddToVideoDeviceList = !!this.videoDevicesListService;

    this.mapModel = new MapBaseModel('baseMapWorkspace', this.mapService);

    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.model = undefined;
        this.videoServerTypeSysName = undefined;
        this.monitoringSubjects = [];
        const deviceId = params.get('id');

        this.mapModel.removeLayer(LayersEnum.camerasFov);

        // Если ID невалидный не выполнять поиск и передать значение, что ничего найдено
        if (!Uuid.isValid(deviceId)) return of(undefined);

        return forkJoin([
          this.videoDevicesService.getVideoDevice(deviceId),
          this.videoDevicesService.getCategories(deviceId, ['categoryId.name']),
        ]);
      }),
      switchMap(([response, videoDevicesCategoriesLinks]: [IVideoDeviceBg, IVideoDevicesCategoriesLinks[]]) => {
        if (!response) {
          this.noteService.pushError('Камера не найдена')
          this.close();
        }
        this.model = response;

        this.videoCameraStatus = this.settings2.getDictObjByTypeSysName('devicesState')[this.model.status]?.name;
        this.videoCameraArchiveMode = this.settings2.getDictObjByTypeSysName('recordArchive')[this.model.archiveMode]?.name;
        if (this.model.monitoringSubjects?.length) {
          const monitoringDict = this.settings2.getDictObjsIdsByType('monitoringSubject');
          this.monitoringSubjects = this.model.monitoringSubjects.map((id: string) => monitoringDict[id]?.name);
        }

        this.referencePhotoUrl = this.model.referencePhotoId
          ? this.sfs.getStreamUrl(this.model.referencePhotoId)
          : '';

        // Отображение угла обзора камеры
        if (this.model.viewDepth !== null && this.model.angleStart !== null && this.model.angleEnd !== null) {
          this.mapModel.addSemiCircleToLayer(
            LayersEnum.camerasFov,
            `fov-${this.model.id}`,
            Coordinates.coordinatesToArray(this.model.coordinates),
            this.model.viewDepth,
            Math.min(this.model.angleStart, this.model.angleEnd),
            Math.max(this.model.angleStart, this.model.angleEnd),
            { color: '#1191E6' },
          );
          this.mapModel.viewLayer(LayersEnum.camerasFov, false);
        }

        if (videoDevicesCategoriesLinks?.length) {
          this.categories = videoDevicesCategoriesLinks.map((category: IAnyObject) => category.categoryId['name']);
        }

        return this.videoDevicesService.getVideoServerById(<string>this.model.videoServer);
      }),
      catchError((err: Error) => {
        return this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка при загрузке данных камеры');
      }),
      untilDestroyed(this),
    ).subscribe(({ data }: IAbstractServiceData) => {
      const server = <IServerData>(data?.items || [])[0];
      this.videoServerTypeSysName = this.settings2.getDictionaryById(<string>server?.type)?.sysname;

      if (this.model.accessRights) {
        this.canRequestVideoBtn = this.model.accessRights.archiveRequestAccess;
        this.canShowVideo = this.model.accessRights.streamAccess;
        this.canShowArchiveVideo = this.model.accessRights.archiveAccess;
      } else {
        this.canShowVideo = !!this.accessService.accessMap['CanShowVideo']?.visible;
        this.canShowArchiveVideo = !!this.accessService.accessMap['CanShowArchiveVideo']?.visible;
        this.canRequestVideoBtn = !!this.accessService.accessMap['CanRequestVideo']?.visible;
      }

      this.canShowVideo = this.canShowVideo && server?.hasVideoStream;
      // Архив может быть запрошен у макроскопа или Лесохранителя.
      this.canShowArchiveVideo =
        this.canShowArchiveVideo
        && server?.hasVideoArchive
        && (this.videoServerTypeSysName === 'macroscop' || this.videoServerTypeSysName === 'forestGuard');

      this.canRequestVideoBtn =
        this.canRequestVideoBtn
        && server?.canRequestVideoArchive
        && (this.videoServerTypeSysName === 'macroscop' || this.videoServerTypeSysName === 'forestGuard');

      // Если камера РТК, надо вывести плеер РТК в iframe
      if (this.videoServerTypeSysName === 'rtc') {
        this.source = this.sanitizer.bypassSecurityTrustResourceUrl(this.model.mediaUrl);
        this.sourceArch = '';
      }

      // Если камера с сервера Лесохранителя, то надо вывести нативный плеер Лесохранителя в iframe
      if (this.videoServerTypeSysName === 'forestGuard') {
        this.source = this.sanitizer.bypassSecurityTrustResourceUrl(this.model.mediaUrl);
        this.sourceArch = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.model.mediaUrl}&dvr=true`);
      }

      // Ранее для воспроизведения видео из макроскопа необходимо получение ссылки на него с помощью логики ниже.
      // В настоящее время для воспроизведения в HLS плеер передается ID устройства и способ воспроизведения
      // (поток или архив).
      // Закомментировано за ненадобностью, но не удалено для истории. При необходимости использовать, учесть
      // необходимость отписаться от запроса при выборе другого устройства (restUnsubscribe)
      // // Получение информации для воспроизведения видео в случае если тип сервера macroscop
      // if (this.videoServerType?.sysname === 'macroscop') {
      //   this.videoDevicesService.getVideoDeviceStreamUrl(this.device.id).pipe(
      //     catchError((err: Error) =>
      //       this.catchErrorFn<string | SafeResourceUrl>(err, 'Ошибка при получении ссылки на поток камеры'),
      //     ),
      //     untilDestroyed(this),
      //   ).subscribe((source: string | SafeResourceUrl) => {
      //     this.source = source || '';
      //     this.cdr.detectChanges();
      //   });
      //   this.videoDevicesService.getVideoDeviceRangeStreamUrl(this.device.id, this.archiveDateStart, 0).pipe(
      //     catchError((err: Error) =>
      //       this.catchErrorFn<string | SafeResourceUrl>(
      //         err,
      //         'Ошибка при получении ссылки на архив потока камеры',
      //       ),
      //     ),
      //     untilDestroyed(this),
      //   ).subscribe((sourceArch: string | SafeResourceUrl) => {
      //     this.sourceArch = sourceArch || '';
      //     this.cdr.detectChanges();
      //   });
      // }
      this.openDrawer = true;
      this.cdr.detectChanges();
    });

    /** При открытии формы инцидента/события, закрываем форму просмотра камеры */
    this.router.events
      .pipe(
        filter((event: IAnyObject) => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe((event: IAnyObject) => {
        if (event.url.match(/editForm|editEventForm|editCallForm/)) {
          this.close();
        }
      });

    /** Подписываемся на изменения фильтра слоев на карте */
    this.layersDataService.mapLayerFilterSelected$.pipe(
      filter((filters: IAnyObject) => !!filters['Видеокамеры']?.length),
      untilDestroyed(this),
    ).subscribe((filters: IAnyObject) => {
      filters['Видеокамеры'].forEach((filter: IMapLayerEntityFilter) => {
        if (filter.property === 'monitoringSubject' && !this.model.monitoringSubjects.includes(filter.value)) {
          this.close();
        } else if (this.model[filter.property].toString() !== filter.value.toString()) {
          this.close();
        }
      });
    });
  }

  /** Добавление видеокамеры в списки */
  public addToListCamera(): void {
    this.videoDevicesListService.getListsByDeviceId(this.model.id).pipe(
      switchMap((list: string[]) =>
        this.modalService.create({
          nzTitle: 'Списки видеокамер',
          nzContent: AddCameraDialogComponent,
          nzComponentParams: { list },
        }).afterClose,
      ),
      switchMap((result: string[]) => {
        if (result?.length) {
          return this.videoDevicesListService.addVideoDeviceToLists(this.model.id, result);
        }
        return of(null);
      }),
      catchError((err: Error) => this.catchErrorFn<unknown>(err, 'Ошибка при добавлении камеры в списки')),
      untilDestroyed(this),
    ).subscribe();
  }

  /** Позиционировать карту на камере */
  public setPositionMapOnCamera(): void {
    this.gisService.setPositionMapOnCoordinates(this.model.coordinates);
  }

  /** Закрытие формы просмотра */
  public close(): void {
    this.mapModel.removeLayer(LayersEnum.clickMarker);
    this.router.navigate(['../..'], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }

  /** Форма запроса на экспорт видео */
  public requestVideo() {
    // TODO: Нужен гвард так как доступ к кнопке определяется с помощью клеймов и прав доступа. Возможна ситуация
    //       когда при скрытой кнопке пользователь перейдет по ссылке на запрос архива.
    this.router.navigate(['requestVideo'], { relativeTo: this.route, queryParamsHandling: 'merge' });
  }

  /**
   * Функция вызывает диалог с плеером
   * @param template - шаблон с содержимым
   */
  public expandDialog(template: TemplateRef<any>) {
    let dialogSize = {};
    switch (this.videoServerTypeSysName) {
      case 'argus-integration':
        dialogSize = { width: '1280px', height: '760px' };
        break;
      case 'macroscop':
        dialogSize = { width: '1280px', height: '720px' };
        break;
    }
    this.modalService.create({
      nzTitle: this.model.name,
      nzContent: template,
      nzFooter: null,
      nzStyle: dialogSize,
      nzBodyStyle: { padding: '0', ...dialogSize },
      nzComponentParams: { ...this.dialogContext },
    })
  }

  /**
   * Обновление плеера
   * @param template - информация о шаблоне в котором расположен плеер
   */
  public refreshPlayer(template: string) {
    if (template === 'liveStream') this.liveStream = undefined;
    if (template === 'archiveStream') this.archiveStream = undefined;
    setTimeout(() => this.cdr.detectChanges(), 0);
  }

  /**
   * Отображение эталонного фото
   * @param template - шаблон с содержимым
   */
  public showReferencePhoto(template: TemplateRef<any>) {
    this.modalService.create({
      nzContent: template,
      nzClosable: false,
      nzFooter: null,
      nzStyle: {
        width: '1280px',
        height: '760px',
        minWidth: '1280px',
        minHeight: '760px',
        maxWidth: '1280px',
        maxHeight: '760px',
      },
      nzBodyStyle: { padding: '0' },
    })
  }

  /** @ignore */
  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.mapModel.removeLayer(LayersEnum.camerasFov);
    this.mapModel.removeLayer(LayersEnum.clickMarker);
  }

  /**
   * Обработка активации раздела аккордеона с плеерами
   * @param active - статус панели
   * @param panelName - наименование панели
   */
  public videoCollapsePanelActivation(active: boolean, panelName: 'live' | 'archive') {
    if (active) {
      this.activeVideoCollapsePanel = panelName;
      setTimeout(() => this.cdr.detectChanges(), 0);
    }
  }
}
