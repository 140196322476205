import { Injector } from '@angular/core';

/**
 * Статический класс хранящий в себе глобальный инжектор
 */
export class AppInjector {
  private static injector: Injector = null;

  static setInjector(injector: Injector) {
    AppInjector.injector = injector;
  }

  static getInjector(): Injector {
    return AppInjector.injector;
  }
}
