import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BgCoreModule } from '@bg-front/core';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { ScMapsModule } from '@smart-city/maps/sc';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

import {
  ForecastingConsequencesOfForestFiresResultComponent,
  ForecastingConsequencesOfForestFiresTaskComponent,
  ForecastingDistanceTraveledByFireFrontResultComponent,
  ForecastingDistanceTraveledByFireFrontTaskComponent,
  ForecastingExtinguishingAgentConsumptionTaskComponent,
  ForecastingExtinguishingAgentConsumptionTaskResultComponent,
  ForecastingTechnologicalFireAreaComponent,
  ForecastingTechnologicalFireAreasTaskComponent,
  ForecastingTechnologicalFireAreasTaskResultComponent,
} from './components';
import {
  ForecastingFiresReportService,
  ForecastingFiresService,
  ForecastingFiresVisualizationService,
} from './services';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ScMapsModule,
    ErrorTailorModule,
    BgCoreModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzTypographyModule,
    NzDividerModule,
    NzNotificationModule,
    NzModalModule,
    NzDescriptionsModule,
    NzToolTipModule,
    NzCheckboxModule,
  ],
  providers: [ForecastingFiresReportService, ForecastingFiresService, ForecastingFiresVisualizationService],
  declarations: [
    ForecastingConsequencesOfForestFiresResultComponent,
    ForecastingConsequencesOfForestFiresTaskComponent,
    ForecastingDistanceTraveledByFireFrontResultComponent,
    ForecastingDistanceTraveledByFireFrontTaskComponent,
    ForecastingExtinguishingAgentConsumptionTaskComponent,
    ForecastingExtinguishingAgentConsumptionTaskResultComponent,
    ForecastingTechnologicalFireAreaComponent,
    ForecastingTechnologicalFireAreasTaskComponent,
    ForecastingTechnologicalFireAreasTaskResultComponent,
  ],
  exports: [
    ForecastingConsequencesOfForestFiresResultComponent,
    ForecastingConsequencesOfForestFiresTaskComponent,
    ForecastingDistanceTraveledByFireFrontResultComponent,
    ForecastingDistanceTraveledByFireFrontTaskComponent,
    ForecastingExtinguishingAgentConsumptionTaskComponent,
    ForecastingExtinguishingAgentConsumptionTaskResultComponent,
    ForecastingTechnologicalFireAreasTaskComponent,
    ForecastingTechnologicalFireAreasTaskResultComponent,
  ],
})
export class ForecastingFiresModule {}
