import { AbstractControl, ValidatorFn } from '@angular/forms';

/** Кастомный валидатор для телефонной маски
 * @param params - количество цифр в номере
 * @return
 */
export function phoneMaskValidatorFn(params: {
  prefix: string;
  maskText: string;
  pattern: string;
}): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return new RegExp(params.pattern).test(control.value)
      ? null
      : { maskPhone: { prefix: params.prefix, maskText: params.maskText } };
  };
}
