import { Injectable } from '@angular/core';
import { Coordinates } from '@bg-front/core/models/classes';
import { SEARCH_MARKER } from '@bg-front/core/models/constants';
import { IForecastingResultDto, IWeatherParamsDto } from '@bg-front/core/models/interfaces';
import { BgMapService } from '@bg-front/core/services';
import ellipse from '@turf/ellipse';
import { Position } from '@turf/helpers';
import rhumbDestination from '@turf/rhumb-destination';
import * as L from 'leaflet';
import { IAnyObject, IRadiationForecastResultsDto } from 'smart-city-types';

import { IRadioactiveCloudTimeTaskDto } from '../../models';

/**
 * Сервис для прогнозирования
 */
@Injectable({
  providedIn: 'root',
})
export class ForecastingRadioactiveEnvironmentVisualizationService {
  private readonly colors = [
    '#000000',
    '#FF0000',
    '#FB4B53',
    '#FD731F',
    '#804040',
    '#066134',
    '#00C8BC',
    '#58BBE4',
    '#0068C8',
    '#A470DA',
    '#463CA9',
  ];

  constructor(private readonly mapService: BgMapService) {}

  /**
   * Кидаем событие, когда нужно отображать результаты расчета рад. обстановки на карте
   * @param result - результаты
   * @param mapModel - модель карты, если не задана то по умолчанию из сервиса
   */
  public displayRadioactiveResults(result: IForecastingResultDto, mapModel: L.Map = this.mapService.mapModel) {
    const typedResult = result.result as IRadiationForecastResultsDto;
    if (result.result) {
      // извлекаем данные из результатов расчета
      const length = typedResult.zoneLength;
      const radius = length / 2;
      const width = typedResult.zoneWidth / 2;
      const degrees = +(result.params.params as IWeatherParamsDto).windDirection;
      const coord = (result.params.coordinates as string).split(',').map((el: string) => +el);

      // смещаем координаты центра эллипса на половину длины
      const coordCenter = rhumbDestination([...coord].reverse(), radius, degrees + 180).geometry.coordinates;

      const ellips = ellipse(coordCenter, radius, width, <any>{
        steps: 10000,
        angle: degrees + 90,
      });

      // координаты вершины эллипса, ужны для построения центральной линии
      const coordTop = rhumbDestination([...coord].reverse(), length, degrees + 180).geometry.coordinates.reverse();

      return [
        L.geoJSON(ellips, {
          fillColor: '#FC5A5A',
          fillOpacity: 0.6,
          weight: 3,
          color: '#FF0000',
        }).addTo(this.mapService.mapModel),
        L.polyline([coord, coordTop], {
          color: '#000',
        }).addTo(mapModel),
      ];
    }
  }

  public shelterCalculatingDoses(result: IForecastingResultDto): any[] {
    const typedResults = result.result as IRadiationForecastResultsDto[];
    const color = ['#463CA9', '#066134', '#FF0000'];
    if (typedResults && typedResults.length) {
      const elements = [];
      // извлекаем данные из результатов расчета
      let degrees: number;
      const coord = (result.params.coordinates as string).split(',').map((el: string) => +el);
      typedResults
        .filter((el: IRadiationForecastResultsDto) => el.zoneLength)
        .forEach((typedResult: IRadiationForecastResultsDto, idx: number) => {
          const length = typedResult.zoneLength;
          const radius = length / 2;
          const width = typedResult.zoneWidth / 2;
          degrees = +(result.params.params as IWeatherParamsDto).windDirection;

          // смещаем координаты центра эллипса на половину длины
          const coordCenter = rhumbDestination([...coord].reverse(), radius, degrees + 180).geometry.coordinates;

          const ellips = ellipse(coordCenter, radius, width, <any>{
            steps: 10000,
            angle: degrees + 90,
          });

          elements.push(
            L.geoJSON(ellips, {
              fillColor: '#FC5A5A',
              fillOpacity: 0,
              weight: 3,
              color: this.colors[idx < 3 ? idx : 2],
            }).addTo(this.mapService.mapModel),
          );
        });

      return elements;
    }
  }

  public propagationTimeDoses(result: IForecastingResultDto): any[] {
    const typedResults = result.result as IRadiationForecastResultsDto[];
    if (typedResults && typedResults.length) {
      const elements = [];
      // извлекаем данные из результатов расчета
      const coord = (result.params.coordinates as string).split(',').map((el: string) => +el);
      typedResults
        .filter((el: IRadiationForecastResultsDto) => el.zoneLength)
        .forEach((typedResult: IRadiationForecastResultsDto, idx: number) => {
          const length = typedResult.zoneLength;
          const radius = length / 2;
          const width = typedResult.zoneWidth / 2;
          const degrees = +(result.params.params as IWeatherParamsDto).windDirection;

          const coordCenter = rhumbDestination([...coord].reverse(), radius, degrees + 180).geometry.coordinates;

          const ellips = ellipse(coordCenter, radius, width, <any>{
            steps: 10000,
            angle: degrees + 90,
          });

          elements.push(
            L.geoJSON(ellips, {
              fillColor: '#FC5A5A',
              fillOpacity: 0,
              weight: 3,
              color: this.colors[idx < 9 ? idx : 9],
            }).addTo(this.mapService.mapModel),
          );
        });

      if (elements.length) {
        elements.push(
          L.circle(coord, {
            radius: 10,
            fillColor: '#463CA9',
            fillOpacity: 1,
            color: '#463CA9',
          }).addTo(this.mapService.mapModel),
        );
      }

      return elements;
    }
  }

  public dangerCalculationDoses(result: IForecastingResultDto): any[] {
    const typedResults = result.result as IRadiationForecastResultsDto[];
    const color = ['#463CA9', '#066134', '#FF0000'];
    if (typedResults && typedResults.length) {
      const elements = [];
      // извлекаем данные из результатов расчета
      const coord = (result.params.coordinates as string).split(',').map((el: string) => +el);
      const degrees = +(result.params.params as IWeatherParamsDto).windDirection;
      typedResults
        .filter((el: IRadiationForecastResultsDto) => el.zoneLength)
        .forEach((typedResult: IRadiationForecastResultsDto, idx: number) => {
          const length = typedResult.zoneLength;
          const radius = length / 2;
          const width = typedResult.zoneWidth / 2;

          // смещаем координаты центра эллипса на половину длины
          const coordCenter = rhumbDestination([...coord].reverse(), radius, degrees + 180).geometry.coordinates;

          const ellips = ellipse(coordCenter, radius, width, <any>{
            steps: 10000,
            angle: degrees + 90,
          });

          elements.push(
            L.geoJSON(ellips, {
              fillColor: '#FC5A5A',
              fillOpacity: 0,
              weight: 3,
              color: color[idx < color.length ? idx : color.length - 1],
            }).addTo(this.mapService.mapModel),
          );
        });

      const coordCenter = rhumbDestination([...coord].reverse(), 0.15, degrees - 180).geometry.coordinates.reverse();

      elements.push(
        L.circle(coordCenter, {
          radius: 200,
          fillColor: '#fff',
          fillOpacity: 1,
          color: '#000',
        }).addTo(this.mapService.mapModel),
      );

      elements.push(
        L.circle(coordCenter, {
          radius: 50,
          fillColor: '#463CA9',
          fillOpacity: 1,
          color: '#463CA9',
        }).addTo(this.mapService.mapModel),
      );

      return elements;
    }
  }

  /**
   * Кидаем событие, когда нужно отображать результаты расчета рад. обстановки на карте
   * @param result - результаты
   * @param recipient - Дети/Взрослые
   */
  public displayThyroidResults(result: IForecastingResultDto, recipient: string) {
    const typedResults = (result.result as IRadiationForecastResultsDto[]).filter(
      (el: IRadiationForecastResultsDto) => el.recipient === recipient,
    );
    if (typedResults && typedResults.length) {
      const elements = [];
      // извлекаем данные из результатов расчета
      const coord = (result.params.coordinates as string).split(',').map((el: string) => +el);
      const degrees = +(result.params.params as IWeatherParamsDto).windDirection;
      typedResults
        .filter((el: IRadiationForecastResultsDto) => el.zoneLength)
        .forEach((typedResult: IRadiationForecastResultsDto, idx: number) => {
          const length = +typedResult.zoneLength;
          const radius = length / 2;
          const width = typedResult.zoneWidth / 2;

          // смещаем координаты центра эллипса на половину длины
          const coordCenter = rhumbDestination([...coord].reverse(), radius, degrees + 180).geometry.coordinates;

          const ellips = ellipse(coordCenter, radius, width, <any>{
            steps: 10000,
            angle: degrees + 90,
          });

          elements.push(
            L.geoJSON(ellips, {
              fillColor: '#FC5A5A',
              fillOpacity: 0,
              weight: 3,
              color: this.colors[idx < 9 ? idx : 9],
            }).addTo(this.mapService.mapModel),
          );
        });

      return elements;
    }
  }

  /**
   * Кидаем событие, когда нужно отображать результаты расчета рад. обстановки на карте
   * @param result - результаты
   * @param recipient - Дети/Взрослые
   */
  public iodineProphylaxisCalculationDoses(result: IForecastingResultDto, recipient: string) {
    const typedResults = (result.result as IRadiationForecastResultsDto[]).filter(
      (el: IRadiationForecastResultsDto) => el.recipient === recipient,
    );
    const color = ['#463CA9', '#066134'];
    if (typedResults && typedResults.length) {
      const elements = [];
      // извлекаем данные из результатов расчета
      const coord = (result.params.coordinates as string).split(',').map((el: string) => +el);
      const degrees = +(result.params.params as IWeatherParamsDto).windDirection;
      typedResults
        .filter((el: IRadiationForecastResultsDto) => el.zoneLength)
        .forEach((typedResult: IRadiationForecastResultsDto, idx: number) => {
          const length = +typedResult.zoneLength;
          const radius = length / 2;
          const width = typedResult.zoneWidth / 2;

          // смещаем координаты центра эллипса на половину длины
          const coordCenter = rhumbDestination([...coord].reverse(), radius, degrees + 180).geometry.coordinates;

          const ellips = ellipse(coordCenter, radius, width, <any>{
            steps: 10000,
            angle: degrees + 90,
          });

          elements.push(
            L.geoJSON(ellips, {
              fillColor: '#FC5A5A',
              fillOpacity: 0,
              weight: 3,
              color: this.colors[idx < 9 ? idx : 9],
            }).addTo(this.mapService.mapModel),
          );
        });

      return elements;
    }
  }

  /**
   * Кидаем событие, когда нужно отображать результаты расчета рад. обстановки на карте
   * @param result - результаты
   * @param recipient - Дети/Взрослые
   */
  public displayThyroidResultsForManual(result: IForecastingResultDto, recipient: string) {
    const typedResult = (result.result as IRadiationForecastResultsDto[]).find(
      (el: IRadiationForecastResultsDto) => el.recipient === recipient,
    );
    if (typedResult) {
      // извлекаем данные из результатов расчета
      const length = typedResult.zoneLength;
      const radius = length / 2;
      const width = typedResult.zoneWidth / 2;
      const degrees = +(result.params.params as IWeatherParamsDto).windDirection;
      const coord = (result.params.coordinates as string).split(',').map((el: string) => +el);

      // смещаем координаты центра эллипса на половину длины
      // смещаем координаты центра эллипса на половину длины
      const coordCenter = rhumbDestination([...coord].reverse(), radius, degrees + 180).geometry.coordinates;

      const ellips = ellipse(coordCenter, radius, width, <any>{
        steps: 10000,
        angle: degrees + 90,
      });

      return [
        L.geoJSON(ellips, {
          fillColor: '#FC5A5A',
          fillOpacity: 0.6,
          weight: 3,
          color: '#FF0000',
        }).addTo(this.mapService.mapModel),
      ];
    }
  }

  /**
   * Убираем результат
   */
  public clearResults(el: any | any[]) {
    if (el) {
      if (el instanceof Array) {
        el.forEach((item: IAnyObject) => this.mapService.mapModel.removeLayer(item));
      } else {
        this.mapService.mapModel.removeLayer(el);
      }
    }
  }

  /** Получение координат зоны */
  public coordinatesRadioactiveResults(result: IForecastingResultDto) {
    const typedResult = result.result as IRadiationForecastResultsDto;
    if (result.result) {
      // извлекаем данные из результатов расчета
      const length = typedResult.zoneLength;
      const radius = length / 2;
      const width = typedResult.zoneWidth / 2;
      const degrees = +(result.params.params as IWeatherParamsDto).windDirection;
      const coord = (result.params.coordinates as string).split(',').map((el: string) => +el);

      // смещаем координаты центра эллипса на половину длины
      const coordCenter = rhumbDestination([...coord].reverse(), radius, degrees + 180).geometry.coordinates;

      const ellips = ellipse(coordCenter, radius, width, <any>{
        steps: 10000,
        angle: degrees + 90,
      });

      return ellips.geometry.coordinates[0].map((coord: Position) => {
        return coord.reverse();
      });
    }
  }

  /**
   * Кидаем событие, когда нужно отображать результаты расчета рад. обстановки на карте
   * @param result - результаты
   * @param recipient - Дети/Взрослые
   */
  public coordinatesThyroidResultsForManual(result: IForecastingResultDto, recipient: string) {
    const typedResult = (result.result as IRadiationForecastResultsDto[]).find(
      (el: IRadiationForecastResultsDto) => el.recipient === recipient,
    );
    if (typedResult) {
      // извлекаем данные из результатов расчета
      const length = typedResult.zoneLength;
      const radius = length / 2;
      const width = typedResult.zoneWidth / 2;
      const degrees = +(result.params.params as IWeatherParamsDto).windDirection;
      const coord = (result.params.coordinates as string).split(',').map((el: string) => +el);

      // смещаем координаты центра эллипса на половину длины
      const coordCenter = rhumbDestination([...coord].reverse(), radius, degrees + 180).geometry.coordinates;

      const ellips = ellipse(coordCenter, radius, width, <any>{
        steps: 9999,
        angle: degrees + 90,
      });

      return ellips.geometry.coordinates[0].map((coord: Position) => {
        return coord.reverse();
      });
    }
  }

  /** Визуализация прогнозирования "Время подхода радиоактивного облака" */
  public displayRadioactiveCloudTime(result: IForecastingResultDto) {
    const degrees = (result.params.params as IRadioactiveCloudTimeTaskDto).windDirection - 90;
    const length = +(result.params.params as IRadioactiveCloudTimeTaskDto).distanceToReactor;
    ``;
    const start = new Coordinates(result.params.coordinates).toArray();
    const end = rhumbDestination(
      new Coordinates(result.params.coordinates).toArray().reverse(),
      length,
      degrees + 180,
    ).geometry.coordinates.reverse() as [number, number];

    return this.displayPath([start, end]);
  }

  /** Построение линии */
  public displayPath(path: [number, number][]) {
    const elems = [
      L.polyline(path, {
        color: '#000',
      }).addTo(this.mapService.mapModel),
    ];

    path.forEach((el: [number, number]) => {
      elems.push(this.displayPoint(el.join(', ')));
    });

    return elems;
  }

  /**
   * Отображение маркера на карте
   * @param coordinates координаты
   * @param iconSize (optional) размеры иконки
   * */
  public displayPoint(coordinates: string, iconSize: [number, number] = [40, 40]) {
    const icon = L.divIcon({
      iconSize,
      className: 'leaflet-node-marker',
      html: L.Util.template(SEARCH_MARKER.iconOptions.shapeSVG),
      iconAnchor: [12, 32],
      popupAnchor: [0, -28],
    });

    return L.marker(new Coordinates(coordinates).toArray(), {
      icon,
    }).addTo(this.mapService.mapModel);
  }
}
