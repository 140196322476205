import { Pipe, PipeTransform } from '@angular/core';

/** Пайп для конвертации размера файла в строку с нужным форматом */
@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
  transform(size: number | string): string {
    switch (true) {
      case +size >= 1024 * 1024 * 1024:
        return `${(+size / (1024 * 1024 * 1024)).toFixed(2)} ГБ`;
      case +size >= 1024 * 1024:
        return `${(+size / (1024 * 1024)).toFixed(2)} МБ`;
      case +size >= 1024:
        return `${(+size / 1024).toFixed(2)} КБ`;
      default:
        return `${size} Б`;
    }
  }
}
