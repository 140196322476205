<div class="container">
  <nw-header [options]="headerOptions" (clickButton)="delete()"></nw-header>
  <form [formGroup]="form">
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select formControlName="stateNumber" [options]="stateNumberOptions"></sc-select>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select formControlName="typeId" [options]="typeOptions"></sc-select>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select formControlName="brandId" [options]="brandOptions"></sc-select>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select formControlName="organizationId" [options]="organizationOptions"></sc-select>
        </div>
      </div>
    </div>
  </form>
</div>
