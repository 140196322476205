import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IScTextareaOptions } from '@smart-city/core/common';

@Component({
  selector: 'bg-internet-portal-comment-dialog',
  templateUrl: './internet-portal-comment-dialog.component.html',
  styleUrls: ['./internet-portal-comment-dialog.component.scss'],
})
export class InternetPortalCommentDialogComponent {
  /**
   * Настройка компоненты Комментарий
   */
  public optionsComment: IScTextareaOptions = {
    label: 'Комментарий',
    maxLength: 1000,
    rows: 2,
  };

  /**
   * Комментарий
   */
  comment: string = 'Обращение передано в работу';

  constructor(public dialogRef: MatDialogRef<InternetPortalCommentDialogComponent>) {}

  /**
   * Закрытие
   */
  public close() {
    this.dialogRef.close();
  }

  /**
   * Сохранение
   */
  public save() {
    this.dialogRef.close(this.comment);
  }
}
