import { ElementRef } from '@angular/core';
import {
  ICanvasRectangle,
  IMjpegPlayerObjectUrl,
  IMjpegPlayerObjectUrlParamTime,
  IRectangleSizes,
} from '../interfaces';
import { SafeUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import * as dayjs from 'dayjs';

/** Управление mjpeg-потоком */
export class ImgStream {
  /** интервал времени в мс, с которым происходит запрос и отображение кадра */
  private frameTimer: ReturnType<typeof setInterval>;
  /** счетчик времени в мс проигронного фрагмента видео */
  private lostTimer: ReturnType<typeof setInterval>;
  /** ссылка на контекст canvas елемента */
  private context: CanvasRenderingContext2D;
  /** ссалка на canvas елемент */
  private player: ElementRef<HTMLCanvasElement>;
  /** Ссылка на обертку для получения размеров */
  private wrapper: ElementRef;
  /** адрес на видео-поток */
  private url: string;
  /** последнее запрашиваемое время */
  private lastFullTime: number = 0;
  /** Частота запроса кадров в миллисекундах */
  private refreshRate: number = 0;
  /** Временной сдвиг в мс для перемотки */
  private moveTime: number = 30 * 1000;
  /** проигранное время */
  private timeLost: number = 0;
  /** итоговый размер рамки от аналитики */
  private sizeStroke: ICanvasRectangle;
  /* разобранная ссылка на поток */
  private readonly objectUrl: IMjpegPlayerObjectUrl;
  /** ссылка на кадр видео-потока */
  private readonly img: HTMLImageElement;
  /** координаты рамки, для аналитики оставленных предметов */
  private readonly strokeCoords: string[] = [];
  /** цвет рамки, для аналитики оставленных предметов */
  private readonly strokeClr: string;
  /** запрашиваемое время у макроскопа */
  private startTimeUrl: string;
  /** события проигрователя */
  public subject: Subject<string> = new Subject<string>();

  public get size(): { width: number; height: number } {
    return { width: this.player.nativeElement.width, height: this.player.nativeElement.height };
  }
  /**
   * Метод преобразует в строку значение даты и времеи в нужном формате.
   * @param startTime — время (мс), с которого следует показать кадр.
   * @return строка с датой и временем.
   */
  public encodeStartTime: (startTime: number) => string;
  /** Ключ которым в URL задан параметр времени начала видеопотока */
  public startTimeKey: string;
  /** Собирает URL с новым значением времени начала кадра */
  public createUrl: (query: string, objectUrl: IMjpegPlayerObjectUrl) => string;

  /** @ignore */
  constructor(private options: {
    url: SafeUrl,
    player: ElementRef<HTMLCanvasElement>,
    context: CanvasRenderingContext2D,
    wrapper: ElementRef,
    autoPlay?: boolean,
    objectUrl?: IMjpegPlayerObjectUrl,
    stroke?: string[],
    strokeClr: string,
    encodeStartTime: (startTime: number) => string,
    startTimeKey: string,
    createUrl: (query: string, objectUrl: IMjpegPlayerObjectUrl) => string,
  }) {
    this.img = new Image();
    this.img.src = options.url as string;
    this.context = options.context;
    this.player = options.player;
    this.wrapper = options.wrapper;
    this.url = options.url as string;
    this.objectUrl = options.objectUrl;
    this.strokeCoords = options.stroke;
    this.strokeClr = options.strokeClr;
    if (options.autoPlay) {
      this.start();
    }
    this.encodeStartTime = options.encodeStartTime;
    this.startTimeKey = options.startTimeKey;
    this.createUrl = options.createUrl;
  }
  /**
   * Метод запускает видеопоток.
   */
  public start(): void {
    this.setRunning(true);
  }

  /**
   * Метод останавливает видеопоток и обновляет время в ссылке на него.
   * @param isMove — признак перемотки, если передан, то ссылка на поток не обновляется
   */
  public stop(isMove?: boolean): void {
    this.setRunning(false);
    // В режиме архива переопределяем ссылку, но не в случае перемотки
    if (!isMove && this.objectUrl.params[this.startTimeKey]) {
      // Контроль прошедшего времени
      const resultTime = this.timeLost + (this.lastFullTime || +dayjs(this.objectUrl.params[this.startTimeKey]));
      this.startTimeUrl = this.encodeStartTime(resultTime);
      this.url = this.genNewUrl(this.startTimeUrl);
    }
  }

  /**
   * Метод отматывает видеопоток назад на величину moveTime.
   */
  public moveBack(): void {
    const resultTime = this.timeLost
      + (this.lastFullTime || +dayjs(this.objectUrl.params[this.startTimeKey]))
      - this.moveTime;
    if (resultTime <  +dayjs(this.objectUrl.params[this.startTimeKey])) {
      this.lastFullTime = +dayjs(this.objectUrl.params[this.startTimeKey]);
    } else {
      this.lastFullTime = resultTime;
    }
    this.keepRunning(this.lastFullTime);
  }

  /**
   * Метод отматывает видеопоток вперед на величину moveTime.
   */
  public moveForward(): void {
    const resultTime = this.timeLost
      + this.moveTime + (this.lastFullTime || +dayjs(this.objectUrl.params[this.startTimeKey]));

    if (this.objectUrl.params.period && resultTime >= this.objectUrl.params.period) {
      this.lastFullTime = +dayjs(this.objectUrl.params[this.startTimeKey])
        + this.objectUrl.params.period;
      this.getLastFrame(this.lastFullTime);
    } else {
      this.lastFullTime = resultTime;
      this.keepRunning(resultTime);
    }
  }

  /**
   * Метод возобновляет видеопоток c момента переданного времени.
   * Срабатывает при нажатии на кнопки перемотке.
   * @param fromTime — время (мс), с которого следует начать воспроизведение видеопотока.
   */
  private keepRunning(fromTime: number): void {
    this.startTimeUrl = this.encodeStartTime(fromTime);
    const newUrl = this.genNewUrl(this.startTimeUrl);
    this.stop(true);
    this.url = newUrl;
    this.resetTimeLost();
    this.start();
  }

  /**
   * Метод останавливает видеопоток и показывает послений кадр c момента переданного времени.
   * Срабатывает при перемотке в слечае, когда вышли за границу переданного периода.
   * @param fromTime — время (мс), с которого следует показать кадр.
   */
  private getLastFrame(fromTime: number): void {
    this.resetTimeLost();
    clearInterval(this.frameTimer);
    clearInterval(this.lostTimer);
    this.startTimeUrl = this.encodeStartTime(fromTime);
    this.img.src = this.genNewUrl(this.startTimeUrl);
    this.updateFrame(this.img);
  }

  /**
   * Метод запускает/останавливает поток загрузок изображений.
   * @param toggleStream — переключение между запуском и останавлкой потока загрузок.
   */
  private setRunning(toggleStream: boolean): void {
    if (toggleStream) {
      this.img.src = this.url;
      this.frameTimer = setInterval(() => {
        this.updateFrame(this.img);
        this.updateStroke();
      }, this.refreshRate);
      this.lostTimer = setInterval(() => {
        this.timeLost += 250;
      }, 250);
    } else {
      this.img.src = '#';
      clearInterval(this.frameTimer);
      clearInterval(this.lostTimer);
    }
  }

  /**
   * Обновление кадра в плеере.
   * @param img — изображение(кадр из потока).
   */
  private updateFrame(img: HTMLImageElement): void {
    if (this.player.nativeElement.width !== this.wrapper.nativeElement.offsetWidth) {
      this.player.nativeElement.width = this.wrapper.nativeElement.offsetWidth;
    }

    if (this.player.nativeElement.height !== this.wrapper.nativeElement.offsetHeight) {
      this.player.nativeElement.height = this.wrapper.nativeElement.offsetHeight;
    }

    /** Размер исходного кадра */
    const srcRect: ICanvasRectangle = {
      x: 0, y: 0,
      width: img.naturalWidth,
      height: img.naturalHeight,
    };

    /** Размер кадра в месте назначения */
    const dstRect: ICanvasRectangle = this.scaleRect(srcRect, {
      width: this.player.nativeElement.width,
      height: this.player.nativeElement.height,
    });

    /** Размер рамки внутри кадра */
    let alarmStroke = undefined;
    if (this.strokeCoords.length === 4) {
      alarmStroke = {
        x: this.parseRelativeSize(this.strokeCoords[0], img.naturalWidth),
        y: this.parseRelativeSize(this.strokeCoords[1], img.naturalHeight),
        width: this.parseRelativeSize(this.strokeCoords[2], img.naturalWidth),
        height: this.parseRelativeSize(this.strokeCoords[3], img.naturalHeight),
      };
      this.sizeStroke = this.scaleStroke(srcRect, dstRect, alarmStroke);
    }

    try {
      this.context.drawImage(img,
        srcRect.x,
        srcRect.y,
        srcRect.width,
        srcRect.height,
        dstRect.x,
        dstRect.y,
        dstRect.width,
        dstRect.height,
      );
    } catch (e) {
      // Если не получилось отрисовать, больше не обновляем, сообщаем об ошибке.
      this.subject.next('error');
      this.stop();
      throw e;
    }
  }
  /**
   * Обновление рамки в плеере.
  */
  private updateStroke(): void {
    if (this.sizeStroke) {
      this.context.strokeStyle = this.strokeClr;
      this.context.lineWidth = 2;
      this.context.strokeRect(
        Math.round(this.sizeStroke.x),
        Math.round(this.sizeStroke.y),
        Math.round(this.sizeStroke.width),
        Math.round(this.sizeStroke.height),
      );
    }
  }

  /**
   * Масштабирование исходного изображения под размер плеера с сохранением пропорций.
   * Метод срабатывает при обновление кардра (updateForm).
   * @param srcSize — размер исходного изображения.
   * @param dstSize — размеры месты назначения.
   * @return размер итогового изображения с сохраниеим пропорций.
   */
  private scaleRect(srcSize: ICanvasRectangle, dstSize: IRectangleSizes): ICanvasRectangle {
    /** Соотношение сторон */
    const ratio = Math.min(dstSize.width / srcSize.width,
      dstSize.height / srcSize.height);
    /** Итоговый размер прямоугольника */
    const newRect: ICanvasRectangle = {
      x: 0, y: 0,
      width: srcSize.width * ratio,
      height: srcSize.height * ratio,
    };
    /** Центрирования итогового изображения внутри плеера */
    newRect.x = (dstSize.width / 2) - (newRect.width / 2);
    newRect.y = (dstSize.height / 2) - (newRect.height / 2);
    return newRect;
  }

  /**
   * Масштабирование рамки от с сохранением пропорций.
   * Метод срабатывает при обновление кардра.
   * @param srcSize — размер исходного изображения.
   * @param dstSize — размеры месты назначения.
   * @param strSize — размеры рамки по координатам от аналитики.
   * @return итоговый размер рамки с сохраниеим пропорций.
   */
  private scaleStroke(
    srcSize: ICanvasRectangle,
    dstSize: ICanvasRectangle,
    strSize: ICanvasRectangle,
  ): ICanvasRectangle {
    /** Соотношение сторон */
    const ratio = Math.min(dstSize.width / srcSize.width,
      dstSize.height / srcSize.height);
    /** Итоговый размер рамки */
    const newRect: ICanvasRectangle = {
      /* TODO Позже надо понять, что за координаты в макроскопе лежат.
       * Если для dstSize добавить умножение на ratio (для x и y), фокус будет на шредере */
      x: strSize.x * ratio + dstSize.x,
      y: strSize.y * ratio + dstSize.y,
      width: strSize.width * ratio,
      height: strSize.height * ratio,
    };
    /** Дельта в каждое направления, для дрожания рамки */
    // const delta = 2;
    /*
    newRect.x = getRandomInt((newRect.x - delta), (newRect.x + delta));
    newRect.y = getRandomInt((newRect.y - delta), (newRect.y + delta));
    */
    return newRect;

    // function getRandomInt(min, max) {
    //   return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min))) + Math.ceil(min);
    // }
  }

  /**
   * Метод переводит относительную координату от аналитики, в точное значение для родителького кадра.
   * @param child — относительный размер от аналитики.
   * @param parent — размер кадра.
   * @return когдинату соответствующу размерам кадра.
   */
  private parseRelativeSize(child: string | number, parent: number): number {
    const ch: number = (typeof child === 'string') ? parseFloat(child.replace(',', '.')) : child;
    return Math.round(parent * ch);
  }

  /**
   * Метода генерирует новую ссылку для видео при перемотке, для запроса фрагмента соответствующего фрагмента.
   * @param time — время начала нужного фрагмента видео.
   * @return итоговую ссылку.
   */
  private genNewUrl(time: string): string {
    let query: string = '';
    Object.keys(this.objectUrl['params']).forEach((key: string) => {
      query += query ? '&' : '';
      if (key === this.startTimeKey) {
        query += `${this.startTimeKey}=${time}`;
      } else {
        query += `${key}=${this.objectUrl['params'][key]}`;
      }
    });

    return this.createUrl(query, this.objectUrl);
  }

  /**
   * Сброс таймера проигования фрагмента
   */
  private resetTimeLost(): void {
    this.timeLost = 0;
  }
}
