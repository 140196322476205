import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

import { KsipCategoriesState } from '../../../models/states';

/** Сервис для работы с Категориями КСиП */
@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'ksip-categories' })
export class KsipCategoriesStore extends EntityStore<KsipCategoriesState> {
  constructor() {
    super([]);
  }
}
