import { Pipe, PipeTransform } from '@angular/core';

/**
 * Пайп для отображения тултипа в селекте для организаций
 */
@Pipe({
  name: 'commaToDot',
})
export class CommaToDotPipe implements PipeTransform {

  /**
   *@param val - число в либом формате
   *@return string | number | undefined
   */
  transform(val: string | number | undefined): string | number | undefined {
    if (!val) {
      switch (typeof val) {
        case 'string':
          return '';
        case 'number':
          return 0;
      }

      return undefined;
    }

    switch (typeof val) {
      case 'string':
        return val.trim().replace(/,/g, '.');
      case 'number':
        return val;
    }
  }
}
