import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IModifiedData } from '@smart-city/core/interfaces';
import { RestService, SubscriberService } from '@smart-city/core/services';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { IAbstractServiceData } from 'smart-city-types';

import { IVideoDeviceBg } from '../../../models/interfaces';
import { VideoDevicesStore } from '../store/video-devices.store';

/**
 * Сервис по обновлению хранилища видеоустройств
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class VideoDevicesStoreService {
  /** Флаг инициализации хранилища */
  private isInit = false;

  constructor(
    private readonly rest: RestService,
    private readonly store: VideoDevicesStore,
    private readonly subs: SubscriberService,
  ) {}

  /**
   * Загружаем список Типов инцидентов
   */
  public initStore(): Observable<boolean> {
    this.store.setLoading(true);

    if (!this.isInit) {
      this.isInit = true;
      this.subs
        .onTableChange<IVideoDeviceBg>('Admin', 'VideoDevices')
        .pipe(untilDestroyed(this))
        .subscribe((result: IModifiedData<IVideoDeviceBg>) => {
          this.store.setLoading(true);
          if (result.action !== 'delete') {
            this.store.upsert(
              result.data.id,
              (oldState: Partial<IVideoDeviceBg>) => ({ ...oldState, ...result.data }),
              (id: string, newState: IVideoDeviceBg) => ({ id, ...newState }),
            );
          } else {
            this.store.remove(result.data.id);
          }
          this.store.setLoading(false);
        });
    }

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'VideoDevices',
          sort: { field: 'name', direction: 'asc' },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          this.store.add(response?.data?.items as IVideoDeviceBg[]);
          return true;
        }),
        catchError(() => {
          return of(false);
        }),
        finalize(() => {
          this.store.setLoading(false);
        }),
      );
  }
}
