import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@bg-front/core/components';
import { IStepDialogDataOptions } from '../../models/interfaces';

/**
 * Форма которая открывается по клику на элемент в блоке 'Привлекаемые службы' при условии
 * ЕСЛИ записи в справочнике "Параметры типов организаций" с выбранным "Типом службы реагирования" соответствует
 * чекбокс "Информационное взаимодействие" = да по выбранному "Типу службы реагирования" и
 * не отправлено поручение в службу
 */

@Component({
  selector: 'bg-involve-org-without-commission-dialog',
  templateUrl: './involve-org-without-commission-dialog.component.html',
})
export class InvolveOrgWithoutCommissionDialogComponent extends BaseComponent {
  /** Форма */
  public involvedWithoutForm: FormGroup;

  /** Опции для формы */
  public options: IStepDialogDataOptions;

  constructor(
    public readonly dialogRef: MatDialogRef<IStepDialogDataOptions>,
    @Inject(MAT_DIALOG_DATA) public data: IStepDialogDataOptions,
  ) {
    super();
  }

  /** Метод закрытия диалога */
  public closeHandler(): void {
    this.dialogRef.close();
  }
}
