<div class="incident-container">
  <div
    class="incident"
    [ngClass]="{ incident__active: isActive }"
    (click)="onClickIncident($event)"
  >
    <div class="incident__icon">
      <span class="sc-icon-order" *ngIf="isOrderCard; else isIncidentCard"></span>
      <ng-template #isIncidentCard>
        <span class="sc-icon-incident"></span>
      </ng-template>
    </div>
    <div
      class="incident__marker"
      *ngIf="isActive || showEvents"
      [ngClass]="{ 'incident__marker-show-events': showEvents && !isActive && selectedEvent }"
      [ngStyle]="{ 'background-color': getMarkerColor() }"
    ></div>
    <div class="incident__header">
      <span class="incident__date">
        {{ incidentTimeCreate }}
      </span>
      <span
        class="incident__state"
        [ngClass]="{ incident__state__new: incident['lifeCycleStepId.status.sysname'] === 'new' }"
      >
        {{ incident['lifeCycleStepId.name'] }}
      </span>
      <i *ngIf="!isOrderCard;"
        class="incident__events sc-icon-chevron"
        [ngClass]="{ incident__events__open: !showEvents }"
        (click)="onEventsClick($event)"
      >
      </i>
    </div>
    <p class="incident__type">
      {{ incident['incidentTypeId.name'] }}
    </p>
    <p
      class="incident__address"
      [ngClass]="{ 'incident__address--link': incident.coordinates}"
      (click)="onClickAddress($event)"
    >
      {{ incident['addressFact.fullText'] }}
    </p>
  </div>
  <div class="incident-events-container" *ngIf="showEvents">
    <div
      class="events-item"
      *ngFor="let event of events"
      (click)="selectEvent($event, event)"
      [ngClass]="{ 'events-item-active': event['id'] === selectedEvent }"
    >
      <div
        class="events-item__marker"
        [ngClass]="{ 'events-item__marker-show': event['id'] !== selectedEvent }"
        [ngStyle]="{ 'background-color': getMarkerColor() }"
      ></div>
      <div class="events-item__type" [ngSwitch]="event['direction']">
        <i class="sc-icon-user" *ngSwitchDefault></i>
        <i class="sc-icon-arrow-right-45" *ngSwitchCase="'outgoing'"></i>
        <i
          class="sc-icon-arrow-right-to-left-45"
          *ngSwitchCase="'incoming'"
          [ngClass]="{ 'sc-icon-arrow-left-135': !event['isHandled'] }"
        ></i>
        <i class="sc-icon-sort-h" *ngSwitchCase="'local'"></i>
      </div>
      <div class="events-item__time">
        {{ event.eventKsipTime }}
      </div>
      <div class="events-item__from">{{ event['sourceId.name'] }}</div>
    </div>
  </div>
</div>
