import { Injectable } from '@angular/core';
import { IOrganization, IResponseWithTotal } from '@bg-front/core/models/interfaces';
import { BaseCrudService } from '@bg-front/core/services';
import { ILoadGridDataParams } from '@smart-city/core/common';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { Uuid } from '@smart-city/core/utils';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, pluck, switchMap } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';
import { IAbstractServiceData, IAbstractServiceEntitySelectResult } from 'smart-city-types/services/abstract';

import { IIncidentType } from '../../../../../../app-common/models/interfaces';
import { IHcsObjectKindsDto } from '../../../../dictionaries/modules/hcs-object-kinds/models/interfaces';
import {
  ISoundNotification,
  ISoundNotificationForTable,
  ISourcesAndSignals,
  ISourcesAndSignalsForTable,
} from '../models/interfaces';

/**
 * Сервис для работы со справочником звуковых уведомлений
 */
@Injectable()
export class SoundNotificationsService extends BaseCrudService {
  /** @ignore */
  constructor(rest: RestService, private readonly settings: Settings2Service) {
    super(
      {
        serviceName: 'Emergency',
        entityName: 'SoundNotifications',
      },
      rest,
    );
  }

  /**
   * Получение списка звуковых уведомлений для грида
   */
  public getSoundNotifications(
    query: IAnyObject,
    params: ILoadGridDataParams,
  ): Observable<IResponseWithTotal<ISoundNotificationForTable[]>> {
    // ...Array и ...String.
    // ...Array нужен для вывода на фронте в требуемом формате.
    // ...String нужен для сортировки.
    return this.rest
      .serviceRequest({
        action: 'getSoundNotifications',
        service: { name: 'Emergency' },
        data: { query, params },
      })
      .pipe(
        map((result: IAbstractServiceData<IAbstractServiceEntitySelectResult>) => ({
          items: result.data.items.map((soundNotification: IAnyObject) => ({
            ...soundNotification,
            organizationsString: this.getFirstElAndDotsAdd(soundNotification.organizationsArray),
            groupsString: this.getFirstElAndDotsAdd(soundNotification.groupsArray),
            isActive: soundNotification.isActive ? 'Да' : 'Нет',
          })),
          totalCount: result.data.totalCount,
        })),
      );
  }

  /**
   * Получение списка источников и сигналов для грида
   */
  public getSourcesAndSignals(
    query: IAnyObject,
    params?: ILoadGridDataParams,
  ): Observable<IResponseWithTotal<ISourcesAndSignalsForTable[]>> {
    // ...Array и ...String.
    // ...Array нужен для вывода на фронте в требуемом формате.
    // ...String нужен для сортировки.

    // Список состояний для донесений и происшествий
    const steps = this.settings
      .getDictionaryByTypeSysName('statusLifeCycleStep')
      .reduce((prev, item) => ({ ...prev, [item.id]: item.name }), {});

    return this.rest
      .serviceRequest({
        action: 'getSourcesAndSignals',
        service: { name: 'Emergency' },
        data: { query, params },
      })
      .pipe(
        map((sourceAndSignals: IAbstractServiceData<IAbstractServiceEntitySelectResult>) => ({
          items: sourceAndSignals.data.items.map((sourceAndSignal: IAnyObject) => ({
            ...sourceAndSignal,
            id: sourceAndSignal.id,
            docTypesString: this.getFirstElAndDotsAdd(sourceAndSignal.docTypesArray),
            sourcesString: this.getFirstElAndDotsAdd(sourceAndSignal.sourcesArray),
            incidentTypesString: this.getFirstElAndDotsAdd(sourceAndSignal.incidentTypesArray),
            informationStatementTypesString: this.getFirstElAndDotsAdd(sourceAndSignal.informationStatementTypesArray),
            informationStatementThemesString: this.getFirstElAndDotsAdd(
              sourceAndSignal.informationStatementThemesArray,
            ),
            statusLifeCycleStepsString: sourceAndSignal.statusLifeCycleStepIds
              ? this.getFirstElAndDotsAdd(
                  sourceAndSignal.statusLifeCycleStepIds?.map((stepId: string) => steps[stepId]),
                )
              : null,
          })),
          totalCount: sourceAndSignals.data.totalCount,
        })),
      );
  }

  /**
   * Получение источников и сигналов по идентификатору
   * @param id Идентификатор записи
   */
  public getSourcesAndSignalsItem(id: string): Observable<ISourcesAndSignalsForTable> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'SourcesAndSignals',
          query: { id },
        },
      })
      .pipe(pluck('data', 'items', '0'));
  }

  /** Получение информации о звуковом уведомлении */
  public getSoundNotification(id: string): Observable<IHcsObjectKindsDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: { name: 'SoundNotifications', query: { id } },
      })
      .pipe(
        map((data: IAbstractServiceData) => {
          return data?.data?.items[0];
        }),
      );
  }

  /** Сохранение звукового уведомления */
  public saveSoundNotification(soundNotification: ISoundNotification): Observable<'success' | 'error'> {
    return this.checkSoundNotificationDuplicate(soundNotification).pipe(
      switchMap((isDuplicate: boolean) => (isDuplicate ? throwError(new Error('duplicate')) : of(null))),
      switchMap(() =>
        this.rest.serviceRequest({
          action: 'upsert',
          service: { name: 'Emergency' },
          entity: {
            name: 'SoundNotifications',
            query: { id: soundNotification.id || Uuid.newUuid() },
          },
          data: soundNotification,
        }),
      ),
      map(() => 'success'),
    );
  }

  /**
   * Сохранение записи в источниках и сигналах
   * @param sourceAndSignal - информация о записи
   */
  public saveSourceAndSignal(sourceAndSignal: ISourcesAndSignals): Observable<'success' | 'error'> {
    return this.checkSourcesAndSignalsDuplicate(sourceAndSignal).pipe(
      switchMap((isDuplicate: boolean) => (isDuplicate ? throwError(new Error('duplicate')) : of(null))),
      switchMap(() =>
        this.rest.serviceRequest({
          action: 'upsert',
          service: { name: 'Emergency' },
          entity: {
            name: 'SourcesAndSignals',
            query: { id: sourceAndSignal.id || Uuid.newUuid() },
          },
          data: sourceAndSignal,
        }),
      ),
      map(() => 'success'),
    );
  }

  /** Удаление записи в источниках и сигналах */
  public deleteSourceAndSignal(ids: string | string[]): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'delete',
      service: { name: 'Emergency' },
      entity: { name: 'SourcesAndSignals', query: { id: Array.isArray(ids) ? { $in: ids } : ids } },
    });
  }

  /** Проверка возможности создания записи звукового уведомления */
  private checkSoundNotificationDuplicate(soundNotification: ISoundNotification): Observable<boolean> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'SoundNotifications',
          query: {
            id: { $ne: soundNotification.id || null },
            isActive: true,
            soundNotificationEntityId: soundNotification.soundNotificationEntityId,
            soundNotificationOrganizationRoleId: soundNotification.soundNotificationOrganizationRoleId,
            $and: [
              {
                $or: [
                  {
                    $expr: soundNotification.organizations?.length
                      ? { $intersects: ['$organizations', `["${soundNotification.organizations.join('","')}"]`] }
                      : undefined,
                  },
                  { $expr: { $eq: ['$organizations', []] } },
                  { $expr: { $eq: ['$organizations', null] } },
                ],
              },
              {
                $or: [
                  {
                    $expr: soundNotification.groups?.length
                      ? { $intersects: ['$groups', `["${soundNotification.groups.join('","')}"]`] }
                      : undefined,
                  },
                  { $expr: { $eq: ['$groups', []] } },
                  { $expr: { $eq: ['$groups', null] } },
                ],
              },
            ],
          },
        },
      })
      .pipe(map((soundNotifications: IAbstractServiceData) => !!soundNotifications?.data?.items.length));
  }

  /** Проверка возможности создания записи в таблице SourcesAndSignals*/
  private checkSourcesAndSignalsDuplicate(sourcesAndSignal: ISourcesAndSignals): Observable<boolean> {
    const $and = [
      'docTypeIds',
      'sourceIds',
      'incidentTypeIds',
      'informationStatementTypeIds',
      'informationStatementThemeIds',
      'statusLifeCycleStepIds',
    ].map((field: string) => ({
      $or: [
        {
          $expr: sourcesAndSignal[field]?.length
            ? { $intersects: [`$${field}`, `["${sourcesAndSignal[field].join('","')}"]`] }
            : undefined,
        },
        { $expr: { $eq: [`$${field}`, []] } },
        { $expr: { $eq: [`$${field}`, null] } },
      ],
    }));

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'SourcesAndSignals',
          query: {
            $and,
            id: { $ne: sourcesAndSignal.id || null },
            soundNotification: sourcesAndSignal.soundNotification,
          },
        },
      })
      .pipe(map((soundNotifications: IAbstractServiceData) => !!soundNotifications?.data?.items.length));
  }

  /**
   * Сформировать строку и первого элемента и добавить точки
   */
  private getFirstElAndDotsAdd(array: string[]): string {
    return `${array[0] ? array[0] : ''}${array.length > 1 ? `... +${array.length - 1}` : ''}`;
  }

  /**
   * Метод для выборки звуковых уведомлений по query
   */
  public getEmergencySoundNotificationByQuery(query?: IAnyObject): Observable<ISoundNotification[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          query: query || {},
          name: 'SoundNotifications',
          attributes: [
            'soundNotificationEntityId.sysname',
            'isActive',
            'soundNotificationOrganizationRoleId.sysname',
            'organizations',
            'groups',
          ],
        },
      })
      .pipe(map((response: IAbstractServiceData) => response.data?.items || []));
  }

  /**
   * Метод для выборки настроек звуковых уведомлений по query
   */
  public getSourcesAndSignalsByQuery(query?: IAnyObject): Observable<ISourcesAndSignals[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          query: query || {},
          name: 'SourcesAndSignals',
          attributes: [
            'id',
            'pushNotificationTypeId',
            'pushNotificationText',
            'sourceIds',
            'incidentTypeIds',
            'docTypeIds',
            'statusLifeCycleStepIds',
            'imageId.sfsId',
            'signal',
            'soundNotification',
            'informationStatementTypeIds',
            'informationStatementThemeIds',
          ]
        },
      })
      .pipe(map((response: IAbstractServiceData) => response.data?.items || []));
  }

  /** Получение списка организаций для селекта */
  public getOrganizationsForSelect(): Observable<IOrganization[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: { name: 'Organizations', attributes: ['id', 'name'] },
      })
      .pipe(
        map((response: IAbstractServiceData) => response.data.items || []),
        catchError(() => of([])),
      );
  }

  /** Получение списка групп прав для селекта */
  public getRuleGroupsForSelect(): Observable<IAnyObject[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: { name: 'RuleGroups', attributes: ['id', 'name'] },
      })
      .pipe(
        map((response: IAbstractServiceData) => response.data.items || []),
        catchError(() => of([])),
      );
  }

  /** Получение списка информационных схем для селекта */
  public getIncidentTypesForSelect(): Observable<Partial<IIncidentType[]>> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: { name: 'IncidentTypes', query: { active: true }, attributes: ['id', 'name'] },
      })
      .pipe(
        pluck('data', 'items'),
        catchError(() => of([])),
      );
  }

  /** Получение списка информационных схем для селекта */
  public getInformationThemesForSelect(): Observable<IAnyObject[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: { name: 'InformationStatementThemes', attributes: ['id', 'name'] },
      })
      .pipe(
        pluck('data', 'items'),
        catchError(() => of([])),
      );
  }

  /** Получение списка сигналов для селекта */
  public getSignalsForSelect(): Observable<IAnyObject[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: { name: 'Signals', attributes: ['id', 'name'] },
      })
      .pipe(
        pluck('data', 'items'),
        catchError(() => of([])),
      );
  }
}
