 <nz-drawer
  [nzVisible]="openDrawer"
  [nzWidth]="500"
  [nzOffsetX]="364"
  [nzClosable]="false"
  [nzMask]="false"
  [nzPlacement]="'left'"
>
  <ng-container *nzDrawerContent>
    <form nz-form nzLayout="vertical" *ngIf="model">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="2">
          <i nz-icon nzType="close" nzTheme="outline" (click)="close()"></i>
        </div>
        <div nz-col [nzSpan]="2">
          <div class="icon"></div>
        </div>
        <div nz-col [nzSpan]="20" class="title">Термоточка №{{model?.extId}}</div>

        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label>Состояние</nz-form-label>
            <nz-form-control>
              <nz-view-control nz-tooltip="Состояние термоточки" [data]="model.statusId?.['name']" mode="text"></nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label>Тип</nz-form-label>
            <nz-form-control>
              <nz-view-control nz-tooltip="Тип термоточки" [data]="model.typeId?.['name']" mode="text"></nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <strong>Дата и время</strong>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label>Отчетные сутки</nz-form-label>
            <nz-form-control>
              <nz-view-control
                [data]="model.reportDateTime | date : 'dd.MM.yyyy'"
                nz-tooltip="Отчетные сутки"
                mode="text"
                addButtonOnAfter="calendar"
              ></nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label>Обнаружение</nz-form-label>
            <nz-form-control>
              <nz-view-control
                [data]="model.detectionDateTime | date : 'dd.MM.yyyy HH:mm:ss'"
                nz-tooltip="Обнаружение термоточки"
                mode="text"
                addButtonOnAfter="calendar"
              ></nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label>Взято в работу</nz-form-label>
            <nz-form-control>
              <nz-view-control
                [data]="model.inWorkDateTime | date : 'dd.MM.yyyy HH:mm:ss'"
                nz-tooltip="Взято в работу"
                mode="text"
                addButtonOnAfter="calendar"
              ></nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label>Обновление состояния</nz-form-label>
            <nz-form-control>
              <nz-view-control
                [data]="model.statusUpdateDateTime | date : 'dd.MM.yyyy HH:mm:ss'"
                nz-tooltip="Обновление состояния"
                mode="text"
                addButtonOnAfter="calendar"
              ></nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label>Категория земель</nz-form-label>
            <nz-form-control nz-tooltip="Категория земель">
              <nz-view-control [data]="model.landCategoryId?.['name']" mode="textarea" [rows]="3"></nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label>Собственник</nz-form-label>
            <nz-form-control nz-tooltip="Собственник земли">
              <nz-view-control [data]="model.owner" mode="textarea" [rows]="3"></nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label>Телефон</nz-form-label>
            <nz-form-control>
              <nz-view-control nz-tooltip="Телефон собственника земли" [data]="model.ownerPhone" mode="text"></nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label>Уровень риска</nz-form-label>
            <nz-form-control>
              <nz-view-control nz-tooltip="Уровень риска" [data]="model.attentionScore" mode="text"></nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label>Вероятный тип</nz-form-label>
            <nz-form-control>
              <nz-view-control nz-tooltip="Вероятный тип риска" [data]="model.possibleType" mode="text"></nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <strong>Силы и средства</strong>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label>Подразделение</nz-form-label>
            <nz-form-control nz-tooltip="Подразделение, привлекаемое к работе с термоточкой">
              <nz-view-control
                [data]="model.unit"
                mode="textarea"
                [rows]="3">
              </nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label>Личного состава</nz-form-label>
            <nz-form-control>
              <nz-view-control
                nz-tooltip="Количество людей, привлекаемое к работе с термоточкой"
                [data]="model.personnelCount"
                mode="text">
              </nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label>Единиц техники</nz-form-label>
            <nz-form-control>
              <nz-view-control
                nz-tooltip="Количество техники, привлекаемое к работе с термоточкой"
                [data]="model.equipmentCount"
                mode="text">
              </nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <strong>Местоположение</strong>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label>Координаты</nz-form-label>
            <nz-form-control>
              <nz-view-control
                nz-tooltip="Координаты центроида полигона термоточки"
                [data]="model.centerCoordinates"
                [linkStyle]="true"
                addButtonOnAfter="environment"
                mode="text"
                (click)="setPositionMapOnCoordinates()">
              </nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label>Федеральный округ</nz-form-label>
            <nz-form-control>
              <nz-view-control
                nz-tooltip="Федеральный округ"
                [data]="model.federalDistrict"
                mode="text">
              </nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label>Субъект</nz-form-label>
            <nz-form-control>
              <nz-view-control
                nz-tooltip="Субъект"
                [data]="model.federalSubject"
                mode="text">
              </nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label>Район</nz-form-label>
            <nz-form-control>
              <nz-view-control
                nz-tooltip="Район"
                [data]="model.municipal"
                mode="text">
              </nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-form-item>
            <nz-form-label>Ближайший населенный пункт</nz-form-label>
            <nz-form-control>
              <nz-view-control
                nz-tooltip="Ближайший населенный пункт"
                [data]="model.closestSettlement"
                mode="text">
              </nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label>Дистанция, м</nz-form-label>
            <nz-form-control>
              <nz-view-control
                nz-tooltip="Дистанция, м"
                [data]="model.distance"
                mode="text">
              </nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label>Азимут, градусы</nz-form-label>
            <nz-form-control>
              <nz-view-control
                nz-tooltip="Азимут, градусы"
                [data]="model.azimuth"
                mode="text">
              </nz-view-control>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="24">
          <nz-collapse>
            <nz-collapse-panel
              nzHeader="Связанные происшествия"
              class="related__emergencies"
            >
              <bg-emergency-mini-card
                *ngFor="let emergency of relatedIncidents"
                [emergency]="emergency">
              </bg-emergency-mini-card>
            </nz-collapse-panel>
            <nz-collapse-panel
              nzHeader="Связанные события"
              class="related__emergencies"
            >
              <bg-emergency-mini-card
                *ngFor="let emergency of relatedEvents"
                [emergency]="emergency">
              </bg-emergency-mini-card>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
    </form>
  </ng-container>
</nz-drawer>
