import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

import { ForecastingResultsState } from '../../../models/states/forecasting-results.state';

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'forecasting-results' })
export class ForecastingResultsStore extends EntityStore<ForecastingResultsState> {
  constructor() {
    super([]);
  }
}
