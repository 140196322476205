import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  DialogService,
  IScCheckboxOptions,
  IScInputOptions,
  IScSelectOptions,
  IScTextareaOptions,
} from '@smart-city/core/common';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import { EmergencyDto } from '../../models/classes';
import { ResponsePlanStepService } from '../../services';
import { BaseIncidentFormComponent } from '../base-incident-form/base-incident-form.component';
import { BgLoosesComponent } from '../bg-looses/bg-looses.component';
import { Coordinates } from '@bg-front/core/models/classes';
import { AccessService, INotificationMessage, RestService, Settings2Service } from '@smart-city/core/services';
import { IEmergencyDto, ILifeCycleStepDto } from 'smart-city-types';
import {
  AtmIntegrationService,
  BgAdminService,
  EmergencyService,
  MiniMapService,
  OrganizationsService,
} from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { MultiFileService } from '@bg-front/core/services';
import { ReportingService } from '../../../bg/modules/reporting/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';

/**
 * Компонент для просмотра поручения для улк
 */
@UntilDestroy()
@Component({
  selector: 'bg-ulk-order-view-form',
  templateUrl: './ulk-order-view-form.component.html',
  styleUrls: ['./ulk-order-view-form.component.scss'],
  providers: [ResponsePlanStepService],
})
export class UlkOrderViewFormComponent extends BaseIncidentFormComponent implements OnInit, AfterViewInit {
  /** Список сообщений об ошибке */
  public errors: INotificationMessage[] = [];

  /** Настройка компоненты Комментарий */
  public optionsComment: IScTextareaOptions = {
    label: 'Комментарий оператора',
    maxLength: 1000,
    rows: 6,
  };
  /** Настройка компоненты Комментарий */
  public optionsUlkComment: IScTextareaOptions = {
    label: 'Комментарий исполнителя',
    rows: 2,
  };

  /** Настройка компоненты Описание */
  public optionsDescription: IScTextareaOptions = {
    label: 'Описание',
    maxLength: 1000,
    rows: 6,
  };

  /** Настройка компоненты Ответсвенный пользователь */
  public optionsResponsibleUser: IScSelectOptions = {
    title: 'Ответственный пользователь',
    clearable: true,
    service: 'Admin',
    entity: 'Users',
    modern: true,
    fieldId: 'id',
    fieldName: 'fio',
    query: { isActive: true },
  };

  /** Настройка компоненты Угроза населению */
  public optionsThreatPopulation: IScCheckboxOptions = {
    title: 'Угроза населению',
  };

  /** Настройка компоненты Угроза организации */
  public optionsThreatOrganization: IScCheckboxOptions = {
    title: 'Угроза организации',
  };

  /** Настройка компоненты Фактического адреса */
  public coordinatesOption: IScInputOptions = {
    label: 'Координаты фактического адреса',
    maxLength: 100,
  };

  /** Настройка компоненты Доп информация о месте КСиП */
  public placeDescriptionOptions: IScTextareaOptions = {
    label: 'Дополнительная информация о месте КСиП',
    maxLength: 1000,
    rows: 8,
  };

  /** Центр миникарты */
  public miniMapCenter: MapBaseCoordinatesType;

  /** Зум для миникарты */
  public miniMapZoom: number;

  /** Координаты по умолчанию для мини карты */
  public defaultMarkerCoordinate: [number, number];

  /** Количество граждан, зарегистрированных в жилом доме */
  public totalRegistryPeopleHouseOptions: IScInputOptions = {
    label: 'Количество граждан, зарегистрированных в жилом доме',
    type: 'number',
  };

  /** Количество граждан, проживающих в жилом доме временно */
  public totalTemporalPeopleHouseOptions: IScInputOptions = {
    label: 'Количество граждан, проживающих в жилом доме временно',
    type: 'number',
  };
  /** Индивидуальное домовладение */
  public individualOwnershipOptions: IScCheckboxOptions = {
    title: 'Индивидуальное домовладение',
  };

  /** Объект ЖКХ */
  public jkhObjectOptions: IScSelectOptions = {
    title: 'Объект ЖКХ',
    clearable: true,
    modern: true,
    service: 'AtmIntegration',
    entity: 'MonitoringObject',
    fieldName: 'name',
    query: { active: true },
  };

  /** Отправить в "Реформу ЖКХ"  */
  public sendingToJkhReformOptions: IScCheckboxOptions = {
    title: 'Отправить в "Реформу ЖКХ"',
  };

  /** Тип ограничения ресурса  */
  public resourceConstraintTypeOptions: IScSelectOptions = {
    title: 'Тип ограничения ресурса',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'Dictionary',
    query: { 'typeid.sysname': 'resourceLimitationType' },
    fieldName: 'name',
  };

  /** Связанные ограничения  */
  public relatedRestrictionsOptions: IScCheckboxOptions = {
    title: 'Связанные ограничения',
  };

  /** Cфера ЖКХ */
  public jkhDirectionOptions: IScSelectOptions = {
    title: 'Сфера ЖКХ',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'IncidentCategories',
    width: '100%',
    isMultiple: true,
    fieldName: 'name',
    query: { 'sectionId.sysname': 'zhkh' },
  };

  /**
   * Объекты первой категории, затронутые ограничением
   */
  public favorableRestrictionObjectsOptions: IScTextareaOptions = {
    label: 'Объекты первой категории, затронутые ограничением',
    maxLength: 1000,
    rows: 8,
  };

  /**
   * Иные объекты, затронутые ограничением
   */
  public affectedRestrictionObjectsOptions: IScTextareaOptions = {
    label: 'Иные объекты, затронутые ограничением',
    maxLength: 1000,
    rows: 8,
  };

  /** Категория электроснабжения потребителей  */
  public consumerPowerCategoryIdOptions: IScSelectOptions = {
    title: 'Категория электроснабжения потребителей',
    clearable: true,
    modern: true,
    service: 'Admin',
    entity: 'Dictionary',
    query: { 'typeid.sysname': 'powerSupplyConsumerCategory' },
    fieldName: 'name',
  };

  /** Флаг для отображения контролов жкх */
  public isJhk: boolean = false;
  /** флаг на значение 'Эксплуатация жилищного фонда' */
  public isHousingService: boolean;
  /** Видимость Отправить в "Реформу ЖКХ" */
  public isSendingReformToJkhVisible: boolean;
  /** Активность блока Привлекаемые службы */
  public orderInvolvedServiceEnabled: boolean = false;

  /** @ignore */
  constructor(
    accessService: AccessService,
    atmIntegrationService: AtmIntegrationService,
    bgAdminService: BgAdminService,
    dialog: MatDialog,
    dialogService: DialogService,
    emergencyService: EmergencyService,
    fb: FormBuilder,
    ksipCategoriesQuery: KsipCategoriesQuery,
    ksipDetailsQuery: KsipDetailsQuery,
    ksipSectionsQuery: KsipSectionsQuery,
    ksipTypesQuery: KsipTypesQuery,
    miniMapService: MiniMapService,
    multiFileService: MultiFileService,
    organizationsService: OrganizationsService,
    reportingService: ReportingService,
    route: ActivatedRoute,
    router: Router,
    settings: Settings2Service,
    significantObjectService: SignificantObjectsService,
    responsePlanStepService: ResponsePlanStepService,
  ) {
    super(
      accessService,
      atmIntegrationService,
      bgAdminService,
      dialog,
      dialogService,
      emergencyService,
      fb,
      ksipCategoriesQuery,
      ksipDetailsQuery,
      ksipSectionsQuery,
      ksipTypesQuery,
      miniMapService,
      multiFileService,
      organizationsService,
      reportingService,
      route,
      router,
      settings,
      significantObjectService,
      responsePlanStepService,
    );
  }

  /** Свойство рассчитывающее общее количество задействованных машин */
  public get summaryCars(): number {
    if (this.model.resolution) {
      return (
        (this.model.resolution.fireCar || 0) +
        (this.model.resolution.ambulance || 0) +
        (this.model.resolution.policeCar || 0)
      );
    }
    return 0;
  }

  /** @ignore */
  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.model = data.model as EmergencyDto;
      // Активность блока Привлекаемые службы
      this.orderInvolvedServiceEnabled = this.accessService.accessMap['OrderInvolvedServiceEnabled']?.visible
        && this.accessService.accessMap['OrderInvolvedServiceEnabled']?.enabled;
      this.incidentForm = new FormGroup({
        /** ----- MAIN ----- **/
        byCoordinates: new FormControl(this.model.byCoordinates),
        addressFact: new FormControl(this.model.addressFact, [Validators.required]),
        moId: new FormControl(this.model.moId),
        districtId: new FormControl({ value: this.model.districtId, disabled: true }),
        detailsFact: new FormControl(this.model.detailsFact),
        incidentTypeId: new FormControl(
          {
            value: this.model.incidentTypeId,
            disabled: true,
          },
        ),
        ksipDetailsId: new FormControl({
          value: this.model.ksipDetailsId,
          disabled: true,
        }),
        comment: new FormControl({
          value: this.model.comment,
          disabled: true,
        }),
        description: new FormControl({
          value: this.model.description,
          disabled: true,
        }),
        responsibleUser: new FormControl(this.model.responsible, [Validators.required]),
        deadline: new FormControl(this.model.deadline),
        organization: new FormControl(this.model.organization),
        threatPopulation: new FormControl(this.model.resolution?.danger),
        threatOrganization: new FormControl(this.model.resolution?.dangerOrg),
        /** ----- PLACEMENT ----- **/
        coordinates: new FormControl(this.model.coordinates),
        ksipPlaceDescription: new FormControl(this.model.ksipPlaceDescription),
        /** ----- ADDITIONAL ----- **/
        ulkAdditionalInfo: new FormControl(this.model.ulkAdditionalInfo),
        totalCars: new FormControl({ value: this.summaryCars, disabled: true }),
        ulkComment: new FormControl({
          value: this.model.ulkAdditionalInfo?.comment || undefined,
          disabled: false,
        }),
        /** ----- LOOSES ----- **/
        resolution: BgLoosesComponent.generateFormGroup(this.fb, {
          ...this.model.resolution,
          peopleInTheZoneOnDate: this.model.resolution?.peopleInTheZoneOnDate || this.model.timeCreate,
          deathOnDate: this.model.resolution?.deathOnDate || this.model.timeCreate,
          rescuedOnDate: this.model.resolution?.rescuedOnDate || this.model.timeCreate,
          victimOnDate: this.model.resolution?.victimOnDate || this.model.timeCreate,
          hospitalizedOnDate: this.model.resolution?.hospitalizedOnDate || this.model.timeCreate,
          evacuatedOnDate: this.model.resolution?.evacuatedOnDate || this.model.timeCreate,
          missingOnDate: this.model.resolution?.missingOnDate || this.model.timeCreate,
        }),
        /** ----- DOCS ----- **/
        documents: new FormControl(this.model.documents),
        totalRegistryPeopleHouse: new FormControl(this.model.totalRegistryPeopleHouse),
        totalTemporalPeopleHouse: new FormControl(this.model.totalTemporalPeopleHouse),
        individualOwnership: new FormControl(this.model.individualOwnership),
        jkhObject: new FormControl(this.model?.jkhObject),
        favorableRestrictionObjects: new FormControl(this.model?.favorableRestrictionObjects),
        affectedRestrictionObjects: new FormControl(this.model?.affectedRestrictionObjects),
        resourceConstraintType: new FormControl(this.model?.resourceConstraintType),
        relatedRestrictions: new FormControl(this.model?.relatedRestrictions),
        jkhDirection: new FormControl(this.model?.jkhDirection),
        consumerPowerCategoryId: new FormControl(this.model?.consumerPowerCategoryId, [Validators.required]),
        isSendingToJkhReform: new FormControl(this.model?.isSendingToJkhReform),
        workTermFrom: new FormControl(this.model?.workTermFrom),
        workTermTo: new FormControl(this.model?.workTermTo),
      });

      this.eventsGridQuery = { incidentId: (<IEmergencyDto>this.model.parentId).id };

      this.incidentForm.disable({
        onlySelf: true
      });

      super.ngOnInit();

      /** Получение координат и зума для МО */
      this.miniMapCenter = this.getMunicipalCoordinates();
      this.miniMapZoom = parseInt(this.getMunicipal().zoom, 10);

      this.validationControlMap = new Map<string, string>([['addressFact', this.addressFactOptions.label]]);

      const markerCoordinates = new Coordinates(this.model.mapMarkerCoordinate || this.model.coordinates);
      if (markerCoordinates.isValid()) {
        this.defaultMarkerCoordinate = markerCoordinates.toArray();
      }

      this.initReactPlan();
      this.updateForm();
    });
  }

  /** Получение данных из формы и сохранение в модель */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public getModelData() {}

  /** Обновление формы */
  public updateForm() {
    // Признак что происшествие находится на согласовании
    const isApproval = this.settings.getDictionaryById(
      <string>(<ILifeCycleStepDto>this.model.lifeCycleStepId)?.status
    )?.sysname?.includes('Approval') || false;
    // Признак что происшествие просматривает согласующая организация
    const isApprovalOrganization = (<IEmergencyDto>this.model.parentId)?.organization ===
      this.settings.currentUser.organizationId.id;
    // Получить экшены если происшествие находится на согласовании и его просматривает согласующая организация
    this.actions = isApproval && isApprovalOrganization
      ? (this.model?.lifeCycleStepId as ILifeCycleStepDto)?.actions || []
      : [];
    this.generateHeaderBar();
    // TODO: Строчка ниже переопределяет, то что было присвоено в функции строчкой выше.
    // Подобное есть например еще в поручениях ДДС 01.
    // Подумать и сделать корректное определение заголовка в generateHeaderBar
    this.headerActionsOptions.title = `Поручение ${this.model.number ?? ''}`;
  }

  /** Закрытие формы */
  public closeForm() {
    if (this.workspace === 'consolidated-registries' && this.route.snapshot.data.title === 'Поручения') {
      this.router.navigate(['consolidated-registries/order-register']);
    } else {
      this.emergencyService.selectIncident({ id: undefined, docType: 'incident' });
      const navigateParams = this.route.snapshot.data['disabled']
        ? [{ outlets: { viewForm: null } }]
        : [{ outlets: { editForm: null, editEventForm: null } }]
      this.router.navigate(navigateParams, { relativeTo: this.route.parent, queryParamsHandling: 'merge' });
    }
  }
}
