import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BaseComponent, MapDialogV2Component } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { ICustomSelectItem, ISelectItem } from '@bg-front/core/models/interfaces';
import { WeatherDataService } from '@bg-front/weather-data/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { IMapBaseEvent, TMapBaseCoordinates } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { forkJoin, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IAnyObject, IForecastingDetailDto } from 'smart-city-types';

import { IForecastingConsequencesOfForestFiresTaskParamsDto } from '../../models/interfaces';
import { ForecastingFiresService } from '../../services';

/**
 * Компонента для задачи "Последствия лесных пожаров"
 */
@UntilDestroy()
@Component({
  selector: 'bg-forecasting-consequences-of-forest-fires-task',
  templateUrl: './forecasting-consequences-of-forest-fires-task.component.html',
  styleUrls: ['./forecasting-consequences-of-forest-fires-task.component.scss'],
})
export class ForecastingConsequencesOfForestFiresTaskComponent extends BaseComponent implements OnInit {
  /** Форма */
  public form: FormGroup;
  public isShow = false;
  @Input()
  public coordinates: string;
  /** Координаты ЛХО */
  @Input()
  public polygonCoordinates: Coordinates[];
  /** Тип лесных насаждений */
  @Input()
  public forestTypeId?: string;
  /** Класс горимости */
  @Input()
  public flammabilityClass?: string;
  /** Средний диаметр */
  @Input()
  public trunkDiameterId?: string;

  /** Настройка компоненты Вид пожара */
  public fireTypes = this.settings.getDictForSelect('fireType').map(
    (el: IScSelectItem) =>
      <ISelectItem>{
        text: el.text,
        value: el.id,
      },
  );

  /** Признак низового пожара */
  public isDownstream = false;
  /**
   * Настройка компоненты Тип лесных насаждений
   */
  public forestTypes = this.settings.getDictForSelect('forestType').map((el: IScSelectItem) => {
    return <ISelectItem>{
      text: el.text,
      value: el.id,
    };
  });

  /**
   * Настройка компоненты Класс горимости
   */
  public flammabilities: ICustomSelectItem<number>[] = [
    <ICustomSelectItem<number>>{
      text: 'I',
      value: 1,
      description: 'Чистые и с примесью лиственных пород хвойные насаждения (кроме лиственничных)',
    },
    <ICustomSelectItem<number>>{
      text: 'II',
      value: 2,
      description: 'Чистые с примесью хвойных пород лиственные насаждения, а также лиственничные насаждения',
    },
  ];
  /**
   * Настройка компоненты Средний диаметр древостоя (в см)
   */
  public trunkDiameters = this.settings
    .getDictForSelect('trunkDiameter')
    .sort((a: IScSelectItem, b: IScSelectItem): 1 | -1 | 0 => {
      if (+a.text > +b.text) {
        return 1;
      }

      if (+a.text < +b.text) {
        return -1;
      }
      return 0;
    })
    .map((el: IScSelectItem) => {
      return <ISelectItem>{
        text: el.text + ' см',
        value: el.id,
      };
    });
  /** Настройка компоненты Облачность */
  public clouds: ISelectItem[] = this.settings.getDictForSelect('cloudy').map((el: IScSelectItem) => {
    return <ISelectItem>{
      text: el.text,
      value: el.id,
    };
  });
  /** Настройка компоненты Время суток */
  public timeOfDays: ISelectItem[] = this.settings.getDictForSelect('timesOfDay').map((el: IScSelectItem) => {
    return <ISelectItem>{
      text: el.text,
      value: el.id,
    };
  });

  /**
   * Настройка компоненты Тип лесных насаждений
   */
  public fireHazardTypes: ISelectItem[] = this.settings.getDictForSelect('fireHazardType').map((el: IScSelectItem) => {
    return <ISelectItem>{
      text: el.text,
      value: el.id,
    };
  });

  /** Ссылка на список времён распространения */
  public timesArray: FormArray;

  /** Единицы измерения */
  public unitName: string = 'м';

  public isLoadWeather: boolean = false;

  constructor(
    private readonly settings: Settings2Service,
    private readonly modalService: NzModalService,
    private readonly weatherService: WeatherDataService,
    private readonly forecastingService: ForecastingFiresService,
    public readonly controlContainer: ControlContainer,
    private readonly fb: FormBuilder,
  ) {
    super();
  }

  /** Входные параметры */
  @Input()
  set emergencyParams(detail: IForecastingDetailDto<IForecastingConsequencesOfForestFiresTaskParamsDto>) {
    if (detail?.params) {
      this.form?.patchValue(detail.params);
    }
  }

  public ngOnChanges() {
    // сделано для заполнения полей Вид, Средний диаметр и Класс горимости в зависимости от значения, которое будет выбрано в селекте "Объект, для которого расчет", или предзаполненными данными об ЛХО
    this.form?.get('forestTypeId')?.setValue(this.forestTypeId);
    this.form?.get('flammabilityClass')?.setValue(this.flammabilityClass);
    this.form?.get('trunkDiameterId')?.setValue(this.trunkDiameterId);
  }

  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;

    if (this.coordinates && !!!this.form.get('coordinates')?.value) {
      this.form.get('coordinates')?.setValue(this.coordinates);
    }

    this.timesArray = this.form.controls.burningTimes as FormArray;

    this.form.controls.fireTypeId.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      if (value) {
        const fireType = this.settings.getDictionaryById(value);
        if (fireType?.sysname === 'downstream') {
          this.isDownstream = true;
        } else {
          this.isDownstream = false;
          this.form.controls.height.setValue(null);
        }
      } else {
        this.isDownstream = false;
      }
    });

    this.form
      .get('sizingTypeId')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        switch (value) {
          case 'square': {
            this.unitName = 'га';
            break;
          }
          case 'perimeter': {
            this.unitName = 'м';
            break;
          }
          default: {
            this.unitName = '';
          }
        }
      });

    // Подписка на изменение поля "Значение"
    this.form.controls.height.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        // Заменяем разделитель "," на "." при вводе
        if (value && /,/.test(value)) {
          this.form.controls.height.patchValue(value.replace(',', '.'), { emitEvent: false });
        }
      });

    if (this.flammabilityClass) {
      this.form.get('flammabilityClass')?.setValue(this.flammabilityClass);
    }

    if (this.forestTypeId) {
      this.form.get('forestTypeId')?.setValue(this.forestTypeId);
    }

    if (this.trunkDiameterId) {
      this.form.get('trunkDiameterId')?.setValue(this.trunkDiameterId);
    }

    this.isShow = this.form.disabled;
  }

  /**
   * Обработчик клика по кнопке Уточнить адрес
   * @param $event
   */
  public onClickSpecifyAddress($event: Event) {
    const exactCoordinates = new Coordinates(this.form.controls.coordinates.value || this.coordinates);

    const data = {
      coordinates: exactCoordinates,
    };
    this.openSpecifyMap(data);
  }

  /** Метод который обеспечивает открытие диалога и подписку на закрытие
   * @param data - параметры для диалога
   */
  public openSpecifyMap(data: { coordinates?: Coordinates }): void {
    const modal = this.modalService.create({
      nzTitle: 'Укажите координату',
      nzContent: MapDialogV2Component,
      nzComponentParams: data,
      nzWidth: '600px',
    });

    modal.afterOpen.pipe(untilDestroyed(this)).subscribe(() => {
      modal.componentInstance.mapModel.addPolygonToLayer(
        'forestryFacility',
        'polygon',
        this.polygonCoordinates.map((coord: Coordinates) => coord.toArray()),
        { color: '#0072C3', weight: 2 },
      );
      modal.componentInstance.mapModel.viewLayer('forestryFacility', false);
    });

    let subs = modal.afterClose.pipe(untilDestroyed(this)).subscribe((params: any) => {
      if (params?.coordinates) {
        this.form.controls.coordinates.setValue(`${params.coordinates.join(', ')}`);
      }

      subs.unsubscribe();
      subs = undefined;
    });
  }

  public getWeatherData(): void {
    const counterTypeId = this.settings.getDictionaryByTypeAndSysName(
      'innerForecastingTasks',
      'calculateWeatherFireHazardType',
    )?.id;
    const obs = [];
    obs.push(this.weatherService.getActualWeather(dayjs().unix() * 1000));
    if (counterTypeId) {
      obs.push(this.forecastingService.getFireHazardType(counterTypeId));
    } else {
      obs.push(of(undefined));
    }
    forkJoin(obs)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: IAnyObject[]) => {
        this.form.controls.windVelocity.setValue(data[0]?.windSpeed);
        this.form.controls.windDirection.setValue(data[0]?.windDirection);
        this.form.controls.fireHazardTypeId.setValue(data[1]?.fireHazardType);
      });
  }

  /** Добавляем время распространения */
  public addTime() {
    const idx = (this.form.controls.burningTimes as FormArray).controls.length;
    const group = this.fb.group({
      id: [idx + 1],
      burningTime: [],
    });

    this.timesArray.setControl(idx, group);
  }

  /** Удаляем время распространения */
  public removeTime(idx: number) {
    this.timesArray.removeAt(idx);
  }
}
