import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IElementButton,
  INwHeaderBarOptions,
  IScInputOptions,
  IScTextareaOptions,
  IScTextButtonOptions,
} from '@smart-city/core/common';
import { AccessService, IAccessAction, Settings2Service } from '@smart-city/core/services';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { IGisServiceResult } from '@smart-city/maps/sc';
import * as moment from 'moment';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';
import {
  IAbstractServiceData,
  IEmergencyDto,
  IEmergencyEventDto,
  IEmergencyVaDetailDto,
  IUserInfoDto,
  IVideoDeviceDto,
} from 'smart-city-types';
import { Coordinates } from '@bg-front/core/models/classes';
import { THIRTY_SECONDS } from '@bg-front/core/models/constants';
import { VideoEmergencyTypeEnum } from '../../models/enums';
import {
  IDownloadSourcesOptions,
  IImageVideoRecordingDialogData,
  IMonitoringDate,
  IVideoDeviceBg,
} from '../../models/interfaces';
import { BgAdminService, EmergencyService, VideoDevicesService } from '../../services';
import { BaseComponent, MapDialogComponent } from '@bg-front/core/components';
import { CloseWithoutReactionDialogComponent } from '../close-without-reaction-dialog/close-without-reaction-dialog.component';
import { ImageVideoRecordingDialogComponent } from '../image-video-recording-dialog/image-video-recording-dialog.component';
import { BgMapService } from '@bg-front/core/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';

@Component({
  selector: 'bg-video-event-show-form',
  templateUrl: './video-event-show-form.component.html',
  styleUrls: ['./video-event-show-form.component.scss'],
})
export class VideoEventShowFormComponent extends BaseComponent implements OnInit {
  /**
   * Форма
   */
  public eventForm: FormGroup;

  /**
   * Событие по которому создали инцидент
   */
  @Input()
  public model: IEmergencyEventDto;

  /**
   * Конфигурация заголовка
   */
  public headerActionsOptions: INwHeaderBarOptions;

  /**
   * Получение типа КСиП
   */
  public ksipTypeName$: Observable<string>;

  /**
   * Получение пользователя
   */
  public userName$: Observable<IUserInfoDto>;
  /**
   * Настройка компоненты Описание
   */
  public optionsKsipInfo: IScTextareaOptions = {
    label: 'Дополнительная информация о месте КСиП',
    maxLength: 1000,
  };
  /**
   * Настройка компоненты Комментарий
   */
  public optionsComment: IScTextareaOptions = {
    label: 'Комментарий',
    maxLength: 1000,
  };

  /**
   * Уточнить координаты адреса поле
   */
  public exactCoordinatesOptions: IScInputOptions = {
    label: 'Уточнить координаты адреса',
    hidden: true,
  };

  /** Конфигурация для кнопки уточнить адрес */
  public optionsSpecifyAddressOnMap: IScTextButtonOptions = {
    title: 'Уточнить адрес на карте',
    color: 'primary',
  };

  /**
   * Превышение максимального количества
   */
  public greateThenMaxPersons: number = undefined;
  /** Название камеры */
  public videoDeviceName: string;
  /** Настройки для кнопок подготовать/скачать фото/видео */
  public downloadSourcesOptions: IDownloadSourcesOptions;
  /** Доступность просмотра материалов */
  public canShowPhotoAndVideo: IAccessAction = { visible: false, enabled: false, name: 'CanPhotoAndVideo' };
  /** Доступность скачивания материалов */
  public canPreparePhotoAndVideo: IAccessAction = { visible: false, enabled: false, name: 'CanPreparePhotoAndVideo' };
  /**
   * Справочник видеоаналитики
   */
  private vaTypesDict: IDictionaryInfo[];
  /** Внешний id камеры */
  private extCameraId: string;
  private timeBeforeEvent: number = THIRTY_SECONDS;
  private timeAfterEvent: number = THIRTY_SECONDS;
  /** Флаг является ли событие неисправностью */
  public isFault: boolean = false;
  public linkedName: string;

  constructor(
    private readonly bgAdmin: BgAdminService,
    private readonly videoDevice: VideoDevicesService,
    private readonly videoDevicesService: VideoDevicesService,
    private readonly emergencyService: EmergencyService,
    private readonly settings: Settings2Service,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly gisService: BgMapService,
    private readonly accessService: AccessService,
    private readonly ksipTypesQuery: KsipTypesQuery,
  ) {
    super();
  }

  /**
   * Инцидент
   */
  public get incident(): IEmergencyDto {
    return this.model.incidentId as IEmergencyDto;
  }

  /**
   * Данные видеоаналитики
   */
  public get vaDetail(): IEmergencyVaDetailDto {
    return this.model?.vaDetail as IEmergencyVaDetailDto;
  }

  ngOnInit() {
    const docTypeObj = this.settings.getDictObjByTypeSysName('docType').fault;
    this.isFault = docTypeObj?.id === (this.model.incidentId as IEmergencyDto)?.docType;
    this.linkedName = this.isFault ? `Неисправность ${ this.incident?.number }` : `Инцидент ${ this.incident?.number }`;

    this.canShowPhotoAndVideo = this.accessService.accessMap[this.canShowPhotoAndVideo.name];
    this.canPreparePhotoAndVideo = this.accessService.accessMap[this.canPreparePhotoAndVideo.name];
    this.vaTypesDict = this.settings.getDictObjsIdsByType('monitoringSubject');
    const parseVaDetail = JSON.parse(this.vaDetail.file1Id);

    const vaTypeId = typeof this.vaDetail.vaTypeId === 'string' ? this.vaDetail.vaTypeId : this.vaDetail.vaTypeId.id;

    const videoServerData = this.videoDevicesService.getVideoDevice(this.vaDetail.camera1Id as string).pipe(
      takeUntil(this.ngUnsubscribe),
      mergeMap((result: IVideoDeviceBg) => {
        if (result) {
          this.extCameraId = result.extId;
          this.videoDeviceName = result.name;
          return this.videoDevicesService.getVideoServerById(<string>result.videoServer);
        }
        return of(null);
      }),
      catchError((error: Error) => this.catchErrorFn<IVideoDeviceDto>(error, 'Ошибка загрузки данных о камере')),
    );

    forkJoin([
      this.videoDevicesService.getTypeVaSettingsByMonitoringSubject(vaTypeId),
      this.videoDevicesService.getVideoDeviceRangeStreamUrl(
        this.vaDetail.camera1Id as string,
        this.vaDetail.camera1Time,
      ),
      videoServerData,
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: [IMonitoringDate, string, IAbstractServiceData]) => {
        const fromTime = this.vaDetail.camera1Time - (result[0]?.timeBeforeEvent || THIRTY_SECONDS);
        const toTime = this.vaDetail.camera1Time + (result[0]?.timeAfterEvent || THIRTY_SECONDS);
        const items = result ? result[2].data.items : [];
        this.timeBeforeEvent = result[0]?.timeBeforeEvent || THIRTY_SECONDS;
        this.timeAfterEvent = result[0]?.timeAfterEvent || THIRTY_SECONDS;
        const serverData = [];
        if (items.length) {
          serverData[0] = {
            ...items[0],
            extId: this.extCameraId,
          };
        }

        this.downloadSourcesOptions = {
          vaTypeId,
          fromTime,
          toTime,
          serverData,
          entityId: this.model.id,
          photoList: Array.isArray(parseVaDetail) ? parseVaDetail : [this.vaDetail.file1Id],
          timeCreate: +this.vaDetail.camera1Time,
          isShowVideoButton: !!result[1],
          photoFileName: `Фотоматериалы_${this.model.number}`,
          videoFileName: `Видеоматериалы_${this.model.number}`,
        };
      });

    this.generateHeaderBar();

    this.eventForm = new FormGroup({
      comment: new FormControl({
        value: this.model.comment || '',
        disabled: this.model.isHandled,
      }),
      ksipInfo: new FormControl({
        value: this.model.ksipInfo || '',
        disabled: this.model.isHandled,
      }),
      exactCoordinates: new FormControl(this.model.exactCoordinates, []),
    });

    this.ksipTypeName$ = of(this.ksipTypesQuery.getById(this.model.ksipTypeId).name);

    this.getUserName();

    this.greateThenMaxPersons =
      this.vaDetail.persons && this.vaDetail.personsLimit
        ? this.vaDetail.persons - this.vaDetail.personsLimit
        : undefined;
  }

  /** Форматирует дату создания происшествия в необходимый для отображения вид */
  public formatDate(date: number): string {
    if (date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss');
    }
    return '';
  }

  /**
   * Открываем карточку инцидента
   */
  public openEmergency() {
    const targetUrl = this.getWorkspaceURL();
    window.open(targetUrl);
  }

  /**
   * Проверка типа аналитики - Массовое скопление
   */
  public isCrowds(): boolean {
    if (this.vaTypesDict && this.vaDetail) {
      return (
        (this.vaTypesDict[this.vaDetail.vaTypeId as string] || {})?.sysname?.toString() ===
        VideoEmergencyTypeEnum.crowds.toString()
      );
    }

    return false;
  }

  /**
   * Проверка типа аналитики - Оставленные предметы
   */
  public isLeftThing(): boolean {
    if (this.vaTypesDict && this.vaDetail) {
      return (
        (this.vaTypesDict[this.vaDetail.vaTypeId as string] || {})?.sysname?.toString() ===
        VideoEmergencyTypeEnum.leftThing.toString()
      );
    }

    return false;
  }

  /**
   * Проверка типа аналитики - Распознавание лиц
   */
  public isFaceRecognition(): boolean {
    if (this.vaTypesDict && this.vaDetail) {
      return (this.vaTypesDict[this.vaDetail.vaTypeId as string] || {}).sysname?.toString() === 'faceRecognition';
    }
    return false;
  }

  /**
   * Получение данных из формы и сохранение в модель
   */
  public getModelData() {
    this.model.comment = this.eventForm.controls['comment'].value;
    this.model.ksipInfo = this.eventForm.controls['ksipInfo'].value;
    if (!this.model.responsibleId) {
      this.model.responsibleId = this.settings.currentUser.id;
    }
  }

  /**
   * Обрабатываем нажатие кнопки управления состоянием
   * @param $event
   */
  public onClickActionsButton($event: IElementButton) {
    if ($event.options.name === 'cancel') {
      this.closeActiveForm();
      return;
    }

    if (this.eventForm.valid) {
      if ($event.options.name === 'closeWithoutReaction') {
        const dialogRef = this.dialog.open(CloseWithoutReactionDialogComponent, {
          closeOnNavigation: true,
          panelClass: 'close-without-reaction-dialog-container',
        });

        dialogRef.afterClosed().subscribe((result: string) => {
          if (result) {
            this.getModelData();
            this.model.closeReasonId = result;
            this.emergencyService
              .executeEventAction({
                action: $event.options.name,
                model: this.model,
              })
              .pipe(
                catchError((error: Error) =>
                  this.catchErrorFn<IEmergencyEventDto>(error, 'Ошибка при выполнении действия'),
                ),
                takeUntil(this.ngUnsubscribe),
              )
              .subscribe((response: IEmergencyEventDto) => {
                this.noteService.pushInfo('Событие закрыто без реагирования');
                this.model = response;
                this.updateForm();
              });
          }
        });
      } else {
        this.getModelData();
        this.emergencyService
          .executeEventAction({
            action: $event.options.name,
            model: this.model,
          })
          .pipe(
            catchError((error: Error) =>
              this.catchErrorFn<IEmergencyEventDto>(error, 'Ошибка при выполнении действия'),
            ),
            takeUntil(this.ngUnsubscribe),
          )
          .subscribe((response: IEmergencyEventDto) => {
            this.noteService.pushInfo('Инцидент успешно создан');
            this.model = response;
            this.updateForm();
          });
      }
    }
  }

  /**
   * Данные фото-видеофиксации
   */
  public showImageVideoRecording() {
    if (this.canShowPhotoAndVideo?.enabled) {
      this.dialog.open(ImageVideoRecordingDialogComponent, {
        closeOnNavigation: true,
        panelClass: 'image-video-recording-dialog-container',
        data: <IImageVideoRecordingDialogData>{
          registrationTime: +this.vaDetail.camera1Time,
          fromTime: +this.vaDetail.camera1Time - this.timeBeforeEvent,
          toTime: +this.vaDetail.camera1Time + this.timeAfterEvent,
          cameraId: this.vaDetail.camera1Id,
          imageId: this.vaDetail.file1Id,
          frame: this.vaDetail['frame'],
          entityId: this.model.id,
        },
      });
    }
  }

  /** Обработчик клика по кнопку Уточнить адрес
   * @param $event
   */
  public onClickSpecifyAddress($event) {
    const factAddress = Object.assign({}, this.model?.address || this.eventForm.value.factAddress);
    const exactCoordinates = new Coordinates(
      this.eventForm.controls['exactCoordinates'].value || [factAddress?.latitude, factAddress?.longitude],
    );

    if (!exactCoordinates.isValid() && factAddress.fullText) {
      this.gisService
        .getCoordinatesByAddress(factAddress.fullText)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          catchError((err: Error) =>
            this.catchErrorFn<IGisServiceResult>(err, 'Ошибка при обработке запроса. Получения координат.'),
          ),
        )
        .subscribe((result: IGisServiceResult) => {
          const data = {
            coordinates: new Coordinates(),
          };
          if (result?.lat && result?.lon) {
            data.coordinates = new Coordinates(result.lat, result.lon);
          }
          this.openSpecifyMap(data);
        });
    } else {
      const data = {
        coordinates: exactCoordinates,
      };
      this.openSpecifyMap(data);
    }
  }

  /** Метод который обеспечивает открытие диалога и подписку на закрытие
   * @param data - параметры для диалога
   */
  public openSpecifyMap(data: { coordinates?: Coordinates }): void {
    const dialogRef = this.dialog.open(MapDialogComponent, {
      data,
      width: '600px',
    });

    let dialogSub: Subscription = dialogRef
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((params) => {
        if (params?.coordinates) {
          this.eventForm.controls['exactCoordinates'].setValue(`${params.coordinates[0]}, ${params.coordinates[1]}`);
        }
        if (dialogSub) {
          dialogSub.unsubscribe();
          dialogSub = null;
        }
      });
  }

  /**
   * Закрытие формы инцидента
   */
  public closeActiveForm() {
    this.emergencyService.selectEvent(undefined);
    this.router.navigate([{ outlets: { editForm: null, editEventForm: null } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }

  private getWorkspaceURL(): string {
    const id = typeof this.model.incidentId === 'object' ? this.model.incidentId['id'] : this.model.incidentId;
    let targetUrl = `http://${window.location.host}`;
    targetUrl += `/consolidated-registries/incident-register/(editForm:incident/${id})`;
    return targetUrl;
  }

  /**
   * Генерация заголовка
   */
  private generateHeaderBar() {
    const btnArray = [
      <IElementButton>{
        type: 'button',
        options: {
          name: 'cancel',
          icon: 'clear',
        },
      },
    ];

    if (this.isCrowds() || this.isLeftThing()) {
      if (!this.model.isHandled) {
        btnArray.push(<IElementButton>{
          type: 'button',
          options: {
            name: 'closeWithoutReaction',
            title: 'Закрыть без реагирования',
          },
        });
        btnArray.push(<IElementButton>{
          type: 'button',
          options: {
            color: 'primary',
            name: 'transferToService',
            title: 'Принять в работу',
          },
        });
      }
    }

    this.headerActionsOptions = {
      title: `Событие ${this.model.number}`,
      name: 'header',
      margin: 'collapse',
      bgColor: 'white',
      buttons: btnArray,
    };
  }

  /**
   * Запрос данных пользователя
   */
  private getUserName() {
    if (this.model.responsibleId) {
      this.userName$ = this.bgAdmin.getUserById(this.model.responsibleId).pipe(takeUntil(this.ngUnsubscribe));
    } else {
      this.userName$ = of(<IUserInfoDto>{});
    }
  }

  /**
   * Обновление формы
   */
  private updateForm() {
    if (this.model.isHandled) {
      this.eventForm.controls['comment'].disable();
      this.eventForm.controls['ksipInfo'].disable();
    }

    this.generateHeaderBar();
    this.getUserName();
  }
}
