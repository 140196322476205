<div>
  <div>
    <div class="dialog-header">
      <div class="bg-row dialog-header-title">
        <h2 mat-dialog-title>Запрос на привлечение службы</h2>
      </div>
    </div>
  </div>
  <org-involve-request-form
    [options]="data"
    (closeEvent)="closeHandler()"
  ></org-involve-request-form>
</div>
