import { Injectable } from '@angular/core';
import { NotificationService, RestService } from '@smart-city/core/services';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IAbstractServiceData } from 'smart-city-types';
import { IPbxQueue } from '@bg-front/core/models/interfaces';

/**
 * Сервис для работы с АТС и телефонными номерами
 */
@Injectable({
  providedIn: 'root',
})
export class PbxService {
  constructor(
    private readonly noteService: NotificationService,
    private readonly rest: RestService,
  ) {}

  /**
   * Метод получения записи очереди из таблицы AtsQueues сервиса Admin
   * @param id идентификатор записи
   * @return объект сущности "Справочник очередей"
   */
  public getPbxQueue(id: string): Observable<IPbxQueue> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'AtsQueues',
        query: { id },
      },
    }).pipe(
      map((response: IAbstractServiceData) => {
        return (response?.data?.items || [])[0] as IPbxQueue;
      }),
    );
  }

  /**
   * Получить массив телефонных номеров по их идентификаторам
   * @param ids массив идентификаторов телефонных номеров(AtsPhones)
   * @return массив телефонных номеров
   */
  public getPhonesByIds(ids: string[]): Observable<string[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'AtsPhones',
        query: {
          id: { $in: ids },
        },
      },
    }).pipe(
      map(({ data }: IAbstractServiceData) => (data.items?.map(elem => elem.phoneNumber) ?? [])),
    );
  }

  /**
   * Получить массив телефонных номеров по id АТС и имени очереди
   * @param pbxId идентификатор записи АТС(Admin_Ats)
   * @param queueName системное наименование очереди в телефонии(Admin_Ats.queues)
   * @return массив телефонных номеров
   */
  public getPhonesByPbxAndQueue(pbxId: string, queueName: string): Observable<string[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'Ats',
        query: { id: pbxId },
      },
    }).pipe(
      map((response: IAbstractServiceData) => {
        const ats = (response?.data?.items || [])[0];
        if (ats?.queues) {
          return ats.queues.find(queue => queue.name === queueName)?.phones || [];
        }
        return [];
      }),
      switchMap((phonesId: string[]) => {
        if (!phonesId.length) return of([]);
        return this.getPhonesByIds(phonesId);
      }),
    );
  }
}
