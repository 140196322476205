import { Injectable } from '@angular/core';
import { RestService } from '@smart-city/core/services';
import { PltWebsocket, PltWebsocketMessageType } from '@smart-city/core/plt-ws';
import { ScConsole } from '@smart-city/core/utils';
import { Settings2Service } from '@smart-city/core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject, throwError } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { IAbstractServiceData } from 'smart-city-types/services';
import {
  ITS_CORE_DEFAULT_AUTH_TOKEN,
  ITS_CORE_DEFAULT_IP,
  ITS_CORE_DEFAULT_PORT,
} from '../../models/constants';
import {
  IRnisRequest,
  IRnisResponse,
  IRnisRequestAddUser,
  IRnisRequestDeleteDevice,
  IRnisRequestUpdateDevice,
  IRnisState,
} from '../../models/interfaces';

/**
 * Сервис по работе с навигационными данными ТС (РНИС).
 * Взаимодействует с сервисом ItsCore.
 *
 * Сервис ожидает наличие следующих переменных в Конфигурации:
 * - itsCoreUrl
 * - itsCoreToken
 *
 * Пример установки WS соединения с РНИС.
 * @example
 * this.rnis.connect().pipe(untilDestroyed(this)).subscribe();
 *
 * Пример получения сообщений от РНИС используя открытое соединение.
 * @example
 * this.rnis.onRnisMessage$
 *   .pipe(untilDestroyed(this))
 *   .subscribe((data: IRnisResponse) => {
 *     console.log('onRnisMessage$ =>', data);
 *   });
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class RnisService {
  /** Инстанс plt-websocket */
  private rnisWs = new PltWebsocket();
  /** Позволяет подписаться на сообщения, приходящие от РНИС */
  private onRnisMessage = new Subject<IRnisResponse>();
  /** Наблюдатель, возвращающий сообщения из РНИС */
  public onRnisMessage$ = this.onRnisMessage.asObservable();

  constructor(
    private readonly rest: RestService,
    private readonly settings: Settings2Service,
  ) {
    /** Обработка сообщений из сокета */
    this.rnisWs.onMessage(PltWebsocketMessageType.JSON)
      .pipe(untilDestroyed(this))
      .subscribe((data: IRnisResponse) => {
        this.onRnisMessage.next(data);
      });
  }

  /**
   * Отправка сообщения в РНИС
   * @param body объект запроса. Поле command (команда в ядре) обязательно.
   */
  public sendRequest(body: IRnisRequest): void {
    const bodyRequest = {
      ...body,
      auth_token: ITS_CORE_DEFAULT_AUTH_TOKEN,
    };
    this.rnisWs.send(JSON.stringify(bodyRequest));
  }

  /** Соединение с сокетом */
  public connect(): Observable<void> {
    const url = this.getUrl();
    if (!url) return throwError(new Error('RnisService.connect Не задан host'));
    return this.rnisWs.connect({ url, pingPong: false })
      .pipe(map(() => void 0));
  }

  /**
   * Закрытие открытого соединения
   */
  public close(): void {
    if (this.rnisWs) this.rnisWs.close();
  }

  /**
   * Получение всех зарегистрированных устройств
   * @return список всех когда-либо зарегистрированных устройств
   */
  public getAllDevice(): Observable<IRnisResponse> {
    return this.rest.serviceRequest(
      {
        action: 'getAllDevice',
        service: { name: 'RnisIntegration' },
        needResponse: true,
      },
      'http',
    ).pipe(map((response: IAbstractServiceData) => response.data));
  }

  /**
   * Получить устройства доступные пользователю
   * @param userId идентификатор пользователя
   * @returns объект с результатом выполнения команды
   */
  public getDevice(userId: string): Observable<IRnisResponse> {
    return this.rest.serviceRequest(
      {
        action: 'getDevice',
        service: { name: 'RnisIntegration' },
        needResponse: true,
        data: {
          user: userId,
        },
      },
      'http',
    ).pipe(map((response: IAbstractServiceData) => response.data));
  }

  /**
   * Получение последних навигационных данных от устройств
   * @param userId - ID пользователя
   * @param att - список устройств
   */
  public getCurrent(userId: string, att: string[]): Observable<IRnisState[]> {
    return this.rest.serviceRequest({
        action: 'current',
        service: { name: 'RnisIntegration' },
        needResponse: true,
        data: { user: userId, filter: att },
      },
      'http',
    ).pipe(pluck('data', 'result', 'states'));
  }

  /**
   * Удаляет пользователя и отвязывает устройства от него
   * @param userId идентификатор пользователя
   * @returns объект с результатом выполнения команды
   */
  public deleteUser(userId: string): Observable<IRnisResponse> {
    return this.rest.serviceRequest(
      {
        action: 'deleteUser',
        service: { name: 'RnisIntegration' },
        needResponse: true,
        data: {
          user: userId,
        },
      },
      'http',
    ).pipe(map((response: IAbstractServiceData) => response.data));
  }

  /**
   * Добавляет пользователя с возможностью сразу привязать устройства
   * @param params параметры запроса
   * @returns объект с результатом выполнения команды
   */
  public addUser(params: IRnisRequestAddUser): Observable<IRnisResponse> {
    return this.rest.serviceRequest(
      {
        action: 'addUser',
        service: { name: 'RnisIntegration' },
        needResponse: true,
        data: params,
      },
      'http',
    ).pipe(map((response: IAbstractServiceData) => response.data));
  }

  /**
   * Получение идентификаторов всех пользователей
   * @return список идентификаторов всех пользователей
   */
  public getAllUsers(): Observable<string[]> {
    return this.rest.serviceRequest(
      {
        action: 'getAllUsers',
        service: { name: 'RnisIntegration' },
        needResponse: true,
      },
      'http',
    ).pipe(map((response: IAbstractServiceData) => {
      return (response?.data as IRnisResponse).result?.users;
    }));
  }

  /**
   * Привязывает список устройств к пользователю.
   * Примечание. Если устройства уже привязаны, ошибки не будет
   * @param params параметры запроса
   * @returns объект с результатом выполнения команды
   */
  public updateDevice(params: IRnisRequestUpdateDevice): Observable<IRnisResponse> {
    return this.rest.serviceRequest(
      {
        action: 'updateDevice',
        service: { name: 'RnisIntegration' },
        needResponse: true,
        data: params,
      },
      'http',
    ).pipe(map((response: IAbstractServiceData) => response.data));
  }

  /**
   * Отвязывает устройства от пользователя.
   * Примечание. Если устройства не привязаны к пользователю, ошибки не будет
   * @param params параметры запроса
   * @returns объект с результатом выполнения команды
   */
  public deleteDevice(params: IRnisRequestDeleteDevice): Observable<IRnisResponse> {
    return this.rest.serviceRequest(
      {
        action: 'deleteDevice',
        service: { name: 'RnisIntegration' },
        needResponse: true,
        data: params,
      },
      'http',
    ).pipe(map((response: IAbstractServiceData) => response.data));
  }

  /**
   * Привязка указанных регистрационных номеров
   * @param params словарь устройств с привязанными к ним регистрационными номерами
   * @return объект с результатом выполнения команды
   */
  public bindingDevice(params: { [originalAtt: string]: string }): Observable<IRnisResponse> {
    return this.rest.serviceRequest(
      {
        action: 'bindingDevice',
        service: { name: 'RnisIntegration' },
        needResponse: true,
        data: {
          grz: { ...params },
        },
      },
      'http',
    ).pipe(map((response: IAbstractServiceData) => response.data));
  }

  /**
   * Удаляет регистрационные номера у указанных устройств
   * @param device список устройств у которых надо удалить регистрационные номера
   * @return объект с результатом выполнения команды
   */
  public deleteGrz(device: string[]): Observable<IRnisResponse> {
    return this.rest.serviceRequest(
      {
        action: 'deleteGrz',
        service: { name: 'RnisIntegration' },
        data: {
          device,
        },
        needResponse: true,
      },
      'http',
    ).pipe(map((response: IAbstractServiceData) => response.data));
  }

  /**
   * Получение всех устройств с регистрационным номером
   * @return объект с результатом выполнения команды
   */
  public getGrz(): Observable<IRnisResponse> {
    return this.rest.serviceRequest(
      {
        action: 'getGrz',
        service: { name: 'RnisIntegration' },
        needResponse: true,
      },
      'http',
    ).pipe(map((response: IAbstractServiceData) => response.data));
  }

  /**
   * Метод получает ws ссылку на навигационный сервис
   * @returns ссылка для ws запросов
   */
  private getUrl(): string {
    const itsCoreUrl = `${ITS_CORE_DEFAULT_IP}:${ITS_CORE_DEFAULT_PORT}`;
    const protocol = window.location.protocol === 'http:' ? 'ws://' : 'wss://';
    const host = window.location.hostname;
    /** метод корректировки ссылки для ws соединения */
    const parsWsUrl = (url: string): string => {
      if (url.startsWith('/')) {
        return `${protocol}${host}:${ITS_CORE_DEFAULT_PORT}${url}/ws`;
      }
      if (!url.includes('://')) {
        return `${protocol}${url}/ws`;
      }
      return url;
    };

    /** Локальный запуск */
    if (host === 'localhost') {
      return parsWsUrl(itsCoreUrl);
    }

    const url = this.settings.getConfig()?.itsCoreUrl;

    if (!url) {
      ScConsole.warning(`Настройка itsCoreUrl не определена! URL: ${protocol}${ITS_CORE_DEFAULT_IP}:${ITS_CORE_DEFAULT_PORT}/ws`);
      return `${protocol}${ITS_CORE_DEFAULT_IP}:${ITS_CORE_DEFAULT_PORT}/ws`;
    }

    return parsWsUrl(url);
  }
}
