<form [formGroup]="$any(controlContainer.control)">
  <div class="parameters-container">
    <div class="parameters__body">
      <div class="bg-row">
        <div class="bg-col-5">
          <div class="bg-form-group">
            <sc-select [options]="fireTypesOptions" formControlName="fireTypeId"></sc-select>
          </div>
        </div>
      </div>
    </div>
    <div class="parameters__header">
      <label>Данные об очаге пожара</label>
    </div>
    <div class="parameters__body">
      <div class="bg-row">
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-select [options]="sizingTypesOptions" formControlName="sizingTypeId"></sc-select>
          </div>
        </div>
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-input
              [options]="sizingTypeValueOptions"
              showErrorOnStart="true"
              showErrorOnStartAfter="3000"
              formControlName="size"
            ></sc-input>
          </div>
        </div>
      </div>
      <div class="bg-row" *ngIf="isDownstream">
        <div class="bg-col-4">
          <div class="bg-form-group">
            <sc-input
              [options]="sizingTypeHeightValueOptions"
              showErrorOnStart="true"
              showErrorOnStartAfter="3000"
              formControlName="height"
            ></sc-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
