import { Injectable } from '@angular/core';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { IAbstractServiceData, IAbstractServiceEntitySelectResult, IAnyObject, IUser } from 'smart-city-types';
import { IRegistryLoadDataParams } from '@smart-city/core/common';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Uuid } from '@smart-city/core/utils';

@Injectable({
  providedIn: 'root',
})
export class TasksService {

  /** @ignore */
  constructor(
    private readonly rest: RestService,
    private settings2: Settings2Service,
  ) {
  }

  /**
   * Получить список задач на обзвон
   * @param query Условие фильтрации
   * @param paginator Пагинация
   */
  public getTasks(query?: IAnyObject, paginator?: { limit?: number, offset?: number })
  : Observable<IAbstractServiceEntitySelectResult> {
    let tasks: IAnyObject[] = [];
    let totalCount = 0;
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Calls' },
      entity: {
        query,
        name: 'Tasks',
        attributes: [
          'name',
          'executionDate',
          'responsible.fio',
          'gasterCallTaskId.state.name',
          'gasterCallTaskId.state.sysname',
          'type',
          'type.id',
          'type.sysname',
          'state',
          'state.id',
          'state.name',
          'creationTime',
        ],
        ...paginator,
        sort: {
          field: 'creationTime',
          direction: 'desc',
        },
      },
      data: {
        isNeedTotal: true,
        order: {
          $expr: {
            $cond: [
              { $expr: { $eq: ['$gasterCallTaskId.state.sysname', 'new'] } },
              0,
              {
                $expr: {
                  $cond: [
                    {
                      $expr: {
                        $or: [
                          { $expr: { $eq: ['$gasterCallTaskId.state.sysname', 'inProgress'] } },
                          { $expr: { $eq: ['$gasterCallTaskId.state.sysname', 'pause'] } },
                        ],
                      },
                    },
                    1,
                    2,
                  ],
                },
              },
            ],
          },
        },
      },
    }).pipe(switchMap((res: IAbstractServiceData) => {
      tasks = res.data?.items ?? [];
      totalCount = res.data?.totalCount ?? 0;
      return this.rest.serviceRequest({
        action: 'select',
        service: { name: 'Calls' },
        entity: {
          name: 'TasksCitizensRelations',
          attributes: ['taskId'],
        },
        data: {
          progress: {
            $expr: {
              $divide: [
                {
                  $expr: {
                    $sum: [{
                      $expr: {
                        $cond: [
                          {
                            $expr: {
                              $eq: [
                                '$status',
                                this.settings2.getDictionaryByTypeAndSysName('taskStatus', 'finished').id,
                              ],
                            },
                          },
                          1,
                          0,
                        ],
                      },
                    }],
                  },
                },
                { $expr: { $sum: [1] } },
              ],
            },
          },
        },
      });
    })).pipe(map((res: IAbstractServiceData) => {
      const progress = (res.data?.items || []).reduce((acc, item) => ({
        ...acc,
        [item.taskId]: parseFloat(item.progress ?? 0),
      }), {});
      const items = tasks.map((task: IAnyObject) => {
        const isSms =
          task['type.id'] === this.settings2.getDictObjByTypeSysName('taskType')['smsSending']?.id;
        task.progress = progress[task.id] ?? 0;
        task.responsible = task['responsible.fio'];
        task.type = this.settings2.getDictionaryById(task['type.id'])?.name?.toLowerCase();
        delete task['responsible.fio'];
        if (isSms) {
          task.state = task['state.name'];
          task.progress =
            this.settings2.getDictObjByTypeSysName('taskStatus')['finished']?.id === task['state.id'];
        } else {
          task.state = task['gasterCallTaskId.state.name'];
        }
        delete task['gasterCallTaskId.state.name'];
        delete task['gasterCallTaskId.state.sysname'];
        task.type = this.settings2.getDictionaryById(task['type.id'])?.name?.toLowerCase();
        return task;
      });
      return {
        items,
        totalCount,
      };
    }));
  }

  /**
   * Функция загрузки данных в реестр заданий
   * @param query Условия выборки
   * @param params Параметры сортировки и пагинации
   */
  public loadRegistryDataFn = (query: IAnyObject, params: IRegistryLoadDataParams) => {
    return this.getTasks(query, {
      limit: params.paSize,
      offset: (params.paSize ?? 0) * (params.paNumber ?? 0),
    });
  }

  /**
   * Метод, создающий новую запись в таблице Tasks сервиса Calls.
   * @param user пользователь, инициировавший создание.
   * @param value объект с полями из формы.
   */
  public createTask(user: IUser, value: IAnyObject, autodialing?: boolean): Observable<IAbstractServiceData> {
    const taskId = value.id ?? Uuid.newUuid();
    const gasterCallTaskId = Uuid.newUuid();
    const data: IAnyObject = {
      gasterCallTaskId,
      id: taskId,
      citizens: value.citizens,
      comment: value.comment,
      type: value.type,
      state: value.state ?? this.settings2.getDictionaryByTypeAndSysName('taskStatus', 'new').id,
      responsible: value.responsible,
      description: value.description,
      executionDate: value.executionDate,
      organizationId: value.organizationId,
      result: value.result,
      name: value.name,
    };

    const gasterCallTask = {
      id: gasterCallTaskId,
      idSFS: value['gasterCallTaskId.idSFS'],
      progress: 0,
      state: value.state ?? this.settings2.getDictionaryByTypeAndSysName('taskStatus', 'new').id,
    };

    const citizensInsert = this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Citizens' },
      entity: {
        name: 'Citizens',
        query: value.citizensQuery,
        attributes: ['id'],
      },
      data: {
        phone: {
          $join: {
            service: 'Citizens',
            entity: 'CitizensPhones',
            attributes: ['citizenId', 'phoneNumber', 'isPrimary'],
            query: {
              $and: [
                { 'phone.isPrimary': true },
                {
                  $expr: {
                    $eq: [
                      { $expr: { $toObjectId: ['$id'] } },
                      { $expr: { $toObjectId: ['$phone.citizenId'] } },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
    }).pipe(
      switchMap((res: IAbstractServiceData) => {
        const citizens: IAnyObject[] = res.data.items.map(citizen => ({
          ...citizen,
          phone: citizen.phone?.phoneNumber,
          gasterUnitId: Uuid.newUuid(),
        }));
        if (!autodialing) {
          return this.rest.serviceRequest({
            action: 'insert',
            service: { name: 'Calls' },
            entity: {
              name: 'TasksCitizensRelations',
            },
            data: citizens.map(citizen => ({
              taskId,
              citizenId: citizen.id,
              status: this.settings2.getDictionaryByTypeAndSysName('taskStatus', 'new').id,
            })),
          });
        }
        return forkJoin([
          this.rest.serviceRequest({
            action: 'insert',
            service: { name: 'Calls' },
            entity: {
              name: 'TasksCitizensRelations',
            },
            data: citizens.map(citizen => ({
              taskId,
              citizenId: citizen.id,
              status: this.settings2.getDictionaryByTypeAndSysName('taskStatus', 'new').id,
              gasterUnitId: citizen.gasterUnitId,
            })),
          }),
          this.rest.serviceRequest({
            action: 'insert',
            service: { name: 'Gaster' },
            entity: {
              name: 'Unit',
            },
            data: citizens.map(citizen => ({
              taskId: gasterCallTaskId,
              id: citizen.gasterUnitId,
              number: citizen.phone,
              state: this.settings2.getDictionaryByTypeAndSysName('taskStatus', 'new').id,
            })),
          }),
        ]);
      }),
    );

    return forkJoin([
      this.rest.serviceRequest({
        data,
        action: 'insert',
        service: {
          name: 'Calls',
        },
        entity: {
          name: 'Tasks',
        },
      }),
      autodialing
      ? this.rest.serviceRequest({
        data: gasterCallTask,
        action: 'insert',
        service: {
          name: 'Gaster',
        },
        entity: {
          name: 'CallTask',
        },
      })
      : of(null),
      value.citizensQuery ? citizensInsert : of(null),
    ]).pipe(map(res => res[0]));
  }

  /**
   * Метод, создающий новую запись в таблице Tasks сервиса Calls.
   * @param user пользователь, инициировавший создание.
   * @param value объект с полями из формы.
   */
  public createSmsTask(user: IUser, value: IAnyObject): Observable<IAbstractServiceData> {
    const taskId = value.id ?? Uuid.newUuid();
    const gasterCallTaskId = Uuid.newUuid();
    const data: IAnyObject = {
      gasterCallTaskId,
      id: taskId,
      citizens: value.citizens,
      comment: value.comment,
      type: value.type,
      state: value.state ?? this.settings2.getDictionaryByTypeAndSysName('smsTaskStatus', 'new').id,
      responsible: value.responsible,
      description: value.description,
      executionDate: value.executionDate,
      organizationId: value.organizationId,
      result: value.result,
      name: value.name,
    };

    const obs =  this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Citizens' },
      entity: {
        name: 'Citizens',
        query: value.citizensQuery,
        attributes: ['id'],
      },
      data: {
        phone: {
          $join: {
            service: 'Citizens',
            entity: 'CitizensPhones',
            attributes: ['citizenId', 'phoneNumber', 'isPrimary'],
            query: {
              $and: [
                { 'phone.isPrimary': true },
                {
                  $expr: {
                    $eq: [
                      { $expr: { $toObjectId: ['$id'] } },
                      { $expr: { $toObjectId: ['$phone.citizenId'] } },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
    }).pipe(
      switchMap((res: IAbstractServiceData) => {
        const citizens: IAnyObject[] = res.data.items.map(citizen => ({
          ...citizen,
          phone: citizen.phone?.phoneNumber,
          gasterUnitId: Uuid.newUuid(),
        }));
        return this.rest.serviceRequest({
          action: 'insert',
          service: { name: 'Calls' },
          entity: {
            name: 'TasksCitizensRelations',
          },
          data: citizens.map(citizen => ({
            taskId,
            citizenId: citizen.id,
            status: this.settings2.getDictionaryByTypeAndSysName('smsTaskStatus', 'new').id,
          })),
        });
      }),
    );

    return forkJoin([
      this.rest.serviceRequest({
        data,
        action: 'insert',
        service: {
          name: 'Calls',
        },
        entity: {
          name: 'Tasks',
        },
      }),
      value.citizensQuery ? obs : of(null),
    ]).pipe(map(res => res[0]));
  }

  /**
   * Получение записей из словаря типов заданий
   * @param query Поисковый запрос
   */
  public getTaskTypes(query: IAnyObject): Observable<IScSelectItem[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'Dictionary',
        query: {
          ...query,
          'typeid.sysname': 'taskType',
        },
      },
    }).pipe(map((res: IAbstractServiceData) => {
      return (res?.data?.items ?? []).map((item) => {
        return { id: item.id, text: item.name, data: item };
      });
    }));
  }

  /**
   * Получение записей из словаря статусов заданий
   * @param query Поисковый запрос
   */
  public getTaskStatuses(query: IAnyObject): Observable<IScSelectItem[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'Dictionary',
        query: {
          ...query,
          'typeid.sysname': 'taskStatus',
        },
      },
    }).pipe(
      map(
        (data: IAbstractServiceData) => (((data || { data: null })
          .data || { items: null }).items || [])
          .map(item => ({ id: item.id, text: item.name })),
      ),
    );
  }

  /**
   * Получение задания для вывода в форму
   * @param id Идентификатор задания
   */
  public getTaskById(id: string): Observable<IAnyObject> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Calls' },
      entity: {
        name: 'Tasks',
        attributes: [
          'id',
          'result',
          'comment',
          'citizens',
          'responsible',
          'description',
          'executionDate',
          'creationTime',
          'creationAuthor',
          'status.id',
          'status.sysname',
          'type.id',
          'type.sysname',
        ],
        query: { id },
      },
    }).pipe(
      map(data => (data?.data?.items ?? [])[0] ?? {}),
    );
  }

  /**
   * Получение списка пользователей для селекта "Ответственный"
   * @param query поисковый запрос
   * @param userId идентификатор пользователя
   */
  public getResponsibleSelectData(query: IAnyObject, userId: string): Observable<IScSelectItem[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'Users',
        query: { id: userId },
      },
    }).pipe(
      switchMap((result: IAbstractServiceData) => {
        const { organizationId } = (((result || { data: null }).data || { items: null }).items || [])[0];
        return this.rest.serviceRequest({
          action: 'select',
          service: { name: 'Admin' },
          entity: {
            name: 'Users',
            query: { ...query, organizationId: organizationId.id },
          },
        });
      }),
      map((result: IAbstractServiceData) => {
        const items = (((result || { data: null }).data || { items: null }).items || []);
        return items.map(item => ({
          id: item.id,
          text: item.fio,
          data: item,
        }));
      }),
    );
  }
}
