import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IScSelectOptions } from '@smart-city/core/common';
import { Settings2Service } from '@smart-city/core/services';
import { Certificate } from 'crypto-pro';
import * as dayjs from 'dayjs';
import { map } from 'rxjs/operators';

import { ISignature } from '../../models/interfaces/signature.interface';
import { SignatureService } from '../../services/signature/signature.service';

// @dynamic
/** Диалоговое окно подписания электронной цифровой подписью */
@UntilDestroy()
@Component({
  selector: 'signature-dialog',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.scss'],
})
export class SignatureDialogComponent {
  /** Форма */
  public form: FormGroup;

  /** Настройка компоненты Тип подписи */
  public typeIdOptions: IScSelectOptions = {
    title: 'Тип подписи',
    data: this.settings.getDictForSelect('electronicSignatureTypes'),
    required: true,
  };

  /** Настройка компоненты Сертификат */
  public certificateOptions: IScSelectOptions = {
    name: 'certificate',
    title: 'Сертификат',
    required: true,
    loadDataFn: () =>
      this.signatureService
        .getUserCertificates()
        .pipe(
          map((result: Certificate[]) =>
            result.map((certificate: Certificate) => ({ id: certificate.thumbprint, text: certificate.name })),
          ),
        ),
  };

  /** Признак, что выбрана Усиленная квалифицированная электронная подпись */
  public isDigitalSignature = false;

  /** @ignore */
  constructor(
    private readonly settings: Settings2Service,
    private readonly dialogRef: MatDialogRef<SignatureDialogComponent>,
    private readonly signatureService: SignatureService,
    @Inject(MAT_DIALOG_DATA) private data: { typeId: string; certificate: string },
  ) {}

  /** @ignore */
  public ngOnInit(): void {
    this.form = new FormGroup({
      typeId: new FormControl(this.data.typeId, Validators.required),
      certificate: new FormControl(undefined, Validators.required),
    });

    if (this.settings.getDictionaryById(this.data.typeId)?.sysname === 'reinforcedElectronicSignature') {
      this.isDigitalSignature = true;
      this.form.controls.certificate.enable();
      this.form.controls.certificate.setValue(this.data.certificate);
    }

    this.form.controls.typeId.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      this.isDigitalSignature = this.settings.getDictionaryById(value)?.sysname === 'reinforcedElectronicSignature';
      if (this.isDigitalSignature) this.form.controls.certificate.enable();
      if (!this.isDigitalSignature) this.form.controls.certificate.disable();
    });
  }

  /** Отмена */
  public cancel() {
    this.dialogRef.close();
  }

  /** Подписать */
  public sign() {
    if (this.form.invalid) return;
    if (!this.isDigitalSignature) {
      this.dialogRef.close({
        typeId: this.form.controls.typeId.value,
        ownerFio: this.settings.currentUser.fio,
        ownerOrganization: this.settings.currentUser.organizationId.name,
        signingDate: dayjs().toISOString(),
      });
      return;
    }

    this.signatureService
      .getCertificate(this.form.controls.certificate.value)
      .pipe(
        map((certificate: Certificate) => ({
          typeId: this.form.controls.typeId.value,
          certificate: certificate.thumbprint,
          ownerFio: this.signatureService.getCertificateField(certificate.subjectName, 'CN='),
          ownerOrganization: this.signatureService.getCertificateField(certificate.subjectName, 'OU='),
          validFrom: certificate.validFrom,
          validTo: certificate.validTo,
          signingDate: dayjs().toISOString(),
        })),
        untilDestroyed(this),
      )
      .subscribe((signature: ISignature) => this.dialogRef.close(signature));
  }
}
