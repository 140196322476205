import { Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { IScSelectOptions, IScTextareaOptions } from '@smart-city/core/common';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';


import { IReasonDto } from 'smart-city-types';

@Component({
  selector: 'bg-close-without-reaction-dialog',
  templateUrl: './close-without-reaction-dialog.component.html',
  styleUrls: ['./close-without-reaction-dialog.component.scss'],
})
export class CloseWithoutReactionDialogComponent {
  /**
   * Настройка компоненты "Причина неисправности"
   */
  public optionsReasons: IScSelectOptions = {
    title: 'Причина закрытия события',
    data: this.getReasons(),
    clearable: true,
  };

  /**
   * Настройка компоненты Комментарий
   */
  public optionsComment: IScTextareaOptions = {
    label: 'Комментарий для заявителя',
    maxLength: 1000,
    rows: 2,
  };

  /**
   * Причина закрытия
   */
  reasonId: string = undefined;

  /**
   * Нужен ли комментарий
   */
  needComment = false;

  /**
   * Комментарий
   */
  comment: string = undefined;

  constructor(
    private readonly settings: Settings2Service,
    public readonly dialogRef: MatDialogRef<CloseWithoutReactionDialogComponent>,
  ) {}

  /**
   * Закрытие
   */
  public close() {
    this.dialogRef.close();
  }

  /**
   * Сохранение
   */
  public save() {
    this.dialogRef.close(this.needComment ? { reasonId: this.reasonId, comment: this.comment } : this.reasonId);
  }

  /**
   * Проверяем валидность формы
   */
  public isValid(): boolean {
    if (this.needComment) {
      return !!this.reasonId && !!this.comment;
    }

    return !!this.reasonId;
  }

  /**
   * Список причин
   */
  private getReasons() {
    const groupId = this.settings.reasonGroups.find((group: any) => group.sysName === 'eventCloseReasons').id;
    return this.settings.reasons
      .filter((reason: IReasonDto) => reason.groupId === groupId)
      .map((reason: IReasonDto) => {
        return <IScSelectItem>{
          id: reason.id,
          text: reason.name,
        };
      });
  }
}
