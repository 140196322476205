import { Injectable } from '@angular/core';
import { Coordinates } from '@bg-front/core/models/classes';
import { QueryEntity } from '@datorama/akita';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISignificantObjectDto } from 'smart-city-types';

import { SignificantObjectsState } from '../../../models/states';
import { SignificantObjectsStore } from '../store/significant-objects.store';

@UntilDestroy()
@Injectable()
export class SignificantObjectsQuery extends QueryEntity<SignificantObjectsState> {
  constructor(protected override store: SignificantObjectsStore) {
    super(store);
  }

  /**
   * Получение важных объектов расположенных рядом с заданной координатой и не дальше указанного радиуса
   * @default radius 500 метров
   * @param coord класс координат
   * @param radius расстояние не далее которого производится поиск
   */
  public getCloseObject(coord: Coordinates, radius: number = 500): Observable<ISignificantObjectDto[]> {
    return this.selectAll({
      filterBy: [
        ({ address, coordinates }: Partial<ISignificantObjectDto>) =>
          !!coordinates || (!!address?.latitude && !!address?.longitude),
        ({ address, coordinates }: Partial<ISignificantObjectDto>) => {
          let itemCoord;
          if (coordinates) {
            itemCoord = new Coordinates(coordinates);
          } else {
            itemCoord = new Coordinates(address?.latitude, address?.longitude);
          }

          return Coordinates.distanceBetweenCoordinates(coord, itemCoord) <= radius;
        },
      ],
    });
  }

  /**
   * Получение имени ВО по Id
   * @param {string} somebody - Somebody's name.
   * @returns {string} Имя объекта
   */
  public getObjectNameById(id: string): Observable<string> {
    return this.selectEntity(id).pipe(map((item: ISignificantObjectDto) => item.shortName));
  }
}
