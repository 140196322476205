import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { LoosesListComponent } from '../looses-list/looses-list.component';

@Component({
  selector: 'rescued-list',
  templateUrl: './rescued-list.component.html',
  styleUrls: ['./rescued-list.component.scss'],
})
export class RescuedListComponent extends LoosesListComponent implements OnInit {

  /** @ignore */
  public override ngOnInit(): void {
    super.ngOnInit();
    this.listData.forEach(item =>
      (<FormArray>this.form.controls.list).push(new FormGroup({
        id: new FormControl(item.id),
        fio: new FormControl(item.fio),
        birthYear: new FormControl(item.birthYear),
        comment: new FormControl(item.comment),
      })),
    );
  }

  /**
   * Добавление записи
   */
  public add() {
    (<FormArray>this.form.controls.list).push(new FormGroup({
      fio: new FormControl(),
      birthYear: new FormControl(),
      comment: new FormControl(),
    }));
  }
}
