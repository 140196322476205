import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent, MapDialogComponent, MapDialogV2Component } from '@bg-front/core/components';
import { Coordinates } from '@bg-front/core/models/classes';
import { ISelectItem } from '@bg-front/core/models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { IFireFightingEquipmentDto } from 'smart-city-types';

import { ForecastingFiresService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'bg-forecasting-technological-fire-area',
  templateUrl: './forecasting-technological-fire-area.component.html',
  styleUrls: ['./forecasting-technological-fire-area.component.scss'],
})
export class ForecastingTechnologicalFireAreaComponent extends BaseComponent implements OnInit {
  /** Ссылка на форму */
  public form: FormGroup;

  @Input()
  public coordinates: string;

  /** Настройка компоненты "Форма пожара" */
  public fireForms: ISelectItem[] = this.settings.getDictForSelect('technologicalFireForm').map(
    (el: IScSelectItem): ISelectItem =>
      <ISelectItem>{
        text: el.text,
        value: el.id,
      },
  );

  /** Настройка компонента "Средства тушения пожара" */
  public fireFightingEquipments$: Observable<ISelectItem[]> = this.forecastingService.getFireEquipment().pipe(
    map((values: IFireFightingEquipmentDto[]) =>
      values.map(
        (el: IFireFightingEquipmentDto): ISelectItem =>
          <ISelectItem>{
            text: el.name,
            value: el.id,
          },
      ),
    ),
  );

  /** Настройка компоненты "Кол-во направлений распространения огня" */
  public directionsCounts: ISelectItem<number>[] = [
    <ISelectItem<number>>{
      text: '1',
      value: 1,
    },
    <ISelectItem<number>>{
      text: '2',
      value: 2,
    },
  ];

  /** Настройка компоненты "Способы тушения пожара прямоугольной формы" */
  public fireExtinguishingWays: ISelectItem[] = this.settings
    .getDictForSelect('rectangularFireExtinguishingMethods')
    .map(
      (el: IScSelectItem): ISelectItem =>
        <ISelectItem>{
          text: el.text,
          value: el.id,
        },
    );

  /** Круглая или Угловая */
  public isRoundedForm = false;

  /** Прямоугольная */
  public isRectangular = false;

  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly settings: Settings2Service,
    private readonly forecastingService: ForecastingFiresService,
    private readonly modalService: NzModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
    this.form.controls.technologicalFireFormId.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: string) => this.checkFireForm(value));

    this.checkFireForm(this.form.get('technologicalFireFormId')?.value);
  }

  /**
   * Обработчик клика по кнопке Уточнить адрес
   */
  public onClickSpecifyAddress() {
    const data = {
      coordinates: new Coordinates(this.form.controls.coordinates.value || this.coordinates),
      zoom: 20,
    };

    const modal = this.modalService.create({
      nzTitle: 'Укажите координату',
      nzContent: MapDialogV2Component,
      nzComponentParams: data,
      nzWidth: '600px',
    });

    let subs = modal.afterClose.pipe(untilDestroyed(this)).subscribe((params: any) => {
      if (params?.coordinates) {
        this.form.controls.coordinates.setValue(`${params.coordinates.join(', ')}`);
      }

      subs.unsubscribe();
      subs = undefined;
    });
  }

  private checkFireForm(value: string) {
    this.isRoundedForm = false;
    this.isRectangular = false;
    if (value) {
      const dict = this.settings.getDictionaryById(value);
      if (['circular', 'angular'].includes(dict.sysname)) {
        this.isRoundedForm = true;
        this.form.controls.angleValue.setValidators(Validators.required);
        this.form.controls.smallerSide.clearValidators();
        this.form.controls.windDirection.clearValidators();
        this.form.controls.directionsCount.clearValidators();
        if (dict.sysname === 'circular') {
          this.form.controls.angleValue.setValue('360');
          this.form.controls.angleValue.disable();
        } else {
          this.form.controls.angleValue.setValue(undefined);
          this.form.controls.angleValue.enable();
        }
      } else {
        this.isRectangular = true;
        this.form.controls.angleValue.clearValidators();
        this.form.controls.angleValue.updateValueAndValidity();
        this.form.controls.smallerSide.setValidators(Validators.required);
        this.form.controls.windDirection.setValidators(Validators.required);
        this.form.controls.directionsCount.setValidators(Validators.required);
      }
    }
  }
}
