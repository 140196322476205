import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { IGridTableOptions, INwForm } from '@smart-city/core/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAnyObject } from 'smart-city-types';
import { of } from 'rxjs';
import { GridTableComponent } from '@smart-city/core/common';
import { Settings2Service } from '@smart-city/core/services';

@Component({
  selector: 'bg-involved-vehicles-edit-dialog',
  templateUrl: './involved-vehicles-edit-dialog.component.html',
  styleUrls: ['./involved-vehicles-edit-dialog.component.scss']
})
export class InvolvedVehiclesEditDialogComponent implements OnInit {
  /** Ссылка на таблицу */
  @ViewChild('grid', { static: false }) private grid: GridTableComponent;

  /** Опции грида c задействованным транспортом */
  public vehiclesGridOptions: IGridTableOptions;

  constructor(
    public dialogRef: MatDialogRef<InvolvedVehiclesEditDialogComponent>,
    public readonly settings: Settings2Service,
    @Inject(MAT_DIALOG_DATA) public data: IAnyObject,
  ) { }

  ngOnInit(): void {
    /** Форма поиска */
    const vehiclesSearchForm: INwForm = {
      type: 'nw-form',
      options: {
        name: 'search',
        title: 'Поиск',
        layoutForm: {
          layout: {
            background: 'white',
            columns: [
              {
                width: '100%',
                elements: [
                  {
                    type: 'input',
                    options: {
                      name: 'search',
                      placeholder: 'Поиск',
                    },
                  },
                  {
                    type: 'button',
                    options: {
                      name: 'search',
                      title: 'Фильтровать',
                      stdBehavior: true,
                      color: 'primary',
                    },
                  },
                  {
                    type: 'button',
                    options: {
                      name: 'cancel',
                      title: 'Отмена',
                      stdBehavior: true,
                    },
                  },
                ],
              },
            ],
          },
        },
      },
    };
    const query = {
      $and: [
        { $not: { attId : null } },
        { $not: { id: { $in: this.data.involvedVehicles || [] } } },
        { typeId: { $in: this.settings.currentUser.vehicleTypeIds || [] } }
      ]
    }
    this.vehiclesGridOptions = {
      title: 'Доступные транспортные средства',
      restVersion: 'modern',
      service: { name: 'Vehicles' },
      entity: 'Dictionary',
      query: query,
      sort: {
        field: 'stateNumber',
        direction: 'desc',
      },
      controls: [
        {
          name: 'search',
          icon: 'search',
          hint: 'Фильтр',
        },
        {
          name: 'reload',
          icon: 'refresh',
          hint: 'Обновить записи',
          callback: () => of({ actionStream: 'reload' }),
        },
      ],
      fields: [
        { name: 'id', title: 'ID', disabled: true },
        { name: 'stateNumber', title: 'Государственный номер', },
        { name: 'typeId.name', title: 'Тип' },
        { name: 'brandId.name', title: 'Марка' },
        { name: 'modelId.name', title: 'Модель' },
        { name: 'organizationId.name', title: 'Организация' },
      ],
      allowSelect: false,
      allowSelectCheckbox: true,
      forms: []
    }
    // таймаут добавлен для избежания ошибки ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.vehiclesGridOptions.forms.push(vehiclesSearchForm)
    }, 0);
  }
  
  /** Закрытие формы */
  public close(): void {
    this.dialogRef.close();
  }

  /** Сохранение адреса */
  public save(): void {
    this.dialogRef.close(this.grid.getCheckedIds());
    
  }

}
