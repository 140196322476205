import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DirtyComponent } from '@ngneat/dirty-check-forms/lib/dirty-component';
import { Settings2Service } from '@smart-city/core/services';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { forkJoin, iif, Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { IEmergencyDto, ILifeCycleStepDto } from 'smart-city-types';
import { AppInjector } from '@bg-front/core/models/classes';
import { Dds01OrderEditFormComponent } from '../../../bg/modules/dds01/components/dds01-order-edit-form/dds01-order-edit-form.component';
import { IIncidentEditForm, IThermopoint } from '../../models/interfaces';
import { EmergencyService, RegimesService } from '../../services';
import { IncidentCuksEditFormComponent } from '../incident-cuks-edit-form/incident-cuks-edit-form.component';
import { IncidentDds01EditFormComponent } from '../incident-dds01-edit-form/incident-dds01-edit-form.component';
import { IncidentEditFormComponent } from '../incident-edit-form/incident-edit-form.component';
import { IncidentUlkEditFormComponent } from '../incident-ulk-edit-form/incident-ulk-edit-form.component';
import { IncidentViewFormComponent } from '../incident-view-form/incident-view-form.component';
import { OrderEditFormComponent } from '../order-edit-form/order-edit-form.component';
import { VideoIncidentEditFormComponent } from '../video-incident-edit-form/video-incident-edit-form.component';
import { BaseComponent } from '@bg-front/core/components';
import { MultiFileService } from '@bg-front/core/services';
import { UlkOrderEditFormComponent } from '../../../bg/modules/ulk/components/ulk-order-edit-form/ulk-order-edit-form.component';
import { WebcamFailureIncidentEditFormComponent } from '../webcam-failure-incident-edit-form/webcam-failure-incident-edit-form.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationsService } from '../../services';
import { IOrganizationType } from '../../models/interfaces';
import { KsionFaultEditFormComponent } from '../ksion-fault-edit-form/ksion-fault-edit-form.component';
import { ThermopointsService } from '../../services/thermopoints/thermopoints.service';

@UntilDestroy()
@Component({
  selector: 'bg-create-incident-form',
  templateUrl: './create-incident-form.component.html',
  styleUrls: ['./create-incident-form.component.scss'],
})
export class CreateIncidentFormComponent extends BaseComponent implements OnInit, DirtyComponent {
  /** Проверка изменений */
  public isDirty$: Observable<boolean> = of(false);

  /** Флаг инициализации */
  public isLoading = false;

  /** Фдаг задизейбленной формы */
  public needDisabledForm: boolean = false;

  /** Проверка наличия источника поступления информации */
  protected hasSourceType: boolean = false;

  /** Тип организации */
  public orgType: IOrganizationType;

  /** Контейнер */
  @ViewChild('editFormContainer', { read: ViewContainerRef, static: false })
  public editFormContainer: ViewContainerRef;
  public readonly emergencyService: EmergencyService;
  public readonly settings2: Settings2Service;
  public readonly componentFactoryResolver: ComponentFactoryResolver;
  public readonly router: Router;
  public readonly regimesService: RegimesService;
  public readonly multiFileService: MultiFileService;
  public readonly organizations: OrganizationsService;
  public readonly thermoPointsService: ThermopointsService;

  /** @ignore */
  constructor(public readonly route: ActivatedRoute) {
    super();
    const injector = AppInjector.getInjector();
    this.emergencyService = injector.get(EmergencyService);
    this.settings2 = injector.get(Settings2Service);
    this.componentFactoryResolver = injector.get(ComponentFactoryResolver);
    this.router = injector.get(Router);
    this.regimesService = injector.get(RegimesService);
    this.multiFileService = injector.get(MultiFileService);
    this.organizations = injector.get(OrganizationsService);
    this.thermoPointsService = injector.get(ThermopointsService);
  }

  /** @ignore */
  public ngOnInit(): void {
    this.isDirty$ = of(false);
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          this.needDisabledForm = params.callForm === 'true';

          if (params.id) {
            return this.emergencyService.getIncidentById(params.id).pipe(
              switchMap((emergency) => {
                return forkJoin([
                  this.regimesService.getRegimeById(emergency.regimeId),
                  this.multiFileService.getFilesFromSfs(emergency.documents),
                ]).pipe(
                  map((response) => {
                    const regime = response[0];
                    const documents = response[1];
                    emergency.documents = documents;

                    return { ...emergency, regime };
                  }),
                );
              }),
            );
          }
          return of(undefined);
        }),
        catchError((err: Error) => {
          return this.catchErrorFn<IEmergencyDto>(err, 'Ошибка при загрузке инцидента');
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((emergencyDto: IEmergencyDto) => {
        if (emergencyDto) {
          this.isLoading = true;
          this.hasSourceType = this.checkSourceType(emergencyDto);
          this.editFormContainer.clear();
          if (this.needDisabledForm) {
            this.createViewIncidentForm(emergencyDto);
          } else {
            this.createEditIncidentForm(emergencyDto);
          }
        } else {
          this.closeActiveForm();
        }
      });
  }

  /**
   * Закрытие формы инцидента
   */
  protected closeActiveForm() {
    this.router.navigate([{ outlets: { editForm: null, editEventForm: null } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Создаём форму редактирования инцидента
   * @param incident инцидент
   */
  protected createEditIncidentForm(incident: IEmergencyDto) {
    if (!incident.sourceType && !incident.docType) {
      this.noteService.pushError('Не удалось определить тип происшествия или поручения');
      this.closeActiveForm();
      return;
    }

    of(null)
      .pipe(
        switchMap(() =>
          iif(() => !incident.sourceType, this.organizations.getOrganizationTypeParam(incident.organization), of(null)),
        ),
        untilDestroyed(this),
      )
      .subscribe((orgType: IOrganizationType) => {
        if (orgType) {
          this.orgType = orgType;
        }
        const component: IIncidentEditForm = this.switchEmergencyComponent(incident);

        if (component) {
          component.model = incident;
          // TODO: убрать action от сюда. Определять их в BaseIncidentFormComponent в onInit
          component.actions = (incident.lifeCycleStepId as ILifeCycleStepDto).actions
            ? (incident.lifeCycleStepId as ILifeCycleStepDto).actions
            : [];

          if (incident.sourceType) {
            // const sourceName = this.settings2
            // .getDictionaryByTypeSysName('sourceTypes')
            // .find((item: IDictionaryInfo) => item.id === incident.sourceType).sysname;
            // TODO: убрать action от сюда. Определять их в BaseIncidentFormComponent в onInit
            // if (this.route.snapshot.data.dds === 'cuks' && sourceName !== 'cuks') {
            //   component.actions = [];
            //   component.disabled = true;
            // }
          }
        } else {
          this.noteService.pushError('Формы для редактирования этого типа происшествия не существует');
          this.closeActiveForm();
        }
      });
  }

  protected switchEmergencyComponent(incident) {
    if (incident.sourceType) {
      return this.getEditIncidentFormComponent(incident);
    }
    return this.getEditOrderFormComponent();
  }

  /** Определяем компонент инцидента и создаём его */
  protected getEditIncidentFormComponent(incident: IEmergencyDto) {
    const sourceTypeId = incident.sourceType;
    const docType = incident.docType;
    this.editFormContainer.clear();
    const sourceName = this.settings2.getDictionaryById(sourceTypeId).sysname;
    const docTypeName = this.settings2.getDictionaryById(docType)?.sysname || '';

    switch (sourceName) {
      case 'ksion': {
        const component =
          docTypeName === 'fault'
            ? this.editFormContainer.createComponent<KsionFaultEditFormComponent>(
                this.componentFactoryResolver.resolveComponentFactory(KsionFaultEditFormComponent),
              ).instance
            : this.editFormContainer.createComponent<IncidentEditFormComponent>(
                this.componentFactoryResolver.resolveComponentFactory(IncidentEditFormComponent),
              ).instance;

        if (component) {
          this.isDirty$ = of(false).pipe(
            switchMap((value: boolean) => {
              if (component.isDirty$) {
                return component.isDirty$;
              }
              return of(value);
            }),
          );
        }
        return component;
      }
      case 'va': {
        const component =
          docTypeName === 'fault'
            ? this.editFormContainer.createComponent<WebcamFailureIncidentEditFormComponent>(
                this.componentFactoryResolver.resolveComponentFactory(WebcamFailureIncidentEditFormComponent),
              ).instance
            : this.editFormContainer.createComponent<VideoIncidentEditFormComponent>(
                this.componentFactoryResolver.resolveComponentFactory(VideoIncidentEditFormComponent),
              ).instance;

        if (component) {
          component.urgentlyVisible = false;
          component.isVaSource = true;
          this.isDirty$ = of(false).pipe(
            switchMap((value: boolean) => {
              if (component.isDirty$) {
                return component.isDirty$;
              }
              return of(value);
            }),
          );
        }
        return component;
      }
      case 'securityForces': {
        const component = this.editFormContainer.createComponent<WebcamFailureIncidentEditFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(WebcamFailureIncidentEditFormComponent),
        ).instance;

        if (component) {
          this.isDirty$ = of(false).pipe(
            switchMap((value: boolean) => {
              if (component.isDirty$) {
                return component.isDirty$;
              }
              return of(value);
            }),
          );
        }
        return component;
      }
      case '112':
      case 'edds':
      case 'internetPortal':
      case 'call':
      case 'callEdds':
      case 'hcsMonitoring':
      case 'ecoMonitoring':
      case 'forestGuard': {
        const component = this.editFormContainer.createComponent<IncidentEditFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(IncidentEditFormComponent),
        ).instance as IncidentEditFormComponent;
        component.isPlanWorkForm = this.settings2.getDictionaryById(docType).sysname === 'plannedWork';
        if (component) {
          this.isDirty$ = of(false).pipe(
            switchMap((value: boolean) => {
              if (component.isDirty$) {
                return component.isDirty$;
              }
              return of(value);
            }),
          );
        }
        return component;
      }
      case '112Dds01':
      case 'dds01':
      case 'callDds01':
      case 'fireMonitoring': {
        const component = this.editFormContainer.createComponent<IncidentDds01EditFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(IncidentDds01EditFormComponent),
        ).instance;

        if (component) {
          this.isDirty$ = of(false).pipe(
            switchMap((value: boolean) => {
              if (component.isDirty$) {
                return component.isDirty$;
              }
              return of(value);
            }),
          );
        }
        return component;
      }
      case 'cuks': {
        const component = this.getIncidentFormComponent(docType, 'cuks');
        if (component) {
          this.isDirty$ = of(false).pipe(
            switchMap((value: boolean) => {
              if (component.isDirty$) {
                return component.isDirty$;
              }
              return of(value);
            }),
          );
        }
        return component as IIncidentEditForm;
      }
      case 'spaceMonitoring': {
        const component = this.editFormContainer.createComponent<IncidentCuksEditFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(IncidentCuksEditFormComponent),
        ).instance as IncidentCuksEditFormComponent;

        if (component) {
          this.isDirty$ = of(false).pipe(
            switchMap((value: boolean) => {
              if (component.isDirty$) {
                return component.isDirty$;
              }
              return of(value);
            }),
          );
        }
        return component as IIncidentEditForm;
      }
      case 'ulk': {
        const component = this.getIncidentFormComponent(docType, 'ulk');
        if (component) {
          this.isDirty$ = of(false).pipe(
            switchMap((value: boolean) => {
              if (component.isDirty$) {
                return component.isDirty$;
              }
              return of(value);
            }),
          );
        }
        return component as IIncidentEditForm;
      }
    }

    return undefined;
  }

  /**
   * Создаём форму просмотра инцидента
   * @param incident - объект данных по инциденту
   */
  protected createViewIncidentForm(incident: IEmergencyDto): void {
    if (!this.hasSourceType) return;

    const component: IIncidentEditForm = this.getViewIncidentFormComponent(incident);

    if (component) {
      component.model = incident;
    } else {
      this.noteService.pushError('Формы для просмотра этого типа происшествия не существует');
      this.closeActiveForm();
    }
  }

  /** Определяем компонент просмотра и создаём его */
  protected getViewIncidentFormComponent(incident: IEmergencyDto): IIncidentEditForm {
    const sourceTypeId = incident.sourceType;
    const docTypeId = incident.docType;
    this.editFormContainer.clear();
    const sourceName = this.settings2.getDictionaryById(sourceTypeId).sysname;
    const docTypeName = this.settings2.getDictionaryById(docTypeId)?.sysname || '';

    switch (sourceName) {
      case '112':
      case 'edds':
      case 'call':
      case 'callEdds':
      case 'cuks':
      case 'spaceMonitoring':
      case 'internetPortal':
      case 'ulk':
      case 'ecoMonitoring':
      case 'fireMonitoring':
      case 'forestGuard':
        return this.editFormContainer.createComponent<IncidentViewFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(IncidentViewFormComponent),
        ).instance as IIncidentEditForm;
      case 'dds01':
      case '112Dds01':
      case 'callDds01':
        return this.editFormContainer.createComponent<IncidentViewFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(IncidentViewFormComponent),
        ).instance as IIncidentEditForm;
      /*
        TODO: Если реализовывать по аналогии с остальными реестрами должна быть отдельная форма на просмотр,
            но аналитики говорят, что редактирование нужно.
            Уточнить этот момент и при необходимости реализовать форму просмотра.
            См. также todo в security-forces-workspace.component.html
        */
      case 'va':
        return this.editFormContainer.createComponent<VideoIncidentEditFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(VideoIncidentEditFormComponent),
        ).instance as IIncidentEditForm;
      case 'ksion': {
        return docTypeName === 'fault'
          ? this.editFormContainer.createComponent<KsionFaultEditFormComponent>(
              this.componentFactoryResolver.resolveComponentFactory(KsionFaultEditFormComponent),
            ).instance
          : this.editFormContainer.createComponent<IncidentEditFormComponent>(
              this.componentFactoryResolver.resolveComponentFactory(IncidentEditFormComponent),
            ).instance;
      }
    }
  }

  /** Определяем компонент поручения и создаём его
   * @Param объект инцидент.
   * @return инстанс класса
   * */

  public getEditOrderFormComponent() {
    /* TODO вкручен костыль, потому что editFormContainer.clear()
     * отрабатывает не в соответствие с ожиданиями, не очищает контейнер
     * от инстанса класса реализующий форму поручения.
     */

    const orgTypeName = this.settings2
      .getDictionaryByTypeSysName('organizationType')
      .find((type: IDictionaryInfo) => type.id === this.orgType.organizationTypeId)?.sysname;

    if (this.orgType.isUlkWork) {
      return this.editFormContainer.createComponent<UlkOrderEditFormComponent>(
        this.componentFactoryResolver.resolveComponentFactory(UlkOrderEditFormComponent),
      ).instance as IIncidentEditForm;
    }

    switch (orgTypeName) {
      case 'dds01': {
        return this.editFormContainer.createComponent<Dds01OrderEditFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(Dds01OrderEditFormComponent),
        ).instance as IIncidentEditForm;
      }
      default:
        return this.editFormContainer.createComponent<OrderEditFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(OrderEditFormComponent),
        ).instance as IIncidentEditForm;
    }
  }

  /**
   * Проверка на существования источника поступления данных по происшествию
   * @param incident - объект данных по происшествию
   * @return true если источник найден, иначе уведомит о его отсутствии и вернет false
   */
  public checkSourceType(incident: IEmergencyDto): boolean {
    if (!incident.sourceType && !incident.docType) {
      this.noteService.pushError('Не удалось определить тип происшествия или поручения');
      this.closeActiveForm();
      return false;
    }
    return true;
  }

  /** Возвращает instance компонента формы
   * @param docType - тип документа
   * @param sourceType
   * @return
   */
  private getIncidentFormComponent(
    docType: string,
    sourceType?: 'cuks' | 'ulk',
  ): IncidentCuksEditFormComponent | IncidentUlkEditFormComponent | IncidentEditFormComponent {
    const isPlanWorkForm = this.settings2.getDictionaryById(docType).sysname === 'plannedWork';
    if (isPlanWorkForm) {
      const component = this.editFormContainer.createComponent<IncidentEditFormComponent>(
        this.componentFactoryResolver.resolveComponentFactory(IncidentEditFormComponent),
      ).instance as IncidentEditFormComponent;
      component.isPlanWorkForm = isPlanWorkForm;
      return component;
    }
    let resClass = null;
    if (sourceType === 'cuks') {
      resClass = IncidentCuksEditFormComponent;
    } else if (sourceType === 'ulk') {
      resClass = IncidentUlkEditFormComponent;
    }
    return this.editFormContainer.createComponent<IncidentCuksEditFormComponent | IncidentUlkEditFormComponent>(
      this.componentFactoryResolver.resolveComponentFactory(resClass),
    ).instance;
  }
}
