import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { IInputMaskSettings } from '../../models/interfaces/input-mask-settings.interface';
import inputmask from 'inputmask';

/**
 * Директива реализует маску ввода даты и времени в инпут
 */
@Directive({
  selector: '[appInputMask]',
})
export class InputMaskDirective implements AfterViewInit {
  @Input('appInputMask') inputMask: IInputMaskSettings;

  /** Объект настроек библиотеки по умолчанию */
  private defaultSettings = {
    /** Скрывать маску при потере фокуса */
    clearMaskOnLostFocus: true,
  };
  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    Object.assign(this.defaultSettings, this.inputMask);
    new inputmask(this.inputMask.mask, { ...this.defaultSettings }).mask(this.el.nativeElement);
  }
}
