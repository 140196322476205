import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { ForecastingResultsState } from '../../../models/states/forecasting-results.state';
import { ForecastingResultsStore } from '../store/forecasting-results-store.service';

@Injectable({
  providedIn: 'root',
})
export class ForecastingResultsQuery extends QueryEntity<ForecastingResultsState> {
  constructor(store: ForecastingResultsStore) {
    super(store);
  }
}
