import { Injectable } from '@angular/core';
import { IFireMonitoringObject } from '@bg-front/core/models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RestService } from '@smart-city/core/services';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';
import { FireMonitoringObjectsStore } from '../store/fire-monitoring-objects-store.service';

/**
 * Сервис по обновлению хранилища Объектов пожарного мониторинга
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class FireMonitoringObjectsStoreService {
  /** Флаг инициализации хранилища */
  private isInit = false;

  constructor(
    private readonly rest: RestService,
    private readonly store: FireMonitoringObjectsStore,
  ) {}

  /**
   * Загружаем список Объекты пожарного мониторинга
   */
  public initStore(): Observable<boolean> {
    this.store.setLoading(true);

    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'FireMonitoringIntegration' },
        entity: {
          name: 'FireMonitoringObjects',
        },
      })
      .pipe(
        map((response: IAbstractServiceData): boolean => {
          this.store.add(response?.data?.items as IFireMonitoringObject[]);
          return true;
        }),
        catchError(() => {
          return of(false);
        }),
        finalize(() => {
          this.store.setLoading(false);
        }),
      );
  }
}
