/**
 * Результат возвращаемый диалогом подтверждения
 */
export enum ConfirmDialogResultEnum {
  /** Без реакции */
  closeWithoutReaction = 0,
  /** Отказать */
  reject = 1,
  /** Подтвердить */
  confirm = 2,
}
