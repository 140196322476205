<nz-layout class="base-dashboard">
  <nz-header>
    <nz-page-header nzTitle="Важные объекты" nzBackIcon="menu" (nzBack)="menu()">
      <nz-page-header-extra>
        <ng-container [formGroup]="headerForm">
          <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
            <input type="text" nz-input placeholder="Поиск" formControlName="search" />
          </nz-input-group>
        </ng-container>
        <ng-template #suffixIconButton>
          <button nz-button nzType="default" nzSearch nz-tooltip="Поиск" (click)="search()">
            <i nz-icon nzType="search"></i>
          </button>
        </ng-template>
        <nz-badge [nzCount]="filterCount">
          <button nz-button nzType="default" nz-tooltip="Фильтрация" (click)="openFilterDialog()">
            <i nz-icon nzType="filter"></i>
          </button>
        </nz-badge>
        <nz-badge [nzCount]="setOfCheckedId?.size">
          <button
            nz-button
            nzDanger
            nzType="default"
            nz-tooltip="Удалить"
            [disabled]="!setOfCheckedId?.size"
            (click)="showDeleteConfirm()"
          >
            <i nz-icon nzType="delete" nzTheme="outline"></i>
          </button>
        </nz-badge>
        <button nz-button (click)="export()" nz-tooltip="Экспорт">
          <i nz-icon nzType="export" nzTheme="outline"></i>
        </button>
        <button nz-button nzType="default" nz-tooltip="Обновить записи" (click)="reload()">
          <i nz-icon nzType="reload" nzTheme="outline"></i>
        </button>
        <button nz-button nzType="primary" (click)="addRecord()">Создать</button>
      </nz-page-header-extra>
    </nz-page-header>
  </nz-header>
  <nz-content>
    <nz-table
      #nzTable
      nzBordered
      nzTableLayout="fixed"
      [nzData]="data"
      [nzShowPagination]="false"
      [nzFrontPagination]="false"
      [nzLoading]="isLoading"
      [class.empty]="!data.length"
      (nzQueryParams)="reload()"
      (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
    >
      <thead>
        <tr>
          <th
            [nzChecked]="selectAll"
            [nzIndeterminate]="indeterminateSelect"
            (nzCheckedChange)="onAllChecked($event)"
            nzWidth="50px"
          ></th>
          <th [nzColumnKey]="sortableColumns.name.key" [(nzSortOrder)]="sortableColumns.name.order" [nzSortFn]="true">
            Наименование
          </th>
          <th
            [nzColumnKey]="sortableColumns.address.key"
            [(nzSortOrder)]="sortableColumns.address.order"
            [nzSortFn]="true"
          >
            Адрес
          </th>
          <th>Координаты</th>
          <th [nzColumnKey]="sortableColumns.state.key" [(nzSortOrder)]="sortableColumns.state.order" [nzSortFn]="true">
            Состояние
          </th>
          <th
            [nzColumnKey]="sortableColumns.category.key"
            [(nzSortOrder)]="sortableColumns.category.order"
            [nzSortFn]="true"
          >
            Категория
          </th>
          <th [nzColumnKey]="sortableColumns.type.key" [(nzSortOrder)]="sortableColumns.type.order" [nzSortFn]="true">
            Тип
          </th>
          <th
            [nzColumnKey]="sortableColumns.responsible.key"
            [(nzSortOrder)]="sortableColumns.responsible.order"
            [nzSortFn]="true"
          >
            Ответственный
          </th>
          <th>Телефон</th>
        </tr>
      </thead>
      <tbody class="no-cell-border">
        <tr *ngFor="let data of nzTable.data; trackBy: trackByFunc" (dblclick)="viewRecord(data.id)">
          <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
          <td [innerHTML]="data.name | highlight : highlightString"></td>
          <td [innerHTML]="data.addressFullText | highlight : highlightString"></td>
          <td [innerHTML]="data.coordinates | highlight : highlightString"></td>
          <td [innerHTML]="data.stateName | highlight : highlightString"></td>
          <td [innerHTML]="data.categoryName | highlight : highlightString"></td>
          <td [innerHTML]="data.typeName | highlight : highlightString"></td>
          <td [innerHTML]="data.responsible | highlight : highlightString"></td>
          <td [innerHTML]="data.phone | highlight : highlightString"></td>
        </tr>
      </tbody>
    </nz-table>
  </nz-content>
  <nz-footer [class.empty]="!data.length">
    <nz-pagination
      [nzPageIndex]="pageIndex"
      [nzPageSize]="pageSize"
      [nzTotal]="totalCount"
      [nzShowTotal]="rangeTemplate"
      (nzPageIndexChange)="changePage($event)"
    ></nz-pagination>
    <ng-template #rangeTemplate let-range="range" let-total> {{ range[0] }}-{{ range[1] }} из {{ total }} </ng-template>
  </nz-footer>
</nz-layout>

<nz-drawer
  #drawer
  nzWrapClassName="base-dashboard-drawer"
  [nzWidth]="'calc(100vw - var(--sidebar-width))'"
  [nzClosable]="false"
  [nzCloseOnNavigation]="false"
  [nzContent]="drawerTemplate"
>
</nz-drawer>
<ng-template #drawerTemplate>
  <router-outlet (activate)="drawer.open()" (deactivate)="onFormDeactivate($event, drawer)"></router-outlet>
</ng-template>
