<div class="main-container">
  <mat-toolbar color="primary" class="header-bar">
    <app-sc-nav-toolbar></app-sc-nav-toolbar>
  </mat-toolbar>

  <mat-sidenav-container class="page-container">
    <mat-sidenav-content>
      <div class="page-content">
        <sc-sidebar [showSystemErrors]="false"
                    style="z-index: 1000"
                    [soundNotificationOptions]="soundNotificationOptions"
                    [showChart]="false">
        </sc-sidebar>
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <ng-template #notification let-source="data">
    <a class="underline"  href="javascript:void(0)" (click)="showEventInNewTab(source.targetUrl, source.eventNumber)">
      {{ source.eventNumber }}
    </a>
    {{ source.date | date: 'dd.MM.YYYY HH:mm:ss'}}
    <br>
    {{ source.pushNotificationText }}
  </ng-template>

  <ng-template #notificationImage>
    <img [src]="notificationFilePath"/>
  </ng-template>
</div>
