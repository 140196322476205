<!-- Блок для отображения видимых тегов.
 Отображается поверх основного блока nz-tree-select
 Все классы взяты с nz-tree-select для имитации его вида и функционала -->
<div
  #hover
  class="ant-select ant-select-multiple ant-select-allow-clear ant-select-hover"
  [class.ant-select-focused]="opened"
  [class.ant-select-disabled]="disabled"
  [class.view-mode]="viewMode"
  (click)="!opened && !viewMode && treeSelect.openDropdown()"
>
  <div class="ant-select-selector">
    <!-- Список всех видимых тегов -->
    <div
      *ngFor="let tag of viewTags"
      class="ant-select-selection-item"
      (click)="$event.stopPropagation()"
    >
      <div class="ant-select-selection-item-content">{{ tag.label }}</div>
      <!-- Иконка удаления тега из списка -->
      <span *ngIf="!viewMode" class="ant-select-selection-item-remove" (click)="removeItem($event, tag.value)">
        <i nz-icon nzType="close"></i>
      </span>
    </div>
    <!-- Дополнительный тег, позволяющий отображать/скрывать все отображаемые теги -->
    <div *ngIf="hidden" class="ant-select-selection-item added-item" (click)="toggleFullMode($event)">
      <div class="ant-select-selection-item-content">{{ fullMode ? 'Скрыть...' : 'Ещё ' + hidden + ' типов...' }}</div>
    </div>
    <!-- Элемент с инпутом для фиксации высоты поля. Без него при отсутствии тегов поле будет узкой полоской -->
    <div class="ant-select-selection-search">
      <input class="ant-select-selection-search-input" />
    </div>
    <!-- Иконка очистки поля, появляется при наведении мышкой -->
    <nz-select-clear *ngIf="tags.length && !disabled && !viewMode" (click)="clearValue($event)"></nz-select-clear>
  </div>
</div>

<!-- Блок выбранных тегов для расчёта их размеров
 Дублирует блок #hover, но содержит все выбранные теги, а не только видимые -->
<div class="ant-select ant-select-multiple ant-select-duplicate" [class.view-mode]="viewMode">
  <div #duplicate class="ant-select-selector">
    <!-- Список всех выбранных тегов -->
    <div *ngFor="let tag of tags" class="ant-select-selection-item">
      <div class="ant-select-selection-item-content">{{ tag.label }}</div>
      <!-- Иконка удаления входит в расчётную ширину тега, поэтому тоже нуждается в отображении -->
      <span *ngIf="!viewMode" class="ant-select-selection-item-remove">
        <i nz-icon nzType="close"></i>
      </span>
    </div>
    <!-- Дополнительный тег управления отображением тегов
     Задано фиксированное количество скрытых тегов, так как с динамическим количеством сложнее высчитывать ширину
     При количестве скрытых блоков более 1000, может неверно рассчитывать ширину этого тега -->
    <div class="ant-select-selection-item">
      <div class="ant-select-selection-item-content">Ещё 999 типов...</div>
    </div>
  </div>
</div>

<!-- Блок с основным функционалом.
 Находится под визуальным блоком, подстраиваясь под его высоту, чтобы dropdown раскрывался правильно -->
<nz-tree-select
  #treeSelect
  nzCheckable
  class="ant-select-main"
  [style.height]="height + 'px'"
  [(ngModel)]="value"
  [nzNodes]="nodes"
  [nzMultiple]="true"
  [nzDropdownStyle]="{ 'max-height': '400px' }"
  [nzDisabled]="disabled"
  (nzOpenChange)="changeOpen($event)"
  (ngModelChange)="onChangeModel($event)">
</nz-tree-select>