import { AfterViewInit, Component, ComponentFactoryResolver, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAnyObject } from 'smart-city-types';
import { LoosesService } from '../../services';
import { catchError, takeUntil } from 'rxjs/operators';
import { IStepDialogDataOptions } from '../../models/interfaces';
import { RescuedListComponent } from '../looses/rescued-list/rescued-list.component';
import { BaseComponent } from '@bg-front/core/components';
import { LoosesListComponent } from '../looses/looses-list/looses-list.component';
import { EvacuatedListComponent } from '../looses/evacuated-list/evacuated-list.component';
import { HospitalizedListComponent } from '../looses/hospitalized-list/hospitalized-list.component';
import { DeathListComponent } from '../looses/death-list/death-list.component';
import { MissingListComponent } from '../looses/missing-list/missing-list.component';

@Component({
  selector: 'looses-dialog',
  templateUrl: './looses-dialog.component.html',
  styleUrls: ['./looses-dialog.component.scss'],
})
export class LoosesDialogComponent extends BaseComponent implements AfterViewInit {
  /** Контейнер */
  @ViewChild('container', { read: ViewContainerRef, static: false }) public container: ViewContainerRef;

  /** Компонент текущего списка */
  private listComponent: LoosesListComponent;

  /** Заголовок */
  public header: string = '';

  /** @ignore */
  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly looserService: LoosesService,
    public readonly dialogRef: MatDialogRef<IStepDialogDataOptions>,
    // todo типизация
    @Inject(MAT_DIALOG_DATA) public data: IAnyObject,
  ) {
    super();
  }

  /** @ignore */
  public ngAfterViewInit() {
    this.looserService.getLooses(this.data.emergencyId, this.data.type).pipe(
      takeUntil(this.ngUnsubscribe),
      catchError((err: Error) =>
        // todo типизация
        this.catchErrorFn<IAnyObject[]>(err, 'Ошибка при получении данных о потерях'),
      ),
    ).subscribe((res) => {
      switch (this.data.type) {
        case 'rescued':
          this.header = 'Список спасенных';
          this.listComponent = this.container.createComponent<RescuedListComponent>(
            this.componentFactoryResolver.resolveComponentFactory(RescuedListComponent),
          ).instance;
          break;
        case 'evacuated':
          this.header = 'Список эвакуированных';
          this.listComponent = this.container.createComponent<EvacuatedListComponent>(
            this.componentFactoryResolver.resolveComponentFactory(EvacuatedListComponent),
          ).instance;
          break;
        case 'hospitalized':
          this.header = 'Список госпитализированных';
          this.listComponent = this.container.createComponent<HospitalizedListComponent>(
            this.componentFactoryResolver.resolveComponentFactory(HospitalizedListComponent),
          ).instance;
          break;
        case 'death':
          this.header = 'Список погибших';
          this.listComponent = this.container.createComponent<DeathListComponent>(
            this.componentFactoryResolver.resolveComponentFactory(DeathListComponent),
          ).instance;
          break;
        case 'missing':
          this.header = 'Список пропавших без вести';
          this.listComponent = this.container.createComponent<MissingListComponent>(
            this.componentFactoryResolver.resolveComponentFactory(MissingListComponent),
          ).instance;
          break;
      }
      this.listComponent.listData = res;
    });
  }

  /**
   * Закрытие
   */
  public cancel() {
    // TODO: вопрос при закрытии о несохраненных изменениях
    this.dialogRef.close();
  }

  /**
   * todo
   */
  public save() {
    this.looserService.saveLooses(this.data.emergencyId, this.data.type, this.listComponent.form.value.list).pipe(
      takeUntil(this.ngUnsubscribe),
      catchError((err: Error) =>
        // todo типизация
        this.catchErrorFn<IAnyObject[]>(err, 'Ошибка при сохранении информации о потерях'),
      ),
    ).subscribe(() => this.dialogRef.close());
  }
}
