<div class="container">
  <nw-header [options]="headerOptions" (clickButton)="delete()"></nw-header>
  <form [formGroup]="form">
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select formControlName="orgTypeParam" [options]="orgTypeParamOptions"></sc-select>
        </div>
      </div>
    </div>
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <sc-select formControlName="organization" [options]="organizationOptions"></sc-select>
        </div>
      </div>
    </div>
  </form>
</div>
