<nz-drawer
  [nzVisible]="openDrawer"
  [nzWidth]="364"
  [nzOffsetX]="offsetX"
  [nzClosable]="false"
  [nzMask]="false"
  [nzPlacement]="'left'"
  [nzBodyStyle]="{ padding: 0 }"
>
  <ng-container *nzDrawerContent>
    <nw-header [options]="headerOptions" (clickButton)="clickHeaderButton($event)"></nw-header>
    <div class="emergency-object-container">
      <div class="bg-row">
        <div class="bg-col">
          <div class="emergency-object-main-info-container">
            <div class="bg-row">
              <div class="bg-col-min">
                <div class="icon">
                  <span class="sc-icon-flag"></span>
                </div>
              </div>
              <div class="bg-col">
                <div [attr.tooltip]="organization.name" [attr.flow]="'down'">
                  <div class="emergency-object__name two-line-ellipsis-overflow">
                    {{ organization.name }}
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div class="bg-row">
              <div class="bg-col">
                <div
                  class="show-on-map"
                  (click)="showOnMap($event)"
                  [attr.tooltip]="organizationCoverage?.creationMethod ? '' : 'Зона действия организации не задана'"
                >
                  <mat-icon
                    _ngcontent-lgs-c418=""
                    role="img" matsuffix=""
                    class="mat-icon
                  notranslate
                  material-icons
                  mat-icon-no-color
                  ng-tns-c150-49"
                    aria-hidden="true"
                    data-mat-icon-type="font">
                    location_on
                  </mat-icon>
                  Зона действия организации
                </div>
              </div>
            </div>
            <div class="bg-row">
              <div class="bg-col">
                <div [attr.tooltip]="organization.fullname" [attr.flow]="'down'">
                  <div class="emergency-object__name two-line-ellipsis-overflow">
                    <label>Полное наименование</label>
                    {{ organization.fullname }}
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div class="bg-row">
              <div class="bg-col">
                <div class="emergency-object-info">
                  <label>Адрес:</label>
                  <p class="emergency-object-info__link" (click)="selectCoordinates()">
                    {{ organization?.address.fullText }}
                  </p>
                </div>
              </div>
            </div>
            <hr/>
            <div class="bg-row" >
              <div class="bg-col">
                <div class="emergency-object-info">
                  <label>Контактный телефон:</label>
                  <div
                    *ngIf="organization?.phone1"
                    class="emergency-object-info__link"
                    (click)="onClickPhone(organization?.phone1)"
                  >{{ formatPhoneFn(organization?.phone1) }}
                  </div>
                  <div
                    class="emergency-object-info__link"
                    *ngIf="organization?.phone2"
                    (click)="onClickPhone(organization?.phone2)"
                  >{{ formatPhoneFn(organization.phone2) }}
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div class="bg-row" >
              <div class="bg-col">
                <div [attr.tooltip]="forceResource?.forcesAndResources" [attr.flow]="'down'">
                  <div class="emergency-object-info">
                    <label>Силы и средства:</label>
                    <div class="emergency-object-info three-lines-block" *ngIf="forceResource?.forcesAndResources">
                      {{ forceResource?.forcesAndResources }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div class="bg-row" >
              <div class="bg-col">
                <div class="emergency-object-info">
                  <label>На дату:</label>
                  <p class="emergency-object-info" *ngIf="forceResource?.onDate">
                    {{ forceResource?.onDate }}
                  </p>
                </div>
              </div>
            </div>
            <hr/>
            <div class="bg-row">
              <div class="bg-col">
                <div class="emergency-object-info">
                  <label>Координаты:</label>
                  <p class="emergency-object-info__link" (click)="selectCoordinates()">
                    {{ organization.coordinates }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</nz-drawer>
