<div class="panel-wrapper" *ngIf="model">
  <div class="bg-row">
    <!--<div class="bg-col-8 pr-none">-->
    <div class="bg-col">
      <nw-header [options]="headerActionsOptions" (clickButton)="onClickActionsButton()"></nw-header>
      <form [formGroup]="form">
        <div class="content">
          <mat-accordion>
            <mat-expansion-panel [expanded]="'true'">
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title>
                  <div class="header-container">
                    <div>Описание</div>
                    <div class="header-container__divider">
                      <hr />
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="bg-row">
                <div class="bg-col">
                  <div class="bg-form-group">
                    <sc-input [options]="nameOptions" formControlName="name"></sc-input>
                  </div>
                </div>
                <div class="bg-col">
                  <div class="bg-form-group">
                    <sc-input [options]="idOptions" formControlName="id"></sc-input>
                  </div>
                </div>
              </div>
              <div class="bg-row">
                <div class="bg-col">
                  <div class="bg-form-group">
                    <sc-input [options]="typeOptions" formControlName="type"></sc-input>
                  </div>
                </div>
                <div class="bg-col">
                  <div class="bg-form-group">
                    <sc-input [options]="serialOptions" formControlName="serial"></sc-input>
                  </div>
                </div>
              </div>
              <div class="bg-row">
                <div class="bg-col">
                  <div class="bg-form-group">
                    <sc-input [options]="dtRegOptions" formControlName="dtReg"></sc-input>
                  </div>
                </div>
                <div class="bg-col">
                  <div class="bg-form-group">
                    <sc-input [options]="lastUpdateOptions" formControlName="lastUpdate"></sc-input>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <ng-container formArrayName="nodes">
            <div *ngFor="let nodeFormGroup of nodesFormGroups; index as i">
              <ng-container [formGroup]="nodeFormGroup">
                <mat-accordion>
                  <mat-expansion-panel [expanded]="'false'">
                    <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                      <mat-panel-title>
                        <div class="header-container">
                          <div>{{nodes[i].name}}</div>
                          <div class="header-container__divider">
                            <hr />
                          </div>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="bg-row">
                      <div class="bg-col">
                        <div class="bg-form-group">
                          <sc-input [options]="nodeNameOptions" formControlName="name"></sc-input>
                        </div>
                      </div>
                      <div class="bg-col">
                        <div class="bg-form-group">
                          <sc-input [options]="nodeIdOptions" formControlName="id"></sc-input>
                        </div>
                      </div>
                    </div>
                    <div class="bg-row d-flex-vertical-center">
                      <div class="bg-col">
                        <div class="bg-form-group">
                          <sc-input [options]="nodeResourceTypeOptions" formControlName="resourceType"></sc-input>
                        </div>
                      </div>
                      <div class="bg-col">
                        <div class="bg-form-group">
                          <sc-checkbox [options]="activeOptions" formControlName="active"></sc-checkbox>
                        </div>
                      </div>
                    </div>
                    <!--<div class="grid-container">-->
                    <!--  <grid-table [grid]="gridOptions"></grid-table>-->
                    <!--</div>-->
                  </mat-expansion-panel>
                </mat-accordion>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
    <!--<div class="bg-col pl-none" style="background-color: white">-->
    <!--<nw-header [options]="parameterHeaderOptions"></nw-header>-->
    <!--  {{currentParameter?.descr}}-->
    <!--</div>-->
  </div>
</div>
