import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmergencyReportsDialogResultEnum } from '../../models/enums';

/**
 * Копмпонента реализует диалоговое окно с выбором формирования отчета
 */
@Component({
  selector: 'bg-emergency-reports-dialog',
  templateUrl: './emergency-reports-dialog.component.html',
  styleUrls: ['./emergency-reports-dialog.component.scss'],
})
export class EmergencyReportsDialogComponent {

  public displayedColumns: string[] = ['name', 'actions'];
  public reports = [];

  @Output()
  public downloadEvent = new EventEmitter<[EmergencyReportsDialogResultEnum, any]>(true);

  constructor(
    public dialogRef: MatDialogRef<EmergencyReportsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    switch (this.data.vaTypeSysname) {
      case '1':
        this.reports.push({
          name: 'Отчет по форме инцидента',
          actions: 'loudSound',
        });
        break;
      case '2':
        this.reports.push({
          name: 'Отчет по форме инцидента',
          actions: 'thing',
        });
        break;
      case '4':
        this.reports.push({
          name: 'Отчет по форме инцидента',
          actions: 'crowds',
        });
        break;
      case '8':
        this.reports.push({
          name: 'Отчет по форме инцидента',
          actions: 'sabotage',
        });
        break;
      case '16':
        this.reports.push({
          name: 'Отчет по форме инцидента',
          actions: 'vehicleSearch',
        });
        break;
      case '32':
        this.reports.push({
          name: 'Отчет по форме инцидента',
          actions: 'duplicateGRZ',
        });
        break;
      case '128':
        this.reports.push({
          name: 'Отчет по форме инцидента',
          actions: 'objRestrictedArea',
        });
    }
  }

  public download(report: string) {
    this.downloadEvent.emit([EmergencyReportsDialogResultEnum[report as string], report]);
  }

  /** Закрыть */
  public close() {
    this.dialogRef.close(EmergencyReportsDialogResultEnum.close);
  }
}
