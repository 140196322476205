import { DONE_ICON, FAILURE_ICON, IN_PROGRESS_ICON, CONSIDERATION_ICON } from "@bg-front/core/models/constants";
export const responsePlanIcons = new Map([
  // Галочка
  ['done', DONE_ICON],
  // Пустой круг
  ['inProgress', IN_PROGRESS_ICON],
  // Крестик
  ['fail', FAILURE_ICON],
  // Часы
  ['message', CONSIDERATION_ICON],
  // Часы
  ['consideration', CONSIDERATION_ICON],
]);
