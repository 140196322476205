import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { ICustomSelectItem, ISelectItem } from '@bg-front/core/models/interfaces';
import { WeatherDataService } from '@bg-front/weather-data/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IScCheckboxOptions } from '@smart-city/core/common';
import { Settings2Service } from '@smart-city/core/services';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { noop, Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { StoredChemicalsService } from '../../../stored-chemicals/services';

import { ForecastingBlowoutService } from '../../services';
import { BaseForecastingBlowoutHazardousChemicalsComponent } from '../base-forecasting-blowout-hazardous-chemicals/base-forecasting-blowout-hazardous-chemicals.component';

@UntilDestroy()
@Component({
  selector: 'bg-accident-on-chemical-object-task',
  templateUrl: './accident-on-chemical-object-task.component.html',
  styleUrls: ['./accident-on-chemical-object-task.component.scss'],
})
export class AccidentOnChemicalObjectTaskComponent
  extends BaseForecastingBlowoutHazardousChemicalsComponent
  implements OnInit
{
  /** Здание */
  @Input() significantObjectId: string;

  public aggregationStateText = '';
  private chemicalTypes: ICustomSelectItem[];
  public chemicalTypes$: Observable<ICustomSelectItem[]> = this.forecastingService
    .getChemicalTypesForSelect()
    .pipe(tap((elements: ICustomSelectItem[]) => (this.chemicalTypes = elements)));

  /** Настройка компоненты Общий запас АХОВ на объекте */
  public totalChemicalAmountOptions: IScCheckboxOptions = {
    name: 'Общий запас АХОВ на объекте',
  };
  /** Настройка компоненты Характер разлива*/
  public dischargeTypes: ISelectItem<string>[] = this.getDictionaryForSelect('emergencyChemicalSubstances');

  /** Режим просмотра */
  public override isShow: boolean = false;

  /**
   * @ignore
   */
  constructor(
    private readonly storedChemicalsService: StoredChemicalsService,
    private readonly controlContainer: ControlContainer,
    private readonly forecastingService: ForecastingBlowoutService,
    notificationService: NzNotificationService,
    settings: Settings2Service,
    weatherService: WeatherDataService,
  ) {
    super(settings, weatherService, notificationService);
  }

  /** @ignore */
  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;

    this.isShow = this.form.disabled;

    if (this.form.controls.chemicalTypeId.value) {
      this.storedChemicalsService
        .getAggregationState(this.form.controls.chemicalTypeId.value)
        .pipe(untilDestroyed(this))
        .subscribe((state: string) => {
          this.aggregationStateText = state;
        });
    }

    if (this.isShow) {
      return;
    }

    this.getStoredChemicals();

    this.defineDischargeAreaActivity(this.form.controls.dischargeTypeId.value);

    /** Подписка на изменение Важного объекта */
    (this.form.parent as FormGroup).controls.objectId.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        // не меняем значение при инициализации формы
        if (this.form.controls.chemicalTypeId.touched) {
          this.form.controls.chemicalTypeId.setValue(null);
          this.resetChemicalControls();
        }
        if (value) {
          this.getStoredChemicals();
          this.significantObjectId = value;
        }
      });

    /** Подписка на изменение типа АХОВ для актуализации агрегатного состояния */
    this.form.controls.chemicalTypeId.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value: string) => {
        // не меняем значение при инициализации формы
        if (this.form.controls.chemicalTypeId.touched) {
          this.resetChemicalControls();
        }

        this.checkTotalChemicalAmountState();

        if (!value) {
          this.form.controls.aggregationStateId.setValue(undefined);
          this.aggregationStateText = undefined;
          this.form.controls.chemicalAmount.disable();
          this.form.controls.chemicalAmount.setValue(null);
        } else {
          this.form.controls.chemicalAmount.enabled ? noop() : this.form.controls.chemicalAmount.enable();
          const state = (this.aggregationStateText = this.chemicalTypes.find(
            (el: ICustomSelectItem) => el.value === value,
          )?.aggregationState);
          this.aggregationStateText = state?.name;
          this.form.controls.aggregationStateId.setValue(state?.id);
        }
      });

    /** Подписка на изменение контроля общий запас АХОВ */
    this.form.controls.totalChemicalAmount.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: boolean) => {
        if (value) {
          const chemicalTypeId = this.form.controls.chemicalTypeId.value;
          if (chemicalTypeId) {
            const chemicalAmount = this.chemicalTypes.find(
              (el: ICustomSelectItem) => el.value === chemicalTypeId,
            )?.chemicalAmount;
            this.form.get('chemicalAmount')?.setValue(chemicalAmount);
            this.form.get('chemicalAmount')?.disable();
          }
        } else {
          this.form.get('chemicalAmount')?.enable();
        }
      });

    this.form.controls.dischargeTypeId.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      this.defineDischargeAreaActivity(value);
    });
  }

  /**
   * Определение активности контрола 'Площадь разлива'
   * @param value - значение контрода 'Характер разлива'
   * @return
   */
  private defineDischargeAreaActivity(value: string): void {
    const sysName = value ? this.settings.getDictionaryById(value)?.sysname : value;
    sysName === 'pallet' ? this.form.controls.dischargeArea.enable() : this.form.controls.dischargeArea.disable();
  }

  /** Проверка агрегатного состояния АХОВ */
  public isLiquid(): boolean {
    return this.aggregationStateText === 'Жидкость';
  }

  /** Очистить контролы для химического объекта */
  private resetChemicalControls() {
    this.form.controls.chemicalAmount.reset();
    this.form.controls.totalChemicalAmount.setValue(false);
    this.form.controls.dischargeTypeId.reset();
    this.form.controls.dischargeArea.reset();
  }

  /** Проверяем статус контрола */
  private checkTotalChemicalAmountState() {
    if (!!this.significantObjectId && !!this.form.controls.chemicalTypeId.value) {
      this.form.controls.totalChemicalAmount.enable();
    } else {
      this.form.controls.totalChemicalAmount.setValue(false);
      this.form.controls.totalChemicalAmount.disable();
    }
  }

  /** Трансформируем данные */
  private getStoredChemicals() {
    const val = (this.form.parent as FormGroup).controls.objectId.value;
    if (val) {
      this.chemicalTypes$ = this.forecastingService
        .getStoredChemicalsForSignificantObject(val)
        .pipe(tap((elements: ICustomSelectItem[]) => (this.chemicalTypes = elements)));
    } else {
      this.chemicalTypes$ = this.forecastingService
        .getChemicalTypesForSelect()
        .pipe(tap((elements: ICustomSelectItem[]) => (this.chemicalTypes = elements)));
    }
  }
}
