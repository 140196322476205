import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { Settings2Service } from '@smart-city/core/services';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { ISignificantObjectsFilter } from '../../models/interfaces';
import { SignificantObjectsService } from '../../services';
import { ISignificantObjectCategory } from '@bg-front/core/models/interfaces';

@Component({
  selector: 'bg-significant-objects-filter',
  templateUrl: './significant-objects-filter.component.html',
  styleUrls: ['./significant-objects-filter.component.scss']
})
export class SignificantObjectsFilterComponent implements OnInit {
  @Input()
  public filter: ISignificantObjectsFilter;

  /** Форма с полями для фильтрации */
  public searchForm: FormGroup;

  /** Список МО для селекта */
  public moList$: Observable<{ id: string; name: string }[]> = this.service.getMoForSelect()

  /** Список категорий для селекта */
  public categoryList$: Observable<ISignificantObjectCategory[]> = this.service.getCategoriesForSelect()

  /** Список статусов для селекта */
  public stateList: IScSelectItem[] = this.settings.getDictForSelect('significantObjectStatus');

  /** Список типов для селекта */
  public typeList: IScSelectItem[] = this.settings.getDictForSelect('significantObjectTypes');


  constructor(
    private readonly modalRef: NzModalRef,
    private readonly settings: Settings2Service,
    private readonly service: SignificantObjectsService,
  ) {
    this.searchForm = new FormGroup({
      state: new FormControl(),
      category: new FormControl(),
      type: new FormControl(),
      mo: new FormControl(),
    });
  }

  public ngOnInit(): void {
    this.searchForm.patchValue(this.filter);
  }

  /** Применение фильтра */
  public search(): void {
    this.modalRef.close(this.searchForm.value);
  }

  /** Закрытие окна */
  public close(): void {
    this.modalRef.close(undefined);
  }

  /** Очистка фильтра */
  public clear(): void {
    this.searchForm.patchValue({
      state: undefined,
      category: undefined,
      type: undefined,
      mo: undefined,
    });
  }
}
