<div class="forecasting-results-container">
  <nw-header [options]="headerOptions" *ngIf="!isShow" (clickButton)="clickHeaderButton($event)"></nw-header>
  <div class="forecasting-result-container">
    <ng-container [ngSwitch]="countTaskSysName">
      <!--  АХОВ-->
      <bg-accident-on-chemical-object-task-result
        [isShow]="isShow"
        [downloadReport]="downloadReport$"
        (hasReport)="onHasReportEvent($event)"
        (isDownloadReport)="onDownloadReportEvent($event)"
        *ngSwitchCase="'accidentChemicalObject'"
        [forecastingResults]="$any(forecastingResults)"
      >
      </bg-accident-on-chemical-object-task-result>
      <bg-destroy-chemical-object-task-result
        [isShow]="isShow"
        [downloadReport]="downloadReport$"
        (hasReport)="onHasReportEvent($event)"
        (isDownloadReport)="onDownloadReportEvent($event)"
        *ngSwitchCase="'destroyChemicalObject'"
        [forecastingResults]="$any(forecastingResults)"
      >
      </bg-destroy-chemical-object-task-result>
      <bg-time-approach-cloud-to-point-task-result
        [isShow]="isShow"
        [downloadReport]="downloadReport$"
        (hasReport)="onHasReportEvent($event)"
        *ngSwitchCase="'timeApproachCloudToPoint'"
        [forecastingResults]="$any(forecastingResults)"
      >
      </bg-time-approach-cloud-to-point-task-result>
      <!--  Радиация-->
      <bg-nuclear-pollution-task-result
        [downloadReport]="downloadReport$"
        (hasReport)="onHasReportEvent($event)"
        (isDownloadReport)="onDownloadReportEvent($event)"
        *ngSwitchCase="'nuclearPollution'"
        [forecastingResults]="forecastingResults"
      >
      </bg-nuclear-pollution-task-result>
      <bg-time-allowed-to-overcome-cloud-trail-task-result
        [forecastingResults]="forecastingResults"
        [downloadReport]="downloadReport$"
        (hasReport)="onHasReportEvent($event)"
        *ngSwitchCase="'timeAllowedToOvercomeCloudTrail'"
      >
      </bg-time-allowed-to-overcome-cloud-trail-task-result>
      <bg-thyroid-irradiation-task-result
        [downloadReport]="downloadReport$"
        (hasReport)="onHasReportEvent($event)"
        (isDownloadReport)="onDownloadReportEvent($event)"
        [forecastingResults]="forecastingResults"
        *ngSwitchCase="'thyroidIrradiation'"
      >
      </bg-thyroid-irradiation-task-result>
      <bg-radioactive-cloud-time-task-result
        [downloadReport]="downloadReport$"
        (hasReport)="onHasReportEvent($event)"
        [forecastingResults]="forecastingResults"
        *ngSwitchCase="'radioactiveCloudTime'"
      >
      </bg-radioactive-cloud-time-task-result>
      <bg-population-irradiation-point-task-result
        [downloadReport]="downloadReport$"
        (hasReport)="onHasReportEvent($event)"
        [forecastingResults]="$any(forecastingResults)"
        *ngSwitchCase="'populationIrradiationPoint'"
      >
      </bg-population-irradiation-point-task-result>
      <bg-nuclear-pollution-point-task-result
        [downloadReport]="downloadReport$"
        (hasReport)="onHasReportEvent($event)"
        [forecastingResults]="forecastingResults"
        *ngSwitchCase="'nuclearPollutionPoint'"
      >
      </bg-nuclear-pollution-point-task-result>
      <bg-exposure-population-overcoming-cloud-trail-task-result
        [downloadReport]="downloadReport$"
        (hasReport)="onHasReportEvent($event)"
        [forecastingResults]="forecastingResults"
        *ngSwitchCase="'exposurePopulationOvercomingCloudTrail'"
      >
      </bg-exposure-population-overcoming-cloud-trail-task-result>

      <!-- Лесные пожары -->
      <bg-forecasting-consequences-of-forest-fires-result
        [isShow]="isShow"
        [downloadReport]="downloadReport$"
        (hasReport)="onHasReportEvent($event)"
        (isDownloadReport)="onDownloadReportEvent($event)"
        [forecastingResults]="$any(forecastingResults)"
        *ngSwitchCase="'forestFireConsequenceRating'"
      ></bg-forecasting-consequences-of-forest-fires-result>
      <!-- Техногенные пожары -->
      <bg-forecasting-distance-traveled-by-fire-front-result
        [isShow]="isShow"
        [downloadReport]="downloadReport$"
        (hasReport)="onHasReportEvent($event)"
        [forecastingResults]="$any(forecastingResults)"
        *ngSwitchCase="'fireCoveredDistance'"
      >
      </bg-forecasting-distance-traveled-by-fire-front-result>
      <bg-forecasting-technological-fire-areas-task-result
        [isShow]="isShow"
        [downloadReport]="downloadReport$"
        (hasReport)="onHasReportEvent($event)"
        (isDownloadReport)="onDownloadReportEvent($event)"
        [forecastingResults]="$any(forecastingResults)"
        *ngSwitchCase="'technologicalFireArea'"
      >
      </bg-forecasting-technological-fire-areas-task-result>
      <bg-forecasting-extinguishing-agent-consumption-task-result
        [isShow]="isShow"
        [downloadReport]="downloadReport$"
        (hasReport)="onHasReportEvent($event)"
        [forecastingResults]="$any(forecastingResults)"
        *ngSwitchCase="'extinguishingAgentConsumption'"
      >
      </bg-forecasting-extinguishing-agent-consumption-task-result>
    </ng-container>
  </div>
  <ng-container *ngIf="!isShow">
    <hr />
    <div class="bg-row">
      <div class="bg-col-6" *ngIf="hasReport">
        <div class="download-button-container">
          <button nz-button nzType="primary" class="download-button" (click)="report()" [disabled]="isDownload">
            Скачать отчёт
          </button>
        </div>
      </div>
      <div class="bg-col-6" *ngIf="canSaveResult">
        <div class="download-button-container">
          <button nz-button nzType="primary" class="download-button" (click)="saveResult()">Сохранить расчёт</button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
