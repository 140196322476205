import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { retryWithDelay } from '@bg-front/core/models/helpers';
import { IBaseDictionaryData } from '@smart-city/core/interfaces';
import { NotificationService, RestService, Settings2Service } from '@smart-city/core/services';
import { ScConsole } from '@smart-city/core/utils';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { of, Subject, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IAbstractServiceData } from 'smart-city-types';

import { IVideoDeviceBg } from '../../app-common/models/interfaces';
import { IServerData } from '@bg-front/core/models/interfaces';
import { IHlsStroke, IHlsStrokeStyle } from '../interfaces';
import { HlsPlayerService } from '../services';
import Hls, { ErrorData } from 'hls.js';

dayjs.extend(utc);
/** Компонент "Видео плеера" */
/** @description
 * Плеер имеет обязательные параметры для работы в разных режимах.
 * @param sourceType - установка типа воспроизведения, может принимать следующие значения:
 *
 *   life - rtsp потоковое видео (требует подключения HLS);
 *   Сервис для получения ссылки:
 *   В случае если поток rtsp будет получен из Macroscop
 *   this.rest.serviceRequest({
 *      "service": { "name": "Transcoding" },
 *      "action": "createMacroscopRtspToHls",
 *      "data": { "deviceId": "bad62183-582b-317f-f79e-ada5172a331c" }
 *   })).subscribe((req: any) => {
 *    const source: string = `/${req.data.path}/${req.data.indexFile}`;
 *   });
 *   @example Пример подключения плеера для воспроизведения потоковое rtsp видео из Macroscop:
 *   <bg-hls-player [sourceType]="'life'" [cameraId]="'d82cc978-76ed-4a3e-ae61-fcaa868c723e'"></bg-hls-player>
 *   В случае если поток rtsp будет получен непосредственно с устройства (передан параметр url)
 *   this.rest.serviceRequest({
 *      "service": { "name": "Transcoding" },
 *      "action": "createRtspToHls",
 *      "data": {
          url: "rtsp://video.test.ru/85209bd90e?token=breBo6oJxeebkxmnVMSBWb9Tu",
          uuid: bad62183-582b-317f-f79e-ada5172a331c,
        },
 *   })).subscribe((req: any) => {
 *    const source: string = `/${req.data.path}/${req.data.indexFile}`;
 *   });
 *   @example Пример подключения плеера для воспроизведения потоковое rtsp видео устройства:
 *   <bg-hls-player
 *      [sourceType]="'life'"
 *      [cameraId]="'d82cc978-76ed-4a3e-ae61-fcaa868c723e'"
 *      [url]="'rtsp://video.test.ru/85209bd90e?token=breBo6oJxeebkxmnVMSBWb9Tu'"
 *   ></bg-hls-player>
 *
 *   archive - воспроизведение видео за период(нативный video элемент);
 *   Сервис для получения ссылки:
 *   this.rest.serviceRequest({
 *      "service": { "name": "VideoExport" },
 *      "action": "generateMacroscopExportArchiveURL",
 *      "data": {
 *         "deviceId": "bad62183-582b-317f-f79e-ada5172a331c",
 *         "fromtime": "15.06.2020+12:00:00",
 *         "totime": "15.06.2020+12:01:00"
 *      }
 *   })).subscribe((req: any) => {
 *    const source: string = `/${req.data.path}/${req.data.indexFile}`;
 *   });
 *   @example Пример подключения плеера для воспроизведения видео за период:
 *   <bg-hls-player
 *     [sourceType]="'archive'"
 *     [cameraId]="request.cameraId"
 *     [period]="{ fromtime: request.from, totime: request.to }"
 *     [frame]="frame"
 *   ></bg-hls-player>
 *
 *   sfs - воспроизведение видео файла из СФС (нативный video элемент).
 *   Не требует сервиса для получения ссылки. Ожидает в параметрах(sfsId) идентификатор записи от СФС
 *   @example Пример подключения плеера для воспроизведения видео из СФС ():
 *   <bg-hls-player [sourceType]="'sfs'" [sfsId]="'153fd1d9-3ca5-4c11-a8f7-59fd8c68258d'"></bg-hls-player>
 *
 * @example Управление воспроизведением со стороны других компонент
 *   HTML OtherComponent ---------------------------------------------
 *   <bg-hls-player
 *     #player *ngIf="request.video?.sfsId; else elseIfPlayer" [sourceType]="'sfs'" [sfsId]="request.video.sfsId">
 *   </bg-hls-player>
 *   <ng-template #elseIfPlayer>
 *     <bg-hls-player
 *       #player *ngIf="request.videoUrl; else elseIf"
 *       [sourceType]="'archive'" [source]="request.videoUrl" [processId]="request.id">
 *     </bg-hls-player>
 *   </ng-template>
 *   <button type="button" (click)="onClickSimpleButton()">Simple Button</button>
 *
 *   JS OtherComponent ---------------------------------------------
 *   @ViewChild('player', { static: false }) public player: HlsPlayerComponent;
 *   public onClickSimpleButton(): void { this.player.togglePlaying(); }
 *
 * @author Alexandr Yakovlev
 */
@Component({
  selector: 'bg-hls-player',
  templateUrl: './hls-player.component.html',
  styleUrls: ['./hls-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HlsPlayerComponent implements AfterViewInit, OnDestroy {
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  /** Ссылка на HLS объект */
  private hls: Hls;
  /** Источник видео потока */
  public source: string;
  /** Флаг проигрывания видео потока */
  public isPlaying: boolean = false;
  /** Параметры запроса видео потока */
  private videoRequest: {
    // Задержка в миллисекундах
    delay: number;
    // Максимальное кол-во попыток
    maxRetryAttempts: number;
  };
  /** Ссылка на плеер */
  @ViewChild('player', { static: false }) public player: ElementRef<HTMLVideoElement>;
  /** Тип источника  */
  @Input() public sourceType: 'life' | 'sfs' | 'archive' = 'life';
  /** Идентификатор камеры(deviceId) */
  @Input() public cameraId: string;
  /** Ссылка на видеопоток */
  @Input() public url: string;
  /** Идентификатор записи видео файла в хранилище для долговременного хранения (СФС) */
  @Input() public sfsId: string;
  /** Интервал(дата и время) в мс видео из архива видео сервера */
  @Input() public period: {
    fromtime: number | string;
    totime?: number | string;
  };
  /** Параметры рамки для оставленных предметов от аналитики */
  @Input() public frame: IHlsStroke;

  /** Объект стиля рамки */
  public strokeStyle: IHlsStrokeStyle;
  /** Флаг о необходимости отобразить рамку для оставленного предмета */
  public needStroke: boolean = false;

  /** @ignore */
  constructor(
    private readonly rest: RestService,
    private http: HttpClient,
    private note: NotificationService,
    private settings: Settings2Service,
    private readonly service: HlsPlayerService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  /** @ignore */
  public ngAfterViewInit(): void {
    // Параметры запроса на получение видео потока
    this.videoRequest = this.getRequestConfig();

    // TODO: Необходим рефакторинг. Перенести работу с базой в сервис. Сделать одну подписку, в условии выбирать
    //       только источник видео.
    // По соответствующему типу источника определяется стратегия получения источника
    if (this.sourceType === 'life') {
      // Оставлено для истории и возможности при необходимости вернуться к этому варианту.
      // В закомментированном коде используется перекодирование которое осуществляется на бэке.
      // Сейчас была найдена возможность получать HLS поток напрямую с сервера,
      // this.rest
      //   .serviceRequest({
      //     service: { name: 'Transcoding' },
      //     action: !!this.url ? 'createRtspToHls' : 'createMacroscopRtspToHls',
      //     data: !!this.url ? { url: this.url, uuid: this.cameraId } : { deviceId: this.cameraId },
      //   })
      //   .pipe(
      //     switchMap((req: IAbstractServiceData) => {
      //       this.source = `/${req.data.path}/${req.data.indexFile}`;
      //       return of(this.source);
      //     }),
      //   )

      // Оставлено для истории и возможности при необходимости вернуться к этому варианту.
      // В закомментированном коде используется возможность получать HLS поток напрямую с сервера.
      // Проблема данной реализации состоит в том что у заказчика может не быть прямого доступа к серверу Macroscope
      // this.service.getUrlInfo(this.cameraId).pipe(
      //   switchMap((result: IHlsUrlInfo) => {
      //     urlInfo = result;
      //     const url: string = `${urlInfo.protocol}://${urlInfo.ip}:${urlInfo.port}/hls?channelid=${urlInfo.channelId}&login=${urlInfo.login}&password=${urlInfo.password}`;
      //     return this.http.get(url, { responseType: 'text' });
      //   }),
      //   switchMap((fileName: string) => {
      //     this.source = `${urlInfo.protocol}://${urlInfo.ip}:${urlInfo.port}/hls/${fileName}`;
      //     return of(this.source);
      //   }),
      // )

      // Поток проксируется на бэке. Метод возвращает ссылку для обращения за видео на бэк. Т.е. необходимо
      // обеспечить только связанность нашего сервера и сервера Macroscope
      of(null).pipe(
        switchMap(() => !!this.url ? of(this.url) : this.service.getDeviceProxyStreamUrl(this.cameraId)),
        tap((source: string) => (this.source = source)),
        // Проверка работоспособности ссылки
        switchMap((source: string) =>
          this.http.head(source).pipe(retryWithDelay({
            delay: this.videoRequest.delay,
            maxRetryAttempts: this.videoRequest.maxRetryAttempts,
            // Событие срабатывающее перед каждой новой попыткой
            onRetry: (attempt) => console.log(`Попытка воспроизведения видеопотока № ${attempt}`),
          })),
        ),
      )
      .pipe(
        catchError((err: Error) => {
          this.url = null;
          console.log('Попытка получения новой ссылки на видеопоток');
          return throwError(err);
        }),
        retry(2),
        catchError((err: Error) => {
          this.note.pushError(
            `${err.name}\nВидео поток не был получен. Кол-во попыток подключения истекло.\n${err.message}`,
          );
          console.error(err.message);
          return of();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        if (!this.hls) {
          this.hls = new Hls({
            debug: false,
            capLevelToPlayerSize: false,
            manifestLoadingMaxRetry: 10,
            manifestLoadingRetryDelay: 5000,
            manifestLoadingMaxRetryTimeout: 50000,
          });
          this.establishHlsStream(this.source);
          if (Hls.isSupported()) {
            // Если ссылка на медиапоток устарела или по каким-то иным причинам возникла ошибка при загрузке видео,
            // то перезапросить ссылку.
            this.hls.on(Hls.Events.ERROR, (error: 'hlsError', data: ErrorData) => {
              console.error(data);
              this.establishHlsStream(this.source);
            });
          }
        }
      });
    } else if (this.sourceType === 'archive') {
      // Необходимо определить тип сервера (macroscop/flussonic) для выбора метода получения ссылки
      this.rest
        .serviceRequest({
          action: 'select',
          service: { name: 'Admin' },
          entity: {
            name: 'VideoDevices',
            query: { id: this.cameraId },
            attributes: [
              'extId',
              'token',
              'mediaUrl',
              'videoServer.useSSL',
              'videoServer.ip',
              'videoServer.port',
              'videoServer.type.sysname',
            ],
          },
        })
        .pipe(
          switchMap((result: IAbstractServiceData) => {
            const camera: IVideoDeviceBg = (result.data?.items || [])[0];
            const server: IServerData = <IServerData>camera.videoServer;

            // Если сервер типа forestGuard, необходимо сформировать url и проверить его доступность
            if ((<IBaseDictionaryData>server.type).sysname === 'forestGuard') {
              const url: string = camera.mediaUrl
                .replace('/embed.html?proto=dash', '')
                .concat(
                  `/archive-${+this.period.fromtime / 1000}-${
                    (+this.period.totime - +this.period.fromtime) / 1000
                  }.mp4`,
                );
              return this.http.head(url).pipe(map(() => ({ playerType: 'video', source: url })));
            }

            // Если сервер типа flussonic, необходимо сформировать url и проверить его доступность
            if ((<IBaseDictionaryData>server.type).sysname === 'flussonic') {
              const url: string = `${server.useSSL ? 'https' : 'http'}://${server.ip}:${server.port}/${
                camera.extId
              }/archive-${+this.period.fromtime / 1000}-${
                (+this.period.totime - +this.period.fromtime) / 1000
              }.mp4?token=${camera.token}`;
              return this.http.head(url).pipe(map(() => ({ playerType: 'video', source: url })));
            }

            // Оставлено для истории и возможности при необходимости вернуться к этому варианту.
            // Проблема данной реализации состоит в том что у заказчика может не быть прямого доступа к серверу Macroscope
            // Если сервер типа macroscop, необходимо запросить url у сервиса видео-интеграции
            // return this.rest.serviceRequest({
            //   service: { name: 'VideoExport' },
            //   action: 'generateMacroscopExportArchiveURL',
            //   data: {
            //     deviceId: this.cameraId, // '6ceedb25-da51-85c3-f99c-8ad7e68937cc',
            //     fromtime: dayjs(this.period.fromtime).utcOffset(0, false).format('DD.MM.YYYY+HH:mm:ss'), // '16.06.2020+00:10:00',
            //     totime: dayjs(this.period.totime).utcOffset(0, false).format('DD.MM.YYYY+HH:mm:ss'), // '16.06.2020+00:11:00',
            //   },
            // });

            // Если сервер типа macroscop, необходимо запросить url.
            // Поток проксируется на бэке. Метод возвращает ссылку для обращения за видео на бэк. Т.е. необходимо
            // обеспечить только связанность нашего сервера и сервера Macroscope
            return !!this.period.totime
              ? this.service.getDeviceProxyArchiveUrl(
                  this.cameraId,
                  dayjs(this.period.fromtime).utcOffset(0, false).format('DD.MM.YYYY+HH:mm:ss'),
                  dayjs(this.period.totime).utcOffset(0, false).format('DD.MM.YYYY+HH:mm:ss'),
                )
              : this.service.getDeviceProxyArchiveByRangeUrl(
                  this.cameraId,
                  dayjs(this.period.fromtime).utcOffset(0, false).format('DD.MM.YYYY+HH:mm:ss'),
                );
          }),
          catchError((err) => {
            ScConsole.error(`${err.message}. Получение ссылки на видеопоток.`);
            return of(undefined);
          }),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(({ playerType, source }) => {
          this.source = source;
          if (playerType === 'hls') {
            if (!this.hls) {
              this.hls = new Hls({
                debug: false,
                capLevelToPlayerSize: false,
                manifestLoadingMaxRetry: 10,
                manifestLoadingRetryDelay: 5000,
                manifestLoadingMaxRetryTimeout: 50000,
              });
              this.establishHlsStream(this.source);
            }
          }
          if (playerType === 'video') {
            this.player.nativeElement.src = this.source;
          }
          this.cdr.detectChanges();
        });
    } else {
      this.source = `/rest/files/get/${this.sfsId}`;
      this.player.nativeElement.src = this.source;
      this.cdr.detectChanges();
    }
  }

  /**
   * Событие готовности плеера отобразить/воспроизвести видео
   * @param event - событие в DOM
   */
  public canplay(event: Event): void {
    /** Условия для отображения рамки */
    if (
      this.frame &&
      (event.target as HTMLElement).offsetHeight >= 0 &&
      (event.target as HTMLElement).offsetWidth >= 0
    ) {
      this.renderStroke({
        height: (event.target as HTMLElement).offsetHeight,
        width: (event.target as HTMLElement).offsetWidth,
      });
    }
  }

  /**
   * Метод отображение рамки
   * @param parent - размеры родительского блока
   */
  private renderStroke(parent: { height: number; width: number }): void {
    this.strokeStyle = this.genStrokeStyle(this.frame, parent);
    this.needStroke = true;
  }

  /**
   * Метод рассчитывает положение и стилизацию для отображения рамки
   * @param frame - параметры рамки переданные аналитикой
   * @param parent - размеры родительского блока
   * @return объект стиля
   */
  private genStrokeStyle(frame: IHlsStroke, parent: { height: number; width: number }): IHlsStrokeStyle {
    return {
      left: `${parent.width * frame.x}px`,
      top: `${parent.height * frame.y}px`,
      height: `${parent.height * frame.height}px`,
      width: `${parent.width * frame.width}px`,
      borderColor: frame.alarm === 1 ? '#FF0000' : '#FF8B02', // #FF0000 - красный, #FF8B02 - оранжевый
    };
  }

  /** Метод устанавливает состояние воспроизведение/остановка видео проигрования. */
  public togglePlaying(): void {
    if (!this.player.nativeElement) return;
    let toggleEvent: Promise<void>;
    if (this.player.nativeElement.paused) {
      toggleEvent = this.player.nativeElement.play();
      this.isPlaying = true;
    } else {
      toggleEvent = Promise.resolve(this.player.nativeElement.pause());
      this.isPlaying = false;
    }
    if (toggleEvent !== undefined) {
      toggleEvent
        .then((_: void) => {
          return;
        })
        .catch((error: Error) => console.error(error));
    }
  }

  /**
   * Метод устанавливает HLS поток.
   * Срабатывает после получения данных от сервиса Transcoding при инициализации компонента.
   * @param source - ссылка на hls поток.
   */
  private establishHlsStream(source: string): void {
    if (Hls.isSupported()) {
      this.hls.loadSource(source);
      this.hls.attachMedia(this.player.nativeElement);
      this.hls.on(Hls.Events.MEDIA_ATTACHED, () => this.hls.on(Hls.Events.MANIFEST_PARSED, () => this.togglePlaying()));
    }
  }

  /**
   * Метод получения параметров запроса на получение видео потока
   * @return { delay: number, maxRetryAttempts: number } - объект конфигурации запроса
   */
  private getRequestConfig(): { delay: number; maxRetryAttempts: number } {
    return {
      delay: this.settings.getConfig().delay ? parseInt(this.settings.getConfig().delay, 10) : 2000,
      maxRetryAttempts: this.settings.getConfig().maxRetryAttempts
        ? parseInt(this.settings.getConfig().maxRetryAttempts, 10)
        : 5,
    };
  }

  /** @ignore */
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (this.hls) {
      this.hls.destroy();
      ScConsole.info('Видеопоток остановлен');
    }
  }
}
