import { Directive, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IElementButton, INwHeaderBarOptions, IScInputOptions } from '@smart-city/core/common';
import { BaseComponent } from '@bg-front/core/components';
import { INotificationObjectsHcsView } from '../../../../bg/modules/external-interactions/modules/notifications-objects-hcs/models/interfaces';
import * as dayjs from 'dayjs';
import { Coordinates } from '@bg-front/core/models/classes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IFiasValueDto } from 'smart-city-types';
import { FiasBgService } from '@bg-front/core/services';
import { RegistryStateService } from '@bg-front/registry-panel/services';

/** Базовый класс для форм просмотра оповещений */
@UntilDestroy()
@Directive()
export class NotificationObjectsBaseViewFormComponent extends BaseComponent implements OnInit {
  /** Состояние формы просмотра информации об устройстве */
  public toggleFormOpen = false;

  /** Просматриваемая запись */
  public model: INotificationObjectsHcsView;

  /** Форма */
  public form: FormGroup;
  /** Настройки заголовка формы создания/редактирования записи */
  public headerActionsOptions: INwHeaderBarOptions;
  /** Признак что форма открыта с рабочего стола */
  private fromWorkspace: boolean = false;

  //#region Настройки компонент
  /** Настройки компоненты Наименование объекта */
  public monitoringObjectOptions: IScInputOptions = {
    label: 'Наименование объекта',
    placeholder: 'Наименование объекта',
    disabled: true,
  };
  /** Настройки компоненты Вид снабжения */
  public supplyTypeOptions: IScInputOptions = {
    label: 'Вид снабжения',
    placeholder: 'Вид снабжения',
    disabled: true,
  };
  /** Настройки компоненты Адрес объекта */
  public addressOptions: IScInputOptions = {
    label: 'Адрес объекта',
    placeholder: 'Адрес объекта',
    disabled: true,
  };
  /** Настройки компоненты Координаты объекта */
  public coordinatesOptions: IScInputOptions = {
    label: 'Координаты объекта',
    placeholder: 'Координаты объекта',
    disabled: true,
  };
  /** Настройки компоненты Тип */
  public typeOptions: IScInputOptions = {
    label: 'Тип',
    placeholder: 'Тип',
    disabled: true,
  };
  /** Настройки компоненты Состояние */
  public stateOptions: IScInputOptions = {
    label: 'Состояние',
    placeholder: 'Состояние',
    disabled: true,
  };
  /** Настройки компоненты Внешний идентификатор объекта */
  public extIdOptions: IScInputOptions = {
    label: 'Внешний идентификатор объекта',
    placeholder: 'Внешний идентификатор объекта',
    disabled: true,
  };
  /** Настройки компоненты Внешний идентификатор аварии */
  public failureExtIdOptions: IScInputOptions = {
    label: 'Внешний идентификатор аварии',
    placeholder: 'Внешний идентификатор аварии',
    disabled: true,
  };
  /** Настройки компоненты Дата и время оповещения */
  public latchDtOptions: IScInputOptions = {
    label: 'Дата и время оповещения',
    placeholder: 'Дата и время оповещения',
    disabled: true,
  };
  /** Настройки компоненты Сообщение в момент оповещения */
  public latchMessageOptions: IScInputOptions = {
    label: 'Сообщение в момент оповещения',
    placeholder: 'Сообщение в момент оповещения',
    disabled: true,
  };
  /** Настройки компоненты Дата и время выключения */
  public unlatchDtOptions: IScInputOptions = {
    label: 'Дата и время выключения',
    placeholder: 'Дата и время выключения',
    disabled: true,
  };
  /** Настройки компоненты Сообщение в момент выключения */
  public unlatchMessageOptions: IScInputOptions = {
    label: 'Сообщение в момент выключения',
    placeholder: 'Сообщение в момент выключения',
    disabled: true,
  };
  /** Настройки компоненты Организация */
  public organizationOptions: IScInputOptions = {
    label: 'Организация',
    placeholder: 'Организация',
    disabled: true,
  };
  /** Настройки компоненты Вид интеграции */
  public integrationTypeOptions: IScInputOptions = {
    label: 'Вид интеграции',
    placeholder: 'Вид интеграции',
    disabled: true,
  };

  //#endregion

  /** @ignore */
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly fiasService: FiasBgService,
    private readonly registryState: RegistryStateService,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.fromWorkspace = this.route.snapshot.data['fromWorkspace'];

    // Открыть реестр "Все происшествия" по подписке на открытие формы создания события
    // Подписка нужна только в случае если оповещение открыто из грида.
    // При открытии с рабочего стола сработает подписка на рабочем столе.
    if (!this.fromWorkspace) {
      this.registryState.callFormState$.pipe(untilDestroyed(this))
        .subscribe((event: 'open' | 'close') => {
          if (event === 'open') {
            this.router.navigate([{ outlets: { allRegistry: ['allEmergenciesRegistry'] } }], {
              relativeTo: this.route,
              queryParamsHandling: 'merge',
            });
          }
        });
    }

    this.form = new FormGroup({
      monitoringObject: new FormControl(this.model.monitoringObject),
      supplyType: new FormControl(this.model.supplyType),
      address: new FormControl(this.model.address),
      coordinates: new FormControl(this.model.coordinates),
      type: new FormControl(this.model.type),
      state: new FormControl(this.model.state),
      extId: new FormControl(this.model.extId),
      failureExtId: new FormControl(this.model.failureExtId),
      latchDt: new FormControl(this.model.latchDt),
      latchMessage: new FormControl(this.model.latchMessage),
      unlatchDt: new FormControl(this.model.unlatchDt),
      unlatchMessage: new FormControl(this.model.unlatchMessage),
      organization: new FormControl(this.model.organization),
      integrationType: new FormControl(this.model.integrationType),
    });

    this.headerActionsOptions = {
      title: this.model.latchDt,
      name: 'header',
      bgColor: 'white',
      margin: 'collapse',
      buttons: [
        {
          type: 'button',
          options: {
            name: 'cancel',
            icon: 'clear',
          },
        },
        {
          type: 'button',
          options: {
            name: 'newEvent',
            title: '+Новое событие',
            color: 'primary',
          },
        },
      ],
    };
  }

  /**
   * Обрабатываем нажатие кнопок в заголовке
   * @param $event - информация о нажатой кнопке
   */
  public onClickActionsButton($event: IElementButton) {
    if ($event.options.name === 'cancel') {
      // Если форма открыта с рабочего стола, при закрытии надо обнулить аутлет
      if (this.fromWorkspace) {
        this.router.navigate([{ outlets: { viewForm: null } }], {
          relativeTo: this.route.parent,
          queryParamsHandling: 'merge',
        });
        // В противно случае при закрытии надо перейти на грид с оповещениями ЖКХ
      } else {
        this.router.navigate(['external-interactions/notifications-objects-hcs']);
      }
      return;
    }
    if ($event.options.name === 'newEvent') {
      this.fiasService.getGeocode(...Coordinates.coordinatesToArray(this.model.coordinates)).pipe(
        untilDestroyed(this),
      ).subscribe((address: IFiasValueDto) => {
        this.router.navigate([{ outlets: { editCallForm: ['newEventForm'] } }], {
          relativeTo: this.route,
          queryParamsHandling: 'merge',
          state: {
            comment: this.model.latchMessage,
            ksipTime: +dayjs(this.model.latchDt),
            ksipInfo: this.model.integrationType === 'ТЭС' ? this.model.descriptionPath : null,
            jkhObject: this.model.monitoringObjectId,
            coordinates: this.model.coordinates,
            // Если определен дом, корпус или строение, то использовать адрес из ФИАС, в противном случае адрес из объекта мониторинга ЖКХ
            factAddress: address?.houseNumber || address?.buildnum || address?.strnum
              ? address
              : { fullText: this.model.address },
          }
        });
      });
    }
  }
}
