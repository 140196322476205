import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IScButtonOptions, IScSelectOptions } from '@smart-city/core/common';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { Workbook, Worksheet } from 'exceljs';
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject, IReportEmergencyDto, IReportRecord } from 'smart-city-types';
import { ReportingService } from '../../../bg/modules/reporting/services';
import { Coordinates } from '@bg-front/core/models/classes';
import { ConfirmDialogResultEnum } from '@bg-front/core/models/enums';
import { IDictionaryModel, IReportControl } from '../../models/interfaces';
import { EmergencyService } from '../../services';
import { BaseComponent, ConfirmationDialogComponent } from '@bg-front/core/components';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';

/**
 * Копмпонента реализует редактирование донесения
 */
@Component({
  selector: 'bg-edit-reports-dialog',
  templateUrl: './edit-reports-dialog.component.html',
  styleUrls: ['./edit-reports-dialog.component.scss'],
})
export class EditReportsDialogComponent extends BaseComponent implements OnInit {

  /** Событие сохранения донесения */
  @Output()
  public saveEvent = new EventEmitter(true);

  /** Настройки для кнопки сохранить */
  public saveBtnOptions: IScButtonOptions = {
    title: 'Сохранить',
    color: 'primary',
  };

  /** Настройки для кнопки отмена */
  public cancelBtnOptions: IScButtonOptions = {
    title: 'Отменить',
  };

  /** Форма */
  public form: FormGroup;

  /** Флаг наличия изменения в форме */
  public formValueChanged: boolean = false;

  /** Массив контролов и настроек формы для полей донесения */
  public reportControls: IReportControl[][];

  /** Модель донесения */
  public model: IReportEmergencyDto;
  /** Флаг того, делаем ли отчет по ЛПУ и ПВР */
    // если в форму редактирования нужно будет добавлять ещё больше отчётов, предлагаю рефакторить полностью
  public isLpuPvrReport = false;
  /** Настройка компонента 'Выбор ЛПУ' */
  public lpuOptions: IScSelectOptions = {
    title: 'Выбор ЛПУ',
    modern: true,
    service: 'Directories',
    entity: 'HealthFacilities',
    query: { 'state.sysname': 'active' },
    fieldName: 'name',
    fieldId: 'id',
  };
  /** Настройка компонента 'Выбор ПВР' */
  public pvrOptions: IScSelectOptions = {
    title: 'Выбор ПВР',
    modern: true,
    service: 'Directories',
    entity: 'Shelters',
    query: { 'state.sysname': 'active' },
    fieldName: 'name',
    fieldId: 'id',
  };
  private currentReportType: IDictionaryModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditReportsDialogComponent>,
    private readonly settings: Settings2Service,
    private readonly dialog: MatDialog,
    private readonly fb: FormBuilder,
    private readonly xlsxUploader: HttpClient,
    private readonly rest: RestService,
    private readonly significantObjectService: SignificantObjectsService,
    private readonly emergencyService: EmergencyService,
    private readonly reportingService: ReportingService,
  ) {
    super();
  }

  public get reportType(): IDictionaryModel {
    return this.currentReportType;
  }

  /** Тип донесения */
  public set reportType(value: IDictionaryModel) {
    this.currentReportType = value;
    /** Обновляем модель */
    this.model = this.getModel();
  }

  ngOnInit(): void {
    this.model = this.getModel();

    this.form = new FormGroup({ reportFields: this.fb.array([]) });

    this.getReportTypeBySysname(this.data.reportSysname)
      .subscribe((response) => {
        this.reportType = response;
        this.initForm(this.reportType.sysname);
      });
  }

  /** Закрыть это окно */
  public close(action: string): void {
    if (action === 'crosshair') {
      if (this.formValueChanged) {
        const dialogConfirmationRef = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: true,
          minWidth: 450,
        });

        dialogConfirmationRef.componentInstance.message = 'Вы хотите сохранить изменения?';

        const subscription = dialogConfirmationRef.afterClosed()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result: ConfirmDialogResultEnum) => {
            switch (result) {
              case ConfirmDialogResultEnum.reject:
                this.dialogRef.close(action);
                break;
              case ConfirmDialogResultEnum.confirm:
                this.save();
                this.dialogRef.close();
                break;
            }
            subscription.unsubscribe();
          });
      } else {
        this.dialogRef.close(action);
      }
    } else if (action === 'cancel') {
      this.dialogRef.close(action);
    }
  }

  /** Сохранить донесение */
  public save(): void {
    this.updateModelByForm();
    this.model = this.getModel();
    this.emergencyService.saveReport(this.model)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        catchError((error: Error) => this.catchErrorFn<any>(error,
          'Ошибка при сохранении')),
      )
      .subscribe((response: any) => {
        this.model = response;
        this.saveEvent.emit();
        this.dialogRef.close();
      });
  }

  /** Инициализация формы редактирования */
  public initForm(reportTypeSysname: string): void {
    const wb = new Workbook();
    let ws: Worksheet = undefined;

    this.getReportCashByEmergencyIdAndReportTypeId(this.model.emergencyId, this.model.typeId)
      .subscribe((cash) => {
        if (cash?.id) {
          this.model.id = cash.id;
        }
        switch (reportTypeSysname) {

          case 'chs1':
            this.getEmergencyById(this.model.emergencyId)
              .pipe(
                takeUntil(this.ngUnsubscribe),
                switchMap((response) => {
                  const emergency = response;
                  const coordinates = new Coordinates(emergency.point?.$lat, emergency.point?.$lon);

                  const sources = [of(emergency)];
                  // не выполняем запрос на важные объекты если нет координат
                  if (emergency.point) {
                    sources.push(this.significantObjectService.getCloseObject(coordinates, 500));
                  }

                  return forkJoin(sources);
                }),
              )
              .subscribe((response) => {
                const emergency = response[0];

                let countOfCloseImportantObjects = null;
                if (response[1]) {
                  countOfCloseImportantObjects = response[1].data.totalCount;
                }

                const user = this.settings.currentUser;
                const windDirection = emergency?.forecastingDetailId?.params?.windDirection;
                const windVelocity = emergency?.forecastingDetailId?.params?.windVelocity;
                let windParams;
                if (windDirection && windVelocity) {
                  windParams = `${windDirection}, ${windVelocity}`;
                }

                this.xlsxUploader.get(
                  '/assets/report-templates/report_by_form_1-CHS.xlsx',
                  {
                    responseType: 'arraybuffer',
                  },
                ).subscribe((buffer) => {
                  wb.xlsx.load(buffer).then((workbook) => {
                    ws = workbook.getWorksheet('Лист 1');
                    if (ws) {
                      this.model.data = [
                        {
                          name: 'Общие данные',
                          skipTitle: true,
                          fields: this.prepareData(ws, 2, 3, [
                            emergency.incidentTypeId?.name,
                            emergency.addressFact?.fullText,
                            '',
                            emergency['organization.mo.name'] ?? ':erase',
                            '',
                            '',
                            '',
                            '',
                          ], 5, 13),
                        },
                        {
                          name: ws.getCell(14, 2).value as string,
                          cellOfName: [14, 3],
                          fields: this.prepareData(ws, 2, 3, [
                            emergency?.forecastingDetailId?.params?.airTemperature ?? '',
                            windParams ?? '',
                            '',
                            '',
                          ], 15, 18),
                        },
                        {
                          name: ws.getCell(19, 2).value as string,
                          cellOfName: [19, 4],
                          fields: this.prepareData(ws, 2, 3, [
                            emergency.totalRegistryPeopleHouse + emergency.totalTemporalPeopleHouse ?? '',
                            '',
                            '',
                            countOfCloseImportantObjects ?? ':erase',
                          ], 20, 23),
                        },
                        {
                          name: 'Другие данные',
                          skipTitle: true,
                          type: 'field',
                          fields: this.prepareData(ws, 2, 3, [
                            '',
                            user.organizationId?.name ?? '',
                            '',
                            '',
                          ], 24, 27),
                        },
                      ];

                      this.applyCash(cash);

                      this.updateFormByModel();
                      this.form.valueChanges
                        .pipe(
                          debounceTime(500),
                          takeUntil(this.ngUnsubscribe),
                        )
                        .subscribe(() => this.formValueChanged = true);
                    }
                  });
                });
              });
            break;

          case 'chs2':
            this.getEmergencyById(this.model.emergencyId)
              .pipe(
                takeUntil(this.ngUnsubscribe),
              )
              .subscribe((response) => {
                const emergency = response;

                const windDirection = emergency?.forecastingDetailId?.params?.windDirection;
                const windVelocity = emergency?.forecastingDetailId?.params?.windVelocity;
                const airTemperature = emergency?.forecastingDetailId?.params?.airTemperature;
                let windParams;
                if (windDirection && windVelocity) {
                  windParams = `${windDirection}, ${windVelocity}`;
                }

                this.xlsxUploader.get(
                  '/assets/report-templates/report_by_form_2-CHS.xlsx',
                  {
                    responseType: 'arraybuffer',
                  },
                ).subscribe((buffer) => {
                  wb.xlsx.load(buffer).then((workbook) => {
                    ws = workbook.getWorksheet('ЧС-2');
                    if (ws) {

                      this.model.data = [
                        {
                          name: ws.getCell(5, 2).value as string,
                          cellOfName: [5, 1],
                          fields: this.prepareData(ws, 2, 3, [
                            emergency.incidentTypeId?.name,
                            '',
                            '',
                            moment(emergency.timeCreate).utcOffset('+03:00', false).format('DD.MM.YYYY HH:mm'),
                            moment(emergency.timeCreate).format('DD.MM.YYYY HH:mm'),
                            '',
                            emergency['organization.mo.name'] ?? ':erase',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                          ], 6, 19),
                        },
                        {
                          name: ws.getCell(20, 2).value as string,
                          cellOfName: [20, 2],
                          fields: this.prepareData(ws, 2, 3, [
                            airTemperature ?? '',
                            windParams ?? '',
                            '',
                            '',
                          ], 21, 24),
                        },
                        {
                          name: ws.getCell(25, 2).value as string,
                          cellOfName: [25, 3],
                          fields: this.prepareData(ws, 2, 3, [
                            emergency.resolution?.victim ?? ':erase',
                            emergency.resolution?.victimChildren ?? ':erase',
                            emergency.resolution?.death ?? ':erase',
                            emergency.resolution?.deathChildren ?? ':erase',
                            emergency.resolution?.hospitalized ?? ':erase',
                            emergency.resolution?.hospitalizedChildren ?? ':erase',
                            '',
                            '',
                            '',
                          ], 26, 34),
                        },
                        {
                          name: '',
                          skipTitle: true,
                          type: 'field',
                          fields: this.prepareData(ws, 2, 3, [
                            '',
                            '',
                          ], 35, 36),
                        },
                      ];

                      this.applyCash(cash);

                      this.updateFormByModel();
                      this.form.valueChanges
                        .pipe(
                          debounceTime(500),
                          takeUntil(this.ngUnsubscribe),
                        )
                        .subscribe(() => this.formValueChanged = true);
                    }
                  });
                });
              });
            break;

          case 'chs3':
            this.getEmergencyById(this.model.emergencyId)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((response) => {

                const emergency = response;

                this.xlsxUploader.get(
                  '/assets/report-templates/report_by_form_3-CHS.xlsx',
                  {
                    responseType: 'arraybuffer',
                  },
                ).subscribe((buffer) => {
                  wb.xlsx.load(buffer).then((workbook) => {
                    ws = workbook.getWorksheet('ЧС-3');
                    if (ws) {

                      this.model.data = [
                        {
                          name: ws.getCell(5, 2).value as string,
                          cellOfName: [5, 1],
                          fields: this.prepareData(ws, 2, 3, [
                            emergency.incidentTypeId?.name,
                          ], 6, 6),
                        },
                        {
                          name: ws.getCell(7, 2).value as string,
                          cellOfName: [7, 2],
                          fields: this.prepareData(ws, 2, 3, [
                            emergency.resolution?.peopleInTheZone ?? ':erase',
                            emergency.resolution?.peopleInTheZoneChildren ?? ':erase',
                          ], 8, 9),
                        },
                        {
                          name: ws.getCell(10, 2).value as string,
                          cellOfName: [10, 3],
                          fields: this.prepareData(ws, 2, 3, [
                            emergency.resolution?.victim ?? ':erase',
                            emergency.resolution?.victimChildren ?? ':erase',
                            emergency.resolution?.death ?? ':erase',
                            emergency.resolution?.deathChildren ?? ':erase',
                            '',
                            '',
                            '',
                            '',
                            '',
                          ], 11, 19),
                        },
                        {
                          name: (ws.getCell(20, 2).value as string),
                          type: 'accordion',
                          cellOfName: [20, 4],
                          fields: this.prepareData(ws, 2, 3, [
                            '',
                            '',
                          ], 21, 22),
                        },
                        {
                          name: ws.getCell(23, 2).value as string,
                          skipTitle: true,
                          type: 'field',
                          fields: this.prepareData(ws, 2, 3, [
                            '',
                          ], 23, 23),
                        },
                      ];

                      this.applyCash(cash);

                      this.updateFormByModel();
                      this.form.valueChanges
                        .pipe(
                          debounceTime(500),
                          takeUntil(this.ngUnsubscribe),
                        )
                        .subscribe(() => this.formValueChanged = true);
                    }
                  });
                });
              });
            break;

          case 'chs4':
            this.xlsxUploader.get(
              '/assets/report-templates/report_by_form_4-CHS.xlsx',
              {
                responseType: 'arraybuffer',
              },
            ).subscribe((buffer) => {
              wb.xlsx.load(buffer).then((workbook) => {
                ws = workbook.getWorksheet('4-ЧС');
                if (ws) {

                  (this.model.data as IAnyObject[]) = [
                    {
                      type: 'subtitle',
                      name: ws.getCell(5, 1).value as string,
                    },
                    {
                      name: ws.getCell(6, 1).value as string,
                      cellOfName: [6, 1],
                      fields: this.prepareDataChs4(ws, 4, 7, [
                        '',
                        '',
                        '',
                        '',
                        ':subtitle',
                        '',
                        '',
                        ':skip',
                      ], 7, 8),
                    },
                    {
                      name: ws.getCell(9, 1).value as string,
                      cellOfName: [9, 1],
                      fields: this.prepareDataChs4(ws, 4, 10, [
                        '',
                        '',
                        '',
                        '',
                        ':subtitle',
                        '',
                        '',
                        ':skip',
                      ], 10, 11),
                    },
                    {
                      type: 'subtitle',
                      name: ws.getCell(12, 1).value as string,
                    },
                    {
                      name: ws.getCell(13, 1).value as string,
                      cellOfName: [13, 1],
                      fields: this.prepareDataChs4(ws, 4, 14, [
                        '',
                        '',
                        '',
                        '',
                        ':subtitle',
                        '',
                        '',
                        ':skip',
                      ], 14, 15),
                    },
                    {
                      name: ws.getCell(16, 1).value as string,
                      cellOfName: [16, 1],
                      fields: this.prepareDataChs4(ws, 4, 17, [
                        '',
                        '',
                        '',
                        '',
                        ':subtitle',
                        '',
                        '',
                        ':skip',
                      ], 17, 18),
                    },
                    {
                      type: 'subtitle',
                      name: ws.getCell(19, 1).value as string,
                    },
                    {
                      name: ws.getCell(20, 1).value as string,
                      cellOfName: [20, 1],
                      skipTitle: true,
                      fields: this.prepareDataChs4(ws, 4, 21, [
                        ':skip',
                        '',
                        '',
                        ':skip',
                      ], 21, 21),
                    },
                    {
                      name: ws.getCell(22, 1).value as string,
                      cellOfName: [22, 1],
                      skipTitle: true,
                      fields: this.prepareDataChs4(ws, 4, 23, [
                        ':skip',
                        '',
                        '',
                        ':skip',
                      ], 23, 23),
                    },
                    {
                      type: 'subtitle',
                      name: ws.getCell(24, 1).value as string,
                    },
                    {
                      name: ws.getCell(25, 1).value as string,
                      cellOfName: [25, 1],
                      fields: this.prepareDataChs4(ws, 4, 26, [
                        '',
                        '',
                        '',
                        '',
                        ':subtitle',
                        '',
                        '',
                        ':skip',
                      ], 26, 27),
                    },
                    {
                      name: ws.getCell(28, 1).value as string,
                      cellOfName: [28, 1],
                      fields: this.prepareDataChs4(ws, 4, 29, [
                        '',
                        '',
                        '',
                        '',
                        ':subtitle',
                        '',
                        '',
                        ':skip',
                      ], 29, 30),
                    },
                    {
                      type: 'subtitle',
                      name: ws.getCell(31, 1).value as string,
                    },
                    {
                      name: ws.getCell(32, 1).value as string,
                      cellOfName: [32, 1],
                      skipTitle: true,
                      fields: this.prepareDataChs4(ws, 4, 33, [
                        ':skip',
                        '',
                        '',
                        ':skip',
                      ], 33, 33),
                    },
                    {
                      name: ws.getCell(34, 1).value as string,
                      cellOfName: [34, 1],
                      skipTitle: true,
                      fields: this.prepareDataChs4(ws, 4, 35, [
                        ':skip',
                        '',
                        '',
                        ':skip',
                      ], 35, 35),
                    },
                  ];

                  this.applyCash(cash);

                  this.updateFormByModel();
                  this.form.valueChanges
                    .pipe(
                      debounceTime(500),
                      takeUntil(this.ngUnsubscribe),
                    )
                    .subscribe(() => this.formValueChanged = true);
                }
              });
            });
            break;

          case 'chs5':
            this.getEmergencyById(this.model.emergencyId)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((response) => {
                const emergency = response;

                const windDirection = emergency?.forecastingDetailId?.params?.windDirection;
                const windVelocity = emergency?.forecastingDetailId?.params?.windVelocity;
                const airTemperature = emergency?.forecastingDetailId?.params?.airTemperature;

                let windParams;
                if (windDirection && windVelocity) {
                  windParams = `${windDirection}, ${windVelocity}`;
                }

                this.xlsxUploader.get(
                  '/assets/report-templates/report_by_form_5-CHS.xlsx',
                  {
                    responseType: 'arraybuffer',
                  },
                ).subscribe((buffer) => {
                  wb.xlsx.load(buffer).then((workbook) => {
                    ws = workbook.getWorksheet('5-ЧС');
                    if (ws) {
                      this.model.data = [
                        {
                          name: 'Общая информация',
                          skipTitle: true,
                          fields: this.prepareData(ws, 2, 3, [
                            emergency.incidentTypeId?.name,
                            '',
                            '',
                            '',
                            moment(emergency.timeCreate).format('DD.MM.YYYY'),
                            moment(emergency.timeCreate).utcOffset('+03:00', false).format('HH:mm'),
                            moment(emergency.timeCreate).format('HH:mm'),
                            moment(emergency.timeFinish).format('DD.MM.YYYY'),
                            moment(emergency.timeFinish).utcOffset('+03:00', false).format('HH:mm'),
                            moment(emergency.timeFinish).format('HH:mm'),
                            emergency.coordinates,
                            'Российская Федерация',
                            emergency['organization.mo.name'],
                            '',
                            '',
                            emergency.coordinates,
                            emergency['organization.mo.name'],
                            '',
                            '',
                            '',
                            '',
                            '',
                            emergency.resolution?.peopleInTheZone ?? ':erase',
                            emergency.resolution?.peopleInTheZoneChildren ?? ':erase',
                            '',
                            emergency.resolution?.evacuated ?? ':erase',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            airTemperature ?? '',
                            windParams ?? '',
                            '',
                            '',
                            '',
                            emergency.resolution?.victim,
                            emergency.resolution?.victimChildren,
                            emergency.resolution?.death,
                            emergency.resolution?.deathChildren,
                            '',
                            '',
                            emergency.resolution?.hospitalized,
                            emergency.resolution?.hospitalizedChildren,
                            emergency.resolution?.missing,
                            emergency.resolution?.missingChildren,
                            '',
                            '',
                            emergency.resolution?.rescued,
                            emergency.resolution?.rescuedChildren,
                            emergency.resolution?.sumDamage,
                            '',
                            '',
                            '',
                            '',
                          ], 5, 67),
                        },
                        {
                          name: ws.getCell(68, 2).value as string,
                          cellOfName: [68, 2],
                          fields: this.prepareData(ws, 2, 3, [
                            '',
                          ], 69, 69),
                        },
                        {
                          name: ws.getCell(70, 2).value as string,
                          cellOfName: [70, 3],
                          fields: this.prepareData(ws, 2, 3, [
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                            '',
                          ], 71, 86),
                        },
                      ];

                      this.applyCash(cash);

                      this.updateFormByModel();
                      this.form.valueChanges
                        .pipe(
                          debounceTime(500),
                          takeUntil(this.ngUnsubscribe),
                        )
                        .subscribe(() => this.formValueChanged = true);
                    }
                  });
                });
              });
            break;
          case 'lpuPvrReport':
            this.isLpuPvrReport = true;
            this.form = new FormGroup({
              lpu: new FormControl(null, [Validators.required]),
              pvr: new FormControl(null, [Validators.required]),
            });
            break;
        }
      });
  }

  /** Подготовка данных для записи в модель */
  public prepareData(
    ws: Worksheet,
    paramsCol: number, valuesCol: number,
    values: string[],
    rowFrom: number, rowTo: number,
  ): IReportRecord[] {
    const result: IReportRecord[] = [];
    for (let i = rowFrom; i <= rowTo; i++) {
      const record: IReportRecord = {
        number: ws.getCell(i, paramsCol - 1).value as string,
        name: ws.getCell(i, paramsCol).value as string,
        value: values[i - rowFrom],
        cellOfName: [i, paramsCol],
        cellOfValue: [i, valuesCol],
      };
      if (record.value === ':subtitle') {
        record.type = 'subtitle';
      }
      if (record.value === ':erase') {
        record.value = '';
        record.type = 'erase';
      }
      if (record.value === ':skip') {
        continue;
      }
      result.push(record);
    }
    return result;
  }

  /** Подготовка данных для записи в модель для отчета ЧС4 */
  public prepareDataChs4(
    ws: Worksheet,
    paramsRow: number, valuesRow: number,
    values: string[],
    rowFrom: number, rowTo: number,
  ): IReportRecord[] {
    const result: IReportRecord[] = [];

    // порядковые номера первого и последнего столбцов в шаблоне
    const colFrom = 1;
    const colTo = 4;

    let x: number = 0; // индекс записи из массива values
    for (let i = rowFrom; i <= rowTo; i++) {

      for (let k = colFrom; k <= colTo; k++) {

        const record: IReportRecord = {
          number: '',
          name: ws.getCell(paramsRow, k).value as string,
          value: values[x],

          cellOfName: [k, paramsRow],
          cellOfValue: [k, valuesRow],
        };
        x++;

        if (record.value === ':subtitle') {
          record.type = 'subtitle';
        }
        // Если в шаблоне есть значение, записываем его
        if (ws.getCell(i, k).value) {
          record.name = (ws.getCell(i, k).value as string);
          record.value = (ws.getCell(i, k).value as string);
        }

        if (record.value === ':erase') {
          record.value = '';
          record.type = 'erase';
        }
        if (record.value === ':skip') {
          continue;
        }

        result.push(record);
      }
    }
    return result;
  }

  /** Применение записанных значений к полям для ручного ввода */
  public applyCash(cash: IReportEmergencyDto): void {
    if (!cash) {
      return;
    }
    for (let i = 0; i < this.model.data.length; i++) {
      if (this.model.data[i].type === 'subtitle') {
        continue;
      }
      for (let y = 0; y < this.model.data[i].fields.length; y++) {
        if (this.model.data[i].fields[y].type === 'erase') {
          continue;
        }
        if (this.model.data[i].fields[y].value === '' && cash.data.length && cash.data[i].fields.length) {
          this.model.data[i].fields[y].value = cash.data[i].fields[y].value;
        }
      }
    }
  }

  /** Добавление и установка контролов в форму по модели */
  public updateFormByModel(): void {
    this.reportControls = [];
    (this.form.controls.reportFields as FormArray).clear();
    for (let i = 0; i < this.model.data.length; i++) {
      if (this.model.data[i].type === 'subtitle') {
        continue;
      }
      this.reportControls[i] = [];
      for (let y = 0; y < this.model.data[i].fields.length; y++) {
        if (this.model.data[i].fields[y].type === 'subtitle') {
          continue;
        }
        const control = this.fb.control(this.model.data[i].fields[y].value);
        const options = {
          label: this.model.data[i].fields[y].name,
        };
        this.reportControls[i][y] = {
          control,
          options,
        };
        (this.form.controls.reportFields as FormArray).push(control);
      }
    }
  }

  /** Обновление модели значениями формы */
  public updateModelByForm(): void {
    // для отчёта 'Сведения о готовности ЛПУ и ПВР'
    if (this.isLpuPvrReport) {
      this.model.data[0] = {
        name: 'lpuPvrReport', fields: [
          { number: '0', name: 'lpu', value: this.form.controls.lpu.value },
          { number: '1', name: 'pvr', value: this.form.controls.pvr.value },
        ],
      };
    } else {
      // для отчётов по форме
      for (let i = 0; i < this.model.data.length; i++) {
        if (this.model.data[i].type === 'subtitle') {
          continue;
        }
        for (let y = 0; y < this.model.data[i].fields.length; y++) {
          if (this.model.data[i].fields[y].type === 'subtitle') {
            continue;
          }
          this.model.data[i].fields[y].value = this.reportControls[i][y].control.value;
        }
      }
    }
  }

  /** Получение модели донесения */
  public getModel(): IReportEmergencyDto {
    return {
      id: this.model?.id,
      emergencyId: this.data?.emergencyId || this.model?.emergencyId,
      typeId: this.reportType?.id || this.model?.typeId,
      data: this.model?.data || [],
    };
  }

  /** Получение типа донесения по системному наименованию */
  public getReportTypeBySysname(sysname: string): Observable<IDictionaryModel> {
    return this.reportingService.getReportTypeBySysname(sysname)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  /**
   * Получение кэша ранее записанных данных формы донесения
   * по ID проишествия и типу донесения
   */
  public getReportCashByEmergencyIdAndReportTypeId(
    emergencyId: string,
    reportTypeId: string,
  ): Observable<IReportEmergencyDto> {
    return this.reportingService.getReportCashByEmergencyIdAndReportTypeId(emergencyId, reportTypeId)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  /** Получение ЧС по ID */
  public getEmergencyById(id: string): Observable<IAnyObject> {
    return this.reportingService.getEmergencyById(id)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  /** Получение параметров прогнозирования */
  public getForecastingParamsByEmergencyId(id: string): Observable<IAbstractServiceData> {
    return this.reportingService.getForecastingParamsByEmergencyId(id)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  /**
   * Метод получения значения для параметра прогнозирования по ссылке
   * @param service - наименование сервиса
   * @param entity - наименование таблицы
   * @param query - объект запроса(id)
   * @return Observable объект данных по ссылке
   */
  private getForecastingAttr(service: string, entity: string, query): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: service },
      entity: { query, name: entity },
    })
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  /**
   * Метод получения всех значений параметров прогнозирования имеющих ссылку на другую таблицу
   * @param params - объект параметров прогнозирования
   * @return Observable массив значений параметров по ссылке
   */
  private getForecastingAllParams(params): Observable<IAnyObject[]> {
    if (!(params && typeof params === 'object' && Object.keys(params).length)) {
      return of([]);
    }
    const obs = [];
    Object.keys(params).forEach((key: string) => {
      if (params[key]) {
        switch (key) {
          case 'aggregationStateId':
            obs.push(
              of(this.settings.getDictionaryByTypeSysName('stateEmergencyChemicalSubstances')
                .find(item => item.id === params[key]))
                .pipe(switchMap((aggregationState: IDictionaryInfo) => {
                  return of({ [key]: aggregationState });
                })),
            );
            break;
          case 'chemicalTypeId':
            obs.push(
              this.getForecastingAttr('Forecasting', 'ChemicalType', { id: params[key] })
                .pipe(switchMap((response: IAbstractServiceData) => {
                  const chemicalType = (response.data.items || [])[0];
                  return of({ [key]: chemicalType });
                })),
            );
            break;
          case 'cloudStateId':
            obs.push(
              of(this.settings.getDictionaryByTypeSysName('cloudy')
                .find(item => item.id === params[key]))
                .pipe(switchMap((cloudState: IDictionaryInfo) => {
                  return of({ [key]: cloudState });
                })),
            );
            break;
          case 'significantObjectId':
            obs.push(
              this.getForecastingAttr('Admin', 'SignificantObjects', { id: params[key] })
                .pipe(switchMap((response: IAbstractServiceData) => {
                  const significantObject = (response.data.items || [])[0];
                  return of({ [key]: significantObject });
                })),
            );
            break;
          case 'timeOfDayId':
            obs.push(
              of(this.settings.getDictionaryByTypeSysName('timesOfDay')
                .find(item => item.id === params[key]))
                .pipe(switchMap((timeOfDay: IDictionaryInfo) => {
                  return of({ [key]: timeOfDay });
                })),
            );
            break;
          case 'dischargeTypeId':
            obs.push(
              of(this.settings.getDictionaryByTypeSysName('emergencyChemicalSubstances')
                .find(item => item.id === params[key]))
                .pipe(switchMap((dischargeType: IDictionaryInfo) => {
                  return of({ [key]: dischargeType });
                })),
            );
            break;
        }
      }
    });

    return forkJoin(obs);
  }
}
