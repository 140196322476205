<div class="bg-incident-panel">
  <div class="bg-row">
    <div class="bg-col pr-none">
      <div class="bg-incident-panel-edit-from">
        <form [formGroup]="incidentForm" appLCSFormState [params]="attributes" *ngIf="incidentForm">
          <nw-header [options]="headerActionsOptions" (clickButton)="onClickActionsButton($event)"></nw-header>
          <div class="incident-panel">
            <mat-accordion multi="true">
              <!-- MAIN ----------------------------------------- -->
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Главное</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="bg-row space-between-container">
                  <div class="bg-col">
                    <span>Регистрация: {{ model.timeCreate | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                  </div>
                  <div class="bg-col">
                    <span>Начало: {{ model.timeStart | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                  </div>
                  <div class="bg-col">
                    <span>Окончание: {{ model.timeFinish | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
                  </div>
                </div>
                <!-- Чекбокс Адрес по координатам -->
                <div class="bg-row">
                  <div class="bg-col align-right">
                    <sc-checkbox [options]="byCoordinatesOptions" formControlName="byCoordinates"></sc-checkbox>
                  </div>
                </div>
                <!-- Адрес задан координатами -->
                <div class="bg-row" *ngIf="byCoordinates; else fiasAddress">
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <sc-input [options]="coordinatesAddressOptions" formControlName="coordinatesAddress"></sc-input>
                    </div>
                  </div>
                </div>
                <!-- Адрес задан в формате ФИАС -->
                <ng-template #fiasAddress>
                  <div class="bg-row">
                    <div class="bg-col">
                      <div class="bg-form-group full-width">
                        <div nz-form nzLayout="vertical">
                          <nz-form-item>
                            <nz-form-label nzRequired>Адрес КСиП</nz-form-label>
                            <nz-form-control>
                              <nz-address-kit formControlName="addressFact"></nz-address-kit>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <div class="bg-row">
                  <div class="bg-col-6">
                    <div class="bg-form-group">
                      <sc-select
                        [ngStyle]="{ display: !moIdVisible ? 'none' : '' }"
                        [options]="moIdOptions"
                        formControlName="moId"
                        (init)="onMoInit($event)"
                      ></sc-select>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select
                        [ngStyle]="{ display: !districtIdVisible ? 'none' : '' }"
                        [options]="districtIdOptions"
                        formControlName="districtId"
                        (init)="onDistrictInit($event)"
                      ></sc-select>
                    </div>
                  </div>
                </div>
                <placement-details-edit
                  formControlName="detailsFact"
                  [attributes]="attributes"
                ></placement-details-edit>
                <div
                  class="bg-row bg-row-align-content-center space-between-container"
                  *ngIf="!!countOfCloseImportantObjects"
                >
                  <div class="bg-col-min">
                    <i
                      class="sc-icon-attention significant-object-attention"
                      [ngClass]="{ 'significant-object-danger': hasDangerObject }"
                    ></i>
                  </div>
                  <div class="bg-col">
                    <p>{{ closeImportantObjectsMessage }} (подробнее см. раздел "Местоположение")</p>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <sc-select
                        [options]="optionsIncidentTypes"
                        formControlName="incidentTypeId"
                        [data]="loadKsipTypes$"
                      ></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group full-width">
                      <sc-select
                        [options]="optionsKsipDetails"
                        formControlName="ksipDetailsId"
                        [data]="loadKsipDetails$"
                      ></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col" *ngIf="model?.workTermFrom">
                    <div class="bg-form-group full-width">
                      <bg-datetime
                        label="Плановый срок проведения работ с"
                        formControlName="workTermFrom"
                      ></bg-datetime>
                    </div>
                  </div>
                  <div class="bg-col" *ngIf="model?.workTermTo">
                    <div class="bg-form-group full-width">
                      <bg-datetime label="Плановый срок проведения работ по" formControlName="workTermTo"></bg-datetime>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea [options]="optionsDescription" formControlName="description"></sc-textarea>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea [options]="optionsComment" formControlName="comment"></sc-textarea>
                    </div>
                  </div>
                </div>
                <div class="bg-row space-between-container">
                  <div class="bg-col" *ngIf="urgentlyVisible">
                    <div class="bg-form-group">
                      <sc-checkbox [options]="urgentlyOptions" formControlName="urgently"></sc-checkbox>
                    </div>
                  </div>
                  <div class="bg-col" *ngIf="threatsVisible">
                    <div class="bg-form-group">
                      <sc-checkbox [options]="optionsThreatPopulation" formControlName="danger"></sc-checkbox>
                    </div>
                  </div>
                  <div class="bg-col" *ngIf="threatsVisible">
                    <div class="bg-form-group">
                      <sc-checkbox [options]="optionsThreatOrganization" formControlName="dangerOrg"></sc-checkbox>
                    </div>
                  </div>
                  <div class="bg-col">
                    <sc-checkbox [options]="optionsSupervisedByCuks" formControlName="supervisedByCuks"></sc-checkbox>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select [options]="optionsOrganization" formControlName="organization"></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select [options]="optionsResponsibleUser" formControlName="responsibleUser"></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group datepicker">
                      <bg-datetime [options]="optionsDeadline" formControlName="deadline"> </bg-datetime>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <!-- PARAMS ----------------------------------------- -->
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Параметры</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <bg-dds01-fire-parameters
                  name="fireParams"
                  formControlName="fireParams"
                  [attributes]="attributes"
                ></bg-dds01-fire-parameters>
              </mat-expansion-panel>
              <!-- PLACEMENT ----------------------------------------- -->
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <i
                        *ngIf="!!countOfCloseImportantObjects"
                        class="sc-icon-attention significant-object-attention"
                        [ngClass]="{ 'significant-object-danger': hasDangerObject }"
                      ></i>
                      <div>Местоположение</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-input [options]="coordinatesOption" formControlName="coordinates"></sc-input>
                    </div>
                    <div class="bg-form-group">
                      <sc-textarea
                        class="additional-incident-type-container"
                        [options]="placeDescriptionOptions"
                        formControlName="ksipPlaceDescription"
                      ></sc-textarea>
                    </div>
                    <div class="bg-form-group">
                      <bg-significant-object-list-dialog
                        [coordinates]="incidentForm?.controls?.coordinates?.value"
                        [radius]="500"
                        [disabled]="!this.countOfCloseImportantObjects"
                      ></bg-significant-object-list-dialog>
                    </div>
                    <div class="bg-form-group">
                      <div class="bg-row bg-row-align-content-center space-between-container justify-content">
                        <div class="bg-col-min" *ngIf="countOfCloseImportantObjects">
                          <i
                            class="sc-icon-attention significant-object-attention"
                            [ngClass]="{ 'significant-object-danger': hasDangerObject }"
                          ></i>
                        </div>
                        <div>
                          <p>{{ this.closeImportantObjectsMessage }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group mini-map-container">
                      <bg-mini-map
                        [center]="miniMapCenter"
                        [zoom]="miniMapZoom"
                        [defaultMarkerCoordinate]="defaultMarkerCoordinate"
                        (changeMarkerPosition)="updateCoordinates($event)"
                        (mapReady)="onMapReady()"
                      ></bg-mini-map>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group readonly-container">
                      <label>Заявленный адрес</label>
                      <p>{{ model?.address?.fullText }}</p>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <placement-details [model]="model?.details"></placement-details>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel (opened)="openCoverageArea()">
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Зона действия</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <bg-coverage-area [address]="factAddress"></bg-coverage-area>
              </mat-expansion-panel>
              <!-- ADDITIONAL ----------------------------------------- -->
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Дополнительная информация</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <bg-dds01-additional-info
                  [options]="model.dds01AdditionalInfo"
                  name="dds01AdditionalInfo"
                  formControlName="dds01AdditionalInfo"
                  [attributes]="attributes"
                >
                </bg-dds01-additional-info>

                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-input
                        [options]="totalRegistryPeopleHouseOptions"
                        formControlName="totalRegistryPeopleHouse"
                      ></sc-input>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select
                        [options]="consumerPowerCategoryIdOptions"
                        formControlName="consumerPowerCategoryId"
                      ></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-input
                        [options]="totalTemporalPeopleHouseOptions"
                        formControlName="totalTemporalPeopleHouse"
                      ></sc-input>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-checkbox
                        [options]="individualOwnershipOptions"
                        formControlName="individualOwnership"
                      ></sc-checkbox>
                    </div>
                  </div>
                </div>
                <div class="bg-row" *ngIf="isJhk">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select [options]="jkhObjectOptions" formControlName="jkhObject"></sc-select>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea
                        [options]="favorableRestrictionObjectsOptions"
                        formControlName="favorableRestrictionObjects"
                      ></sc-textarea>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea
                        [options]="affectedRestrictionObjectsOptions"
                        formControlName="affectedRestrictionObjects"
                      ></sc-textarea>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col" *ngIf="model.isSendingToJkhReform && isHousingService">
                    <div class="bg-form-group">
                      <sc-select
                        [options]="resourceConstraintTypeOptions"
                        formControlName="resourceConstraintType"
                      ></sc-select>
                    </div>
                  </div>
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-checkbox
                        [options]="relatedRestrictionsOptions"
                        formControlName="relatedRestrictions"
                      ></sc-checkbox>
                    </div>
                  </div>
                </div>
                <div class="bg-row" *ngIf="model.isSendingToJkhReform">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-select [options]="jkhDirectionOptions" formControlName="jkhDirection"></sc-select>
                    </div>
                  </div>
                </div>

                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group">
                      <sc-textarea [options]="optionsDds01Comment" formControlName="dds01Comment"></sc-textarea>
                    </div>
                  </div>
                </div>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group readonly-container">
                      <label>Причина возврата происшествия в работу</label>
                      <p>{{ model?.reasonOfBackToWork }}</p>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <!-- LOOSES ----------------------------------------- -->
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Потери</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <bg-looses [emergencyId]="model.id" formGroupName="resolution"></bg-looses>
              </mat-expansion-panel>
              <!-- DOCS ----------------------------------------- -->
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Документы</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <multi-file [selfEnabled]="true" formControlName="documents"></multi-file>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                  <mat-panel-title>
                    <div class="header-container">
                      <div>Информация о водоисточниках</div>
                      <div class="header-container__divider">
                        <hr />
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bg-row">
                  <div class="bg-col">
                    <div class="bg-form-group h-600">
                      <grid-table [grid]="waterSourcesGridOptions"></grid-table>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </form>
        <lib-note-shown-message [infoNotes]="errors" stackPosition="bottom-right"></lib-note-shown-message>
      </div>
    </div>
    <div class="bg-col bg-incident-panel-steps-list-col bg-incident-panel-steps-list">
      <bg-response-plan-step-list
        *ngIf="involvedServiceEnabled || responsePlanStepService.getListCount('involvedOrgOpts')"
        (stepClickEvent)="showInvolvedOrgForm($event)"
        (createStepEvent)="createNewStepInvolveOrg()"
        [disabled]="!orderInvolvedServiceEnabled || isEmergencyEndStepCycle"
        [options]="involvedOrgOpts"
      >
      </bg-response-plan-step-list>
      <bg-response-plan-step-list
        (stepClickEvent)="showResponseStepForm($event)"
        (createStepEvent)="createNewStepScriptReact($event)"
        [disabled]="isEmergencyEndStepCycle"
        [options]="responseOrgOpts"
      >
      </bg-response-plan-step-list>
    </div>
  </div>
</div>
