import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { IRadiationCloudApproachResultsDto } from 'smart-city-types';
import { Coordinates } from '@bg-front/core/models/classes';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';
import { BaseComponent } from '@bg-front/core/components';
import { ForecastingRadioactiveEnvironmentVisualizationService } from '../../services';

@Component({
  selector: 'bg-radioactive-cloud-time-task-result',
  templateUrl: './radioactive-cloud-time-task-result.component.html',
  styleUrls: ['./radioactive-cloud-time-task-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioactiveCloudTimeTaskResultComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input()
  public forecastingResults: IForecastingResultDto;

  public result: IRadiationCloudApproachResultsDto;

  private elements: any[] = [];

  /** Скачать отчёт */
  @Input()
  public downloadReport: Observable<void>;

  /** Уведомляем о наличии отчёта */
  @Output()
  public hasReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor(
    private readonly forecastingService: ForecastingRadioactiveEnvironmentVisualizationService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.result = this.forecastingResults.result as IRadiationCloudApproachResultsDto;
    if (new Coordinates(this.forecastingResults.params.coordinates).isValid()) {
      this.elements = this.forecastingService.displayRadioactiveCloudTime(this.forecastingResults);
    } else {
      this.noteService.pushWarning('Отсутствуют координаты для визуализации расчёта');
    }

    this.hasReport.emit(false);

    this.cdr.markForCheck();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.forecastingService.clearResults(this.elements);
  }
}
