import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup } from '@angular/forms';
import {
  IScInputOptions,
  IScSelectLoadParams,
  IScSelectOptions,
} from '@smart-city/core/common';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData, IForecastingTechnologicalFireParamsDto, IReasonDto } from 'smart-city-types';
import { BaseComponent } from '@bg-front/core/components';
import { IScSelectItem } from '@smart-city/core/interfaces';

@Component({
  selector: 'bg-forecasting-technological-fire-event-form',
  templateUrl: './forecasting-technological-fire-event-form.component.html',
  styleUrls: ['./forecasting-technological-fire-event-form.component.scss'],
})
export class ForecastingTechnologicalFireEventFormComponent extends BaseComponent implements OnInit {
  /** Реактивная форма, для взаимодействия с внешней реактивной формой */
  public form: FormGroup;
  /** Настройка компоненты "Вид объекта пожара" */
  public fireObjectType: IScSelectOptions = {
    title: 'Вид объекта пожара',
    clearable: true,
    data: this.settings.getDictForSelect('fireObjectType'),
  };
  /** Настройка компоненты "Объект пожара" */
  public objectFire: IScSelectOptions = {
    title: 'Объект пожара',
    clearable: true,
    loadDataFn: (params: IScSelectLoadParams) => this.getFireObjects(params),
  };
  /** Настройка компоненты "Причина пожара" */
  public causeFire: IScSelectOptions = {
    title: 'Причина пожара',
    data: this.getReasons(),
    clearable: true,
  };
  /** Настройка компоненты "Виновное лицо" */
  public fireCulprit: IScInputOptions = {
    label: 'Виновное лицо',
    maxLength: 100,
  };
  /** Настройка компоненты "Ранг пожара" */
  public rank: IScSelectOptions = {
    title: 'Ранг пожара',
    clearable: true,
    data: this.settings.getDictForSelect('fireRank'),
  };

  /** @ignore */
  constructor(
    private readonly settings: Settings2Service,
    private readonly rest: RestService,
    public readonly controlContainer: ControlContainer,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }

  /**
   * Метод срабатывает при клике на селект "Причина пожара" и получает список из настроек.
   * @return список причин для селекта.
   */
  private getReasons(): IScSelectItem[] {
    const groupId = this.settings.reasonGroups.find((group: any) => group.sysName === 'fireReasons').id;
    return this.settings.reasons
      .filter((reason: IReasonDto) => reason.groupId === groupId)
      .map((reason: IReasonDto) => {
        return <IScSelectItem>{
          id: reason.id,
          text: reason.name,
        };
      });
  }

  /**
   * Метод срабатывает при клике на select "Объект пожара" и загружает справочник "Объекты пожара".
   * @param params Параметры загрузки данных.
   * @return список объектов пожара для select'а.
   * TODO: Вынести в сервис
   */
  private getFireObjects(params: IScSelectLoadParams): Observable<IScSelectItem[]> {
    if (params.limit && params.limit.paNumber > 0) return of([]);
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'FireObjects',
          sort: {
            field: 'name',
            direction: 'asc',
          },
          query: {
            $text: (params.query || { $text: undefined }).$text
              ? {
                  $search: params.query.$text.$search,
                  $fields: ['name'],
                }
              : undefined,
          },
        },
      })
      .pipe(
        map((data: IAbstractServiceData) =>
          data.data.items.map((item) => {
            return { id: item.id, text: item.name };
          }),
        ),
      );
  }

  /** Генерация FormGroup */
  public static generateFormGroup(fb: FormBuilder, params: IForecastingTechnologicalFireParamsDto) {
    return fb.group({
      fireObjectTypeId: [params?.fireObjectTypeId],
      objectFireId: [params?.objectFireId],
      causeFireId: [params?.causeFireId],
      fireCulprit: [params?.fireCulprit],
      rankId: [params?.rankId],
    });
  }
}
