import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';
import { BaseComponent } from '@bg-front/core/components';
import { EmergencyService } from '../../services';

/**
 * Компонент предназначен для отображания данных о событии в реестре происшествий
 */
@Component({
  selector: 'app-edds-event-mini-card',
  templateUrl: './event-mini-card.component.html',
  styleUrls: ['./event-mini-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventMiniCardComponent extends BaseComponent implements OnInit, OnDestroy {
  /**
   * Модель события
   */
  public event: any;

  /**
   * Состояние
   */
  public isActive = false;

  /**
   * Обработано или нет
   */
  public isHandled: boolean;

  /** Текущий статус */
  public eventStatusName: string;

  /** Время возникновения события */
  public ksipTime: string;

  constructor(private cdr: ChangeDetectorRef, private service: EmergencyService) {
    super();
  }

  @Input()
  public set data(val: any) {
    this.event = val;
    this.isHandled = this.event['isHandled'];
    if (this.isHandled) {
      this.eventStatusName = this.event['closeReasonId.name'] ? 'Без реагирования' : 'Передано в службы';
    } else {
      this.eventStatusName = 'Новое';
    }

    this.cdr.detectChanges();
  }

  /**
   * @ignore
   */
  public ngOnInit(): void {
    this.service.eventSelectItem$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((eventId: string) => {
      this.isActive = this.event.id === eventId;
      this.cdr.detectChanges();
    });

    /** Подписка на обновление происшествия в реестре */
    this.service.eventUpdateItem$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((event: string) => {
      if (event === this.event.id) {
        this.service.getEventByIdForMiniCard(event)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          catchError((err: Error) =>
            this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка при загрузке информации карточки события'),
          ),
        )
        .subscribe(
          (res: IAnyObject) => {
            this.event = res;
            this.isHandled = this.event['isHandled'];
            if (this.isHandled) {
              this.eventStatusName = this.event['closeReasonId.name'] ? 'Без реагирования' : 'Передано в службы';
            } else {
              this.eventStatusName = 'Новое';
            }
            this.cdr.detectChanges();
          },
        );
      }
      this.cdr.detectChanges();
    });
  }

  /**
   * Обрабатываем нажатие на карточку
   */
  public selectEvent() {
    if (this.isActive) {
      this.isActive = !this.isActive;
      this.service.closeEventForm();
    } else {
      this.service.selectEvent(this.event.id);
    }
  }

  /**
   * @ignore
   */
  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
