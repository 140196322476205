import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IScButtonOptions, IScInputOptions, IScSelectOptions, IScTextareaOptions } from '@smart-city/core/common';
import { Settings2Service } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IAnyObject } from 'smart-city-types';
import { IEmergencyResponsePlanStep, IOrganization, IStepDialogDataOptions } from '../../models/interfaces';
import { OrganizationsService, ResponsePlanStepService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';

@UntilDestroy()
@Component({
  selector: 'bg-involve-org-without-interaction-form',
  templateUrl: './involve-org-without-interaction-form.component.html',
  styleUrls: ['./involve-org-without-interaction-form.component.scss'],
})
export class InvolveOrgWithoutInteractionFormComponent extends BaseComponent implements OnInit {
  /** EventEmitter для закрытия */
  @Output()
  public closeEvent = new EventEmitter<IAnyObject>(true);
  /** EventEmitter по нажатию на кнопку сохранить */
  @Output()
  public saveEvent = new EventEmitter<IEmergencyResponsePlanStep[]>(true);
  /** Опции для формы */
  @Input()
  public options: IStepDialogDataOptions;

  /** Форма */
  public form: FormGroup;

  //#region Настройки компонент

  /** Настройки для отображения Наименование службы */
  public optionsIncidentTypesOptions: IScSelectOptions = {
    title: 'Наименование службы *',
    clearable: true,
    service: 'Admin',
    entity: 'Organizations',
    modern: true,
    fieldName: 'name',
    width: '100%',
    query: {
      active: true,
    },
  };

  /** Настройки для отображения Состояние работ привлекаемых служб */
  public attractOrgStatusOptions: IScSelectOptions = {
    title: 'Состояние работ привлекаемых служб *',
    data: this.settings.getDictForSelect('statusNotificationAttractOrg'),
    fieldName: 'text',
    modern: true,
    clearable: true,
    width: '100%',
  };

  /** Настройки для отображения Комментарий */
  public optionsComment: IScTextareaOptions = {
    label: 'Комментарий',
    name: 'operatorComment',
    width: '100%',
    rows: 5,
  };

  // responseTime - нормированный промежуток времени, до истечения которого служба обязана приступить к реализации
  // процедур по ликвидации последствий происшествия (аварии, чрезвычайного обстоятельства)
  // timeReact - нормированный промежуток времени, в течение которого служба обязана ликвидировать
  // последствия происшествия (аварии, чрезвычайного обстоятельства)
  /** Настройки для отображения Время реакции (в м.) */
  public responseTimeOptions: IScInputOptions = {
    label: 'Время реакции (в м.)',
    type: 'number',
  };

  /** Настройки для отображения Время реагирования (в ч.) */
  public timeReactOptions: IScInputOptions = {
    label: 'Время реагирования (в ч.)',
    type: 'number',
  };

  /** Настройки для отображения Контактный телефон */
  public phone1Options: IScInputOptions = {
    label: 'Контактный телефон',
    name: 'phone1',
    placeholder: 'Контактный телефон',
    validators: [{ pattern: '\\+?[0-9\\s\\-]*[0-9]+[0-9\\s\\-]*' }],
    disabled: true,
  };

  /** Настройки для отображения Состояние шага реагирования */
  public statusStepOptions: IScSelectOptions = {
    title: 'Состояние шага реагирования *',
    name: 'status',
    clearable: true,
    data: this.settings.getDictForSelect('responseStepStatus'),
  };

  /** Настройки для отображения кнопки сохранить */
  public saveBtnOptions: IScButtonOptions = {
    title: 'Сохранить',
    color: 'primary',
  };

  /** Настройки для отображения кнопки отмена */
  public cancelBtnOptions: IScButtonOptions = {
    title: 'Отменить',
  };

  //#endregion

  constructor(
    public readonly responsePlanStepService: ResponsePlanStepService,
    private readonly settings: Settings2Service,
    private readonly orgService: OrganizationsService,
  ) {
    super();
    dayjs.extend(duration);
  }

  /** @ignore */
  public ngOnInit(): void {
    if (this.options.saveBtnOptions) {
      this.saveBtnOptions = {
        ...this.options?.saveBtnOptions,
      };
    }
    const serviceType = (this.options?.step || {})['serviceType.id'] || this.options?.serviceType;

    this.form = new FormGroup({
      attractOrgData: new FormControl((<IOrganization>this.options?.step?.attractOrgData)?.id, [Validators.required]),
      attractOrgStatus: new FormControl(this.options?.step?.attractOrgStatus, [Validators.required]),
      operatorComment: new FormControl(this.options?.step?.operatorComment),
      phone1: new FormControl((this.options?.step || {})['attractOrgData.phone1']),
      status: new FormControl(this.options?.step?.status, [Validators.required]),
      timeReact: new FormControl(this.options?.step?.timeReact || this.options?.timeReact),
      responseTime: new FormControl(
        this.options?.step?.responseTime || this.options?.responseTime
          ? dayjs.duration(this.options?.step?.responseTime || this.options?.responseTime).asMinutes()
          : Math.ceil(parseFloat(this.settings.getSettings().responsePlanStep || 0)),
      ),
    });

    this.disableForm();

    if (serviceType) {
      this.optionsIncidentTypesOptions.query = {
        ...this.optionsIncidentTypesOptions.query,
        orgTypeParam: serviceType,
      };
    }
    this.form.controls['attractOrgData'].valueChanges
      .pipe(
        switchMap((attractOrgDataId: string) => this.orgService.getOrganizationBy(attractOrgDataId)),
        untilDestroyed(this),
      )
      .subscribe((organization: IOrganization) => {
        this.form.controls['phone1'].setValue(organization?.phone1);
      });
  }

  /**
   * Обработчик по нажатию на кнопку сохранить
   * @param event
   */
  public saveHandler(event: IEmergencyResponsePlanStep) {
    if (this.form.valid) {
      const params = {
        status: this.form.controls['status'].value || null,
        operatorComment: this.form.controls['operatorComment'].value || '',
        attractOrgStatus: this.form.controls['attractOrgStatus'].value || undefined,
        attractOrgData: this.form.controls['attractOrgData'].value || null,
        responseTime: dayjs
          .duration(
            this.form.controls['responseTime'].value ||
              Math.ceil(parseFloat(this.settings.getSettings().responsePlanStep) || 0),
            'm',
          )
          .asMilliseconds(),
        timeReact: this.form.controls['timeReact'].value || null,
        // Если шаг обновляется, то номер шага оставить прежним
        number: this.options?.step?.id ? undefined : this.responsePlanStepService.getListCount('involvedOrgOpts') + 1,
      };
      this.editStep(params);
    }
  }

  /** Метод редактирования записи
   * @param params
   * @return
   */
  public editStep(params): void {
    const dateAndTimeNotificationService = this.receiveDateAndTimeNotificationService();
    const dateAndTimeCompleteReact = this.receiveDateAndTimeCompleteReact();
    const dateAndTimeStartReact = this.receiveDateAndTimeStartReact();
    const attractOrgStatusSysname = this.settings.getDictionaryById(params.attractOrgStatus).sysname;
    const isFinishTimeStep = ['done', 'fail'].includes(attractOrgStatusSysname);
    const data = {
      ...params,
      ...dateAndTimeNotificationService,
      ...dateAndTimeCompleteReact,
      ...dateAndTimeStartReact,
      ...(isFinishTimeStep ? { finishTimeStep: new Date().getTime() } : {}),
    };
    if (this.options?.step?.id) {
      this.updateStep(data)
        .pipe(
          tap(() => this.noteService.pushInfo('Запись успешно обновлена')),
          untilDestroyed(this),
        )
        .subscribe(() => this.closeHandler());
    } else {
      this.createSteps(data)
        .pipe(
          tap(() => this.noteService.pushInfo('Запись успешно создана')),
          untilDestroyed(this),
        )
        .subscribe(() => {
          this.closeHandler();
        });
    }
  }

  /** Обработчик по нажатию на кнопку сохранить
   * @param result - список шагов
   * @return
   */
  public updateStep(result: IEmergencyResponsePlanStep): Observable<IEmergencyResponsePlanStep[]> {
    return this.responsePlanStepService
      .updateStepItemById(
        this.options.step.id,
        {
          ...result,
          startTimeStep: !this.options.step.startTimeStep ? new Date().getTime() : undefined,
        },
        this.options.entity,
      )
      .pipe(
        catchError((error: Error) =>
          this.catchErrorFn<IEmergencyResponsePlanStep[]>(error, 'Ошибка получения сценария реагирования происшествия'),
        ),
      );
  }

  /**
   * Метод создания шага для служб без информационного взаимодействия
   * @result - данные шага
   * @return
   */
  public createSteps(result: IEmergencyResponsePlanStep) {
    const time = new Date().getTime();
    const data = {
      ...result,
      serviceType: this.options.serviceType,
      emergencyId: this.options.emergencyModel.id,
      startTimeStep: new Date().getTime(),
      responseTime: dayjs
        .duration(
          this.form.controls['responseTime'].value ||
            Math.ceil(parseFloat(this.settings.getSettings().responsePlanStep) || 0),
          'm',
        )
        .asMilliseconds(),
      timeReact: this.form.controls['timeReact'].value || null,
      // TODO: Системное наименование auto соответствует значению 'Привлечение службы к реагированию'
      //       Подумать, может быть можно привести к дружелюбному значению.
      //       Если нет, то оставить только комментарий выше и жить с этим.
      stepType: this.settings.getDictionaryByTypeAndSysName('stepType', 'auto')?.id,
      ...(result.attractOrgData ? { dateAndTimeAttractService: time } : {}),
    };

    return this.responsePlanStepService
      .createSteps([data], 'involvedOrgOpts')
      .pipe(tap(() => this.saveEvent.emit([data])));
  }

  /** Обработчик по нажатию на кнопку отмена
   * @return
   */
  public closeHandler(): void {
    this.closeEvent.emit();
  }

  /**
   * Блокировка формы
   */
  private disableForm(): void {
    if (this.options.disabled) {
      this.form.disable();
      return;
    }
    const sysnameStatusList = ['done', 'fail'];
    const responseStepStatus = this.settings.getDictionaryByTypeSysName('responseStepStatus');
    const statusNotificationAttractOrg = this.settings.getDictionaryByTypeSysName('statusNotificationAttractOrg');
    const sysnameStep = responseStepStatus.find((item) => {
      return item.id === this.options?.step?.status;
    })?.sysname;

    const sysnameAttractOrg = statusNotificationAttractOrg.find((item) => {
      return item.id === this.options?.step?.attractOrgStatus;
    })?.sysname;

    if (sysnameStatusList.includes(sysnameStep) && sysnameStatusList.includes(sysnameAttractOrg)) {
      this.form.disable();
    }
  }

  /**
   *  Проверка на статус Оповещена по полю Состояние работ привлекаемых служб
   *  @return
   */
  private isAnnouncedStepStatus(): boolean {
    return this.settings.getDictionaryById(this.form.controls['attractOrgStatus'].value)?.sysname === 'announced';
  }

  /**
   *  Проверка на статус Оповестить по полю Состояние работ привлекаемых служб
   *  @return
   */
  private isDoneOrFailStepStatus(): boolean {
    const statuses = ['done', 'fail'];
    return statuses.includes(this.settings.getDictionaryById(this.form.controls['attractOrgStatus'].value)?.sysname);
  }

  /**
   *  Проверка на статус В работе по полю Состояние работ привлекаемых служб
   *  @return
   */
  private isAtWorkStepStatus(): boolean {
    return this.settings.getDictionaryById(this.form.controls['attractOrgStatus'].value)?.sysname === 'atWork';
  }

  /** Получение значение для поля Дата и время оповещения службы */
  private receiveDateAndTimeNotificationService(): { dateAndTimeNotificationService: number } {
    return this.isAnnouncedStepStatus()
      ? { dateAndTimeNotificationService: new Date().getTime() }
      : { dateAndTimeNotificationService: this.options?.step?.dateAndTimeNotificationService || 0 };
  }

  /** Получение значение для поля Дата и время завершения реагирования */
  private receiveDateAndTimeCompleteReact(): { dateAndTimeCompleteReact: number } {
    return this.isDoneOrFailStepStatus()
      ? { dateAndTimeCompleteReact: new Date().getTime() }
      : { dateAndTimeCompleteReact: this.options?.step?.dateAndTimeCompleteReact || 0 };
  }

  /** Получение значение для поля Дата и время начала реагирования */
  private receiveDateAndTimeStartReact(): { dateAndTimeStartReact: number } {
    return this.isAtWorkStepStatus()
      ? { dateAndTimeStartReact: new Date().getTime() }
      : { dateAndTimeStartReact: this.options?.step?.dateAndTimeStartReact || 0 };
  }
}
