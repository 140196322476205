import { IDownloadButton } from '../interfaces/download-sources-statuses.interface';
import { ArchiveStatuses, Prefixes } from '../enums';

const photo = Prefixes.photo;
const defaultPropName = `${ArchiveStatuses.default}${photo}`;
const pendingPropName = `${ArchiveStatuses.pending}${photo}`;
const errorPropName = `${ArchiveStatuses.error}${photo}`;
const successPropName = `${ArchiveStatuses.success}${photo}`;

const prepareButtonText = 'Подготовить фото';
const downloadButtonText = 'Скачать фото';

/** Статус по умолчание*/
const defaultStatus: IDownloadButton = {
  text: prepareButtonText,
  message: 'Запуск формирования архива с фото',
  icon: 'sc-icon-inform',
  iconColor: '#7B7B7B',
  messageColor: '#7B7B7B',
  disabled: false,
};

/** "Скачать фотоматериалы" - статус доступно для скачивания */
const downloadPhotoAccess: IDownloadButton = {
  text: downloadButtonText,
  message: 'Фото доступны для скачивания',
  icon: 'sc-icon-check',
  iconColor: '#1CCA84',
  disabled: false,
};

/** "Скачать фотоматериалы" - статус доступно в процессе */
const downloadPhotoInProgress: IDownloadButton = {
  text: downloadButtonText,
  message: 'Идёт процесс формирования архива фото',
  icon: 'sc-icon-waiting',
  iconColor: '#FF9700',
  disabled: true,
};

/** "Скачать фотоматериалы" - статус ошибка при скачивании */
const downloadPhotoInError: IDownloadButton = {
  text: prepareButtonText,
  message: 'Ошибка при скачивании фото. Пожалуйста, обратитесь в техподдержку.',
  icon: 'sc-icon-inform',
  iconColor: '#FC5A5A',
  messageColor: '#FC5A5A',
  disabled: false,
};

export const photoStatuses: Map<string, IDownloadButton> = new Map([
  [defaultPropName, defaultStatus],
  [pendingPropName, downloadPhotoInProgress],
  [errorPropName, downloadPhotoInError],
  [successPropName, downloadPhotoAccess],
]);
