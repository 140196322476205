import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseDashboardComponent, ExportDialogComponent } from '@bg-front/core/components';
import { IResponseWithTotal } from '@bg-front/core/models/interfaces';
import { ExportService } from '@bg-front/core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationService, ScNavService } from '@smart-city/core/services';
import * as dayjs from 'dayjs';
import { NzContextMenuService } from 'ng-zorro-antd/dropdown';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NEVER } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';

import { IRegistryPanelDashboardFilter, IRegistryPanelDto } from '../../models/interfaces';
import { RegistryPanelService } from '../../services';
import { RegistryPanelDashboardFilterComponent } from '../registry-panel-dashboard-filter/registry-panel-dashboard-filter.component';

@UntilDestroy()
@Component({
  selector: 'bg-registry-panel-dashboard',
  templateUrl: './registry-panel-dashboard.component.html',
  styleUrls: ['./registry-panel-dashboard.component.scss'],
})
export class RegistryPanelDashboardComponent extends BaseDashboardComponent<
  IRegistryPanelDto,
  IRegistryPanelDashboardFilter
> {
  /** @ignore */
  constructor(
    private readonly router: Router,
    private readonly registryPanelService: RegistryPanelService,
    private readonly modalService: NzModalService,
    private readonly exportService: ExportService,
    route: ActivatedRoute,
    nzContextMenuService: NzContextMenuService,
    notificationService: NotificationService,
    scNavService: ScNavService,
  ) {
    super(notificationService, nzContextMenuService, route, scNavService);

    this.sortableColumns = {
      name: {
        key: 'name',
        order: null,
      },
      sysName: {
        key: 'sysName',
        order: null,
      },
      claim: {
        key: 'claim',
        order: null,
      },
      order: {
        key: 'order',
        order: null,
      },
    };
  }

  /** Редактирование запроса */
  public editRow(panel: IRegistryPanelDto): void {
    this.currentId = panel.id;
    this.router.navigate(['edit', panel.id], {
      relativeTo: this.route,
    });
  }

  public openFilterDialog(): void {
    this.modalService
      .create({
        nzTitle: 'Параметры фильтрации',
        nzContent: RegistryPanelDashboardFilterComponent,
        nzFooter: null,
        nzComponentParams: {
          filter: this.filter,
        },
      })
      .afterClose.pipe(untilDestroyed(this))
      .subscribe((value: IRegistryPanelDashboardFilter) => {
        if (value) {
          this.setFilterValue(value);
        }
      });
  }

  public override prepareQuery(): IAnyObject | undefined {
    const and = [];
    if (this.filter) {
      if (this.filter.text) {
        and.push({
          $text: {
            $search: this.filter.text,
            $fields: ['name', 'sysName', 'claim', 'order'],
          },
        });
      }
    }
    this.filterCount = and.length;
    return and.length ? { $and: and } : undefined;
  }

  /** Удаление елементов */
  public delete(ids: string | string[]): void {
    if (!Array.isArray(ids)) {
      this.currentId = ids;
    }

    this.registryPanelService
      .delete(ids)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.clearCheckedIds(Array.isArray(ids) ? ids.length : 1);
        this.reload();
      });
  }

  /** Добавление новой записи */
  public add(): void {
    this.router.navigate(['edit', ''], {
      relativeTo: this.route,
    });
  }

  /** Функция загрузки и обновления данных */
  public reload(): void {
    this.isLoading = true;
    this.registryPanelService
      .getRegistriesForTable(
        this.prepareQuery(),
        this.pageIndex ? this.pageIndex - 1 : 0,
        this.pageSize,
        this.getSort(),
      )
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe((response: IResponseWithTotal) => {
        this.totalCount = response.totalCount;
        this.data = response.items;
      });
  }

  /** Функция экспорта */
  public export(): void {
    const modal = this.modalService.create({
      nzTitle: 'Параметры экспорта',
      nzContent: ExportDialogComponent,
      nzFooter: null,
    });

    modal.afterClose
      .pipe(
        switchMap((fileType: string) => {
          if (fileType) {
            return this.exportService.exportEntity(
              {
                title: `Конструктор панели реестров_${dayjs().format('DD.MM.YYYY HH_mm_ss')}`,
                attributes: ['name', 'sysName', 'claim', 'order', 'icon', 'iconColor'],
                titles: {
                  name: 'Наименование',
                  sysName: 'Системное наименование',
                  claim: 'Клэйм',
                  order: 'Порядок',
                  icon: 'Иконка',
                  iconColor: 'Цвет иконки',
                },
                limit: 500,
                serviceName: 'Admin',
                entityName: 'RegistriesPanel',
              },
              ['name', 'sysName', 'claim', 'order', 'icon', 'iconColor'],
              fileType,
            );
          }
          return NEVER;
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  /** Функция выполняемая при закрытии формы редактирования */
  public onFormDeactivate(form: IAnyObject): void {
    if (form.closeAction === 'delete') {
      this.clearCheckedIds(1);
    } else {
      this.currentId = undefined;
    }
    if (!!form.closeAction) this.reload();
  }
}
