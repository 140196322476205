import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { IScInputOptions } from '@smart-city/core/common';
import { NotificationObjectsBaseViewFormComponent } from '../notification-objects-base-view-form/notification-objects-base-view-form.component';

@Component({
  selector: 'bg-notification-objects-tes-view-form',
  templateUrl: './notification-objects-tes-view-form.component.html',
  styleUrls: ['./notification-objects-tes-view-form.component.scss'],
})
export class NotificationObjectsTesViewFormComponent
  extends NotificationObjectsBaseViewFormComponent
  implements OnInit {

  /** Геттер массива формгрупп с сообщениями */
  get messages() {
    return <FormGroup[]>(<FormArray>this.form.controls.messages).controls;
  }

  //#region Настройки компонент
  /** Настройки компоненты Место регистрации */
  public descriptionPathOptions: IScInputOptions = {
    label: 'Место регистрации',
    placeholder: 'Место регистрации',
    disabled: true,
  };
  /** Настройки компоненты Дата и время сообщения */
  public messageDtOptions: IScInputOptions = {
    label: 'Дата и время',
    placeholder: 'Дата и время',
    disabled: true,
  };
  /** Настройки компоненты Состояние сообщения */
  public stateOptions: IScInputOptions = {
    label: 'Состояние',
    placeholder: 'Состояние',
    disabled: true,
  };
  /** Настройки компоненты Текст сообщения */
  public textOptions: IScInputOptions = {
    label: 'Текст',
    placeholder: 'Текст',
    disabled: true,
  };
  //#endregion

  /** @ignore */
  public override ngOnInit(): void {
    super.ngOnInit();

    this.form.addControl('descriptionPath', new FormControl(this.model.descriptionPath));
    this.form.addControl('messages', new FormArray((this.model.messages || []).map(message =>
      new FormGroup({
        text: new FormControl(message.text),
        messageDt: new FormControl(message.messageDt),
        state: new FormControl(message.state),
      }),
    )));
  }
}
