import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { INwHeaderBarOptions, IScButtonOptions, IScCheckboxOptions, IScSelectOptions } from '@smart-city/core/common';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { AccessService, IAccessAction, Settings2Service } from '@smart-city/core/services';
import { CommonService } from '@bg-front/core/services';
import { FormControl, FormGroup } from '@angular/forms';
import { IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { FilterOperationEnum } from '@bg-front/core/models/enums';

/**
 * Диалог с фильтрами объектов на карте
 */
@Component({
  selector: 'bg-map-layers-filters-dialog',
  templateUrl: './map-layers-filters-dialog.component.html',
  styleUrls: ['./map-layers-filters-dialog.component.scss'],
})
export class MapLayersFiltersDialogComponent {
  /** Список фильтров на форме */
  public filters: string[] = [];

  /** Список скрытых фильтров */
  public hideFilters: string[] = [];

  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions = {
    margin: 'collapse',
    title: 'Параметры фильтрации',
    buttons: [
      {
        type: 'button',
        options: {
          name: 'close',
          icon: 'close',
        },
      },
    ],
  };

  /** Настройка компоненты МО */
  public moOptions: IScSelectOptions = {
    title: 'МО',
    service: 'Admin',
    entity: 'Municipal',
    fieldName: 'name',
    modern: true,
    query: {
      active: true,
    },
    clearable: true,
  };

  /** Настройки для кнопки применить */
  public applyOptions: IScButtonOptions = {
    title: 'Применить',
    color: 'primary',
  };
  /** Настройки кнопки отмена */
  public cancelOptions: IScButtonOptions = {
    title: 'Отмена',
  };

  /** Настройка компоненты фильтра Мои происшествия */
  public myIncidentsOptions: IScCheckboxOptions = {
    title: 'Мои происшествия',
  };

  /** Форма */
  public form: FormGroup;

  /** Есть ли слои с фильтром "Мои инциденты" */
  public layersWithMyIncidentsFilterExist: boolean;

  /** Значение для фильтра МО */
  private get moValue() {
    return (
      this.data.mapLayersFilters['mo']['Инциденты']?.value ||
      this.data.mapLayersFilters['mo']['Поручения']?.value ||
      this.data.mapLayersFilters['mo']['Неисправности видеокамер']?.value ||
      this.data.mapLayersFilters['mo']['Важные объекты']?.value ||
      this.data.mapLayersFilters['mo']['КСЭОН']?.value ||
      this.data.mapLayersFilters['mo']['Организации Экстренного реагирования']?.value ||
      this.data.mapLayersFilters['mo']['ДДС городских служб и организаций']?.value ||
      this.data.mapLayersFilters['mo']['Плановые работы']?.value
    );
  }

  /** Значение для фильтра "Мои инциденты" */
  private get myIncidentsValue() {
    return (
      this.data.mapLayersFilters['responsible']['Инциденты']?.value ||
      this.data.mapLayersFilters['responsible']['Неисправности видеокамер']?.value ||
      this.data.mapLayersFilters['responsible']['Поручения']?.value ||
      this.data.mapLayersFilters['responsible']['Плановые работы']?.value
    );
  }

  /** Доступность фильтра "Муниципальное образование" */
  public globalMoFilter: IAccessAction = { name: 'globalMoFilter' };
  /** Видимость контрола "Муниципальное образование" */
  public isMoFilterVisible: boolean;

  /** Доступность фильтра "Плановые работы" */
  public plannedWorksLayer: IAccessAction = { name: 'PlannedWorksLayer' };
  /** Видимость контрола "Плановые работы" */
  public isPlannedWorksLayerVisible: boolean;

  /** @ignore */
  constructor(
    private readonly dialogRef: MatDialogRef<MapLayersFiltersDialogComponent>,
    private readonly settings: Settings2Service,
    private readonly commonService: CommonService,
    private readonly accessService: AccessService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mapLayersFilters: IMapLayerEntityFilter[];
      layersFilters: IMapLayerEntityFilter[];
      hideMyIncidentsFilter: boolean;
    },
  ) {
    this.moOptions.value = this.moValue;
    // Если пользователь не админ, то заблокировать фильтр по МО.
    if (!this.commonService.isAdmin()) {
      this.moOptions.disabled = true;
    }

    this.globalMoFilter = this.accessService.accessMap[this.globalMoFilter.name];
    this.isMoFilterVisible = this.globalMoFilter?.visible;

    this.plannedWorksLayer = this.accessService.accessMap[this.plannedWorksLayer.name];
    this.isPlannedWorksLayerVisible = this.plannedWorksLayer?.visible;

    this.form = new FormGroup({
      myIncidents: new FormControl(undefined),
    });

    this.form.controls.myIncidents.patchValue(!!this.myIncidentsValue);

    /** Слои для которых дбавляется фильтр "Мои происшествия" */
    const mapLayers: string[] = ['Инциденты', 'Неисправности видеокамер', 'Поручения', 'Плановые работы'];

    this.form.controls.myIncidents.valueChanges.pipe().subscribe((value: boolean) => {
      if (value) {
        mapLayers.map((layer: string) => {
          this.data.mapLayersFilters['responsible'][layer] = {
            property: 'responsible',
            value: this.settings.currentUser.id,
            operation: FilterOperationEnum.equal,
          };
        });
      } else {
        this.data.mapLayersFilters['responsible'] = {};
      }
    });

    this.layersWithMyIncidentsFilterExist = !!mapLayers.find((mapLayer: string) => {
      return Object.keys(this.data.mapLayersFilters).find((activeLayer: string) => mapLayer === activeLayer);
    });
  }

  /**
   * Закрытие
   */
  public close() {
    this.dialogRef.close();
  }

  /**
   * Выполнить фильтрацию объектов на карте
   */
  public apply() {
    this.dialogRef.close(this.data.mapLayersFilters);
  }

  /**
   * Сбор информации о фильтрах
   * @param filterName наименование фильтра (он же слой)
   * @param value значение фильтров
   */
  public onFiltersChange(filterName: string, value: IMapLayerEntityFilter[] | 'delete') {
    if (value === 'delete') {
      this.data.mapLayersFilters[filterName] = [];
      this.hideFilters.push(filterName);
    } else {
      this.data.mapLayersFilters[filterName] = value;
    }
  }

  /**
   * Добавление к фильтрам информации о МО
   * @param value значение фильтра
   */
  public onMoChange(value: IScSelectItem) {
    if (value?.id) {
      this.data.mapLayersFilters['mo']['Инциденты'] = {
        property: 'organization.mo',
        value: value.id,
        operation: FilterOperationEnum.equal,
      };
      this.data.mapLayersFilters['mo']['Поручения'] = {
        property: 'organization.mo',
        value: value.id,
        operation: FilterOperationEnum.equal,
      };
      this.data.mapLayersFilters['mo']['Неисправности видеокамер'] = {
        property: 'organization.mo',
        value: value.id,
        operation: FilterOperationEnum.equal,
      };
      this.data.mapLayersFilters['mo']['Важные объекты'] = {
        property: 'mo',
        value: value.id,
        operation: FilterOperationEnum.equal,
      };
      this.data.mapLayersFilters['mo']['КСЭОН'] = {
        property: 'mo',
        value: value.id,
        operation: FilterOperationEnum.equal,
      };
      this.data.mapLayersFilters['mo']['Организации Экстренного реагирования'] = {
        property: 'mo',
        value: value.id,
        operation: FilterOperationEnum.equal,
      };
      this.data.mapLayersFilters['mo']['ДДС городских служб и организаций'] = {
        property: 'mo',
        value: value.id,
        operation: FilterOperationEnum.equal,
      };
      this.data.mapLayersFilters['mo']['Плановые работы'] = {
        property: 'organization.mo',
        value: value.id,
        operation: FilterOperationEnum.equal,
      };
    } else {
      this.data.mapLayersFilters['mo'] = {};
    }
  }
}
