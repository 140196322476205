import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  IForecastingExtinguishingAgentConsumptionTaskDto,
  IForecastingExtinguishingAgentConsumptionTaskResultDto,
} from '../../models/interfaces';
import { BaseComponent } from '@bg-front/core/components';
import { IForecastingResultDto } from '@bg-front/core/models/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bg-forecasting-extinguishing-agent-consumption-task-result',
  templateUrl: './forecasting-extinguishing-agent-consumption-task-result.component.html',
  styleUrls: ['./forecasting-extinguishing-agent-consumption-task-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastingExtinguishingAgentConsumptionTaskResultComponent extends BaseComponent implements OnInit {
  /**
   * Режим просмотра.
   * Отображать визуализацию не надо
   * TODO: После реализации формы просмотра убрать
   */
  @Input()
  public isShow = false;

  /** Результат расчёта */
  @Input()
  public forecastingResults:
    IForecastingResultDto<IForecastingExtinguishingAgentConsumptionTaskDto,
      IForecastingExtinguishingAgentConsumptionTaskResultDto>;

  @Input()
  public downloadReport: Observable<void>;

  /** Уведомляем о наличии отчёта */
  @Output()
  public hasReport: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
  }

  /** Результат прогнозирования */
  public get result(): IForecastingExtinguishingAgentConsumptionTaskResultDto {
    return this.forecastingResults.result;
  }

  /** Параметры */
  public get params(): IForecastingExtinguishingAgentConsumptionTaskDto {
    return this.forecastingResults.params.params;
  }

  public ngOnInit(): void {
    this.hasReport.emit(false);
    this.downloadReport.pipe(untilDestroyed(this)).subscribe(this.createReport);

    this.cdr.markForCheck();
  }

  /** Скачивание */
  public createReport() {
    throw ('Реализовать метод');
  }
}
