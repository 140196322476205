import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { MapLayer } from '../../models/classes';
import { CLICK_MAKER_SHAPE_SVG } from '../../models/constants';
import {
  IBaseSetViewOptions,
  IMapBaseAddObjectOptions,
  IMapBaseCircleOptions,
  IMapBaseEvent,
  IMapBaseFitBoundsOptions,
  IMapBaseGeoJSONOptions,
  IMapBaseIcon,
  IMapBaseLayerOptions,
  IMapBaseMarkerOptions,
  IMapBaseObjectBaseEvent,
  IMapBaseObjectEvent,
  IMapBaseOptions,
  IMapBasePanOptions,
  IMapBasePolygonOptions,
  IMapBasePolylineOptions,
  IMapBaseRectangleOptions,
  IMapBaseRemoveDifferentObjectsOptions,
  IMapBaseRemoveObjectOptions,
  IMapBaseSelectObjectOptions,
  IMapBaseSemiCircleOptions,
  IMapBaseShowLayerOptions,
  IMapBaseShowObjectOptions,
  IMapBaseZoomOptions,
  IMapObjectIconOptions,
  MapBaseTileSourceOptions,
} from '../../models/interfaces';
import { IMapBasePositionOnCoordinateOptions } from '../../models/interfaces/map-base-position-on-coordinate-options.interface';
import { MapBaseCoordinatesType, TMapBaseCoordinates } from '../../models/types';
import { MapUtils } from '../../models/utils';
import { MapLayersQuery } from '../map-layers-store';
import { MapObjectsQuery } from '../map-objects-store';

/**
 * Сервис для компонента базовой карты.
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MapBaseService {
  /** Объект subject событий объектов карты */
  private mapBaseServiceSubjectEventObject = new Subject<IMapBaseObjectEvent>();
  /** Объект observable событий объектов карты только только для подписки  */
  private mapBaseServiceSubjectEventObject$ = this.mapBaseServiceSubjectEventObject.asObservable();
  /** Объект subject событий карты */
  private mapBaseServiceSubjectMapEvent = new Subject<IMapBaseEvent>();
  /** Объект observable событий карты только для подписки  */
  private mapBaseServiceSubjectMapEvent$ = this.mapBaseServiceSubjectMapEvent.asObservable();
  /** ссылки на хранилища */
  private mapStoreLinks: Map<
    string,
    {
      objects: MapObjectsQuery;
      layers: MapLayersQuery;
    }
  > = new Map<
    string,
    {
      objects: MapObjectsQuery;
      layers: MapLayersQuery;
    }
  >();

  /** Изменение настроек зума карты */
  private setZoomOptionsSub: Subject<IMapBaseOptions<IMapBaseZoomOptions>> = new Subject<
    IMapBaseOptions<IMapBaseZoomOptions>
  >();
  public setZoomOptions$: Observable<IMapBaseOptions<IMapBaseZoomOptions>> = this.setZoomOptionsSub.asObservable();

  /** Изменение настроек зума карты */
  private setZoomSub: Subject<IMapBaseOptions<number>> = new Subject<IMapBaseOptions<number>>();
  public setZoom$: Observable<IMapBaseOptions<number>> = this.setZoomSub.asObservable();

  /** Изменение настроек Pan карты */
  private setPanSub: Subject<IMapBaseOptions<IMapBasePanOptions>> = new Subject<IMapBaseOptions<IMapBasePanOptions>>();
  public setPanOptions$: Observable<IMapBaseOptions<IMapBasePanOptions>> = this.setPanSub.asObservable();

  /** Изменение настроек Pan карты */
  private setFitBoundsOptionsSub: Subject<IMapBaseOptions<IMapBaseFitBoundsOptions>> = new Subject<
    IMapBaseOptions<IMapBaseFitBoundsOptions>
  >();
  public setFitBoundsOptionsSub$: Observable<IMapBaseOptions<IMapBaseFitBoundsOptions>> =
    this.setFitBoundsOptionsSub.asObservable();

  /** Изменение настроек Pan карты */
  private setFitBoundsSub: Subject<IMapBaseOptions<TMapBaseCoordinates>> = new Subject<
    IMapBaseOptions<TMapBaseCoordinates>
  >();
  public setFitBoundsSub$: Observable<IMapBaseOptions<TMapBaseCoordinates>> = this.setFitBoundsSub.asObservable();

  /** Изменение настроек Pan карты */
  private flyToBoundsSub: Subject<IMapBaseOptions> = new Subject<IMapBaseOptions>();
  public flyToBoundsSub$: Observable<IMapBaseOptions> = this.flyToBoundsSub.asObservable();

  /** Изменение настроек Pan карты */
  private setCenterSub: Subject<IMapBaseOptions<MapBaseCoordinatesType>> = new Subject<
    IMapBaseOptions<MapBaseCoordinatesType>
  >();
  public setCenterSub$: Observable<IMapBaseOptions<MapBaseCoordinatesType>> = this.setCenterSub.asObservable();

  /** Изменение настроек MaxZoom карты */
  private setMaxZoomSub: Subject<IMapBaseOptions<number>> = new Subject<IMapBaseOptions<number>>();
  public setMaxZoomSub$: Observable<IMapBaseOptions<number>> = this.setMaxZoomSub.asObservable();

  /** Изменение настроек MaxZoom карты */
  private setMinZoomSub: Subject<IMapBaseOptions<number>> = new Subject<IMapBaseOptions<number>>();
  public setMinZoomSub$: Observable<IMapBaseOptions<number>> = this.setMinZoomSub.asObservable();

  /** Изменение настроек MaxZoom карты */
  private setTileSourceSub: Subject<IMapBaseOptions<MapBaseTileSourceOptions>> = new Subject<
    IMapBaseOptions<MapBaseTileSourceOptions>
  >();
  public setTileSourceSub$: Observable<IMapBaseOptions<MapBaseTileSourceOptions>> =
    this.setTileSourceSub.asObservable();

  /** Изменение настроек MaxZoom карты */
  private setViewSub: Subject<IMapBaseOptions<IBaseSetViewOptions>> = new Subject<
    IMapBaseOptions<IBaseSetViewOptions>
  >();
  public setViewSub$: Observable<IMapBaseOptions<IBaseSetViewOptions>> = this.setViewSub.asObservable();

  /** Изменение настроек MaxZoom карты */
  private setBackViewSub: Subject<IMapBaseOptions> = new Subject<IMapBaseOptions>();
  public setBackViewSub$: Observable<IMapBaseOptions> = this.setBackViewSub.asObservable();

  /** Добавление линии на карту */
  private addPolylineSub: Subject<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBasePolylineOptions>>> = new Subject<
    IMapBaseOptions<IMapBaseAddObjectOptions<IMapBasePolylineOptions>>
  >();
  public addPolylineSub$: Observable<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBasePolylineOptions>>> =
    this.addPolylineSub.asObservable();

  /** Добавление полигона на карту */
  private addPolygonSub: Subject<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBasePolygonOptions>>> = new Subject<
    IMapBaseOptions<IMapBaseAddObjectOptions<IMapBasePolygonOptions>>
  >();
  public addPolygonSub$: Observable<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBasePolygonOptions>>> =
    this.addPolygonSub.asObservable();

  /** Добавление треугольника на карту */
  private addRectangleSub: Subject<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseRectangleOptions>>> = new Subject<
    IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseRectangleOptions>>
  >();
  public addRectangleSub$: Observable<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseRectangleOptions>>> =
    this.addRectangleSub.asObservable();

  /** Добавление круга на карту */
  private addCircleSub: Subject<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseCircleOptions>>> = new Subject<
    IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseCircleOptions>>
  >();
  public addCircleSub$: Observable<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseCircleOptions>>> =
    this.addCircleSub.asObservable();

  /** Добавление сектора на карту */
  private addSemiCircleSub: Subject<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseSemiCircleOptions>>> = new Subject<
    IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseSemiCircleOptions>>
  >();
  public addSemiCircleSub$: Observable<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseSemiCircleOptions>>> =
    this.addSemiCircleSub.asObservable();

  /** Добавление маркера на карту */
  private addMarkerSub: Subject<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseMarkerOptions>>> = new Subject<
    IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseMarkerOptions>>
  >();
  public addMarkerSub$: Observable<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseMarkerOptions>>> =
    this.addMarkerSub.asObservable();

  /** Добавление маркера на карту */
  private addMarkersSub: Subject<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseMarkerOptions>[]>> = new Subject<
    IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseMarkerOptions>[]>
  >();
  public addMarkersSub$: Observable<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseMarkerOptions>[]>> =
    this.addMarkersSub.asObservable();

  /** Добавляем слой на карте */
  private addLayerSub: Subject<IMapBaseOptions<MapLayer>> = new Subject<IMapBaseOptions<MapLayer>>();
  public readonly addLayerSub$: Observable<IMapBaseOptions<MapLayer>> = this.addLayerSub.asObservable();

  /** Отображаем/Скрываем слой на карте */
  private showLayerSub: Subject<IMapBaseOptions<IMapBaseShowLayerOptions>> = new Subject<
    IMapBaseOptions<IMapBaseShowLayerOptions>
  >();
  public readonly showLayerSub$: Observable<IMapBaseOptions<IMapBaseShowLayerOptions>> =
    this.showLayerSub.asObservable();

  /** Удаляем слой на карте */
  private removeLayerSub: Subject<IMapBaseOptions<IMapBaseLayerOptions>> = new Subject<
    IMapBaseOptions<IMapBaseLayerOptions>
  >();
  public readonly removeLayerSub$: Observable<IMapBaseOptions<IMapBaseLayerOptions>> =
    this.removeLayerSub.asObservable();

  /** Отображаем/Скрываем объект на карте */
  private showObjectSub: Subject<IMapBaseOptions<IMapBaseShowObjectOptions>> = new Subject<
    IMapBaseOptions<IMapBaseShowObjectOptions>
  >();
  public readonly showObjectSub$: Observable<IMapBaseOptions<IMapBaseShowObjectOptions>> =
    this.showObjectSub.asObservable();

  /** Удаляем объект на карте */
  private removeObjectSub: Subject<IMapBaseOptions<IMapBaseRemoveObjectOptions>> = new Subject<
    IMapBaseOptions<IMapBaseRemoveObjectOptions>
  >();
  public readonly removeObjectSub$: Observable<IMapBaseOptions<IMapBaseRemoveObjectOptions>> =
    this.removeObjectSub.asObservable();

  /**
   * Удаляем объекты на карте, отличные от указанных
   * Метод предназначен для "плавного" удаления элементов при обновлении списка
   */
  private removeDifferentObjectsSub: Subject<IMapBaseOptions<IMapBaseRemoveDifferentObjectsOptions>> = new Subject<
    IMapBaseOptions<IMapBaseRemoveDifferentObjectsOptions>
  >();
  public readonly removeDifferentObjectsSub$: Observable<IMapBaseOptions<IMapBaseRemoveDifferentObjectsOptions>> =
    this.removeDifferentObjectsSub.asObservable();

  /** Подписка на события от объекта на карте */
  private objectEvents: Subject<IMapBaseObjectBaseEvent> = new Subject<IMapBaseObjectBaseEvent>();
  public objectEvents$: Observable<IMapBaseObjectBaseEvent> = this.objectEvents.asObservable();

  /** Подписка на события от объекта на карте */
  private setPositionOnCoordinateSub: Subject<Required<IMapBaseOptions<IMapBasePositionOnCoordinateOptions>>> =
    new Subject<Required<IMapBaseOptions<IMapBasePositionOnCoordinateOptions>>>();
  public setPositionOnCoordinateSub$: Observable<Required<IMapBaseOptions<IMapBasePositionOnCoordinateOptions>>> =
    this.setPositionOnCoordinateSub.asObservable();

  /** Выбираем объект на карте */
  private selectObjectSub: Subject<IMapBaseOptions<IMapBaseSelectObjectOptions>> = new Subject<
    IMapBaseOptions<IMapBaseSelectObjectOptions>
  >();
  public readonly selectObjectSub$: Observable<IMapBaseOptions<IMapBaseSelectObjectOptions>> =
    this.selectObjectSub.asObservable();

  /** Удаляем объект на карте */
  private deselectObjectSub: Subject<IMapBaseOptions<IMapBaseSelectObjectOptions>> = new Subject<
    IMapBaseOptions<IMapBaseSelectObjectOptions>
  >();
  public readonly deselectObjectSub$: Observable<IMapBaseOptions<IMapBaseSelectObjectOptions>> =
    this.deselectObjectSub.asObservable();

  /** Сбрасываем выбор объекта на карте без отправки события*/
  private resetActiveObjectSub: Subject<IMapBaseOptions<IMapBaseSelectObjectOptions>> = new Subject<
    IMapBaseOptions<IMapBaseSelectObjectOptions>
  >();
  public readonly resetActiveObjectSub$: Observable<IMapBaseOptions<IMapBaseSelectObjectOptions>> =
    this.resetActiveObjectSub.asObservable();

  /** Добавление geoJSON на карту */
  private addGeoJSONSub: Subject<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseGeoJSONOptions>>> = new Subject<
    IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseGeoJSONOptions>>
  >();
  public addGeoJSONSub$: Observable<IMapBaseOptions<IMapBaseAddObjectOptions<IMapBaseGeoJSONOptions>>> =
    this.addGeoJSONSub.asObservable();

  constructor(private readonly mapObjectsQuery: MapObjectsQuery) {}
  /**
   * Возвращает Observable события карты
   * @returns Observable объект
   */
  public getObservableMapEvents(mapId: string, type?: string): Observable<IMapBaseEvent> {
    return this.mapBaseServiceSubjectMapEvent$.pipe(
      filter((event: IMapBaseEvent): boolean => event.mapId === mapId && (type ? event.type === type : true)),
    );
  }

  /**
   * Возвращает Observable событий объектов на карте
   * @param mapId Идентификатор карты
   * @param eventType Тип события
   * @returns Observable объект
   */
  public getObservableObjectEvent<T = IMapBaseObjectBaseEvent>(
    mapId: string,
    eventType?: string,
    layerId?: string,
    objectId?: string,
  ): Observable<T> {
    return this.objectEvents$.pipe(
      filter((event: IMapBaseObjectBaseEvent): boolean => {
        return (
          event.mapId === mapId &&
          (eventType ? event.type === eventType : true) &&
          (layerId ? event.layerId === layerId : true) &&
          (objectId ? event.objectId === objectId : true)
        );
      }),
      map((event: IMapBaseObjectBaseEvent) => event as unknown as T),
    );
  }

  /**
   * Возвращает Observable событий объектов на карте
   * @param event Событие объекта карты
   */
  public sendObjectEvent(event: IMapBaseObjectBaseEvent): void {
    this.objectEvents.next(event);
  }

  /**
   * Возврат событий объектов на карте через механизм Observable.
   * @param event событие MapBaseClickEvent.
   */
  public eventObject(event: IMapBaseObjectEvent): void {
    this.mapBaseServiceSubjectEventObject.next(event);
  }

  /**
   * Возврат Observable событий объектов на карте.
   */
  public getObservableSubjectEvent(): Observable<IMapBaseObjectEvent> {
    return this.mapBaseServiceSubjectEventObject$;
  }

  /**
   * Возврат события карты.
   */
  public mapEvent(event: IMapBaseEvent): void {
    this.mapBaseServiceSubjectMapEvent.next(event);
  }

  // TODO: Доделать для разного размера иконки внутри ClickMarker
  /**
   * Возвращает иконку метка для карты и svg в центре метке
   * @param clickMarker Картинка маркера с опциями.
   * @param iconOptions Параметры отрисовки иконки.
   * @param changeAnchor Изменить anchor для mapbox.
   */
  public makeClickMarker(
    clickMarker: IMapObjectIconOptions,
    iconOptions?: IMapObjectIconOptions,
    changeAnchor?: boolean,
  ): IMapBaseIcon {
    const canvas = document.createElement('canvas');
    canvas.width = clickMarker.shapeImgSize?.[0];
    canvas.height = clickMarker.shapeImgSize?.[1];
    const ctx = canvas.getContext('2d')!;
    ctx.drawImage(clickMarker?.shapeImg, 0, 0);

    /** если необходимо внутри маркера отрисовать иконку */
    if (iconOptions && iconOptions.iconSVG && iconOptions.iconSVG.length !== 0) {
      const sizeSVG = MapUtils.extractSizeFromSvg(iconOptions.iconSVG);
      const pathsSVG = MapUtils.extractPathsFromSvg(iconOptions.iconSVG);

      ctx.beginPath();
      ctx.fillStyle = clickMarker.iconColor || iconOptions.iconColor!;
      const path = new Path2D();
      pathsSVG.forEach((icon) => {
        path.addPath(new Path2D(icon));
      });
      const size = (canvas.width - sizeSVG[0]) / 2;
      ctx.translate(size, size - 2);
      ctx.fill(path);
    }

    const image = canvas.toDataURL('image/png');
    let iconAnchor = clickMarker.shapeAnchor || [canvas.width / 2, canvas.height - 10];
    if (changeAnchor) {
      iconAnchor = [0, -iconAnchor[1]];
    }
    return {
      iconAnchor,
      iconUrl: image,
      // iconImage: ctx.getImageData(0, 0, canvas.width, canvas.height),
    };
  }

  /** Устанавливаем/Меняем настройку зума карты */
  public setZoomOptions(mapId: string, options: IMapBaseZoomOptions): void {
    this.setZoomOptionsSub.next({
      mapId,
      options,
    });
  }

  /** Устанавливаем/Меняем зум карты */
  public setZoom(mapId: string, zoom: number): void {
    this.setZoomSub.next({
      mapId,
      options: zoom,
    });
  }

  /** Устанавливаем/Меняем настройку Pan карты */
  public setPan(mapId: string, options: IMapBasePanOptions): void {
    this.setPanSub.next({
      mapId,
      options,
    });
  }

  /** Устанавливаем/Меняем настройку Pan карты */
  public setFitBoundsOptions(mapId: string, options: IMapBaseFitBoundsOptions): void {
    this.setFitBoundsOptionsSub.next({
      mapId,
      options,
    });
  }

  /** Устанавливаем/Меняем настройку Center карты */
  public setCenter(mapId: string, options: MapBaseCoordinatesType): void {
    this.setCenterSub.next({
      mapId,
      options,
    });
  }

  /** Устанавливаем/Меняем настройку MaxZoom карты */
  public setMaxZoom(mapId: string, options: number): void {
    this.setMaxZoomSub.next({
      mapId,
      options,
    });
  }

  /** Устанавливаем/Меняем настройку MinZoom карты */
  public setMinZoom(mapId: string, options: number): void {
    this.setMinZoomSub.next({
      mapId,
      options,
    });
  }

  /** Устанавливаем/Меняем настройку MinZoom карты */
  public setTileSource(mapId: string, options: MapBaseTileSourceOptions): void {
    this.setTileSourceSub.next({
      mapId,
      options,
    });
  }

  /** Устанавливаем/Меняем настройку MinZoom карты */
  public setView(mapId: string, options: IBaseSetViewOptions): void {
    this.setViewSub.next({
      mapId,
      options,
    });
  }

  /** Устанавливаем/Меняем настройку MinZoom карты */
  public setBackView(mapId: string): void {
    this.setBackViewSub.next({
      mapId,
    });
  }

  /** Устанавливаем/Меняем настройку MinZoom карты */
  public setFitBounds(mapId: string, options: TMapBaseCoordinates): void {
    this.setFitBoundsSub.next({
      mapId,
      options,
    });
  }

  /** Устанавливаем/Меняем настройку MinZoom карты */
  public flyToBounds(mapId: string): void {
    this.flyToBoundsSub.next({
      mapId,
    });
  }

  /** Позиционируем карту на координатах */
  public setPositionOnCoordinate(mapId: string, options: IMapBasePositionOnCoordinateOptions): void {
    this.setPositionOnCoordinateSub.next({ mapId, options });
  }

  /** Добавляем линию на карту */
  public addPolyline(mapId: string, options: IMapBaseAddObjectOptions<IMapBasePolylineOptions>): void {
    this.addPolylineSub.next({
      mapId,
      options,
    });
  }

  /** Добавляем полигон на карту */
  public addPolygon(mapId: string, options: IMapBaseAddObjectOptions<IMapBasePolygonOptions>): void {
    this.addPolygonSub.next({
      mapId,
      options,
    });
  }

  /** Добавляем треугольник на карту */
  public addRectangle(mapId: string, options: IMapBaseAddObjectOptions<IMapBaseRectangleOptions>): void {
    this.addRectangleSub.next({
      mapId,
      options,
    });
  }

  /** Добавляем круг на карту */
  public addCircle(mapId: string, options: IMapBaseAddObjectOptions<IMapBaseCircleOptions>): void {
    this.addCircleSub.next({
      mapId,
      options,
    });
  }

  /** Добавляем круг на карту */
  public addSemiCircle(mapId: string, options: IMapBaseAddObjectOptions<IMapBaseSemiCircleOptions>): void {
    this.addSemiCircleSub.next({
      mapId,
      options,
    });
  }

  /** Добавляем маркер на карту */
  public addMarker(mapId: string, options: IMapBaseAddObjectOptions<IMapBaseMarkerOptions>): void {
    this.addMarkerSub.next({
      mapId,
      options,
    });
  }

  /** Добавляем маркер на карту */
  public addMarkers(mapId: string, options: IMapBaseAddObjectOptions<IMapBaseMarkerOptions>[]): void {
    const activeObjectId: string = this.mapObjectsQuery.getActiveId();
    // Не добавляем маркер текущего объекта на карте, чтобы не перерисовывалась его иконка
    const filteredOptions: IMapBaseAddObjectOptions<IMapBaseMarkerOptions>[] = options.filter(
      (option: IMapBaseAddObjectOptions<IMapBaseMarkerOptions>) => option.objectId !== activeObjectId,
    );
    this.addMarkersSub.next({
      mapId,
      options: filteredOptions,
    });
  }

  /** Добавляем слой на карте */
  public addLayer(mapId: string, options: MapLayer): void {
    this.addLayerSub.next({
      mapId,
      options,
    });
  }

  /** Отображаем слой на карте */
  public showLayer(mapId: string, options: IMapBaseShowLayerOptions): void {
    this.showLayerSub.next({
      mapId,
      options,
    });
  }

  /** Удаляем слой на карте */
  public removeLayer(mapId: string, id: string): void {
    this.removeLayerSub.next({
      mapId,
      options: {
        id,
      },
    });
  }

  /** Отображаем слой на карте */
  public showObject(mapId: string, options: IMapBaseShowObjectOptions): void {
    this.showObjectSub.next({
      mapId,
      options,
    });
  }

  /** Удаляем объект на карте */
  public removeObject(mapId: string, layerId: string, id?: string | string[]): void {
    this.removeObjectSub.next({
      mapId,
      options: <IMapBaseRemoveObjectOptions>{
        layerId,
        id,
      },
    });
  }

  /** Удаляем объект на карте */
  public removeDifferentObjects(mapId: string, layerId: string, ids: string[]): void {
    this.removeDifferentObjectsSub.next({
      mapId,
      options: <IMapBaseRemoveDifferentObjectsOptions>{
        layerId,
        ids,
      },
    });
  }

  /** Выбор элемента на карте */
  public selectObjectOnMap(mapId: string, objectId: string, layerId: string = undefined): void {
    this.selectObjectSub.next({
      mapId,
      options: <IMapBaseSelectObjectOptions>{
        layerId,
        id: objectId,
      },
    });
  }

  /** Сброс состояния выбранного элемента на карте */
  public deselectObjectOnMap(mapId: string, objectId: string, layerId: string = undefined): void {
    this.selectObjectSub.next({
      mapId,
      options: <IMapBaseSelectObjectOptions>{
        layerId,
        id: objectId,
      },
    });
  }

  /** Сброс состояния выбранного элемента на карте */
  public resetActiveObjectOnMap(mapId: string, objectId: string, layerId: string = undefined): void {
    this.resetActiveObjectSub.next({
      mapId,
      options: <IMapBaseSelectObjectOptions>{
        layerId,
        id: objectId,
      },
    });
  }

  /** Добавляем geoJSON на карту */
  public addGeoJSON(mapId: string, options: IMapBaseAddObjectOptions<IMapBaseGeoJSONOptions>): void {
    this.addGeoJSONSub.next({
      mapId,
      options,
    });
  }

  /**
   * Создание иконки для маркера
   */
  private createClickMarkerIcon(): HTMLImageElement {
    const canvas = document.createElement('canvas');
    canvas.width = 52;
    canvas.height = 76;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
    };
    img.src = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(CLICK_MAKER_SHAPE_SVG)}`;
    return img;
  }

  /**
   * Возвращает иконку из подложки (круг, прямоугольник, ромб) и svg в центре подложки
   * @param iconOptions Параметры отрисовки иконки.
   */
  public makeIcon(iconOptions: IMapObjectIconOptions): IMapBaseIcon | undefined {
    if (!iconOptions.iconSVG || iconOptions.iconSVG.length === 0) {
      return;
    }
    let iconShapeSize = iconOptions.shapeSize!;
    if (iconOptions.shapeType === 'Прямоугольник') {
      iconShapeSize -= 8;
    }
    const sizeSVG = MapUtils.extractSizeFromSvg(iconOptions.iconSVG);
    const pathsSVG = MapUtils.extractPathsFromSvg(iconOptions.iconSVG);
    const { iconSize = 0 } = iconOptions;
    const scale = [iconSize / sizeSVG[0], iconSize / sizeSVG[1]];

    const trans = [(iconShapeSize - iconOptions.iconSize!) / 2, (iconShapeSize - iconOptions.iconSize!) / 2];

    const canvas = document.createElement('canvas');
    canvas.width = iconShapeSize;
    canvas.height = iconShapeSize;
    const ctx = canvas.getContext('2d')!;

    this.makeMarkerShape(canvas, ctx, iconOptions);

    ctx.beginPath();
    ctx.fillStyle = iconOptions.iconColor!;
    const path = new Path2D();
    pathsSVG.forEach((icon) => {
      path.addPath(new Path2D(icon));
    });
    if (iconOptions.shapeType === 'Треугольник') {
      ctx.translate(trans[0]!, trans[1]! * 1.3);
    } else {
      ctx.translate(trans[0]!, trans[1]!);
    }
    ctx.scale(scale[0]!, scale[1]!);
    ctx.fill(path);

    const url = canvas.toDataURL('image/png');
    const value = <IMapBaseIcon>{
      iconAnchor: [canvas.width / 2, canvas.height / 2],
      iconSize: [canvas.width, canvas.height],
      iconUrl: url as string,
      iconImage: ctx.getImageData(0, 0, canvas.width, canvas.height),
    };

    URL.revokeObjectURL(value.iconUrl);

    return value;
  }

  /**
   * Формирует подложку иконки (круг, прямоугольник, ромб, треугольник, без подложки)
   * @param canvas HTML элемент canvas.
   * @param ctx canvas 2d.
   * @param iconOptions конфигурация иконки.
   */
  private makeMarkerShape(
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    iconOptions: IMapObjectIconOptions,
  ): void {
    let radius = iconOptions.shapeLineRadius || 10;
    const width = canvas.width;
    const height = canvas.height;
    const lineWidth = iconOptions.strokeWidth || 0;

    switch (iconOptions.shapeType) {
      case 'Круг':
        radius = iconOptions.strokeWidth || 0;
        ctx.beginPath();
        ctx.fillStyle = iconOptions.shapeColor!;
        ctx.strokeStyle = iconOptions.strokeColor || iconOptions.shapeColor!;
        ctx.lineWidth = lineWidth;
        ctx.arc(width / 2, height / 2, height / 3 - lineWidth, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.fill();
        break;
      case 'Ромб':
        radius = iconOptions.shapeLineRadius || 3;
        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = iconOptions.shapeColor!;
        ctx.strokeStyle = iconOptions.strokeColor || iconOptions.shapeColor!;
        ctx.lineWidth = iconOptions.strokeWidth || 0;
        ctx.moveTo(width / 2 + radius, lineWidth + radius);
        ctx.lineTo(width - lineWidth - radius, height / 2 - radius);
        ctx.quadraticCurveTo(width - lineWidth, height / 2, width - radius - lineWidth, height / 2 + radius);
        ctx.lineTo(width / 2 + radius, height - lineWidth - radius);
        ctx.quadraticCurveTo(width / 2, height - lineWidth, width / 2 - radius, height - radius - lineWidth);
        ctx.lineTo(lineWidth + radius, height / 2 + radius);
        ctx.quadraticCurveTo(lineWidth, height / 2, lineWidth + radius, height / 2 - radius);
        ctx.lineTo(width / 2 - radius, lineWidth + radius);
        ctx.quadraticCurveTo(width / 2, lineWidth, width / 2 + radius, lineWidth + radius);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        ctx.restore();
        break;
      case 'Прямоугольник':
        radius = iconOptions.shapeLineRadius || 8;
        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = iconOptions.shapeColor!;
        ctx.strokeStyle = iconOptions.strokeColor || iconOptions.shapeColor!;
        ctx.lineWidth = iconOptions.strokeWidth || 0;
        ctx.moveTo(radius + lineWidth, lineWidth as number);
        ctx.lineTo(width - radius - lineWidth, lineWidth as number);
        ctx.quadraticCurveTo(width - lineWidth, lineWidth, width - lineWidth, radius + lineWidth);
        ctx.lineTo(width - lineWidth, height - radius - lineWidth);
        ctx.quadraticCurveTo(width - lineWidth, height - lineWidth, width - radius - lineWidth, height - lineWidth);
        ctx.lineTo(radius + lineWidth, height - lineWidth);
        ctx.quadraticCurveTo(lineWidth, height - lineWidth, lineWidth, height - radius - lineWidth);
        ctx.lineTo(lineWidth, radius + lineWidth);
        ctx.quadraticCurveTo(lineWidth, lineWidth, radius + lineWidth, lineWidth as number);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        ctx.restore();
        break;
      case 'Треугольник': {
        radius = iconOptions.shapeLineRadius || 3;
        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = iconOptions.shapeColor!;
        ctx.strokeStyle = iconOptions.strokeColor || iconOptions.shapeColor!;
        ctx.lineWidth = iconOptions.strokeWidth || 0;
        // const radius2 = Math.cbrt((radius * radius) / 2);
        const radius2 = radius / 1.5;
        ctx.moveTo(width / 2 + radius2, lineWidth + radius2);
        ctx.lineTo(width - lineWidth, height - radius - lineWidth);
        ctx.quadraticCurveTo(width - lineWidth, height - lineWidth, width - radius - lineWidth, height - lineWidth);
        ctx.lineTo(radius + lineWidth, height - lineWidth);
        ctx.quadraticCurveTo(lineWidth, height - lineWidth, lineWidth, height - radius - lineWidth);
        ctx.lineTo(width / 2 - radius2, lineWidth + radius2);
        ctx.quadraticCurveTo(width / 2, lineWidth, width / 2 + radius2, lineWidth + radius2);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        ctx.restore();
        break;
      }
      default:
        break;
    }
  }
}
