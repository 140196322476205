/**
 * Состояние телефона
 */
export enum PhoneStateEnum {
  /** Вызов */
  ring = 'ring',
  /** Снятие трубки, при входящем звонке */
  pickupIn = 'pickup',
  /** Снятие трубки, при исходящем звонке */
  pickupOut = 'pickup-out',
  /** Завершение входящего вызова */
  hangupIn = 'hangup',
  /** Завершение исходящего вызова */
  hangupOut = 'hangup-out',
  /** Уведомление */
  notification = 'notification',
  /** Мониторинг */
  monitoring = 'monitoring',
}
