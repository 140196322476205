import { Injectable } from '@angular/core';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { IAnyObject } from 'smart-city-types';
import { Settings2Service } from '@smart-city/core/services';

import { FilterOperationEnum } from '../../models/enums';

@Injectable()
export class OperationsService {
  constructor(private readonly settings: Settings2Service) {}

  /** Возвращает список операций для select */
  public operationsToArray(): IScSelectItem[] {
    return [
      {
        id: FilterOperationEnum.equal,
        text: 'Равно',
      },
      {
        id: FilterOperationEnum.notEqual,
        text: 'Не равно',
      },
      {
        id: FilterOperationEnum.greatThen,
        text: 'Больше',
      },
      {
        id: FilterOperationEnum.greatThenOrEqual,
        text: 'Больше или равно',
      },
      {
        id: FilterOperationEnum.lessThen,
        text: 'Меньше',
      },
      {
        id: FilterOperationEnum.lessThenOrEqual,
        text: 'Меньше или равно',
      },
      {
        id: FilterOperationEnum.or,
        text: 'Или',
      },
      {
        id: FilterOperationEnum.in,
        text: 'В',
      },
    ];
  }

  /** Преобразование типа операции и значение в объект для запроса */
  public createValueByOperation(
    property: string,
    value: string,
    operation: FilterOperationEnum = FilterOperationEnum.equal,
  ): IAnyObject {
    const filter = <IAnyObject>{};
    if (property && value) {
      switch (operation) {
        case FilterOperationEnum.equal:
          filter[property] = this.transformValue(value);
          break;
        case FilterOperationEnum.notEqual:
          filter[property] = { $ne: this.transformValue(value) };
          break;
        case FilterOperationEnum.lessThen:
          filter[property] = { $lt: this.transformValue(value) };
          break;
        case FilterOperationEnum.lessThenOrEqual:
          filter[property] = { $lte: this.transformValue(value) };
          break;
        case FilterOperationEnum.greatThen:
          filter[property] = { $gt: this.transformValue(value) };
          break;
        case FilterOperationEnum.greatThenOrEqual:
          filter[property] = { $gte: this.transformValue(value) };
          break;
        case FilterOperationEnum.or:
          filter['$or'] = (value || '').split(',').map((val: string) => {
            const lValue = val.trimLeft().trimRight();
            return this.createValueByOperation(property, this.transformValue(lValue));
          });
      }
    }
    return filter;
  }

  /** Проверка значения согласно операции и условия */
  public checkValueByOperation(
    checkValue: any,
    value: any,
    operation: FilterOperationEnum = FilterOperationEnum.equal,
  ): boolean {
    const filter = <IAnyObject>{};
    if (checkValue !== undefined && value !== undefined) {
      switch (operation) {
        case FilterOperationEnum.equal:
          return checkValue == this.transformValue(value);
        case FilterOperationEnum.notEqual:
          return checkValue != this.transformValue(value);
        case FilterOperationEnum.lessThen:
          return +checkValue < this.transformValue(value);
        case FilterOperationEnum.lessThenOrEqual:
          return +checkValue <= this.transformValue(value);
        case FilterOperationEnum.greatThen:
          return +checkValue > this.transformValue(value);
        case FilterOperationEnum.greatThenOrEqual:
          return +checkValue >= this.transformValue(value);
          break;
        case FilterOperationEnum.or:
          (value as string || '').split(',').every((val: string) => {
            const lValue = val.trimLeft().trimRight();
            return this.createValueByOperation(checkValue, this.transformValue(lValue));
          });
      }
    }
    return false;
  }

  /** Проверка на константы */
  private transformValue(value: string) {
    switch(value) {
      case '__NULL__':
      case 'null':
        return null;
      case '__CURRENT_USER_ID__':
        return this.settings.currentUser.id;
      case 'true':
        return true;
      case 'false':
        return false;
    }

    if(Number.isInteger(value)) {
      return Number.parseInt(value);
    }

    return value;
  }
}
