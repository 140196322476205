import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IGridTableOptions, INwHeaderBarOptions, IScCheckboxOptions, IScInputOptions } from '@smart-city/core/common';
import * as dayjs from 'dayjs';
import { of } from 'rxjs';
import { IAnyObject } from 'smart-city-types';
import {
  IAtmDevice,
  IAtmNodeParameter,
  IAtmNodes
} from '../../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/models/interfaces';
import { BaseComponent } from '@bg-front/core/components';
import { MonitoringObjectHcsService } from '../../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/services';

@Component({
  selector: 'atm-device-view-form',
  templateUrl: './atm-device-view-form.component.html',
  styleUrls: ['./atm-device-view-form.component.scss'],
})
export class AtmDeviceViewFormComponent extends BaseComponent implements OnInit {
  /** Форма */
  public form: FormGroup;

  /** Просматриваемая запись */
  public model: IAtmDevice = undefined;

  /** Узлы учета просматриваемой записи */
  public nodes: IAtmNodes[] = undefined;

  /** Текущий просмотриваемый параметр */
  public currentParameter: IAtmNodeParameter;

  /** Геттер массива формгрупп с привязанными адресами */
  get nodesFormGroups() {
    return <FormGroup[]>(<FormArray>this.form.controls.nodes).controls;
  }

  public gridOptions: IGridTableOptions = {
    height: '500px',
    name: 'nodeParameters',
    // restVersion: 'modern',
    title: 'Параметры',
    loadGridDataFn: () => this.service.getParameters('objectId', 0, 0),
    // service: { name: 'AtmIntegration' },
    // entity: 'MonitoringObject',
    // sort: {
    //   field: 'description',
    //   direction: 'asc',
    // },
    // buildQueryFn: (value: IAnyObject) => this.buildQueryFn(value),
    onSelectRow: (row: IAnyObject) => this.showParameter(row),
    showTotalRecords: true,
    controls: [
      {
        name: 'reload',
        icon: 'refresh',
        hint: 'Обновить',
        callback: () => of({ actionStream: 'reload' }),
      },
      {
        name: 'search',
        icon: 'search',
        hint: 'Поиск',
      },
    ],
    fields: [{ name: 'descr', title: 'Описание' }],
    forms: [
      /*this.searchForm*/
    ],
  };

  /** Настройки заголовка формы просмотра записи */
  public headerActionsOptions: INwHeaderBarOptions;

  /** Настройки заголовка блока Параметр */
  public parameterHeaderOptions: INwHeaderBarOptions = {
    title: 'Параметр',
    name: 'devicesHeader',
    bgColor: 'white',
    margin: 'collapse',
  };

  //#region Настройки компонент
  /** Настройки компоненты Наименование устройства */
  public nameOptions: IScInputOptions = {
    label: 'Наименование устройства',
    placeholder: 'Наименование устройства',
    disabled: true,
  };
  /** Настройки компоненты Внешний идентификатор устройства */
  public idOptions: IScInputOptions = {
    label: 'Внешний идентификатор устройства',
    placeholder: 'Внешний идентификатор устройства',
    disabled: true,
  };
  /** Настройки компоненты Тип устройства */
  public typeOptions: IScInputOptions = {
    label: 'Тип устройства',
    placeholder: 'Тип устройства',
    disabled: true,
  };
  /** Настройки компоненты Серийный номер */
  public serialOptions: IScInputOptions = {
    label: 'Серийный номер',
    placeholder: 'Серийный номер',
    disabled: true,
  };
  /** Настройки компоненты Дата регистрации устройства */
  public dtRegOptions: IScInputOptions = {
    label: 'Дата регистрации устройства',
    placeholder: 'Дата регистрации устройства',
    disabled: true,
  };
  /** Настройки компоненты Дата получения последних данных */
  public lastUpdateOptions: IScInputOptions = {
    label: 'Дата получения последних данных',
    placeholder: 'Дата получения последних данных',
    disabled: true,
  };
  /** Настройки компоненты Наименование узла учета */
  public nodeNameOptions: IScInputOptions = {
    label: 'Наименование узла учета',
    placeholder: 'Наименование узла учета',
    disabled: true,
  };
  /** Настройки компоненты Внешний идентификатор узла учета */
  public nodeIdOptions: IScInputOptions = {
    label: 'Внешний идентификатор узла учета',
    placeholder: 'Внешний идентификатор узла учета',
    disabled: true,
  };
  /** Настройки компоненты Тип ресурса узла учета */
  public nodeResourceTypeOptions: IScInputOptions = {
    label: 'Тип ресурса узла учета',
    placeholder: 'Тип ресурса узла учета',
    disabled: true,
  };
  /** Настройки компоненты Активность узла учета */
  public activeOptions: IScCheckboxOptions = {
    title: 'Активность',
    disabled: true,
  };

  //#endregion

  /** @ignore */
  constructor(
    private readonly router: Router,
    private readonly service: MonitoringObjectHcsService,
    private readonly route: ActivatedRoute,
  ) {
    super();
    this.model = <IAtmDevice>this.router.getCurrentNavigation().extras.state;
  }

  /** @ignore */
  public ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(this.model.name),
      id: new FormControl(this.model.id),
      type: new FormControl(this.model.type),
      serial: new FormControl(this.model.serial),
      dtReg: new FormControl(this.model.dt_reg ? dayjs(this.model.dt_reg).format('DD.MM.YYYY HH:mm:ss') : ''),
      lastUpdate: new FormControl(
        this.model.last_update ? dayjs(this.model.last_update).format('DD.MM.YYYY HH:mm:ss') : '',
      ),
      nodes: new FormArray([]),
    });
    this.headerActionsOptions = {
      title: this.model.name,
      name: 'header',
      bgColor: 'white',
      margin: 'collapse',
      buttons: [
        {
          type: 'button',
          options: {
            name: 'cancel',
            icon: 'clear',
          },
        },
      ],
    };

    this.service.getNodes(this.model.objectId, this.model.id).subscribe((nodes: IAtmNodes[]) => {
      this.nodes = nodes.map((node: IAtmNodes) => {
        if (!node.name) {
          if (node.node_num === 0) node.name = 'Общие параметры';
          if (node.node_num !== 0) node.name = `Узел учета ${node.node_num}`;
        }
        return node;
      });
      if (this.nodes.length === 0) {
        this.nodes = [
          {
            name: 'Общие параметры',
            is_active: 1,
          },
        ];
      }

      this.nodes.forEach((node: IAtmNodes) =>
        (<FormArray>this.form.controls.nodes).push(
          new FormGroup({
            name: new FormControl(node.name),
            id: new FormControl(node.id),
            resourceType: new FormControl(node.resource_type === 'unknown' ? '' : node.resource_type),
            active: new FormControl(!!node.is_active),
          }),
        ),
      );
    });
  }

  /**
   * Обрабатываем нажатие кнопок в заголовке
   */
  public onClickActionsButton() {
    this.router.navigate([{ outlets: { viewForm: null } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }

  private showParameter(row: IAnyObject) {
    this.currentParameter = { descr: row.descr };
  }
}
