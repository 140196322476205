/**
 * Названия слоев на карте.
 *
 * Используется связь со свойство слоя `nameOnMap`
 */
export enum LayersEnum {
  incidents = 'Инциденты',
  orders = 'Поручения',
  videoDevices = 'Видеокамеры',
  camerasMalfunctions = 'Неисправности видеокамер',
  significantObjects = 'Важные объекты',
  kseon = 'КСЭОН',
  eos = 'Организации Экстренного реагирования',
  dds = 'ДДС городских служб и организаций',
  heatSupplyFacilities = 'Объекты теплоснабжения',
  regionControlCenter = 'Инциденты ЦУР',
  electricitySupplyFacility = 'Объекты электроснабжения',
  // TODO: Перенести в отдельный enum для полигонов
  /** Слой лесохозяйственных объектов */
  forestryFacilities = 'forestryFacilities',
  /** Слой МО  */
  municipalities = 'municipalities',
  /** Слой районов */
  districts = 'districts',
  /** Углы обзора камер */
  camerasFov = 'camerasFov',
  /** Слой "Плановые работы" */
  plannedWorks = 'Плановые работы',
  /** Транспортные средства */
  rnis = 'Транспортные средства',
  waterSource = 'Водоисточники',
  fireMonitoringObject = 'Объекты пожарного мониторинга',
  fireRiskObject = 'Пожароопасные объекты',
  ecoMonitoringObject = 'Объекты экомониторинга',
  chemicalMonitoringObjects = 'Объекты мониторинга АХОВ',
  rasco = 'РАСЦО',
  /** Water level control system */
  wlcsMonitoringObjects = 'Объекты мониторинга уровня воды',
  aqi = 'Объекты мониторинга АХОВ',
  /** Маркер объекта при клике */
  clickMarker = 'clickMarker',
  /** Слой "Термоточки" */
  thermopoints = 'Термоточки',
  /** Слой "Видеокамеры лесохранителя" */
  forestGuardVideoDevices = 'Видеокамеры лесохранителя',
}
