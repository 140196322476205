<div class="dialog-container">
    <div class="bg-row">
      <div class="bg-col">
        <div class="bg-form-group">
          <div *ngIf="isHtml">
            <span [innerHTML]="message | safe: 'html'"></span>
          </div>
          <div *ngIf="!isHtml">
            <span>{{message}}</span>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <div class="bg-row">
        <div class="bg-col align-center">
          <button mat-button mat-dialog-close>Закрыть</button>
        </div>
      </div>
    </div>
</div>
