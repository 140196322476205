/**
 * Класс статических функций для грамматических преобразований
 */
export class GrammarHelper {
  /**
   * В зависимости от величины числительного возвращает корректную форму подчиненного слова
   * @param number числительное
   * @param forms массив форм существительного, где
   * form[0] - форма согласованная со значением, которое оканчивается на 1, кроме 11.
   * form[1] - форма согласованная со значением, которое оканчивается на 2, 3 или 4, кроме 12, 13 или 14.
   * form[2] - форма для остальных случаев.
   * @returns Склонение подчиненного слова.
   */
  public static endings(number: number, forms: string[]): string {
    const modulo10 = number % 10;
    const modulo100 = number % 100;
    if (modulo10 === 1 && modulo100 !== 11) {
      return forms[0];
    }
    if (modulo10 >= 2 && modulo10 <= 4 && (modulo100 < 12 || modulo100 > 14)) {
      return forms[1];
    }
    return forms[2];
  }
}
