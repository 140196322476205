import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAnyObject } from 'smart-city-types';
import { IOrganization } from '../../models/interfaces';
import { formatPhone, formatPhoneDb } from '@smart-city/core/utils';
import { CallService } from '../../../call/services';

@Component({
  selector: 'bg-detail-organization-dialog',
  templateUrl: './detail-organization-dialog.component.html',
  styleUrls: ['./detail-organization-dialog.component.scss']
})
export class DetailOrganizationDialogComponent {
  public detailOrganization: IAnyObject = {};
  public isDetailNotEmpty: boolean;
  public isOrganizationSelected: boolean;
  public isOrganizationView: boolean;
  public notViewOrg: IAnyObject = {};
  public switchModal: boolean = false;

  constructor(
    private readonly callService: CallService,
    public readonly dialogRef: MatDialogRef<DetailOrganizationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { organizations: IOrganization[]},
  ) {
    this.switchModal = !this.isOrganizationSelected || !this.isOrganizationView || !this.isDetailNotEmpty;
    this.data.organizations.map((organization: IOrganization) => {
      this.notViewOrg = organization;
      if (this.notViewOrg == undefined) {
        this.isOrganizationView = true;
      }
    })
    /** Если в массиве организаций >= 1 , значит организация не выбрана и организация не на просмотре */
    if (this.data.organizations.length == 1 && this.notViewOrg != undefined) {
      this.isOrganizationSelected = true;
      const orgInfo: IOrganization = this.data.organizations[0];

      this.detailOrganization.fullname = orgInfo.fullname || '';
      this.detailOrganization.address = `${ orgInfo.address?.fullText || ''} ${ orgInfo.placement || ''}`;
      this.detailOrganization.phone = orgInfo.phone1 ? formatPhone(orgInfo.phone1) : '';
      this.detailOrganization.leader = orgInfo.fioLeader || '';
      this.detailOrganization.phoneLeader = orgInfo.phoneLeader ? formatPhone(orgInfo.phoneLeader) : '';
      this.detailOrganization.workingHours = orgInfo.workingHours ? orgInfo.workingHours : '';
      this.detailOrganization.comment = orgInfo.comment ? orgInfo.comment : '';

      /** Флаг, указывающий на отсутствие дополнительной информации */
      this.isDetailNotEmpty = !!(Object.values(this.detailOrganization).filter((org: string) => org).length);
    }
  }

  /** Закрыть диалоговое окно */
  public close(): void {
    this.dialogRef.close();
  }

  /** Совершить вызов по клику на номер телефона и закрыть окно */
  public call(phone: string): void {
    this.callService.call(formatPhoneDb(phone));
    this.close();
  }
}
