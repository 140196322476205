import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BaseComponent } from '@bg-front/core/components';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Uuid } from '@smart-city/core/utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { IAnyObject } from 'smart-city-types';

import { IRegistryPanelDto, IRegistryPanelEntity } from '../../models/interfaces';
import { RegistryPanelService } from '../../services';

/**
 * Компонента реализует редактирование сущности "Реестр"
 */
@UntilDestroy()
@Component({
  selector: 'bg-edit-registry-panel',
  templateUrl: './edit-registry-panel.component.html',
  styleUrls: ['./edit-registry-panel.component.scss'],
})
export class EditRegistryPanelComponent extends BaseComponent implements OnInit {
  /** Модель */
  public model: IRegistryPanelDto = undefined;

  /** Шапка */
  public title = 'Новая запись';

  /** Форма */
  public form: FormGroup = undefined;

  public openDrawer = true;

  /** Подписка на проверку изменений в форме */
  public isDirty$: Observable<boolean> = of(false);

  /** Эталонное значение формы */
  private ngMasterValue: BehaviorSubject<IAnyObject>;

  /** Событие при закрытии формы */
  public closeAction: 'save' | 'delete';

  /** Цвет иконки */
  public iconColor;

  /** Ссылка на массив сущностей */
  public entitiesArray: FormArray;

  /** Список иконок для селекта */
  public icons$ = this.registryPanelService.getIconsForSelect();

  /** @ignore */
  constructor(
    private readonly registryPanelService: RegistryPanelService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly fb: FormBuilder,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.openDrawer = false;
          this.model = undefined;
          this.form = undefined;
          const panelId = params.get('id');

          if (!panelId) {
            return of({});
          }
          // Если ID невалидный не выполнять поиск и передать значение что ничего найдено
          if (!Uuid.isValid(panelId)) {
            return of(undefined);
          }

          return this.registryPanelService.getRegistryPanelById(panelId);
        }),
        untilDestroyed(this),
      )
      .subscribe((registryPanel: IRegistryPanelDto) => {
        this.model = registryPanel;
        if (registryPanel.id) {
          this.title = this.model.name;
          this.iconColor = this.model.iconColor;
        }
        this.model.entities = this.model.entities || [undefined];

        this.form = new FormGroup({
          name: new FormControl(this.model.name, [Validators.required]),
          sysName: new FormControl(this.model.sysName, [Validators.required]),
          claim: new FormControl(this.model.claim, [Validators.required]),
          iconColor: new FormControl(this.model.iconColor, [Validators.required]),
          icon: new FormControl(this.model.icon, [Validators.required]),
          order: new FormControl(this.model.order, [Validators.required]),
          entities: this.fb.array([]),
        });

        this.entitiesArray = this.form.controls.entities as FormArray;

        this.model.entities.forEach((item: IRegistryPanelEntity) => {
          this.entitiesArray.push(new FormControl(item, [Validators.required]));
        });

        this.form.controls.iconColor.valueChanges
          .pipe(untilDestroyed(this))
          .subscribe((color: string) => (this.iconColor = color));

        this.ngMasterValue = new BehaviorSubject<IAnyObject>(this.form.getRawValue());
        this.isDirty$ = dirtyCheck(this.form, this.ngMasterValue.asObservable());

        this.openDrawer = true;
      });
  }

  /** Добавляем новую сущность */
  public addEntity(): void {
    this.entitiesArray.push(new FormControl({}, [Validators.required]));
  }

  /** Удаляем сущность */
  public removeEntity(idx: number): void {
    this.entitiesArray.removeAt(idx);
  }

  /** Собираем модель */
  private getModel(): void {
    this.model.iconColor = this.form.controls.iconColor.value;
    this.model.order = this.form.controls.order.value;
    this.model.claim = this.form.controls.claim.value;
    this.model.sysName = this.form.controls.sysName.value;
    this.model.name = this.form.controls.name.value;
    this.model.icon = this.form.controls.icon.value;
    this.model.entities = this.entitiesArray.value;
  }

  /** Удаление записи из базы */
  public delete(): void {
    this.registryPanelService
      .delete(this.model.id)
      .pipe(
        catchError((err: Error) => this.catchErrorFn(err, 'Ошибка удаления записи')),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.closeAction = 'delete';
        this.close();
      });
  }

  /** Сохранение записи */
  public save(): void {
    if (this.form.valid) {
      this.getModel();
      this.registryPanelService
        .save(this.model)
        .pipe(
          catchError((error: Error) => this.catchErrorFn<string>(error, 'Ошибка при сохранении')),
          untilDestroyed(this),
        )
        .subscribe(() => {
          this.isDirty$ = of(false);
          this.closeAction = 'save';
          this.close();
        });
    }
  }

  /** Закрыть форму */
  public close() {
    this.router.navigate(['../..'], {
      relativeTo: this.route,
    });
  }
}
