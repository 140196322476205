import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MonitoringObjectBaseEditFormComponent } from '../monitoring-object-base-edit-form/monitoring-object-base-edit-form.component';
import { IElementButton, IScInputOptions, IScSelectOptions, IScTextareaOptions } from '@smart-city/core/common';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { FormControl, Validators } from '@angular/forms';
import { IAbstractServiceData } from 'smart-city-types';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { untilDestroyed } from '@ngneat/until-destroy';
import { coordinatesValidator } from '@bg-front/core/validators';
import { IMonitoringObjectHcsDto } from '../../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/models/interfaces';

@Component({
  selector: 'bg-monitoring-object-custom-edit-form',
  templateUrl: './monitoring-object-custom-edit-form.component.html',
  styleUrls: ['./monitoring-object-custom-edit-form.component.scss'],
})
export class MonitoringObjectCustomEditFormComponent
  extends MonitoringObjectBaseEditFormComponent
  implements AfterViewInit, OnInit
{
  /** Редактируемая запись */
  public model: IMonitoringObjectHcsDto;

  /** Настройки компоненты Наименование */
  public nameOptions: IScInputOptions = {
    label: 'Наименование',
    placeholder: 'Наименование *',
    maxLength: 300,
    required: true,
  };

  /** Настройки компоненты Наименование */
  public addressOptions: IScInputOptions = {
    label: 'Адрес',
    placeholder: 'Адрес',
    maxLength: 300,
  };

  /** Настройки компоненты Состояние */
  public statusIdOptions: IScSelectOptions = {
    title: 'Состояние',
    required: true,
    data: this.settings.getDictForSelect('hcsMonitoringObjectStatuses'),
  };

  /** Настройки компоненты Вид снабжения */
  public supplyTypeIdOptions: IScSelectOptions = {
    title: 'Вид снабжения',
    required: true,
    data: this.settings.getDictForSelect('hcsSupplyTypes'),
  };

  /** настройки компоненты Действующий статус */
  public objectStatusOptions: IScSelectOptions = {
    title: 'Действующий статус',
    required: true,
    data: null,
  };

  /** Настройка компоненты Описание */
  public descriptionOptions: IScTextareaOptions = {
    label: 'Описание',
    maxLength: 500,
    rows: 6,
  };

  /** ID типа Водоотведения */
  private wasteWaterDisposalId: string = this.settings.getDictionaryByTypeAndSysName(
    'hcsSupplyTypes',
    'wasteWaterDisposal',
  )?.id;

  /** ID типа Водоснабжения */
  private waterSuplyId: string = this.settings.getDictionaryByTypeAndSysName('hcsSupplyTypes', 'waterSupply')?.id;

  /** ID опции "Выведен из экспулатации" */
  private outOfServiceId: string = this.settings.getDictionaryByTypeAndSysName('hcsObjectStatus', 'outOfService')?.id;

  /** ID опции "В эксплуатации" */
  private exploitationId: string = this.settings.getDictionaryByTypeAndSysName('hcsObjectStatus', 'exploitation')?.id;

  /** ID опции "В резерве" */
  private reserveId: string = this.settings.getDictionaryByTypeAndSysName('hcsObjectStatus', 'Reserve')?.id;

  /** @ignore */
  public override ngOnInit(): void {
    super.ngOnInit();

    if (this.model.id) {
      this.updateObjectStatusData(this.model.supplyTypeId as string);
      this.headerActionsOptions.buttons.unshift({
        type: 'button',
        options: {
          title: 'Удалить',
          name: 'delete',
          icon: 'delete_outline',
          hidden: this.isDisabled,
        },
      });
    }

    this.form.addControl('statusId', new FormControl(this.model.statusId));
    this.form.addControl('supplyTypeId', new FormControl(this.model.supplyTypeId));
    this.form.addControl('hcsObjectStatus', new FormControl(this.model.hcsObjectStatus));
    this.form.addControl('description', new FormControl(this.model.description));

    this.coordinatesOptions.disabled = false;

    this.form.controls.name.setValidators(Validators.required);
    this.form.controls.coordinates.setValidators([
      Validators.maxLength(100),
      Validators.pattern(/(-?\d{1,2}[.]\d+)[,][ ]+(-?\d{1,3}[.]\d+)/),
      coordinatesValidator(),
    ]);
    this.form.controls.statusId.setValidators(Validators.required);
    this.form.controls.supplyTypeId.setValidators(Validators.required);
    this.form.controls.hcsObjectStatus.setValidators(Validators.required);

    if (this.model.supplyTypeId) {
      this.hcsObjectKindIdOptions.query = {
        supplyTypeId: this.model.supplyTypeId,
      };
    } else {
      this.form.controls.hcsObjectKindId.disable();
      this.hcsObjectKindIdOptions.disabled = true;
    }
  }

  /** @ignore */
  public ngAfterViewInit() {
    super.ngAfterViewInit();

    setTimeout(() => {
      // Подписка на изменения вида снабжения ЖКХ для активации селекта вида объекта ЖКХ
      this.form.controls.supplyTypeId.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
        if (value) {
          this.form.controls.hcsObjectKindId.setValue(null);
          this.form.controls.hcsObjectKindId.enable({ emitEvent: false });
          this.form.controls.hcsObjectKindId.markAsUntouched();
          this.hcsObjectKindIdOptions.disabled = false;
          this.hcsObjectKindIdOptions.query = { supplyTypeId: value };
          this.updateObjectStatusData(value);
        } else {
          this.form.controls.hcsObjectKindId.disable();
          this.hcsObjectKindIdOptions.disabled = true;
          this.form.controls.hcsObjectKindId.setValue(null);
          this.hcsObjectKindIdOptions.query = {};
        }
      });
    }, 1000);
  }

  /**
   * Обрабатываем нажатие кнопок в заголовке
   * @param $event - информация о нажатой кнопке
   */
  public onClickActionsButton($event: IElementButton) {
    if ($event.options.name === 'delete') {
      this.dialog.open({
        title: 'Внимание!',
        text: ['Вы действительно хотите удалить запись?'],
        buttons: [
          {
            name: 'yes',
            title: 'Да',
            type: 'mat-flat-button',
            color: 'warn',
            callback: (): Observable<IAbstractServiceData> => {
              return this.service.delete(this.model.id).pipe(
                catchError(() => {
                  this.note.pushInfo('Невозможно удалить запись, которая используется в системе.');
                  return of(undefined);
                }),
                tap(() => {
                  this.router.navigate(['dictionaries/monitoring-objects-hcs'], {
                    state: { refreshGrid: true },
                  });
                }),
              );
            },
          },
          {
            name: 'no',
            title: 'Отмена',
            type: 'mat-flat-button',
            color: 'primary',
            callback: () => {
              return of(undefined);
            },
          },
        ],
      });
      return;
    }
    super.onClickActionsButton($event);

    if (this.form.invalid || $event.options.name === 'cancel') return;

    this.service.save(this.model).subscribe((data: IAbstractServiceData) => {
      if (data) {
        this.router.navigate(['dictionaries/monitoring-objects-hcs'], {
          state: { refreshGrid: true },
        });
      }
    });
  }

  /**
   * Обновляем опции селекта Действующий статус объекта
   * в зависимости от значения селекта Вид снабжения
   * */
  private updateObjectStatusData(value: string): void {
    let selectOptions = this.settings.getDictForSelect('hcsObjectStatus');
    if (this.form.controls?.hcsObjectStatus?.value) {
      this.form.controls.hcsObjectStatus.reset();
    }
    if (value === this.wasteWaterDisposalId || value === this.waterSuplyId) {
      selectOptions = selectOptions.filter(
        (objectStausType: IScSelectItem) => objectStausType.id !== this.outOfServiceId,
      );
    } else {
      selectOptions = selectOptions.filter((objectStausType: IScSelectItem) =>
        [this.outOfServiceId, this.exploitationId, this.reserveId].includes(objectStausType.id),
      );
    }
    this.objectStatusOptions.data = selectOptions;
  }
}
