<h3 class="main-header">
  <mat-icon class="header-icon">track_changes</mat-icon>
  Результаты прогноза
</h3>
<hr />

<ng-container [ngSwitch]="inputTypeSysname">
  <ng-container *ngSwitchCase="'manually'">
    <div class="bg-row">
      <div class="bg-col">
        <h4 class="section-header">Размеры зоны радиоактивного загрязнения местности</h4>
        Для дозы {{ forecastingResults?.result.dose }}
        {{ unitName }}
        <ng-container *ngTemplateOutlet="resultBlock; context: { result: forecastingResults?.result }"></ng-container>
        <div class="recommended-measures-container">
          <h5>Рекомендованная мера защиты при указанной дозе за первые 10 суток:</h5>
          <p>{{ forecastingResults?.result.recommendedMeasures }}</p>
        </div>
      </div>
    </div>

    <div class="bg-row" *ngIf="counterTaskSysname === 'timeAllowedToStartWork'">
      <div class="bg-col">
        <h4 class="section-header">Допустимое время пребывания населения и начала работ на загряз. территории</h4>
        Для дозы {{ forecastingResults?.result.dose?.toFixed(2) }}
        {{ unitName }}
        <div class="clarification">
          Время пребывания населения на загрязнённой территории рассчитывается по рисунку 3 из ГОСТ Р 22.2.11-2018 с
          помощью значений:
        </div>
        <ul class="results-list" style="margin: 0; width: unset">
          <li class="results-list-item" style="margin-bottom: 10px">
            <mat-icon
              aria-hidden="false"
              #tooltip="matTooltip"
              [matTooltip]="tooltipText"
              matTooltipPosition="right"
              matTooltipHideDelay="100000"
              [matTooltipClass]="'time-of-day-tooltip'"
              class="time-of-day-tooltip-icon"
              >info
            </mat-icon>
            <label> Время начала облучения t(ч):</label>
            <label class="results-list-item--var">
              {{ forecastingResults?.result.irradiationStartTime | commaToDot | number: '0.2-2' }}
            </label>
          </li>
          <li class="results-list-item">
            <label> Коэффициент η:</label>
            <label class="results-list-item--var">
              {{ forecastingResults?.result.etaCoefficient | commaToDot | number: '0.2-2' }}
            </label>
          </li>
        </ul>
        <hr />
        <div class="clarification">
          Время начала работ на загрязнённой территории рассчитывается по рисунку 3 из ГОСТ Р 22.2.11-2018 с помощью
          требуемой продолжительности работ и значения:
        </div>
        <ul class="results-list" style="margin: 0; width: unset">
          <li class="results-list-item">
            <label> Коэффициент η:</label>
            <label class="results-list-item--var">
              {{ forecastingResults?.result.etaCoefficient | commaToDot | number: '0.2-2' }}
            </label>
          </li>
        </ul>
      </div>
    </div>

    <div class="bg-row">
      <div class="download-button-container">
        <button mat-raised-button class="download-button" (click)="openExtraParams()">
          {{
            counterTaskSysname !== 'timeAllowedToStartWork'
              ? 'Расчёт доп. параметров'
              : 'Пересчитать доп. параметры'
          }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'dangerCalculationDoses'">
    <div class="bg-row">
      <div class="bg-col">
        <h4 class="section-header">Размеры зон радиоактивного загрязнения местности по степени опасности</h4>
        <ng-container *ngFor="let result of results; let i = index">
          <ng-container *ngIf="i === 0">
            Умеренное заражение (доза {{ result.dose }}
            {{ unitName }})
          </ng-container>
          <ng-container *ngIf="i === 1">
            Сильное заражение (доза {{ result.dose }}
            {{ unitName }})
          </ng-container>
          <ng-container *ngIf="i === 2">
            Опасное заражение (доза {{ result.dose }}
            {{ unitName }})
          </ng-container>
          <ng-container *ngTemplateOutlet="resultBlock; context: { result: result }"></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'shelterCalculatingDoses'">
    <div class="bg-row">
      <div class="bg-col">
        <h4 class="section-header">Размеры зон укрытия/эвакуации при радиоактивном загрязнении местности</h4>
        <ng-container *ngFor="let result of results; let i = index">
          <ng-container *ngIf="i === 0">
            Укрытие (доза {{ result.dose }}
            {{ unitName }})
          </ng-container>
          <ng-container *ngIf="i === 1">
            Эвакуация, уровень А (доза {{ result.dose }}
            {{ unitName }})
          </ng-container>
          <ng-container *ngIf="i === 2">
            Эвакуация, уровень Б (доза {{ result.dose }}
            {{ unitName }})
          </ng-container>
          <ng-container *ngTemplateOutlet="resultBlock; context: { result: result }"></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'propagationTimeDoses'">
    <div class="bg-row">
      <div class="bg-col">
        <h4 class="section-header">Размеры зон радиоактивного загрязнения местности</h4>
        <ng-container *ngFor="let result of results; let i = index">
          Для дозы {{ result.dose }}
          {{ settings.getDictionaryById(forecastingResults?.params.params.unitRadiation)?.name }}
          <ng-container *ngTemplateOutlet="resultBlock; context: { result: result }"></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #resultBlock let-result="result">
  <ng-container *ngIf="!result.error">
    <ul class="results-list">
      <li class="results-list-item">
        <label> Длина зоны (км):</label>
        <label class="results-list-item--var">
          {{ result.zoneLength?.toFixed(2) }}
        </label>
      </li>
      <li class="results-list-item">
        <label>Ширина зоны (км):</label>
        <label class="results-list-item--var">
          {{ result.zoneWidth?.toFixed(2) }}
        </label>
      </li>
      <li class="results-list-item">
        <label>Площадь зоны (км²):</label>
        <label class="results-list-item--var">
          {{ result.zoneArea?.toFixed(2) }}
        </label>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="result.error">
    <p>{{ result.error }}</p>
    <br />
  </ng-container>
</ng-template>
