import { Injectable } from '@angular/core';
import { BaseCrudService } from '@bg-front/core/services';
import { RestService } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData } from 'smart-city-types';
import { IMunicipality } from '@bg-front/core/models/interfaces';

@Injectable({ providedIn: 'root' })
export class MunicipalitiesService extends BaseCrudService<IMunicipality> {
  /** @ignore */
  constructor(rest: RestService) {
    super(
      {
        serviceName: 'Admin',
        entityName: 'Municipal',
      },
      rest,
    );
  }

  /**
   * Получение информации о МО
   * @param id - ID МО
   */
  public getMunicipalityById(id: string | string[]): Observable<IMunicipality | IMunicipality[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Municipal',
          query: {
            id: Array.isArray(id) ? { $in: id } : id,
          },
        },
      })
      .pipe(
        map((result: IAbstractServiceData) =>
          Array.isArray(id) ? <IMunicipality[]>result.data.items : <IMunicipality>result.data.items[0],
        ),
      );
  }

  /**
   * Получение всех МО
   * */
  public getAllMunicipalities(): Observable<IMunicipality[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: { name: 'Municipal' },
      })
      .pipe(map((result: IAbstractServiceData) => <IMunicipality[]>result.data.items));
  }

  /** Возвращает главное МО (первое МО, где Главное МО не заполнено) */
  public getMainMunicipality(): Observable<IMunicipality> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: { name: 'Municipal' },
      })
      .pipe(
        map((result: IAbstractServiceData) => {
          // первая запись, где Главное МО не заполнено
          return result.data.items.find((el: IMunicipality) => !el.municipal);
        }),
      );
  }

  /** Проверяет, является ли МО главным (поле Главное МО не заполнено) */
  public checkIfMainMunicipality(id: string): Observable<boolean> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: { name: 'Municipal', query: { id } },
      })
      .pipe(map((result: IAbstractServiceData) => !result.data.items[0].municipal));
  }

  /**
   * Получение координат нескольких МО
   * @param municipalitiesIds - массив ID МО
   */
  public getMultipleMunicipalitiesCoordinates(municipalitiesIds: string[]): Observable<IMunicipality[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Municipal',
          query: { id: { $in: municipalitiesIds } },
          attributes: ['polygonId'],
        },
      })
      .pipe(map((result: IAbstractServiceData) => <IMunicipality[]>result.data.items));
  }
}
