import { Component, Input, OnInit } from '@angular/core';
import { EmergencyService } from '../../services';
import { of } from 'rxjs';
import { catchError, map} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  IGridTableOptions,
  INwForm,
  GridTableComponent,
  IScSelectOptions,
} from '@smart-city/core/common';
import { Settings2Service, NotificationService } from '@smart-city/core/services';
import { IAnyObject } from 'smart-city-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddressDetailsAddDialogComponent } from './../address-details-add-dialog/address-details-add-dialog.component';
import { AddressDetailsViewDialogComponent } from '../address-details-view-dialog/address-details-view-dialog.component';
import { EmergencyDto } from '../../models/classes';
import { IAddressDetailsDto } from '../../models/interfaces';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { ScConsole } from '@smart-city/core/utils';
import { FormControl, FormGroup } from '@angular/forms';
import { AddressDetailsMapViewComponent } from '../address-details-map-view/address-details-map-view.component';
import { GarFindFlatResponseElement } from '@bg-front/core';

/** Компонент для отображения блока "зона действия" */
@UntilDestroy()
@Component({
  selector: 'bg-coverage-area',
  templateUrl: './coverage-area.component.html',
  styleUrls: ['./coverage-area.component.scss']
})
export class CoverageAreaComponent implements OnInit {
  /** Адрес из формы происшествия */
  @Input()
  public address: GarFindFlatResponseElement;

  /** Форма */
  public form: FormGroup;
  /** Настройка селекта Способ визуализации */
  public areaVisualizationMethodOptions: IScSelectOptions = {
    title: 'Способ визуализации',
    data: this.settings.getDictForSelect('areaVisualizationMethod'),
    clearable: true,
  };
  /** Опции грида */
  public gridOptions: IGridTableOptions = {};
  /** Id происшествия */
  public emergency: EmergencyDto;
  /** Количество жителей */
  public totalRegistered: number = 0;
  public totalResidents: number = 0;
  public totalChildren: number = 0;
  /** id типа поручение */
  public orderTypeId: string = this.settings.getDictionaryByTypeSysName('docType')
    .find((type: IDictionaryInfo) => type.sysname = 'order')?.id;
  /** Флаг, является ли происшествие поручением */
  public isOrder: boolean;
  /** загруженные из базы значения адресов */
  public addressDetails: IAddressDetailsDto[] = [];
  /** Форма поиска */
  private searchForm: INwForm = {
    type: 'nw-form',
    options: {
      name: 'search',
      title: 'Поиск',
      layoutForm: {
        layout: {
          background: 'white',
          columns: [
            {
              width: '100%',
              elements: [
                {
                  type: 'input',
                  options: {
                    name: 'search',
                    placeholder: 'Поиск',
                  },
                },
                {
                  type: 'button',
                  options: {
                    name: 'search',
                    title: 'Искать',
                    stdBehavior: true,
                    color: 'primary',
                  },
                },
                {
                  type: 'button',
                  options: {
                    name: 'cancel',
                    title: 'Отмена',
                    stdBehavior: true,
                  },
                },
              ],
            },
          ],
        },
      },
    },
  };

  constructor(
    private readonly emergencyService: EmergencyService,
    private readonly settings: Settings2Service,
    public readonly dialog: MatDialog,
    private readonly note: NotificationService,
  ) { }

  public ngOnInit(): void {
    const defaultMethodId = this.settings.getDictionaryByTypeSysName('areaVisualizationMethod')
      .find((item: IDictionaryInfo) => item.sysname === 'geotags').id
    this.form = new FormGroup({
      areaVisualizationMethod: new FormControl(defaultMethodId)
    })

    this.emergencyService.coverageAreaOpened$.pipe(untilDestroyed(this))
      .subscribe((emergency: EmergencyDto) => {
        this.emergency = emergency;
        this.isOrder = this.emergency.docType === this.orderTypeId;

        this.gridOptions = <IGridTableOptions>{
          height: '100%',
          name: '',
          restVersion: 'modern',
          service: { name: 'Emergency' },
          entity: 'AddressDetails',
          query: { emergencyId: this.isOrder ? this.emergency.parentId['id'] : this.emergency?.id },
          sort: {
            field: 'creationTime',
            direction: 'desc',
          },
          showTotalRecords: true,
          allowSelectCheckbox: true,
          isInfiniteScroll: false,
          onRowDblClick: (row: IAddressDetailsDto, grid: GridTableComponent) => {
            this.updateHandler(grid, row);
            return of({ actionStream: 'donothing' });
          },
          controls: [
            {
              name: 'reload',
              icon: 'refresh',
              hint: 'Обновить',
              callback: () => {
                return of({actionStream: 'reload'})
              },
            },
            {
              name: 'search',
              icon: 'search',
              hint: 'Поиск',
            },
            {
              name: 'insert',
              icon: 'add',
              color: 'primary',
              hint: 'Добавление новой записи',
              callback: (params) => {
                this.addHandler(params.grid);
                return of({ actionStream: 'donothing' });
              },
            },
          ],
          fields: [
            { name: 'address', title: 'Адрес' },
            { name: 'buildingKindId.name', title: 'Вид здания' },
            { name: 'total', title: 'Всего граждан' },
            { name: 'children', title: 'В т.ч. детей' },
          ],
          loadGridDataFn: (query: IAnyObject) => {
            return this.emergencyService.getAddressDetails(
              {
                emergencyId: this.isOrder ? this.emergency.parentId['id'] : this.emergency?.id,
                ...query,
              }).pipe(
              map((data: IAnyObject) => {
                this.totalRegistered = 0;
                this.totalResidents = 0;
                this.totalChildren = 0;

                return data.map((item) => {
                  const registered: number = item.numberOfRegistered?.total || 0;
                  const residents: number = item.numberOfResidents?.total || 0;
                  const registeredChildren: number = item.numberOfRegistered?.children || 0;
                  const residentsChildren: number = item.numberOfResidents?.children || 0;

                  this.totalRegistered = this.totalRegistered + registered;
                  this.totalResidents = this.totalResidents + residents;
                  this.totalChildren = this.totalChildren + registeredChildren + residentsChildren;

                  item.coordinates = item.address.latitude && item.address.longitude
                    ? `${ item.address.latitude }, ${ item.address.longitude }`
                    : null;
                  item.address = item.address?.fullText;
                  item.total = registered + residents;
                  item.children = registeredChildren + residentsChildren;

                  return item;
                })
              }),
              map((data: IAddressDetailsDto[]) => {
                this.addressDetails = data;
                return {
                  items: data,
                  totalCount: data.length,
                };
              }),
            )
          },
          forms: [this.searchForm],
        }
      })
    };

  public addHandler(grid: GridTableComponent): void {
    const dialogRef = this.dialog.open(AddressDetailsAddDialogComponent, {
      width: '1000px',
      panelClass: 'modal-dialog-gray',
      data: {
        isOrder: this.isOrder,
        emergency: this.emergency,
        address: this.address,
        addressDetails: this.addressDetails,
        visualizationMethod: this.form.controls.areaVisualizationMethod.value,
      }
    });

    dialogRef.afterClosed().pipe(untilDestroyed(this))
      .subscribe((data: IAddressDetailsDto | IAddressDetailsDto[]) => {
        if (data) {
          this.emergencyService.saveAddressDetails(<IAddressDetailsDto>data).pipe(
            untilDestroyed(this),
            catchError((err: Error) => {
              this.note.pushInfo('Ошибка сохранения записи!');
              ScConsole.error([err.message]);
              return of(null);
            })
          ).subscribe(() => grid.refresh().pipe(untilDestroyed(this)).subscribe());
        }
      })
  }

  public updateHandler(grid: GridTableComponent, data: IAddressDetailsDto): void {
    const dialogRef = this.dialog.open(AddressDetailsViewDialogComponent, {
      width: '1000px',
      panelClass: 'modal-dialog-gray',
      data: {
        isOrder: this.isOrder,
        emergency: this.emergency,
        addressDetails: data,
      }
    });

    dialogRef.afterClosed().pipe(untilDestroyed(this))
      .subscribe((data: IAddressDetailsDto) => {
        if (data) {
          this.emergencyService.updateAddressDetails(data).pipe(
            untilDestroyed(this),
            catchError((err: Error) => {
              this.note.pushInfo('Ошибка сохранения записи!');
              ScConsole.error([err.message]);
              return of(null);
            })
          ).subscribe(() => grid.refresh().pipe(untilDestroyed(this)).subscribe());
        }
      })
  }

  public showOnMap(): void {
    this.dialog.open(AddressDetailsMapViewComponent, {
      width: '1100px',
      panelClass: 'modal-dialog-gray',
      data: {
        address: this.address,
        addressDetails: this.addressDetails,
        visualizationMethod: this.form.controls.areaVisualizationMethod.value,
      }
    });
  }
}
