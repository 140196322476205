<div class="response-plan">
  <div class="response-plan-header">
    <div class="response-plan-header-title" [class]="fixedHeaderClass">{{ options?.headerTitle }}</div>
    <sc-button
      *ngIf="!disabled"
      [options]="createBtnOptions"
      (clickButton)="createStepHandler($event)">
    </sc-button>
  </div>
  <div class="response-plan-list">
    <bg-response-plan-step
      *ngFor="let step of options?.stepList"
      (click)="stepClickHandler($event, step)"
      [step]="step">
    </bg-response-plan-step>
  </div>
</div>
