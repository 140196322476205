import { Component, Input, OnInit } from '@angular/core';
import { differenceInCalendarDays, differenceInYears } from 'date-fns';
import { ControlsOf, FormGroup } from '@ngneat/reactive-forms';
import { Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Observer, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { ONE_DAY, ONE_MINUTE } from '@bg-front/core/models/constants';
import {
  ILifeCycleStepParamDto,
  ILifeCycleStepActionDto,
  IAbstractServiceData,
  IFileInfoDto,
  IAnyObject,
} from 'smart-city-types';
import { IInformationRequestData } from '../../models/interfaces/information-request-data.interface';
import { AccessService, Settings2Service } from '@smart-city/core/services';
import { BaseComponent } from '@bg-front/core/components';
import { IInformationRequestForm, IRequestActionResult } from '../../models/interfaces';
import { RequestsService, VideoDevicesService } from '../../services';
import { AppInjector } from '@bg-front/core/models/classes';
import { MultiFileService } from '@bg-front/core/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ConfirmDialogResultEnum } from '@bg-front/core/models/enums';


// TODO После завершения переноса на новые компоненты, переименовать в BaseRequestsComponent
// TODO старую BaseRequestsComponent реализацию удалить

/**
 * Базовый класс компонентов запросов на предоставление информации
 * Реализует логику создания/удаления/изменения записей запросов
 */

@UntilDestroy()
@Component({
  template: '',
})
export abstract class BaseRequestsV2Component extends BaseComponent implements OnInit {
  /** Объект с параметрами запроса */
  @Input()
  public request: IInformationRequestData;
  /** Флаг длительное хранение */
  public isLongTermStorage = false;
  /** Ограничение долговременного хранения информации (лет) */
  public maxStorageDuration = 1;
  /** Список аттрибутов */
  public attributes: ILifeCycleStepParamDto[] = [];
  /** Текущая дата */
  public currentDate = new Date();
  /** Кнопки/Действия шага ЖЦ запроса */
  @Input()
  public actions: ILifeCycleStepActionDto[] = [];
  /** Сообщения валидатора поля "Напомнить за (в днях)" */
  public remindDateErrors = 'Введите значение от 1 до 7';
  /** Форма запроса */
  public form: FormGroup<ControlsOf<IInformationRequestForm>>;
  /** Смещение часового пояса */
  protected timezoneOffset: number = new Date().getTimezoneOffset() * ONE_MINUTE;

  /** Ограничитель максимального значения для времени с которого должно начинаться видео. */
  public fromMaxDate = (current: Date): boolean => differenceInCalendarDays(current, this.currentDate) > 0;
  /** Ограничитель даты для контрола "Хранить информацию до" */
  public deleteTimeMinDate = (current: Date): boolean => differenceInCalendarDays(current, this.currentDate) < 0
    || differenceInYears(current, this.currentDate) === this.maxStorageDuration;
  /** Огрничитель минимальной даты для "Обработать до" */
  public minDateTimeResolveTo = (current: Date): boolean => differenceInCalendarDays(current, this.currentDate) < 0;

  private readonly settings: Settings2Service;
  private readonly requestService: RequestsService;
  private readonly videoDevicesService: VideoDevicesService;
  private readonly multiFileService: MultiFileService;
  private readonly access: AccessService;
  private readonly modal: NzModalService;

  /** @ignore */
  constructor() {
    super();
    const injector = AppInjector.getInjector();
    this.settings = injector.get(Settings2Service);
    this.requestService = injector.get(RequestsService);
    this.videoDevicesService = injector.get(VideoDevicesService);
    this.multiFileService = injector.get(MultiFileService);
    this.access = injector.get(AccessService);
    this.modal = injector.get(NzModalService);
  }

  /** @ignore */
  public ngOnInit(): void {
    /**
     * Подписка на изменение даты удаления видео для актуализации значения даты напоминания.
     * Исключение возможности указать прошлое.
     */
    this.form.controls.deleteTime.valueChanges.pipe(untilDestroyed(this))
      .subscribe((val: Date) => {
        if (val) {
          this.form.controls.remindDate.enable();
          // Если отсутствует значение, предустанавливаем 1
          if (!this.form.controls.remindDate.value) {
            this.form.controls.remindDate.setValue(1);
          }
          // Определение максимального значения напоминания
          const max = (val.setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0)) / ONE_DAY;

          // Обновление сообщения об ошибке, в соответствии с максимальным значением
          this.remindDateErrors = `Введите значение от 1 до ${ max }`

          this.form.controls.remindDate.setValidators([Validators.max(max)]);
          this.form.controls.remindDate.updateValueAndValidity();
        } else {
          this.form.controls.remindDate.reset();
          this.form.controls.remindDate.disable();
        }
      });

    /**
     * Подписка на изменение значения чекбокса долговременного хранения информации
     */
    this.form.controls.permanent.valueChanges.pipe(untilDestroyed(this))
      .subscribe((val: boolean) => {
        this.isLongTermStorage = val;
        if (val) {
          this.form.controls.deleteTime.setValidators([Validators.required]);
        } else {
          this.form.controls.deleteTime.reset();
          this.form.controls.deleteTime.clearValidators();
        }
        this.form.controls.deleteTime.updateValueAndValidity();
      });
  }

  /**
   * Блокировка контролов на форме в зависимости от настроек шага ЖЦ
   */
  protected disableControls(params?: ILifeCycleStepParamDto[]) {
    // Активные аттрибуты берем либо из входного значения либо из ЖЦ
    const attributes = params || this.request.lifeCycleStep.params;
    // Если это не запрос пользователя, то взять редактирование поля так же на основании роли пользователя
    if (this.request && this.request.creationAuthor !== this.settings.currentUser.id) {
      attributes.forEach(
        (item: ILifeCycleStepParamDto) =>
          // Если явно не указано что поле активно, то считаем что оно неактивно
          (item.enabled = this.access.accessMap[item.attribute]?.enabled && item.enabled),
      );
    }

    if (attributes.find((item: ILifeCycleStepParamDto) => item.attribute === 'to' && item.enabled)) {
      attributes.push({ attribute: 'durationInput', enabled: true });
    }
    if (!this.request?.permanent) {
      if (attributes.find((item: ILifeCycleStepParamDto) => item.attribute === 'deleteTime')) {
        attributes.find((item: ILifeCycleStepParamDto) => item.attribute === 'deleteTime').enabled = false;
      }
      if (attributes.find((item: ILifeCycleStepParamDto) => item.attribute === 'remindDate')) {
        attributes.find((item: ILifeCycleStepParamDto) => item.attribute === 'remindDate').enabled = false;
      }
    }

    this.attributes = attributes;
  }

  /**
   * Метод создания/сохранения/удаления записи
   * @param action - экшен
   * @param request - иформация об запросе
   */
  public performAction(action: string, request: IInformationRequestData): Observable<IRequestActionResult> {
    // Флаг для обозначения необходимости запустить процедуру передачи видео в долговременное хранение
    // после получения обновленных данных о запросе.
    let needPerformVideo = false;
    return new Observable((o: Observer<string | void>) => {
      // Если не "Сохранить" то указать дату обновления статуса
      if (action !== 'toDraft') {
        request.updateTime = Date.now();
      }
      // Если "Отозвать" то затереть причину отказа,
      // пользователя который запросил и пользователя который обработал запрос
      if (action === 'recall') {
        request.rejectionReason = null;
        request.executorId = null;
        request.initiatorId = null;

        // При отзыве надо попытаться удалить видео из архива.
        // Его может не быть, но нужно пометить запись как неактивную
        this.requestService
          .removeArchive(this.request.id)
          .pipe(
            // Если в процессе удаления произошла ошибка (видео не было), то игнорировать это.
            // Главное что запись помечена как неактивная
            catchError(() => {
              return of(null);
            }),
            untilDestroyed(this),
          )
          .subscribe();
      }
      // Если "Одобрить" или "Отклонить" то укажем сотрудника который обработал запрос
      if (action === 'approve' || action === 'reject') {
        request.executorId = this.settings.currentUser.id;
      }
      // Если "Одобрить", то затереть причину отказа
      if (action === 'approve') {
        request.rejectionReason = null;
      }
      // Если "Отправить в обработку", то укажем сотрудника который запросил информацию
      if (action === 'inProcess') {
        request.initiatorId = this.settings.currentUser.id;
        if (!request.permanent) {
          const text = `Не выбран режим долговременного хранения. Видео будет доступно в зависимости от настроек хранения архива на видеосервере.
          Вы действительно хотите отправить запрос в обработку?`;

          // Диалог подтверждающий сохранение без даты удаления запроса
          this.modal.create({
            nzTitle: 'Внимание',
            nzContent: text,
            nzClosable: false,
            nzCancelText: 'Нет',
            nzOkText: 'Да',
            nzOnOk: () => ConfirmDialogResultEnum.confirm,
            nzOnCancel: () => ConfirmDialogResultEnum.reject,
          })
            .afterClose.pipe(untilDestroyed(this))
            .subscribe((result: number) => {
              if (result === ConfirmDialogResultEnum.confirm) {
                o.next();
                o.complete();
              }
            });

          return;
        }
      }
      // Если "Удалить", то откроем диалог подтверждающий удаление
      if (action === 'delete') {
        const text = 'Вы действительно хотите удалить эту запись?';
        // Диалог подтверждающий удаление запроса
        this.modal.create({
          nzTitle: 'Внимание',
          nzContent: text,
          nzClosable: false,
          nzCancelText: 'Нет',
          nzOkText: 'Да',
          nzOnOk: () => ConfirmDialogResultEnum.confirm,
          nzOnCancel: () => ConfirmDialogResultEnum.reject,
        })
          .afterClose.pipe(untilDestroyed(this))
          .subscribe((result: number) => {
            if (result === ConfirmDialogResultEnum.confirm) {
              o.next();
              o.complete();
            }
          });

        return;
      }
      // Если "Отклонить", то откроем форму с заполнением причины отказа
      if (action === 'reject') {
        this.reject().subscribe((data) => {
          if (data.action === 'ok') {
            request.rejectionReason = data.form.formGroup.value.rejectionReason;
            // При отклонении надо попытаться удалить видео из архива.
            // Его может не быть, но нужно пометить запись как неактивную
            this.requestService
              .removeArchive(this.request.id)
              .pipe(
                takeUntil(this.ngUnsubscribe),
                // Если в процессе удаления произошла ошибка (видео не было), то игнорировать это.
                // Главное что запись помечена как неактивная
                catchError(() => {
                  return of(null);
                }),
              )
              .subscribe();
            o.next();
            o.complete();
          }
        });
        return;
      }
      // Если "Отправить в обработку"(Сформировать запрос), то укажем сотрудника который запросил информацию
      if (action === 'toGeneration') {
        request.initiatorId = this.settings.currentUser.id;
        if (!request.permanent) {
          const text =
            `Не выбран режим долговременного хранения. Видео будет доступно в зависимости от настроек хранения архива на видеосервере. ` +
            `Вы действительно хотите отправить запрос в обработку?`;

          // Диалог подтверждающий сохранение без даты удаления запроса
          this.modal.create({
            nzTitle: 'Внимание',
            nzContent: text,
            nzClosable: false,
            nzCancelText: 'Нет',
            nzOkText: 'Да',
            nzOnOk: () => ConfirmDialogResultEnum.confirm,
            nzOnCancel: () => ConfirmDialogResultEnum.reject,
          })
            .afterClose.pipe(
              filter((result: number) => result === ConfirmDialogResultEnum.confirm),
              switchMap(() => {
                // Так же в этом случае нужно попробовать сформировать ссылку на видео, для определения в какое состояние
                // перевести запрос 'Успех' или 'Ошибка'
                return this.videoDevicesService
                  .getVideoDeviceRangeStreamUrl(request.cameraId as string, request.from as number, 1000);
              }),
              untilDestroyed(this)
            )
            .subscribe((url: string) => {
              o.next(url || null);
              o.complete();
            });

          return;
        }
        // Если дата удаления запроса указана, надо запустить микросервис фонового скачивания видео
        const text =
          'После успешного сохранения видео запрос будет автоматически отправлен в обработку. В противном случае запрос будет отклонён и Вам необходимо будет скорректировать параметры сохранения или обратиться в техподдержку';

        this.modal.create({
          nzTitle: 'Внимание',
          nzContent: text,
          nzClosable: false,
          nzCancelText: 'Нет',
          nzOkText: 'Да',
          nzOnOk: () => ConfirmDialogResultEnum.confirm,
          nzOnCancel: () => ConfirmDialogResultEnum.reject,
        })
          .afterClose.pipe(untilDestroyed(this))
          .subscribe((result: number) => {
            if (result === ConfirmDialogResultEnum.confirm) {
              needPerformVideo = true;
              o.next();
              o.complete();
            }
          });
        return;
      }
      // Если "Сохранить" в статусе "Одобрен" и изменилось значение долговременного хранения
      if (action === 'save' && this.request.permanent !== request.permanent) {
        if (request.permanent) {
          const text =
            'Видео будет отправлено на долговременное хранение. ' +
            'Необходимо время для получения видео с видеосервера и загрузки в Систему. ' +
            'При возникновении ошибок обратитесь в техподдержку';

          this.modal.create({
            nzTitle: 'Внимание',
            nzContent: text,
            nzClosable: false,
            nzCancelText: 'Нет',
            nzOkText: 'Да',
            nzOnOk: () => ConfirmDialogResultEnum.confirm,
            nzOnCancel: () => ConfirmDialogResultEnum.reject,
          })
            .afterClose.pipe(untilDestroyed(this))
            .subscribe((result: number) => {
              if (result === ConfirmDialogResultEnum.confirm) {
                if (!this.request.video.sfsId) {
                  // Если нет подготовленного видео, то укажем необходимость запустить процесс его формирования
                  needPerformVideo = true;
                } else {
                  // Если есть подготовленное видео, то обновим сроки его хранения.
                  this.requestService
                    .updateArchive(this.request.id, <number>request.deleteTime)
                    .pipe(
                      catchError((err: Error) => {
                        return this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка изменения срока хранения видео');
                      }),
                      untilDestroyed(this),
                    )
                    .subscribe();
                }
                o.next();
                o.complete();
              }
            });

          return;
        }

        const text =
          'Видео будет удалено из хранилища для долговременного хранения. ' +
          'Просмотр и экспорт будут доступны в зависимости от настроек хранения архива на видеосервере. ' +
          'Продолжить?';
        // Диалог предупреждающий, что видео будет удалено из хранилища
        this.modal.create({
          nzTitle: 'Внимание',
          nzContent: text,
          nzClosable: false,
          nzCancelText: 'Нет',
          nzOkText: 'Да',
          nzOnOk: () => ConfirmDialogResultEnum.confirm,
          nzOnCancel: () => ConfirmDialogResultEnum.reject,
        })
          .afterClose.pipe(untilDestroyed(this))
          .subscribe((result: number) => {
            if (result === ConfirmDialogResultEnum.confirm) {
              this.requestService
                .removeArchive(this.request.id)
                .pipe(
                  catchError((err: Error) => {
                    return this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка удаления видео из архива');
                  }),
                  untilDestroyed(this),
                )
                .subscribe();
              o.next();
              o.complete();
            }
          });

        return;
      }
      // Если "Сохранить" в статусе "Одобрен" и изменилась дата удаления видео при условии что флаг долговременного
      // хранения не изменился и остался true (данное условие перехвачено в блоке выше)
      if (action === 'save' && this.request.deleteTime !== request.deleteTime) {
        this.requestService
          .updateArchive(this.request.id, <number>request.deleteTime)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            catchError((err: Error) => {
              return this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка изменения срока хранения видео');
            }),
          )
          .subscribe();
        o.next();
        o.complete();
      }
      o.next();
      o.complete();
    }).pipe(
      switchMap((result: string | void) => {
        // Если результат не равен undefined, то была попытка сформировать URL на видео с камеры.
        // В зависимости от результата надо перевести запрос в состояние Успех или Ошибка.
        if (result !== undefined) {
          request.videoUrl = result ? result : null;
          // В случае ошибки нужно заполнить причину отказа.
          request.rejectionReason = !result
            ? 'Не удалось получить видео с видеосервера. Пожалуйста, обратитесь в техподдержку.'
            : null;
          const action = !!request.videoUrl ? 'success' : 'fail';
          request.lifeCycleStepId = this.actions.find((a: ILifeCycleStepActionDto) => a.name === action).nextStep;
        }
        // Выполним необходимое действие
        return action === 'delete'
          ? this.requestService.delete(request.id)
          : this.multiFileService.saveFilesToSfs(request.documents).pipe(
            switchMap((documents: IFileInfoDto[]) => {
              request.documents = documents;
              return this.requestService.save(request);
            }),
          );
      }),
      // Если реквест изменился, запросим обновленную информацию о нем
      switchMap((result: IAbstractServiceData) =>
        action === 'delete'
          ? of({})
          : // Если выполняется действие Формирование запроса необходимо попытаться
            // получить ссылку на видео
          this.requestService.getRequestData(result.data.id, action === 'toGeneration'),
      ),
      map((data: IRequestActionResult) => {
        // Если по предыдущей логике надо отправить видео в долговременное хранение, то сделать это
        if (needPerformVideo) {
          this.requestService
            .prepareVideo(data.request)
            .pipe(
              takeUntil(this.ngUnsubscribe),
              catchError((err: Error) => {
                return this.catchErrorFn<IAbstractServiceData>(err, 'Ошибка создания видеоархива');
              }),
            )
            .subscribe();
        }
        data.action = action;
        return data;
      }),
      catchError((err: Error) => {
        return this.catchErrorFn<IAbstractServiceData>(
          err,
          action === 'delete'
            ? 'Невозможно удалить запись, которая используется в системе.'
            : 'В процессе выполнения действия произошла ошибка',
          { action: 'donothing' },
        );
      }),
    );
  }

  /**
   * Получение информационного сообщения в зависимости от действия
   * @param stepAction - действие над запросом
   * @param formAction - действие формы
   */
  public getInfo(stepAction: string, formAction?: string): string {
    switch (stepAction) {
      case 'toDraft':
        return formAction === 'create' ? 'Запись успешно создана' : 'Запись успешно изменена';
      case 'save':
        return 'Запись успешно изменена';
      case 'inProcess':
        return 'Запись отправлена в обработку';
      case 'approve':
        return 'Запись успешно одобрена';
      case 'recall':
        return 'Запись успешно отозвана';
      case 'reject':
        return 'Запись успешно отклонена';
      case 'close':
        return 'Запись успешно закрыта';
      case 'toGeneration':
        return 'Запись отправлена на формирование';
      case 'fail':
        return 'Ошибка при формировании видео';
      case 'success':
        return 'Запись отправлена в обработку';
    }
  }

  // TODO Реализовать при переносе компонентов RequestsUpdate/RequestsCreate
  public reject(): Observable<IAnyObject> {
    return of();
  }
}
