import { Component, Input } from '@angular/core';
import { ControlContainer, FormBuilder } from '@angular/forms';
import { IScInputOptions, IScSelectOptions } from '@smart-city/core/common';
import { IEmergencyResolutionDto } from 'smart-city-types';
import { FormModal } from '@bg-front/core/models/classes';
import { BaseComponent } from '@bg-front/core/components';
import { LoosesDialogComponent } from '../looses-dialog/looses-dialog.component';
import { Settings2Service } from '@smart-city/core/services';

/**
 * Компонента реализует возможность просмотра и редактирования блока "Потери" в форме происшествия
 */
@Component({
  selector: 'bg-looses',
  templateUrl: './bg-looses.component.html',
  styleUrls: ['./bg-looses.component.scss'],
})
export class BgLoosesComponent extends BaseComponent {
  /** ID инцидента */
  @Input() public emergencyId: string;

  //#region Настройки компонент

  /** Настройка компоненты "Характер ущерба" */
  public damageOptions: IScSelectOptions = {
    title: 'Характер ущерба',
    clearable: true,
    data: this.settings.getDictForSelect('fireDamage'),
  };

  /** Погибшие */
  public peopleInTheZoneOption: IScInputOptions = {
    label: 'Всего в зоне КСиП',
    mask: '0*',
  };

  /** В т.ч дети */
  public peopleInTheZoneChildrenOption: IScInputOptions = {
    label: 'В т.ч. дети',
    mask: '0*',
  };

  /** Госпитализированные */
  public hospitalizedOption: IScInputOptions = {
    label: 'Госпитализированные',
    mask: '0*',
  };

  /** В т.ч дети */
  public hospitalizedChildrenOption: IScInputOptions = {
    label: 'В т.ч. дети',
    mask: '0*',
  };

  /** Погибшие */
  public deathOption: IScInputOptions = {
    label: 'Погибшие',
    mask: '0*',
  };

  /** В т.ч дети */
  public deathChildrenOption: IScInputOptions = {
    label: 'В т.ч. дети',
    mask: '0*',
  };

  /** Спасённые */
  public rescuedOption: IScInputOptions = {
    label: 'Спасённые',
    mask: '0*',
  };

  /** В т.ч дети */
  public rescuedChildrenOption: IScInputOptions = {
    label: 'В т.ч. дети',
    mask: '0*',
  };

  /** Спасённые */
  public evacuatedOption: IScInputOptions = {
    label: 'Эвакуированные',
    mask: '0*',
  };

  /** В т.ч дети */
  public evacuatedChildrenOption: IScInputOptions = {
    label: 'В т.ч. дети',
    mask: '0*',
  };

  /** Пострадавшие */
  public victimOption: IScInputOptions = {
    label: 'Пострадавшие',
    mask: '0*',
  };

  /** В т.ч дети */
  public victimChildrenOption: IScInputOptions = {
    label: 'В т.ч. дети',
    mask: '0*',
  };

  /** Пропавшие без вести */
  public missingOption: IScInputOptions = {
    label: 'Пропавшие без вести',
    mask: '0*',
  };

  /** В т.ч дети */
  public missingChildrenOption: IScInputOptions = {
    label: 'В т.ч. дети',
    mask: '0*',
  };

  /** Сумма ущерба */
  public sumDamageOption: IScInputOptions = {
    label: 'Сумма ущерба (руб)',
    mask: '0*,0*'
  };

  /** Спасено мат. ценностей */
  public savedTangiblesOption: IScInputOptions = {
    label: 'Спасено мат. ценностей (руб)',
    mask: '0*,0*',
  };

  //#endregion

  /** @ignore */
  constructor(private readonly settings: Settings2Service, public readonly controlContainer: ControlContainer) {
    super();
  }

  /**
   * Открытие диалога с добавлением записей к списку указанного типа.
   * @param listType - тип списка
   */
  public addButtonClick(listType: string) {
    new FormModal(
      LoosesDialogComponent,
      { panelClass: 'org-panel', maxWidth: '90vw', width: '90vw', disableClose: true },
      { type: listType, emergencyId: this.emergencyId },
    ).open();
  }

  /** Генерация FormGroup */
  public static generateFormGroup(fb: FormBuilder, params: IEmergencyResolutionDto) {
    return fb.group({
      damageId: [params?.damageId],
      sumDamage: [params?.sumDamage || 0],
      savedTangibles: [params?.savedTangibles || 0],
      destroyedBuildings: [params?.destroyedBuildings || 0],
      destroyedTech: [params?.destroyedTech || 0],
      destroyedCattle: [params?.destroyedCattle || 0],
      // Всего в зоне
      peopleInTheZone: [params?.peopleInTheZone || 0],
      peopleInTheZoneChildren: [params?.peopleInTheZoneChildren || 0],
      peopleInTheZoneOnDate: [params?.peopleInTheZoneOnDate || undefined],
      // Погибшие
      death: [params?.death || 0],
      deathChildren: [params?.deathChildren || 0],
      deathOnDate: [params?.deathOnDate || undefined],
      // Спасённые
      rescued: [params?.rescued || 0],
      rescuedChildren: [params?.rescuedChildren || 0],
      rescuedOnDate: [params?.rescuedOnDate || undefined],
      // Пострадавшие
      victim: [params?.victim || 0],
      victimChildren: [params?.victimChildren || 0],
      victimOnDate: [params?.victimOnDate || undefined],
      // Пострадавшие
      hospitalized: [params?.hospitalized || 0],
      hospitalizedChildren: [params?.hospitalizedChildren || 0],
      hospitalizedOnDate: [params?.hospitalizedOnDate || undefined],
      // Эвакуированные
      evacuated: [params?.evacuated || 0],
      evacuatedChildren: [params?.evacuatedChildren || 0],
      evacuatedOnDate: [params?.evacuatedOnDate || undefined],
      // Пропавшие без вести
      missing: [params?.missing || 0],
      missingChildren: [params?.missingChildren || 0],
      missingOnDate: [params?.missingOnDate || undefined],
    });
  }
}
