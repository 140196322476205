import { TMapBaseCoordinates } from '../types';

/**
 * Статический класс хранящий методы для карты
 */
export abstract class MapUtils {
  /**
   * Меняет местами координаты
   * @param coordinates координаты точки
   * @returns координаты точки, поменянные местами
   */
  public static swapCoordinates(coordinates: TMapBaseCoordinates): TMapBaseCoordinates {
    return [coordinates[1], coordinates[0]];
  }

  /**
   * Проверяет строку координат точки на корректность данных
   * @param coordinates строка с координатами точки, например: 'coord1,coord2'
   * @param regexpCoordinates выражение RegExp для разбора строки координат.
   */
  public static checkCoordinates(coordinates: string, regexpCoordinates: RegExp = /\s*,\s*/): boolean {
    return coordinates?.split(regexpCoordinates)?.length === 2;
  }

  /**
   * Проверяет строку координат полигона на корректность данных
   * @param polygon строка с координатами полигона, например: 'coord1,coord2;coord3,coord4.....'
   * @param regexpPolygon выражение RegExp для разбора строки полигона.
   * @param regexpCoordinates выражение RegExp для разбора строки координат.
   */
  public static checkPolygon(
    polygon: string,
    regexpPolygon: RegExp = /\s*;\s*/,
    regexpCoordinates: RegExp = /\s*,\s*/,
  ): boolean {
    if (polygon?.split(regexpPolygon)?.length > 2) {
      polygon.split(regexpPolygon).forEach((coordinates) => {
        if (!this.checkCoordinates(coordinates, regexpCoordinates)) {
          return false;
        }
      });
      return true;
    }
    return false;
  }

  /**
   * Преобразует строку координат точки в массив координат с проверкой корректности данных
   * @param coordinatesString строка с координатами точки, например: 'coord1,coord2'
   * @param regexpCoordinates выражение RegExp для разбора строки координат.
   * @returns массив с координатами или null, если строка некорректна
   */
  public static getCoordinates(coordinatesString: string, regexpCoordinates = /\s*,\s*/): TMapBaseCoordinates | null {
    if (!MapUtils.checkCoordinates(coordinatesString, regexpCoordinates)) return null;
    const coordinates = coordinatesString?.split(regexpCoordinates);
    if (coordinates?.length === 0) return null;
    return coordinates.map((coordinates) => Number(coordinates)) as TMapBaseCoordinates;
  }

  /**
   * Преобразует строку координат полигона в массивы координат с проверкой корректности данных
   * @param polygonString строка с координатами полигона, например: 'coord1,coord2;coord3,coord4.....'
   * @param regexpPolygon выражение RegExp для разбора строки полигона.
   * @param regexpCoordinates выражение RegExp для разбора строки координат.
   * @returns массивы координат
   */
  public static getPolygon(
    polygonString: string,
    regexpPolygon: RegExp = /\s*;\s*/,
    regexpCoordinates: RegExp = /\s*,\s*/,
  ): TMapBaseCoordinates[] | null {
    if (!MapUtils.checkPolygon(polygonString, regexpPolygon)) return null;
    const polygon = polygonString?.split(regexpPolygon);
    if (polygon?.length === 0) return null;
    return polygon.map((coordinatesArray) =>
      coordinatesArray.split(regexpCoordinates).map((coordinates) => Number(coordinates)),
    ) as TMapBaseCoordinates[];
  }

  /**
   * Выдает центр полигона
   * @param polygon массивы с координатами полигона формата: '[[coord1,coord2],[coord3,coord4].....]'
   * @returns массив координат
   */
  public static getPolygonCenter(polygon: TMapBaseCoordinates[]): TMapBaseCoordinates {
    let center: number[] = [];
    polygon.forEach((coordinates) => {
      center = center.length === 0 ? [...coordinates] : [center[0]! + coordinates[0], center[1]! + coordinates[1]];
    });
    const length = polygon.length;
    return [center[0]! / length, center[1]! / length];
  }

  /**
   * Получение угла в радианы из градусов
   * @param degrees угол в градусах
   * @returns угол в радианах
   */
  public static degreesToRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
  }

  /**
   * Расчёт расстояние между координатами
   * @param coordinates массив из двух массивов координат
   * @returns расстояние в километрах между точками
   */
  public static distanceInKmBetweenEarthCoordinates(coordinates: TMapBaseCoordinates[]): number {
    const earthRadiusKm = 6367;

    const dLat = MapUtils.degreesToRadians(coordinates[1]![0] - coordinates[0]![0]);
    const dLon = MapUtils.degreesToRadians(coordinates[1]![1] - coordinates[0]![1]);

    const newLat1 = MapUtils.degreesToRadians(coordinates[0]![0]);
    const newLat2 = MapUtils.degreesToRadians(coordinates[1]![0]);

    // eslint-disable-next-line no-restricted-properties,no-mixed-operators
    const a1 =
      Math.pow(Math.sin(dLat / 2), 2) + Math.cos(newLat1) * Math.cos(newLat2) * Math.pow(Math.sin(dLon / 2), 2); // eslint-disable-line no-restricted-properties,no-mixed-operators
    const c1 = 2 * Math.atan2(Math.sqrt(a1), Math.sqrt(1 - a1));
    return earthRadiusKm * c1;
  }

  /**
   * Возвращает размер SVG
   * @param svg строка с SVG
   * @returns размер SVG в виде массива [ширина, высота]
   */
  public static extractSizeFromSvg(svg: string): [number, number] {
    const sizes = (svg.match(/viewBox="([\s\S]*?)"/)?.[1] || '').split(' ').map((param: string) => parseFloat(param));
    return [sizes[2]! - sizes[0]!, sizes[3]! - sizes[1]!];
  }

  /**
   * Возвращает массив path из строки SVG
   * @param svg строка с SVG
   * @returns Массив строк с path
   */
  public static extractPathsFromSvg(svg: string): string[] {
    const results = svg.match(/<path\b([\s\S]*?)\/>/g)!;
    const paths: string[] = [];
    const len = results?.length;
    for (let i = 0; i < len; i++) {
      const str = results[i];
      const data = str!.match(/\Wd="([\s\S]*?)"/)!;
      paths.push(data[1]!);
    }
    return paths;
  }
}
