<form nz-form [formGroup]="form" errorTailor id="idForTest" nzLayout="vertical" *ngIf="form">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="22">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="coordinates">Координаты</nz-form-label>
            <nz-form-control>
              <nz-input-group nzSize="large" nzSearch [nzAddOnAfter]="suffixIconButton">
                <input type="text" placeholder="Широта, Долгота" nz-input formControlName="coordinates" />
              </nz-input-group>
              <ng-template #suffixIconButton>
                <button
                  nz-button
                  nzSize="large"
                  nzSearch
                  nzType="primary"
                  type="button"
                  alt="Задать координаты"
                  (click)="onClickSpecifyAddress($event)"
                >
                  <i nz-icon nzType="aim"></i>
                </button>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <nz-form-item>
            <nz-form-label nzFor="dose">Доза радиации</nz-form-label>
            <nz-form-control>
              <nz-input-group nzSize="large" [nzSuffix]="dose">
                <input nzSize="large" nz-input formControlName="dose" />
              </nz-input-group>
              <ng-template #dose> {{ unit }} </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
    <div nz-col [nzSpan]="2">
      <nz-form-item *ngIf="canDelete">
        <nz-form-label></nz-form-label>
        <nz-form-control>
          <i nz-icon nzType="minus-circle-o" class="dynamic-delete-button" (click)="remove()"></i>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
