import { Injectable } from '@angular/core';
import { IDictionaryDataModify } from '@bg-front/core/models/interfaces';
import { DialogService } from '@smart-city/core/common';
import { ILimit, ISort, NotificationService, RestService, Settings2Service } from '@smart-city/core/services';
import { ScConsole } from '@smart-city/core/utils';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IEmergencyEventDto } from 'smart-city-types';
import { IAbstractServiceData } from 'smart-city-types/services/index';
import { IEqupmentData, IKsionEventDto } from '../../models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class UnitServersService {
  constructor(
    private readonly rest: RestService,
    private readonly dialog: DialogService,
    private readonly settings: Settings2Service,
    private readonly noteService: NotificationService,
  ) {}

  protected catchErrorFn<T>(
    err: Error,
    defaultMessage: string = 'Ошибка при запросе',
    defaultValue: T = undefined,
  ): Observable<T> {
    this.noteService.pushError(defaultMessage);
    ScConsole.error([err.message]);
    return of(defaultValue);
  }

  /**
   * Метод возращающий объект "Сеанс"
   * @id - индентификтор сеанса
   */
  public getCurrentSeance(id): IDictionaryDataModify {
    return this.settings.getDictionaryById(id);
  }

  /** TODO написать интерфейс, избавиться от any
   * Получить сервер мониторинга с требуемыми полями.
   * @param query фильтр для запроса
   * @param sort сортировка записей запроса
   * @param limit ограничения запрашиваемых данных
   */
  public getKsionUnit(query?: any, limit?: ILimit, sort?: ISort): Observable<any> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Ksion' },
        entity: {
          query,
          name: 'Units',
          attributes: [
            'extId',
            'name',
            'address',
            'server',
            'coordinates',
            'currentSeance.id',
            'currentSeance.name',
            'type.name',
            'syncTime',
          ],
        },
        data: {
          limit,
          sort,
        },
      })
      .pipe(
        map((unit) => {
          return unit?.data?.items?.map((item) => ({
            id: item.id,
            name: item.name,
            extId: item.extId,
            address: item.address,
            coordinates: item.coordinates,
            server: item.server,
            currentSeanceId: item.currentSeance?.id,
            currentSeanceName: item.currentSeance?.name,
            type: item.type?.name,
            syncTime: item.syncTime,
          }));
        }),
        catchError((error: Error) =>
          this.catchErrorFn<IEmergencyEventDto>(error, 'Ошибка при запросе сервера мониторинга'),
        ),
      );
  }

  /** TODO написать интерфейс, избавиться от any
   * Получить сервер сервера мониторинга с требуемыми полями.
   * @param query фильтр для запроса
   * @param sort сортировка записей запроса
   * @param limit ограничения запрашиваемых данных
   */
  public getKsionServer(query?: any, limit?: ILimit, sort?: ISort): Observable<any> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Ksion' },
        entity: {
          query,
          name: 'Servers',
          attributes: ['name'],
        },
        data: {
          limit,
          sort,
        },
      })
      .pipe(
        map((server) => {
          catchError((error: Error) =>
            this.catchErrorFn<IEmergencyEventDto>(error, 'Ошибка при запросе сервера мониторинга'),
          );
          return server;
        }),
      );
  }

  /**
   * Получить оборудование (определенный тип) сервера.
   * @param optinos объект с опциями для запроса
   */
  public getEquipmentUnit(optinos: IEqupmentData): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Ksion' },
      entity: {
        attributes: optinos.attributes,
        name: optinos.name,
        query: {
          unit: optinos.id,
        },
      },
    });
  }

  /**
   * Получить событие интеграции Ksion
   * @param eventId - id события
   * @param attributes - аттрибуты запроса
   */
  public getKsionEvent(eventId: string, attributes?: string[]): Observable<IKsionEventDto> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Ksion' },
      entity: {
        attributes: attributes || [],
        name: 'IntegrationEvents',
        query: {
          eventId,
        },
      },
    })
      .pipe(
        map((res: IAbstractServiceData) => {
          return (res.data.items[0] || {}) as IKsionEventDto;
        }),
      );
  }

}
