<div>
  <header class="bg-row flex">
    <div class="bg-col title">Легенда</div>
    <div>
      <mat-icon class="bg-col-2 btn-close" (click)="close()">close</mat-icon>
    </div>
  </header>
  <div *ngFor="let layer of data.configurations">
    <h5 class="m-t-15"><strong>{{layer.title}}</strong></h5>
    <div *ngFor="let setting of layer.pictogramSettingsArray">
      <div class="config-title">{{setting.title}}</div>
      <div *ngFor="let value of setting.pictogramSettingsValuesArray" class=" bg-row flex state-container">
        <div class="bg-col-2 flex center">
          <img *ngIf="value.icon" class="icon" src="{{value.icon.iconUrl}}" alt="">
        </div>
        <div class="state bg-col">{{value.title}}</div>
      </div>
    </div>
  </div>
</div>
