<nz-collapse class="collapse">
  <nz-collapse-panel
    [nzHeader]="parentSource?.name"
  >
  <div nz-row class="parent-source__description">
    <div nz-col [nzSpan]="24">
      <div class="form-label">Описание</div>
      <div class="form-control-container">
        <div class="form-control">
          <span>{{ parentSource?.description}}</span>
        </div>
      </div>
    </div>
  </div>
    
    <nz-card *ngFor="let childSource of parentSource.children" [nzTitle]="titleTemplate" class="child-source">
      <ng-template #titleTemplate>
        <p class="child-source__title">{{ childSource?.name}} <span>{{ childSource?.sourceStatus }}</span></p>
      </ng-template>

      <p class="child-source__info"><span>Категория:</span> {{ childSource?.sourceCategory }}</p>
      <p class="child-source__info"><span>Тип:</span> {{ childSource?.sourceType }}</p>
    </nz-card>
  </nz-collapse-panel>
</nz-collapse>
