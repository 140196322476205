import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { Settings2Service, SfsService } from '@smart-city/core/services';
import { Observable, of } from 'rxjs';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { IFileUpload } from '@bg-front/core/models/interfaces';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { InputBoolean } from 'ng-zorro-antd/core/util';
import { FileSizeUnitEnum } from '@bg-front/core/models/enums';
import { FileSizeHelper } from '@bg-front/core/models/helpers';

/** Компонент загрузки файлов. Поддерживает загрузку нескольких файлов */
@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileUploadComponent),
    multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: FileUploadComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements ControlValueAccessor, Validator {

  /** Список файлов */
  @Input() public files: IFileUpload[] = [];

  /** Заголовок */
  @Input() public title: string;

  static ngAcceptInputType_readOnly: boolean | string | undefined | null;
  @Input() @InputBoolean() readOnly = false;

  /** Возможность загружать файлы */
  @Input()
  public uploadDisabled?: boolean;

  /** Возможность удаления файлов */
  @Input()
  public deleteDisabled?: boolean;

  /** Дотупные типы файлов */
  @Input()
  public availableTypes?: string;

  /** Типы файлов, доступные в окне выбора файла */
  @Input()
  public acceptTypes?: string;

  /** Возможность выбрать несколько файлов */
  @Input()
  public multiple?: boolean;

  /** Убрать имя загрузившего */
  @Input()
  public userDisabled?: boolean;

  /** Минимальное значение файла для загрузки */
  @Input()
  minSize: number = undefined;

  /** Максимальное значение файла для загрузки */
  @Input()
  maxSize: number = undefined;

  /** Единица измерения */
  @Input()
  unit: FileSizeUnitEnum = FileSizeUnitEnum.b;

  /** Ошибки валидации */
  private validationErrorsList: ValidationErrors[] = [];

  /** @ignore */
  constructor(
    private readonly settings: Settings2Service,
    public readonly sfs: SfsService,
    private readonly cdr: ChangeDetectorRef,
  ) {
  }

  /**
   * Обработчик события добавления файла в список
   * @param uploadArgs - информация о файле
   */
  onFileAddition(uploadArgs: NzUploadXHRArgs) {
    if(uploadArgs.file.type?.split('/')[0] === 'image') {
      this.toBase64(uploadArgs.file).then((link: string) => uploadArgs.file.link = link);
    }
    this.files.push({
      file: uploadArgs.file,
      userId: { id: this.settings.currentUser.id, fio: this.settings.currentUser.fio },
    });
    this.propagateChange(this.files);
    return of(null).subscribe();
  }

  /**
   * Удаление файла из списка файлов
   * @param fileIdx - файл из списка
   */
  public deleteFile(fileIdx: number) {
    this.files.splice(fileIdx, 1);
    this.validationErrorsList.splice(fileIdx, 1);
    this.propagateChange(this.files);
    this.cdr.detectChanges();
  }

  /**
   * Запись нового значения в компонент
   * value - новое значение
   */
  public writeValue(value: IFileUpload[]): void {
    if (value) {
      this.files = value;
      this.validationErrorsList = value?.length ? this.validationErrorsList : [];
      this.cdr.detectChanges();
    }
  }

  /** Обработать значение из компонента */
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /** Обработать потерю фокуса */
  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /** Подписка на изменения */
  private propagateChange = (_: any) => { return; };

  /** Подписка на нажатия */
  private propagateTouch = () => { return; };

  /** Установка ошибки */
  validate(control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = this.validationErrorsList.reduce((acc: ValidationErrors, item: ValidationErrors) => {
      if (item) {
        const prop = Object.keys(item)[0];
        return {
          ...acc,
          [prop]: item[prop],
        }
      }
      return acc;
    }, {});

    return Object.keys(errors).length ? errors : null;
  }

  /** Запуск валидации */
  onValidationChange: any = () => { return; };

  /** Регистрация коллбэка вызова валидации */
  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  /** Скачивание файла */
  public downloadFile(index: number): void {
    const file = this.files[index];
    this.sfs.directDownload(file.file.uuid, file.file.fileName);
  }

  /**
   * Конвертация файла в строку Base64
   * @param file Исходный файл
   */
  private toBase64(file: NzUploadFile | Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file as Blob);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  /** Коллбэк вызываемый перед загрузкой файла
   * @param file - загружаемый файл
   * Если возвращает false, то загрузка файла останавливается
   **/
  public beforeUpload(file: NzUploadFile): boolean | Observable<boolean> {
    // Проверка на диапазон
    if (this.minSize && this.maxSize) {
      if (!FileSizeHelper.isBetweenSize(file.size, this.minSize, this.maxSize, FileSizeUnitEnum.kb)) {
        this.validationErrorsList.push(
          FileSizeHelper.isMoreSize(file.size, this.minSize, FileSizeUnitEnum.kb)
            ? { maxFileSize: { value: this.maxSize, unit: FileSizeUnitEnum.kb }}
            : { minFileSize: { value: this.minSize, unit: FileSizeUnitEnum.kb }}
        );
        this.addEmptyFile(file);
        this.onValidationChange();
        return false;
      }
    }

    // Проверка на минимальное значение
    if (this.minSize) {
      if (!FileSizeHelper.isMoreSize(file.size, this.minSize, FileSizeUnitEnum.kb)) {
        this.validationErrorsList.push({ minFileSize: { value: this.minSize, unit: FileSizeUnitEnum.kb }});
        this.addEmptyFile(file);
        this.onValidationChange();
        return false;
      }
    }

    // Проверка на максимальное значение
    if (this.maxSize) {
      if (!FileSizeHelper.isLessSize(file.size, this.maxSize, FileSizeUnitEnum.kb)) {
        this.validationErrorsList.push({ maxFileSize: { value: this.maxSize, unit: FileSizeUnitEnum.kb }});
        this.addEmptyFile(file);
        this.onValidationChange();
        return false;
      }
    }

    this.validationErrorsList.push(null);
    return true;
  }

  /** Добавляет в список пустой файл, в статусе error
   * @param file - загружаемый файл
   **/
  private addEmptyFile(file: NzUploadFile): void {
    this.files.push({
      file: {
        uid: file.uid,
        name: file.name,
        size: file.size,
        status: 'error',
      },
      userId: { id: this.settings.currentUser.id, fio: this.settings.currentUser.fio },
    });
    this.propagateChange(this.files);
  }
}
