import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IOrganization } from '@bg-front/core/models/interfaces';
import { BaseCrudService } from '@bg-front/core/services';
import { IBaseDictionaryData, IScSelectItem } from '@smart-city/core/interfaces';
import { AccessService, RestService, Settings2Service, SfsService } from '@smart-city/core/services';
import * as dayjs from 'dayjs';
import {
  AlignmentType,
  BorderStyle,
  Document,
  Packer,
  PageOrientation,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
} from 'docx';
import { Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import {
  IAbstractServiceData,
  IAbstractServiceEntityField,
  IAnyObject,
  IReasonDto,
  IReportDataGroup,
  IReportEmergencyDto,
} from 'smart-city-types';

import { EmergencyDto } from '../../../../app-common/models/classes';
import {
  IDictionaryModel,
  IForecastingForestFireReport,
  IForecastingTechnologicalFireReport,
  IIncidentType,
  IRegisteredCitizensReport,
  IReportingDevice,
  IUnusableTree,
} from '../../../../app-common/models/interfaces';
import { IMunicipality } from '../../dictionaries/modules/municipalities/models/municipality.interface';
import { IReportFilter } from '../models/interfaces';
import { IReportData } from '../models/interfaces/report-data.interface';

@Injectable({
  providedIn: 'root',
})
export class ReportingService extends BaseCrudService<IReportData> {
  /** Флаг настройки формирования отчета без полей **/
  private flagTable: boolean = false;

  /** Извещение о необходимости обновить грид с отчетами */
  private reportProtocolReload = new Subject();
  public reportProtocolReload$ = this.reportProtocolReload.asObservable();

  constructor(
    rest: RestService,
    private readonly settings: Settings2Service,
    private readonly xlsxUploader: HttpClient,
    private readonly sfs: SfsService,
    private readonly access: AccessService,
  ) {
    super(
      {
        serviceName: 'Report',
        entityName: 'ReportProtocol',
      },
      rest,
    );
  }

  /**
   * Получение информации об отчете
   * @param id ID отчета
   */
  public getReportData(id: string): Observable<IReportData> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Report' },
        entity: {
          name: 'ReportProtocol',
          query: { id },
          linksMode: 'raw',
          attributes: [
            'id',
            'dateFrom',
            'dateTo',
            'filters',
            'reportUUID',
            'template.id',
            'template.title',
            'systemTemplate.sysname',
            'systemTemplate.name',
            'status.id',
            'status.name',
            'timeCreate',
            'timeUpdate',
          ],
        },
      })
      .pipe(
        map((report: IAbstractServiceData) => report.data.items[0]),
        map((report: IReportData) => ({
          ...report,
          reportType: report['template.id'] ? 'custom' : 'system',
          reportTemplate: {
            id: report['template.id'],
            sysname: report['systemTemplate.sysname'],
            name: report['template.title'] || report['systemTemplate.name'],
          },
          reportStatus: {
            id: report['status.id'],
            name: report['status.name'],
          },
        })),
      );
  }

  public getAttributes(
    service: string,
    entity: string,
    idPrefix: string = '',
    textPrefix: string = '',
    deep: number = 0,
  ): IAbstractServiceEntityField[] {
    return (
      this.settings
        .getEntityAttrs(service, entity)
        // Исключить поля ID
        .filter((attribute: IAbstractServiceEntityField) => attribute.name !== 'id')
        .map((attribute: IAbstractServiceEntityField): IAbstractServiceEntityField[] => {
          // Атрибут является ссылкой и не превышена глубина получения атрибутов для ссылки
          if (attribute.link && deep < (this.settings.getConfig().DEPTH_GETTING_ENTITY_ATTRIBUTES || 2)) {
            return [
              // Искусственно сформировать атрибут типа ссылка с именем типа name.id, для возможности фильтрации по ссылке
              // (с помощью sc-select)
              {
                ...attribute,
                name: `${idPrefix}${attribute.name}.id`,
                title: `${textPrefix}${attribute.title || attribute.name}`,
              },
              // Рекурсивно запросить атрибуты у данной ссылки
              ...this.getAttributes(
                attribute.link.service,
                attribute.link.entity,
                `${idPrefix}${attribute.name}.`,
                `${textPrefix}${attribute.title || attribute.name}.`,
                deep + 1,
              ),
            ];
          }
          // Атрибут является ссылкой и превышена глубина получения атрибутов для ссылки
          if (attribute.link && deep >= (this.settings.getConfig().DEPTH_GETTING_ENTITY_ATTRIBUTES || 2)) {
            return [
              // Искусственно сформировать атрибут типа ссылка с именем типа name.id, для возможности фильтрации по ссылке
              // (с помощью sc-select)
              {
                ...attribute,
                name: `${idPrefix}${attribute.name}.id`,
                title: `${textPrefix}${attribute.title || attribute.name}`,
              },
            ];
          }
          // Вернуть атрибут с заголовком и наименованием согласно уровню вложенности
          return [
            {
              ...attribute,
              name: `${idPrefix}${attribute.name}`,
              title: `${textPrefix}${attribute.title || attribute.name}`,
            },
          ];
        })
        // Поднять вложенный массив атрибутов наверх
        .reduce((acc: IAbstractServiceEntityField[], val: IAbstractServiceEntityField[]) => acc.concat(val), [])
    );
  }

  public getAttributesForSelect(service: string, entity: string): IScSelectItem[] {
    return this.getAttributes(service, entity).map((attribute) => ({ id: attribute.name, text: attribute.title }));
  }

  /**
   * Метод определяет есть ли в сущности по которой строится запрос поле timeCreate
   * для отключения полей фильтрации по времени создания в случае отрицательного результата.
   * @param templateId - объект, который содержит данные о выбранной для скачивания записи в гриде
   * @returns true - в случае если поле timeCreate есть в сущности отчета, иначе возвращает false.
   */
  public needTimeFilters(templateId: string): Observable<boolean> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Report' },
        entity: {
          name: 'ReportTemplate',
          query: {
            id: templateId,
          },
        },
      })
      .pipe(
        switchMap((result: IAbstractServiceData) => {
          const template = (((result || {})['data'] || {}).items || [])[0];
          if (!template) {
            return of(false);
          }
          if (
            this.settings
              .getEntityAttrs(template.service, template.entity)
              .filter((field) => field.name === 'timeCreate').length
          ) {
            return of(true);
          }
          return of(false);
        }),
      );
  }

  /**
   * Запрашивает фильтры для выбранного шаблона отчета
   * @param templateId - выбранный шаблон отчета
   * @return - список фильтров
   */
  public getFilters(templateId: string): Observable<IReportFilter[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Report' },
        entity: {
          name: 'ReportTemplate',
          query: {
            id: templateId,
          },
        },
      })
      .pipe(
        switchMap((template: IAbstractServiceData) => {
          return this.rest.serviceRequest({
            action: 'select',
            service: { name: 'Report' },
            entity: {
              name: 'ReportDataSet',
              query: {
                id: {
                  $in: [...(template.data.items[0].filters || [])],
                },
              },
            },
          });
        }),
        map((res: IAbstractServiceData) => {
          return res.data.items;
        }),
      );
  }

  /** Получение всех активных муниципальных образований */
  public getMunicipalitiesForSelect(): Observable<IScSelectItem[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Municipal',
          query: {
            active: true,
          },
          attributes: ['id', 'name'],
        },
        data: {
          sort: {
            field: 'name',
            direction: 'asc',
          },
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return (response.data.items || []).map((item: IMunicipality) => ({
            id: item.id,
            text: item.name,
          }));
        }),
      );
  }

  /** Получение пользовательских шаблонов отчетов */
  public getCustomReportTemplatesForSelect(): Observable<{ id: string; title: string }[]> {
    const user = this.settings.currentUser;
    const orgTypeParamId = user.organizationId.orgTypeParam;
    const orgId = user.organizationId.id;
    const query = {
      $expr: {
        $or: [
          { $expr: { $in: ['$organizationTypes', orgTypeParamId] } },
          { $expr: { $in: ['$organizations', orgId] } },
        ],
      },
    };
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Report' },
        entity: {
          query,
          name: 'ReportTemplate',
          attributes: ['id', 'title'],
        },
        data: {
          sort: { field: 'title', direction: 'asc' },
        },
      })
      .pipe(pluck('data', 'items'));
  }

  /** Получение системных шаблонов отчетов */
  public getSystemReportTemplatesForSelect(): Observable<{ id: string; title: string }[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query: { 'typeid.sysname': 'systemReportsTypes' },
          name: 'Dictionary',
          attributes: ['sysname', 'name'],
        },
        data: {
          sort: { field: 'name', direction: 'asc' },
        },
      })
      .pipe(
        pluck('data', 'items'),
        map((items: IBaseDictionaryData[]) =>
          items
            .map((item: IBaseDictionaryData) => ({
              id: item.sysname,
              title: item.name,
            }))
            // Фильтрация отчетов в зависимости от роли доступа
            .filter((item: { id: string; title: string }) => this.access.accessMap[item.id]?.visible),
        ),
      );
  }

  /**
   * Запуск построения пользовательского отчета
   * @param template - шаблон отчета
   * @param filters - значения фильтров
   */
  buildCustomReport(template: string, filters: IAnyObject): Observable<IReportData> {
    let dateFrom;
    let dateTo;
    if (filters.dateFrom) {
      dateFrom = dayjs(filters.dateFrom).unix() * 1000;
      delete filters.dateFrom;
    }
    if (filters.dateTo) {
      dateTo = dayjs(filters.dateTo).unix() * 1000;
      delete filters.dateTo;
    }

    return forkJoin([
      this.rest.serviceRequest({
        data: {
          template,
          dateFrom,
          dateTo,
          filters: JSON.stringify(filters),
        },
        action: 'buildCustomReport',
        service: { name: 'Report' },
        entity: { name: 'ReportProtocol' },
      }),
      this.rest.serviceRequest({
        action: 'select',
        service: { name: 'Report' },
        entity: {
          name: 'ReportTemplate',
          query: { id: template },
          attributes: ['title'],
        },
      }),
    ]).pipe(
      map((result: IAbstractServiceData[]) => ({
        ...result[0].data,
        reportTemplate: {
          name: result[1].data.items[0].title,
        },
      })),
    );
  }

  /**
   * Запуск построения годового отчета по происшествиям
   * @param template - шаблон отчета
   * @param filters - значения фильтров
   */
  buildAnnualReport(template: string, filters: IAnyObject): Observable<IReportData> {
    return this.rest
      .serviceRequest({
        data: {
          template,
          filters: JSON.stringify(filters),
        },
        action: 'buildAnnualReport',
        service: { name: 'Emergency' },
      })
      .pipe(
        map((result: IAbstractServiceData) => ({
          ...result.data,
          reportTemplate: {
            name: this.settings.getDictObjsIdsByType('systemReportsTypes')[template].name,
          },
        })),
      );
  }

  /**
   * Запуск построения отчета Статистика происшествий УК
   * @param template - шаблон отчета
   * @param filters - значения фильтров
   */
  public buildManagingOrganizationEmergencyStatistic(template: string, filters: IAnyObject): Observable<IReportData> {
    return this.rest
      .serviceRequest({
        data: {
          template,
          filters: JSON.stringify(filters),
        },
        action: 'buildManagingOrganizationEmergencyStatistic',
        service: { name: 'Emergency' },
      })
      .pipe(
        map((result: IAbstractServiceData) => ({
          ...result.data,
          reportTemplate: {
            name: this.settings.getDictObjsIdsByType('systemReportsTypes')[template].name,
          },
        })),
      );
  }

  /**
   * Запуск построения отчета Статистика вызовов по типам
   * @param template - шаблон отчета
   * @param filters - значения фильтров
   */
  public buildCallStatisticReport(template: string, filters: IAnyObject): Observable<IReportData> {
    return this.rest
      .serviceRequest({
        data: {
          template,
          filters: JSON.stringify(filters),
        },
        action: 'buildCallTypesStatistic',
        service: { name: 'Emergency' },
      })
      .pipe(
        map((result: IAbstractServiceData) => ({
          ...result.data,
          reportTemplate: {
            name: this.settings.getDictObjsIdsByType('systemReportsTypes')[template].name,
          },
        })),
      );
  }

  /**
   * Запуск построения отчета Статистика вызовов по службам
   * @param template - шаблон отчета
   * @param filters - значения фильтров
   */
  public buildCallServiceStatistic(template: string, filters: IAnyObject): Observable<IReportData> {
    return this.rest
      .serviceRequest({
        data: {
          template,
          filters: JSON.stringify(filters),
        },
        action: 'buildCallServiceStatistic',
        service: { name: 'Emergency' },
      })
      .pipe(
        map((result: IAbstractServiceData) => ({
          ...result.data,
          reportTemplate: {
            name: this.settings.getDictObjsIdsByType('systemReportsTypes')[template].name,
          },
        })),
      );
  }

  /**
   * Запуск построения отчета Статистика происшествий УК
   * @param template - шаблон отчета
   * @param filters - значения фильтров
   */
  public buildCallResponseStatistic(template: string, filters: IAnyObject): Observable<IReportData> {
    return this.rest
      .serviceRequest({
        data: {
          template,
          filters: JSON.stringify(filters),
        },
        action: 'buildCallResponseStatistic',
        service: { name: 'Emergency' },
      })
      .pipe(
        map((result: IAbstractServiceData) => ({
          ...result.data,
          reportTemplate: {
            name: this.settings.getDictObjsIdsByType('systemReportsTypes')[template].name,
          },
        })),
      );
  }

  /**
   * Запуск построения отчета Учет времени работы операторов
   * @param template - шаблон отчета
   * @param filters - значения фильтров
   */

  public buildOperatorWorkTimeStatistic(template: string, filters: IAnyObject): Observable<IReportData> {
    return this.rest
      .serviceRequest({
        data: {
          template,
          filters: JSON.stringify(filters),
        },
        action: 'buildOperatorWorkTimeStatistic',
        service: { name: 'Emergency' },
      })
      .pipe(
        map((result: IAbstractServiceData) => ({
          ...result.data,
          reportTemplate: {
            name: this.settings.getDictObjsIdsByType('systemReportsTypes')[template].name,
          },
        })),
      );
  }

  /**
   * Запуск построения отчета Статистика обращений граждан
   * @param template - шаблон отчета
   * @param filters - значения фильтров
   */
  public buildCitizenAppealStatisticReport(template: string, filters: IAnyObject): Observable<IReportData> {
    return this.rest
      .serviceRequest({
        data: {
          template,
          filters: JSON.stringify(filters),
        },
        action: 'buildCitizenAppealStatisticReport',
        service: { name: 'Emergency' },
      })
      .pipe(
        map((result: IAbstractServiceData) => ({
          ...result.data,
          reportTemplate: {
            name: this.settings.getDictObjsIdsByType('systemReportsTypes')[template].name,
          },
        })),
      );
  }

  /**
   * Скачивает отчет из sfs хранилища
   * @param reportUUID - id файла в хранилище
   */
  download(reportUUID: string) {
    this.sfs.directDownload(reportUUID);
  }

  /**
   * Формирование и скачивание отчета "Розыск ТС"
   */
  public buildVehicleSearchReport(incidentId: string): void {
    this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          query: {
            id: incidentId,
          },
          name: 'Emergency',
          attributes: [
            'id',
            'timeCreate',
            'timeStart',
            'timeFinish',
            'addressFact.fullText',
            'incidentTypeId.name',
            'description',
            'comment',
            'responsible.fio',
            'parentEventId.vaDetail.camera1Id.name',
            'parentEventId.vaDetail.markId.codeName',
            'parentEventId.vaDetail.markId.organization',
            'parentEventId.vaDetail.markId.responsible',
            'parentEventId.vaDetail.markId.contactPhone',
            'parentEventId.vaDetail.camera1Id.coordinates',
            'parentEventId.vaDetail.camera1Id.place',
            'number',
            'ksipPlaceDescription',
            'coordinates',
          ],
        },
      })
      .subscribe((response) => {
        const emergency = response.data.items[0];
        const wb = new Workbook();
        let ws: Worksheet = undefined;

        this.xlsxUploader
          .get('/assets/report-templates/vehicle_search_report.xlsx', {
            responseType: 'arraybuffer',
          })
          .subscribe((buffer) => {
            wb.xlsx.load(buffer).then((workbook) => {
              ws = workbook.getWorksheet(1);
              const dateFormat = 'DD.MM.YYYY HH:mm:ss';
              ws.getCell(3, 1).value = emergency.timeCreate ? moment(emergency.timeCreate).format(dateFormat) : '-';
              ws.getCell(3, 3).value = emergency.timeStart ? moment(emergency.timeStart).format(dateFormat) : '-';
              ws.getCell(3, 5).value = emergency.timeFinish ? moment(emergency.timeFinish).format(dateFormat) : '-';
              ws.getCell(5, 1).value = emergency['addressFact.fullText'] ? emergency['addressFact.fullText'] : '-';
              ws.getCell(7, 1).value = emergency['incidentTypeId.name'] ? emergency['incidentTypeId.name'] : '-';
              ws.getCell(9, 1).value = emergency.description ? emergency.description : '-';
              ws.getCell(9, 4).value = emergency.comment ? emergency.comment : '-';
              ws.getCell(11, 1).value = emergency['responsible.fio'] ? emergency['responsible.fio'] : '-';
              ws.getCell(15, 1).value = emergency['parentEventId.vaDetail.camera1Id.name']
                ? emergency['parentEventId.vaDetail.camera1Id.name']
                : '-';
              ws.getCell(17, 1).value = emergency['parentEventId.vaDetail.markId.codeName']
                ? emergency['parentEventId.vaDetail.markId.codeName']
                : '-';
              ws.getCell(19, 1).value = emergency['parentEventId.vaDetail.markId.organization']
                ? emergency['parentEventId.vaDetail.markId.organization']
                : '-';
              ws.getCell(21, 1).value = emergency['parentEventId.vaDetail.markId.responsible']
                ? emergency['parentEventId.vaDetail.markId.responsible']
                : '-';
              ws.getCell(21, 4).value = emergency['parentEventId.vaDetail.markId.contactPhone']
                ? emergency['parentEventId.vaDetail.markId.contactPhone']
                : '-';
              ws.getCell(25, 1).value = emergency.coordinates ? emergency.coordinates : '-';
              ws.getCell(27, 1).value = emergency.ksipPlaceDescription ? emergency.ksipPlaceDescription : '-';

              const currentDate = this.getCurrentTime('DD.MM.YYYY HH_mm_ss');
              workbook.xlsx.writeBuffer().then((buf) => {
                saveAs(
                  new Blob([buf]),
                  `Отчет по форме инцидента ${emergency['number']} по состоянию на ${currentDate}.xlsx`,
                );
              });
            });
          });
      });
  }

  /**
   * Формирование и скачивание отчета "Дубликат ГРЗ"
   */
  public buildDuplicateGRZReport(incidentId: string) {
    this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          query: {
            id: incidentId,
          },
          name: 'Emergency',
          attributes: [
            'id',
            'timeCreate',
            'timeStart',
            'timeFinish',
            'addressFact.fullText',
            'incidentTypeId.name',
            'description',
            'comment',
            'responsible.fio',
            'parentEventId.vaDetail.mark',
            'parentEventId.vaDetail.distance',
            'parentEventId.vaDetail.camera1Id.name',
            'parentEventId.vaDetail.camera1Time',
            'parentEventId.vaDetail.camera2Id.name',
            'parentEventId.vaDetail.camera2Time',
            'parentEventId.vaDetail.camera1Id.coordinates',
            'parentEventId.vaDetail.camera1Id.place',
            'parentEventId.vaDetail.camera1Id.address.fullText',
            'number',
            'coordinates',
            'ksipPlaceDescription',
          ],
        },
      })
      .subscribe((response) => {
        const emergency = response.data.items[0];
        const wb = new Workbook();
        let ws: Worksheet = undefined;

        this.xlsxUploader
          .get('/assets/report-templates/duplicate_grz.xlsx', {
            responseType: 'arraybuffer',
          })
          .subscribe((buffer) => {
            wb.xlsx.load(buffer).then((workbook) => {
              ws = workbook.getWorksheet(1);
              const dateFormat = 'DD.MM.YYYY HH:mm:ss';
              ws.getCell(3, 1).value = emergency.timeCreate ? moment(emergency.timeCreate).format(dateFormat) : '-';
              ws.getCell(3, 3).value = emergency.timeStart ? moment(emergency.timeStart).format(dateFormat) : '-';
              ws.getCell(3, 5).value = emergency.timeFinish ? moment(emergency.timeFinish).format(dateFormat) : '-';
              ws.getCell(5, 1).value = emergency['addressFact.fullText'] ? emergency['addressFact.fullText'] : '-';
              ws.getCell(7, 1).value = emergency['incidentTypeId.name'] ? emergency['incidentTypeId.name'] : '-';
              ws.getCell(9, 1).value = emergency.description ? emergency.description : '-';
              ws.getCell(9, 4).value = emergency.comment ? emergency.comment : '-';
              ws.getCell(11, 1).value = emergency['responsible.fio'] ? emergency['responsible.fio'] : '-';
              ws.getCell(15, 1).value = emergency['parentEventId.vaDetail.mark']
                ? emergency['parentEventId.vaDetail.mark']
                : '-';
              ws.getCell(15, 4).value = emergency['parentEventId.vaDetail.distance']
                ? `${parseFloat(emergency['parentEventId.vaDetail.distance'])} м`
                : '-';
              ws.getCell(19, 1).value = emergency['parentEventId.vaDetail.camera1Id.name']
                ? emergency['parentEventId.vaDetail.camera1Id.name']
                : '-';
              ws.getCell(19, 4).value = emergency['parentEventId.vaDetail.camera2Id.name']
                ? emergency['parentEventId.vaDetail.camera2Id.name']
                : '-';
              ws.getCell(21, 1).value = emergency['parentEventId.vaDetail.camera1Time']
                ? moment(parseInt(emergency['parentEventId.vaDetail.camera1Time'], 10)).format(dateFormat)
                : '-';
              ws.getCell(21, 4).value = emergency['parentEventId.vaDetail.camera2Time']
                ? moment(parseInt(emergency['parentEventId.vaDetail.camera2Time'], 10)).format(dateFormat)
                : '-';
              ws.getCell(25, 1).value = emergency.coordinates ? emergency.coordinates : '-';
              ws.getCell(27, 1).value = emergency.ksipPlaceDescription ? emergency.ksipPlaceDescription : '-';
              ws.getCell(31, 1).value = emergency['parentEventId.vaDetail.camera1Id.coordinates']
                ? emergency['parentEventId.vaDetail.camera1Id.coordinates']
                : '-';
              ws.getCell(31, 4).value = emergency['parentEventId.vaDetail.camera1Id.address.fullText']
                ? emergency['parentEventId.vaDetail.camera1Id.address.fullText']
                : '-';

              const currentDate = this.getCurrentTime('DD.MM.YYYY HH_mm_ss');
              workbook.xlsx.writeBuffer().then((buf) => {
                saveAs(
                  new Blob([buf]),
                  `Отчет по форме инцидента ${emergency['number']} по состоянию на ${currentDate}.xlsx`,
                );
              });
            });
          });
      });
  }

  /**
   * Формирование и скачивание отчета "Громкий звук"
   */
  public buildLoudSoundReport(incidentId: string) {
    this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          query: {
            id: incidentId,
          },
          name: 'Emergency',
          attributes: [
            'id',
            'timeCreate',
            'timeStart',
            'timeFinish',
            'addressFact.fullText',
            'incidentTypeId.name',
            'description',
            'comment',
            'responsible.fio',
            'resolution',
            'parentEventId.vaDetail.camera1Id.name',
            'parentEventId.vaDetail.camera1Id.coordinates',
            'parentEventId.vaDetail.camera1Id.place',
            'ksipPlaceDescription',
            'number',
            'coordinates',
          ],
        },
      })
      .subscribe((response) => {
        const emergency = response.data.items[0];
        const wb = new Workbook();
        let ws: Worksheet = undefined;

        this.xlsxUploader
          .get('/assets/report-templates/loud_sound.xlsx', {
            responseType: 'arraybuffer',
          })
          .subscribe((buffer) => {
            wb.xlsx.load(buffer).then((workbook) => {
              ws = workbook.getWorksheet(2);
              const dateFormat = 'DD.MM.YYYY HH:mm:ss';
              ws.getCell(3, 1).value = emergency.timeCreate ? moment(emergency.timeCreate).format(dateFormat) : '-';
              ws.getCell(3, 3).value = emergency.timeStart ? moment(emergency.timeStart).format(dateFormat) : '-';
              ws.getCell(3, 5).value = emergency.timeFinish ? moment(emergency.timeFinish).format(dateFormat) : '-';
              ws.getCell(5, 1).value = emergency['addressFact.fullText'] ? emergency['addressFact.fullText'] : '-';
              ws.getCell(7, 1).value = emergency['incidentTypeId.name'] ? emergency['incidentTypeId.name'] : '-';
              ws.getCell(9, 1).value = emergency.description ? emergency.description : '-';
              ws.getCell(9, 4).value = emergency.comment ? emergency.comment : '-';
              ws.getCell(10, 3).value = emergency.resolution?.danger ? 'Есть' : '-';
              ws.getCell(11, 3).value = emergency.resolution?.dangerOrg ? 'Есть' : '-';
              ws.getCell(11, 4).value = emergency['responsible.fio'] ? emergency['responsible.fio'] : '-';
              ws.getCell(15, 1).value = emergency['parentEventId.vaDetail.camera1Id.name']
                ? emergency['parentEventId.vaDetail.camera1Id.name']
                : '-';
              ws.getCell(19, 1).value = emergency.coordinates ? emergency.coordinates : '-';
              ws.getCell(21, 1).value = emergency.ksipPlaceDescription ? emergency.ksipPlaceDescription : '-';
              ws.getCell(25, 1).value = emergency.resolution?.report ? emergency.resolution?.report : '-';

              const currentDate = this.getCurrentTime('DD.MM.YYYY HH_mm_ss');
              workbook.xlsx.writeBuffer().then((buf) => {
                saveAs(
                  new Blob([buf]),
                  `Отчет по форме инцидента ${emergency['number']} по состоянию на ${currentDate}.xlsx`,
                );
              });
            });
          });
      });
  }

  /**
   * Формирование и скачивание отчета "Оставленный предмет"
   */
  public buildThingReport(incidentId: string) {
    this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          query: {
            id: incidentId,
          },
          name: 'Emergency',
          attributes: [
            'id',
            'timeCreate',
            'timeStart',
            'timeFinish',
            'addressFact.fullText',
            'incidentTypeId.name',
            'description',
            'comment',
            'responsible.fio',
            'resolution',
            'parentEventId.vaDetail.camera1Id.name',
            'parentEventId.vaDetail.camera1Id.coordinates',
            'parentEventId.vaDetail.camera1Id.place',
            'ksipPlaceDescription',
            'number',
            'coordinates',
          ],
        },
      })
      .subscribe((response) => {
        const emergency = response.data.items[0];
        const wb = new Workbook();
        let ws: Worksheet = undefined;

        this.xlsxUploader
          .get('/assets/report-templates/loud_sound.xlsx', {
            responseType: 'arraybuffer',
          })
          .subscribe((buffer) => {
            wb.xlsx.load(buffer).then((workbook) => {
              ws = workbook.getWorksheet(2);
              const dateFormat = 'DD.MM.YYYY HH:mm:ss';
              ws.getCell(3, 1).value = emergency.timeCreate ? moment(emergency.timeCreate).format(dateFormat) : '-';
              ws.getCell(3, 3).value = emergency.timeStart ? moment(emergency.timeStart).format(dateFormat) : '-';
              ws.getCell(3, 5).value = emergency.timeFinish ? moment(emergency.timeFinish).format(dateFormat) : '-';
              ws.getCell(5, 1).value = emergency['addressFact.fullText'] ? emergency['addressFact.fullText'] : '-';
              ws.getCell(7, 1).value = emergency['incidentTypeId.name'] ? emergency['incidentTypeId.name'] : '-';
              ws.getCell(9, 1).value = emergency.description ? emergency.description : '-';
              ws.getCell(9, 4).value = emergency.comment ? emergency.comment : '-';
              ws.getCell(10, 3).value = emergency.resolution?.danger ? 'Есть' : '-';
              ws.getCell(11, 3).value = emergency.resolution?.dangerOrg ? 'Есть' : '-';
              ws.getCell(11, 4).value = emergency['responsible.fio'] ? emergency['responsible.fio'] : '-';
              ws.getCell(15, 1).value = emergency['parentEventId.vaDetail.camera1Id.name']
                ? emergency['parentEventId.vaDetail.camera1Id.name']
                : '-';
              ws.getCell(19, 1).value = emergency.coordinates ? emergency.coordinates : '-';
              ws.getCell(21, 1).value = emergency.ksipPlaceDescription ? emergency.ksipPlaceDescription : '-';
              ws.getCell(25, 1).value = emergency.resolution?.report ? emergency.resolution?.report : '-';

              const currentDate = this.getCurrentTime('DD.MM.YYYY HH_mm_ss');
              workbook.xlsx.writeBuffer().then((buf) => {
                saveAs(
                  new Blob([buf]),
                  `Отчет по форме инцидента ${emergency['number']} по состоянию на ${currentDate}.xlsx`,
                );
              });
            });
          });
      });
  }

  /**
   * Формирование и скачивание отчета "Массовое скопление"
   */
  public buildCrowdsReport(incidentId: string) {
    this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          query: {
            id: incidentId,
          },
          name: 'Emergency',
          attributes: [
            'id',
            'timeCreate',
            'timeStart',
            'timeFinish',
            'addressFact.fullText',
            'incidentTypeId.name',
            'description',
            'comment',
            'responsible.fio',
            'resolution',
            'parentEventId.vaDetail.camera1Id.name',
            'parentEventId.vaDetail.camera1Id.coordinates',
            'parentEventId.vaDetail.camera1Id.place',
            'coordinates',
            'ksipPlaceDescription',
            'parentEventId.vaDetail.persons',
            'parentEventId.vaDetail.personsLimit',
            'number',
          ],
        },
      })
      .subscribe((response) => {
        const emergency = response.data.items[0];
        const wb = new Workbook();
        let ws: Worksheet = undefined;

        this.xlsxUploader
          .get('/assets/report-templates/crowds.xlsx', {
            responseType: 'arraybuffer',
          })
          .subscribe((buffer) => {
            wb.xlsx.load(buffer).then((workbook) => {
              ws = workbook.getWorksheet(2);
              if (ws) {
                const dateFormat = 'DD.MM.YYYY HH:mm:ss';
                ws.getCell(3, 1).value = emergency.timeCreate ? moment(emergency.timeCreate).format(dateFormat) : '-';
                ws.getCell(3, 3).value = emergency.timeStart ? moment(emergency.timeStart).format(dateFormat) : '-';
                ws.getCell(3, 5).value = emergency.timeFinish ? moment(emergency.timeFinish).format(dateFormat) : '-';
                ws.getCell(5, 1).value = emergency['addressFact.fullText'] ? emergency['addressFact.fullText'] : '-';
                ws.getCell(7, 1).value = emergency['incidentTypeId.name'] ? emergency['incidentTypeId.name'] : '-';
                ws.getCell(9, 1).value = emergency.description ? emergency.description : '-';
                ws.getCell(9, 4).value = emergency.comment ? emergency.comment : '-';
                ws.getCell(10, 3).value = emergency.resolution?.danger ? 'Есть' : '-';
                ws.getCell(11, 3).value = emergency.resolution?.dangerOrg ? 'Есть' : '-';
                ws.getCell(11, 4).value = emergency['responsible.fio'] ? emergency['responsible.fio'] : '-';
                ws.getCell(15, 1).value = emergency['parentEventId.vaDetail.camera1Id.name']
                  ? emergency['parentEventId.vaDetail.camera1Id.name']
                  : '-';
                ws.getCell(17, 1).value = emergency['parentEventId.vaDetail.persons']
                  ? emergency['parentEventId.vaDetail.persons']
                  : '-';
                ws.getCell(17, 4).value = emergency['parentEventId.vaDetail.personsLimit']
                  ? emergency['parentEventId.vaDetail.personsLimit']
                  : '-';
                ws.getCell(21, 1).value = emergency.coordinates ? emergency.coordinates : '-';
                ws.getCell(23, 1).value = emergency.ksipPlaceDescription ? emergency.ksipPlaceDescription : '-';
                ws.getCell(27, 1).value = emergency.resolution?.report ? emergency.resolution?.report : '-';

                const currentDate = this.getCurrentTime('DD.MM.YYYY HH_mm_ss');
                workbook.xlsx.writeBuffer().then((buf) => {
                  saveAs(
                    new Blob([buf]),
                    `Отчет по форме инцидента ${emergency['number']} по состоянию на ${currentDate}.xlsx`,
                  );
                });
              }
            });
          });
      });
  }

  /**
   * Формирование и скачивание отчета "Саботаж"
   */
  public buildSabotageReport(incidentId: string) {
    this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          query: {
            id: incidentId,
          },
          name: 'Emergency',
          attributes: [
            'id',
            'timeCreate',
            'timeStart',
            'timeFinish',
            'addressFact.fullText',
            'incidentTypeId.name',
            'description',
            'comment',
            'responsible.fio',
            'resolution',
            'parentEventId.vaDetail.camera1Id.name',
            'parentEventId.vaDetail.camera1Id.coordinates',
            'parentEventId.vaDetail.camera1Id.place',
            'parentEventId.vaDetail.reasonId.name',
            'number',
            'coordinates',
            'ksipPlaceDescription',
          ],
        },
      })
      .subscribe((response) => {
        const emergency = response.data.items[0];
        const wb = new Workbook();
        let ws: Worksheet = undefined;

        this.xlsxUploader
          .get('/assets/report-templates/sabotage.xlsx', {
            responseType: 'arraybuffer',
          })
          .subscribe((buffer) => {
            wb.xlsx.load(buffer).then((workbook) => {
              ws = workbook.getWorksheet(1);
              if (ws) {
                const dateFormat = 'DD.MM.YYYY HH:mm:ss';
                ws.getCell(3, 1).value = emergency.timeCreate ? moment(emergency.timeCreate).format(dateFormat) : '-';
                ws.getCell(3, 3).value = emergency.timeStart ? moment(emergency.timeStart).format(dateFormat) : '-';
                ws.getCell(3, 5).value = emergency.timeFinish ? moment(emergency.timeFinish).format(dateFormat) : '-';
                ws.getCell(5, 1).value = emergency['addressFact.fullText'] ? emergency['addressFact.fullText'] : '-';
                ws.getCell(7, 1).value = emergency['incidentTypeId.name'] ? emergency['incidentTypeId.name'] : '-';
                ws.getCell(9, 1).value = emergency.description ? emergency.description : '-';
                ws.getCell(9, 4).value = emergency.comment ? emergency.comment : '-';
                ws.getCell(11, 1).value = emergency['responsible.fio'] ? emergency['responsible.fio'] : '-';
                ws.getCell(15, 1).value = emergency['parentEventId.vaDetail.camera1Id.name']
                  ? emergency['parentEventId.vaDetail.camera1Id.name']
                  : '-';
                ws.getCell(17, 1).value = emergency['parentEventId.vaDetail.reasonId.name']
                  ? emergency['parentEventId.vaDetail.reasonId.name']
                  : '-';
                ws.getCell(21, 1).value = emergency.coordinates ? emergency.coordinates : '-';
                ws.getCell(23, 1).value = emergency.ksipPlaceDescription ? emergency.ksipPlaceDescription : '-';
                ws.getCell(27, 1).value = emergency.resolution?.report ? emergency.resolution?.report : '-';

                const currentDate = this.getCurrentTime('DD.MM.YYYY HH_mm_ss');
                workbook.xlsx.writeBuffer().then((buf) => {
                  saveAs(
                    new Blob([buf]),
                    `Отчет по форме инцидента ${emergency['number']} по состоянию на ${currentDate}.xlsx`,
                  );
                });
              }
            });
          });
      });
  }

  /**
   * Формирование и скачивание отчета по форме инцидента для ЕДДС, Вызовы ЕДДС, 112
   */
  public buildEddsCallEdds112Report(incidentId: string) {
    this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          query: {
            id: incidentId,
          },
          name: 'Emergency',
          attributes: [
            'number',
            'timeCreate',
            'timeStart',
            'timeFinish',
            'addressFact.fullText',
            'detailsFact.corp',
            'detailsFact.storeys',
            'detailsFact.floor',
            'detailsFact.entrance',
            'detailsFact.room',
            'detailsFact.km',
            'incidentTypeId.name',
            'description',
            'comment',
            'resolution.danger',
            'resolution.dangerOrg',
            'responsible.fio',
            'coordinates',
            'ksipPlaceDescription',
            'address.fullText',
            'details.corp',
            'details.storeys',
            'details.floor',
            'details.entrance',
            'details.room',
            'details.km',
            'resolution.totalCars',
            'resolution.fireCar',
            'resolution.policeCar',
            'resolution.ambulance',
            'resolution.otherCars',
            'resolution.timeLimit',
            'resolution.additionalInfoAboutCars',
            'resolution.report',
            'resolution.death',
            'resolution.deathChildren',
            'resolution.deathOnDate',
            'resolution.deathPlace',
            'resolution.rescued',
            'resolution.rescuedChildren',
            'resolution.rescuedOnDate',
            'resolution.rescuedPlace',
            'resolution.victim',
            'resolution.victimChildren',
            'resolution.victimOnDate',
            'resolution.victimPlace',
            'resolution.hospitalized',
            'resolution.hospitalizedChildren',
            'resolution.hospitalizedOnDate',
            'resolution.missing',
            'resolution.missingChildren',
            'resolution.missingOnDate',
            'resolution.incidentFinishTime',
          ],
        },
      })
      .subscribe((response) => {
        const emergency = response.data.items[0];
        const wb = new Workbook();
        let ws: Worksheet = undefined;

        this.xlsxUploader
          .get('/assets/report-templates/edds_calledds_112.xlsx', {
            responseType: 'arraybuffer',
          })
          .subscribe((buffer) => {
            wb.xlsx.load(buffer).then((workbook) => {
              ws = workbook.getWorksheet(1);
              if (ws) {
                const dateFormat = 'DD.MM.YYYY HH:mm:ss';
                const timeCreate = emergency.timeCreate ? moment(emergency.timeCreate).format(dateFormat) : '–––';
                ws.getCell(3, 1).value = timeCreate;
                ws.getCell(3, 3).value = emergency.timeStart ? moment(emergency.timeStart).format(dateFormat) : '–––';
                ws.getCell(3, 5).value = emergency.timeFinish ? moment(emergency.timeFinish).format(dateFormat) : '–––';

                // Некоторые строки закомментированы в рамках BG-5654.
                // Для опытной эксплуатации убрал костыль с расширением высоты строки.
                // ws.getRow(5).height = 0;
                ws.getCell(5, 1).value = this.prepareValue(emergency['addressFact.fullText']);
                // ws.getCell(5, 101).value = this.prepareValue(emergency['addressFact.fullText']);

                ws.getCell(7, 1).value = this.prepareValue(emergency['detailsFact.corp']);
                ws.getCell(7, 2).value = this.prepareValue(emergency['detailsFact.storeys']);
                ws.getCell(7, 3).value = this.prepareValue(emergency['detailsFact.floor']);
                ws.getCell(7, 4).value = this.prepareValue(emergency['detailsFact.entrance']);
                ws.getCell(7, 5).value = this.prepareValue(emergency['detailsFact.room']);
                ws.getCell(7, 6).value = this.prepareValue(emergency['detailsFact.km']);

                ws.getCell(9, 1).value = emergency['incidentTypeId.name'] || '–––';

                // ws.getRow(11).height = 0;
                ws.getCell(11, 1).value = this.prepareValue(emergency['description']);
                // ws.getCell(11, 102).value = this.prepareValue(emergency['description']);
                ws.getCell(11, 4).value = this.prepareValue(emergency['comment']);
                // ws.getCell(11, 103).value = this.prepareValue(emergency['comment']);

                ws.getCell(12, 3).value = emergency['resolution.danger'] === 'true' ? 'Есть' : '–––';
                ws.getCell(13, 3).value = emergency['resolution.dangerOrg'] === 'true' ? 'Есть' : '–––';
                ws.getCell(13, 4).value = emergency['responsible.fio'] || '–––';

                ws.getCell(17, 1).value = emergency['coordinates'] || '–––';

                // ws.getRow(19).height = 0;
                ws.getCell(19, 1).value = this.prepareValue(emergency['ksipPlaceDescription']);
                // ws.getCell(19, 101).value = this.prepareValue(emergency['ksipPlaceDescription']);

                // ws.getRow(21).height = 0;
                ws.getCell(21, 1).value = this.prepareValue(emergency['address.fullText']);
                // ws.getCell(21, 101).value = this.prepareValue(emergency['address.fullText']);

                ws.getCell(23, 1).value = this.prepareValue(emergency['details.corp']);
                ws.getCell(23, 2).value = this.prepareValue(emergency['details.storeys']);
                ws.getCell(23, 3).value = this.prepareValue(emergency['details.floor']);
                ws.getCell(23, 4).value = this.prepareValue(emergency['details.entrance']);
                ws.getCell(23, 5).value = this.prepareValue(emergency['details.room']);
                ws.getCell(23, 6).value = this.prepareValue(emergency['details.km']);

                ws.getCell(26, 3).value = this.prepareValue(emergency['resolution.totalCars'], '0');
                ws.getCell(27, 3).value = this.prepareValue(emergency['resolution.fireCar'], '0');
                ws.getCell(28, 3).value = this.prepareValue(emergency['resolution.policeCar'], '0');
                ws.getCell(26, 6).value = this.prepareValue(emergency['resolution.ambulance'], '0');
                ws.getCell(27, 6).value = this.prepareValue(emergency['resolution.otherCars'], '0');
                ws.getCell(28, 6).value = emergency['resolution.incidentFinishTime']
                  ? moment(+emergency['resolution.incidentFinishTime']).format(dateFormat)
                  : '–––';

                // ws.getRow(30).height = 0;
                ws.getCell(30, 1).value = this.prepareValue(emergency['resolution.additionalInfoAboutCars']);
                // ws.getCell(30, 101).value = this.prepareValue(emergency['resolution.additionalInfoAboutCars']);
                // ws.getRow(32).height = 0;
                ws.getCell(32, 1).value = this.prepareValue(emergency['resolution.report']);
                // ws.getCell(32, 101).value = this.prepareValue(emergency['resolution.report']);

                ws.getCell(36, 1).value = this.prepareValue(emergency['resolution.death'], '0');
                ws.getCell(36, 3).value = this.prepareValue(emergency['resolution.deathChildren'], '0');
                ws.getCell(36, 5).value = emergency['resolution.deathOnDate']
                  ? moment(+emergency['resolution.deathOnDate']).format(dateFormat)
                  : timeCreate;
                // ws.getRow(38).height = 0;
                ws.getCell(38, 1).value = this.prepareValue(emergency['resolution.deathPlace']);
                // ws.getCell(38, 101).value = this.prepareValue(emergency['resolution.deathPlace']);
                ws.getCell(40, 1).value = this.prepareValue(emergency['resolution.rescued'], '0');
                ws.getCell(40, 3).value = this.prepareValue(emergency['resolution.rescuedChildren'], '0');
                ws.getCell(40, 5).value = emergency['resolution.rescuedOnDate']
                  ? moment(+emergency['resolution.rescuedOnDate']).format(dateFormat)
                  : timeCreate;
                // ws.getRow(42).height = 0;
                ws.getCell(42, 1).value = this.prepareValue(emergency['resolution.rescuedPlace']);
                // ws.getCell(42, 101).value = this.prepareValue(emergency['resolution.rescuedPlace']);
                ws.getCell(44, 1).value = this.prepareValue(emergency['resolution.victim'], '0');
                ws.getCell(44, 3).value = this.prepareValue(emergency['resolution.victimChildren'], '0');
                ws.getCell(44, 5).value = emergency['resolution.victimOnDate']
                  ? moment(+emergency['resolution.victimOnDate']).format(dateFormat)
                  : timeCreate;
                // ws.getRow(46).height = 0;
                ws.getCell(46, 1).value = this.prepareValue(emergency['resolution.victimPlace']);
                // ws.getCell(46, 101).value = this.prepareValue(emergency['resolution.victimPlace']);
                ws.getCell(48, 1).value = this.prepareValue(emergency['resolution.hospitalized'], '0');
                ws.getCell(48, 3).value = this.prepareValue(emergency['resolution.hospitalizedChildren'], '0');
                ws.getCell(48, 5).value = emergency['resolution.hospitalizedOnDate']
                  ? moment(+emergency['resolution.hospitalizedOnDate']).format(dateFormat)
                  : timeCreate;
                ws.getCell(50, 1).value = this.prepareValue(emergency['resolution.missing'], '0');
                ws.getCell(50, 3).value = this.prepareValue(emergency['resolution.missingChildren'], '0');
                ws.getCell(50, 5).value = emergency['resolution.missingOnDate']
                  ? moment(+emergency['resolution.missingOnDate']).format(dateFormat)
                  : timeCreate;

                // todo область печати
                const currentDate = this.getCurrentTime('DD.MM.YYYY HH_mm_ss');
                workbook.xlsx.writeBuffer().then((buf) => {
                  saveAs(
                    new Blob([buf]),
                    `Отчет по форме инцидента ${emergency['number']} по состоянию на ${currentDate}.xlsx`,
                  );
                });
              }
            });
          });
      });
  }

  /**
   * Формирование и скачивание отчета "Донесение по форме" (проишествие ЕДДС)
   */
  public buildReportEmergency(emergency, report) {
    const isOrder = this.settings.getDictObjByTypeSysName('docType').order.id === emergency.docType;
    forkJoin([
      this.rest
        .serviceRequest({
          action: 'select',
          service: { name: 'Report' },
          entity: {
            query: {
              emergencyId: isOrder ? (<EmergencyDto>emergency.parentId)?.id || emergency.parentId : emergency.id,
              'typeId.sysname': report.sysname,
            },
            name: 'ReportEmergency',
          },
        })
        .pipe(
          map((response: IAbstractServiceData) => {
            return response?.data?.items[0];
          }),
        ),
      this.rest
        .serviceRequest({
          action: 'select',
          service: { name: 'Admin' },
          entity: {
            name: 'Settings',
            query: {
              sysName: 'noEmptyRowsDownload',
            },
          },
        })
        .pipe(
          map((response: IAbstractServiceData) => {
            return (response?.data?.items || [])[0] as any;
          }),
        ),
    ]).subscribe((resp: IAnyObject) => {
      const cash = resp[0];
      this.flagTable = resp[1].value === 'false';

      const wb = new Workbook();
      let ws: Worksheet = undefined;

      const currentTime = this.getCurrentTime('DD.MM.YYYY HH:mm:ss');
      const user = this.settings.currentUser;

      let fileName: string;
      let page: number | string;
      let topTitle: string;
      let title: string;
      let finalFileName: string;

      switch (report.sysname) {
        case 'chs1':
          fileName = 'report_by_form_1-CHS';
          page = 1;
          topTitle = 'Форма 1/ЧС';
          title = 'Донесение об угрозе (прогнозе) чрезвычайной ситуации';
          finalFileName = 'Донесение по форме 1_ЧС';
          break;
        case 'chs2':
          fileName = 'report_by_form_2-CHS';
          page = 1;
          topTitle = 'Форма 2/ЧС';
          title = 'Донесение о факте и основных параметрах чрезвычайной ситуации';
          finalFileName = 'Донесение по форме 2_ЧС';
          break;
        case 'chs3':
          fileName = 'report_by_form_3-CHS';
          page = 1;
          topTitle = 'Форма 3/ЧС';
          title =
            'Донесение о мерах по защите населения и территорий, ведении ' +
            'аварийно-восстановительных и других неотложных работ';
          finalFileName = 'Донесение по форме 3_ЧС';
          break;
        case 'chs4':
          fileName = 'report_by_form_4-CHS';
          page = 1;
          topTitle = 'Форма 4/ЧС';
          title = 'Донесение о силах и средствах, задействованных для ликвидации ЧС';
          finalFileName = 'Донесение по форме 4_ЧС';
          break;
        case 'chs5':
          fileName = 'report_by_form_5-CHS';
          page = '5-ЧС';
          topTitle = 'Форма 5/ЧС';
          title = 'Итоговое донесение о чрезвычайной ситуации';
          finalFileName = 'Донесение по форме 5_ЧС';
          break;
      }

      this.xlsxUploader
        .get(`/assets/report-templates/${fileName}.xlsx`, {
          responseType: 'arraybuffer',
        })
        .subscribe((buffer) => {
          /**
           * Формироание отчета в формате docx
           */
          const doc = new Document({ title });

          /** Формирование загаловка отчета */
          let titleChildren: TextRun[] = [];
          if (report.sysname !== 'chs5') {
            titleChildren.push(this.createText('Донесение', 12).break());
          }
          titleChildren = titleChildren.concat([this.createText(title.replace(/^Донесение\s*/, ''), 12).break()]);

          /** Форсирование таблицы отчета */
          // Строки таблицы отчета с инициализацией заголовочной строки
          let rows: TableRow[];
          if (report.sysname === 'chs4') {
            // Хедер таблицы для отчета ЧС4
            rows = [
              new TableRow({
                children: [
                  this.createCell('Подразделения', AlignmentType.CENTER, VerticalAlign.CENTER),
                  this.createCell('Личный состав', AlignmentType.CENTER, VerticalAlign.CENTER),
                  this.createCell('Техника', AlignmentType.CENTER, VerticalAlign.CENTER),
                  this.createCell(
                    `Должность, фамилия, имя, отчество (при наличии) и телефон ответственного лица`,
                    AlignmentType.CENTER,
                    VerticalAlign.CENTER,
                  ),
                ],
              }),
            ];
          } else {
            // Хедер таблицы для остальных отчетов
            rows = [
              new TableRow({
                // TODO высота 2 строки
                children: [
                  this.createCell(' ', AlignmentType.CENTER, VerticalAlign.CENTER),
                  this.createCell('Показатели', AlignmentType.CENTER, VerticalAlign.CENTER),
                  this.createCell('Содержание донесения', AlignmentType.CENTER, VerticalAlign.CENTER),
                ],
              }),
            ];
          }

          // Перебор групп параметров (аккардионы)
          if (report.sysname !== 'chs4') {
            for (let i = 0; i < cash.data.length; i++) {
              if (this.isNotEmpty(cash.data, i) && !cash.data[i].skipTitle) {
                // добавляем нестандартные строки заголовков в отчет
                const isChs5Title = cash.data[i].cellOfName[0] === 68 || cash.data[i].cellOfName[0] === 70;
                if (report.sysname === 'chs5' && isChs5Title) {
                  rows.push(
                    new TableRow({
                      children: [
                        this.createCell(cash.data[i].name, AlignmentType.LEFT, VerticalAlign.CENTER, false, 12, 3, 0),
                      ],
                    }),
                  );
                } else {
                  rows.push(
                    new TableRow({
                      children: [
                        this.createCell(cash.data[i].cellOfName[1].toString(), AlignmentType.CENTER),
                        this.createCell(cash.data[i].name, AlignmentType.LEFT, VerticalAlign.CENTER, false, 12, 2, 0),
                      ],
                    }),
                  );
                }
              }

              if (!cash.data[i].fields) {
                continue;
              }
              // Смещение строки, которую растягиваем вниз
              let rowOffset = 1;
              // Смещение строки в кеше из-за пропускания строк-параметров без значений
              let cashRowOffset = 0;
              // Перебор параметров в группе параметров (внутри аккордиона)
              for (let y = 0; y < cash.data[i].fields.length; y++) {
                // Проверка на существование значения у строки-параметра
                if (this.isNotEmpty(cash.data, i, y)) {
                  if (cash.data[i].fields[y].number === cash.data[i].fields[y - rowOffset - cashRowOffset]?.number) {
                    // заменяем строку для переустановки rowSpan, чтобы растянуть ячейку с номером параметра
                    rows[rows.length - rowOffset] = new TableRow({
                      children: [
                        this.createCell(
                          cash.data[i].fields[y].number,
                          AlignmentType.CENTER,
                          VerticalAlign.CENTER,
                          false,
                          undefined,
                          undefined,
                          rowOffset + 1,
                        ),
                        rows[rows.length - rowOffset].cells[1],
                        rows[rows.length - rowOffset].cells[2],
                      ],
                    });
                    rowOffset++;
                  } else {
                    rowOffset = 1;
                  }
                  // Удаляем  служебное значение из внутреннего сабтайтла
                  if (cash.data[i].fields[y].type === 'subtitle') {
                    cash.data[i].fields[y].value = '';
                  }

                  /**
                   * Так как система построения отчетов сейчас недостаточно гибкая, и нужно обеспечить
                   * полное соответствие документов формата word, для нестандартных отчетов приходится
                   * добавлять строки в документ дополнительно таким способом.
                   **/
                  const chs3Report = report.sysname === 'chs3' && cash.data[i].fields[y].cellOfName[0] === 23;
                  const chs2Report =
                    report.sysname === 'chs2' &&
                    (cash.data[i].fields[y].cellOfName[0] === 35 || cash.data[i].fields[y].cellOfName[0] === 36);

                  if (chs3Report || chs2Report) {
                    rows.push(
                      new TableRow({
                        children: [
                          this.createCell(
                            cash.data[i].fields[y].number || '',
                            AlignmentType.CENTER,
                            VerticalAlign.CENTER,
                          ),
                          this.createCell(
                            cash.data[i].fields[y].name,
                            AlignmentType.LEFT,
                            VerticalAlign.CENTER,
                            false,
                            12,
                            2,
                            0,
                          ),
                        ],
                      }),
                      new TableRow({
                        children: [
                          this.createCell('', AlignmentType.LEFT, VerticalAlign.CENTER),
                          this.createCell(
                            cash.data[i].fields[y].value,
                            AlignmentType.LEFT,
                            VerticalAlign.CENTER,
                            false,
                            12,
                            2,
                            0,
                          ),
                        ],
                      }),
                    );
                  } else {
                    rows.push(
                      new TableRow({
                        children: [
                          this.createCell(
                            cash.data[i].fields[y].number || '',
                            AlignmentType.CENTER,
                            VerticalAlign.CENTER,
                          ),
                          this.createCell(cash.data[i].fields[y].name, undefined, VerticalAlign.CENTER),
                          this.createCell(cash.data[i].fields[y].value || '', undefined, VerticalAlign.CENTER),
                        ],
                      }),
                    );
                  }

                  // Удаляем лишнюю ячейку с Кодом (номером параметра), если предыдущая "растягивается" вниз
                  if (rowOffset > 1) {
                    rows[rows.length - 1].cells[0].delete();
                  }
                  cashRowOffset = 0;
                } else {
                  cashRowOffset++;
                }
              }
            }
          } else {
            // Если форма отчета ЧС4
            for (let i = 0; i < cash.data.length; i++) {
              if (cash.data[i].type === 'subtitle' && this.isNotEmpty(cash.data, i)) {
                rows.push(
                  new TableRow({
                    children: [
                      this.createCell(cash.data[i].name, AlignmentType.LEFT, VerticalAlign.CENTER, false, 12, 4, 0),
                    ],
                  }),
                );
              }

              if (cash.data[i].fields && this.isNotEmpty(cash.data, i)) {
                if (!cash.data[i].skipTitle) {
                  rows.push(
                    new TableRow({
                      children: [
                        this.createCell(cash.data[i].name, AlignmentType.LEFT, VerticalAlign.CENTER, false, 12, 4, 0),
                      ],
                    }),
                  );
                } else {
                  // если пропускаем заголовок аккордеона в отчете, добавляем его в начало следующей строки
                  cash.data[i].fields.unshift({
                    number: '',
                    name: cash.data[i].name,
                    value: cash.data[i].name,
                  });
                }

                for (let k = 0; k < cash.data[i].fields.length; k += 4) {
                  const data = cash.data[i].fields;
                  if (k > data.length && this.isNotEmpty(cash.data, i)) continue;
                  rows.push(
                    new TableRow({
                      children: [
                        this.createCell(data[k]?.value || '', AlignmentType.LEFT, VerticalAlign.CENTER),
                        this.createCell(data[k + 1]?.value || '', AlignmentType.LEFT, VerticalAlign.CENTER),
                        this.createCell(data[k + 2]?.value || '', AlignmentType.LEFT, VerticalAlign.CENTER),
                        this.createCell(data[k + 3]?.value || '', AlignmentType.LEFT, VerticalAlign.CENTER),
                      ],
                    }),
                  );
                }
              }
            }
          }

          /** Форсирование примечания отчета */
          let footerChildren: TextRun[] = [];
          switch (report.sysname) {
            case 'chs1':
              footerChildren = [];
              break;
            case 'chs2':
              footerChildren = [];
              break;
            case 'chs3':
              footerChildren = [];
              break;
            case 'chs4':
              footerChildren = [];
              break;
            case 'chs5':
              footerChildren = [];
              break;
          }

          /** Новый футер */
          const footerTable = new Table({
            rows: [
              new TableRow({
                children: [
                  this.createCell('Должность', AlignmentType.LEFT),
                  this.createCell('(подпись)', AlignmentType.CENTER),
                  this.createCell('Фамилия Имя Отчество (при наличии)', AlignmentType.RIGHT),
                ],
              }),
            ],
            //
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
              left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
              right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
              insideVertical: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
            },
          });

          /** Итогова сборка отчета */
          doc.addSection({
            properties: {},
            margins: {
              top: 0,
              right: 500,
              bottom: 0,
              left: 400,
            },
            children: [
              new Paragraph({
                children: [this.createText(topTitle, 12)],
                alignment: AlignmentType.RIGHT,
              }),
              new Paragraph({
                children: titleChildren,
                alignment: AlignmentType.CENTER,
                spacing: { line: 330, after: 200 },
              }),
              new Table({
                rows,
                width: {
                  size: 100,
                  type: WidthType.AUTO,
                },
              }),
              // TODO добавить нормальные отступы
              new Paragraph(''),
              new Paragraph(''),
              new Paragraph(''),
              // выводим фио текущего пользователя
              new Paragraph({
                children: [this.createText(user.fio, 12)],
                alignment: AlignmentType.RIGHT,
              }),
              footerTable,
              new Paragraph({
                children: footerChildren,
              }),
            ],
          });

          /** Сохранение файла отчета */
          const currentDate = this.getCurrentTime('DD.MM.YYYY HH_mm_ss');
          Packer.toBuffer(doc).then((buf) => {
            saveAs(
              new Blob([buf]),
              `${finalFileName} на основании инцидента ${emergency.number} по состоянию на ${currentDate}.docx`,
            );
          });

          return;

          /**
           * Следующий код служит для формирования отчета в формате xlsx.
           * Будет использоваться в дальнейшем, когда будет тикет на выбор формата.
           */
          wb.xlsx.load(buffer).then((workbook) => {
            ws = workbook.getWorksheet(page);
            if (ws) {
              switch (report.sysname) {
                case 'chs1':
                  ws.getCell(3, 3).value = currentTime;
                  ws.getCell(44, 3).value = user.fio;
                  ws.getCell(45, 3).value = user.organizationId?.name;
                  break;
                case 'chs2':
                  ws.getCell(3, 3).value = currentTime;
                  ws.getCell(241, 3).value = user.fio;
                  ws.getCell(242, 3).value = user.organizationId?.name;
                  break;
                case 'chs3':
                  ws.getCell(3, 3).value = currentTime;
                  ws.getCell(97, 3).value = user.fio;
                  ws.getCell(98, 3).value = user.organizationId?.name;
                  break;
                case 'chs4':
                  ws.getCell(3, 3).value = currentTime;
                  ws.getCell(83, 3).value = user.fio;
                  ws.getCell(84, 3).value = user.organizationId?.name;
                  break;
                case 'chs5':
                  ws.getCell(3, 3).value = currentTime;
                  ws.getCell(225, 3).value = user.fio;
                  ws.getCell(226, 3).value = user.organizationId?.name;
                  break;
              }

              // Номер строки внешнего сабтайтла
              let subtitleRow: number = null;
              // Флаг существования элементов под внешним сабтайтлом
              let hasElements: boolean = false;

              for (let i = 0; i < cash.data.length; i++) {
                // Номер строки аккордиона
                const accordionRow: number = cash.data[i].cellOfName ? cash.data[i].cellOfName[0] : null;
                // Флаг существования элементов под аккордионом
                let accordionElements: boolean = false;

                if (cash.data[i].type === 'subtitle') {
                  // Если уже был внешний сабтайтл и у него не было элементов, то скрываем его
                  if (subtitleRow && !hasElements) {
                    ws.getRow(subtitleRow).hidden = true;
                  }
                  subtitleRow = cash.data[i].cellOfName ? cash.data[i].cellOfName[0] : null;
                  hasElements = false;
                  continue;
                }

                // Номер строки внутреннего сабтайтла
                let innerSubtitleRow: number = null;
                // Флаг существования элементов под внутреннем сабтайтлом
                let hasInnerElements: boolean = false;

                for (let y = 0; y < cash.data[i].fields.length; y++) {
                  if (cash.data[i].fields[y].type === 'subtitle') {
                    // Если уже был внутренний сабтайтл и у него не было элементов, то скрываем его
                    if (innerSubtitleRow && !hasInnerElements) {
                      ws.getRow(innerSubtitleRow).hidden = true;
                    }
                    innerSubtitleRow = cash.data[i].fields[y].cellOfValue[0];
                    hasInnerElements = false;
                    continue;
                  }
                  // Скрытие строк, для которых нет введенных значений
                  if (['', null, undefined].includes(cash.data[i].fields[y].value)) {
                    ws.getRow(cash.data[i].fields[y].cellOfValue[0]).hidden = true;
                    continue;
                  }
                  // Установка значений в ячейки
                  ws.getCell(cash.data[i].fields[y].cellOfValue[0], cash.data[i].fields[y].cellOfValue[1]).value =
                    cash.data[i].fields[y].value;

                  hasInnerElements = true;
                  hasElements = true;
                  accordionElements = true;
                }

                if (innerSubtitleRow && !hasInnerElements) {
                  ws.getRow(innerSubtitleRow).hidden = true;
                }

                if (accordionRow && !accordionElements) {
                  ws.getRow(accordionRow).hidden = true;
                }
              }

              if (subtitleRow && !hasElements) {
                ws.getRow(subtitleRow).hidden = true;
              }

              workbook.xlsx.writeBuffer().then((buf) => {
                saveAs(
                  new Blob([buf]),
                  `${finalFileName} на основании инцидента ${emergency.number} по состоянию на ${currentDate}.xlsx`,
                );
              });
            }
          });
        });
    });
  }

  /**
   * Формирование и скачивание отчета "Сведения о готовности ЛПУ и ПВР" (проишествие ЕДДС)
   */
  public buildLpuPvrReport(emergency, report) {
    this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Report' },
        entity: {
          query: {
            $expr: {
              $and: [
                {
                  $expr: {
                    $eq: ['$emergencyId', emergency.id],
                  },
                },
                {
                  $expr: {
                    $eq: ['$typeId.sysname', report.sysname],
                  },
                },
              ],
            },
          },
          name: 'ReportEmergency',
        },
      })
      .pipe(
        mergeMap((res: IAbstractServiceData) => {
          return forkJoin([
            this.rest
              .serviceRequest({
                action: 'select',
                service: { name: 'Emergency' },
                entity: {
                  name: 'Emergency',
                  query: { id: res.data?.items[0]?.emergencyId },
                  attributes: ['id', 'incidentTypeId.name'],
                },
              })
              .pipe(map((res) => res.data?.items[0])),
            this.rest
              .serviceRequest({
                action: 'select',
                service: { name: 'Directories' },
                entity: {
                  name: 'HealthFacilities',
                  query: { id: res.data?.items[0]?.data[0]?.fields[0]?.value },
                  attributes: ['id', 'name', 'address', 'director', 'phone', 'beds'],
                },
              })
              .pipe(map((res) => res.data?.items[0])),
            this.rest
              .serviceRequest({
                action: 'select',
                service: { name: 'Directories' },
                entity: {
                  name: 'Shelters',
                  query: { id: res.data?.items[0]?.data[0]?.fields[1]?.value },
                  attributes: ['id', 'name', 'address', 'director', 'directorPhone', 'beds'],
                },
              })
              .pipe(map((res) => res.data?.items[0])),
          ]);
        }),
      )
      .subscribe((res: any[]) => {
        /**
         * Данные для отчёта
         */
        const emergency = res[0];
        const lpu = res[1];
        const pvr = res[2];
        const user = this.settings.currentUser;

        /**
         * Формирование отчёта в формате docx
         */
        const doc = new Document({ title: 'СВЕДЕНИЯ по ЛПУ и ПВР' });

        /** Формирование таблицы ЛПУ */
        const rowsLpu: TableRow[] = [
          new TableRow({
            children: [
              this.createCell(
                'Наименование',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                14,
                undefined,
                undefined,
                'yellow',
                undefined,
                300,
              ),
              this.createCell(
                'Адрес',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                14,
                undefined,
                undefined,
                'yellow',
                undefined,
                300,
              ),
              this.createCell(
                'Руководитель',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                14,
                undefined,
                undefined,
                'yellow',
                undefined,
                300,
              ),
              this.createCell(
                'Контактный телефон',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                14,
                undefined,
                undefined,
                'yellow',
                undefined,
                300,
              ),
              this.createCell(
                'Количество койко-мест',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                14,
                undefined,
                undefined,
                'yellow',
                undefined,
                300,
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(this.prepareValue(lpu?.name), AlignmentType.CENTER, VerticalAlign.CENTER, false, 11),
              this.createCell(
                this.prepareValue(lpu?.address?.fullText),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
              ),
              this.createCell(this.prepareValue(lpu?.director), AlignmentType.CENTER, VerticalAlign.CENTER, false, 11),
              this.createCell(this.prepareValue(lpu?.phone), AlignmentType.CENTER, VerticalAlign.CENTER, false, 11),
              this.createCell(this.prepareValue(lpu?.beds), AlignmentType.CENTER, VerticalAlign.CENTER, false, 11),
            ],
          }),
        ];
        /** Формирование таблицы ПВР */
        const rowsPvr: TableRow[] = [
          new TableRow({
            children: [
              this.createCell(
                'Наименование',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                14,
                undefined,
                undefined,
                'yellow',
                undefined,
                300,
              ),
              this.createCell(
                'Адрес',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                14,
                undefined,
                undefined,
                'yellow',
                undefined,
                300,
              ),
              this.createCell(
                'Руководитель организации (балансодержателя)',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                14,
                undefined,
                undefined,
                'yellow',
                undefined,
                300,
              ),
              this.createCell(
                'Контактный телефон',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                14,
                undefined,
                undefined,
                'yellow',
                undefined,
                300,
              ),
              this.createCell(
                'Вместимость',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                14,
                undefined,
                undefined,
                'yellow',
                undefined,
                300,
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(this.prepareValue(pvr?.name), AlignmentType.CENTER, VerticalAlign.CENTER, false, 11),
              this.createCell(
                this.prepareValue(pvr?.address?.fullText),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
              ),
              this.createCell(this.prepareValue(pvr?.director), AlignmentType.CENTER, VerticalAlign.CENTER, false, 11),
              this.createCell(
                this.prepareValue(pvr?.directorPhone),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
              ),
              this.createCell(this.prepareValue(pvr?.beds), AlignmentType.CENTER, VerticalAlign.CENTER, false, 11),
            ],
          }),
        ];

        /** Итоговая сборка отчёта */
        doc.addSection({
          properties: {},
          size: {
            orientation: PageOrientation.LANDSCAPE,
          },
          margins: {
            top: 600,
            right: 1100,
            bottom: 0,
            left: 1100,
          },
          children: [
            new Paragraph({
              children: [
                this.createText('СВЕДЕНИЯ по ЛПУ и ПВР', 14, true).break(),
                this.createText(`пострадавших в результате ${emergency.incidentTypeId?.name}`, 14, true).break(),
                this.createText(`(по состоянию на ${moment().format('HH:mm:ss DD.MM.YYYY')})`, 14, true).break(),
              ],
              alignment: AlignmentType.CENTER,
              spacing: { line: 250, after: 0 },
            }),
            new Paragraph({
              children: [this.createText('В готовности ЛПУ:', 14).break()],
              alignment: AlignmentType.LEFT,
              spacing: { after: 300 },
            }),
            new Table({
              rows: rowsLpu,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
            }),
            new Paragraph({
              children: [this.createText('В готовности ПВР:', 14).break()],
              alignment: AlignmentType.LEFT,
              spacing: { after: 300 },
            }),
            new Table({
              rows: rowsPvr,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
            }),
            new Paragraph({
              children: [this.createText(user.organizationId?.name, 14).break(), this.createText(user.fio, 14).break()],
              alignment: AlignmentType.LEFT,
              spacing: { before: 500 },
            }),
          ],
        });

        /** Сохранение файла отчёта */
        const currentDate = this.getCurrentTime('DD.MM.YYYY HH_mm_ss');
        Packer.toBuffer(doc).then((buf) => {
          saveAs(new Blob([buf]), `Сведения о готовности ЛПУ и ПВР ${currentDate}.docx`);
        });
      });
  }

  /**
   * Формирование и скачивание отчета "Список госпитализированных/погибших/эвакуированных/пострадавших"
   * (происшествие ЕДДС)
   */
  public buildVictimsReport(emergency, report) {
    forkJoin([
      this.rest
        .serviceRequest({
          action: 'select',
          service: { name: 'Emergency' },
          entity: {
            name: 'Emergency',
            query: { id: emergency.id },
            attributes: ['id', 'incidentTypeId.name'],
          },
        })
        .pipe(map((res) => res.data?.items[0])),
      this.rest
        .serviceRequest({
          action: 'select',
          service: { name: 'Emergency' },
          entity: {
            name: 'Humans',
            query: {
              emergencyId: emergency.id,
              type: report.sysname,
            },
            attributes: [
              'id',
              'type',
              'fio',
              'birthYear',
              'healthFacilitiesId.name',
              'relativesAddress',
              'shelterId.name',
              'shelterId.address.fullText',
              'patientCondition.name',
              'diagnosis',
              'burialPlace',
              'bodyLocation',
              'comment',
            ],
          },
        })
        .pipe(map((res) => res.data?.items)),
    ]).subscribe((res) => {
      const emergencyRes = res[0];
      // TODO использовать интерфейс из smart-city-types после влития bg-6955
      const victims = [];
      if (res[1]) {
        victims.push(...res[1]);
      }
      const user = this.settings.currentUser;
      /** Для какой категории населения делаем отчёт */
      let people: string = '';
      /** Тело отчёта */
      const bodyChildren = [];
      const currentDate = this.getCurrentTime('DD.MM.YYYY HH_mm_ss');

      /** Таблицы */
      let tableHospitalized: Table;
      let tableTotal: Table;
      let tableDeath: Table;
      let tableEvacuated: Table;
      /**
       * Формирование отчёта в формате docx
       */
      const doc = new Document();

      /** Заполняем тело отчёта в зависимости от того, какой отчёт формируем */
      switch (report.sysname) {
        case 'hospitalized':
          people = 'госпитализированных';
          tableHospitalized = this.formHospitalizedTable(victims, true);
          tableTotal = this.formTotalTable(report.sysname, emergency.resolution);
          bodyChildren.push(tableHospitalized);
          bodyChildren.push(new Paragraph({ spacing: { after: 300 } }));
          bodyChildren.push(tableTotal);
          break;
        case 'death':
          people = 'погибших';
          tableDeath = this.formDeathTable(victims, true);
          tableTotal = this.formTotalTable(report.sysname, emergency.resolution);
          bodyChildren.push(tableDeath);
          bodyChildren.push(new Paragraph({ spacing: { after: 300 } }));
          bodyChildren.push(tableTotal);
          break;
        case 'evacuated':
          people = 'эвакуированных';
          tableEvacuated = this.formEvacuatedTable(victims, emergency?.address?.fullText, true);
          tableTotal = this.formTotalTable(report.sysname, emergency.resolution);
          bodyChildren.push(tableEvacuated);
          bodyChildren.push(new Paragraph({ spacing: { after: 300 } }));
          bodyChildren.push(tableTotal);
          break;
      }

      /** Итоговая сборка отчёта */
      doc.addSection({
        properties: {},
        size: {
          orientation: PageOrientation.LANDSCAPE,
        },
        margins: {
          top: 600,
          right: 1100,
          bottom: 0,
          left: 1100,
        },
        children: [
          new Paragraph({
            children: [
              this.createText('СПИСОК', 14, true).break(),
              this.createText(`${people} в результате ${emergencyRes.incidentTypeId?.name}`, 14, true).break(),
              this.createText(
                `(по состоянию на ${moment().format('HH:mm:ss')} (мск) ${moment().format('DD.MM.YYYY')})`,
                14,
                true,
              ).break(),
            ],
            alignment: AlignmentType.CENTER,
            spacing: { line: 250, after: 100 },
          }),
          ...bodyChildren,
          new Paragraph({
            children: [this.createText(user.organizationId?.name, 14).break(), this.createText(user.fio, 14).break()],
            alignment: AlignmentType.LEFT,
            spacing: { before: 500 },
          }),
        ],
      });

      /** Сохранение файла отчёта */
      const fileName = `Список ${people}_${currentDate}.docx`;
      Packer.toBuffer(doc).then((buf) => {
        saveAs(new Blob([buf]), fileName);
      });
    });
  }

  /**
   * Формирование и скачивание отчета "Список пострадавших"
   * (происшествие ЕДДС)
   */
  public buildListOfVictimsReport(emergency) {
    forkJoin([
      // TODO: Упростить запрос. Проверить наличие справочника в setting2
      this.rest
        .serviceRequest({
          action: 'select',
          service: { name: 'Emergency' },
          entity: {
            name: 'Emergency',
            query: { id: emergency.id },
            attributes: ['id', 'incidentTypeId.name'],
          },
        })
        .pipe(map((res) => res.data?.items[0])),
      this.rest
        .serviceRequest({
          action: 'select',
          service: { name: 'Emergency' },
          entity: {
            name: 'Humans',
            query: {
              emergencyId: emergency.id,
            },
            attributes: [
              'id',
              'type',
              'fio',
              'birthYear',
              'healthFacilitiesId.name',
              'relativesAddress',
              'shelterId.name',
              'shelterId.address.fullText',
              'patientCondition.name',
              'diagnosis',
              'burialPlace',
              'bodyLocation',
              'comment',
            ],
          },
        })
        .pipe(map((res) => res.data?.items)),
    ]).subscribe((res) => {
      const emergencyRes = res[0];
      // TODO использовать интерфейс из smart-city-types после влития bg-6955
      const victims = [];
      if (res[1]) {
        victims.push(...res[1]);
      }

      const user = this.settings.currentUser;
      /** Тело отчёта */
      const bodyChildren = [];
      const currentDate = this.getCurrentTime('DD.MM.YYYY HH_mm_ss');

      /**
       * Формирование отчёта в формате docx
       */
      const doc = new Document();

      /** Заполняем тело отчёта */
      const tableHospitalized = this.formHospitalizedTable(
        victims.filter((el: { type }) => el.type === 'hospitalized'),
        true,
      );
      bodyChildren.push(tableHospitalized);
      bodyChildren.push(new Paragraph({ spacing: { after: 300 } }));

      const tableDeath = this.formDeathTable(
        victims.filter((el: { type }) => el.type === 'death'),
        true,
      );
      bodyChildren.push(tableDeath);
      bodyChildren.push(new Paragraph({ spacing: { after: 300 } }));

      const tableEvacuated = this.formEvacuatedTable(
        victims.filter((el: { type }) => el.type === 'evacuated'),
        emergency?.address?.fullText,
        true,
      );
      bodyChildren.push(tableEvacuated);
      bodyChildren.push(new Paragraph({ spacing: { after: 300 } }));

      const tableTotal = this.formTotalTable('all', emergency.resolution);
      bodyChildren.push(tableTotal);

      /** Итоговая сборка отчёта */
      doc.addSection({
        properties: {},
        size: {
          orientation: PageOrientation.LANDSCAPE,
        },
        margins: {
          top: 600,
          right: 1100,
          bottom: 0,
          left: 1100,
        },
        children: [
          new Paragraph({
            children: [
              this.createText('СПИСОК', 14, true).break(),
              this.createText(`пострадавших в результате ${emergencyRes.incidentTypeId?.name}`, 14, true).break(),
              this.createText(`(по состоянию на ${moment().format('HH:mm:ss (МСК) DD.MM.YYYY')})`, 14, true).break(),
            ],
            alignment: AlignmentType.CENTER,
            spacing: { line: 250, after: 100 },
          }),
          ...bodyChildren,
          new Paragraph({
            children: [this.createText(user.organizationId?.name, 14).break(), this.createText(user.fio, 14).break()],
            alignment: AlignmentType.LEFT,
            spacing: { before: 500 },
          }),
        ],
      });

      /** Сохранение файла отчёта */
      const fileName = `Список пострадавших_${currentDate}.docx`;
      Packer.toBuffer(doc).then((buf) => {
        saveAs(new Blob([buf]), fileName);
      });
    });
  }

  /**
   * Формирование и скачивание отчета по прогнозированию техногенного пожаров
   */
  public buildForecastingTechnologicalFireReport(report: IForecastingTechnologicalFireReport) {
    const wb = new Workbook();
    let ws2: Worksheet = undefined;
    let ws3: Worksheet = undefined;
    let ws4: Worksheet = undefined;
    let ws5: Worksheet = undefined;

    this.xlsxUploader
      .get('/assets/report-templates/forecasting-technological-fire-report.xlsx', {
        responseType: 'arraybuffer',
      })
      .subscribe((buffer) => {
        wb.xlsx.load(buffer).then((workbook) => {
          const dateFormat = 'DD.MM.YYYY HH:mm:ss';

          ws2 = workbook.getWorksheet('Тех. пожар_стр. 2');
          if (ws2) {
            /** Блок "Используемая методология для прогнозирования" */
            ws2.getCell(4, 1).value = this.prepareValue(report.methodology);

            /** Блок "Местоположение" */
            ws2.getCell(9, 2).value = this.prepareValue(report.address);
            ws2.getCell(11, 2).value = this.prepareValue(report.coordinates);
            ws2.getCell(12, 2).value = this.prepareValue(report.object);
            ws2.getCell(13, 2).value = this.prepareValue(report.responsible);
            ws2.getCell(14, 2).value = this.prepareValue(report.phone);

            /** Блок "Исходные данные" */
            ws2.getCell(19, 2).value = this.prepareValue(report.incident);
            ws2.getCell(20, 2).value = this.prepareValue(report.occurrenceTime);
            ws2.getCell(21, 2).value = this.prepareValue(report.fireObject);
            ws2.getCell(22, 2).value = this.prepareValue(report.fireDistance);
            ws2.getCell(23, 2).value = this.prepareValue(report.auto ? 'Да' : 'Нет');
            ws2.getCell(24, 2).value = this.prepareValue(report.fireSpeed);
            ws2.getCell(25, 2).value = this.prepareValue(report.fireTime);
            ws2.getCell(26, 2).value = this.prepareValue(report.firstBarrel);

            /** Блок "Данные о зоне горения" */
            let nextBlock = 0;
            (report.fireZones || []).forEach((item, index) => {
              if (index !== 0) {
                for (let i = 8; i < 13; i++) {
                  for (let j = 6; j < 8; j++) {
                    ws2.getCell(nextBlock + i, j).style = ws2.getCell(i, j).style;
                    ws2.getCell(nextBlock + i, j).value = ws2.getCell(i, j).value;
                  }
                }
              }
              ws2.getCell(nextBlock + 9, 7).value = this.prepareValue(item?.sourceCoordinates || '---');
              ws2.getCell(nextBlock + 10, 7).value = this.prepareValue(item?.fireShape || '---');
              ws2.getCell(nextBlock + 11, 7).value = this.prepareValue(item?.extinguishingAgent || '---');
              (item?.params || []).forEach((param: string, index: number) => {
                ws2.getCell(nextBlock + index + 12, 7).value = this.prepareValue(param || '---');
                if (index !== 0) {
                  ws2.getCell(nextBlock + index + 12, 7).style = ws2.getCell(nextBlock + 12, 7).style;
                }
              });
              if ((item?.params || []).length > 1) {
                ws2.mergeCells(nextBlock + 12, 6, nextBlock + 12 + item.params.length - 1, 6);
              }
              nextBlock += 5 + (item.params.length || 1);
            });
          }

          ws3 = workbook.getWorksheet('Тех. пожар_стр. 3');
          if (ws3) {
            ws3.getCell(3, 3).value = this.prepareValue(report.fireDistanceResults);
            ws3.getCell(4, 3).value = this.prepareValue(report.fireAreaResult);
            ws3.getCell(5, 3).value = this.prepareValue(report.fireExtinguishingAgentConsumptionResult);
            ws3.getCell(6, 3).value = this.prepareValue(report.fireExtinguishingAreaResult);

            let nextBlock = 0;
            (report.fireZonesResult || []).forEach((item, index) => {
              if (index !== 0) {
                for (let i = 8; i < 13; i++) {
                  ws3.mergeCells(nextBlock + i, 1, nextBlock + i, 2);
                  ws3.getCell(nextBlock + i, 1).style = ws3.getCell(i, 1).style;
                  ws3.getCell(nextBlock + i, 1).value = ws3.getCell(i, 1).value;
                  ws3.getCell(nextBlock + i, 3).style = ws3.getCell(i, 3).style;
                }
              }
              ws3.getCell(nextBlock + 8, 3).value = this.prepareValue(item.combustionZone);
              ws3.getCell(nextBlock + 9, 3).value = this.prepareValue(item.fireArea);
              ws3.getCell(nextBlock + 10, 3).value = this.prepareValue(item.fireExtinguishingAgentConsumption);
              ws3.getCell(nextBlock + 11, 3).value = this.prepareValue(item.fireExtinguishingArea);
              ws3.getCell(nextBlock + 12, 3).value = this.prepareValue(item.fireExtinguishingAgentSupply);
              nextBlock += 6;
            });
          }

          ws4 = workbook.getWorksheet('Тех. пожар_стр. 4');
          if (ws4) {
            if (report.buildings?.length) {
              for (let i = 0; i < report.buildings.length; i++) {
                const el = report.buildings[i];
                const building = `${el.city}, ${el.street}, д.${el.houseNumber}`;
                ws4.getCell(i + 2, 1).value = this.prepareValue(building);
              }
            } else {
              ws4.getCell(2, 1).value = 'Здания в зоне возможного распространения техногенного пожара не найдены';
            }
          }

          ws5 = workbook.getWorksheet('Тех. пожар_стр. 5');
          if (ws5) {
            if (report.devices?.length) {
              report.devices.forEach((device: IReportingDevice, index: number) => {
                ws5.getCell(3 + index, 1).value = this.prepareValue(device.type);
                ws5.getCell(3 + index, 2).value = this.prepareValue(device.name);
                ws5.getCell(3 + index, 3).value = this.prepareValue(device.currentSeanceName);
                ws5.getCell(3 + index, 4).value = this.prepareDate(new Date(device.syncTime), dateFormat);
                // TODO добавить динамические стили для границ
                // ws5.getCell(3 + index, 1).style.border = ws5.getCell(3 + index - 1, 1).style.border;
                // ws5.getCell(3 + index, 2).style.border = ws5.getCell(3 + index - 1, 2).style.border;
                // ws5.getCell(3 + index, 3).style.border = ws5.getCell(3 + index - 1, 3).style.border;
                // ws5.getCell(3 + index, 4).style.border = ws5.getCell(3 + index - 1, 4).style.border;
              });
            } else {
              ws5.getCell(2, 1).value = 'Не найдены средства оповещения в рассчитанной зоне';
            }
          }

          workbook.xlsx.writeBuffer().then((buf) => {
            saveAs(
              new Blob([buf]),
              `Расчёт параметров развития техногенного пожара_${this.getCurrentTime(dateFormat)}.xlsx`,
            );
          });
        });
      });
  }

  /**
   * Формирование и скачивание отчета по прогнозированию лесного пожаров
   */
  public buildForecastingForestFireReport(report: IForecastingForestFireReport) {
    const wb = new Workbook();
    let ws2: Worksheet = undefined;
    let ws3: Worksheet = undefined;
    let ws4: Worksheet = undefined;
    let ws5: Worksheet = undefined;

    this.xlsxUploader
      .get('/assets/report-templates/forecasting-forest-fire-report.xlsx', {
        responseType: 'arraybuffer',
      })
      .subscribe((buffer) => {
        wb.xlsx.load(buffer).then((workbook) => {
          const dateFormat = 'DD.MM.YYYY HH:mm:ss';

          ws2 = workbook.getWorksheet('Лес. пожар_стр. 2');
          if (ws2) {
            /** Блок "Используемая методология для прогнозирования" */
            ws2.getCell(4, 1).value = this.prepareValue(report.methodology);

            /** Блок "Местоположение" */
            ws2.getCell(7, 2).value = this.prepareValue(report.coordinates);
            ws2.getCell(8, 2).value = this.prepareValue(report.object);
            ws2.getCell(9, 2).value = this.prepareValue(report.responsible);
            ws2.getCell(10, 2).value = this.prepareValue(report.phone);

            /** Блок "Исходные данные" */
            ws2.getCell(13, 2).value = this.prepareValue(report.incident);
            ws2.getCell(14, 2).value = this.prepareValue(report.occurrenceTime);
            ws2.getCell(15, 2).value = this.prepareValue(report.fireType);
            ws2.getCell(16, 2).value = this.prepareValue(report.fireTime);
            ws2.getCell(17, 2).value = this.prepareValue(report.initOutbreakArea);
            ws2.getCell(18, 2).value = this.prepareValue(report.fireHeight);

            /** Блок "Метеоусловия" */
            ws2.getCell(7, 6).value = this.prepareValue(report.windSpeed);
            ws2.getCell(8, 6).value = this.prepareValue(report.windDirection);
            ws2.getCell(9, 6).value = this.prepareValue(report.weatherFireHazard);

            /** Блок "Характеристика лесных насаждений" */
            ws2.getCell(13, 6).value = this.prepareValue(report.forestType);
            ws2.getCell(14, 6).value = this.prepareValue(report.forestDiameters);
            ws2.getCell(15, 6).value = this.prepareValue(report.burningClass);
            ws2.getCell(16, 6).value = this.prepareValue(report.forestNote);
          }

          ws3 = workbook.getWorksheet('Лес. пожар_стр. 3');
          if (ws3) {
            /** Блок "Результаты расчёта" */
            ws3.getCell(4, 2).value = this.prepareValue(report.typeOfFire);

            ws3.getCell(7, 2).value = this.prepareValue(report.speedFront);
            ws3.getCell(8, 2).value = this.prepareValue(report.speedFlanks);
            ws3.getCell(9, 2).value = this.prepareValue(report.speedRear);

            ws3.getCell(12, 2).value = this.prepareValue(report.fireZoneCombustion);
            ws3.getCell(13, 2).value = this.prepareValue(report.fireZoneArea);
            ws3.getCell(14, 2).value = this.prepareValue(report.standDamage);
            ws3.getCell(15, 2).value = this.prepareValue(report.standState);

            if (!report.unusableTrees) {
              ws3.getCell(18, 1).value = this.prepareValue(
                'Расчёт невозможен, т.к. для лесных насаждений не заданы виды древесных пород.',
              );
            } else {
              report.unusableTrees.forEach((el: IUnusableTree, index: number) => {
                ws3.getCell(18 + index, 1).value = this.prepareValue(el.name);
                ws3.getCell(18 + index, 2).value = this.prepareValue(el.value);
              });
            }
          }

          ws4 = workbook.getWorksheet('Лес. пожар_стр. 4');
          if (ws4) {
            if (report.buildings?.length) {
              for (let i = 0; i < report.buildings.length; i++) {
                const el = report.buildings[i];
                const building = `${el.city}, ${el.street}, д.${el.houseNumber}`;
                ws4.getCell(i + 2, 1).value = this.prepareValue(building);
              }
            } else {
              ws4.getCell(2, 1).value = 'Здания в зоне возможного распространения лесного пожара не найдены';
            }
          }

          ws5 = workbook.getWorksheet('Лес. пожар_стр. 5');
          if (ws5) {
            if (report.devices?.length) {
              report.devices.forEach((device: IReportingDevice, index: number) => {
                ws5.getCell(3 + index, 1).value = this.prepareValue(device.type);
                ws5.getCell(3 + index, 2).value = this.prepareValue(device.name);
                ws5.getCell(3 + index, 3).value = this.prepareValue(device.currentSeanceName);
                ws5.getCell(3 + index, 4).value = this.prepareDate(new Date(device.syncTime), dateFormat);
                // TODO добавить динамические стили для границ
                //ws5.getCell(3, 1).style.border = ws5.getCell(2, 1).style.border
              });
            } else {
              ws5.getCell(3, 1).value = 'Не найдены средства оповещения в рассчитанной зоне';
            }
          }

          workbook.xlsx.writeBuffer().then((buf) => {
            saveAs(new Blob([buf]), `Расчёт последствий лесного пожара_${this.getCurrentTime(dateFormat)}.xlsx`);
          });
        });
      });
  }

  /**
   * Формирование и скачивание отчета по зарегистрированным жителям
   */
  public buildRegisteredCitizensReport(report: IRegisteredCitizensReport) {
    const wb = new Workbook();
    let ws: Worksheet = undefined;

    this.xlsxUploader
      .get('/assets/report-templates/registered-citizens.xlsx', {
        responseType: 'arraybuffer',
      })
      .subscribe((buffer) => {
        wb.xlsx.load(buffer).then((workbook) => {
          const dateFormat = 'DD.MM.YYYY HH:mm:ss';

          ws = workbook.getWorksheet('Сведения');
          if (ws) {
            // Дата отчета
            ws.getCell(2, 1).value = this.prepareValue(moment().format('DD.MM.YYYY HH:mm:ss'));

            // Суммарные данные
            ws.getCell(5, 1).value = this.prepareValue(report.list.length);
            ws.getCell(5, 2).value = this.prepareValue(report.list.reduce((acc, item) => acc + item.citizens, 0));

            // Список адресов
            if (!report.list?.length) {
              ws.getCell(8, 1).value = this.prepareValue(null);
              ws.getCell(8, 2).value = this.prepareValue(null);
            }

            let currentLine = report.list?.length ? 8 : 9;
            report.list.forEach((item, index) => {
              if (index > 0) {
                ws.spliceRows(currentLine, 0, [this.prepareValue(item.address), this.prepareValue(item.citizens)]);
                ws.getRow(currentLine).height = 14;
                ws.getCell(currentLine, 1).style = ws.getCell(currentLine - 1, 1).style;
                ws.getCell(currentLine, 2).style = ws.getCell(currentLine - 1, 2).style;
              } else {
                ws.getCell(currentLine, 1).value = this.prepareValue(item.address);
                ws.getCell(currentLine, 1).style.alignment.horizontal = 'left';
                ws.getCell(currentLine, 2).value = this.prepareValue(item.citizens);
              }
              currentLine++;
            });

            // Составитель отчета
            ws.mergeCells(currentLine + 1, 1, currentLine + 1, 2);
            ws.mergeCells(currentLine + 2, 1, currentLine + 2, 2);
            ws.mergeCells(currentLine + 3, 1, currentLine + 3, 2);
            ws.getCell(currentLine + 2, 1).value = this.prepareValue(report.userFio);
            ws.getCell(currentLine + 2, 1).style = ws.getCell(currentLine + 1, 1);
            ws.getCell(currentLine + 3, 1).value = this.prepareValue(report.userOrganization);
            ws.getCell(currentLine + 3, 1).style = ws.getCell(currentLine + 1, 1);
          }

          workbook.xlsx.writeBuffer().then((buf) => {
            saveAs(new Blob([buf]), `Сведения о зарегистрированных гражданах_${this.getCurrentTime(dateFormat)}.xlsx`);
          });
        });
      });
  }

  /** Получить текущее время */
  public getCurrentTime(dateFormat: string): string {
    return moment().format(dateFormat);
  }

  /** Возвращает дату в нужном формате (ex. 'DD.MM.YYYY hh:mm:ss') */
  private prepareDate(d: Date, dateFormat: string): string {
    return moment(d).format(dateFormat);
  }

  /** Получение типа донесения по системному наименованию */
  public getReportTypeBySysname(sysname: string): Observable<IDictionaryModel> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          query: { sysname },
          name: 'Dictionary',
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items[0];
        }),
      );
  }

  /**
   * Получение кэша ранее записанных данных формы донесения
   * по ID проишествия и типу донесения
   */
  public getReportCashByEmergencyIdAndReportTypeId(
    emergencyId: string,
    reportTypeId: string,
  ): Observable<IReportEmergencyDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Report' },
        entity: {
          query: {
            emergencyId,
            typeId: reportTypeId,
          },
          name: 'ReportEmergency',
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items[0];
        }),
      );
  }

  /** Получение ЧС по ID */
  public getEmergencyById(id: string): Observable<IAnyObject> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          query: {
            id,
          },
          name: 'Emergency',
          attributes: [
            'id',
            'incidentTypeId.name',
            'addressFact',
            'organization.mo.name',
            'organization.mo.municipal.name',
            'timeCreate',
            'point',
            'description',
            'resolution',
            'fireParams',
            'forecastingDetailId.params',
            'totalRegistryPeopleHouse',
            'totalTemporalPeopleHouse',
            'coordinates',
          ],
        },
      })
      .pipe(
        map((response: IAbstractServiceData) => {
          return response?.data?.items[0];
        }),
      );
  }

  /** Получение параметров прогнозирования */
  public getForecastingParamsByEmergencyId(id: string): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Forecasting' },
      entity: {
        query: { id },
        name: 'ForecastingDetail',
      },
    });
  }

  /**
   * Проверяет наличие строк-параметров со значением у строки-сабтайтла или есть ли значение у строки-параметра
   */
  private isNotEmpty(data: IReportDataGroup[], groupIndex: number, recordIndex: number = undefined): boolean {
    // Если не передан индекс строки-параметра, то имеем дело со строкой-сабтайтлом
    if ([null, undefined].includes(recordIndex)) {
      // Это внешний сабтайтл
      if (data[groupIndex].type === 'subtitle') {
        // Проверяем у внешнего сабтайтла существование текста
        if (['', null, undefined].includes(data[groupIndex].name)) {
          return false;
        }
        // Проверяем у внешнего сабтайтла существование непустых аккордионов
        for (let i = groupIndex + 1; i < data.length; i++) {
          // Если дошли до следующего внешнего сабтайтла
          if (data[i].type === 'subtitle') {
            return false;
          }
          if (this.isNotEmpty(data, i)) {
            return true;
          }
        }
        return false;
      }
      // Это аккордион
      // Проверяем у аккордиона существование непустых параметров
      for (let i = 0; i < data[groupIndex].fields.length; i++) {
        if (this.isNotEmpty(data, groupIndex, i)) {
          return true;
        }
      }
      return false;
    }
    // Имеем дело со строкой-параметром или внутренним сабтайтлом
    // Это внутренний сабтайтл
    if (data[groupIndex].fields[recordIndex].type === 'subtitle') {
      // Проверяем у внутреннего сабтайтал существование непустых параметров
      for (let i = recordIndex + 1; i < data[groupIndex].fields.length; i++) {
        // Если дошли до следующего внутреннего сабтайтла
        if (data[groupIndex].fields[i].type === 'subtitle') {
          return false;
        }
        if (this.isNotEmpty(data, groupIndex, i)) {
          return true;
        }
      }
      return false;
    }
    // Это параметр-значение
    return !['', null, undefined].includes(data[groupIndex].fields[recordIndex].value) || this.flagTable;
  }

  /**
   * Проверяет первого ли порядка номер записи (например 1. или 4.)
   */
  private isMainNumber(number: string): boolean {
    return /^\d+\.*$/.test(number);
  }

  /**
   * Возвращает объект ячейки
   */
  private createCell(
    text: string,
    alignment: AlignmentType = AlignmentType.LEFT,
    verticalAlign: VerticalAlign = VerticalAlign.TOP,
    bold: boolean = false,
    fontSize: number = 12,
    columnSpan: number = 0,
    rowSpan: number = 0,
    color: string = 'ffffff',
    marginX: number = 60,
    marginBottom: number = 0,
    marginTop: number = 0,
    substring: string = null,
  ): TableCell {
    const children = [
      new Paragraph({
        alignment,
        children: [this.createText(text, fontSize, bold)],
      }),
    ];
    substring &&
      children.push(
        new Paragraph({
          alignment,
          children: [this.createText(substring, fontSize, bold)],
        }),
      );
    return new TableCell({
      verticalAlign,
      columnSpan,
      rowSpan,
      children,
      margins: { top: marginTop, bottom: marginBottom, left: marginX, right: marginX },
      shading: { color, fill: 'auto', val: ShadingType.SOLID },
    });
  }

  /**
   * Возвращает объект текста
   */
  private createText(text: string, size: number = 12, bold: boolean = false): TextRun {
    return new TextRun({
      bold,
      text: `${text}`,
      size: size * 2,
      font: 'Times New Roman',
    });
  }

  /**
   * Подготовка значения для вывода в таблицу
   * @param value значение
   * @param defaultValue значение по умолчанию
   */
  private prepareValue(value: any, defaultValue?: string): string {
    if (value === null || value === undefined || value === '') {
      return defaultValue || '–––';
    }
    return value.toString();
  }

  /**
   * Формирование таблицы госпитализированных
   * @param victims пострадавшие
   * @param hasTitle флаг для отображения заголовка
   * */
  // TODO использовать интерфейс из smart-city-types после влития bg-6955
  private formHospitalizedTable(victims: any[], hasTitle: boolean = false): Table {
    const rowsHospitalized: TableRow[] = [];
    if (hasTitle) {
      rowsHospitalized.push(
        new TableRow({
          children: [
            this.createCell('Список госпитализированных', AlignmentType.CENTER, VerticalAlign.CENTER, true, 11, 6),
          ],
        }),
      );
    }
    rowsHospitalized.push(
      new TableRow({
        children: [
          this.createCell(
            '№',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            undefined,
            'yellow',
            undefined,
            300,
            0,
            'п/п',
          ),
          this.createCell(
            'ФИО',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            undefined,
            'yellow',
            undefined,
            300,
          ),
          this.createCell(
            'Год Рождения',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            undefined,
            'yellow',
            undefined,
            300,
          ),
          this.createCell(
            'Наименование ЛПУ',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            undefined,
            'yellow',
            undefined,
            300,
          ),
          this.createCell(
            'Состояние, диагноз',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            undefined,
            'yellow',
            undefined,
            300,
          ),
          this.createCell(
            'Примечание',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            undefined,
            'yellow',
            undefined,
            300,
          ),
        ],
        tableHeader: true,
      }),
    );
    if (victims.length) {
      for (let i = 0; i < victims.length; i++) {
        rowsHospitalized.push(
          new TableRow({
            children: [
              this.createCell(this.prepareValue(`${i + 1}`), AlignmentType.CENTER, VerticalAlign.CENTER, false, 11),
              this.createCell(this.prepareValue(victims[i].fio), AlignmentType.CENTER, VerticalAlign.CENTER, false, 11),
              this.createCell(
                this.prepareValue(victims[i].birthYear),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
              ),
              this.createCell(
                this.prepareValue(victims[i].healthFacilitiesId?.name),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
              ),
              this.createCell(
                `${this.prepareValue(victims[i].patientCondition?.name)}, ${this.prepareValue(victims[i].diagnosis)}`,
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
              ),
              this.createCell(
                this.prepareValue(victims[i].comment),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
              ),
            ],
            tableHeader: true,
          }),
        );
      }
    } else {
      rowsHospitalized.push(
        new TableRow({
          children: [
            this.createCell('Госпитализация не требуется.', AlignmentType.CENTER, VerticalAlign.CENTER, false, 11, 6),
          ],
        }),
      );
    }
    return new Table({
      rows: rowsHospitalized,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });
  }

  /**
   * Формирование таблицы погибших
   * @param victims пострадавшие
   * @param hasTitle флаг для отображения заголовка
   * */
  // TODO использовать интерфейс из smart-city-types после влития bg-6955
  private formDeathTable(victims: any[], hasTitle: boolean = false): Table {
    const rowsDeath: TableRow[] = [];
    if (hasTitle) {
      rowsDeath.push(
        new TableRow({
          children: [this.createCell('Список погибших', AlignmentType.CENTER, VerticalAlign.CENTER, true, 11, 6)],
        }),
      );
    }
    rowsDeath.push(
      new TableRow({
        children: [
          this.createCell(
            '№',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            undefined,
            'yellow',
            undefined,
            300,
            0,
            'п/п',
          ),
          this.createCell(
            'ФИО',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            undefined,
            'yellow',
            undefined,
            300,
          ),
          this.createCell(
            'Год Рождения',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            undefined,
            'yellow',
            undefined,
            300,
          ),
          this.createCell(
            'Место нахождения тела',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            undefined,
            'yellow',
            undefined,
            300,
          ),
          this.createCell(
            'Планируемое место захоронения',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            undefined,
            'yellow',
            undefined,
            300,
          ),
          this.createCell(
            'Примечание',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            undefined,
            'yellow',
            undefined,
            300,
          ),
        ],
        tableHeader: true,
      }),
    );
    if (victims.length) {
      for (let i = 0; i < victims.length; i++) {
        rowsDeath.push(
          new TableRow({
            children: [
              this.createCell(this.prepareValue(`${i + 1}`), AlignmentType.CENTER, VerticalAlign.CENTER, false, 11),
              this.createCell(this.prepareValue(victims[i].fio), AlignmentType.CENTER, VerticalAlign.CENTER, false, 11),
              this.createCell(
                this.prepareValue(victims[i].birthYear),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
              ),
              this.createCell(
                this.prepareValue(victims[i].bodyLocation),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
              ),
              this.createCell(
                this.prepareValue(victims[i].burialPlace),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
              ),
              this.createCell(
                this.prepareValue(victims[i].comment),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
              ),
            ],
            tableHeader: true,
          }),
        );
      }
    } else {
      rowsDeath.push(
        new TableRow({
          children: [this.createCell('Погибших нет.', AlignmentType.CENTER, VerticalAlign.CENTER, false, 11, 6)],
        }),
      );
    }
    return new Table({
      rows: rowsDeath,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });
  }

  /**
   * Формирование таблицы эвакуированных
   * @param victims пострадавшие
   * @param address населенный пункт, откуда эвакуировано население (Emergency.address.fullText)
   * @param hasTitle флаг для отображения заголовка
   * */
  // TODO использовать интерфейс из smart-city-types после влития bg-6955
  private formEvacuatedTable(victims: any[], address: string, hasTitle: boolean = false): Table {
    const rowsEvacuated: TableRow[] = [];
    if (hasTitle) {
      rowsEvacuated.push(
        new TableRow({
          children: [this.createCell('Список эвакуированных', AlignmentType.CENTER, VerticalAlign.CENTER, true, 11, 8)],
        }),
      );
    }
    rowsEvacuated.push(
      new TableRow({
        children: [
          this.createCell(
            '№',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            2,
            'yellow',
            60,
            300,
            220,
            'п/п',
          ),
          this.createCell(
            'ФИО',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            2,
            'yellow',
            100,
            300,
          ),
          this.createCell(
            'Год Рождения',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            2,
            'yellow',
            0,
            300,
          ),
          this.createCell(
            'Населенный пункт, откуда эвакуировано население',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            2,
            'yellow',
            0,
            250,
            0,
          ),
          this.createCell(
            'Место эвакуации',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            3,
            undefined,
            'yellow',
            undefined,
            300,
          ),
          this.createCell(
            'Примечание',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            true,
            11,
            undefined,
            2,
            'yellow',
            undefined,
            300,
          ),
        ],
        tableHeader: true,
      }),
      new TableRow({
        children: [
          this.createCell(
            'Населенный пункт',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            false,
            10,
            undefined,
            undefined,
            'yellow',
            0,
            0,
            0,
          ),
          this.createCell(
            'ПВР',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            false,
            10,
            undefined,
            undefined,
            'yellow',
            100,
            0,
            0,
          ),
          this.createCell(
            'Адрес родственников',
            AlignmentType.CENTER,
            VerticalAlign.CENTER,
            false,
            10,
            undefined,
            undefined,
            'yellow',
            0,
            0,
            0,
          ),
        ],
      }),
    );
    if (victims.length) {
      for (let i = 0; i < victims.length; i++) {
        rowsEvacuated.push(
          new TableRow({
            children: [
              this.createCell(this.prepareValue(`${i + 1}`), AlignmentType.CENTER, VerticalAlign.CENTER, false, 11),
              this.createCell(this.prepareValue(victims[i].fio), AlignmentType.CENTER, VerticalAlign.CENTER, false, 11),
              this.createCell(
                this.prepareValue(victims[i].birthYear),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
              ),
              this.createCell(this.prepareValue(address), AlignmentType.CENTER, VerticalAlign.CENTER, false, 11),
              this.createCell(
                this.prepareValue(victims[i]['shelterId.address.fullText']),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                0,
                0,
                undefined,
                0,
              ),
              this.createCell(
                this.prepareValue(victims[i].shelterId?.name),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                0,
                0,
                undefined,
                400,
              ),
              this.createCell(
                this.prepareValue(victims[i].relativesAddress),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
              ),
              this.createCell(
                this.prepareValue(victims[i].comment),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
              ),
            ],
            tableHeader: true,
          }),
        );
      }
    } else {
      rowsEvacuated.push(
        new TableRow({
          children: [
            this.createCell('Эвакуация не требуется.', AlignmentType.CENTER, VerticalAlign.CENTER, false, 11, 8),
          ],
        }),
      );
    }
    return new Table({
      rows: rowsEvacuated,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });
  }

  // TODO добавить тип IEmergencyResolutionDto из smart-city-types, когда в него добавят нужные поля
  // TODO Вынести заголовок в объявление rows
  /** Формирование таблицы "Итого" */
  private formTotalTable(type: string, resolution: any): Table {
    let rowsTotal: TableRow[];
    switch (type) {
      case 'hospitalized':
        rowsTotal = [
          new TableRow({
            children: [
              this.createCell(
                'Всего',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
              this.createCell(
                this.prepareValue(resolution?.peopleInTheZone),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                'из них детей:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
              this.createCell(
                this.prepareValue(resolution?.peopleInTheZoneChildren),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                'Госпитализировано:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
              this.createCell(
                this.prepareValue(resolution?.hospitalized),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                'из них детей:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
              this.createCell(
                this.prepareValue(resolution?.hospitalizedChildren),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
            ],
            tableHeader: true,
          }),
        ];
        break;
      case 'death':
        rowsTotal = [
          new TableRow({
            children: [
              this.createCell(
                'Всего',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
              this.createCell(
                this.prepareValue(resolution?.peopleInTheZone),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                'из них детей:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
              this.createCell(
                this.prepareValue(resolution?.peopleInTheZoneChildren),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                'Погибло:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
              this.createCell(
                this.prepareValue(resolution?.death),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                'из них детей:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
              this.createCell(
                this.prepareValue(resolution?.deathChildren),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
            ],
            tableHeader: true,
          }),
        ];
        break;
      case 'evacuated':
        rowsTotal = [
          new TableRow({
            children: [
              this.createCell(
                'Всего',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
              this.createCell(
                this.prepareValue(resolution?.peopleInTheZone),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                'из них детей:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
              this.createCell(
                this.prepareValue(resolution?.peopleInTheZoneChildren),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                'Эвакуировано:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
              this.createCell(
                this.prepareValue(resolution?.evacuated),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                'из них детей:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
              this.createCell(
                this.prepareValue(resolution?.evacuatedChildren),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
            ],
            tableHeader: true,
          }),
        ];
        break;
      case 'all': {
        rowsTotal = [
          new TableRow({
            children: [
              this.createCell(
                'Всего в зоне ЧС',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
              this.createCell(
                this.prepareValue(resolution?.peopleInTheZone),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                'из них детей:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
              this.createCell(
                this.prepareValue(resolution?.peopleInTheZoneChildren),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'yellow',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                'Госпитализировано:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
              this.createCell(
                this.prepareValue(resolution?.hospitalized),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                'из них детей:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
              this.createCell(
                this.prepareValue(resolution?.hospitalizedChildren),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                '-',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'white',
              ),
              this.createCell(
                '-',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'white',
              ),
            ],
            tableHeader: true,
          }),

          new TableRow({
            children: [
              this.createCell(
                'Эвакуировано:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
              this.createCell(
                this.prepareValue(resolution?.evacuated),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                'из них детей:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
              this.createCell(
                this.prepareValue(resolution?.evacuatedChildren),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
            ],
            tableHeader: true,
          }),

          new TableRow({
            children: [
              this.createCell(
                '-',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'white',
              ),
              this.createCell(
                '-',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'white',
              ),
            ],
            tableHeader: true,
          }),

          new TableRow({
            children: [
              this.createCell(
                'Погибло:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
              this.createCell(
                this.prepareValue(resolution?.death),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
            ],
            tableHeader: true,
          }),
          new TableRow({
            children: [
              this.createCell(
                'из них детей:',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
              this.createCell(
                this.prepareValue(resolution?.deathChildren),
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                '#92D050',
              ),
            ],
            tableHeader: true,
          }),

          new TableRow({
            children: [
              this.createCell(
                'БСМЭ',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'white',
              ),
              this.createCell(
                '0',
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                false,
                11,
                undefined,
                undefined,
                'white',
              ),
            ],
            tableHeader: true,
          }),
        ];
        break;
      }
      default:
        return null;
    }
    return new Table({
      rows: rowsTotal,
      width: {
        size: 100,
        type: WidthType.AUTO,
      },
    });
  }

  public getOrganizationsForAnnualReport(mo?: string): Observable<IOrganization[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          query: {
            active: true,
            ...(mo ? { mo: mo } : {}),
          },
          attributes: ['id', 'name'],
        },
        data: { sort: { field: 'name', direction: 'asc' } },
      })
      .pipe(map((report: IAbstractServiceData) => report.data.items));
  }

  public getManagingOrganizations(): Observable<IOrganization[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          query: {
            'orgTypeParam.organizationTypeId.sysname': 'managingOrganization',
            active: true,
          },
          attributes: ['id', 'name'],
        },
      })
      .pipe(
        map((report: IAbstractServiceData) =>
          report.data.items.sort((a: IOrganization, b: IOrganization) => a.name.localeCompare(b.name)),
        ),
      );
  }

  public getKsipTypes(): Observable<IIncidentType[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'IncidentTypes',
          attributes: ['id', 'name'],
        },
      })
      .pipe(
        map((report: IAbstractServiceData) =>
          report.data.items.sort((a: IIncidentType, b: IIncidentType) => a.name.localeCompare(b.name)),
        ),
      );
  }

  /** Извещение о необходимости обновить грид с отчетами */
  public runReportProtocolReload(): void {
    this.reportProtocolReload.next();
  }

  /**
   * Удаление записи об отчете и файла отчета
   * @param id - ID отчета
   * @param reportUUID - ID файла в хранилище SFS
   */
  deleteReport(id: string, reportUUID: string) {
    return this.rest
      .serviceRequest({
        action: 'delete',
        service: { name: 'Report' },
        entity: {
          name: 'ReportProtocol',
          query: { id },
        },
      })
      .pipe(switchMap(() => this.sfs.delete('reportUUID')));
  }

  public getOrganizations(mo?: string): Observable<IOrganization[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          query: {
            active: true,
            ...(mo ? { mo: mo } : {}),
          },
          attributes: ['id', 'name'],
        },
        data: { sort: { field: 'name', direction: 'asc' } },
      })
      .pipe(map((report: IAbstractServiceData) => report.data.items));
  }

  public getReasons(sysname?: string): Observable<IReasonDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Reason',
          query: {
            active: true,
            'groupId.sysName': sysname,
          },
          attributes: ['id', 'name'],
        },
        data: { sort: { field: 'name', direction: 'asc' } },
      })
      .pipe(map((report: IAbstractServiceData) => report.data.items));
  }

  /**
   * Изменить часовой пояс у даты
   * @param value значение даты
   * @param toConfig привести дату к часовому поясу из конфига или к поясу пользователя
   */
  public changeTimeZone(value: string | number | Date | dayjs.Dayjs, toConfig?: boolean): number {
    return toConfig
      ? +dayjs(value)
        .subtract(new Date().getTimezoneOffset(), 'minutes')
        .subtract(+this.settings.getConfig().utcOffset, 'hours')
      : +dayjs(value)
        .add(+this.settings.getConfig().utcOffset, 'hours')
        .add(new Date().getTimezoneOffset(), 'minutes');
  }
}
