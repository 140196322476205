import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AccessService, IAccessAction, SfsService } from '@smart-city/core/services';
import { forkJoin, of } from 'rxjs';
import { catchError, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { IArchiveTaskDto, IEmergencyVaDetailDto, IVideoDeviceDto } from 'smart-city-types';
import { IAbstractServiceData } from 'smart-city-types/services';
import { THIRTY_SECONDS } from '@bg-front/core/models/constants';
import { IDownloadSourcesOptions, IMonitoringDate, IVideoDeviceBg } from '../../models/interfaces';
import { VideoDevicesService, VideoResultsService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';

/**
 * Результат видеоаналитики "Громкий звук"
 */
@Component({
  selector: 'bg-video-result-loud-sound',
  templateUrl: './video-result-loud-sound.component.html',
  styleUrls: ['./video-result-loud-sound.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoResultLoudSoundComponent extends BaseComponent implements OnInit {
  /**
   * Имя камеры
   */
  public name: string = undefined;

  /** Перриод просмотра, для нового плеера*/
  public customPeriod;

  /** id камеры */
  public cameraId: string | IVideoDeviceDto;

  /** Опции для кнопок скачать фото/видео */
  public downloadSourcesOptions: IDownloadSourcesOptions;

  /** Внешний id камеры */
  public extCameraId: string = undefined;

  /**
   * Ссылки на фотографии
   */
  @Input()
  public imagesUrls: string[] = [];

  /** Опция id - сущности */
  @Input()
  public entityId: string;
  @Input()
  public incidentNumber: string;
  /**
   * Ссылка на поток
   */
  streamUrl: string = undefined;

  /** ID файла с видео в хранилище SFS */
  public sfsId: string = undefined;

  /** Статус получения видео */
  public videoStatus: string = 'Запущен процесс получения видео';

  /** Доступность скачивания материалов */
  public canPreparePhotoAndVideo: IAccessAction = { visible: false, enabled: false, name: 'CanPreparePhotoAndVideo' };
  /** Доступность просмотра фото и видео */
  public canPhotoAndVideo: IAccessAction = {
    visible: false,
    enabled: false,
    name: 'CanPhotoAndVideo',
  };

  constructor(
    private readonly videoDevicesService: VideoDevicesService,
    private readonly cdr: ChangeDetectorRef,
    private readonly sfs: SfsService,
    private readonly accessService: AccessService,
    private readonly videoResultsService: VideoResultsService,
  ) {
    super();
  }

  @Input()
  public set vaDetail(val: IEmergencyVaDetailDto) {
    if (val) {
      this.cameraId = val.camera1Id;
      const vaTypeId = typeof val.vaTypeId === 'string' ? val.vaTypeId : val.vaTypeId.id;

      const videoServerData = this.videoDevicesService
        .getVideoDevice(val.camera1Id as string)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          mergeMap((result: IVideoDeviceBg) => {
            if (result) {
              this.extCameraId = result.extId;
              this.name = result.name;
              return this.videoDevicesService.getVideoServerById(<string>result.videoServer);
            }
            return of(null);
          }),
          catchError((error: Error) => this.catchErrorFn<IVideoDeviceDto>(error, 'Ошибка загрузки данных о камере')),
        );

      this.videoDevicesService
        .getTypeVaSettingsByMonitoringSubject(vaTypeId)
        .pipe(
          switchMap((monitoringData: IMonitoringDate) => {
            return forkJoin([
              of(monitoringData),
              this.videoDevicesService.getVideoDeviceRangeStreamUrl(val.camera1Id as string, val.camera1Time),
              this.videoDevicesService.getMacroscopExportArchiveURL(
                val.camera1Id as string,
                val.camera1Time - (monitoringData?.timeBeforeEvent || THIRTY_SECONDS),
                val.camera1Time + (monitoringData?.timeAfterEvent || THIRTY_SECONDS),
              ),
              videoServerData,
            ]);
          }),
          switchMap((result: [IMonitoringDate, string, string, IAbstractServiceData]) => {
            const fromTime = val.camera1Time - (result[0]?.timeBeforeEvent || THIRTY_SECONDS);
            const toTime = val.camera1Time + (result[0]?.timeAfterEvent || THIRTY_SECONDS);
            const items = result ? result[3].data.items : [];
            const serverData = [];
            if (items.length) {
              serverData[0] = {
                ...items[0],
                extId: this.extCameraId,
              };
            }

            this.downloadSourcesOptions = {
              vaTypeId,
              fromTime,
              toTime,
              serverData,
              entityId: this.entityId,
              photoList: JSON.parse(val.file1Id),
              timeCreate: +val.camera1Time,
              isShowVideoButton: !!result[1],
              photoFileName: `Фотоматериалы_${this.incidentNumber}`,
              videoFileName: `Видеоматериалы_${this.incidentNumber}`,
            };

            this.customPeriod = {
              fromtime: fromTime,
              totime: toTime,
            };

            this.streamUrl = result[1];

            this.cdr.detectChanges();

            return this.videoResultsService.createArchive(result[2], this.entityId);
          }),
          takeUntil(this.ngUnsubscribe),
        ).subscribe((archive: IArchiveTaskDto) => {
        switch (archive.status) {
          case 'error':
            this.videoStatus = 'Ошибка запроса видео';
            this.cdr.detectChanges();
            return;
          case 'success':
            this.sfsId = archive.video[0];
            this.cdr.detectChanges();
            return;
        }
      });

      this.imagesUrls = JSON.parse(val.file1Id)?.map((id: string) => this.sfs.getStreamUrl(id));
      this.cdr.detectChanges();
    }
  }

  public ngOnInit(): void {
    this.canPreparePhotoAndVideo = this.accessService.accessMap[this.canPreparePhotoAndVideo.name];
    this.canPhotoAndVideo =
      this.accessService.accessMap[this.canPhotoAndVideo.name];

    this.downloadSourcesOptions = {
      ...this.downloadSourcesOptions,
      photoFileName: `Фотоматериалы_${this.incidentNumber}`,
      videoFileName: `Видеоматериалы_${this.incidentNumber}`,
    };
  }
}
