import { Injectable } from '@angular/core';
import { SELECTOR_SHELTER_FLOOR } from '@bg-front/core/models/constants';
import { IForecastingSaveResultDto, IForecastingTaskParamsDto, ISelectItem } from '@bg-front/core/models/interfaces';
import { ILimit, ISort, RestService, Settings2Service } from '@smart-city/core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAbstractServiceData, IFireFightingEquipmentDto, IAnyObject } from 'smart-city-types';

import { IEmergencyForReport, IForecastingDistanceTraveledByFireFrontEmergencyData } from '../../models';

/**
 * Сервис для прогнозирования
 */
@Injectable()
export class ForecastingFiresService {
  constructor(private readonly settings: Settings2Service, private readonly rest: RestService) {}
  /**
   *  Фильтрую список данных для селектора shelterType если выбран "Город, улица местного значения"
   *  в контроле "Расположение укрытия"
   *  @return
   */
  public shelterTypeForLocationAppointment(): ISelectItem[] {
    const exeptedItems = [
      'manufactory',
      'industrialThreeFloorBuilding',
      'stonesOneFloorBuilding',
      'stonesTwoFloorBuilding',
      'stonesThreeFloorBuilding',
      'stonesFiveFloorBuilding',
      'woodOneFloorBuilding',
      'woodTwoFloorBuilding',
    ];
    return this.settings
      .getDictionaryByTypeSysName('typeShelterFromRadiation')
      .filter(({ sysname }) => {
        return exeptedItems.includes(sysname);
      })
      .map((item) => {
        return <ISelectItem>{ value: item.id, text: item.name };
      });
  }

  /**
   *  Получение списка этажей для конкретного типа укрытия для рогнозирования
   *  @param  sysname - ситемное наименование
   *  @return
   */
  public shelterFloorForType(sysname: string): ISelectItem[] {
    const shelterFloorList: string[] = SELECTOR_SHELTER_FLOOR[sysname];
    return this.settings
      .getDictionaryByTypeSysName('floorShelterFromRadiation')
      .filter((item) => {
        if (!shelterFloorList) {
          return item;
        }
        return shelterFloorList.includes(item.sysname);
      })
      .map((item) => {
        return <ISelectItem>{ value: item.id, text: item.name };
      });
  }

  /** Выбор задачи для прогнозирования Радиоактивной обстановки */
  public startRadioactiveForecasting(data: IForecastingTaskParamsDto) {
    return this.rest
      .serviceRequest({
        data,
        action: 'selectRadiationForecastingTask',
        service: { name: 'Forecasting' },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return res.data;
        }),
      );
  }

  /** Запрос средств тушения. Дублирует код модуля */
  public getFireEquipment(): Observable<IFireFightingEquipmentDto[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: { name: 'FireFightingEquipment' },
      })
      .pipe(map((fireEquip: IAbstractServiceData) => fireEquip.data?.items || []));
  }

  /** TODO написать интерфейс, избавиться от any
   * Получить сервер мониторинга с требуемыми полями.
   * @param query фильтр для запроса
   * @param sort сортировка записей запроса
   * @param limit ограничения запрашиваемых данных
   */
  public getKseonUnit(query?: any, limit?: ILimit, sort?: ISort): Observable<any> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Ksion' },
        entity: {
          query,
          name: 'Units',
          attributes: [
            'extId',
            'name',
            'address',
            'server',
            'coordinates',
            'currentSeance.id',
            'currentSeance.name',
            'type.name',
            'syncTime',
          ],
        },
        data: {
          limit,
          sort,
        },
      })
      .pipe(
        map((unit) => {
          return unit?.data?.items?.map((item) => ({
            id: item.id,
            name: item.name,
            extId: item.extId,
            address: item.address,
            coordinates: item.coordinates,
            server: item.server,
            currentSeanceId: item.currentSeance?.id,
            currentSeanceName: item.currentSeance?.name,
            type: item.type?.name,
            syncTime: item.syncTime,
          }));
        }),
      );
  }

  /** Запрос дополнительной информации об инциденте */
  public getEmergencyForecastingResultById(id: string): Observable<IForecastingSaveResultDto> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Forecasting' },
        entity: {
          name: 'ForecastingResults',
          query: {
            id,
          },
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return (res.data.items[0] || {}) as IForecastingSaveResultDto;
        }),
      );
  }

  /**
   * Получение данных инцидента для отчёта по Id
   * @param id ID инцидента
   */
  public getIncidentData(id: string): Observable<IEmergencyForReport> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          attributes: ['timeCreate', 'number', 'address', 'addressFact', 'coordinates'],
          query: {
            id
          }
        },
      })
      .pipe(map((response: IAbstractServiceData): IEmergencyForReport => response.data.items[0] as IEmergencyForReport));
  }

  /** Запрос дополнительной информации об инциденте */
  public getDistanceTraveledByFireFrontDataFromEmergency(
    id: string,
  ): Observable<IForecastingDistanceTraveledByFireFrontEmergencyData> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Emergency' },
        entity: {
          name: 'Emergency',
          attributes: ['resolution', 'parentEventId.ksipTime'],
          query: {
            id,
          },
        },
      })
      .pipe(
        map(
          (res: IAbstractServiceData): IForecastingDistanceTraveledByFireFrontEmergencyData => {
            const data = res.data.items[0] || {};
            return <IForecastingDistanceTraveledByFireFrontEmergencyData>{
              ksipTime: data['parentEventId.ksipTime'],
              timeFirstSalvo: data.resolution.timeFirstSalvo,
              timeLocalization: data.resolution.timeLocalization,
            };
          },
        ),
      );
  }

  /** Получение списка объектов пожара */
  public getFireObjects(): Observable<ISelectItem[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: { name: 'FireObjects' },
      })
      .pipe(
        map((fireObjects: IAbstractServiceData) => {
          return (fireObjects.data?.items || []).map(
            ({ id, name }: { id: string; name: string }): ISelectItem =>
              <ISelectItem>{
                text: name,
                value: id,
              },
          );
        }),
      );
  }

  /** Расчёт пожароопасности погоды */
  public getFireHazardType(countTaskId: string): Observable<string> {
    return this.rest
      .serviceRequest({
        action: 'calculateForecasting',
        service: { name: 'Forecasting' },
        data: {
          countTaskId,
        },
      })
      .pipe(
        map((res: IAbstractServiceData) => {
          return res.data;
        }),
      );
  }
}
