import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, IElementButton, IScTextareaOptions } from '@smart-city/core/common';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import {
  IDds01FireParamsDto,
  IEmergencyDto,
  IEmergencyResolutionDto,
  ILifeCycleStepActionDto,
  ILifeCycleStepDto,
} from 'smart-city-types';
import { Coordinates } from '@bg-front/core/models/classes';
import { BUTTON_ICONS } from '@bg-front/core/models/constants';
import { IMiniMapMarkerPositionEvent } from '../../models/interfaces';
import { BaseIncidentFormComponent } from '../base-incident-form/base-incident-form.component';
import { BgLoosesComponent } from '../bg-looses/bg-looses.component';
import { AccessService, RestService, Settings2Service } from '@smart-city/core/services';
import {
  AtmIntegrationService,
  BgAdminService,
  EmergencyService,
  MiniMapService,
  OrganizationsService,
  ResponsePlanStepService,
} from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { MultiFileService } from '@bg-front/core/services';
import { ReportingService } from '../../../bg/modules/reporting/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';

@Component({
  selector: 'bg-order-edit-form',
  templateUrl: './order-edit-form.component.html',
  styleUrls: ['./order-edit-form.component.scss'],
})
export class OrderEditFormComponent extends BaseIncidentFormComponent implements OnInit, AfterViewInit {
  /** Настройка компоненты Комментарий */
  public optionsDds01Comment: IScTextareaOptions = {
    label: 'Комментарий исполнителя',
    rows: 2,
  };

  /** Центр миникарты */
  public miniMapCenter: MapBaseCoordinatesType;

  /** Зум для миникарты */
  public miniMapZoom: number;

  /** Координаты по умолчанию для мини карты */
  public defaultMarkerCoordinate: [number, number];

  /** Поле номера инцидента */
  public linkedName: string;

  /** @ignore */
  constructor(
    accessService: AccessService,
    atmIntegrationService: AtmIntegrationService,
    bgAdminService: BgAdminService,
    dialog: MatDialog,
    dialogService: DialogService,
    emergencyService: EmergencyService,
    fb: FormBuilder,
    ksipCategoriesQuery: KsipCategoriesQuery,
    ksipDetailsQuery: KsipDetailsQuery,
    ksipSectionsQuery: KsipSectionsQuery,
    ksipTypesQuery: KsipTypesQuery,
    miniMapService: MiniMapService,
    multiFileService: MultiFileService,
    organizationsService: OrganizationsService,
    reportingService: ReportingService,
    route: ActivatedRoute,
    router: Router,
    settings: Settings2Service,
    significantObjectService: SignificantObjectsService,
    responsePlanStepService: ResponsePlanStepService,
  ) {
    super(
      accessService,
      atmIntegrationService,
      bgAdminService,
      dialog,
      dialogService,
      emergencyService,
      fb,
      ksipCategoriesQuery,
      ksipDetailsQuery,
      ksipSectionsQuery,
      ksipTypesQuery,
      miniMapService,
      multiFileService,
      organizationsService,
      reportingService,
      route,
      router,
      settings,
      significantObjectService,
      responsePlanStepService,
    );
  }

  /** @ignore */
  ngOnInit(): void {
    if (!this.model.parentId['number']?.startsWith('w')) {
      this.linkedName = `Инцидент ${this.model?.parentId['number']}`;
    } else {
      this.linkedName = `Плановая работа ${this.model?.parentId['number']}`;
    }
    this.incidentForm = new FormGroup({
      /** ----- MAIN ----- **/
      byCoordinates: new FormControl(this.model.byCoordinates),
      addressFact: new FormControl(this.model.addressFact, [Validators.required]),
      coordinatesAddress: new FormControl(this.model.byCoordinates ? (this.model.addressFact.fullText || this.model.address.fullText) : null),
      moId: new FormControl(this.model.moId),
      districtId: new FormControl({ value: this.model.districtId, disabled: true }),
      detailsFact: new FormControl(this.model.detailsFact),
      incidentTypeId: new FormControl(this.model.incidentTypeId, [Validators.required]),
      ksipDetailsId: new FormControl(this.model.ksipDetailsId),
      comment: new FormControl(this.model.comment),
      description: new FormControl(this.model.description),
      organization: new FormControl(this.model.organization),
      urgently: new FormControl(this.model.urgently),
      responsibleUser: new FormControl(this.model.responsible || this.settings.currentUser.id, [Validators.required]),
      deadline: new FormControl(this.model.deadline),
      threatPopulation: new FormControl((this.model.resolution || <IEmergencyResolutionDto>{}).danger),
      threatOrganization: new FormControl((this.model.resolution || <IEmergencyResolutionDto>{}).dangerOrg),
      jkhObject: new FormControl((this.model.jkhObject)),
      workTermFrom: new FormControl(this.model.workTermFrom),
      workTermTo: new FormControl(this.model.workTermTo),
      /** ----- PARAMS ----- **/
      fireParams: new FormControl(this.model.fireParams || <IDds01FireParamsDto>{}),
      /** ----- PLACEMENT ----- **/
      coordinates: new FormControl(this.model.coordinates),
      ksipPlaceDescription: new FormControl(this.model.ksipPlaceDescription),
      /** ----- ADDITIONAL ----- **/
      dds01AdditionalInfo: new FormControl(this.model.dds01AdditionalInfo),
      dds01Comment: new FormControl(this.model.dds01AdditionalInfo?.comment || undefined),
      /** ----- LOOSES ----- **/
      resolution: BgLoosesComponent.generateFormGroup(this.fb, {
        ...this.model.resolution,
        peopleInTheZoneOnDate: this.model.resolution?.peopleInTheZoneOnDate || this.model.timeCreate,
        deathOnDate: this.model.resolution?.deathOnDate || this.model.timeCreate,
        rescuedOnDate: this.model.resolution?.rescuedOnDate || this.model.timeCreate,
        victimOnDate: this.model.resolution?.victimOnDate || this.model.timeCreate,
        hospitalizedOnDate: this.model.resolution?.hospitalizedOnDate || this.model.timeCreate,
        evacuatedOnDate: this.model.resolution?.evacuatedOnDate || this.model.timeCreate,
        missingOnDate: this.model.resolution?.missingOnDate || this.model.timeCreate,
      }),
      /** ----- DOCS ----- **/
      documents: new FormControl(this.model.documents),
      totalRegistryPeopleHouse: new FormControl(this.model.totalRegistryPeopleHouse),
      totalTemporalPeopleHouse: new FormControl(this.model.totalTemporalPeopleHouse),
      individualOwnership: new FormControl(this.model.individualOwnership),
    });

    this.eventsGridQuery = { incidentId: (<IEmergencyDto>this.model.parentId).id };

    super.ngOnInit();

    this.actions = (this.model?.lifeCycleStepId as ILifeCycleStepDto)?.actions || [];
    this.headerActionsOptions.title = `Поручение ${this.model.number ?? ''}`;
    this.miniMapCenter = this.getMunicipalCoordinates();
    this.miniMapZoom = parseInt(this.getMunicipal().zoom, 10);

    const markerCoordinates = new Coordinates(this.model.mapMarkerCoordinate || this.model.coordinates);
    if (markerCoordinates.isValid()) {
      this.defaultMarkerCoordinate = markerCoordinates.toArray();
    }

    this.optionsResponsibleUser.query = { isActive: true, organizationId: this.model.organization };

    this.updateForm();
  }

  /**
   * Установка маркера
   * @param miniMapEvent событие
   */
  public updateCoordinates(miniMapEvent: IMiniMapMarkerPositionEvent) {
    const markerCoordinates = new Coordinates(this.incidentForm.controls['mapMarkerCoordinate'].value);
    const newMarkerCoordinates = new Coordinates(miniMapEvent?.coordinates[0], miniMapEvent?.coordinates[1]);
    if (!markerCoordinates.equal(newMarkerCoordinates)) {
      this.incidentForm.controls['mapMarkerCoordinate'].setValue(newMarkerCoordinates.toString());
      this.redrawEventMarker(this.incidentForm.controls['mapMarkerCoordinate'].value);
      this.model.mapMarkerCoordinate = this.incidentForm.controls['mapMarkerCoordinate'].value;
    }
  }

  /** Получение данных из формы и сохранение в модель */
  public getModelData() {
    if (!this.model.id) {
      this.model.timeCreate = dayjs().unix() * 1000;
      this.model.number = `i-${dayjs(this.model.timeCreate).format('YYYYMMDD-HHmmss')}`;
      this.model.organization = this.settings.currentUser.organizationId.id;
      this.model.createdBy = this.settings.currentUser.id;
    }
    /** ----- MAIN ----- **/
    this.model.byCoordinates = this.incidentForm.controls['byCoordinates'].value;
    this.model.addressFact = this.model.byCoordinates
      ? this.addressByCoordinates
      : this.incidentForm.controls['addressFact'].value;
    this.model.moId = <string>this.moIdComponent.value;
    this.model.districtId = <string>this.districtIdComponent.value;
    this.model.detailsFact = this.incidentForm.controls['detailsFact'].value;
    this.model.incidentTypeId = this.incidentForm.controls['incidentTypeId'].value;
    this.model.ksipDetailsId = this.incidentForm.controls['ksipDetailsId'].value;
    this.model.comment = this.incidentForm.controls['comment'].value;
    this.model.description = this.incidentForm.controls['description'].value;
    this.model.responsible = this.incidentForm.controls['responsibleUser'].value;
    this.model.resolution = this.model.resolution || <IEmergencyResolutionDto>{};
    this.model.urgently = this.incidentForm.controls['urgently'].value;
    this.model.resolution.danger = this.incidentForm.controls['threatPopulation'].value;
    this.model.resolution.dangerOrg = this.incidentForm.controls['threatOrganization'].value;
    /** ----- PARAMS ----- **/
    this.model.fireParams = this.incidentForm.controls['fireParams'].value || <IDds01FireParamsDto>{};
    /** ----- PLACEMENT ----- **/
    this.model.coordinates = this.incidentForm.controls['coordinates'].value;
    this.model.mapMarkerCoordinate = this.incidentForm.controls['mapMarkerCoordinate']?.value;
    this.model.ksipPlaceDescription = this.incidentForm.controls['ksipPlaceDescription'].value;
    /** ----- ADDITIONAL ----- **/
    this.model.dds01AdditionalInfo = this.incidentForm.controls['dds01AdditionalInfo'].value || {};
    this.model.dds01AdditionalInfo['comment'] = this.incidentForm.controls['dds01Comment'].value;
    // Значения по умолчанию
    if (!this.model?.dds01AdditionalInfo?.timeDetection) {
      this.model.dds01AdditionalInfo.timeDetection = this.model.timeCreate;
    }
    /** ----- LOOSES ----- **/
    this.model.resolution = this.incidentForm.controls['resolution'].value || <IEmergencyResolutionDto>{};
    /** ----- DOCS ----- **/
    this.model.documents = this.incidentForm.controls['documents'].value;
    this.model.totalRegistryPeopleHouse = Number(this.incidentForm.controls['totalRegistryPeopleHouse'].value) || 0;
    this.model.totalTemporalPeopleHouse = Number(this.incidentForm.controls['totalTemporalPeopleHouse'].value) || 0;
    this.model.individualOwnership = this.incidentForm.controls['individualOwnership'].value;
    this.model.workTermFrom = this.incidentForm.controls['workTermFrom'].value;
    this.model.workTermTo = this.incidentForm.controls['workTermTo'].value;
  }

  /** Обновление формы */
  public updateForm() {
    // Обновление заголовка
    this.generateHeaderBar();
    // Блокировка контролов соглазсно ЖЦ
    this.attributes = (this.model.lifeCycleStepId as ILifeCycleStepDto).params;
  }

  public generateHeaderBar() {
    const btnArray = [];

    this.actions.forEach((action: ILifeCycleStepActionDto) =>
      btnArray.push({
        type: 'button',
        options: {
          name: action.name,
          title: action.title,
          icon: BUTTON_ICONS.get(action.name),
        },
      }),
    );

    btnArray.unshift(<IElementButton>{
      type: 'button',
      options: {
        name: 'cancel',
        icon: BUTTON_ICONS.get('cancel'),
      },
    });

    this.headerActionsOptions = {
      title: `Поручение ${this.model.number ?? ''}`,
      name: 'header',
      margin: 'collapse',
      bgColor: 'white',
      buttons: btnArray,
    };
  }

  /** Закрытие формы */
  public closeForm() {
    this.router.navigate(['consolidated-registries/order-register']);
  }

  /** Открыть родительский инцидент в новом окне/вкладке */
  public showIncidentInNewWindow() {
    const id = typeof this.model.parentId === 'object' ? this.model.parentId['id'] : this.model.parentId;
    let targetUrl = window.location.origin;
    targetUrl += `/consolidated-registries/incident-register/(editForm:incident/${id})`;
    window.open(targetUrl);
  }

  /** Раскрытие блока Зона действия */
  public openCoverageArea(): void {
    this.emergencyService.openCoverageArea({ ...this.model, id: this.model.parentId['id'] });
  }
}
