import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { WeatherDataService } from '@bg-front/weather-data/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Settings2Service } from '@smart-city/core/services';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { BaseForecastingRadioactiveEnvironmentComponent } from '../base-forecasting-radioactive-environment/base-forecasting-radioactive-environment.component';

/**
 * Компонент реализует блок параметров прогнозирования "Время подхода радиоактивного облака"
 */
@UntilDestroy()
@Component({
  selector: 'bg-radioactive-cloud-time-task',
  templateUrl: './radioactive-cloud-time-task.component.html',
  styleUrls: ['./radioactive-cloud-time-task.component.scss'],
})
export class RadioactiveCloudTimeTaskComponent
  extends BaseForecastingRadioactiveEnvironmentComponent
  implements OnInit {
  constructor(
    public readonly controlContainer: ControlContainer,
    notificationService: NzNotificationService,
    settings: Settings2Service,
    weatherService: WeatherDataService,
  ) {
    super(settings, weatherService, notificationService);
  }

  /** @ignore */
  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
  }
}
