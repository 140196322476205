import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BUTTON_ICONS } from '@bg-front/core/models/constants';
import { RegistryStateService } from '@bg-front/registry-panel/services';
import {
  DialogService,
  IElementButton,
  INwHeaderBarOptions,
  IScCheckboxOptions,
  IScFias3Options,
  IScInputOptions,
  IScSelectOptions,
  IScTextareaOptions,
} from '@smart-city/core/common';
import { IScDatepickerOptions } from '@smart-city/core/common/components/sc-datepicker/sc-datepicker.component';
import { IDictionaryInfo, IScSelectItem } from '@smart-city/core/interfaces';
import {
  AccessService,
  INotificationMessage,
  RestService,
  Settings2Service,
  SfsService,
} from '@smart-city/core/services';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { Observable, of } from 'rxjs';
import { catchError, takeUntil, switchMap, tap } from 'rxjs/operators';
import {
  IDictionaryModelDto,
  IEmergencyResolutionDto,
  IEmergencyTotalCars,
  ILifeCycleStepActionDto,
  ILifeCycleStepDto,
  IEmergencyEventDto,
  IEmergencyVaDetailDto,
  IAnyObject,
} from 'smart-city-types';

import { EmergencyDto } from '../../models/classes';
import { IIncidentEditForm, IThermopoint, IVideoDeviceBg } from '../../models/interfaces';
import { IServerData } from '@bg-front/core/models/interfaces';
import { BaseIncidentFormComponent } from '../base-incident-form/base-incident-form.component';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ThermopointsService, VideoDevicesService } from '../../services';
import { untilDestroyed } from '@ngneat/until-destroy';
import {
  AtmIntegrationService,
  BgAdminService,
  EmergencyService,
  MiniMapService,
  OrganizationsService,
  ResponsePlanStepService,
} from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { MultiFileService } from '@bg-front/core/services';
import { ReportingService } from '../../../bg/modules/reporting/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';

@Component({
  selector: 'bg-incident-view-form',
  templateUrl: './incident-view-form.component.html',
  styleUrls: ['./incident-view-form.component.scss'],
})
export class IncidentViewFormComponent
  extends BaseIncidentFormComponent
  implements IIncidentEditForm, OnInit, AfterViewInit {
  /** Настройка компоненты фактического адреса */
  public addressFactOptions: IScFias3Options = {
    label: 'Фактический адрес',
    width: '100%',
    isCoordinatesNecessary: true,
  };
  /** Настройка компоненты Отчёт о выполнении инцидента */
  public reportOptions: IScTextareaOptions = {
    label: 'Отчёт о выполнении инцидента',
    maxLength: 1000,
    rows: 8,
  };
  /** Настройка компоненты Доп информация о месте КСиП */
  public placeDescriptionOptions: IScTextareaOptions = {
    label: 'Дополнительная информация о месте КСиП',
    maxLength: 1000,
    rows: 8,
  };
  /** Настройка компоненты Дополнительна информация о технике */
  public additionalInfoAboutCarsOptions: IScTextareaOptions = {
    label: 'Дополнительная информация о технике',
    maxLength: 1000,
    rows: 4,
  };
  /** Настройка компоненты Контрольное время устранения */
  public timeLimitOptions: IScDatepickerOptions = {
    formGroup: this.incidentForm,
  };

  public optionsKsipDetailsUlk: IScSelectOptions = {
    title: 'Детализация КСиП',
    disabled: true,
  };
  /** Настройка компоненты Комментарий */
  public optionsComment: IScTextareaOptions = {
    label: 'Комментарий оператора',
    maxLength: 1000,
    rows: 6,
  };
  /** Настройка компоненты Куда отправляются тела погибших */
  public deathPlaceOptions: IScTextareaOptions = {
    label: 'Куда производится (планируется) отправка тел погибших',
    maxLength: 1000,
    rows: 8,
  };
  /** Настройка компоненты Куда отправляются тела погибших */
  public rescuedPlaceOptions: IScTextareaOptions = {
    label: 'Куда производится (планируется) размещение спасённых',
    maxLength: 1000,
    rows: 8,
  };
  /** Настройка компоненты Куда госпитализируются пострадавшие */
  public victimPlaceOptions: IScTextareaOptions = {
    label: 'Куда производится (планируется) госпитализация пострадавших',
    maxLength: 1000,
    rows: 8,
  };
  /** Настройка компоненты Описание */
  public optionsDescription: IScTextareaOptions = {
    label: 'Описание',
    maxLength: 1000,
    rows: 6,
  };
  /** Настройка компоненты Ответственный пользователь */
  public optionsResponsibleUser: IScSelectOptions = {
    title: 'Ответственный пользователь',
    clearable: true,
    service: 'Admin',
    entity: 'Users',
    modern: true,
    fieldId: 'id',
    fieldName: 'fio',
    query: { isActive: true },
  };
  /** Настройка компоненты Угроза населению */
  public optionsThreatPopulation: IScCheckboxOptions = {
    title: 'Угроза населению',
  };
  /** Настройка компоненты Угроза организации */
  public optionsThreatOrganization: IScCheckboxOptions = {
    title: 'Угроза организации',
  };
  /**
   * Настройка компоненты Ответственная организация
   */
  public optionsOrganization: IScSelectOptions = {
    title: 'Ответственная организация',
    clearable: true,
    service: 'Admin',
    entity: 'Organizations',
    query: { active: true },
    modern: true,
    fieldId: 'id',
    fieldName: 'name',
  };
  /** Настройка компоненты Фактического адреса */
  public coordinatesOption: IScInputOptions = {
    label: 'Координаты фактического адреса',
    maxLength: 100,
  };
  /** Список сообщений об ошибке */
  public errors: INotificationMessage[] = [];
  /** Центр мини-карты */
  public miniMapCenter: MapBaseCoordinatesType;
  //#endregion
  /** Зум для мини-карты */
  public miniMapZoom: number;
  /** Координаты по умолчанию для мини карты */
  public defaultMarkerCoordinate: [number, number];
  /** Объект содержащий информацию о привлечённых машинах */
  public totalCars: IEmergencyTotalCars = undefined;
  /** Свойство рассчитывающее общее количество задействованных машин */
  public summaryCars: number;
  /** Конфигурация заголовка */
  public headerActionsOptions: INwHeaderBarOptions = undefined;
  /** Флаг для отображения блока "Параметры" */
  public showParams: boolean = false;
  /** Опции для настройки блока "Параметры" */
  public projectedRisk: string;
  /** Термоточка */
  public thermoPoint: IThermopoint;
  /** Ссылка на открытие термоточки в новой вкладке */
  public thermoPointInnerLink: string;
  /** Модель */
  @Input()
  public model: EmergencyDto = new EmergencyDto();
  /**
   * Настройка компоненты Режим функционирования
   */
  public optionsRegime: IScSelectOptions = {
    title: 'Введен режим функционирования',
    service: 'Emergency',
    entity: 'Regimes',
    modern: true,
    fieldId: 'id',
    fieldName: 'type.name',
    query: { isActive: true },
    disabled: true,
    afterLoadDataFn: (regimes: IScSelectItem[]) =>
      of(
        regimes.map((regime) => {
          regime.text =
            regime.text +
            (this.settings.getDictionaryById(this.model['regime']?.state)?.sysname === 'withdrawal' ? ' (снят)' : '');
          return regime;
        }),
      ),
  };
  /** Возможные действия с формой */
  @Input()
  public actions: ILifeCycleStepActionDto[] = [];
  /** Массив кнопок формы */
  private buttonsArray: IElementButton[] = [
    {
      type: 'button',
      options: {
        name: 'cancel',
        icon: BUTTON_ICONS.get('cancel'),
      },
    },
    {
      type: 'button',
      options: {
        color: 'primary',
        name: 'bindEvent',
        title: 'Привязать событие',
        icon: 'link',
      },
    },
  ];
    /** Текст подсказки*/
    public videoDeviceTooltipText: string;
    /** Наименование камеры */
    public videoDeviceNameOptions: IScInputOptions = {
      label: 'Наименование',
      required: true,
      readonly: true,
    }
    /** Ссылка на кмеру */
    public source: SafeResourceUrl;
    /** Форма камеры */
    public cameraForm: FormGroup;
    /** Путь сфс к фото */
    public filePath: string;
    /** Связанное событие */
    public emergencyEvent: IEmergencyEventDto;

    /** Флаг видимости блока "Информация для интернет портала" */
    public showInternetPortalInfo: boolean = false;

  /** @ignore */
  constructor(
      private registryState: RegistryStateService,
      private readonly videoDevicesService: VideoDevicesService,
      private readonly sanitizer: DomSanitizer,
      private readonly sfsService: SfsService,
      private readonly thermoPointsService: ThermopointsService,
      accessService: AccessService,
      atmIntegrationService: AtmIntegrationService,
      bgAdminService: BgAdminService,
      dialog: MatDialog,
      dialogService: DialogService,
      emergencyService: EmergencyService,
      fb: FormBuilder,
      ksipCategoriesQuery: KsipCategoriesQuery,
      ksipDetailsQuery: KsipDetailsQuery,
      ksipSectionsQuery: KsipSectionsQuery,
      ksipTypesQuery: KsipTypesQuery,
      miniMapService: MiniMapService,
      multiFileService: MultiFileService,
      organizationsService: OrganizationsService,
      reportingService: ReportingService,
      route: ActivatedRoute,
      router: Router,
      settings: Settings2Service,
      significantObjectService: SignificantObjectsService,
      responsePlanStepService: ResponsePlanStepService,
    ) {
    super(
      accessService,
      atmIntegrationService,
      bgAdminService,
      dialog,
      dialogService,
      emergencyService,
      fb,
      ksipCategoriesQuery,
      ksipDetailsQuery,
      ksipSectionsQuery,
      ksipTypesQuery,
      miniMapService,
      multiFileService,
      organizationsService,
      reportingService,
      route,
      router,
      settings,
      significantObjectService,
      responsePlanStepService,
    );
  }

  /** @ignore */
  public ngOnInit(): void {
    this.thermoPointInnerLink = `${ window.origin }/cuks/workspace/(thermopoint/${ this.thermoPoint?.id }//leftSidebar:all)`;
    const ksip = this.ksipTypesQuery.getById(this.model.incidentTypeId);
    this.isAccidentForm = this.settings.getDictionaryById(ksip.eventTypeId)?.sysname === '001';
    this.isPlanWorkForm = this.settings.getDictionaryById(ksip.eventTypeId)?.sysname === '003';
    this.incidentForm = new FormGroup({
      byCoordinates: new FormControl(this.model.byCoordinates),
      addressFact: new FormControl({
        value: this.model.addressFact?.fullText || '',
        disabled: true,
      }),
      moId: new FormControl(this.model.moId),
      districtId: new FormControl({ value: this.model.districtId, disabled: true }),
      incidentTypeId: new FormControl({ value: this.model.incidentTypeId, disabled: true }),
      ksipDetailsId: new FormControl({ value: this.model.ksipDetailsId, disabled: true }),
      comment: new FormControl({ value: this.model.comment, disabled: true }),
      description: new FormControl({ value: this.model.description, disabled: true }),
      responsibleUser: new FormControl({ value: this.model.responsible, disabled: true }),
      threatPopulation: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).danger,
        disabled: true,
      }),
      threatOrganization: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).dangerOrg,
        disabled: true,
      }),
      organization: new FormControl({ value: this.model.organization, disabled: true }),
      coordinates: new FormControl({ value: this.model.coordinates, disabled: true }),
      ksipPlaceDescription: new FormControl({ value: this.model.ksipPlaceDescription, disabled: true }),
      otherCars: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).otherCars,
        disabled: true,
      }),
      additionalInfoAboutCars: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).additionalInfoAboutCars,
        disabled: true,
      }),
      report: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).report,
        disabled: true,
      }),
      death: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).death || 0,
        disabled: true,
      }),
      deathChildren: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).deathChildren || 0,
        disabled: true,
      }),
      deathOnDate: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).deathOnDate || dayjs().unix() * 1000,
        disabled: true,
      }),
      deathPlace: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).deathPlace,
        disabled: true,
      }),
      rescued: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).rescued || 0,
        disabled: true,
      }),
      rescuedChildren: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).rescuedChildren || 0,
        disabled: true,
      }),
      rescuedOnDate: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).rescuedOnDate || dayjs().unix() * 1000,
        disabled: true,
      }),
      rescuedPlace: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).rescuedPlace,
        disabled: true,
      }),
      victim: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).victim || 0,
        disabled: true,
      }),
      victimChildren: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).victimChildren || 0,
        disabled: true,
      }),
      victimOnDate: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).victimOnDate || dayjs().unix() * 1000,
        disabled: true,
      }),
      victimPlace: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).victimPlace,
        disabled: true,
      }),
      hospitalized: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).hospitalized || 0,
        disabled: true,
      }),
      hospitalizedChildren: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).hospitalizedChildren || 0,
        disabled: true,
      }),
      hospitalizedOnDate: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).hospitalizedOnDate || dayjs().unix() * 1000,
        disabled: true,
      }),
      missing: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).missing || 0,
        disabled: true,
      }),
      missingChildren: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).missingChildren || 0,
        disabled: true,
      }),
      missingOnDate: new FormControl({
        value: (this.model.resolution || <IEmergencyResolutionDto>{}).missingOnDate || dayjs().unix() * 1000,
        disabled: true,
      }),
      timeLimit: new FormControl({ value: this.model.resolution?.timeLimit, disabled: true }),
      mapMarkerCoordinate: new FormControl({ value: this.model.mapMarkerCoordinate, disabled: true }),
      regime: new FormControl(this.model.regimeId),
      documents: new FormControl(this.model.documents),
      workTermFrom: new FormControl({ value: this.model?.workTermFrom, disabled: true }),
      workTermTo: new FormControl({ value: this.model?.workTermTo, disabled: true }),
    });

    this.thermoPointsService.getThermoPointByEventId(this.model.parentEventId as string).pipe(untilDestroyed(this))
      .subscribe((thermoPoint: IThermopoint) => {
        this.thermoPoint = thermoPoint;
      });

    this.emergencyEvent = this.model.parentEventId as IEmergencyEventDto
    this.videoDevicesService.getVideoDevice(
      (this.emergencyEvent.vaDetail as IEmergencyVaDetailDto)?.camera1Id as string,
      [
        'type.name',
        'videoServer.name',
        'status',
        'coordinates',
        'cameraInstallationHeight',
        'modelName',
        'name',
        'mediaUrl'
      ]
    )
    .pipe(
      switchMap((res: IVideoDeviceBg) => {
        const statusName = res.status === 0 ? 'Не работает' : res.status === 1 ? 'Работает и записывает' : ''
        const typeName = (res.type as IDictionaryModelDto)?.name
        const videoServerName = (res.videoServer as IServerData)?.name

        this.videoDeviceTooltipText = (typeName ? `Тип: ${typeName}\n` : '')
        + (videoServerName ? `Видеосервер: ${videoServerName}\n` : '')
        + (statusName ? `Состояние: ${statusName}\n` : '')
        + (res.coordinates ? `Координаты: ${res.coordinates}\n` : '')
        + (res.cameraInstallationHeight ? `Высота: ${res.cameraInstallationHeight}\n` : '')
        + (res.modelName ? `Модель: ${res.modelName}\n` : '')

        this.cameraForm = new FormGroup({
          videoDeviceName: new FormControl(res.name),
        });
        this.source =  this.sanitizer.bypassSecurityTrustResourceUrl(res.mediaUrl)
        return this.videoDevicesService.getForestGuardCameraStatus(res.mediaUrl)
      }),
      catchError((err: Error) => {
        this.source = ''
        console.error(err.message);
        return of(undefined);
      }),
    )
    .subscribe((res: IAnyObject) => {
      if (res?.availability === 'unavailable') this.source = ''
    })
    const fileUuid = JSON.parse((this.emergencyEvent.vaDetail as IEmergencyVaDetailDto)?.file1Id || '[]')?.[0]
    this.filePath = this.sfsService.getStreamUrl(fileUuid)

    super.ngOnInit();
    const headerTitle =
      this.settings
        .getDictionaryByTypeSysName('docType')
        .find((type: IDictionaryInfo) => type.id === this.model.docType)?.name || 'Инцидент';

    const editEventForm: ActivatedRoute = this.route.parent.children.find((route: ActivatedRoute) => route.outlet === 'editEventForm')
    const obs: Observable<Params | null> = !!editEventForm
      ? editEventForm.params.pipe(
          switchMap((value: Params) => this.emergencyService.getEventById(value.id)),
          tap((event: IEmergencyEventDto) => {
            if (event?.isHandled) {
              this.buttonsArray.splice(1, 1)
            }
          }),
          untilDestroyed(this),
        )
      : of(null);
    obs.subscribe(() => {
      this.headerActionsOptions = {
        title: `${headerTitle} ${this.model.number ?? ''}`,
        name: 'header',
        margin: 'collapse',
        bgColor: 'white',
        buttons: this.buttonsArray,
      }
    })

    this.miniMapCenter = this.getMunicipalCoordinates();
    this.miniMapZoom = Number.parseInt(this.getMunicipal().zoom, 10);

    if (this.model.resolution) {
      if (this.model.resolution.fireCar >= 0) {
        this.summaryCars = (this.summaryCars || 0) + this.model.resolution.fireCar;
      }

      if (this.model.resolution.ambulance >= 0) {
        this.summaryCars = (this.summaryCars || 0) + this.model.resolution.ambulance;
      }

      if (this.model.resolution.policeCar >= 0) {
        this.summaryCars = (this.summaryCars || 0) + this.model.resolution.policeCar;
      }
    }

    this.validationControlMap = new Map<string, string>([
      ['addressFact', this.addressFactOptions.label],
      ['incidentTypeId', this.optionsIncidentTypes.title],
      ['responsibleUser', this.optionsResponsibleUser.title],
      ['deathOnDate', 'Потери. Актуально на'],
      ['rescuedOnDate', 'Спасённые. Актуально на'],
      ['victimOnDate', 'Жертвы. Актуально на'],
      ['hospitalizedOnDate', 'Жертвы. Актуально на'],
      ['missingOnDate', 'Пропавшие. Актуально на'],
    ]);

    if (this.model.id) {
      this.emergencyService
        .getEmergencyTotalCars(this.model.id)
        .pipe(
          catchError((error: Error) =>
            this.catchErrorFn<IEmergencyTotalCars>(error, 'Ошибка запроса количества задействованных машин'),
          ),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((total: IEmergencyTotalCars) => {
          this.totalCars = total;
          this.summaryCars = this.model.resolution?.otherCars || 0;
          this.summaryCars += this.totalCars.totalFireCars;
          this.summaryCars += this.totalCars.totalAmbulanceCars;
          this.summaryCars += this.totalCars.totalPoliceCars;
        });
    }
  }

  /** @ignore */
  public ngAfterViewInit(): void {
    this.isDirty$ = of(false);
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public getModelData() {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public updateForm() {}

  /**
   * Обрабатываем нажатие кнопки управления
   * @param $event
   */
  public onClickActionsButton($event: IElementButton): void {
    if ($event.options.name === 'cancel') {
      this.closeForm();
      return;
    }
    if ($event.options.name === 'bindEvent') {
      this.bindEvent();
      return;
    }
  }

  /** Привязать событие к происшествию */
  private bindEvent(): void {
    const currentLifeCycleStepStatus = this.settings.getDictionaryById(
      (<IDictionaryModelDto>(<ILifeCycleStepDto>this.model.lifeCycleStepId).status)?.id
      || <string>(<ILifeCycleStepDto>this.model.lifeCycleStepId).status
    );

    this.registryState.bindToEvent({
      incident: this.model,
      // Если привязывается событие, которое пришло с интернет-портала, то нужно в комментариях для портала указать,
      // в том числе шаг ЖЦ
      lifeCycleStepId: currentLifeCycleStepStatus?.id
        ? {
          id: (<ILifeCycleStepDto>this.model.lifeCycleStepId).id,
          name: (<ILifeCycleStepDto>this.model.lifeCycleStepId).name,
          status: {
            id: currentLifeCycleStepStatus.id,
            name: currentLifeCycleStepStatus.name,
            sysname: currentLifeCycleStepStatus.sysname,
          }
        }
        : undefined,
    });
    this.emergencyService.selectIncident({ id: undefined, docType: 'incident' });
    this.router.navigate([{ outlets: { editForm: null } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }
}
