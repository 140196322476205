import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/** Валидатор даты напоминания об окончании срока хранения видео */
export class RemindDateValidator {
  /**
   * Напоминание может появляться в пределах указанного количество дней до истечения срока хранения
   * @param min нижняя граница периода даты напоминания
   * @param max верхняя граница периода даты напоминания
   */
  static inRange(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value !== '' && (control.value < min || control.value > max)) {
        return { inRange: false };
      }
      return null;
    };
  }
}
