import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DirtyComponent } from '@ngneat/dirty-check-forms';
import { Settings2Service } from '@smart-city/core/services';
import { IDictionaryInfo } from '@smart-city/core/interfaces';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { IEmergencyDto, IEmergencyEventDto } from 'smart-city-types';
import { AppInjector } from '@bg-front/core/models/classes';

import { EmergencyService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';
import { VideoEventShowFormComponent } from '../video-event-show-form/video-event-show-form.component';

@Component({
  selector: 'bg-create-event-form',
  templateUrl: './create-event-form.component.html',
  styleUrls: ['./create-event-form.component.scss'],
})
export class CreateEventFormComponent extends BaseComponent implements OnInit, DirtyComponent, OnDestroy {
  /** Флаг инициализации */
  public isLoading = false;

  /** Подписка на проверку изменений в форме */
  public isDirty$: Observable<boolean> = of(false);

  /** Контейнер */
  @ViewChild('editFormContainer', { read: ViewContainerRef, static: false })
  public editFormContainer: ViewContainerRef;
  public readonly emergencyService: EmergencyService;
  public readonly settings2: Settings2Service;
  public readonly componentFactoryResolver: ComponentFactoryResolver;
  public readonly router: Router;

  /** @ignore */
  constructor(public readonly route: ActivatedRoute) {
    super();
    const injector = AppInjector.getInjector();
    this.emergencyService = injector.get(EmergencyService);
    this.settings2 = injector.get(Settings2Service);
    this.componentFactoryResolver = injector.get(ComponentFactoryResolver);
    this.router = injector.get(Router);
  }

  /** @ignore */
  public ngOnInit(): void {
    this.isDirty$ = of(false);
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          if (params.id) {
            return this.emergencyService.getEventById(params.id);
          }
          return of(undefined);
        }),
        catchError((err: Error) => {
          return this.catchErrorFn<IEmergencyDto>(err, 'Ошибка при загрузке инцидента');
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((emergencyEvent: IEmergencyEventDto) => {
        if (emergencyEvent) {
          this.isLoading = true;
          this.editFormContainer.clear();
          this.createViewEventForm(emergencyEvent);
        } else {
          this.closeActiveForm();
        }
      });

    this.emergencyService.closeEventForm$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.closeActiveForm();
    });
  }

  /**
   * Закрытие формы инцидента
   */
  protected closeActiveForm() {
    this.router.navigate([{ outlets: { editForm: null, editEventForm: null } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }

  /** Определяем компонент и создаём его */
  protected createViewEventForm(event: IEmergencyEventDto) {
    const sourceName = this.settings2
      .getDictionaryByTypeSysName('sourceTypes')
      .find((item: IDictionaryInfo) => item.id === event.sourceId)?.sysname;

    switch (sourceName) {
      case 'va':
      case 'vaMonitoring': {
        const component = this.editFormContainer.createComponent<VideoEventShowFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(VideoEventShowFormComponent),
        ).instance;

        if (component) {
          component.model = event;
        } else {
          this.noteService.pushError('Формы для просмотра этого типа события не существует');
        }
        return;
      }
    }

    return undefined;
  }

  /* @ignore */
  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.editFormContainer.clear();
  }
}
