import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@bg-front/core/components';
import { ISelectItem } from '@bg-front/core/models/interfaces';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ForecastingFiresService } from '../../services';

@Component({
  selector: 'bg-forecasting-technological-fire-areas-task',
  templateUrl: './forecasting-technological-fire-areas-task.component.html',
  styleUrls: ['./forecasting-technological-fire-areas-task.component.scss'],
})
export class ForecastingTechnologicalFireAreasTaskComponent extends BaseComponent implements OnInit {
  /** Ссылка на список зон */
  public zonesArray: FormArray;
  @Input()
  public emergencyId: string;
  @Input()
  public coordinates: string;
  /** Настройка компоненты "Объект пожара" */
  public fireObjects$: Observable<ISelectItem<string>[]> = this.forecastingService.getFireObjects();
  /** Ссылка на форму */
  public form: FormGroup;
  private _isAutoCalculateDistance: boolean = false;

  /** @ignore */
  constructor(
    public readonly controlContainer: ControlContainer,
    private readonly fb: FormBuilder,
    private readonly forecastingService: ForecastingFiresService,
  ) {
    super();
  }

  public get isAutoCalculateDistance(): boolean {
    return this._isAutoCalculateDistance;
  }

  /** Автоматический рассчёт расстояния */
  public set isAutoCalculateDistance(val: boolean) {
    this._isAutoCalculateDistance = val;
    if (val) {
      this.form.controls.fireCoveredDistance.disable();
      this.form.controls.fireCoveredDistance.setValue(null);
      this.form.controls.fireCoveredDistance.clearValidators();
      this.form.controls.linearVelocity.setValidators(Validators.required);
      this.form.controls.propagationTime.setValidators(Validators.required);
      this.form.controls.firstSalvoTime.setValidators(Validators.required);
    } else {
      this.form.controls.fireCoveredDistance.enable();
      this.form.controls.fireCoveredDistance.setValidators(Validators.required);
      this.form.controls.linearVelocity.setValue(null);
      this.form.controls.propagationTime.setValue(null);
      this.form.controls.firstSalvoTime.setValue(null);
      this.form.controls.firstSalvoTime.clearValidators();
      this.form.controls.linearVelocity.clearValidators();
      this.form.controls.propagationTime.clearValidators();
    }
  }

  public ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
    this.zonesArray = this.form.controls.fireAreaZones as FormArray;

    this.form.controls.autoCalculateDistance.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((val: boolean) => {
        this.isAutoCalculateDistance = val;
      });

    this.isAutoCalculateDistance = this.form.controls.autoCalculateDistance.value;
  }

  /** Добавляем новую сущность */
  public addZone() {
    const idx = (this.form.controls.fireAreaZones as FormArray).controls.length;
    const group = this.fb.group({
      id: [idx + 1],
      coordinates: [],
      technologicalFireFormId: [],
      angleValue: [],
      fireFightingEquipmentId: [],
      smallerSide: [],
      windDirection: [],
      fireExtinguishingWayId: [],
      directionsCount: [],
    });

    this.zonesArray.setControl(idx, group);
  }

  /** Удаляем сущность */
  public removeZone(idx: number) {
    this.zonesArray.removeAt(idx);
  }
}
