import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, pluck, takeUntil } from 'rxjs/operators';

import { IAnyObject } from 'smart-city-types';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { RestService, Settings2Service } from '@smart-city/core/services';
import { INwHeaderBarOptions, IScSelectLoadParams, IScSelectOptions } from '@smart-city/core/common';

import { IMapLayerEntityFilter } from '@bg-front/core/models/interfaces';
import { FilterOperationEnum } from '@bg-front/core/models/enums';
import { BaseComponent } from '@bg-front/core/components';

@Component({
  selector: 'map-eco-objects-filter',
  templateUrl: './map-eco-objects-filter.component.html',
  styleUrls: ['./map-eco-objects-filter.component.scss'],
})
export class MapEcoObjectsFilterComponent extends BaseComponent implements OnInit, OnChanges {
  /** Заголовок фильтра */
  @Input() public title: string;

  /** Значения фильтров */
  @Input() public mapLayerFilters: IMapLayerEntityFilter[] = [];

  /** Оповещение о изменении значений фильтров */
  @Output() public changedFilters: EventEmitter<IMapLayerEntityFilter[] | 'delete'> = new EventEmitter();

  /** Форма */
  public form: FormGroup;

  /** Настройки заголовка */
  public headerOptions: INwHeaderBarOptions;

  /** Настройка компонента Наименование */
  public nameOptions: IScSelectOptions = {
    title: 'Наименование',
    clearable: true,
    service: 'EcoMonitoringIntegration',
    entity: 'Aggregated',
    modern: true,
    fieldName: 'measurementPointName',
    isMultiple: true,
  };

  /** Настройка компонента Измеритель */
  public measurerOptions: IScSelectOptions = {
    title: 'Измеритель',
    service: 'EcoMonitoringIntegration',
    entity: 'Measurers',
    modern: true,
    fieldName: 'name',
    clearable: true,
  };

  /** Настройка компонента Превышение порогового значения AQI */
  public thresholdOptions: IScSelectOptions = {
    title: 'Превышение порогового значения AQI',
    clearable: true,
    data: [
      { id: 'yes', text: 'Да' },
      { id: 'no', text: 'Нет' },
    ],
  };

  /** @ignore */
  constructor(
    private readonly rest: RestService,
    private readonly settings: Settings2Service,
  ) {
    super();
  }

  /** @ignore */
  public ngOnInit(): void {
    this.headerOptions = {
      margin: 'collapse',
      title: this.title,
      buttons: [
        {
          type: 'button',
          options: {
            name: 'delete',
            icon: 'delete_outline',
          },
        },
      ],
    };

    const aqi = this.mapLayerFilters?.find((item: IMapLayerEntityFilter) => item.property === 'indexAqi')?.operation;
    this.form = new FormGroup({
      name: new FormControl(this.mapLayerFilters?.find((item: IMapLayerEntityFilter) => item.property === 'id')?.value),
      measurer: new FormControl(
        this.mapLayerFilters?.find((item: IMapLayerEntityFilter) => item.property === 'measurerId')?.value,
      ),
      threshold: new FormControl(
        aqi === FilterOperationEnum.greatThen
          ? 'yes'
          : aqi === FilterOperationEnum.lessThenOrEqual ? 'no' : undefined,
      ),
    });

    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => this.changedFilters.emit(this.getFilters(value)));
  }

  /** @ignore */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue.length === 0) {
      this.form?.reset({}, { emitEvent: false });
    }
  }

  /**
   * Формирование фильтров на основании значения формы
   * @param value значение формы
   */
  getFilters(value: IAnyObject): IMapLayerEntityFilter[] {
    const result: IMapLayerEntityFilter[] = [];
    if (value.name) {
      result.push({
        property: 'id',
        value: value.name,
        operation: FilterOperationEnum.in,
      });
    }
    if (value.measurer) {
      result.push({
        property: 'measurerId',
        value: value.measurer,
        operation: FilterOperationEnum.in,
      });
    }
    if (value.threshold) {
      const threshold = +(this.settings.getSettings()?.thresholdAQI || 100);
      result.push({
        property: 'indexAqi',
        value: threshold,
        operation: value.threshold === 'yes' ? FilterOperationEnum.greatThen : FilterOperationEnum.lessThenOrEqual,
      });
    }
    return result;
  }

  public delete() {
    this.changedFilters.emit('delete');
    this.form.reset();
    this.form.enable();
  }
}
