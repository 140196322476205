import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Coordinates, FormModal } from '@bg-front/core/models/classes';
import { BgDatetimeValidator, coordinatesValidator } from '@bg-front/core/validators';
import { IForestryFacilityDto } from '@bg-front/forestry-facilities/models/interfaces';
import { ForestryFacilitiesService } from '@bg-front/forestry-facilities/services';
import { dirtyCheck } from '@ngneat/dirty-check-forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { MapBaseCoordinatesType } from '@smart-city/maps/sc';
import * as dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';
import { catchError, debounceTime, mergeMap, takeUntil } from 'rxjs/operators';
import {
  IAnyObject,
  IEmergencyEventDto,
  IEmergencyResolutionDto,
  IEmergencyTotalCars,
  ILifeCycleStepDto,
} from 'smart-city-types';
import { IAppealDetailDto, IDictionaryModelDto, ILifeCycleStepActionDto } from 'smart-city-types/interfaces';
import { IAppealCommentDto } from 'smart-city-types/interfaces/appeal-comment-dto.interface';

import { IMiniMapMarkerPositionEvent, IThermopoint } from '../../models/interfaces';
import { BaseIncidentFormComponent } from '../base-incident-form/base-incident-form.component';
import { BgLoosesComponent } from '../bg-looses/bg-looses.component';
import { ResponseOrgFormComponent } from '../response-org-form/response-org-form.component';
import { AccessService, RestService, Settings2Service } from '@smart-city/core/services';
import {
  AtmIntegrationService,
  BgAdminService,
  EmergencyService,
  MiniMapService,
  OrganizationsService,
  ResponsePlanStepService,
  ThermopointsService,
} from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { DialogService, IScCheckboxOptions } from '@smart-city/core/common';
import { KsipCategoriesQuery } from '@bg-front/ksip-categories/services';
import { KsipDetailsQuery } from '@bg-front/ksip-details/services';
import { KsipSectionsQuery } from '@bg-front/ksip-sections/services';
import { KsipTypesQuery } from '@bg-front/ksip-types/services';
import { MultiFileService } from '@bg-front/core/services';
import { ReportingService } from '../../../bg/modules/reporting/services';
import { SignificantObjectsService } from '@bg-front/significant-objects/services';

@Component({
  selector: 'bg-incident-cuks-edit-form',
  templateUrl: './incident-cuks-edit-form.component.html',
  styleUrls: ['./incident-cuks-edit-form.component.scss'],
})
/**
 * Компонента реализует возможность просмотра и редактирования инцидента
 */
export class IncidentCuksEditFormComponent
  extends BaseIncidentFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  //#region Настройки компонент

  /**
   * Центр мини карты
   */
  public miniMapCenter: MapBaseCoordinatesType;
  /**
   * Зум для мини карты
   */
  public miniMapZoom: number;
  /**
   * Координаты по умолчанию для мини карты
   */
  public defaultMarkerCoordinate: [number, number];
  /**
   * Объект содержащий информацию о привлечённых машинах
   */
  public totalCars: IEmergencyTotalCars = undefined;
  // TODO заменить на метод поиска полигона из платформы, когда тот будет готов
  /** Лесохозяйственный объект, в полигоне которого расположен инцидент */
  public forestryFacility: IForestryFacilityDto = { name: '' };
  /**
   * Свойство рассчитывающее общее количество задействованных машин
   */
  public summaryCars: number;
  /** Флаг видимости блока "Информация для интернет портала" */
  public showInternetPortalInfo: boolean = false;
  /** Сохраняем состояние комментариев, что бы не задваивать при ошибках */
  private currentIPComments: IAppealCommentDto[];
  /** Передать в АИУС РСЧС */
  public needToSendEmergencyAtlasOptions: IScCheckboxOptions = {
    title: 'Передать в АИУС РСЧС',
  };
  /** Термоточка */
  public thermoPoint: IThermopoint;
  /** Ссылка на открытие термоточки в новой вкладке */
  public thermoPointInnerLink: string;

  //#endregion
  /**
   * @ignore
   */
  constructor(
    private readonly forestryFacilitiesService: ForestryFacilitiesService,
    private readonly thermoPointsService: ThermopointsService,
    accessService: AccessService,
    atmIntegrationService: AtmIntegrationService,
    bgAdminService: BgAdminService,
    dialog: MatDialog,
    dialogService: DialogService,
    emergencyService: EmergencyService,
    fb: FormBuilder,
    ksipCategoriesQuery: KsipCategoriesQuery,
    ksipDetailsQuery: KsipDetailsQuery,
    ksipSectionsQuery: KsipSectionsQuery,
    ksipTypesQuery: KsipTypesQuery,
    miniMapService: MiniMapService,
    multiFileService: MultiFileService,
    organizationsService: OrganizationsService,
    reportingService: ReportingService,
    route: ActivatedRoute,
    router: Router,
    settings: Settings2Service,
    significantObjectService: SignificantObjectsService,
    responsePlanStepService: ResponsePlanStepService,
  ) {
    super(
      accessService,
      atmIntegrationService,
      bgAdminService,
      dialog,
      dialogService,
      emergencyService,
      fb,
      ksipCategoriesQuery,
      ksipDetailsQuery,
      ksipSectionsQuery,
      ksipTypesQuery,
      miniMapService,
      multiFileService,
      organizationsService,
      reportingService,
      route,
      router,
      settings,
      significantObjectService,
      responsePlanStepService,
    );
  }

  /**
   * @ignore
   */
  ngOnInit() {
    this.showInternetPortalInfo = this.accessService.accessMap['showInternetPortalInfo']?.visible;
    /** Проверка состояния флагов в зависимости от типа события для отображения полей плановых сроков работ
     * 001 - авария
     * 003 - плановое **/
    const ksip = this.ksipTypesQuery.getById(this.model.incidentTypeId);
    this.isAccidentForm = this.settings.getDictionaryById(ksip.eventTypeId)?.sysname === '001';
    this.isPlanWorkForm = this.settings.getDictionaryById(ksip.eventTypeId)?.sysname === '003';
    if (ksip.ksipDetails) {
      this.isAccidentFormDetails = this.settings.getDictionaryById(ksip.ksipDetails[0])?.sysname === '001';
    }

    this.miniMapCenter = this.getMunicipalCoordinates();
    this.miniMapZoom = Number.parseInt(this.getMunicipal().zoom, 10);

    this.incidentForm = new FormGroup({
      byCoordinates: new FormControl(this.model.byCoordinates),
      addressFact: new FormControl(this.model.addressFact, [Validators.required]),
      coordinatesAddress: new FormControl(
        this.model.byCoordinates ? this.model.addressFact.fullText || this.model.address.fullText : null,
      ),
      moId: new FormControl(this.model.moId),
      districtId: new FormControl({ value: this.model.districtId, disabled: true }),
      detailsFact: new FormControl(this.model.detailsFact),
      incidentTypeId: new FormControl(this.model.incidentTypeId, [Validators.required]),
      comment: new FormControl(this.model.comment),
      description: new FormControl(this.model.description),
      organization: new FormControl(this.model.organization),
      responsibleUser: new FormControl(this.model.responsible || this.settings.currentUser.id, [Validators.required]),
      urgently: new FormControl(this.model.urgently),
      danger: new FormControl(this.model.resolution?.danger),
      dangerOrg: new FormControl(this.model.resolution?.dangerOrg),
      supervisedByCuks: new FormControl(this.model.supervisedByCuks),
      coordinates: new FormControl(this.model.coordinates, [coordinatesValidator()]),
      ksipPlaceDescription: new FormControl(this.model.ksipPlaceDescription),
      otherCars: new FormControl(this.model.resolution?.otherCars || 0),
      additionalInfoAboutCars: new FormControl(this.model.resolution?.additionalInfoAboutCars),
      report: new FormControl(this.model.resolution?.report),
      /** ----- LOOSES ----- **/
      resolution: BgLoosesComponent.generateFormGroup(this.fb, {
        ...this.model.resolution,
        peopleInTheZoneOnDate: this.model.resolution?.peopleInTheZoneOnDate || this.model.timeCreate,
        deathOnDate: this.model.resolution?.deathOnDate || this.model.timeCreate,
        rescuedOnDate: this.model.resolution?.rescuedOnDate || this.model.timeCreate,
        victimOnDate: this.model.resolution?.victimOnDate || this.model.timeCreate,
        hospitalizedOnDate: this.model.resolution?.hospitalizedOnDate || this.model.timeCreate,
        evacuatedOnDate: this.model.resolution?.evacuatedOnDate || this.model.timeCreate,
        missingOnDate: this.model.resolution?.missingOnDate || this.model.timeCreate,
      }),
      incidentFinishTime: new FormControl(this.model.resolution?.incidentFinishTime),
      mapMarkerCoordinate: new FormControl(this.model.mapMarkerCoordinate),
      regime: new FormControl(this.model.regimeId),
      forecasting: new FormControl(this.model?.forecastingDetailId),
      documents: new FormControl(this.model.documents),
      technologicalFireAdditionalInfo: new FormGroup({
        totalCars: new FormControl(this.model.resolution?.totalCars, [Validators.required]),
        trunks: new FormControl((this.model.resolution || { trunks: null })['trunks'], [Validators.required]),
        personal: new FormControl((this.model.resolution || { personal: null })['personal'], [Validators.required]),
        actual: new FormControl((this.model.resolution || { actual: null })['actual']),
        timeDetection: new FormControl((this.model.resolution || { timeDetection: null })['timeDetection']),
        timeDeparture: new FormControl((this.model.resolution || { timeDeparture: null })['timeDeparture']),
        timeArrival: new FormControl((this.model.resolution || { timeArrival: null })['timeArrival']),
        timeFirstSalvo: new FormControl((this.model.resolution || { timeFirstSalvo: null })['timeFirstSalvo']),
        timeLocalization: new FormControl((this.model.resolution || { timeLocalization: null })['timeLocalization']),
        timeLiquidation: new FormControl((this.model.resolution || { timeLiquidation: null })['timeLiquidation']),
        timeRecovery: new FormControl((this.model.resolution || { timeRecovery: null })['timeRecovery']),
      }),
      totalRegistryPeopleHouse: new FormControl(this.model.totalRegistryPeopleHouse),
      totalTemporalPeopleHouse: new FormControl(this.model.totalTemporalPeopleHouse),
      individualOwnership: new FormControl(this.model.individualOwnership),
      jkhObject: new FormControl(this.model.jkhObject),
      favorableRestrictionObjects: new FormControl(this.model.favorableRestrictionObjects),
      affectedRestrictionObjects: new FormControl(this.model.affectedRestrictionObjects),
      resourceConstraintType: new FormControl(this.model.resourceConstraintType),
      relatedRestrictions: new FormControl(this.model.relatedRestrictions),
      jkhDirection: new FormControl(this.model.jkhDirection),
      consumerPowerCategoryId: new FormControl(this.model.consumerPowerCategoryId, [Validators.required]),
      isSendingToJkhReform: new FormControl(this.model.isSendingToJkhReform),
      needToSendEmergencyAtlas: new FormControl(this.model?.needToSendEmergencyAtlas),
      workTermFrom: new FormControl(this.model?.workTermFrom),
      workTermTo: new FormControl(this.model?.workTermTo),
      showOnPortal: new FormControl(this.model.showOnPortal),
      internetPortalComment: new FormControl(undefined),
      internetPortalDesc: new FormControl(this.model?.internetPortalDesc || this.model?.description?.substring(0, 300)),
    });

    this.eventsGridQuery = { incidentId: this.model.id };

    this.thermoPointsService.getThermoPointByEventId((<IEmergencyEventDto>this.model.parentEventId).id)
      .pipe(untilDestroyed(this))
      .subscribe((thermoPoint: IThermopoint) => {
        this.thermoPoint = thermoPoint;
        this.thermoPointInnerLink = `${ window.origin }/cuks/workspace/(thermopoint/${ thermoPoint?.id }//leftSidebar:all)`;
      });

    this.vehiclesGridQuery = { id: { $in: this.model.involvedVehicles || [] } };

    super.ngOnInit();

    if (this.isPlanWorkForm || this.isAccidentForm) {
      let minDate;
      const dateDefault = new Date(Date.now()).setHours(0, 0, 0, 0);
      if (this.model.timeCreate < dateDefault) {
        minDate = new Date(this.model?.timeCreate).setHours(0, 0, 0, 0);
      } else {
        minDate = dayjs().startOf('day').valueOf();
      }
      const workTermToInitValidators = [
        Validators.required,
        BgDatetimeValidator.minDate(minDate),
        BgDatetimeValidator.minDateOf(this.incidentForm.controls.workTermFrom.value),
      ];
      const validators = this.isPlanWorkForm ? [Validators.required, BgDatetimeValidator.minDate(minDate)] : [];
      this.incidentForm.controls.workTermFrom.setValidators(validators);
      this.incidentForm.controls.workTermFrom.setErrors(null);
      this.incidentForm.controls.workTermTo.setValidators(workTermToInitValidators);
      this.incidentForm.controls.workTermFrom.valueChanges.pipe(untilDestroyed(this)).subscribe((value: number) => {
        if (value) {
          this.incidentForm.controls.workTermTo.setValidators([
            Validators.required,
            BgDatetimeValidator.minDate(minDate),
            BgDatetimeValidator.minDateOf(value),
          ]);
        } else {
          this.incidentForm.controls.workTermTo.setValidators(validators);
        }
        this.incidentForm.controls.workTermTo.reset(this.incidentForm.controls.workTermTo.value, {
          emitEvent: true,
          onlySelf: false,
        });
      });
      this.incidentForm.controls.workTermTo.setErrors(null);
    }

    /** Добавляем работу с комментарием для интернет портала */
    if (this.needShowInternetPortalComment) {
      this.currentIPComments = [
        ...(((this.model.parentEventId as IEmergencyEventDto)?.appealDetail as IAppealDetailDto)?.comments ?? []),
      ];
    }

    if (this.disabled) {
      this.incidentForm.disable();
    }

    // Чекбокс "На контроле ЦУКС" должен быть заблокирован всегда
    this.incidentForm.controls.supervisedByCuks.disable();

    if (this.model.id) {
      this.emergencyService
        .getEmergencyTotalCars(this.model.id)
        .pipe(
          catchError((error: Error) =>
            this.catchErrorFn<IEmergencyTotalCars>(error, 'Ошибка запроса количества задействованных машин'),
          ),
          untilDestroyed(this),
        )
        .subscribe((total: IEmergencyTotalCars) => {
          this.totalCars = total;
          this.summaryCars = this.model.resolution?.otherCars || 0;
          this.summaryCars += this.totalCars.totalFireCars;
          this.summaryCars += this.totalCars.totalAmbulanceCars;
          this.summaryCars += this.totalCars.totalPoliceCars;
        });
    }

    this.setForecastingParamsOptions();

    this.incidentForm.controls.incidentTypeId.valueChanges.pipe(untilDestroyed(this)).subscribe((value: string) => {
      this.getForecastingParamsInfo(value);
    });

    /**
     * Подписка на изменение формы, с цель сброса ошибок валидации
     */
    this.incidentForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.errors = [];
    });

    this.incidentForm.controls['coordinates'].valueChanges
      .pipe(debounceTime(1500), takeUntil(this.ngUnsubscribe))
      .subscribe((val: string) => {
        this.redrawEventMarker(val);
      });

    this.showLinkToJkhObjectMonitoring(Boolean(this.model.jkhObject));
    this.setMonitoringObjectId(this.model.jkhObject);

    this.incidentForm.controls['jkhObject'].valueChanges
      .pipe(debounceTime(1500), takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.showLinkToJkhObjectMonitoring(Boolean(value));
        this.setMonitoringObjectId(value);
        this.setAddressObservableForLink();
      });

    // поиск лесохозяйственных объектов, в полигоне которых находится инцидент
    // TODO заменить на метод поиска полигона из платформы, когда тот будет готов
    this.forestryFacilitiesService
      .getForestryFacilityForEmergency(this.model.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: IForestryFacilityDto) => {
        this.forestryFacility = res;
      });

    // TODO заменить на метод поиска полигона из платформы, когда тот будет готов
    this.incidentForm.controls.coordinates.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        mergeMap((value: string) => {
          return this.forestryFacilitiesService.getForestryFacilityForEmergency(this.model.id);
        }),
      )
      .subscribe((res: IForestryFacilityDto) => {
        this.forestryFacility = res;
      });

    const markerCoordinates = new Coordinates(this.model.mapMarkerCoordinate || this.model.coordinates);
    if (markerCoordinates.isValid()) {
      this.defaultMarkerCoordinate = markerCoordinates.toArray();
    }

    this.optionsResponsibleUser.query = { isActive: true, organizationId: this.model.organization };

    this.initReactPlan();
    this.updateForm();

    const masterFormValue = this.incidentForm.getRawValue();

    this.ngMasterValue = new BehaviorSubject<IAnyObject>(masterFormValue);
    this.isDirty$ = dirtyCheck(this.incidentForm, this.ngMasterValue.asObservable());
  }

  /**
   * @ignore
   */
  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  /**
   * Установка маркера
   * @param miniMapEvent событие
   */
  public updateCoordinates(miniMapEvent: IMiniMapMarkerPositionEvent) {
    const markerCoordinates = new Coordinates(this.incidentForm.controls['mapMarkerCoordinate'].value);
    const newMarkerCoordinates = new Coordinates(miniMapEvent?.coordinates[0], miniMapEvent?.coordinates[1]);
    if (!markerCoordinates.equal(newMarkerCoordinates)) {
      this.incidentForm.controls['mapMarkerCoordinate'].setValue(newMarkerCoordinates.toString());
      this.redrawEventMarker(this.incidentForm.controls['mapMarkerCoordinate'].value);
      this.model.mapMarkerCoordinate = this.incidentForm.controls['mapMarkerCoordinate'].value;
    }
  }

  /**
   * Получение данных из формы и сохранение в модель
   * @param action - действие которое выполняется на форме
   * TODO: Перенести часть кода в базовую функцию
   */
  public getModelData(action: string) {
    if (!this.model.address) {
      this.model.address = this.model.byCoordinates
        ? this.addressByCoordinates
        : this.incidentForm.controls['addressFact'].value;
    }
    this.model.byCoordinates = this.incidentForm.controls['byCoordinates'].value;
    this.model.addressFact = this.model.byCoordinates
      ? this.addressByCoordinates
      : this.incidentForm.controls['addressFact'].value;
    if (!this.model.id) {
      this.model.timeCreate = dayjs().unix() * 1000;
      this.model.number = `i-${dayjs(this.model.timeCreate).format('YYYYMMDD-HHmmss')}`;
      this.model.organization = this.settings.currentUser.organizationId.id;
      this.model.createdBy = this.settings.currentUser.id;
    }
    this.model.moId = <string>this.moIdComponent.value;
    this.model.districtId = <string>this.districtIdComponent.value;
    this.model.detailsFact = this.incidentForm.controls['detailsFact'].value;
    this.model.incidentTypeId = this.incidentForm.controls['incidentTypeId'].value;
    this.model.comment = this.incidentForm.controls['comment'].value;
    this.model.description = this.incidentForm.controls['description'].value;
    this.model.responsible = this.incidentForm.controls['responsibleUser'].value;
    this.model.resolution = this.model.resolution || <IEmergencyResolutionDto>{};
    this.model.urgently = this.incidentForm.controls['urgently'].value;
    this.model.resolution.danger = this.incidentForm.controls['danger'].value;
    this.model.resolution.dangerOrg = this.incidentForm.controls['dangerOrg'].value;
    this.model.supervisedByCuks = this.incidentForm.controls['supervisedByCuks'].value;
    this.model.coordinates = new Coordinates(this.incidentForm.controls['coordinates'].value).toString();
    this.model.ksipPlaceDescription = this.incidentForm.controls['ksipPlaceDescription'].value;
    this.model.resolution.otherCars = this.incidentForm.controls['otherCars'].value;
    this.model.resolution.additionalInfoAboutCars = this.incidentForm.controls['additionalInfoAboutCars'].value;
    this.model.resolution.incidentFinishTime = this.incidentForm.controls['incidentFinishTime'].value;
    this.model.resolution.report = this.incidentForm.controls['report'].value;
    /** ----- LOOSES ----- **/
    this.model.resolution = {
      ...this.model.resolution,
      ...(this.incidentForm.controls['resolution'].value || <IEmergencyResolutionDto>{}),
    };
    this.model.documents = this.incidentForm.controls['documents'].value;

    this.model.jkhObject = this.incidentForm.controls['jkhObject'].value;
    this.model.favorableRestrictionObjects = this.incidentForm.controls['favorableRestrictionObjects'].value;
    this.model.affectedRestrictionObjects = this.incidentForm.controls['affectedRestrictionObjects'].value;
    this.model.resourceConstraintType = this.incidentForm.controls['resourceConstraintType'].value;
    this.model.relatedRestrictions = this.incidentForm.controls['relatedRestrictions'].value;
    this.model.jkhDirection = this.incidentForm.controls['jkhDirection'].value;
    this.model.consumerPowerCategoryId = this.incidentForm.controls['consumerPowerCategoryId'].value
      ? this.incidentForm.controls['consumerPowerCategoryId'].value
      : undefined;
    this.model.isSendingToJkhReform = this.incidentForm.controls['isSendingToJkhReform'].value;
    this.model.needToSendEmergencyAtlas = this.incidentForm.controls['needToSendEmergencyAtlas'].value;

    if (this.showForecastingParams) {
      this.model.forecastingDetailId = {
        id: this.model.forecastingDetailId?.id,
        params: (this.incidentForm.controls.forecasting as FormGroup).getRawValue(),
      };
    } else {
      this.model.forecastingDetailId = null;
    }

    this.model.totalRegistryPeopleHouse = this.incidentForm.controls['totalRegistryPeopleHouse'].value;
    this.model.totalTemporalPeopleHouse = this.incidentForm.controls['totalTemporalPeopleHouse'].value;
    this.model.individualOwnership = this.incidentForm.controls['individualOwnership'].value;

    this.model.resolution = Object.assign(
      this.model.resolution,
      this.incidentForm.controls['technologicalFireAdditionalInfo'].value,
    );
    this.model.showOnPortal = this.incidentForm.controls['showOnPortal'].value;
    if (this.model.showOnPortal) {
      this.model.internetPortalDesc = this.incidentForm.controls['internetPortalDesc'].value;
    }

    if (this.needShowInternetPortalComment && this.incidentForm.controls['internetPortalComment'].value) {
      // Текущий статус
      const currentStatus = (<IDictionaryModelDto>(<ILifeCycleStepDto>this.model.lifeCycleStepId).status)?.id
        ? (<IDictionaryModelDto>(<ILifeCycleStepDto>this.model.lifeCycleStepId).status).id
        : <string>(<ILifeCycleStepDto>this.model.lifeCycleStepId).status;
      // Получение информации о шаге ЖЦ в который переходит происшествие
      const nextStep = (
        this.settings.lifeCycleStep[(<ILifeCycleStepDto>this.model.lifeCycleStepId).id].actions || []
      ).find((i: ILifeCycleStepActionDto) => i.name === action)?.nextStep;
      const nextLifeCycleStep = nextStep
        ? this.settings.lifeCycleStep[nextStep]
        : <ILifeCycleStepDto>this.model.lifeCycleStepId;
      // Если отсутствует следующий шаг, то происшествие остается в текущем шаге
      const newStatus = !!(<string>nextLifeCycleStep?.status)
        ? this.settings.getDictionaryById(<string>nextLifeCycleStep.status)
        : this.settings.getDictionaryById(currentStatus);
      // Инициализация текущего списка комментариев для интернет портала
      ((this.model.parentEventId as IEmergencyEventDto).appealDetail as IAppealDetailDto).comments =
        this.currentIPComments;
      // Добавление нового комментария для интернет портала
      ((this.model.parentEventId as IEmergencyEventDto).appealDetail as IAppealDetailDto).comments.push({
        lifeCycleStepId: {
          id: nextLifeCycleStep.id,
          name: nextLifeCycleStep.name,
          status: <IDictionaryModelDto>{
            id: nextLifeCycleStep.status,
            name: newStatus.name,
            sysname: newStatus.sysname,
          },
        },
        text: this.incidentForm.controls['internetPortalComment'].value,
        createTime: Date.now(),
        userId: this.settings.currentUser.id,
      });
    }
  }

  /**
   * Обновление формы
   */
  public updateForm() {
    this.attributes = (this.model.lifeCycleStepId as ILifeCycleStepDto).params;
    // Обновляем заголовок
    this.generateHeaderBar();
  }

  /** TODO временное решение, пожелание аналитика (пока что не отправляем донесения из цукса)
   *  Метод для создания шага сценария для реагирования
   *  @param $event
   *  @return
   */
  public createNewStepScriptReact($event): void {
    const dialogOptions = {
      width: '906px',
      panelClass: 'org-panel',
    };
    const dataOptions = {
      title: 'Новый шаг реагирования',
      emergencyModel: this.model,
      entity: 'involvedOrgOpts',
    };
    const dialog = new FormModal(ResponseOrgFormComponent, dialogOptions, dataOptions);
    dialog.open();
  }

  /**
   * @ignore
   */
  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    localStorage.removeItem('isAddressBlockShow');
  }

  /** Показывает ссылку на объект монитроинга */
  private showLinkToJkhObjectMonitoring(value: boolean): void {
    this.linkToJkhObjectVisible = this.isJhk && value;
  }
}
