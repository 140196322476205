<h3 class="main-header">
  <mat-icon class="header-icon">track_changes</mat-icon>
  Результаты прогноза
</h3>
<hr/>
<div class="bg-col">
  <h4 class="section-header">Допустимое время преодоления следа облака населением по заданному маршруту</h4>
  <h4 class="section-header">Для дозы {{params?.acceptableDose}} {{unitName}}</h4>
  <h5>Время начала преодоления следа рад облака рассчитывается по рисунку 3 из ГОСТ Р.22.2.11-2018 с помощью
    значений: </h5>
  <ul class="results-list">
    <li class="results-list-item">
      <label>Время движения Т (ч):</label>
      <label class="results-list-item--var" *ngIf="result?.movementTime">
        {{result?.movementTime | commaToDot | number: '0.2-2'}} ч
      </label>
    </li>
    <li class="results-list-item">
      <label>Коэффициент η:</label>
      <label class="results-list-item--var" *ngIf="result?.etaCoefficient">
        {{result?.etaCoefficient | commaToDot | number: '0.2-2'}} ч
      </label>
    </li>
  </ul>
</div>
<hr/>
