<div *ngIf="polygon">
  <nw-header [options]="headerOptions" (clickButton)="clickHeaderButton($event)"></nw-header>
  <div class="polygon-container">
    <div class="content">
      <div class="bg-row polygon-header">
        <div class="bg-col-min">
          <div class="icon">
            <span class="sc-icon-polygon"></span>
          </div>
        </div>
        <div class="bg-col polygon-name">
          <div>
            {{ polygon.name }}
          </div>
        </div>
        <div class="bg-col-min" (click)="clickDeletePolygon($event)">
          <div class="icon">
            <mat-icon
              _ngcontent-boo-c368=""
              role="img"
              class="mat-icon
              notranslate
              mat-tooltip-trigger
              material-icons
              mat-icon-no-color
              ng-star-inserted"
              aria-hidden="true"
              data-mat-icon-type="font">
                delete_outline
            </mat-icon>
          </div>
        </div>
      </div>
      <hr/>
      <div class="bg-row">
        <div class="bg-col">
          <div class="main-info-container">
            <div class="bg-row">
              <div class="bg-col">

                <div class="show-on-map" (click)="showOnMap($event)">
                  <mat-icon
                    _ngcontent-lgs-c418=""
                    role="img" matsuffix=""
                    class="mat-icon
                    notranslate
                    material-icons
                    mat-icon-no-color
                    ng-tns-c150-49"
                    aria-hidden="true"
                    data-mat-icon-type="font">
                    location_on
                  </mat-icon>
                   Показать на карте
                </div>
                <div>
                  <label>Дата и время создания:</label>
                  <p>{{ creationTime }}</p>
                </div>
              </div>
            </div>
            <hr/>
            <div class="bg-row">
              <div class="bg-col">
                <div>
                  <label>Пользователь, создавший полигон:</label>
                  <p>{{ creationAuthor }}</p>
                </div>
              </div>
            </div>
            <hr/>
            <div class="bg-row">
              <div class="bg-col">
                <div>
                  <label>Описание:</label>
                  <p>{{ polygon.description }}</p>
                </div>
              </div>
            </div>
            <hr/>
            <div class="significant-object-main-info-container" style="padding-right: 5px">
              <div class="bg-row">
                <div class="bg-col">
                  <label>Связанные инциденты</label>
                </div>
              </div>
              <div class="bg-row">
                <div class="bg-col no-padding">
                  <div class="related-emergencies-container">
                    <ng-template ngFor let-emergency [ngForOf]="emergencies">
                      <bg-incident-mini-card [data]="emergency"></bg-incident-mini-card>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
            <hr/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
