import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  IElementButton,
  INwHeaderBarOptions,
  IScInputOptions,
  IScrollableContainerLoadDataFnParams,
  IScrollableContainerLoadDataFnResult,
  IScrollableContainerOptions,
  ScrollableContainerComponent,
} from '@smart-city/core/common';
import { catchError, debounceTime, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { IAbstractServiceData, IAnyObject } from 'smart-city-types';
import { EmergencyService } from '../../../services';
import { EventMiniCardComponent } from '../../event-mini-card/event-mini-card.component';
import { IncidentMiniCardComponent } from '../../incident-mini-card/incident-mini-card.component';
import { BaseRegistryComponent } from '@bg-front/registry-panel/components';
import { RegistryPanelService } from '@bg-front/registry-panel/services';
import { IRegistryPanelDto } from '@bg-front/registry-panel/models';
import { OperationsService } from '@bg-front/core/services';

@Component({
  selector: 'bg-planned-works-registry',
  templateUrl: './planned-works-registry.component.html',
  styleUrls: ['./planned-works-registry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OperationsService],
})
export class PlannedWorksRegistryComponent extends BaseRegistryComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Ссылка на компонент отображающий происшествия
   */
  @ViewChildren('incidentsScroll')
  public incidentsScrollList: QueryList<ScrollableContainerComponent>;

  /** Ссылка на элемент */
  public incidentsScroll: ScrollableContainerComponent;

  /**
   * Ссылка на компонент отображающий события
   */
  @ViewChildren('eventsScroll')
  public eventsScrollList: QueryList<ScrollableContainerComponent>;

  /** Ссылка на элемент */
  public eventsScroll: ScrollableContainerComponent;

  /**
   * Настройки скролла для Инцидентов
   */
  public optionsIncidents: IScrollableContainerOptions;

  /**
   * Настройки скролла для Событий
   */
  public optionsEvents: IScrollableContainerOptions;

  /**
   * Настройки заголовка
   */
  public headerOptions: INwHeaderBarOptions;

  /**
   * Настройка строки поиска инцидентов
   */
  public incidentsSearchOption: IScInputOptions;

  /**
   * Настройка строки поиска событий
   */
  public eventsSearchOption: IScInputOptions;
  /**
   * Группа для подписки на изменения
   */
  public searchFormGroup: FormGroup = new FormGroup({
    'incidents-search': new FormControl(''),
    'events-search': new FormControl(''),
  });
  /**
   * Список для хранения номера станицы подгружаемых элементов в гриде для соответсвующей вкладки
   */
  public listPageCounter = new Map([
    ['incidents', 0],
    ['events', 0],
  ]);
  /**
   * Фильтр инцидентов
   */
  private incidentsFilter: IAnyObject;
  /**
   * Фильтр событий
   */
  private eventsFilter: IAnyObject;

  /**
   * @param cdr
   * @param emergencyRegistry
   * @param panelService
   * @param route
   * @param router
   */
  constructor(
    private cdr: ChangeDetectorRef,
    private emergencyRegistry: EmergencyService,
    private panelService: RegistryPanelService,
    route: ActivatedRoute,
    router: Router,
    operationsService: OperationsService,
    ) {
      super(route, router, operationsService);
    }

  /** @ignore */
  public ngOnInit(): void {
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          return this.panelService.getRegistryPanelById(params['id']);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((registry: IRegistryPanelDto) => {
        this.registry = registry;

        super.ngOnInit();

        const incidentsFilter = this.getEntityFilters('incidents') || [];
        const eventsFilter = this.getEntityFilters('events') || [];

        /** Фильтрация по подведомственным организациям */
        const subOrganizationsIds: string[] = this.currentUser.organizationId?.subOrganizationsIds;
        const userOrganizationId: string = this.currentUser.organizationId.id;


        this.optionsIncidents = {
          loadDataFn: (params: IScrollableContainerLoadDataFnParams) => {
            const query: IAnyObject = {
              $and: incidentsFilter.slice(),
            };

            query.$and.push({
              organization: {
                $in: [...(subOrganizationsIds || []), userOrganizationId],
              },
            });

            if (!!this.incidentsFilter) {
              query.$and = [...query.$and, ...this.incidentsFilter.$and];
            }
            if (this.searchFormGroup.controls['incidents-search'].value) {
              query.$and.push({
                $text: {
                  $search: this.searchFormGroup.controls['incidents-search'].value,
                  $fields: ['number', 'addressFact.fullText', 'incidentTypeId.name'],
                },
              });
            }
            query.$and.push({
              'docType.sysname': 'plannedWork',
            });

            return this.emergencyRegistry
              .getIncidents(
                query,
                {
                  paNumber: params.page,
                  paSize: params.limit,
                },
                {
                  field: 'timeCreate',
                  direction: 'desc',
                },
              )
              .pipe(
                map((response: IAbstractServiceData) => {
                  if (response.data && response.data.items.length) {
                    return <IScrollableContainerLoadDataFnResult>{
                      currentPage: params.page,
                      data: response.data.items.map((el: any) => {
                        return {
                          data: el,
                          type: IncidentMiniCardComponent,
                        };
                      }),
                    };
                  }
                  return <IScrollableContainerLoadDataFnResult>{
                    currentPage: params.page,
                    data: [null],
                  };
                }),
                catchError((err: Error) =>
                  this.catchErrorFn<IScrollableContainerLoadDataFnResult>(err, 'Ошибка при запросе списка инцидентов'),
                ),
                takeUntil(this.ngUnsubscribe),
              );
          },
          limit: this.pageSize,
          maxPage: this.maxPage,
        };

        this.optionsEvents = {
          loadDataFn: (params: IScrollableContainerLoadDataFnParams) => {
            const query: IAnyObject = {
              $and: eventsFilter.slice(),
            };
            if (this.searchFormGroup.controls['events-search'].value) {
              query.$and.push({
                $text: {
                  $search: this.searchFormGroup.controls['events-search'].value,
                  $fields: ['closeReasonId.name', 'comment', 'number'],
                },
              });
            }
            query.$and.push({
              'organizationId.id': {
                $in: [...(subOrganizationsIds || []), userOrganizationId],
              },
            });
            if (!!this.eventsFilter) {
              query.$and = [...query.$and, ...this.eventsFilter.$and];
            }

            if (!this.isAdmin()) {
              query.$and.push({
                organizationId: this.currentUser.organizationId?.id,
              });
            }

            return this.emergencyRegistry
              .getEvents(
                query,
                {
                  paNumber: params.page,
                  paSize: params.limit,
                },
                {
                  field: 'timeCreate',
                  direction: 'desc',
                },
              )
              .pipe(
                takeUntil(this.ngUnsubscribe),
                map((response: IAbstractServiceData) => {
                  if (response.data && response.data.items.length) {
                    return <IScrollableContainerLoadDataFnResult>{
                      currentPage: params.page,
                      data: response.data.items.map((el: any) => {
                        return {
                          data: el,
                          type: EventMiniCardComponent,
                        };
                      }),
                    };
                  }

                  return <IScrollableContainerLoadDataFnResult>{
                    currentPage: params.page,
                    data: [null],
                  };
                }),
                catchError((err: Error) =>
                  this.catchErrorFn<IScrollableContainerLoadDataFnResult>(err, 'Ошибка при запросе списка событий'),
                ),
              );
          },
          limit: this.pageSize,
          maxPage: this.maxPage,
        };

        this.incidentsSearchOption = {
          name: 'incidents-search',
          formGroup: this.searchFormGroup,
          type: 'search',
          prefixIcon: 'search',
          placeholder: 'Поиск происшествий',
          color: 'white',
        };

        this.eventsSearchOption = {
          name: 'events-search',
          formGroup: this.searchFormGroup,
          type: 'search',
          prefixIcon: 'search',
          placeholder: 'Поиск событий',
          color: 'white',
        };

        this.searchFormGroup.controls['incidents-search'].valueChanges
          .pipe(debounceTime(200), takeUntil(this.ngUnsubscribe))
          .subscribe((val) => {
            if (val !== null && val !== undefined) {
              this.incidentsScroll.refresh().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
            }
          });

        this.searchFormGroup.controls['events-search'].valueChanges
          .pipe(debounceTime(200), takeUntil(this.ngUnsubscribe))
          .subscribe((val) => {
            if (val !== null && val !== undefined) {
              this.eventsScroll.refresh().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
            }
          });

        this.cdr.detectChanges();
      });
  }

  public selectedTabChange({ index }: { index: number }) {
    /** Обновляю данные при переключение между табами */
    const container = index ? this.eventsScroll : this.incidentsScroll;
    container.refresh().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
  }

  public ngAfterViewInit() {
    /** Подгружаю первую пачку данных на вкладках в момент монтирования табов */
    this.incidentsScrollList.changes
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((comps: QueryList<ScrollableContainerComponent>) => {
        if (comps.first) {
          this.incidentsScroll = comps.first;
          this.incidentsScroll.getNextData();
        } else {
          this.incidentsScroll?.ngOnDestroy();
          this.incidentsScroll = undefined;
        }

        this.cdr.markForCheck();
      });

    this.eventsScrollList.changes
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((comps: QueryList<ScrollableContainerComponent>) => {
        if (comps.first) {
          this.eventsScroll = comps.first;
          this.eventsScroll.getNextData();
        } else {
          this.eventsScroll?.ngOnDestroy();
          this.eventsScroll = undefined;
        }

        this.cdr.markForCheck();
      });

    /** Подписка на добавление происшествия в реестр */
    this.emergencyRegistry.incidentAdded$
      .pipe(
        // для избежания перегрузки при массовом добавлении происшествий
        debounceTime(1000),
        mergeMap(() => this.incidentsScroll.refresh()),
        catchError((err: Error) =>
          this.catchErrorFn<IScrollableContainerLoadDataFnResult>(
            err,
            'Ошибка при добавлении нового происшествия в реестр',
          ),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();

    /** Подписка на добавление события в реестр */
    this.emergencyRegistry.eventAdded$
      .pipe(
        // для избежания перегрузки при массовом добавлении событий
        debounceTime(1000),
        mergeMap(() => this.eventsScroll.refresh()),
        catchError((err: Error) =>
          this.catchErrorFn<IScrollableContainerLoadDataFnResult>(err, 'Ошибка при добавлении нового события в реестр'),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  /**
   * Обработка событий из заголовка
   * @param $event событие
   */
  public override clickHeaderButton($event: IElementButton): void {
    this.router.navigate([{ outlets: { leftSidebar: ['all'], editForm: null } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Подписка на изменения в фильтра
   * @param $event фильтр
   */
  public changedIncidentsFilter($event) {
    this.incidentsFilter = $event;
    this.listPageCounter.set('incidents', 0);

    if (this.incidentsScroll) {
      this.incidentsScroll.refresh().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    }
  }

  public changedEventsFilter($event) {
    this.eventsFilter = $event;
    this.listPageCounter.set('events', 0);
    if (this.eventsScroll) {
      this.eventsScroll.refresh().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    }
  }
}
