import { AfterViewInit, Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IBaseDictionaryData, IUser } from '@smart-city/core/interfaces';
import { ScConsole, Uuid } from '@smart-city/core/utils';
import * as dayjs from 'dayjs';
import { of } from 'rxjs';
import { catchError, delay, switchMap } from 'rxjs/operators';
import { MonitoringObjectAtmEditFormComponent } from '../monitoring-object-atm-edit-form/monitoring-object-atm-edit-form.component';
import { MonitoringObjectBaseEditFormComponent } from '../monitoring-object-base-edit-form/monitoring-object-base-edit-form.component';
import { MonitoringObjectCustomEditFormComponent } from '../monitoring-object-custom-edit-form/monitoring-object-custom-edit-form.component';
import { MonitoringObjectTesEditFormComponent } from '../monitoring-object-tes-edit-form/monitoring-object-tes-edit-form.component';
import { IOrganization } from '../../../models/interfaces';
import {
  IMonitoringObjectHcsDto,
  IMonitoringObjectHcsView,
} from '../../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/models/interfaces';
import { IHcsObjectKindsDto } from '../../../../bg/modules/dictionaries/modules/hcs-object-kinds/models/interfaces';
import { IHcsObjectTypesDto } from '../../../../bg/modules/dictionaries/modules/hcs-object-types/models/interfaces';
import { MonitoringObjectHcsService } from '../../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/services';
import { IMonitoringHcsServerDto } from '../../../../bg/modules/utility/modules/monitoring-hcs-server/interfaces';
import { ONE_SECOND } from '@bg-front/core/models/constants';
import { IRoutineMaintenanceDto } from '../../../../bg/modules/dictionaries/modules/monitoring-objects-hcs/models/interfaces/monitoring-object-hcs.interface';
import { BaseComponent } from '@bg-front/core/components';

@UntilDestroy()
@Component({
  selector: 'bg-monitoring-object-hcs-form',
  templateUrl: './monitoring-object-hcs-form.component.html',
  styleUrls: ['./monitoring-object-hcs-form.component.scss'],
})
export class MonitoringObjectHcsFormComponent extends BaseComponent implements AfterViewInit {
  /** Флаг инициализации */
  public isLoading = true;

  /** Контейнер */
  @ViewChild('formContainer', { read: ViewContainerRef, static: false }) public formContainer: ViewContainerRef;

  /** Сообщение об ошибке */
  public errors: string;

  /** @ignore */
  constructor(
    private readonly route: ActivatedRoute,
    private readonly service: MonitoringObjectHcsService,
    private readonly router: Router,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super();
  }

  /** @ignore */
  public ngAfterViewInit(): void {
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          // Если ID нет в параметрах, то открыта форма для создания записи
          if (!params.id) {
            return of({});
          }

          // Если ID невалидный вывести сообщение что ничего не найдено
          if (!Uuid.isValid(params.id)) {
            this.errors = 'Запись не найдена';
          }

          return this.service.getHcsMonitoringObject(params.id);
        }),
        catchError((err: Error) => {
          ScConsole.error([err.message]);
          this.errors = 'Ошибка при загрузке информации о записи';
          return of(undefined);
        }),
        delay(0),
        untilDestroyed(this),
      )
      .subscribe((monitoringObject: IMonitoringObjectHcsDto) => {
        this.isLoading = false;
        if (monitoringObject) {
          this.createForm(monitoringObject);
        } else {
          this.errors = this.errors || 'Запись не найдена';
          this.closeActiveForm();
        }
      });
  }

  /**
   * Закрытие формы
   */
  private closeActiveForm() {
    this.router.navigate(['dictionaries/monitoring-objects-hcs']);
  }

  /**
   * Создание формы
   * @param monitoringObject - информация об объекте
   */
  private createForm(monitoringObject: IMonitoringObjectHcsDto): void {
    this.formContainer.clear();

    const component = this.getFormComponent(
      (<IBaseDictionaryData>(<IMonitoringHcsServerDto>monitoringObject.serverId)?.integrationTypeId)?.name,
    );

    if (component) {
      component.model = {
        id: monitoringObject.id,
        creationTime: monitoringObject.creationTime,
        updateTime: monitoringObject.updateTime,
        name: monitoringObject.name,
        address: monitoringObject.address,
        coordinates: monitoringObject.coordinates,
        showOnMap: monitoringObject.showOnMap,
        hcsObjectStatus: monitoringObject.hcsObjectStatus,
        active: monitoringObject.active,

        hcsObjectKindId: (<IHcsObjectKindsDto>monitoringObject.hcsObjectKindId)?.id,
        hcsObjectTypeId: (<IHcsObjectTypesDto>monitoringObject.hcsObjectTypeId)?.id,
        operatingOrganizationId: (<IOrganization>monitoringObject.operatingOrganizationId)?.id,
        ownerOrganizationId: (<IOrganization>monitoringObject.ownerOrganizationId)?.id,
      };
      if (monitoringObject.serverId) {
        const serverLastTimeUpdate = (<IMonitoringHcsServerDto>monitoringObject?.serverId)?.lastTimeUpdate;
        const serverParamCollectionInterval =
          (<IMonitoringHcsServerDto>monitoringObject?.serverId)?.paramCollectionInterval || 0;
        const routineMaintenanceStartTime = +(<IRoutineMaintenanceDto>monitoringObject.routineMaintenanceId)?.startTime;
        const routineMaintenancePlannedFinishTime = +(<IRoutineMaintenanceDto>monitoringObject.routineMaintenanceId)
          ?.plannedFinishTime;
        const routineMaintenanceLastUpdateTime = +(<IRoutineMaintenanceDto>monitoringObject.routineMaintenanceId)
          ?.lastUpdateTime;
        const routineMaintenanceFactFinishTime = +(<IRoutineMaintenanceDto>monitoringObject.routineMaintenanceId)
          ?.factFinishTime;

        (<IMonitoringObjectHcsView>component.model).status = (<IBaseDictionaryData>monitoringObject.statusId)?.name;
        (<IMonitoringObjectHcsView>component.model).supplyTypeId = monitoringObject.supplyTypeId;
        (<IMonitoringObjectHcsView>component.model).extId = monitoringObject.extId;
        (<IMonitoringObjectHcsView>component.model).integrationType = (<IBaseDictionaryData>(
          (<IMonitoringHcsServerDto>monitoringObject.serverId)?.integrationTypeId
        ))?.name;
        (<IMonitoringObjectHcsView>component.model).server = (<IMonitoringHcsServerDto>monitoringObject.serverId)?.name;
        (<IMonitoringObjectHcsView>component.model).organization = (<IOrganization>(
          (<IMonitoringHcsServerDto>monitoringObject.serverId)?.organizationId
        ))?.name;
        (<IMonitoringObjectHcsView>component.model).phone = (<IOrganization>(
          (<IMonitoringHcsServerDto>monitoringObject.serverId)?.organizationId
        ))?.phone1;
        (<IMonitoringObjectHcsView>component.model).serverLastTimeUpdate = serverLastTimeUpdate
          ? dayjs(serverLastTimeUpdate).unix() * ONE_SECOND
          : 0;
        (<IMonitoringObjectHcsView>component.model).serverParamCollectionInterval = serverParamCollectionInterval;
        (<IMonitoringObjectHcsView>component.model).routineMaintenanceStatus = (<IBaseDictionaryData>(
          (<IRoutineMaintenanceDto>monitoringObject.routineMaintenanceId)?.statusId
        ))?.name;
        (<IMonitoringObjectHcsView>component.model).routineMaintenanceExtId = (<IRoutineMaintenanceDto>(
          monitoringObject.routineMaintenanceId
        ))?.extId;
        (<IMonitoringObjectHcsView>component.model).routineMaintenanceStartTime = routineMaintenanceStartTime
          ? dayjs(routineMaintenanceStartTime).format('DD.MM.YYYY HH:mm:ss')
          : '';
        (<IMonitoringObjectHcsView>(
          component.model
        )).routineMaintenancePlannedFinishTime = routineMaintenancePlannedFinishTime
          ? dayjs(routineMaintenancePlannedFinishTime).format('DD.MM.YYYY HH:mm:ss')
          : '';
        (<IMonitoringObjectHcsView>component.model).routineMaintenanceLastUpdateTime = routineMaintenanceLastUpdateTime
          ? dayjs(routineMaintenanceLastUpdateTime).format('DD.MM.YYYY HH:mm:ss')
          : '';
        (<IMonitoringObjectHcsView>component.model).routineMaintenanceFactFinishTime = routineMaintenanceFactFinishTime
          ? dayjs(routineMaintenanceFactFinishTime).format('DD.MM.YYYY HH:mm:ss')
          : '';
        (<IMonitoringObjectHcsView>component.model).routineMaintenanceUser = (<IUser>(
          (<IRoutineMaintenanceDto>monitoringObject.routineMaintenanceId)?.userId
        ))?.fio;
        (<IMonitoringObjectHcsView>component.model).routineMaintenanceDescription = (<IRoutineMaintenanceDto>(
          monitoringObject.routineMaintenanceId
        ))?.description;
      } else {
        (<IMonitoringObjectHcsDto>component.model).statusId = (<IBaseDictionaryData>monitoringObject.statusId)?.id;
        (<IMonitoringObjectHcsDto>component.model).supplyTypeId = (<IBaseDictionaryData>(
          monitoringObject.supplyTypeId
        ))?.id;
        (<IMonitoringObjectHcsDto>component.model).description = monitoringObject.description;
      }
    } else {
      this.noteService.pushError('Не существует формы для просмотра этого вида интеграции');
      this.closeActiveForm();
    }
  }

  /** Определение компонента формы и его создание */
  private getFormComponent(integrationType: string): MonitoringObjectBaseEditFormComponent {
    switch (integrationType) {
      case 'ATM':
        return this.formContainer.createComponent<MonitoringObjectAtmEditFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(MonitoringObjectAtmEditFormComponent),
        ).instance;
      case 'ТЭС':
        return this.formContainer.createComponent<MonitoringObjectTesEditFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(MonitoringObjectTesEditFormComponent),
        ).instance;
      default:
        return this.formContainer.createComponent<MonitoringObjectCustomEditFormComponent>(
          this.componentFactoryResolver.resolveComponentFactory(MonitoringObjectCustomEditFormComponent),
        ).instance;
    }
  }
}
