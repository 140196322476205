import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { IScInputOptions, IScSelectOptions } from '@smart-city/core/common';
import { takeUntil } from 'rxjs/operators';
import { IDictionaryModelDto, ISurveyQuestionDto } from 'smart-city-types';
import { AppInjector } from '@bg-front/core/models/classes';
import { SurveyQuestionsService } from '../../services';
import { BaseComponent } from '@bg-front/core/components';
import { IScSelectItem } from '@smart-city/core/interfaces';

/** Компонент для отображения вопроса и вариантов ответа */

@Component({
  selector: 'bg-survey-question',
  templateUrl: './survey-question.component.html',
  styleUrls: ['./survey-question.component.scss'],
})
export class SurveyQuestionComponent extends BaseComponent implements OnInit {
  /** Вопрос */
  @Input()
  public question: ISurveyQuestionDto;

  /**
   * Время в секундах, через которое запускается поиск следующего вопроса в опросе,
   * после того, как пользователь закончил отвечать на предыдущий вопрос
   */
  @Input()
  public answerTime: number;

  /** Событие при ответе */
  @Output()
  public answer = new EventEmitter();

  /** Событие при нажатии на "Пропустить вопрос" */
  @Output()
  public miss = new EventEmitter();

  /** Таймер для окончание ответа */
  private timer;

  /** Форма вопроса */
  public form: FormGroup;

  /** Массив чекбокс контролов для типа ответа с чекбоксами */
  public checkboxesArray: FormArray;

  /** Тип (вид) компонента для ответа */
  public componentType: string;

  /** Сервис вопросов */
  public readonly surveyQuestionsService: SurveyQuestionsService;

  /** Сервис FormBuilder */
  public readonly fb: FormBuilder;

  /** Настройка компоненты Текстовый ввод */
  public textOptions: IScInputOptions;

  /** Настройка компоненты Один из селекта */
  public selectOptions: IScSelectOptions;

  /** Настройка компоненты Несколько из селекта */
  public multiSelectOptions: IScSelectOptions;

  /** Настройка компоненты Текстовый ввод другого варианта */
  public otherTextOptions: IScInputOptions;

  constructor() {
    super();

    const injector = AppInjector.getInjector();
    this.surveyQuestionsService = injector.get(SurveyQuestionsService);
    this.fb = injector.get(FormBuilder);
  }

  /** @ignore */
  ngOnInit(): void {
    this.form = this.fb.group({});
    if (this.question.optionOther) {
      this.form.addControl('otherAnswer', this.fb.control(null));
      this.otherTextOptions = {
        label: 'Ваш вариант ответа',
        maxLength: 165,
      };
    }

    if (typeof this.question?.answerType === 'object') {
      switch ((this.question?.answerType as IDictionaryModelDto)?.sysname) {
        case 'textType':
          this.componentType = 'text';
          break;
        case 'oneOfListType':
          if (this.question.answerOptions.length < 3) {
            this.componentType = 'radioButtons';
          } else {
            this.componentType = 'select';
          }
          break;
        case 'fewFromListType':
          if (this.question.answerOptions.length < 3) {
            this.componentType = 'checkboxes';
          } else {
            this.componentType = 'multiSelect';
          }
          break;
      }
    }

    switch (this.componentType) {
      case 'text':
        this.form.addControl('answer', this.fb.control(null));
        this.textOptions = {
          label: this.question?.text,
          maxLength: 100,
        };
        break;
      case 'radioButtons':
        this.form.addControl('answer', this.fb.control(null));
        break;
      case 'checkboxes':
        this.form.addControl('answer', this.fb.array([]));
        this.checkboxesArray = this.form.controls.answer as FormArray;
        this.question.answerOptions.forEach(() => {
          this.checkboxesArray.push(this.fb.control(null));
        });
        if (this.question.optionOther) {
          this.checkboxesArray.push(this.fb.control(null));
        }
        break;
      case 'select':
        this.form.addControl('answer', this.fb.control(null));
        this.selectOptions = {
          title: this.question?.text,
        };
        const answerOptionsForSelect: IScSelectItem[] = [];
        for (const answerOption of this.question?.answerOptions) {
          answerOptionsForSelect.push({ id: answerOption, text: answerOption });
        }
        if (this.question.optionOther) {
          answerOptionsForSelect.push({ id: 'otherAnswer', text: 'Другое' });
        }
        this.selectOptions.data = answerOptionsForSelect;
        break;
      case 'multiSelect':
        this.form.addControl('answer', this.fb.control(null));
        this.multiSelectOptions = {
          title: this.question?.text,
          isMultiple: true,
        };
        const answerOptionsForMultiSelect: IScSelectItem[] = [];
        for (const answerOption of this.question?.answerOptions) {
          answerOptionsForMultiSelect.push({ id: answerOption, text: answerOption });
        }
        if (this.question.optionOther) {
          answerOptionsForMultiSelect.push({ id: 'otherAnswer', text: 'Другое' });
        }
        this.multiSelectOptions.data = answerOptionsForMultiSelect;
        break;
    }

    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      switch (this.componentType) {
        case 'text':
        case 'radioButtons':
        case 'checkboxes':
        case 'select':
        case 'multiSelect':
          if (!this.wasChosenOptionOther() && this.form.controls.otherAnswer) {
            this.form.controls.otherAnswer.setValue(null, { emitEvent: false });
          }

          if (this.hasAnswer()) {
            this.restartAnswerTimer();
          } else {
            this.stopAnswerTimer();
          }

          break;
      }
    });
  }

  /** Остановить таймер для окончание ответа */
  public stopAnswerTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  /** Рестарт таймера для окончание ответа */
  public restartAnswerTimer(): void {
    this.stopAnswerTimer();
    this.timer = setTimeout(() => {
      this.answer.emit(this.getAnswerData());
    }, this.answerTime * 1000);
  }

  /** Сформировать данные ответа */
  public getAnswerData(miss = false) {
    return {
      question: this.question,
      answer: !miss ? this.getAnswer() : null,
      otherAnswer: !miss ? this.form.value.otherAnswer || null : null,
    };
  }

  /** Сформировать ответ (с одним или несколькими вариантами) */
  public getAnswer(): string[] {
    let answer: string[] = [];
    switch (this.componentType) {
      case 'text':
        answer.push(this.form.value.answer);
        break;
      case 'radioButtons':
        answer.push(this.form.value.answer);
        break;
      case 'checkboxes':
        for (let i = 0; i < this.form.value.answer.length; i++) {
          if (this.form.value.answer[i]) {
            answer.push(this.question.answerOptions[i]);
          }
        }
        break;
      case 'select':
        answer.push(this.form.value.answer);
        break;
      case 'multiSelect':
        /** slice используется для копирования массива, чтобы форма не мутировалась далее */
        answer = this.form.value.answer?.slice();
        break;
    }

    for (let i = 0; i < answer.length; i++) {
      if (!answer[i] || answer[i] === 'otherAnswer') {
        answer.splice(i, 1);
      }
    }

    return answer.length ? answer : null;
  }

  /** Проверяет выбран ли вариант "Другой вариант" при ответе */
  public wasChosenOptionOther(): boolean {
    switch (this.componentType) {
      case 'text':
      case 'radioButtons':
      case 'select':
        return this.form.value.answer === 'otherAnswer';
        break;
      case 'checkboxes':
        return this.checkboxesArray?.value[this.checkboxesArray.length - 1] && this.question.optionOther;
        break;
      case 'multiSelect':
        return this.form.value.answer?.includes('otherAnswer');
        break;
    }
  }

  /** Проверяет есть ли какой-либо вариант ответа при ответе */
  public hasAnswer(): boolean {
    switch (this.componentType) {
      case 'text':
      case 'radioButtons':
      case 'select':
        return (
          (this.form.value.answer && !this.wasChosenOptionOther()) ||
          (this.wasChosenOptionOther() && this.form.value.otherAnswer)
        );
        break;
      case 'checkboxes':
        return (
          (this.checkboxesArray?.value?.includes(true) && !this.wasChosenOptionOther()) ||
          (this.wasChosenOptionOther() && this.form.value.otherAnswer)
        );
        break;
      case 'multiSelect':
        return (
          (this.form.value.answer?.length && !this.wasChosenOptionOther()) ||
          (this.wasChosenOptionOther() && this.form.value.otherAnswer)
        );
        break;
    }
  }

  /** Обработчик события при нажатии на "Пропустить вопрос" */
  public onMiss(): void {
    this.stopAnswerTimer();
    this.miss.emit(this.getAnswerData(true));
  }
}
