import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IScSelectOptions } from '@smart-city/core/common';
import { AccessService, IAccessAction, Settings2Service } from '@smart-city/core/services';
import { IScSelectItem } from '@smart-city/core/interfaces';
import { takeUntil, tap } from 'rxjs/operators';
import { IAdminMunicipalSchemaDto, IAnyObject } from 'smart-city-types';
import { IButtonFilter } from '../../../models/interfaces';
import { BaseComponent } from '@bg-front/core/components';
import { RegistryFilterService } from '@bg-front/core/services';
import { TOrganizationTypeSysname } from '@bg-front/core/models/types';

@Component({
  selector: 'bg-planned-works-incident-filter',
  templateUrl: './planned-works-incident-filter.component.html',
  styleUrls: ['./planned-works-incident-filter.component.scss'],
})
export class PlannedWorksIncidentFilterComponent extends BaseComponent implements OnInit, OnDestroy {
  /**
   * Признак показа всех фильтров.
   */
  public showAllFilters = false;

  /**
   * Настрйоки фильтра по МО
   */
  public optionsMO: IScSelectOptions;

  /**
   * Натройки фильтра по Типу инцидентов
   */
  public optionsIncidentTypes: IScSelectOptions;

  /**
   * Настройки фильтра по Источнику по поступления
   */
  public optionsSourceType: IScSelectOptions;

  /**
   * Описание формгруппы
   */
  public formGroup: FormGroup = new FormGroup({
    municipal: new FormControl(undefined),
    incidentTypeId: new FormControl(undefined),
    sourceType: new FormControl(undefined),
    dateTimeFrom: new FormControl(undefined),
    dateTimeTo: new FormControl(undefined),
  });
  /**
   * Эммитр для оповещения о изменении
   */
  @Output()
  public changedFilter: EventEmitter<IAnyObject> = new EventEmitter<IAnyObject>();
  /**
   * Массив видимых кнопок
   */
  public buttons: IButtonFilter[] = [];

  /**
   * Массив невидимых кнопок
   */
  public hiddenButtons: IButtonFilter[] = [];

  /** Доступность фильтра "Муниципальное образование" */
  public globalMoFilter: IAccessAction = { name: 'globalMoFilter' };

  /** Видимость контрола "Муниципальное образование" */
  public isMoFilterVisible: boolean;

  /**
   * @ignore
   */
  constructor(
    private settings: Settings2Service,
    private registryFilterService: RegistryFilterService,
    private readonly accessService: AccessService,
    public readonly route: ActivatedRoute,
  ) {
    super();

    let sourceTypeSysname: TOrganizationTypeSysname = this.route.snapshot?.data?.workspace;
    if (sourceTypeSysname === 'ulk') {
      sourceTypeSysname = 'edds';
    }

    this.registryFilterService
      .getStatusesFilters({
        lifeCycleTypeSysname: 'incident',
        lifeCycleDocTypeSysname: 'plannedWork',
        lifeCycleOrgTypeParamOrgTypeSysname: sourceTypeSysname
      }, 'plannedWorks')
      .pipe(
        tap((buttons: IButtonFilter[]) => {
          this.hiddenButtons = buttons;
          this.hiddenButtons.push(<IButtonFilter>{
            name: 'Созданы мной',
            sysname: 'createdByMe',
            state: false,
            filter: { createdBy: this.settings.currentUser.id },
          });

          if (this.accessService.accessMap['ExpiredFilterButton']?.visible) {
            this.hiddenButtons.push(<IButtonFilter>{
              name: 'Просроченные',
              sysname: 'expired',
              state: false,
              filter: { 'deadlineIsExpired': true },
            });
          }

          this.hiddenButtons.push(<IButtonFilter>{
            name: 'Мои происшествия',
            sysname: 'myResponsible',
            state: false,
            filter: {
              responsible: this.settings.currentUser.id,
            },
          });

          this.buttons = this.hiddenButtons.slice(0, 3);
          this.hiddenButtons.splice(0, 3);
        }),
      )
      .subscribe(() => {
        this.changedFilter.emit(this.getFilterObject());
      });
  }

  /**
   * @ignore
   */
  public ngOnInit(): void {
    this.globalMoFilter = this.accessService.accessMap[this.globalMoFilter.name];
    this.isMoFilterVisible = this.globalMoFilter?.visible;

    this.changedFilter.emit(this.getFilterObject());

    this.optionsMO = <IScSelectOptions>{
      name: 'municipal',
      title: 'Муниципальное образование',
      data: this.settings.allMo.map((mo: IAdminMunicipalSchemaDto) => {
        return <IScSelectItem>{
          id: mo.id,
          text: mo.name,
        };
      }),
      formGroup: this.formGroup,
      clearable: true,
    };

    this.optionsIncidentTypes = <IScSelectOptions>{
      name: 'incidentTypeId',
      title: 'Тип КСиП',
      service: 'Admin',
      entity: 'IncidentTypes',
      modern: true,
      fieldName: 'name',
      formGroup: this.formGroup,
      clearable: true,
    };

    this.formGroup.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.changedFilter.emit(this.getFilterObject());
    });
  }

  /**
   * Скрытие/Открытие фильтра
   */
  public hide() {
    this.showAllFilters = !this.showAllFilters;
  }

  /**
   * Получаем состояние кнопки
   * @param sysName системное имя кнопки
   */
  public getState(sysName: string): boolean {
    const btn = this.getButton(sysName);
    return btn ? btn.state : false;
  }

  /**
   * Обработка нажатия на кнопку
   */
  public selectFilter(sysName: string): void {
    const btn = this.getButton(sysName);
    if (btn) {
      /**
       * Проверяем, что данная кнопка не является единственной выбранной
       */
      if (btn.state) {
        if ([...this.buttons, ...this.hiddenButtons].filter((btn: IButtonFilter) => btn.state).length === 1) {
          this.noteService.pushWarning('Должно быть выбрано хотя бы одно состояние инцидента');
          return;
        }
      }

      btn.state = !btn.state;
      this.changedFilter.emit(this.getFilterObject());
    }
  }

  /**
   * Получаем кнопку по системному имени
   * @param sysName системнео имя
   */
  private getButton(sysName: string): IButtonFilter {
    return [...this.buttons, ...this.hiddenButtons].find((btn: IButtonFilter) => btn.sysname === sysName);
  }

  /**
   * Формируем объект фильтра
   */
  private getFilterObject(): IAnyObject {
    const filter: IAnyObject = {
      $and: [],
    };

    const orFilter = { $or: [] };

    [...this.buttons, ...this.hiddenButtons]
      .filter((btn: IButtonFilter) => btn.state && btn.filter)
      .forEach((btn: IButtonFilter) => {
        /**
         * Кнопки "Создано мной", "просроченные", "Мои Происшествия" попадают под условие И
         */
        if (btn.sysname !== 'createdByMe' && btn.sysname !== 'expired' && btn.sysname !== 'myResponsible') {
          orFilter.$or.push(btn.filter);
        } else {
          filter.$and.push(btn.filter);
        }
      });

    if (orFilter.$or.length) {
      filter.$and.push(orFilter);
    }

    if (this.formGroup.controls['municipal'].value) {
      filter.$and.push({
        'organization.mo.id': this.formGroup.controls['municipal'].value,
      });
    }

    if (this.formGroup.controls['incidentTypeId'].value) {
      filter.$and.push({
        incidentTypeId: this.formGroup.controls['incidentTypeId'].value,
      });
    }

    if (this.formGroup.controls['sourceType'].value) {
      filter.$and.push({
        sourceType: this.formGroup.controls['sourceType'].value,
      });
    }

    if (this.formGroup.controls['dateTimeFrom'].value) {
      filter.$and.push({
        timeCreate: {
          $gte: this.formGroup.controls['dateTimeFrom'].value,
        },
      });
    }

    if (this.formGroup.controls['dateTimeTo'].value) {
      filter.$and.push({
        timeCreate: {
          $lte: this.formGroup.controls['dateTimeTo'].value,
        },
      });
    }
    return filter.$and.length ? filter : undefined;
  }
}
